import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FnUiPopoverComponent } from 'projects/gpp/src/app/shared/fn-ui-popover/fn-ui-popover.component';
import { FunctionArgumentsDirective } from '../../directives/function-arguments.directive';
import { ConditionFunction } from '../../models';
import { FunctionResult } from '../../models/function-result.model';

@Component({
  selector: 'app-insert-function-popover',
  templateUrl: './insert-function-popover.component.html',
  styleUrls: ['./insert-function-popover.component.scss']
})
export class InsertFunctionPopoverComponent {

  @Input() function: ConditionFunction;

  @Output() functionApplied = new EventEmitter<FunctionResult>();

  @ViewChild(FnUiPopoverComponent, { static: true }) fnUiPopoverComponent: FnUiPopoverComponent;
  @ViewChild(FunctionArgumentsDirective, { static: false }) functionArgumentsDirective: FunctionArgumentsDirective;

  popoverOffsetY = 20;

  get popoverOpen(): boolean {
    return this.fnUiPopoverComponent.popoverOpen;
  }

  openPopover(): void {
    this.fnUiPopoverComponent?.openPopover();
  }

  closePopover(): void {
    this.fnUiPopoverComponent?.closePopover();
  }

  updatePopoverPosition(): void {
    this.fnUiPopoverComponent?.updatePopoverPosition();
  }

  applyFunction(): void {
    const argumentValues = this.functionArgumentsDirective.argumentValues;
    const functionArguments = `(${argumentValues.join(', ')})`;
    const functionName = this.function.alias.split('(').shift();
    const functionAsString = functionName + functionArguments;
    this.functionApplied.emit({ argumentValues, functionAsString });
    this.closePopover();
  }

}
