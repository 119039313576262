export enum CountryPreferences {
	DEPARTMENT = 'COUNTRY_BU-DEPARTMENT',
	OFFICE = 'COUNTRY_BU-OFFICE',
	REC_STATUS = 'COUNTRY_BU-REC_STATUS',
	PROFILE_CHANGE_STATUS = 'COUNTRY_BU-PROFILE_CHANGE_STATUS',
	PENDING_ACTION = 'COUNTRY_BU-PENDING_ACTION',
	EFFECTIVE_DATE = 'EFFECTIVE_DATE',
	COUNTRYCODE = 'COUNTRY_BU-COUNTRYCODE',
	CURRENCY = 'COUNTRY_CFG-CURRENCY',
	COUNTRYNAME = 'COUNTRY_CFG-COUNTRYNAME',
	OFAC_MANDATORY = 'COUNTRY_BU-OFAC_MANDATORY',
	STOPFLAGFROM = 'COUNTRY_BU-STOPFLAGFROM',
	REASONFROM = 'COUNTRY_BU-REASONFROM',
	DESCRIPTION_DEBIT = 'STOP_FLAGS-DESCRIPTION_DEBIT',
	STOPFLAGTO = 'COUNTRY_BU-STOPFLAGTO',
	REASONTO = 'COUNTRY_BU-REASONTO',
	DESCRIPTION_CREDIT = 'STOP_FLAGS-DESCRIPTION_CREDIT',
	DEFAULTCORBIC = 'COUNTRY_BU-DEFAULTCORBIC',
	CALNAME = 'COUNTRY_CFG-CALNAME',
	CHK_DIGIT_TYPE = 'COUNTRY_CFG-CHK_DIGIT_TYPE',
	IBAN_LEN = 'COUNTRY_CFG-IBAN_LEN',
	IBAN_MANDATORY = 'COUNTRY_CFG-IBAN_MANDATORY',
	IBAN_BANK_ID_POS = 'COUNTRY_CFG-IBAN_BANK_ID_POS',
	IBAN_BANK_ID_LEN = 'COUNTRY_CFG-IBAN_BANK_ID_LEN',
	IBAN_BRANCH_POS = 'COUNTRY_CFG-IBAN_BRANCH_POS',
	IBAN_BRANCH_LEN = 'COUNTRY_CFG-IBAN_BRANCH_LEN',
	IBAN_ACCOUNT_POS = 'COUNTRY_CFG-IBAN_ACCOUNT_POS',
	IBAN_ACCOUNT_LEN = 'COUNTRY_CFG-IBAN_ACCOUNT_LEN',
	IBAN_LABEL = 'IBAN_EXAMPLE_LABEL',
	DRAWEE_BIC = 'COUNTRY_BU-DRAWEE_BIC',
	DRAWEE_ACCT_NB = 'COUNTRY_BU-DRAWEE_ACCT_NB',
	DRAWEE_EXT_ACCT_NB = 'COUNTRY_BU-DRAWEE_EXT_ACCT_NB',
	DRAWEE_NM = 'COUNTRY_BU-DRAWEE_NM',
	DRAWEE_ADRLINE1 = 'COUNTRY_BU-DRAWEE_ADRLINE1',
	DRAWEE_ADRLINE2 = 'COUNTRY_BU-DRAWEE_ADRLINE2',
	DRAWEE_ADRLINE3 = 'COUNTRY_BU-DRAWEE_ADRLINE3',
	DRAWEE_ADRLINE4 = 'COUNTRY_BU-DRAWEE_ADRLINE4',
	DRAWEE_ADRLINE5 = 'COUNTRY_BU-DRAWEE_ADRLINE5',
	TIME_STAMP = 'TIME_STAMP',
	UID = 'VIRTUAL-UID',
	PROFILE_UPDATE_PK = 'PROFILE_UPDATE_PK',
	PU_TIME_STAMP = 'PU_TIME_STAMP',
	NOTE = 'NOTE'
}
