import {Component} from '@angular/core';
import {Drawdown} from '../../models/drawdown.model';
import {IValueChange} from '../../../core/interfaces/form-value-change.interface';
import {
  BFOnFormLoadComplete,
  BFOnValueChange, BFOnInit,
  BFOnButtonClick, BFProfileOnSave, BFProfileAfterSave
} from '../../../core/interfaces/sdk-profile-hooks.interface';
import {DrawDownCustomReducer} from './drawdown-custom.reducer';
import {ProfileFormTemplateProviders} from '../../../core/templates/profile-form.template.providers';
import {BFEvents, DrilldownComponent} from '@fgpp-ui/components';
import {AbstractProfileFormComponent} from "../../abstract/abstract-profile/abstract-profile-form.component";
import {AccountSource} from "../advising/advising.component";
import {filter} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {ProfileLayoutType} from "../../enums/profile-layout-type.enum";

@Component({
  selector: 'fa-drawdown',
  templateUrl: '../../../core/templates/profile-form.template.html',
  styleUrls: ['../../../core/templates/profile-form.template.scss'],
  providers: ProfileFormTemplateProviders,
})

export class DrawdownComponent extends AbstractProfileFormComponent implements BFOnInit, BFOnValueChange, BFOnFormLoadComplete,
  BFProfileOnSave, BFOnButtonClick, BFProfileAfterSave {

  private defaultPartyCode: string;
  private accountSource: AccountSource;

  fgppOnInit(): void {
    this.setCustomReducer(new DrawDownCustomReducer());
    this.drilldownEvent.listen().pipe(filter((e) => e.target === Drawdown.DEBTOR_ACC_NO), takeUntil(this.destroy$)).subscribe((event) => {
      const data = event.value.data;
      const accSource = this.formMgr.get(Drawdown.ACC_SOURCE).getValue();
      if (data && data.length === 1 && accSource === AccountSource.INTERNAL) {
        this.populateDebtorDetails(data[0]);
      }
    });
  }

  fgppValueChanges(change: IValueChange): void {
    if (change.control === Drawdown.DEBTOR_ACC_NO) {
      const templateData = this.getDrilldownData(Drawdown.DEBTOR_ACC_NO);
      const accNumber = this.formMgr.get(Drawdown.DEBTOR_ACC_NO).getValue();
      const accSource = this.formMgr.get(Drawdown.ACC_SOURCE).getValue();
      if (accSource === AccountSource.INTERNAL) {
        this.populateDebtorDetails(templateData);
      }
      if (!accNumber) {
        this.formMgr.get(Drawdown.DEBTOR_CURRENCY).reset();
      }
    } else if (change.control === Drawdown.START_DATE) {
      if (this.formMgr.get(Drawdown.START_DATE).getValue() && this.formMgr.get(Drawdown.END_DATE).getValue()) {
        if (this.formMgr.get(Drawdown.START_DATE).getValue() >= this.formMgr.get(Drawdown.END_DATE).getValue()) {
          this.formMgr.get(Drawdown.START_DATE).setValue('');
          this.notificationService.error(this.translate.instant('business-framework.profiles.113.errors.greater_from_date.message'));
        }
      }
    } else if (change.control === Drawdown.END_DATE) {
      if (this.formMgr.get(Drawdown.START_DATE).getValue() && this.formMgr.get(Drawdown.END_DATE).getValue()) {
        if (this.formMgr.get(Drawdown.END_DATE).getValue() <= this.formMgr.get(Drawdown.START_DATE).getValue()) {
          this.formMgr.get(Drawdown.END_DATE).setValue('');
          this.notificationService.error(this.translate.instant('business-framework.profiles.113.errors.smaller_end_date.message'));
        }
      }
    } else if (change.control === Drawdown.DEPARTMENT && this.formMgr.get(Drawdown.DEPARTMENT).getValue()) {
      this.getAttachmentButtonPermission(this.formMgr.get(Drawdown.DEPARTMENT).getValue());
    } else if (change.control === Drawdown.ACC_SOURCE) {
      this.formMgr.get(Drawdown.DEBTOR_ACC_NO).reset();
      this.formMgr.get(Drawdown.ACCOUNTS_CUSTNAME).reset();
      this.formMgr.get(Drawdown.ACCOUNTS_CUSTOMERCODE).reset();
      this.formMgr.get(Drawdown.ACCOUNTS_NAME).reset();
      this.formMgr.get(Drawdown.DEBTOR_CURRENCY).reset();

      this.handleAccSourceValidations(change.value.inputValue);
    }
  }

  private formatFields(): void {
    const fieldsArray = [Drawdown.START_DATE, Drawdown.END_DATE, Drawdown.LAST_USE_DATE, Drawdown.MAX_AMOUNT];
    fieldsArray.forEach(field => {
      if (this.formMgr.get(field).getValue() !== '' && this.formMgr.get(field).getValue() !== null) {
        this.formMgr.get(field).setValue(this.formMgr.get(field).getValue().toString());
      }
    });
  }

  fgppOnSave(): boolean {
    this.formatFields();
    return true;
  }

  fgppOnButtonClick(actionPayload: BFEvents.ButtonEvent): void {
    if (actionPayload.target === 'REPLICATE' && actionPayload.name === 'buttonClick') {
      this.formatFields();
      actionPayload.target = 'CREATE';
      this.onSaveProfile(actionPayload, false);
    }
  }

  fgppAfterSave(payload: BFEvents.ButtonEvent) {
    if (payload.value.aliasFor  === 'REPLICATE') {
      const profileData = {
        'DRAWDOWN-DEPARTMENT': this.formMgr.get(Drawdown.DEPARTMENT).getValue(),
        'DRAWDOWN-DEBTOR_ACC_NO': this.formMgr.get(Drawdown.DEBTOR_ACC_NO).getValue(),
        'DRAWDOWN-DEBTOR_CURRENCY': this.formMgr.get(Drawdown.DEBTOR_CURRENCY).getValue(),
        'ACCOUNTS-NAME': this.formMgr.get(Drawdown.ACCOUNTS_NAME).getValue(),
        'ACCOUNTS-CUSTNAME': this.formMgr.get(Drawdown.ACCOUNTS_CUSTNAME).getValue(),
        'ACCOUNTS-CUSTOMERCODE': this.formMgr.get(Drawdown.ACCOUNTS_CUSTOMERCODE).getValue()
      };
      this.onSaveAsProfile(this.formMgr.get(Drawdown.OFFICE).getValue(), structuredClone(profileData));
    }
    return true;
  }

  private populateDebtorDetails(templateData: any) {
    if (templateData) {
      this.formMgr.get(Drawdown.ACCOUNTS_NAME).setValue(templateData['ACCOUNTS-ACCOUNTNAME']);
      this.formMgr.get(Drawdown.ACCOUNTS_CUSTNAME).setValue(templateData['CUSTOMRS-CUST_NAME']);
      this.formMgr.get(Drawdown.ACCOUNTS_CUSTOMERCODE).setValue(templateData['ACCOUNTS-CUST_CODE']);
      this.formMgr.get(Drawdown.DEBTOR_CURRENCY).setValue(templateData['ACCOUNTS-CURRENCY']);
    } else {
      this.formMgr.get(Drawdown.ACCOUNTS_NAME).reset().disable();
      this.formMgr.get(Drawdown.ACCOUNTS_CUSTNAME).reset().disable();
      this.formMgr.get(Drawdown.ACCOUNTS_CUSTOMERCODE).reset().disable();
      this.formMgr.get(Drawdown.DEBTOR_CURRENCY).reset().disable();
    }
  }

  fgppFormLoadComplete(): void {
    this.office = this.formMgr.get(Drawdown.OFFICE).getValue();
    this.getAttachmentButtonPermission(this.formMgr.get(Drawdown.DEPARTMENT).getValue());

    this.validateExternalAccount();
  }

  private validateExternalAccount() {
    this.defaultPartyCode = this.getData()[Drawdown.DEFAULT_CUST_CODE];
    this.accountSource = this.getData()[Drawdown.ACC_SOURCE];
    if (!this.defaultPartyCode) {
      this.formMgr.get(Drawdown.ACC_SOURCE).disable();
    }
    if (this.layoutMode !== ProfileLayoutType.VIEW) {
      this.handleAccSourceValidations(this.accountSource);
    }
  }

  handleAccSourceValidations(accSource: AccountSource) {
    if (accSource === AccountSource.EXTERNAL) {
      this.formMgr.get(Drawdown.ACCOUNTS_CUSTOMERCODE).setValue(this.defaultPartyCode);
      this.formMgr.get(Drawdown.DEBTOR_CURRENCY).enable().markAsRequired();
      this.layoutEvent.setProperty(Drawdown.DEBTOR_ACC_NO, 'disableSeek', true);
      this.layoutEvent.setProperty(Drawdown.DEBTOR_ACC_NO, 'checkManualEntry', false);
    } else if (accSource === AccountSource.INTERNAL) {

      setTimeout(() => {
        this.layoutEvent.setProperty(Drawdown.DEBTOR_ACC_NO, 'disableSeek', false);
      }, 0);
      this.formMgr.get(Drawdown.DEBTOR_CURRENCY).disable().markAsOptional();
      this.layoutEvent.setProperty(Drawdown.DEBTOR_ACC_NO, 'checkManualEntry', true);
    }
  }
}
