import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ComponentsModule } from '@fgpp-ui/components';
import { Pacs_008DomesticPaymentComponent } from './components/pacs_008-domestic-payment/pacs_008-domestic-payment.component';
import { Pacs_008InternationalPaymentComponent } from './components/pacs_008-international-payment/pacs_008-international-payment.component';
import { Pacs_008FxPaymentComponent } from './components/pacs_008-fx-payment/pacs_008-fx-payment.component';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { CallToActionComponent } from './third-party/call-to-action/call-to-action.component';
import { MatListModule } from '@angular/material/list';
import {BfMessageActionsService} from "./services/bf-message-actions.service";
import {BfMessageNavigationService} from "./services/bf-message-navigation.service";
import {BfCountryPickerService} from "./services/bf-country-picker.service";
import {AUTHENTICATION_SERVICE} from "@fgpp-ui/messages-components";
import {AuthenticationService} from "../../authentication/services/authentication.service";
import {ActivityTrackerApiService} from "./common/services/activity-tracker-api.service";

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    CdkAccordionModule,
    MatIconModule,
    TranslateModule.forChild({
      isolate: true
    }),
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatButtonModule,
    MatListModule,
  ],
  providers: [
    ActivityTrackerApiService,
    BfMessageActionsService,
    BfMessageNavigationService,
    BfCountryPickerService,
    {
      provide: AUTHENTICATION_SERVICE,
      useExisting: AuthenticationService
    }
  ],
  declarations: [
    Pacs_008DomesticPaymentComponent,
    Pacs_008InternationalPaymentComponent,
    Pacs_008FxPaymentComponent,
    CallToActionComponent
  ]
})
export class BusinessFrameworkMessagesModule { }
