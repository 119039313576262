import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TableRow } from '@fgpp-ui/grid';
import { Subscription } from 'rxjs';
import { RouterHelper } from '../../../base-view/classes/router-helper';
import { TaskBarService } from '../../../core/navigation/task-bar/services/task-bar.service';
import { GridSearchResponse } from '../../../grid/models/grid-search-response.model';
import { FnUiInputComponent } from '../../../shared/fn-ui-input/fn-ui-input.component';
import { PRULE_TYPES_RULE_TYPE_ID, RULE_TYPE_DELIMITER } from '../../models/consts/rule-type.consts';
import { RuleTypeService } from '../../services/rule-type.service';
import { RuleTypesApiService } from '../../services/rule-types-api.service';
@Component({
  selector: 'app-business-rules',
  templateUrl: './business-rules.component.html',
  styleUrls: ['./business-rules.component.scss']
})
export class BusinessRulesComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(FnUiInputComponent) searchFilterInput: FnUiInputComponent;

  resourceForSearch = [];
  splitListAtIndex = -1;
  lastSelectedRuleType = '';
  checked: boolean;
  searchFilter: string;

  private _selectedRules = [];
  private _subscriber = new Subscription();

  constructor(private router: Router,
              private route: ActivatedRoute,
              private taskBarService: TaskBarService,
              private ruleTypesApiService: RuleTypesApiService,
              public ruleTypeService: RuleTypeService) { }

  ngOnInit(): void {
      this.taskBarService.taskBarState.isLandingPage = true;
      this.ruleTypeService.removeAll();
      this.initRuleTypes();
  }

  ngAfterViewInit(): void {
    const subscription = this.ruleTypeService.setFocus$.subscribe((value) => {
      if (value) {
        this.searchFilterInput.focus();
      }
    });
    this._subscriber.add(subscription);
    const searchInputElement = this.searchFilterInput.matInput['_elementRef'].nativeElement;
    searchInputElement.setAttribute('azure-id', 'rules-search-filter-tree');
    searchInputElement.id = 'searchFilter';
  }

  ngOnDestroy(): void {
    this._subscriber.unsubscribe();
  }

  toggleSelection(row: TableRow, selected: boolean): void {
    if (this.ruleTypeService.disableTreeItems) {
      return;
    }
    if (selected) {
      this._selectedRules.push(row[PRULE_TYPES_RULE_TYPE_ID]);
      this.ruleTypeService.put(row[PRULE_TYPES_RULE_TYPE_ID], row);
    } else {
      this._selectedRules = this._selectedRules.filter(ruleType => ruleType !== row[PRULE_TYPES_RULE_TYPE_ID]);
      this.ruleTypeService.remove(row[PRULE_TYPES_RULE_TYPE_ID]);
    }
    this.splitListAtIndex = this.ruleTypeService.splitListAtIndex;
    this._selectedRules.length > 0 ?
      this.router.navigate(['/home/rules/business-rules'], { queryParams: { ruleType: this._selectedRules.sort()?.join(RULE_TYPE_DELIMITER) } }) :
      this.router.navigate(['/home/rules/business-rules'], { queryParams: {} });
  }

  unselectAll(): void {
    if (this.ruleTypeService.disableTreeItems) {
      return;
    }
    this._selectedRules = [];
    this.ruleTypeService.clearAll();
    this.ruleTypeService.sort();
    this.splitListAtIndex = -1;
    this.router.navigate(['/home/rules/business-rules'], { queryParams: {} });
  }

  filterRuleTypes(searchString: string): void {
    this.ruleTypeService.searchFilter = searchString;
    this.resourceForSearch = this.ruleTypeService.sort();
  }

  private initRuleTypes(): void {
    this.ruleTypesApiService.search().subscribe({
      next: (response: GridSearchResponse) => {
        this.resourceForSearch = response.rows;
        this.ruleTypeService.rows = response.rows;
        this.ruleTypeService.noRuleTypes$.next(response.rows.length < 1);
        this.setSelectedRuleTypes();
      },
      error: () => {
        this.ruleTypeService.noRuleTypes$.next(true);
      }
    });
  }

  private setSelectedRuleTypes(): void {
    const subscription = this.route.queryParams.subscribe((params: Params) => {
      if (RouterHelper.getSegments(this.router).pop().path !== 'business-rules') {
        return;
      }
      const ruleType = params.ruleType;
      if (ruleType) {
        const ruleTypeList = ruleType.split(RULE_TYPE_DELIMITER);
        if (JSON.stringify(ruleTypeList) !== JSON.stringify(this._selectedRules)) { // if there is any change from the url and its not a regular click
          this._selectedRules = ruleTypeList; // take from the url because its the updated one.
          for (const item of this.resourceForSearch) {
            if (ruleTypeList.indexOf(item[PRULE_TYPES_RULE_TYPE_ID]) === -1) {
              item.selected = false;
            } else {
              item.selected = true;
              this.ruleTypeService.put(item[PRULE_TYPES_RULE_TYPE_ID], item);
            }
          }
        }
      } else {
        this._selectedRules = [];
        this.ruleTypeService.clearAll();
      }
      this.resourceForSearch = this.ruleTypeService.sortRuleTypes();
      this.splitListAtIndex = this.ruleTypeService.splitListAtIndex;
    });
    this._subscriber.add(subscription);
  }
}
