import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  template: `
    <app-fa-dialog [title]="data.modalTitle" [footer]="footerTemplate" [body]="bodyTemplate">
      <ng-template #bodyTemplate>
        <span>{{data.content | translate}}</span>
      </ng-template>

      <ng-template #footerTemplate>
        <button mat-button color="primary" id="exit-and-discard-modal-dismiss-button" mat-dialog-close>
          {{'forms.no_button' | translate}}
        </button>
        <button mat-flat-button color="primary" id="exit-and-discard-modal-save-button" [mat-dialog-close]="'yes'">
          {{'forms.yes_button' | translate}}
        </button>
      </ng-template>
    </app-fa-dialog>    `,
    styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

}
