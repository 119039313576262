import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MainViewComponent } from '../../base-view/components/main-view/main-view.component';
import { NavigationGuardService } from '../../core/navigation/services/navigation-guard.service';
import { TabTaskBarResolver } from '../../resolvers/tab-task-bar.resolver';
import { TaskBarItemComponentResolver } from '../../resolvers/task-bar-item-component.resolver';
import { LiquidityComponent } from '../components/liquidity/liquidity.component';
import { RidirectLiquidityGuard } from './guards/redirect-liquidity.guard';
import { LiquidityTreeResolver } from './resolvers/liquidity-tree.resolver';

export const liquidityRoutes = [
  {
    path: '',
    component: LiquidityComponent,
    canActivate: [RidirectLiquidityGuard]
  },
  {
    path: ':taskBarItem',
    component: MainViewComponent,
    resolve: {
      taskBar: TabTaskBarResolver,
      taskBarComponent: TaskBarItemComponentResolver,
      tree: LiquidityTreeResolver
    },
    children: [
      {
        path: '',
        canActivate: [NavigationGuardService],
        component: LiquidityComponent
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(liquidityRoutes)],
  exports: [RouterModule]
})
export class LiquidityRoutingModule { }
