import { NgModule } from '@angular/core';
import { FileAttachmentManagerComponent } from './file-attachment-manager/file-attachment-manager.component';
import { FileAttachmentManagerMapperService } from './file-attachment-manager/services/file-attachment-manager-mapper.service';
import { FileAttachmentModule } from './file-attachment/file-attachment.module';
import { FileAttachmentTableModule } from './file-attachment-table/file-attachment-table.module';
import { StepperModule } from './stepper/stepper.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonModule, LocationStrategy, HashLocationStrategy } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';


@NgModule({
    declarations: [
    FileAttachmentManagerComponent
    ],
    imports: [
        FileAttachmentModule,
        FileAttachmentTableModule,
        StepperModule,
        MatTooltipModule,
        CommonModule,
        MatButtonModule
    ],
    providers: [ FileAttachmentManagerMapperService],
    exports: [
        FileAttachmentManagerComponent
    ]
  })
export class AttachmentModule {

}
