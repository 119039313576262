export enum AuthenticationUrls {
    SIGN_IN = 'do/login',
    SIGN_IN_TOKEN_AUTH = 'do/tokenAuth',
    USER_DATA = 'do/userdata',
    VASCO_CRONTO_VALIDATE = 'do/security/vasco/cronto-license-validate',
    VASCO_CRONTO_ACTIVATE = 'do/security/vasco/cronto-license-activate',
    CHANGE_PASSWORD = 'do/changePassword',
    SIGN_OUT = 'do/logout',
    FORCE_SIGN_OUT = 'do/forceLogout'
}
