import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { IFileAttachmentDialogApi } from './base/file-attachment-dialog-api.interface';
import { SourceDetails } from '../models/source-details.model';
import { FileAttachmentMapping } from '../models/file-attachment-mapping.model';

export interface AttachmentListReponse {
    attachments: Array<FileAttachmentMapping>;
}

@Injectable({
    providedIn: 'root'
})
export class FileAttachmentApiService implements IFileAttachmentDialogApi {

    private apiUrl = 'do/forms/attachments';
    constructor(private http: HttpClient) { }

    getFileDownload(fileId: string): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Accept: 'application/json'
        });
        const url = `${this.apiUrl}/download/${fileId}`;
        return this.http.get<any>(url, { headers, responseType: 'blob' as 'json' })
            .pipe(catchError(this.handleError));
    }

    getAttachmentList(data: SourceDetails): Observable<AttachmentListReponse> {
        const url = `${this.apiUrl}/${data.sourceUid}/${data.sourceTypeId}`;
        return this.http.get<AttachmentListReponse>(url)
        .pipe(catchError(this.handleError));
    }

    private handleError(err) {
        // in a real world app, we may send the server to some remote logging infrastructure
        // instead of just logging it to the console
        let errorMessage: string;
        if (err.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            errorMessage = `An error occurred: ${err.error.message || err.message}`;
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            errorMessage = `Backend returned code ${err.status}: ${err.message}`;
        }
        return throwError(errorMessage);
    }
}
