<div class="main-login">
  <div class="col-xs-6 col-md-6 contentWrapper" ng-class="{'rtl':isLeftToRight}">
    <div class="header"></div>

    <div class="content">
      <div class="row">
        <span class="col-xs-3 col-md-3"></span>

        <div class="col-xs-6 col-md-6">
          <div class="logo"></div>

<!--          <div class="main-content" ui-view></div>-->
          <router-outlet></router-outlet>
        </div>
        <span class="col-xs-3 col-md-3"></span>
      </div>
    </div>

    <div class="footer">
      <div class="footerContent">
      <span class="col-xs-3 col-md-3"></span>
      <span class="col-xs-6 col-md-6 url">
        <span class="version">Version {{productVersion}}</span>
        <a target="_blank" href="https://www.finastra.com" rel="noopener noreferrer">https://www.finastra.com</a>
      </span>
      <span class="col-xs-3 col-md-3"></span>
      </div>
    </div>
  </div>
  <div class="col-xs-6 col-md-6 brandingWrapper">
    <div class="background">
    </div>
  </div>
</div>

