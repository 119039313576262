import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {
  CheckBalanceRequestBody,
  CheckBalanceResponse,
  FxErrorResponse,
  FxRate,
  FxRateModal,
  FxRateRetry,
  FxRequiredFields,
  GetRateFields,
  GetRateRequestBody,
  GetRateResponse
} from '../models/fx-rate.model';
import {NotificationService} from '@fgpp-ui/components';
import {FormatsService} from '../../../../shared/services/formats.service';
import {FxRateApiService} from './fx-rate-api.service';
import {FxRateModalService} from './fx-rate-modal.service';
import {DataServiceService} from '../../../../services/data-service.service';
import {TransactionsLimits} from "../models/enums/transactions-limit.enum";

@Injectable()
export class FxRateService {

  readonly requiredGetFxRateFields = ['P_MID', 'P_OFFICE', 'OX_STTLM_CCY', 'OX_STTLM_AMT', 'X_DBTR_ACCT_ID',
    'P_DBT_ACCT_CCY', 'P_DBT_AMT', 'P_RVS_SELL'];
  private getRateRequestBody: GetRateRequestBody;

  constructor(private translateService: TranslateService, private notificationService: NotificationService,
              private formatsService: FormatsService, private fxRateApiService: FxRateApiService,
              private fxRateModalService: FxRateModalService, private dataService: DataServiceService) {
  }

  getRequiredFxRateFields(reverseSellValue: boolean): Array<string> {
    const requiredFields = ['P_MID', 'P_OFFICE', 'OX_STTLM_CCY', 'X_DBTR_ACCT_ID', 'P_DBT_ACCT_CCY'];
    if (reverseSellValue) {
      requiredFields.push('P_DBT_AMT');
    } else {
      requiredFields.push('OX_STTLM_AMT');
    }
    return requiredFields;
  }

  validateFxRateFields(fields: FxRequiredFields, dbtrAcctCcy: string): boolean {
    const invalid = Object.keys(fields).some((key) => {
      return !fields[key] || fields[key].invalid
        || (fields[key].hasOwnProperty('value') && !fields[key].value);
    });

    if (invalid) {
      this.handleNotification('message.panel.fx_rate_table.missing_mandatory_fields', true);
      return false;
    }

    if (this.isSameCurrency(fields, dbtrAcctCcy)) {
      this.handleNotification('message.panel.fx_rate_table.same_currency', true);
      return false;
    }
    return true;
  }

  isSameCurrency(fields: FxRequiredFields, dbtrAcctCcy: string): boolean {
    const stlmCcy = fields['OX_STTLM_CCY']?.value;
    return stlmCcy === dbtrAcctCcy;
  }

  getFxRateRequestParams(fields: GetRateFields): GetRateRequestBody {
    const paramValues = {
      mid: fields['P_MID']?.value,
      office: fields['P_OFFICE']?.value,
      stlmCcy: fields['OX_STTLM_CCY']?.value,
      stlmAmt: this.getValueForAmount(fields['OX_STTLM_AMT']?.value),
      dbtAcctNb: fields['X_DBTR_ACCT_ID']?.value,
      dbtAcctCcy: fields['P_DBT_ACCT_CCY']?.value,
      debitAmt: this.getValueForAmount(fields['P_DBT_AMT']?.value),
      reverseSell: fields['P_RVS_SELL']?.value,
      fxContractRef: null
    };
    this.getRateRequestBody = paramValues;
    return paramValues;
  }

  getFxRate(getFxRateBody: GetRateRequestBody, isReverseSell?: boolean, debitAmt?: string): Promise<FxRateRetry> {
    return new Promise((resolve, reject) => {
      this.fxRateApiService.getFxRate(getFxRateBody).subscribe((fxRate: GetRateResponse) => {
        const fxRateModalData = this.getDataForModal(fxRate.fxRateDetails, getFxRateBody, isReverseSell, debitAmt);
        this.openFxRateModal(fxRateModalData).then(
          (res: FxRate) => resolve({isRetry: false, fxRate: res}),
          () => reject()
        );
      }, (err) => {
        if(err.error && err.error.fxRateDetails &&
          ((err.error.errorCode === TransactionsLimits.HARD_LIMIT || err.error.errorCode === TransactionsLimits.DAILY_LIMIT))) {
          const fxRateModalData = this.getDataForModal(err.error.fxRateDetails, getFxRateBody, isReverseSell, debitAmt);
          this.openFxRateModal(fxRateModalData, {errorCode: err.error.errorCode, returnCode: err.error.returnCode, errDescription: err.error.errDescription}).then(
            (res: FxRate) => resolve({isRetry: false}),
            () => reject()
          )
        } else {
          this.openFxRatRetryModel().then(
            (isRetry: boolean) => resolve({isRetry}),
            () => reject()
          );
        }
      });
    });
  }

  getDataForModal(fxRate: FxRate, getFxRateBody: GetRateRequestBody, isReverseSell?: boolean, debitAmt?: string): FxRateModal {
    return {
      fxRateDetails: fxRate,
      mid: getFxRateBody.mid,
      office: getFxRateBody.office,
      isReverseSell,
      debitAmt
    } as FxRateModal;
  }

  openFxRateModal(fxRateModal: FxRateModal, errorDetails?: FxErrorResponse): Promise<FxRate> {
    this.setFxAmountsFormat(fxRateModal);
    return new Promise((resolve, reject) => {
      this.fxRateModalService.openModal({fxRate: fxRateModal, rateRequestBody: this.getRateRequestBody, errorDetails}).afterClosed().subscribe(
        (fxRate: FxRate | undefined) => {
          if (fxRate) {
            resolve(fxRate);
            reject();
          } else {
            this.rejectFxRate();
            reject();
          }
        });
    });
  }

  openFxRatRetryModel(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.fxRateModalService.openRetryModal().afterClosed().subscribe(
        (isRetry: boolean | undefined) => {
          if (isRetry) {
            resolve(true);
          } else {
            reject();
          }
        });
    });
  }

  rejectFxRate(): void {
    this.fxRateApiService.rejectRate(this.getRateRequestBody).subscribe(
      () => {},
      (error: FxErrorResponse) => {
        console.log('reject FX Rate error', error);
      }
    );
  }

  handleNotification(messageKey: string, isError: boolean) {
    const message = this.translateService.instant(messageKey);
    isError ? this.notificationService.error(message) : this.notificationService.success(message);
  }

  checkBalanceForFxRate(checkBalanceRequestBody: CheckBalanceRequestBody): Promise<CheckBalanceResponse> {
    return this.fxRateApiService.checkBalanceForFxRate(checkBalanceRequestBody).toPromise();
  }

  forceNsf(mid: string) {
    this.fxRateApiService.force(mid).subscribe();
  }

  private setFxAmountsFormat(fxRate: FxRateModal): void {
    fxRate.toAmountBeforeFormatting = fxRate.fxRateDetails.toAmount;
    const amountPrecision = this.dataService.getPrecisionByCurrency(fxRate.fxRateDetails.fromCurrency);
    const fixedAmount = this.formatsService.toNumberFormat(fxRate.fxRateDetails.fxAmount, amountPrecision);
    const toAmountPrecision = this.dataService.getPrecisionByCurrency(fxRate.fxRateDetails.toCurrency);
    const fixedToAmount = this.formatsService.toNumberFormat(fxRate.fxRateDetails.toAmount, toAmountPrecision);
    fxRate.fxRateDetails.fxAmount = fixedAmount;
    fxRate.fxRateDetails.toAmount = fixedToAmount;
  }

  private getValueForAmount(value: any): any {
    if (value) {
      return this.formatsService.toNumber(value);
    }
    return value;
  }
}
