import {PaymentDataModel} from "../models/payment-data.model";
import {IPaymentTypeStrategy} from "../interfaces/payment-type-strategy.interface";
import {BFPaymentTypes} from "../models/enums/bf-payment-types.enum";

export class DomesticPaymentStrategy implements IPaymentTypeStrategy {
  isMatch(data: any): boolean {
    return data[PaymentDataModel.MESSAGE_TYPE] === 'FED_10'
      && data[PaymentDataModel.MESSAGE_SUB_TYPE] === '00'
      && data[PaymentDataModel.DIRECTION] === 'O'
      && data[PaymentDataModel.LOCAL_INSTR_CODE] === 'CTR'
      && (data[PaymentDataModel.STATUS] === 'REPAIR' || data[PaymentDataModel.STATUS] === 'RELEASE' || data[PaymentDataModel.STATUS] === 'VERIFY');
  }

  paymentType(): BFPaymentTypes {
    return BFPaymentTypes.PACS_008_US_DOMESTIC;
  }
}
