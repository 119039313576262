<!-- ruleAssociationFooter.html -->
<span class="preview-button-spacer"></span>
<div class="dh-dropdown-button dropup">
  <button mat-button color="primary" azure-id="bottom-menu-btn" automation-id="bottom-menu-btn" class="dh-hoverable"
          [disabled]="ruleAssociationCommonService.moreDisable" [matMenuTriggerFor]="menu">
    <app-fn-ui-icon icon="menu"></app-fn-ui-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="deleteRule()" name="rules.footer.delete"
            *ngIf="showDeleteButton">{{'rules.footer.delete' | translate}}
    </button>
    <button mat-menu-item (click)="holdRule()" name="rules.footer.hold"
            *ngIf="showHoldButton">{{'rules.footer.hold' | translate}}
    </button>
    <button mat-menu-item (click)="toggleAuditTrail()" name="entity.audit_trail"
            *ngIf="showAuditTrailButton">{{'system_navigation.entity.audit_trail' | translate}}
    </button>
  </mat-menu>
</div>
<div class="rule-association-footer-right-side">

  <button mat-button color="primary" automation-id="close-rule" azure-id="close-rule" type="button"
          (click)="closeRuleAssociation()"
          *ngIf="showMainCloseButton">
    <span class="dh-text-label">{{'general.close' | translate}}</span>
  </button>

  <button mat-button color="primary" automation-id="decline-rule" azure-id="decline-rule" type="button"
          (click)="declineRule()"
          *ngIf="showDeclineButton">
    <span class="dh-text-label">{{'ruleAssociation.footer.decline_rule' | translate}}</span>
  </button>

  <button mat-raised-button color="primary" automation-id="save-rule" azure-id="save-rule" type="button"
          [disabled]="ruleAssociationCommonService.saveDisable"
          (click)="saveRecord()"
          *ngIf="showSaveButton">
    <span class="dh-text-label">{{'general.save' | translate}}</span>
  </button>

  <button mat-raised-button="primary" automation-id="approve-rule" azure-id="approve-rule" type="button"
          (click)="approveRule()"
          *ngIf="showApproveButton">
    <span class="dh-text-label">{{'rules.footer.approve_rule' | translate}}</span>
  </button>

  <button mat-raised-button color="primary" automation-id="revert-rule" azure-id="revert-rule" type="button"
          (click)="declineRule()"
          *ngIf="showRevertButton">
    <span class="dh-text-label">{{'rules.footer.revert_rule' | translate}}</span>
  </button>
  <button mat-raised-button color="primary" automation-id="activate-rule" azure-id="activate-rule" type="button"
          (click)="activateRule()"
          *ngIf="showActivateButton">
    <span class="dh-text-label">{{'rules.footer.activate' | translate}}</span>
  </button>
</div>
