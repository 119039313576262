import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AzureInsightsService } from '../../../azure-insight/services/azure-insights.service';
import { FeatureFlagsService } from '../../../core/feature-flags/services/feature-flags.service';
import { AuthenticationService } from '../../../authentication/services/authentication.service';
import { SignOutService } from '../../../authentication/services/sign-out.service';
import { PopOutService } from '../../../services/pop-out.service';
import { MultiTabsManagerService } from '../../../core/multi-tabs/services/multi-tabs-manager.service';
import { HomeService } from '../../../core/services/home.service';
import { IdleService } from '../../../core/idle/services/idle.service';
import { AlertService } from '../../../core/alerts/services/alert.service';
import { Subscription } from 'rxjs';
import { MatDialogConfig } from 'ng-dialog-animation';
import { FnUiDialogService } from '../../../shared/fn-ui-dialog/services/fn-ui-dialog.service';
import { OpenModal } from '../../../core/state/models/open-modal-state.model';
import { SetOpenModal } from '../../../core/state/actions/open-modal.actions';
import { Actions, ofActionSuccessful } from '@ngxs/store';

@Component({
  selector: 'app-base-view',
  templateUrl: './base-view.component.html',
  styleUrls: ['./base-view.component.scss']
})
export class BaseViewComponent implements OnInit, OnDestroy {

  private subscriber: Subscription;
  started = false;
  isStandAlone = false;

  constructor(private route: ActivatedRoute, private router: Router, private featureFlagsService: FeatureFlagsService,
    private authenticationService: AuthenticationService, private signOutService: SignOutService,
    private popOutService: PopOutService, private multiTabsManagerService: MultiTabsManagerService,
    private homeService: HomeService, private idleService: IdleService, private alertService: AlertService,
    private azureInsightsService: AzureInsightsService,
    private dialogService: FnUiDialogService,
    private actions$: Actions) {
    this.homeService.showWelcomeNotification();
    this.idleService.start();
    this.alertService.start();
    this.azureInsightsService.init();
  }


  ngOnInit(): void {
    this.subscriber = this.route.params.subscribe((data => {
      this.isStandAlone = data.isStandAlone === 'true';
    }));
    this.isStandAlone = this.route.snapshot.queryParams?.isStandAlone === 'true';
    this.multiTabsManagerService.addTab();
    window.onbeforeunload = this.onBeforeUnload.bind(this);
    window.onunload = this.onUnload.bind(this);

    this.setActiveTab();

    this.actions$.pipe(ofActionSuccessful(SetOpenModal)).subscribe((data) => this.openModal(data.payload));
  }

  onBeforeUnload(e) {
    if (this.featureFlagsService.isFeatureOn('beforeUnloadAlert') && this.authenticationService.userData && !this.popOutService.isPopOut()) {
      e.returnValue = 'onbeforeunload';
      return 'onbeforeunload';
    }
  }

  onUnload() {
    if (this.multiTabsManagerService.isSingleTab) {
      this.signOutService.forceSignOut();
    } else {
      this.multiTabsManagerService.removeTab();
    }
  }

  openModal(data: OpenModal) {
    const config: MatDialogConfig = {
      width: '500px',
      data: {
        ...data.config,
        message: '',
        noteCharsLimit: 250
      }
    };
    this.dialogService.open(data.component, config).afterClosed().subscribe((response) => {
      data.promise.resolve(response);
    });
  }

  private setActiveTab(): void {
    if (this.router.url === '/home') {
      const activeTab = this.route.snapshot.data.activeTab;
      this.router.navigate([activeTab.routerLink]);
    }
  }

  ngOnDestroy(): void {
    this.subscriber.unsubscribe();
  }
}
