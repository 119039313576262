import { CdkDrag, CdkDropList, CdkDragMove, CdkDragRelease, CdkDragDrop } from '@angular/cdk/drag-drop';
import { Injectable } from '@angular/core';
import { DragDropService } from '../../../shared/services/drag-drop.service';
import { ComplexCondition, SimpleCondition } from '../models';
import { RuleConditionsService } from './rule-conditions.service';

@Injectable()
export class ComplexConditionService {

  constructor(private dragDropService: DragDropService,
              private ruleConditionsService: RuleConditionsService) { }

  isDropAllowed(drag: CdkDrag, drop: CdkDropList): boolean {
    if (this.dragDropService.currentHoverDropListId == null) {
      return true;
    }
    return drop.id === this.dragDropService.currentHoverDropListId;
  }

  onDragMove($event: CdkDragMove<ComplexCondition>): void {
    this.dragDropService.onDragMove($event);
  }

  onDragRelease($event: CdkDragRelease): void {
    this.dragDropService.onDragRelease($event);
  }

  onDrop($event: CdkDragDrop<ComplexCondition>): void {
    if ($event.previousContainer === $event.container) {
      this.dragDropService.onDrop($event, (data) => data.conditions);
    } else {
      const sourceComplexCondition = $event.previousContainer.data;
      const targetComplexCondition = $event.container.data;

      const sourceContainerOldLength = sourceComplexCondition.conditions.length;
      const targetContainerOldLength = targetComplexCondition.conditions.length;

      this.dragDropService.onDrop($event, (data) => data.conditions);

      const sourceContainerNewLength = sourceComplexCondition.conditions.length;
      const targetContainerNewLength = targetComplexCondition.conditions.length;

      this.onConditionsChange(sourceContainerNewLength, sourceContainerOldLength, sourceComplexCondition);
      this.onConditionsChange(targetContainerNewLength, targetContainerOldLength, targetComplexCondition);
    }
  }

  onConditionsChange(newLength: number, oldLength: number, complexCondition?: ComplexCondition): void {
    if (newLength === 0 && oldLength === 0) {
      return;
    }

    if ((newLength === oldLength) && newLength !== 1) { //newVal != 1 is for rules with 1 condition at start //TODO can be removed?
      return;
    }

    if (newLength === 0) {
      this.handleNoConditions(complexCondition);
    } else if (newLength === 1) {
      //if we have one condition only don't show the operator and brackets
      this.findAndMoveSingleCondition(this.ruleConditionsService.conditions.conditions);
      this.handleNoConditions(complexCondition);
    } else if (newLength === 2 && oldLength === 1) {
      //when we create group by having 2 conditions
      complexCondition.logicalOperator = 'AND';
    }
  }

  private findAndMoveSingleCondition(conditions: Array<SimpleCondition | ComplexCondition>): void {
    for (let i = 0; i < conditions.length; i++) {
      if ((conditions[i] as ComplexCondition).conditions) {
        const complexCondition = conditions[i] as ComplexCondition;
        if (complexCondition.conditions.length === 1) {
          conditions[i] = complexCondition.conditions[0];
        } else {
          this.findAndMoveSingleCondition(complexCondition.conditions);
        }
      }
    }
  }

  private handleNoConditions(complexCondition: ComplexCondition): void {
    const isRoot = this.ruleConditionsService.conditions === complexCondition;
    if (isRoot) {
      complexCondition.logicalOperator = undefined;
    }
  }

}
