import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, ElementRef, AfterViewInit } from '@angular/core';
import { ButtonType } from './models/enums/button-type.enum';
import { ButtonColor } from './models/enums/button-color.enum';
import { ButtonTypeClass } from './models/consts/button-type-class.const';
import { ButtonColorClass } from './models/consts/button-color-class.const';
import { FnUiButton } from './models/fn-ui-button.model';

@Component({
  selector: 'app-fn-ui-button',
  templateUrl: './fn-ui-button.component.html',
  styleUrls: ['./fn-ui-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FnUiButtonComponent implements FnUiButton, AfterViewInit {

  @Input() name = '';
  @Input() type = ButtonType.BASIC;
  @Input() color = ButtonColor.NONE;
  @Input() disabled = false;
  @Input() disableRipple = false;

  @Output() buttonClick: EventEmitter<void> = new EventEmitter();

  constructor(protected elem: ElementRef) {}
  readonly ButtonTypeClass = ButtonTypeClass;
  readonly ButtonColorClass = ButtonColorClass;

  onClick() {
    this.buttonClick.emit();
  }

  ngAfterViewInit(): void {
    this.elem.nativeElement.setAttribute('azure-id', this.name);
  }
}
