import {Injectable, Injector} from '@angular/core';
import {FormService, NotificationService} from '@fgpp-ui/components';
import {TranslateService} from '@ngx-translate/core';
import {TransferMethods} from '../../../models/transfer-methods.model';

@Injectable()
export class TransferMethodsService {
  private formService: FormService;

  constructor(private notificationService: NotificationService, private translate: TranslateService, protected injector: Injector) {
    this.formService = injector.get(FormService);
  }

  validateRequiredFields(): boolean {
    const formMgr = this.formService.getFormManager();
    const countryField = formMgr.get(TransferMethods.COUNTRY).getValue();
    const currencyField = formMgr.get(TransferMethods.CURRENCY).getValue();

    if (countryField || currencyField) {
      return true;
    }

    this.notificationService.error(this.translate.instant('business-framework.profiles.82.errors.mandatoryFields'));
    return false;
  }
}
