import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TreeConfig } from '@fgpp-ui/components';
import { Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { CallerCallbackService } from '../services/caller-callback.service';
import { CallerCallbackApiService } from '../services/caller-callback-api.service';
import { CallerMessagesManagementService } from '../../../../messages/services/caller-messages-management.service';
import { MessagesCenterQueue } from '../../../../core/models/messages-center-queue.model';
import { MessagesCenterQueueState } from '../../../../core/state/messages-center-queue.state';
import { SetCallerAuthentication } from '../../../../core/state/actions/caller-authentication.actions';
import { CallerAuthentication } from '../../../../core/state/models/caller-authentication-state.model';
import { StoreService } from '../../../../core/services/store.service';
import { MessagesCenterNavigationService } from '../../../messages-center-navigation/services/messages-center-navigation.service';
import { MessagesCenterNavigationTreeComponent } from '../../../messages-center-navigation/components/messages-center-navigation-tree/messages-center-navigation-tree.component';
import { MessagesQueue } from '../../../../messages/models/messages-queue.model';


@Component({
  selector: 'app-messages-center-callback-queues',
  templateUrl: './messages-center-callback-queues.component.html'
})
export class MessagesCenterCallbackQueuesComponent implements OnInit, OnDestroy {

  @Select(MessagesCenterQueueState.getCallbackQueue) queue$: Observable<MessagesCenterQueue>;

  @ViewChild(MessagesCenterNavigationTreeComponent, { static: true }) messagesCenterNavigationTreeComponent: MessagesCenterNavigationTreeComponent;

  treeConfig: Partial<TreeConfig> = {
    getUId: (node) => node.uid || node.id,
    trackBy: (node) => node
  };
  selectedNode: MessagesQueue;

  private _subscription = new Subscription();

  constructor(public messagesCenterNavigationService: MessagesCenterNavigationService,
              private router: Router,
              private storeService: StoreService,
              private callerCallbackService: CallerCallbackService,
              private callerCallbackApiService: CallerCallbackApiService,
              private callerMessagesManagementService: CallerMessagesManagementService) {
    this.messagesCenterNavigationService.registerTreeUpdateFunction(this.updateTree);
  }

  ngOnInit(): void {
    this._subscription.add(this.queue$.subscribe((queue: MessagesCenterQueue) => {
      this.selectTreeItem(queue);
    }));
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
    this.onExit();
  }

  selectTreeItem(queue: MessagesCenterQueue) {

    // if(queue && queue.uid && queue.uid.split('^')[1] !== this.callerMessagesManagementService.currentTreeItem?.uid.split('^')[1] ){
      this.storeService.dispatch(new SetCallerAuthentication({ mode: false } as CallerAuthentication));
    // }

    if (queue && (queue.uid && queue.uid !== this.callerMessagesManagementService.currentTreeItem?.uid ||
      !queue.uid && queue.id && queue.id !== this.callerMessagesManagementService.currentTreeItem?.id)) {
      queue.id = queue.uid ? queue.uid : queue.id;
      const path = this.findCallerPath(this.messagesCenterNavigationService.callbackQueuesTree, queue.id, []);
      if (!path) {
        return;
      }
      const node = this.callerMessagesManagementService.currentTreeItem = path.length >= 3 ? path[2] : path[1];
      const parentPath = this.callerMessagesManagementService.currentTreeItem = path && path.length >= 3 ? path[1] : path[0];
      this.selectedNode = node;
      setTimeout(() => {
        this.getAuthentication(node, parentPath);
      }, 0);
    }
  }

  getAuthentication(callerQueue: any, itemParent: any): void {
    if (this.callerMessagesManagementService.isCallerAuthenticated) {
      this.getCallerMessages(callerQueue, itemParent);
    } else {
      const payload = {
        custId: itemParent.id,
        custName: itemParent.alias,
        callerId: callerQueue.id,
        phone1: callerQueue.phone1,
        phone2: callerQueue.phone2,
        authType: callerQueue.authType,
        superCaller: callerQueue.superCaller,
        companyDisabled: true,
      };
      this.callerCallbackService.openCallerAuthenticationModal(payload).then(res => {
        if (!res || (res.responseMsg !== 'success' && res.responseMsg !== 'valid result')) {
          this.selectedNode = null;
          this.callerMessagesManagementService.currentTreeItem = null;
          this.router.navigate(['/home/messages-center/callback']);
          return;
        }
        this.getCallerMessages(callerQueue, itemParent);
      });
    }
  }

  getCallerMessages(callerQueue: any, itemParent: any) {
    this.callerCallbackApiService.getMidsForCaller(callerQueue.id, itemParent.id, callerQueue.superCaller).then(midsForCaller => {
      this.callerMessagesManagementService.currentTreeItem = callerQueue;
      this.callerMessagesManagementService.setFilter(midsForCaller);
      this.callerMessagesManagementService.onTreeItemSelect(callerQueue);
      this.storeService.dispatch(new SetCallerAuthentication({ mode: true } as CallerAuthentication));
    });
  }

  /**
   * This function will be passed to a service which it will execute on tree refresh
   * Arrow function is a must in order to keep 'this'
   */
  updateTree = () => {
    return this.messagesCenterNavigationService.updateCallbackQueuesTree().then((tree) => {
      this.updateSelectedNode();
      return tree;
    });
  };

  private findCallerPath(tree: any, id: string, path: Array<any>): Array<any> {
    if (tree?.uid === id) {
      return [tree];
    }
    if (tree?.nodes) {
      for (let i = 0; i < tree.nodes.length; i++) {
        const result = this.findCallerPath(tree.nodes[i], id, path);
        if (result != null) {
          path.unshift(tree.nodes[i]);
          return path;
        }
      }
    }
    return null;
  }

  private updateSelectedNode(): void {
    if (this.selectedNode) {
      const path = this.findCallerPath(this.messagesCenterNavigationService.callbackQueuesTree, this.selectedNode.uid || this.selectedNode.id, []);
      if (path) {
        this.selectedNode = this.callerMessagesManagementService.currentTreeItem = path.length >= 3 ? path[2] : path[1];
      }
    }
  }

  private onExit(): void {
    this.callerMessagesManagementService.currentTreeItem = null;
    this.storeService.dispatch(new SetCallerAuthentication({ mode: false } as CallerAuthentication));
  }

}
