import {
  Component,
  Input,
  OnInit,
  ComponentFactoryResolver,
  Injector,
  ApplicationRef,
  ChangeDetectorRef
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormatsService } from '@fgpp-ui/components';
import { AuditTrailRulesConfig } from './models/consts/audit-trail-rules-grid-config.const';
import { RulesGridComponent } from '../rules-grid/rules-grid.component';
import { TranslateService } from '@ngx-translate/core';
import { ColumnSettingsService } from '../../../grid/services/column-settings.service';
import { ExportService } from '../../../shared/fn-ui-export/services/export.service';
import { AuditTrailsRulesProfilesManagementService } from '../../services/audit-trails-rules-profiles-management.service';
import { FilterMapperService } from '../../../grid/services/filter-mapper.service';
import { ActionFilterService } from '@fgpp-ui/grid';
import { ActionFilterMapperService } from '@fgpp-ui/grid';
import { GroupActionsService } from '../../../messages-center/services/group-actions.service';
import { NotificationService } from '@fgpp-ui/components';
import { TableRow } from '@fgpp-ui/grid';
import { GridEventsService } from '../../../azure-insight/services/grid-events.service';
import { UserSettingsService } from '../../../core/user-settings/services/user-settings.service';

@Component({
  selector: 'app-audit-trail-rules-grid',
  templateUrl: './audit-trail-rules-grid.component.html',
  styleUrls: ['./audit-trail-rules-grid.component.scss']
})
export class AuditTrailRulesGridComponent extends RulesGridComponent implements OnInit {

  @Input() ruleProfileId;

  constructor(route: ActivatedRoute,
              router: Router,
              userSettingsService: UserSettingsService,
              gridEventsService: GridEventsService,
              public auditTrailsRulesProfilesManagementService: AuditTrailsRulesProfilesManagementService,
              groupActionsService: GroupActionsService,
              notificationService: NotificationService,
              $translate: TranslateService,
              columnSettingsService: ColumnSettingsService,
              exportService: ExportService,
              filterMapperService: FilterMapperService,
              actionFilterMapperService: ActionFilterMapperService,
              actionFilterService: ActionFilterService,
              resolver: ComponentFactoryResolver,
              injector: Injector, app: ApplicationRef,cdr: ChangeDetectorRef, protected formatsService: FormatsService) {
    super(route, router, userSettingsService, gridEventsService, auditTrailsRulesProfilesManagementService,
      groupActionsService, notificationService, $translate, columnSettingsService,
      exportService, filterMapperService, actionFilterMapperService, actionFilterService, resolver, injector, app, cdr, formatsService);
  }

  ngOnInit() {
    this.setContainerConfig();
    super.ngOnInit();
  }

  setContainerConfig() {
    this.gridContainerConfig = AuditTrailRulesConfig;
  }

  protected initializeProfileGrid(): void {
    super.initializeProfileGrid();
    this.setContainerConfig();
  }

  onRowClicked(row: TableRow): void {
    this.rowClicked.emit(row);
  }

}
