<div *ngIf="auditTrail" class="audit-trail-details">
  <div class="ft-top-grid-action">
    <button
      type="button"
      mat-button
      color="primary"
      class="back-to-audit"
      (click)="onBackClick()"
    >
      {{
        "system_navigation.entity.audit_trail.audit_trail_changes.back_to_audit_trail"
          | translate
      }}
    </button>

    <span class="top-grid-action-section-title" [innerHtml]="'rules.auditTrail.madeBy'
    | translate
      : { username: auditTrail.username, timestamp: auditTrail.timestamp | ftDateTime }"></span>
  </div>

  <div class="audit-trail-rules">
    <table class="table table-bordered table-striped">
      <tbody>
        <tr>
          <th>
            {{ "rules.auditTrail.fieldName" | translate }}
          </th>
          <th>
            {{ "rules.auditTrail.oldValue" | translate }}
          </th>
          <th>
            {{ "rules.auditTrail.newValue" | translate }}
          </th>
        </tr>
        <tr *ngFor="let change of auditTrail.changes">
          <td>{{ change.fieldName }}</td>
          <td>{{ change.oldValue }}</td>
          <td>{{ change.newValue }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
