<mat-form-field [floatLabel]="floatLabel" [appearance]="appearance">

  <mat-label *ngIf="label" class="dh-form-component-header" [ngClass]="{ 'required_input-label': required }">{{ label }}</mat-label>

  <mat-select [(ngModel)]="ngModel"
              [multiple]="multiple"
              [disabled]="disabled"
              [required]="required"
              [panelClass]="panelClass"
              [hideSingleSelectionIndicator]="true"
              (selectionChange)="onSelectionChange($event)">

    <mat-option *ngFor="let option of options" [value]="getValue(option)" [ngClass]="optionClass">
      <ng-container [ngTemplateOutlet]="optionTemplate"
                    [ngTemplateOutletContext]="{ option }">
      </ng-container>
    </mat-option>

  </mat-select>
</mat-form-field>

<ng-template #defaultOptionTemplate let-option="option">
  <span class="fn-ui-option">{{ getAlias(option) }}</span>
</ng-template>
