import { Component, OnInit } from '@angular/core';
import { AzureInsightsService } from '../../../azure-insight/services/azure-insights.service';
import { AlertService } from '../../../core/alerts/services/alert.service';
import { IdleService } from '../../../core/idle/services/idle.service';
import { SignOutService } from '../../services/sign-out.service';

@Component({
  selector: 'app-sign-out',
  templateUrl: './sign-out.component.html',
  styleUrls: ['./sign-out.component.scss']
})
export class SignOutComponent implements OnInit {

  constructor(private signOutService: SignOutService, private idleService: IdleService,
              private alertService: AlertService, private azureInsightsService: AzureInsightsService) { }

  // todo: fix azureInsightsService circular dependency
  ngOnInit(): void {
    this.idleService.stop()
    this.alertService.stop();
    this.azureInsightsService.clearUserId();
    this.signOutService.signOut();
  }

}
