<h1 mat-dialog-title>
  {{title}}
  <button automation-id="close-dialog-icon" azure-id="close-dialog-icon" mat-icon-button mat-dialog-close class="close-dialog-x-button">
    <app-fn-ui-icon icon="close"></app-fn-ui-icon>
  </button>
</h1>

<div mat-dialog-content>
  <ng-container *ngTemplateOutlet="body"></ng-container>
</div>

<div mat-dialog-actions align="end">
  <ng-container *ngTemplateOutlet="footer"></ng-container>
</div>
