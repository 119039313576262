import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CallerCallbackApiService } from '../services/caller-callback-api.service';
import { AutofocusDirective } from '../../../../core/directives/autofocus.directive';

@Component({
  styleUrls: ['./caller-pin-verification-modal.component.scss'],
  templateUrl: './caller-pin-verification-modal.component.html'
})
export class CallerPinVerificationModalComponent implements OnInit {
  form;
  callerId;
  custCode;
  validationInProgress = false;

  constructor(private dialogRef: MatDialogRef<CallerPinVerificationModalComponent>,
              @Inject(MAT_DIALOG_DATA) private params: any, private callerCallbackApiService: CallerCallbackApiService) {
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      pin: new FormControl({ value: '', disabled: false }, [Validators.required])
    });
    this.callerId = this.params.callerId;
    this.custCode = this.params.custCode;
  }



  /**
   * Validates the caller credentials
   */
  authenticatePin() {
    const callerCredentials = {
      callerId: this.callerId,
      custCode: this.custCode,
      callerPin: this.form.get('pin').value
    };

    this.validationInProgress = true;
    this.callerCallbackApiService.authenticatePin(callerCredentials).subscribe(
      (res: any) => {
          //res.callerId = this.form.get('callerId').value;
        this.dialogRef.close(res);
      },
      (res) => {
        this.form.get('pin').setErrors({ 'pinInvalid': true });
        this.form.get('pin').markAsTouched(); //updates view on the first time in order to show error on Enter click
      }).add(() => {
      this.validationInProgress = false;
    });
  }
}
