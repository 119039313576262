<div class="audit-trail-rules-grid" [appSpinner]="isFetchingData">
  <app-grid-header *ngIf="gridContainerConfig.gridHeaderConfig"
                    [gridHeaderConfig]="gridContainerConfig.gridHeaderConfig"
                    [queueTitle]="queueTitle"
                    [entity]="entity"
                    (print)="onPrintClick()">
  </app-grid-header>

  <lib-grid [config]="gridConfig"
            [columns]="gridColumns"
            [data]="gridData"
            [leftFooterTemplate]="footerTemplateRef"
            [keyId]="keyId"
            (pageChanged)="onPageChanged($event)"
            (rowClicked)="onRowClicked($event)"
            (sortChanged)="onSortChanged($event)"
            (pinChanged)="onColumnPinChange($event)"
            (operatorChanged)="onOperatorChanged($event)">
  </lib-grid>
</div>

<ng-template #footerTemplateRef>
  <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
  <app-grid-footer *ngIf="gridContainerConfig.gridFooterConfig"
                   [gridFooterConfig]="gridContainerConfig.gridFooterConfig"
                   [totalCount]="gridData.totalCount"
                   [selectedRows]="selectedRows">
  </app-grid-footer>
</ng-template>
