<app-fa-dialog [title]="'modals.two_factor_auth.title' | translate" [body]="bodyTemplate" [footer]="footerTemplate" >

  <ng-template #bodyTemplate>
    <form [formGroup]="form" (keyup.enter)="authenticatePin()" >



	      <mat-form-field [floatLabel]="'always'" >
            <input matInput autofocus
                   formControlName="pin"
                   [id]="'callback-caller-authentication-modal-pin'"
                   [placeholder]="'caller.authentication_modal.pin' | translate">
          <mat-error *ngIf="form.controls.pin.errors?.pinInvalid">{{'caller_profile.pin_failed' | translate}}</mat-error>
	        </mat-form-field>

    </form>

  </ng-template>

  <ng-template #footerTemplate>
       <button mat-button color="primary" mat-dialog-close>{{'general.cancel' | translate}}</button>
      <button mat-flat-button color="primary" type="submit" id="callback-caller-authentication-modal-auth" [disabled]="validationInProgress || !form.valid" (click)="authenticatePin()">
        {{'general.auth' | translate}}
      </button>

  </ng-template>


</app-fa-dialog>
