import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appRestrictSpecialCharacters]'
})
export class RestrictSpecialCharactersDirective {

  private readonly regex = /^[^`~!@#$%\^&*\/()+={}|[\]\\:';"<>?,.]*$/;

  @HostListener('keypress', ['$event'])
  onKeyPress(event): boolean {
    return new RegExp(this.regex).test(event.key);
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent): boolean {
    return new RegExp(this.regex).test(event.clipboardData.getData('Text'));
  }

}
