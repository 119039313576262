<div class="conditions-bank-tree" [ngClass]="{'rule-disabled': ruleDisabled }">

  <gpp-ui-tree [treeConfig]="treeConfig"
               [treeModel]="treeModel"
               [selectedNode]="selectedNode"
               [searchTerm]="searchTerm"
               (selectionChanged)="onSelectionChange($event)">

    <ng-template #node let-node="node">
      <button class="node-button group-node" [attr.azure-id]="node.id">
        <span class="node-alias">{{ node.treeAlias || node.alias }}</span>
      </button>
    </ng-template>

    <ng-template #leaf let-node="node">
      <ng-container [ngTemplateOutlet]="node.locked ? lockedNodeTemplate : node.available ? availableNodeTemplate : unavailableNodeTemplate"></ng-container>

      <ng-template #lockedNodeTemplate>
        <button class="node-button locked-node" [attr.azure-id]="node.id">
          <span class="node-alias">{{ node.treeAlias || node.alias }}</span>
          <app-restricted-logical-field-popover [logicalField]="node"></app-restricted-logical-field-popover>
        </button>
      </ng-template>

      <ng-template #availableNodeTemplate>
        <button class="node-button available-node" [attr.azure-id]="node.id">
          <span class="node-alias" draggable="true" (dragstart)="onDragStart($event, node)">{{ node.treeAlias || node.alias }}</span>
        </button>
      </ng-template>

      <ng-template #unavailableNodeTemplate>
        <button class="node-button unavailable-node" disabled="true" [attr.azure-id]="node.id">
          <span class="node-alias">{{ node.treeAlias || node.alias }}</span>
        </button>
      </ng-template>

    </ng-template>
  </gpp-ui-tree>
</div>
