export const InnerGridApi = {
  files: {
    api: ['bulks', 'batches', 'messages', 'linkedFiles', 'internalBatches'],
},
  bulks: {
    api: ['batches', 'messages', 'linkedFiles', 'internalBatches']
  },
  batches: {
    api: ['messages', 'linkedFiles']
  }
};
