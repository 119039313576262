<button mat-button
        class="fn-ui-popover-trigger {{popoverTriggerClass}}"
        name="popover-trigger"
        [color]="popoverTriggerColor"
        [mdePopoverTriggerFor]="fnUiPopover"
        [mdePopoverTargetAt]="popoverTargetAt"
        [mdePopoverBackdropCloseOnClick]="popoverBackdropCloseOnClick"
        [ngClass]="{ 'trigger-off': popoverTriggerOn === 'none' }"
        (opened)="onPopoverOpen()"
        (closed)="onPopoverClose()">
  <ng-container [ngTemplateOutlet]="popoverTriggerTemplate"></ng-container>
</button>

<mde-popover #fnUiPopover="mdePopover"
             [mdePopoverPositionX]="popoverPositionX"
             [mdePopoverPositionY]="popoverPositionY"
             [mdePopoverTriggerOn]="popoverTriggerOn"
             [mdePopoverEnterDelay]="popoverEnterDelay"
             [mdePopoverLeaveDelay]="popoverLeaveDelay"
             [mdePopoverOverlapTrigger]="popoverOverlapTrigger"
             [mdePopoverOffsetX]="popoverOffsetX"
             [mdePopoverOffsetY]="popoverOffsetY"
             [mdePopoverArrowOffsetX]="popoverArrowOffsetX"
             [mdePopoverArrowWidth]="popoverArrowWidth"
             [mdePopoverArrowColor]="popoverArrowColor"
             [mdePopoverCloseOnClick]="popoverCloseOnClick"
             [mdeFocusTrapEnabled]="popoverFocusTrapEnabled"
             [mdeFocusTrapAutoCaptureEnabled]="popoverFocusTrapAutoCaptureEnabled"
             [class]="popoverClass"
             (close)="onPopoverClose()">
  <mat-card appearance="outlined" class="popover-card">
    <mat-card-content>
      <ng-container [ngTemplateOutlet]="popoverTemplate"></ng-container>
    </mat-card-content>
  </mat-card>
</mde-popover>


<ng-template #defaultPopoverContent>
  <div class="fn-ui-popover-content">
    <div class="fn-ui-popover-header">
      <ng-container [ngTemplateOutlet]="popoverHeaderTemplate"></ng-container>
    </div>
    <mat-divider></mat-divider>
    <div class="fn-ui-popover-body">
      <ng-container [ngTemplateOutlet]="popoverBodyTemplate"></ng-container>
    </div>
    <div class="fn-ui-popover-footer">
      <ng-container [ngTemplateOutlet]="popoverFooterTemplate"></ng-container>
    </div>
  </div>
</ng-template>
