import { Operators } from '../enums/operators.enum';

export class SearchCondition {
  class = 'SearchCondition';
  not = false;
  leftOperand: string;
  operator: Operators;
  rightOperand: Array<string>;

  constructor(not: boolean, leftOperand: string, operator: Operators, rightOperand: Array<string>) {
    this.not = not;
    this.leftOperand = leftOperand;
    this.operator = operator;
    this.rightOperand = rightOperand;
  }
}
