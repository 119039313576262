import { Injectable } from '@angular/core';
import { AuthenticationService } from '../../authentication/services/authentication.service';
import { PopupService } from '../../core/services/popup.service';

@Injectable({
  providedIn: 'root'
})
export class AdvancedSearchService {

  private _fieldsWithNumberType: string[] = [];
  private profileId = '421';
  private emptyResponseTemplate = '<pruleConditions xmlns=\"http://Fundtech.com/prule-metadata-dto.xsd\"></pruleConditions>';
  private defaultCreateFields = 'SEARCH_SCREEN.V_QUEUE_NAME@TRN_SRCH;SEARCH_SCREEN.V_QUEUE_MESSAGE_TYPE@H;V_SEARCH_TERM@';
  private popupParams = undefined;


  constructor(private popupService: PopupService,
              private authenticationService: AuthenticationService) {
    this.setPopupParams();
  }

  setPopupParams() {
    this.popupParams = {
      'VIRTUAL.CREATE_FIELDS': this.defaultCreateFields + 'NULL',
      'PK': -1,
      '@FORM_NAME@': 'frmLoadProfile',
      '@COMMAND_EVENT@': 'eventLoadForCreate',
      'VIRTUAL.STATIC_DATA_ID': this.profileId,
      'VIRTUAL.LOCK_MODE': 0,
      'VIRTUAL.UID': '-1',
      'FROM_PROFILE_ID': {
        '0': {
          'VIRTUAL.UID': 'TRN_SRCH'
        },
        '1': this.profileId
      }
    };
  }

  get fieldsWithNumberType() {
    return this._fieldsWithNumberType;
  }

  search(): Promise<any> {

    this.popupParams['@SESSION_ID@'] = this.authenticationService.sessionId;

    return new Promise((resolve, reject) => {
      this.popupService.open(this.popupParams, this.profileId).then(popupResult => {
        if (popupResult) {
          if (popupResult.filterXmlString != null &&
            popupResult.filterXmlString !== '' &&
            popupResult.filterXmlString !== this.emptyResponseTemplate) {
            this.popupParams['VIRTUAL.CREATE_FIELDS'] = this.defaultCreateFields + popupResult.filterXmlString;
            this.addToCacheFieldsWithNumberType(popupResult.fieldsWithNumberType);
          } else {
            this.popupParams['VIRTUAL.CREATE_FIELDS'] = this.defaultCreateFields + 'NULL';
          }
         resolve(popupResult);
        } else {
          reject();
        }
      });
    });
  }


  private addToCacheFieldsWithNumberType(fields: string[]): void {
    fields?.forEach((field: string) => {
      if (this._fieldsWithNumberType.indexOf(field) === -1) {
        this._fieldsWithNumberType.push(field);
      }
    });
  }
}
