import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { SitemapNode, SitemapTreeItem } from '../../sitemap/models';
import { ITabHandlerService } from './tab-handler-service.interface';

@Injectable()
export class TabHandlerService implements ITabHandlerService {

  get moduleNames(): Array<string> {
    return ['inner_navigation'];
  }

  getKey(node: SitemapNode, moduleName?: string) {
    return node.id;
  }

  shouldMapped(node: SitemapNode): boolean {
    const isLeaf = !node.nodes?.length;
    return isLeaf;
  }

  getAlias(node: SitemapNode): string {
    return node.alias;
  }

  getIcon(node: SitemapNode, parents: Array<SitemapTreeItem>): string {
    return parents.concat(node).reverse().find((item: SitemapTreeItem) => item.icon).icon;
  }

  getPath(parents: Array<SitemapTreeItem>): Array<string> {
    return parents.map((parent: SitemapTreeItem) => parent.alias);
  }

  getRouterLink(node: SitemapNode, parents: Array<SitemapTreeItem>): string {
    return parents.concat(node).map((parent: SitemapTreeItem) => parent.id).join('/');
  }

  getStateName(node: SitemapNode, parents: Array<SitemapTreeItem>): string {
    return 'home.' + parents.concat(node).map((parent: SitemapTreeItem) => parent.id).join('.');
  }

  getStateParams(node: SitemapNode, parents: Array<SitemapTreeItem>): Params {
    return {};
  }

}
