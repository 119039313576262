import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-validation-errors-message',
    templateUrl: './validation-errors-message.component.html',
    styles: [
        `
          :host {
            flex: 1;
          }
        `
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DhValidationErrorsMessageComponent implements OnInit, OnChanges {
    @Input() errors: {};
    @Input() validations: any[] = [];
    errorForView = '';
    validationParam;
    multifieldName;

    constructor() {}

    ngOnInit() {
        this.showFirstError(this.errors);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['errors'] != null) {
            this.showFirstError(changes['errors']['currentValue']);
        }
    }

    public showFirstError(errors: {}): void {
        if (errors !== null) {
            const errorsName = Object.keys(errors);
            const index = errorsName[0]?.includes('Verification') && errorsName[1] ? 1 : 0;
            const errorName = errorsName[index];
            this.errorForView = errorName;
            const firstError = {};
            firstError[errorName] = errors[errorName];
            this.getValidationParam(firstError, errorName);
        }
    }

    public showPaymentError(errors: {}): void {
        if (errors !== null) {
            this.errorForView = 'PaymentError';
            this.validations.forEach((validation, index) => {
                if (validation.name === this.errorForView) {
                    this.validationParam = validation.arguments;
                }
            });
        }
    }

    private getValidationParam(error: {}, errorName: string) {
      if (!this.validations) {
        return;
      }

        this.validations.forEach(
            validation => {
                if (validation.name === errorName) {
                    this.validationParam = validation.arguments;
                    //for multifields
                    if (error[errorName] != null && error[errorName] instanceof Array) {
                        this.multifieldName = error[errorName][1];
                    }
                }
            }
        );
    }
}
