import { SystemParameters } from '../models/system-parameters.model';

export const SYSTEM_PARAMETERS_VALUE_PER_UNIT_CODE = {
    1: SystemParameters.PARM_VALUE,
    2: SystemParameters.V_PARM_VALUE_INTEGER,
    3: SystemParameters.V_PARM_VALUE_INTEGER,
    4: SystemParameters.V_PARM_VALUE_DATE,
    5: SystemParameters.V_PARM_VALUE_NO_TEXTAREA,
    6: SystemParameters.V_PARM_VALUE_NO_TEXTAREA,
    7: SystemParameters.V_PARM_VALUE_SELECT_YESNO,
    8: SystemParameters.V_PARM_VALUE_INTEGER,
    9: SystemParameters.V_PARM_VALUE_MINUTES_SECONDS,
    10: SystemParameters.V_PARM_VALUE_MINUTES_SECONDS,
    11: SystemParameters.V_PARM_VALUE_TIME,
    12: SystemParameters.V_PARM_VALUE_INTEGER,
    13: SystemParameters.V_PARM_VALUE_FLOAT,
    14: SystemParameters.V_PARM_VALUE_NO_TEXTAREA,
    15: SystemParameters.V_PARM_VALUE_SELECT_LOGLEVEL,
    16: SystemParameters.V_PARM_VALUE_SELECT_BOOLSTRING,
    17: SystemParameters.V_PARM_VALUE_SELECT_REPAIR,
    18: SystemParameters.PARM_VALUE,
    19: SystemParameters.V_PARM_VALUE_DATE_METHOD,
    20: SystemParameters.V_PARM_VALUE_FX_SLIDER_OPTIONS
};
