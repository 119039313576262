import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-prompt-with-note-modal',
  templateUrl: './prompt-with-note-modal.component.html',
  styleUrls: ['./prompt-with-note-modal.component.scss']
})
export class PromptWithNoteModalComponent {

  constructor(@Inject(MAT_DIALOG_DATA) private _params,
              private dialogRef: MatDialogRef<PromptWithNoteModalComponent>) { }

  get params() {
    return this._params;
  }

  close(data?) {
    this.dialogRef.close(data);
  }
}
