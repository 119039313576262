import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FnUiOption } from 'projects/gpp/src/app/shared/fn-ui-select/fn-ui-select.component';
import { TableRow } from '@fgpp-ui/grid';
import { ConditionOperator, SimpleCondition, ValueListParams } from '../../models';
import { SimpleConditionLogicService } from '../../services/simple-condition-logic.service';

@Component({
  selector: 'app-inner-condition',
  templateUrl: './inner-condition.component.html',
  styleUrls: ['./inner-condition.component.scss']
})
export class InnerConditionComponent {

  @Input() condition: SimpleCondition;
  @Input() disableUpBtn: boolean;
  @Input() disableDownBtn: boolean;
  @Input() removeAllButtons: boolean;
  @Input() showAddBtn: boolean;
  @Input() allowedTypes: Array<string>;

  @Input() set operators(operators: Array<FnUiOption>) {
    this._operators = operators;
    this.operatorChanged(operators[0].value);
  };

  @Output() moveUp = new EventEmitter();
  @Output() moveDown = new EventEmitter();
  @Output() remove = new EventEmitter();
  @Output() add = new EventEmitter();

  shouldShowDrillDown = false;
  valueList: ValueListParams;

  get operators(): Array<FnUiOption> {
    return this._operators;
  }

  private _operators: Array<FnUiOption>;

  constructor(private simpleConditionLogicService: SimpleConditionLogicService) { }

  onMoveUp(): void {
    this.moveUp.emit();
  }

  onMoveDown(): void {
    this.moveDown.emit();
  }

  onRemove(): void {
    this.remove.emit();
  }

  onAdd(): void {
    this.add.emit();
  }

  operatorChanged(newOperator: string): void {
    this.condition.conditionOperator = newOperator as ConditionOperator;

    switch (newOperator.toLowerCase()) {
      case 'in value list':
      case 'not in value list':
        if (!this.shouldShowDrillDown) {
          this.condition.rightOperand = this.simpleConditionLogicService.getEmptyOperand();
        }
        this.valueList = this.simpleConditionLogicService.getValueListParams();
        this.condition.rightOperand.type = 'InValueList';
        this.shouldShowDrillDown = true;
        break;
      default:
        if (this.shouldShowDrillDown) {
          this.condition.rightOperand = this.simpleConditionLogicService.getEmptyOperand();
        }
        this.shouldShowDrillDown = false;
        break;
    }
  }

  onValueListSelected(selectedRows: Array<TableRow>): void {
    this.condition.rightOperand.alias =
      this.simpleConditionLogicService.extractOutputFromDrilldown(null, selectedRows, this.valueList.source);
  }
}
