import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input, OnInit,
  ViewChild,
} from '@angular/core';
import { MdePopoverTrigger } from '@material-extended/mde';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss']
})
export class PopoverComponent implements OnInit, AfterViewInit {

  @Input() tourItem;
  @Input() isTourAbortable;
  @Input() isNextLabelEnabled;
  @Input() isPrevLabelEnabled;
  @Input() onOkHandler;
  @Input() onPostponeHandler;
  @Input() onPrevHandler;
  @Input() onNextHandler;
  @Input() onCloseHandler;

  @Input() shouldShowPopover;
  @Input() targetAt;
  @Input() arrowWidth;

  public removeArrowBorder;

  @ViewChild(MdePopoverTrigger) trigger: MdePopoverTrigger;

  private isDetected = false;

  constructor(private ref: ChangeDetectorRef) {
    if (!this.isDetected) {
      window.setInterval(() => {
        this.ref.detectChanges();
      }, 200);
    }
  }

  ngOnInit(): void {
    this.removeArrowBorder = !this.arrowWidth || this.arrowWidth === '0' ? 'remove-arrow-border' : '';
  }

  ngAfterViewInit(): void {
    if (this.shouldShowPopover) {
      this.trigger.openPopover();
      this.isDetected = true;
    }
  }
}
