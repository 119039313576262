import { InjectionToken, Injectable, Inject } from '@angular/core';
import { delay, filter, of, map, repeatWhen, take, throwError, timeoutWith } from 'rxjs';
import { AuthenticationService } from '../../authentication/services/authentication.service';
import { LiquidityNode, AccountNode, CustomAccountGroupNode, AccountNodeData, PartyNode, LiquidityTaskBarItem } from '../models';
import { ILiquidityService } from './interfaces/liquidity-service.interface';
import { ILiquidityTaskBarItemService } from './interfaces/liquidity-task-bar-item-service.interface';
import { LiquidityApiService } from './liquidity-api.service';
import { LiquidityStatesService } from './liquidity-states.service';

export const LIQUIDITY_ITEM_TOKEN = new InjectionToken<Array<ILiquidityTaskBarItemService>>('liquidity-task-bar-item');

@Injectable()
export class LiquidityService implements ILiquidityService {

  private readonly _checkIframeLoadedDelay = 500;
  private readonly _checkIframeLoadedMaxTries = 20;

  private _taskBarItemServiceMap: { [key: string]: ILiquidityTaskBarItemService };

  constructor(@Inject(LIQUIDITY_ITEM_TOKEN) private liquidityTaskBarItemServices: Array<ILiquidityTaskBarItemService>,
              private liquidityApiService: LiquidityApiService,
              private liquidityStatesService: LiquidityStatesService,
              private authenticationService: AuthenticationService) {
    this.initializeTaskBarItemServiceMap();
  }

  getTaskBarItemService(taskBarItem: LiquidityTaskBarItem): ILiquidityTaskBarItemService {
    return this._taskBarItemServiceMap[taskBarItem];
  }

  postViaForm(action: string, params: Object, target: string): void {
    const form = document.createElement('form');
    form.setAttribute('target', target);
    form.setAttribute('action', action);
    form.setAttribute('method', 'POST');
    form.classList.add('hide');

    Object.keys(params).forEach(key => {
      const input = document.createElement('input');
      input.setAttribute('type', 'hidden');
      input.setAttribute('name', '' + key);
      input.setAttribute('value', params[key]);
      form.append(input);
    });

    const session = document.createElement('input');
    session.setAttribute('name', '@SESSION_ID@');
    session.setAttribute('value', this.authenticationService.sessionId);
    form.append(session);

    const submit = document.createElement('input');
    submit.setAttribute('type', 'submit');
    form.append(submit);

    document.getElementsByTagName('body')[0].append(form);

    form.submit();
    form.remove();
  }

  getSystemAccountsNodeById(treeNodes: Array<LiquidityNode>, searchID: string): any {
    for (const currentNode of treeNodes) {
      const currentId = currentNode.id;
      const currentChildren = currentNode.nodes;
      const accountGroupPrefix = 'ACCOUNTS_GROUPS_TREE_ITEM_';
      const customAccountGroupPrefix = 'CUSTOM_ACCOUNTS_GROUPS_TREE_ITEM_';
      if (currentId === searchID || accountGroupPrefix + currentId === searchID || customAccountGroupPrefix + currentId === searchID) {
        return currentNode;
      } else {
        if (currentChildren.length > 0) {
          const foundDescendant = this.getSystemAccountsNodeById(currentChildren, searchID);
          if (foundDescendant) {
            return foundDescendant;
          }
        }
      }
    }
     return false;
  }

  async loadLiquidityScreen(node: LiquidityNode): Promise<void> {
    const isParty = node.data?.action === 'navigateToPartyExposureScreen';
    if (isParty) {
      await this.loadPartyExplorerScreen(node);
    } else {
      await this.loadPositionScreen(node);
    }
    this.liquidityStatesService.currentNode.node = node;
  }

  private initializeTaskBarItemServiceMap(): void {
    this._taskBarItemServiceMap = {};
    this.liquidityTaskBarItemServices.forEach((liquidityTaskBarItemService: ILiquidityTaskBarItemService) => {
      this._taskBarItemServiceMap[liquidityTaskBarItemService.taskBarItem] = liquidityTaskBarItemService;
    });
  }

  private async loadPartyExplorerScreen(node: PartyNode): Promise<void> {
    const data = node.data;
    const partyGroup = this.liquidityStatesService.partyGroup;
    partyGroup.action = data.action;
    partyGroup.groupOffice = data.office;
    partyGroup.groupName = data.name;
    const iframe = await this.getLiquidityIframePromise();
    iframe.contentWindow['partyGroupAction']();
  }

  private async loadPositionScreen(node: AccountNode | CustomAccountGroupNode): Promise<void> {
    const data = node.data as AccountNodeData;
    const isCustomAccountGroup = !data.uid;
    if (isCustomAccountGroup) {
      const customAccountGroup = node as CustomAccountGroupNode;
      const uids = customAccountGroup.nodes.map((accountNode: AccountNode) => accountNode.data.uid);
      data.accGroupUids = uids.join('@');

      const currencys = customAccountGroup.nodes.map((accountNode: AccountNode) => accountNode.data.currency);
      const isSameCurrency = !!currencys.reduce((currency1, currency2) => currency1 === currency2 ? currency1 : undefined);
      if (isSameCurrency) {
        data.currency = currencys[0];
      }
    }
    const intraDayPosition = this.liquidityStatesService.intraDayPosition;
    intraDayPosition.ACC_ID = data.id;
    intraDayPosition.ACC_CURRENCY = data.currency;
    intraDayPosition.ACC_NAME = data.name;
    intraDayPosition.ACTUAL_FRAME = data.actualFrame;
    intraDayPosition.ACC_FAMILY = data.family;
    intraDayPosition.DIVIDE_LEVEL = 'FULL_AMOUNT';
    intraDayPosition.VIEW_DATE = '';
    intraDayPosition.ACC_OFFICE = data.office;
    intraDayPosition.ACC_OFFICE_BUSINESS_DTE = data.officeBusinessDate;
    intraDayPosition.ACC_UID = data.uid;
    intraDayPosition.ACC_GROUP_UIDS = data.accGroupUids;
    intraDayPosition.CCY_SRC_TPE = 'CCY_ACCT';
    intraDayPosition.ACC_OFFICE_BASE_CCY = data.officeBaseCurrency;
    intraDayPosition.VIEW_POSITION_CYCLE = undefined;
    if (data.uid) {
      intraDayPosition.ACC_IS_HOLD = await this.getAccountIsHold(data.uid);
    }
    const iframe = await this.getLiquidityIframePromise();
    iframe.contentWindow['liquidityAction']('navigateToPositionScreen');
  }

  private getAccountIsHold(accountUid: string): Promise<boolean> {
    return this.getAccount(accountUid).then((account) => {
      if (account) {
        return account['liquidityStopInd'] === 1;
      } else {
        throw new Error('account is null');
      }
    });
  }

  private getAccount(accountUid: string): Promise<any> {
    return this.liquidityApiService.getAccount(accountUid).toPromise();
  }

  private getLiquidityIframe(): HTMLIFrameElement {
    return document.getElementById('liquidity') as HTMLIFrameElement;
  }

  private isLiquidityIframeLoaded(iframe: HTMLIFrameElement): boolean {
    return !!iframe && iframe.contentWindow.document.getElementById('liquidityContainerUi') != null;
  }

  private getLiquidityIframePromise(): Promise<HTMLIFrameElement> {
    return of(true).pipe(
      map(() => this.getLiquidityIframe()),
      repeatWhen(obs => obs.pipe(map(() => true), delay(this._checkIframeLoadedDelay))),
      filter(iframe => this.isLiquidityIframeLoaded(iframe)),
      timeoutWith(this._checkIframeLoadedDelay * this._checkIframeLoadedMaxTries, throwError(() => new Error('liquidity iframe loading timeout'))),
      take(1)
    ).toPromise();
  }
}
