import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { SearchBoxItem } from '../../models';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent implements AfterViewInit {

  @Input()
  set selectedItem(value: SearchBoxItem) {
    this.onSelectedItemChange(value);
  }

  @Output() searchTriggered = new EventEmitter<string>();

  @ViewChild('input') inputElementRef: ElementRef<HTMLInputElement>;

  searchTerm = '';
  placeholder = '';

  readonly placeholderTranslationPrefix = 'navigation_search.search_box.item.placeholder.';

  get disabled(): boolean {
    return !this.searchTerm.length;
  }

  constructor(private elem: ElementRef) {}

  triggerSearch(): void {
    if (!this.disabled) {
      this.searchTriggered.emit(this.searchTerm);
    }
  }

  focus(): void {
    setTimeout(() => {
      this.inputElementRef.nativeElement.focus();
    });
  }

  reset(): void {
    this.searchTerm = '';
  }

  private onSelectedItemChange(item: SearchBoxItem): void {
    this.reset();
    this.placeholder = `${this.placeholderTranslationPrefix}${item.name}`;
  }

  ngAfterViewInit(): void {
    this.elem.nativeElement.setAttribute('azure-id', 'search-input');
  }
}
