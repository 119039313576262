import { Injectable } from '@angular/core';
import { TreeConfig } from '@fgpp-ui/components';
import { Observable, map, tap } from 'rxjs';
import { LiquidityTaskBarItem, SystemAccountGroupsTree, AccountNode, LiquidityNode, SystemAccountOfficeNode, SystemAccountGroupNode } from '../models';
import { ILiquidityTaskBarItemService } from './interfaces/liquidity-task-bar-item-service.interface';
import { LiquidityApiService } from './liquidity-api.service';

@Injectable()
export class SystemAccountGroupsService implements ILiquidityTaskBarItemService {

  get taskBarItem(): LiquidityTaskBarItem {
    return LiquidityTaskBarItem.SYSTEM_ACCOUNT_GROUPS;
  }

  get treeConfig(): Partial<TreeConfig> {
    return this._treeConfig;
  }

  get tree(): SystemAccountGroupsTree {
    return this._tree;
  }

  get activeNode(): AccountNode {
    return this._activeNode;
  }

  set activeNode(value: AccountNode) {
    this._activeNode = value;
  }

  get lastUpdate(): Date {
    return this._lastUpdate;
  }

  private _treeConfig: Partial<TreeConfig>;
  private _tree: SystemAccountGroupsTree;
  private _activeNode: AccountNode;
  private _lastUpdate: Date;

  constructor(private liquidityApiService: LiquidityApiService) {
    this.initTreeConfig();
  }

  getTree(): Observable<SystemAccountGroupsTree> {
    return this.liquidityApiService.getSystemAccountGroups().pipe(map((response: SystemAccountGroupsTree) => {
      this.setRoutingData(response);
      this._lastUpdate = new Date();
      this._tree = response;
      return response;
    }));
  }

  refreshTree(): Observable<SystemAccountGroupsTree> {
    return this.getTree().pipe(tap(() => {
      this.updateActiveNode();
    }));
  }

  findActiveNode(id: string): AccountNode {
    for (const officeNode of this.tree.nodes) {
      for (const accountGroupNode of officeNode.nodes) {
        for (const accountNode of accountGroupNode.nodes) {
          if (accountNode.id === id) {
            return accountNode;
          }
        }
      }
    }
    return null;
  }

  private initTreeConfig(): void {
    this._treeConfig = {
      expandable: (node: LiquidityNode) => !node.data?.uid,
      selectable: (node: LiquidityNode) => !!node.data?.uid,
      trackBy: (node) => node,
      expansionTrackBy: (node: LiquidityNode) => node.id
    };
  }

  private setRoutingData(tree: SystemAccountGroupsTree): void {
    tree.nodes.forEach((officeNode: SystemAccountOfficeNode) => {
      officeNode.nodes.forEach((accountGroupNode: SystemAccountGroupNode) => {
        accountGroupNode.nodes.forEach((accountNode: AccountNode) => {
          accountNode.routerLink = `/home/liquidity/${this.taskBarItem}`;
          accountNode.stateName = 'home.liquidity.views.system-account-groups.single';
          accountNode.stateParams = { account: accountNode.id };
        });
      });
    });
  }

  private updateActiveNode(): void {
    if (this.activeNode) {
      this.activeNode = this.findActiveNode(this.activeNode.id);
    }
  }
}
