import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FnUiScrollbarComponent } from './fn-ui-scrollbar.component';


@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    FnUiScrollbarComponent
  ],
  exports: [
    FnUiScrollbarComponent
  ]
})
export class FnUiScrollbarModule { }
