export const PaymentDataModel = {
  SETTLEMENT_CURRENCY: 'Sttlm Ccy',
  SETTLEMENT_AMOUNT: 'Sttlm amt',
  SETTLEMENT_DATE: 'Sttlm dt',
  DEBTOR_ACCOUNT: 'Dbtr acct ID',
  DEBTOR_NAME: 'Dbtr nm',
  FI_TO_FI: 'BBI Prtry instr nxt agt inf',
  ORIG_TO_BENE: 'Unstructured rmt info',
  PURPOSE_CODE: 'Proprietary Purpose for pmt transaction',
  DESTINATION_COUNTRY: 'Destination Country',
  ENABLE_FX: 'Enable FX',
  OFFICE: 'Pmt office',
  MESSAGE_TYPE: 'Msg tp',
  MESSAGE_SUB_TYPE: 'Msg stp',
  STATUS: 'Msg sts',
  MID: 'MID',
  DIRECTION: 'Transaction Direction',
  VERSION: 'Version',
  MOP: 'Dbt MOP',
  CREDIT_MOP: 'Cdt MOP',
  LOCAL_INSTR_CODE: 'Lcl instrm cd',
  LOCAL_INSTR_PRTRY: 'Lcl instrm prtry',
  BENE_AGT_ID_TYPE: 'Cdtr agt NCC cd',
  INSTR_ID: 'Instr ID X'
};
