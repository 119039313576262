import {Component, Injector, OnInit} from '@angular/core';
import {IValueChange} from '../../../core/interfaces/form-value-change.interface';
import {
  StandingOrderActivationStatus,
  StandingOrderPaymentType,
  StandingOrderTemplateType
} from '../../enums/standing-order-field-mapping.enum';
import {StandingOrderCustomReducer} from './standing-order-custom.reducer';
import {StandingOrder} from '../../models/standing-order.model';
import {BFEvents, ComponentManagerService, FormService} from '@fgpp-ui/components';
import {StandingOrderService} from './standing-order.service';
import {
  BFBeforeProfileCreate,
  BFBeforeProfileEdit, BFOnInit, BFOnValueChange, BFProfileOnSave
} from '../../../core/interfaces/sdk-profile-hooks.interface';
import {AbstractProfileFormComponent} from "../../abstract/abstract-profile/abstract-profile-form.component";
import {StandingOrdersProfileProviders} from "../../../core/templates/profile-form.template.providers";

@Component({
  selector: 'app-standing-order-profile',
  templateUrl: '../../../core/templates/profile-form.template.html',
  styleUrls: ['../../../core/templates/profile-form.template.scss'],
  providers: StandingOrdersProfileProviders
})
export class StandingOrderComponent extends AbstractProfileFormComponent implements BFOnInit, BFBeforeProfileCreate, BFBeforeProfileEdit, BFProfileOnSave, BFOnValueChange {


  constructor(protected injector: Injector, private soService: StandingOrderService) {
    super(injector);
  }

  fgppOnInit(): void {
    this.setCustomReducer(new StandingOrderCustomReducer());
  }

  fgppBeforeCreate(): void {
    this.soService.initializeFields();
  }

  fgppBeforeView(): void {
    this.initializeFieldsOnLoad();
    this.form.disable();
  }

  fgppBeforeEdit(): void {
    this.initializeFieldsOnLoad();
    const activationStatus = this.formMgr.get(StandingOrder.SO_CLOSED).getValue();
    if (activationStatus === StandingOrderActivationStatus.CLOSED) {
      this.form.disable();
    }
  }

  fgppValueChanges(change: IValueChange): void {
    switch (change.control) {
      case StandingOrder.PRIMARY:
        this.formMgr.get(StandingOrder.DD_TEMPLATE_MID).reset();
        this.formMgr.get(StandingOrder.V_CT_TEMPLATE_NM).reset();
        this.formMgr.get(StandingOrder.CT_TEMPLATE_MID).reset();
        this.formMgr.get(StandingOrder.V_DD_TEMPLATE_NM).reset();
        this.formMgr.get(StandingOrder.ACC_NO).reset();
        this.formMgr.get(StandingOrder.ACC_CCY).reset();
        const primaryTemplate = this.formMgr.get(StandingOrder.PRIMARY).getValue();
        this.soService.applyPrimaryTemplate(primaryTemplate);
        break;

      case StandingOrder.V_BEFORE_AFTER:
        const beforeAfter = this.formMgr.get(StandingOrder.V_BEFORE_AFTER).getValue();
        this.soService.applyRecurrenceRange(beforeAfter);
        break;

      case StandingOrder.PMNT_TYPE:
        const paymentType = this.formMgr.get(StandingOrder.PMNT_TYPE).getValue();
        this.soService.applyPaymentType(paymentType);
        if (paymentType === StandingOrderPaymentType.ORDER) {
          this.formMgr.get(StandingOrder.PRIMARY).setValue(StandingOrderTemplateType.CT);
          this.formMgr.get(StandingOrder.DD_TEMPLATE_MID).disable().markAsOptional().reset();
          this.formMgr.get(StandingOrder.CT_TEMPLATE_MID).enable().markAsRequired().reset();
          this.formMgr.get(StandingOrder.V_DD_TEMPLATE_NM);
        } else if (paymentType === StandingOrderPaymentType.SWEEP) {
          this.formMgr.get(StandingOrder.PRIMARY).setValue(StandingOrderTemplateType.CT);
          this.formMgr.get(StandingOrder.CT_TEMPLATE_MID).enable();
          this.formMgr.get(StandingOrder.DD_TEMPLATE_MID).enable();
        }
        break;

      case StandingOrder.CT_MAX_SWEEP_AMT:
        if (!this.soService.isAmountValid(StandingOrder.CT_MIN_SWEEP_AMT, StandingOrder.CT_MAX_SWEEP_AMT)) {
          this.formMgr.get(StandingOrder.CT_MAX_SWEEP_AMT).reset();
          this.notificationService.error(this.translate.instant('business-framework.profiles.16.errors.max_greater_than_min'));
        }
        break;

      case StandingOrder.CT_MIN_SWEEP_AMT:
        if (!this.soService.isAmountValid(StandingOrder.CT_MIN_SWEEP_AMT, StandingOrder.CT_MAX_SWEEP_AMT)) {
          this.formMgr.get(StandingOrder.CT_MIN_SWEEP_AMT).reset();
          this.notificationService.error(this.translate.instant('business-framework.profiles.16.errors.max_greater_than_min'));
        }
        break;

      case StandingOrder.DD_MAX_SWEEP_AMT:
        if (!this.soService.isAmountValid(StandingOrder.DD_MIN_SWEEP_AMT, StandingOrder.DD_MAX_SWEEP_AMT)) {
          this.formMgr.get(StandingOrder.DD_MAX_SWEEP_AMT).reset();
          this.notificationService.error(this.translate.instant('business-framework.profiles.16.errors.max_greater_than_min'));
        }
        break;

      case StandingOrder.DD_MIN_SWEEP_AMT:
        if (!this.soService.isAmountValid(StandingOrder.DD_MIN_SWEEP_AMT, StandingOrder.DD_MAX_SWEEP_AMT)) {
          this.formMgr.get(StandingOrder.DD_MIN_SWEEP_AMT).reset();
          this.notificationService.error(this.translate.instant('business-framework.profiles.16.errors.max_greater_than_min'));
        }
        break;

      case StandingOrder.FREQUENCY_PATTERN:
        const frequency = this.formMgr.get(StandingOrder.FREQUENCY_PATTERN).getValue();
        this.soService.applyFrequency(frequency, true);
        const titles = this.getFrequencyTitles();
        this.layoutEvent.setProperty(StandingOrder.RECUR_EVERY, 'title', titles[frequency]);
        break;

      case StandingOrder.CT_TEMPLATE_MID :
        if (change.value) {
          const templateData = this.getDrilldownData(StandingOrder.CT_TEMPLATE_MID);
          if (templateData) {
            if (this.formMgr.get(StandingOrder.PMNT_TYPE).getValue() === StandingOrderPaymentType.SWEEP) {
              if (this.formMgr.get(StandingOrder.DD_TEMPLATE_MID).getValue()) {
                const ddTemplateData = this.getDrilldownData(StandingOrder.DD_TEMPLATE_MID);
                if (!ddTemplateData || ddTemplateData['MINF.P_CDT_ACCT_NB'] !== templateData['MINF.P_DBT_ACCT_NB']) {
                  this.notificationService.error(this.translate.instant('business-framework.profiles.16.errors.debit_ac_mismatch'));
                  this.formMgr.get(StandingOrder.CT_TEMPLATE_MID).reset();
                  this.formMgr.get(StandingOrder.V_CT_TEMPLATE_NM).reset();
                  return;
                }
              }
            }
            this.soService.setCTTemplateData(templateData);
          } else {
            this.formMgr.get(StandingOrder.V_CT_TEMPLATE_NM).reset();
            if (!this.getDrilldownData(StandingOrder.DD_TEMPLATE_MID)) {
              this.formMgr.get(StandingOrder.V_CT_TEMPLATE_NM).reset();
              this.formMgr.get(StandingOrder.ACC_NO).reset();
              this.formMgr.get(StandingOrder.ACC_CCY).reset();
            }
          }
        } else {
          this.formMgr.get(StandingOrder.V_CT_TEMPLATE_NM).reset();
          if (!this.getDrilldownData(StandingOrder.DD_TEMPLATE_MID)) {
            this.formMgr.get(StandingOrder.V_CT_TEMPLATE_NM).reset();
            this.formMgr.get(StandingOrder.ACC_NO).reset();
            this.formMgr.get(StandingOrder.ACC_CCY).reset();
          }
        }
        break;

      case StandingOrder.DD_TEMPLATE_MID:
        if (change.value) {
          const templateData = this.getDrilldownData(StandingOrder.DD_TEMPLATE_MID);
          if (templateData) {
            if (this.formMgr.get(StandingOrder.PMNT_TYPE).getValue() === StandingOrderPaymentType.SWEEP) {
              if (this.formMgr.get(StandingOrder.CT_TEMPLATE_MID).getValue()) {
                const ctTemplateData = this.getDrilldownData(StandingOrder.CT_TEMPLATE_MID);
                if (!ctTemplateData || ctTemplateData['MINF.P_DBT_ACCT_NB'] !== templateData['MINF.P_CDT_ACCT_NB']) {
                  this.notificationService.error(this.translate.instant('business-framework.profiles.16.errors.debit_ac_mismatch'));
                  this.formMgr.get(StandingOrder.DD_TEMPLATE_MID).reset();
                  this.formMgr.get(StandingOrder.V_DD_TEMPLATE_NM).reset();
                  return;
                }
              }
            }
            this.soService.setDDTemplateData(templateData);
          } else {
            this.formMgr.get(StandingOrder.V_DD_TEMPLATE_NM).reset();
            if (!this.getDrilldownData(StandingOrder.CT_TEMPLATE_MID)) {
              this.formMgr.get(StandingOrder.V_DD_TEMPLATE_NM).reset();
              this.formMgr.get(StandingOrder.ACC_NO).reset();
              this.formMgr.get(StandingOrder.ACC_CCY).reset();
            }
          }
        } else {
          this.formMgr.get(StandingOrder.V_DD_TEMPLATE_NM).reset();
          if (!this.getDrilldownData(StandingOrder.CT_TEMPLATE_MID)) {
            this.formMgr.get(StandingOrder.V_DD_TEMPLATE_NM).reset();
            this.formMgr.get(StandingOrder.ACC_NO).reset();
            this.formMgr.get(StandingOrder.ACC_CCY).reset();
          }
        }
        break;
    }
  }

  fgppOnSave(): boolean {
    const fieldsArray = [StandingOrder.END_BY_DATE, StandingOrder.DD_MAX_SWEEP_AMT, StandingOrder.DD_MIN_SWEEP_AMT,
      StandingOrder.CT_MIN_SWEEP_AMT, StandingOrder.CT_MAX_SWEEP_AMT, StandingOrder.MIN_REMAIN_BAL];
    fieldsArray.forEach(field => {
      let val = this.formMgr.get(field).getValue();
      if (val === null || val === undefined) {
        val = '';
      }
      this.formMgr.get(field).setValue(val.toString());
    });
    return true;
  }

  fgppOnButtonClick(actionPayload: BFEvents.ButtonEvent): void {
    if (actionPayload.target === 'EXECUTE_MANUALLY') {
      if (actionPayload.name === 'buttonClickResponse') {
        this.hideSpinner();
        if (actionPayload.value.action && actionPayload.value.action.error) {
          this.notificationService.error(actionPayload.value.action.error['errorMessage']);
        } else {
          this.notificationService.success(this.translate.instant('business-framework.profiles.16.errors.execution_successful'));
        }
      } else if (actionPayload.name === 'buttonClick') {
        this.showSpinner();
      }
    }
  }

  private getFrequencyTitles(): any {
    const D = this.translate.instant('business-framework.profiles.16.recur_every.dailyTitle');
    const W = this.translate.instant('business-framework.profiles.16.recur_every.weekTitle');
    const M = this.translate.instant('business-framework.profiles.16.recur_every.monthTitle');
    const Y = this.translate.instant('business-framework.profiles.16.recur_every.yearTitle');
    return {
      D,
      W,
      M,
      Y,
      default: D
    };
  }

  initializeFieldsOnLoad() {
    const primaryTemplate = this.formMgr.get(StandingOrder.PRIMARY).getValue();
    this.soService.applyPrimaryTemplate(primaryTemplate);

    const beforeAfter = this.formMgr.get(StandingOrder.V_BEFORE_AFTER).getValue();
    this.soService.applyRecurrenceRange(beforeAfter);

    const paymentType = this.formMgr.get(StandingOrder.PMNT_TYPE).getValue();
    this.soService.applyPaymentType(paymentType);

    const frequency = this.formMgr.get(StandingOrder.FREQUENCY_PATTERN).getValue();

    this.soService.applyFrequency(frequency);
    const titles = this.getFrequencyTitles();
    this.layoutEvent.setProperty(StandingOrder.RECUR_EVERY, 'title', titles[frequency]);
  }

}
