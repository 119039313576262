import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { NavigationTabConfig } from '../../core/navigation/models';
import { MenuService } from '../../core/services/menu.service';

export const NavigationTabsResolver: ResolveFn<Array<NavigationTabConfig>> =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Array<NavigationTabConfig> => {

    const menuService = inject(MenuService);

    const items = menuService.getMenuItem('main').items;
    const keys = Object.keys(items);
    keys.sort((key1: string, key2: string) => items[key1].position - items[key2].position);

    return keys.map((key: string, index: number) => {
      const item = items[key];
      return {
        id: key,
        alias: item.alias,
        icon: item.icon,
        width: 0,
        index: index,
        routerLink: item.routerLink,
        stateName: item.stateName
      };
    });
  };
