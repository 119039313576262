import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn, FormControl, Validators } from '@angular/forms';
import { ExportOptions } from '../models/enums/export-options.enum';
import { FileTypes } from '../models/enums/file-types.enum';
import { ExportRequest } from '../models/export-request.model';
import { KeyValue } from '../../models/key-value.model';

@Injectable()
export class ExportFormService {
  protected exportOptions: Array<KeyValue<string, ExportOptions>>;

  constructor(private formBuilder: FormBuilder) {
    this.setExportOptions();
  }

  createForm(): FormGroup {
    const exportForm = this.formBuilder.group({
      option: [ExportOptions.CURRENT_PAGE],
      fileType: [FileTypes.EXCEL],
      fileName: ['', this.fileNameValidator],
      fileTemplate: true
    });
    return exportForm;
  }

  getExportRequest(exportForm: FormGroup) {
    const formValue = exportForm.value;
    const exportRequest: ExportRequest = {
      option: formValue.option,
      fileName: formValue.fileName,
      fileType: formValue.fileType,
      fileTemplate: formValue.fileTemplate
    };
    if (formValue.option === ExportOptions.CUSTOM) {
      exportRequest.range = {
        from: this.fromPage(formValue.range),
        to: this.toPage(formValue.range)
      };
    }
    return exportRequest;
  }

  async getExportOptions() {
    return this.exportOptions;
  }

  get rangeValidators(): ValidatorFn[] {
    return [
      this.requiredRangeValidator,
      this.rangeFormatValidator
    ];
  }

  private setExportOptions() {
    this.exportOptions = [
      { key: 'export.options.current_page', value: ExportOptions.CURRENT_PAGE },
      { key: 'export.options.top', value: ExportOptions.TOP },
      { key: 'export.options.selected_rows', value: ExportOptions.SELECTED_ROWS }
    ];
  }

  private fromPage(range: string): number {
    const regex = new RegExp('^\\d+');
    return +regex.exec(range)[0];
  }

  private toPage(range: string): number {
    const regex = new RegExp('\\d+$');
    return +regex.exec(range)[0];
  }

  private get requiredRangeValidator(): ValidatorFn {
    return (rangeControl: FormControl) =>
      Validators.required(rangeControl) ? { required: 'export.range.required' } : null;
  }

  private get rangeFormatValidator(): ValidatorFn {
    const regex = new RegExp('^(\\d+\-\\d+|\\d+ \- \\d+)$');
    return (rangeControl: FormControl) => {
      if (Validators.required(rangeControl)) {
        return null;
      }
      if (!regex.test(rangeControl.value)) {
        return { format: 'export.range.format' };
      }
      const from = this.fromPage(rangeControl.value);
      const to = this.toPage(rangeControl.value);
      return from < to ? null : { format: 'export.range.format' };
    };
  }

  private get fileNameValidator(): ValidatorFn {
    const regex = new RegExp('^[^<\<\>\:\"\\\\/\|\?\*>]+$');
    return (fileNameControl: FormControl) => Validators.required(fileNameControl) || regex.test(fileNameControl.value) ? null : { format: 'export.file_name.format' };
  }

}
