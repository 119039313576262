import { AfterViewChecked, AfterViewInit, Component, Inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UserSettingsComponent } from '../user-settings/user-settings.component';
import { UserSettingsService } from '../../services/user-settings.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MenuService } from '../../../services/menu.service';
import { TaskBarService } from '../../../navigation/task-bar/services/task-bar.service';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';

@Component({
  selector: 'app-display',
  templateUrl: './display.component.html',
  styleUrls: ['./display.component.scss']
})
export class DisplayComponent implements OnInit, AfterViewChecked {

  @ViewChildren(MatSelect) selectItems: QueryList<MatSelect>;
  @ViewChildren(MatOption) optionItems: QueryList<MatOption>;

  private _menuItems;

  changed = false;
  landingPages: any;
  navigationBars: any;
  form: FormGroup;

  constructor(private _menu: MenuService,
    private _taskBar: TaskBarService,
    private userSettingsService: UserSettingsService,
    private dialogRef: MatDialogRef<UserSettingsComponent>,
    private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.createForm();

    this.landingPages = this.getMenu();
    this.navigationBars = this.getNavigationBar() || [];
  }

  private createForm() {
    const settings = this.userSettingsService.getPreferences();
    if (!settings.landingPage) {
      settings.landingPage = this._menu.getDefaultTopLevelMenu().alias;
    }
    this.form = this.formBuilder.group({
      'landingPage': [settings.landingPage],
      'navigationBar': [{ value: settings.navigationBar, disabled: true }],
      'roundNumber': [settings.roundNumber]
    });

    this.form.valueChanges.subscribe(() => {
      this.onFormChanged();
    });
  }

  onLandingPageChange() {
    this.form.get('navigationBar').setValue(null);
    this.setNavigationBar();
  }

  apply() {
    const newSettings = this.getNewSettings();
    this.changed = false;
    this.userSettingsService.save(newSettings).then((response) => {
      console.log(`Setting saved successfully ${response}`);
    }, (response) => {
      console.error(`Error while saving user settings ${response}`);
    });
  }

  cancel() {
    this.dialogRef.close();
  }

  onFormChanged() {
    this.changed = true;
  }

  setNavigationBar() {
    const landingPage = this.form.get('landingPage').value;
    this.navigationBars = this.getNavigationBar();
    let navigationBar = null;

    if (this.navigationBars && this.navigationBars.length > 0) {
      let number;
      switch (landingPage) {
        case 'system_navigation.MAINTAB_QUEUES': {
          number = this.getNumberOfDefaultValue(this.navigationBars, 'my_favorite');
          navigationBar = this.navigationBars[number];
          break;
        }
        case 'system_navigation.MAINTAB_LIQUIDITY': {
          number = this.getNumberOfDefaultValue(this.navigationBars, 'LIQUIDITY_FAVORITES');
          navigationBar = this.navigationBars[number];
          break;
        }
        case 'system_navigation.MAINTAB_BUSINESS_CONFIGURATION': {
          number = this.getNumberOfDefaultValue(this.navigationBars, 'Business_Entities');
          navigationBar = this.navigationBars[number];
          break;
        }
        case 'system_navigation.MAINTAB_SYSTEM_CONFIGURATION': {
          number = this.getNumberOfDefaultValue(this.navigationBars, 'System_Parameters');
          navigationBar = this.navigationBars[number];
          break;
        }
        case 'system_navigation.MAINTAB_HOUSKEEPING': {
          number = this.getNumberOfDefaultValue(this.navigationBars, 'Interfaces');
          navigationBar = this.navigationBars[number];
          break;
        }
        default: {
          navigationBar = this.navigationBars[0];
        }
      }
    }
    this.form.get('navigationBar').setValue(navigationBar);
  }

  restore() {
    this.form.get('roundNumber').setValue(false);
    this.form.get('landingPage').setValue(this._menu.getDefaultTopLevelMenu().alias);
    this.form.get('roundNumber').setValue(null);
    this.form.markAsDirty();
    this.onFormChanged();
    this.setNavigationBar();
  }

  save() {
    const newSettings = this.getNewSettings();
    this.form.markAsPristine();
    this.userSettingsService.save(newSettings).then((response) => {
      this.dialogRef.close();
    })['catch']((response) => {
      console.error(`Error while saving user settings ${response}`);
    });
  }

  private getMenu = () => {
    const landingPages = [];
    this._menuItems = this._menu.getMenuItem('main').items;
    const keys = Object.keys(this._menuItems);
    let i = 0;
    // TODO:Debug to check for functionality
    keys.forEach((key) => {
      landingPages[i] = this._menuItems[key].alias;
      i++;
    });
    return landingPages;
  };

  private getNavigationBar = () => {
    let navigationBar = [];
    let item;

    const landingPage = this.form.get('landingPage').value;

    for (const key of Object.keys(this._menuItems)) {
      if (this._menuItems[key].alias === landingPage) {
        item = key;
        break;
      }
    }

    this.form.get('navigationBar').enable();

    navigationBar = this.selectNavigationBar(item);
    return navigationBar;
  };

  private getNewSettings = () => {
    const settings: any = {};
    Object.assign(settings, this.userSettingsService.getPreferences());
    settings.roundNumber = this.form.get('roundNumber').value;
    settings.landingPage = this.form.get('landingPage').value;
    settings.navigationBar = this.form.get('navigationBar').value;
    return settings;
  };

  private getNumberOfDefaultValue = (navigationBars, nameDefaultValue) => {
    let res = 0;
    for (let i = 0; i < navigationBars.length; i++) {
      if (navigationBars[i].search(nameDefaultValue) > -1) {
        res = i;
        break;
      }
    }
    return res;
  };

  private selectNavigationBar = (key) => {
    const navigationBar = [];
    const taskBars = this._taskBar.taskBars;

    if (!taskBars[key]) {
      return undefined;
    }

    const taskBar = taskBars[key].items;
    for (let i = 0; i < taskBar.length; i++) {
      navigationBar[i] = taskBar[i].alias;
    }
    return navigationBar;
  };

  ngAfterViewChecked() {
    for (const item of this.selectItems) {
      item._elementRef.nativeElement.setAttribute('azure-id', item['_id']);
    }
    for (const itemList of this.optionItems) {
      itemList._getHostElement().setAttribute('azure-id', `${itemList.value}`);
    }
  }
}
