import { IProfileFooter } from '../../interfaces/profile-footer.interface';
import { ButtonCategory } from '../../../core/enums/button-category.enum';
import { ProfilesFooterService } from '../../services/profiles-footer.service';
import { FgppButtonProps } from '../../../core/utils/fgpp-button-props.utils';
import { IProfileLayoutSelectionMetadata } from '../../interfaces/profile-layout-selection-metadata.interface';
import { ButtonType } from '../../../core/enums/button-type.enum';
import { ProfileButtons } from '../../enums/profile-buttons.enum';
import { StandingOrder } from '../../models/standing-order.model';
import { StandingOrderActivationStatus } from '../../enums/standing-order-field-mapping.enum';
import { ISdkProfileFooterReducer } from '../../interfaces/profile-footer-reducer.interface';

export class DrawDownCustomReducer implements ISdkProfileFooterReducer {
  public reduce(buttonCategory: ButtonCategory, footer: IProfileFooter, inputs: IProfileLayoutSelectionMetadata): IProfileFooter {
    const footerService = new ProfilesFooterService().setFooter(footer);
    const buttonList: FgppButtonProps[] = [];
    const replicateButton = new FgppButtonProps('REPLICATE', ButtonType.FLAT, 3);
    const attachmentsButton = new FgppButtonProps('ATTACHMENTS-BUTTON', ButtonType.MENU, 99);
    if (inputs.mode === 'create') {
      buttonList.push(replicateButton);
    }
    buttonList.push(attachmentsButton);
    return footerService.retain(buttonCategory, buttonList).getFooter();
  }
}
