import { ActivatedRouteSnapshot, Routes } from '@angular/router';
import { RedirectMessageCenterGuard } from './guards/redirect-message-center.guard';
import { LandingPageComponent } from '../../messages/components/landing-page/landing-page.component';
import { MainViewComponent } from '../../base-view/components/main-view/main-view.component';
import { TabTaskBarResolver } from '../../resolvers/tab-task-bar.resolver';
import { TaskBarItemComponentResolver } from '../../resolvers/task-bar-item-component.resolver';
import { MessagesCenterTreeResolver } from './resolvers/messages-center-tree.resolver';
import { TaskBarItemExitGuard } from './guards/task-bar-item-exit.guard';
import { RedirectSearchGuard } from './guards/redirect-search.guard';
import { Entity } from '../../shared/models/enums/entity.enum';
import { MessagesCenterTaskBarItemGuard } from './guards/messages-center-task-bar-item.guard';

export const MessageCenterRoutes: Routes = [
  { path: '', canActivate: [RedirectMessageCenterGuard], component: LandingPageComponent },
  {
    path: ':taskBarItem',
    component: MainViewComponent,
    data: {
      type: Entity.MESSAGES
    },
    resolve: {
      'taskBar': TabTaskBarResolver,
      'taskBarComponent': TaskBarItemComponentResolver,
      'tree': MessagesCenterTreeResolver
    },
    canActivate: [MessagesCenterTaskBarItemGuard, RedirectSearchGuard],
    canDeactivate: [TaskBarItemExitGuard],
    runGuardsAndResolvers: (from: ActivatedRouteSnapshot, to: ActivatedRouteSnapshot) => {
      if (to.queryParams.search) {
        return true;
      }
      return from.params.taskBarItem !== to.params.taskBarItem;
    },
    children: [
      { path: '', pathMatch: 'full', component: LandingPageComponent },
      {
        path: 'messages',
        loadChildren: () => import('../../message/message.module').then(m => m.MessageModule)
      },
      {
        path: 'batches',
        loadChildren: () => import('../../mass-pay/batches/batches.module').then(m => m.BatchesModule)
      },
      {
        path: 'bulks',
        loadChildren: () => import('../../mass-pay/bulks/bulks.module').then(m => m.BulksModule)
      },
      {
        path: 'files',
        loadChildren: () => import('../../mass-pay/files/files.module').then(m => m.FilesModule)
      },
    ]
  }
];
