import { Component } from '@angular/core';
import { TableRow } from '@fgpp-ui/grid';
import { ExternalGridDialogComponent } from '../external-grid-dialog/external-grid-dialog.component';

@Component({
  selector: 'app-drill-down-dialog',
  templateUrl: './drill-down-dialog.component.html',
})
export class DialogDrillDownComponent extends ExternalGridDialogComponent {

  selectedRows: Array<TableRow> = [];

  onRowClicked($event: TableRow) {
    this.dialogRef.close([$event]);
  }

  onSelectionChange(rows: Array<TableRow>) {
    this.selectedRows = rows;
  }

  onConfirmBtn() {
    this.closeDialog();
  }

  closeDialog() {
    this.dialogRef.close(this.selectedRows);
  }
}
