import {Component, OnInit, Input, EventEmitter, Output, Type, inject} from '@angular/core';
import { FileAttachmentColumn } from '../models/file-attachment-column.model';
import { FileAttachmentPermissionMode } from '../../file-attachment-manager/models/enums/file-attachment-permission-mode.enum';
import { Icon } from '../../shared/models/icon.model';
import { FileStatus } from '../../file-attachment/file-attachment-form/models/enums/file-status.enum';
import { FileAttachmentRow } from '../file-attachment-row/models/file-attachment-row.model';
import { FileAttachmentListTexts } from './models/file-attachment-list-texts.model';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'lib-file-attachments-list',
  templateUrl: './file-attachments-list.component.html',
  styleUrls: ['./file-attachments-list.component.scss']
})

export class FileAttachmentsListComponent implements OnInit {
  @Input() fileStatus: FileStatus;
  @Input() fileStatusBool: boolean;
  @Input() permissions: FileAttachmentPermissionMode;
  @Input() texts: FileAttachmentListTexts;
  @Input() fileAttachmentsList: Array<FileAttachmentRow> = new Array<FileAttachmentRow>();
  @Output() downloadOnClick: EventEmitter<FileAttachmentRow> = new EventEmitter<FileAttachmentRow>();
  @Output() clearOnClick: EventEmitter<FileAttachmentRow> = new EventEmitter<FileAttachmentRow>();
  @Input() loaderComponent: Type<any>;
  @Input() showLoader: boolean;
  @Input() dateFormatParser: Function;

  uploadSuccessfulIcon: Icon;
  title: FileAttachmentColumn;
  FileStatus = FileStatus;
  translate = inject(TranslateService);
  uploadNotification = '';
  constructor() { }

  ngOnInit() {
    this.initUploadSuccessfulIcon();
    this.title = {
      description: 'File Description',
      userId: 'User Id',
      fileName: 'File Name',
      updatedDate: 'Upload Date'
    };
    this.uploadNotification = this.translate.instant('file-upload.save-alert');
  }

  onDownloadClick(fileAttachmentRow: FileAttachmentRow) {
    this.downloadOnClick.emit(fileAttachmentRow);
  }

  onClearClick(fileAttachmentRow: FileAttachmentRow) {
    this.clearOnClick.emit(fileAttachmentRow);
  }

  protected initUploadSuccessfulIcon() {
    const style = {
      'height.px': 23,
      'width.px': 23
    };
    const uploadSuccessfulIconTemplateRef = 'assets/icons/upload_successful.svg';
    this.uploadSuccessfulIcon = {
      iconTemplateRef: uploadSuccessfulIconTemplateRef,
      isDisabled: true,
      mainClass: null,
      iconClass: null,
      tooltip: null,
      style
    };
  }
}
