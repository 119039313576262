import {inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import * as bcrypt from 'bcryptjs';
import {AuthenticationService} from "../../../../authentication/services/authentication.service";
import {throwError, of} from 'rxjs';
import {map, switchMap} from "rxjs/operators";
import { CallerQueuesTree } from 'projects/gpp/src/app/core/navigation/sitemap/models';

@Injectable()
export class CallerCallbackApiService {

  authenticationService = inject(AuthenticationService);

  constructor(private httpClient: HttpClient) {}

  public authenticateCaller(callerParams, requestTime: string) {
    const httpHeaders = new HttpHeaders({"request-time": requestTime});
    let obs = null;
    if (callerParams.authType === 'A' || callerParams.authType === 'V') {
      obs = this.httpClient.post('do/callerCallBack/doCallerAuthAsync', callerParams, { headers: httpHeaders, observe: 'response'});
    }
    obs = this.httpClient.post('do/callerCallBack/doCallerAuth', callerParams, {headers: httpHeaders, observe: 'response'});
    return  obs.pipe(switchMap((res: HttpResponse<any>) => {
      if (this.verifyDigitalSignature(res,requestTime)) {
        return of(res);
      } else {
        return  throwError({
          error: {
            responseMsg: 'invalidDigitalSignature'
          }
        });
      }
    }));
  }

  verifyDigitalSignature(response: HttpResponse<any>, requestTime: string): boolean {
    const plaintextSignature = `${response.body.responseMsg}${response.body.requireMFA}${this.authenticationService.sessionId}${requestTime}`;
    const hashedSignature = response.headers.get('X-Digital-Signature');
    try {
      return bcrypt.compareSync(plaintextSignature, hashedSignature);
    } catch (e) {
      return false;
    }
  }


  public authenticatePin(callerParams) {
    return this.httpClient.post('do/callerCallBack/doVerifyPin', callerParams);
  }
  public getCallerTree(): Promise<CallerQueuesTree> {
    return this.httpClient.get('do/callerCallBack/callerCallbackTree').toPromise().then((res: any) => {
      return res.payload && res.payload.data ? res.payload.data : res;
    });
  }

  public getMidsForCaller(callerId, custCode, isSuperCaller?: string) {
    custCode = isSuperCaller ? '' : custCode;
    const params = new HttpParams().set('custCode', custCode).set('callerId', callerId);
    return this.httpClient.get('do/callerCallBack/paymentsForCaller', { params: params }).toPromise();
  }
}
