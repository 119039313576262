import { Injectable } from '@angular/core';
import { FormValidatorsService } from '../../../services/form-validators.service';

@Injectable({
  providedIn: 'root'
})
export class FaFormService {

  constructor(private formValidatorsService: FormValidatorsService) { }

  getControlsConfig(data) {
    const controlsConfig = {};
    const keys = Object.keys(data);
    for (let i = 0; i < keys.length; i++) {
      if (data[keys[i]] !== null && data[keys[i]] !== undefined && this.isFormField(data[keys[i]])) {
        if (data[keys[i]].value !== undefined) {
          controlsConfig[keys[i]] =
            [{ value: data[keys[i]].value, disabled: data[keys[i]].readOnly }, this.formValidatorsService.setValidators(data[keys[i]].validations)];
        } else {
          controlsConfig[keys[i]] = [{ value: data[keys[i]].value, disabled: data[keys[i]].readOnly }];
        }
      }
    }
    return controlsConfig;
  }

  isFormField(field) {
    return field.hasOwnProperty('value') && field.hasOwnProperty('validations') && field.hasOwnProperty('readOnly');
  }

  addValidator(validator, control, controlMetadata) {
    if (controlMetadata.validations == null) {
      controlMetadata.validations = [];
    }

    let validatorObj = {};
    if (typeof validator === 'string') {
      validatorObj['name'] = validator;
    } else if (typeof validator === 'object') {
      validatorObj = validator;
    }

    if (controlMetadata.validations.find(item => item.name === validatorObj['name'])) {
      return;
    }

    controlMetadata.validations.push(validatorObj);
    control.setValidators(this.formValidatorsService.setValidators(controlMetadata.validations));
  }

  removeValidator(validator, control, controlMetadata) {
    const validations = controlMetadata?.validations;

    for (let i = 0; i < validations?.length; i++) {
      if (validations[i] != null && validations[i].name != null && validations[i].name === validator) {
        validations.splice(i, 1);
        control?.setValidators(this.formValidatorsService.setValidators(validations));
      }
    }
  }

}
