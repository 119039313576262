import { Injectable } from '@angular/core';
import { GridManagementService } from './grid-management.service';
import { ProfilesSearchRequest } from '../../profiles/models/profiles-search-request.type';
import { Entity } from '../../shared/models/enums/entity.enum';
import { HttpParams } from '@angular/common/http';
import { GridConfigService } from './grid-config.service';
import { Filter, SearchFilter } from '../../shared/models/search-filter.model';
import { MatDialogConfig } from '@angular/material/dialog';
import { ExternalFilterGridData } from '../models/external-filter-grid-data.model';
import { SearchRequestBuilderService } from './search-request-builder.service';
import { ExternalFilterGridHelperService } from '../../profiles/services/external-filter-grid-helper.service';
import { FormatsService } from '../../shared/services/formats.service';
import { PreDefinedFacet } from '../models/pre-defined-facets.model';
import { PROFILE_UID } from '../../profiles/services/profiles-management.service';
import { FilterOperator } from '@fgpp-ui/grid';
import { BooleanValue } from '@fgpp-ui/grid';
import { ExternalFilter } from '../../shared/models/external-filter.model';
import { UserSettingsService } from '../../core/user-settings/services/user-settings.service';

export const EMPTY = 'EMPTY';
export const NOT_EMPTY = 'NOT_EMPTY';


@Injectable({
  providedIn: 'root'
})
export class ExternalFilterGridService extends GridManagementService<ProfilesSearchRequest> {

  protected _externalFilterGridData;

  get externalFilterGridData() {
    return this._externalFilterGridData;
  }

  set externalFilterGridData( value ) {
    this._externalFilterGridData = value;
  }

  get keyId() {
    return this.externalFilterGridData.isSelectType && this.gridData.columns.length ?
      this.gridData.columns[0].name : PROFILE_UID;
  }

  constructor(
    userSettingsService: UserSettingsService,
    public externalFilterGridHelperService: ExternalFilterGridHelperService,
    gridConfigService: GridConfigService,
    searchRequestBuilderService: SearchRequestBuilderService<ProfilesSearchRequest>,
    formatsService: FormatsService) {
    super(userSettingsService, externalFilterGridHelperService, gridConfigService, searchRequestBuilderService, formatsService);
  }

  get baseUrl(): string {
    return this.externalFilterGridData.baseUrl;
  }

  get defaultFilters(): Array<SearchFilter> {
    return this.externalFilterGridData.searchFilter.filter( filter => filter.hidden);
  }

  getDefaultSearchRequest(): ProfilesSearchRequest {
    const searchRequest = super.getDefaultSearchRequest();
    searchRequest.selectType = 1;
    searchRequest.searchQuery.additionalParameters = this.externalFilterGridData.additionalParameters;

    if (!searchRequest.searchQuery.searchCriteria) {
      delete searchRequest.searchQuery.searchCriteria;
    }
    return searchRequest;
  }

  getDialogConfig(data: ExternalFilterGridData): MatDialogConfig {
      return {
        data: data,
        panelClass: 'dialog-container',
        width: '1200px',
        height: '630px'
      };
  }

  getPreDefinedFacets(filter: Filter | ExternalFilter, filterResultByColumn: string, label: string, filterKeys: Array<string>, controllerValue: string,
    isSelectType?: boolean): PreDefinedFacet[] {
    if (isSelectType) {
      return [];
    }
    const arr = [];
    if (filterKeys && filterKeys.length) {
      filterKeys.forEach(key => {
        const filterValue = filter.searchFilter[key];
        let operator, value;
        if (Array.isArray(filterValue)) {
          operator = FilterOperator.IN;
          value = filterValue;
        } else {
          switch (filterValue) {
            case EMPTY:
              operator = FilterOperator.IS_EMPTY;
              value = [BooleanValue.TRUE];
              break;
            case NOT_EMPTY:
              operator = FilterOperator.IS_EMPTY;
              value = [BooleanValue.FALSE];
              break;
            default:
              operator = FilterOperator.CONTAINS;
              value = [filterValue];
          }
        }

        arr.push({
          columnId: key,
          operator: operator,
          alias: key,
          value: value,
          hidden: true
        });
      });

    }
    if (controllerValue) {
      arr.push({
        columnId: filterResultByColumn,
        operator: FilterOperator.CONTAINS,
        alias: label,
        value: [controllerValue],
        hidden: false
      });
    }
    return arr;
  }

  get entity(): Entity {
    return this.externalFilterGridData.entity;
  }

  get httpParams(): HttpParams {
    return null;
  }
}
