<div class="rule-associations-grid-wrapper" [appSpinner]="isFetchingData">
  <app-tour-item [tourId]="'ruleAssociationScreenDetails'" [tourStepNumber]="1"></app-tour-item>
  <lib-grid [config]="gridConfig"
            [columns]="gridColumns"
            [data]="gridData"
            [selectedFilters]="selectedActionFilters"
            [selectedRows]="selectedRows"
            [leftFooterTemplate]="footerTemplateRef"
            [keyId]="keyId"
            (filterChanged)="onFilterChanged($event)"
            (pageChanged)="onPageChanged($event)"
            (selectionChanged)="onSelectionChanged($event)"
            (sortChanged)="onSortChanged($event)"
            (pinChanged)="onColumnPinChange($event)"
            (columnSizeChanged)="onColumnSizeChanged()"
            (refineFilteringClick)="onRefineFilteringClick($event)"
            (operatorChanged)="onOperatorChanged($event)">
  </lib-grid>
</div>

<ng-template #footerTemplateRef>
  <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
  <app-grid-footer *ngIf="gridContainerConfig.gridFooterConfig"
                   [gridFooterConfig]="gridContainerConfig.gridFooterConfig"
                   [totalCount]="gridData.totalCount"
                   [selectedRows]="selectedRows"
  ></app-grid-footer>
</ng-template>
