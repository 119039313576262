import { Component, Input, OnInit } from '@angular/core';
import { FnUiOption } from 'projects/gpp/src/app/shared/fn-ui-select/fn-ui-select.component';
import { ConditionOperator, OperandDataType, SimpleCondition } from '../../models';
import { AllowedTypesMap, InnerConditionsAllowedTypesMap, OperatorsMap } from '../../models/consts/rule-config.const';
import { RuleConditionsActionsService } from '../../services/rule-conditions-actions.service';
import { SimpleConditionLogicService } from '../../services/simple-condition-logic.service';

@Component({
  selector: 'app-inner-conditions',
  templateUrl: './inner-conditions.component.html',
  styleUrls: ['./inner-conditions.component.scss']
})
export class InnerConditionsComponent implements OnInit {

  @Input() conditions: Array<SimpleCondition>;
  @Input() selectedOperator;
  @Input() operandType: OperandDataType;

  lastConditionOperators: Array<FnUiOption>;
  conditionsOperators: Array<FnUiOption>;
  lastConditionAllowedDataTypes: Array<string>;
  allowedDataTypes: Array<string>;

  constructor(private ruleConditionsActionsService: RuleConditionsActionsService,
              private simpleConditionLogicService: SimpleConditionLogicService) { }

  ngOnInit(): void {
    this.lastConditionOperators = this.getLastConditionOperators(this.selectedOperator);
    this.conditionsOperators = this.getConditionsOperators(this.selectedOperator);
    this.lastConditionAllowedDataTypes = this.getLastConditionAllowedDataTypes(this.operandType);
    this.allowedDataTypes = this.getAllowedDataTypes(this.selectedOperator);
  }

  onMoveDown(condition: SimpleCondition): void {
    const conditionIdx = this.getConditionPlace.call(this, condition);
    if (conditionIdx === this.conditions.length - 1) {
      return;
    }

    this.conditions.splice(conditionIdx + 1, 0, this.conditions.splice(conditionIdx, 1)[0]);
  }

  onMoveUp(condition: SimpleCondition): void {
    const conditionIdx = this.getConditionPlace.call(this, condition);
    if (conditionIdx === 0) {
      return;
    }

    this.conditions.splice(conditionIdx - 1, 0, this.conditions.splice(conditionIdx, 1)[0]);
  }

  onRemove(condition: SimpleCondition): void {
    const conditionIdx = this.getConditionPlace.call(this, condition);
    this.conditions.splice(conditionIdx, 1);
  }

  onAdd(condition: SimpleCondition): void {
    const conditionIdx = this.getConditionPlace.call(this, condition);
    if (this.conditions.length === 1) { //in case of 1 condition which is the closing condition we add new condition above it
      this.conditions.unshift(JSON.parse(JSON.stringify(this.ruleConditionsActionsService.emptyCondition)));
    } else {
      this.conditions.splice(conditionIdx + 1, 0, JSON.parse(JSON.stringify(this.ruleConditionsActionsService.emptyCondition)));
    }
  }

  private getConditionPlace(condition: SimpleCondition): number {
    for (let i = 0; i < this.conditions.length; i++) {
      if (this.conditions[i] === condition) {
        return i;
      }
    }
  }

  private getOperators(operator: ConditionOperator): Array<FnUiOption> {
    let operators;
    switch (operator.toLowerCase()) {
      case 'concatenate':
        operators = OperatorsMap.CONCATENATE_OPERATORS;
        break;
      case '*':
      case '/':
      case '+':
      case '-':
        operators = OperatorsMap.MATHEMATICAL_OPERATORS;
        break;
      default:
        console.error('Operator ' + operator + ' is invalid');
        break;
    }

    return this.simpleConditionLogicService.mapOutOperators(operators);
  }

  private getLastConditionOperators(operator: ConditionOperator): Array<FnUiOption> {
    return this.getOperators(operator).filter(this.filterOperatorsForLastCondition);
  }

  private getConditionsOperators(operator: ConditionOperator): Array<FnUiOption> {
    return this.getOperators(operator).filter(this.filterOperatorsForConditions);
  }

  private getLastConditionAllowedDataTypes(type: OperandDataType): Array<string> {
    return AllowedTypesMap[type.toUpperCase()];
  }

  private getAllowedDataTypes(operator: ConditionOperator): Array<string> {
    return InnerConditionsAllowedTypesMap[operator.toLowerCase()];
  }

  private filterOperatorsForLastCondition(operator: FnUiOption): boolean {
    return OperatorsMap.INNER_CONDITIONS_OPERATORS.indexOf(operator.value.toLowerCase()) === -1;
  }

  private filterOperatorsForConditions(operator: FnUiOption): boolean {
    return OperatorsMap.INNER_CONDITIONS_OPERATORS.indexOf(operator.value.toLowerCase()) > -1;
  }

}
