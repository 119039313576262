import { Router, UrlSegment } from '@angular/router';

export class RouterHelper {

  static getSegments(router: Router): UrlSegment[] {
    const tree = router.parseUrl(router.url);
    return tree.root.children.primary.segments;
  }

  static getSegmentsFromUrl(router: Router, url: string): UrlSegment[] {
    const tree = router.parseUrl(url);
    return tree.root.children.primary.segments;
  }
}
