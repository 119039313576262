import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { GppTreeComponent, TreeConfig } from '@fgpp-ui/components';
import { NavigationNode } from './models/navigation-node.model';

@Component({
  selector: 'app-fn-ui-navigation-tree',
  templateUrl: './fn-ui-navigation-tree.component.html',
  styleUrls: ['./fn-ui-navigation-tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FnUiNavigationTreeComponent<T extends NavigationNode> implements OnInit, OnDestroy {

  @Input() treeConfig: Partial<TreeConfig>;
  @Input() treeModel: Array<T>;
  @Input() selectedNode: T;

  @Output() selectionChanged = new EventEmitter<T>();

  @ContentChild('node', { static: true }) nodeTemplate?: TemplateRef<ElementRef>;
  @ContentChild('leaf', { static: true }) leafTemplate?: TemplateRef<ElementRef>;

  @ViewChild('defaultNode', { static: true }) defaultNodeTemplate: TemplateRef<ElementRef>;
  @ViewChild(GppTreeComponent, { static: true }) treeComponent: GppTreeComponent<T>;

  ngOnInit(): void {
    if (!this.nodeTemplate) {
      this.nodeTemplate = this.defaultNodeTemplate;
    }
    if (!this.leafTemplate) {
      this.leafTemplate = this.nodeTemplate;
    }
  }

  onSelectionChange($event: T): void {
    this.selectionChanged.emit($event);
  }

  onNodeEnterClick($event: KeyboardEvent): void {
    const aElement = $event.target as HTMLAnchorElement;
    if (!aElement.href) {
      aElement.click();
    }
  }

  ngOnDestroy(): void {
    this.selectionChanged.unsubscribe();
  }

}
