<app-fn-ui-dialog class="create-payment">
  <ng-template #header>
    <div class="create-payment-header">
      <span class="title">{{ 'modals.create_payment.title' | translate }}</span>
      <app-fn-ui-close-icon mat-dialog-close class="close"></app-fn-ui-close-icon>
    </div>
  </ng-template>

  <ng-template #body>
    <div class="create-payment-body">
      <div *ngIf="officeIsVisible">
        <div class="sub-header">{{'modals.create_payment.office_title' | translate}}</div>
        <mat-select [hideSingleSelectionIndicator]="true" [panelClass]="'select-dropdown'" disableOptionCentering id="create-payment-select-office" [(ngModel)]="office"
          (selectionChange)="officeChanged()">
          <mat-option *ngFor="let officeObj of offices" [value]="officeObj">
            <div>{{officeObj.name + ' - ' + officeObj.alias}} </div>
          </mat-option>
        </mat-select>
      </div>

      <div class="sub-header">{{'modals.create_payment.payment_type_title' | translate}}</div>
      <mat-select [panelClass]="'select-dropdown'"disableOptionCentering id="create-payment-select-type" [(ngModel)]="paymentType" [hideSingleSelectionIndicator]="true"
        (selectionChange)="paymentTypeChanged()">
        <mat-option *ngFor="let paymentTypeObj of paymentTypes" [value]="paymentTypeObj">
          <div>{{paymentTypeObj.alias | translate}}</div>
        </mat-option>
      </mat-select>

      <div class="fx-checkbox-outer" [style.visibility]="showfxEnhancement ? 'visible' : 'hidden'">
        <span class="fx-wire-label">FX wire</span>
        <mat-slide-toggle class="fx-checkbox" id="fx-checkbox" [(ngModel)]="fxEnhancementCheckbox" [disabled]="fxEnhancementCheckboxMode" [color]="primary">
        </mat-slide-toggle>
      </div>
    </div>
  </ng-template>


  <ng-template #footer>
    <div class="create-payment-footer">

      <app-fn-ui-button color="primary" name="create-payment-close-modal" mat-dialog-close>
        {{ 'general.cancel' | translate }}
      </app-fn-ui-button>

      <app-fn-ui-button *ngIf="isCallerPaymentVisible" name="create-caller-payment" (click)="createCallerPayment()"
                        color="primary">
        {{ 'general.callerPayment' | translate }}
      </app-fn-ui-button>

      <app-fn-ui-button name="create-payment" type="flat" id="create-payment-submit-button" (click)="createPayment()"
                        [disabled]="!officeSelected || !paymentTypeSelected" color="primary">
        {{ 'general.ok' | translate }}
      </app-fn-ui-button>

    </div>
  </ng-template>
</app-fn-ui-dialog>
