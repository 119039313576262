import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-error-page',
  styleUrls: ['./error-page.component.scss'],
  templateUrl: './error-page.component.html'
})
export class ErrorPageComponent {

  @Input() errorMessage;
  @Input() errorCode;

  @Output()
  buttonClicked: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  constructor() {
  }

  public onButtonClicked(e: MouseEvent): void {
    this.buttonClicked.emit(e);
  }
}
