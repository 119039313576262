import { Injectable } from '@angular/core';
import CryptoJS from 'crypto-js';
@Injectable({
  providedIn: 'root'
})
export class SecurityService {
  private STATE_KEY = 'gpp-state';
  private CODE_VERIFIER_KEY = 'gpp-code';

  constructor() { }

  public getCodeChallenge(): string {
    const code_verifier = this.generateRandomString(128);
    this.saveCodeVerifierToLocalStorage(code_verifier);
    return this.base64URL(CryptoJS.SHA256(code_verifier));
  }

  private saveCodeVerifierToLocalStorage(code_verifier) {
    sessionStorage.setItem(this.CODE_VERIFIER_KEY, code_verifier);
  }

  public getCodeVerifierFromLocalStorage() {
    return sessionStorage.getItem(this.CODE_VERIFIER_KEY);
  }

  public getState() {
    const state = this.generateRandomString(16);
    this.saveStateToLocalStorage(state);
    return state;
  }

  private generateRandomString(length: number): string {
    let result = '';
    const stringLength = length;
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
    for (let i = 0; i < stringLength; i++) {
      result += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return result;
  }

   base64URL(string) {
    return string.toString(CryptoJS.enc.Base64).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
  }

  private saveStateToLocalStorage(state) {
    sessionStorage.setItem(this.STATE_KEY, state);
  }

  public isMatchState(receivedState): boolean {
    const state = this.getStateFromLocalStorage();
    return receivedState === state;
  }

  public getStateFromLocalStorage() {
    return sessionStorage.getItem(this.STATE_KEY);
  }

  public clearLocalStorage() {
    sessionStorage.removeItem(this.CODE_VERIFIER_KEY);
    sessionStorage.removeItem(this.STATE_KEY);
  }

  public encodeBase64Url(text): string {
    const utf8 = CryptoJS.enc.Utf8.parse(text);
    return CryptoJS.enc.Base64.stringify(utf8);
  }
}
