export const PROFILES_SCOPES = [{
  alias: 'system_navigation.INNERTAB_ACTIVE',
  position: 0,
  selectType: 1,
  loadType: 2
}, {
  alias: 'system_navigation.INNERTAB_INACTIVE',
  position: 1,
  selectType: 2,
  loadType: 6
}, {
  alias: 'system_navigation.INNERTAB_ALL',
  position: 2,
  selectType: 0,
  loadType: 2
}, {
  alias: 'system_navigation.INNERTAB_APPROVALS',
  position: 3,
  selectType: 3,
  loadType: 3
}];
