import { Injectable } from '@angular/core';
import { NotificationService } from '@fgpp-ui/components';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../../authentication/services/authentication.service';
import { FormatsService } from '../../shared/services/formats.service';
import { MultiTabsManagerService } from '../multi-tabs/services/multi-tabs-manager.service';
import { UserSettingsService } from '../user-settings/services/user-settings.service';
import { IHomeService } from './interfaces/home-service.interface';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
dayjs.extend(utc);

@Injectable()
export class HomeService implements IHomeService {

  constructor(private notificationService: NotificationService,
              private translateService: TranslateService,
              private authenticationService: AuthenticationService,
              private multiTabsManagerService: MultiTabsManagerService,
              private userSettingsService: UserSettingsService,
              private formatsService: FormatsService) { }

  showWelcomeNotification(): void {
    if (this.multiTabsManagerService.hasActiveTabs) {
      return;
    }
    const message = this.getWelcomeMessage();
    this.notificationService.success(message);
  }

  private getWelcomeMessage(): string {
    const userId = this.authenticationService.userData.userId;
    const lastLogin = this.getUserLastLogin();

    let translateKey, translateParams;
    if (lastLogin) {
      translateKey = 'authentication.last_login_message';
      translateParams = { lastLogin, userId };
    } else {
      translateKey = 'authentication.login_welcome_message';
      translateParams = { userId };
    }
    return this.translateService.instant(translateKey, translateParams);
  }

  private getUserLastLogin(): string {
    const lastLogin = this.authenticationService.userData.lastLogin;
    if (!lastLogin) {
      return null;
    }
    const lastLoginServerFormat = 'YYYY-MM-DD HH:mm:ss';
    const serverTimeZoneOffset = this.userSettingsService.getPreferences().serverTimeZoneOffset;
    const timestamp = serverTimeZoneOffset === undefined ?
      dayjs(lastLogin, lastLoginServerFormat).valueOf() :
      dayjs.utc(lastLogin, lastLoginServerFormat).valueOf() - serverTimeZoneOffset;
    return this.formatsService.toDateTimeFormat(dayjs(timestamp));
  }

}
