import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, HostListener } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '@fgpp-ui/components';
import { Store } from '@ngxs/store';
import { UserData } from '../../models/user-data.model';
import { AuthenticationApiService } from '../../services/authentication-api.service';
import { SystemVariablesService } from '../../../core/services/system-variables/system-variables.service';
import { CreateSession } from '../../../core/store/session/action/session.action';

@Component({
  selector: 'app-token-two-auth',
  templateUrl: './token-two-auth.component.html',
  styleUrls: ['./token-two-auth.component.scss']
})
export class TokenTwoAuthComponent implements OnInit {
  form: FormGroup;
  ldapDomains: string;
  authenticationInProgress = false;
  isChallengeExist = true;
  credentials = { userId: null, TokenPassword: null, domain: null, tokenType: null, challengeCode: null, userPass: null };
  capsLock = false;
  challengCode: string;
  userId: string;
  userPass: string;
  tokenType: string;
  goBack = false;

  private _error: string;

  constructor(private formBuilder: FormBuilder,
              private store: Store,
              private route: ActivatedRoute,
              private router: Router,
              private notificationService: NotificationService,
              private authenticationApiService: AuthenticationApiService,
              private systemVariablesService: SystemVariablesService) {
    this.userId = this.route.snapshot.paramMap.get('userId');
    this.challengCode = this.route.snapshot.paramMap.get('messageCode');
    this.tokenType = this.route.snapshot.paramMap.get('message');
    this.userPass = this.router.getCurrentNavigation().extras?.state?.userPassWord || null;
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      'password': ['', Validators.required]
    });

    this.ldapDomains = this.systemVariablesService.getSingleVariable('ldapDomains');

    if (this.userId !== undefined) {
      this.credentials.userId = this.userId;
    }

    if (this.challengCode === 'RO') {
      this.isChallengeExist = false;
    }
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDownShowCapLock(event): void {
    this.capsLock = event.getModifierState && event.getModifierState('CapsLock');
  }

  onBackClick(): void {
    this.goBack = true;
  }

  onFormSubmit(): void {
    this._error = null;
    this.credentials.TokenPassword = this.form.value.password;
    this.credentials.userId = this.userId;
    this.credentials.userPass = this.userPass;
    this.credentials.tokenType = this.tokenType;
    this.credentials.challengeCode = this.challengCode;
    if (this.goBack === true) {
      this.router.navigateByUrl('signout');
    } else {
      this.authenticationInProgress = true;
      this.authenticationApiService.signInTokenAuth(this.credentials).subscribe({
        next: (response: UserData) => {
          this.store.dispatch(new CreateSession({ userData: response, token: null }));
          this.router.navigate(['home']);
        },
        error: (response: HttpErrorResponse) => {
          this._error = response.error?.type || 'An unexpected error has occurred';
          if (this._error === 'PasswordExpiredException') {
            this.router.navigate([`user/changePassword/${this.credentials.userId}/${response.error.type}`]);
          } else {
            this.notificationService.error(this._error);
            this.router.navigateByUrl('user/signin');
          }
        },
        complete: () => {
          this.credentials.TokenPassword = '';
          this.authenticationInProgress = false;
        }
      });
    }
  }

}
