import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { MainTab, NavigationItem, NavigationMap, NavigationTab } from '../../../navigation/models';
import { NavigationService } from '../../../navigation/services/navigation.service';
import { NavigationResultGroup, NavigationResultItem } from '../../models';
import { NavigationSearchConfigService } from '../navigation-search-config.service';
import { ISearchResourceService } from './search-resource-service.intreface';

@Injectable()
export class SitemapSearchService implements ISearchResourceService {

  constructor(private navigationService: NavigationService,
              private configService: NavigationSearchConfigService,
              private translateService: TranslateService) { }

  search(term: string): Observable<Array<NavigationResultGroup>> {
    return new Observable((observer) => {
      const navigationMap = this.navigationService.navigationMap;
      const groups = this.filter(navigationMap, term);
      observer.next(groups);
    });
  }

  isSearchable(mainTab: MainTab): boolean {
    return this.configService.navigationSearchTabs.indexOf(mainTab) !== -1;
  }

  isMatch(item: NavigationItem, searchTerm: string): boolean {
    searchTerm = searchTerm.trim().toLowerCase();
    const isAliasMatch = this.translateService.instant(item.alias).trim().toLowerCase().includes(searchTerm);
    if (isAliasMatch) {
      return true;
    }
    const isPathMatch = item.path.slice(1).some((part: string) => this.translateService.instant(part).trim().toLowerCase().includes(searchTerm));
    return isPathMatch;
  }

  private filter(navigationMap: NavigationMap, searchTerm: string): Array<NavigationResultGroup> {
    const groups = [];
    navigationMap.forEach((navigationTab: NavigationTab) => {
      const group = this.createGroup(navigationTab, searchTerm);
      if (group !== null) {
        groups.push(group);
      }
    });
    return groups;
  }

  private createGroup(navigationTab: NavigationTab, searchTerm: string): NavigationResultGroup {
    if (!this.isSearchable(navigationTab.id)) {
      return null;
    }
    const items = [];
    navigationTab.items.forEach((item: NavigationItem) => {
      if (this.isMatch(item, searchTerm)) {
        items.push(this.createItem(item));
      }
    });
    if (!items.length) {
      return null;
    }
    return {
      id: navigationTab.id,
      alias: this.translateService.instant(navigationTab.alias),
      items: items
    };
  }

  private createItem(item: NavigationItem): NavigationResultItem {
    return {
      id: item.id,
      alias: this.translateService.instant(item.alias),
      icon: item.icon,
      path: item.path.map((part: string) => this.translateService.instant(part)),
      routerLink: item.routerLink,
      stateName: item.stateName,
      stateParams: item.stateParams
    };
  }

}
