import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { ActivityLogModule } from './components/activity-log/activity-log.module';
import { UiComponentsModule } from '../ui-components/ui-components.module';
import { DynamicComponentLoaderModule } from '../ui-components/dynamic-component-loader/dynamic-component-loader.module';
import { FnUiDialogModule } from '../shared/fn-ui-dialog/fn-ui-dialog.module';
import { FnUiIconModule } from '../shared/fn-ui-icons/fn-ui-icon/fn-ui-icon.module';

import { FormsService } from './services/forms.service';

import { ActionResultContentComponent } from './components/action-result-content/action-result-content.component';
import { CountdownTimerComponent } from './components/countdown-timer/countdown-timer.component';
import { DynamicFormLoaderComponent } from './components/dynamic-form-loader/dynamic-form-loader.component';
import { ExitAndDiscardModalComponent } from './components/exit-and-discard-modal/exit-and-discard-modal.component';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    TranslateModule.forChild(),
    ActivityLogModule,
    UiComponentsModule,
    DynamicComponentLoaderModule,
    FnUiDialogModule,
    FnUiIconModule
  ],
  declarations: [
    ActionResultContentComponent,
    CountdownTimerComponent,
    DynamicFormLoaderComponent,
    ExitAndDiscardModalComponent
  ],
  exports: [
    CountdownTimerComponent,
    DynamicFormLoaderComponent,
    ExitAndDiscardModalComponent
  ]
})
export class FaFormsModule { }
