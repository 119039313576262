import { Component, inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router, RouterStateSnapshot } from '@angular/router';
import { TaskbarItemConstants } from '../base-view/config/taskbar-item-constants';
import { ROUTES_MAPPING_RULES } from '../core/navigation/models/consts/routes-mapping.const';

export const RuleTaskBarComponentResolver: ResolveFn<Component> =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Component => {

    const router = inject(Router);

    const parent = route.parent.routeConfig.path;
    const taskBarItem = route.params.taskBarItem;
    let component;
    component = TaskbarItemConstants.taskBarItemCodeToComponent[parent][ROUTES_MAPPING_RULES];
    if (!component) {
      const error = 'no form component found, component code = ' + [parent][taskBarItem];
      console.log(error);
      router.navigate(['/home/page-not-found'], { replaceUrl: true });
      return;
    }
    return component;
  };
