import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NotificationService } from '@fgpp-ui/components';
import { TranslateService } from '@ngx-translate/core';
import { TaskBarService } from 'projects/gpp/src/app/core/navigation/task-bar/services/task-bar.service';
import { FnUiMenuItemClickEvent } from 'projects/gpp/src/app/shared/fn-ui-menu/models/fn-ui-menu-item-click-event.model';
import { FnUiMenuItem } from 'projects/gpp/src/app/shared/fn-ui-menu/models/fn-ui-menu-item.model';
import { ModalsService } from 'projects/gpp/src/app/shared/fn-ui-modals/services/modals.service';
import { Subscription } from 'rxjs';
import { BaseConditionUsedProfilesResponse, Rule, RuleMetadata, RuleStatuses, SearchBaseConditionResponse } from '../../models';
import { RuleCommonService } from '../../services/rule-common.service';
import { RuleConditionsAlgorithmsService } from '../../services/rule-conditions-algorithms.service';
import { RuleModalsService } from '../../services/rule-modals.service';

export const rulesContentDivElement = '.rule-content-upper-section';
export const invalidControlsQuery = 'input.ng-invalid:enabled, mat-select.ng-invalid:not(.mat-select-disabled)';
export const requiredInputQuery = 'input[required], input[required=required], mat-select[required]';

@Component({
  selector: 'app-rule-footer',
  templateUrl: './rule-footer.component.html',
  styleUrls: ['./rule-footer.component.scss']
})
export class RuleFooterComponent implements OnInit, OnDestroy {

  @Input() metadata: RuleMetadata;
  @Input() rule: Rule;
  @Input() ruleStatuses: RuleStatuses;

  requiredInputInvalidElm = [];

  isCreate: boolean;
  showSaveButton: boolean;
  showHoldButton: boolean;
  showRetractButton: boolean;
  showDeleteButton: boolean;
  showAuditTrailButton: boolean;
  showDocumentationButton: boolean;
  showDownloadButton: boolean;
  showActivateButton: boolean;
  showApproveButton: boolean;
  showDeclineButton: boolean;
  showRevertButton: boolean;
  showMainCloseButton: boolean;

  actions: Array<FnUiMenuItem> = [];

  private _subscriber = new Subscription();

  constructor(private notificationService: NotificationService,
              private translateService: TranslateService,
              private taskBarService: TaskBarService,
              private modalsService: ModalsService,
              private ruleCommonService: RuleCommonService,
              private ruleConditionsAlgorithmsService: RuleConditionsAlgorithmsService,
              private ruleModalsService: RuleModalsService) { }

  ngOnInit(): void {
    this._subscriber.add(this.taskBarService.taskBarStateChange.subscribe(this.onTaskBarStateChange.bind(this)));
    this.isCreate = this.ruleStatuses.isCreate;
    this.updateActionButtonsVisibility();
    this.initializeRuleActionsMenu();
  }

  ngOnDestroy(): void {
    this._subscriber.unsubscribe();
  }

  toggleConditionBank(): void {
    this.ruleCommonService.toggleConditionsBank();
    if (this.taskBarService.taskBarState.showSideBar) {
      this.taskBarService.taskBarShowState = false;
    }
  }

  togglePreviewRulePane(): void {
    this.ruleCommonService.togglePreviewRulePane();
  }

  onMenuItemClick($event: FnUiMenuItemClickEvent): void {
    const action = $event.item.action as Function;
    if (action) {
      action();
    }
  }

  toggleAuditTrail(): void {
    this.ruleCommonService.toggleAuditTrail();
  }

  documentation(): void {
    this.modalsService.promptWithNote({
      title: 'rule.documentation.confirmation.title',
      message: this.rule.DOCUMENTATION,
      isNoteMandatory: true,
      isNoteEditable: this.showSaveButton
    }).afterClosed().subscribe((message: string) => this.saveDocumentation(message));
  }

  deleteRule(): void {
    if (this.rule.BASE_CONDITION_IND == '1' && (JSON.stringify(this.rule.BC_RESTRICTED_LOGICAL_FIELDS || null) !== '{}')) {
      const texts = {
        title: 'rules.delete_base_condition.title',
        notification: 'rules.delete_base_condition.notification'
      };
      this.ruleModalsService.openDeleteBaseConditionModal(this.rule.RULE_NAME, this.rule.BC_RESTRICTED_LOGICAL_FIELDS, texts);
    } else {
      this.modalsService.promptWithNote({
        title: 'rule.delete.confirmation.title',
        aboveNoteText: 'rule.delete.confirmation.message',
        okButton: 'rule.delete.confirmation.ok_button',
        cancelButton: 'rule.delete.confirmation.cancel_button',
        isNoteMandatory: true
      }).afterClosed().subscribe((note: string) => this.doDelete(note));
    }
  }

  holdRule(): void {
    if (this.rule.BASE_CONDITION_IND == '1' && (JSON.stringify(this.rule.BC_RESTRICTED_LOGICAL_FIELDS || null) !== '{}')) {
      const texts = {
        title: 'rules.hold_base_condition.title',
        notification: 'rules.hold_base_condition.notification'
      };
      this.ruleModalsService.openDeleteBaseConditionModal(this.rule.RULE_NAME, this.rule.BC_RESTRICTED_LOGICAL_FIELDS, texts);
    } else {
      this.modalsService.promptWithNote({
        title: 'rule.hold.confirmation.title',
        aboveNoteText: 'rule.hold.confirmation.message',
        okButton: 'rule.hold.confirmation.ok_button',
        cancelButton: 'rule.delete.confirmation.cancel_button',
        isNoteMandatory: true
      }).afterClosed().subscribe((note: string) => this.doHold(note));
    }
  }

  retractRule(): void {
    this.ruleCommonService.retractRule(this.rule);
  }

  downloadRule(): void {
    this.ruleCommonService.downloadRule(this.rule, this.metadata);
  }

  closeRule(): void {
    if (this.isCreate) {
      this.ruleCommonService.isNeedCheckBeforeClose = true;
    }
    this.ruleCommonService.closeRule();
  }

  saveRule(): void {
    let requiredCounter = 0;
    const requiredInputInvalidElm = [];
    const ruleContentDiv = document.querySelector(rulesContentDivElement);
    const invalidCtrls = ruleContentDiv.querySelectorAll(invalidControlsQuery);
    const requiredCtrls = ruleContentDiv.querySelectorAll(requiredInputQuery);
    for (let index = 0; index < requiredCtrls.length; index++) {
      const control = requiredCtrls[index] as HTMLInputElement;
      const value = control.classList.contains('mat-select') ? control.textContent : control.value;
      if (value?.toString().trim().length === 0) {
        this.markAsTouched(control);
        requiredInputInvalidElm.push(requiredCtrls[index]);
        requiredCounter++;
      }
    }
    if (requiredInputInvalidElm.length > 0) {
      this.callEventOnrequiredInputInvalidElm(requiredInputInvalidElm);
    }
    if (invalidCtrls.length > 0 || requiredCounter > 0) {
      this.notificationService.error(this.translateService.instant('rule.save_rule.missing_data_message'));
      return;
    }

    if (this.ruleCommonService.operatorsChangedOnInit > 0) {
      this.modalsService.confirm({
        title: 'rule.save.operators_changed_on_init.confirmation.title',
        message: this.translateService.instant('rule.save.operators_changed_on_init.confirmation.message'),
        okButton: 'rule.save.operators_changed_on_init.confirmation.ok_button',
        cancelButton: 'rule.save.operators_changed_on_init.confirmation.cancel_button'
      }).afterClosed().subscribe({
        next: (reason: string) => this.responseByReason(reason),
        error: () => { }
      });
    } else {
      this.doSaveRule();
    }
  }

  approveRule(): void {
    this.ruleCommonService.approveRule(this.rule);
  }

  declineRule(): void {
    this.modalsService.promptWithNote({
      title: 'rule.decline_rule.title',
      aboveNoteText: 'rule.decline_rule.text_above_prompt',
      message: '',
      okButton: 'rule.decline_rule.ok_button',
      cancelButton: 'rule.decline_rule.cancel_button',
      isNoteMandatory: true
    }).afterClosed().subscribe((note: string) => this.doDecline(note));
  }

  revertRule(): void {
    this.modalsService.promptWithNote({
      title: 'rule.revert_rule.title',
      aboveNoteText: 'rule.revert_rule.text_above_prompt',
      message: '',
      okButton: 'rule.revert_rule.ok_button',
      cancelButton: 'rule.revert_rule.cancel_button',
      isNoteMandatory: true
    }).afterClosed().subscribe((note: string) => this.doRevert(note));
  }

  activateRule(): void {
    const baseConditionData = this.ruleCommonService.getBaseConditionData();
    baseConditionData.then((data: SearchBaseConditionResponse) => {
      const ruleProfileList = data.contentHolder.ruleprofileList[1];
      if (ruleProfileList.length > 0) {
        this.modalsService.confirm({
          title: 'rule.activate.confirmation.title',
          message: this.translateService.instant('rule.activate.basecondition.confirmation.message'),
          okButton: 'rule.activate.basecondition.confirmation.ok_button',
          cancelButton: 'rule.activate.basecondition.confirmation.cancel_button'
        }).afterClosed().subscribe({ next: (note: string) => this.doActivate(note), error: () => { } });
      } else {
        this.modalsService.promptWithNote({
          title: 'rule.activate.confirmation.title',
          aboveNoteText: 'rule.activate.confirmation.message',
          okButton: 'rule.activate.confirmation.ok_button',
          cancelButton: 'rule.activate.confirmation.cancel_button',
          isNoteMandatory: true
        }).afterClosed().subscribe((note: string) => this.doActivate(note));
      }
    }, () => { });
  }

  private onTaskBarStateChange(state: boolean): void {
    if (state && this.ruleCommonService.show.conditionsBank) {
      this.ruleCommonService.toggleConditionsBank();
    }
  }

  private updateActionButtonsVisibility(): void {
    this.showSaveButton =
      (this.ruleStatuses.isWritable && !this.ruleStatuses.isReadOnly) &&
      (this.ruleStatuses.isOpenedFromApprovalScreen && this.ruleStatuses.isEditable || !this.ruleStatuses.isOpenedFromApprovalScreen) &&
      ((this.ruleStatuses.isStatusNew &&
        (this.ruleStatuses.isChangeStatusNO ||
          this.ruleStatuses.isChangeStatusFU ||
          (this.ruleStatuses.isChangeStatusPN && (this.ruleStatuses.isInitiator || this.ruleStatuses.isCreate))
        )) ||
        (this.ruleStatuses.isStatusActive &&
          (this.ruleStatuses.isChangeStatusNO ||
            this.ruleStatuses.isChangeStatusFU ||
            (this.ruleStatuses.isChangeStatusPN && (this.ruleStatuses.isInitiator || this.ruleStatuses.isCreate))
          )));

    this.showHoldButton =
      (this.ruleStatuses.isWritable && this.ruleStatuses.isEditMode && !this.ruleStatuses.isReadOnly) &&
      (this.ruleStatuses.isOpenedFromApprovalScreen && this.ruleStatuses.isEditable || !this.ruleStatuses.isOpenedFromApprovalScreen) &&
      (this.ruleStatuses.isStatusActive && this.ruleStatuses.isChangeStatusNO);

    this.showRetractButton =
      this.ruleStatuses.isChangeStatusFU && this.ruleStatuses.isOpenedFromInactiveScreen && !this.ruleStatuses.isReadOnly &&
      this.ruleStatuses.isWritable && this.ruleStatuses.isEditMode;

    this.showDeleteButton =
      (this.ruleStatuses.isWritable && !this.ruleStatuses.isReadOnly) &&
      (this.ruleStatuses.isOpenedFromApprovalScreen && this.ruleStatuses.isEditable || !this.ruleStatuses.isOpenedFromApprovalScreen) &&
      ((this.ruleStatuses.isStatusActive && this.ruleStatuses.isChangeStatusNO) ||
        (this.ruleStatuses.isStatusHold && this.ruleStatuses.isChangeStatusNO));

    this.showAuditTrailButton =
      (this.ruleStatuses.isEditMode || this.ruleStatuses.isCreate) &&
      (false === (this.ruleStatuses.isStatusHold && this.ruleStatuses.isChangeStatusFU)) &&
      (false === (this.ruleStatuses.isStatusDeleted && this.ruleStatuses.isChangeStatusFU)) &&
      (false === (this.ruleStatuses.isStatusNew && this.ruleStatuses.isChangeStatusNO)) &&
      (false === (this.ruleStatuses.isStatusNew && this.ruleStatuses.isCreate));

    this.showDocumentationButton =
      (this.ruleStatuses.isWritable && (this.ruleStatuses.isEditMode || this.ruleStatuses.isCreate)) &&
      (false === (this.ruleStatuses.isStatusHold && this.ruleStatuses.isChangeStatusFU)) &&
      (false === (this.ruleStatuses.isStatusDeleted && this.ruleStatuses.isChangeStatusFU));

    this.showDownloadButton =
      (this.ruleStatuses.isWritable && (this.ruleStatuses.isEditMode || this.ruleStatuses.isCreate)) &&
      (false === (this.ruleStatuses.isStatusHold && this.ruleStatuses.isChangeStatusFU)) &&
      (false === (this.ruleStatuses.isStatusDeleted && this.ruleStatuses.isChangeStatusFU));

    this.showActivateButton =
      (this.ruleStatuses.isWritable && this.ruleStatuses.isEditMode && !this.ruleStatuses.isReadOnly) &&
      (this.ruleStatuses.isOpenedFromApprovalScreen && this.ruleStatuses.isEditable || !this.ruleStatuses.isOpenedFromApprovalScreen) &&
      ((this.ruleStatuses.isStatusDeleted && this.ruleStatuses.isChangeStatusNO) ||
        (this.ruleStatuses.isStatusHold && this.ruleStatuses.isChangeStatusNO));

    this.showApproveButton =
      (this.ruleStatuses.isWritable && !this.ruleStatuses.isReadOnly) &&
      (false === this.ruleStatuses.isCreate) &&
      (this.ruleStatuses.isChangeStatusPN) &&
      (this.ruleStatuses.isInitiator === false || (this.ruleStatuses.isInitiator && this.ruleStatuses.canUserApproveOwnRules &&
        (this.ruleStatuses.isOpenedFromApprovalScreen && this.ruleStatuses.isEditable)));

    this.showDeclineButton = (this.ruleStatuses.isWritable && !this.ruleStatuses.isReadOnly) && (this.showApproveButton) && !this.ruleStatuses.isInitiator;

    this.showRevertButton = (this.ruleStatuses.isWritable && !this.ruleStatuses.isReadOnly) && (this.showApproveButton) && this.ruleStatuses.isInitiator;

    this.showMainCloseButton =
      (false === (this.ruleStatuses.isStatusHold && this.ruleStatuses.isChangeStatusFU)) &&
      (false === (this.ruleStatuses.isStatusDeleted && this.ruleStatuses.isChangeStatusFU));
  }

  private initializeRuleActionsMenu(): void {
    if (this.showAuditTrailButton) {
      this.actions.push({
        name: 'rule-audit-trail',
        alias: 'system_navigation.entity.audit_trail',
        action: this.toggleAuditTrail.bind(this),
        attributes: { 'azure-id': 'toggle-audit-trail' }
      });
    }
    if (this.showDocumentationButton) {
      this.actions.push({
        name: 'rule-documentation',
        alias: 'rules.footer.documentation',
        action: this.documentation.bind(this),
        attributes: { 'azure-id': 'documentation' }
      });
    }
    if (this.showDeleteButton) {
      this.actions.push({
        name: 'delete-rule',
        alias: 'rules.footer.delete',
        action: this.deleteRule.bind(this),
        attributes: { 'azure-id': 'delete-rule' }
      });
    }
    if (this.showHoldButton) {
      this.actions.push({
        name: 'hold-rule',
        alias: 'rules.footer.hold',
        action: this.holdRule.bind(this),
        attributes: { 'azure-id': 'hold-rule' }
      });
    }
    if (this.showRetractButton) {
      this.actions.push({
        name: 'retract-rule',
        alias: 'rules.footer.retract',
        action: this.retractRule.bind(this),
        attributes: { 'azure-id': 'retract-rule' }
      });
    }
    if (this.showDownloadButton) {
      this.actions.push({
        name: 'download-rule',
        alias: 'rules.footer.download',
        action: this.downloadRule.bind(this),
        attributes: { 'azure-id': 'download' }
      });
    }
  }

  private markAsTouched(control: HTMLElement): void {
    control.classList.add('ng-touched');
    control.classList.remove('ng-untouched');
    control.classList.remove('ng-pristine');

    const isMaterialControl = control.classList.contains('mat-input-element') || control.classList.contains('mat-select')
    if (isMaterialControl) {
      console.log(control);
      let matFormField: HTMLElement = control;
      while (!matFormField.classList.contains('mat-form-field')) {
        matFormField = matFormField.parentElement;
      }
      matFormField.classList.add('ng-touched');
      matFormField.classList.remove('ng-untouched');
      matFormField.classList.remove('ng-pristine');
    }
  }

  private saveDocumentation(message: string): void {
    if (message != null) {
      this.rule.DOCUMENTATION = message;
    }
  }

  private doDelete(note: string): void {
    if (note != null) {
      this.ruleCommonService.deleteRule(this.rule, note);
    }
  }

  private doHold(note: string): void {
    if (note != null) {
      this.ruleCommonService.holdRule(this.rule, note);
    }
  }

  private callEventOnrequiredInputInvalidElm(elmArray: Array<HTMLElement>): void {
    for (let index = 0; index < elmArray.length; index++) {
      const onFocus = () => {
        (elmArray[index] as any).style.boxShadow = '';
        elmArray[index].removeEventListener('focus', onFocus);
      };
      elmArray[index].addEventListener('focus', onFocus);
    }
  }

  private responseByReason(reason: string): void {
    if (reason === 'ok') {
      this.doSaveRule();
    }
  }

  private doSaveRule(): void {
    let baseConditionPendingRecords = [];
    const currentRule = this.ruleCommonService.sanitizeRule(this.rule);
    if (!this.ruleConditionsAlgorithmsService.allConditionsValid) {
      this.showInvalidConditions();
      this.ruleConditionsAlgorithmsService.allConditionsValid = true;
      this.ruleConditionsAlgorithmsService.invalidConditions = [];
      return;
    }

    if (!this.ruleCommonService.isRuleChanged()) {
      this.notificationService.error(this.translateService.instant('rule.save_rule.no_change_message'));
      return;
    }

    if (currentRule.RULE_TYPE_ID === '45') {
      const baseConditionData = this.ruleCommonService.getBaseConditionUsedProfiles();
      baseConditionData.then((data: BaseConditionUsedProfilesResponse) => {
        baseConditionPendingRecords = data.contentHolder.pendingRuleprofileList[0];
        if (baseConditionPendingRecords.length > 0) {
          let affectedRecords = '';
          let recordCount = 0;
          baseConditionPendingRecords.forEach((row) => {
            affectedRecords += row.RULE_NAME + '\r\n';
            recordCount++;
          });

          if (recordCount === 1) {
            this.modalsService.confirm({
              title: 'rule.save.base.condition.confirmation.title',
              message: this.translateService.instant('rule.save.basecondition.single.confirmation.message',
                { rulename: baseConditionPendingRecords[0].RULE_NAME }),
              okButton: 'rule.save.basecondition.confirmation.ok_button',
              cancelButton: 'rule.save.basecondition.confirmation.cancel_button'
            }).afterClosed().subscribe({ next: () => { }, error: () => { } });
          } else {
            this.modalsService.confirmWithCopy({
              title: 'rule.save.base.condition.confirmation.title',
              message: this.translateService.instant('rule.save.basecondition.multiple.confirmation.message'),
              okButton: 'rule.save.basecondition.confirmation.ok_button',
              cancelButton: 'rule.save.basecondition.confirmation.cancel_button',
              affectedList: affectedRecords,
              listName: 'rules',
              count: recordCount
            }).afterClosed().subscribe({ next: () => { }, error: () => { } });
          }

        } else {
          this.openEffectiveDateModal(currentRule);
        }
      }, () => { });
    } else {
      this.openEffectiveDateModal(currentRule);
    }
  }

  private showInvalidConditions(): void {
    this.modalsService.conditionsNotAllowed({
      message: this.ruleConditionsAlgorithmsService.invalidConditions,
      messageText: this.translateService.instant('modals.alertListMessage')
    });
  }

  private openEffectiveDateModal(currentRule: Rule): void {

    const effectiveDateForModal = this.rule.EFFECTIVE_DATE < this.rule.businessDate ? this.rule.businessDate : this.rule.EFFECTIVE_DATE;

    this.modalsService.effectiveDate({
      EFFECTIVE_DATE: effectiveDateForModal,
      isEffectiveDateEditable: !this.ruleStatuses.isCreate && !this.ruleStatuses.isChangeStatusFU && !this.ruleStatuses.isChangeStatusPN,
      maxDate: this.rule.EXPIRY_DATE,
      minDate: this.rule.businessDate,
      isNoteMandatory: !this.isCreate //when creating new rule the note is not mandatory
    }).afterClosed().subscribe((response) => this.doSave(response, currentRule));
  }

  private doSave(response, currentRule: Rule): void {
    if (!!response && !!response.payload) {
      const results = response.payload;
      currentRule.NOTE = results.NOTE;
      currentRule.EFFECTIVE_DATE = results.EFFECTIVE_DATE.toString();
      this.rule.NOTE = results.NOTE;
      this.rule.EFFECTIVE_DATE = results.EFFECTIVE_DATE.toString();
      this.ruleCommonService.isNeedCheckBeforeClose = false;
      this.isCreate ?
        this.ruleCommonService.createRule(currentRule) : this.ruleCommonService.saveRule(currentRule);
    }
  }

  private doDecline(note: string): void {
    if (note != null) {
      this.ruleCommonService.declineRule(this.rule, note);
    }
  }

  private doRevert(note: string): void {
    if (note != null) {
      this.ruleCommonService.declineRule(this.rule, note); //TODO the revert flow when revert is implemented on server side
    }
  }

  private doActivate(note: string): void {
    if (note != null) {
      this.ruleCommonService.activateRule(this.rule, note);
    }
  }

}
