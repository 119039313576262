import { Component, OnInit } from '@angular/core';
import { MultiLineComponent, SelectComponent } from '@fgpp-ui/components';
import { IEndpoint } from '../../../core/interfaces/endpoint.interface';
import { IValueChange } from '../../../core/interfaces/form-value-change.interface';
import { BFOnFormLoadComplete, BFOnValueChange } from '../../../core/interfaces/sdk-profile-hooks.interface';
import { ProfileFormTemplateProviders } from '../../../core/templates/profile-form.template.providers';
import { AbstractProfileFormComponent } from '../../abstract/abstract-profile/abstract-profile-form.component';
import { ProfileActions } from '../../enums/profile-actions.enum';
import { UniqueIdFormat } from '../../models/unique-id-format.model';
import { FieldFormatFormModalComponent } from './modals/field-format-form-modal/field-format-form-modal.component';
import { FormatFieldName } from './modals/field-format-form-modal/format-field-name.enum';

@Component({
  selector: 'app-unique-id-format',
  templateUrl: '../../../core/templates/profile-form.template.html',
  styleUrls: ['../../../core/templates/profile-form.template.scss'],
  providers: ProfileFormTemplateProviders
})
export class UniqueIdFormatComponent extends AbstractProfileFormComponent implements OnInit, BFOnFormLoadComplete, BFOnValueChange {

  fgppFormLoadComplete(): void {
    this.componentManager.getComponent<MultiLineComponent>(UniqueIdFormat.DETAILS).setModalComponent(FieldFormatFormModalComponent);
    this.formService.addRequired(UniqueIdFormat.DETAILS);
  }

  fgppValueChanges(change: IValueChange): void {
    if (change.control === UniqueIdFormat.FIELD_LOGICAL_ID) {
      this.setMaxLengthField(change.value.inputValue);
    }
  }

  protected getServerActionRequestBody(endpoint: IEndpoint, buttonId: string): any {
    const body = super.getServerActionRequestBody(endpoint, buttonId);
    const action = ProfileActions[buttonId];
    if (action === ProfileActions.CREATE || action === ProfileActions.SAVE || action === ProfileActions.APPROVE) {
      this.setServerMultiLineData(body);
    }
    return body;
  }

  private setMaxLengthField(value: string): void {
    const control = this.formMgr.get(UniqueIdFormat.FIELD_LOGICAL_ID);
    const options = (control.getLayoutControl() as SelectComponent).options;
    const selectedOption = options.find((option) => option.FIELD_ID === value);
    const fieldLength = selectedOption?.FIELD_LENGTH || null;
    this.formMgr.get(UniqueIdFormat.MAX_LENGTH).setValue(fieldLength);
  }

  private setServerMultiLineData(body: any): void {
    if (body[UniqueIdFormat.DETAILS]) {
      body[UniqueIdFormat.DETAILS].forEach((row: any, index: number) => {
        const fieldName = row[UniqueIdFormat.SUBSET_FLD_NM];
        if (fieldName === FormatFieldName.BUSINESS_DATE) {
          row[UniqueIdFormat.V_SUBSET_FLD_NM_VAL] = row[UniqueIdFormat.SUBSET_FLD_NM_VAL];
        }
        delete row[UniqueIdFormat.V_SUBSET_FLD_NM_VAL_BUSINESS_DATE];
        delete row[UniqueIdFormat.V_SUBSET_FLD_NM_VAL_BUSINESS_UNIT];
        delete row[UniqueIdFormat.V_SUBSET_FLD_NM_VAL_SEQUENCE];
      });
    }
  }

}
