import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { GppTreeConfigService, GppTreeModule } from '@fgpp-ui/components';
import { TranslateModule } from '@ngx-translate/core';
import { FnUiNavigationTreeComponent } from './fn-ui-navigation-tree.component';
import { FnUiNavigationTreeConfigService } from './services/fn-ui-navigation-tree-config.service';


@NgModule({
  declarations: [
    FnUiNavigationTreeComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    GppTreeModule,
    RouterModule
  ],
  providers: [
    { provide: GppTreeConfigService, useClass: FnUiNavigationTreeConfigService }
  ],
  exports: [
    FnUiNavigationTreeComponent
  ]
})
export class FnUiNavigationTreeModule { }
