import { Component } from '@angular/core';
import { IValueChange } from '../../../core/interfaces/form-value-change.interface';
import { BFOnFormLoadStart, BFOnValueChange } from '../../../core/interfaces/sdk-profile-hooks.interface';
import { ProfileFormTemplateProviders } from '../../../core/templates/profile-form.template.providers';
import { AbstractProfileFormComponent } from '../../abstract/abstract-profile/abstract-profile-form.component';
import { ProfileMode } from '../../enums/profile-mode.enum';
import { ErrorCodeMapping } from '../../models/error-code-mapping.model';
import { ChannelTypeControlsMetadata, ChannelTypeControlMetadata } from './models/channel-type-control-metadata.model';
import { FromChannelTypeControlsMetadata, ToChannelTypeControlsMetadata } from './models/consts/channel-type-controls-metadata.const';
import { ErrorCodeChannelType } from './models/enums/error-code-channel-type.enum';

@Component({
  selector: 'app-error-code-mapping',
  templateUrl: '../../../core/templates/profile-form.template.html',
  styleUrls: ['../../../core/templates/profile-form.template.scss'],
  providers: ProfileFormTemplateProviders,
})
export class ErrorCodeMappingComponent extends AbstractProfileFormComponent implements BFOnFormLoadStart, BFOnValueChange {

  fgppFormLoadStart(): void {
    this.fixChannelTypeSelectsValue();
    this.onFromChannelTypeChange();
    this.onToChannelTypeChange();
  }

  fgppValueChanges(change: IValueChange): void {
    switch (change.control) {
      case ErrorCodeMapping.FROM_CHANNEL_TYPE:
        this.onFromChannelTypeChange();
        return;
      case ErrorCodeMapping.TO_CHANNEL_TYPE:
        this.onToChannelTypeChange();
        return;
      case ErrorCodeMapping.V_FROM_MOP_SELECT:
        this.onFromMopChange();
        return;
      case ErrorCodeMapping.V_TO_MOP_SELECT:
        this.onToMopChange();
        return;
    }
  }

  private fixChannelTypeSelectsValue(): void {
    if (this.mode !== ProfileMode.CREATE) {
      this.fixChannelTypeSelectValue(ErrorCodeMapping.FROM_CHANNEL_TYPE);
      this.fixChannelTypeSelectValue(ErrorCodeMapping.TO_CHANNEL_TYPE);
    }
  }

  private fixChannelTypeSelectValue(channelTypeControlId: ErrorCodeMapping): void {
    const channelTypeControl = this.formMgr.get(channelTypeControlId);
    const channelType = channelTypeControl.getValue();
    if (channelType && channelType !== channelType.toUpperCase() && this.isValidChannelType(channelType.toUpperCase())) {
      channelTypeControl.setValue(channelType.toUpperCase(), { emitEvent: false });
    }
  }

  private onFromChannelTypeChange(): void {
    this.onChannelTypeChange(ErrorCodeMapping.FROM_CHANNEL_TYPE, FromChannelTypeControlsMetadata);
  }

  private onToChannelTypeChange(): void {
    this.onChannelTypeChange(ErrorCodeMapping.TO_CHANNEL_TYPE, ToChannelTypeControlsMetadata);
  }

  private onChannelTypeChange(channelTypeControlId: ErrorCodeMapping, channelTypeControlsMetadata: ChannelTypeControlsMetadata): void {
    let channelType = this.formMgr.get(channelTypeControlId).getValue() || '';
    if (!this.isValidChannelType(channelType)) {
      channelType = ErrorCodeChannelType.NONE;
    }
    this.hideUnrelatedControls(channelType, channelTypeControlsMetadata);
    this.showRelatedControls(channelType, channelTypeControlsMetadata);
  }

  private hideUnrelatedControls(channelType: ErrorCodeChannelType, channelTypeControlsMetadata: ChannelTypeControlsMetadata): void {
    const relatedControls = channelTypeControlsMetadata[channelType].map((controlMetadata: ChannelTypeControlMetadata) => controlMetadata.displayControlId);
    let unrelatedControls = Object.values(channelTypeControlsMetadata)
      .flat()
      .map((controlMetadata: ChannelTypeControlMetadata) => controlMetadata.displayControlId)
      .filter((controlId: ErrorCodeMapping) => !relatedControls.includes(controlId));
    unrelatedControls = [...new Set(unrelatedControls)];
    unrelatedControls.forEach((controlId: ErrorCodeMapping) => this.formMgr.get(controlId).hide().reset().markAsOptional());
  }

  private showRelatedControls(channelType: ErrorCodeChannelType, channelTypeControlsMetadata: ChannelTypeControlsMetadata): void {
    channelTypeControlsMetadata[channelType].forEach((controlMetadata: ChannelTypeControlMetadata, index) => {
      const displayControl = this.formMgr.get(controlMetadata.displayControlId);
      displayControl.show();

      if (controlMetadata.isMandatory) {
        displayControl.markAsRequired();
      } else {
        displayControl.markAsOptional();
      }

      if (controlMetadata.defaultValue) {
        displayControl.setValue(controlMetadata.defaultValue);
      }
    });
  }

  private isValidChannelType(channelType: string): boolean {
    return Object.keys(ErrorCodeChannelType).includes(channelType);
  }

  private onFromMopChange(): void {
    this.resetMopCodeControl(ErrorCodeMapping.V_FROM_MOP_CODE_SELECT);
  }

  private onToMopChange(): void {
    this.resetMopCodeControl(ErrorCodeMapping.V_TO_MOP_CODE_SELECT);
  }

  private resetMopCodeControl(mopCodeControlId: ErrorCodeMapping): void {
    this.formMgr.get(mopCodeControlId).reset();
  }
}
