import { Component, EventEmitter, Inject, Injectable, Input, Output, ViewChild } from '@angular/core';
import { ComponentManagerService, FormService, LegacyTableComponent } from '@fgpp-ui/components';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Base64 } from 'js-base64';
import { IDynamicModalDialogData } from '../../interfaces/dynamic-modal-dialog-data.interface';
import { ProfileAuditDetails } from '../../models/profile-audit-details.model';
import { ProfileUpdate } from '../../models/profile-update.model';

@Injectable()
@Component({
  selector: 'app-profile-audit-trail',
  templateUrl: './profile-audit-trail.component.html',
  styleUrls: ['./profile-audit-trail.component.scss'],
  providers: [FormService, ComponentManagerService]
})
export class ProfileAuditTrailComponent {
  @Output() onClose = new EventEmitter<void>();
  @Input() dataEndpoint: string;
  public isGridView = true;
  public detailEndpoint: string = null;
  public changeUserId: string;
  public changeDateTime: string;
  public endpoint;
  private masterTable: string;
  private profileId: string;

  @ViewChild(LegacyTableComponent) profileAuditDetails: LegacyTableComponent;

  constructor(@Inject(MAT_DIALOG_DATA) public dialogConfig: IDynamicModalDialogData,
              private matDialogRef: MatDialogRef<ProfileAuditTrailComponent>,
              private translateService: TranslateService) {
    this.endpoint = dialogConfig;
  }

  rowClick(rows): void {
    const row = rows[0];
    this.detailEndpoint = `${this.endpoint}/${Base64.encodeURL(row[ProfileUpdate.UID])}`;
    this.changeDateTime = row[ProfileUpdate.TIME_STAMP];
    this.changeUserId = row[ProfileUpdate.INITIATOR];
    this.masterTable = row[ProfileUpdate.UID].split('^').shift();
    this.profileId = row[ProfileUpdate.PROFILE_ID];
    this.isGridView = false;
  }

  returnToGrid(): void {
    this.isGridView = true;
    this.changeDateTime = undefined;
    this.changeUserId = undefined;
  }

  close(): void {
    this.onClose.emit();
    this.matDialogRef.close();
  }

  onProfileAuditDetailsLoad(): void {
    this.translateAuditFieldsName();
  }

  private translateAuditFieldsName(): void {
    this.profileAuditDetails.dataSource.data.forEach((row) => {
      const fullColumnName = `${this.masterTable}-${row[ProfileAuditDetails.COLUMN_NAME]}`;
      let traslateKey = `business-framework.profiles.${this.profileId}.${fullColumnName}.title`;
      let fieldName = this.translateService.instant(traslateKey);
      if (fieldName === traslateKey) {
        traslateKey = fullColumnName;
        fieldName = this.translateService.instant(traslateKey);
      }
      if (fieldName !== traslateKey) {
        row[ProfileAuditDetails.FIELD_NAME] = fieldName;
      }
    });
  }
}
