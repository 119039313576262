import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TreeConfig} from '@fgpp-ui/components';
import {Subscription} from 'rxjs';
import {SitemapNode} from '../../core/navigation/sitemap/models';
import {RouterHelper} from '../../base-view/classes/router-helper';
import {NavigationService} from '../../core/navigation/services/navigation.service';
import {ArchiveTabHandlerService} from '../../core/navigation/services/tab-handlers/archive-tab-handler.service';
import {DataExchangeService} from "@fgpp-ui/archive";

export enum ArchiveRouter {
  MAIN_SEARCHES = 'basic-searches',
  SAVED_SEARCHES = 'saved-searches',
  RECENT_SEARCHES = 'recent-searches',
  RESULT_SEARCHES = 'search_results',
  TABLE_DETAILS = 'table_details'
}

@Component({
  selector: 'app-archive-tree',
  templateUrl: './archive-tree.component.html',
})
export class ArchiveTreeComponent implements OnInit, OnDestroy {

  @Input() navigationTree: SitemapNode;

  treeConfig: Partial<TreeConfig> = {
    expandable: (node) => false,
    selectable: (node) => true
  };

  private _subscriber = new Subscription();
  private _selectedTreeNode: SitemapNode;

  set selectedTreeNode(value: SitemapNode) {
    this._selectedTreeNode = value;
  }

  get selectedTreeNode(): SitemapNode {
    return this._selectedTreeNode;
  }

  constructor(private route: ActivatedRoute, protected archiveTabHandlerService: ArchiveTabHandlerService,
              private router: Router, private navigationService: NavigationService,
              private dx: DataExchangeService) { }

  ngOnInit(): void {
    this.subscribeRouteData();
    this.subscribeRouteParams();
    this.dx.setRouteState(ArchiveRouter.MAIN_SEARCHES);
  }

  ngOnDestroy(): void {
    this._subscriber.unsubscribe();
  }

  private subscribeRouteData(): void {
    const routeDataSubscriber = this.route.data.subscribe((data) => {
       this.navigationTree = data.navigationTree;
    });
    this._subscriber.add(routeDataSubscriber);
  }

  private subscribeRouteParams(): void {
    const routeParamsSubscriber = this.route.firstChild.params.subscribe(params => {
      this.dx.setRouteState(params['taskBarItem'] === 'search-builder' ? ArchiveRouter.MAIN_SEARCHES : params['taskBarItem']);
      this.selectTreeItem();
    });
    this._subscriber.add(routeParamsSubscriber);
  }
  selectTreeItem(): void {
    let taskBarId = RouterHelper.getSegments(this.router)[2].path;
    this.selectedTreeNode =  {
      id: this.navigationTree.id,
      alias: this.navigationTree.alias,
      icon: null,
      data: this.navigationTree.data,
      nodes: [],
      routerLink: `/home/archive/${taskBarId}`,
      stateName: `home.archive.taskbar.main`,
      stateParams: { taskbarItem: taskBarId},
    }
  }
  private getSelectedNode(id: string): SitemapNode {
    return this.navigationTree.nodes?.find((node: SitemapNode) => node.id === id);
  }

}
