import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FilterEventType } from '@fgpp-ui/grid';
import { PaginatorData } from '@fgpp-ui/grid';
import { TablePin, TableSort } from '@fgpp-ui/grid';
import { SelectionEventType } from '@fgpp-ui/grid';
import { IGroupAction } from '../../messages-center/services/group-actions.service';
import { GridEventType } from '../interfaces/grid-event-type.enum';
import { GridEvent } from '../interfaces/grid-event.model';

@Injectable()
export class GridEventsService {

    private gridEvent$: BehaviorSubject<GridEvent> = new BehaviorSubject(null);

    triggerEvent(eventType: GridEventType): void {
        const event = {
          id: eventType,
        };
        this.gridEvent$.next(event);
    }

    triggerPinEvent(pin: TablePin): void {
        const event = {
          id: GridEventType.PIN,
          columnName: pin.column
        };
        this.gridEvent$.next(event);
    }

    triggerSortEvent(sort: TableSort): void {
        const event = {
          id: GridEventType.SORT,
          columnName: sort.active,
          direction: sort.direction
        };
        this.gridEvent$.next(event);
    }

    triggerPageEvent(paginatorData: PaginatorData): void {
        const event = {
          id: GridEventType.PAGINATION,
          action: paginatorData.action
        };
        this.gridEvent$.next(event);
    }

    triggerPageSizeEvent(paginatorData: PaginatorData): void {
        const event = {
          id: GridEventType.ITEMS_PER_PAGE,
          pageSize: paginatorData.pageSize
        };
        this.gridEvent$.next(event);
    }

    triggerGroupActionsEvent(action: IGroupAction): void {
        const event = {
          id: GridEventType.GROUP_ACTIONS,
          action: action.actionId
        };
        this.gridEvent$.next(event);
    }

    triggerFilterEvent(filterType: FilterEventType): void {
        let eventType;
        switch (filterType) {
            case FilterEventType.BASIC:
                eventType = GridEventType.BASIC_FILTER_SEARCH;
                break;
            case FilterEventType.ADVANCED:
                eventType = GridEventType.ADVANCED_FILTER_APPPLY;
                break;
            case FilterEventType.TRASH_CAN:
                eventType = GridEventType.TRASH_CAN;
                break;
            default:
                return;
        }
        const event = {
          id: eventType
        };
        this.gridEvent$.next(event);
    }

    triggerSelectionEvent(selectionType: SelectionEventType): void {
        let eventType;
        switch (selectionType) {
            case SelectionEventType.SELECT_ITEM:
                eventType = GridEventType.CHECK_ITEM;
                break;
            case SelectionEventType.DESELECT_ITEM:
                eventType = GridEventType.UNCHECK_ITEM;
                break;
            case SelectionEventType.SELECT_ALL:
                eventType = GridEventType.CHECK_ALL;
                break;
            case SelectionEventType.DESELECT_ALL:
                eventType = GridEventType.UNCHECK_ALL;
                break;
            default:
                return;
        }
        const event = {
          id: eventType
        };
        this.gridEvent$.next(event);
    }

    subscribeGridEvent(): Observable<GridEvent> {
        return this.gridEvent$.asObservable();
    }
}
