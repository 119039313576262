<app-fn-ui-autocomplete class="condition-operand-field"
                        [ngClass]="{ 'condition-operand-in-focus':isSubjectOfOperandFocus }"
                        [(ngModel)]="operandForAutoComplete"
                        [displayProperty]="'alias'"
                        [displayWithOverride]="operandTextOverride"
                        [filterFunction]="dataFilter"
                        [trackByFunction]="trackByFunction"
                        [required]="true"
                        [attr.title]="operand.alias"
                        (focused)="operandFocused()"
                        (blured)="operandUnfocused($event)"
                        (textChanged)="operandValueChanged($event)"
                        (selectionChanged)="operandItemSelected($event)"
                        (drop)="onDrop($event)">
</app-fn-ui-autocomplete>

<app-insert-function-popover *ngIf="operand?.type === 'function'"
                             [function]="operand"
                             (functionApplied)="functionResultHandler($event)">
</app-insert-function-popover>

<span *ngIf="!isValid && invalidValueErrorText" class="operand-error"  [title]="invalidValueErrorText">
  <app-fn-ui-icon icon="exclamation"></app-fn-ui-icon>
</span>

<div [appWatch]="operand | json" (valueChanged)="onOperandChange($event.newValue, $event.oldValue)"></div>
