import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TableRow } from '@fgpp-ui/grid';
import { FnUiOption } from '../../../shared/fn-ui-select/fn-ui-select.component';
import {
  ConditionFunction, ConditionOperand, ConditionOperator, ConditionsBankNode,
  ContentType, DefaultOperand, LogicalField, OperandDataType, ValueListParams
} from '../models';
import { OperatorsMap } from '../models/consts/rule-config.const';
import { RuleCommonService } from './rule-common.service';
import { RuleService } from './rule.service';

@Injectable()
export class SimpleConditionLogicService {

  constructor(private translateService: TranslateService,
              private ruleCommonService: RuleCommonService,
              private ruleService: RuleService) { }

  getOperandDataType(operand: ConditionOperand): OperandDataType {
    let operandType;

    if (typeof (operand as ConditionsBankNode).contentType === 'undefined') { //if it's a value (freeText)
      return;
    }

    switch ((operand as ConditionsBankNode).contentType.toLowerCase()) {
      case 'function':
        operandType = this.getFunctionDataType(operand as ConditionFunction);
        break;
      case 'base_condition':
        operandType = OperandDataType.BASE_CONDITION;
        break;
      case 'field':
        operandType = operand.type;
    }

    return operandType;
  }

  getOperatorsForOperand(operand: ConditionOperand): Array<FnUiOption> {
    const dataType = this.getOperandDataType(operand);
    if (typeof dataType === 'undefined') { //if it's a value (freeText)
      return [];
    }

    let operators = this.ruleService.operatorsPerDataType[dataType.toUpperCase()] || [];

    if ((operand as LogicalField).pruleContentsource == null) {
      operators = operators.filter((item: ConditionOperator) => this.filterOutOperators(item, OperatorsMap.CONTENT_SOURCE_FALSE_FILTER))
        .filter((item) => this.filterOutOperators(item, OperatorsMap.REDUNDANT_OPERATORS)); //TODO remove this filter once operators script runs
    } else {
      operators = operators.filter((item) => this.filterOutOperators(item, OperatorsMap.CONTENT_SOURCE_TRUE_FILTER))
        .filter((item) => this.filterOutOperators(item, OperatorsMap.REDUNDANT_OPERATORS)); //TODO remove this filter once operators script runs
    }

    const operatorList = this.mapOutOperators(operators);
    return operatorList;
  }

  getContentSource(operand: LogicalField): string {
    let contentSource = operand.pruleContentsource;

    if (contentSource.startsWith('P_=')) { //if source of left operand is PROFILE
      contentSource = contentSource.substring(3);
    }

    return contentSource;
  }

  getFilterFunction(operand: LogicalField, forcingType?: string): { [key: string]: any } {
    let filter = {};

    if (forcingType === '136') {
      //special case for "In value list" operator
      filter = {
        searchFilter: {
          'LIST_TYPES.OFFICE': this.ruleCommonService.rule.OFFICE
        }
      };
    } else if (!operand.pruleContentsource.startsWith('P_=')) {
      filter = {
        'type': 'LOGICAL_FIELD',
        'PRULES.HELP_LIST_FIELD_ALIAS': operand.id,
        'LIST_TYPES.OFFICE': this.ruleCommonService.rule.OFFICE
      };
    }
    if (Object.keys(filter).length === 0 && operand.inParams) {
      filter = this.processInParams(operand.inParams, operand.pruleContentsource);
    }
    return filter;
  }

  processInParams(inParams: { [key: string]: any }, contentSource: string) {
    const filter = { searchFilter: {}};
    Object.keys(inParams).forEach((key) => {
      if(inParams[key] === '1@IS') {
        filter.searchFilter[key.replace('.', '-')] = 'NOT_EMPTY';
      }
    });
    if (contentSource === 'P_=191') {
      filter.searchFilter['CUSTOMRS-OFFICE'] = this.ruleCommonService.rule.OFFICE;
    }
    return filter;
  }

  getFieldValue(operand: LogicalField, source: string) {
    return source === '136' ? 'LIST_TYPES-LIST_ID' : operand.outParams[Object.keys(operand.outParams)[0]];
  }

  extractOutputFromDrilldown(operand: LogicalField, selectedRows: Array<TableRow>, source: string): string {

    const fieldValue = this.getFieldValue(operand, source);

    const values = selectedRows.map((row: TableRow) => typeof row[fieldValue] !== 'undefined' ? row[fieldValue] : row[fieldValue.replace('.', '-')]);

    return values.join(',');
  }

  getEmptyOperand(): DefaultOperand {
    return {
      alias: '',
      type: ContentType.VALUE
    };
  }

  private filterOutOperators(item: string, filterArr: Array<string>): boolean {
    return filterArr.indexOf(item.toLowerCase()) === -1;
  }

  mapOutOperators(operators: Array<string>): Array<FnUiOption> {

    const OPERATOR_KEY_PREFIX = 'operators.';
    const OPERATOR_REGEX = /[A-Za-z]/;
    const SPACE = ' ';
    const UNDERSCORE = '_';

    const getAlias = (value: string): string => {
      const isLiteral = OPERATOR_REGEX.test(value);
      if (!isLiteral) {
        return value;
      }
      const key = OPERATOR_KEY_PREFIX + value.toUpperCase().split(SPACE).join(UNDERSCORE);
      return this.translateService.instant(key);
    };

    return operators.map((value: string) => {
      return {
        value: value,
        alias: getAlias(value)
      };
    });
  }

  private getFunctionDataType(operand: ConditionFunction): string {
    if (operand.alias.startsWith(OperandDataType.BASE_CONDITION)) {
      return OperandDataType.BASE_CONDITION;
    }

    let dataType = operand.outParameterType;

    if (dataType.startsWith('#')) { //if the type should be the type of one of the function arguments
      const position = dataType.slice(-1) as any;

      let functionArgumentValue = operand.functionArguments[position - 1].value;
      if (typeof functionArgumentValue !== 'undefined') {
        while (functionArgumentValue.indexOf(' ') === 0) {
          functionArgumentValue = functionArgumentValue.substring(1);
        }
        const type = this.getDataType(functionArgumentValue);
        if (typeof type !== 'undefined') {
          dataType = type;
        }
      } else {
        dataType = operand.functionArguments[position - 1].type;
      }
    }

    return dataType;
  }

  private getDataType(functionArgumentValue: any) {
    let result;
    const typeFromMap = this.ruleService.operatorsPerDataType[functionArgumentValue];

    if (typeof typeFromMap !== 'undefined' && typeFromMap.length > 0) {
      result = functionArgumentValue;
    } else {
      const obj = this.ruleService.getFieldAsObject(functionArgumentValue) as LogicalField;
      if (typeof obj !== 'undefined') {
        result = obj.type;
      }
    }

    return result;
  }

  isInnerConditionsOperator(operator: ConditionOperator | ''): boolean {
    return OperatorsMap.INNER_CONDITIONS_OPERATORS.indexOf(operator.toLowerCase()) > -1;
  }

  getValueListParams(): ValueListParams {
    return {
      source: '136',
      shouldDisableMultiSelect: true,
      filter: this.getFilterFunction(null, '136')
    };
  }

  isValidOperand(operand: ConditionOperand, allowValue: boolean): boolean {
    if (typeof operand === 'undefined') {
      return false;
    }

    let isValid = false;

    //if it's value (can be only in right operand) and it has an object in the conditions bank it's invalid
    if (allowValue && typeof operand.type !== 'undefined' && typeof (operand as LogicalField).contentType === 'undefined') {
      isValid = typeof this.ruleService.getFieldAsObject(operand.alias) === 'undefined';
    } else if (typeof (operand as LogicalField).contentType !== 'undefined') { //if operand is function/logicalField/BC
      isValid = typeof this.ruleService.getFieldAsObject(operand.alias) !== 'undefined';
    }
    return isValid;
  }

}
