import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { StoreService } from '../../core/services/store.service';
import { SetLiquidity } from '../../core/state/actions/liquidity.actions';


@Injectable()
export class LiquidityStoreService {

  constructor(protected storeService: StoreService) { }

  public setLiquidityRoute(path: string, queryParams: Params): void {
    const liquidityRoute = { path, queryParams };
    this.storeService.dispatch(new SetLiquidity(liquidityRoute));
  }

}
