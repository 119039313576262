import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { TaskBarService } from '../../../core/navigation/task-bar/services/task-bar.service';
import { UserSettingsService } from '../../../core/user-settings/services/user-settings.service';

@Injectable()
export class RedirectMessageCenterGuard implements CanActivate {

  private _prefix = 'home/messages-center/';
  private _defaultTaskBarItem = 'favorites';
  private _defaultState = this._prefix + this._defaultTaskBarItem;
  private _navigationBarToTaskBarItem = {
    'main_window.system_queue': 'system-queues',
    'main_window.custom_queue': 'custom-queues',
    'main_window.recent_searches': 'recent-searches',
    'main_window.templates': 'templates',
    'main_window.callback': 'callback',
    'main_window.my_favorite': 'favorites'
  };

  constructor(private router: Router,
              private userSettingsService: UserSettingsService,
              private taskBarService: TaskBarService) { }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const redirect = this.chooseDefaultState(route);
    this.router.navigate([redirect]);
    return true;
  }

  private chooseDefaultState(route: ActivatedRouteSnapshot): string {
    const landingPage = this.userSettingsService.getPreferences().landingPage;

    if (landingPage !== 'system_navigation.MAINTAB_QUEUES') {
      return this._defaultState;
    }

    const navigationBar = this.userSettingsService.getPreferences().navigationBar
    const taskBarItem = this._navigationBarToTaskBarItem[navigationBar];
    const taskbar = this.taskBarService.getTaskBar(route.parent.routeConfig.path);
    const isTaskBarItemExist = taskbar.taskBar.items.some((item) => item.id === taskBarItem);
    return isTaskBarItemExist ? this._prefix + taskBarItem : this._defaultState;
  }

}
