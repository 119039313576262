import { Injectable } from '@angular/core';
import { FnUiNavigationTreeConfigService } from '../../../shared/fn-ui-navigation-tree/services/fn-ui-navigation-tree-config.service';

@Injectable()
export class MessagesCenterNavigationTreeConfigService extends FnUiNavigationTreeConfigService {

  get getUId(): (node) => string {
    return (node) => `${node.type}.${node.id}`;
  }

  get expandable(): (node) => boolean {
      return (node) => !node.isLeaf;
  }

  get selectable(): (node) => boolean {
      return (node) => node.searchable;
  }

}
