<mat-form-field class="fn-ui-autocomplete" [appearance]="appearance" [floatLabel]="floatLabel">

    <mat-label *ngIf="label" class="dh-form-component-header" [ngClass]="{ 'required_input-label': required }">{{ label }}</mat-label>

    <input matInput [matAutocomplete]="auto" [placeholder]="placeholder" [disabled]="disabled" [required]="required" [(ngModel)]="selectedOption" (ngModelChange)="onModelChange($event)" (change)="onValueChange($event)" (focus)="onFocus($event)" (blur)="onBlur($event)"
        #autoCompleteInput>

    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelection($event)" [displayWith]="displayWith" class="fn-ui-autocomplete-panel select-dropdown" [hideSingleSelectionIndicator]="true">
        <mat-option *ngFor="let option of filteredOptions; trackBy: trackByFunction" [value]="option">
            <span class="fn-ui-option" [innerHTML]="getDisplay(option) | highlight: selectedOption"></span>
        </mat-option>
    </mat-autocomplete>

</mat-form-field>
