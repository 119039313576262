import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ToursApiService {

  constructor(private http: HttpClient) { }

  getTours(): Promise<any> {
    return this.http.get('do/guidedTour').toPromise();
  }

  saveSeenTours(userSeenTours): Promise<any> {
    return this.http.post('do/guidedTour', userSeenTours, { responseType: 'text' }).toPromise();
  }
}
