import { Injectable } from '@angular/core';
import { MessagesHelperService } from './messages-helper.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { MessageMatchingGridColumnService } from './message-matching-grid-column.service';
import { GridColumnsModel } from '../../grid/models/grid-columns.model';
import { MessageGridColumnsService } from './message-grid-column.service';
import { MessagesMatchingApiService } from './messages-matching-api.service';

@Injectable()
export class MessagesMatchingHelperService extends MessagesHelperService {

  constructor(
    messagesMatchingApiService: MessagesMatchingApiService,
    public messageMatchingGridColumnsService: MessageMatchingGridColumnService,
    http: HttpClient,
    translateService: TranslateService,
    private mainMessageGridColumnsService: MessageGridColumnsService) {
    super(messagesMatchingApiService, messageMatchingGridColumnsService, http, translateService);
  }

  getGridColumns(baseUrl: string, httpParams?: HttpParams): Promise<GridColumnsModel> {
    const cols = { ...this.mainMessageGridColumnsService.getStoredColumns() };
    cols.gridColumns = [...cols.gridColumns];
    cols.gridColumns.unshift(this.messageMatchingGridColumnsService.matchingTypeColumn);
    return Promise.resolve(cols);
  }
}
