import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ResizableModule } from 'angular-resizable-element';

import { GridEventsService } from '../azure-insight/services/grid-events.service';

import { MessageCenterEntityViewComponent } from '../messages-center/routing/components/message-center-entity-view/message-center-entity-view.component';

import { GridModule } from '../grid/grid.module';

import { MessagesCenterCallbackSplitGridComponent } from '../messages-center/components/messages-center-callback/messages-center-callback-grid/messages-center-callback-split-grid.component';
import { MessagesCenterCallbackQueuesComponent } from '../messages-center/components/messages-center-callback/messages-center-callback-queues/messages-center-callback-queues.component';

import { MessagesCenterNavigationModule } from '../messages-center/messages-center-navigation/messages-center-navigation.module';
import { ProfilesModule } from '../profiles/profiles.module';
import { FnUiButtonModule } from '../shared/fn-ui-button/fn-ui-button.module';
import { FnUiDialogModule } from '../shared/fn-ui-dialog/fn-ui-dialog.module';
import { FnUiCloseIconModule } from '../shared/fn-ui-icons/fn-ui-close-icon/fn-ui-close-icon.module';
import { FnUiEmptyIconModule } from '../shared/fn-ui-icons/fn-ui-empty-icon/fn-ui-empty-icon.module';
import { FnUiIconModule } from '../shared/fn-ui-icons/fn-ui-icon/fn-ui-icon.module';
import { FnUiNavigationActionsTreeModule } from '../shared/fn-ui-navigation-actions-tree/fn-ui-navigation-actions-tree.module';
import { FnUiNotificationModule } from '../shared/fn-ui-notification/fn-ui-notification.module';
import { ToursModule } from '../tours/tours.module';
import { SpinnerModule } from '../ui-components/spinner/spinner.module';
import { UiComponentsModule } from '../ui-components/ui-components.module';
import { AutoFeederModule } from './auto-feeder/auto-feeder.module';

import { CreatePaymentComponent } from './components/create-payment/create-payment.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { MessagesCallbackGridComponent } from './components/messages-callback-grid/messages-callback-grid.component';
import { MessagesCenterCustomQueuesComponent } from './components/messages-center-custom-queues/messages-center-custom-queues.component';
import { MessagesCenterFavoritesQueuesComponent } from './components/messages-center-favorites-queues/messages-center-favorites-queues.component';
import { MessagesCenterRecentSearchesComponent } from './components/messages-center-recent-searches/messages-center-recent-searches.component';
import { MessagesCenterSystemQueuesComponent } from './components/messages-center-system-queues/messages-center-system-queues.component';
import { MessagesCenterTemplatesComponent } from './components/messages-center-templates/messages-center-templates.component';
import { MessagesGridComponent } from './components/messages-grid/messages-grid.component';
import { MessagesMatchingGridComponent } from './components/messages-matching/messages-matching-grid/messages-matching-grid.component';

import { MessagesQueues } from './models/consts/messages-queues.service';

import { CallerMessagesManagementService } from './services/caller-messages-management.service';
import { CreatePaymentService } from './services/create-payment.service';
import { MessageGridColumnsService } from './services/message-grid-column.service';
import { MessageMatchingGridColumnService } from './services/message-matching-grid-column.service';
import { MessagesApiService } from './services/messages-api.service';
import { MessagesHelperService } from './services/messages-helper.service';
import { MessagesManagementService } from './services/messages-management.service';
import { MessagesMatchingApiService } from './services/messages-matching-api.service';
import { MessagesMatchingHelperService } from './services/messages-matching-helper.service';
import { MessagesMatchingManagementService } from './services/messages-matching-management.service';


@NgModule({
  providers: [
    MessagesMatchingManagementService,
    MessagesApiService,
    MessageGridColumnsService,
    MessagesHelperService,
    CallerMessagesManagementService,
    MessagesQueues,
    MessageMatchingGridColumnService,
    MessagesMatchingHelperService,
    MessagesMatchingApiService,
    CreatePaymentService,
    GridEventsService
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    UiComponentsModule,
    GridModule,
    TranslateModule.forChild(),
    MatMenuModule,
    MatButtonModule,
    ProfilesModule,
    MatFormFieldModule,
    MatSelectModule,
    SpinnerModule,
    FnUiNotificationModule,
    AutoFeederModule,
    FnUiNotificationModule,
    FnUiDialogModule,
    FnUiCloseIconModule,
    FormsModule,
    ResizableModule,
    ToursModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    FnUiEmptyIconModule,
    FnUiIconModule,
    FnUiButtonModule,
    MessagesCenterNavigationModule,
    FnUiNavigationActionsTreeModule,
    RouterModule
  ],
  declarations: [
    MessageCenterEntityViewComponent,
    MessagesCenterSystemQueuesComponent,
    MessagesCenterCustomQueuesComponent,
    MessagesCenterFavoritesQueuesComponent,
    MessagesCenterRecentSearchesComponent,
    MessagesCenterTemplatesComponent,
    MessagesGridComponent,
    MessagesMatchingGridComponent,
    MessagesCallbackGridComponent,
    MessagesCenterCallbackQueuesComponent,
    MessagesCenterCallbackSplitGridComponent,
    CreatePaymentComponent,
    LandingPageComponent
  ],
  exports: [
    MessagesGridComponent,
    MessagesMatchingGridComponent,
    MessagesCenterCallbackSplitGridComponent,
    ResizableModule,
    MessagesCenterSystemQueuesComponent,
    MessagesCenterCustomQueuesComponent,
    MessagesCenterFavoritesQueuesComponent,
    MessagesCenterRecentSearchesComponent,
    MessagesCenterTemplatesComponent,
    MessagesCenterCallbackQueuesComponent,
    MessagesCallbackGridComponent
  ],
})
export class MessagesModule {
}
