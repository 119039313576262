<div *ngIf="shouldShowPopover">

  <div [mdePopoverTriggerFor]="appPopover" mdePopoverTriggerOn="none" [mdePopoverBackdropCloseOnClick]="false"
       [mdePopoverTargetAt]="targetAt"></div>

  <mde-popover
    #appPopover="mdePopover"
    [mdePopoverOverlapTrigger]="false"
    [mdePopoverCloseOnClick]="false"
    [mdeFocusTrapEnabled]="true"
    [mdePopoverArrowWidth]="arrowWidth"
    [mdePopoverArrowColor]="'#fff'"
    [class]=removeArrowBorder>

        <app-tour-step
          [isNextLabelEnabled]="isNextLabelEnabled"
          [isPrevLabelEnabled]="isPrevLabelEnabled"
          [isTourAbortable]="isTourAbortable"
          [onCloseHandler]="onCloseHandler"
          [onNextHandler]="onNextHandler"
          [onOkHandler]="onOkHandler"
          [onPostponeHandler]="onPostponeHandler"
          [onPrevHandler]="onPrevHandler"
          [tourItem]="tourItem">
        </app-tour-step>
  </mde-popover>
</div>
