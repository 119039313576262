<div class="user-settings">
    <div class="header">
        <app-fn-ui-icon icon="cog_outline" size="21px"></app-fn-ui-icon>
        <span>{{'my_profile.my_profile_main_header' | translate}}</span>
        <app-fn-ui-close-icon (closeClick)="close()" id="user-settings-close"></app-fn-ui-close-icon>
    </div>

    <mat-divider></mat-divider>

    <div class="body">
        <div class="side-menu">
          <mat-selection-list [multiple]="false" [hideSingleSelectionIndicator]="true" #list>
                <mat-list-option *ngIf="showChangePassword" id="set-view-password" [value]="'password'">
                    <app-fn-ui-icon icon="password"></app-fn-ui-icon>{{'my_profile.password' | translate}}
                </mat-list-option>
                <mat-list-option id="set-view-localization" [value]="'localization'">
                    <app-fn-ui-icon icon="localization"></app-fn-ui-icon>{{'my_profile.localization' | translate}}
                </mat-list-option>
                <mat-list-option id="set-view-display" [value]="'display'">
                    <app-fn-ui-icon icon="television"></app-fn-ui-icon>{{'my_profile.display' | translate}}
                </mat-list-option>
            </mat-selection-list>
        </div>
        <div class="content">
            <div class="wrapper">
                <app-change-password *ngIf="showChangePassword" [hidden]="list.selectedOptions.selected[0]?.value !== 'password'">
                </app-change-password>
                <app-localization [hidden]="list.selectedOptions.selected[0]?.value !== 'localization'">
                </app-localization>
                <app-display [hidden]="list.selectedOptions.selected[0]?.value !== 'display'"></app-display>
            </div>
        </div>
    </div>
</div>
