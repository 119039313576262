import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Alert } from '../../models/alert.model';

@Component({
  selector: 'app-alert-item',
  templateUrl: './alert-item.component.html',
  styleUrls: ['./alert-item.component.scss']
})
export class AlertItemComponent {

  @Input() alert: Alert;

  @Output() detailsClick: EventEmitter<void> = new EventEmitter();
  @Output() investigateClick: EventEmitter<void> = new EventEmitter();

  onDetailsClick() {
    this.detailsClick.emit();
  }

  onInvestigateClick() {
    if (this.alert.permittedQueue) {
      this.investigateClick.emit();
    }
  }

}
