import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {SettlementCurrencyModel} from './models/settlement-currency-state.model';
import {SetSettlementCurrency} from './actions/settlement-currency.action';


@State<SettlementCurrencyModel>({
  name: 'settlementCurrency',
  defaults: {
    currency: null
  }
})

@Injectable()
export class SettlementCurrencyState {

  @Selector()
  static getSettlementCurrency(state: SettlementCurrencyModel): string {
    return state.currency;
  }

  @Action(SetSettlementCurrency)
  SetSettlementCurrency(ctx: StateContext<SettlementCurrencyModel>, {payload}: SetSettlementCurrency) {
    ctx.patchState({
      currency: payload.currency
    });
  }
}
