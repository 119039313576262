import {AfterContentChecked, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from "rxjs";
import {ActivatedRoute, ParamMap} from "@angular/router";
import {TaskBarService} from "../../../core/navigation/task-bar/services/task-bar.service";
import {ProfilesMode} from "../../../profiles/routing/models/enums/profiles-mode.enum";
import {DynamicFormLoaderComponent} from "../../../forms/components/dynamic-form-loader/dynamic-form-loader.component";
import {RulesProfilesManagementService} from "../../../profiles/services/rules-profiles-management.service";
import { RuleTypeService } from '../../../rules/services/rule-type.service';
import { RULE_TYPE_DELIMITER } from '../../../rules/models/consts/rule-type.consts';

@Component({
  selector: 'app-rules-main-view',
  templateUrl: './rules-main-view.component.html',
  styleUrls: ['./rules-main-view.component.scss']
})
export class RulesMainViewComponent implements OnInit, OnDestroy, AfterContentChecked {

  private _isStandAlone: boolean;
  private _isLoading: boolean;
  private _subscriber = new Subscription();
  public rulesLandingView: boolean;
  private _profilesMode: ProfilesMode;
  private _previousParamMap: ParamMap;
  isGridView: boolean;
  component;

  @ViewChild(DynamicFormLoaderComponent) singleProfile: DynamicFormLoaderComponent;

  get profilesModeModal(): typeof ProfilesMode {
    return ProfilesMode;
  }

  get profilesMode(): ProfilesMode {
    return this._profilesMode;
  }

  get showSideBar(): boolean {
    return this.taskBarService.taskBarState.showSideBar;
  }

  get isStandAlone(): boolean {
    return this._isStandAlone;
  }

  get isLoading(): boolean {
    return this._isLoading;
  }

  constructor(private changeDetectorRef: ChangeDetectorRef,
              private route: ActivatedRoute,
              private taskBarService: TaskBarService,
              private rulesProfileService: RulesProfilesManagementService,
              private ruleTypeService: RuleTypeService) {
    this.route.queryParamMap.subscribe(async (paramMap) => {
      const isSingleRule = paramMap.has('mode');
      if (isSingleRule) {
        this.rulesLandingView = false;
        this._profilesMode = ProfilesMode.PROFILE_FORM_VIEW;
        this.ruleTypeService.disableTreeItems = true;
        this._previousParamMap = paramMap;
        return;
      }
      ruleTypeService.disableTreeItems = false;
      const hasRuleType = paramMap.has('ruleType');
      this.rulesLandingView = !hasRuleType;
      if (hasRuleType) {
        this._profilesMode = ProfilesMode.GRID_VIEW;
        if (!this.rulesProfileService.getSearchRequest()) {
          this.rulesProfileService.initializeSearchRequest();
        }
        const fromForm = this._previousParamMap?.has('mode');
        if (fromForm) {
          this.rulesProfileService.invokeGridDataChange();
        } else {
          await this.rulesProfileService.onRuleTypeSelected(paramMap.get('ruleType').split(RULE_TYPE_DELIMITER));
        }
      }
      this._previousParamMap = paramMap;
    });
  }

  ngOnInit(): void {
    const subscription = this.taskBarService.loading$.subscribe(value => {
      this._isLoading = value;
    });
    this._subscriber.add(subscription);
  }

  ngOnDestroy(): void {
    this._subscriber.unsubscribe();
  }

  ngAfterContentChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

}
