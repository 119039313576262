import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import packageIcons from './icons.json';

@Component({
  selector: 'app-fn-ui-icon',
  templateUrl: './fn-ui-icon.component.html',
  styleUrls: ['./fn-ui-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FnUiIconComponent {

  private _icon;

  @Input() size = '21px';
  @Input() fontweight = 'normal';

  @Input()
  set icon(icon) {
    this._icon = packageIcons.icons[icon];
  }
  get icon() {
    return this._icon;
  }
}
