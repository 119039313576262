<div class="fx-rate-modal">
  <div mat-dialog-title>
    <span class="title">{{'message.panel.fx_rate_table.get_rate' | translate}}</span>
    <app-fn-ui-close-icon [size]="'18px'" (closeClick)="reject()" [disabled]="!enableReject"></app-fn-ui-close-icon>
  </div>

  <div mat-dialog-content>
    <div class="first">
      <div class="line">
        <div>{{'message.panel.fx_rate_table.fx_rate.label' | translate}}:</div>
        <div class="bold">{{getFxRateTitle()}}</div>
      </div>
      <div class="line">
        <div>{{'message.panel.fx_rate_table.total_converted_amount.label' | translate}}:</div>
        <div>{{fxRate.fxRateDetails.toAmount}} {{fxRate.fxRateDetails.toCurrency}}</div>
      </div>
      <div class="line">
        <div>{{'message.panel.fx_rate_table.fx_issued.label' | translate}}:</div>
        <div>{{fxRate.fxRateDetails.fxAmount}} {{fxRate.fxRateDetails.fromCurrency}}</div>
      </div>
    </div>
    <div class="border"></div>
    <div class="second">
      <div class="line">
        <div>{{'message.panel.fx_rate_table.expiration' | translate}}:</div>
        <div class="bold"><span class="timer" [ngClass]="{'expired': counter === 0 }">{{counter}}</span>
          {{'message.panel.fx_rate_table.seconds' | translate}}</div>
      </div>
    </div>
  </div>

  <div class="status-container">
    <div class="status"
      [ngClass]="{'info': statusType === StatusType.INFO, 'success': statusType === StatusType.SUCCESSFUL,
        'error': statusType === StatusType.ERROR}">
      <div class="text-status">
        <span class="message-icon" *ngIf="statusType === StatusType.INFO"><app-fn-ui-icon icon="information_outline" size="20px"></app-fn-ui-icon></span>
        <span class="message-icon" *ngIf="statusType === StatusType.SUCCESSFUL"><app-fn-ui-icon icon="check_circle_outline" size="20px"></app-fn-ui-icon></span>
        <span class="message-icon" *ngIf="statusType === StatusType.ERROR"><app-fn-ui-icon icon="alert_circle_outline" size="20px"></app-fn-ui-icon></span>
        <span class="message">{{status}}</span>
      </div>
      <div class="loader">
        <div class="wrapper" libLoader [loaderVisible]="showLoader" [loaderComponent]="spinnerComponent"></div>
      </div>
      <div *ngIf="showForce" class="force">
        <button mat-stroked-button [disabled]="counter === 0" (click)="force()" color="primary">{{'button.ForceNSF' | translate}}</button>
      </div>
    </div>
  </div >



  <div mat-dialog-actions>
    <button mat-button (click)="reject()" [disabled]="!enableReject">{{'button.Reject' | translate}}</button>
    <button mat-flat-button color="primary" (click)="approve()" [disabled]="counter === 0 || !enableAccept">{{'button.Approve' |
      translate}}</button>
  </div>
</div>
