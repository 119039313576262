import { GridContainerConfig } from '../../../../grid/models/grid-container-config.model';
import { GridFooterConfig } from '../../../../grid/models/grid-footer-config.model';
import { GridHeaderConfig } from '../../../../grid/models/grid-header-config.model';
import { GroupActionsConfig } from '../../../../grid/models/group-actions-config.model';

export const MassPayHeaderConfig: GridHeaderConfig = {
  showLastUpdate: false,
  showScopes: false,
  showRefresh: true,
  showLeftMenu: true,
  showRightMenu: true,
  showFavorite: true,
  showType: true,
  showTitle: true,
  showAutoFeeder: false,
  showFilter: true,
  showAllActions: true,
  showCreate: true,
  disableCreate: true,
  processingScope: true
};

export const MpGroupActionsConfig: GroupActionsConfig = {
  showGroupActionIcon: false,
  showBeforeAfter: true,
  showAddNotes: false,
  showGroupActions: false,
};

export const MassPayFooterConfig: GridFooterConfig = {
  showResultsAndCounts: true,
  groupActions: MpGroupActionsConfig,
  showCallerDetails: false,
  showCreateFromTemplate: false,
  showResultsWarning: false
};

export const MassPayGridContainerConfig: GridContainerConfig = {
  gridHeaderConfig: MassPayHeaderConfig,
  gridFooterConfig: MassPayFooterConfig,
};

