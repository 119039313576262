import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface CountdownTimerData {
  modalTitle: string;
  title: string;
  subTitle: string;
  abortButton: string;
  button: string;
  saveAndClose: string;
}
/**
 * @title countdown-timer
 */
@Component({
  selector: 'app-countdown-timer',
  templateUrl: 'countdown-timer.component.html',
  styleUrls: ['countdown-timer.component.scss']
})

export class CountdownTimerComponent implements OnInit {

  public display: boolean;
  public time: any;
  //public timer: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: CountdownTimerData) {
    this.time = '00:00';
  }

  ngOnInit() {
    this.display = false;
    //this.countdown();
    this.timer();
    setTimeout(() => {
      this.display = true;
    }, 10000);
  }

  timer() {
    let min: any = 0;
    let sec: any = 0;
    setInterval(() => {
      sec++;
      if (sec < 10) {
        sec = '0' + sec;
      }
      if (sec === 60) {
        min++;
        sec = 0;
        if (min < 10) {
          min = '0' + min;
        }
      }
      this.time = min + ':' + sec;
    }, 1000);
  }

}
