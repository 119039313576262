import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanDeactivate } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { map, of } from 'rxjs';
import { ConfirmationModalComponent } from '../../../forms/components/confirmation-modal/confirmation-modal.component';
import { DynamicFormLoaderComponent } from '../../../forms/components/dynamic-form-loader/dynamic-form-loader.component';
import { FnUiDialogService } from '../../../shared/fn-ui-dialog/services/fn-ui-dialog.service';

@Injectable()
export class DeactivateGuard implements CanDeactivate<DynamicFormLoaderComponent> {

  constructor(public dialogService: FnUiDialogService, private translateService: TranslateService) {}

   private config = {
     panelClass: ['confirmation-modal'],
    data: {
      modalTitle: this.translateService.instant('forms.modal_create_close_confirm_title'),
      content: this.translateService.instant('forms.modal_create_close_confirm_content')
    }
  };

  canDeactivate(component: DynamicFormLoaderComponent, currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot) {
    if (component.instance['componentRef'].instance.isDirty()) {
      return this.dialogService.open(ConfirmationModalComponent, this.config).afterClosed().pipe(map((result) => {
        if (result === 'yes') {
          component.instance['componentRef'].instance.doAfterApproveExit();
          component.instance['componentRef'].instance.closeForm(nextState);
          return true;
        } else {
          return false;
        }
      }));
    } else {
      return of(true);
    }
  }
}
