import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FnUiIconModule } from '../fn-ui-icon/fn-ui-icon.module';

import { FnUiEmptyIconComponent } from './fn-ui-empty-icon.component';


@NgModule({
    imports: [
        CommonModule,
        FnUiIconModule
    ],
    declarations: [
        FnUiEmptyIconComponent
    ],
    exports: [
        FnUiEmptyIconComponent
    ]
})
export class FnUiEmptyIconModule { }
