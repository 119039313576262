import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { ProfilesManagementService } from '../../services/profiles-management.service';

export const LoadTypeResolver: ResolveFn<void> =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void => {

    const profilesManagementService = inject(ProfilesManagementService);

    if (route.params.profileId !== profilesManagementService.profileId) {
      profilesManagementService.loadType = null;
    }
  };
