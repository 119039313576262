import { Injectable } from '@angular/core';
import { ApiCacheEntry } from '../models/api-cache-entry.model';
import { ApiCacheKeys } from '../models/enums/api-cache-keys.enum';
import { IApiCachingStorageService } from './interfaces/api-caching-storage-service.interface';

@Injectable()
export class ApiCachingStorageService implements IApiCachingStorageService {

  get<T>(key: ApiCacheKeys): ApiCacheEntry<T> {
    const item = localStorage.getItem(key.toString());
    try {
      return JSON.parse(item);
    } catch (error) {
      console.warn(`Couldn't parse cache entry for '${key}' key`, error);
      return null;
    }
  }

  set<T>(key: ApiCacheKeys, value: ApiCacheEntry<T>): void {
    localStorage.setItem(key.toString(), JSON.stringify(value));
  }

  remove(key: ApiCacheKeys): void {
    localStorage.removeItem(key.toString());
  }

  clear(): void {
    const keys = Object.keys(ApiCacheKeys);
    keys.forEach((key: string) => {
      this.remove(ApiCacheKeys[key]);
    });
  }

}
