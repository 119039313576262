import { Injectable } from '@angular/core';
import { UserSettingsApiService } from './user-settings-api.service';
import { UserPreferences } from '../models/user-preferences.model';
import { StoreService } from '../../services/store.service';
import { SetUserPreferences } from '../../state/actions/user-preferences.actions';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UserSettingsComponent } from '../components/user-settings/user-settings.component';
import { FormatsService } from '../../../shared/services/formats.service';
import { DEFAULT_USER_PREFERENCES } from '../models/default-user-preferences.const';
import { SearchQueryBuilderService } from '../../../shared/services/search-query-builder.service';

@Injectable()
export class UserSettingsService {

    private _preferences: UserPreferences = {} as UserPreferences;

    constructor(private userSettingsApiService: UserSettingsApiService,
        private storeService: StoreService,
        private dialog: MatDialog,
        private formatsService: FormatsService,
        //instance searchQueryBuilderService needs to run subscription for user settings
        private searchQueryBuilderService: SearchQueryBuilderService) {}

    openUserSettings() {
        const config: MatDialogConfig = {
            minWidth: '700px',
            panelClass: 'user-settings-modal',
            disableClose: true
        };
        const modalRef = this.dialog.open(UserSettingsComponent, config);
        return modalRef;
    }

    getPreferences(): UserPreferences {
        return this._preferences;
    }

    get(): Promise<UserPreferences> {
        if (Object.getOwnPropertyNames(this._preferences).length > 0) {
            return new Promise((resolve) => {
                resolve(this._preferences);
            });
        }
        return this.load();
    }

    applyChanges() {
        const formats = this.formatsService.formats;
        formats.dateFormat = this._preferences.dateFormat || formats.dateFormat;
        formats.timeFormat = this._preferences.timeFormat || formats.timeFormat;
        formats.thousandSeparator = this._preferences.thousandSeparator || formats.thousandSeparator;
        formats.decimalSeparator = this._preferences.decimalSeparator || formats.decimalSeparator;
        formats.roundNumber = this._preferences.roundNumber || formats.roundNumber;
        formats.language = this._preferences.language || formats.language;
        formats.itemsPerPage = this._preferences.itemsPerPage || formats.itemsPerPage;
    }

    save(preferences) {
        return this.userSettingsApiService.savePreferences(preferences).then(() => {
            if (JSON.stringify(preferences) !== JSON.stringify(this._preferences)) {
                Object.assign(this._preferences, preferences);
                this.storeService.dispatch(new SetUserPreferences(preferences));
            }
            this.applyChanges();
        });
    }

    getDefault() {
        return this.userSettingsApiService.getDefault().then((response) => {
            return response;
        });
    }

    reset() {
        return this.userSettingsApiService.resetPreferences(this._preferences).then((response) => {
            Object.assign(this._preferences, response.data);
        });
    }

    private load(): Promise<UserPreferences> {
        return this.userSettingsApiService.getPreferences().then((response) => {
            Object.assign(this._preferences, response);
            this.setDefaults(this._preferences);
            this.applyChanges();
            this.storeService.dispatch(new SetUserPreferences(this._preferences));
            return response;
        });
    }

    private setDefaults(preferences: UserPreferences) {
        preferences.timeFormat = preferences.timeFormat || DEFAULT_USER_PREFERENCES.timeFormat;
        preferences.dateFormat = preferences.dateFormat || DEFAULT_USER_PREFERENCES.dateFormat;
        preferences.decimalSeparator = preferences.decimalSeparator || DEFAULT_USER_PREFERENCES.decimalSeparator;
        preferences.thousandSeparator = preferences.thousandSeparator || DEFAULT_USER_PREFERENCES.thousandSeparator;
        preferences.roundNumber = preferences.roundNumber || DEFAULT_USER_PREFERENCES.roundNumber;
        preferences.landingPage = preferences.landingPage || DEFAULT_USER_PREFERENCES.landingPage;
        preferences.navigationBar = preferences.navigationBar || DEFAULT_USER_PREFERENCES.navigationBar;
        preferences.language = preferences.language || DEFAULT_USER_PREFERENCES.language;
        preferences.itemsPerPage = +preferences.itemsPerPage || DEFAULT_USER_PREFERENCES.itemsPerPage;
        preferences.timeMode = DEFAULT_USER_PREFERENCES.timeMode;
    }
}
