<form class="dh-expired-pass-form dh-signin-form" [formGroup]="form" #signupForm="ngForm" (ngSubmit)="changeExpiredPassword()" autocomplete="off">
  <div class="dh-product-title">Payments To Go</div>

    <mat-form-field [floatLabel]="'always'" class="dh-form-field username field">
      <mat-label>User ID</mat-label>
      <input matInput id="expired-password-username-field"
             [(ngModel)]="credentials.userId"
             required
             [disableControl]="authenticationInProgress"
             formControlName="username"
             autocomplete="off"
             #usernameField>
      <mat-hint>Enter user ID</mat-hint>
    </mat-form-field>

    <mat-form-field [floatLabel]="'always'" class="dh-form-field field">
      <mat-label>Current Password</mat-label>
      <div style="display: flex; flex-direction: row;">
        <input matInput id="expired-password-old-password-field"
               type="password"
               [(ngModel)]="credentials.password"
               required
               [disableControl]="authenticationInProgress"
               formControlName="password"
               autocomplete="off"
               (keydown)="onKeyDownShowCapLock($event)"
               #passwordField>
      </div>
      <mat-hint>You must enter a password</mat-hint>
      <mat-error>{{ getError(form.controls.password)}}</mat-error>
    </mat-form-field>

    <mat-form-field [floatLabel]="'always'" class="dh-form-field field">
      <mat-label>New Password</mat-label>
      <div style="display: flex; flex-direction: row;">
        <input matInput id="expired-password-new-password-field"
               type="text"
               [(ngModel)]="newPassword"
               required
               [disableControl]="authenticationInProgress"
               formControlName="newPassword"
               autocomplete="off"
               (keydown)="onKeyDownShowCapLock($event)"
               #newPasswordField>
        <i class="material-icons" style="cursor: pointer"  (click)="toggle(newPasswordField)"> {{ newPasswordField.type === 'password' ? 'visibility_off' : 'visibility' }}</i>
      </div>
      <mat-hint>You must enter a password</mat-hint>
      <mat-error>{{ getError(form.controls.newPassword)}}</mat-error>
    </mat-form-field>

  <span class="login-caps-lock-warning" *ngIf="capsLock"><app-fn-ui-icon icon="report_problem" size="14px"></app-fn-ui-icon>Caps Lock is on</span>
  <app-password-policy [form]="form"></app-password-policy>

    <button mat-flat-button
            class="login-button"
            color="primary"
            [disabled]="authenticationInProgress || !signupForm.valid"
            type="submit">
      <span class="dh-text-label">Change Password</span>
    </button>

</form>
