import { Component, ElementRef, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-fa-dialog',
  templateUrl: './fa-dialog.component.html',
  styleUrls: ['./fa-dialog.component.scss']
})
export class FaDialogComponent {
  @Input() title;
  @Input() body: TemplateRef<ElementRef>;
  @Input() footer: TemplateRef<ElementRef>;
}
