<div class="drill-down-grid" [appSpinner]="isFetchingData">
    <lib-grid [config]="gridConfig"
              [columns]="gridColumns"
              [data]="gridData"
              [selectedFilters]="selectedActionFilters"
              [keyId]="keyId"
              [selectedRows]="externalFilterGridData.selectedRows"
              (filterChanged)="onFilterChanged($event)"
              (pageChanged)="onPageChanged($event)"
              (rowClicked)="onRowClicked($event)"
              (selectionChanged)="onSelectionChanged($event)"
              (sortChanged)="onSortChanged($event)"
              (pinChanged)="onColumnPinChange($event)"
              (columnSizeChanged)="onColumnSizeChanged()"
              (refineFilteringClick)="onRefineFilteringClick($event)"
              (operatorChanged)="onOperatorChanged($event)">
    </lib-grid>
</div>
