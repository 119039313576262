export enum EMessageButtons {
  SAVE = 'save',
  CLOSE = 'close',
  INITIATE = 'initiate',
  CANCEL = 'cancel',
  VERIFY = 'verify',
  RELEASE = 'release',
  SEND_TO_REPAIR = 'send-to-repair',
  SUBMIT = 'submit',
  PROCESSING_COMMUNICATIONS = 'processing-communications',
  PRINT = 'print',
  OPEN_ACCORDION = 'open-accordion'
}
