<div class="rule-form">
  <div class="rule-conditions-bank" [ngClass]="{ 'shown': ruleCommonService.show.conditionsBank }">
    <app-conditions-bank></app-conditions-bank>
  </div>
  <div class="rule-content">
    <div class="rule-content-upper-section">
      <app-rule-content [metadata]="metadata"
                        [rule]="rule"
                        [ruleStatuses]="ruleStatuses">
      </app-rule-content>
    </div>
    <div class="rule-content-footer">
      <app-rule-footer [metadata]="metadata"
                       [rule]="rule"
                       [ruleStatuses]="ruleStatuses">
      </app-rule-footer>
    </div>
  </div>  
</div>
