import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TreeConfig } from '@fgpp-ui/components';
import { Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { MessagesCenterQueue } from '../../../core/models/messages-center-queue.model';
import { Search } from '../../../core/navigation-search/models/search.model';
import { MessagesCenterQueueState } from '../../../core/state/messages-center-queue.state';
import { MessagesCenterQueuesAlias } from '../../../messages-center/models/consts/messages-center-queues-alias.const';
import { RecentSearchesTree } from '../../../messages-center/recent-searches/models/recent-searches-tree.model';
import { RecentSearch } from '../../../messages-center/recent-searches/models/recent-search.model';
import { RecentSearchActionsService } from '../../../messages-center/recent-searches/services/recent-search-actions.service';
import { RecentSearchesManagerService } from '../../../messages-center/recent-searches/services/recent-searches-manager.service';
import { SearchQueryBuilderService } from '../../../shared/services/search-query-builder.service';
import { MessagesQueueType } from '../../models/enums/messages-queue-type.enum';
import { MessagesManagementService } from '../../services/messages-management.service';
import { MessagesCenterNavigationService } from '../../../messages-center/messages-center-navigation/services/messages-center-navigation.service';
import { NodeActionClickEvent } from '../../../shared/fn-ui-navigation-actions-tree/models/node-action-click-event.model';
import { FnUiNavigationActionsTreeComponent } from '../../../shared/fn-ui-navigation-actions-tree/fn-ui-navigation-actions-tree.component';
import { MessagesType } from '../../models/enums/messages-type.enum';
import { NodeAction } from '../../../shared/fn-ui-navigation-actions-tree/models/node-action.model';
import { BreadcrumbsService } from '../../../ui-components/services/breadcrumbs.service';
import { SearchType } from '../../../core/navigation-search/models';

@Component({
  selector: 'app-messages-center-recent-searches',
  templateUrl: 'messages-center-recent-searches.component.html'
})
export class MessagesCenterRecentSearchesComponent implements OnInit, OnDestroy {

  @Select(MessagesCenterQueueState.getRecentSearchesQueue) queue$: Observable<MessagesCenterQueue>;

  @ViewChild(FnUiNavigationActionsTreeComponent, { static: true }) navigationActionsTreeComponent: FnUiNavigationActionsTreeComponent<RecentSearch>;

  treeConfig: Partial<TreeConfig> = {
    childrenProperty: 'recentSearches',
    expandable: (node) => !!node.recentSearches,
    selectable: (node) => !node.isNode,
    trackBy: (node) => node,
    expansionTrackBy: (node) => node.id
  };

  selectedNode: RecentSearch;
  menuActions: Array<NodeAction>;

  private _subscription = new Subscription();
  private _shouldCollapseTree: boolean;

  get tree(): RecentSearchesTree {
    return this.recentSearchesManagerService.tree;
  }

  constructor(private router: Router,
              private route: ActivatedRoute,
              private recentSearchesManagerService: RecentSearchesManagerService,
              private recentSearchActionsService: RecentSearchActionsService,
              private messagesManagementService: MessagesManagementService,
              private messagesCenterNavigation: MessagesCenterNavigationService, private breadCrumbsService: BreadcrumbsService) {
    this.menuActions = this.recentSearchActionsService.actions;
    this.messagesCenterNavigation.registerTreeUpdateFunction(function () { });
  }

  ngOnInit(): void {
    this.setFilterSearch();
    this.breadCrumbsService.reset();
    this._subscription.add(this.queue$.subscribe((queue: MessagesCenterQueue) => {
      this.onQueueChange(queue);
    }));
    this._subscription.add(this.recentSearchesManagerService.recentSearchChanged$.subscribe(() => {
      this.onRecentSearchChange();
    }));
  }

  private setFilterSearch(): void {
    const searchParam = this.route.snapshot.queryParams['search'];
    const search = searchParam ? JSON.parse(searchParam) as Search : undefined;
    if (search?.type === SearchType.FILTERS_SEARCH) {
      this.applyFiltersSearch(search);
    }
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
    this.recentSearchesManagerService.resetTree();
  }

  onMenuItemClick($event: NodeActionClickEvent<RecentSearch>): void {
    $event?.action?.command($event.node);
  }

  private applyFiltersSearch(search: Search): void {
    const queue = this.getFiltersSearchQueue(search);
    this.selectTreeItem(queue);
  }

  private getFiltersSearchQueue(search: Search): RecentSearch {
    return {
      id: 'filters-search',
      alias: '',
      searchDate: null,
      searchQuery: {
        additionalParameters: {},
        searchCriteria: SearchQueryBuilderService.buildSearchQuery(search.data)
      },
      type: search.entity,
      queueType: MessagesQueueType.RECENT_SEARCH,
      routerLink: null,
      stateName: null,
      stateParams: null
    };
  }

  private onQueueChange(queue: MessagesCenterQueue): void {
    if (!queue || !queue.id) {
      this.messagesManagementService.currentTreeItem = null;
      if (this.selectedNode) {
        this._shouldCollapseTree = true;
      }
      return;
    }
    const recentSearch = this.findQueue(queue);
    if (recentSearch) {
      this.selectTreeItem(recentSearch);
      if (this._shouldCollapseTree) {
        this.navigationActionsTreeComponent.treeComponent.collapseAll();
        this._shouldCollapseTree = false;
      }
    } else {
      if (queue.id === this.messagesManagementService.queueId) {
        this.selectedNode = this.messagesManagementService.currentTreeItem;
        return;
      } else {
        this.router.navigate(['/home/page-not-found'], { replaceUrl: true });
      }
    }
  }

  private selectTreeItem(recentSearch: RecentSearch): void {
    this.selectedNode = recentSearch;
    this.messagesManagementService.onTreeItemSelect(recentSearch as any);
  }

  private findQueue(queue: MessagesCenterQueue): RecentSearch {
    const entityTree = this.recentSearchesManagerService.tree.recentSearches
      .find((tree) => tree.alias === MessagesCenterQueuesAlias[queue.entityType]) as RecentSearchesTree;

    for (const dateTree of entityTree?.recentSearches) {
      const recentSearchesQueue = (<RecentSearchesTree>dateTree).recentSearches.find(recentSearch => recentSearch.id === queue.id) as RecentSearch;
      if (recentSearchesQueue) {
        return recentSearchesQueue;
      }
    }
  }

  private onRecentSearchChange(): void {
    this.navigationActionsTreeComponent.treeComponent.onTreeModelChange();
    this.updateSelectedNode();
  }

  private updateSelectedNode(): void {
    if (this.selectedNode) {
      const queue = {
        id: this.selectedNode.id,
        type: MessagesQueueType.RECENT_SEARCH,
        entityType: this.selectedNode.type as string as MessagesType
      };
      this.selectedNode = this.findQueue(queue);
      this.messagesManagementService.currentTreeItem = this.selectedNode;
    }
  }
}
