import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IKeyboardShortcutsResourceService } from './interfaces/keyboard-shortcuts-resource-service.interface';
import { KeyboardShortcutsResource } from '../models/keyboard-shortcuts-resource.model';

@Injectable()
export class KeyboardShortcutsResourceService implements IKeyboardShortcutsResourceService {

  constructor(private http: HttpClient) { }

  getShortcuts(resourceUrl: string): Observable<KeyboardShortcutsResource> {
    return this.http.get<KeyboardShortcutsResource>(resourceUrl);
  }

}
