import { Component, OnInit } from '@angular/core';
import { Reasons } from '../../models/reasons.model';
import { IValueChange } from '../../../core/interfaces/form-value-change.interface';
import { ProfileFormTemplateProviders } from '../../../core/templates/profile-form.template.providers';
import {AbstractProfileFormComponent} from "../../abstract/abstract-profile/abstract-profile-form.component";
import {BFOnFormLoadStart, BFOnInit, BFOnValueChange} from "../../../core/interfaces/sdk-profile-hooks.interface";

@Component({
  selector: 'app-reasons',
  templateUrl: '../../../core/templates/profile-form.template.html',
  styleUrls: ['../../../core/templates/profile-form.template.scss'],
  providers: ProfileFormTemplateProviders
})
export class ReasonsComponent extends AbstractProfileFormComponent implements BFOnInit, BFOnFormLoadStart, BFOnValueChange {

  fgppOnInit(): void {
    this.setPrefetchDrilldownList(Reasons.REASONTYPE);
  }

  fgppFormLoadStart(): void {
    this.setMaxlength();
  }

  fgppValueChanges(change: IValueChange): void {
    if (change.control === Reasons.REASONTYPE) {
      this.setMaxlength();
      this.formMgr.get(Reasons.REASONCODE).reset();
      this.formMgr.get(Reasons.REASONDESCRIPTION).reset();
    }
  }

  setMaxlength() {
    const reason = this.getDrilldownData(Reasons.REASONTYPE);
    if (reason && reason['REASON_TYPES.CODE_LENGTH']) {
      this.layoutEvent.setProperty(Reasons.REASONCODE, 'maxLength', reason['REASON_TYPES.CODE_LENGTH']);
    }
    if (reason && reason['REASON_TYPES.DESCR_LENGTH']) {
      this.layoutEvent.setProperty(Reasons.REASONDESCRIPTION,'maxLength', reason['REASON_TYPES.DESCR_LENGTH']);
    }
  }

}
