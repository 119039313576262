import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params, Router } from "@angular/router";
import {ProfilesManagementService} from "../../../../profiles/services/profiles-management.service";
import {RulesAssociationApiService} from "../services/rules-association-api.service";
import {RulesAssociationCommonService} from "../services/rules-association-common.service";
import {AuthenticationService} from "../../../../authentication/services/authentication.service";
import {RuleStatuses} from "../interfaces/rule-statuses.interface";
import {TaskBarService} from "../../../../core/navigation/task-bar/services/task-bar.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-rules-association',
  templateUrl: './rules-association.component.html',
  styleUrls: ['./rules-association.component.scss']
})
export class RulesAssociationComponent implements OnInit {
  public rulesAssociationDetails: any;
  subscription = new Subscription();
  routeSubscription: any;
  constructor(private router: Router,
              private route: ActivatedRoute,
              private profilesService: ProfilesManagementService,
              private rulesAssociationApiService: RulesAssociationApiService,
              public rulesAssociationCommonService: RulesAssociationCommonService,
              private authenticationService: AuthenticationService,
              public taskBarService: TaskBarService, ) {
    const paramSub = this.route.params.subscribe((params) => {
      this.profilesService.setProfileId(params['profileId']);
    });
    this.subscription.add(paramSub);
  }

  ngOnInit(): void {
    this.rulesAssociationCommonService.isNeedCheckBeforeClose = true;
    this.rulesAssociationCommonService.setRuleAssociationStatuses(null);
    this.taskBarService.taskBarShowState = !this.taskBarService.taskBarState.showSideBar;
    this.taskBarService.setSideNavigationStateOnClick(this.taskBarService.taskBarShowState);
    this.routeSubscription = this.route.queryParams.subscribe((queryParams) => {
      if(queryParams && queryParams['uniqueRecId']) {
        this.getRuleAssociationDetails(queryParams);
      } else if(queryParams && queryParams['mode'] === 'create') {
        this.initializeRuleAssociation(queryParams);
      }
    });
  }

  private getRuleAssociationStatuses(queryParams: any, rulesAssociationDetails: any) : RuleStatuses{
    const isInitiator = this.authenticationService.userData.userId === rulesAssociationDetails.INITIATOR;
    const isRuleForApproval = queryParams.mode !== 'create' && ('PN' === rulesAssociationDetails.PROFILE_CHANGE_STATUS &&
      (!isInitiator || (isInitiator && rulesAssociationDetails.IS_SAME_APPROVER)));
    const isLockedByAnotherUser = queryParams.mode !== 'create' && (rulesAssociationDetails.IS_LOCKED_BY_ANOTHER_USER);
    const isReadOnly = isLockedByAnotherUser || (isRuleForApproval && queryParams.loadType !== '3') ||
      (!isRuleForApproval && queryParams.loadType === '3' && isInitiator && !rulesAssociationDetails.IS_EDITABLE)
      || (queryParams.loadType === '2' && 'FU' === rulesAssociationDetails.PROFILE_CHANGE_STATUS);

    const isWritable = queryParams.mode === 'create' || rulesAssociationDetails.IS_WRITABLE;

    return {
      isCreate: queryParams.mode === 'create',
      isCreateMode: queryParams.mode === 'create',
      isEditMode: queryParams.mode === 'edit',
      isRuleForApproval: isRuleForApproval,
      isLockedByAnotherUser: isLockedByAnotherUser,
      isReadOnly: isReadOnly,
      loadType: queryParams.loadType,
      isEditable: isWritable || rulesAssociationDetails.IS_EDITABLE,
      isInitiator: isInitiator,
      isWritable: isWritable,
      isStatusHold: 'HD' === rulesAssociationDetails.REC_STATUS,
      isStatusDeleted: 'DL' === rulesAssociationDetails.REC_STATUS,
      isStatusNew: 'NW' === rulesAssociationDetails.REC_STATUS,
      isStatusActive: 'AC' === rulesAssociationDetails.REC_STATUS,
      isChangeStatusPN: 'PN' === rulesAssociationDetails.PROFILE_CHANGE_STATUS,
      isChangeStatusNO: 'NO' === rulesAssociationDetails.PROFILE_CHANGE_STATUS,
      isChangeStatusFU: 'FU' === rulesAssociationDetails.PROFILE_CHANGE_STATUS,
      canUserApproveOwnRules: rulesAssociationDetails.IS_SAME_APPROVER,
      isOpenedFromApprovalScreen: queryParams.loadType === '3'
    }
  }

  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
    this.subscription.unsubscribe();
  }

  private getRuleAssociationDetails(queryParams: Params) {
    this.rulesAssociationApiService.getRulesAssociationDetails(queryParams['uniqueRecId'], queryParams['loadType']).subscribe((res) => {
      this.rulesAssociationDetails = res.contentHolder;
      this.rulesAssociationCommonService.setOriginalRules(res.contentHolder.RULES ? res.contentHolder.RULES : []);
      this.rulesAssociationDetails.EFFECTIVE_DATE = res.contentHolder.EFFECTIVE_DATE ?  parseInt(res.contentHolder.EFFECTIVE_DATE): res.contentHolder.EFFECTIVE_DATE;
      this.rulesAssociationDetails.ruleSubTypeValue = res.contentHolder.RULE_SUB_TYPE;
      this.rulesAssociationCommonService.rule = this.rulesAssociationDetails;
      this.rulesAssociationApiService.getRuleTypeDetails({
        'typeId': this.rulesAssociationDetails.RULE_TYPE_ID,
        'office':this.rulesAssociationDetails.OFFICE
      }).subscribe((res) => {
        if (res.subType) {
          this.rulesAssociationDetails.subType = res.subType;
        }
        if (res.subTypeProfileInfo) {
          this.rulesAssociationDetails.subTypeProfileInfo = res.subTypeProfileInfo;
        }
        if (res.ruleSubType.profileId === '') {
          this.rulesAssociationDetails.ruleSubTypeValue = 'N/A';
          this.rulesAssociationDetails.ruleSubTypeFlag = true;
        }
        this.rulesAssociationDetails.businessDate = parseInt(res.businessDate); //this is sanitized on save
        /*this.rulesAssociationDetails.ruleSubTypeValue = res.ruleSubTypeValue;
        this.rulesAssociationDetails.ruleSubTypeFlag = res.ruleSubTypeFlag;
        this.rulesAssociationDetails.subType = res.RULE_SUB_TYPE;
        if (!this.rulesAssociationDetails.ruleSubTypeFlag) {
          this.rulesAssociationDetails.ruleSubTypeValue = res.subType;
        }*/
        if (this.rulesAssociationDetails.EFFECTIVE_DATE == '' && res.businessDate) {
          this.rulesAssociationDetails.EFFECTIVE_DATE = parseInt(res.businessDate);
        }
      });
      this.rulesAssociationCommonService.setRuleAssociationStatuses(this.getRuleAssociationStatuses(queryParams, this.rulesAssociationDetails));
    });
  }

  private initializeRuleAssociation(queryParams: Params) {
    this.rulesAssociationDetails = {};
    this.rulesAssociationDetails['OFFICE'] = queryParams['office'];
    this.rulesAssociationDetails['REC_STATUS'] = 'NW';
    this.rulesAssociationDetails['PROFILE_CHANGE_STATUS'] = 'PN';
    this.rulesAssociationDetails['RULE_SUB_TYPE'] = '0';
    this.rulesAssociationDetails['subType'] = {
      profileId: ''
    };
    this.rulesAssociationCommonService.rule = this.rulesAssociationDetails;
    this.rulesAssociationCommonService.setRuleAssociationStatuses(this.getRuleAssociationStatuses(queryParams, this.rulesAssociationDetails));
    this.rulesAssociationCommonService.setOriginalRules([]);
  }
}
