import { GridContainerConfig } from '../../../grid/models/grid-container-config.model';
import { GridHeaderConfig } from '../../../grid/models/grid-header-config.model';
import { GridFooterConfig } from '../../../grid/models/grid-footer-config.model';
import { GroupActionsConfig } from '../../../grid/models/group-actions-config.model';

export const MessagesGridHeaderConfig: GridHeaderConfig = {
  showLastUpdate: false,
  showScopes: false,
  showRefresh: true,
  showLeftMenu: true,
  showRightMenu: true,
  showFavorite: true,
  showType: true,
  showTitle: true,
  showAutoFeeder: true,
  showFilter: true,
  showAllActions: true,
  showCreate: true,
  disableCreate: false,
  processingScope: true
};

export const MessageGridConfig: GroupActionsConfig = {
  showGroupActionIcon: true,
  showBeforeAfter: true,
  showAddNotes: true,
  showGroupActions: true,
  showFollowUpFlag:true,
};

export const MessagesGridFooterConfig: GridFooterConfig = {
  showResultsAndCounts : true,
  groupActions: MessageGridConfig,
  showCallerDetails: false,
  showCreateFromTemplate: false,
  showResultsWarning: false
};

export const CallbackMessages: GridFooterConfig = {
  showResultsAndCounts : true,
  groupActions: MessageGridConfig,
  showCallerDetails: true,
  showCreateFromTemplate: false,
  showResultsWarning: false
};

export const MessagesGridContainerConfig: GridContainerConfig = {
  gridHeaderConfig: MessagesGridHeaderConfig,
  gridFooterConfig: MessagesGridFooterConfig,
};

export const CallbackMessagesGridContainerConfig: GridContainerConfig = {
  gridHeaderConfig: MessagesGridHeaderConfig,
  gridFooterConfig: CallbackMessages,
};
