import { Component, OnInit, OnDestroy, ChangeDetectorRef, AfterViewInit, ElementRef } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { StoreService } from '../../../../core/services/store.service';
import { Alert } from '../../models/alert.model';
import { AlertState } from '../../state/alert/alert.state';
import { SidebarState } from '../../state/sidebar/sidebar.state';
import { ToggleSidebar } from '../../state/sidebar/actions/toggle-sidebar.action';
import { SidebarMode } from '../../../state/models/enums/sidebar-mode.enum';
import { ALERTS_CONFIG } from '../../models/consts/alerts-config.const';

@Component({
  selector: 'app-notification-bell',
  templateUrl: './notification-bell.component.html',
  styleUrls: ['./notification-bell.component.scss']
})
export class NotificationBellComponent implements OnInit, OnDestroy, AfterViewInit {

  @Select(AlertState.getAlerts) alert$: Observable<Array<Alert>>;
  @Select(SidebarState.getSidebarMode) sidebarMode$: Observable<SidebarMode>;

  private _alertCount: number;

  subscriber = new Subscription();
  sidebarMode: SidebarMode;

  isAnimating: boolean;

  constructor(private storeService: StoreService,
              private ref: ChangeDetectorRef,
              private elem: ElementRef) { }

  ngOnInit() {
    this.subscribeToAlerts();
    this.subscribeToSidebarMode();
  }

  ngOnDestroy() {
    this.subscriber.unsubscribe();
  }

  get alertCount() {
    return this._alertCount;
  }

  set alertCount(value: number) {
    const newAlertArrived = value > this.alertCount;
    if (newAlertArrived) {
      this.notify();
    }
    this._alertCount = value;
    this.ref.detectChanges();
  }

  subscribeToAlerts() {
    const subscription = this.alert$.subscribe((alerts: Array<Alert>) => {
      this.alertCount = alerts ? alerts.length : 0;
    });
    this.subscriber.add(subscription);
  }

  notify() {
    this.isAnimating = true;
    setTimeout(() => {
      this.isAnimating = false;
    }, ALERTS_CONFIG.bellTimeout);
  }

  subscribeToSidebarMode() {
    const subscription = this.sidebarMode$.subscribe((mode: SidebarMode) => {
      this.sidebarMode = mode;
    });
    this.subscriber.add(subscription);
  }

  onBellClick() {
    this.storeService.dispatch(new ToggleSidebar());
  }

  ngAfterViewInit(): void {
    this.elem.nativeElement.setAttribute('azure-id', 'notification-bell');
  }
}
