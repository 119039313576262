import { Injectable } from '@angular/core';
import { MULTI_TABS_DEFAULT_STATE } from '../models/consts/multi-tabs-default-state.const';
import { MultiTabsState } from '../models/multi-tabs-state.model';
import { IMultiTabsStorageService } from './interfaces/multi-tabs-storage-service.interface';

export const MULTI_TABS_STATE = 'multi-tabs-state';

@Injectable()
export class MultiTabsStorageService implements IMultiTabsStorageService {

  getState(): MultiTabsState {
    const item = localStorage.getItem(MULTI_TABS_STATE);
    return item ? JSON.parse(item) : { ...MULTI_TABS_DEFAULT_STATE };
  }

  setState(state: MultiTabsState): void {
    localStorage.setItem(MULTI_TABS_STATE, JSON.stringify(state));
  }

  resetState(): void {
    localStorage.removeItem(MULTI_TABS_STATE);
  }

  addTab(value: string): void {
    const state = this.getState();
    state.activeTabs.push(value);
    this.setState(state);
  }

  removeTab(value: string): void {
    const state = this.getState();
    const index = state.activeTabs.indexOf(value);
    if (index !== -1) {
      state.activeTabs.splice(index, 1);
      this.setState(state);
    }
  }

  dismissTour(value: boolean): void {
    const state = this.getState();
    state.isTourDismissed = value;
    this.setState(state);
  }

}
