import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError, map, throwError } from 'rxjs';
import { DownloadResponse } from '../models/download-response.model';

@Injectable({
  providedIn: 'root'
})
export class FileDownloaderService {

  constructor(private http: HttpClient) { }

  download(url: string, body: any, httpParams?: HttpParams) {
    this.post(url, body, httpParams)
      .subscribe((response: DownloadResponse) => this.downloadFile(response));
  }

  private post(url: string, body: any, httpParams?: HttpParams): Observable<DownloadResponse> {
    return this.http.post(url, body,
      { params: httpParams, responseType: 'blob', observe: 'response' }).pipe(
        map((response: HttpResponse<Blob>) => {
          return {
            blob: response.body,
            type: response.headers.get('Content-Type'),
            fileName: response.headers.get('File-Name') || url.split('/').pop()
          };
        }),
        catchError((error: HttpErrorResponse) => throwError(error)));
  }

   downloadFile(response: DownloadResponse): void {

    const blob = response.blob;

    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = data;
    link.download = response.fileName;

    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
  }
}
