<body>
<div class="wrapper">
  <div class="container">
    <div class="col-left">
      <h1>{{ 'Sign In Failed' }}</h1>
      <p>{{ 'If the problem persists, contact your administrator.' }}</p>
      <a class="btn" (click)="onButtonClicked($event)">{{'Try Again'}}</a>
    </div>
    <div class="col-right">
      <img src="assets/images/png/robot.png" alt="Broken robot image">
    </div>
  </div>
</div>
</body>

