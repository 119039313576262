import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TaskBarService } from 'projects/gpp/src/app/core/navigation/task-bar/services/task-bar.service';
import { RuleTypeService } from '../../../services/rule-type.service';
import { RuleMetadata, Rule, RuleStatuses } from '../../models';
import { RuleCommonService } from '../../services/rule-common.service';

@Component({
  selector: 'app-rule-form',
  templateUrl: './rule-form.component.html',
  styleUrls: ['./rule-form.component.scss']
})
export class RuleFormComponent implements OnInit, OnDestroy {

  metadata: RuleMetadata;
  rule: Rule;
  ruleStatuses: RuleStatuses;

  constructor(private route: ActivatedRoute,
              private taskBarService: TaskBarService,
              private ruleTypeService: RuleTypeService,
              public ruleCommonService: RuleCommonService) { }

  ngOnInit(): void {
    this.metadata = this.route.snapshot.data.metadata;
    this.rule = this.route.snapshot.data.ruleContainer.rule;
    this.ruleStatuses = this.route.snapshot.data.ruleContainer.statuses;
    this.closeTaskBar();
  }

  ngOnDestroy(): void {
    if (this.ruleTypeService.fromSearch) {
      this.closeTaskBar();
      return;
    }

    if (this.taskBarService.taskBarShowState === false) {
      this.openTaskBar();
    }
  }

  openTaskBar(): void {
    if (this.taskBarService.taskBarShowState === false) {
      this.toggleTaskBar();
    }
  }

  closeTaskBar(): void {
    if (this.taskBarService.taskBarShowState === true) {
      this.toggleTaskBar();
    }
  }

  toggleTaskBar(): void {
    this.taskBarService.taskBarShowState = !this.taskBarService.taskBarShowState;
  }

}
