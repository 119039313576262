export enum ProfileUpdate {
  ACTION = 'PROFILE_UPDATE-ACTION',
  APPROVAL_STATUS = 'PROFILE_UPDATE-APPROVAL_STATUS',
  APPROVER = 'PROFILE_UPDATE-APPROVER',
  APPROVE_DATE = 'PROFILE_UPDATE-APPROVE_DATE',
  EFFECTIVE_DATE = 'PROFILE_UPDATE-EFFECTIVE_DATE',
  FK_PROFILE_UID = 'PROFILE_UPDATE-FK_PROFILE_UID',
  INITIATOR = 'PROFILE_UPDATE-INITIATOR',
  INIT_DATE = 'PROFILE_UPDATE-INIT_DATE',
  NOTE = 'PROFILE_UPDATE-NOTE',
  PROFILE_ID = 'PROFILE_UPDATE-PROFILE_ID',
  TIME_STAMP = 'PROFILE_UPDATE.TIME_STAMP',
  UID = 'VIRTUAL.UID',
}
