import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OldAbstractFormComponent } from '../../abstract-form/old-abstract-form.component';
import { DynamicComponentLoaderComponent } from '../../../ui-components/dynamic-component-loader/dynamic-component-loader.component';

@Component({
  selector: 'app-dynamic-form-loader',
  templateUrl: './dynamic-form-loader.component.html'
})
export class DynamicFormLoaderComponent implements OnInit {
  injectableData = {};

  @Input() formComponent: OldAbstractFormComponent;
  @Input() formData;

  @ViewChild(DynamicComponentLoaderComponent) instance: OldAbstractFormComponent;


  constructor(private route: ActivatedRoute) {
    this.formComponent = this.route.snapshot.data.formComponent;
    this.formData = this.route.snapshot.data.formData;
  }

  ngOnInit() {
    this.injectableData = {
      'formData': this.formData
    };
  }
}
