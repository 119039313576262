import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { Observable, map, of } from 'rxjs';
import { AuthenticationService } from '../../../authentication/services/authentication.service';
import { Rule, RuleCriteria, RuleStatuses, RuleSubType } from '../../single-rule/models';
import { RuleCommonService } from '../../single-rule/services/rule-common.service';
import { RuleConditionsAlgorithmsService } from '../../single-rule/services/rule-conditions-algorithms.service';
import { RuleConditionsService } from '../../single-rule/services/rule-conditions.service';
import { RuleService } from '../../single-rule/services/rule.service';

export const RuleResolver: ResolveFn<{ rule: Rule, statuses: RuleStatuses }> =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<{ rule: Rule, statuses: RuleStatuses }> => {

    const authenticationService = inject(AuthenticationService);
    const ruleCommonService = inject(RuleCommonService);
    const ruleConditionsService = inject(RuleConditionsService);
    const ruleConditionsAlgorithmsService = inject(RuleConditionsAlgorithmsService);
    const ruleService = inject(RuleService);

    const createNewRule = (): Rule => {
      const newRule = {} as Rule;
      newRule.OFFICE = route.queryParams.office;
      newRule.CONDITIONS = {} as RuleCriteria;
      newRule.BASE_CONDITION_IND = 0;
      newRule.DEPARTMENT = '';
      newRule.DESCRIPTION = '';
      newRule.SEC_RULE_ACTION_UID = '0';
      newRule.RULE_ACTION_UID = '0';
      newRule.RULE_TYPE_ID = '';
      newRule.RULE_NAME = '';
      newRule.RULE_SUB_TYPE = '0';
      newRule.PROFILE_CHANGE_STATUS = 'PN';
      newRule.REC_STATUS = 'NW';
      newRule.conditionsBank = [];
      newRule.flatConditionsBank = [];
      newRule.actionDetails = [];
      newRule.actionDetails.actionContentSource = '';
      newRule.subType = { profileId: '' } as RuleSubType;

      ruleConditionsService.conditions = ruleConditionsAlgorithmsService.fromBinaryTreeToNTree(JSON.parse(JSON.stringify(newRule.CONDITIONS)));

      return newRule;
    };

    const getStatuses = () => {
      const rule = ruleCommonService.rule;
      const isInitiator = authenticationService.userData.userId === rule.INITIATOR;
      const isRuleForApproval = route.queryParams.mode !== 'create' && ('PN' === rule.PROFILE_CHANGE_STATUS &&
        (!isInitiator || (isInitiator && rule.IS_SAME_APPROVER)));
      const isLockedByAnotherUser = route.queryParams.mode !== 'create' && (rule.IS_LOCKED_BY_ANOTHER_USER);
      const isReadOnly = isLockedByAnotherUser || (isRuleForApproval && route.queryParams.loadType !== '3') ||
        (!isRuleForApproval && route.queryParams.loadType === '3' && isInitiator && !rule.IS_EDITABLE) ||
        (route.queryParams.loadType === '2' && 'FU' === rule.PROFILE_CHANGE_STATUS);

      const isWritable = route.queryParams.mode === 'create' || rule.IS_WRITABLE;

      return {
        isCreate: route.queryParams.mode === 'create',
        isEditMode: route.queryParams.mode === 'edit',

        isRuleForApproval: isRuleForApproval,
        isLockedByAnotherUser: isLockedByAnotherUser,
        isReadOnly: isReadOnly,
        loadType: route.queryParams.loadType,
        isEditable: rule.IS_EDITABLE,
        isInitiator: isInitiator,
        isWritable: isWritable,

        isStatusHold: 'HD' === rule.REC_STATUS,
        isStatusDeleted: 'DL' === rule.REC_STATUS,
        isStatusNew: 'NW' === rule.REC_STATUS,
        isStatusActive: 'AC' === rule.REC_STATUS,
        isChangeStatusPN: 'PN' === rule.PROFILE_CHANGE_STATUS,
        isChangeStatusNO: 'NO' === rule.PROFILE_CHANGE_STATUS,
        isChangeStatusFU: 'FU' === rule.PROFILE_CHANGE_STATUS,

        canUserApproveOwnRules: rule.IS_SAME_APPROVER,
        isOpenedFromApprovalScreen: route.queryParams.loadType === '3',
        isOpenedFromInactiveScreen: route.queryParams.loadType === '6'
      };
    };

    let rule$: Observable<Rule>;
    if (route.queryParams.mode === 'create') {
      rule$ = of(createNewRule());
    } else {
      rule$ = ruleService.doGetRule(route.queryParams.ruleId, route.queryParams.loadType);
    }
    return rule$.pipe(map((rule: Rule) => {
      ruleCommonService.rule = rule;
      ruleCommonService.originalRule = JSON.parse(JSON.stringify(rule));
      const statuses = getStatuses();
      return { rule, statuses };
    }));
  };
