<mat-form-field [floatLabel]="'always'" class="dh-form-field">
  <mat-label> {{label}} </mat-label>
  <div matTooltip="{{tooltip}}" [matTooltipDisabled]="isTooltipDisable">
    <input matInput type="text" value={{controller.value}} [formControl]="controller"
           (input)="onInput($event)"
           (keydown)="onInput($event)"
           [readonly]="blockFreeText"
           (change)="onDrillDownInputChange(formControlName, $event)"
           [maxlength]="allowTypingMaxLength" #input>
  </div>
  <span matSuffix [hidden]="isHiddenDrillDown">
      <button *ngIf="!isDrillDownButtonClicked; else showLoading" mat-icon-button [attr.azure-id]="id + '-icon'" type="button" class="drilldown-btn btn dh-hoverable" (click)="onDrillDownClick($event)"
              [disabled]="controller.disabled" (focus)="onDrillDownButtonFocus()" (blur)="onDrillDownButtonBlur()">
        <app-fn-ui-icon icon="dots_horizontal"></app-fn-ui-icon>
      </button>
      <ng-template #showLoading>
            <mat-spinner class="dh-drilldown-loading"  diameter="15"></mat-spinner>
      </ng-template>
    </span>
  <mat-hint
    *ngIf="maxLength && (controller.valid || !controller.touched)"
    [ngClass]="{'mat-hint-error': passMaxLength}"
    align="end">{{input.value?.length || 0}}/{{maxLength}}
  </mat-hint>
  <mat-hint class="drill-down-hint-error">{{  metadata?.hint || controller.hint | translate }}</mat-hint>
  <mat-error *ngIf="!controller.valid && controller.touched">
    <app-validation-errors-message
      [errors]="controller.errors"
      [validations]="metadata?.validations">
    </app-validation-errors-message>
    <mat-hint
      align="end"
      class="float-right text-right mat-hint-error"
      *ngIf="shouldShowHint">
      {{input.value?.length || 0}}/{{maxLength}}
    </mat-hint>
  </mat-error>
  <!--error is stronger than hint-->
  <app-hint-validation *ngIf="controller.hint && controller.valid" [hint]="controller.hint"></app-hint-validation>
</mat-form-field>
