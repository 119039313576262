import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-fn-ui-alert-modal',
  templateUrl: './conditions-not-allowed-modal.component.html',
  styleUrls: ['./conditions-not-allowed-modal.component.scss']
})
export class ConditionsNotAllowedModalComponent {

  constructor(@Inject(MAT_DIALOG_DATA) private _params) { }

  get params() {
    return this._params;
  }

}
