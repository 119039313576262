import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NotificationService, TreeConfig } from '@fgpp-ui/components';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { SitemapNode } from '../../../core/navigation/sitemap/models';
import {PbiTaskbarItem} from "../../enums/pbi-taskbar-item.enum";

@Component({
  selector: 'app-insight-tree',
  templateUrl: './insight-tree.component.html',
  styleUrls: ['./insight-tree.component.scss']
})
export class InsightTreeComponent implements OnInit, OnDestroy {

  @Input() navigationTree: SitemapNode;

  treeConfig: Partial<TreeConfig> = {
    expandable: (node) => false,
    selectable: (node) => true
  };

  private _subscriber = new Subscription();
  private _selectedNode: SitemapNode;

  get selectedNode(): SitemapNode {
    return this._selectedNode;
  }

  constructor(private route: ActivatedRoute,
              private notificationService: NotificationService,
              private translateService: TranslateService) { }

  ngOnInit(): void {
    this.subscribeRouteData();
    this.subscribeRouteParams();
  }

  ngOnDestroy(): void {
    this._subscriber.unsubscribe();
  }

  private subscribeRouteData(): void {
    const routeDataSubscriber = this.route.data.subscribe((data) => {
      this.navigationTree = data.insightTaskBar;
      if (this.navigationTree.nodes === null) {
        this.navigationTree.nodes = [];
        this.notificationService.error(this.translateService.instant('power-bi.errors.missing-configuration'));
      }
    });
    this._subscriber.add(routeDataSubscriber);
  }

  private subscribeRouteParams(): void {
    const routeParamsSubscriber = this.route.firstChild.queryParams.subscribe(params => {
      this._selectedNode = this.getSelectedNode(params.report);
    });
    this._subscriber.add(routeParamsSubscriber);
  }

  private getSelectedNode(id: string): SitemapNode {
    return this.navigationTree.id === PbiTaskbarItem.OPERATIONAL_DASHBOARD ? this.navigationTree.nodes[0]
      : this.navigationTree.nodes?.find((node: SitemapNode) => node.id === id);
  }

}
