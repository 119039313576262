import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { GetRateResponse, GetRateRequestBody, CheckBalanceResponse, CheckBalanceRequestBody, GetApproveResponse } from '../models/fx-rate.model';

@Injectable()
export class FxRateApiService {

  getRateUrl = 'do/fxRateController/getFxRate';
  approveRateUrl = 'do/fxRateController/approveFxRate';
  rejectRateUrl = 'do/fxRateController/rejectFxRate';
  checkBalanceForFxRateUrl = 'do/fxRateController/checkBalanceForFxRate';
  forceUrl = 'do/fxRateController/manualForceNsf';

  constructor(private http: HttpClient) {
  }

  getFxRate(getRateRequestBody: GetRateRequestBody): Observable<GetRateResponse> {
    if ((<any>window).automation === true) return this.http.post<GetRateResponse>('/gppmock/fxRateController/getFxRate', getRateRequestBody);
    return this.http.post<GetRateResponse>(this.getRateUrl, getRateRequestBody);
  }

  approveRate(getRateRequestBody: GetRateRequestBody): Observable<GetApproveResponse> {
    if ((<any>window).automation === true) return this.http.post<GetApproveResponse>('/gppmock/fxRateController/approveFxRate', getRateRequestBody);
    return this.http.post<GetApproveResponse>(this.approveRateUrl, getRateRequestBody);
  }

  rejectRate(getRateRequestBody: GetRateRequestBody): Observable<any> {
    if ((<any>window).automation === true) return this.http.post('/gppmock/fxRateController/rejectFxRate', getRateRequestBody);
    return this.http.post(this.rejectRateUrl, getRateRequestBody);
  }

  checkBalanceForFxRate(checkBalanceRequestBody: CheckBalanceRequestBody): Observable<CheckBalanceResponse> {
    if ((<any>window).automation === true) return this.http.post<CheckBalanceResponse>('/gppmock/fxRateController/checkBalanceForFxRate', checkBalanceRequestBody);
    return this.http.post<CheckBalanceResponse>(this.checkBalanceForFxRateUrl, checkBalanceRequestBody);
  }

  force(uid: string): Observable<any> {
    const params = new HttpParams().set('uid', uid);
    if ((<any>window).automation === true) return this.http.get('/gppmock/fxRateController/manualForceNsf', { params });
    return this.http.get(this.forceUrl, { params });
  }
}
