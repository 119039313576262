import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { SearchFilter } from '../../shared/models/search-filter.model';
import { Entity } from '../../shared/models/enums/entity.enum';
import { Resource } from '../models/resource.model';
import { GridDataInitialState } from '../models/consts/grid-data-initial-state.const';
import { GridHelperService } from './grid-helper.service';
import { GridConfigService } from './grid-config.service';
import { GridConfig, GridColumn } from '@fgpp-ui/grid';
import { TableSort } from '@fgpp-ui/grid';
import { PaginatorConfig, PaginatorData } from '@fgpp-ui/grid';
import { DialogResult } from '@fgpp-ui/grid';
import { BehaviorSubject, Subject, Subscription, switchMap, take } from 'rxjs';
import { GridSearchRequest } from '../models/grid-search-request.model';
import { SearchRequestBuilderService } from './search-request-builder.service';
import { FormatsService } from '../../shared/services/formats.service';
import { SearchQueryBuilderService } from '../../shared/services/search-query-builder.service';
import { GridPreferences } from '../models/grid-preferences.model';
import { GridDataApiParameters } from '../models/grid-data-api-parameters.model';
import { GridColumnsModel } from '../models/grid-columns.model';
import { UserSettingsService } from '../../core/user-settings/services/user-settings.service';
import { DialogDataResponse } from '../../shared/fn-ui-export/models/dialog-data-response.model';

@Injectable()
export abstract class GridManagementService<T extends GridSearchRequest> implements OnDestroy {
  public gridData$: BehaviorSubject<Resource> = new BehaviorSubject(GridDataInitialState);
  protected gridDataSubscriber$: Subject<GridDataApiParameters> = new Subject();
  public gridDataReset$: Subject<void> = new Subject();
  public fetchingDataInProgress$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public gridDataReset = this.gridDataReset$.asObservable();
  public fetchingDataInProgress = this.fetchingDataInProgress$.asObservable();
  protected gridData: Resource = GridDataInitialState;
  protected title: string;
  public currentTreeItem = undefined;
  private _selectedFilters: Array<SearchFilter> = [];
  protected searchRequest: T;
  protected externalFilters: Array<SearchFilter> = [];
  protected internalFilters: Array<SearchFilter> = [];
  protected gridConfig: GridConfig;
  public gridConfigChange$: Subject<boolean> = new Subject();
  public gridConfigChange = this.gridConfigChange$.asObservable();
  protected subscriber = new Subscription();

  get currentData() {
    if (this.gridData.columns && this.gridData.columns.length) {
      this.setStoredColumns();
    }
    return this.gridData$.asObservable();
  }

  get defaultSearchRequest(): T {
    return this.getDefaultSearchRequest();
  }

  get selectedFilters(): Array<SearchFilter> {
    return this._selectedFilters;
  }

  set selectedFilters(value: Array<SearchFilter>) {
    this._selectedFilters = value;
  }

  get availableColumns() {
    const columns = this.gridHelperService.gridColumnsService.getStoredColumns();
    return columns && columns.baseUrl === this.baseUrl ? columns.availableColumns : [];
  }

  get columnsForView() {
    if (!!this.gridData.gridData) {
      return this.gridData.columns;
    } else {
      return [];
    }
  }

  get queueId(): string {
    return this.currentTreeItem ? this.currentTreeItem.id : null;
  }

  get gridTitle(): string {
    return this.title;
  }

  abstract get baseUrl(): string;

  abstract get httpParams(): HttpParams;

  abstract get entity(): Entity;

  abstract get defaultFilters(): Array<SearchFilter>;

  protected constructor(
    protected userSettingsService: UserSettingsService,
    protected gridHelperService: GridHelperService,
    protected gridConfigService: GridConfigService,
    protected searchRequestBuilderService: SearchRequestBuilderService<T>,
    protected formatsService: FormatsService) {
    this.initGridDataSubscription();
  }

  ngOnDestroy() {
    this.subscriber.unsubscribe();
  }

  initGridDataSubscription() {
    this.subscriber.add(this.gridDataSubscriber$.pipe(switchMap((data: GridDataApiParameters): any => {
        return this.getGridData(data.getMetaData);
    })).subscribe((resource: Resource) => {
      this.resolveGridData(resource);
    }));
  }

  public resetGridData() {
    this.selectedFilters = [];
    this.gridData = GridDataInitialState;
    this.gridConfig = null;
    this.gridData$.next(this.gridData);
    this.gridDataReset$.next();
  }

  invokeGridDataChange(getMetaData?: boolean) {
    this.gridDataSubscriber$.next({
      searchRequest: this.searchRequest,
      baseUrl: this.baseUrl,
      httpParams: this.httpParams,
      getMetaData: getMetaData ? getMetaData : false
    });
  }

  // send api request
  protected getGridData(getMetaData?: boolean): Promise<Resource> {
    this.fetchingDataInProgress$.next(true);
    return this.gridHelperService.getGridData(this.searchRequest, this.baseUrl, this.httpParams, getMetaData)
    .then((response) => response, (error) => {
      this.rejectGridData(error);
    });
  }

  protected resolveGridData(resource: Resource) {
    const gridData = this.handleResponse(resource);
    if (!!gridData) {
      this.gridData = gridData;
      this.gridData.queueTitle = this.title;
      this.setTitle(gridData.baseAmountSum);
      this.setDataAggregationCount();
      this.nextGridData();
      this.fetchingDataInProgress$.next(false);
    }
  }

  protected rejectGridData(error: HttpErrorResponse) {
    console.error(error);
    this.fetchingDataInProgress$.next(false);
  }

  protected handleResponse(response: any): any {
    let gridData;
    if (Array.isArray(response)) {
      gridData = response[0];
      this.gridConfig.table.refineFiltering.showRefine = response[1].showRefine;
      this.gridConfigChange$.next(this.gridConfig.table.refineFiltering.showRefine);
    } else {
      gridData = response;
    }
    return gridData;
  }

  getMetadata(): Promise<GridColumnsModel> {
    return this.gridHelperService.getGridMetaData(this.baseUrl, this.httpParams);
  }

  nextGridData() {
    this.gridData$.next(this.gridData);
  }

  setTitle(filteredAmount?: number): void {
    if (!!this.currentTreeItem) {
      this.setQueueTitle();
    }
  }

  setQueueTitle() {
    this.gridData.queueTitle = this.title;
  }

  handleColumnSettings(result: DialogDataResponse | string): Promise<GridColumnsModel> {
    let promise;
    if (  typeof result === 'object') {
      promise = this.saveColumns(result.selectedColumns,result.applyAllQueues);
    }
    else if (result === DialogResult.RESTORE) {
      promise = this.restoreColumnSettings();
    }
    if (promise) {
      this.fetchingDataInProgress$.next(true);
      return promise.then(() => this.onColumnSettingsChange().catch(() => {
        this.fetchingDataInProgress$.next(false);
      }));
    }

  }

  onColumnSettingsChange(): Promise<GridColumnsModel> {
    return this.getMetadata();
  }

  // init search data
  getDefaultSearchRequest(): T {
    const gridConfig = this.getGridConfig();
    return <T>{
      pageNo: 1,
      sort: [],
      pageSize: gridConfig.paginator.size,
      searchQuery: {
        additionalParameters: {},
        searchCriteria: SearchQueryBuilderService.buildSearchQuery(this.defaultFilters)
      }
    };
  }

  getSearchRequest(): T {
    if (this.searchRequest) {
      return JSON.parse(JSON.stringify(this.searchRequest));
    }
    return null;
  }

  saveColumns(columns: GridColumn[],applyAllQueues=false): Promise<void> {
    return this.gridHelperService.gridApiService.saveColumns(columns, this.baseUrl, this.httpParams, applyAllQueues);
  }

  restoreColumnSettings(): Promise<void> {
    return this.gridHelperService.gridApiService.deleteColumnSettings(this.baseUrl, this.httpParams);
  }

  getGridConfig(): GridConfig {
    if (!this.gridConfig) {
      this.gridConfig = this.gridConfigService.defaultGridConfig;
      this.setPageSize(this.gridConfig.paginator);
    }
    return this.gridConfig;
  }

  setSearchPage(paginatorData: PaginatorData) {
    this.searchRequestBuilderService.buildPage(this.searchRequest, paginatorData);
    this.invokeGridDataChange(false);
  }

  setSearchSort(sort: TableSort) {
    this.resetPageNo();
    this.searchRequestBuilderService.buildSort(this.searchRequest, sort);
    this.invokeGridDataChange(false);
  }

  async setInternalFilters(filters: Array<SearchFilter>, getMetaData?: boolean): Promise<void> {
    this.internalFilters = filters;
    return this.setSearchFilters(getMetaData);
  }

  async setExternalFilters(filters: Array<SearchFilter>, getMetaData = false): Promise<any> {
    this.externalFilters = filters;
    return this.setSearchFilters(getMetaData);
  }

  setAdditionalParameters(additionalParameters: { [key: string]: string | number }, getMetaData = false) {
    this.searchRequestBuilderService.buildAdditionalParameters(this.searchRequest, additionalParameters);
    this.invokeGridDataChange(getMetaData);
  }

  resetSearchRequest() {
    this.internalFilters = [];
    this.externalFilters = [];
    this.searchRequest = this.defaultSearchRequest;
  }

  initializeSearchRequest() {
    this.searchRequest = this.defaultSearchRequest;
    this.searchRequestBuilderService.buildFilters(this.searchRequest, this.defaultFilters || []);
  }

  saveGridPreferences(showRefine: boolean) {
    const gridPreferences: GridPreferences = {
      showRefine: showRefine
    };
    this.gridHelperService.gridApiService.saveGridPreferences(gridPreferences, this.baseUrl, this.httpParams);
  }

  private setPageSize(paginatorConfig: PaginatorConfig) {
    const pageSize = this.gridConfigService.itemsPerPage;
    if (pageSize) {
      paginatorConfig.size = pageSize === 1000 ? 100 : pageSize;
    }
  }

  setPageIndex(paginatorConfig: PaginatorConfig): boolean {
    if (this.searchRequest && this.searchRequest.pageNo - 1 !== paginatorConfig.index) {
      paginatorConfig.index = this.searchRequest.pageNo - 1;
      return true;
    }
    return false;
  }

  private resetPageNo() {
    this.searchRequestBuilderService.resetPageNo(this.searchRequest);
  }

  protected async setSearchFilters(getMetaData: boolean = false): Promise<void> {
    return new Promise(resolve => {
      this.resetPageNo();
      const filters = this.defaultFilters.concat(this.externalFilters).concat(this.internalFilters);
      this.searchRequestBuilderService.buildFilters(this.searchRequest, filters);
      this.invokeGridDataChange(getMetaData);
      this.subscriber.add(this.gridData$.pipe(take(1)).subscribe(() => {
        resolve();
      }));
    });
  }

  protected setStoredColumns() {
    const storedColumns = this.gridHelperService.gridColumnsService.getStoredColumns();
    storedColumns.gridColumns = this.gridData.columns;
    storedColumns.availableColumns = this.gridData.availableColumns;
    storedColumns.baseUrl = this.baseUrl;
  }

  protected setDataAggregationCount() {
    const dataAggregationCount = this.gridConfig.facetFiltering.facetsFilteringContent.maxAggregationCount;
    if (!dataAggregationCount) {
      return;
    }
    this.gridData.gridData.dataAggregations?.forEach(aggregation => {
      if (Array.isArray(aggregation.options) && aggregation.options.length > dataAggregationCount) {
        aggregation.options = aggregation.options.slice(0, dataAggregationCount);
      }
    });
  }

}
