import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SystemAccountGroupsTree, CustomAccountGroupsTree, PartyGroupsTree, LiquidityFavorite } from '../models';
import { ILiquidityApiService } from './interfaces/liquidity-api-service.interface';

@Injectable()
export class LiquidityApiService implements ILiquidityApiService {

  private _baseUrl = 'do/liquidity/';

  constructor(private httpClient: HttpClient) { }

  getSystemAccountGroups(): Observable<SystemAccountGroupsTree> {
    return this.httpClient.get<SystemAccountGroupsTree>(this._baseUrl + 'systemAccounts');
  }

  getCustomAccountGroups(): Observable<CustomAccountGroupsTree> {
    return this.httpClient.get<CustomAccountGroupsTree>(this._baseUrl + 'customAccounts');
  }

  getPartyGroups(): Observable<PartyGroupsTree> {
    return this.httpClient.get<PartyGroupsTree>(this._baseUrl + 'partyGroups');
  }

  getFavorites(): Observable<Array<string>> {
    return this.httpClient.get<Array<string>>(this._baseUrl + 'favorites');
  }

  addFavorite(favorite: LiquidityFavorite): Observable<void> {
    return this.httpClient.post<void>(this._baseUrl + 'favorites', favorite);
  }

  deleteFavorite(id: number): Observable<void> {
    return this.httpClient.delete<void>(this._baseUrl + 'favorites/' + id);
  }

  getAccount(accountUid: string): Observable<any> {
    return this.httpClient.get(this._baseUrl + 'account', { params: { accountUid } });
  }

}
