import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ProfilesManagementService } from './services/profiles-management.service';
import { ProfilesNavigationTreeComponent } from './components/profiles-navigation-tree/profiles-navigation-tree.component';
import { UiComponentsModule } from '../ui-components/ui-components.module';
import { ProfilesHelperService } from './services/profiles-helper.service';
import { ProfilesApiService } from './services/profiles-api.service';
import { ProfilesGridComponent } from './components/profiles-grid/profiles-grid.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { GridModule } from '../grid/grid.module';
import { NotesGridFooterComponent } from './components/notes-grid-footer/notes-grid-footer.component';
import { NoteApiService } from './services/note-api.service';
import { ProfilesSearchRequestBuilderService } from './services/profiles-search-request-builder';
import { RulesProfilesManagementService } from './services/rules-profiles-management.service';
import { RulesGridComponent } from './components/rules-grid/rules-grid.component';
import { CallerProfilesManagementService } from './services/caller-profiles-management.service';
import { ProfilesNavigationService } from './services/profiles-navigation.service';
import { AuditTrailRulesGridComponent } from './components/audit-trail-rules-grid/audit-trail-rules-grid.component';
import { AuditTrailRulesContainerComponent } from './components/audit-trail-rules-container/audit-trail-rules-container.component';
import { AuditTrailsRulesProfilesManagementService } from './services/audit-trails-rules-profiles-management.service';
import { DialogProfilesExternalGridComponent } from './components/external-grid-profiles-dialog/external-grid-profiles-dialog.component';
import { ProfilesExternalFilterGridComponent } from './components/profiles-external-filter-grid/profiles-external-filter-grid.component';
import { FnUiIconModule } from '../shared/fn-ui-icons/fn-ui-icon/fn-ui-icon.module';
import { SpinnerModule } from '../ui-components/spinner/spinner.module';
import { FnUiEmptyIconModule } from '../shared/fn-ui-icons/fn-ui-empty-icon/fn-ui-empty-icon.module';
import { SingularProfileContentComponent } from './components/singular-profile-content/singular-profile-content.component';
import { FnUiNotificationModule } from '../shared/fn-ui-notification/fn-ui-notification.module';
import { GroupActionsService } from '../messages-center/services/group-actions.service';
import { ToursModule } from '../tours/tours.module';
import { FnUiNavigationTreeModule } from '../shared/fn-ui-navigation-tree/fn-ui-navigation-tree.module';

@NgModule({
  providers: [
    ProfilesManagementService,
    ProfilesHelperService,
    ProfilesApiService,
    NoteApiService,
    ProfilesSearchRequestBuilderService,
    RulesProfilesManagementService,
    AuditTrailsRulesProfilesManagementService,
    CallerProfilesManagementService,
    GroupActionsService,
    ProfilesNavigationService
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    GridModule,
    UiComponentsModule,
    TranslateModule.forChild(),
    MatMenuModule,
    MatButtonModule,
    FnUiIconModule,
    SpinnerModule,
    FnUiEmptyIconModule,
    FnUiNotificationModule,
    ToursModule,
    FnUiNavigationTreeModule
  ],
  declarations: [
    ProfilesNavigationTreeComponent,
    ProfilesGridComponent,
    NotesGridFooterComponent,
    RulesGridComponent,
    AuditTrailRulesGridComponent,
    AuditTrailRulesContainerComponent,
    DialogProfilesExternalGridComponent,
    ProfilesExternalFilterGridComponent,
    SingularProfileContentComponent
  ],
  exports: [
    ProfilesGridComponent,
    NotesGridFooterComponent,
    RulesGridComponent,
    SingularProfileContentComponent,
    AuditTrailRulesGridComponent,
    AuditTrailRulesContainerComponent
  ]
})
export class ProfilesModule {
}
