import { Injectable, OnDestroy } from '@angular/core';
import { Subscription, filter, fromEvent, map } from 'rxjs';
import { GenerateUID } from '../../../shared/functions/generate-uid.function';
import { MultiTabsActionType } from '../models/enums/multi-tabs-action-type.enum';
import { MultiTabsAction } from '../models/multi-tabs-action.model';
import { IMultiTabsManagerService } from './interfaces/multi-tabs-manager-service.interface';
import { MultiTabsActionsService, MULTI_TABS_ACTION } from './multi-tabs-actions.service';
import { MultiTabsStorageService } from './multi-tabs-storage.service';

@Injectable()
export class MultiTabsManagerService implements IMultiTabsManagerService, OnDestroy {

  get activeTabId(): string {
    return this._activeTabId;
  }

  get activeTabs(): Array<string> {
    return this.storageService.getState().activeTabs;
  }

  get hasActiveTabs(): boolean {
    return this.activeTabs.length > 0;
  }

  get isMasterTab(): boolean {
    return !this.hasActiveTabs || this.activeTabId === this.activeTabs[0];
  }

  get isSingleTab(): boolean {
    return this.activeTabs.length === 1;
  }

  get isTourDismissed(): boolean {
    return this.storageService.getState().isTourDismissed;
  }

  private _activeTabId: string;
  private _subscription = new Subscription();

  constructor(private actionsService: MultiTabsActionsService,
              private storageService: MultiTabsStorageService) {
    this.generateActiveTabId();
    this.subscribeToLocalStorage();
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  addTab(): void {
    const action = {
      type: MultiTabsActionType.ADD_TAB,
      value: this._activeTabId
    };
    this.doAction(action);
  }

  removeTab(): void {
    const action = {
      type: MultiTabsActionType.REMOVE_TAB,
      value: this._activeTabId
    };
    this.doAction(action);
  }

  dismissTour(value = true): void {
    const action = {
      type: MultiTabsActionType.DISMISS_TOUR,
      value: value
    };
    this.doAction(action);
  }

  reset(): void {
    this.storageService.resetState();
    this.actionsService.resetAction();
  }

  private generateActiveTabId(): void {
    this._activeTabId = GenerateUID('TAB_');
  }

  private subscribeToLocalStorage(): void {
    const subscription = fromEvent(window, 'storage')
      .pipe(
        filter((event: StorageEvent) => event.key === MULTI_TABS_ACTION),
        filter((event: StorageEvent) => event.newValue !== null),
        filter((event: StorageEvent) => this.isMasterTab),
        map((event: StorageEvent) => JSON.parse(event.newValue) as MultiTabsAction)
      )
      .subscribe((action: MultiTabsAction) => {
        this.doAction(action);
      });
    this._subscription.add(subscription);
  }

  private doAction(action: MultiTabsAction): void {
    if (!this.isMasterTab) {
      this.actionsService.setAction(action);
      return;
    }
    switch (action.type) {
      case MultiTabsActionType.ADD_TAB:
        this.storageService.addTab(action.value);
        break;
      case MultiTabsActionType.REMOVE_TAB:
        this.storageService.removeTab(action.value);
        break;
      case MultiTabsActionType.DISMISS_TOUR:
        this.storageService.dismissTour(action.value);
        break;
    }
  }

}
