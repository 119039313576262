import { Formats } from './formats.model';

export const DEFAULT_FORMATS: Formats = {
    language: 'en-US',
    dateFormat: 'dd/MM/yyyy',
    timeFormat: 'HH:mm',
    thousandSeparator: ',',
    decimalSeparator: '.',
    roundNumber: false,
    itemsPerPage: 25,
    timeMode: 24
};
