import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { AuthenticationService } from '../../authentication/services/authentication.service';
import { NavigationService } from '../../core/navigation/services/navigation.service';
import { PopupService } from '../../core/services/popup.service';
import { SetOpenModal } from '../../core/state/actions/open-modal.actions';
import { Office } from '../../shared/models/office.model';
import { HoldModalComponent } from '../components/hold-modal/hold-modal.component';
import { MopSelectionModalComponent } from '../components/mop-selection-modal/mop-selection-modal.component';
import { CustomAccountGroupsTree, LiquidityFavoritesTree, LiquidityTaskBarItem, PartyGroupsTree, SystemAccountGroupsTree } from '../models';
import { LiquidityFavoritesService } from './liquidity-favorites.service';
import { LiquidityStatesService } from './liquidity-states.service';
import { LiquidityService } from './liquidity.service';

declare let GPPFrontEnd: any;
@Injectable()
export class GPPFrontendLiquidityService {

  cookie = {};

  constructor(private translateService: TranslateService,
    private authenticationService: AuthenticationService,
    private navigationService: NavigationService,
    private liquidityStatesService: LiquidityStatesService,
    private liquidityService: LiquidityService,
    private popupService: PopupService,
    private store: Store) { }

  translate = (label: string): string => {
    return this.translateService.instant(label);
  };

  getSessionId = (): string => {
    return this.authenticationService.sessionId;
  };

  getAllowedOfficesString = (): string => {
    const allowedOffices = this.navigationService.getAllowedOffices();
    return allowedOffices.map((office: Office) => office.name).join(',');
  };

  intraDayPosition = (...params: Array<any>): any => {
    const key = params[0];
    if (params.length > 1) {
      const value = params[1];
      this.liquidityStatesService.intraDayPosition[key] = value;
      return this;
    }
    return this.liquidityStatesService.intraDayPosition[key];
  };

  partyGroup = (...params: Array<any>): any => {
    const key = params[0];
    if (params.length > 1) {
      const value = params[1];
      this.liquidityStatesService.partyGroup[key] = value;
      return this;
    }
    return this.liquidityStatesService.partyGroup[key];
  };

  getSystemAccounts = (): SystemAccountGroupsTree => {
    return this.liquidityService.getTaskBarItemService(LiquidityTaskBarItem.SYSTEM_ACCOUNT_GROUPS).tree;
  };

  getCustomAccountGroups = (): CustomAccountGroupsTree => {
    return this.liquidityService.getTaskBarItemService(LiquidityTaskBarItem.CUSTOM_ACCOUNT_GROUPS).tree;
  };

  getPartyGroups = (): PartyGroupsTree => {
    return this.liquidityService.getTaskBarItemService(LiquidityTaskBarItem.PARTY_EXPLORER).tree;
  };

  getMyLiquidityItems = (): LiquidityFavoritesTree => {
    return this.liquidityService.getTaskBarItemService(LiquidityTaskBarItem.FAVORITES).tree;
  };

  onMessageLinkButtonClick = (itemId): void => {
    GPPFrontEnd().navigate(['/home/liquidity/system-account-groups']);
    const nodeToOpen = this.liquidityService.getSystemAccountsNodeById(this.getSystemAccounts().nodes, itemId);
    if (nodeToOpen != null) {
      this.liquidityService.loadLiquidityScreen(nodeToOpen);
    }
  };

  addToMyLiquidityItems = (): void => {
    const service = this.liquidityService.getTaskBarItemService(LiquidityTaskBarItem.FAVORITES) as LiquidityFavoritesService;
    service.addFavorite(this.liquidityStatesService.currentNode.node).subscribe(() => {
      service.refreshTree().subscribe();
    });
  };

  openMopSelectionModal = (modalBodyTitle, modalTitle, promise) => {
    const setOpenModal = new SetOpenModal({
      component: MopSelectionModalComponent, promise: promise, config: {
        modalBodyTitle: modalBodyTitle,
        modalTitle: modalTitle
      }
    });
    this.store.dispatch(setOpenModal);
  };

  openHoldModal = (modalBodyTitle, modalTitle, promise) => {
    const setOpenModal = new SetOpenModal({
      component: HoldModalComponent, promise: promise, config: {
        modalBodyTitle: modalBodyTitle,
        modalTitle: modalTitle
      }
    });
    this.store.dispatch(setOpenModal);
  };

  openPopup = (params): Promise<any> => {
    return this.popupService.open(params);
  };
}
