import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { FnUiDialogModule } from '../../shared/fn-ui-dialog/fn-ui-dialog.module';
import { FnUiToastModule } from '../../shared/fn-ui-toast/fn-ui-toast.module';
import { FnUiCloseIconModule } from '../../shared/fn-ui-icons/fn-ui-close-icon/fn-ui-close-icon.module';
import { FnUiSeverityIconModule } from '../../shared/fn-ui-icons/fn-ui-severity-icon/fn-ui-severity-icon.module';
import { FnUiScrollbarModule } from '../../shared/fn-ui-scrollbar/fn-ui-scrollbar.module';
import { FnUiButtonModule } from '../../shared/fn-ui-button/fn-ui-button.module';

import { AlertService } from './services/alert.service';
import { AlertApiService } from './services/alert-api.service';
import { AlertMapperService } from './services/alert-mapper.service';
import { ToastAlertManagerService } from './components/toast-alert/services/toast-alert-manager.service';
import { AlertDetailsDialogService } from './components/alert-details-dialog/services/alert-details-dialog.service';
import { InvestigationService } from './investigation/services/investigation.service';

import { NotificationBellComponent } from './components/notification-bell/notification-bell.component';
import { AlertItemComponent } from './components/alert-item/alert-item.component';
import { ToastAlertComponent } from './components/toast-alert/toast-alert.component';
import { AlertDetailsDialogComponent } from './components/alert-details-dialog/alert-details-dialog.component';
import { AlertMoreDetailsComponent } from './components/alert-more-details/alert-more-details.component';
import { AlertSidebarComponent } from './components/alert-sidebar/alert-sidebar.component';
import { AlertSidebarItemComponent } from './components/alert-sidebar-item/alert-sidebar-item.component';
import { AlertSidebarListComponent } from './components/alert-sidebar-list/alert-sidebar-list.component';
import { AlertSidebarEmptyComponent } from './components/alert-sidebar-empty/alert-sidebar-empty.component';
import { AlertNotificationComponent } from './components/alert-notification/alert-notification.component';
import { ToursModule } from '../../tours/tours.module';
import {MatRippleModule} from "@angular/material/core";

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    MatTooltipModule,
    TranslateModule.forChild(),
    FnUiDialogModule,
    FnUiToastModule,
    FnUiCloseIconModule,
    FnUiSeverityIconModule,
    FnUiScrollbarModule,
    FnUiButtonModule,
    ToursModule,
    MatRippleModule
  ],
  declarations: [
    NotificationBellComponent,
    AlertItemComponent,
    ToastAlertComponent,
    AlertDetailsDialogComponent,
    AlertMoreDetailsComponent,
    AlertSidebarComponent,
    AlertSidebarItemComponent,
    AlertSidebarListComponent,
    AlertSidebarEmptyComponent,
    AlertNotificationComponent
  ],
  providers: [
    AlertService,
    AlertApiService,
    AlertMapperService,
    ToastAlertManagerService,
    AlertDetailsDialogService,
    InvestigationService
  ],
  exports: [
    NotificationBellComponent,
    AlertSidebarComponent
  ]
})
export class AlertsModule { }
