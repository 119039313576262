import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { GridColumn } from '@fgpp-ui/grid';
import { PROFILE_ID } from '../../../profiles/components/profiles-grid/profiles-grid.component';
import { RulesProfilesManagementService } from '../../../profiles/services/rules-profiles-management.service';
import { RuleMetadata } from '../../single-rule/models';

export const RuleMetadataResolver: ResolveFn<RuleMetadata> =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<RuleMetadata> => {

    const rulesProfilesManagementService = inject(RulesProfilesManagementService);

    const getColumns = (): Promise<Array<GridColumn>> | Array<GridColumn> => {
      if (rulesProfilesManagementService.profileId === PROFILE_ID.RULE && rulesProfilesManagementService.availableColumns?.length) {
        return rulesProfilesManagementService.availableColumns;
      }
      if (rulesProfilesManagementService.profileId !== PROFILE_ID.RULE) {
        rulesProfilesManagementService.profileId = PROFILE_ID.RULE;
      }
      return rulesProfilesManagementService.getMetadata().then(columns => columns.availableColumns);
    };

    const mapColumns = (columns: Array<GridColumn>): RuleMetadata => {
      const map = {};
      columns.forEach((column) => {
        map[column.name] = column;
      });
      return map;
    };

    return Promise.resolve(getColumns()).then(columns => mapColumns(columns));
  };
