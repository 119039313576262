export enum MainTab {
    INSIGHT = 'insight',
    MESSAGES_CENTER = 'messages-center',
    LIQUIDITY = 'liquidity',
    RULES = 'rules',
    BUSINESS_SETUP = 'business-setup',
    SYSTEM_SETUP = 'system-setup',
    OPERATIONS = 'operations',
    ARCHIVE = 'archive',
}
