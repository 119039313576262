
export class MemoizedService {
  static cache: Map<string, any> = new Map();
}

export interface CacheConfig {
  keyResolver: any;
}

export function unmemoize<T = any>(cacheConfig: CacheConfig) {
  return (target: T, propertyName: keyof T, descriptor: PropertyDescriptor) => {
    const originalMethod = descriptor.value;
    descriptor.value = async function (...args: any[]): Promise<any> {
      const key = cacheConfig.keyResolver(...args);
      MemoizedService.cache.delete(key);
      return await originalMethod.apply(this, args);
    };
    return descriptor;
  };
}

export function unmemoizeKeyListPrefix<T = any>(cacheConfig: CacheConfig) {
  return (target: T, propertyName: keyof T, descriptor: PropertyDescriptor) => {
    const originalMethod = descriptor.value;
    descriptor.value = async function (...args: any[]): Promise<any> {
      const resolve = cacheConfig.keyResolver(...args);
      if(!resolve){
        return descriptor;
      }
      const keyPrefix =resolve[0];
      const excludeKeyLists =resolve[1];
      for(let key of MemoizedService.cache.keys()){
        if(key.startsWith(keyPrefix) && !excludeKeyLists.includes(key)){
          MemoizedService.cache.delete(key);
        }
      }
      return await originalMethod.apply(this, args);
    };
    return descriptor;
  };
}

export function memoize<T = any>(cacheConfig: CacheConfig) {
  return (target: T, propertyName: keyof T, descriptor: PropertyDescriptor) => {
    const originalMethod = descriptor.value;
    descriptor.value = async function (...args: any[]): Promise<any> {
      const key = cacheConfig.keyResolver(...args);
      const cacheValue: any = MemoizedService.cache.get(key);
      if (cacheValue) {
        return Promise.resolve(cacheValue);
      } else {
        const prom = new Promise<any>(async (resolve, reject) => {
          try {
            const data = await originalMethod.apply(this, args);
            MemoizedService.cache.set(key, data);
            resolve(data);
          } catch (e) {
            reject(e);
          }
        });
        return prom;
      }
    };
    return descriptor;
  };
}

