<app-fn-ui-dialog class="fn-ui-alert-modal">

    <ng-template #header>
      <div class="fn-ui-alert-header">
        <span class="title" [translate]="data.title"></span>
        <app-fn-ui-close-icon *ngIf="data.showCloseButton" mat-dialog-close></app-fn-ui-close-icon>
      </div>
      <mat-divider></mat-divider>

    </ng-template>

    <ng-template #body>
      <div class="fn-ui-alert-body">
        <span *ngIf="data.message" [translate]="data.message"></span>
        <ng-container *ngIf="data.component">
          <ng-container *ngComponentOutlet="data.component.type;injector: data.component.injectorData"></ng-container>
        </ng-container>
      </div>
    </ng-template>

    <ng-template #footer>
      <div *ngIf="data.okButton" class="fn-ui-alert-footer">
        <app-fn-ui-button [name]="data.title + ' ' +data.okButton" type="flat" color="primary" mat-dialog-close="ok">
            <span [translate]="data.okButton"></span>
        </app-fn-ui-button>
      </div>
    </ng-template>

  </app-fn-ui-dialog>
