import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { MatMenuItem } from '@angular/material/menu';
import { SearchBoxItem } from '../../models';

@Component({
  selector: 'app-search-menu',
  templateUrl: './search-menu.component.html',
  styleUrls: ['./search-menu.component.scss']
})
export class SearchMenuComponent implements AfterViewInit {

  @Input() items: Array<SearchBoxItem>;

  @Input()
  get selectedItem(): SearchBoxItem {
    return this._selectedItem;
  }
  set selectedItem(value: SearchBoxItem) {
    this._selectedItem = value;
    this.onSelectedItemChange();
  }
  private _selectedItem: SearchBoxItem;

  @Output() menuItemClicked = new EventEmitter<SearchBoxItem>();

  @ViewChildren(MatMenuItem) menuItems: QueryList<MatMenuItem>;

  readonly aliasTranslationPrefix = 'navigation_search.search_box.item.alias.';

  triggerAlias: string;

  constructor(private elem: ElementRef) {}

  onOpen(): void {
    this.focusSelectedItem();
  }

  onMenuItemClick($event: SearchBoxItem): void {
    this.menuItemClicked.emit($event);
  }

  trackByFunction(index: number, item: SearchBoxItem): string {
    return item.name;
  }

  private onSelectedItemChange() {
    this.triggerAlias = `${this.aliasTranslationPrefix}${this.selectedItem.name}`;
  }

  private focusSelectedItem(): void {
    const index = this.items.indexOf(this.selectedItem);
    if (index !== -1) {
      setTimeout(() => {
        this.menuItems.toArray()[index].focus();
      });
    }
  }

  ngAfterViewInit(): void {
    this.elem.nativeElement.setAttribute('azure-id', 'search-menu');
    for ( const item of this.menuItems) {
      item._getHostElement().setAttribute('azure-id', item.getLabel());
    }
  }
}
