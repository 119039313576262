<div class="dialog-main" [ngClass]="{'dialog-confrim': showConfirmView, 'dialog-file': !showConfirmView}">
    <div [hidden]="showConfirmView"  class="main-form">
        <div class="form-view">
            <h2 class="dialog-title" mat-dialog-title>
                <span>{{texts.title}}</span>
              <button azure-id="close-attachment-icon" mat-icon-button class="new-close" (click)="onClose()">
                  <app-fn-ui-icon icon="close"></app-fn-ui-icon>
                </button>

            </h2>
            <div class="form-attachment" mat-dialog-content>
                <lib-file-attachment-manager #manager
                    [fileAttachmentConfig]="fileAttachmenConfig"
                    [fileAttachments]="fileAttachmentsList"
                     [loaderComponent]="spinnerComponent"
                    [texts] = "texts"
                    (attachmentsChanged)="setAttachmentChanged()">
                </lib-file-attachment-manager>
            </div>
        </div>
      <button azure-id="close-attachment-button" mat-button color="primary" class="span-close" (click)="onClose()">{{texts.closeDialog}}</button>
    </div>
    <div *ngIf="showConfirmView" class="confirm-view">
        <div class="confirm-content">
            <h2 class="confirm-title" mat-dialog-title>
                <span>{{texts.confirmation}}</span>
            </h2>
            <div class="confirm-action" mat-dialog-actions>
                <button azure-id="close-confirm-yes" mat-button class="confirm-yes" (click)="sendAttachmentListData()">{{texts.confirmationAgree}}</button>
                <button azure-id="close-confirm-no" mat-button class="confirm-no" (click)="returnToForm()">{{texts.confirmationDisagree}}</button>
            </div>
        </div>
        <div class="confirm-background"></div>
    </div>
</div>
