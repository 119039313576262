import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { GppTreeComponent, GppTreeConfigService, TreeConfig } from '@fgpp-ui/components';
import { MessagesQueue } from '../../../../messages/models/messages-queue.model';
import { FnUiNavigationTreeComponent } from '../../../../shared/fn-ui-navigation-tree/fn-ui-navigation-tree.component';
import { MessagesCenterNavigationTreeConfigService } from '../../services/messages-center-navigation-tree-config.service';

@Component({
  selector: 'app-messages-center-navigation-tree',
  templateUrl: './messages-center-navigation-tree.component.html',
  styleUrls: ['./messages-center-navigation-tree.component.scss'],
  providers: [
    {
      provide: GppTreeConfigService,
      useClass: MessagesCenterNavigationTreeConfigService
    }
  ]
})
export class MessagesCenterNavigationTreeComponent {

  @Input() treeConfig: Partial<TreeConfig>;
  @Input() treeModel: Array<MessagesQueue>;
  @Input() selectedNode: MessagesQueue;

  @Output() selectionChanged = new EventEmitter<MessagesQueue>();

  @ViewChild(FnUiNavigationTreeComponent, { static: true }) navigationTreeComponent: FnUiNavigationTreeComponent<MessagesQueue>;

  get treeComponent(): GppTreeComponent<MessagesQueue> {
    return this.navigationTreeComponent.treeComponent;
  }

  onSelectionChange($event: MessagesQueue): void {
    this.selectionChanged.emit($event);
  }

}

