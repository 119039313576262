import { Injectable } from '@angular/core';
import { ArchiveTab } from '../../../sitemap/models';
import { TaskBar } from '../../models/task-bar.model';
import { ITaskBarHandlerService } from './task-bar-handler-service.interface';

@Injectable()
export class ArchiveTaskBarHandlerService implements ITaskBarHandlerService {

    constructor() { }

    getTaskBar(component: ArchiveTab): TaskBar {
        const taskBar = {
            items: []
        };
        // component.modules.inner_navigation?.nodes?.forEach((node: SitemapNode, index) => {
        //     if (node.nodes) {
        //       taskBar.items.push({
        //         stateName: 'home.archive.taskbar.main',
        //         stateParams: { taskbarItem: node.id },
        //         routerLink: `/home/archive/${node.id}`,
        //         alias: node.alias,
        //         icon: node.icon,
        //         position: index,
        //         id: node.id
        //       });
        //     }
        // });
        return taskBar;
    }
}
