import { FileAttachmentRow } from '../../file-attachment-table/file-attachment-row/models/file-attachment-row.model';
import { FileAttachment } from '../../models/file-attachment.model';
import { IFileAttachmentManagerMapperService } from './file-atchemnt-manager-mapper.interface';
import { Injectable } from '@angular/core';

@Injectable()
export class FileAttachmentManagerMapperService implements IFileAttachmentManagerMapperService {
    mapFileAttachmentToFileAttachmentRow(uploadFileAttachmentDetails: FileAttachment, uploaded = false): FileAttachmentRow {
        return ({
            fileName: uploadFileAttachmentDetails.fileName,
            updatedDate: new Date(uploadFileAttachmentDetails.updatedDate),
            userId: uploadFileAttachmentDetails.userId,
            description: uploadFileAttachmentDetails.description,
            fileId: uploadFileAttachmentDetails.fileId,
            status: uploadFileAttachmentDetails.status,
            uploaded,
            isDisabled: null,
            mainClass: null
        });
    }
}
