export const StopFlags = {
    'OFFICE': 'STOP_FLAGS-OFFICE',
    'DEPARTMENT': 'STOP_FLAGS-DEPARTMENT',
    'REC_STATUS': 'STOP_FLAGS-REC_STATUS',
    'PROFILE_CHANGE_STATUS': 'STOP_FLAGS-PROFILE_CHANGE_STATUS',
    'EFFECTIVE_DATE': 'EFFECTIVE_DATE',
    'STOP_FLAGS_CODE': 'STOP_FLAGS-STOP_FLAGS_CODE',
    'DESCRIPTION': 'STOP_FLAGS-DESCRIPTION',
    'NON_OVERRIDDEN': 'STOP_FLAGS-NON_OVERRIDDEN',
    'TIME_STAMP': 'TIME_STAMP',
    'UID': 'VIRTUAL-UID',
    'PROFILE_UPDATE_PK': 'PROFILE_UPDATE_PK',
    'PU_TIME_STAMP': 'PU_TIME_STAMP',
    'NOTE': 'NOTE'
};
