import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from './spinner.component';
import { SpinnerDirective } from './spinner.directive';


@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        SpinnerComponent,
        SpinnerDirective
    ],
    exports: [
        SpinnerComponent,
        SpinnerDirective
    ]
})
export class SpinnerModule { }
