import { PasswordPolicyService } from './password-policy.service';
import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { PasswordPolicyInterface } from '../models/password-policy.interface';

@Injectable()
export class PasswordValidationService {
  private passwordPolicy: PasswordPolicyInterface;
  constructor(passwordPolicyService: PasswordPolicyService) {
    this.passwordPolicy = passwordPolicyService.fetch();
  }

  public validPassword(isRequired: boolean = false): ValidatorFn {
    const specialChars = this.passwordPolicy.specialChar ? this.getSpecialCharRegex(this.passwordPolicy.specialChar) : null;
    const upperCaseChars = this.passwordPolicy.upperCase ? this.getUpperCaseRegex(this.passwordPolicy.upperCase) : null;
    const lowerCaseChars = this.passwordPolicy.lowerCase ? this.getLowerCaseRegex(this.passwordPolicy.lowerCase) : null;
    const digits = this.passwordPolicy.digits ? this.getDigitsRegex(this.passwordPolicy.digits) : null;
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return isRequired ? { MandatoryField: true } : null;
      }
      if ((this.passwordPolicy.minLength && control.value.length < this.passwordPolicy.minLength) || control.value.length > this.passwordPolicy.maxLength) {
        return { invalidPassword: 'Password length is invalid.' };
      }
      if (specialChars && !specialChars.test(control.value)) {
        return {
          invalidPassword: 'Password requires at least one special character.',
        };
      }
      if (upperCaseChars && !upperCaseChars.test(control.value)) {
        return {
          invalidPassword: 'Password requires at least one uppercase character.',
        };
      }
      if (lowerCaseChars && !lowerCaseChars.test(control.value)) {
        return {
          invalidPassword: 'Password requires at least one lowercase character.',
        };
      }
      if (digits && !digits.test(control.value)) {
        return {
          invalidPassword: 'Password requires at least one numeric character.',
        };
      }
      return null;
    };
  }

  getSpecialCharRegex(count: number) {
    return new RegExp(`(?:[^\`!@#$%^&*\\-_=+'/.,]*[\`!@#$%^&*\\-_=+'/.,]){${count},}`);
  }

  getDigitsRegex(count: number) {
    return new RegExp(`[0-9]{${count},}`);
  }

  getUpperCaseRegex(count: number) {
    return new RegExp(`[A-Z]{${count},}`);
  }

  getLowerCaseRegex(count: number) {
    return new RegExp(`[a-z]{${count},}`);
  }
}
