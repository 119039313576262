import { Injectable } from '@angular/core';
import { RouterHelper } from '../../../base-view/classes/router-helper';
import { PowerBIReportMetadata } from '../models/power-bi-report-metadata.model';
import * as pbiClient from 'powerbi-client';
import { PowerBIApiService } from './power-bi-api.service';
import { PowerBIReportsResponse } from '../models/power-bi-reports-response.model';
import { PowerBINavigationService } from './power-bi-navigation.service';
import { IPowerBiRefreshToken } from './interfaces/power-bi-refresh-token.interface';
import {  Router } from '@angular/router';

@Injectable()
export class PowerBIRefreshTokenService implements IPowerBiRefreshToken {

  private readonly MINUTES_BEFORE_EXPIRATION = 10;
  private readonly INTERVAL_TIME = 30000;
  private _tokenExpiration: number;
  private interval: ReturnType<typeof setTimeout>;

  set tokenExpiration(value: number) {
    this._tokenExpiration = value;
  }

  get tokenExpiration(): number {
    return this._tokenExpiration;
  }

  constructor(private powerBIApiService: PowerBIApiService,
              private powerBINavigationService: PowerBINavigationService,
              private router: Router
  ) { }

  public startCheckTokenExpiration(reportMetadata: PowerBIReportMetadata, report: pbiClient.Report): void {
    if (!reportMetadata) {
      return;
    }
    this._tokenExpiration = Date.parse(reportMetadata.tokenExpiration);
    this.interval = setInterval(() => this.checkTokenAndUpdate(reportMetadata.id, report), this.INTERVAL_TIME);
  }

  public finishCheckTokenExpiration(): void {
    clearInterval(this.interval);
  }

  public checkTokenAndUpdate(reportId: string, report: pbiClient.Report) {
    const currentTime = Date.now();
    const timeUntilExpiration = this._tokenExpiration - currentTime;
    const timeToUpdate = this.MINUTES_BEFORE_EXPIRATION * 60 * 1000;

    if (timeUntilExpiration <= timeToUpdate) {
      this.updateToken(reportId, report);
    }
  }

  public updateToken(reportId: string, report: pbiClient.Report): void {
    const taskBarId = RouterHelper.getSegments(this.router)[2].path;
    this.powerBIApiService.getReports(taskBarId).subscribe(
      (response: PowerBIReportsResponse) => {
        const reportNewMetadata = response.responseMsg.value.find((reportMetadata: PowerBIReportMetadata) => reportMetadata.id === reportId);
        this._tokenExpiration = Date.parse(reportNewMetadata.tokenExpiration);
        report.setAccessToken(reportNewMetadata.embedToken).then(
          () => { },
          error => console.log('Embed token was not updated', error)
        );
        this.powerBINavigationService.updateTaskBar(response.responseMsg.value);
      },
      (error) => console.log('Error on update embed token', error)
    );
  }

}
