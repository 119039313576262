import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KeyboardShortcutsModule as NgKeyboardShortcutsModule } from '@egoistdeveloper/ng-keyboard-shortcuts';
import { FnUiDialogModule } from '../../shared/fn-ui-dialog/fn-ui-dialog.module';

import { KeyboardShortcutsComponent } from './components/keyboard-shortcuts/keyboard-shortcuts.component';

import { KeyboardConfigurationService } from './services/keyboard-configuration.service';
import { KeyboardShortcutElementSelectorService } from './services/keyboard-shortcut-element-selector.service';
import { KeyboardShortcutEventEmitterService } from './services/keyboard-shortcut-event-emitter.service';
import { KeyboardShortcutHandlerFactoryService } from './services/handlers/keyboard-shortcut-handler-factory.service';
import { KeyboardShortcutsBuilderService } from './services/keyboard-shortcuts-builder.service';
import { KeyboardShortcutsResourceService } from './services/keyboard-shortcuts-resource.service';
import { KeyboardShortcutHandlerService } from './services/handlers/keyboard-shortcut-handler.service';
import { NativeFocusKeyboardShortcutHandlerService } from './services/handlers/native-focus-keyboard-shortcut-handler.service';
import { NativeSimpleClickKeyboardShortcutHandlerService } from './services/handlers/native-simple-click-keyboard-shortcut-handler.service';

import { KeyboardShortcutType } from './models/enums/keyboard-shortcut-type.enum';


@NgModule({
  declarations: [
    KeyboardShortcutsComponent
  ],
  imports: [
    CommonModule,
    NgKeyboardShortcutsModule.forRoot(),
    FnUiDialogModule
  ],
  providers: [
    KeyboardConfigurationService,
    KeyboardShortcutElementSelectorService,
    KeyboardShortcutEventEmitterService,
    KeyboardShortcutHandlerFactoryService,
    KeyboardShortcutsBuilderService,
    KeyboardShortcutsResourceService,
    KeyboardShortcutHandlerService,
    { provide: KeyboardShortcutType.FOCUS, useClass: NativeFocusKeyboardShortcutHandlerService },
    { provide: KeyboardShortcutType.SIMPLE_CLICK, useClass: NativeSimpleClickKeyboardShortcutHandlerService }
  ],
  exports: [
    KeyboardShortcutsComponent
  ]
})
export class KeyboardShortcutsModule { }
