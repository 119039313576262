import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { PasswordPolicyService } from '../../../core/user-settings/services/password-policy.service';
import { PasswordPolicyInterface } from '../../../core/user-settings/models/password-policy.interface';
import { PasswordValidationService } from '../../../core/user-settings/services/password-validation.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-password-policy',
  templateUrl: './password-policy.component.html',
  styleUrls: ['./password-policy.component.scss']
})
export class PasswordPolicyComponent implements OnInit {
  @Input() public form: FormGroup = new FormGroup({});
  policyDefinition: PasswordPolicyInterface;
  passwordLength: boolean;
  passwordSpecialChars: boolean;
  passwordDigits: boolean;
  passwordUpperCase: boolean;
  passwordLowerCase: boolean;
  lengthClause = 'Between {{length}} max characters';
  specialCharClause = '{{minSpc}} special character';
  uppercaseCharClause = '{{minUpr}} uppercase character';
  lowercaseCharClause = '{{minLwr}} lowercase character';
  digitClause = '{{minDig}} number';

  constructor(private passwordPolicyService: PasswordPolicyService,
              private passwordValidationService: PasswordValidationService,
              private $translate: TranslateService) {
  }

  private subscriptions: Subscription[] = [];

  ngOnInit(): void {
    this.policyDefinition = this.passwordPolicyService.fetch();
    this.displayPolicy(this.policyDefinition);
    this.setupConditionalValidators();
  }

  private get password(): AbstractControl {
    return this.form.get('newPassword') as AbstractControl;
  }

  private checkPolicyAdherence(value: string) {
    if (!value) {
      this.passwordLength = false;
      this.passwordSpecialChars = false;
      this.passwordDigits = false;
      this.passwordUpperCase = false;
      this.passwordLowerCase = false;
      return;
    }
    this.passwordLength = value.length >= this.policyDefinition.minLength && value.length <= this.policyDefinition.maxLength;
    this.passwordSpecialChars = this.passwordValidationService.getSpecialCharRegex(this.policyDefinition.specialChar).test(value);
    this.passwordDigits = this.passwordValidationService.getDigitsRegex(this.policyDefinition.digits).test(value);
    this.passwordUpperCase = this.passwordValidationService.getUpperCaseRegex(this.policyDefinition.upperCase).test(value);
    this.passwordLowerCase = this.passwordValidationService.getLowerCaseRegex(this.policyDefinition.lowerCase).test(value);
  }

  public setupConditionalValidators(): void {
    const passwordControlSubscription: Subscription = this.password.valueChanges.subscribe(
      (controlValue: string) => this.checkPolicyAdherence(controlValue)
    );
    this.subscriptions.push(passwordControlSubscription);
  }

  private displayPolicy(policyDefinition: PasswordPolicyInterface) {
    if (policyDefinition) {
      const lengthKey = this.$translate.instant('USERS-PASSWORD-LENGTH', { length: `${policyDefinition.minLength}-${policyDefinition.maxLength}` });
      this.lengthClause = lengthKey === 'USERS-PASSWORD-LENGTH' ? this.lengthClause.replace('{{length}}', `${policyDefinition.minLength}-${policyDefinition.maxLength}`) : lengthKey;
      const spcKey = this.$translate.instant('USERS-PASSWORD-SPC', { minSpc: `${policyDefinition.specialChar}` });
      this.specialCharClause = spcKey === 'USERS-PASSWORD-SPC' ? this.specialCharClause.replace('{{minSpc}}', `${policyDefinition.specialChar}`) : spcKey;
      const uprKey = this.$translate.instant('USERS-PASSWORD-UPR', { minUpr: `${policyDefinition.upperCase}` });
      this.uppercaseCharClause = uprKey === 'USERS-PASSWORD-UPR' ? this.uppercaseCharClause.replace('{{minUpr}}', `${policyDefinition.upperCase}`) : uprKey;
      const lwrKey = this.$translate.instant('USERS-PASSWORD-LWR', { minLwr: `${policyDefinition.lowerCase}` });
      this.lowercaseCharClause = lwrKey === 'USERS-PASSWORD-LWR' ? this.lowercaseCharClause.replace('{{minLwr}}', `${policyDefinition.lowerCase}`) : lwrKey;
      const digKey = this.$translate.instant('USERS-PASSWORD-DIG', { minDig: `${policyDefinition.digits}` });
      this.digitClause = digKey === 'USERS-PASSWORD-DIG' ? this.digitClause.replace('{{minDig}}', `${policyDefinition.digits}`) : digKey;
    }
  }
}
