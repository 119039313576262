import { GridContainerConfig } from '../../../../grid/models/grid-container-config.model';
import { GridFooterConfig } from '../../../../grid/models/grid-footer-config.model';
import { GroupActionsConfig } from '../../../../grid/models/group-actions-config.model';
import { MsgInnerGridHeaderConfig } from './messages-inner-grid-config.const';

export const MpInnerGridGroupActionsConfig: GroupActionsConfig = {
  showGroupActionIcon: false,
  showBeforeAfter: false,
  showAddNotes: false,
  showGroupActions: false,
};
//footer no group action
export const MpInnerGridFooterConfig: GridFooterConfig = {
  showResultsAndCounts: true,
  groupActions: MpInnerGridGroupActionsConfig,
  showCallerDetails: false,
  showCreateFromTemplate: false,
  showResultsWarning: false
};

// config no group actions
export const MpMsgInnerGridWConfig: GridContainerConfig = {
  gridHeaderConfig: MsgInnerGridHeaderConfig,
  gridFooterConfig: MpInnerGridFooterConfig,
};
