import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';

@Pipe({
  name: 'timeExample'
})
export class TimeExamplePipe implements PipeTransform {

  transform(value: string): string {
    return dayjs('1995-06-15T21:37:06').format(value);
  }
}
