import { Component, Input, Output, EventEmitter, ElementRef, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-fn-ui-close-icon',
  templateUrl: './fn-ui-close-icon.component.html',
  styleUrls: ['./fn-ui-close-icon.component.scss']
})
export class FnUiCloseIconComponent implements AfterViewInit {

  @Input() disabled: boolean;
  @Input() size = '24px';

  @Output() closeClick: EventEmitter<void> = new EventEmitter();

  constructor(private elem: ElementRef) { }

  onCloseClick() {
    this.closeClick.emit();
  }

  ngAfterViewInit(): void {
    this.elem.nativeElement.setAttribute('azure-id', 'close icon');
  }
}
