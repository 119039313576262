import { Action, State, Selector, StateContext } from '@ngxs/store';
import { SidebarStateModel } from './models/sidebar-state.model';
import { SidebarMode } from '../../../state/models/enums/sidebar-mode.enum';
import { ToggleSidebar } from './actions/toggle-sidebar.action';
import { CloseSidebar } from './actions/close-sidebar.action';
import { Injectable } from '@angular/core';

@State<SidebarStateModel>({
    name: 'sidebar',
    defaults: {
        mode: SidebarMode.CLOSE
    }
})
@Injectable()
export class SidebarState {

    @Selector()
    static getSidebarMode(state: SidebarStateModel): SidebarMode {
        return state.mode;
    }

    @Action(ToggleSidebar)
    toggleSidebar(ctx: StateContext<SidebarStateModel>) {
        const state = ctx.getState();
        ctx.patchState({
            mode: state.mode === SidebarMode.OPEN ? SidebarMode.CLOSE : SidebarMode.OPEN
        });
    }

    @Action(CloseSidebar)
    closeSidebar(ctx: StateContext<SidebarStateModel>) {
        ctx.patchState({
            mode:  SidebarMode.CLOSE
        });
    }
}

