export enum CountryInfo  {
	"REC_STATUS"= "COUNTRY_CFG-REC_STATUS",
	"PROFILE_CHANGE_STATUS"= "COUNTRY_CFG-PROFILE_CHANGE_STATUS",
	"EFFECTIVE_DATE"= "EFFECTIVE_DATE",
	"BBAN_SHORT_LEN"= "COUNTRY_CFG-BBAN_SHORT_LEN",
	"CALNAME"= "COUNTRY_CFG-CALNAME",
	"CHK_DIGIT_TYPE"= "COUNTRY_CFG-CHK_DIGIT_TYPE",
  "IBAN_LABEL"= "IBAN_EXAMPLE_LABEL",
	"COUNTRYCODE"= "COUNTRY_CFG-COUNTRYCODE",
	"COUNTRYNAME"= "COUNTRY_CFG-COUNTRYNAME",
	"CURRENCY"= "COUNTRY_CFG-CURRENCY",
	"EU_COUNTRY"= "COUNTRY_CFG-EU_COUNTRY",
	"HOBRANCHSUBSTITUTE"= "COUNTRY_CFG-HOBRANCHSUBSTITUTE",
	"IBAN_ACCOUNT_LEN"= "COUNTRY_CFG-IBAN_ACCOUNT_LEN",
	"IBAN_ACCOUNT_POS"= "COUNTRY_CFG-IBAN_ACCOUNT_POS",
	"IBAN_BANK_ID_LEN"= "COUNTRY_CFG-IBAN_BANK_ID_LEN",
	"IBAN_BANK_ID_POS"= "COUNTRY_CFG-IBAN_BANK_ID_POS",
	"IBAN_BRANCH_LEN"= "COUNTRY_CFG-IBAN_BRANCH_LEN",
	"IBAN_BRANCH_POS"= "COUNTRY_CFG-IBAN_BRANCH_POS",
	"IBAN_LEN"= "COUNTRY_CFG-IBAN_LEN",
	"IBAN_MANDATORY"= "COUNTRY_CFG-IBAN_MANDATORY",
	"STEP2_ENABLED"= "COUNTRY_CFG-STEP2_ENABLED",
	"TARGETCENTRALBANK"= "COUNTRY_CFG-TARGETCENTRALBANK",
	"TIME_STAMP"= "TIME_STAMP",
	"UID"= "VIRTUAL-UID",
	"PROFILE_UPDATE_PK"= "PROFILE_UPDATE_PK",
	"PU_TIME_STAMP"= "PU_TIME_STAMP",
	"NOTE"= "NOTE"
};
