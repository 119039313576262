import { Injectable } from '@angular/core';
import { ToastrService, IndividualConfig, ActiveToast } from 'ngx-toastr';

@Injectable()
export class FnUiToastService {

  constructor(private toastrService: ToastrService) { }

  show<T>(data: T, options?: Partial<IndividualConfig>): ActiveToast<any> {
    return this.toastrService.show(data as any, null, options);
  }

  remove(toastId: number) {
    this.toastrService.remove(toastId);
  }

  clear() {
    this.toastrService.clear();
  }

  success(message: string, title?: string, options?: Partial<IndividualConfig>) {
    this.toastrService.success(message, title, options);
  }

}
