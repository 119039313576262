import { Component, Input } from '@angular/core';
import { Alert } from '../../models/alert.model';

@Component({
  selector: 'app-alert-sidebar-list',
  templateUrl: './alert-sidebar-list.component.html',
  styleUrls: ['./alert-sidebar-list.component.scss']
})
export class AlertSidebarListComponent {

  @Input() alerts: Array<Alert>;

  selectedAlert: Alert;

  onSelectAlert(alert: Alert) {
    alert.isSelected = true;
    this.selectedAlert = alert;
  }

  onMouseEnter(alert: Alert) {
    if (this.selectedAlert && this.selectedAlert !== alert) {
      this.selectedAlert.isSelected = false;
      this.selectedAlert = null;
    }
  }

}
