import { AfterViewInit, Directive, Input, OnChanges, OnDestroy, SimpleChanges, ViewContainerRef } from '@angular/core';
import { FnUiAutocompleteComponent } from 'projects/gpp/src/app/shared/fn-ui-autocomplete/fn-ui-autocomplete.component';
import { FnUiCheckboxComponent } from 'projects/gpp/src/app/shared/fn-ui-checkbox/fn-ui-checkbox.component';
import { FnUiDrillDownComponent } from 'projects/gpp/src/app/shared/fn-ui-drilldown/fn-ui-drill-down.component';
import { FnUiInputComponent } from 'projects/gpp/src/app/shared/fn-ui-input/fn-ui-input.component';
import { Subscription } from 'rxjs';
import { FnUiDrillDownResult } from '../../../shared/fn-ui-drilldown/models/fn-ui-drill-down-result.modelt';
import { Entity } from '../../../shared/models/enums/entity.enum';
import { ConditionsBankNode, ContentType } from '../models';
import { AllowedTypesMap, TypeToControlMap } from '../models/consts/rule-config.const';
import { FunctionArgumentField } from '../models/function-argument-field.model';
import { FunctionArgument } from '../models/function-argument.model';
import { RuleCommonService } from '../services/rule-common.service';

@Directive({
  selector: '[appFunctionArguments]'
})
export class FunctionArgumentsDirective implements AfterViewInit, OnChanges, OnDestroy {

  @Input() functionName: string;
  @Input() metadata: Array<FunctionArgument>;

  private _argumentFields: Array<FunctionArgumentField> = [];
  private _subscriber = new Subscription();
  private _viewInited = false;

  get argumentValues(): Array<string | boolean> {
    return this._argumentFields.map((field: FunctionArgumentField) => field.value);
  }

  constructor(private viewContainerRef: ViewContainerRef,
              private ruleCommonService: RuleCommonService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this._viewInited) {
      this.initFunction();
    }
  }

  ngAfterViewInit(): void {
    this.initFunction();
    this._viewInited = true;
  }

  ngOnDestroy(): void {
    this._subscriber.unsubscribe();
  }

  private initFunction(): void {
    this.viewContainerRef.clear();
    if (!this.metadata) {
      return;
    }
    this._argumentFields = this.metadata.map(this.addFunctionArgumant.bind(this));
  }

  private addFunctionArgumant(argument: FunctionArgument): FunctionArgumentField {
    const controlType = TypeToControlMap[argument.type];
    switch (controlType) {
      case 'input':
        return this.addInputField(argument);
      case 'autocomplete':
        return this.addAutoCompleteField(argument);
      case 'checkbox':
        return this.addCheckboxField(argument);
      case 'datePicker':
        return this.addAutoCompleteField(argument);
      case 'datetimePicker':
        return this.addAutoCompleteField(argument);
      case 'timePicker':
        return this.addAutoCompleteField(argument);
      case 'drilldown':
        return this.addDrilDownField(argument);
      default:
        return this.addInputField(argument);
    }
  }

  private addInputField(argument: FunctionArgument): FunctionArgumentField {
    const componentRef = this.viewContainerRef.createComponent(FnUiInputComponent);

    componentRef.instance.label = argument.alias;
    componentRef.instance.ngModel = argument.value;

    const field = {
      componentRef: componentRef,
      value: argument.value
    };

    componentRef.instance.registerOnChange((value: string) => {
      field.value = value;
    });

    return field;
  }

  private addAutoCompleteField(argument: FunctionArgument): FunctionArgumentField {
    const componentRef = this.viewContainerRef.createComponent(FnUiAutocompleteComponent);

    componentRef.instance.label = argument.alias;
    componentRef.instance.displayProperty = 'alias';
    componentRef.instance.filterFunction = this.getAutocompleteFilter(argument);
    componentRef.instance.ngModel = argument.value;

    const field = {
      componentRef: componentRef,
      value: argument.value
    };

    const subscription = componentRef.instance.textChanged.subscribe((value: string) => {
      field.value = value;
    });

    this._subscriber.add(subscription);

    return field;
  }

  private addCheckboxField(argument: FunctionArgument): FunctionArgumentField {
    const componentRef = this.viewContainerRef.createComponent(FnUiCheckboxComponent);
    const booleanValue = argument.value?.trim().toLowerCase() === true.toString();

    componentRef.instance.label = argument.alias;
    componentRef.instance.value = booleanValue;

    const field = {
      componentRef: componentRef,
      value: booleanValue
    };

    const subscription = componentRef.instance.valueChange.subscribe((value: boolean) => {
      field.value = value;
    });

    this._subscriber.add(subscription);

    return field;
  }

  private addDrilDownField(argument: FunctionArgument): FunctionArgumentField {
    const componentRef = this.viewContainerRef.createComponent(FnUiDrillDownComponent);

    componentRef.instance.dhDrillDown = argument.profileId;
    componentRef.instance.inputLabel = argument.alias;
    componentRef.instance.inputValue = argument.value;
    componentRef.instance.filterParameters = this.getDrilldownInParams(argument);
    componentRef.instance.entity = Entity.RULES;

    const field = {
      componentRef: componentRef,
      value: argument.value
    };

    const subscription = componentRef.instance.rowSelected.subscribe(($event: FnUiDrillDownResult) => {
      field.value = this.createDrilldownOutParamsExpression($event.selectedRows[0], argument);
    });

    this._subscriber.add(subscription);

    return field;
  }

  private getAutocompleteFilter(argument: FunctionArgument): (query: string) => Array<ConditionsBankNode> {
    return (query: string): Array<ConditionsBankNode> => {
      query = query.toLowerCase();
      return this.ruleCommonService.getFlatConditionsBank()
        .filter(this.filterFunction(argument, query))
        .slice(0, 10); //the requirement says to show 10 options max in autocomplete
    };
  };

  private filterFunction(argument: FunctionArgument, query: string): (item) => boolean {
    if (this.functionName.startsWith(ContentType.BASE_CONDITION)) {
      return (item) => {
        return item.contentType === ContentType.BASE_CONDITION && (item.alias.toLowerCase().indexOf(query) === 0 ||
          (item.id && item.id.toLowerCase().indexOf(query) === 0));
      };
    } else {
      return (item) => {
        const allowedTypesPerArgType = AllowedTypesMap[argument.type.toUpperCase()];

        return ((item.contentType === ContentType.FIELD && item.parent === false) || item.contentType !== ContentType.FIELD) &&
          item.type && allowedTypesPerArgType.indexOf(item.type.toUpperCase()) > -1 &&
          (item.alias.toLowerCase().indexOf(query) === 0 || (item.id && item.id.toLowerCase().indexOf(query) === 0));
      };
    }
  };

  private getDrilldownInParams(argument: FunctionArgument): Object {
    return {
      'type': 'LOGICAL_FIELD',
      'PRULES.HELP_LIST_FIELD_ALIAS': argument.id,
      'LIST_TYPES.OFFICE': this.ruleCommonService.rule.OFFICE
    };
  }

  private createDrilldownOutParamsExpression(selectedRow: Object, argument: FunctionArgument): string {
    const values = Object.keys(argument.outParams).map((key: string) => {
      const fieldValue = argument.outParams[key];
      return selectedRow[fieldValue];
    });
    return values.join(', ');
  }

}
