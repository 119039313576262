import { Injectable } from '@angular/core';
import { State, Selector, Action, StateContext } from '@ngxs/store';
import { Token } from '../../../../authentication/models/token.model';
import { UserData } from '../../../../authentication/models/user-data.model';
import { AuthenticationConfigService } from '../../../../authentication/services/authentication-config.service';
import { MultiTabsManagerService } from '../../../multi-tabs/services/multi-tabs-manager.service';
import { CreateToken, CreateSession, DuplicateSession, GetSession,
   UpdateSession, DeleteSessionToken, DeleteSessionUserData, RestSessionStateWithoutAction } from '../action/session.action';
import { SESSIONACTION } from '../model/session-action.enum';
import { SessionStateWithActionModel } from '../model/session-state-with-action.model';
import { SessionStateModel } from '../model/session-state.model';


const DEFAULT_SESSION_STATE = {
  token:  null,
  userData: null
};


@State<SessionStateModel>({
  name: 'SessionState',
  defaults: DEFAULT_SESSION_STATE
})
@Injectable()
export class SessionState {

  private static authenticationConfigService: AuthenticationConfigService;

  constructor(private multiTabsManagerService: MultiTabsManagerService,
              authenticationConfigService: AuthenticationConfigService) {
    SessionState.authenticationConfigService = authenticationConfigService;
  }

  @Selector()
  static token(state: SessionStateModel): Token {
    return state.token;
  }

  @Selector()
  static accessToken(state: SessionStateModel): string {
    if (state.token) {
      const property = SessionState.authenticationConfigService.accessTokenProperty;
      return `Bearer ${state.token[property]}`;
    }
    return null;
  }

  @Selector()
  static idToken(state: SessionStateModel): string {
    return state.token?.id_token;
  }

  @Selector()
  static refreshToken(state: SessionStateModel): string {
    return state.token?.refresh_token;
  }

  @Selector()
  static userData(state: SessionStateModel): UserData {
    return state.userData;
  }

  @Selector()
  static isUserDataSet(state: SessionStateModel): boolean {
    return state.userData !== null;
  }

  @Selector()
  static sessionId(state: SessionStateModel): string {
    return state.userData?.sessionId;
  }

  @Action(CreateToken)
  createToken({ getState, patchState }, { payload }: CreateToken) {
    patchState({ token : payload, userData: null });
    this.setDataInSession({ token : payload });
    console.log('CreateToken');
  }

  @Action(CreateSession)
  CreateSession(ctx: StateContext<SessionStateModel>, { payload }: CreateSession) {
    const state = ctx.getState();
    const token = state.token || payload.token || null;
    payload.token = token;
    const createStateAction: SessionStateWithActionModel = { ...payload, sessionAction: SESSIONACTION.CREATE };
    ctx.patchState({ token: payload.token, userData: payload.userData });
    this.setDataInSession(payload);
    this.multiTabsManagerService.reset();
    localStorage.setItem('session', JSON.stringify(createStateAction));
    localStorage.removeItem('session');
    console.log('CreateSession');
  }

  @Action(DuplicateSession)
  duplicate(ctx: StateContext<SessionStateModel>, { payload }: DuplicateSession) {
    ctx.patchState({ token: payload.token, userData : payload.userData });
    this.setDataInSession(payload);
    console.log('DuplicateSession');
  }


  @Action(GetSession)
  getSession({ getState }) {
    const sessionInfoState: SessionStateModel = getState();
    console.log(sessionInfoState);
    const sessionInfoAction: SessionStateWithActionModel = { ...sessionInfoState, sessionAction: SESSIONACTION.CREATE };
    localStorage.setItem('session', JSON.stringify(sessionInfoAction));
    console.log('GetSession');
    localStorage.removeItem('session');
  }

  @Action(UpdateSession)
  update({ getState, patchState }, { payload }: UpdateSession) {
    const updateSessionState: SessionStateModel = { token: payload };
    const updateStateAction: SessionStateWithActionModel = { ...updateSessionState, sessionAction: SESSIONACTION.UPDATE };
    localStorage.setItem('session', JSON.stringify(updateStateAction));
    this.setDataInSession(updateSessionState);
    patchState(updateSessionState);
  }

  @Action(DeleteSessionToken)
  deleteToken({ getState, patchState }) {
    patchState({ token: null });
    sessionStorage.removeItem('token');
  }

  @Action(DeleteSessionUserData)
  deleteUserData({ getState, patchState }) {
    patchState({ userData: null });
    sessionStorage.removeItem('userData');
    const deleteUserDataAction: SessionStateWithActionModel = { sessionAction: SESSIONACTION.DELETE_SESSION_USER_DATA };
    localStorage.setItem('session', JSON.stringify(deleteUserDataAction));
    localStorage.removeItem('session');
  }

  @Action(RestSessionStateWithoutAction)
  deleteUserDataWithoutAction({ getState, patchState }) {
    patchState(DEFAULT_SESSION_STATE);
    sessionStorage.removeItem('userData');
  }

  private setDataInSession(data: Object) {
    Object.keys(data).forEach(key => {
      sessionStorage.setItem(key, JSON.stringify(data[key]));
    });
  }

}
