import { AlertType } from '../enums/alert-type.enum';

export const CATEGORY = {
    1: AlertType.ACCOUNT_POSITION,
    2: AlertType.MESSAGE_QUEUES,
    3: AlertType.INTERFACE_INACTIVITY,
    4: AlertType.EVENT_INACTIVITY,
    5: AlertType.SYSTEM_ERROR_LOG,
    6: AlertType.FAILED_INCOMING_INTERACTION
};
