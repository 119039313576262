import { Injectable } from '@angular/core';
import { State, Selector, Action, StateContext } from '@ngxs/store';
import { KeyboardShortcutsStateModel } from '../models/keyboard-shortcuts-state.model';
import { KeyboardShortcutsResource } from '../../models/keyboard-shortcuts-resource.model';
import { KeyboardShortcut } from '../../models/keyboard-shortcut.model';
import { KeyboardShortcutActions } from '../actions/keyboard-shortcuts.actions';
import { KeyboardConfigurationService } from '../../services/keyboard-configuration.service';
import { KeyboardShortcutsResourceService } from '../../services/keyboard-shortcuts-resource.service';
import { KeyboardMode } from '../../models/enums/keyboard-mode.enum';

@State<KeyboardShortcutsStateModel>({
    name: 'keyboard_shortcuts',
    defaults: {
        mode: KeyboardMode.OFF,
        shortcuts: [],
        activeShortcut: null
    }
})
@Injectable()
export class KeyboardShortcutsState {

    constructor(private keyboardConfigurationService: KeyboardConfigurationService,
        private keyboardShortcutsResourceService: KeyboardShortcutsResourceService) { }

    @Selector()
    static getKeboardMode(state: KeyboardShortcutsStateModel): KeyboardMode {
        return state.mode;
    }

    @Selector()
    static getShortcuts(state: KeyboardShortcutsStateModel): Array<KeyboardShortcut> {
        return state.shortcuts;
    }

    @Selector()
    static getActiveShortcut(state: KeyboardShortcutsStateModel): KeyboardShortcut {
        return state.activeShortcut;
    }

    @Action(KeyboardShortcutActions.FetchKeboardMode)
    fetchKeyboardMode(ctx: StateContext<KeyboardShortcutsStateModel>): void {
        this.keyboardConfigurationService.getKeyboardMode().subscribe((mode: KeyboardMode) => {
            ctx.patchState({
                mode: mode
            });
        });
    }

    @Action(KeyboardShortcutActions.FetchKeyboardShortcuts)
    fetchKeyboardShortcuts(ctx: StateContext<KeyboardShortcutsStateModel>): void {
        this.keyboardConfigurationService.getResourceUrl().subscribe((resourceUrl: string) => {
            this.keyboardShortcutsResourceService.getShortcuts(resourceUrl).subscribe((response: KeyboardShortcutsResource) => {
                ctx.patchState({
                    shortcuts: response.data
                });
            });
        });
    }

    @Action(KeyboardShortcutActions.PressUserKeyboard)
    pressUserKeyboard(ctx: StateContext<KeyboardShortcutsStateModel>, { payload }: KeyboardShortcutActions.PressUserKeyboard): void {
        ctx.patchState({
            activeShortcut: null
        });
        ctx.patchState({
            activeShortcut: payload
        });
    }

}
