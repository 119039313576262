import { Injectable } from '@angular/core';
import { IInsightNavigationService } from './insight-navigation-service.interface';
import { NavigationService } from '../../core/navigation/services/navigation.service';
import { InsightTab, SitemapNode } from '../../core/navigation/sitemap/models';
import { MainTab } from '../../core/navigation/models';
@Injectable()
export class InsightNavigationService implements IInsightNavigationService {

    constructor(private navigationService: NavigationService) { }

    getTaskBar(taskbarItemId: string): SitemapNode {
        if (taskbarItemId) {
            const component = this.navigationService.getSitemapComponent(MainTab.INSIGHT) as InsightTab;
            const tree = component?.modules.inner_navigation;
            return tree?.nodes.find((node: any) => node.id === taskbarItemId);
        }
    }

    getFirstTaskBar(): string {
        const component = this.navigationService.getSitemapComponent(MainTab.INSIGHT) as InsightTab;
        const tree = component?.modules.inner_navigation;
        if (tree?.nodes.length > 0) {
            const taskBarItemId = tree.nodes[0].id;
            return taskBarItemId;
        }
    }

    isPbi(taskBarItemId: string): boolean {
        return this.navigationService.isInsightPbi(taskBarItemId);
    }

    getFirstNavigationItem(taskbarItemId: string): string {
        if (taskbarItemId) {
            const component = this.navigationService.getSitemapComponent(MainTab.INSIGHT) as InsightTab;
            const tree = component?.modules.inner_navigation;
            const taskBar = tree?.nodes.find(node => node.id === taskbarItemId);
            if (taskBar && taskBar.nodes.length) {
                return taskBar.nodes[0].id;
            }
        }
    }
}
