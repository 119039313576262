import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TaskBarService } from '../../../core/navigation/task-bar/services/task-bar.service';

@Injectable()
export class MessagesCenterTaskBarItemGuard implements CanActivate {

  constructor(private router: Router,
              private taskBarService: TaskBarService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    const taskBarItem = route.params.taskBarItem;
    const taskbar = this.taskBarService.getTaskBar(route.parent.routeConfig.path);
    const isTaskBarItemExist = taskbar.taskBar.items.some((item) => item.id === taskBarItem);
    if (!isTaskBarItemExist) {
      this.router.navigate(['/home/page-not-found'], { replaceUrl: true });
      return false;
    }
    return true;
  }

}
