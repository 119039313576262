import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ChangePasswordCredentials } from '../models/change-password-credentials.model';
import { AuthenticationUrls } from '../models/enums/authentication-urls.enum';
import { SignInCredentials } from '../models/sign-in-credentials.model';
import { TokenTwoAuthCredentials } from '../models/token-two-auth-credentials.model';
import { UserData } from '../models/user-data.model';
import { VascoActivationMessageResponse } from '../models/vasco-activation-message-response.model';
import { VascoCrontoActivate } from '../models/vasco-cronto-activate.model';
import { VascoCrontoValidate } from '../models/vasco-cronto-validate.model';
import { IAuthenticationApiService } from './interfaces/authentication-api-service.interface';
import { AuthenticationService } from './authentication.service';
import { AuthenticationMode } from '../models/enums/authentication-mode.enum';
import { AuthenticationConfigService } from './authentication-config.service';

@Injectable()
export class AuthenticationApiService implements IAuthenticationApiService {

  constructor(private http: HttpClient,
              protected authenticationService: AuthenticationService,
              protected authenticationConfigService: AuthenticationConfigService) { }

  signIn(credentials: SignInCredentials): Observable<UserData> {
    return this.http.post<UserData>(AuthenticationUrls.SIGN_IN, credentials);
  }

  signInTokenAuth(credentials: TokenTwoAuthCredentials): Observable<UserData> {
    return this.http.post<UserData>(AuthenticationUrls.SIGN_IN_TOKEN_AUTH, credentials);
  }

  getUserData(): Observable<UserData> {
    return this.http.post<UserData>(AuthenticationUrls.USER_DATA, null);
  }

  vascoCrontoValidate(data: VascoCrontoValidate): Observable<VascoActivationMessageResponse> {
    return this.http.post<VascoActivationMessageResponse>(AuthenticationUrls.VASCO_CRONTO_VALIDATE, data);
  }

  vascoCrontoActivate(data: VascoCrontoActivate): Observable<void> {
    return this.http.post<void>(AuthenticationUrls.VASCO_CRONTO_ACTIVATE, data);
  }

  changePassword(credentials: ChangePasswordCredentials): Observable<void> {
    return this.http.put<void>(AuthenticationUrls.CHANGE_PASSWORD, credentials);
  }

  signOut(): Observable<void> {
    return this.http.post<void>(AuthenticationUrls.SIGN_OUT, null);
  }

  forceSignOut(sessionId: string): boolean {
    const data = { sessionId };
    const header = new Headers( {
      'Content-Type' : 'application/json' ,
    });
    const isIdpMode = this.authenticationConfigService.authenticationMode === AuthenticationMode.IDP;
    if(isIdpMode){
      header.set('Authorization',this.authenticationService.accessToken);
    }
    fetch(AuthenticationUrls.FORCE_SIGN_OUT, {
      method: 'POST',
      headers: header,
      body: JSON.stringify(data),
      keepalive: true
    });
    return true;
  }

}
