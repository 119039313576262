import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs';
import { TranslationConfigurationService } from './translation-configuration.service';
import { ITranslationsHandler } from './translations-handler.interface';

@Injectable()
export class TranslationsHandlerService implements ITranslationsHandler {

  constructor(private translationConfigurationService: TranslationConfigurationService,
              private translateService: TranslateService) { }


  setTranslation(langId: string): Promise<Object> {
    const defaultLangId = this.translationConfigurationService.getDefaultLang();
    langId = langId || defaultLangId;

    return this.setLangTranslation(langId).catch(() => {
      return this.setLangTranslation(defaultLangId);
    });
  }

  private setLangTranslation(langId: string): Promise<Object> {
    return new Promise((resolve, reject) => {
      this.translateService.use(langId).pipe(filter((translations) => !!translations)).subscribe(translations => {

        this.translateService.setTranslation(langId, translations);

        resolve(translations);
      }, error => {
        reject(error);
      });
    });
  }
}
