import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FnUiIconModule } from '../../shared/fn-ui-icons/fn-ui-icon/fn-ui-icon.module';

import { NavigationGuardService } from './services/navigation-guard.service';
import { NavigationService } from './services/navigation.service';
import { NavigationMapperService } from './services/navigation-mapper.service';
import { TabHandlerService } from './services/tab-handlers/tab-handler.service';
import { InsightTabHandlerService } from './services/tab-handlers/insight-tab-handler.service';
import { MessagesCenterTabHandlerService } from './services/tab-handlers/messages-center-tab-handler.service';
import { ProfilesTabHandlerService } from './services/tab-handlers/profiles-tab-handler.service';
import { SitemapApiService } from './sitemap/services/sitemap-api.service';

import { MainNavigationComponent } from './components/main-navigation/main-navigation.component';

import { ARCHIVE_TOKEN, INSIGHT_TOKEN, LIQUIDITY_TOKEN, MESSAGES_CENTER_TOKEN, PROFILES_TOKEN, RULES_TOKEN } from './models/consts/tab-handler-tokens.const';
import { TaskBarModule } from './task-bar/task-bar.module';
import { ToursModule } from '../../tours/tours.module';
import { ApiCachingModule } from '../api-caching/api-caching.module';
import { ArchiveTabHandlerService } from './services/tab-handlers/archive-tab-handler.service';


@NgModule({
    declarations: [
        MainNavigationComponent
    ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatMenuModule,
    MatTabsModule,
    TranslateModule.forChild(),
    FnUiIconModule,
    TaskBarModule,
    ToursModule,
    ApiCachingModule,
    RouterModule
  ],
    providers: [
        NavigationGuardService,
        NavigationService,
        NavigationMapperService,
        { provide: INSIGHT_TOKEN, useClass: InsightTabHandlerService },
        { provide: LIQUIDITY_TOKEN, useClass: TabHandlerService },
        { provide: MESSAGES_CENTER_TOKEN, useClass: MessagesCenterTabHandlerService },
        { provide: PROFILES_TOKEN, useClass: ProfilesTabHandlerService },
        { provide: RULES_TOKEN, useClass: ProfilesTabHandlerService },
        { provide: ARCHIVE_TOKEN, useClass: ArchiveTabHandlerService },
        SitemapApiService
    ],
    exports: [
        MainNavigationComponent
    ]
})
export class NavigationModule { }
