import { CustomFieldSettings } from '../../../models/custom-field-settings.model';
import { FieldType } from '../enums';

export const CustomFieldAttributesState = {
  [FieldType.DATE]: {
    disable: [
      CustomFieldSettings.MIN_LENGTH,
      CustomFieldSettings.TYPE_LENGTH,
      CustomFieldSettings.NUMBER_PRECISION,
      CustomFieldSettings.LIST_ID,
      CustomFieldSettings.IS_AUTO_EXPOSE_MSG_UDF
    ]
  },
  [FieldType.DATE_TIME]: {
    disable: [
      CustomFieldSettings.MIN_LENGTH,
      CustomFieldSettings.TYPE_LENGTH,
      CustomFieldSettings.NUMBER_PRECISION,
      CustomFieldSettings.LIST_ID,
      CustomFieldSettings.IS_AUTO_EXPOSE_MSG_UDF
    ]
  },
  [FieldType.DECIMAL]: {
    mandatory: [
      CustomFieldSettings.TYPE_LENGTH
    ],
    optional: [
      CustomFieldSettings.NUMBER_PRECISION
    ],
    disable: [
      CustomFieldSettings.MIN_LENGTH,
      CustomFieldSettings.LIST_ID,
      CustomFieldSettings.IS_AUTO_EXPOSE_MSG_UDF
    ]
  },
  [FieldType.NUMBER]: {
    mandatory: [CustomFieldSettings.TYPE_LENGTH],
    disable: [
      CustomFieldSettings.MIN_LENGTH,
      CustomFieldSettings.NUMBER_PRECISION,
      CustomFieldSettings.LIST_ID,
      CustomFieldSettings.IS_AUTO_EXPOSE_MSG_UDF
    ]
  },
  [FieldType.STRING]: {
    mandatory: [
      CustomFieldSettings.MIN_LENGTH,
      CustomFieldSettings.TYPE_LENGTH
    ],
    optional: [
      CustomFieldSettings.LIST_ID
    ],
    disable: [
      CustomFieldSettings.NUMBER_PRECISION,
      CustomFieldSettings.IS_AUTO_EXPOSE_MSG_UDF
    ]
  },
  '': {
    disable: [
      CustomFieldSettings.MIN_LENGTH,
      CustomFieldSettings.TYPE_LENGTH,
      CustomFieldSettings.NUMBER_PRECISION,
      CustomFieldSettings.LIST_ID,
      CustomFieldSettings.IS_AUTO_EXPOSE_MSG_UDF
    ]
  }
};
