<app-fa-dialog [title]="'export.title' | translate" [body]="bodyTemplate" [footer]="footerTemplate">

    <ng-template #bodyTemplate>
        <app-fn-ui-export-form [disableSelectedRowsOption]="data.disableSelectedRowsOption" #appExportForm></app-fn-ui-export-form>
    </ng-template>

    <ng-template #footerTemplate>
        <button automation-id="export-modal-cancel" azure-id="export-modal-cancel" mat-button color="primary" mat-dialog-close>{{'general.cancel' | translate}}</button>
        <button automation-id="export-modal-export" azure-id="export-modal-export" mat-flat-button color="primary" [disabled]="!isValid" (click)="submit()">{{'export.submit' | translate}}</button>
    </ng-template>

</app-fa-dialog>
