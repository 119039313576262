import { Injectable } from '@angular/core';
import { MessagesApiService } from './messages-api.service';
import { GridSearchRequest } from '../../grid/models/grid-search-request.model';
import { HttpParams } from '@angular/common/http';
import { MatchingType } from '../components/messages-matching/models/matching-type.model';
import { GridApiRoutes } from '../../grid/models/enums/grid-api.routes';
import { forkJoin, map } from 'rxjs';

@Injectable()
export class MessagesMatchingApiService extends MessagesApiService {

  getSearch<T extends GridSearchRequest>(searchRequest: T, baseUrl: string, httpParams: HttpParams): Promise<any> {

    const finalResponse = {
      rows: [],
      totalCount: 0
    };
    const typeArray = searchRequest.searchQuery.additionalParameters.matchingTypes as MatchingType[] || [];
    delete searchRequest.searchQuery.additionalParameters.matchingTypes;

    const reqArray = [];
    for (const type of typeArray) {
      const matchingSearchRequest = { ...searchRequest };
      matchingSearchRequest.searchQuery = { ...matchingSearchRequest.searchQuery };
      matchingSearchRequest.searchQuery.additionalParameters = { ...matchingSearchRequest.searchQuery.additionalParameters };
      matchingSearchRequest.searchQuery.additionalParameters['MATCHING_UID'] = type.uid;
      reqArray.push(this.http.post(`${baseUrl}${GridApiRoutes.search}`, matchingSearchRequest, { params: httpParams }));
    }
    return forkJoin(
      reqArray
    ).pipe(
      map((response: any[]) => {
        for (let i = 0; i < response.length; i++) {
          const currentResponse = { ...response[i] };
          if (currentResponse.rows) {
              for (const row of currentResponse.rows) {
                row['MATCHING_TYPE'] = typeArray[i].alias;
                row['MATCHING_TYPE_UID'] = typeArray[i].uid;
              }
              finalResponse.rows.push(...currentResponse.rows);
              finalResponse.totalCount += currentResponse.totalCount;
          }
        }
        return Promise.resolve(finalResponse);
      })
    ).toPromise();
  }
}
