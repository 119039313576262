import { Component, OnInit } from '@angular/core';
import { ProfileFormTemplateProviders } from '../../../core/templates/profile-form.template.providers';
import { AbstractProfileFormComponent } from '../../abstract/abstract-profile/abstract-profile-form.component';

@Component({
  selector: 'app-errcodes',
  templateUrl: '../../../core/templates/profile-form.template.html',
  styleUrls: ['../../../core/templates/profile-form.template.scss'],
  providers: ProfileFormTemplateProviders
})
export class ErrcodesComponent extends AbstractProfileFormComponent implements OnInit {

}

