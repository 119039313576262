import { Component } from '@angular/core';

@Component({
  selector: 'app-fn-ui-scrollbar',
  templateUrl: './fn-ui-scrollbar.component.html',
  styleUrls: ['./fn-ui-scrollbar.component.scss']
})
export class FnUiScrollbarComponent {

}
