import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessagesManagementService } from '../../services/messages-management.service';
import { Observable, Subscription } from 'rxjs';
import { Select } from '@ngxs/store';
import { MessagesCenterQueueState } from '../../../core/state/messages-center-queue.state';
import { MessagesCenterQueue } from '../../../core/models/messages-center-queue.model';
import { MessagesCenterNavigationService } from '../../../messages-center/messages-center-navigation/services/messages-center-navigation.service';
import { BreadcrumbsService } from '../../../ui-components/services/breadcrumbs.service';

@Component({
  selector: 'app-messages-center-templates',
  providers: [],
  templateUrl: 'messages-center-templates.component.html',
})
export class MessagesCenterTemplatesComponent implements OnInit, OnDestroy {

  @Select(MessagesCenterQueueState.getTemplatesQueue) queue$: Observable<MessagesCenterQueue>;

  private _subscription = new Subscription();

  constructor(private breadcrumbs: BreadcrumbsService,
              public messagesCenterNavigationService: MessagesCenterNavigationService,
              private translateService: TranslateService,
              private messagesManagementService: MessagesManagementService) {
    this.messagesCenterNavigationService.registerTreeUpdateFunction(this.updateTree);
  }

  ngOnInit() {
    this._subscription.add(this.queue$.subscribe((queue: MessagesCenterQueue) => {
      this.selectTreeItem(queue);
    }));
    this.messagesCenterNavigationService.initTemplatesQueuesTree();
     this.messagesCenterNavigationService.updateTemplatesQueuesTree();
  }

  selectTreeItem(queue: MessagesCenterQueue): void {
    if (queue && queue.id) {
      const templatesQueuesTree = this.messagesCenterNavigationService.templatesQueuesTree?.nodes.find(node => node.type === queue.entityType);
      this.messagesCenterNavigationService.getQueuesTreeByQueueId(queue.id, templatesQueuesTree);
      this.messagesCenterNavigationService.currentQueue.queueType = queue.type;
      this.messagesManagementService.onTreeItemSelect(this.messagesCenterNavigationService.currentQueue);
      this.handleBreadcrumbs(queue);
    } else {
      this.messagesCenterNavigationService.currentQueue = null;
    }
  }

  private handleBreadcrumbs(templatesQueue): void {
    this.breadcrumbs.reset();
    const firstBreadcrumbAlias = 'messages_center.' + templatesQueue.type;
    const alias = this.translateService.instant(firstBreadcrumbAlias);

    //todo: refactor it

    // const firstBreadcrumb = { link: this.stateService.current.name, alias: alias };
    // this.breadcrumbs.push(firstBreadcrumb);
  }

  /**
   * This function will be passed to a service which it will execute on tree refresh
   * Arrow function is a must in order to keep 'this'
   */
  updateTree = () => {
    return this.messagesCenterNavigationService.updateTemplatesQueuesTree();
  };

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }
}
