import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { CreateToken, CreateSession } from '../../../core/store/session/action/session.action';
import { Token } from '../../models/token.model';
import { AuthenticationApiService } from '../../services/authentication-api.service';
import { AuthenticationIdpService } from '../../services/authentication-idp.service';
import { SecurityService } from '../../services/security.service';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss']
})
export class CallbackComponent implements OnInit, OnDestroy {

  showError = false;
  private urlParamsData;
  private sub: Subscription;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private store: Store,
              private authenticationApiService: AuthenticationApiService,
              private authenticationIdpService: AuthenticationIdpService,
              private securityService: SecurityService) { }

  ngOnInit(): void {
    this.sub = this.route.fragment.subscribe(data => {
      this.urlParamsData = data
      const paramsMap = this.parseUrl(this.urlParamsData);
      const state = paramsMap['state'];
      const code = paramsMap['code'];
      if (state != null && this.securityService.isMatchState(state)) {
        this.getToken(code);
      }
    })
  }

  goToLoginPage(): void {
    this.authenticationIdpService.goToLoginPageIDP();
  }

  private parseUrl(params): { [key: string]: string } {
    const parsedParamsMap = {};
    if (typeof (params) !== 'string') {
      return parsedParamsMap;
    }
    params.split('&').forEach(function (part) {
      const item = part.split('=');
      if (item[0].charAt(0) === '#') {
        item[0] = item[0].substring(1);
      }
      parsedParamsMap[item[0]] = decodeURIComponent(item[1]);
    });
    return parsedParamsMap;
  }

  private getToken(authorizationCode: string): void {
    if (authorizationCode != null) {
      this.authenticationIdpService.getToken(authorizationCode).then(
        response => {
          this.enterToGpp(response);
        },
        error => {
          this.showErrorPage();
        }
      );
    } else {
      this.showErrorPage();
    }
  }

  private async enterToGpp(token: Token): Promise<void> {
    this.store.dispatch(new CreateToken(token));
    try {
      const userData = await this.authenticationApiService.getUserData().toPromise();
      if (userData.sessionId) {
        this.store.dispatch(new CreateSession({ userData }));
        this.router.navigate(['home']);
      } else {
        this.showErrorPage();
      }
    } catch (err) {
      this.showErrorPage();
    }
  }

  private showErrorPage(): void {
    this.showError = true;
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

}
