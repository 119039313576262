<mat-expansion-panel [disabled]="isExpandDisabled" [expanded]="expanded" (opened)="onOpen()"
  (closed)="onClose()" [id]="title" (afterExpand)="afterExpand()" (afterCollapse)="afterCollapse()"
  [ngClass]="{'hide-panel-content': panelClosed}">
  <mat-expansion-panel-header class="dh-panel-header">
    <mat-panel-title>
      <span class="fa-title">{{title}}</span>
      <app-fn-ui-icon icon="alert_circle_outline" [size]="'19px'" *ngIf="showIcon" [matTooltip]="iconTitle"></app-fn-ui-icon>
    </mat-panel-title>
    <mat-panel-description *ngIf="previewTemplate && !panelOpened">
      <ng-container *ngTemplateOutlet="previewTemplate"></ng-container>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <ng-template matExpansionPanelContent>
    <fieldset [disabled]="disabled">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </fieldset>
  </ng-template>
</mat-expansion-panel>
