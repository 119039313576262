import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router } from '@angular/router';
import { NavigationService } from '../../core/navigation/services/navigation.service';
import { StateStoreService } from '../../core/routing/services/state-store.service';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class AuthenticationGuardService implements CanActivate {


  constructor(private authenticationService:AuthenticationService, private router: Router,
              private stateStoreService: StateStoreService, private navigationService: NavigationService) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const isLoggedIn = this.authenticationService.isUserDataSet;
    if(isLoggedIn) {
      await this.navigationService.getSitemap();
      const requiredState = this.getRequiredState();
      if (requiredState) {
        this.goToRequiredState(requiredState);
        return false;
      } else {
        return true;
      }
    } else {
        this.stateStoreService.saveRouterState(window.location.hash.substring(1));
        this.router.navigateByUrl(this.router.url);
        return false;
    }
  }

  private getRequiredState(): string {
    const routerState = this.stateStoreService.getRouterState();
    this.stateStoreService.clearRouterState();
    return routerState;
  }

  private goToRequiredState(url: string) {
    if (url === '/home' || this.navigationService.isUrlAllowed(url)) {
      this.router.navigateByUrl(url);
    } else {
      this.router.navigate(['home/page-not-found']);
    }
  }
}
