import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { MdePopoverModule } from '@material-extended/mde';
import { TranslateModule } from '@ngx-translate/core';
import { NavigationModule } from '../navigation/navigation.module';
import { RulesModule } from '../../rules/rules.module';
import { FnUiEmptyIconModule } from '../../shared/fn-ui-icons/fn-ui-empty-icon/fn-ui-empty-icon.module';
import { FnUiIconModule } from '../../shared/fn-ui-icons/fn-ui-icon/fn-ui-icon.module';
import { ToursModule } from '../../tours/tours.module';

import { SearchBoxComponent } from './components/search-box/search-box.component';
import { SearchMenuComponent } from './components/search-menu/search-menu.component';
import { SearchInputComponent } from './components/search-input/search-input.component';
import { NavigationResultComponent } from './components/navigation-result/navigation-result.component';
import { NavigationResultEmptyComponent } from './components/navigation-result-empty/navigation-result-empty.component';
import { NavigationResultItemComponent } from './components/navigation-result-item/navigation-result-item.component';

import { HighlightSearchDirective } from './directives/highlight-search.directive';

import { NavigationSearchConfigService } from './services/navigation-search-config.service';
import { NavigationSearchSortService } from './services/navigation-search-sort.service';
import { NavigationSearchService } from './services/navigation-search.service';
import { SearchBoxService } from './services/search-box.service';

import { SitemapSearchService } from './services/resources/sitemap-search.service';
import { RuleTypesSearchService } from './services/resources/rule-types-search.service';

import { RULE_TYPES_TOKEN, SITEMAP_TOKEN } from './config/search-resource-tokens.config';
import { AdvancedSearchService } from '../../messages/services/advanced-search.service';
import {MatRippleModule} from "@angular/material/core";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule.forChild(),
    MatButtonModule,
    MatCheckboxModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MdePopoverModule,
    NavigationModule,
    RulesModule,
    FnUiEmptyIconModule,
    FnUiIconModule,
    ToursModule,
    RouterModule,
    MatRippleModule
  ],
  declarations: [
    SearchBoxComponent,
    SearchMenuComponent,
    SearchInputComponent,
    NavigationResultComponent,
    NavigationResultEmptyComponent,
    NavigationResultItemComponent,
    HighlightSearchDirective
  ],
  providers: [
    NavigationSearchConfigService,
    NavigationSearchSortService,
    NavigationSearchService,
    SearchBoxService,
    AdvancedSearchService,
    { provide: SITEMAP_TOKEN, useClass: SitemapSearchService },
    { provide: RULE_TYPES_TOKEN, useClass: RuleTypesSearchService }
  ],
  exports: [
    SearchBoxComponent,
    NavigationResultComponent
  ]
})
export class NavigationSearchModule {

}
