import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Entity } from '../../../../shared/models/enums/entity.enum';
import { CallerLeafData, QueueLeafData, SitemapNode, SitemapTreeItem, SitemapComponent } from '../../sitemap/models';
import { TabHandlerService } from './tab-handler.service';
import { ROUTES_MAPPING_MESSAGES_CENTER } from '../../models/consts/routes-mapping.const';

@Injectable()
export class MessagesCenterTabHandlerService extends TabHandlerService {

  get moduleNames(): Array<string> {
    return Object.keys(this._modulesPerEntity);
  }

  private _modulesPerEntity = {
    system_queues_messages: `${ROUTES_MAPPING_MESSAGES_CENTER['system-queues']}.${Entity.MESSAGES}`,
    system_queues_files: `${ROUTES_MAPPING_MESSAGES_CENTER['system-queues']}.${Entity.FILES}`,
    system_queues_bulks: `${ROUTES_MAPPING_MESSAGES_CENTER['system-queues']}.${Entity.BULKS}`,
    system_queues_batches: `${ROUTES_MAPPING_MESSAGES_CENTER['system-queues']}.${Entity.BATCHES}`,
    custom_queues_messages: `${ROUTES_MAPPING_MESSAGES_CENTER['custom-queues']}.${Entity.MESSAGES}`,
    custom_queues_files: `${ROUTES_MAPPING_MESSAGES_CENTER['custom-queues']}.${Entity.FILES}`,
    custom_queues_bulks: `${ROUTES_MAPPING_MESSAGES_CENTER['custom-queues']}.${Entity.BULKS}`,
    custom_queues_batches: `${ROUTES_MAPPING_MESSAGES_CENTER['custom-queues']}.${Entity.BATCHES}`,
    template_queues_messages: `${ROUTES_MAPPING_MESSAGES_CENTER['templates']}.${Entity.MESSAGES}`,
    callback_queues_messages: `${ROUTES_MAPPING_MESSAGES_CENTER['callback']}.${Entity.MESSAGES}`
  };

  getKey(node: SitemapNode, moduleName: string) {
    let key = `${this._modulesPerEntity[moduleName]}.`;
    const uid = (<CallerLeafData>node.data).uid;
    if (uid) {
      key += uid;
    } else {
      key += node.id;
      const isLeaf = !node.nodes.length;
      if (isLeaf && (<QueueLeafData>node.data).office) {
        key += `.${(<QueueLeafData>node.data).office}`;
      }
    }
    return key;
  }

  shouldMapped(node: SitemapNode): boolean {
    const isStatusQueue = node.nodes?.length && (<QueueLeafData>node.nodes[0].data).office !== undefined;
    return isStatusQueue || super.shouldMapped(node);
  }

  getAlias(node: SitemapNode): string {
    let alias = super.getAlias(node);
    const isLeaf = !node.nodes.length;
    if (isLeaf) {
      alias += ` (${(<QueueLeafData>node.data).office})`;
    }
    return alias;
  }

  getRouterLink(node: SitemapNode, parents: Array<SitemapTreeItem | SitemapComponent>, moduleName?: string): string {
    const mainTab = (parents[0] as SitemapComponent).id;
    const key = this._modulesPerEntity[moduleName];
    return `/home/${mainTab}/${key.replace('.', '/')}`;
  }

  getStateName(node: SitemapNode, parents: Array<SitemapTreeItem | SitemapComponent>, moduleName?: string): string {
    const mainTab = (parents[0] as SitemapComponent).type;
    let queue = this._modulesPerEntity[moduleName];
    const index = queue.lastIndexOf('.');
    queue = queue.substring(0, index);
    return `home.${mainTab}.layout.taskBar.${queue}`;
  }

  getStateParams(node: SitemapNode, parents: Array<SitemapTreeItem>): Params {
    const params = { queueId: node.id } as Params;
    const uid = (<CallerLeafData>node.data).uid;
    if (uid) {
      params.uid = uid;
    } else {
      const isLeaf = !node.nodes.length;
      if (isLeaf) {
        params.queueId += '/' + (<QueueLeafData>node.data).office;
      }
    }
    return params;
  }

}
