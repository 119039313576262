import { RulesProfilesManagementService } from './rules-profiles-management.service';
import { HttpParams } from '@angular/common/http';
import { GridConfig } from '@fgpp-ui/grid';
import { Injectable } from '@angular/core';

@Injectable()
export class AuditTrailsRulesProfilesManagementService extends RulesProfilesManagementService {
  get ruleProfileId() {
    return this._ruleProfileId;
  }

  set ruleProfileId( value ) {
    this._ruleProfileId = value;
  }

  get ruleId() {
    return this._ruleId;
  }

  set ruleId( value ) {
    this._ruleId = value;
  }

  private _ruleId;
  private _ruleProfileId;

  get baseUrl() {
    return `do/PROFILE/${this.ruleProfileId}/auditTrail/`;
  }

  get httpParams(): HttpParams {
    return new HttpParams().set('ruleId', this.ruleId);
  }

  getGridConfig(): GridConfig {
    const gridConfig = super.getGridConfig();
    gridConfig.table.refineFiltering.enableRefine = false;
    gridConfig.filterButtons.refineFilterBtnConfig.showButton = false;
    return gridConfig;
  }
}
