<button mat-button
        [name]="name"
        [attr.automation-id]="name"
        [class]="'fn-ui-button mat-button ' + ButtonTypeClass[type] + ' ' + ButtonColorClass[color]"
        [disableRipple]="disableRipple"
        [disabled]="disabled"
        (click)="onClick()">
  <ng-content></ng-content>
</button>


