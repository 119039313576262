export enum OperandDataType {
  NUMBER = 'NUMBER',
  DECIMAL = 'DECIMAL',
  INTEGER = 'INTEGER',
  NUM_NO_DEC = 'NUM_NO_DEC',
  DATE = 'DATE',
  DATE_TIME = 'DATE_TIME',
  TIME = 'TIME',
  BOOL = 'BOOL',
  STRING = 'STRING',
  BASE_CONDITION = 'BASE_CONDITION'
}
