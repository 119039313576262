import { Injectable } from '@angular/core';
import { IEventTelemetry } from '@microsoft/applicationinsights-web';
import { EventType } from '../../interfaces/event-type.enum';
import { GridEvent } from '../../interfaces/grid-event.model';


@Injectable({
  providedIn: 'root'
})
export class GridEventFilterService {

  private messageFilterRegex = '(?<=mid\:\s)[^\s]+|(?<=uid\:\s)[^\s]+';
  constructor() { }

  public initGridEventFilter() {

  }

  public getGridEventData(gridEvent: GridEvent): IEventTelemetry {
    const eventTelemetry = {} as IEventTelemetry;
    eventTelemetry.name = EventType.GRIDEVENT;
    eventTelemetry.properties = gridEvent;
    return eventTelemetry;
  }
}


