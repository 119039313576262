<app-fn-ui-dialog class="delete-base-condition-modal">

  <ng-template #header>
    <div class="delete-base-header">
      <span class="title" [translate]="params.title"></span>
      <app-fn-ui-close-icon mat-dialog-close></app-fn-ui-close-icon>
    </div>
    <mat-divider></mat-divider>
  </ng-template>

  <ng-template #body>
    <div class="delete-base-body">
      <p>{{params.notification}}</p>
      <br>
      <p>{{params.confirmationMessage}}</p>
    </div>
  </ng-template>

  <ng-template #footer>
    <div class="delete-base-footer">
      <app-fn-ui-button (click)="downloadRules()" class="download-button">
        <span [translate]="'rules.delete_base_condition.download'"></span>
      </app-fn-ui-button>

      <app-fn-ui-button class="copy-to-clipboard" [cdkCopyToClipboard]="params.rules" (cdkCopyToClipboardCopied)="copySuccess()">
        <app-fn-ui-icon icon="content_copy"></app-fn-ui-icon>
        <span [translate]="'rules.preview.copy_to_clipboard'"></span>
      </app-fn-ui-button>

      <app-fn-ui-button type="flat" color="primary" mat-dialog-close>
        <span [translate]="params.cancelButton"></span>
      </app-fn-ui-button>
    </div>
  </ng-template>

</app-fn-ui-dialog>
