import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { FnUiInputComponent } from 'projects/gpp/src/app/shared/fn-ui-input/fn-ui-input.component';
import { ConditionsBankNode, LogicalField } from '../../models';
import { RuleCommonService } from '../../services/rule-common.service';
import { RuleConditionsService } from '../../services/rule-conditions.service';
import { RuleService } from '../../services/rule.service';

@Component({
  selector: 'app-conditions-bank',
  templateUrl: './conditions-bank.component.html',
  styleUrls: ['./conditions-bank.component.scss']
})
export class ConditionsBankComponent implements AfterViewInit {

  @ViewChild(FnUiInputComponent) fnUiInputComponent: FnUiInputComponent;

  searchFilter: string;

  constructor(public ruleCommonService: RuleCommonService,
              public ruleService: RuleService,
              public ruleConditionsService: RuleConditionsService) { }

  ngAfterViewInit(): void {
    this.fnUiInputComponent.matInput['_elementRef'].nativeElement.setAttribute('azure-id', 'search-filter-input');
  }

  closeButtonClicked(): void {
    this.ruleCommonService.toggleConditionsBank();
  }

  onTreeItemSelect(node: ConditionsBankNode): void {
    if (this.ruleCommonService.isReadOnly || this.ruleConditionsService.checkedConditionsCount > 0 || (node as LogicalField).locked || !node.available) {
      return;
    }
    this.ruleCommonService.conditionBankItemSelected(node);
  }

  onTreeItemMouseDown(): void {
    if (this.ruleCommonService.focusedOperand) {
      this.ruleCommonService.loseFocusOperand = this.ruleCommonService.focusedOperand;
    }
  }

}
