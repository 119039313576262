import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FilterOperator } from '@fgpp-ui/grid';
import { NavigationItem, MainTab } from '../../../core/navigation/models';
import { NavigationService } from '../../../core/navigation/services/navigation.service';
import { PROFILE_ID } from '../../../profiles/components/profiles-grid/profiles-grid.component';
import { ConfirmModalComponent } from '../../../shared/fn-ui-modals/components/confirm-modal/confirm-modal.component';
import { ModalsService } from '../../../shared/fn-ui-modals/services/modals.service';
import { SearchFilter } from '../../../shared/models/search-filter.model';
import { IAttachedRuleHandlerService } from './interfaces/attached-rule-handler-service.interface';

@Injectable()
export class AttachedRuleHandlerService implements IAttachedRuleHandlerService {

  get ruleAttachmentsItem(): NavigationItem {
    return this.navigationService.getTabNavigationItem(MainTab.RULES, PROFILE_ID.RULE_ASSOCIATION);
  }

  constructor(private router: Router,
              private navigationService: NavigationService,
              private modalsService: ModalsService) { }

  onDelete(relatedObjectIds: Array<string>): void {
    this.openConfirmModal().afterClosed().subscribe((result: string) => {
      if (result === 'ok') {
        this.goToRuleAttachments(relatedObjectIds);
      }
    });
  }

  private openConfirmModal(): MatDialogRef<ConfirmModalComponent> {
    const confirmData = {
      title: 'attached-rule.delete.title',
      message: 'attached-rule.delete.message',
      okButton: 'general.yes',
      cancelButton: 'general.no'
    };

    return this.modalsService.confirm(confirmData);
  }

  goToRuleAttachments(relatedObjectIds: Array<string>): Promise<boolean> {
    return this.router.navigate([this.ruleAttachmentsItem.routerLink], {
      queryParams: {
        filters: JSON.stringify([this.getObjectIdsFilter(relatedObjectIds)])
      }
    });
  }

  private getObjectIdsFilter(relatedObjectIds: Array<string>): SearchFilter {
    return {
      columnId: 'OBJECT_PRULE_TYPES-UID_OBJECT_PRULE_TYPES',
      operator: FilterOperator.IN,
      value: relatedObjectIds
    };
  }
}
