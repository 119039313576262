import { IProfileFooter } from '../../interfaces/profile-footer.interface';
import { ButtonCategory } from '../../../core/enums/button-category.enum';
import { ProfilesFooterService } from '../../services/profiles-footer.service';
import { FgppButtonProps } from '../../../core/utils/fgpp-button-props.utils';
import { IProfileLayoutSelectionMetadata } from '../../interfaces/profile-layout-selection-metadata.interface';
import { ButtonType } from '../../../core/enums/button-type.enum';
import { ProfileButtons } from '../../enums/profile-buttons.enum';
import { StandingOrder } from '../../models/standing-order.model';
import { StandingOrderActivationStatus } from '../../enums/standing-order-field-mapping.enum';
import { ISdkProfileFooterReducer } from '../../interfaces/profile-footer-reducer.interface';

export class SystemParametersCustomReducer implements ISdkProfileFooterReducer {
  public reduce(buttonCategory: ButtonCategory, footer: IProfileFooter, inputs: IProfileLayoutSelectionMetadata): IProfileFooter {
    const footerService = new ProfilesFooterService().setFooter(footer);
    const buttonList: FgppButtonProps[] = [];
    const data = inputs.formData;
    if (data['SYST_PAR-ENABLE'] !== '1') {
      footerService.removeStandardButtons([ProfileButtons.SAVEAS]);
    }
    return footerService.retain(buttonCategory, buttonList).getFooter();
  }

}
