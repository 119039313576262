import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PRULE_TYPES_RULE_TYPE_ID, PRULE_TYPES_RULE_TYPE_NAME } from '../models/consts/rule-type.consts';

@Injectable()
export class RuleTypeService {

  //TODO: make private after controller refactoring
  ruleTypeSelections: object = { count: 0 };
  rows: Array<any> = [];
  searchFilter: string = null;
  splitListAtIndex = -1;
  lastSelectedRuleType: any = null;
  disableTreeItems = true;
  fromSearch = true;
  sort: any = this.sortRuleTypes;
  filter: any = this.filterRuleTypes;

  setFocus$ = new BehaviorSubject(false);
  noRuleTypes$ = new BehaviorSubject(false);

  put(key: string, value: object): void {
    if (!this.exists(key)) {
      this.ruleTypeSelections[key] = value;
      this.ruleTypeSelections['count']++;
    }
  }

  get(key: string): object {
    return this.ruleTypeSelections[key];
  }

  remove(key: string): void {
    if (this.exists(key)) {
      delete this.ruleTypeSelections[key];
      this.ruleTypeSelections['count']--;
    }
  }

  toArray(): Array<string> {
    const array = Object.keys(this.ruleTypeSelections);
    array.splice(array.indexOf('count'), 1);
    return array;
  }

  getCount(): number {
    return this.ruleTypeSelections['count'];
  }

  removeAll(): void {
    this.ruleTypeSelections = { count: 0 };
    this.rows = [];
    this.searchFilter = null;
    this.splitListAtIndex = -1;
    this.lastSelectedRuleType = null;
  }

  selectAll(): void {
    this.ruleTypeSelections = { count: 0 };
    for (let i = 0; i < this.rows.length; i++) {
      this.put(this.rows[i][PRULE_TYPES_RULE_TYPE_ID], this.rows[i]);
      this.rows[i].selected = true;
    }
  }

  clearAll(): void {
    this.ruleTypeSelections = { count: 0 };
    for (let i = 0; i < this.rows.length; i++) {
      this.rows[i].selected = false;
    }
  }

  sortRuleTypes(): Array<object> {
    if (this.rows && this.rows.length > 0) {
      this.rows.sort(function (row1, row2) {
        const alc = row1[PRULE_TYPES_RULE_TYPE_NAME].toLowerCase();
        const blc = row2[PRULE_TYPES_RULE_TYPE_NAME].toLowerCase();
        if (row1.selected && !row2.selected) {
          return -1;
        } else if (!row1.selected && row2.selected) {
          return 1;
        } else {
          return alc > blc ? 1 : alc < blc ? -1 : 0;
        }
      });

      let index = -1;

      this.rows.forEach(function (row) {
        if (row.selected) {
          index++;
        } else {
          return;
        }
      });

      this.splitListAtIndex = index;
      return this.filterRuleTypes(this.searchFilter);
    }
  }

  filterRuleTypes(searchString: string): Array<object> {
    this.searchFilter = searchString;
    let resourceForSearch = [];

    if (searchString) {
      for (let i = 0; i < this.rows.length; i++) {
        if (this.rows[i][PRULE_TYPES_RULE_TYPE_NAME].toUpperCase()
          .startsWith(searchString.toUpperCase()) || this.rows[i].selected) {
          resourceForSearch.push(this.rows[i]);
        }
      }
    } else {
      resourceForSearch = this.rows;
    }
    return resourceForSearch;
  }

  private exists(key: string): boolean {
    return (this.ruleTypeSelections[key]) ? true : false;
  }
}
