import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IDialogService } from '../../../../../shared/interfaces/dialog-service.interface';
import { FnUiDialogService } from '../../../../../shared/fn-ui-dialog/services/fn-ui-dialog.service';
import { InvestigationService } from '../../../investigation/services/investigation.service';
import { AlertDetailsDialogComponent } from '../alert-details-dialog.component';
import { AlertDetailsDialogData } from '../models/alert-details-dialog-data.model';
import { DialogResult } from '../models/enums/dialog-result.enum';
import { Alert } from '../../../models/alert.model';

@Injectable()
export class AlertDetailsDialogService implements IDialogService<AlertDetailsDialogComponent, AlertDetailsDialogData> {

  constructor(
    private dialogService: FnUiDialogService,
    private investigationService: InvestigationService
  ) { }

  open(dialogData: AlertDetailsDialogData): MatDialogRef<AlertDetailsDialogComponent> {
    const config = {
      data: dialogData,
      panelClass: ['alert-details-panel', dialogData.alert.severity],
      width: '680px',
      autoFocus: false,
      disableClose: true
    };
    const dialogRef = this.dialogService.open(AlertDetailsDialogComponent, config);
    dialogRef.afterClosed().toPromise().then(this.afterClose.bind(this, dialogData.alert));
    return dialogRef;
  }

  private afterClose(alert: Alert, result: DialogResult) {
    if (result === DialogResult.INVESTIGATE) {
      this.investigationService.investigate(alert);
    }
  }

}
