import { Component } from '@angular/core';
import { PartyExplorerService } from '../../../services/party-explorer.service';

@Component({
  selector: 'app-party-explorer-navigation',
  templateUrl: './party-explorer-navigation.component.html',
  styleUrls: ['./party-explorer-navigation.component.scss']
})
export class PartyExplorerNavigationComponent {

  constructor(public partyExplorerService: PartyExplorerService) { }

}
