import { GridContainerConfig } from '../../../grid/models/grid-container-config.model';
import { GridHeaderConfig } from '../../../grid/models/grid-header-config.model';
import { GridFooterConfig } from '../../../grid/models/grid-footer-config.model';

export const ProfilesGridHeaderConfig: GridHeaderConfig = {
  showLastUpdate: true,
  showScopes: true,
  showRefresh: true,
  showLeftMenu: true,
  showRightMenu: true,
  showFavorite: false,
  showType: false,
  showTitle: true,
  showAutoFeeder: false,
  showFilter: false,
  showAllActions: true,
  showCreate: true,
  disableCreate: false,
  processingScope: false
};

export const ProfilesGridFooterConfig: GridFooterConfig = {
  showResultsAndCounts : true,
  groupActions: {
    showAddNotes: false,
    showBeforeAfter: false,
    showGroupActionIcon: false,
    showGroupActions: false
  },
  showCallerDetails: false,
  showCreateFromTemplate: false,
  showResultsWarning: false
};

export const ProfilesGridContainerConfig: GridContainerConfig = {
  gridHeaderConfig: ProfilesGridHeaderConfig,
  gridFooterConfig: ProfilesGridFooterConfig
};

