import { Injectable } from '@angular/core';
import { ComplexCondition, SimpleCondition } from '../models';
import { RuleCommonService } from './rule-common.service';
import { RuleConditionsService } from './rule-conditions.service';

@Injectable()
export class RuleConditionsActionsService {

  get emptyCondition(): SimpleCondition {
    return {
      leftOperand: '',
      leftOperandType: '',
      operator: '',
      rightOperand: [],
      rightOperandType: '',
      isChecked: false
    } as any as SimpleCondition;
  }

  get emptyGroup(): ComplexCondition {
    return {
      logicalOperator: 'AND',
      conditions: []
    } as ComplexCondition;
  }

  private _baseCondition = {} as ComplexCondition;
  private _newParent = {} as ComplexCondition;
  private _isMovedDown = false;

  constructor(private ruleConditionsService: RuleConditionsService,
              private ruleCommonService: RuleCommonService) { }

  add(ruleConditionsTree: ComplexCondition, specificCondition: SimpleCondition): void {
    if (specificCondition) {
      this.addAfterCondition(ruleConditionsTree, specificCondition);
    } else if (this.ruleConditionsService.checkedConditionsCount === 0) {
      ruleConditionsTree.conditions.push(this.emptyCondition);
    } else {
      this.addToCheckedCondition(ruleConditionsTree);
    }
  }

  remove(ruleConditionsTree: ComplexCondition): void {
    while (this.ruleConditionsService.checkedConditionsCount > 0) {
      this.removeCondition(ruleConditionsTree);
    }

    for (let i = 0; i < this.ruleConditionsService.maxTreeLevel; i++) {
      this.checkIfGroupIsEmptyOrWithOneCondition(ruleConditionsTree);
    }
  }

  removeLastCondition(): void {
    this.ruleConditionsService.conditions.conditions.pop();
  }

  moveRight(): void {
    this.getCountCheckedConditions(this.ruleConditionsService.conditions);
    if (this.ruleConditionsService.checkedConditionsCount === 1) {
      this.goRightForOneCondition(this.ruleConditionsService.conditions);
    } else if (this.ruleConditionsService.checkIfCheckedConditionsInTheSameGroup()) {
      this.ruleConditionsService.unMarkGroupsWithCheckedConditions(this.ruleConditionsService.conditions);
      const isConsecutive =
        this.checkIfCheckedConditionsConsecutive(this.ruleConditionsService.checkedGroup, this.ruleConditionsService.checkedConditionsCount);
      if (!isConsecutive) {
        this.doConsecutiveCheckedConditions(this.ruleConditionsService.checkedGroup);
      }
      this.goRightForManyConditions(this.ruleConditionsService.checkedGroup, this.ruleConditionsService.checkedConditionsCount);
    }
  }

  moveLeft(): void {
    this.ruleConditionsService.checkIfCheckedConditionsInTheSameGroup();
    this.doMoveLeft(this.ruleConditionsService.checkedGroup, this.ruleConditionsService.checkedConditionsCount);
    this.ruleConditionsService.unMarkGroupsWithCheckedConditions(this.ruleConditionsService.conditions);
  }

  moveDown(): void {
    this.getCountCheckedConditions(this.ruleConditionsService.conditions);
    this._isMovedDown = false;
    this.doMoveDown(this.ruleConditionsService.conditions);
  }

  moveUp(): void {
    this.doMoveUp(this.ruleConditionsService.conditions);
  }

  beautify(group: ComplexCondition): void {
    this.doBeauty(group, this.ruleConditionsService.maxTreeLevel);
  }

  convertToBaseCondition(): ComplexCondition | SimpleCondition {
    this.ruleConditionsService.countParentsGroups = 0;
    this.ruleConditionsService.markParentsGroups(this.ruleConditionsService.conditions);
    if (this.ruleConditionsService.countParentsGroups === 0) {
      return this.getCheckedConditionsFromMarkedGroup(this.ruleConditionsService.checkedGroup);
    } else if (this.ruleConditionsService.countParentsGroups === 1 && this.ruleConditionsService.checkedConditionsCount === 1) { //single inner condition case
      return this.ruleConditionsService.getCheckedCondition(this.ruleConditionsService.conditions.conditions);
    } else if (this.ruleConditionsService.countParentsGroups === 1) {
      return this.ruleConditionsService.parentGroup;
    } else if (this.ruleConditionsService.countParentsGroups > 1) {
      this.ruleConditionsService.checkIfCheckedConditionsInTheSameGroup();
      this.ruleConditionsService.countParentsGroups = 0;
      this.ruleConditionsService.markParentsGroups(this.ruleConditionsService.conditions);
      this.getBaseConditionGroup(this.ruleConditionsService.conditions);
      return this.getCheckedConditionsFromMarkedGroup(this._baseCondition);
    }
  }

  changeCheckedConditionsToExistingBaseCondition(group: ComplexCondition, existingBaseCondition: SimpleCondition): void {
    if (this.ruleConditionsService.countParentsGroups === 0) {
      this.changeConditionsToExistingBaseCondition(group, existingBaseCondition);
    } else if (this.ruleConditionsService.countParentsGroups === 1) {
      this.ruleConditionsService.markParentsGroups(this.ruleConditionsService.conditions);
      group.isParent ?
        this.changeAllConditionsToBaseCondition(group, existingBaseCondition) :
        this.changeParentGroupToExistingBaseCondition(group, existingBaseCondition);
    } else {
      this.ruleConditionsService.markParentsGroups(this.ruleConditionsService.conditions);
      this.changeConditionsToExistingBaseCondition(this.ruleConditionsService.conditions, existingBaseCondition);
    }
  }

  private changeAllConditionsToBaseCondition(group: ComplexCondition, existingBaseCondition: SimpleCondition): void {
    group.conditions.length = 0;
    group.conditions.push(existingBaseCondition);
  }

  private changeParentGroupToExistingBaseCondition(group: ComplexCondition, existingBaseCondition: SimpleCondition): void {
    for (let i = 0; i < group.conditions.length; i++) {
      if ((group.conditions[i] as ComplexCondition).isParent) {
        group.conditions.splice(i, 1, existingBaseCondition);
      } else if ((group.conditions[i] as ComplexCondition).logicalOperator) {
        this.changeParentGroupToExistingBaseCondition(group.conditions[i] as ComplexCondition, existingBaseCondition);
      }
    }
  }

  private changeConditionsToExistingBaseCondition(group: ComplexCondition, existingBaseCondition: SimpleCondition): void {
    if (!group.conditions) {
      return;
    }

    let isChanged = false;
    const indexes = [];

    for (let i = 0; i < group.conditions.length; i++) {
      if ((group.conditions[i] as SimpleCondition).isChecked || (group.conditions[i] as ComplexCondition).isParent) {
        if (isChanged) {
          indexes.push(i);
        } else {
          group.conditions.splice(i, 1, existingBaseCondition);
          isChanged = true;
        }
      } else if ((group.conditions[i] as ComplexCondition).logicalOperator) {
        this.changeConditionsToExistingBaseCondition(group.conditions[i] as ComplexCondition, existingBaseCondition);
      }
    }

    this.deleteConditions(group, indexes);
  }

  private deleteConditions(group: ComplexCondition, indexes: Array<number>): void {
    for (let i = 0; i < indexes.length; i++) {
      group.conditions.splice(indexes[i] - i, 1);
    }
  }

  private getBaseConditionGroup(group: ComplexCondition): void {
    for (let i = 0; i < group.conditions.length; i++) {
      if ((group.conditions[i] as ComplexCondition).isParent) {
        this._baseCondition = JSON.parse(JSON.stringify(group));
        break;
      } else if ((group.conditions[i] as ComplexCondition).logicalOperator) {
        this.getBaseConditionGroup(group.conditions[i] as ComplexCondition);
      }
    }
  }

  private getCheckedConditionsFromMarkedGroup(group: ComplexCondition): ComplexCondition {
    const conditions = [];
    for (let i = 0; i < group.conditions.length; i++) {
      if ((group.conditions[i] as SimpleCondition).isChecked || (group.conditions[i] as ComplexCondition).isParent) {
        conditions.push(group.conditions[i]);
      }
    }
    const checkedGroupWithOnlyCheckedConditions = JSON.parse(JSON.stringify(group));
    checkedGroupWithOnlyCheckedConditions.conditions = conditions;
    return checkedGroupWithOnlyCheckedConditions;
  }

  private doBeauty(group: ComplexCondition, maxTreeLevel: number): void {
    if (!group.conditions) {
      return;
    }

    let isChangedConditionsInTheTree = false;

    for (let i = 0; i < maxTreeLevel; i++) {
      if (i === 0 || (i > 0 && isChangedConditionsInTheTree)) {
        const indexes = this.getIndexesConditionsWithTheSameLogicalOperator(group, maxTreeLevel);
        if (indexes.length > 0) {
          isChangedConditionsInTheTree = true;
          this.deleteRedundantOperators(indexes, group);
        }
      }
    }
  }

  private deleteRedundantOperators(indexes: Array<number>, group: ComplexCondition): void {
    let numberAddedConditions = 0;
    for (let i = 0; i < indexes.length; i++) {
      const numberConditions = (group.conditions[indexes[i] + numberAddedConditions - i] as ComplexCondition).conditions.length;
      const conditions = (group.conditions[indexes[i] + numberAddedConditions - i] as ComplexCondition).conditions;
      group.conditions.splice(indexes[i] + numberAddedConditions - i, 1);
      for (let j = 0; j < conditions.length; j++) {
        group.conditions.splice(indexes[i] + numberAddedConditions - i + j, 0, conditions[j]);
      }
      numberAddedConditions = numberAddedConditions + numberConditions;
    }
  }

  private getIndexesConditionsWithTheSameLogicalOperator(group: ComplexCondition, maxTreeLevel: number): Array<number> {
    const indexes = [];
    for (let i = 0; i < group.conditions.length; i++) {
      if ((group.conditions[i] as ComplexCondition).logicalOperator) {
        group.logicalOperator === (group.conditions[i] as ComplexCondition).logicalOperator ? indexes.push(i) :
          this.doBeauty(group.conditions[i] as ComplexCondition, maxTreeLevel - 1);
      }
    }
    return indexes;
  }

  private doMoveUp(group: ComplexCondition, parent?: ComplexCondition): void {
    for (let i = 0; i < group.conditions.length; i++) {
      if ((group.conditions[i] as SimpleCondition).isChecked === true) {
        if (i > 0) {
          this.addConditionToTheSameGroup(group, i);
        } else if (parent !== undefined) {
          (group.conditions[i] as SimpleCondition).isChecked = false;
          this.addToParentGroupMoveUp(group, group.conditions[i] as SimpleCondition, parent);
          this.deleteCondition(group, i);
        }
        break;
      } else if ((group.conditions[i] as ComplexCondition).logicalOperator) {
        this.doMoveUp(group.conditions[i] as ComplexCondition, group);
      }
    }
  }

  private addToParentGroupMoveUp(group: ComplexCondition, elem: SimpleCondition, parent: ComplexCondition): void {

    const addToParentGroup = (): void => {
      this.changeParentGroup(parent);
      this.addToParentGroupMoveUp(parent, elem, this._newParent);
    };

    if (parent.logicalOperator && parent.conditions.length > 1) {
      group.isCheckedCondition = true;
      const indexNumber = this.getIndexOfCheckedElementInParentArray(parent);
      group.isCheckedCondition = false;
      indexNumber > 0 ? this.addToPreviousSibling(parent, elem, indexNumber) : addToParentGroup();
    } else {
      addToParentGroup();
    }
  }

  private changeParentGroup(parent: ComplexCondition): number {
    parent.isCheckedCondition = true;
    this.getParentGroup(this.ruleConditionsService.conditions);
    const index = this.getIndexOfCheckedElementInParentArray(this._newParent);
    parent.isCheckedCondition = false;
    return index;
  }

  private addConditionToTheSameGroup(group: ComplexCondition, conditionNumber: number): void {
    if (!(group.conditions[conditionNumber - 1] as ComplexCondition).logicalOperator) {
      this.changePlacesBetweenSimpleConditions(group, conditionNumber);
    } else {
      this.addToPreviousSibling(group, group.conditions[conditionNumber] as SimpleCondition, conditionNumber);
      this.deleteCondition(group, conditionNumber);
    }
  }

  private changePlacesBetweenSimpleConditions(group: ComplexCondition, index: number): void {
    const prev = group.conditions[index - 1];
    group.conditions[index - 1] = group.conditions[index];
    group.conditions[index] = prev;
  }

  private addToPreviousSibling(group: ComplexCondition, elem: SimpleCondition, index: number): void {
    if ((group.conditions[index - 1] as ComplexCondition).logicalOperator) {

      const condition = (group.conditions[index - 1] as ComplexCondition).conditions.length === 1 ?
        (group.conditions[index - 1] as ComplexCondition).conditions[0] :
        (group.conditions[index - 1] as ComplexCondition).conditions[(group.conditions[index - 1] as ComplexCondition).conditions.length - 1];

      (condition as ComplexCondition).logicalOperator ?
        this.addItemToBottom(condition as ComplexCondition, elem) : this.addToArray(group, index, elem);

    } else {
      group.conditions.splice(index, 0, elem);
      (group.conditions[index] as SimpleCondition).isChecked = true;
      (group.conditions[index + 1] as ComplexCondition).isCheckedCondition = false;
    }
  }

  private addToArray(group: ComplexCondition, index: number, elem: SimpleCondition): void {
    (group.conditions[index - 1] as ComplexCondition).conditions.push(elem);
    ((group.conditions[index - 1] as ComplexCondition)
      .conditions[(group.conditions[index - 1] as ComplexCondition).conditions.length - 1] as SimpleCondition).isChecked = true;
  }

  private addItemToBottom(group: ComplexCondition, elem: SimpleCondition): void {
    if (group.conditions.length === 1) {
      this.addItemToBottomByCondition(group.conditions[0], group, elem);
    } else {
      this.addItemToBottomByCondition(group.conditions[group.conditions.length - 1], group, elem);
    }
  }

  private addItemToBottomByCondition(condition: ComplexCondition | SimpleCondition, group: ComplexCondition, elem: SimpleCondition): void {
    if ((condition as ComplexCondition).logicalOperator) {
      this.addItemToBottom(condition as ComplexCondition, elem);
    } else {
      group.conditions.push(elem);
      (group.conditions[group.conditions.length - 1] as SimpleCondition).isChecked = true;
    }
  }

  private getCountCheckedConditions(ruleConditionsTree: ComplexCondition): void {
    this.ruleConditionsService.checkedConditionsCount = 0;
    this.ruleConditionsService.countCheckedConditions(ruleConditionsTree);
  }

  private doMoveDown(group: ComplexCondition): void {
    for (let i = 0; i < group.conditions.length; i++) {
      if (!this._isMovedDown && (group.conditions[i] as SimpleCondition).isChecked) {
        if (i < group.conditions.length - 1) {
          this.addToTheSameGroup(group, i);
        } else {
          this.addToParentGroup(group, i);
        }
      } else if (!this._isMovedDown && (group.conditions[i] as ComplexCondition).logicalOperator) {
        this.doMoveDown(group.conditions[i] as ComplexCondition);
      }
    }
  }

  private addToTheSameGroup(group: ComplexCondition, conditionNumber: number): void {
    if (!(group.conditions[conditionNumber + 1] as ComplexCondition).logicalOperator) {
      this.changePlacesBetweenSimpleConditions(group, conditionNumber + 1);
      this._isMovedDown = true;
    } else {
      this.addToNextSibling(group, group.conditions[conditionNumber] as SimpleCondition, conditionNumber);
      this.deleteCondition(group, conditionNumber);
      this._isMovedDown = true;
    }
  }

  private addToNextSibling(group: ComplexCondition, elem: SimpleCondition, index: number): void {
    if ((group.conditions[index + 1] as ComplexCondition).logicalOperator !== undefined) {
      if (((group.conditions[index + 1] as ComplexCondition).conditions[0] as ComplexCondition).logicalOperator) {
        this.addItemToTop((group.conditions[index + 1] as ComplexCondition).conditions[0] as ComplexCondition, elem);
      } else {
        this.addToTopOfArrayConditions((group.conditions[index + 1] as ComplexCondition).conditions, elem);
      }
    } else {
      const obj = {
        leftOperand: elem.leftOperand, leftOperandType: elem.leftOperandType, operator: elem.operator,
        rightOperand: elem.rightOperand, rightOperandType: elem.rightOperandType, isChecked: true
      } as SimpleCondition;
      group.conditions.splice(index + 1, 0, obj);
      (group.conditions[index + 1] as SimpleCondition).isChecked = true;
    }
  }

  private addToTopOfArrayConditions(arrayConditions: Array<ComplexCondition | SimpleCondition>, elem: SimpleCondition): void {
    const obj = JSON.parse(JSON.stringify(elem));
    arrayConditions.unshift(obj);
    (arrayConditions[0] as SimpleCondition).isChecked = true;
  }

  private addItemToTop(group: ComplexCondition, elem: SimpleCondition): void {
    if ((group.conditions[0] as ComplexCondition).logicalOperator) {
      this.addItemToTop(group.conditions[0] as ComplexCondition, elem);
    } else {
      const obj = {
        leftOperand: elem.leftOperand,
        leftOperandType: elem.leftOperandType,
        operator: elem.operator,
        rightOperand: elem.rightOperand,
        rightOperandType: elem.rightOperandType,
        isChecked: true
      } as SimpleCondition;
      group.conditions.unshift(obj);
      if (group.conditions.length === 1) {
        (group.conditions[0] as SimpleCondition).isChecked = true;
      }
    }
  }

  private deleteCondition(group: ComplexCondition, conditionNumber: number): void {
    group.conditions.splice(conditionNumber, 1);
    this.checkIfGroupIsEmptyOrWithOneCondition(this.ruleConditionsService.conditions);
  }

  private doMoveLeft(group: ComplexCondition, checkedConditionsNumber: number): void {
    this.getParentGroup(this.ruleConditionsService.conditions);
    if (group.conditions.length === checkedConditionsNumber) {
      this.addAllConditionsFromCheckedGroupToParentGroup(group);
    } else {
      this.addCheckedConditionsFromCheckedGroupToParentGroup(group);
    }
  }

  private addToParentGroup(group: ComplexCondition, conditionNumber: number): void {
    let isAdded = false;
    if (group.level === undefined || group.level !== 0) {
      group.isCheckedCondition = true;
      this.getParentGroup(this.ruleConditionsService.conditions);
      const indexInParentArray = this.getIndexOfCheckedElementInParentArray(this._newParent);
      group.isCheckedCondition = false;
      isAdded = this.addToParentGroupMoveDown(group.conditions[conditionNumber] as SimpleCondition, indexInParentArray, this._newParent);
    } else {
      isAdded = this.addToParentGroupMoveDown(group.conditions[conditionNumber] as SimpleCondition, conditionNumber, group);
    }
    if (isAdded) {
      this.deleteCondition(group, conditionNumber);
      this._isMovedDown = true;
    }
  }

  private addToParentGroupMoveDown(elem: SimpleCondition, indexInParentArray: number, parent: ComplexCondition): boolean {
    const moveDown = (): boolean => {
      const index = this.changeParentGroup(parent);
      return this.addToParentGroupMoveDown(elem, index, this._newParent);
    };

    if (parent.conditions.length > 1) {
      if (indexInParentArray < (parent.conditions.length - 1)) {
        this.addToNextSibling(parent, elem, indexInParentArray);
        return true;
      } else if (parent.level === undefined) {
        return moveDown();
      } else {
        return false;
      }
    } else {
      return moveDown();
    }
  }

  private addAllConditionsFromCheckedGroupToParentGroup(group: ComplexCondition): void {
    const indexInParentGroup = this.getIndexOfCheckedElementInParentArray(this._newParent);
    for (let k = 0; k < group.conditions.length; k++) {
      this._newParent.conditions.push(group.conditions[k]);
    }
    this._newParent.conditions.splice(indexInParentGroup, 1);
  }

  private getIndexOfCheckedElementInParentArray(parent: ComplexCondition): number {
    for (let i = 0; i < parent.conditions.length; i++) {
      if ((parent.conditions[i] as ComplexCondition).isCheckedCondition) {
        return i;
      }
    }
    return 0;
  }

  private addCheckedConditionsFromCheckedGroupToParentGroup(group: ComplexCondition): void {
    const indexes = [];
    for (let i = 0; i < group.conditions.length; i++) {
      if ((group.conditions[i] as SimpleCondition).isChecked === true) {
        this._newParent.conditions.push(group.conditions[i]);
        indexes.push(i);
      }
    }
    for (let i = 0; i < indexes.length; i++) {
      group.conditions.splice(indexes[i] - i, 1);
    }
  }

  private getParentGroup(group: ComplexCondition): void {
    for (let i = 0; i < group.conditions.length; i++) {
      if ((group.conditions[i] as ComplexCondition).isCheckedCondition === true) {
        this._newParent = group;
        break;
      } else if ((group.conditions[i] as ComplexCondition).logicalOperator) {
        this.getParentGroup(group.conditions[i] as ComplexCondition);
      }
    }
  }

  private goRightForManyConditions(group: ComplexCondition, checkedConditionsNumber: number): void {
    const newGroup = this.emptyGroup;
    const conditionsLength = group.conditions.length;
    if (checkedConditionsNumber === conditionsLength) { //in case all group is checked
      group.conditions.push(this.emptyCondition);
    }
    for (let i = 0; i < conditionsLength; i++) {
      if ((group.conditions[i] as SimpleCondition).isChecked) {
        for (let j = 0; j < checkedConditionsNumber; j++) {
          newGroup.conditions.push(group.conditions[i + j]);
        }
        group.conditions.splice(i, checkedConditionsNumber);
        group.conditions.splice(i, 0, newGroup);
        break;
      }
    }
  }

  private doConsecutiveCheckedConditions(group: ComplexCondition): void {
    const indexes = [];
    const conditions = [];
    for (let i = 0; i < group.conditions.length; i++) {
      if ((group.conditions[i] as SimpleCondition).isChecked) {
        indexes.push(i);
        conditions.push(group.conditions[i]);
      }
    }
    for (let k = 0; k < indexes.length; k++) {
      group.conditions.splice(indexes[k] - k, 1);
    }
    for (let m = 0; m < conditions.length; m++) {
      group.conditions.push(conditions[m]);
    }
  }

  private checkIfCheckedConditionsConsecutive(group: ComplexCondition, checkedConditionsNumber: number): boolean {
    if (checkedConditionsNumber === group.conditions.length) {
      return true;
    }
    let previousCheckedConditionIndex = -1;
    for (let i = 0; i < group.conditions.length; i++) {
      if ((group.conditions[i] as SimpleCondition).isChecked) {
        if (previousCheckedConditionIndex !== -1 && i - previousCheckedConditionIndex > 1) {
          return false;
        } else {
          previousCheckedConditionIndex = i;
        }
      }
    }
    return true;
  }

  private goRightForOneCondition(group: ComplexCondition): void {
    const conditionsLength = group.conditions.length;
    for (let i = 0; i < conditionsLength; i++) {
      if ((group.conditions[i] as SimpleCondition).isChecked) {
        const newGroup = this.emptyGroup;
        newGroup.conditions.push(group.conditions[i]);
        group.conditions.splice(i, 1);
        group.conditions.splice(i, 0, newGroup);
        newGroup.conditions.push(this.emptyCondition);
        break;
      } else if ((group.conditions[i] as ComplexCondition).logicalOperator) {
        this.goRightForOneCondition(group.conditions[i] as ComplexCondition);
      }
    }
  }

  private removeCondition(group: ComplexCondition): void {
    for (let i = 0; i < group.conditions.length; i++) {
      if ((group.conditions[i] as SimpleCondition).isChecked === true) {
        this.ruleConditionsService.checkedConditionsCount--;
        this.ruleCommonService.operatorsChangedOnInit--;
        group.conditions.splice(i, 1);
      } else if ((group.conditions[i] as ComplexCondition).logicalOperator) {
        this.removeCondition(group.conditions[i] as ComplexCondition);
      }
    }
  }

  private checkIfGroupIsEmptyOrWithOneCondition(group: ComplexCondition): void {
    if (group.conditions.length === 1) {
      group.logicalOperator = (group.conditions[0] as ComplexCondition).logicalOperator;
      group.conditions = (group.conditions[0] as ComplexCondition).conditions;
    } else {
      this.checkIfGroupIsEmpty(group);
    }
  }

  private checkIfGroupIsEmpty(group: ComplexCondition): void {
    for (let i = 0; i < group.conditions.length; i++) {
      if ((group.conditions[i] as ComplexCondition).logicalOperator) {
        const complexCondition = group.conditions[i] as ComplexCondition;
        if (complexCondition.conditions.length === 0) {
          group.conditions.splice(i, 1);
        } else if (complexCondition.conditions.length === 1) {
          group.conditions[i] = complexCondition.conditions[0];
        } else {
          this.checkIfGroupIsEmpty(complexCondition);
        }
      }
    }
  }

  private addAfterCondition(ruleConditionsTree: ComplexCondition, condition: SimpleCondition): void {
    for (let i = 0; i < ruleConditionsTree.conditions.length; i++) {

      if (ruleConditionsTree.conditions[i] === condition) {
        ruleConditionsTree.conditions.splice(i + 1, 0, this.emptyCondition);
        break;
      } else if ((ruleConditionsTree.conditions[i] as ComplexCondition).conditions) {
        this.addAfterCondition(ruleConditionsTree.conditions[i] as ComplexCondition, condition);
      }
    }
  }

  private addToCheckedCondition(ruleConditionsTree: ComplexCondition): void {
    for (let i = 0; i < ruleConditionsTree.conditions.length; i++) {
      if ((ruleConditionsTree.conditions[i] as SimpleCondition).isChecked === true) {
        ruleConditionsTree.conditions.splice(i + 1, 0, this.emptyCondition);
        break;
      } else if ((ruleConditionsTree.conditions[i] as ComplexCondition).logicalOperator) {
        this.addToCheckedCondition(ruleConditionsTree.conditions[i] as ComplexCondition);
      }
    }
  }

}
