import { GridContainerConfig } from '../../../grid/models/grid-container-config.model';
import { GridHeaderConfig } from '../../../grid/models/grid-header-config.model';

export const ProfilesInnerGridHeaderConfig: GridHeaderConfig = {
  showLastUpdate: false,
  showScopes: false,
  showRefresh: false,
  showLeftMenu: false,
  showRightMenu: true,
  showFavorite: false,
  showType: false,
  showTitle: false,
  showAutoFeeder: false,
  showFilter: false,
  showAllActions: false,
  showExport: true,
  showPrint: true,
  showCreate: false,
  disableCreate: false,
  processingScope: false
};

export const ProfilesInnerGridContainerConfig: GridContainerConfig = {
  gridHeaderConfig: ProfilesInnerGridHeaderConfig,
};

