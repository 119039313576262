<div class="dh-inner-condition-wrapper">

  <div class="dh-simple-condition">
    <!--we want the styles of simple-condition for keeping it aligned-->

    <div class="dh-check-box-wrapper"></div>
    <!--dummy for align the flex styles as its simple-condition father-->

    <div class="dh-inner-condition-actions">
      <button
        mat-icon-button dense
        color="primary"
        (click)="onMoveUp()"
        [title]="'rules.button.inner_condition.moveUp' | translate" [disabled]="disableUpBtn"
        [hidden]="removeAllButtons">
          <app-fn-ui-icon icon="chevron_up"></app-fn-ui-icon>
      </button>

      <button
        mat-icon-button dense
        color="primary"
        (click)="onMoveDown()"
        [title]="'rules.button.inner_condition.moveDown' | translate" [disabled]="disableDownBtn"
        [hidden]="removeAllButtons">
          <app-fn-ui-icon icon="chevron_down"></app-fn-ui-icon>
      </button>

      <button
        mat-icon-button dense
        color="primary"
        (click)="onRemove()"
        [title]="'rules.button.inner_condition.remove' | translate" [hidden]="removeAllButtons">
          <app-fn-ui-icon icon="minus"></app-fn-ui-icon>
      </button>

      <button
        mat-icon-button dense
        color="primary"
        (click)="onAdd()"
        [title]="'rules.button.inner_condition.add' | translate" *ngIf="showAddBtn">
          <app-fn-ui-icon icon="plus"></app-fn-ui-icon>
      </button>
    </div>

    <app-condition-operator [operator]="condition.conditionOperator" [operatorList]="operators" class="condition-operator-wrapper"
      (operatorChanged)="operatorChanged($event)">
    </app-condition-operator>

    <div class="condition-right-operand-wrapper">
      <app-condition-operand *ngIf="!shouldShowDrillDown" [operand]="condition.rightOperand"
        [operandDataType]="condition.rightOperand.type" [allowedTypes]="allowedTypes" [isRightOperand]="true">
      </app-condition-operand>

      <app-fn-ui-drill-down *ngIf="shouldShowDrillDown" [dhDrillDown]="valueList.source"
        [inputValue]="condition.rightOperand.alias" [filterParameters]="valueList.filter"
        [preventMultiSelect]="valueList.shouldDisableMultiSelect" [entity]="'rules'" [isRequired]="true"
        (rowSelected)="onValueListSelected($event.selectedRows)">
      </app-fn-ui-drill-down>
    </div>

    <div class="dh-simple-condition-actions"></div>
    <!--dummy for align the flex styles as its simple-condition father-->
  </div>
</div>
