import {
  ComponentManagerService,
  FormService, ModalFormValueChange,
  MultiLineComponentTemplate,
  MultiLineFormModalComponent
} from '@fgpp-ui/components';
import { Component, OnDestroy, OnInit } from '@angular/core';


@Component({
  selector: 'app-md-fields-to-mask',
  template : MultiLineComponentTemplate.HTML_TEMPLATE,
  styles: [MultiLineComponentTemplate.STYLE_TEMPLATE],
  providers: [
    FormService,
    ComponentManagerService
  ]
})
export class FieldsToParticipantAvailabilityComponent extends MultiLineFormModalComponent implements OnInit, OnDestroy {
  formLoadComplete(): void {
    super.formLoadComplete();
    this.formMgr.get('SERVICE_PARAMETERS-MOP').setValue(this.data.parentFormData['TASKS_SETTINGS-MOP']);
  }
  protected valueChanges(change: ModalFormValueChange): void {
    if(change.control === 'SERVICE_PARAMETERS-SERVICE'){
      this.formMgr.get('SERVICE_PARAMETERS-VERSION').reset();
    }
  }
}
