import { Component } from '@angular/core';
import { IValueChange } from '../../../core/interfaces/form-value-change.interface';
import { BFOnValueChange } from '../../../core/interfaces/sdk-profile-hooks.interface';
import { ProfileFormTemplateProviders } from '../../../core/templates/profile-form.template.providers';
import { AbstractProfileFormComponent } from '../../abstract/abstract-profile/abstract-profile-form.component';
import { AccountAlias } from '../../models/account-alias.model';

const ACCOUNTS_CURRENCY = 'ACCOUNTS-CURRENCY';

@Component({
  selector: 'app-account-alias',
  templateUrl: '../../../core/templates/profile-form.template.html',
  styleUrls: ['../../../core/templates/profile-form.template.scss'],
  providers: ProfileFormTemplateProviders
})
export class AccountAliasComponent extends AbstractProfileFormComponent implements BFOnValueChange {

  fgppValueChanges(change: IValueChange): void {
    if (change.control === AccountAlias.ACC_NO) {
      const data = this.getDrilldownData(AccountAlias.ACC_NO);
      const currency = data ? data[ACCOUNTS_CURRENCY] : '';
      this.formMgr.get(AccountAlias.CURRENCY).setValue(currency);
    }
  }
}
