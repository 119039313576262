import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FnUiRefreshIconComponent } from './fn-ui-refresh-icon.component';
import { MatButtonModule } from '@angular/material/button';
import { FnUiIconModule } from '../fn-ui-icon/fn-ui-icon.module';


@NgModule({
    imports: [
        CommonModule,
        MatButtonModule,
        FnUiIconModule
    ],
    declarations: [
        FnUiRefreshIconComponent
    ],
    exports: [
        FnUiRefreshIconComponent
    ]
})
export class FnUiRefreshIconModule { }
