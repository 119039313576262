import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { filter, timeoutWith, of } from 'rxjs';
import { take } from 'rxjs/operators';
import { AuthenticationService } from '../../authentication/services/authentication.service';
import { MultiTabsManagerService } from '../../core/multi-tabs/services/multi-tabs-manager.service';
import { DuplicateSession } from '../../core/store/session/action/session.action';
import { SessionState } from '../../core/store/session/state/session.state';

@Injectable({
  providedIn: 'root'
})
export class MultiTabsInitializerService {

  private readonly _multiTabsGetSessionTimeout = 500;

  constructor(private store: Store,
              private authenticationService: AuthenticationService,
              private multiTabsManagerService: MultiTabsManagerService) { }

    async isAuthenticated(): Promise<any> {
     console.log('MultiTabsInitializerService');
     if (this.authenticationService.isUserDataSet) {
      return true;
    }
    if (this.checkSessionStorageAuthentication()) {
      return true;
    }
    return await this.checkMultiTabsAuthentication();
  }

  private checkSessionStorageAuthentication(): boolean {
    const token = JSON.parse(sessionStorage.getItem('token'));
    const userData = JSON.parse(sessionStorage.getItem('userData'));
    if (userData) {
      this.store.dispatch(new DuplicateSession({ token, userData }));
      return true;
    }
    return false;
  }

  private async checkMultiTabsAuthentication(): Promise<any> {
    if (!this.multiTabsManagerService.hasActiveTabs) {
      return false;
    }

    localStorage.setItem('session', JSON.stringify({ sessionAction: 'info', randomVal: Math.random().toString() }));
    localStorage.removeItem('session');

    const isUserDataSet = await this.store.select(SessionState.isUserDataSet).pipe(
      filter(value => value),
      timeoutWith(this._multiTabsGetSessionTimeout, of(false)),
      take(1)
    ).toPromise();

    if (!isUserDataSet) {
      this.multiTabsManagerService.reset();
      this.reloadWindow();
    }
    return isUserDataSet;
  }

  private reloadWindow(): void {
    window.location.reload();
  }
}

