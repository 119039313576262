import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { MenuService } from '../../core/services/menu.service';

export const MenuResolver: ResolveFn<void> =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void => {

    const menuService = inject(MenuService);

    menuService.init();
  };
