import { TableRow } from '@fgpp-ui/grid';
import { PaginatorData } from '@fgpp-ui/grid';

//TODO: remove service after implementing pagination on server
export class PaginationService {

  static getPageRows(rows: Array<TableRow>, paginatorData: PaginatorData): Array<TableRow> {
    const pageIndex = paginatorData.pageNumber - 1;
    const pageSize = paginatorData.pageSize;

    const start = pageIndex * pageSize;
    const end = start + pageSize;

    return rows.slice(start, end);
  }

}
