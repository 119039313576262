import {inject, Injectable} from "@angular/core";
import {flatten, unflatten} from 'flat';
import {BFEvents, NotificationService} from "@fgpp-ui/components";
import {EMessageButtons} from "../enums/message-buttons.enum";
import {IEndpoint} from "../../core/interfaces/endpoint.interface";
import {TranslateService} from "@ngx-translate/core";
import {FormGroup} from "@angular/forms";
import {HttpErrorResponse, HttpHeaders} from "@angular/common/http";
import {v4 as uuid} from 'uuid';

@Injectable()
export class BfMessageActionsService {

  private _paymentActionSuccessMessage = new Map<EMessageButtons, { key: string, params?: Object }>();
  private _paymentActionErrorMessage = new Map<EMessageButtons, { key: string, params?: Object }>();
  private notificationService = inject(NotificationService);
  private translate = inject(TranslateService);

  constructor() {
    this.initializeSuccessNotificationMessages();
    this.initializeErrorNotificationMessages();
  }

  excludeDummyFields(data: any): any {
    const DUMMY_KEYS = [/ID_TYPE_DYN/];
    const flatFormData = flatten(data, {safe: true});
    Object.keys(flatFormData).forEach(key => {
      if (DUMMY_KEYS.some(rx => rx.test(key))) {
        delete flatFormData[key];
      }
    });
    return unflatten(flatFormData);
  }

  checkFormClosureCondition(payload: BFEvents.ButtonEvent, response: any): boolean {
    return payload.target !== EMessageButtons.SAVE && response.status !== 'APPROVE_REG';
  }

  handleSuccessResponse(actionEndpoint: IEndpoint, buttonId: EMessageButtons, response: any, form: FormGroup) {
    actionEndpoint.idempotencyKey = null;
    this.notificationService.success(
      this.translate.instant(this._paymentActionSuccessMessage.get(buttonId).key, {
        mid: response.mid,
        status: response.status
      }));
    form.markAsPristine();
  }

  handleErrorResponse(actionEndpoint: IEndpoint, res: HttpErrorResponse, buttonId: EMessageButtons) {
    if (actionEndpoint.idempotencyKey && !(res.status >= 500 && res.status < 599)) {
      actionEndpoint.idempotencyKey = uuid();
    }
    this.notificationService.error(this.translate.instant(this._paymentActionErrorMessage.get(buttonId).key));
  }

  setRequestHeaders(actionEndpoint: IEndpoint, eTag: string): HttpHeaders {
    let headers = new HttpHeaders().set('X-Request-ID', uuid());
    if (['POST', 'PUT'].includes(actionEndpoint.method) && !actionEndpoint.idempotencyKey) {
      actionEndpoint.idempotencyKey = uuid();
      headers = headers.set('Idempotency-Key', actionEndpoint.idempotencyKey);
    }
    headers = headers.set('If-Match', eTag);
    return headers;
  }

  private initializeSuccessNotificationMessages(): void {
    const key = 'business-framework.messages.action';
    this._paymentActionSuccessMessage.set(EMessageButtons.SUBMIT, {key: `${key}.submit-success`});
    this._paymentActionSuccessMessage.set(EMessageButtons.INITIATE, {key: `${key}.submit-success`});
    this._paymentActionSuccessMessage.set(EMessageButtons.SAVE, {key: `${key}.save-success`});
    this._paymentActionSuccessMessage.set(EMessageButtons.VERIFY, {key: `${key}.verify-success`});
    this._paymentActionSuccessMessage.set(EMessageButtons.CANCEL, {key: `${key}.cancel-success`});
    this._paymentActionSuccessMessage.set(EMessageButtons.RELEASE, {key: `${key}.release-success`});
    this._paymentActionSuccessMessage.set(EMessageButtons.SEND_TO_REPAIR, {key: `${key}.send-to-repair-success`});
  }

  private initializeErrorNotificationMessages(): void {
    const key = 'business-framework.messages.errors';
    this._paymentActionErrorMessage.set(EMessageButtons.SUBMIT, {key: `${key}.submit-failed`});
    this._paymentActionErrorMessage.set(EMessageButtons.INITIATE, {key: `${key}.submit-failed`});
    this._paymentActionErrorMessage.set(EMessageButtons.SAVE, {key: `${key}.save-failed`});
    this._paymentActionErrorMessage.set(EMessageButtons.VERIFY, {key: `${key}.verify-failed`});
    this._paymentActionErrorMessage.set(EMessageButtons.RELEASE, {key: `${key}.release-failed`});
    this._paymentActionErrorMessage.set(EMessageButtons.CANCEL, {key: `${key}.cancel-failed`});
    this._paymentActionErrorMessage.set(EMessageButtons.SEND_TO_REPAIR, {key: `${key}.send-to-repair-failed`});
  }
}
