import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanDeactivate,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import {Observable} from 'rxjs';
import {RulesAssociationComponent} from "../../rules-associations/single-view/components/rules-association.component";
import {ProfilesMode} from "../../../profiles/routing/models/enums/profiles-mode.enum";

@Injectable({
  providedIn: 'root'
})
export class DeactivateRulesAssociationProfileGuard implements CanDeactivate<RulesAssociationComponent> {

  constructor() {}

  canDeactivate(component: RulesAssociationComponent, currentRoute: ActivatedRouteSnapshot,
                currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): Observable<boolean> | boolean {
    if(component['profilesMode'] === ProfilesMode.SINGULAR_RULES_ASSOCIATION_PROFILE_VIEW) {
      const ruleAssociationComponent = component['rulesAssociationComponent'];

      if (!ruleAssociationComponent.rulesAssociationCommonService.isNeedCheckBeforeClose) {
        return true;
      }
      ruleAssociationComponent.rulesAssociationCommonService.isNeedCheckBeforeClose = false;
      return ruleAssociationComponent.rulesAssociationCommonService.checkBeforeClose(nextState);
    } else {
      return true;
    }
  }
}
