<div class="dh-main-external-container">

  <app-spinner *ngIf="isLoading"></app-spinner>

  <div *ngIf="hasTaskBar" class="dh-main-left-container-wrapper"
       [hidden]="isStandAlone" [ngClass]="{'minimized': !showSideBar}">

    <app-task-bar-layout></app-task-bar-layout>
  </div>


  <div class="dh-main-right-container" [ngClass]="{'expand' : !showSideBar, 'isStandAlone': isStandAlone}">
    <app-navigation-result></app-navigation-result>
    <div class="dh-main-right-content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
