import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FtDatePipe } from '../../pipes/ftDate.pipe';
import { Breadcrumb } from '../models/breadcrumb.model';
import {Entity} from "../../shared/models/enums/entity.enum";

@Injectable()
export class BreadcrumbsService {

  private linkStack = new Array<Breadcrumb>();
  public toUpdate = true;

  constructor(private translateService: TranslateService, private ftDate: FtDatePipe) { }

  getBreadCrumbStack(): Array<Breadcrumb> {
    return this.linkStack;
  }

  setBreadcrumbs(actionTypeObj: any, translateStr: string, status: string): void {
    if (!this.toUpdate || !actionTypeObj) {
      return;
    }
    const type = 'messages_center.' + actionTypeObj.type;
    const date = actionTypeObj.searchDate ? actionTypeObj.searchDate : undefined;
    const alias = this.translateService.instant(translateStr, {
      type: this.translateService.instant(type),
      date: this.ftDate.transform(date),
      condition: this.translateService.instant(status || ' ')
    });
    const firstBreadcrumb: Breadcrumb = { link: actionTypeObj.routerLink, alias: alias, params: { entityType: actionTypeObj.type, ...actionTypeObj.stateParams } };
    this.reset();
    this.push(firstBreadcrumb);
  }

  pushToBreadcrumbLinkedFile() {
    const lastBreadcrumb = this.head();
    if(lastBreadcrumb) {
      const lastBreadcrumbAlias = lastBreadcrumb.alias.split(' ');
      const penultimateBreadcrumbAlias = this.penultimate() ? this.penultimate().alias.split(' ') : [];
      if (lastBreadcrumbAlias[0] == penultimateBreadcrumbAlias[0]) {
        for (var i = 0; i < 2; i++) {
          this.pop();
        }
        this.push(lastBreadcrumb);
      }
    }
  }

  pushOnEnter(linkTo: string, params: Object, entityIdKey: string): void {
    var alias = this.translateService.instant('breadcrumbs.' + (entityIdKey.substring(0, entityIdKey.length - 2)),
      {[entityIdKey]: params[entityIdKey]});
    var breadcrumb = { link: linkTo, params: params, alias: alias };
    if (!this.isEmpty()) {
      if (this.head().link === breadcrumb.link) {
        this.pop();
      }
      if (breadcrumb.alias != this.head()?.alias) {
        this.push(breadcrumb);
      }
    } else {
      this.push(breadcrumb);
    }
  }

  pushBreadcrumbRecentSearches(link: string, entity: Entity, condition: string) {
    const type = this.translateService.instant('messages_center.' + entity);
    const date = new Date();
    const alias = this.translateService.instant('breadcrumbs.RECENT_SEARCH', { type: type, date: this.ftDate.transform(date), condition: condition });
    const firstBreadcrumb = { link: link, alias: alias };
    this.push(firstBreadcrumb);
  }

  push(value: Breadcrumb): void {
    this.linkStack.push(value);
  }

  pop(): Breadcrumb {
    return this.linkStack.pop();
  }

  reset(): void {
    this.linkStack.length = 0;
  }

  head(): Breadcrumb {
    return this.linkStack[this.linkStack.length - 1];
  }

  penultimate(): Breadcrumb {
    return this.linkStack[this.linkStack.length - 2];
  }

  isEmpty(): boolean {
    return this.linkStack.length === 0;
  }

}
