export const RecordLockedLayout = {
    properties: {
      controls: {
        'message': {
          controlType: 'label',
          title: '',
          colSpan: 12,
          position: 0,
          padding: '0',
          margin: '0'
        }
      }
    }
};
