export const NCC = {
  OFFICE: 'NCC-OFFICE',
  DEPARTMENT: 'NCC-DEPARTMENT',
  REC_STATUS: 'NCC-REC_STATUS',
  PROFILE_CHANGE_STATUS: 'NCC-PROFILE_CHANGE_STATUS',
  EFFECTIVE_DATE: 'NCC-EFFECTIVE_DATE',
  NCC_TYPE_ISO: 'NCC-NCC_TYPE_ISO',
  NCC_CODE: 'NCC-NCC_CODE',
  NCC_TYPE_SWIFT: 'NCC-NCC_TYPE_SWIFT',
  CUST_CODE: 'NCC-CUST_CODE',
  CUST_NAME: 'CUSTOMRS-CUST_NAME',
  SHORT_NAME: 'CUSTOMRS-SHORT_NAME',
  BANK_NAME: 'NCC-BANK_NAME',
  ADDRESS1: 'NCC-ADDRESS1',
  ADDRESS2: 'NCC-ADDRESS2',
  ADDRESS3: 'NCC-ADDRESS3',
  ADDRESS4: 'NCC-ADDRESS4',
  CITY: 'CUSTOMRS-CITY',
  COUNTRYCODE: 'CUSTOMRS-COUNTRYCODE',
  ZIP: 'CUSTOMRS-ZIP',
  MEMBER_ID_IND: 'NCC-MEMBER_ID_IND',
  DEFAULT_NCC: 'NCC-DEFAULT_NCC',
  TREASURY_NCC: 'NCC-TREASURY_NCC',
  INTERNAL_NCC: 'NCC-INTERNAL_NCC',
  OFFLINE_ABA: 'NCC-OFFLINE_ABA',
  PROPRIETARY: 'COUNTRY_NCC-PROPRIETARY',
  TIME_STAMP: 'TIME_STAMP',
  UID: 'VIRTUAL-UID',
  PROFILE_UPDATE_PK: 'PROFILE_UPDATE_PK',
  PU_TIME_STAMP: 'PU_TIME_STAMP',
  NOTE: 'NOTE',
  COUNTRY_NCC_CLRSYS_CODE: 'COUNTRY_NCC.CLRSYS_CODE',
  COUNTRY_NCC_TYPE: 'COUNTRY_NCC.NCC_TYPE',
  COUNTRY_NCC_PROPRIETARY: 'COUNTRY_NCC.PROPRIETARY'
};
