<div class="preview-rule-title">
  {{ 'rules.preview.title' | translate }}
  <button azure-id="copy-to-clipboard"
          class="info-btn clipboard-copy-button"
          mat-button
          color="primary"
          (click)="onCopyToClipboardClick()">
    {{ 'rules.preview.copy_to_clipboard' | translate }}
  </button>
  <button azure-id="close-preview-pane"
          mat-icon-button dense
          class="preview-btn"
          (click)="togglePreviewRulePane()">
    <app-fn-ui-icon icon="close"></app-fn-ui-icon>
  </button>
</div>
<div class="preview-rule-content-wrapper">
  <div class="preview-rule-content" [innerHTML]="conditionsAsHtml" #rulePreviewContent></div>
</div>
