import { Injectable } from '@angular/core';
import { IMapperService } from '../../../shared/interfaces/mapper-service.interface';
import { AlertResponse } from '../models/alert-response.model';
import { Alert } from '../models/alert.model';
import { CATEGORY } from '../models/consts/category.const';
import { EXTERNAL_SEVERITY } from '../models/consts/external-severity.const';
import { Severity } from '../../../shared/fn-ui-icons/fn-ui-severity-icon/enums/severity.enum';

@Injectable()
export class AlertMapperService implements IMapperService<AlertResponse, Alert> {

  map(alertResponse: AlertResponse): Alert {
    if (!alertResponse) {
      return null;
    }
    return {
      id: alertResponse.UID_ALERTS,
      name: alertResponse.ALERT_NAME,
      description: alertResponse.DESCRIPTION,
      type: CATEGORY[+alertResponse.CATEGORY],
      severity: alertResponse.EXTERNAL_SEVERITY ? EXTERNAL_SEVERITY[+alertResponse.EXTERNAL_SEVERITY] : Severity.CRITICAL,
      status: alertResponse.STATUS === 'true',
      action: alertResponse.ACTION,
      alias: alertResponse.ALIAS,
      tooltip: alertResponse.TOOL_TIP,
      payByApproaching: alertResponse.PAY_BY_APPROACHING === 'true',
      accountUid: alertResponse.ACCOUNT_UID,
      accountOffice: alertResponse.ACCOUNT_OFFICE,
      positionFigure: alertResponse.POSITION_FIGURE,
      positionFigureValue: alertResponse.POSITION_FIGURE_VALUE,
      permittedQueue: alertResponse.PERMITTED_QUEUE === 'true',
      caption: alertResponse.NAME
    };
  }

  mapAll(alerts: Array<AlertResponse>): Array<Alert> {
    if (!alerts) {
      return null;
    }
    return alerts.map((alertResponse: AlertResponse) => this.map(alertResponse));
  }

}
