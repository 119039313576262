export const DNEStop = {
  'DEPARTMENT': 'STOP_PROFILE-DEPARTMENT',
  'OFFICE': 'STOP_PROFILE-OFFICE',
  'REC_STATUS': 'STOP_PROFILE-REC_STATUS',
  'PROFILE_CHANGE_STATUS': 'STOP_PROFILE-PROFILE_CHANGE_STATUS',
  'PENDING_ACTION': 'STOP_PROFILE-PENDING_ACTION',
  'EFFECTIVE_DATE': 'EFFECTIVE_DATE',
  'END_TO_END_ID': 'STOP_PROFILE-END_TO_END_ID',
  'DEST_ACCT_ID': 'STOP_PROFILE-DEST_ACCT_ID',
  'DATE_OF_DEATH': 'STOP_PROFILE-DATE_OF_DEATH',
  'LAST_HIT': 'STOP_PROFILE-LAST_HIT',
  'TIME_STAMP': 'TIME_STAMP',
  'UID': 'VIRTUAL-UID',
  'PROFILE_UPDATE_PK': 'PROFILE_UPDATE_PK',
  'PU_TIME_STAMP': 'PU_TIME_STAMP',
  'NOTE': 'NOTE'
};
