<div class="audit-trail">
  <div class="audit-trail-title">
    <button
      mat-icon-button dense
      azure-id="close-audit-trails"
      (click)="closeAuditTrails()"
      title="{{ 'rules.audit_trail.collapse_tooltip' | translate }}"
    >
      <app-fn-ui-icon icon="close"></app-fn-ui-icon>
    </button>
    {{ 'system_navigation.entity.audit_trail' | translate }}
  </div>
  <div class="audit-trail-content-wrapper">
    <div class="full">
      <app-audit-trail-rules-container
        *ngIf="!selectedRow; else auditTrailDetails"
        [ruleProfileId]="'416'"
        [ruleId]="ruleId"
        (rowClicked)="onRowClick($event)"
      ></app-audit-trail-rules-container>

      <ng-template #auditTrailDetails>
        <app-audit-trail-details
          [uid]="selectedRow['VIRTUAL.UID']"
          [userName]="selectedRow['PROFILE_UPDATE-INITIATOR']"
          [timestamp]="selectedRow['PROFILE_UPDATE.TIME_STAMP']"
          (backClicked)="onBackClick()"
        ></app-audit-trail-details>
      </ng-template>
    </div>
  </div>
</div>
