<h1 mat-dialog-title>
  <div class="title-wrapper">
    <div class="title" translate>
        {{'business-framework.profiles.common.audit.title' | translate}}
    </div>
    <button mat-icon-button matSuffix (click)="close()" class="close-btn">
      <app-fn-ui-icon icon="close"></app-fn-ui-icon>
    </button>
  </div>
</h1>
<div mat-dialog-content>
  <div class="body form-content">
    <ng-container *ngIf="isGridView">
      <gpp-ui-legacy-table
        [endpoint]="endpoint"
        [rowClickable]="true"
        (rowClicked)="rowClick($event)"
        [rowSelection]="false">
      </gpp-ui-legacy-table>
    </ng-container>
    <ng-container *ngIf="!isGridView">
      <div class="audit-header" *ngIf="changeUserId">
        <span>
          {{'business-framework.profiles.common.audit.changeInfo' | translate : {changeUserId: changeUserId, changeDateTime: changeDateTime} }}
        </span>
      </div>
      <button mat-button class="return-to-grid" color="primary" (click)="returnToGrid()"><app-fn-ui-icon icon="arrow_left"></app-fn-ui-icon><span>{{'business-framework.profiles.common.audit.backBtn' | translate}}</span></button>
      <gpp-ui-legacy-table
        [endpoint]="detailEndpoint"
        [rowClickable]="false"
        [rowSelection]="false"
        (totalRows)="onProfileAuditDetailsLoad()">
      </gpp-ui-legacy-table>
    </ng-container>
  </div>
</div>


