export enum ErrorCodeMapping {
  DEPARTMENT = 'ERROR_MAPPING-DEPARTMENT',
  OFFICE = 'ERROR_MAPPING-OFFICE',
  REC_STATUS = 'ERROR_MAPPING-REC_STATUS',
  PROFILE_CHANGE_STATUS = 'ERROR_MAPPING-PROFILE_CHANGE_STATUS',
  PENDING_ACTION = 'ERROR_MAPPING-PENDING_ACTION',
  EFFECTIVE_DATE = 'EFFECTIVE_DATE',
  FROM_CHANNEL_TYPE = 'ERROR_MAPPING-FROM_CHANNEL_TYPE',
  FROM_CHANNEL = 'ERROR_MAPPING-FROM_CHANNEL',
  V_FROM_GPP = 'ERROR_MAPPING-V_FROM_GPP',
  V_FROM_HOST_SELECT = 'ERROR_MAPPING-V_FROM_HOST_SELECT',
  V_FROM_MOP_SELECT = 'ERROR_MAPPING-V_FROM_MOP_SELECT',
  FROM_CODE = 'ERROR_MAPPING-FROM_CODE',
  V_FROM_GPP_CODE_SELECT = 'ERROR_MAPPING-V_FROM_GPP_CODE_SELECT',
  V_FROM_MOP_CODE_SELECT = 'ERROR_MAPPING-V_FROM_MOP_CODE_SELECT',
  TO_CHANNEL_TYPE = 'ERROR_MAPPING-TO_CHANNEL_TYPE',
  TO_CHANNEL = 'ERROR_MAPPING-TO_CHANNEL',
  V_TO_GPP = 'ERROR_MAPPING-V_TO_GPP',
  V_TO_HOST_SELECT = 'ERROR_MAPPING-V_TO_HOST_SELECT',
  V_TO_MOP_SELECT = 'ERROR_MAPPING-V_TO_MOP_SELECT',
  TO_CODE = 'ERROR_MAPPING-TO_CODE',
  V_TO_GPP_CODE_SELECT = 'ERROR_MAPPING-V_TO_GPP_CODE_SELECT',
  V_TO_MOP_CODE_SELECT = 'ERROR_MAPPING-V_TO_MOP_CODE_SELECT',
  MSG_TYPE = 'ERROR_MAPPING-MSG_TYPE',
  SUPPORT_PROPRIETARY_CODES = 'ERROR_MAPPING-SUPPORT_PROPRIETARY_CODES',
  DESCRIPTION = 'ERROR_MAPPING-DESCRIPTION'
};
