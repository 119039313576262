import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationService } from '@fgpp-ui/components';
import { TranslateService } from '@ngx-translate/core';
import { ProfileActionErrorCode } from '../../profiles/enums/profile-action-error-code.enum';
import { IProfileActionErrorHandlerService } from '../../profiles/services/intrefaces/profile-action-error-handler-service.interface';
import { RulesRelatedProfileHandlerService } from './rules-related-profile-handler.service';

@Injectable()
export class ProfileActionErrorHandlerService implements IProfileActionErrorHandlerService {

  constructor(private notificationService: NotificationService,
              private translateService: TranslateService,
              private rulesRelatedProfileHandlerService: RulesRelatedProfileHandlerService) { }

  onServerError(response: HttpErrorResponse): void {
    const errorCode = response.error.title || 28560;
    const errorMessage = response.error.detail;
    switch (errorCode) {
      case ProfileActionErrorCode.RULES_RELATED_PROFILE_DELETE:
      case ProfileActionErrorCode.RULES_RELATED_PROFILE_HOLD:
        this.rulesRelatedProfileHandlerService.onActionError(errorCode, errorMessage);
        break;
      default:
        this.notificationService.error(this.getErrorMessage(errorCode, errorMessage));
        break;
    }
  }

  private getErrorMessage(errorCode: string, errorMessage: string): string {
    const translationKey = `business-framework.profiles.errors.${errorCode}`;
    const translation = this.translateService.instant(translationKey, { 'fields': errorMessage });
    if (translation !== translationKey) {
      return translation;
    } else {
      return `${errorMessage || errorCode}`;
    }
  }

}
