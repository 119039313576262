import { Action, State, Selector, StateContext } from '@ngxs/store';
import { AlertStateModel } from './models/alert-state.model';
import { Alert } from '../../models/alert.model';
import { AddAlerts } from './actions/add-alerts.action';
import { UpdateNewAlerts } from './actions/update-new-alert.action';
import { Severity } from '../../../../shared/fn-ui-icons/fn-ui-severity-icon/enums/severity.enum';
import { Injectable } from '@angular/core';

@State<AlertStateModel>({
  name: 'alerts',
  defaults: {
    allAlerts: [],
    newAlerts: [],
    firstTime: true
  }
})
@Injectable()
export class AlertState {

  @Selector()
  static getAlerts(state: AlertStateModel): Array<Alert> {
    return state.allAlerts;
  }

  @Selector()
  static getNewAlerts(state: AlertStateModel): Array<Alert> {
    return state.newAlerts;
  }

  @Action(AddAlerts)
  addAlerts(ctx: StateContext<AlertStateModel>, { payload }: AddAlerts) {
    payload = payload || [];
    ctx.dispatch(new UpdateNewAlerts(payload))
      .toPromise().then(() => {
        const state = ctx.getState();
        const allAlerts = this.getAllAlerts(state, payload);
        ctx.patchState({
          allAlerts: allAlerts,
          firstTime: false
        });
      });
  }

  @Action(UpdateNewAlerts)
  updateNewAlerts(ctx: StateContext<AlertStateModel>, { payload }: UpdateNewAlerts) {
    const state = ctx.getState();
    if (!state.firstTime) {
      const newAalerts = this.getNewAlerts(state, payload);
      ctx.patchState({
        newAlerts: newAalerts
      });
    }
  }

  private getAllAlerts(state: AlertStateModel, payload: Array<Alert>): Array<Alert> {
    let allAlerts;
    if (state.firstTime) {
      allAlerts = payload;
    } else {
      allAlerts = state.allAlerts.filter((alert: Alert) => this.isExist(alert, payload));
      allAlerts.push(...state.newAlerts);
    }
    return this.sortBySeverity(allAlerts);
  }

  private getNewAlerts(state: AlertStateModel, payload: Array<Alert>): Array<Alert> {
    const newAlerts = payload.filter((alert: Alert) => this.isNew(alert, state.allAlerts));
    return this.sortBySeverity(newAlerts);
  }

  private isNew(alert: Alert, alerts: Array<Alert>): boolean {
    return alerts.every((item: Alert) => item.id !== alert.id);
  }

  private isExist(alert: Alert, payload: Array<Alert>): boolean {
    return payload.some((item: Alert) => item.id === alert.id);
  }

  private sortBySeverity(alerts: Array<Alert>): Array<Alert> {
    const severities = this.getSeverities();
    return alerts.sort((alert1: Alert, alert2: Alert) => {
      const index1 = severities.indexOf(alert1.severity);
      const index2 = severities.indexOf(alert2.severity);
      return index1 - index2;
    });
  }

  private getSeverities(): Array<string> {
    return Object.keys(Severity).map((key: string) => Severity[key]);
  }

}
