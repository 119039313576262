import * as X2JS from 'x2js';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SmartSearchPruleConditions } from '../shared/search/models/smart-search-prule-conditions.model';
import { IXmlJsonParserInterface } from '../shared/interfaces/xml-json-parser.interface';
import { FormatsService } from '../shared/services/formats.service';
import { AdvancedSearchService } from '../messages/services/advanced-search.service';

const AND_OR_KEY_PREFIX = 'advanced_search.options.';
const OPERATOR_KEY_PREFIX = 'operators.';
const OPERATOR_REGEX = /[A-Za-z]/;
const SPACE = ' ';
const UNDERSCORE = '_';

export const DEFAULT_XML = '<>';

@Injectable()
export class XmlJsonParserService implements IXmlJsonParserInterface {

  private readonly numericOperators = ['>', '<', '=', '>=', '<=', '<>', '+', '-', '+', '*', '/'];
  private x2js: X2JS;

  constructor(private formatsService: FormatsService, private translateService: TranslateService,
              private advancedSearch: AdvancedSearchService) {
    this.x2js = new X2JS();
  }

  fromXmlToJsObject(xmlObject: string): SmartSearchPruleConditions {
    return this.x2js.xml2js<SmartSearchPruleConditions>(xmlObject);
  }

  fromJsToJson(jsObject: Object): string {
    return this.x2js.js2xml<Object>(jsObject);
  }

  prepareSearchStringFromXml(xmlObject: string): string {
    if (!xmlObject) {
      return null;
    }
    const jsObject: SmartSearchPruleConditions = this.fromXmlToJsObject(xmlObject);
    return this.parseJsObject(jsObject);
  }

  parseJsObject(obj: SmartSearchPruleConditions): string {
    let result = '';
    if (!obj.pruleConditions) {
      return '';
    }
    if (Array.isArray(obj.pruleConditions.cond)) {
      obj.pruleConditions.cond.forEach(cond => {
        if (!!cond.andOr) {
          const andOr = this.translateService.instant(AND_OR_KEY_PREFIX + cond.andOr);
          result = result.concat(` ${andOr} `);
        }
        result = result.concat(this.parseCondition(cond));

      });
    } else {
      result = result.concat(this.parseCondition(obj.pruleConditions.cond));
    }
    return result;
  }

  parseCondition(cond) {
    if (!cond || cond['leftVal'] === undefined) {
       return '';
    }
    const leftVal = this.getLeftVal(cond);
    const op = this.getOperator(cond);
    const rightVal = this.getRightVal(cond);
    return `${leftVal} ${op} ${rightVal}`;
  }

  private getLeftVal(cond): string {
    return !!cond.leftVal && !!cond.leftVal._value ? cond.leftVal._value : '';
  }

  private getOperator(cond): string {
    const op = !!cond.op ? cond.op : '';
    const isLiterally = OPERATOR_REGEX.test(op);
    if (!isLiterally) {
      return op;
    }
    return this.translateService.instant(OPERATOR_KEY_PREFIX + op.toUpperCase().split(SPACE).join(UNDERSCORE));
  }

  private getRightVal(cond: any): string {
    const rightVal = !!cond.rightVal && !!cond.rightVal._value ? cond.rightVal._value : '';
    const isNumericOperator = this.numericOperators.indexOf(cond.op) !== -1;
    if (this.advancedSearch.fieldsWithNumberType.indexOf(cond.leftVal?._value) !== -1 && isNumericOperator) {
      return this.formatsService.toNumberFormat(rightVal);
    }
    return rightVal;
  }
}
