<div ng2FileDrop [ngClass]="{'nv-file-over': hasBaseDropZoneOver}" (fileOver)="fileOverBase($event)"
    [uploader]="uploaderInput"  class="well my-drop-zone" (onFileDrop)="fileDropped($event)">
        <div class="text-box">
            <p class="primary-text" [ngClass]="{'read-only': disabled}">{{ texts.uploadInstructions }}
                 <span class="file-browser" (click)="inputFile.click(); $event.stopPropagation()" >{{ texts.browse }}</span>
                </p>
            <p class="secodry-text"  [ngClass]="{'read-only': disabled}">{{ texts.fileSizeInstructions }}<br>{{ texts.fileTypesInstructions }}</p>
            <p class="file-name-view" *ngIf="uploaderInput.queue[0]">{{ uploaderInput.queue[0].file.name }}</p>

            <input azure-id="browse" class="select-file-input" [disabled]="disabled" type="file" #inputFile ng2FileSelect [uploader]="uploaderInput"
            (onFileSelected)="fileDropped($event)" />
        </div>
</div>
