import { Component } from '@angular/core';
import { MultiLineComponent } from '@fgpp-ui/components';
import { AccountGroups } from '../../models/account-groups.model';
import { ProfileFormTemplateProviders } from '../../../core/templates/profile-form.template.providers';
import {AbstractProfileFormComponent} from "../../abstract/abstract-profile/abstract-profile-form.component";
import {BFOnFormLoadComplete, BFProfileOnSave} from "../../../core/interfaces/sdk-profile-hooks.interface";
import {AccountsMultiLineFormComponent} from "./modals/accounts-mutli-line-form.component";

@Component({
  selector: 'app-account-groups',
  templateUrl: '../../../core/templates/profile-form.template.html',
  styleUrls: ['./account-groups.component.scss'],
  providers: ProfileFormTemplateProviders
})
export class AccountGroupsComponent extends AbstractProfileFormComponent implements BFOnFormLoadComplete, BFProfileOnSave {

  fgppFormLoadComplete(): void {
    this.formMgr.get('DETAILS').getLayoutControl<MultiLineComponent>().setModalComponent(AccountsMultiLineFormComponent);
    this.subscribeToRowClick();
    this.loadAccounts();
  }

  fgppOnSave(): boolean {
    this.setSelectedValues();
    return true;
  }

  private subscribeToRowClick() {
    const component = this.formMgr.get('DETAILS').getLayoutControl<MultiLineComponent>();
    const multiLineSub = component.tableRowClick.subscribe((response) => {
      this.profileService.openProfiles([{ 'VIRTUAL.UID': response.data[AccountGroups.ACC_VIRTUAL_UID] }], [], '28');
    });
    this.subscription.add(multiLineSub);
  }

  private loadAccounts() {
    const url = `/ui/profiles/${this.profileId}/selectListData?dataKey=ACCOUNT_GROUPS.SELECTED_VALUES&selectListFilter=ACCOUNT_GROUPS.UID_ACCOUNT_GROUPS@V@${this.uid}`;
    this.dataService.get(url).subscribe((response: any) => {
      const accounts = [];
      response?.rows?.forEach((row) => {
        accounts.push(this.newAccount(row));
      });
      this.formMgr.get('DETAILS').setValue(accounts);
    });
  }

  private newAccount(row) {
    const account = {};
    account[AccountGroups.ACC_ACCOUNTNAME] = row['ACCOUNTS.ACCOUNTNAME'];
    account[AccountGroups.ACC_CURRENCY] = row['ACCOUNTS.CURRENCY'];
    account[AccountGroups.ACC_CUST_CODE] = row['ACCOUNTS.CUST_CODE'];
    account[AccountGroups.ACC_NO] = row['ACCOUNTS.ACC_NO'];
    account[AccountGroups.ACC_TYPE] = row['ACCOUNTS.ACC_TYPE'];
    account[AccountGroups.ACC_VIRTUAL_UID] = row['VIRTUAL.UID'];
    return account;
  }

  private setSelectedValues() {
    const details = this.formMgr.get('DETAILS').getValue();
    const selectedValues = details?.map((item) => item[AccountGroups.ACC_VIRTUAL_UID]).join(',');
    this.formMgr.get(AccountGroups.SELECTED_VALUES).setValue(selectedValues);
  }
}
