import { Action, State, Selector, StateContext } from '@ngxs/store';
import { FullRoute } from '../models/full-route.model';
import { SetLiquidity } from './actions/liquidity.actions';
import { LiquidityStateModel } from './models/liquidity-state.model';
import { Injectable } from '@angular/core';
import { LiquidityTaskBarItem } from '../../liquidity/models';


export const LIQUIDITY_DEFAULT_STATE = {
  path: `/home/liquidity/${LiquidityTaskBarItem.FAVORITES}`,
  queryParams: {
    account: null,
    parent: null
  }
};

@State<LiquidityStateModel>({
  name: 'liquidity',
  defaults: {
    liquidity: LIQUIDITY_DEFAULT_STATE
  }
})
@Injectable()
export class LiquidityState {

  @Selector()
  static getLiquidityState(state: LiquidityStateModel): FullRoute {
    return state.liquidity;
  }

  @Action(SetLiquidity)
  SetLiquidityState(ctx: StateContext<LiquidityStateModel>, { payload }: SetLiquidity) {
    ctx.patchState({
      liquidity: payload
    });
  }
}
