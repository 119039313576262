<form class="dh-signin-form" [formGroup]="form" (ngSubmit)="onFormSubmit()" autocomplete="off">
  <div class="dh-product-title">Payments To Go</div>
  <div>Using DIGIPASS app, scan the first CRONTO image</div>
  <img src="do/security/cronto-{{message}}.png" class="cronto"
       alt="First CRONTO image"/>
  <mat-form-field [floatLabel]="'always'" class="signature field">
    <input matInput
           placeholder="Device Code"
           id="signin-cronto-field"
           type="text"
           autocomplete="off"
           formControlName="signature" />
    <mat-hint>Enter the Device Code generated by your device</mat-hint>
  </mat-form-field>
  <button mat-raised-button
          id="signin-cronto-validate-button"
          class="login-button"
          color="primary"
          type="submit">
    <span class="dh-text-label">Continue</span>
  </button>
  <button mat-raised-button
          (click)="onBackClick()"
          id="back-button"
          class="back-button"
          color="primary"
          type="button">
    <span class="dh-text-label">Back</span>
  </button>
</form>
