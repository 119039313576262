import { Component, Injector } from '@angular/core';
import { IValueChange } from '../../../core/interfaces/form-value-change.interface';
import { BFBeforeProfileCreate, BFBeforeProfileEdit, BFOnFormLoadComplete, BFOnValueChange } from '../../../core/interfaces/sdk-profile-hooks.interface';
import { CountryPreferencesProfileProviders } from '../../../core/templates/profile-form.template.providers';
import { AbstractProfileFormComponent } from '../../abstract/abstract-profile/abstract-profile-form.component';
import { CountryPreferences } from '../../models/country-preferences.model';
import { StopFlags } from '../../models/stop-flags.model';
import { IBANService } from '../country-info/services/iban.service';
import { StopFlagDirection } from './enums/stop-flag-direction.enum';

@Component({
	selector: 'app-country-preferences',
	templateUrl: '../../../core/templates/profile-form.template.html',
	styleUrls: ['../../../core/templates/profile-form.template.scss'],
	providers: CountryPreferencesProfileProviders
})
export class CountryPreferencesComponent extends AbstractProfileFormComponent
	implements BFOnFormLoadComplete, BFOnValueChange, BFBeforeProfileCreate, BFBeforeProfileEdit {

	private stopFlagControlsId = {
		[StopFlagDirection.DEBIT]: {
			indicator: CountryPreferences.STOPFLAGFROM,
			reasonCode: CountryPreferences.REASONFROM,
			reasonDescription: CountryPreferences.DESCRIPTION_DEBIT
		},
		[StopFlagDirection.CREDIT]: {
			indicator: CountryPreferences.STOPFLAGTO,
			reasonCode: CountryPreferences.REASONTO,
			reasonDescription: CountryPreferences.DESCRIPTION_CREDIT
		}
	};

	constructor(injector: Injector, private ibanService: IBANService) {
		super(injector);
	}

	fgppFormLoadComplete(): void {
		this.ibanService.setIBANExample();
	}

	fgppBeforeCreate(): void {
		this.handleReasonControlsAvailability();
	}

	fgppBeforeEdit(): void {
		this.handleReasonControlsAvailability();
	}

	fgppValueChanges(change: IValueChange): void {
		switch (change.control) {
			case CountryPreferences.COUNTRYCODE:
				this.populoateCountryControls();
				return;
			case CountryPreferences.STOPFLAGFROM:
				this.handleReasonControlAvailability(StopFlagDirection.DEBIT);
				return;
			case CountryPreferences.STOPFLAGTO:
				this.handleReasonControlAvailability(StopFlagDirection.CREDIT);
				return;
			case CountryPreferences.REASONFROM:
				this.populateReasonDescriptionControl(StopFlagDirection.DEBIT);
				return;
			case CountryPreferences.REASONTO:
				this.populateReasonDescriptionControl(StopFlagDirection.CREDIT);
				return;
		}
	}

	private populoateCountryControls(): void {
		const countryControls = [
			CountryPreferences.CURRENCY,
			CountryPreferences.COUNTRYNAME,
			CountryPreferences.CALNAME,
			CountryPreferences.CHK_DIGIT_TYPE,
			CountryPreferences.IBAN_MANDATORY,
			CountryPreferences.IBAN_LEN,
			CountryPreferences.IBAN_BANK_ID_POS,
			CountryPreferences.IBAN_BRANCH_POS,
			CountryPreferences.IBAN_ACCOUNT_POS,
			CountryPreferences.IBAN_BANK_ID_LEN,
			CountryPreferences.IBAN_BRANCH_LEN,
			CountryPreferences.IBAN_ACCOUNT_LEN
		];
		const countryData = this.getDrilldownData(CountryPreferences.COUNTRYCODE);
		if (countryData) {
			countryControls.forEach((controlId: CountryPreferences) => {
				this.formMgr.get(controlId).setValue(countryData[controlId]);
			});
		} else {
			countryControls.forEach((controlId: CountryPreferences) => {
				this.formMgr.get(controlId).reset();
			});
		}
		this.ibanService.setIBANExample();
	}

	private handleReasonControlAvailability(direction: StopFlagDirection, reset = true): void {
		const indicatorControl = this.formMgr.get(this.stopFlagControlsId[direction].indicator);
		const reasonCodeControl = this.formMgr.get(this.stopFlagControlsId[direction].reasonCode);
		const descriptionControl = this.formMgr.get(this.stopFlagControlsId[direction].reasonDescription);
		if (indicatorControl.getValue() === '1') {
			reasonCodeControl.enable().markAsRequired();
		} else {
			reasonCodeControl.disable().markAsOptional();
			if (reset) {
				reasonCodeControl.setValue('');
				descriptionControl.setValue('');
			}
		}
	}

	private handleReasonControlsAvailability(): void {
		this.handleReasonControlAvailability(StopFlagDirection.DEBIT, false);
		this.handleReasonControlAvailability(StopFlagDirection.CREDIT, false);
	}

	private populateReasonDescriptionControl(direction: StopFlagDirection): void {
		const reasonCodeControlId = this.stopFlagControlsId[direction].reasonCode;
		const reasonDescriptionControlId = this.stopFlagControlsId[direction].reasonDescription;
		const stopFlagData = this.getDrilldownData(reasonCodeControlId);
		const reasonDescription = stopFlagData ? stopFlagData[StopFlags.DESCRIPTION.replace('-', '.')] : '';
		this.formMgr.get(reasonDescriptionControlId).setValue(reasonDescription);
	}

}
