import { Injectable, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, map, of } from 'rxjs';
import { RuleType } from '../../../../rules/models/rule-type.model';
import { RuleTypesApiService } from '../../../../rules/services/rule-types-api.service';
import { NavigationItem, MainTab } from '../../../navigation/models';
import { NavigationService } from '../../../navigation/services/navigation.service';
import { SITEMAP_TOKEN } from '../../config/search-resource-tokens.config';
import { NavigationResultGroup, NavigationResultItem } from '../../models';
import { ISearchResourceService } from './search-resource-service.intreface';
import { SitemapSearchService } from './sitemap-search.service';

@Injectable()
export class RuleTypesSearchService implements ISearchResourceService {

  get navigationItem(): NavigationItem {
    return this.navigationService.getTabNavigationItem(MainTab.RULES, '416');
  }

  constructor(@Inject(SITEMAP_TOKEN) private sitemapSearchService: SitemapSearchService,
              private ruleTypesApiService: RuleTypesApiService,
              private navigationService: NavigationService,
              private translateService: TranslateService) { }

  search(term: string): Observable<NavigationResultGroup> {
    if (!this.sitemapSearchService.isSearchable(MainTab.RULES)) {
      return of(null);
    }
    return this.ruleTypesApiService.get().pipe(map((ruleTypes: Array<RuleType>) => {
      ruleTypes = this.filter(ruleTypes, term);
      if (!ruleTypes.length) {
        return null;
      }
      const items = this.map(ruleTypes);
      const navigationTab = this.navigationService.navigationMap.get(MainTab.RULES);
      return {
        id: navigationTab.id,
        alias: this.translateService.instant(navigationTab.alias),
        items: items
      };
    }));
  }

  private filter(ruleTypes: Array<RuleType>, searchTerm: string): Array<RuleType> {
    const navigationItem = this.navigationItem;
    if (!navigationItem) {
      return [];
    }
    const isMatch = this.sitemapSearchService.isMatch(navigationItem, searchTerm);
    if (isMatch) {
      return ruleTypes;
    }
    return ruleTypes.filter(ruleType => this.isMatch(ruleType, searchTerm));
  }

  private map(ruleTypes: Array<RuleType>): Array<NavigationResultItem> {
    const navigationItem = this.navigationItem;
    return ruleTypes.map((ruleType) => this.createItem(ruleType, navigationItem));
  }

  private isMatch(ruleType: RuleType, searchTerm: string): boolean {
    return this.translateService.instant(ruleType.alias).trim().toLowerCase().includes(searchTerm.trim().toLowerCase());
  }

  private createItem(ruleType: RuleType, navigationItem: NavigationItem): NavigationResultItem {
    return {
      id: ruleType.id,
      alias: this.translateService.instant(ruleType.alias),
      icon: navigationItem.icon,
      path: navigationItem.path.map((part: string) => this.translateService.instant(part)),
      routerLink: navigationItem.routerLink.substring(0, navigationItem.routerLink.lastIndexOf('/')),
      stateName: 'home.rules.views.grid',
      stateParams: { ruleType: ruleType.id }
    };
  }

}
