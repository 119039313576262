import { HttpBackend, HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InterceptingHandler } from './intercepting.handler';

@Injectable()
export class InterceptingHandlerInjector {

  createInstance(backend: HttpBackend, interceptors: HttpInterceptor[]) {
    return new InterceptingHandler(backend, interceptors);
  }
}
