import { Component, OnInit } from '@angular/core';

import { ProfileFormTemplateProviders } from '../../../core/templates/profile-form.template.providers';
import {AbstractTaskFormComponent} from "../../abstract/abstract-task/abstract-task-form.component";

@Component({
  selector: 'app-generic-task',
  templateUrl: '../../../core/templates/profile-form.template.html',
  styleUrls: ['../../../core/templates/profile-form.template.scss'],
  providers: ProfileFormTemplateProviders
})
export class GenericTaskComponent extends AbstractTaskFormComponent implements OnInit {
}
