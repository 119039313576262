import { AfterContentChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { TaskBarService } from '../../../core/navigation/task-bar/services/task-bar.service';

@Component({
  selector: 'app-main-view',
  templateUrl: './main-view.component.html',
  styleUrls: ['./main-view.component.scss']
})
export class MainViewComponent implements OnInit, OnDestroy, AfterContentChecked {

  private _isStandAlone: boolean;
  private _isLoading: boolean;
  private _subscriber = new Subscription();

  readonly hasTaskBar: boolean;

  get showSideBar(): boolean {
    return this.taskBarService.taskBarState.showSideBar;
  }

  get isStandAlone(): boolean {
    return this._isStandAlone;
  }

  get isLoading(): boolean {
    return this._isLoading;
  }

  constructor(private changeDetectorRef: ChangeDetectorRef,
              private route: ActivatedRoute,
              private taskBarService: TaskBarService) {
    this.hasTaskBar = this.route.snapshot.data.taskBar.taskBar.items.length > 0;
    const sub = this.route.params.subscribe(data => {
      this._isStandAlone = data.isStandAlone === 'true';
    });
    this._isStandAlone = this.route.snapshot.queryParams?.isStandAlone === 'true'
    this._subscriber.add(sub);
 }

  ngOnInit(): void {
    const subscription = this.taskBarService.loading$.subscribe(value => {
      this._isLoading = value;
    });
    this._subscriber.add(subscription);
  }

  ngOnDestroy(): void {
    this._subscriber.unsubscribe();
  }

  ngAfterContentChecked(): void {
    this.changeDetectorRef.detectChanges();
  }
}
