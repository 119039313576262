import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAlertsApiService } from './interfaces/alerts-api-service.interface';
import { AlertsResponse } from '../models/alerts-response.model';
import { ALERTS_CONFIG } from '../models/consts/alerts-config.const';

@Injectable()
export class AlertApiService implements IAlertsApiService {

  constructor(protected http: HttpClient) { }

  getAlerts(): Promise<AlertsResponse> {
    return this.http.get<AlertsResponse>(ALERTS_CONFIG.url).toPromise();
  }
}
