import { Component, Input, EventEmitter, Output, OnInit, OnDestroy, ViewChildren, QueryList, AfterViewChecked } from '@angular/core';
import { MatListItem } from '@angular/material/list';
import { ActivatedRoute } from '@angular/router';
import { SideNavigationItem } from '../../models/side-navigation-item.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-side-navigation',
  templateUrl: './side-navigation.component.html',
  styleUrls: ['./side-navigation.component.scss']
})
export class SideNavigationComponent implements OnInit, AfterViewChecked, OnDestroy {

  @ViewChildren(MatListItem) listItems: QueryList<MatListItem>;

  private _items: SideNavigationItem[];
  private subscriber: Subscription;

  showList = true;
  selectedItem: SideNavigationItem;

  @Input() set items(items: SideNavigationItem[]) {
    items.sort((item1: SideNavigationItem, item2: SideNavigationItem) => item1.position - item2.position);
    this._items = items;
  }

  @Input() isOpen: boolean;
  @Output() itemClicked = new EventEmitter();

  get items() {
    return this._items;
  }

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.initSelectedItem();
  }

  initSelectedItem() {
    this.subscriber = this.route.params.subscribe(params => {
      const taskBarId = params.taskBarItem;
      this.selectedItem = this.items.find(item => item.id === taskBarId);
    });
  }

  onItemClick(item) {
    if (JSON.stringify(item) == JSON.stringify(this.selectedItem)) {
      this.toggleList();
    }
    this.selectedItem = item;
    this.itemClicked.emit();
  }

  toggleList() {
    this.showList = !this.showList;
  }

  trackByFunction(index: number, item: SideNavigationItem): string {
    return item.id || item.alias;
  }

  ngAfterViewChecked(): void {
    let i = 0;
    for (const itemList of this.listItems) {
      itemList._unscopedContent.nativeElement.setAttribute('azure-id', this.items[i++].alias);
    }
  }

  ngOnDestroy(): void {
    this.subscriber.unsubscribe();
  }
}
