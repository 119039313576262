import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GridSearchQuery } from '../../../grid/models/grid-search-query.model';
import { Entity } from '../../../shared/models/enums/entity.enum';
import { RecentSearchResponse } from '../models/recent-search-response.model';
import { IRecentSearchesApiService } from './interfaces/recent-searches-api-service.interface';

@Injectable()
export class RecentSearchesApiService implements IRecentSearchesApiService {

  constructor(private http: HttpClient) { }

  getAll(entity: Entity): Observable<Array<RecentSearchResponse>> {
    return this.http.get<Array<RecentSearchResponse>>(`do/${entity}/recentSearches`);
  }

  add(searchQuery: GridSearchQuery, entity: Entity): Observable<number> {
    return this.http.post<number>(`do/${entity}/recentSearches`, searchQuery);
  }

  delete(recentSearchId: number, entity: Entity): Observable<void> {
    return this.http.delete<void>(`do/${entity}/recentSearches/${recentSearchId}`);
  }

}
