import { Component, EventEmitter, OnInit, Output, Input, ElementRef, AfterViewInit } from '@angular/core';
import { Icon } from '../../models/icon.model';

@Component({
  selector: 'lib-download-icon',
  templateUrl: './download-icon.component.html',
  styleUrls: ['./download-icon.component.scss']
})
export class DownloadIconComponent implements OnInit, AfterViewInit {
  downloadIcon: Icon;
  readonly downloadIconTemplateRef = 'assets/icons/download.svg';
  @Input() isDisabled: boolean;
  @Output() onClicked = new EventEmitter<void>();

  constructor(private elem: ElementRef) { }

  ngOnInit() {
    this.downloadIcon = {
      iconTemplateRef: this.downloadIconTemplateRef,
      isDisabled: this.isDisabled,
      mainClass: null,
      iconClass: 'disabledDownloadIcon',
      tooltip: null,
      style: null
    };
  }

  onClick() {
    this.onClicked.emit();
  }

  ngAfterViewInit(): void {
    this.elem.nativeElement.setAttribute('azure-id', 'download-file-icon');
  }

}
