export enum SenderCreditLine {
	DEPARTMENT = 'POL_DEFINITION-DEPARTMENT',
	OFFICE = 'POL_DEFINITION-OFFICE',
	REC_STATUS = 'POL_DEFINITION-REC_STATUS',
	PROFILE_CHANGE_STATUS = 'POL_DEFINITION-PROFILE_CHANGE_STATUS',
	EFFECTIVE_DATE = 'EFFECTIVE_DATE',
	POL_CODE = 'POL_DEFINITION-POL_CODE',
	MATCH_LVL = 'POL_DEFINITION-MATCH_LVL',
	POL_DESCRIPTION = 'POL_DEFINITION-POL_DESCRIPTION',
	UNLIMITED = 'POL_DEFINITION-UNLIMITED',
	POL_AMOUNT = 'POL_DEFINITION-POL_AMOUNT',
	POL_CURRENCY = 'POL_DEFINITION-POL_CURRENCY',
	POL_ACTION = 'POL_DEFINITION-POL_ACTION',
	CURRENCY_LIST = 'POL_DEFINITION-CURRENCY_LIST',
	TIME_STAMP = 'TIME_STAMP',
	UID = 'VIRTUAL-UID',
	PROFILE_UPDATE_PK = 'PROFILE_UPDATE_PK',
	PU_TIME_STAMP = 'PU_TIME_STAMP',
	NOTE = 'NOTE'
};
