import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { FormatsService } from '../shared/services/formats.service';

// FRB stream dirty fix for rekeyVerification => blindVerification
//

export enum FieldVerifications {
  Sight = 'sightVerification',
  Click = 'clickVerification',
  Rekey = 'rekeyVerification',
  Blind = 'blindVerification'
}

@Injectable()
export class FieldVerificationsService {
  private renderer: Renderer2;
  public paymentError = { fieldLogicalId: '', error: {}, validations: { name: '', arguments: '' } };

  constructor(rendererFactory: RendererFactory2, private formatService: FormatsService) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  static sightVerificationInterval(elem) {
    const rect = elem.getBoundingClientRect();
    const html = document.documentElement;
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || html.clientHeight) &&
        rect.right <= (window.innerWidth || html.clientWidth)
    );
  }

  static clearSightVerificationInterval(sightVerificationInterval) {
    clearInterval(sightVerificationInterval);
  }

  sightVerification(elem) {
    return new Promise<void>((resolve, reject) => {
      const sightVerificationInterval = setInterval(() => {
        if (FieldVerificationsService.sightVerificationInterval(elem)) {
          FieldVerificationsService.clearSightVerificationInterval(sightVerificationInterval);
          resolve();
        }
      }, 1000);
    });
  }

  clickVerification(elem) {
    return new Promise<void>((resolve, reject) => {
      const listener = this.renderer.listen(elem, 'click', function() {
        listener();
        resolve();
      });
    });
  }

  rekeyVerification(elem, elemController) {
    return new Promise<void>((resolve, reject) => {
      let elemValue = elemController.value;
      elemController.patchValue('');
      const listener = this.renderer.listen(elem, 'click', () => {
        const blurListener = this.renderer.listen(elem, 'focusout', () => {
          let value = elemController.value;
          if (!isNaN(parseFloat(elemValue)) && !isNaN(parseFloat(value))) {
            elemValue = this.formatService.toNumber(elemValue);
            value = this.formatService.toNumber(value);
          }
          if (elemValue === value) {
            resolve();
            blurListener();
          }
        });
        listener();
      });
    });
  }

  blindVerification(elem, elemController) {
    return new Promise<void>((resolve, reject) => {
      let elemValue = elemController.value;
      if (typeof elemController.value === 'number' && elemController.value % 1 !== 0) {
        elemValue = elemController.value;
      }
      setTimeout(() => {
          elemController.patchValue('');
        }, 0);
      const blurListener = this.renderer.listen(elem, 'focusout', () => {
        const value = typeof(elemValue) === 'number' ? parseFloat(elemController.value) : elemController.value;
        if (elemValue == value) {
          resolve();
          blurListener();
        }
      });
    });
  }

}
