export const UserVacation = {
	'FIRST_NAME': 'USERS-FIRST_NAME',
	'ABSENCE_TYPE': 'USER_VACATION-ABSENCE_TYPE',
	'DEPARTMENT': 'USER_VACATION-DEPARTMENT',
	'EFFECTIVE_DATE': 'EFFECTIVE_DATE',
	'NOTE': 'NOTE',
	'OFFICE': 'USER_VACATION-OFFICE',
	'PENDING_ACTION': 'USER_VACATION-PENDING_ACTION',
	'PROFILE_CHANGE_STATUS': 'USER_VACATION-PROFILE_CHANGE_STATUS',
	'REC_STATUS': 'USER_VACATION-REC_STATUS',
	'USER_ID': 'USER_VACATION-USER_ID',
	'V_END_DATE': 'USER_VACATION-V_END_DATE',
	'V_START_DATE': 'USER_VACATION-V_START_DATE',
	'USER_NAME': 'USERS-USER_NAME',
	'TIME_STAMP': 'TIME_STAMP',
	'UID': 'VIRTUAL-UID',
	'PROFILE_UPDATE_PK': 'PROFILE_UPDATE_PK',
	'PU_TIME_STAMP': 'PU_TIME_STAMP'
};
