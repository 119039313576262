import { Injectable } from '@angular/core';
import { Idle } from '@ng-idle/core';
import { Subscription } from 'rxjs';
import { SignOutService } from '../../../authentication/services/sign-out.service';
import { IdleTimeoutWarningData } from '../models/idle-timeout-warning-data.model';
import { IdleConfigService } from './idle-config.service';
import { IdleModalsService } from './idle-modals.service';
import { IIdleService } from './interfaces/idle-service.interface';

@Injectable()
export class IdleService implements IIdleService {

  private _timeoutWarningData: IdleTimeoutWarningData;
  private _subscription = new Subscription();

  constructor(private idle: Idle,
              private idleConfigService: IdleConfigService,
              private idleModalsService: IdleModalsService,
              private signOutService: SignOutService) { }

  start(): void {
    if (this.idle.isRunning()) {
      return;
    }
    this.configure();
    this.startWatch();
    this.subscribeIdleEvents();
  }

  stop(): void {
    this.idle.stop();
    this.idle.clearInterrupts();
    this._subscription.unsubscribe();
  }

  resetIdleTimer(): void {
    this.startWatch();
    this.idle.onIdleEnd.next(null);
  }

  private configure(): void {
    const config = this.idleConfigService.getConfig();
    this.idle.setIdle(config.seconds);
    this.idle.setTimeout(config.timeout);
    this.idle.setInterrupts(config.interrupts);
  }

  private startWatch(): void {
    this.idle.stop();
    this.idle.watch();
  }

  private subscribeIdleEvents(): void {
    this._subscription.add(this.idle.onIdleStart.subscribe(this.onIdleStart.bind(this)));
    this._subscription.add(this.idle.onIdleEnd.subscribe(this.onIdleEnd.bind(this)));
    this._subscription.add(this.idle.onTimeoutWarning.subscribe(this.onTimeoutWarning.bind(this)));
    this._subscription.add(this.idle.onTimeout.subscribe(this.onTimeout.bind(this)));
  }

  private onIdleStart(): void {
    const timeout = this.idle.getTimeout();
    this._timeoutWarningData = new IdleTimeoutWarningData(timeout);
    this.idleModalsService.closeTimeoutWarningModal();
    this.idleModalsService.openTimeoutWarningModal(this._timeoutWarningData);
  }

  private onIdleEnd(): void {
    this.idleModalsService.closeTimeoutWarningModal();
  }

  private onTimeoutWarning(countdown: number): void {
    this._timeoutWarningData.countdown = countdown;
  }

  private onTimeout(): void {
    this.idleModalsService.closeTimeoutWarningModal();
    this.idleModalsService.openTimeoutModal().afterClosed().subscribe(() => {
      this.signOutService.signOut();
    });
    setTimeout(() => {
      this.idleModalsService.closeTimeoutModal();
    }, 3000);
  }

}
