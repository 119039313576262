<div class="alert-sidebar-list">

    <div *ngIf="alerts && alerts.length; then listTemplate; else noAlertsTemplate"></div>

    <ng-template #listTemplate>
        <app-fn-ui-scrollbar>
            <ng-container *ngFor="let alert of alerts">
                <app-alert-sidebar-item [alert]="alert"
                                        (alertSelect)="onSelectAlert(alert)"
                                        (mouseenter)="onMouseEnter(alert)"></app-alert-sidebar-item>
            </ng-container>
        </app-fn-ui-scrollbar>
    </ng-template>

    <ng-template #noAlertsTemplate>
        <app-alert-sidebar-empty></app-alert-sidebar-empty>
    </ng-template>

</div>
