<div class="landing-page">

  <app-tour-item [tourId]="'ruleLandingPageDialer'" [tourStepNumber]="0"></app-tour-item>

  <button mat-button color="primary" azure-id="rules-landing-page-create-rule" id="rules-landing-page-create-rule"
    class="btn landing-page-link" (click)="createRule()" [disabled]="noRuleTypes || hideNewButton">
    <app-fn-ui-icon class="landing-page-icon" icon="pencil" size="32px"></app-fn-ui-icon>
    <span>{{'rules.create_rule' | translate}}</span>
  </button>
  <button mat-button color="primary" azure-id="rules-landing-page-search-rule-type" id="rules-landing-page-search-rule-type"
    class="btn landing-page-link" (click)="searchRuleType()" [disabled]="noRuleTypes">
    <app-fn-ui-icon class="landing-page-icon" icon="book_search" size="32px"></app-fn-ui-icon>
    <span>{{'rules.search_rule_type' | translate}}</span>
  </button>
  <button mat-button color="primary" azure-id="rules-landing-page-search-rule" id="rules-landing-page-search-rule"
    class="btn landing-page-link" (click)="searchRules()" [disabled]="noRuleTypes">
    <app-fn-ui-icon class="landing-page-icon" icon="search" size="32px"></app-fn-ui-icon>

    <span>{{'rules.search_rule' | translate}}</span>
  </button>
</div>
