import {
  ApplicationRef, ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormatsService } from '@fgpp-ui/components';
import {  TableRow, ActionFilterService, ActionFilterMapperService, PaginatorData } from '@fgpp-ui/grid';
import { GridContainerConfig } from '../../../../../grid/models/grid-container-config.model';
import { GridContainerComponent } from '../../../../../grid/components/grid-container/grid-container.component';
import { PROFILE_UID } from '../../../../../profiles/services/profiles-management.service';
import { ColumnSettingsService } from '../../../../../grid/services/column-settings.service';
import { ExportService } from '../../../../../shared/fn-ui-export/services/export.service';
import { ProfilesSearchRequest } from '../../../../../profiles/models/profiles-search-request.type';
import { Resource } from '../../../../../grid/models/resource.model';
import { FilterMapperService } from '../../../../../grid/services/filter-mapper.service';
import { RuleAssociationsEditService } from '../../services/rule-associations-edit.service';
import { GridEventsService } from '../../../../../azure-insight/services/grid-events.service';
import { UserSettingsService } from '../../../../../core/user-settings/services/user-settings.service';
import { RulesAssociationCommonService } from '../../services/rules-association-common.service';
import { SearchRequestBuilderService } from '../../../../../grid/services/search-request-builder.service';
import { PROFILE_ID } from 'projects/gpp/src/app/profiles/components/profiles-grid/profiles-grid.component';

@Component({
  selector: 'app-rule-associations-grid-wrapper',
  templateUrl: 'rule-associations-grid-wrapper.component.html',
  styleUrls: ['rule-associations-grid-wrapper.component.scss']
})

export class RuleAssociationsGridWrapperComponent extends GridContainerComponent<ProfilesSearchRequest> implements OnInit {

  @Input() gridContainerConfig: GridContainerConfig;
  @Input() rulesAssociationDetails: any;
  @Output() rowSelected = new EventEmitter<any>();
  @Output() pageChanged = new EventEmitter<any>();

  get keyId(): string {
    return PROFILE_UID;
  }

  constructor(route: ActivatedRoute,
              userSettingsService: UserSettingsService,
              gridEventsService: GridEventsService,
              private ruleAssociationsEditService: RuleAssociationsEditService,
              private rulesCommonService: RulesAssociationCommonService,
              private searchRequestBuilder: SearchRequestBuilderService<ProfilesSearchRequest>,
              columnSettingsService: ColumnSettingsService,
              exportService: ExportService,
              filterMapperService: FilterMapperService,
              protected actionFilterMapperService: ActionFilterMapperService,
              protected actionFilterService: ActionFilterService, resolver: ComponentFactoryResolver,
              injector: Injector, app: ApplicationRef, cdr: ChangeDetectorRef, protected formatsService: FormatsService) {
    super(route, userSettingsService, gridEventsService, ruleAssociationsEditService, columnSettingsService, exportService, filterMapperService,
      actionFilterMapperService, actionFilterService, resolver, injector, app, cdr, formatsService);
  }

  ngOnInit() {
    super.ngOnInit();
    if (!this.ruleAssociationsEditService.getSearchRequest()) {
      this.ruleAssociationsEditService.initializeSearchRequest();
    }
    this.getResourceForGrid(PROFILE_ID.RULE);
  }

  getResourceForGrid(id) {
    const ruleTypeFilter = {
      columnId: 'PRULES-RULE_TYPE_ID',
      operator: 'equal',
      value: [this.rulesAssociationDetails.RULE_TYPE_ID]
    };
    const offices = ['***', this.rulesAssociationDetails.OFFICE];
    const officeFilter = { columnId: 'PRULES-OFFICE', operator: 'in', value: offices };

    //New feature for rule evaluation F8194 -Run Time classification and evaluation per class - START
    const layerClassifications = this.rulesCommonService.ALL_LAYER.split(',');
    const layerClassificationFilter = {
      columnId: 'PRULES-LAYER_CLASSIFICATION',
      operator: 'in',
      value: layerClassifications
    };
    //New feature for rule evaluation F8194 -Run Time classification and evaluation per class - END
    let searchFilters: any[];
    // keep 0 in double quote only
    if (this.rulesCommonService.RULE_SUB_TYPE && this.rulesCommonService.RULE_SUB_TYPE !== '0') {
      const ruleSubTypeFilter = {
        columnId: 'PRULES-RULE_SUB_TYPE',
        operator: 'equal',
        value: [this.rulesCommonService.RULE_SUB_TYPE]
      };
      searchFilters = [ruleTypeFilter, officeFilter, ruleSubTypeFilter, layerClassificationFilter];
    } else {
      searchFilters = [ruleTypeFilter, officeFilter, layerClassificationFilter];
    }
    return this.createProfileResource(id, searchFilters);
  }

  async createProfileResource(id, searchFilters: any[]) {
    this.ruleAssociationsEditService.setProfileId(id);
    this.searchRequestBuilder.buildAdditionalParameters(this.ruleAssociationsEditService.getSearchRequest(), { recStatus: 1 });
    this.ruleAssociationsEditService.getSearchRequest().selectType = 1;
    this.ruleAssociationsEditService.setSearchRequestFilters(searchFilters);
    this.ruleAssociationsEditService.invokeGridDataChange(true);
  }

  setContainerGridData(resource: Resource) {
    super.setContainerGridData(resource);
    this.ruleAssociationsEditService.setSelectedRows(resource.gridData.rows);
    this.ruleAssociationsEditService.setNewSelectedRows(resource.gridData.rows);
    this.selectedRows = resource.gridData.rows.filter((row: TableRow) => row.isSelected);
  }

  onRowSelected($event): void {
    this.rowSelected.emit($event);
  }

  onPageChanged(paginatorData: PaginatorData) {
    this.pageChanged.emit(paginatorData);
    super.onPageChanged(paginatorData);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.ruleAssociationsEditService.resetGridData();
    this.rulesCommonService.rulesGridRows.next({});
  }
}
