import { Injectable, SecurityContext } from '@angular/core';
import { Store } from '@ngxs/store';
import { ApiCachingStorageService } from '../../core/api-caching/services/api-caching-storage.service';
import { MultiTabsManagerService } from '../../core/multi-tabs/services/multi-tabs-manager.service';
import { PopupService } from '../../core/services/popup.service';
import { SystemVariablesService } from '../../core/services/system-variables/system-variables.service';
import { DeleteSessionUserData } from '../../core/store/session/action/session.action';
import { PopOutService } from '../../services/pop-out.service';
import { AuthenticationMode } from '../models/enums/authentication-mode.enum';
import { AuthenticationApiService } from './authentication-api.service';
import { AuthenticationConfigService } from './authentication-config.service';
import { AuthenticationIdpService } from './authentication-idp.service';
import { AuthenticationService } from './authentication.service';
import { ISignOutService } from './interfaces/sign-out-service.interface';
import { SecurityService } from './security.service';
import { DomSanitizer } from '@angular/platform-browser';
import {PowerBINavigationService} from "../../insight/power-bi/services/power-bi-navigation.service";

@Injectable()
export class SignOutService implements ISignOutService {

  constructor(private store: Store,
              private multiTabsManagerService: MultiTabsManagerService,
              private systemVariablesService: SystemVariablesService,
              private popupService: PopupService,
              private popOutService: PopOutService,
              private authenticationApiService: AuthenticationApiService,
              private authenticationIdpService: AuthenticationIdpService,
              private authenticationService: AuthenticationService,
              private authenticationConfigService: AuthenticationConfigService,
              private securityService: SecurityService,
              private apiCachingStorageService: ApiCachingStorageService,
              private domSanitizer: DomSanitizer,
              private powerBINavigationService: PowerBINavigationService) { }

  signOut(): Promise<void> {
    this.cleanBeforeSignOut();
    try {
      return this.doSignOut();
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      this.doAfterSignOut();
    }
  }

  forceSignOut(): boolean {
    if (this.authenticationService.userData) {
      this.cleanBeforeSignOut();
      try {
        return this.doForceSignOut();
      } catch (error) {
        console.error(error);
        throw error;
      } finally {
        this.doAfterSignOut(true);
      }
    }
    return false;
  }

  cleanBeforeSignOut(): void {
    this.popupService.closeAll();
    this.popOutService.closeAll();
    window.open('', 'win_ProfileAudit')?.close();
  }

  cleanAfterSignOut(): void {
    this.securityService.clearLocalStorage();
    this.powerBINavigationService.clearLastState();
  }

  private async doSignOut(): Promise<void> {
    return this.authenticationApiService.signOut().toPromise();
  }

  private doForceSignOut(): boolean {
    return this.authenticationApiService.forceSignOut(this.authenticationService.sessionId);
  }

  private doAfterSignOut(isForceSingout = false): void {
    this.dispatchSignOut();
    this.multiTabsManagerService.reset();
    this.apiCachingStorageService.clear();
    this.cleanAfterSignOut();
    if (this.isIDPMode() && this.authenticationConfigService.doIdpLogout) {
      if (isForceSingout) {
        this.authenticationIdpService.forceLogout();
      } else {
        this.authenticationIdpService.logout();
      }
    } else {
      this.changeLocation();
    }
  }

  changeLocation(doRedirect = false): void {
    const mode = this.authenticationConfigService.authenticationMode;
    if (mode === AuthenticationMode.SSO) {
      window.location.replace(this.systemVariablesService.getSingleVariable('logoutForwardRequest'));
      return;
    }

    if (mode === AuthenticationMode.IDP) {
      if (!this.authenticationConfigService.doIdpLogout || doRedirect) {
        window.location.replace(this.systemVariablesService.getSingleVariable('logoutForwardRequest'));
      }
      return;
    }
    window.location.href = this.domSanitizer.sanitize(SecurityContext.URL, window.location.origin + window.location.pathname);
  }

  private dispatchSignOut(): void {
    this.store.dispatch(new DeleteSessionUserData());
  }

  private isIDPMode(): boolean {
    return !!this.authenticationService.token;
  }
}
