import { Directive, EventEmitter, Input, Output } from '@angular/core';

@Directive({
  selector: '[appWatch]'
})
export class WatchDirective {

  @Input()
  get appWatch(): any {
    return this._appWatch;
  }
  set appWatch(value: any) {
    this.valueChanged.emit({ newValue: value, oldValue: this.appWatch });
    this._appWatch = value;
  }
  private _appWatch: any;

  @Output() valueChanged = new EventEmitter<any>();

}
