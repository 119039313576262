import { ButtonType } from '../enums/button-type.enum';

export class FgppButton {
  private caption: string;
  private tooltip: string;
  public action: string;
  private icon: string;
  private controlType: string;
  public buttonType: ButtonType;
  public position: number;
  private refererId?: string;

  constructor(button: FgppButton) {
    if (button.controlType === 'button') {
      this.caption = button.caption;
      this.tooltip = button.tooltip;
      this.action = button.action;
      this.icon = button.icon;
      this.controlType = button.controlType;
      this.buttonType = button.buttonType;
      this.position = button.position;
      this.refererId = button.refererId;
    }
  }
}
