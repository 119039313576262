export const FnUiDatetimePickerContract = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  $id: 'http://example.com/product.schema.json',
  $title: 'Datetime',
  $description: 'JSON Schema Contract for Datetime picker',
  $type: 'object',
  properties: {
    'DATE_TIME': {
      title: 'Datetime',
    }
  },
  required: []
};
