import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalsService } from '../../../shared/fn-ui-modals/services/modals.service';
import { NodeAction } from '../../../shared/fn-ui-navigation-actions-tree/models/node-action.model';
import { FavoritesManagerService } from '../../favorites/services/favorites-manager.service';
import { RecentSearch } from '../models/recent-search.model';
import { IRecentSearchActionsService } from './interfaces/recent-search-actions-service.interface';
import { RecentSearchesManagerService } from './recent-searches-manager.service';

@Injectable()
export class RecentSearchActionsService implements IRecentSearchActionsService {

  get actions(): Array<NodeAction> {
    return [
      this._addToFavoritesAction,
      this._deleteAction
    ];
  }

  private get _addToFavoritesAction(): NodeAction {
    return {
      name: 'Add To Favorites',
      alias: 'tree_action.add_to_favorites',
      command: this.addToFavorites.bind(this)
    };
  }

  private get _deleteAction(): NodeAction {
    return {
      name: 'Delete',
      alias: 'tree_action.delete',
      command: this.delete.bind(this)
    };
  }

  constructor(private modalsService: ModalsService,
              private translateService: TranslateService,
              private favoritesManagerService: FavoritesManagerService,
              private recentSearchesManagerService: RecentSearchesManagerService) { }

  addToFavorites(recentSearch: RecentSearch): void {
    const modalRef = this.openRenamePrompt(recentSearch);
    modalRef.afterClosed().subscribe((name) => {
      if (name) {
        const favorite = {
          id: null,
          alias: name,
          type: recentSearch.type,
          searchQuery: recentSearch.searchQuery,
          sortBy: [],
          pinned: [],
          routerLink: null,
          stateName: null,
          stateParams: null
        };
        this.favoritesManagerService.add(favorite, recentSearch.queueType).subscribe();
      }
    });
  }

  delete(recentSearch: RecentSearch): void {
    this.recentSearchesManagerService.delete(recentSearch).subscribe();
  }

  private openRenamePrompt(recentSearch: RecentSearch) {
    return this.modalsService.prompt({
      message: this.translateService.instant('modals.recent_searches.rename_message'),
      title: this.translateService.instant('modals.recent_searches.rename'),
      name: recentSearch.alias
    });
  }

}
