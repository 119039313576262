<div class="rule-footer-container">
  <div>
    <button azure-id="rule-footer-toggle-conditions-bank"
            id="rule-footer-toggle-conditions-bank"
            class="info-btn"
            mat-button
            color="primary"
            (click)="toggleConditionBank()">
      {{ 'rules.footer.conditions_bank' | translate }}
    </button>

    <button azure-id="rule-footer-toggle-preview-rule-pane"
            id="rule-footer-toggle-preview-rule-pane"
            class="info-btn"
            mat-button
            color="primary"
            (click)="togglePreviewRulePane()">
      <app-tour-item [tourId]="'rulesScreen'" [tourStepNumber]="4"></app-tour-item>
      {{ 'rules.footer.preview_rule' | translate }}
    </button>

    <app-fn-ui-menu [items]="actions"
                    triggerColor="primary"
                    triggerClass="actions-menu-trigger mat-icon-button menu-actions"
                    panelClass="actions-menu-panel"
                    yPosition="above"
                    (menuItemClicked)="onMenuItemClick($event)">
    </app-fn-ui-menu>
  </div>

  <div class="rule-footer-right-side">
    <button *ngIf="showMainCloseButton"
            mat-button
            color="primary"
            azure-id="close-rule"
            id="rule-footer-close-rule"
            type="button"
            class="rule-footer-close-rule"
            (click)="closeRule()">
      {{ 'general.close' | translate }}
    </button>
  
    <button *ngIf="showSaveButton"
            mat-raised-button
            color="primary"
            azure-id="save-rule"
            id="rule-footer-save-rule"
            type="button"
            class="info-btn rule-footer-save-rule"
            (click)="saveRule()">
      {{ 'rules.footer.save_rule' | translate }}
    </button>
  
    <button *ngIf="showDeclineButton"
            mat-button
            color="primary"
            azure-id="decline-rule"
            id="rule-footer-decline-rule"
            type="button"
            (click)="declineRule()">
      {{ 'rules.footer.decline_rule' | translate }}
    </button>
  
    <button *ngIf="showApproveButton"
            mat-raised-button
            color="primary"
            azure-id="approve-rule"
            id="rule-footer-approve-rule"
            type="button"
            class="info-btn"
            (click)="approveRule()">
      {{ 'rules.footer.approve_rule' | translate }}
    </button>
  
    <button *ngIf="showRevertButton"
            mat-raised-button
            color="primary"
            azure-id="revert-rule"
            id="rule-footer-revert-rule"
            type="button"
            (click)="revertRule()">
      {{ 'rules.footer.revert_rule' | translate }}
    </button>
  
    <button *ngIf="showActivateButton"
            mat-raised-button
            color="primary"
            azure-id="activate-rule"
            id="rule-footer-activate-rule"
            type="button"
            class="info-btn"
            (click)="activateRule()">
      {{ 'rules.footer.activate' | translate }}
    </button>
  </div>
</div>
