import { AfterViewInit, Component, ElementRef, Inject, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ComponentManagerService, FormService } from '@fgpp-ui/components';
import { UserSettingsService } from '../../../../core/user-settings/services/user-settings.service';
import { IDynamicModalDialogData } from '../../interfaces/dynamic-modal-dialog-data.interface';
import { IDynamicModalDialogResult } from '../../interfaces/dynamic-modal-dialog-result.interface';

@Component({
  selector: 'app-dynamic-modal',
  templateUrl: './dynamic-modal.component.html',
  styleUrls: ['./dynamic-modal.component.scss'],
  providers: [FormService, ComponentManagerService]
})
export class DynamicModalComponent implements OnInit, AfterViewInit {
  form: FormGroup;
  layout = null;
  contract = null;
  data = null;
  okBtnConfig = null;
  cancelBtnConfig = null;
  formReady = false;
  userPreferences: any;
  formMetadata: any;
  constructor(private elementRef: ElementRef,
              private renderer: Renderer2,
              @Inject(MAT_DIALOG_DATA) public dialogConfig: IDynamicModalDialogData,
              private matDialogRef: MatDialogRef<DynamicModalComponent>,
              private fb: FormBuilder,
              private formService: FormService,
              userSettingsService: UserSettingsService) {
    this.contract = structuredClone(dialogConfig.contract || {});
    this.layout = structuredClone(dialogConfig.layout || {});
    this.data = structuredClone(dialogConfig.data || {});
    this.userPreferences = userSettingsService.getPreferences();

    this.okBtnConfig = {
      id: 'modal-ok-btn',
      caption: this.dialogConfig.okButtonTittle || 'OK',
      controlType: 'button',
      buttonType: 'raised'
    };

    this.cancelBtnConfig = {
      id: 'modal-cancel-btn',
      caption: this.dialogConfig.cancelButtonTitle || 'Cancel',
      controlType: 'button',
      buttonType: 'flat'
    };
  }

  ngOnInit(): void {
    this.matDialogRef.updateSize(`${this.dialogConfig.width}px`, `${this.dialogConfig.height}px`);
    this.matDialogRef.keydownEvents().subscribe(event => {
      if (event.key === 'Escape') {
        this.onCancelClick();
      }
    });

    this.updateForm(this.layout, this.contract, this.data);
    setTimeout(() => this.setDefaults());
  }

  setDefaults(): void {
    this.form.patchValue(this.data);
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.formReady = true);
    const el = this.elementRef.nativeElement.querySelector('.mat-dialog-content .body');
    if (el) {
      this.renderer.setStyle(el, 'height', `${this.dialogConfig.height - (52 * 2)}px`);
    }
  }

  onCancelClick() {
    const dialogResult: IDynamicModalDialogResult = {
      choice: 0,
      payload: null
    };
     this.matDialogRef.close(dialogResult);
  }

  onOkClick() {
    const dialogResult: IDynamicModalDialogResult = {
      choice: 1,
      payload: this.form.getRawValue()
    };
    this.matDialogRef.close(dialogResult);
  }

  private updateForm(layout, contract, data) {
    if (!layout || !contract) {
      return;
    }
    this.form = new FormGroup({});
    this.formService.setForm(this.form);
    this.formMetadata = this.formService.createForm(this.form, layout, contract, data);
  }
}
