import { Injectable } from '@angular/core';
import { NavigationService } from '../../../services/navigation.service';
import { InsightTab, SitemapNode } from '../../../sitemap/models';
import { TaskBar } from '../../models/task-bar.model';
import { ITaskBarHandlerService } from './task-bar-handler-service.interface';

@Injectable()
export class InsightTaskBarHandlerService implements ITaskBarHandlerService {

  constructor(private navigationService: NavigationService) { }

  getTaskBar(component: InsightTab): TaskBar {
    const taskBar = {
      items: []
    };
    component.modules.inner_navigation?.nodes?.forEach((node: SitemapNode, index) => {
      if (node.nodes) {
        const stateObj = this.navigationService.isInsightPbi(node.id) ? {
          stateName: `home.${component.id}.taskbar.pbi`,
          stateParams: { taskbarItem: node.id },
        } : {
          stateName: `home.${component.id}.taskbar.${node.id}`,
          stateParams: {}
        };

        taskBar.items.push({
          id: node.id,
          alias: node.alias,
          icon: node.icon,
          position: index,
          routerLink: `/home/${component.id}/${node.id}`,
          stateName: stateObj.stateName,
          stateParams: stateObj.stateParams,
        });
      }
    });
    return taskBar;
  }

}
