<app-fn-ui-popover popoverTriggerOn="none" popoverClass="insert-function-popover" [popoverOffsetY]="popoverOffsetY">

  <ng-template #popoverHeader>
    <div class="insert-function-header">
      <span class="insert-function-title">{{ function.alias }}</span>
      <app-fn-ui-close-icon class="insert-function-close-icon" azure-id="insert-function-close-icon" (closeClick)="closePopover()"></app-fn-ui-close-icon>
    </div>
  </ng-template>

  <ng-template #popoverBody>
    <div class="insert-function-body">
      <div class="insert-function-description">{{ function.description }}</div>
      <div [hidden]="function.functionArguments.length === 0" class="insert-function-args">
        <form role="form" name="functionArgumentsForm" autocomplete="off">
          <span class="insert-function-args-header">{{ 'rules.function_arguments' | translate }}</span>
          <div class="insert-function-args-fields">
            <div appFunctionArguments
                 [functionName]="function.alias"
                 [metadata]="function.functionArguments">
            </div>
          </div>
        </form>
      </div>
    </div>
  </ng-template>

  <ng-template #popoverFooter>
    <app-fn-ui-button type="flat" color="primary" name="apply" class="apply-button" (click)="applyFunction()">
      <span class="apply-button-text">{{ 'general.apply' | translate }}</span>
    </app-fn-ui-button>
  </ng-template>

</app-fn-ui-popover>
