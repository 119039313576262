import { KeyboardShortcut } from '../../models/keyboard-shortcut.model';

export namespace KeyboardShortcutActions {

    export class FetchKeboardMode {

        static readonly type = '[KEYBOARD_MODE API] Fetch keyboard mode';

    }

    export class FetchKeyboardShortcuts {

        static readonly type = '[KEYBOARD_SHORTCUTS API] Fetch keyboard shortcuts';

    }

    export class PressUserKeyboard {

        static readonly type = '[KEYBOARD USER] set user active keyboard action';

        constructor(public payload: KeyboardShortcut) { }

    }

}
