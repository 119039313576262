import { Injectable } from '@angular/core';
import { ShortcutEventOutput } from '@egoistdeveloper/ng-keyboard-shortcuts';
import { IKeyboardShortcutEventEmitterService } from './interfaces/keyboard-shortcut-event-emitter-service.interface';
import { StoreService } from '../../services/store.service';
import { KeyboardShortcut } from '../models/keyboard-shortcut.model';
import { KeyboardShortcutActions } from '../store/actions/keyboard-shortcuts.actions';

@Injectable()
export class KeyboardShortcutEventEmitterService implements IKeyboardShortcutEventEmitterService {

  constructor(private storeService: StoreService) { }

  emitEvent(shortcut: KeyboardShortcut, event: ShortcutEventOutput): void {
    this.storeService.dispatch(new KeyboardShortcutActions.PressUserKeyboard(shortcut));
  }

}
