import { Clipboard } from '@angular/cdk/clipboard';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from '@fgpp-ui/components';
import { TranslateService } from '@ngx-translate/core';
import { UserSettingsService } from '../../user-settings/services/user-settings.service';
import { IUserMenuServiceInterface } from './interfaces/user-menu-service.interface';

@Injectable()
export class UserMenuService implements IUserMenuServiceInterface {

  constructor(private router: Router,
              private clipboard: Clipboard,
              private notificationService: NotificationService,
              private translateService: TranslateService,
              private userSettingsService: UserSettingsService) { }

  openUserSettings(): void {
    this.userSettingsService.openUserSettings();
  }

  onFinastraVersion(text: string): void {
    this.clipboard.copy(text);
    this.notificationService.success(this.translateService.instant('userMenu.version-copied'));
  }

  openHelpPage(): void {
    window.open('/gppwebhelp/Default.htm', '_blank');
  }

  logOut(): void {
    this.router.navigate(['user/signout']);
  }

  openFinastraPage(): void {
    window.open('http://www.finastra.com');
  }

}
