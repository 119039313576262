import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-create-base-condition-modal',
  templateUrl: './create-base-condition-modal.component.html',
  styleUrls: ['./create-base-condition-modal.component.scss']
})
export class CreateBaseConditionModalComponent {

  constructor(@Inject(MAT_DIALOG_DATA) private _params,
              private dialogRef: MatDialogRef<CreateBaseConditionModalComponent>) { }

  get params() {
    return this._params;
  }

  onSubmit() {
    this.dialogRef.close({
      ruleName: this._params.ruleName,
      description: this._params.description
    });
  }

}
