import { Component, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FileUploader, FileItem } from 'ng2-file-upload';
import { FileUploadTexts } from './models/file-upload-texts.model';

@Component({
  selector: 'lib-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent {

  @Input() uploaderInput: FileUploader;
  @Input() disabled: boolean;
  @Input() texts: FileUploadTexts;
  @Output() fileData: EventEmitter<FileItem> = new EventEmitter<FileItem>();

  @ViewChild('inputFile') inputFile: ElementRef;

  fileName: string;
  hasBaseDropZoneOver: boolean;

  constructor() {
  }


  public fileDropped(event) {
    if (this.disabled) {
      this.uploaderInput.clearQueue();
    } else {
      if (this.uploaderInput.queue.length > 0) {
        this.fileName = this.uploaderInput.queue[0].file.name || '';
      }
      this.fileData.emit(event[0]);
    }
  }


  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  emptyInputFile() {
    this.inputFile.nativeElement.value = '';
  }
}
