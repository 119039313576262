import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SearchCondition } from '../models/classes/search-condition.model';
import { Operators } from '../models/enums/operators.enum';
import { ISearchCriteriaService } from './interfaces/search-criteria-service.interface';

@Injectable({
  providedIn: 'root'
})
export class SearchConditionService implements ISearchCriteriaService {

  get defaultCondition(): SearchCondition {
    return new SearchCondition(false, '_all', Operators.equal, ['*']);
  }

  constructor(private translateService: TranslateService) { }

  toString(searchCondition: SearchCondition): string {
    const type = searchCondition.class;
    const leftOperand = searchCondition.leftOperand;
    const not = searchCondition.not ? '!' : '';
    const operator = searchCondition.operator;
    const rightOperand = searchCondition.rightOperand.join(',');
    return `<@${type}:[${leftOperand}] ${not}${operator} (${rightOperand})>`;
  }

  toHumanString(searchCondition: SearchCondition): string {
    const leftOperand = searchCondition.leftOperand === 'quick_search' ?
      this.translateService.instant('messages_center.recent_searches.quick_search') + ': ' : searchCondition.leftOperand;
    const rightOperand = searchCondition.rightOperand.join(',');
    return leftOperand + rightOperand;
  }

}
