import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

import { FnUiCloseIconComponent } from './fn-ui-close-icon.component';
import { FnUiIconModule } from '../fn-ui-icon/fn-ui-icon.module';


@NgModule({
    imports: [
        CommonModule,
        MatButtonModule,
        FnUiIconModule
    ],
    declarations: [
        FnUiCloseIconComponent
    ],
    exports: [
        FnUiCloseIconComponent
    ]
})
export class FnUiCloseIconModule { }
