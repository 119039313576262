import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ApiCachingStorageService } from './services/api-caching-storage.service';
import { ApiCachingService } from './services/api-caching.service';

@NgModule({
  imports: [
    HttpClientModule
  ],
  providers: [
    ApiCachingStorageService,
    ApiCachingService
  ]
})
export class ApiCachingModule { }
