import { Entity } from '../../../shared/models/enums/entity.enum';
import { MainTab } from '../../navigation/models';
import { SearchType } from '../models';

export const SEARCH_BOX_ITEMS = [
    {
        name: 'messages',
        entity: Entity.MESSAGES,
        type: SearchType.QUICK_SEARCH,
        tabs: [MainTab.MESSAGES_CENTER]
    },
    {
        name: 'files',
        entity: Entity.FILES,
        type: SearchType.QUICK_SEARCH,
        tabs: [MainTab.MESSAGES_CENTER]
    },
    {
        name: 'bulks',
        entity: Entity.BULKS,
        type: SearchType.QUICK_SEARCH,
        tabs: [MainTab.MESSAGES_CENTER]
    },
    {
        name: 'batches',
        entity: Entity.BATCHES,
        type: SearchType.QUICK_SEARCH,
        tabs: [MainTab.MESSAGES_CENTER]
    },
    {
        name: 'advancedSearch',
        entity: Entity.MESSAGES,
        type: SearchType.ADVANCED_SEARCH,
        tabs: [MainTab.MESSAGES_CENTER]
    },
    {
        name: 'navigationSearch',
        type: SearchType.NAVIGATION_SEARCH,
        tabs: [
            MainTab.BUSINESS_SETUP,
            MainTab.SYSTEM_SETUP,
            MainTab.OPERATIONS,
            MainTab.RULES
        ]
    }
];
