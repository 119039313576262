export enum Caller  {
	"USER_AVAILABILITY_NAME"= "USER_AVAILABILITY-USER_AVAILABILITY_NAME",
	"ENT_CALLER_IND"= "CALLERS-ENT_CALLER_IND",
	"CUST_CODE"= "CALLERS-CUST_CODE",
	"SUPER_CALLER_IND"= "CALLERS-SUPER_CALLER_IND",
	"SUPER_CALLER_CALLBACK_MIN_AMT"= "CALLERS-SUPER_CALLER_CALLBACK_MIN_AMT",
	"AUTH_TYPE"= "CALLERS-AUTH_TYPE",
	"CALLBACK_REQ_IND"= "CALLERS-CALLBACK_REQ_IND",
	"CALLER_ID"= "CALLERS-CALLER_ID",
	"CALLER_PASSWORD"= "CALLERS-CALLER_PASSWORD",
	"CREATE_LIMIT"= "CALLERS-CREATE_LIMIT",
	"CURRENCY"= "CALLERS-CURRENCY",
	"DEPARTMENT"= "CALLERS-DEPARTMENT",
	"EFFECTIVE_DATE"= "EFFECTIVE_DATE",
	"EMAIL_ADDRESS"= "CALLERS-EMAIL_ADDRESS",
	"FIRST_NAME"= "CALLERS-FIRST_NAME",
	"FROM_TEMPLATE_ONLY_IND"= "CALLERS-FROM_TEMPLATE_ONLY_IND",
	"LAST_NAME"= "CALLERS-LAST_NAME",
	"OFFICE"= "CALLERS-OFFICE",
	"PHONE_NO_1"= "CALLERS-PHONE_NO_1",
	"PHONE_NO_2"= "CALLERS-PHONE_NO_2",
	"PROFILE_CHANGE_STATUS"= "CALLERS-PROFILE_CHANGE_STATUS",
	"REC_STATUS"= "CALLERS-REC_STATUS",
	"USER_AVAILABILITY_UID"= "CALLERS-USER_AVAILABILITY_UID",
	"VERIFY_LIMIT"= "CALLERS-VERIFY_LIMIT",
	"VERIFY_OWN_CREATE_IND"= "CALLERS-VERIFY_OWN_CREATE_IND",
	"CUST_NAME"= "CUSTOMRS-CUST_NAME",
	"TIME_STAMP"= "TIME_STAMP",
	"UID"= "VIRTUAL-UID",
	"PROFILE_UPDATE_PK"= "PROFILE_UPDATE_PK",
	"PU_TIME_STAMP"= "PU_TIME_STAMP",
	"NOTE"= "NOTE"
};
