import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import {filter, Subscription} from 'rxjs';
import { SearchBoxService } from '../../services/search-box.service';
import { NavigationSearchService } from '../../services/navigation-search.service';
import { NavigationBoxMode, NavigationResultGroup, NavigationResultItem } from '../../models';
import {NavigationEnd, Router} from "@angular/router";

@Component({
  selector: 'app-navigation-result',
  templateUrl: './navigation-result.component.html',
  styleUrls: ['./navigation-result.component.scss'],
})
export class NavigationResultComponent implements OnInit, OnDestroy {

  searchTerm: string;
  groups: Array<NavigationResultGroup>;
  totalResults: number;
  isOpen: boolean;
  readonly header = 'navigation_search.results.header';

  @HostBinding('class')
  get hostClass(): string {
    return this.isOpen ? 'open' : 'closed';
  }

  get isEmpty(): boolean {
    return !this.groups?.length;
  }

  private _subscriber = new Subscription();

  constructor(private searchBoxService: SearchBoxService,
              private navigationSearchService: NavigationSearchService, private router: Router) {
    this.listenToTransitionChanges();
  }

  ngOnInit(): void {
    this.subscribeToSearchTerm();
    this.subscribeToMode();
    this.subscribeToResults();
    this.listenToTransitionChanges();
  }

  private listenToTransitionChanges(): void {
    this._subscriber.add(this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe(() => {
      if (this.isOpen) {
        this.searchBoxService.closeNavigationBox();
      }
    }));
  }

  private subscribeToSearchTerm(): void {
    const subscription = this.searchBoxService.searchTerm$.subscribe((searchTerm: string) => {
      if (searchTerm) {
        this.searchTerm = searchTerm;
        this.search();
      }
    });
    this._subscriber.add(subscription);
  }

  private subscribeToMode(): void {
    const subscription = this.searchBoxService.navigationBoxMode$.subscribe((mode: NavigationBoxMode) => {
      this.isOpen = mode === NavigationBoxMode.OPEN;
    });
    this._subscriber.add(subscription);
  }

  private subscribeToResults(): void {
    const subscription = this.navigationSearchService.results$.subscribe((results: Array<NavigationResultGroup>) => {
      this.groups = results;
      this.setTotalResults();
    });
    this._subscriber.add(subscription);
  }

  private search(): void {
    this.navigationSearchService.search(this.searchTerm);
  }

  private setTotalResults(): void {
    let totalResults = 0;
    this.groups.forEach((group: NavigationResultGroup) => {
      totalResults += group.items.length;
    });
    this.totalResults = totalResults;
  }

  trackByFunction(index: number, item: NavigationResultGroup | NavigationResultItem): string {
    return item.id;
  }

  ngOnDestroy(): void {
    this._subscriber.unsubscribe();
  }

}
