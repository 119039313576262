<h1 mat-dialog-title>
  <div class="title-wrapper">
    <div class="title" translate>
      {{'business-framework.tasks.common.execution-history.title' | translate}}
    </div>
    <button mat-icon-button matSuffix (click)="close()" class="close-btn" tabindex="-1">
      <app-fn-ui-icon icon="close"></app-fn-ui-icon>
    </button>
  </div>
</h1>
<div mat-dialog-content>
  <div class="body form-content">
    <ng-container *ngIf="isGridView">
      <gpp-ui-legacy-table
        [endpoint]="endpoint"
        [rowClickable]="true"
        [rowSelection]="false">
      </gpp-ui-legacy-table>
    </ng-container>
  </div>
</div>


