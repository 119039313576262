import { Action, State, Selector, StateContext } from '@ngxs/store';
import { SetGridEvent } from './actions/grid-event.actions';
import { GridEventStateModel, GridEvent } from './models/grid-event-state.model';




@State<GridEventStateModel>({
  name: 'gridEvent',
  defaults: {
    gridEvent: {
      filter: [],
      pageNumber: 1,
      sort: [],
      sendData: false
    }
  }
})
export class GridEventState {

  @Selector()
  static getGridEvent(state: GridEventStateModel): GridEvent {
    return state.gridEvent;
  }

  @Action(SetGridEvent)
  SetgridEvent(ctx: StateContext<GridEventStateModel>, { payload }: SetGridEvent) {
    ctx.patchState({
      gridEvent: payload
    });
  }
}
