import { PipeTransform, Pipe } from '@angular/core';
@Pipe({ name: 'highlight' })
export class FtHighlightText implements PipeTransform {
  transform(text: string, search: string): string {
    if (search && typeof search !== 'undefined' && typeof search === 'string') {
      const pattern = search
        .replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&')
        .split(' ')
        .filter((t) => t.length > 0)
        .join('|');
      const regex = new RegExp(pattern, 'gi');
      return search
        ? text.replace(
            regex,
            (match) => `<span class="highlight">${match}</span>`
          )
        : text;
    }
    return text;
  }
}
