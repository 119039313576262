import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FnUiSeverityIconComponent } from './fn-ui-severity-icon.component';


@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        FnUiSeverityIconComponent
    ],
    exports: [
        FnUiSeverityIconComponent
    ]
})
export class FnUiSeverityIconModule { }
