export enum Company {
  'OFFICE' = 'COMPANY-OFFICE',
  'DEPARTMENT' = 'COMPANY-DEPARTMENT',
  'REC_STATUS' = 'COMPANY-REC_STATUS',
  'PROFILE_CHANGE_STATUS' = 'COMPANY-PROFILE_CHANGE_STATUS',
  'EFFECTIVE_DATE' = 'EFFECTIVE_DATE',

  'CUST_CODE' = 'COMPANY-CUST_CODE',
  'CUST_NAME' = 'CUSTOMRS-CUST_NAME',

  'CMPNY_TP' = 'COMPANY-CMPNY_TP',
  'BUS_REG_NO' = 'COMPANY-BUS_REG_NO',
  'CMPNY_EFT_ORG' = 'COMPANY-CMPNY_EFT_ORG',
  'CMPNY_EFT_MLT' = 'COMPANY-CMPNY_EFT_MLT',

  'CMPNY_BLLNG_BK' = 'COMPANY-CMPNY_BLLNG_BK',
  'CMPNY_BLLNG_ACCT_NO' = 'COMPANY-CMPNY_BLLNG_ACCT_NO',

  'CMPY_GCI_PRM' = 'COMPANY-CMPY_GCI_PRM',
  'CMPY_GCI_FAM' = 'COMPANY-CMPY_GCI_FAM',

  'FORMAT_DATE' = 'COMPANY-FORMAT_DATE',
  'TIME_ZONE' = 'COMPANY-TIME_ZONE',
  'FORMAT_NO' = 'COMPANY-FORMAT_NO',
  'CUST_LANGUAGE' = 'COMPANY-CUST_LANGUAGE',

  'TIME_STAMP' = 'TIME_STAMP',
  'UID' = 'VIRTUAL-UID',
  'PROFILE_UPDATE_PK' = 'PROFILE_UPDATE_PK',
  'PU_TIME_STAMP' = 'PU_TIME_STAMP',
  'NOTE' = 'NOTE'
};
