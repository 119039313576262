import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { MessagesApiService } from './messages-api.service';
import { MessagesQueue } from '../models/messages-queue.model';
import { InnerGridApi } from '../../mass-pay/entity/models/consts/inner-grid-api.const';
import { Resource } from '../../grid/models/resource.model';
import { GridSearchRequest } from '../../grid/models/grid-search-request.model';
import { GridSearchResponse } from '../../grid/models/grid-search-response.model';
import { MessagesType } from '../models/enums/messages-type.enum';
import { GridColumnsModel } from '../../grid/models/grid-columns.model';
import { InnerGridTabs } from '../../mass-pay/entity/models/enums/inner-grid-tabs.enum';
import { TranslateService } from '@ngx-translate/core';
import { MessageGridColumnsService } from './message-grid-column.service';
import { GridSort } from '../../grid/models/grid-sort.model';
import { SortDirection } from '@fgpp-ui/grid';
import { QueueCountsSearchResponse } from '../models/queues-counts-search-response.model';
import { OfficesCounts } from '../../messages-center/messages-center-navigation/models/offices-counts.model';
import { GridHelperService } from '../../grid/services/grid-helper.service';
import { Aggregate } from '../../grid/models/aggregate.model';
import { Aggregation } from '../../grid/models/aggregation.model';

@Injectable()
export class MessagesHelperService extends GridHelperService {

  constructor(
    private messagesApiService: MessagesApiService,
    public messageGridColumnsService: MessageGridColumnsService,
    http: HttpClient,
    translateService: TranslateService) {
    super(messagesApiService, messageGridColumnsService, http, translateService);
  }

  getInnerAllGridData(searchRequest: GridSearchRequest, queueObject: MessagesQueue): Observable<Resource> {
    return this.messagesApiService.getInnerAllGridData(searchRequest, queueObject.type, queueObject.paramsFileId)
      .pipe(
        map((res) => {
          return this.prepareInvestigationDataObj(res, queueObject.type);
        })
      );
  }

  getInnerGridData(searchRequest: GridSearchRequest, messageType: MessagesType, id: string, tab: InnerGridTabs, columns: GridColumnsModel) {
    return this.messagesApiService.getInnerGridData(searchRequest, messageType, id, tab)
      .pipe(map((response) => {
        return this.prepareDataObj(response, columns.gridColumns, columns.availableColumns);
      }));
  }

  prepareInvestigationDataObj(responses: GridSearchResponse[], type): any {
    const investigationGridData = {};
    responses.forEach((response, index) => {
      investigationGridData[InnerGridApi[type].api[index]] = this.prepareDataObj(response);
    });
    return investigationGridData;
  }

  addSortToSearchRequest<T extends GridSearchRequest>(columns: GridColumnsModel, searchRequest: T): void {
    super.addSortToSearchRequest(columns, searchRequest);
    if (!searchRequest.sort || searchRequest.sort.length === 0) {
      const defaultSortColumn = 'P_MID';
      const sort: GridSort = { fieldName: defaultSortColumn, sortType: SortDirection.DESC };
      searchRequest.sort = [sort];
    }
  }

  protected callMetaDataSearch<T extends GridSearchRequest>(searchRequest: T, baseUrl: string, httpParams?: HttpParams): Promise<any> {
    const promises = [];
    promises.push(this.getGridColumns(baseUrl, httpParams).then((columns: GridColumnsModel) => {
      this.addSortToSearchRequest(columns, searchRequest);
      return this.doSearch(searchRequest, baseUrl, httpParams, columns);
    }));
    promises.push(this.gridApiService.getGridPreferences(baseUrl, httpParams).catch(() => Promise.resolve({ showRefine: true })));
    return Promise.all(promises);
  }

  getGridColumns(baseUrl: string, httpParams?: HttpParams): Promise<GridColumnsModel> {
    return this.gridApiService.getGridsColumns(baseUrl, httpParams).then(metadata => {
      return this.gridColumnsService.getColumns(metadata, baseUrl);
    });
  }

  doMultiSearch<T extends GridSearchRequest>(searchRequest: T, baseUrl: string): Promise<Array<OfficesCounts>> {
    return this.messagesApiService.multiSearch(searchRequest, baseUrl).then(
      (searchResults: QueueCountsSearchResponse[]) => {
        const endResult: OfficesCounts[] = [];
        searchResults.forEach((searchResult: QueueCountsSearchResponse) => {
          const offices: OfficesCounts = this.countOffices(searchResult.aggregates);
          if (offices) {
            endResult.push(offices);
          }
        });
        return Promise.resolve(endResult);
      },
      (reason) => {
        return Promise.reject(reason);
      }
    );
  }

  countOffices(aggregates: Aggregate[]): OfficesCounts {
    if (!aggregates || aggregates.length === 0) {
      return null;
    }

    const offices = {};
    const buckets = aggregates[0].buckets;
    if (buckets) {
      for (let j = 0; j < buckets.length; j++) {
        const bucket = buckets[j];
        offices[bucket.key] = {
          count: bucket.docCount,
          amount: (bucket.aggregations[0] as Aggregation).sum
        };
      }
    }

    return offices;
  }

  aggregationsToMap(aggregations: Array<Aggregate>): OfficesCounts {
    if (aggregations == null || aggregations.length === 0) {
      return null;
    }

    const statuses = {};
    const buckets = aggregations[0].buckets;
    for (let j = 0; j < buckets.length; j++) {
      const bucket = buckets[j];
      statuses[bucket.key] = {
        count: bucket.docCount,
        offices: this.countOffices(bucket.aggregations as Array<Aggregate>)
      };
    }

    return statuses;
  }


}
