import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '@fgpp-ui/components';
import { AuthenticationApiService } from '../../services/authentication-api.service';

@Component({
  selector: 'app-activate-soft-token',
  templateUrl: './activate-soft-token.component.html',
  styleUrls: ['./activate-soft-token.component.scss']
})
export class ActivateSoftTokenComponent implements OnInit {

  form: FormGroup;
  instanceActivationMessage: string;
  private _sequenceNumber: number;
  private _userId: string;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private formBuilder: FormBuilder,
              private notificationService: NotificationService,
              private authenticationApiService: AuthenticationApiService) {
    this.instanceActivationMessage = this.router.getCurrentNavigation().extras?.state?.instanceActivationMessage || null;
    this._sequenceNumber = this.router.getCurrentNavigation().extras?.state?.sequenceNumber || null;
    this._userId = this.router.getCurrentNavigation().extras?.state?.userId || null;
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      signature: ['', Validators.required]
    });
  }

  onBackClick(): void {
    this.router.navigateByUrl('user/signin');
  }

  onFormSubmit(): boolean {
    this.authenticationApiService.vascoCrontoActivate({
      userId: this._userId,
      sequenceNumber: this._sequenceNumber,
      signature: this.form.value.signature
    }).subscribe({
      next: () => {
        this.router.navigateByUrl('user/signin');
        this.notificationService.success('Activation successful');
      },
      error: (response: HttpErrorResponse) => {
        this.notificationService.error(response.error.type);
      }
    });
    return false;
  }

}
