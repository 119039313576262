import { Component, Injector, OnInit } from '@angular/core';
import { IValueChange } from '../../../core/interfaces/form-value-change.interface';
import { BFBeforeProfileCreate, BFBeforeProfileEdit, BFBeforeProfileView, BFOnValueChange } from '../../../core/interfaces/sdk-profile-hooks.interface';
import { CountryInfoProfileProviders } from '../../../core/templates/profile-form.template.providers';
import { AbstractProfileFormComponent } from '../../abstract/abstract-profile/abstract-profile-form.component';
import { CountryInfo } from '../../models/country-info.model';
import { IBANService } from './services/iban.service';


@Component({
	selector: 'app-country-info',
	templateUrl: '../../../core/templates/profile-form.template.html',
	styleUrls: ['../../../core/templates/profile-form.template.scss'],
	providers: CountryInfoProfileProviders
})
export class CountryInfoComponent extends AbstractProfileFormComponent
	implements OnInit, BFOnValueChange, BFBeforeProfileCreate, BFBeforeProfileEdit, BFBeforeProfileView {

	constructor(injector: Injector, private ibanService: IBANService) {
		super(injector);
	}

	checkDigitTypeValueChangeHandler(digitType: string): void {
		if (digitType === 'IBAN') {
			this.formMgr.get(CountryInfo.IBAN_MANDATORY).enable();
			this.formMgr.get(CountryInfo.IBAN_LEN).enable().markAsRequired();
			this.formMgr.get(CountryInfo.IBAN_BANK_ID_POS).markAsRequired();
			this.formMgr.get(CountryInfo.IBAN_BANK_ID_LEN).enable().markAsRequired();
			this.formMgr.get(CountryInfo.IBAN_BRANCH_POS).enable();
			this.formMgr.get(CountryInfo.IBAN_BRANCH_LEN).enable();
			this.formMgr.get(CountryInfo.IBAN_ACCOUNT_POS).enable().markAsRequired();
			this.formMgr.get(CountryInfo.IBAN_ACCOUNT_LEN).enable().markAsRequired();
			this.setIBANExample();
		} else {
			this.formMgr.get(CountryInfo.IBAN_MANDATORY).disable();
			this.formMgr.get(CountryInfo.IBAN_LEN).disable().markAsOptional();
			this.formMgr.get(CountryInfo.IBAN_BANK_ID_POS).disable().markAsOptional();
			this.formMgr.get(CountryInfo.IBAN_BANK_ID_LEN).disable().markAsOptional();
			this.formMgr.get(CountryInfo.IBAN_BRANCH_POS).disable();
			this.formMgr.get(CountryInfo.IBAN_BRANCH_LEN).disable();
			this.formMgr.get(CountryInfo.IBAN_ACCOUNT_POS).disable().markAsOptional();
			this.formMgr.get(CountryInfo.IBAN_ACCOUNT_LEN).disable().markAsOptional();
			this.layoutEvent.hide(CountryInfo.IBAN_LABEL);
		}
	}

	fgppValueChanges(change: IValueChange): void {
		const control = change.control;
		switch (control) {
			case CountryInfo.COUNTRYCODE: {
				this.setIBANExample();
				break;
			}
			case CountryInfo.CHK_DIGIT_TYPE: {
				const digitType = this.formMgr.get(CountryInfo.CHK_DIGIT_TYPE).getValue();
				if (digitType === 'IBAN') {
					this.formMgr.get(CountryInfo.IBAN_BANK_ID_POS).setValue('5');
					this.formMgr.get(CountryInfo.IBAN_BRANCH_POS).setValue('0');
					this.formMgr.get(CountryInfo.IBAN_BRANCH_LEN).setValue('0');
				} else {
					this.formMgr.get(CountryInfo.IBAN_MANDATORY).setValue('0');
					this.formMgr.get(CountryInfo.IBAN_LEN).setValue('');
					this.formMgr.get(CountryInfo.IBAN_BANK_ID_POS).setValue('');
					this.formMgr.get(CountryInfo.IBAN_BANK_ID_LEN).setValue('');
					this.formMgr.get(CountryInfo.IBAN_BRANCH_POS).setValue('');
					this.formMgr.get(CountryInfo.IBAN_BRANCH_LEN).setValue('');
					this.formMgr.get(CountryInfo.IBAN_ACCOUNT_POS).setValue('');
					this.formMgr.get(CountryInfo.IBAN_ACCOUNT_LEN).setValue('');
				}
				this.checkDigitTypeValueChangeHandler(digitType);
				break;
			}
			case CountryInfo.IBAN_LEN: {
				this.validateIBANLength();
				break;
			}
			/*      case CountryInfo.IBAN_BANK_ID_POS: {
							this.validateIBANLengthAndPosition();
							this.setIBANExample();
							break;
						}*/
			case CountryInfo.IBAN_BANK_ID_LEN: {
				const ibanBankPosition = +this.formMgr.get(CountryInfo.IBAN_BANK_ID_POS).getValue();
				const ibanBankLength = +this.formMgr.get(CountryInfo.IBAN_BANK_ID_LEN).getValue();
				const ibanBranchPosition = +this.formMgr.get(CountryInfo.IBAN_BRANCH_POS).getValue();
				const ibanBranchLength = +this.formMgr.get(CountryInfo.IBAN_BRANCH_LEN).getValue();
				const ibanAccountPosition = +this.formMgr.get(CountryInfo.IBAN_ACCOUNT_POS).getValue();
				if (ibanBankLength) {
					let valid = true;
					if (ibanBranchPosition && ibanBranchLength) {
						valid = this.validatePosition(ibanBankLength, ibanBankPosition, ibanBranchPosition, CountryInfo.IBAN_BRANCH_POS);
					} else if (ibanAccountPosition) {
						valid = this.validatePosition(ibanBankLength, ibanBankPosition, ibanAccountPosition, CountryInfo.IBAN_ACCOUNT_POS);
					}
					if (valid) {
						this.validateIBANLength();
					}
					this.setIBANExample();
				}
				break;
			}
			case CountryInfo.IBAN_BRANCH_POS: {
				const ibanBankLength = +this.formMgr.get(CountryInfo.IBAN_BANK_ID_LEN).getValue();
				const ibanBankPosition = +this.formMgr.get(CountryInfo.IBAN_BANK_ID_POS).getValue();
				const ibanBranchPosition = +this.formMgr.get(CountryInfo.IBAN_BRANCH_POS).getValue();
				const ibanBranchLength = +this.formMgr.get(CountryInfo.IBAN_BRANCH_LEN).getValue();
				const ibanAccountPosition = +this.formMgr.get(CountryInfo.IBAN_ACCOUNT_POS).getValue();
				if (ibanBranchPosition && ibanBranchLength) {
					if (ibanBankLength) {
						this.validatePosition(ibanBankLength, ibanBankPosition, ibanBranchPosition, CountryInfo.IBAN_BRANCH_POS);
					}
					if (ibanAccountPosition) {
						this.validatePosition(ibanBranchLength, ibanBranchPosition, ibanAccountPosition, CountryInfo.IBAN_ACCOUNT_POS);
					}
				}
				this.setIBANExample();
				break;
			}
			case CountryInfo.IBAN_BRANCH_LEN: {
				const ibanBankPosition = +this.formMgr.get(CountryInfo.IBAN_BANK_ID_POS).getValue();
				const ibanBankLength = +this.formMgr.get(CountryInfo.IBAN_BANK_ID_LEN).getValue();
				const ibanBranchPosition = +this.formMgr.get(CountryInfo.IBAN_BRANCH_POS).getValue();
				const ibanBranchLength = +this.formMgr.get(CountryInfo.IBAN_BRANCH_LEN).getValue();
				const ibanAccountPosition = +this.formMgr.get(CountryInfo.IBAN_ACCOUNT_POS).getValue();
				let valid = true;

				if (ibanBranchPosition && ibanBranchLength) {
					if (ibanBankLength) {
						valid = this.validatePosition(ibanBankLength, ibanBankPosition, ibanBranchPosition, CountryInfo.IBAN_BRANCH_POS);
					}
					if (ibanAccountPosition) {
						valid = this.validatePosition(ibanBranchLength, ibanBranchPosition, ibanAccountPosition, CountryInfo.IBAN_ACCOUNT_POS);
					}
				}
				if (valid) {
					this.validateIBANLength();
				}
				this.setIBANExample();
				break;
			}
			case CountryInfo.IBAN_ACCOUNT_POS: {
				const ibanBranchLength = +this.formMgr.get(CountryInfo.IBAN_BRANCH_LEN).getValue();
				const ibanBranchPosition = +this.formMgr.get(CountryInfo.IBAN_BRANCH_POS).getValue();
				const ibanAccountPosition = +this.formMgr.get(CountryInfo.IBAN_ACCOUNT_POS).getValue();
				const ibanBankLength = +this.formMgr.get(CountryInfo.IBAN_BANK_ID_LEN).getValue();
				const ibanBankPosition = +this.formMgr.get(CountryInfo.IBAN_BANK_ID_POS).getValue();
				if (ibanAccountPosition) {
					if (ibanBranchLength && ibanBranchPosition) {
						this.validatePosition(ibanBranchLength, ibanBranchPosition, ibanAccountPosition, CountryInfo.IBAN_ACCOUNT_POS);
					} else if (ibanBankLength) {
						this.validatePosition(ibanBankLength, ibanBankPosition, ibanAccountPosition, CountryInfo.IBAN_ACCOUNT_POS);
					}
				}
				this.setIBANExample();
				break;
			}
			case CountryInfo.IBAN_ACCOUNT_LEN: {
				const ibanAccountLength = +this.formMgr.get(CountryInfo.IBAN_ACCOUNT_LEN).getValue();
				if (ibanAccountLength) {
					this.validateIBANLength();
				}
				this.setIBANExample();
				break;
			}
		}
	}

	fgppBeforeView(): void {
		const digitType = this.formMgr.get(CountryInfo.CHK_DIGIT_TYPE).getValue();
		if (digitType === 'IBAN') {
			this.setIBANExample();
		} else {
			this.layoutEvent.hide(CountryInfo.IBAN_LABEL);
		}
	}

	fgppBeforeEdit(): void {
		const digitType = this.formMgr.get(CountryInfo.CHK_DIGIT_TYPE).getValue();
		this.checkDigitTypeValueChangeHandler(digitType);
		if (digitType === 'IBAN') {
			this.setIBANExample();
		} else {
			this.layoutEvent.hide(CountryInfo.IBAN_LABEL);
		}
	}

	fgppBeforeCreate(): void {
		const digitType = this.formMgr.get(CountryInfo.CHK_DIGIT_TYPE).getValue();
		this.layoutEvent.hide(CountryInfo.IBAN_LABEL);
		this.checkDigitTypeValueChangeHandler(digitType);
	}

	private validatePosition(currentFieldPosition: number, currentFieldLength: number, nextFieldPosition: number, nextFieldName: string): boolean {
		return this.ibanService.validatePosition(currentFieldPosition, currentFieldLength, nextFieldPosition, nextFieldName);
	}

	private validateIBANLength(): boolean {
		return this.ibanService.validateIBANLength();
	}

	private setIBANExample(): void {
		this.ibanService.setIBANExample();
	}

	fgppOnSave(): boolean {
		if (this.checkRequired()) {
			const ibanBankLength = +this.formMgr.get(CountryInfo.IBAN_BANK_ID_LEN).getValue();
			const ibanBankPosition = +this.formMgr.get(CountryInfo.IBAN_BANK_ID_POS).getValue();

			const ibanBranchLength = +this.formMgr.get(CountryInfo.IBAN_BRANCH_LEN).getValue();
			const ibanBranchPosition = +this.formMgr.get(CountryInfo.IBAN_BRANCH_POS).getValue();

			const ibanAccountLength = +this.formMgr.get(CountryInfo.IBAN_ACCOUNT_LEN).getValue();
			const ibanAccountPosition = +this.formMgr.get(CountryInfo.IBAN_ACCOUNT_POS).getValue();

			if (ibanBranchLength && ibanBranchPosition) {
				if (ibanBankLength && ibanBankPosition) {
					const valid = this.validatePosition(ibanBankLength, ibanBankPosition, ibanBranchPosition, CountryInfo.IBAN_BRANCH_POS);
					if (valid) {
						if (ibanAccountPosition) {
							return this.validatePosition(ibanBranchLength, ibanBranchPosition, ibanAccountPosition, CountryInfo.IBAN_ACCOUNT_POS);
						} else {
							return true;
						}
					} else {
						return false;
					}
				}
			} else if (ibanAccountLength && ibanAccountPosition) {
				return this.validatePosition(ibanBankLength, ibanBankPosition, ibanAccountPosition, CountryInfo.IBAN_ACCOUNT_POS);
			}
		}
		return true;
	}
}
