import {inject, Injectable} from "@angular/core";
import {Params, Router} from "@angular/router";
import {TaskBarService} from "../../../core/navigation/task-bar/services/task-bar.service";
import {SearchCondition} from "../../../shared/search/models/classes/search-condition.model";
import {Operators} from "../../../shared/search/models/enums/operators.enum";
import {SortDirection} from "@fgpp-ui/grid";
import {NavigationService} from "../../../core/navigation/services/navigation.service";

@Injectable()
export class BfMessageNavigationService {

  private router = inject(Router);
  private taskBarService: TaskBarService = inject(TaskBarService);
  private navigationService = inject(NavigationService)

  hideSideNavigationBar() {
    this.taskBarService.taskBarShowState = false;
  }

  navigateToTargetState(queryParams: Params, state?: { [k: string]: any }) {
    setTimeout(async () => {
      const currentUrl = this.router.url.split('?')[0];
      this.router.navigateByUrl(this.getReturnState(queryParams), {
        skipLocationChange: true,
        onSameUrlNavigation: 'reload'
      }).then(() => {
        this.router.navigate([currentUrl], {
          queryParams,
          state,
          onSameUrlNavigation: 'reload',
          skipLocationChange: false
        });
      });
    });
  }

  getReturnState(params: Params) {
    const url = this.router.url;
    const createMsgState = this.getReturnStateNameForCreateState(url, params);
    return createMsgState ? createMsgState : this.getReturnStateNameForSingle(url);
  }

  returnToPreviousRoute(params: Params) {
    const returnState = this.getReturnState(params);
    setTimeout(() => {
      this.router.navigate([returnState], {queryParams: {queueId: params.queueId}});
    }, 0);
  }

  getReturnStateNameForSingle(url: string) {
    const index = url.indexOf('single-msg-ops');
    if (index > -1) {
      return url.substring(1, index);
    } else {
      return null;
    }
  }

  getReturnStateNameForCreateState(url: string, params: Params) {
    if (url.indexOf('create-msg-ops') > -1) {
      let index;
      if (!params.queueId) {
        index = url.indexOf('/messages/');
      } else {
        index = url.indexOf('/create-msg-ops');
      }
      return url.substring(1, index);
    } else {
      return null;
    }
  }

  goToProcessingCommunications(uid: string): void {
    const navigationItem = this.navigationService.getNavigationItem("467");
    if (!navigationItem) {
      return;
    }
    const filter = new SearchCondition(false, 'MESSAGE_EXTERNAL_INTERACTION.MID', Operators.equal, [uid])
    const sort = {fieldName: 'MESSAGE_EXTERNAL_INTERACTION-TIME_STAMP', sortType: SortDirection.DESC};
    const path = navigationItem.routerLink;
    const queryParams = {
      ...navigationItem.stateParams,
      filters: JSON.stringify(filter),
      sort: JSON.stringify(sort)
    };
    this.router.navigate([path], {queryParams});
  }

}
