export enum ConditionOperator {
  EQAULS = '=',
  NON_EQUALS = '<>',
  GT = '>',
  LT = '<',
  GTE = '>=',
  LTE = '<=',
  LIKE = 'Like',
  NOT_LIKE = 'Not Like',
  IN = 'In',
  NOT_IN = 'Not In',
  IS = 'Is',
  IS_NOT = 'Is Not',
  BETWEEN = 'Between',
  CONTAINS = 'Contains',
  IN_OUT_FILE = 'In Out File',
  IN_GROUP_MANUAL_MATCH = 'In Group Manual Match',
  MANUAL_MATCH_OCCURRENCES = 'Manual Match Occurrences',
  SET_VAL_MULTI = 'setValMulti',
  DIVIDE = '/',
  MINUS = '-',
  PLUS = '+',
  MULTIPLY = '*',
  LIKE_VALUE_LIST = 'Like Value List',
  IN_VALUE_LIST = 'In Value List',
  DOES_NOT_CONTAIN = 'Does Not Contain',
  CONCATENATE = 'Concatenate',
  NOT_IN_VALUE_LIST = 'Not In Value List',
  SET_VAL_MULTI_NL = 'setValMultiNL',
  SET_VAL = 'setVal',
  REMOVE = 'remove',
  VOID = 'Void',
  STARTS_WITH = 'Starts with',
  NOT_STARTS_WITH = 'Not Starts With',
  DOES_NOT_START_WITH = 'Does not start with',
  NOT_IN_TEMPLATE_SET = 'Not In Template Set',
  IN_TEMPLATE_SET = 'In Template Set',
  ENDS_WITH = 'Ends with',
  DOES_NOT_END_WITH = 'Does not end with',
  CONTAINS_ID = 'ContainsId',
  NOT_LIKE_VALUE_LIST = 'Not Like Value List',
  IN_SYSTEM_LIST = 'In system list',
  NOT_IN_SYSTEM_LIST = 'Not in system list',
  IN_LIST = 'In list',
  NOT_IN_LIST = 'Not in list'
}
