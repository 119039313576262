<form [formGroup]="form" autocomplete="off" class="user-settings-change-password">
    <app-dh-input id="inputPassword"  [label]="'my_profile.current_password' | translate"
        formControlName="currentPassword" [type]="'password'"></app-dh-input>
    <app-dh-input id="newPassword"  [label]="'my_profile.new_password' | translate" formControlName="newPassword"
                  [type]="'text'" [isPasswordField]="true">
    </app-dh-input>
    <app-password-policy [form]="form"></app-password-policy>
    <ng-container *ngIf="alerts.length > 0" id="alert">
      <div class="dh-alert-notification {{alert.type}}" *ngFor="let alert of alerts" (click)="closeAlerts()">
        {{alert.msg | translate}}</div>
    </ng-container>
    <div class="actions">
        <app-fn-ui-button id="change-password-cancel" (buttonClick)="cancel()" color="primary">{{'general.cancel' | translate}}
        </app-fn-ui-button>
        <app-fn-ui-button id="change-password-ok" (buttonClick)="changePassword()" color="primary" type="flat"
            [disabled]="form.disabled || !form.valid">{{'general.changePassword' | translate}}
        </app-fn-ui-button>
    </div>
</form>
