import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HintService {

  static DRILL_DOWN_VALUE_DOES_NOT_EXISTS = 'drillDownValueDoesNotExist';
  static DRILL_DOWN_MULTI_VALUE = 'drillDownMultiValues';

  constructor() { }

   getHint( errorCode: String): String {
    let hint;
    switch (errorCode) {
      case '95036':
        hint = HintService.DRILL_DOWN_MULTI_VALUE;
      break;
    case '60067':
      default:
      hint = HintService.DRILL_DOWN_VALUE_DOES_NOT_EXISTS;
    }
    return hint;
  }
}
