import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MdePopoverModule } from '@material-extended/mde';
import { FnUiPopoverComponent } from './fn-ui-popover.component';


@NgModule({
  declarations: [
    FnUiPopoverComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatDividerModule,
    MdePopoverModule
  ],
  exports: [
    FnUiPopoverComponent
  ]
})
export class FnUiPopoverModule { }
