import { Component, OnInit } from '@angular/core';
import {
  AbstractControlComponent, BaseControlData, BFEvents, ComponentManagerService, FormService,
  ModalFormValueChange, MultiLineComponentTemplate, MultiLineFormModalComponent
} from '@fgpp-ui/components';
import { filter, map } from 'rxjs';
import { UniqueIdFormat } from '../../../../models/unique-id-format.model';
import { FormatFieldName } from './format-field-name.enum';
import { FormatFields } from './format-fields.const';

@Component({
  selector: 'app-field-format-form-modal',
  template: MultiLineComponentTemplate.HTML_TEMPLATE,
  styles: [MultiLineComponentTemplate.STYLE_TEMPLATE],
  providers: [
    FormService,
    ComponentManagerService
  ]
})
export class FieldFormatFormModalComponent extends MultiLineFormModalComponent implements OnInit {

  private _fieldName: FormatFieldName | '' = '';

  ngOnInit(): void {
    super.ngOnInit();
    this.formService.getEventBus().on(BFEvents.Type.CANVAS)
      .pipe(filter((status: BFEvents.CanvasEvent) => status.value.state === 'ready'))
      .pipe(map(() => this.form.get(UniqueIdFormat.SUBSET_FLD_NM).value))
      .pipe(filter((fieldName: FormatFieldName | '') => !!fieldName))
      .subscribe((fieldName: FormatFieldName | '') => {
        const fieldValue = this.form.get(UniqueIdFormat.SUBSET_FLD_NM_VAL).value;
        this.form.get(FormatFields[fieldName]).setValue(fieldValue);
        this.onFieldNameChange(fieldName);
      });
  }

  protected valueChanges(change: ModalFormValueChange): void {
    switch (change.control) {
      case UniqueIdFormat.SUBSET_FLD_NM:
        this.onFieldNameChange(change.value);
        break;
      case UniqueIdFormat.V_SUBSET_FLD_NM_VAL_BUSINESS_DATE:
      case UniqueIdFormat.V_SUBSET_FLD_NM_VAL_BUSINESS_UNIT:
      case UniqueIdFormat.V_SUBSET_FLD_NM_VAL_SEQUENCE:
        this.onVirtualValueChange(change.value);
        break;
    }
  }

  private onFieldNameChange(fieldName: FormatFieldName | ''): void {
    if (fieldName !== null) {
      this.formMgr.get(FormatFields[this._fieldName]).reset().hide().markAsOptional();
      this.formMgr.get(FormatFields[fieldName]).show().markAsRequired();
      this._fieldName = fieldName;
    } else {
      this._fieldName = '';
    }
  }

  private onVirtualValueChange(value: string): void {
    this.form.get(UniqueIdFormat.SUBSET_FLD_NM_VAL).setValue(value);
  }

}

