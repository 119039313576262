import { ChangeDetectionStrategy, Component, Injectable, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FaFormService } from './services/fa-form.service';

@Component({
  selector: 'app-fa-form',
  templateUrl: './fa-form.component.html',
  styleUrls: ['./fa-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Injectable({ providedIn: 'root' })
export class FaFormComponent {
  selectControls;

  @Input() formGroup: FormGroup;
  @Input() metadata = {};

  constructor(private formBuilder: FormBuilder,
              private faFormService: FaFormService) {
    this.selectControls = new Map();
  }

  buildForm(data) {
    return this.formBuilder.group(this.faFormService.getControlsConfig(data));
  }

  getFormData(controlName) {
    return this.metadata[controlName]; // object if exists undefined otherwise
  }

  addValidator(validator, controlName) {
    const control = this.formGroup.get(controlName);
    const controlMetadata = this.metadata[controlName];
    this.faFormService.addValidator(validator, control, controlMetadata);
  }

  removeValidator(validator, controlName, clearValidationErrors = false) {
    const control = this.formGroup.get(controlName);
    const controlMetadata = this.metadata[controlName];
    if (clearValidationErrors) {
      control.setErrors(null);
    }
    this.faFormService.removeValidator(validator, control, controlMetadata);
  }

}
