import { Action, State, Selector, StateContext } from '@ngxs/store';
import { SetButtonsSettingsMessages } from './actions/set-buttons-settings-messages.actions';
import { ButtonsSettingsStateModel } from './models/buttons-settings-state.model';
import { Injectable } from '@angular/core';


@State<ButtonsSettingsStateModel>({
name: 'buttons',
  defaults: {
    messages: {
      disableButtons: false
    }
  }
})

@Injectable()
export class ButtonsSettingsState {

  @Selector()
  static getDisableButtonsForMessages(state: ButtonsSettingsStateModel): boolean {
    return state.messages.disableButtons;
  }

  @Action(SetButtonsSettingsMessages)
  SetButtonsSettingsForMessages(ctx: StateContext<ButtonsSettingsStateModel>, { payload }: SetButtonsSettingsMessages) {
    ctx.patchState({
      messages: payload
    });
  }
}
