
<div data-ui-view="navigation" [hidden]="isStandAlone" class="ft-pagination-container">
  <app-header></app-header>
</div>

<div class="dh-main-container" [ngClass]="{'isStandAlone': isStandAlone}">

  <router-outlet></router-outlet>

  <app-alert-sidebar></app-alert-sidebar>
</div>

<app-keyboard-shortcuts></app-keyboard-shortcuts>

