import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FaFormComponent } from './fa-form/fa-form.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRadioModule } from '@angular/material/radio';
import { DhSelectComponent } from './dh-select/dh-select.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DhTextareaComponent } from './dh-textarea/dh-textarea.component';
import { DhPanelComponent } from './dh-panel/dh-panel.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { DhDrilldownComponent } from './dh-drilldown/dh-drilldown.component';
import { DhDatepickerComponent } from './dh-datepicker/dh-datepicker.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { DhTimepickerComponent } from './dh-timepicker/dh-timepicker.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { DhMenuButtonComponent } from './dh-mat-menu-button/dh-menu-button.component';
import { DhInputselectComponent } from './dh-inputselect/dh-inputselect.component';
import { DrillDownSelectComponent } from './drill-down-select/drill-down-select.component';
import { TranslateModule } from '@ngx-translate/core';
import { AddNoteModalComponent } from './add-note-modal/add-note-modal.component';
import { LockedModalComponent } from './locked-modal/locked-modal.component';
import { DhTableComponent } from './dh-table/dh-table.component';
import { DhSelectSimpleComponent } from './dh-select-simple/dh-select-simple.component';
import { DhInputSimpleComponent } from './dh-input-simple/dh-input-simple.component';
import { DhValidationErrorsMessageComponent } from './validation-errors-message/validation-errors-message.component';
import { DhTimepickerSimpleComponent } from './dh-timepicker-simple/dh-timepicker-simple.component';
import { TimeService } from './services/time.service';
import { DhOrderingDetailsComponent } from './dh-ordering-details/dh-ordering-details.component';
import { DhBeneficiaryCustomerDetailsComponent } from './dh-beneficiary-customer-details/dh-beneficiary-customer-details.component';
import { DhSelectInputSimpleComponent } from './dh-select-input-simple/dh-select-input-simple.component';
import { DhInputComponent } from './dh-input/dh-input.component';
import { ConfirmationModalComponent } from '../forms/components/confirmation-modal/confirmation-modal.component';
import { NoteModalComponent } from '../forms/components/note-modal/note-modal.component';
import { DynamicComponentLoaderModule } from './dynamic-component-loader/dynamic-component-loader.module';
import { FaListModule } from './fa-list/fa-list.module';
import { MissingAcknowledgeItemsComponent } from './missing-acknowledge-items/missing-acknowledge-items.component';
import { FaDialogComponent } from './fa-dialog/fa-dialog.component';
import { DhNumericInputComponent } from './dh-numeric-input/dh-numeric-input.component';
import { DhSelectSimpleInputComponent } from './dh-select-simple-input/dh-select-simple-input.component';
import { HintValidationComponent } from './hint-validation/hint-validation.component';
import { HintErrorMessageComponent } from './hint-error-message/hint-error-message.component';
import { MatListModule } from '@angular/material/list';
import { FnUiDialogModule } from '../shared/fn-ui-dialog/fn-ui-dialog.module';
import { FnUiIconModule } from '../shared/fn-ui-icons/fn-ui-icon/fn-ui-icon.module';
import { FnUiToastComponent } from '../shared/fn-ui-toast/fn-ui-toast.component';
import { FnUiNotificationModule } from '../shared/fn-ui-notification/fn-ui-notification.module';
import { ToursModule } from '../tours/tours.module';
import { DhCheckboxComponent } from './dh-checkbox/dh-checkbox.component';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatRadioModule,
    MatMenuModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatExpansionModule,
    MatGridListModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatSnackBarModule,
    MatTableModule,
    MatSortModule,
    MatProgressSpinnerModule,
    FaListModule,
    DynamicComponentLoaderModule,
    TranslateModule.forChild(),
    MatListModule,
    FnUiDialogModule,
    FnUiIconModule,
    FnUiNotificationModule,
    ToursModule
  ],
  declarations: [
    DhInputComponent,
    FaFormComponent,
    DhSelectComponent,
    DhTextareaComponent,
    DhPanelComponent,
    DhDrilldownComponent,
    DhDatepickerComponent,
    DhTimepickerComponent,
    DhMenuButtonComponent,
    DhInputselectComponent,
    DrillDownSelectComponent,
    AddNoteModalComponent,
    LockedModalComponent,
    DhTableComponent,
    DhSelectSimpleComponent,
    DhInputSimpleComponent,
    DhOrderingDetailsComponent,
    DhValidationErrorsMessageComponent,
    DhTimepickerSimpleComponent,
    DhSelectInputSimpleComponent,
    DhBeneficiaryCustomerDetailsComponent,
    ConfirmationModalComponent,
    NoteModalComponent,
    MissingAcknowledgeItemsComponent,
    FaDialogComponent,
    DhNumericInputComponent,
    DhSelectSimpleInputComponent,
    HintValidationComponent,
    HintErrorMessageComponent,
    HintErrorMessageComponent,
    DhCheckboxComponent,
    FnUiToastComponent
  ],
  providers: [
    TimeService
  ],
  exports: [
    FaFormComponent,
    DhInputComponent,
    DhSelectComponent,
    DhTextareaComponent,
    DhPanelComponent,
    DhDrilldownComponent,
    DhDatepickerComponent,
    DhTimepickerComponent,
    DhMenuButtonComponent,
    DhInputselectComponent,
    DrillDownSelectComponent,
    DhTableComponent,
    MatMenuModule,
    DhSelectSimpleComponent,
    DhCheckboxComponent,
    DhInputSimpleComponent,
    DhOrderingDetailsComponent,
    DhValidationErrorsMessageComponent,
    DhTimepickerSimpleComponent,
    DhSelectInputSimpleComponent,
    DhBeneficiaryCustomerDetailsComponent,
    ConfirmationModalComponent,
    NoteModalComponent,
    MissingAcknowledgeItemsComponent,
    FaDialogComponent,
    DhNumericInputComponent,
    DhSelectSimpleInputComponent,
    HintValidationComponent,
    HintErrorMessageComponent
  ]
})

export class UiComponentsModule {
}
