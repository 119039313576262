import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Entity } from '../../shared/models/enums/entity.enum';
import { EntityRecentSearchesFactory, ENTITY_RECENT_SEARCHES_DEPS, ENTITY_RECENT_SEARCHES_TOKEN } from './services/entity-recent-searches.service';
import { RecentSearchActionsService } from './services/recent-search-actions.service';
import { RecentSearchMapperService } from './services/recent-search-mapper.service';
import { RecentSearchesApiService } from './services/recent-searches-api.service';
import { RecentSearchesManagerService } from './services/recent-searches-manager.service';
import { RecentSearchesTreeBuilderService } from './services/recent-searches-tree-builder.service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    TranslateModule.forChild()
  ],
  providers: [
    {
      provide: ENTITY_RECENT_SEARCHES_TOKEN,
      multi: true,
      useFactory: EntityRecentSearchesFactory(Entity.MESSAGES),
      deps: ENTITY_RECENT_SEARCHES_DEPS
    },
    {
      provide: ENTITY_RECENT_SEARCHES_TOKEN,
      multi: true,
      useFactory: EntityRecentSearchesFactory(Entity.FILES),
      deps: ENTITY_RECENT_SEARCHES_DEPS
    },
    {
      provide: ENTITY_RECENT_SEARCHES_TOKEN,
      multi: true,
      useFactory: EntityRecentSearchesFactory(Entity.BULKS),
      deps: ENTITY_RECENT_SEARCHES_DEPS
    },
    {
      provide: ENTITY_RECENT_SEARCHES_TOKEN,
      multi: true,
      useFactory: EntityRecentSearchesFactory(Entity.BATCHES),
      deps: ENTITY_RECENT_SEARCHES_DEPS
    },
    RecentSearchActionsService,
    RecentSearchMapperService,
    RecentSearchesApiService,
    RecentSearchesManagerService,
    RecentSearchesTreeBuilderService
  ]
})
export class RecentSearchesModule { }
