<lib-file-attachment-form
    [fileAttachmentUploaderConfig]="fileAttachmentConfig.fileUploaderConfiguration"
    [userId]="fileAttachmentConfig.fileAttachmentManagerConfig.userId"
    [permissions]="permissions"
    [texts] = "texts"
    (isUploadingInProgress)="setIsUploadInProgress($event)"
    (formStatusChanged)="formStatusChanged($event)"
    (uploadedFileDetails)="uploadedFile($event)">
</lib-file-attachment-form>

<lib-file-attachments-list
    [permissions]="permissions"
    [fileAttachmentsList]="fileAttachmentsList"
    [fileStatus]="fileStatus"
    [texts] = "texts"
    [dateFormatParser] = "dateFormatParser"
    [showLoader]="isUploadInProgress"
    [loaderComponent]="loaderComponent"
    (downloadOnClick)="downloadClicked($event)"
    (clearOnClick)="clearClicked($event)">
</lib-file-attachments-list>

