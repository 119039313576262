import {Component, Injector, OnInit} from '@angular/core';
import {ComponentManagerService, FormService} from '@fgpp-ui/components';
import {BFProfileOnSave} from '../../../core/interfaces/sdk-profile-hooks.interface';
import {TransferMethodsService} from './services/transfer-methods.service';
import {TaskBarService} from '../../../../core/navigation/task-bar/services/task-bar.service';
import {AbstractProfileFormComponent} from "../../abstract/abstract-profile/abstract-profile-form.component";
import {FileAttachmentApiService} from "../../../../shared/attachments/file-attachment-dialog/services/file-attachment-api.service";
import {FileAttachmentDialogService} from "../../../../shared/attachments/file-attachment-dialog/services/file-attachment-dialog.service";

@Component({
  selector: 'app-transfer-methods',
  templateUrl: '../../../core/templates/profile-form.template.html',
  styleUrls: ['../../../core/templates/profile-form.template.scss'],
  providers: [
    TaskBarService,
    FormService,
    ComponentManagerService,
    FileAttachmentApiService,
    FileAttachmentDialogService,
    TransferMethodsService
  ]
})
export class TransferMethodsComponent extends AbstractProfileFormComponent implements OnInit, BFProfileOnSave {

  constructor(protected injector: Injector, private transferMethodsService: TransferMethodsService) {
    super(injector);
  }

  fgppOnSave(): boolean {
    return this.transferMethodsService.validateRequiredFields();
  }
}
