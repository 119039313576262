import {Component, OnInit} from '@angular/core';
import {CallerAssociatedAccount} from '../../models/caller-associated-account.model';
import { ProfileFormTemplateProviders } from '../../../core/templates/profile-form.template.providers';
import {AbstractProfileFormComponent} from "../../abstract/abstract-profile/abstract-profile-form.component";
import {
  BFBeforeProfileCreate,
  BFBeforeProfileEdit, BFBeforeProfileView, BFOnInit,
  BFOnValueChange
} from "../../../core/interfaces/sdk-profile-hooks.interface";
import {IValueChange} from "../../../core/interfaces/form-value-change.interface";
import {DrilldownComponent, FilterBuilder, TokenUtilsService} from "@fgpp-ui/components";

@Component({
  selector: 'app-caller-associated-account',
  templateUrl: '../../../core/templates/profile-form.template.html',
  styleUrls: ['../../../core/templates/profile-form.template.scss'],
  providers: ProfileFormTemplateProviders
})
export class CallerAssociatedAccountComponent extends AbstractProfileFormComponent  implements  BFOnInit, BFOnValueChange, BFBeforeProfileCreate, BFBeforeProfileEdit, BFBeforeProfileView {


  fgppOnInit(): void {
    this.setPrefetchDrilldownList(CallerAssociatedAccount.CALLERS_UID);
  }

  fgppBeforeCreate(): void {
    this.formMgr.get(CallerAssociatedAccount.CURRENCY).setValue(this.formMgr.get('BASE_CURRENCY').getValue());
  }

  fgppBeforeEdit(): void {
    const callerDetails = this.getDrilldownData(CallerAssociatedAccount.CALLERS_UID);
    this.toggleFieldSettings(callerDetails);
    this.updateAccountsURL(callerDetails);
  }

  fgppBeforeView(): void {
    const callerDetails = this.getDrilldownData(CallerAssociatedAccount.CALLERS_UID);
    if(callerDetails && callerDetails['CALLERS-SUPER_CALLER_IND'] === '1') {
      this.formMgr.get(CallerAssociatedAccount.COMPANY).markAsOptional();
    }
  }

  fgppValueChanges(change: IValueChange): void {
    switch (change.control) {
      case CallerAssociatedAccount.CALLERS_UID : {
        const callerDetails = this.getDrilldownData(CallerAssociatedAccount.CALLERS_UID);
        this.populateCallerDetails(callerDetails);
        this.toggleFieldSettings(callerDetails);
        this.updateAccountsURL(callerDetails);
        this.formMgr.get(CallerAssociatedAccount.ACC_NO).reset();
        break;
      }

      case CallerAssociatedAccount.ACC_NO : {
        const accountDetails = this.getDrilldownData(CallerAssociatedAccount.ACC_NO);
        this.populateCallerAccountDetails(accountDetails);
      }
    }
  }

  private populateCallerAccountDetails(accountDetails: any) {
    if(accountDetails){
      this.formMgr.get(CallerAssociatedAccount.ACCOUNT_UID).setValue(accountDetails['VIRTUAL.UID']);
    }
  }

  private populateCallerDetails(callerDetails: any) {
    if(callerDetails){
      this.formMgr.get(CallerAssociatedAccount.COMPANY).setValue(callerDetails['CALLERS-CUST_CODE']);
      this.formMgr.get(CallerAssociatedAccount.COMPANY_NAME).setValue(callerDetails['CUSTOMRS-CUST_NAME']);
    }
  }

  private updateAccountsURL(callerDetails: any) {
    if(callerDetails && callerDetails['CALLERS-SUPER_CALLER_IND'] === '1') {

      const accountsProfileId = '28';
      const accountsDataKey = 'ACCOUNTS-ACC_NO';

      const filterBuilder = new FilterBuilder();
      const tokenUtils: any = new TokenUtilsService();

      filterBuilder.add('ACCOUNTS-OFFICE', this.formMgr.get(CallerAssociatedAccount.OFFICE).getValue());
      filterBuilder.add('ACCOUNTS-REC_STATUS', 'AC');

      const filters = filterBuilder.getFilters();
      const tokens = filterBuilder.getTokens();

      const queryParams = tokenUtils.getQueryString(tokenUtils.parse(filters, tokens));
      const accountField = this.formMgr.get(CallerAssociatedAccount.ACC_NO).getLayoutControl<DrilldownComponent>();
      accountField.set('filters', '');
      accountField.set('selectList', `/ui/profiles/${accountsProfileId}${queryParams}`).set('dataKey', accountsDataKey);
    } else {
      const accountField = this.formMgr.get(CallerAssociatedAccount.ACC_NO).getLayoutControl<DrilldownComponent>();
      accountField.set('selectList', `/ui/profiles/288/selectListData`).set('dataKey', 'ACCOUNTS.ACC_NO');
      accountField.set('filters', {
        "CALLER_ASSOCIATED_ACCOUNT-OFFICE": "${CALLER_ASSOCIATED_ACCOUNT-OFFICE}",
        "selectListFilter": "ACCOUNTS.OFFICE@V@CALLER_ASSOCIATED_ACCOUNT.OFFICE;ACCOUNTS.CUST_CODE@V@CALLER_ASSOCIATED_ACCOUNT.COMPANY;CALLERS.CALLER_ID@V@CALLER_ASSOCIATED_ACCOUNT.CALLERS_UID;ACCOUNTS.ACC_NO@V@ACCOUNTS.ACC_NO",
        "CALLER_ASSOCIATED_ACCOUNT-CALLERS_UID": "${CALLER_ASSOCIATED_ACCOUNT-CALLERS_UID}",
        "CALLER_ASSOCIATED_ACCOUNT-COMPANY": "${CALLER_ASSOCIATED_ACCOUNT-COMPANY}",
        "ACCOUNTS-ACC_NO": "%",
        "fieldId": "CALLER_ASSOCIATED_ACCOUNT.ACCOUNT_UID"
      });
      accountField.resolveTokens('CALLER_ASSOCIATED_ACCOUNT-COMPANY', callerDetails['CALLERS-CUST_CODE']);
    }
  }

  private toggleFieldSettings(callerDetails: any) {
    if(callerDetails && callerDetails['CALLERS-SUPER_CALLER_IND'] === '1') {
      this.formMgr.get(CallerAssociatedAccount.COMPANY).markAsOptional();
      this.formMgr.get(CallerAssociatedAccount.ACC_NO).enable();
    } else {
      this.formMgr.get(CallerAssociatedAccount.ACC_NO).enable();
      this.formMgr.get(CallerAssociatedAccount.COMPANY).markAsRequired();
    }
  }
}
