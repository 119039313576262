import { ProfileButtons } from '../../profiles/enums/profile-buttons.enum';
import { ButtonType } from '../enums/button-type.enum';

export class FgppButtonProps {
  buttonId!: ProfileButtons | string;
  position!: number;
  buttonType!: ButtonType;
  action!: string;

  constructor(buttonId: ProfileButtons | string, buttonType: ButtonType = ButtonType.MENU, position: number = 0, action: string = null) {
    this.buttonId = buttonId;
    this.position = position;
    this.buttonType = buttonType;
    this.action = action;
  }
}
