<app-dh-panel [title]="'mp.single.details.general_details' | translate"
              [previewTemplate]="previewTemplate"
              [contentTemplate]="contentTemplate"
              [disabled]="ruleStatuses.isReadOnly || !isEditable || !ruleStatuses.isWritable"
              [expanded]="!ruleCommonService.show.auditTrail"
              [showContentInDom]="true"
              (opened)="onPanelOpen()"
              (afterExpanded)="afterPanelExpand()"
              (afterCollapsed)="afterPanelCollapse()">
</app-dh-panel>

<ng-template #previewTemplate>
  <div class="rule-preview">
    <span *ngIf="rule.RULE_NAME" class="rule-field-preview">
      <label class="dh-form-component-header">{{ 'PRULES-RULE_NAME' | translate }}</label>
      <label class="dh-form-field-value">{{ rule.RULE_NAME }}</label>
    </span>
    <span *ngIf="rule.RULE_TYPE_NAME" class="rule-field-preview">
      <label class="dh-form-component-header">{{ 'rules.details.action_details_title' | translate }}</label>
      <label class="dh-form-field-value">{{ rule.RULE_TYPE_NAME }}</label>
    </span>
    <span *ngIf="rule.RULE_SUB_TYPE" class="rule-field-preview">
      <label class="dh-form-component-header">{{ 'PRULES-RULE_SUB_TYPE' | translate }}</label>
      <label class="dh-form-field-value">{{ rule.RULE_SUB_TYPE }}</label>
    </span>
    <span *ngIf="ruleActionType && ruleActionType !== 'SET'" class="rule-field-preview">
      <label class="dh-form-component-header">{{ 'rules.details.action.label' | translate }}</label>
      <label class="dh-form-field-value">{{ ruleActionType }}</label>
    </span>
    <span *ngIf="rule.RULE_ACTION && showRuleAction" class="rule-field-preview">
      <label class="dh-form-component-header">{{ rule.actionDetails.actionLabel | translate }}</label>
      <label class="dh-form-field-value">{{ rule.RULE_ACTION }}</label>
    </span>
    <span *ngIf="rule.SEC_RULE_ACTION" class="rule-field-preview">
      <label class="dh-form-component-header">{{ 'rules.details.actionUsage' | translate }}</label>
      <label class="dh-form-field-value">{{ rule.SEC_RULE_ACTION }}</label>
    </span>
    <span *ngIf="rule.DEPARTMENT" class="rule-field-preview">
      <label class="dh-form-component-header">{{ 'PRULES-DEPARTMENT' | translate }}</label>
      <label class="dh-form-field-value">{{ rule.DEPARTMENT }}</label>
    </span>
    <span *ngIf="rule.EXPIRY_DATE" class="rule-field-preview">
      <label class="dh-form-component-header">{{ 'PRULES-EXPIRY_DATE' | translate }}</label>
      <label class="dh-form-field-value">{{ rule.EXPIRY_DATE | ftDateTime }}</label>
    </span>
  </div>
</ng-template>

<ng-template #contentTemplate>
  <div class="general-details">
    <div class="row">

      <div class="col-xs-3" automation-id="rule-office-container">
        <label class="dh-form-component-header">{{ 'PRULES-OFFICE' | translate }}</label>
        <label class="dh-form-field-value ft-ellipsis">{{ rule.OFFICE }}</label>
      </div>

      <app-tour-item *ngIf="!ruleCommonService.modalVisible" [tourId]="'rulesScreen'" [tourStepNumber]="0"></app-tour-item>

      <div class="col-xs-3" automation-id="rule-department-container" azure-id="department">

        <app-fn-ui-autocomplete id="rule-content-department"
                                name="rule-content-department"
                                [(ngModel)]="rule.DEPARTMENT"
                                [autoSelect]="true"
                                [options]="departmentOptions"
                                [label]="'PRULES-DEPARTMENT' | translate"
                                [disableFreeText]="true"
                                [required]="true">
        </app-fn-ui-autocomplete>
      </div>

      <div class="col-xs-3" automation-id="rule-change-status-container">
        <label class="dh-form-component-header">{{ 'PRULES-PROFILE_CHANGE_STATUS' | translate }}</label>
        <label class="dh-form-field-value ft-ellipsis">{{ rule.PROFILE_CHANGE_STATUS }}</label>
      </div>

      <div class="col-xs-3" automation-id="rule-status-container">
        <label class="dh-form-component-header">{{ 'PRULES-REC_STATUS' | translate }}</label>
        <label class="dh-form-field-value ft-ellipsis">{{ rule.REC_STATUS }}</label>
      </div>
    </div>

    <div class="row">

      <div class="col-xs-3" automation-id="rule-type-name-container">
        <div id="rule-content-rule-type">
          <app-fn-ui-drill-down [name]="'PRULE_TYPES-RULE_TYPE_NAME'"
                                [dhDrillDown]="ruleTypesProfileId"
                                [inputValue]="rule.RULE_TYPE_NAME"
                                [inputLabel]="'PRULE_TYPES-RULE_TYPE_NAME' | translate"
                                [filterParameters]="inParamsRuleTypeFilter"
                                [preventMultiSelect]="true"
                                [readOnly]="ruleStatuses.isEditMode"
                                [required]="true"
                                (rowSelected)="onRuleTypeSelected($event.selectedRows)">
          </app-fn-ui-drill-down>
        </div>
      </div>

      <div class="col-xs-3" automation-id="rule-name-container" >
        <label class="dh-form-component-header required_input-label">{{ 'PRULES-RULE_NAME' | translate }}</label>
        <input azure-id="rule-name"
               name="rule-name"
               [(ngModel)]="rule.RULE_NAME"
               [disabled]="ruleStatuses.isEditMode"
               type="text"
               class="dh-input-field"
               maxlength="50"
               required
               appRestrictSpecialCharacters>
      </div>

      <div class="col-xs-3" *ngIf="showRuleSubType" automation-id="rule-sub-type-name-container">
        <div id="rule-content-rule-sub-type">
          <app-fn-ui-drill-down [name]="'RULE_SUB_TYPE'"
                                [dhDrillDown]="rule.subType.profileId"
                                [inputValue]="rule.RULE_SUB_TYPE"
                                [inputLabel]="'RULE_SUB_TYPE' | translate"
                                [filterParameters]="inParamsSubTypeFilter"
                                [preventMultiSelect]="true"
                                [readOnly]="ruleStatuses.isEditMode"
                                [entity]="'rules'"
                                [required]="true"
                                (rowSelected)="onSubTypeSelect($event.selectedRows)">
          </app-fn-ui-drill-down>
        </div>
      </div>
      <div class="col-xs-3" >
        <div id="rule-content-layer-classification" automation-id="rule-applicable-for-container">
          <app-fn-ui-drill-down [name]="'LAYER_CLASSIFICATION'"
                                [dhDrillDown]="layerClassificationProfileId"
                                [inputValue]="rule.LAYER_CLASSIFICATION_ALIAS"
                                [inputLabel]="'rule.LAYER_CLASSIFICATION' | translate"
                                [filterParameters]="inParamsLayerClassificationFilter"
                                [preventMultiSelect]="true"
                                [readOnly]="ruleStatuses.isEditMode || disableAssociationDetails || disableFormFields || disableLayerClassification"
                                [required]="true"
                                (rowSelected)="onLayerClassificationSelected($event.selectedRows)">
          </app-fn-ui-drill-down>
        </div>
        <div class="dh-field-hint allowed-levels">
          {{ 'rules.details.layer_classification.allowed_levels' | translate }}
          {{ rule.CLASSIFICATION_LEVEL }}
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12" automation-id="rule-description-container">
        <label class="dh-form-component-header required_input-label">{{ 'PRULES-DESCRIPTION' | translate }}</label>
        <input azure-id="rule-description"
               class="dh-input-field"
               [(ngModel)]="rule.DESCRIPTION"
               [ngClass]="{ 'field-changed':  rule.CHANGED_FIELDS?.DESCRIPTION }"
               [disabled]="metadata['PRULES-DESCRIPTION'].isReadOnly"
               type="text"
               required
               maxlength="200">
      </div>
    </div>

    <div class="row">
      <div class="col-xs-3" automation-id="rule-effective-date-container">
        <app-fn-ui-datetime-picker *ngIf="shouldRenderDatePicker"
                                   [(ngModel)]="rule.EFFECTIVE_DATE"
                                   [label]="'PRULES-EFFECTIVE_DATE' | translate"
                                   [minDate]="MIN_EFFECTIVE_DATE"
                                   [disabled]="!(isRuleInCreateMode || isInitiator || isChangeStatusFU)"
                                   [ngClass]="{ 'field-changed': rule.CHANGED_FIELDS?.EFFECTIVE_DATE }">
        </app-fn-ui-datetime-picker>
      </div>
      <div class="col-xs-3" automation-id="rule-expiry-date-container">
        <app-fn-ui-datetime-picker *ngIf="shouldRenderDatePicker"
                                   [(ngModel)]="rule.EXPIRY_DATE"
                                   [label]="'PRULES-EXPIRY_DATE' | translate"
                                   [minDate]="MIN_EXPIRY_DATE"
                                   [disabled]="isDisabledExpiryDate"
                                   [ngClass]="{ 'field-changed': rule.CHANGED_FIELDS?.EXPIRY_DATE }">
        </app-fn-ui-datetime-picker>
      </div>
    </div>

    <div class="action-details-title">{{ 'rules.details.action_details_title' | translate }}</div>

    <div class="row">

      <div class="col-xs-3 action-details-section" azure-id="action-details" automation-id="rule-action-details-container">
        <app-fn-ui-autocomplete id="rule-content-rule-action-details"
                                name="rule-content-rule-action-details"
                                [(ngModel)]="ruleActionType"
                                [options]="actionTypeOptions"
                                [label]="ruleActionTypeTitle | translate"
                                [disabled]="disableActionType"
                                [disableFreeText]="true"
                                (selectionChanged)="onRuleActionTypeChange($event)">
        </app-fn-ui-autocomplete>
      </div>
      <div class="col-xs-3" *ngIf="showRuleAction">
        <div id="rule-content-rule-action">
          <app-fn-ui-drill-down [name]="'RULE_ACTION'"
                                [dhDrillDown]="ruleActionContentSource"
                                [inputValue]="rule.RULE_ACTION"
                                [inputLabel]="rule.actionDetails.actionLabel"
                                [filterParameters]="inParamsActionFilter"
                                [preventMultiSelect]="true"
                                [readOnly]="disableActions"
                                [entity]="'rules'"
                                [required]="true"
                                (rowSelected)="onActionSelect($event.selectedRows)">
          </app-fn-ui-drill-down>
        </div>
      </div>
      <div class="col-xs-3" *ngIf="showRuleSecAction">
        <div id="rule-content-rule-secondary-action">
          <app-fn-ui-drill-down [name]="'SEC_RULE_ACTION'"
                                [dhDrillDown]="rule.actionDetails.secActionContentSource"
                                [inputValue]="rule['SEC_RULE_ACTION']"
                                [inputLabel]="'rules.details.actionUsage' | translate"
                                [filterParameters]="inParamsSecActionFilter"
                                [preventMultiSelect]="true"
                                [readOnly]="isFirstActionMissing || disableActions"
                                [entity]="'rules'"
                                [required]="true"
                                (rowSelected)="onSecActionSelect($event.selectedRows)">
          </app-fn-ui-drill-down>
        </div>
      </div>

    </div>
  </div>
</ng-template>
