import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationService } from '@fgpp-ui/components';
import { TranslateService } from '@ngx-translate/core';
import { Observable, catchError, mergeMap, of, throwError } from 'rxjs';
import { PopupService } from '../../../core/services/popup.service';
import { GridSearchRequest } from '../../../grid/models/grid-search-request.model';
import { MessagesService } from '../../../message/services/messages.service';
import { Entity } from '../../../shared/models/enums/entity.enum';
import { SearchCondition } from '../../../shared/search/models/classes/search-condition.model';
import { Operators } from '../../../shared/search/models/enums/operators.enum';
import { AutoFeederResult } from '../../models/auto-feeder-result.model';
import { MessagesManagementService } from '../../services/messages-management.service';
import { AutoFeederMessage } from '../models/auto-feeder-message.model';
import { AutoFeederAction } from '../models/enums/auto-feeder-action.enum';
import { AutoFeederRejectReason } from '../models/enums/auto-feeder-reject-reason.enum';
import { AutoFeederType } from '../models/enums/auto-feeder-type.enum';
import { AutoFeederApiService } from './auto-feeder-api.service';
import { IAutoFeederService } from './interfaces/auto-feeder-service.interface';

@Injectable()
export class AutoFeederService implements IAutoFeederService {

    constructor(private autoFeederApiService: AutoFeederApiService,
                private messagesManagementService: MessagesManagementService,
                private messagesService: MessagesService,
                private notificationService: NotificationService,
                private translateService: TranslateService,
                private popupService: PopupService) { }

    runAutoFeeder(type: AutoFeederType): Observable<void> {
        const searchRequest = this.getSearchRequest(type);
        return this.nextMessage(type, searchRequest);
    }

    private getSearchRequest(type: AutoFeederType): GridSearchRequest {
        const searchRequest = this.messagesManagementService.getSearchRequest();
        if (type === AutoFeederType.SELECTED_AUTO_FEEDER) {
            const leftOperand = this.messagesService.getMidKey(Entity.MESSAGES);
            const rightOperand = this.messagesService.multiSelectedMessages;
            const selectedCondition = new SearchCondition(false, leftOperand, Operators.in, rightOperand);
            searchRequest.searchQuery.searchCriteria = selectedCondition;
        }
        return searchRequest;
    }

    private nextMessage(type: AutoFeederType, searchRequest: GridSearchRequest, midToSkip: string = null): Observable<void> {
      return this.getNextMessage(type, searchRequest, midToSkip).pipe(mergeMap(
        (message: AutoFeederMessage) => {
          return this.onNextMessageSuccess(message, type, searchRequest);
        }),
        catchError((error) => {
          return this.onNextMessageFailure(error);
        })
      );
    }

    private getNextMessage(type: AutoFeederType, searchRequest: GridSearchRequest, midToSkip: string): Observable<AutoFeederMessage> {
      return this.autoFeederApiService.nextMessage(type, midToSkip, searchRequest).pipe(mergeMap(
        (response: AutoFeederMessage): Observable<AutoFeederMessage> => {
          if (response.P_MID === midToSkip) {
            const isMessageOpen = this.popupService.isWindowAlreadyOpen({ txtMID: response.P_MID });
            const reason = isMessageOpen ? AutoFeederRejectReason.FOCUS : AutoFeederRejectReason.EXIT;
            return throwError(reason);
          } else {
            return of(response);
          }
        })
      );
    }

    private onNextMessageSuccess(message: AutoFeederMessage, type: AutoFeederType, searchRequest: GridSearchRequest, isOld = false): Observable<void> {
      return this.openMessage(message, isOld).pipe(mergeMap(
        (event: AutoFeederResult): Observable<any> => {
          const action = event?.action || AutoFeederAction.CLOSE;
          switch (action) {
            case AutoFeederAction.NEXT:
              return this.nextMessage(type, searchRequest, message.P_MID);
            case AutoFeederAction.CLOSE:
              return this.reset();
            case AutoFeederAction.QUICK_TO_OLD:
              const windowId = this.popupService.getWindowByMID(message.P_MID);
              if (windowId !== null) {
                this.popupService.close(windowId);
                return this.onNextMessageSuccess(message, type, searchRequest, true);
              }
              return of(null);
            default:
              return this.nextMessage(type, searchRequest);
          }
        })
      );
    }

    private onNextMessageFailure(reason: HttpErrorResponse | AutoFeederRejectReason): Observable<void> {
      if (reason !== AutoFeederRejectReason.EXIT) {
          this.notificationService.warning(this.translateService.instant('messages_center.auto_feeder_failed_to_fetch'));
          return this.reset();
      }
      return of(null);
  }

    private openMessage(message: AutoFeederMessage, isOld = false): Observable<AutoFeederResult> {
        return this.messagesService.openAutoFeederMessage(message, isOld);
    }

    private reset(): Observable<void> {
        return this.autoFeederApiService.reset();
    }

}
