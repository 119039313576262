import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { ProfilesApiService } from './profiles-api.service';
import { GridColumnsService } from './../../grid/services/grid-columns.service';
import { GridHelperService } from './../../grid/services/grid-helper.service';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { ProfilesSearchRequest } from '../models/profiles-search-request.type';
import { GridSearchRequest } from '../../grid/models/grid-search-request.model';
import { GridApiRoutes } from '../../grid/models/enums/grid-api.routes';

@Injectable()
export class ProfilesHelperService extends GridHelperService {

  constructor(
    private profilesApiService: ProfilesApiService,
    gridColumnsService: GridColumnsService,
    http: HttpClient,
    translateService: TranslateService) {

    super(profilesApiService, gridColumnsService, http, translateService);
  }

  getDataForInnerGrid(searchRequest: ProfilesSearchRequest, fileId, tab, messageType) {
    return this.profilesApiService.getInnerGridData(searchRequest, fileId, tab, messageType)
      .pipe(map((response: any[]) => {
        const columns = this.gridColumnsService.getColumns(response[1], `${GridApiRoutes.do}/${messageType}/${fileId}/${tab}/`);
        const searchResponse = response[0];
        return this.prepareDataObj(searchResponse, columns.gridColumns, columns.availableColumns);
      }));
  }

  search<T extends GridSearchRequest>(searchRequest: T, baseUrl): Promise<any> {
    return this.profilesApiService.getSearch(searchRequest, baseUrl);
  }

}
