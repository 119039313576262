import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ROUTES_MAPPING_MESSAGES_CENTER } from '../../../core/navigation/models/consts/routes-mapping.const';
import { MessagesQueue } from '../../../messages/models/messages-queue.model';
import { CallerCallbackApiService } from '../../components/messages-center-callback/services/caller-callback-api.service';
import { FavoritesTree } from '../../favorites/models/favorite-tree.model';
import { FavoritesManagerService } from '../../favorites/services/favorites-manager.service';
import { MessagesCenterNavigationService } from '../../messages-center-navigation/services/messages-center-navigation.service';
import { RecentSearchesTree } from '../../recent-searches/models/recent-searches-tree.model';
import { RecentSearchesManagerService } from '../../recent-searches/services/recent-searches-manager.service';

export type MessagesCenterTree = MessagesQueue | FavoritesTree | RecentSearchesTree;

export const MessagesCenterTreeResolver: ResolveFn<any> =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): MessagesCenterTree | Promise<MessagesCenterTree> | Observable<MessagesCenterTree> => {

    const messagesCenterNavigationService = inject(MessagesCenterNavigationService);
    const favoritesManagerService = inject(FavoritesManagerService);
    const recentSearchesManagerService = inject(RecentSearchesManagerService);
    const callerCallbackApiService = inject(CallerCallbackApiService);

    const taskBarItem = route.params.taskBarItem;

    switch (taskBarItem) {
      case ROUTES_MAPPING_MESSAGES_CENTER['system-queues']:
        const systemQueuesTree = messagesCenterNavigationService.initSystemQueuesTree();
        messagesCenterNavigationService.updateSystemQueuesTree();
        return systemQueuesTree;

      case ROUTES_MAPPING_MESSAGES_CENTER['custom-queues']:
        const customQueuesTree = messagesCenterNavigationService.initCustomQueuesTree();
        return messagesCenterNavigationService.updateCustomQueuesTree().then(() => customQueuesTree);

      case ROUTES_MAPPING_MESSAGES_CENTER.favorites:
        return favoritesManagerService.getTree();

      case ROUTES_MAPPING_MESSAGES_CENTER['recent-searches']:
        return recentSearchesManagerService.getTree();

      case ROUTES_MAPPING_MESSAGES_CENTER.callback:
        return callerCallbackApiService.getCallerTree().then((callerTree) => {
          return messagesCenterNavigationService.initCallbackQueuesTree(callerTree);
        });

      case ROUTES_MAPPING_MESSAGES_CENTER.templates:
        const templatesQueuesTree = messagesCenterNavigationService.initTemplatesQueuesTree();
        return messagesCenterNavigationService.updateTemplatesQueuesTree().then(() => templatesQueuesTree);
    }
  };
