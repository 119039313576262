export const CallerAssociatedAccount  = {
	"VERIFY_LIMIT": "CALLER_ASSOCIATED_ACCOUNT-VERIFY_LIMIT",
	"VERIFY_OWN_CREATE_IND": "CALLER_ASSOCIATED_ACCOUNT-VERIFY_OWN_CREATE_IND",
	"CALLBACK_MIN_AMT": "CALLER_ASSOCIATED_ACCOUNT-CALLBACK_MIN_AMT",
	"CALLBACK_REQ_IND": "CALLER_ASSOCIATED_ACCOUNT-CALLBACK_REQ_IND",
	"CALLERS_UID": "CALLER_ASSOCIATED_ACCOUNT-CALLERS_UID",
	"CALLER_ID": "CALLERS-CALLER_ID",
	"COMPANY": "CALLER_ASSOCIATED_ACCOUNT-COMPANY",
	"COMPANY_NAME": "CALLER_ASSOCIATED_ACCOUNT-COMPANY_NAME",
	"CREATE_LIMIT": "CALLER_ASSOCIATED_ACCOUNT-CREATE_LIMIT",
	"CURRENCY": "CALLER_ASSOCIATED_ACCOUNT-CURRENCY",
	"CUST_CODE": "CALLERS-CUST_CODE",
	"DEPARTMENT": "CALLER_ASSOCIATED_ACCOUNT-DEPARTMENT",
	"EFFECTIVE_DATE": "EFFECTIVE_DATE",
	"ACCOUNT_UID": "CALLER_ASSOCIATED_ACCOUNT-ACCOUNT_UID",
	"ACC_NO": "ACCOUNTS-ACC_NO",
	"CUST_NAME": "CUSTOMRS-CUST_NAME",
	"OFFICE": "CALLER_ASSOCIATED_ACCOUNT-OFFICE",
	"PROFILE_CHANGE_STATUS": "CALLER_ASSOCIATED_ACCOUNT-PROFILE_CHANGE_STATUS",
	"REC_STATUS": "CALLER_ASSOCIATED_ACCOUNT-REC_STATUS",
	"TIME_STAMP": "TIME_STAMP",
	"UID": "VIRTUAL-UID",
	"PROFILE_UPDATE_PK": "PROFILE_UPDATE_PK",
	"PU_TIME_STAMP": "PU_TIME_STAMP",
	"NOTE": "NOTE"
};
