import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

@Injectable()
export class StoreService {

  constructor(private store: Store) {
  }

  dispatch(action: any): Observable<any> {
    return this.store.dispatch(action);
  }
}
