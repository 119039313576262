import { Component, ViewChild, ElementRef, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FileUploader, FileItem, FileLikeObject } from 'ng2-file-upload';
import { StepStaus } from '../../stepper/enum/step-stauts.enum';
import { StepStyle } from '../../stepper/enum/step-style.enum';
import { StepForm } from '../models/step-form.model';
import { FileStatus } from './models/enums/file-status.enum';
import { FileAttachmentError } from './models/enums/file-attachment-error.enum';
import { FileUploaderConfiguration } from './models/file-uploader-configuration.model';
import { FileAttachment } from '../../models/file-attachment.model';
import { FileAttachmentPermissionMode } from '../../file-attachment-manager/models/enums/file-attachment-permission-mode.enum';
import { FileAttachmentFormTexts } from './models/file-attachment-form-texts.model';
import { FileUploadComponent } from '../file-upload/file-upload.component';


@Component({
  selector: 'lib-file-attachment-form',
  templateUrl: './file-attachment-form.component.html',
  styleUrls: ['./file-attachment-form.component.scss']
})
export class FileAttachmentFormComponent implements OnInit {

  @ViewChild('description') descriptionStep: ElementRef;
  @ViewChild('fileUpload') fileUpload: FileUploadComponent;

  @Input() fileAttachmentUploaderConfig: FileUploaderConfiguration;
  @Input() permissions: FileAttachmentPermissionMode;
  @Input() userId: string;
  @Input() texts: FileAttachmentFormTexts;
  @Output() uploadedFileDetails: EventEmitter<FileAttachment> = new EventEmitter<FileAttachment>();
  @Output() formStatusChanged: EventEmitter<FileStatus> = new EventEmitter<FileStatus>();
  @Output() isUploadingInProgress: EventEmitter<boolean> = new EventEmitter<boolean>();


  public uploader: FileUploader;
  public description: string;
  public stepFileUpload: StepForm;
  public stepDescription: StepForm;
  public response: string;
  public file: FileItem;
  public attachments = [];
  public fileError: FileAttachmentError;
  public errorText = ' ';

  StepStaus = StepStaus;
  FileAttachmentError = FileAttachmentError;
  FileAttachmentPermissionMode = FileAttachmentPermissionMode;
  fileStatus: FileStatus;

  constructor() { }

  ngOnInit(): void {
    this.initUploader();
    this.initFormSteps();
  }

  public setDescriptionComplite(value) {
    if (value !== '') {
      this.stepDescription.status = StepStaus.COMPLETE;
    }
  }

  public setFileToUpload(file: FileLikeObject) {
    if (this.isFileHasErrors(file)) {
      return;
    }
    this.clearErrorAndDiscription();
    this.stepFileUpload.status = StepStaus.COMPLETE;
    this.stepDescription.status = StepStaus.ACTIVE;
    this.fileStatus = FileStatus.SELECTED;
    this.formStatusChanged.emit(this.fileStatus);
    this.focusOnDescription();
  }

  private clearErrorAndDiscription() {
    this.fileError = null;
    this.descriptionStep.nativeElement.value = '';
    this.errorText = ' ';
  }

  private focusOnDescription() {
    setTimeout(() => {
      this.descriptionStep.nativeElement.focus();
    }, 0);
  }

  public uploadFile() {
    this.isUploadingInProgress.emit(true);
    this.description = this.descriptionStep.nativeElement.value;
    this.setFormDateParams();
    this.stepDescription.status = StepStaus.COMPLETE;
    this.file = this.uploader.queue[0];
    this.uploader.uploadItem(this.file);
  }

  protected setFormDateParams() {
    this.uploader.options.additionalParameter = {
      description: this.description,
    };
  }

  protected initFormSteps() {
    this.stepFileUpload = {
      title: 1,
      status: this.getFileUploadStepStatus(),
      style: StepStyle.DASHED
    };

    this.stepDescription = {
      title: 2,
      status: StepStaus.DISABLE
    };

    this.fileStatus = FileStatus.EMPTY;
  }

  protected getFileUploadStepStatus(): StepStaus {
    return this.permissions !== FileAttachmentPermissionMode.READ ? StepStaus.ACTIVE : StepStaus.DISABLE;
  }

  protected initUploader() {
    this.uploader = new FileUploader({
      url: this.fileAttachmentUploaderConfig.uploadFileApi,
      removeAfterUpload: true,
      disableMultipart: false, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
      formatDataFunctionIsAsync: false,
      allowedMimeType: this.fileAttachmentUploaderConfig.contentType,
      maxFileSize: this.fileAttachmentUploaderConfig.maxFileSizeInByte,
      headers: this.fileAttachmentUploaderConfig.requestHeaders
    });

    this.uploader.onBeforeUploadItem = (fileItem: any) => {
      fileItem.withCredentials = false;
    };

    this.uploader.response.subscribe(res => this.updateFileList(res));

    this.uploader.onAfterAddingFile = f => {
      if (this.uploader.queue.length > 1) {
        this.uploader.queue.splice(0, 1);
      }
    };
  }

  protected updateFileList(res) {
    try {
      const fileInfo = JSON.parse(res);
      this.attachments.push(fileInfo);
      this.initFormSteps();
      this.descriptionStep.nativeElement.value = '';
      this.fileStatus = FileStatus.UPLOADED;
      this.formStatusChanged.emit(this.fileStatus);
      this.uploadedFileDetails.emit(fileInfo);
      this.isUploadingInProgress.emit(false);
      this.fileUpload.emptyInputFile();
    } catch {
      this.stepFileUpload.status = StepStaus.ACTIVE;
      this.stepDescription.status = StepStaus.DISABLE;
      this.fileStatus = FileStatus.SELECTED;
      this.isUploadingInProgress.emit(false);
    }
  }

  private isFileHasErrors(file: FileLikeObject) {
    if (this.fileAttachmentUploaderConfig.contentType.indexOf(file.type) === -1) {
      this.fileError = FileAttachmentError.FILETYPE;
      this.errorText = this.texts.typeErrorText;
      return true;
    } else {
      if (file.size > this.fileAttachmentUploaderConfig.maxFileSizeInByte) {
        this.fileError = FileAttachmentError.FILESIZE;
        this.errorText = this.texts.sizeErrorText;
        return true;
      }
    }
    return false;
  }

}
