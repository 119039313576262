<div class="dh-main-left-container">

  <!-- Side  bar  (for tree)  -->
  <app-tour-item *ngIf="!taskBarService.taskBarState.isLandingPage"
                 [tourId]="'navigationPane'"
                 [tourStepNumber]="0">
  </app-tour-item>

  <!-- Task bar header -->
  <div class="dh-taskbar-header">
    <div azure-id="toggle-taskbar"
         class="toggle-sidebar clickable"
         [ngClass]="!taskBarService.taskBarState.showSideBar ? 'ft-left-side-toggle pull-right' : 'hide-panel'"
         (click)="toggleSideBar()">
      <app-fn-ui-icon [icon]="!taskBarService.taskBarState.showSideBar ? 'chevron_right' : 'chevron_left'"
                      fontweight="900">
      </app-fn-ui-icon>
      <div class="dh-hide-panel-text-label" [hidden]="!taskBarService.taskBarState.showSideBar">
        <span class="dh-text-label">{{'main_window.hide_panel' | translate}}</span>
      </div>
    </div>

    <div *ngIf="showTaskBarSettings" [hidden]="!taskBarService.taskBarShowState" class="item" style="display: inline">
      <app-task-bar-settings></app-task-bar-settings>
    </div>
  </div>

  <div class="dh-taskbar-content">

    <!-- Task bar items -->
    <app-tour-item *ngIf="!taskBarService.taskBarState.isLandingPage"
                   [tourId]="'navigationPane'"
                   [tourStepNumber]="1">
    </app-tour-item>

    <app-side-navigation [items]="taskBar.items"
                         [isOpen]="taskBarService.taskBarState.showSideBar"
                         (itemClicked)="onItemClick()">
    </app-side-navigation>

    <!-- Queues pane -->
    <app-tour-item *ngIf="!taskBarService.taskBarState.isLandingPage"
                   [tourId]="'navigationPane'"
                   [tourStepNumber]="2">
    </app-tour-item>

    <div [hidden]="!taskBarService.taskBarShowState" class="ft-tree scrollable-content">
      <app-dynamic-component-loader [component]="taskBarComponent"></app-dynamic-component-loader>
    </div>

  </div>

</div>
