import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SideNavigationComponent } from './components/side-navigation/side-navigation.component';
import { ARCHIVE_TOKEN, INSIGHT_TOKEN, LIQUIDITY_TOKEN, MESSAGES_CENTER_TOKEN, PROFILES_TOKEN, RULES_TOKEN } from './models/task-bar-handler-tokens.const';
import { TaskBarService } from './services/task-bar.service';
import { InsightTaskBarHandlerService } from './services/task-bar-handlers/insight-task-bar-handler.service';
import { ProfilesTaskBarHandlerService } from './services/task-bar-handlers/profiles-task-bar.service';
import { MessageCentertTaskBarHandlerService } from './services/task-bar-handlers/message-center-task-bar-handler.service';
import { LiquidityTaskBarHandlerService } from './services/task-bar-handlers/liquidity-task-bar-handler.service';
import { FnUiIconModule } from '../../../shared/fn-ui-icons/fn-ui-icon/fn-ui-icon.module';
import { MessagesModule } from '../../../messages/messages.module';
import { TaskBarSettingsComponent } from './components/task-bar-settings/task-bar-settings.component';
import { ArchiveTaskBarHandlerService } from './services/task-bar-handlers/archive-task-bar-handler.service';

@NgModule({
    declarations: [SideNavigationComponent, TaskBarSettingsComponent],
  imports: [
    MatListModule,
    CommonModule,
    FnUiIconModule,
    RouterModule,
    TranslateModule.forChild(),
    MessagesModule
  ],
  exports: [
    SideNavigationComponent,
    TaskBarSettingsComponent
  ],
    providers: [
        TaskBarService,
        { provide: INSIGHT_TOKEN, useClass: InsightTaskBarHandlerService },
        { provide: LIQUIDITY_TOKEN, useClass: LiquidityTaskBarHandlerService },
        { provide: MESSAGES_CENTER_TOKEN, useClass: MessageCentertTaskBarHandlerService },
        { provide: PROFILES_TOKEN, useClass: ProfilesTaskBarHandlerService },
        { provide: RULES_TOKEN, useClass: ProfilesTaskBarHandlerService },
        { provide: ARCHIVE_TOKEN, useClass: ArchiveTaskBarHandlerService },
    ]

})

export class TaskBarModule {

}
