<div class="ft-top-grid-action" [ngClass]="{'ft-top-grid-action-matching-mode': isMatchingMode}">
  <div id="pull-left" class="pull-left" [ngClass]="{'pull-left-matching-mode': isMatchingMode}" *ngIf="gridHeaderConfig.showLeftMenu">

    <div class="toggle-favorites-container">
      <app-fn-ui-favorite-icon *ngIf="gridHeaderConfig.showFavorite"
                               class="toggle-favorites-grid"
                               [isFavorite]="isFavorite"
                               (click)="toggleFavorites()">
      </app-fn-ui-favorite-icon>
      <app-tour-item *ngIf="displayTour && gridHeaderConfig.showFavorite" [tourId]="'gridHeaderAddToFavorites'" [tourStepNumber]="0"></app-tour-item>

    </div>

    <div class="dh-payment-type-name" *ngIf="gridHeaderConfig.showType">
      <span>{{_entity}}:</span>
    </div>

    <div class="top-grid-action-section-title paymnet-screen" *ngIf="gridHeaderConfig.showTitle">
      <span class="queue-title" [title]="_queueTitle | translate">{{_queueTitle | translate}} </span>
      <span class="dh-refresh-grid-text" *ngIf="gridHeaderConfig.showLastUpdate">
        <span class="last-update-separator" *ngIf ="_queueTitle">|</span>
        {{"main_window.last_tree_refresh" | translate}}:
        <span class="dh-refresh-grid-text-date"
              [ngClass]="{'updated-now': updatedNow}">{{ lastUpdate | date: dateTimeFormat }} </span>
        </span>
    </div>
  </div>

  <div automation-id="matching-mode" class="matching-mode" *ngIf="isMatchingMode">
    <span class="matching-mode-title" [translate]="'matching-mode.title'"></span>
    <app-fn-ui-close-icon (click)="onMatchingClick()"></app-fn-ui-close-icon>
  </div>

  <div id="pull-right" class="pull-right right-hand-side" [ngClass]="{'pull-right-matching-mode': isMatchingMode}" *ngIf="gridHeaderConfig.showRightMenu">

    <div id="refresh" class="btn-group refresh-grid-container" *ngIf="gridHeaderConfig.showRefresh">
      <div class="dh-dropdown-button">
        <app-fn-ui-refresh-icon class="refresh-grid"
                                disableMS="10000"
                                (refreshClicked)="onRefreshClick()">
        </app-fn-ui-refresh-icon>
        <div *ngIf="displayTour">
          <app-tour-item [tourId]="'materialGridRefresh'" [tourStepNumber]="0"></app-tour-item>
        </div>
      </div>
    </div>

    <div class="btn-group" *ngIf="gridHeaderConfig.processingScope">
      <div class="dh-dropdown-button">
        <button class="fgpp-btn processing-scope-btn" automation-id="processing_scope" color="primary" mat-button
                [matMenuTriggerFor]="processingScopesMenu">
          <app-fn-ui-icon icon="chevron_down"></app-fn-ui-icon>
          {{ selectedProcessingScopes | translate}}
        </button>
        <mat-menu #processingScopesMenu="matMenu">
          <button mat-menu-item *ngFor="let processingScope of _processingScopes; index as i"
          [attr.automation-id]="processingScope"
          (click)="onProcessingScopeChanged(processingScope)">
            {{processingScope | translate}}
          </button>
        </mat-menu>
      </div>
    </div>

    <div class="btn-group" *ngIf="gridHeaderConfig.showAutoFeeder">
      <div class="dh-dropdown-button">
        <button automation-id="auto_feeder"class="fgpp-btn" color="primary" mat-button [matMenuTriggerFor]="autoFeederMenu"
                [disabled]="callerCallbackVisibleState">
          <app-fn-ui-icon icon="chevron_down"></app-fn-ui-icon>
          {{"messages_center.auto_feeder" | translate}}
        </button>
        <mat-menu #autoFeederMenu="matMenu">
          <button mat-menu-item
                  *ngFor="let autoFeeder of autoFeederOptions"
                  (click)="onAutoFeederClicked(autoFeeder.type)"
                  [attr.automation-id]="autoFeeder.alias"
                  >
            {{ autoFeeder.alias | translate }}
          </button>
        </mat-menu>
      </div>
    </div>

    <div class="btn-group" *ngIf="gridHeaderConfig.showScopes">
      <div class="dh-dropdown-button">
        <button automation-id="status"  class="fgpp-btn" color="primary" mat-button [matMenuTriggerFor]="scopesMenu">
          <app-fn-ui-icon icon="chevron_down"></app-fn-ui-icon>
          {{ selectedScope.alias | translate}}
        </button>
        <mat-menu #scopesMenu="matMenu">
          <button [attr.azure-id]="eventType.GRIDEVENT" name="{{scope.alias }}" mat-menu-item *ngFor="let scope of scopes; index as i" (click)="onScopeChanged(i)">
            {{scope.alias | translate}}
          </button>
        </mat-menu>
      </div>
    </div>

    <div class="dh-dropdown-button" *ngIf="showActions">
      <button automation-id="actions" class="fgpp-btn" color="primary" mat-button [matMenuTriggerFor]="actionsMenu">
        <app-fn-ui-icon #chevron_downIcon icon="chevron_down"></app-fn-ui-icon>
        {{ "main_window.actions" | translate}}
        <span *ngIf="displayTour">
          <app-tour-item [tourId]="'materialGrid'" [tourStepNumber]="0" [targetAt]="chevron_downIcon"></app-tour-item>
        </span>
      </button>
      <mat-menu #actionsMenu="matMenu">
        <button *ngIf="showMatching" automation-id="matching_mode" [disabled]="totalCount==0" mat-menu-item class="matching-mode-menu-item"
                (click)="onMatchingClick()">{{"matching-mode.title" | translate}}</button>
        <button *ngIf="showColumnSettings" automation-id="column_settings" mat-menu-item class="column-settings-menu-item"
                (click)="onColumnSettingsClick()">{{"column_settings.button" | translate}}</button>
        <button *ngIf="showExport" automation-id="export" mat-menu-item class="export-menu-item"
                (click)="onExportClick()">{{"main_window.export" | translate}}</button>
        <button *ngIf="showPrint" automation-id="print" mat-menu-item class="print-menu-item"
                (click)="onPrintClick()">{{"button.print" | translate}}</button>
      </mat-menu>
    </div>

    <span [matTooltip]="(gridHeaderConfig.disableCreate ? 'button.tooltip.create' : '') | translate">
    <button mat-flat-button color="primary" *ngIf="gridHeaderConfig.showCreate"
            [disabled]="gridHeaderConfig.disableCreate"
            id="payment-create-btn"
            class="fgpp-btn create-btn"
            (click)="onCreate()">
      <span class="dh-text-label">{{'system_navigation.CreatePayment' | translate}}</span>
    </button>
  </span>

  </div>

</div>
