import { Injectable } from '@angular/core';
import { TreeConfig } from '@fgpp-ui/components';
import { Observable, map, tap } from 'rxjs';
import { LiquidityTaskBarItem, PartyGroupsTree, PartyNode, LiquidityNode, PartyGroupNode } from '../models';
import { ILiquidityTaskBarItemService } from './interfaces/liquidity-task-bar-item-service.interface';
import { LiquidityApiService } from './liquidity-api.service';

@Injectable()
export class PartyExplorerService implements ILiquidityTaskBarItemService {

  get taskBarItem(): LiquidityTaskBarItem {
    return LiquidityTaskBarItem.PARTY_EXPLORER;
  }

  get treeConfig(): Partial<TreeConfig> {
    return this._treeConfig;
  }

  get tree(): PartyGroupsTree {
    return this._tree;
  }

  get activeNode(): PartyNode {
    return this._activeNode;
  }

  set activeNode(value: PartyNode) {
    this._activeNode = value;
  }

  get lastUpdate(): Date {
    return this._lastUpdate;
  }

  private _treeConfig: Partial<TreeConfig>;
  private _tree: PartyGroupsTree;
  private _activeNode: PartyNode;
  private _lastUpdate: Date;

  constructor(private liquidityApiService: LiquidityApiService) {
    this.initTreeConfig();
  }

  getTree(): Observable<PartyGroupsTree> {
    return this.liquidityApiService.getPartyGroups().pipe(map((response: PartyGroupsTree) => {
      this.setRoutingData(response);
      this._lastUpdate = new Date();
      this._tree = response;
      return response;
    }));
  }

  refreshTree(): Observable<PartyGroupsTree> {
    return this.getTree().pipe(tap(() => {
      this.updateActiveNode();
    }));
  }

  findActiveNode(id: string): PartyNode {
    for (const partyGroupNode of this.tree.nodes) {
      for (const partyNode of partyGroupNode.nodes) {
        if (partyNode.id === id) {
          return partyNode;
        }
      }
    }
    return null;
  }

  private initTreeConfig(): void {
    this._treeConfig = {
      expandable: (node: LiquidityNode) => node.nodes?.length > 0,
      selectable: (node: LiquidityNode) => !node.nodes?.length,
      trackBy: (node) => node,
      expansionTrackBy: (node: LiquidityNode) => node.id
    };
  }

  private setRoutingData(tree: PartyGroupsTree): void {
    tree.nodes.forEach((partyGroupNode: PartyGroupNode) => {
      partyGroupNode.nodes.forEach((partyNode: PartyNode) => {
        partyNode.routerLink = `/home/liquidity/${this.taskBarItem}`;
        partyNode.stateName = 'home.liquidity.views.party-explorer.single';
        partyNode.stateParams = { account: partyNode.id };
      });
    });
  }

  private updateActiveNode(): void {
    if (this.activeNode) {
      this.activeNode = this.findActiveNode(this.activeNode.id);
    }
  }
}
