import { Component, OnInit, OnDestroy } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { AlertState } from '../../state/alert/alert.state';
import { SidebarState } from '../../state/sidebar/sidebar.state';
import { Alert } from '../../models/alert.model';
import { SidebarMode } from '../../../state/models/enums/sidebar-mode.enum';

@Component({
  selector: 'app-alert-sidebar',
  templateUrl: './alert-sidebar.component.html',
  styleUrls: ['./alert-sidebar.component.scss']
})
export class AlertSidebarComponent implements OnInit, OnDestroy {

  @Select(AlertState.getAlerts) alert$: Observable<Array<Alert>>;
  @Select(SidebarState.getSidebarMode) sidebarMode$: Observable<SidebarMode>;

  alerts: Array<Alert> = [];
  subscriber = new Subscription();
  sidebarMode: SidebarMode;
  isOpen = false;

  constructor() { }

  ngOnInit() {
    this.subscribeToAlerts();
    this.subscribeToSidebarMode();
  }

  ngOnDestroy() {
    this.subscriber.unsubscribe();
  }

  subscribeToAlerts() {
    const subscription = this.alert$.subscribe((alerts: Array<Alert>) => {
      this.alerts = alerts;
    });
    this.subscriber.add(subscription);
  }

  subscribeToSidebarMode() {
    const subscription = this.sidebarMode$.subscribe((mode: SidebarMode) => {
      this.sidebarMode = mode;
      if (mode === SidebarMode.OPEN) {
        this.openSidebar();
      } else {
        this.closeSidebar();
      }
    });
    this.subscriber.add(subscription);
  }

  private openSidebar() {
    this.isOpen = true;
  }

  private closeSidebar() {
    this.isOpen = false;
  }

}
