import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router, RouterStateSnapshot } from '@angular/router';
import { RuleCommonService } from '../../single-rule/services/rule-common.service';

export const RuleTypesResolver: ResolveFn<string> =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): string => {

    const router = inject(Router);
    const ruleCommonService = inject(RuleCommonService);

    const currentUrl = router.url;
    const urlTree = router.parseUrl(currentUrl);
    const ruleTypes = urlTree.queryParams.ruleType;
    ruleCommonService.ruleTypes = ruleTypes;
    ruleCommonService.isNeedCheckBeforeClose = true;
    return ruleTypes;
  };
