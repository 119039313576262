import { HttpInterceptor, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse, HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable, catchError, tap, throwError } from 'rxjs';
import { SignOutService } from '../app/authentication/services/sign-out.service';
import { AlertModalComponent } from '../app/shared/fn-ui-modals/components/alert-modal/alert-modal.component';
import { ModalsService } from '../app/shared/fn-ui-modals/services/modals.service';

@Injectable()
export class AvailabilityInterceptor implements HttpInterceptor {

  requestPrefixToMatch = 'do/';
  maxNoAvailabilityDelay = 30000;
  maxNotFoundErrorCount = 3;
  lastSuccessTimeStamp = new Date().getTime();
  errorCount = 0;
  noConnectionModalRef: MatDialogRef<AlertModalComponent>;

  constructor(private modalsService: ModalsService,
    private signOutService: SignOutService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next
      .handle(req)
      .pipe(
        tap((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.handleSuccess();
          }
        }),
        catchError(response => {
          if (response instanceof HttpErrorResponse) {

            if (this.isNotAvailable(req, response)) {
              this.handleNotAvailable();
            }
          }
          return throwError(response);
        }));
  }

  private handleSuccess(): void {
    this.errorCount = 0;
    this.lastSuccessTimeStamp = new Date().getTime();
  }

  private isNotAvailable(req, response): boolean {
    return (response.status === 0 && req.url.startsWith(this.requestPrefixToMatch));
  }

  private handleNotAvailable(): void {
    this.errorCount++;
    if (!this.noConnectionModalRef
      && (new Date().getTime() - this.lastSuccessTimeStamp > this.maxNoAvailabilityDelay)
      && this.errorCount > this.maxNotFoundErrorCount) {

      const data = {
        title: 'main_window.no_connection.title',
        message: 'main_window.no_connection.message',
        okButton: 'main_window.no_connection.ok_button',
        showCloseButton: false
      };

      this.noConnectionModalRef = this.modalsService.alert(data);

      this.noConnectionModalRef.afterClosed().subscribe((result) => {
        if (result) {
          this.signoutHandler();
        } else {
          this.closeHandler();
        }
      });
    }
  }

  private signoutHandler(): void {
    this.signOutService.signOut();
  }

  private closeHandler(): void {
    this.noConnectionModalRef = null;
  }

}
