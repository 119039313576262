import { Pipe, PipeTransform } from '@angular/core';
import { FormatsService } from '../shared/services/formats.service';


@Pipe({ name: 'ftNumber' })
export class FtNumberPipe implements PipeTransform {

  constructor(private formatsService: FormatsService) { }

  transform(value: any, thousandSeparator?: string, decimalSeparator?: string): string {
    if (isNaN(value) || value === null) {
      return '';
    }

    value = this.formatNumberValue(value, thousandSeparator, decimalSeparator);

    return value.toLocaleString();
  }


  formatNumberValue(value: any, thousandSeparator: string, decimalSeparator: string): string {
    thousandSeparator = thousandSeparator || this.formatsService.thousandSeparator;
    decimalSeparator = decimalSeparator || this.formatsService.decimalSeparator;

    return value.toString().replace('.', decimalSeparator).replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);
  }

}
