import { Component } from '@angular/core';
import { DatepickerComponent } from '@fgpp-ui/components';
import dayjs from 'dayjs';
import { MIN_MAX_DATE_FORMAT } from '../../../core/consts/general.const';
import { BFOnFormLoadStart } from '../../../core/interfaces/sdk-profile-hooks.interface';
import { ProfileFormTemplateProviders } from '../../../core/templates/profile-form.template.providers';
import { AbstractProfileFormComponent } from '../../abstract/abstract-profile/abstract-profile-form.component';
import { DNEStop } from '../../models/dne-stop.model';

@Component({
  selector: 'app-dne-stop',
  templateUrl: '../../../core/templates/profile-form.template.html',
  styleUrls: ['../../../core/templates/profile-form.template.scss'],
  providers: ProfileFormTemplateProviders
})
export class DNEStopComponent extends AbstractProfileFormComponent implements BFOnFormLoadStart {

  fgppFormLoadStart(): void {
    this.setDateOfDeathMaxDate();
  }

  private setDateOfDeathMaxDate(): void {
    const datepickerComponent = this.formMgr.get(DNEStop.DATE_OF_DEATH).getLayoutControl() as DatepickerComponent;
    const today = new Date().setHours(0, 0, 0);
    const tomorrow = new Date(today).setDate(new Date(today).getDate() + 1);
    const maxDate = dayjs(tomorrow).format(MIN_MAX_DATE_FORMAT);
    datepickerComponent.controlData.maxDate = maxDate;
    datepickerComponent.set('maximum', today);
  }
}
