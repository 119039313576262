<div class="rule-content" id="rule-content-inner-section">

  <app-rule-details [metadata]="metadata"
                    [rule]="rule"
                    [ruleStatuses]="ruleStatuses"
                    [isEditable]="isEditable"
                    (panelOpened)="closeAuditTrails()"
                    (afterPanelExpanded)="afterRuleDetailsToggle()"
                    (afterPanelCollapsed)="afterRuleDetailsToggle()">
  </app-rule-details>

  <div *ngIf="showConditions" class="condition-details-wrapper">
    <app-conditions-editor class="conditions-editor"
                           [conditionsData]="conditionsData"
                           [isReadOnly]="ruleStatuses.isReadOnly || !isEditable || !ruleStatuses.isWritable || ruleCommonService.show.auditTrail"
                           [isNewRule]="ruleStatuses.isCreate">
    </app-conditions-editor>
  </div>

  <div class="preview-rule-wrapper" [ngClass]="{ shown: ruleCommonService.show.previewRule }">
    <app-rule-preview [conditionsDataJson]="conditionsData | json"></app-rule-preview>
  </div>

  <div class="audit-trail-wrapper" [ngClass]="{ shown: ruleCommonService.show.auditTrail }">
    <app-audit-trail [ruleId]="rule.UID"></app-audit-trail>
  </div>

</div>
