import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { GppTreeComponent, TreeConfig } from '@fgpp-ui/components';
import { FnUiMenuComponent } from '../fn-ui-menu/fn-ui-menu.component';
import { FnUiMenuItemClickEvent } from '../fn-ui-menu/models/fn-ui-menu-item-click-event.model';
import { FnUiNavigationTreeComponent } from '../fn-ui-navigation-tree/fn-ui-navigation-tree.component';
import { NavigationNode } from '../fn-ui-navigation-tree/models/navigation-node.model';
import { NodeActionClickEvent } from './models/node-action-click-event.model';
import { NodeAction } from './models/node-action.model';

@Component({
  selector: 'app-fn-ui-navigation-actions-tree',
  templateUrl: './fn-ui-navigation-actions-tree.component.html',
  styleUrls: ['./fn-ui-navigation-actions-tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FnUiNavigationActionsTreeComponent<T extends NavigationNode> {

  @Input() treeConfig: Partial<TreeConfig>;
  @Input() treeModel: Array<T>;
  @Input() selectedNode: T;
  @Input() nodeActions: Array<NodeAction>;
  @Input() leafActions: Array<NodeAction>;

  @Output() selectionChanged = new EventEmitter<T>();
  @Output() nodeActionClicked = new EventEmitter<NodeActionClickEvent<T>>();

  @ViewChild(FnUiNavigationTreeComponent, { static: true }) navigationTreeComponent: FnUiNavigationTreeComponent<any>;

  openedMenuComponent: FnUiMenuComponent;

  get treeComponent(): GppTreeComponent<T> {
    return this.navigationTreeComponent.treeComponent;
  }

  onSelectionChange($event: T): void {
    this.selectionChanged.emit($event);
  }

  onMenuTriggerClick($event: MouseEvent): void {
    $event.stopPropagation();
    $event.preventDefault();
  }

  onMenuItemClick($event: FnUiMenuItemClickEvent, node: T): void {
    const value = {
      node: node,
      action: $event.item as NodeAction,
      $event: $event.$event
    };
    this.nodeActionClicked.emit(value);
  }

  onMenuOpen(menuComponent: FnUiMenuComponent): void {
    if (this.openedMenuComponent) {
      this.openedMenuComponent.closeMenu();
    }
    this.openedMenuComponent = menuComponent;
  }

  onMenuClose(menuComponent: FnUiMenuComponent): void {
    this.openedMenuComponent = null;
  }

}
