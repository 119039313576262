import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AzureInsightsModule } from './azure-insight/azure-insights.module';
import { MultiTabsInitializerService } from './base-view/services/multi-tabs-initializer.service';
import { CoreModule } from './core/core.module';
import { SessionSyncService } from './core/services/session-sync.service';
import { FormsService } from './forms/services/forms.service';
import { MessageDisclaimerModule } from './message/message-disclaimer/message-disclaimer.module';
import { FxProviderModule } from './message/messageTypes/fx-provider/fx-provider.module';
import { MessagesService } from './message/services/messages.service';
import { MessagesCenterModule } from './messages-center/messages-center.module';
import { MessagesManagementService } from './messages/services/messages-management.service';
import { FnUiButtonModule } from './shared/fn-ui-button/fn-ui-button.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateLoaderFactory } from './core/translations/services/translation-loader.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxsModule } from '@ngxs/store';
import { DefaultsInterceptor } from '../services/defaultsInterceptor.service';
import { AvailabilityInterceptor } from '../services/availabilityInterceptor.service';
import { AuthenticationModule } from './authentication/authentication.module';
import { BusinessFrameworkModule } from './business-framework/business-framework.module';
import { AlertState } from './core/alerts/state/alert/alert.state';
import { SidebarState } from './core/alerts/state/sidebar/sidebar.state';
import { ApiCachingModule } from './core/api-caching/api-caching.module';
import { KeyboardShortcutsState } from './core/keyboard-shortcuts/store/states/keyboard-shortcuts.state';
import { ButtonsSettingsState } from './core/state/buttons-settings.state';
import { CallerAuthenticationState } from './core/state/caller-authentication.state';
import { LiquidityState } from './core/state/liquidity.state';
import { MessagesCenterQueueState } from './core/state/messages-center-queue.state';
import { SideNavigationState } from './core/state/side-navigation.state';
import { SystemVariablesState } from './core/state/system-variables.state';
import { UserPreferencesState } from './core/state/user-preferences.state';
import { FeatureFlagsState } from './core/store/feature-flags/feature-flags.state';
import { SessionState } from './core/store/session/state/session.state';
import { CdtrAcctIbanState } from './core/state/cdtr-acct-iban.state';
import { SettlementCurrencyState } from './core/state/settlement-currency.state';
import { TranslationsModule } from './core/translations/translations.module';
import { LiquidityModule } from './liquidity/liquidity.module';
import { MessagesQueuesStateService } from './messages/services/messages-queues-state.service';
import { FtDateTimePipe } from './pipes/ftDateTime.pipe';
import { ProfilesModule } from './profiles/profiles.module';
import { RulesModule } from './rules/rules.module';
import { BrowsersService } from './services/browsers.service';
import { DataServiceService } from './services/data-service.service';
import { FieldVerificationsService } from './services/field-verifications.service';
import { NotesService } from './services/notes.service';
import { PopOutHelperService } from './services/pop-out-helper.service';
import { PopOutService } from './services/pop-out.service';
import { SpecialItemsService } from './services/special-items.service';
import { XmlJsonParserService } from './services/xml-json-parser.service';
import { FileAttachmentDialogModule } from './shared/attachments/file-attachment-dialog/file-attachment-dialog.module';
import { UiComponentsModule } from './ui-components/ui-components.module';
import { AppConfigService } from './config/services/app-config.service';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { BaseViewModule } from './base-view/base-view.module';
import { SearchConditionService } from './shared/search/services/search-condition.service';
import { SearchExpressionService } from './shared/search/services/search-expression.service';
import { SEARCH_CONDITION, SEARCH_EXPRESSION } from './shared/search/models/consts/search-criteria-tokens.const';
import { TaskBarLayoutModule } from './task-bar-layout/task-bar-layout.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { GPPFrontEndService } from './core/services/gpp-frontend.service';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { SystemVariablesService } from './core/services/system-variables/system-variables.service';
import { InsightModule } from './insight/insight.module';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { AppArchiveModule } from './archive/archive.module';

dayjs.extend(utc);
dayjs.extend(customParseFormat);
window['dayjs'] = dayjs;

export function AppConfigFactory(injector: Injector) {
  async function asyncInitializer(): Promise<void> {
    await injector.get(AppConfigService).initialize();
  }
  return (asyncInitializer);
}

export function SystemVariablesFactory(injector: Injector) {
  async function asyncInitializer(): Promise<void> {
    await injector.get(SystemVariablesService).getSystemVariables();
  }
  return (asyncInitializer);
}

export function SessionSyncFactory(injector: Injector) {
  async function asyncInitializer(): Promise<void> {
    await injector.get(SessionSyncService).init();
  }
  return (asyncInitializer);
}

export function MultiTabFactory(injector: Injector) {
  async function asyncInitializer(): Promise<void> {
    await injector.get(MultiTabsInitializerService).isAuthenticated();
  }
  return (asyncInitializer);
}
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserAnimationsModule,
    TranslationsModule,
    AppRoutingModule,
    BaseViewModule,
    BrowserModule,
    CoreModule,
    FnUiButtonModule,
    UiComponentsModule,
    AuthenticationModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (TranslateLoaderFactory),
        deps: [Injector]
      }
    }),
    ProfilesModule,
    NgxsModule.forRoot([
      AlertState,
      SidebarState,
      UserPreferencesState,
      SystemVariablesState,
      KeyboardShortcutsState,
      ButtonsSettingsState,
      SideNavigationState,
      LiquidityState,
      MessagesCenterQueueState,
      CallerAuthenticationState,
      SessionState,
      FeatureFlagsState,
      CdtrAcctIbanState,
      SettlementCurrencyState
    ]
    ),
    NgxsLoggerPluginModule.forRoot(
      { disabled: true }
    ),
    ProfilesModule,
    InsightModule,
    FileAttachmentDialogModule,
    TaskBarLayoutModule,
    MessagesCenterModule,
    LiquidityModule,
    RulesModule,
    AngularSvgIconModule.forRoot(),
    BusinessFrameworkModule,
    ApiCachingModule,
    HttpClientModule,
    AppArchiveModule,
    AzureInsightsModule.forRoot({
      disableAjaxTracking: true,
      disableExceptionTracking: true
    }),
    // AppArchiveModule
    MessageDisclaimerModule,
    FxProviderModule
  ],
  providers: [
    FormsService,
    MessagesManagementService,
    MessagesService,
    DataServiceService,
    NotesService,
    PopOutService,
    SpecialItemsService,
    FtDateTimePipe,
    MessagesQueuesStateService,
    BrowsersService,
    PopOutHelperService,
    FieldVerificationsService,
    { provide: HTTP_INTERCEPTORS, useClass: DefaultsInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AvailabilityInterceptor, multi: true },
    SpecialItemsService,
    XmlJsonParserService,
    { provide: SEARCH_CONDITION, useClass: SearchConditionService },
    { provide: SEARCH_EXPRESSION, useClass: SearchExpressionService },
    { provide: APP_INITIALIZER, useFactory: AppConfigFactory, deps: [Injector], multi: true },
    { provide: APP_INITIALIZER, useFactory: SystemVariablesFactory, deps: [Injector], multi: true },
    { provide: APP_INITIALIZER, useFactory: SessionSyncFactory, deps: [Injector], multi: true },
    { provide: APP_INITIALIZER, useFactory: MultiTabFactory, deps: [Injector], multi: true },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { hideRequiredMarker: true } }
  ],
  bootstrap: [AppComponent]
})

export class AppModule {

  constructor(gppFrontEndService: GPPFrontEndService) {

    window['GPPFrontEnd'] = () => gppFrontEndService;
  }

}
