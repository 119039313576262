import { inject } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { NavigationService } from '../../../core/navigation/services/navigation.service';

export const IsSingularProfileResolver: ResolveFn<boolean> =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {

    const navigationService = inject(NavigationService);

    const navigationItem = navigationService.getNavigationItem(route.params.profileId);
    const action = navigationItem.data.action;
    return action !== 'navigateToProfileList';
  };
