import { Resource } from '../resource.model';

export const GridDataInitialState: Resource = {
  gridData: {
    rows: [],
    totalCount: 0
  },
  columns: [],
  availableColumns: [],
  queueTitle: '',
  lastUpdate: null
};
