import { Component } from '@angular/core';
import { SystemVariablesService } from '../../../core/services/system-variables/system-variables.service';

@Component({
  selector: 'app-sign-in-page',
  templateUrl: './sign-in-page.component.html',
  styleUrls: ['./sign-in-page.component.scss']
})
export class SignInPageComponent {

  isRightToLeft = false;
  productVersion = this.systemVariablesService.getSingleVariable('PRODUCT_VERSION');

  constructor(private systemVariablesService: SystemVariablesService) { }

}
