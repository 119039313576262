<div class="alert-details-dialog">
    <app-fn-ui-close-icon (closeClick)="onCloseClick()"></app-fn-ui-close-icon>
    <div class="dialog-content">
        <app-alert-more-details [fields]="data.fields"
                                [alert]="data.alert" 
                                [actionLabels]="data.actionLabels"
                                (closeClick)="onCloseClick()"
                                (investigateClick)="onInvestigateClick()"
                                ></app-alert-more-details>
    </div>
</div>
