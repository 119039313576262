import { Action, State, Selector, StateContext } from '@ngxs/store';
import { CallerAuthentication, CallerAuthenticationStateModel } from './models/caller-authentication-state.model';
import { SetCallerAuthentication } from './actions/caller-authentication.actions';


@State<CallerAuthenticationStateModel>({
  name: 'CallerAuthentication',
  defaults: {
    authentication: {
      mode: false
    }
  }
})
export class CallerAuthenticationState {

  @Selector()
  static getAuthentication(state: CallerAuthenticationStateModel): CallerAuthentication {
    return state.authentication;
  }

  @Action(SetCallerAuthentication)
  SetAuthentication(ctx: StateContext<CallerAuthenticationStateModel>, { payload }: SetCallerAuthentication) {
    ctx.patchState({
      authentication: payload
    });
  }
}
