export const OperatorsMap = {
  INNER_CONDITIONS_OPERATORS: ['*', '/', '+', '-', 'concatenate'],
  MATHEMATICAL_OPERATORS: ['*', '/', '+', '-', '=', '<>', '<', '>', '<=', '>='],
  CONCATENATE_OPERATORS: ['Concatenate', '=', '<>', 'In Value List', 'Not In Value List', 'In list', 'Not in list', 'Starts with',
    'Does not start with', 'Ends with', 'Does not end with', 'Contains', 'Does Not Contain'],
  CONTENT_SOURCE_FALSE_FILTER: ['in', 'not in', 'in system list', 'not in system list'],
  CONTENT_SOURCE_TRUE_FILTER: [
    'in out file',
    'does not end with',
    'in group manual match',
    'not like value list',
    'contains',
    'starts with',
    'in',
    'not like',
    'does not contain',
    'not in template set',
    'in template set',
    'not in list',
    'like',
    'ends with',
    'like value list',
    'not in',
    'containsid',
    'in list',
    'manual match occurrences',
    'does not start with'
  ],
  //TODO remove this filter once operators script runs
  REDUNDANT_OPERATORS: ['in out file', 'in group manual match', 'manual match occurrences', 'in template set',
    'not in template set', 'between', 'in', 'not in', 'like value list', 'not like value list', 'containsid', 'like', 'not like', 'not starts with']
};

//A map that shows the allowed type in right operand according to the left operand type
export const AllowedTypesMap = {
  'NUMBER': ['NUMBER', 'DECIMAL', 'INTEGER', 'NUM_NO_DEC'],
  'DECIMAL': ['NUMBER', 'DECIMAL', 'INTEGER', 'NUM_NO_DEC'],
  'INTEGER': ['NUMBER', 'DECIMAL', 'INTEGER', 'NUM_NO_DEC'],
  'NUM_NO_DEC': ['NUMBER', 'DECIMAL', 'INTEGER', 'NUM_NO_DEC'],
  'DATE': ['DATE', 'DATE_TIME'],
  'DATE_TIME': ['DATE_TIME', 'DATE'],
  'TIME': ['TIME'],
  'BOOL': ['BOOL'],
  'STRING': ['STRING']
};

export const InnerConditionsAllowedTypesMap = {
  '*': ['NUMBER', 'DECIMAL', 'INTEGER', 'NUM_NO_DEC'],
  '/': ['NUMBER', 'DECIMAL', 'INTEGER', 'NUM_NO_DEC'],
  '+': ['NUMBER', 'DECIMAL', 'INTEGER', 'NUM_NO_DEC'],
  '-': ['NUMBER', 'DECIMAL', 'INTEGER', 'NUM_NO_DEC'],
  'concatenate': ['NUMBER', 'DECIMAL', 'INTEGER', 'NUM_NO_DEC', 'STRING']
};

export const TypeToControlMap = {
  'NUMBER': 'autocomplete',
  'STRING': 'autocomplete',
  'BOOL': 'checkbox',
  'DATE': 'datePicker',
  'DATE_TIME': 'datetimePicker',
  'TIME': 'timePicker',
  'LOGICAL_FIELD': 'drilldown'
};

export const RuleTypesToDisableLayerClassification = ['2'];

export const Dates = {
  'DEFAULT_MAX_DATE_TIMESTAMP': '4102358400000'
};
