import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StoreService } from '../../../services/store.service';
import { AlertDetailsDialogService } from '../alert-details-dialog/services/alert-details-dialog.service';
import { InvestigationService } from '../../investigation/services/investigation.service';
import { Alert } from '../../models/alert.model';
import { ALERT_DETILES_FIELDS } from '../../models/consts/alert-details-fields.const';
import { ToggleSidebar } from '../../state/sidebar/actions/toggle-sidebar.action';

@Component({
  selector: 'app-alert-sidebar-item',
  templateUrl: './alert-sidebar-item.component.html',
  styleUrls: ['./alert-sidebar-item.component.scss']
})
export class AlertSidebarItemComponent {

  @Input() alert: Alert;

  @Output() alertSelect = new EventEmitter<void>();

  constructor(
    private alertDetailsDialogService: AlertDetailsDialogService,
    private investigationService: InvestigationService,
    private translateService: TranslateService,
    private storeService: StoreService
  ) { }

  onDetailsClick() {
    const actionLabels = { close: this.translateService.instant('alert.action.back') };
    const dialogData = { fields: ALERT_DETILES_FIELDS, alert: this.alert, actionLabels };
    const dialogRef = this.alertDetailsDialogService.open(dialogData);
    dialogRef.afterOpened().toPromise().then(() => this.alertSelect.emit());
  }

  onInvestigateClick() {
    this.storeService.dispatch(new ToggleSidebar());
    this.investigationService.investigate(this.alert);
  }

}
