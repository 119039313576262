import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterOperator } from '@fgpp-ui/grid';
import { MainTab, NavigationItem } from '../../../core/navigation/models';
import { NavigationService } from '../../../core/navigation/services/navigation.service';
import { PROFILE_ID } from '../../../profiles/components/profiles-grid/profiles-grid.component';
import { ALL_RULE_TYPES } from '../../../rules/models/consts/rule-type.consts';
import { PopOutService } from '../../../services/pop-out.service';
import { ConfirmModalComponent } from '../../../shared/fn-ui-modals/components/confirm-modal/confirm-modal.component';
import { ModalsService } from '../../../shared/fn-ui-modals/services/modals.service';
import { SearchFilter } from '../../../shared/models/search-filter.model';
import { IRulesRelatedProfileHandlerService } from './intrefaces/rules-related-profile-handler-service.interface';

@Injectable()
export class RulesRelatedProfileHandlerService implements IRulesRelatedProfileHandlerService {

  get businessRulesItem(): NavigationItem {
    return this.navigationService.getTabNavigationItem(MainTab.RULES, PROFILE_ID.RULE);
  }

  get ruleAttachmentsItem(): NavigationItem {
    return this.navigationService.getTabNavigationItem(MainTab.RULES, PROFILE_ID.RULE_ASSOCIATION);
  }

  constructor(private router: Router,
              private route: ActivatedRoute,
              private modalsService: ModalsService,
              private popOutService: PopOutService,
              private navigationService: NavigationService) { }

  onActionError(errorCode: string, errorMessage: string): void {
    this.openConfirmModal(errorCode, errorMessage).afterClosed().subscribe((result: string) => {
      if (result === 'ok') {
        this.goToRelatedRules(this.route.snapshot.queryParams.uniqueRecId);
      }
    });
  }

  goToRelatedRules(profileUid: string): Promise<boolean> {
    const link = this.businessRulesItem.routerLink;
    const path = link.substring(0, link.lastIndexOf('/'));
    const queryParams = {
      ruleType: ALL_RULE_TYPES,
      filters: JSON.stringify([this.getRulesFilter(profileUid)])
    };
    if (this.popOutService.isPopOut()) {
      setTimeout(() => window.close(), 0);
      return (window.top.opener as any).GPPFrontEnd().navigate([path], { queryParams });
    } else {
      return this.router.navigate([path], { queryParams });
    }
  }

  goToRelatedOfficeRules(office: string): Promise<boolean> {
    const path = this.ruleAttachmentsItem.routerLink;
    const queryParams = {
      filters: JSON.stringify([this.getRulesFilterForOffice(office)])
    };
    if (this.popOutService.isPopOut()) {
      setTimeout(() => window.close(), 0);
      return (window.top.opener as any).GPPFrontEnd().navigate([path], { queryParams });
    } else {
      return this.router.navigate([path], { queryParams });
    }
  }

  private openConfirmModal(errorCode: string, errorMessage: string): MatDialogRef<ConfirmModalComponent> {
    const confirmData = {
      title: `business-framework.profiles.errors.${errorCode}`,
      message: errorMessage.split('\\n').join('\n'),
      okButton: 'general.yes',
      cancelButton: 'general.no'
    };

    return this.modalsService.confirm(confirmData);
  }

  private getRulesFilter(profileUid: string): SearchFilter {
    return {
      columnId: 'PRULES-RULE_ACTION_UID',
      operator: FilterOperator.EQUAL,
      value: [profileUid]
    };
  }

  private getRulesFilterForOffice(office: string): SearchFilter {
    return {
      columnId: 'OBJECT_PRULE_TYPES-OBJECT_UID',
      operator: FilterOperator.EQUAL,
      value: [office]
    };
  }

}
