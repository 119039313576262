<div class="power-bi-reports" [hidden]="!reportMetadata">
    <header *ngIf="isLoaded">
        <div class="logo">
            <img class="logo-image" src="assets/images/png/logo_finastra.png">
        </div>
        <div class="report-icons">
            <button mat-icon-button class="show-bookmarks" [title]="'power-bi.actions.show-bookmarks' | translate" (click)="showBookmarks()">
                <app-fn-ui-icon icon="bookmark_plus_outline"></app-fn-ui-icon>
            </button>
            <button mat-icon-button class="reload-report" [title]="'power-bi.actions.reload-report' | translate" (click)="reloadReport()">
                <app-fn-ui-icon icon="refresh"></app-fn-ui-icon>
            </button>

            <button mat-icon-button class="print-report" [title]="'power-bi.actions.print-report' | translate" (click)="printReport()">
                <app-fn-ui-icon icon="printer"></app-fn-ui-icon>
            </button>
        </div>
    </header>
    <div class="reports-container" #reportsContainer></div>
</div>