import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { CallbackComponent } from './authentication/components/callback/callback.component';
import { AuthenticationGuardService } from './authentication/services/authentication-guard.service';
import { MessageCenterRoutes } from './messages-center/routing/message-center-routing.module';
import { BusinessSetupRoutes } from './profiles/routing/business-setup/business-setup-routing.module';
import { BaseViewComponent } from './base-view/components/base-view/base-view.component';
import { DeactivateBaseViewGuard } from './base-view/guards/deactivate-base-view.guard';
import { OperationsRoutes } from './profiles/routing/operations/operations-routing.module';
import { rulesRoutes } from './base-view/rules/rules-routing.module';
import { SystemSetupRoutes } from './profiles/routing/system-setup/system-setup-routing.module';
import { OopsPageComponent } from './core/components/oops-page/oops-page.component';
import { liquidityRoutes } from './liquidity/routing/liquidity-routing.module';
import { UserPreferencesResolver } from './base-view/resolvers/user-preferences.resolver';
import { TranslationsResolver } from './base-view/resolvers/translation.resolver';
import { SitemapResolver } from './base-view/resolvers/sitemap.resolver';
import { TaskBarResolver } from './resolvers/task-bar.resolver';
import { MenuResolver } from './base-view/resolvers/menu.resolver';
import { ActiveMenuTabResolver } from './base-view/resolvers/active-menu-tab.resolver';
import { NavigationTabsResolver } from './base-view/resolvers/navigation-tabs.resolver';
import { ToursResolver } from './tours/resolvers/tours.resolver';
import { InsightRoutes } from './insight/insight-routing.module';
import { ArchiveRoutes } from './archive/archive-routing.module';

export const baseRoutes: Routes = [
  {
    path: 'popout/messages',
    loadChildren: () => import('./messages/messages-popout/messages-popout.module').then(m => m.MessagesPopoutModule)
  },
  {
    path: 'callback',
    data: {
      urlParamsData: null
    },
    component: CallbackComponent
  },
  {
    path: 'home',
    canActivate: [AuthenticationGuardService],
    resolve: {
      userPreferences: UserPreferencesResolver,
      translation: TranslationsResolver,
      navigation: SitemapResolver,
      taskBar: TaskBarResolver
    },
    children: [
      {
        path: '',
        component: BaseViewComponent,
        resolve: {
          menu: MenuResolver,
          activeTab: ActiveMenuTabResolver,
          navigationTabs: NavigationTabsResolver,
          // Removing Tours initialization. TODO: Code cleanup for Tours
          //toursResolver: ToursResolver
        },
        children: [
          { path: 'insight', children: InsightRoutes, canDeactivate: [DeactivateBaseViewGuard] },
          { path: 'messages-center', children: MessageCenterRoutes, canDeactivate: [DeactivateBaseViewGuard] },
          { path: 'liquidity', children: liquidityRoutes, canDeactivate: [DeactivateBaseViewGuard] },
          { path: 'rules', children: rulesRoutes, canDeactivate: [DeactivateBaseViewGuard] },
          { path: 'business-setup', children: BusinessSetupRoutes, canDeactivate: [DeactivateBaseViewGuard] },
          { path: 'system-setup', children: SystemSetupRoutes, canDeactivate: [DeactivateBaseViewGuard] },
          { path: 'operations', children: OperationsRoutes, canDeactivate: [DeactivateBaseViewGuard] },
          { path: 'archive', children: ArchiveRoutes, canDeactivate: [DeactivateBaseViewGuard] },
          { path: 'page-not-found', component: OopsPageComponent },
        ]
      },
    ]
  },
  { path: '**', redirectTo: '/home/page-not-found' }
];

@NgModule({
  imports: [RouterModule.forRoot(baseRoutes, {
    useHash: true,
    initialNavigation: 'disabled',
    preloadingStrategy: PreloadAllModules

  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
