import { Component, HostListener, ViewEncapsulation } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import { TOAST_ANIMATION } from '../../../../shared/fn-ui-toast/consts/toast-animation.const';
import { FnUiToastComponent } from '../../../../shared/fn-ui-toast/fn-ui-toast.component';

@Component({
  selector: 'app-new-alert-notification',
  templateUrl: './alert-notification.component.html',
  styleUrls: [
    '../../../../shared/fn-ui-toast/fn-ui-toast.component.scss',
    './alert-notification.component.scss'
  ],
  animations: [TOAST_ANIMATION],
  preserveWhitespaces: false,
})
export class AlertNotificationComponent extends Toast {

  @HostListener('mouseenter')
  preventMouseEnterStick(): boolean {
    return false;
  }

  @HostListener('mouseleave')
  preventMouseLeaveHide(): boolean {
    return false;
  }

  count: number = this.message as any;

  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage) {
    super(toastrService, toastPackage);
  }

}
