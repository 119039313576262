<div class="dh-inner-conditions-wrapper">
  <div *ngFor="let condition of conditions; let i = index">
    <app-inner-condition [condition]="condition"
      [operators]="i === conditions.length - 1 ? lastConditionOperators : conditionsOperators"
      [allowedTypes]="i === conditions.length - 1 ? lastConditionAllowedDataTypes : allowedDataTypes"
      [disableUpBtn]="i === 0" [disableDownBtn]="i === conditions.length - 2"
      [showAddBtn]="conditions.length - 1 === 0 || i < conditions.length - 1"
      [removeAllButtons]="i === conditions.length - 1"
      (moveUp)="onMoveUp(condition)"
      (moveDown)="onMoveDown(condition)"
      (remove)="onRemove(condition)"
      (add)="onAdd(condition)">
    </app-inner-condition>
  </div>
</div>