import { Injectable } from '@angular/core';
import { StoreService } from '../../../core/services/store.service';
import { AlertApiService } from './alert-api.service';
import { AlertMapperService } from './alert-mapper.service';
import { AddAlerts } from '../state/alert/actions/add-alerts.action';
import { IAlertsService } from './interfaces/alerts-service.interface';
import { ToastAlertManagerService } from '../components/toast-alert/services/toast-alert-manager.service';
import { AlertsResponse } from '../models/alerts-response.model';
import { ALERTS_CONFIG } from '../models/consts/alerts-config.const';

@Injectable()
export class AlertService implements IAlertsService {

  private _intervalRef: any;

  constructor(private alertApiService: AlertApiService,
              private alertMapperService: AlertMapperService,
              private storeService: StoreService,
              private toastAlertManagerService: ToastAlertManagerService) { }

  start() {
    this.initAlertMechanism();
  }

  stop() {
    clearInterval(this._intervalRef);
  }

  private initAlertMechanism(): void {
    this.toastAlertManagerService.initializeToast();
    this.getAlerts();
    this._intervalRef = setInterval(this.getAlerts.bind(this), ALERTS_CONFIG.intervalTimeout);
  }

  private getAlerts() {
    this.alertApiService.getAlerts().then((alertsResponse: AlertsResponse) => {
      const alerts = this.alertMapperService.mapAll(alertsResponse.items);
      this.storeService.dispatch(new AddAlerts(alerts));
    });
  }

}
