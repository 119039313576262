import { Component, OnDestroy } from '@angular/core';
import { MultiLineComponent } from '@fgpp-ui/components';
import { FieldsToMaskComponent } from './modals/fields-to-mask/fields-to-mask.component';
import { ProfileFormTemplateProviders } from '../../../core/templates/profile-form.template.providers';
import {BFOnFormLoadComplete} from '../../../core/interfaces/sdk-profile-hooks.interface';
import {AbstractProfileFormComponent} from "../../abstract/abstract-profile/abstract-profile-form.component";

@Component({
  selector: 'app-masking-profile',
  templateUrl: '../../../core/templates/profile-form.template.html',
  styleUrls: ['./masking.component.scss'],
  providers: ProfileFormTemplateProviders
})
export class MaskingComponent extends AbstractProfileFormComponent implements OnDestroy, BFOnFormLoadComplete {
  fgppFormLoadComplete(): void {
    setTimeout(() => this.formMgr.get('DETAILS').getLayoutControl<MultiLineComponent>().setModalComponent(FieldsToMaskComponent));
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  checkRequired(): boolean {
    const mandatoryFieldsEntered = super.checkRequired();
    const details = this.formMgr.get('DETAILS').getValue();
    const multilineEntered = details && (Array.isArray(details) && details.length > 0);
    if (mandatoryFieldsEntered && !multilineEntered) {
      this.notificationService.error(this.translate.instant('business-framework.profiles.659.details.requiredError'));
    }
    return mandatoryFieldsEntered && multilineEntered;
  }
}
