export const TasksSettings = {
    'DEPARTMENT': 'TASKS_SETTINGS-DEPARTMENT',
    'EFFECTIVE_DATE': 'EFFECTIVE_DATE',
    'FILE_PATH': 'TASKS_SETTINGS-FILE_PATH',
    'OFFICE': 'TASKS_SETTINGS-OFFICE',
    'PROFILE_CHANGE_STATUS': 'TASKS_SETTINGS-PROFILE_CHANGE_STATUS',
    'REC_STATUS': 'TASKS_SETTINGS-REC_STATUS',
    'TASKS_SETTINGS_DESCRIPTION': 'TASKS_SETTINGS-TASKS_SETTINGS_DESCRIPTION',
    'TASKS_SETTINGS_NAME': 'TASKS_SETTINGS-TASKS_SETTINGS_NAME',
    'UPLOAD_PATH': 'TASKS_SETTINGS-UPLOAD_PATH',
    'UPLOAD_TYPE': 'TASKS_SETTINGS-UPLOAD_TYPE',
    'V_ATTACHED_TO_SCRIPT': 'TASKS_SETTINGS-V_ATTACHED_TO_SCRIPT',
    'V_TASKDESCRIPTION': 'TASKS_SETTINGS-V_TASKDESCRIPTION',
    'V_TASKNAME': 'TASKS_SETTINGS-V_TASKNAME',
    'MOP': 'TASKS_SETTINGS-MOP',
    'TIME_STAMP': 'TIME_STAMP',
    'UID': 'VIRTUAL-UID',
    'PROFILE_UPDATE_PK': 'PROFILE_UPDATE_PK',
    'PU_TIME_STAMP': 'PU_TIME_STAMP',
    'NOTE': 'NOTE'
};
