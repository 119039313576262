import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription, from } from 'rxjs';
import { FormsService } from '../forms/services/forms.service';
import { BrowsersService } from './browsers.service';
import { PopOutHelperService } from './pop-out-helper.service';
import { AutoFeederResult } from '../messages/models/auto-feeder-result.model';
import { AutoFeederAction } from '../messages/auto-feeder/models/enums/auto-feeder-action.enum';

@Injectable()
export class PopOutService implements OnDestroy {

  public static readonly MESSAGE_TYPE = 'message';
  public static readonly PROCESSING_COMMUNICATION_TYPE = 'processingCommunication';
  public static readonly MESSAGE_DISCLAIMER_TYPE = 'messageDisclaimer';
  public static readonly MESSAGE_PRE_SUBMIT_VALIDATION_TYPE = 'messagePreSubmitValidation';

  subscriber = new Subscription();
  private _isPopOutOpenEvent = true;
  private _stateParams: object = {};
  private _stateName: string;
  private _defaultOptions = 'width=1024px, height=768px, resizable=1, location=yes';
  private _options = this._defaultOptions;
  public openedWindows = {};
  private _openedWindowsLimit: number;
  private _openedWindowsCounter: number;
  private _isReachedToLimitation: boolean;
  private _type: string;
  private _shouldDoUnload = true;
  private isFormDirty = '';

  constructor(private route: ActivatedRoute, public formsService: FormsService,
              public browsersService: BrowsersService,
              private popOutHelperService: PopOutHelperService) {
    this.isPopOutOpenEvent = false;
    this.openedWindowsLimit = 5;
    this.openedWindowsCounter = 0;
    this.isReachedToLimitation = false;
    //TODO : refactor: create popOut service inheritance per type
    this.type = PopOutService.MESSAGE_TYPE;
    ( <any> window ).isBtnCloseClicked = false;
    this.subscribeToRouteParams();
  }

  private subscribeToRouteParams(): void {
    const sub = this.route.params.subscribe(params => {
      if (params['isFormDirty'] && params['isFormDirty'] === 'true') {
        this.isFormDirty = 'true';
      }
    });
    this.subscriber.add(sub);
  }

  ngOnDestroy() {
    this.subscriber.unsubscribe();
  }

  set isPopOutOpenEvent(flag: boolean) {
    this._isPopOutOpenEvent = flag;
  }

  get isPopOutOpenEvent(): boolean {
    return this._isPopOutOpenEvent;
  }

  set stateName(name: string) {
    this._stateName = name;
  }

  get stateName(): string {
    return this._stateName;
  }

  set stateParams(params: object) {
    //deep copy in order to prevent change in case of change transition
    this._stateParams = { ...params };
  }

  get stateParams(): object {
    return this._stateParams;
  }

  get options(): string {
    return this._options;
  }

  set options(value: string) {
    this._options = value;
  }

  get openedWindowsLimit(): number {
    return this._openedWindowsLimit;
  }

  set openedWindowsLimit( value: number ) {
    this._openedWindowsLimit = value;
  }

  get openedWindowsCounter(): number {
    return this._openedWindowsCounter;
  }

  set openedWindowsCounter( value: number ) {
    this._openedWindowsCounter = value;
  }

  get isReachedToLimitation(): boolean {
    return this._isReachedToLimitation;
  }

  set isReachedToLimitation( value: boolean ) {
    this._isReachedToLimitation = value;
  }

  get type(): string {
    return this._type;
  }

  set type( value: string ) {
    this._type = value;
  }

  get shouldDoUnload(): boolean {
    return this._shouldDoUnload;
  }

  set shouldDoUnload( value: boolean ) {
    this._shouldDoUnload = value;
  }

  initBeforeUnload( form, metadata ) {
    let counter = 1;
    const refreshMe = setInterval( () => {
      // if window.addEventListener exists, than addEventListener can work
      if ( window.addEventListener ) {
        window.addEventListener( 'beforeunload', (e) => {
          //Extra check for IE
          if ( (form.dirty || this.isFormDirty) && this.shouldDoUnload) {
            ( e || window.event ).returnValue = '';
            return null;
          }
          // in case form not dirty
          this.actionsOnXClose( metadata );
        }, false );
        clearInterval( refreshMe );
      } else if ( counter === 20 ) {// if after 10 sec addEventListener not exists on childWindow
        clearInterval( refreshMe );
      }
      counter++;
    }, 500 );
    this.isPopOutOpenEvent = false;
  }

  actionsOnXClose( metadata ) {
    if (metadata) {
      if ( !metadata.locked ) {
        this.subscriber.add(this.formsService.releaseLock( metadata[ 'P_MID' ].value ).subscribe());
      }
      this.notifyPopOutClosed( metadata[ 'P_MID' ].value );
    }
  }

  public removeClosedWindows() {
    const keys = Object.keys(this.openedWindows);
    for (let i = 0; i < keys.length; i++) {
      if (this.openedWindows[keys[i]].windowRef.closed) {
        delete this.openedWindows[keys[i]];
        --this._openedWindowsCounter;
        this.isReachedToLimitation = this._openedWindowsCounter === this._openedWindowsLimit;
      }
    }
    if (!keys.length) {
      this._openedWindowsCounter = 0;
      this.isReachedToLimitation = false;
    }
  }

  /**
   * @desc when popOut close - update inline message dynamically (if inline is open)
   * @param mid
   */
  public notifyPopOutClosed(mid) {
    delete this.openedWindows[mid];
    this._openedWindowsCounter--;
    this._isReachedToLimitation = false;
  }

  public getPopoutFormData(uid) {
    let response = null;
    if (this.openedWindows[uid] != null) {
      response = this.openedWindows[uid].formData;
    }

    return response;
  }

  public getPopoutOriginalFormData(uid) {
    let response = null;
    if (this.openedWindows[uid] != null) {
      response = this.openedWindows[uid].originalFormData;
    }

    return response;
  }

  open(uid, formData?, originalFormData?) {
    switch (this.type) {
      case PopOutService.PROCESSING_COMMUNICATION_TYPE:
      case PopOutService.MESSAGE_DISCLAIMER_TYPE:
      case PopOutService.MESSAGE_PRE_SUBMIT_VALIDATION_TYPE:
        this.openAdditionalFunctionsForMessage(uid, formData, originalFormData);
        break;
      default:
        this.openMessage(uid, formData, originalFormData);
    }
  }

  /**
   * @desc message has a limitation of opening pop outs
   * @param uid
   * @param formData
   * @param originalFormData
   */
  openMessage( uid, formData?, originalFormData? ) {
    try {
      if ( this._stateName ) {
        if ( this.openedWindowsCounter < this.openedWindowsLimit ) {
          this.subscriber.add(this.formsService.releaseLock(uid).subscribe(function () {}));
          this.isReachedToLimitation = false;
          const url = this.popOutHelperService.createUrl(this._stateParams);
          const childWindow: any = window.open(url, '_blank', this._options);
          if ( this.openedWindows[ uid ] == null ) {
            this.openedWindows[ uid ] = {
              'windowRef' : childWindow,
              'formData' : JSON.stringify( formData ),
              'originalFormData' : JSON.stringify( originalFormData )
            };
          }
          const isIE = this.browsersService.isIE();
          const isChrome = this.browsersService.isChrome();
          let onUnloadHandle;
          if ( isChrome ) {
            onUnloadHandle = () => {
              this.onUnloadHandle2( childWindow, uid, formData );
            };
          } else if ( isIE ) {
            onUnloadHandle = () => {
              this.popOuMsgClosed( childWindow, formData );
            };
          }
          //on IE addEventListener exists in childWindow in delay
          if ( typeof childWindow.addEventListener === 'undefined' && isIE ) {
            let counter = 1;
            const refreshMe = setInterval( function () {
              // if childWindow.addEventListener exists, than addEventListener can work
              if ( childWindow.addEventListener ) {
                childWindow.addEventListener( 'unload', onUnloadHandle, false );
                clearInterval( refreshMe );
              } else if ( counter === 20 ) {// if after 10 sec addEventListener not exists on childWindow
                clearInterval( refreshMe );
              }
              counter++;
            }, 500 );

          } else if ( childWindow.addEventListener || isChrome ) { //no delay at chrome
            childWindow.addEventListener( 'unload', onUnloadHandle, false );
          }
          this.isPopOutOpenEvent = false;
          this._openedWindowsCounter++;
          if ( this.openedWindowsCounter === this.openedWindowsLimit ) {
            this.isReachedToLimitation = true;
          }
        }
      }
    } catch ( e ) {
      throw e;
    }
  }

  // two unload events in chrome occurs
  onUnloadHandle2( childWindow, uid, metadata ) {
    const onunloadHandle = () => {
      this.popOuMsgClosed( childWindow, metadata );
    };
    childWindow.addEventListener( 'unload', onunloadHandle, false );
  }

  popOuMsgClosed( childWindow, metadata ) {
    if ( !childWindow.isBtnCloseClicked ) {
      setTimeout( () => {
        this.actionsOnXClose( metadata );
      }, 500 );
    }
  }

  /**
   * @desc Processing communication, Message Disclaimer has no limitation of opening pop outs
   * @param uid
   * @param formData
   * @param originalFormData
   */
  openAdditionalFunctionsForMessage(uid, formData?, originalFormData?) {
    try {
      if (this._stateName) {
        const url = this.popOutHelperService.createUrl(this._stateParams);
        const childWindow: any = window.open(url, '_blank', this._options);

        const windowId = `${this.type}_${uid}`;
        if (this.openedWindows[windowId] == null) {
          this.openedWindows[windowId] = {
            'windowRef': childWindow,
            'formData': JSON.stringify(formData),
            'originalFormData': JSON.stringify(originalFormData)
          };
          const onUnloadHandle = ($event) => {
            if ($event.target.location.href !== 'about:blank') {
              this.notifyPopOutClosed(windowId);
            }
          };
          childWindow.addEventListener('unload', onUnloadHandle, false);
          this._openedWindowsCounter++;
        }
        return new Promise<any>((resolve, reject) => {});
      } else {
        console.log(this._stateName);
      }
    } catch (e) {
      throw e;
    } finally {
      this.type = null;
      this.options = this._defaultOptions;
    }
  }

  /**
   * @desc 'null' for Chrome, 'undefined' for IE
   * Check GPPFrontEnd for new ui (the old ui changed the top opener from some reason)
   * @returns {boolean}
   */
  isPopOut(): boolean {
    if (window.top.opener == null) {
      return false;
    }

    // since we saw cases in which there is some opener which is not the main screen
    // and we cannot access its properties we are using try catch
    // if we get to the catch it means that we are not popout
    try {
      return (window.top.opener as any)?.GPPFrontEnd != null;
    } catch (e) {
      return false;
    }
  }

  close() {
    this.setActionClose();
  }

  openAutoFeeder(state: string, params: object): Observable<AutoFeederResult> {
    const segments = ['popout', 'messages', 'single'];
    const url = this.popOutHelperService.createUrl(params, segments);
    const popupWindow = window.open(url, '_blank', this._options);
    popupWindow['popupResult'] = null;
    return from(this.getPromise(popupWindow));
  }

  getPromise(popupWindow): Promise<AutoFeederResult> {
    return new Promise<AutoFeederResult>((resolve, reject) => {
      if (popupWindow['popupResult'] != null) {
        resolve(popupWindow['popupResult']);
      } else {
        let counter = 1;
        const refreshMe = setInterval( () => {
          if (popupWindow.addEventListener) {
            popupWindow.addEventListener('load', () => {
              setTimeout(() => {
                if (popupWindow['popupResult'] != null) {
                  resolve(popupWindow['popupResult']);
                } else {
                  popupWindow.addEventListener('beforeunload', () => {
                    const popupResult: any = Object.assign({}, popupWindow['popupResult']);
                    resolve(popupResult);
                  }, false);
                }
              }, 1000);
            }, false);
            clearInterval( refreshMe );
          } else if ( counter === 20 ) {// if after 10 sec addEventListener not exists on childWindow
            clearInterval( refreshMe );
          }
          counter++;
        }, 500 );
      }
    });
  }

  closeAutoFeederMultiselectMessage() {
    window.close();
  }

  setActionClose() {
    window['popupResult'] = { action: AutoFeederAction.CLOSE } as AutoFeederResult;
  }

  setActionNext() {
    window['popupResult'] = { action: AutoFeederAction.NEXT } as AutoFeederResult;
  }

  setActionQuickToOld() {
    window['popupResult'] = { action: AutoFeederAction.QUICK_TO_OLD } as AutoFeederResult;
  }

  closeAll() {
    const keys = Object.keys( this.openedWindows );
    for ( let i = 0; i < keys.length; i++ ) {
      this.openedWindows[keys[i]].windowRef.close();
    }
  }

  openMessageDisclaimerPreview(uid) {
    const url = this.popOutHelperService.createUrl(this._stateParams);
    const childWindow = window.open(url, '_blank', this._options);
    childWindow['isLoaded'] = null;
    // focus for IE (need also timeout)
    setTimeout(function () {
      childWindow.focus();
    }, 500 );
    return new Promise<any>((resolve, reject) => {
      this.checkOnLoadParametersMessageDisclaimerPreview(childWindow, resolve);
    });
  }

  closePreSubmitValidationPopUp(uid) {
    if (this.openedWindows[uid] == null) {
      this.openedWindows[uid].windowRef.close();
    }
  }

  checkOnLoadParametersMessageDisclaimerPreview(popupWindow, resolve) {
    if (popupWindow['isLoaded'] != null) {
      resolve();
    } else {
      setTimeout(() => {
        this.checkOnLoadParametersMessageDisclaimerPreview(popupWindow, resolve);
      }, 100);
    }
  }
}
