import { Action, State, Selector, StateContext } from '@ngxs/store';
import { SetUserNotification } from './actions/user-notification.actions';
import { UserNotification, UserNotificationStateModel } from './models/user-notification-state.model';



@State<UserNotificationStateModel>({
  name: 'userNotification',
  defaults: {
    userNotification: {
      message: ''
    }
  }
})
export class UserNotificationState {

  @Selector()
  static getUserNotification(state: UserNotificationStateModel): UserNotification {
    return state.userNotification;
  }

  @Action(SetUserNotification)
  SetUserNotification(ctx: StateContext<UserNotificationStateModel>, { payload }: SetUserNotification) {
    ctx.patchState({
      userNotification: payload
    });
  }
}
