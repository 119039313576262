import { Injectable } from '@angular/core';
import {ConfirmationModalComponent} from '../../../../forms/components/confirmation-modal/confirmation-modal.component';
import {AbstractControl} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {map} from 'rxjs/operators';

@Injectable()
export class FxRateModalsService {

  constructor(public translateService: TranslateService, public dialogService: MatDialog) { }

  openNccCodeConfirmationModal(control: AbstractControl): Observable<any> {
    const config = {
      panelClass: 'confirmation-modal',
      data: {
        modalTitle: this.translateService.instant('forms.modal_ncc_code_confirm_title'),
        content: this.translateService.instant('forms.modal_ncc_code_confirm_content',
          { party: control.value })
      }
    };
    return this.dialogService.open(ConfirmationModalComponent, config).afterClosed();
  }

  openNoFxRateModal(): Observable<boolean> {
    const config = {
      panelClass: 'confirmation-modal',
      data: {
        modalTitle: this.translateService.instant('forms.confirmation_fx_rate_no_approved_modal.title'),
        content: this.translateService.instant('forms.confirmation_fx_rate_no_approved_modal.content')
      }
    };
    return this.dialogService.open(ConfirmationModalComponent, config).afterClosed().pipe(map(result => {
      return !!result;
    }));
  }
}
