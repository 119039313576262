import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationService } from '@fgpp-ui/components';
import { ProfilesManagementService } from './profiles-management.service';
import { ProfilesHelperService } from './profiles-helper.service';
import { GridColumnsService } from '../../grid/services/grid-columns.service';
import { ProfilesSearchRequestBuilderService } from './profiles-search-request-builder';
import { GridConfigService } from '../../grid/services/grid-config.service';
import { SearchFilter } from '../../shared/models/search-filter.model';
import { GridConfig } from '@fgpp-ui/grid';
import { FormatsService } from '../../shared/services/formats.service';
import { OfficeSelectorService } from '../../core/services/office-selector.service';
import { GridClass } from '../../grid/models/enums/grid-class';
import { AuthenticationService } from '../../authentication/services/authentication.service';
import { SpecialItemsService } from '../../services/special-items.service';
import { UserSettingsService } from '../../core/user-settings/services/user-settings.service';
import { PopupService } from '../../core/services/popup.service';

@Injectable()
export class CallerProfilesManagementService extends ProfilesManagementService {

  private _externalParams;

  get externalParams() {
    return this._externalParams;
  }

  set setExternalParams(externalParams: string) {
    this._externalParams = externalParams;
  }

  constructor(userSettingsService: UserSettingsService,
    route: ActivatedRoute,
    router: Router,
    authenticationService: AuthenticationService,
    specialItems: SpecialItemsService,
    popupService: PopupService,
    officeSelector: OfficeSelectorService,
    profilesHelperService: ProfilesHelperService,
    gridColumnsService: GridColumnsService,
    searchRequestBuilderService: ProfilesSearchRequestBuilderService,
    http: HttpClient,
    notificationService: NotificationService,
    gridConfigService: GridConfigService,
    formatsService: FormatsService) {
    super(userSettingsService, route, router, authenticationService, specialItems, officeSelector, popupService,profilesHelperService,
      gridColumnsService, searchRequestBuilderService, http, notificationService, gridConfigService, formatsService);
  }

  get baseUrl(): string {
    return 'do/PROFILE/287/';
  }

  get defaultFilters(): Array<SearchFilter> {
    return [];
  }

  getGridConfig(): GridConfig {
    const gridConfig = super.getGridConfig();
    this.gridConfig.table.tableClass = this.gridConfig.table.tableClass.concat(' ').concat(GridClass.DENSE_MODE);
    return gridConfig;
  }
}
