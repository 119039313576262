import { Injectable } from '@angular/core';
import { PopOutService } from './pop-out.service';
import { FormatsService } from '../shared/services/formats.service';
import { NavigationService } from '../core/navigation/services/navigation.service';
import dayjs from 'dayjs';

@Injectable()
export class DataServiceService {
  readonly defaultPrecision = 2;
  isNotesNotChecked = false;
  pendingAckSpecialInstructions = false;
  isCheckedUserNotice = false;
  msgStatus = '';
  leftPanelFeatureSelection = 'activityLog';
  officeOffsetFromUTC = 0;
  officeDepartmentsMap = new Map();
  selectOptions = {
    'DEPARTMENT': [],
    'NCCPREFIX_ISO': [],
    'QSCURRENCY': []
  };
  isRegulatoryTermsAccepted = false;
  msgType = '';

  comboBoxes = ['CURRENCY', 'MOP', 'NCCPREFIX_SWIFT', 'COUNTRY', 'CHRG_TYPE_ISO', 'DBTR_AGENT_CODE', 'X_RMT_INF_USTRD', 'X_RGLTRY_RPTG_DTLS_CD',
    'X_SVC_LVL_PRTRY_SWIFT', 'X_INSTR_NXT_AGT_OTHER_CD', 'NCCPREFIX_ISO', 'QSCURRENCY', 'X_CTGY_PURP_CD_SWIFT', 'ACC_TYPE', 'X_LCL_INSTRM_PRTRY'];

  fedComboBoxes = ['CHRG_TYPE_FOR_QUICK_US_SCREEN', 'X_INSTG_AGT_ID_2AND', 'NCCPREFIX_ISO', 'QSCURRENCY', 'CURRENCY', 'ACC_TYPE'];

  constructor(private formatsService: FormatsService,
              public popOutService: PopOutService,
              private navigationService: NavigationService) { }

  public clearAllData() {
    this.isNotesNotChecked = false;
    this.pendingAckSpecialInstructions = false;
    this.isCheckedUserNotice = false;
    this.msgStatus = '';
    this.officeOffsetFromUTC = 0;
    this.leftPanelFeatureSelection = 'activityLog';
    this.officeDepartmentsMap = new Map();
    //    this.selectOptions = {
    //      'DEPARTMENT': [],
    //      'NCCPREFIX_ISO': [],
    //      'QSCURRENCY': []
    //    }; //not to clean QSCURRENCY - used in fullscreen
    this.selectOptions['DEPARTMENT'] = [];
    this.selectOptions['NCCPREFIX_ISO'] = [];
    this.isRegulatoryTermsAccepted = false;
    this.msgType = '';
  }

  public setCurrenciesByOffice(currentOffice: string) {
    const data = this.selectOptions['QSCURRENCY'];
    this.selectOptions['QSCURRENCY'] = [];
    data.forEach((obj) => {
      const valueObj = (obj.value).split('~');
      const ccyId = obj.id;
      const ccyValue = valueObj[0];
      const ccyPrecision = valueObj[1];
      const ccyOffice = valueObj[2];
      if (ccyOffice === currentOffice) {
        this.selectOptions['QSCURRENCY'].push({ id: ccyId, value: ccyValue, precision: ccyPrecision });
      }
    });
  }

  public addDepartmentToSelectOptions(office) {
    let departments;
    if (!this.popOutService.isPopOut()) {
      departments = this.navigationService.sitemap.modules.officeDepartments;
    } else {
      departments = (window.top.opener as any)?.GPPFrontEnd().getOfficeDepartment();
    }
    const departmentsForOffice = departments[office];
    for (let i = 0; i < departmentsForOffice.length; i++) {
      this.selectOptions['DEPARTMENT'].push({ id: departmentsForOffice[i], value: departmentsForOffice[i] });
    }
  }

  public addWriteOnlyDepartment(office) {
    let departments;
    if (!this.popOutService.isPopOut()) {
      departments = this.navigationService.sitemap.modules.writeOnlyOfficeDepartments;
    } else {
      departments = (window.top.opener as any)?.GPPFrontEnd().getWriteOnlyOfficeDepartment();
    }
    const departmentsForOffice = departments[office];
    for (let i = 0; i < departmentsForOffice.length; i++) {
      this.selectOptions['DEPARTMENT'].push({ id: departmentsForOffice[i], value: departmentsForOffice[i] });
    }
  }
  public addToSelectOptions(data) {
    const keys = Object.keys(data);
    for (let i = 0; i < keys.length; i++) {
      this.selectOptions[keys[i]] = data[keys[i]];
    }
  }

  public getCurrencyPrecision(id: number) {
    return this.selectOptions['QSCURRENCY'][id].precision;
  }

  public getSelectOptions(formControlName) {
    const comboBox = this.getComboBox(formControlName);
    // only of fedComboBoxes
    if (formControlName === 'OX_CHRG_BR') {
      if (this.selectOptions['CHRG_TYPE_FOR_QUICK_US_SCREEN'] && this.selectOptions['CHRG_TYPE_FOR_QUICK_US_SCREEN'].length > 0) {
        return this.selectOptions['CHRG_TYPE_FOR_QUICK_US_SCREEN'];
      }
    }
    return this.selectOptions[comboBox] || [];
  }

  public getCustomComboBox(formControlName) {
    //this is a temp solution for implementing custom comboboxes controlled in the form type level
    //for now every form that needs a custom behaviour will override this function
    return null;
  }

  public getComboBox(formControlName) {
    const result = this.getCustomComboBox(formControlName);
    if (result != null) {
      return result;
    }

    const pattern = 'CCY$';
    if (formControlName.match(pattern) != null) {
      if ('X_DBTR_ACCT_CCY' === formControlName || 'OX_STTLM_CCY' === formControlName) {
        return 'QSCURRENCY';
      }
      return 'CURRENCY';
    } else if ('X_DBTR_CTRYOFBIRTH' === formControlName || 'X_DBTR_CTRY' === formControlName || 'X_CDTR_CTRY' === formControlName ||
      'X_RGLTRY_RPTG_DTLS_CTRY' === formControlName || 'X_DBTR_PRVTID_OTHR_ISSR' === formControlName) {
      return 'COUNTRY';
    } else if ('P_CDT_MOP' === formControlName || 'P_DBT_MOP' === formControlName) {
      return 'MOP';
    } else if ('X_CHRG_BR' === formControlName || 'OX_CHRG_BR' === formControlName) {
      return 'CHRG_TYPE_ISO';
    } else if ('P_DEPARTMENT' === formControlName) {
      return 'DEPARTMENT';
    } else if ('X_DBTR_PRVTID_OTHR_CD' === formControlName) {
      return 'DBTR_AGENT_CODE';
    } else if ('X_SVC_LVL_PRTRY' === formControlName) {
      return 'X_SVC_LVL_PRTRY_SWIFT';
    } else if ('OX_CHRG_BR' === formControlName) {
      return 'CHRG_TYPE_FOR_QUICK_US_SCREEN';
    } else if ('X_CDTR_AGT_CLR_SYS_CD' === formControlName) {
      return 'NCCPREFIX_ISO';
    } else if ('X_INTRMY_AGT1_CLR_SYS_CD' === formControlName || 'X_INSTG_AGT_CLR_SYS_CD' === formControlName
      || 'X_INSTD_AGT_CLR_SYS_CD' === formControlName) {
      return this.msgType === 'Pacs_008' ? 'NCCPREFIX_ISO_INTRMY' : 'NCCPREFIX_ISO';
    } else if('D_DBTR_ACCT_TYPE' === formControlName) {
      return 'ACC_TYPE';
    }
    return formControlName;
  }

  public getCurrentDateAsPerUserFormat() {
    const officeOffsetFromUTC = this.officeOffsetFromUTC;
    return dayjs().utcOffset(officeOffsetFromUTC).unix();
  }

  public parseDateTime(date) {
    if (!isNaN(+date)) {
      return dayjs(+date).utcOffset(this.officeOffsetFromUTC)
        .format(this.formatsService.dateFormat.toUpperCase() + ' ' + this.formatsService.timeFormat);
    }

    return dayjs(date).format(this.formatsService.dateFormat.toUpperCase() + ' ' + this.formatsService.timeFormat);
  }

  public getPrecisionByCurrency(currency: string, calledFromFS: boolean = false) {
    if (currency) {
      const comboBoxName = 'QSCURRENCY';

      if (calledFromFS && this.selectOptions[comboBoxName].length == 0)
        return -1;

      return this.selectOptions[comboBoxName].find(item => item.id === currency)?.precision || this.defaultPrecision;
    }
    return this.defaultPrecision;
  }
}
