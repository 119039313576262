import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef } from '@angular/core';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogoComponent implements AfterViewInit {

  constructor(private elem: ElementRef) {
  }

  ngAfterViewInit(): void {
    this.elem.nativeElement.setAttribute('azure-id', 'logo');
  }
}
