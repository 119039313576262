import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { ProfileComponentLoader } from '../../../business-framework/config/profile-component-loader';
import { NavigationService } from '../../../core/navigation/services/navigation.service';
import { ProfilesManagementService } from '../../services/profiles-management.service';

export const IsNewUiProfileResolver: ResolveFn<{ profileInfo: { profileId: string, taskCode?: string } } | false> =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): { profileInfo: { profileId: string, taskCode?: string } } | false => {

    const profilesManagementService = inject(ProfilesManagementService);
    const navigationService = inject(NavigationService);

    const profileId = navigationService.getNavigationItem(route.params.profileId)?.data['profileId'];
    if (profileId) {
      const profileInfo = profilesManagementService.getProfileInfo(profileId);
      if (ProfileComponentLoader.isMigratedProfile(profileInfo.profileId)) {
        return { profileInfo };
      } else {
        return false;
      }
      // TODO : uncomment metadata call
      /*      return this.profilesManagementService.businessFrameworkCheck(profileInfo.profileId).then(
              (response) => (response && (profileInfo.taskCode ? response.tasks[profileInfo.profileId] : response.profiles[profileInfo.profileId])) ? { profileInfo } : false
            );*/
    }
  };
