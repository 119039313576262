<app-fn-ui-dialog class="prompt-modal">

  <ng-template #header>
    <div class="prompt-header">
    <span class="title" [translate]="params.title"></span>
    <app-fn-ui-close-icon mat-dialog-close></app-fn-ui-close-icon>
    </div>
    <mat-divider></mat-divider>
  </ng-template>

  <ng-template #body>
    <div class="prompt-body">
      <mat-form-field>
        <span>{{params.message}}</span>
      <input matInput appSelectElement [(ngModel)]="params.name"
              type="text">
      </mat-form-field>
    </div>
  </ng-template>

  <ng-template #footer>
    <div class="prompt-footer">
        <app-fn-ui-button [name]="params.title + ' ' + params.cancelButton" mat-dialog-close>
          <span [translate]="params.cancelButton"></span>
        </app-fn-ui-button>

        <app-fn-ui-button [name]="params.title + ' ' + params.okButton" type="flat" color="primary" mat-dialog-close="{{params.name}}" [disabled]="!params.name">
          <span [translate]="params.okButton"></span>
        </app-fn-ui-button>
    </div>
  </ng-template>

</app-fn-ui-dialog>
