<div class="alert-more-details">

  <div class="header">
      <app-fn-ui-severity-icon [severity]="alert.severity" width="34px" height="27px"></app-fn-ui-severity-icon>
      <div class="alert-name">{{alert.name}}</div>
      <div class="alert-description">{{alert.description}}</div>
  </div>

  <div class="alert-details">
      <div *ngFor="let field of fields" class="field">
          <span class="field-name">{{ 'alert.details.label.' + field.name | translate }}</span>
          <span class="field-value" *ngIf="field.value === 'P_=456' || field.value === 'P_=461' || field.value === 'P_=195' || field.value === 'P_=28'" 
              [ngClass]="{ 'empty' : !alert[field.name] }" style="cursor: pointer;" title="{{ field.value }}">{{ 'alert.details.label.' + field.value | translate}}</span>
    <span class="field-value" *ngIf="field.value !== 'P_=456' && field.value !== 'P_=461' && field.value !== 'P_=195' && field.value !== 'P_=28'" 
      [ngClass]="{ 'empty' : !alert[field.name] }" style="cursor: pointer;" title="{{ field.value }}">{{ field.value }}</span>
      </div>
  </div>


  <div class="footer">
      <app-fn-ui-button name="close-alert-btn"
                        (buttonClick)="onCloseClick()">
        {{ actionLabels.close }}
      </app-fn-ui-button>
      
      <app-fn-ui-button name="investigate"
                        [disabled]="!alert.permittedQueue"
                        [matTooltip]="!alert.permittedQueue ? ('alert.message.not-permitted-queue' | translate) : ''"
                        (buttonClick)="onInvestigateClick()">
        {{ actionLabels.investigate }}
    </app-fn-ui-button>
  </div>

</div>
