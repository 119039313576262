import { Component, Input } from '@angular/core';
import { NavigationResultItem } from '../../models';
import { SearchBoxService } from '../../services/search-box.service';

@Component({
  selector: 'app-navigation-result-item',
  templateUrl: './navigation-result-item.component.html',
  styleUrls: ['./navigation-result-item.component.scss']
})
export class NavigationResultItemComponent {


  @Input() item: NavigationResultItem;

  @Input() searchTerm: string;

  transitionOptions = { reload: false, inherit: false };

  constructor(private searchBoxService: SearchBoxService) { }

  onNavigationItemClick() {
    //For case of routing to current state.
    //Can be removed when navigation-result will be in self state
    this.searchBoxService.closeNavigationBox();
  }

}
