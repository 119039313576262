<app-fa-dialog [title]="'caller.authentication_modal_title' | translate" [body]="bodyTemplate" [footer]="footerTemplate">

  <ng-template #bodyTemplate>
    <form [formGroup]="form" (keyup.enter)="verifyCaller()">
      <app-dh-drilldown [formControlName]="'customerName'"
                        [filterResultByColumn]="customerNameColumn"
                        profileId="191"
                        [id]="'callback-caller-authentication-modal-company'"
                        [label]="'caller.authentication_modal.company' | translate"
                        [filter]="custCodeFilter"
                        [allowMultiSelect]="false"
                        [blockFreeText]="false"
                        (keydown)="onKeyDown($event)"
                        (rowsSelected)="onCompanySelected($event)">
      </app-dh-drilldown>
      <app-dh-drilldown [formControlName]="'callerId'"
                        [filterResultByColumn]="callerIdColumn"
                        profileId="287"
                        [id]="'callback-caller-authentication-modal-caller-id'"
                        [label]="'caller.authentication_modal.caller_id' | translate"
                        [filter]="callerFilter"
                        [allowMultiSelect]="false"
                        [blockFreeText]="true"
                        (rowsSelected)="onCallerSelected($event)">
      </app-dh-drilldown>

      <mat-form-field [floatLabel]="'always'">
        <input matInput
               [disabled]="true"
               [value]="callerPhone1"
               [placeholder]="'callback-caller-authentication-modal-phone1' | translate"
               [id]="'callback-caller-authentication-modal-phone1'">
      </mat-form-field>

      <mat-form-field [floatLabel]="'always'" *ngIf="callerPhone2">
        <input matInput
               [disabled]="true"
               [value]="callerPhone2"
               [placeholder]="'callback-caller-authentication-modal-phone2' | translate"
               [id]="'callback-caller-authentication-modal-phone2'">
      </mat-form-field>

      <mat-form-field [floatLabel]="'always'" *ngIf="showPassword">
        <input matInput
               [autofocus]="true"
               formControlName="password"
               type="password"
               [id]="'callback-caller-authentication-modal-password'"
               [placeholder]="'caller.authentication_modal.password' | translate">
        <mat-error *ngIf="form.controls.password.errors?.loginFailed">{{'caller_profile.password_failed' | translate}}</mat-error>
        <mat-error *ngIf="form.controls.password.errors?.callerNotAvailable">{{'caller_profile.Availability_exceeded' | translate}}</mat-error>
        <mat-error *ngIf="form.controls.password.errors?.invalidDigitalSignature">{{'caller_profile.invalid_digital_signature' | translate}}</mat-error>
      </mat-form-field>
      <span class="callback-caller-authentication-modal-generic-fail" *ngIf="!showPassword && form.controls.password.errors?.loginFailed">{{'caller_profile.generic_fail' | translate}}</span>
    </form>
  </ng-template>

  <ng-template #footerTemplate>
    <button azure-id="callback-caller-authentication-modal-cancel" mat-button color="primary" mat-dialog-close>{{'general.cancel' | translate}}</button>
    <button azure-id="callback-caller-authentication-modal-ok" mat-flat-button color="primary" type="submit" id="callback-caller-authentication-modal-ok" [disabled]="showPassword && form.controls['password'].value === ''" (click)="verifyCaller()">
      {{'general.ok' | translate}}
    </button>
  </ng-template>

</app-fa-dialog>
