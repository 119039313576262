import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NotificationService } from '@fgpp-ui/components';
import { TranslateService } from '@ngx-translate/core';
import { FnUiAutocompleteComponent } from 'projects/gpp/src/app/shared/fn-ui-autocomplete/fn-ui-autocomplete.component';
import {
  ConditionFunction, ConditionOperand, ConditionsBankNode, ContentType, DefaultOperand, FunctionResult, LogicalField, OperandDataType
} from '../../models';
import { RuleCommonService } from '../../services/rule-common.service';
import { RuleService } from '../../services/rule.service';
import { SimpleConditionLogicService } from '../../services/simple-condition-logic.service';
import { InsertFunctionPopoverComponent } from '../insert-function-popover/insert-function-popover.component';

@Component({
  selector: 'app-condition-operand',
  templateUrl: './condition-operand.component.html',
  styleUrls: ['./condition-operand.component.scss']
})
export class ConditionOperandComponent implements OnInit {

  @Input() operand: ConditionOperand;
  @Input() operandDataType: OperandDataType;
  @Input() isRightOperand: boolean;
  @Input() allowedTypes: Array<OperandDataType>;
  @Input() isFocus: boolean;
  @Input() isValid: boolean;
  @Input() invalidValueErrorText: string;

  @Output() operandChanged = new EventEmitter<ConditionOperand>();

  @ViewChild(InsertFunctionPopoverComponent, { static: false }) insertFunctionPopover: InsertFunctionPopoverComponent;
  @ViewChild(FnUiAutocompleteComponent) autoCompleteComponent: FnUiAutocompleteComponent<ConditionsBankNode>;

  operandForAutoComplete: ConditionsBankNode | ConditionOperand;
  isSubjectOfOperandFocus = false;
  functionApplied = false;

  get popoverOpen(): boolean {
    return this.insertFunctionPopover?.popoverOpen || false;
  }

  private _isColonExtractMode = false;
  private _isDropOccurredColonExtract = false;

  constructor(private notificationService: NotificationService,
              private translateService: TranslateService,
              private ruleService: RuleService,
              private ruleCommonService: RuleCommonService,
              private simpleConditionLogicService: SimpleConditionLogicService) { }

  ngOnInit(): void {
    this.operandForAutoComplete = JSON.parse(JSON.stringify(this.operand));
  }

  operandItemSelected(value: ConditionsBankNode): void {
    this.operandValueChanged(value);
  }

  operandTextOverride = (text: string, item: ConditionsBankNode, displayProperty: string): string => {
    let finalText = text;

    if (finalText !== undefined && !finalText.startsWith(':') && this._isColonExtractMode) {
      finalText = ':' + finalText;
    }
    return finalText;
  };

  /**
   * Listens to changes when the user is typing inside the auto complete field and updates the operand
   * and the operand type accordingly. the operand type is always 'Value' when we have a string as a value.
   *
   * @param {string} value
   */
  operandValueChanged(value: ConditionsBankNode | string): void {

    this.addOperandToJustUsedConditions(value);

    if (typeof value === 'string') {
      this.handleString(value);
    } else {
      this.handleObject(value);
    }
    this.operandChanged.emit(this.operand);
  }

  private handleString(value: string): void {
    this.closePopover();
    if (typeof this.operand === 'undefined' ||
      (this.operand.type !== ContentType.VALUE && this.operand.type !== 'InList')) {
      this.operand = {} as DefaultOperand;
    }
    this.operand.alias = value;
    if (this.operand.type !== ContentType.VALUE && this.operand.type !== 'InList') {
      this.operand.type = ContentType.VALUE;
    }
    if (value.trim() === '') {
      this._isColonExtractMode = false;
    }
    this.operandForAutoComplete = this.operand;
  }

  private handleObject(value: ConditionsBankNode): void {
    if (ContentType.BASE_CONDITION === value.contentType) { //if base condition - convert it to function and continue
      const bcFunction = this.ruleService.getFieldAsObject(ContentType.BASE_CONDITION) as ConditionFunction;
      bcFunction.functionArguments[0].value = value.alias;
      value = bcFunction;
    }

    this.operand = value as ConditionOperand;
    this.operandForAutoComplete = value;

    if (this.operand.type === 'function' && !this.functionApplied) {
      this.displayPopover();
      this._isColonExtractMode = false;
    } else {
      this.functionApplied = false;
      this.checkIfBaseConditionExists();

      if (this._isColonExtractMode && !(this.operand as LogicalField).id.startsWith(':')) {
        (this.operand as LogicalField).id = ':' + (this.operand as LogicalField).id;
      }

    }
  }

  private addOperandToJustUsedConditions(value: ConditionsBankNode | string): void {
    if (this.ruleService.justUsedConditions.indexOf((value as ConditionsBankNode).id) < 0) {
      this.ruleService.justUsedConditions.push((value as ConditionsBankNode).id);
    }
  }

  private checkIfBaseConditionExists(): void {
    if ((this.operand as ConditionFunction).id.indexOf(ContentType.BASE_CONDITION) !== -1) {
      if (!(this.operand as ConditionFunction).functionArguments[0].value) {
        return;
      }
      let hasBCInConditionsBank = false;
      for (let i = 0; i < this.ruleService.baseConditions.length; i++) {
        if (this.ruleService.baseConditions[i] === (this.operand as ConditionFunction).functionArguments[0].value) {
          hasBCInConditionsBank = true;
          break;
        }
      }
      if (!hasBCInConditionsBank) {
        this.ruleCommonService.showInvalidActionError('rules.value_on_right_operand_error');
      }
    }
  }

  /**
   * Gets the function outcome and updated the operand accordingly.
   *
   * @param {string} $event
   */
  functionResultHandler($event: FunctionResult): void {
    this.operand = JSON.parse(JSON.stringify(this.operand));
    (this.operand as ConditionFunction).functionArguments.forEach((argument, index) => {
      argument.value = $event.argumentValues[index];
    });
    this.operand.alias = $event.functionAsString;
    this.operandDataType = this.simpleConditionLogicService.getOperandDataType(this.operand);
    this.operandForAutoComplete = this.operand as ConditionFunction;
    this.functionApplied = true;
    this.checkIfBaseConditionExists();
    this.operandChanged.emit(this.operand);
  }

  /**
   * This function is being called whenever the user types and it returns the valid values for the auto complete
   *
   * @param {string} query
   * @returns {array} - a filtered array of values
   */
  dataFilter = (query: string): Array<ConditionsBankNode> => {
    let additionalFilter: (value: ConditionsBankNode) => boolean;
    if (this.isRightOperand) {
      //Base condition is not allowed on right operand
      additionalFilter = this.filterUnsupportedTypes.bind(this);
    }
    return this.ruleCommonService.filterRules(query, additionalFilter);
  };

  trackByFunction(index: number, option: ConditionsBankNode): string {
    return option.alias;
  }

  private filterUnsupportedTypes(value: ConditionsBankNode): boolean {
    return value.contentType !== ContentType.BASE_CONDITION && this.isDataTypeValid(value);
  }

  onDragOver($event: DragEvent): void {
    $event.preventDefault();
  }

  onDrop($event: DragEvent): void {
    $event.preventDefault();
    const dragDataAsText = $event.dataTransfer.getData('text');
    const dragData = JSON.parse(dragDataAsText);
    this.onDropComplete(dragData.data);
  }

  /**
   * Called when an item from the conditions bank is dropped inside the operand
   *
   * @param {object} data
   */
  private onDropComplete(data: ConditionsBankNode): void {
    this.closePopover();

    this._isDropOccurredColonExtract = true;

    if (this.isRightOperand && this.alertIfInvalidType(data, true)) {
      return;
    }

    //we remove the : in case of drop complete event
    this._isColonExtractMode = false;

    if (this.operandForAutoComplete.alias !== data.alias) {
      if (this.operandForAutoComplete.alias?.trim()) {
        this.notificationService.info(this.translateService.instant('rules.drag_and_drop_info', {
          oldVal: this.operandForAutoComplete.alias,
          oldValType: (this.operandForAutoComplete as ConditionsBankNode).contentType,
          newVal: data.alias,
          newValType: data.contentType
        }));
      }
      this.operandForAutoComplete = data;
      this.operandValueChanged(data);
    }
  }

  /**
   * Called when we have a focus (user clicks) on the operand input field
   * the "Insert function" popup will be shown populated with available parameters if we had a function
   * populated in the field. The screen is being rendered to show the popup immediately.
   */

  operandFocused(): void {
    const loseFocusOperand = this.ruleCommonService.loseFocusOperand;
    this.ruleCommonService.loseFocusOperand = null;
    if (loseFocusOperand === this) {
      return;
    }

    const operand = this.operand;

    if (typeof operand === 'object' && operand.type === 'function') {

      if (this.alertIfInvalidType(operand as ConditionFunction, false, true)) {
        this.closePopover();
        return;
      } else {
        this.displayPopover();
      }
    } else {
      this.closePopover();
    }

    setTimeout(() => {
      this.ruleCommonService.operandFocused(this);
    });
  }

  operandUnfocused(isThereValueFromAutocomplete: boolean): void {
    if (this.ruleCommonService.loseFocusOperand) {
      setTimeout(() => {
        this.autoCompleteComponent.focusElement();
      }, 10);
      return;
    }
    let operandObj = this.ruleService.getFieldAsObject(this.operand.alias) as ConditionsBankNode;

    if (this.operand.type === ContentType.VALUE) {

      if (operandObj) {

        if (this.isRightOperand && this.alertIfInvalidType(operandObj, false)) {
          return;
        } else {
          if (operandObj.contentType === ContentType.FUNCTION) {
            operandObj = JSON.parse(JSON.stringify(operandObj));
            this.ruleService.doOperandObjectFromFunction(operandObj as ConditionFunction, this.operand.alias);
            this.functionApplied = true;
          }
          this.operandValueChanged(operandObj);
        }

      } else if (!isThereValueFromAutocomplete && !this.isRightOperand && typeof this.operand.alias !== 'undefined') {

        this.ruleCommonService.showInvalidActionError('rules.value_in_operand_is_invalid');
      }
    } else if (this.operand.type === undefined) {

      if (operandObj !== undefined) {
        this.operandValueChanged(operandObj);
      }
    }

    this.ruleCommonService.operandUnfocused();
  }

  changeOperand(item: ConditionsBankNode): void {
    if (this.isRightOperand && this.alertIfInvalidType(item, false)) {
      return;
    }

    this.operandForAutoComplete = item;
    this.operandValueChanged(item);
  }

  private displayPopover(): void {
    this.ruleCommonService.showFunctionPopover(this);
  }

  private closePopover(): void {
    this.ruleCommonService.closeFunctionPopover();
  }
  /**
   * Checks if the data type of the field is valid, currently used for the right operand types.
   *
   * @param {object} item
   * @returns {boolean}
   * @private
   */
  private isDataTypeValid(item: ConditionsBankNode): boolean {
    if (!this.isRightOperand) {
      return true;
    }
    const type = this.simpleConditionLogicService.getOperandDataType(item as ConditionOperand);
    return this.allowedTypes.indexOf(type) > -1;
  }

  /**
   * If the type of the operand is invalid for use we show relevant error notification.
   * The return value is for the caller function to know if it should stop the function execution or not.
   *
   * @param {object} item
   * @returns {boolean}
   * @private
   */
  private alertIfInvalidType(item: ConditionsBankNode, isFromDropComplete: boolean, isNotShowErrorMessage?: boolean): boolean {

    if (item.contentType === ContentType.BASE_CONDITION) {
      //Base condition is not allowed on right operand
      this.ruleCommonService.showInvalidActionError('rules.base_condition_on_right_operand_error');
      return true;
    } else if (!this.isDataTypeValid(item)) {

      if (typeof isNotShowErrorMessage === 'undefined' || !isNotShowErrorMessage) {

        const errMsg = isFromDropComplete ? 'rules.function.arguments.incorrect_type_drop' : 'rules.function.arguments.incorrect_type';

        this.ruleCommonService.showInvalidActionError(errMsg,
          { itemType: (item as ConditionOperand).type, fieldType: this.allowedTypes.toString() });
      }

      return true;
    }

    return false;
  }

  private isNewStringWithoutStartColon(newstr: string, oldstr: string): boolean {

    if (oldstr != null && oldstr.startsWith(':')) {
      oldstr = oldstr.slice(1);
    }

    return newstr === oldstr;
  }

  onOperandChange(newValue: string, oldValue: string): void {
    this.determinColonExtractMode(JSON.parse(newValue || null), JSON.parse(oldValue || null));

    if (this._isDropOccurredColonExtract) {
      this._isDropOccurredColonExtract = false;
    }
  }

  private determinColonExtractMode(operandNewValue: ConditionOperand, operandOldValue: ConditionOperand): void {
    if (operandNewValue && operandNewValue.alias && (operandNewValue.alias.startsWith(':') ||
      (operandOldValue && operandOldValue.alias && operandOldValue.alias.startsWith(':')))) {

      if (!this._isDropOccurredColonExtract) {
        this._isColonExtractMode = true;
      }
    }
    if (operandNewValue && (operandNewValue.alias === '') ||
      (operandOldValue && this.isNewStringWithoutStartColon(operandNewValue.alias, operandOldValue.alias))) {
      this._isColonExtractMode = false;
    }
    if (operandNewValue && operandNewValue.alias && !operandNewValue.alias.startsWith(':') &&
      (operandOldValue && operandOldValue.alias && !operandOldValue.alias.startsWith(':'))) {
      this._isColonExtractMode = false;
    }
  }

}
