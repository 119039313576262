import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ComponentsModule, NotificationConfigService, NotificationService } from '@fgpp-ui/components';

import { FnUiNotificationConfigService } from './services/fn-ui-notification-config.service';


@NgModule({
    imports: [
        CommonModule,
        ComponentsModule
    ],
    providers: [
        { provide: NotificationConfigService, useClass: FnUiNotificationConfigService }
    ],
    exports: [
        ComponentsModule
    ]
})
export class FnUiNotificationModule { }
