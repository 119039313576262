import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Entity } from '../../../shared/models/enums/entity.enum';
import { Favorite } from '../models/favorite.model';
import { IFavoritesApiService } from './interfaces/favorite-api-service.interface';

@Injectable()
export class FavoritesApiService implements IFavoritesApiService {

    constructor(private http: HttpClient) { }

    getAll(entity: Entity): Observable<Array<Favorite>> {
        return this.http.get<Array<Favorite>>(`do/${entity}/favorites`);
    }

    add(favorite: Favorite, entity: Entity, queueType: string, queueId?: string): Observable<void> {
        const params = new HttpParams().set('queueType', queueType).set('queueId', queueId);
        return this.http.post<void>(`do/${entity}/favorites`, favorite, { params });
    }

    update(favorite: Favorite, favoriteId: string, entity: Entity): Observable<void> {
        return this.http.put<void>(`do/${entity}/favorites/${favoriteId}`, favorite);
    }

    delete(favoriteId: string, entity: Entity): Observable<void> {
        return this.http.delete<void>(`do/${entity}/favorites/${favoriteId}`);
    }

}
