import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiCacheKeys } from '../../../api-caching/models/enums/api-cache-keys.enum';
import { ApiCachingService } from '../../../api-caching/services/api-caching.service';
import { Sitemap } from '../models';
import { ISitemapApiService } from './sitemap-api-service.interface';

@Injectable()
export class SitemapApiService implements ISitemapApiService {

  constructor(private apiCachingService: ApiCachingService) { }

  get(): Observable<Sitemap> {
    const request = {
      key: ApiCacheKeys.SITEMAP,
      url: 'do/sitemap'
    };
    return this.apiCachingService.cacheRequest<Sitemap>(request);
  }
}
