import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-fn-ui-checkbox',
  templateUrl: './fn-ui-checkbox.component.html',
  styleUrls: ['./fn-ui-checkbox.component.scss']
})
export class FnUiCheckboxComponent implements AfterViewInit {

  private checkBoxUniqueId: number;
  private checkBoxId: string;
  private isIntegerValues = false;
  private currentValue;
  private checkBoxViewValue: boolean;

  @Input() isDisabled = false;
  @Input() label: string;
  @Output() valueChange = new EventEmitter();

  @Input()
  get value() {
    return this.currentValue;
  }

  set value(val: boolean) {
    this.onCheckboxChange(val);
    this.valueChange.emit(this.currentValue);
  }
  constructor(private element: ElementRef) {
    this.checkBoxUniqueId = Date.now();
  }

  ngAfterViewInit(): void {
    this.checkBoxId = this.element.nativeElement.getAttribute('id') || this.checkBoxUniqueId++ + 'CB';
    if ( typeof this.value === 'number') {
      this.isIntegerValues = true;
      this.checkBoxViewValue = this.currentValue === 1; // in case its a number convert only the view to boolean
    } else {
      this.checkBoxViewValue = this.value != null ? this.currentValue : false;
    }
    this.element.nativeElement.setAttribute('azure-id', this.checkBoxId);
  }

  private onCheckboxChange(val: boolean): void {
    if (this.isIntegerValues) {
      this.currentValue = val ? 1 : 0;
    } else {
      this.currentValue = val;
    }
  }
}
