export enum CurrencyCfg {
	'CALNAME'= 'CURRENCY_CFG-CALNAME',
	'CURRENCY'= 'CURRENCY_CFG-CURRENCY',
	'EFFECTIVE_DATE'= 'EFFECTIVE_DATE',
	'EURO'= 'CURRENCY_CFG-EURO',
	'NOOFDECIMAL'= 'CURRENCY_CFG-NOOFDECIMAL',
	'PROFILE_CHANGE_STATUS'= 'CURRENCY_CFG-PROFILE_CHANGE_STATUS',
	'RANKING'= 'CURRENCY_CFG-RANKING',
	'REC_STATUS'= 'CURRENCY_CFG-REC_STATUS',
	'TEXT'= 'CURRENCY_CFG-TEXT',
	'UPDATE_DATE'= 'CURRENCY_CFG-UPDATE_DATE',
	'USER_ID'= 'CURRENCY_CFG-USER_ID',
	'TIME_STAMP'= 'TIME_STAMP',
	'UID'= 'VIRTUAL-UID',
	'PROFILE_UPDATE_PK'= 'PROFILE_UPDATE_PK',
	'PU_TIME_STAMP'= 'PU_TIME_STAMP',
	'NOTE'= 'NOTE'
};
