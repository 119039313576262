import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { NotificationService } from '@fgpp-ui/components';
import { TranslateService } from '@ngx-translate/core';
import { ComplexCondition, ConditionOperator, ContentType, OperandDataType, Rule, RuleCriteria, RulePayload, SimpleCondition } from '../../models';
import { OperatorsMap } from '../../models/consts/rule-config.const';
import { RuleCommonService } from '../../services/rule-common.service';
import { RuleConditionsActionsService } from '../../services/rule-conditions-actions.service';
import { RuleConditionsAlgorithmsService } from '../../services/rule-conditions-algorithms.service';
import { RuleConditionsService } from '../../services/rule-conditions.service';
import { RuleModalsService } from '../../services/rule-modals.service';
import { RuleService } from '../../services/rule.service';

@Component({
  selector: 'app-conditions-editor',
  templateUrl: './conditions-editor.component.html',
  styleUrls: ['./conditions-editor.component.scss']
})
export class ConditionsEditorComponent implements OnInit {

  @Input() conditionsData: ComplexCondition;
  @Input() isReadOnly: boolean;
  @Input() isNewRule: boolean;

  readonly maxIndentLevel = 4;

  addDisabled = true;
  removeDisabled = true;
  moveRightDisabled = true;
  moveLeftDisabled = true;
  moveDownDisabled = true;
  moveUpDisabled = true;
  beautifyDisabled = true;
  baseConditionDisabled = true;

  constructor(private translateService: TranslateService,
              private notificationService: NotificationService,
              private ruleCommonService: RuleCommonService,
              private ruleConditionsActionsService: RuleConditionsActionsService,
              private ruleConditionsAlgorithmsService: RuleConditionsAlgorithmsService,
              private ruleConditionsService: RuleConditionsService,
              private ruleModalsService: RuleModalsService,
              private ruleService: RuleService) { }

  ngOnInit(): void {
    this.init();
  }

  onConditionsDataChange(): void {
    this.ruleConditionsService.countTreeLevels(this.conditionsData.conditions, this.conditionsData);
    this.checkBeautifyButton();

    this.ruleConditionsService.checkedConditionsCount = 0;
    this.ruleConditionsService.conditionsCount = 0;
    this.ruleConditionsService.countCheckedConditions(this.conditionsData);
    if (this.ruleConditionsService.checkedConditionsCount === 0) {
      this.addDisabled = false;
      this.removeDisabled = true;
      this.moveDownDisabled = true;
      this.moveUpDisabled = true;
      this.moveLeftDisabled = true;
      this.moveRightDisabled = true;
      this.baseConditionDisabled = true;
    } else if (this.ruleConditionsService.checkedConditionsCount === 1) {
      this.removeDisabled = false;
      this.addDisabled = false;
      this.moveDownDisabled = false;
      this.moveUpDisabled = false;

      if (this.ruleConditionsService.checkedGroup.level > 0) {
        this.moveLeftDisabled = false;
      } else {
        this.moveLeftDisabled = true;
      }

      this.baseConditionDisabled = !this.isAllowedConvertOneConditionToBC();
      this.checkForMoveRightButton();
    } else {
      this.addDisabled = true;
      this.moveDownDisabled = true;
      this.moveUpDisabled = true;
      this.checkForMoveRightMoveLeftAndBaseCondition();
    }

    if (this.conditionsData.conditions.length === 1) {
      this.moveDownDisabled = true;
      this.moveUpDisabled = true;
    }
  }

  private checkForMoveRightButton(): void {
    this.ruleConditionsService.maxTreeLevel < this.maxIndentLevel ? this.moveRightDisabled = false :
      this.moveRightDisabled = this.ruleConditionsService.checkedGroup.level >= this.maxIndentLevel;
  }

  private isAllowedConvertOneConditionToBC(): boolean {
    if (!this.ruleService.isBCAllowed) {
      return false;
    }

    let isAllowedConvertToBC = false;
    const condition = this.ruleConditionsService.getCheckedCondition(this.conditionsData.conditions);
    if (condition && condition.conditionOperator) {
      isAllowedConvertToBC = OperatorsMap.INNER_CONDITIONS_OPERATORS.indexOf(condition.conditionOperator.toLowerCase()) > -1;
    }
    return isAllowedConvertToBC;
  }

  private checkForMoveRightMoveLeftAndBaseCondition(): void {
    if (this.ruleConditionsService.conditionsCount === this.ruleConditionsService.checkedConditionsCount) {
      this.ruleCommonService.rule['RULE_TYPE_ID'] == '45' ? this.baseConditionDisabled = true :
        this.baseConditionDisabled = !this.ruleService.isBCAllowed;
      this._checkForMoveRightMoveLeftAndBaseCondition(false);
    } else {
      this._checkForMoveRightMoveLeftAndBaseCondition(true);
      this.ruleConditionsService.unMarkParentsGroups(this.conditionsData);
    }

    this.ruleConditionsService.unMarkGroupsWithCheckedConditions(this.conditionsData);
  }

  private _checkForMoveRightMoveLeftAndBaseCondition(isCheckBaseCondition: boolean): void {
    if (this.ruleConditionsService.checkIfCheckedConditionsInTheSameGroup()) {
      if (isCheckBaseCondition) {
        this.baseConditionDisabled = !this.ruleService.isBCAllowed;
      }
      this.moveLeftDisabled = this.ruleConditionsService.checkedGroup.level === 0;
      this.checkForMoveRightButton();
    } else {
      this.moveRightDisabled = true;
      this.moveLeftDisabled = true;
      if (isCheckBaseCondition) {
        this.ruleService.isBCAllowed ? this.checkForBaseConditionButton() : this.baseConditionDisabled = true;
      }
    }
  }

  private checkForBaseConditionButton(): void {
    this.ruleConditionsService.countParentsGroups = 0;
    this.ruleConditionsService.markParentsGroups(this.conditionsData);
    this.ruleConditionsService.countParentsGroups === 1 ? this.baseConditionDisabled = false :
      this.checkIfParentsGroupsAreSiblings();
  }

  private checkIfParentsGroupsAreSiblings(): void {
    this.ruleConditionsService.parentsGroupsIsSiblings(this.conditionsData);
    this.baseConditionDisabled = !this.ruleConditionsService.parentGroupsAreSiblings;
  }

  private init(): void {

    //Rule has to have one condition by default on create
    if (this.isNewRule && !this.ruleCommonService.show.isToggleClicked) {
      this.add();
    } else {
      this.checkBeautifyButton();
    }
    if(!this.ruleCommonService.show.isToggleClicked) { // init only in the first time the component initiated
      this.ruleCommonService.initConditions(this.conditionsData);
    }
  }

  checkBeautifyButton(): void {
    this.ruleConditionsService.isRedundantOperators = false;
    this.ruleConditionsService.checkRedundantOperators(this.conditionsData);
    this.beautifyDisabled = !this.ruleConditionsService.isRedundantOperators;
  }

  add(): void {
    this.ruleConditionsActionsService.add(this.ruleConditionsService.conditions, undefined);
  }

  remove(): void {
    this.ruleConditionsActionsService.remove(this.ruleConditionsService.conditions);
  }

  moveRight(): void {
    this.ruleConditionsActionsService.moveRight();
  }

  moveLeft(): void {
    this.ruleConditionsActionsService.moveLeft();
  }

  moveDown(): void {
    this.ruleConditionsActionsService.moveDown();
  }

  moveUp(): void {
    this.ruleConditionsActionsService.moveUp();
  }

  beautify(): void {
    this.ruleConditionsActionsService.beautify(this.ruleConditionsService.conditions);
  }

  convertToBaseCondition(): void {
    const complexCondition = this.ruleConditionsActionsService.convertToBaseCondition();
    const conditions = this.ruleConditionsAlgorithmsService.fromNTreeToBinaryTree(JSON.parse(JSON.stringify(complexCondition)),
      this.ruleService.baseConditions, this.ruleService.flatConditionsBank);

    if (!this.ruleConditionsAlgorithmsService.allRightOperandsFill) {
      this.ruleCommonService.showInvalidActionError('rule.save_rule.missing_data_message');
      return;
    }

    if (!this.ruleConditionsAlgorithmsService.allConditionsValid) {
      this.ruleCommonService.showInvalidActionError('rules.value_on_right_operand_error');
      return;
    }

    this.ruleService.verifyBaseCondition(this.ruleCommonService.rule.OFFICE, conditions).subscribe({
      next: (response: RulePayload) => this.onVerifyBaseConditionSuccess(response, conditions),
      error: (error: HttpErrorResponse) => this.onVerifyBaseConditionError(error)
    });
    this.ruleConditionsService.unMarkParentsGroups(this.conditionsData);
  }

  private onVerifyBaseConditionSuccess(response: RulePayload, conditions: RuleCriteria): void {
    if (JSON.stringify(response.contentHolder) === '{}') {
      this.createNewBaseCondition(conditions);
    } else {
      this.changeCheckedConditionsToExistingBaseCondition(response);
    }
  }

  private createNewBaseCondition(conditions: RuleCriteria): void {
    this.ruleModalsService.openCreateBaseConditionModal().afterClosed().subscribe((data) => {
      if (!!data) {
        const baseCondition = {
          RULE_NAME: data.ruleName,
          DESCRIPTION: data.description,
          CONDITIONS: conditions,
          OFFICE: this.ruleCommonService.rule.OFFICE,
          EFFECTIVE_DATE: (this.ruleCommonService.rule.EFFECTIVE_DATE).toString(), //use EFFECTIVE_DATE ,decision by PO
          EXPIRY_DATE: (this.ruleCommonService.rule.EXPIRY_DATE).toString(),
          RULE_ACTION_UID: 'MET',
          BASE_CONDITION_IND: 1,
          SEC_RULE_ACTION_UID: '0',
          RULE_TYPE_ID: '45',
          DEPARTMENT: this.ruleCommonService.rule.DEPARTMENT
        } as Rule;
        this.ruleService.createRule(baseCondition).subscribe({
          next: () => this.onCreateBaseConditionSuccess(),
          error: (error: HttpErrorResponse) => this.onCreateBaseConditionError(error)
        });
      }
    });
  }

  private changeCheckedConditionsToExistingBaseCondition(response: RulePayload): void {
    this.ruleModalsService.openBaseConditionFoundModal(response.contentHolder.RULE_NAME, 'replaceMode').afterClosed().subscribe((data) => {
      if (!!data) {
        const existingBaseCondition = this.createBaseConditionObject(response);
        this.ruleConditionsActionsService.changeCheckedConditionsToExistingBaseCondition(this.ruleConditionsService.conditions, existingBaseCondition);
      }
    });
  }

  private onCreateBaseConditionSuccess(): void {
    this.notificationService.success(this.translateService.instant('rules.save_base_condition.successfully'));
  }

  private onCreateBaseConditionError(error: HttpErrorResponse): void {
    console.error('Exception: ' + error);
    this.notificationService.error(this.translateService.instant('rules.save_base_condition.unsuccessfully'));
  }

  private onVerifyBaseConditionError(error: HttpErrorResponse) {
    console.error('Exception: ' + error);
  }

  private createBaseConditionObject(responseRule: RulePayload): SimpleCondition {
    const nameExistingBaseCondition = 'BASE_CONDITION(' + responseRule.contentHolder.RULE_NAME + ')';
    const baseCondition = {
      class: 'PRulesConditionBean',
      operator: null,
      leftOperand: {
        alias: nameExistingBaseCondition,
        contentType: ContentType.FUNCTION,
        type: 'function',
        outParameterType: OperandDataType.BOOL,
        description: responseRule.contentHolder.DESCRIPTION,
        id: 'ORACLE^BASE_CONDITION',
        available: true,
        treeAlias: ContentType.BASE_CONDITION,
        functionArguments: [
          {
            id: null,
            alias: 'Name',
            inParams: null,
            outParams: null,
            profileId: null,
            type: OperandDataType.STRING,
            value: responseRule.contentHolder.RULE_NAME
          }
        ]
      },
      rightOperand: {
        alias: 'MET',
        contentType: ContentType.VALUE,
        type: ContentType.VALUE
      },
      leftOperandType: ContentType.FUNCTION,
      conditionOperator: ConditionOperator.IS,
      rightOperandType: ContentType.VALUE,
      not: false
    } as SimpleCondition;
    return baseCondition;
  }

}
