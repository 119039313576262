import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { FormValidatorsService } from '../../../../services/form-validators.service';
import { UserSettingsComponent } from '../user-settings/user-settings.component';
import { SystemVariablesService } from '../../../services/system-variables/system-variables.service';
import { PasswordValidationService } from '../../services/password-validation.service';
import { NotificationService } from '@fgpp-ui/components';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationApiService } from '../../../../authentication/services/authentication-api.service';
import { AuthenticationService } from '../../../../authentication/services/authentication.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  form: FormGroup;
  alerts: Array<any> = [];

  constructor(private systemVariablesService: SystemVariablesService,
              private formBuilder: FormBuilder,
              private formValidatorsService: FormValidatorsService,
              private passwordValidationService: PasswordValidationService,
              private notificationService: NotificationService,
              private $translate: TranslateService,
              private dialogRef: MatDialogRef<UserSettingsComponent>,
              private authenticationService: AuthenticationService,
              private authenticationApiService: AuthenticationApiService) {
  }

  ngOnInit(): void {
    // this._passwordPattern = this.systemVariablesService.getSingleVariable('passwordValidationPattern');
    this.createForm();
  }

  changePassword() {
    this.form.disable();
    const credentials = {
      userId: this.authenticationService.userData.userId,
      password: this.form.get('currentPassword').value,
      newPassword: this.form.get('newPassword').value,
    };
    this.authenticationApiService.changePassword(credentials).toPromise().then(() => {
        this.notificationService.success(this.$translate.instant('general.password_changed'));
        this.form.enable();
      },
        (response: HttpErrorResponse) => {
          if (response.error?.type === 'PasswordStrengthException') {
            const errorMessage = this.$translate.instant('USERS-PASSWORD-STRENGTH-ERROR');
            this.notificationService.error(errorMessage);
          } else if (response.error?.type === 'BadCredentialsException') {
            const errorMessage = this.$translate.instant('USERS-PASSWORD-BAD-CREDENTIALS');
            this.notificationService.error(errorMessage);
          } else if (response.error?.type === 'PasswordHistoryException') {
            const errorMessage = this.$translate.instant('USERS-PASSWORD-HISTORY-ERROR');
            this.notificationService.error(errorMessage);
          } else {
            this.notificationService.error(response.error.message);
          }
          this.form.enable();
        }
      );

    this.form.reset();
    this.form.markAsUntouched();
  }

  cancel() {
    this.dialogRef.close();
  }

  closeAlerts() {
    this.alerts = [];
  }

  private createForm() {
    this.form = this.formBuilder.group({
      'currentPassword': ['', [this.formValidatorsService.MandatoryField()]],
      'newPassword': ['', [this.formValidatorsService.MandatoryField(), this.passwordValidationService.validPassword(true)]],
    });
  }
}
