<div class="search-input-container">
    <div class="search-input">
        <input automation-id="search-input" class="search-input-element"
               [(ngModel)]="searchTerm"
               [placeholder]="placeholder | translate"
               (keyup.enter)="triggerSearch()" #input>
    </div>

  <button mat-icon-button color="primary"
           name="trigger-search-button"
           class="trigger-search-button"
            azure-id="trigger-search"
           [disabled]="disabled"
           (click)="triggerSearch()">
       <app-fn-ui-icon icon="search"></app-fn-ui-icon>
   </button>
</div>
