import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IMessageTemplateDownload } from '../../interfaces/message-template-download.interface';
import { DisclaimerRequest } from '../../models/disclaimer-request.model';
import { NotificationService } from '@fgpp-ui/components';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class MessageTemplateDownloadService implements IMessageTemplateDownload {

  constructor(protected http: HttpClient, public notificationService: NotificationService, public $translate: TranslateService) { }

  downloadTemplateFile(mid: string, data: Object): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.getFileDownload(mid, data)
        .subscribe(
          (res: HttpResponse<any>) => {
            const fileName = this.getFileName(res);
            const fileType = this.getFileType(res);
            this.downloadFile(res.body, fileType, fileName);
            resolve();
          },
          (error) => {
            if (error.status === 500) {
              this.notificationService.error(this.$translate.instant('message.unableToLoadTemplate'));
            }
            else {
              this.notificationService.error(this.$translate.instant('message.unableToIdentifyPaymentType'));
            }
            reject(error);
          }
        );
    });
  }
  private getFileType(res: HttpResponse<any>) {
    return res.headers.get('Content-Type');
  }

  public getFileName(res: HttpResponse<any>) {
    let fileName = 'temp.txt';
    const contentDispositionHeaderList = res.headers.get('content-disposition').split(';');
    for (const item of contentDispositionHeaderList) {
      if (item.includes('filename')) {
        fileName = item.split('=')[1].trim().replace(/['"]+/g, '');
        break;
      }
    }
    return fileName;
  }

  protected getFileDownload(mid: string, data: Object ): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Expose-Headers' : 'Content-Disposition',
      Accept: 'application/json'
    });
    const disclaimerRequest: DisclaimerRequest = {data: JSON.stringify(data)};
    const url = `do/templates/download/${mid}`;
    return this.http.post<any>(url, disclaimerRequest, {headers: headers, responseType: 'blob' as 'json', observe: 'response'})
  }

  private downloadFile(data: any, type: string, fileName: string) {
    const a = document.createElement('a');
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
  }
}
