import { ButtonType } from '../enums/button-type.enum';

export const ButtonTypeClass = {
    [ButtonType.BASIC]: '',
    [ButtonType.RAISED]: 'mat-mdc-raised-button',
    [ButtonType.STROKED]: 'mat-mdc-outlined-button',
    [ButtonType.FLAT]: 'mat-mdc-unelevated-button',
    [ButtonType.ICON]: 'mat-icon-button',
    [ButtonType.FAB]: 'mat-fab',
    [ButtonType.MINI_FAB]: 'mat-mini-fab'
};
