import { Component, Inject } from '@angular/core';
import { NotificationService } from '@fgpp-ui/components';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public params,
              private notificationService: NotificationService,
              private translateService: TranslateService) { }

  copySuccess() {
    this.notificationService.success(this.translateService.instant(
      'modal.clipboard_message.successfully',
      { count: this.params.count,
        listName: this.params.listName }));
  }

  copyFailed(error) {
    console.log('copyFailed', error);
    this.notificationService.error(this.translateService.instant(
      'modal.clipboard_message.unsuccessfully',
      { listName: this.params.listName }));
  }

}
