import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { LiquidityNode } from '../../models';
import { LiquidityService } from '../../services/liquidity.service';

export const LiquidityTreeResolver: ResolveFn<LiquidityNode> =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LiquidityNode> => {

    const liquidityService = inject(LiquidityService);

    const taskBarItem = route.params.taskBarItem;
    const taskBarItemService = liquidityService.getTaskBarItemService(taskBarItem);
    if (taskBarItemService) {
      return taskBarItemService.getTree();
    }
  };
