import { Component, OnInit } from '@angular/core';
import { AbstractProfileFormComponent } from '../../abstract/abstract-profile/abstract-profile-form.component';
import { ProfileFormTemplateProviders } from '../../../core/templates/profile-form.template.providers';
import { CurrencyCfg } from '../../models/currency-cfg.model';

@Component({
  selector: 'app-currency-cfg',
  templateUrl: '../../../core/templates/profile-form.template.html',
  styleUrls: ['../../../core/templates/profile-form.template.scss'],
  providers: ProfileFormTemplateProviders
})
export class CurrencyCfgComponent extends AbstractProfileFormComponent implements OnInit {
  fgppFormLoadComplete(): void {
    if (!this.formMgr.get(CurrencyCfg.NOOFDECIMAL).getValue()) {
      this.formMgr.get(CurrencyCfg.NOOFDECIMAL).setValue(2);
    }
  }
}
