import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ApiCachingModule } from '../core/api-caching/api-caching.module';
import { FnUiIconModule } from '../shared/fn-ui-icons/fn-ui-icon/fn-ui-icon.module';
import { authenticationRoutes } from './config/authentication.routes';
import { ActivateSoftTokenComponent } from './components/activate-soft-token/activate-soft-token.component';
import { AssignSoftTokenComponent } from './components/assign-soft-token/assign-soft-token.component';
import { CallbackComponent } from './components/callback/callback.component';
import { ChangeExpiredPasswordComponent } from './components/change-expired-password/change-expired-password.component';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { PasswordPolicyComponent } from './components/password-policy/password-policy.component';
import { SignInPageComponent } from './components/sign-in-page/sign-in-page.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { TokenTwoAuthComponent } from './components/token-two-auth/token-two-auth.component';

import { DisableControlDirective } from './directives/disable-control.directive';
import { EqualValidator } from './directives/equal-validator.directive';

import { AuthenticationApiService } from './services/authentication-api.service';
import { AuthenticationConfigService } from './services/authentication-config.service';
import { AuthenticationGuardService } from './services/authentication-guard.service';
import { AuthenticationIdpService } from './services/authentication-idp.service';
import { AuthenticationService } from './services/authentication.service';
import { SecurityService } from './services/security.service';
import { SignOutService } from './services/sign-out.service';
import { SignOutComponent } from './components/sign-out/sign-out.component';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    TranslateModule.forChild(),
    RouterModule.forChild(authenticationRoutes),
    ApiCachingModule,
    FnUiIconModule
  ],
  declarations: [
    ActivateSoftTokenComponent,
    AssignSoftTokenComponent,
    CallbackComponent,
    ChangeExpiredPasswordComponent,
    ErrorPageComponent,
    PasswordPolicyComponent,
    SignInComponent,
    SignInPageComponent,
    TokenTwoAuthComponent,
    DisableControlDirective,
    EqualValidator,
    SignOutComponent
  ],
  providers: [
    AuthenticationApiService,
    AuthenticationConfigService,
    AuthenticationGuardService,
    AuthenticationIdpService,
    AuthenticationService,
    SecurityService,
    SignOutService,
  ],
  exports: [
    PasswordPolicyComponent,
    SignInComponent,
    SignInPageComponent
  ]
})
export class AuthenticationModule { }
