import { FgppButtonProps } from '../../core/utils/fgpp-button-props.utils';
import { ButtonType } from '../../core/enums/button-type.enum';
import { ButtonCategory } from '../../core/enums/button-category.enum';
import { ISdkProfileFooterReducer } from '../../profiles/interfaces/profile-footer-reducer.interface';
import { IProfileFooter } from '../../profiles/interfaces/profile-footer.interface';
import { IProfileLayoutSelectionMetadata } from '../../profiles/interfaces/profile-layout-selection-metadata.interface';
import { StandardProfileReducer } from '../../profiles/utils/standard-profile.reducer';
import { ProfileLoadType } from '../../profiles/enums/profile-load-type.enum';
import { ProfileChangeStatus } from '../../profiles/enums/profile-change-status.enum';

export class StandardTaskReducer extends StandardProfileReducer implements ISdkProfileFooterReducer {
  public reduce(buttonCategory: ButtonCategory, footer: IProfileFooter, inputs: IProfileLayoutSelectionMetadata): IProfileFooter {
    const ExecuteButton = new FgppButtonProps('EXECUTE', ButtonType.FLAT, 6);
    const HistoryButton = new FgppButtonProps('EXECUTION_HISTORY', ButtonType.MENU, 12);

    this.processStandardProfileButtons(buttonCategory, footer, inputs);

    if (inputs.loadType === ProfileLoadType.EDIT) {
      if (inputs.profileChangeStatus !== ProfileChangeStatus.FUTURE) {
        this.buttonList.push(ExecuteButton);
      }
      this.buttonList.push(HistoryButton);
    }

    return this.footerService.retain(buttonCategory, this.buttonList).getFooter();
  }
}
