import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { Search } from '../models/search.model';
import { MenuService } from '../../services/menu.service';
import { NavigationBoxMode, SearchBoxItem } from '../models';
import { ISearchBoxService } from './interfaces/search-box-service.interface';
import { AdvancedSearchService } from '../../../messages/services/advanced-search.service';

@Injectable()
export class SearchBoxService implements ISearchBoxService {

  private _searchTerm$ = new BehaviorSubject('');
  private _searchBoxItem$ = new BehaviorSubject(null);
  private _navigationBoxMode$ = new BehaviorSubject(NavigationBoxMode.CLOSED);

  get searchTerm$(): Observable<string> {
    return this._searchTerm$.asObservable();
  }

  get searchBoxItem$(): Observable<SearchBoxItem> {
    return this._searchBoxItem$.asObservable();
  }

  get navigationBoxMode$(): Observable<NavigationBoxMode> {
    return this._navigationBoxMode$.asObservable();
  }

  constructor(private router: Router,
              private advancedSearchService: AdvancedSearchService,
              private menuService: MenuService) { }

  emitSearchTerm(term: string): void {
    this._searchTerm$.next(term);
  }

  emitSearchBoxItem(item: SearchBoxItem): void {
    this._searchBoxItem$.next(item);
  }

  openNavigationBox(): void {
    if (this.router.url.indexOf('page-not-found') > -1) {
      const topLevelMenu = this.menuService.getTopLevelMenu();
      this.router.navigate([topLevelMenu.routerLink]);
    }
    this._navigationBoxMode$.next(NavigationBoxMode.OPEN);
  }

  closeNavigationBox(): void {
    this._navigationBoxMode$.next(NavigationBoxMode.CLOSED);
  }

  applySearch(search: Search): Promise<boolean> | void {
    const searchFn = this[search.type];
    if (typeof search.data === 'string') {
      search.data = search.data?.trim();
    }
    return searchFn.call(this, search);
  }

  private quickSearch(search: Search): Promise<boolean> {
    if (!search.entity || !search.data?.length) {
      return null;
    }
    return this.goToRecentSearches(search);
  }

  private advancedSearch(search: Search): Promise<boolean> {
    return this.advancedSearchService.search().then((data) => {
      search.data = data;
      return this.goToRecentSearches(search);
    });
  }

  private navigationSearch(search: Search): void {
    if (!search.data.length) {
      return;
    }
    this.emitSearchTerm(search.data);
    this.openNavigationBox();
  }

  private goToRecentSearches(search: Search): Promise<boolean> {
    return this.router.navigate(['/home/messages-center/recent-searches'], { queryParams: { search: JSON.stringify(search), skipLocationChange: true } });
  }

}
