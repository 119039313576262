import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthenticationService } from '../../../authentication/services/authentication.service';
import { NavigationService } from './navigation.service';

@Injectable()
export class NavigationGuardService implements CanActivate {

  // private readonly _allowedStates = ['home', 'home.views', 'home.oops-page',
  //   'user.signin', 'user.signout', 'user.token', 'user.assignSoftToken', 'user.activateSoftToken', 'user.changePassword', 'callback',
  //   'popOut.messageDisclaimer', 'popOut.messageDisclaimerPreview'];

  constructor(private authenticationService: AuthenticationService,
              private navigationService: NavigationService, private router: Router) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if (!this.authenticationService.isUserDataSet) {
      return true;
    }

    await this.navigationService.getSitemap();

    const url = state.url;

    const isUrlAllowed = this.navigationService.isUrlAllowed(url);

    if (isUrlAllowed) {
      return true;
    }
    return this.router.navigateByUrl('/home/page-not-found', { replaceUrl: true });
  }
}
