import {Component, Input, OnInit} from '@angular/core';
import {NavigationService} from "../../../../../core/navigation/services/navigation.service";
import {TranslateService} from "@ngx-translate/core";
import {RulesAssociationCommonService} from "../../services/rules-association-common.service";
import {RulesAssociationApiService} from "../../services/rules-association-api.service";
import {FnUiDialogService} from "../../../../../shared/fn-ui-dialog/services/fn-ui-dialog.service";
import {ConfirmModalComponent} from "../../../../../shared/fn-ui-modals/components/confirm-modal/confirm-modal.component";
import {ModalSize} from "../../../../../shared/fn-ui-modals/models/enums/modal-size.enum";
import {PROFILE_ID} from 'projects/gpp/src/app/profiles/components/profiles-grid/profiles-grid.component';

@Component({
  selector: 'app-rules-association-content-area',
  templateUrl: './rules-association-content-area.component.html',
  styleUrls: ['./rules-association-content-area.component.scss']
})
export class RulesAssociationContentAreaComponent implements OnInit {
  @Input() rulesAssociationDetails: any;
  departments: string[];
  shouldRenderDatePicker: boolean = true;
  MIN_EFFECTIVE_DATE;
  inParamsActionFilter: {};
  ruleAssociationProfileId = 543;
  layerClassificationProfileId = 645;
  subTypeProfileId = '';
  isEditable: boolean = true;
  public inParamsRuleTypeFilter1: {};
  public inParamsLayerClassificationFilter: {};
  public isReadOnly: boolean;
  private showGeneralDetails: boolean = true;
  public disableActions: boolean = true;
  public inParamsSubTypeFilter: {};
  private isCreateMode: boolean;
  private showRuleSubType: boolean = false;
  public disableFormFields: boolean = true;
  private ruleSubTypeFlag: boolean = false;
  public  objectTypeProfileId: any;
  private objectTypeProfileValue: any;
  private fieldAlias: any;
  private ruleStatuses: any = {};
  private isInitiator: any;
  public isWritable: any;

  constructor(private navigationService: NavigationService,
              private translate: TranslateService,
              public rulesCommonService: RulesAssociationCommonService,
              private rulesAssociationApiService: RulesAssociationApiService,
              private dialogService: FnUiDialogService) {
  }

  ngOnInit(): void {
    this.departments = this.getDepartments(this.rulesAssociationDetails.OFFICE);
    this.ruleStatuses = this.rulesCommonService.ruleAssociationStatuses;
    this.isInitiator = this.ruleStatuses.isInitiator;
    this.isCreateMode = this.ruleStatuses.isCreateMode;
    this.isWritable = this.ruleStatuses.isWritable;

    this.rulesCommonService.UID = this.rulesAssociationDetails.UID;

    this.rulesCommonService.selectedRuleTypeId = this.rulesAssociationDetails.RULE_TYPE_ID;
    this.rulesCommonService.RULE_SUB_TYPE = this.rulesAssociationDetails.RULE_SUB_TYPE;
    this.rulesCommonService.selectedRuleTypeName = this.rulesAssociationDetails.RULE_TYPE_NAME;
    this.rulesCommonService.selectedAssociatedTo = this.rulesAssociationDetails.OBJECT_UID;
    this.rulesCommonService.selectedObjectType = '';
    this.rulesCommonService.isReadOnlyMode = false;
    this.rulesCommonService.rule = this.rulesAssociationDetails;
    // TODO remove spread operator
    this.rulesCommonService.originalRule = {...this.rulesAssociationDetails};
    this.rulesCommonService.ObjectsDataOptions = [];

    this.checkIfReadOnly();
    this.getRuleTypeInParams();

    //Classification
    this.getLayerClassificationInParams();
    this.rulesCommonService.LAYER_CLASSIFICATION_RELATION = "";
    this.rulesCommonService.LAYER_CLASSIFICATION = this.rulesAssociationDetails.LAYER_CLASSIFICATION;
    this.rulesCommonService.ALL_LAYER = this.rulesAssociationDetails.ALL_LAYER;

    //Sub-type
    if (this.rulesAssociationDetails.ATTACHMENT_CONTENTS_SOURCE != null) {
      this.setSelectedObjetType(this.rulesAssociationDetails.ATTACHMENT_CONTENTS_SOURCE);
    }

    if ((this.rulesAssociationDetails.RULE_SUB_TYPE !== '' ||
      this.rulesAssociationDetails.RULE_SUB_TYPE !== null) && this.ruleStatuses.isEditMode) {
      this.rulesAssociationDetails.showRuleSubType = true;
    }

    if (this.ruleStatuses.isEditMode) {
      this.MIN_EFFECTIVE_DATE = this.rulesAssociationDetails.businessDate;
    } else {
      this.MIN_EFFECTIVE_DATE = this.rulesAssociationDetails.EFFECTIVE_DATE;
    }
  }

  private getDepartments(office: string) {
    return this.navigationService.getOfficeDepartments(office);
  }

  public getRuleTypeInParams() {
    this.rulesCommonService.closePreviewAndResetFilter();
    this.inParamsActionFilter = {
      searchFilter: {
        'PRULE_TYPES.VISIBLE_IN_LIST': '1',
        'PRULE_TYPES.ATTACHMENT_CONTENTS_SOURCE': 'P_*',
      },
      ExternalParams: 'RULE_TYPE=WRITE'
    };
    this.inParamsRuleTypeFilter1 = {
      searchFilter: {
        'FROM_PROFILE_ID': PROFILE_ID.RULE_ASSOCIATION,
        'PROFILE_OFFICE': this.rulesAssociationDetails.OFFICE
      }
    };
  }

  //Classification
  public getLayerClassificationInParams() {
    this.rulesCommonService.closePreviewAndResetFilter();
    this.inParamsLayerClassificationFilter = {
      ExternalParams: this.rulesAssociationDetails.RULE_TYPE_ID
    };
  }

  public handleInputParams(inputParams) {
    const inputParamsCopy = {...inputParams}; //we do not want to override original data
    for (let key in inputParamsCopy) {
      const index = inputParamsCopy[key].indexOf('.');
      if (index > -1) {
        const param = inputParamsCopy[key].substr(index + 1);
        inputParamsCopy[key] = this.rulesAssociationDetails[param];
      }
    }
    return inputParamsCopy;
  }

  public checkIfReadOnly() {
    this.isReadOnly = this.ruleStatuses.isReadOnly;
    this.rulesCommonService.isInitiator = this.ruleStatuses.isInitiator;
    this.rulesCommonService.isChangeStatusPN = this.ruleStatuses.isChangeStatusPN;
    this.rulesCommonService.isRuleForApproval = this.ruleStatuses.isRuleForApproval;
    if (this.isReadOnly) {
      this.rulesCommonService.isReadOnlyMode = this.isReadOnly;
      let message: string;
      if ((this.ruleStatuses.isRuleForApproval && this.ruleStatuses.loadType !== '3') ||
        (!this.ruleStatuses.isRuleForApproval && this.ruleStatuses.loadType === '3' && this.ruleStatuses.isInitiator && !this.ruleStatuses.isEditable)) {
        message = this.translate.instant('general.pending_approval_profile_locked_message', {profile: 'Rule Association'});
      } else if (this.ruleStatuses.loadType == 2 && this.ruleStatuses.isChangeStatusFU) {
        message = this.translate.instant('general.future_profile_lock_message', {profile: 'Rule Association'});
      } else if (this.ruleStatuses.loadType == 2 && this.rulesAssociationDetails.LOCKED_BY_USER === 'system') {
        message = this.translate.instant('general.profile_locked_message_onboarding', { profile: 'Rule Association' });
      } else {
        message = this.translate.instant('general.profile_locked_message', {
          profile: 'Rule Association',
          user: this.rulesAssociationDetails.LOCKED_BY_USER
        });
      }
      this.rulesCommonService.modalVisible = true;
      this.dialogService.open(ConfirmModalComponent, {
        width: ModalSize.MEDIUM,
        data: {
          title: this.translate.instant('general.profile_locked_title', {profile: 'Rule Association'}),
          message: message,
          okButton: 'general.ok',
          cancelButton: 'general.cancel'
        }
      }).afterClosed().subscribe((choice) => {
        choice === 'ok' ? this.rulesCommonService.modalVisible = false : this.returnToGrid();
      });

    } else if ((this.ruleStatuses.loadType === '3' &&
      (!this.ruleStatuses.isInitiator || (this.ruleStatuses.isInitiator && !this.ruleStatuses.isEditable))) ||
      this.ruleStatuses.isOnHold || this.ruleStatuses.isDeleted) {
      this.isEditable = false;
    } else if (this.ruleStatuses.isStatusDeleted || this.ruleStatuses.isStatusHold) {
      this.isEditable = false;
      this.rulesCommonService.modalVisible = false;
    }
    this.rulesCommonService.controlListItemMovementOptions();
  }

  returnToGrid() {
    this.rulesCommonService.isNeedCheckBeforeClose = false;
    this.rulesCommonService.closeRuleAssociation();
  }

  toggleGeneralDetailsPane() {
    this.showGeneralDetails = !this.showGeneralDetails;
  }

  onRuleTypeSelected(selectedRows) {
    this.rulesCommonService.closePreviewAndResetFilter();
    this.rulesCommonService.resetListSection();
    this.disableActions = false;
    this.disableFormFields = true;

    this.showRuleSubType = false;
    this.rulesAssociationDetails.RULE_SUB_TYPE = '';
    this.rulesCommonService.RULE_SUB_TYPE = '';
    this.rulesAssociationDetails.ruleSubTypeValue = '';

    this.resetObjectTypeFields();

    this.rulesCommonService.ALL_LAYER = '';
    this.rulesAssociationDetails.LAYER_CLASSIFICATION = '';
    this.rulesCommonService.LAYER_CLASSIFICATION_RELATION = '';
    this.rulesAssociationDetails.LAYER_CLASSIFICATION_ALIAS = '';
    this.rulesCommonService.rule.dataChanged = true;

    const selected = selectedRows[0];
    this.rulesAssociationDetails.RULE_TYPE_ID = selected['PRULE_TYPES-RULE_TYPE_ID'];
    this.rulesAssociationDetails.RULE_TYPE_NAME = selected['PRULE_TYPES-RULE_TYPE_NAME'];
    this.rulesCommonService.ruleTypeDetails.next({RULE_TYPE_NAME: selected['PRULE_TYPES-RULE_TYPE_NAME']});
    this.rulesCommonService.selectedRuleTypeId = selected['PRULE_TYPES-RULE_TYPE_ID'];
    this.rulesAssociationDetails.CLASSIFICATION_LEVEL = selected['PRULE_TYPES-CLASSIFICATION_LEVEL'];

    this.ruleSubTypeFlag = false;
    this.rulesAssociationApiService.getRuleTypeDetails({
      'typeId': selected['PRULE_TYPES-RULE_TYPE_ID'],
      'office': this.rulesAssociationDetails.OFFICE
    }).subscribe({
      next: (data) => {
        if (data.businessDate !== '' && data.businessDate) {
          this.rulesAssociationDetails.EFFECTIVE_DATE = parseInt(data.businessDate);
          this.rulesAssociationDetails.businessDate = parseInt(data.businessDate);
          this.MIN_EFFECTIVE_DATE = parseInt(data.businessDate);
        }
        if (data.ruleSubType.profileId != '') {
          this.disableFormFields = true;
          this.handleRuleSubType(data);
        } else {
          this.rulesAssociationDetails.RULE_SUB_TYPE = '0';
          if (data.ruleSubType.profileId === '') {
            this.rulesAssociationDetails.ruleSubTypeValue = 'N/A';
            this.ruleSubTypeFlag = true;
          }
          this.disableFormFields = false;
          this.setCommonData();
        }
      },
      error: (error) => {
        console.error('Exception', error);
      }
    });
    this.getLayerClassificationInParams();
    this.rulesCommonService.enableAddButton();
  }

  //New feature for rule evaluation F8194 -Run Time classification and evaluation per class - START
  onLayerClassificationSelected(selectedRows) {
    this.rulesCommonService.closePreviewAndResetFilter();
    this.rulesCommonService.resetListSection();
    this.disableActions = false;
    const selected = selectedRows[0];
    this.rulesCommonService.LAYER_CLASSIFICATION_RELATION = selected['LAYER_CLASSIFICATION_RELATIONS-CLASSIFICATION_RELATIONS'];
    this.rulesAssociationDetails.LAYER_CLASSIFICATION = selected['LAYER_CLASSIFICATION_RELATIONS-CLASSIFICATION_KEY'];
    this.rulesAssociationDetails.LAYER_CLASSIFICATION_ALIAS = selected['LAYER_CLASSIFICATION_RELATIONS-ALIAS'];
    this.rulesCommonService.ALL_LAYER = this.rulesAssociationDetails.LAYER_CLASSIFICATION + ',' + this.rulesCommonService.LAYER_CLASSIFICATION_RELATION;
    if (this.rulesAssociationDetails.OBJECT_UID != '') {
      const UID = this.createUID();
      /*      this.rulesAssociationApiService.getRuleAssociation({
                'ruleAssociationId': UID,
                'loadType': '2'
              }
            ).subscribe((data) => {
              this.setRuleAssociationDetails(data);
            }, (error) => {
              this.rulesCommonService.associationExists = false;
              this.rulesCommonService.createMode = true;
              this.rulesCommonService.RULES = [];
              this.rulesCommonService.virtualList.length = 0;
            });*/
    }
  }

  // New feature for rule evaluation F8194 -Run Time classification and evaluation per class - END
  ruleObjectTypeCommonData(data) {
    var result = data.contentHolder.DATA[0];
    if (data.contentHolder.DATA.length > 0) {
      this.rulesCommonService.ObjectsData = data.contentHolder.DATA[0].OBJECTS;
      if (this.rulesCommonService.ObjectsData) {
        var keys = Object.keys(this.rulesCommonService.ObjectsData);
        keys.forEach((key) => {
          var value = this.rulesCommonService.ObjectsData[key];
          this.rulesCommonService.ObjectsDataOptions.push({'key': key.substring(3), 'value': value});
        });
      }
      this.rulesCommonService.objectTypeOffice = result['OBJECT_PRULE_TYPES.OFFICE'];
      this.rulesCommonService.selectedRuleTypeName = result['PRULE_TYPES.RULE_TYPE_NAME'];
      this.rulesCommonService.outputParam = result['PRULE_TYPES.ATTACHMENT_OUTPUT_PARAMS'];
      this.rulesAssociationDetails.selectedObjectType = this.rulesCommonService.ObjectsDataOptions[0].key;
      this.rulesAssociationDetails.selectedObjectTypeValue = this.rulesCommonService.ObjectsDataOptions[0].value;
      if (this.rulesAssociationDetails.selectedObjectType) {
        this.rulesAssociationDetails.OBJECT_TYPE = 'P_=' + this.rulesAssociationDetails.selectedObjectType;
      }
      this.rulesCommonService.selectedObjectType = this.rulesAssociationDetails.selectedObjectType;
      this.objectTypeProfileId = this.rulesAssociationDetails.selectedObjectType;
    }
    this.rulesAssociationDetails.objectTypeProfileId = '';
    this.rulesCommonService.removeAll();
    this.renderDatePickers();
  }

  renderDatePickers() {
    this.shouldRenderDatePicker = false;
  }

  onObjectTypeProfileIdSelected(selectedRows) {
    this.rulesCommonService.closePreviewAndResetFilter();
    this.rulesCommonService.resetListSection();
    const objectName = this.getObjectName(selectedRows[0]);
    this.objectTypeProfileValue = objectName;
    this.rulesAssociationDetails.OBJECTID_NAME = objectName;
    this.rulesCommonService.ruleTypeDetails.next({
      RULE_TYPE_NAME: this.rulesAssociationDetails.RULE_TYPE_NAME,
      associatedTo: objectName
    });
    this.rulesAssociationDetails.OBJECT_UID = selectedRows[0]['VIRTUAL.UID'];
    this.rulesAssociationDetails.objectTypeProfileId = objectName;
    this.rulesCommonService.selectedAssociatedTo = objectName;
    this.rulesCommonService.RULE_SUB_TYPE = this.rulesAssociationDetails.RULE_SUB_TYPE;
    const UID = this.createUID();

    /*    const rulesAssociationPromise = this.rulesAssociationApiService.getRuleAssociation({
            'ruleAssociationId': UID,
            'loadType': '2'
          }
        ).subscribe((data) => {
        }, (error) => {
        });*/
    this.rulesCommonService.enableAddButton();
  }

  getObjectName(row) {
    if (this.rulesAssociationDetails.selectedObjectType === '452') {
      this.rulesAssociationDetails.OBJECTID_DESCR = row['MESSAGE_STP_FIELD_RULE-FIELD_LOGICAL_ID'];
      return row['MESSAGE_STP_FIELD_RULE-FIELD_LOGICAL_ID'];
    } else if (this.rulesAssociationDetails.selectedObjectType === '27') {
      this.rulesAssociationDetails.OBJECTID_DESCR = row['BANKS-OFFC_NAME'];
      return row['BANKS-OFFICE'];
    } else if (this.rulesAssociationDetails.selectedObjectType === '191') {
      this.rulesAssociationDetails.OBJECTID_DESCR = row['CUSTOMRS-CUST_NAME'];
      return row['CUSTOMRS-CUST_CODE'];
    } else if (this.rulesAssociationDetails.selectedObjectType === '15') {
      this.rulesAssociationDetails.OBJECTID_DESCR = row['STATUSES-ALIAS'];
      return row['STATUSES-MSG_STATUS'];
    } else if (this.rulesAssociationDetails.selectedObjectType === '32') {
      this.rulesAssociationDetails.OBJECTID_DESCR = row['MOP-DESCRIPTIN'];
      return row['MOP-MOP'];
    } else if (this.rulesAssociationDetails.selectedObjectType === '429') {
      this.rulesAssociationDetails.OBJECTID_DESCR = row['MSG_TYPES-DESCRIPTION'];
      return row['MSG_TYPES-MSG_TYPE'];
    } else if (this.rulesAssociationDetails.selectedObjectType === '451') {
      this.rulesAssociationDetails.OBJECTID_DESCR = row['MESSAGE_STP_RULES-MESSAGE_STP_RULES_DESCR'];
      return row['MESSAGE_STP_RULES-MESSAGE_STP_RULES_NAME'];
    } else if (this.rulesAssociationDetails.selectedObjectType === '456') {
      this.rulesAssociationDetails.OBJECTID_DESCR = row['INTERFACE_TYPES-INTERFACE_NAME'];
      return row['INTERFACE_TYPES-INTERFACE_NAME'];
    } else if (this.rulesAssociationDetails.selectedObjectType === '79') {
      this.rulesAssociationDetails.OBJECTID_NAME = row['BULKING_PROFILE-DESCRIPTION'];
      return row['BULKING_PROFILE-BP_NAME'];
    } else if (this.rulesAssociationDetails.selectedObjectType === '440') {
      this.rulesAssociationDetails.OBJECTID_DESCR = row['GENERATE_TRANS_PROFILE-DESCRIPTION'];
      return row['GENERATE_TRANS_PROFILE-NAME'];
    } else if (this.rulesAssociationDetails.selectedObjectType === '378') {
      this.rulesAssociationDetails.OBJECTID_DESCR = row['REPORT_SENDING_PREFERENCES-DESCRIPTION'];
      return row['REPORT_SENDING_PREFERENCES-RP_NAME'];
    } else {
      return row['VIRTUAL.UID'];
    }
  }

  handleRuleSubType(data) {
    this.rulesAssociationDetails.subType = data.ruleSubType;
    this.rulesAssociationDetails.RULE_SUB_TYPE = '';
    this.rulesAssociationDetails.ruleSubTypeValue = '';
    if (this.rulesAssociationDetails.subType.profileId !== '') {
      this.showRuleSubType = true;
      if (this.rulesAssociationDetails.subType.profileId.indexOf('P_=') === 0) {
        this.subTypeProfileId = this.rulesAssociationDetails.subType.profileId.substr(3);
      } else {
        this.subTypeProfileId = this.rulesAssociationDetails.subType.profileId;
      }
      this.getSubTypeFilter();
      if (this.isCreateMode) {
        this.blockActionsUntilSubTypeChosen();
      }
    } else {
      this.showRuleSubType = false;
    }
  }

  getSubTypeFilter() {
    this.inParamsSubTypeFilter = {
      type: 'SUB_TYPE',
      searchFilter: this.handleInputParams(this.rulesAssociationDetails.subType.inputParams)
    };
  }

  blockActionsUntilSubTypeChosen() {
    this.disableActions = true;
  }

  enableActions() {
    this.disableActions = false;
  }

  onRuleSubTypeSelect(selectedRows) {
    this.rulesCommonService.closePreviewAndResetFilter();
    this.rulesCommonService.resetListSection();
    this.disableFormFields = false;
    this.resetObjectTypeFields();
    this.handleOutputParams(this.rulesAssociationDetails.subType.outputParams, selectedRows);
    this.getSubTypeFilter();
    this.enableActions();
    this.setCommonData();
    this.rulesCommonService.enableAddButton();
  }

  setObjectTypeProfile(selection) {
    this.rulesCommonService.undoStack = [];
    this.rulesCommonService.redoStack = [];
    this.objectTypeProfileId = this.rulesAssociationDetails.selectedObjectType;
    this.rulesCommonService.selectedObjectType = this.objectTypeProfileId;
    if (this.rulesAssociationDetails.selectedObjectType) {
      this.rulesAssociationDetails.OBJECT_TYPE = 'P_=' + this.rulesAssociationDetails.selectedObjectType;
    }
    this.rulesAssociationDetails.OBJECTID_DESCR = '';
    this.rulesAssociationDetails.selectedAssociatedTo = '';
    this.rulesCommonService.selectedAssociatedTo = '';
    this.rulesAssociationDetails.OBJECTID_NAME = '';
  }

  handleOutputParams(outputParams, selectedRows) {
    const keys = Object.keys(outputParams);
    for (let i = 0; i < keys.length; i++) {
      let ruleParam;
      if (keys[i].indexOf('.') > -1) {
        ruleParam = keys[i].split('.')[1];
      }
      let value = selectedRows[0][outputParams[keys[i]]];
      if (!value) {
        value = selectedRows[0][outputParams[keys[i]].replace('.', '-')];
      }
      this.rulesAssociationDetails[ruleParam] = value;
      this.rulesAssociationDetails.ruleSubTypeValue = value;
    }
  }

  createUID() {
    const UID = this.rulesCommonService.selectedRuleTypeId +
      '^' + this.rulesAssociationDetails.RULE_SUB_TYPE +
      '^' + this.rulesAssociationDetails.OBJECTID_NAME +
      '^' + this.rulesAssociationDetails.LAYER_CLASSIFICATION;
    this.rulesAssociationDetails.UID = UID;
    return UID;
  }

  setSelectedObjetType(data) {
    this.rulesCommonService.ObjectsData = data.split(';');
    if (this.rulesCommonService.ObjectsData) {
      const key = this.rulesCommonService.ObjectsData[0];
      const value = this.rulesCommonService.ObjectsData[1];
      this.rulesCommonService.ObjectsDataOptions = [];
      this.rulesCommonService.ObjectsDataOptions.push({'key': key.substring(3), 'value': value});
      this.rulesAssociationDetails.selectedObjectType = this.rulesCommonService.ObjectsDataOptions[0].key;
      this.rulesAssociationDetails.selectedObjectTypeValue = this.rulesCommonService.ObjectsDataOptions[0].value;
      this.rulesCommonService.selectedObjectType = this.rulesAssociationDetails.selectedObjectType;
    }
  }

/*  private setRuleAssociationDetails(data: any) {
    this.rulesCommonService.associationExists = true;
    this.rulesAssociationDetails.TIME_STAMP = data.TIME_STAMP;
    this.rulesCommonService.createMode = false;
    this.rulesAssociationDetails.OBJECT_TYPE = data.OBJECT_TYPE;
    this.rulesAssociationDetails.OBJECTID_DESCR = data.OBJECTID_DESCR;
    this.rulesAssociationDetails.OBJECTID_NAME = data.OBJECTID_NAME;
    this.rulesAssociationDetails.RULE_SUB_TYPE = data.RULE_SUB_TYPE;
    this.rulesAssociationDetails.ruleSubTypeValue = data.RULE_SUB_TYPE;
    delete this.rulesAssociationDetails.REC_STATUS;
    if (this.ruleSubTypeFlag) {
      this.rulesAssociationDetails.ruleSubTypeValue = 'N/A';
    }
    this.rulesCommonService.RULE_SUB_TYPE = data.RULE_SUB_TYPE;
    this.rulesAssociationDetails.ATTACHMENT_CONTENTS_SOURCE = data.ATTACHMENT_CONTENTS_SOURCE;
    this.rulesAssociationDetails.OBJECT_UID = data.OBJECT_UID;
    this.rulesAssociationDetails.RULE_TYPE_ID = data.RULE_TYPE_ID;
    this.rulesAssociationDetails.RULE_TYPE_NAME = data.RULE_TYPE_NAME;
    this.rulesCommonService.RULE_TYPE_NAME = data.RULE_TYPE_NAME;
    this.rulesCommonService.ruleTypeDetails.next({RULE_TYPE_NAME: data.RULE_TYPE_NAME})
    this.rulesCommonService.RULES = data.RULES;
    this.rulesCommonService.virtualList.length = 0;
    this.fieldAlias = data.OBJECTID_DESCR;

    if (data.RULES && data.RULES.length > 0) {
      data.RULES.forEach((rule) => {
        this.rulesCommonService.virtualList.push(rule);
      });
      this.rulesCommonService.sort();
    }
    if (this.rulesCommonService.RULE_SUB_TYPE != '' && this.rulesCommonService.RULE_SUB_TYPE !== '0') {
      this.showRuleSubType = true;
    }
  }*/

  private setCommonData() {
    this.rulesAssociationApiService.getRuleTypeObjects({
      'typeId': this.rulesAssociationDetails.RULE_TYPE_ID
    }).subscribe({
      next: (data) => {
        this.ruleObjectTypeCommonData(data);
      },
      error: error => {
        console.error('Exception', error);
      }
    });
  }

  private resetObjectTypeFields() {
    this.rulesAssociationDetails.selectedObjectType = '';
    this.rulesCommonService.selectedObjectType = '';
    this.rulesAssociationDetails.selectedAssociatedTo = '';
    this.rulesCommonService.selectedAssociatedTo = '';
    this.rulesAssociationDetails.objectTypeProfileId = '';
    this.rulesAssociationDetails.OBJECTID_NAME = '';
    this.rulesAssociationDetails.OBJECT_UID = '';
    this.rulesAssociationDetails.OBJECTID_DESCR = '';
    this.rulesCommonService.ObjectsDataOptions = [];
  }
}
