import { CommonModule } from '@angular/common';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { FnUiButtonModule } from '../fn-ui-button/fn-ui-button.module';
import { FnUiDialogModule } from '../fn-ui-dialog/fn-ui-dialog.module';
import { FnUiCloseIconModule } from '../fn-ui-icons/fn-ui-close-icon/fn-ui-close-icon.module';
import { FnUiIconModule } from '../fn-ui-icons/fn-ui-icon/fn-ui-icon.module';

import { AlertModalComponent } from './components/alert-modal/alert-modal.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { PromptModalComponent } from './components/prompt-modal/prompt-modal.component';
import { PromptWithNoteModalComponent } from './components/prompt-with-note-modal/prompt-with-note-modal.component';
import { StayLeaveLaterModalComponent } from './components/stay-leave-later-modal/stay-leave-later-modal.component';
import { TourAdditionalIntroModalComponent } from './components/tour-additional-intro-modal/tour-additional-intro-modal.component';

import { SelectElementDirective } from '../directives/select-element.directive';

import { ModalsService } from './services/modals.service';


@NgModule({
  imports: [
    CommonModule,
    ClipboardModule,
    FormsModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    TranslateModule.forChild(),
    FnUiButtonModule,
    FnUiDialogModule,
    FnUiCloseIconModule,
    FnUiIconModule
  ],
  declarations: [
    AlertModalComponent,
    ConfirmModalComponent,
    PromptModalComponent,
    PromptWithNoteModalComponent,
    StayLeaveLaterModalComponent,
    TourAdditionalIntroModalComponent,
    SelectElementDirective,
  ],
  providers: [
    ModalsService
  ],
  exports: [
    AlertModalComponent,
    ConfirmModalComponent,
    PromptModalComponent,
    PromptWithNoteModalComponent,
    StayLeaveLaterModalComponent,
    TourAdditionalIntroModalComponent
  ]
})
export class FnUiModalModule { }
