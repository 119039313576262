import { StandardTaskReducer } from '../../utils/standard-task.reducer';
import {Component, OnInit} from '@angular/core';
import { BFEvents } from '@fgpp-ui/components';
import { TaskExecutionHistoryComponent } from '../../../core/components/task-execution-history/task-execution-history.component';
import { ProfileActions } from '../../../profiles/enums/profile-actions.enum';
import { TaskSchedulerModalComponent } from '../../components/task-scheduler-modal/task-scheduler-modal.component';
import { ProfileButtons } from '../../../profiles/enums/profile-buttons.enum';
import {AbstractProfileFormComponent} from '../../../profiles/abstract/abstract-profile/abstract-profile-form.component';
import { IEndpoint } from '../../../core/interfaces/endpoint.interface';
import { TasksSettings } from '../../models/tasks-settings.model';

@Component({
  template: ''
})
export abstract class AbstractTaskFormComponent extends AbstractProfileFormComponent implements OnInit {
  private executionHistoryEndpoint: string;

  get profileName(): string {
    return this.translate.instant(`business-framework.tasks.${this.taskCode}.label`);
  }

  get actionMessageProfileAlias(): string {
    return this.translate.instant('business-framework.tasks.common.type');
  }

  beforeOnInit() {
    super.beforeOnInit();
    const profileId = this.stateParams.profile ? this.stateParams.profile : this.profileService.getProfileInfo(this.profileService.profileId).profileId;
    this.profileId = this.getProfileId(profileId);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.setStandardReducer(new StandardTaskReducer());
    const message = { key: 'business-framework.profiles.result.task_execute_success', params: { alias: this.actionMessageProfileAlias } };
    this.setProfileActionSuccessMessage(ProfileButtons.EXECUTE, message);
  }

  public buttonClickHandler(actionPayload: BFEvents.ButtonEvent) {
    super.buttonClickHandler(actionPayload);
    if (actionPayload.target === 'EXECUTION_HISTORY') {
      this.executionHistoryEndpoint = this.resolveProfileEndpoint(ProfileActions.EXECUTION_HISTORY, this.uid).endpoint;
      this.openDialog(TaskExecutionHistoryComponent, this.executionHistoryEndpoint);
    } else if (actionPayload.target === 'TASK_SCHEDULER') {
      const endpoint = this.resolveProfileEndpoint(ProfileActions.TASK_SCHEDULER, this.uid).endpoint;
      this.openDialog(TaskSchedulerModalComponent, endpoint);
    } else if (actionPayload.target === 'EXECUTE') {
      this.doServerAction(actionPayload,
        this.getToastMessage(actionPayload.target as ProfileButtons), false );
    }
  }

  protected getServerActionRequestBody(endpoint: IEndpoint, buttonId: string): any {
    const body = super.getServerActionRequestBody(endpoint, buttonId);
    this.excludeVirtualFields(body);
    return body;
  }

  private excludeVirtualFields(body: any): any {
    delete body[TasksSettings.V_TASKNAME];
    delete body[TasksSettings.V_TASKDESCRIPTION];
    delete body[TasksSettings.V_ATTACHED_TO_SCRIPT];
  }
}
