import { Injectable } from '@angular/core';
import { NavigationService } from '../navigation/services/navigation.service';
import { UserSettingsService } from '../user-settings/services/user-settings.service';
import { TaskBarService } from '../navigation/task-bar/services/task-bar.service';
import { MenuItemModel } from '../models/menu-item.model';
import { SideNavigationItem } from '../navigation/task-bar/models/side-navigation-item.model';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  private menus = {
    items: {}
  } as MenuItemModel;

  constructor(private taskBarService: TaskBarService, private userPreferencesService: UserSettingsService,
              private navigationService: NavigationService) {

    this.addMenuItem('main', { alias: '', icon: '', position: 0, routerLink: '', stateName: '', items: {}, shouldRender: this.shouldRender });
  }

  init() {
    if (this.navigationService.sitemap && Object.keys(this.menus.items?.main?.items).length === 0) {
      for (let index = 0; index < this.navigationService.sitemap.components.length; index++) {
        const item = this.navigationService.sitemap.components[index];

        let stateName = 'home.' + item.type;
        if (item.type === 'profiles') {
          stateName = 'home.' + item.id;
        }

        this.addMenuItem('main.' + item.id, {
          alias: item.alias,
          icon: item.icon,
          position: index,
          routerLink: `/home/${item.id}`,
          stateName,
          items: {},
          shouldRender: this.shouldRender
        });
      }
    }
  }

  private shouldRender(): boolean {
    return true;
  }

  private createMenuItem(settings: MenuItemModel): MenuItemModel {
    return {
      alias: settings.alias,
      icon: settings.icon,
      position: settings.position || 0,
      routerLink: settings.routerLink,
      stateName: settings.stateName,
      items: {},
      shouldRender: this.shouldRender
    };
  }

  private createMenuParts(menuId: string): string[] {
    if (menuId && menuId.length) {
      return menuId.split('.');
    } else {
      throw new Error('MenuId was not provided');
    }
  }

  private getMenuItemParent(menuId: string): MenuItemModel {
    const parts = this.createMenuParts(menuId);
    if (parts.length === 1) {
      return this.menus;
    } else if (parts.length < 1) {
      throw new Error('Menu parent is missing');
    }
    const parentId = parts.slice(0, parts.length - 1)
      .join('.');

    return this.getMenuItem(parentId);
  }

  getMenuItem(menuId: string): MenuItemModel {
    const parts = this.createMenuParts(menuId);
    let item = this.menus;
    for (let index = 0; index < parts.length; index++) {
      const part = parts[index];
      if (part && part.length && item.items.hasOwnProperty(part)) {
        item = item.items[part];
      } else {
        throw new Error('Menu does not exists');
      }
    }
    return item;
  }

  private addMenuItem(menuId: string, settings: MenuItemModel): MenuItemModel {
    const parent = this.getMenuItemParent(menuId);

    const parts = this.createMenuParts(menuId);
    const item = this.createMenuItem(settings);

    parent.items[parts[parts.length - 1]] = item;

    return item;
  }

  getDefaultTopLevelMenu(): MenuItemModel {
    const mainMenu = this.getMenuItem('main');
    const keys = Object.keys(mainMenu.items);
    for (let j = 0; j < keys.length; j++) {
      const key = keys[j];
      const menuItem = mainMenu.items[key];
      if (menuItem.shouldRender()) {
        return menuItem;
      }
    }
  }

  getTopLevelMenu(): SideNavigationItem {
    const preferences = this.userPreferencesService.getPreferences();
    let topLevelMenu = null;
    let landingPageRoute = null;
    if (preferences.landingPage !== null) {
      topLevelMenu = this.getLandingPageLevelMenu(preferences.landingPage);
      landingPageRoute = topLevelMenu.hasOwnProperty('stateName') ? topLevelMenu.stateName : null;
    }
    if (!topLevelMenu) {
      topLevelMenu = this.getDefaultTopLevelMenu();
    } else {
      if (preferences.navigationBar !== null) {
        const taskBarId = topLevelMenu.routerLink.split('/')[2];
        topLevelMenu = this.getNavigationBarLevelMenu(taskBarId, preferences.navigationBar) || topLevelMenu;
      }
    }
    if (landingPageRoute && preferences.landingPage === 'system_navigation.MAINTAB_INSIGHT') {
      topLevelMenu.stateName = landingPageRoute;
    }
    return topLevelMenu;
  }

  private getLandingPageLevelMenu(landingPage: string): SideNavigationItem {
    let result;
    const mainMenu = this.getMenuItem('main');
    const keys = Object.keys(mainMenu.items);
    for (let j = 0; j < keys.length; j++) {
      if (mainMenu.items[keys[j]].alias === landingPage) {
        result = mainMenu.items[keys[j]];
        return result;
      }
    }
  }

  private getNavigationBarLevelMenu(taskBarId: string, navigationBar: string): SideNavigationItem {
    const taskBar = this.taskBarService.taskBars[taskBarId];
    return taskBar?.items.find(item => item.alias === navigationBar);
  }
}
