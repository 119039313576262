import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalsService } from '../../shared/fn-ui-modals/services/modals.service';
import { NotificationService } from '@fgpp-ui/components';
import { PopOutService } from '../../services/pop-out.service';
import { MultiTabsManagerService } from '../../core/multi-tabs/services/multi-tabs-manager.service';
import { MatDialogRef } from '@angular/material/dialog';
import { TourAdditionalIntroModalComponent } from '../../shared/fn-ui-modals/components/tour-additional-intro-modal/tour-additional-intro-modal.component';
import { TourModel } from '../models/tour.model';
import { ActiveTourModel } from '../models/active-tour.model';
import { ToursApiService } from './tours-api.service';
import { TourStepModel } from '../models/tour-step.model';
import { ToursConfig } from '../config/tours.config';

@Injectable({
  providedIn: 'root'
})
export class ToursService {

  private tours = {};
  private userSeenTours = {};
  private playedToursForSingleRun = [];
  private alreadySawTourForThisSession = false;
  private alreadyInitializedThisSession = false;
  allToursSortedByPriority: string[] = [];
  private currentActiveTour: ActiveTourModel = {
    tourName: undefined,
    currentStep: undefined,
    totalSteps: undefined
  };
  private toursConfig = ToursConfig;

  constructor(private translateService: TranslateService, private modals: ModalsService,
    private notificationService: NotificationService,
    private popOutService: PopOutService, private multiTabsManagerService: MultiTabsManagerService,
    private toursApiService: ToursApiService) {
  }

  isTourRunning(tourId: string): boolean {
    return this.tours[tourId] != null;
  }

  initTours(isForceInit): void {
    if (!isForceInit && this.alreadyInitializedThisSession || this.isToursDisabled()
      || this.multiTabsManagerService.isTourDismissed || this.popOutService.isPopOut()) {
      return;
    }

    this.alreadyInitializedThisSession = true;
    console.debug('Get user tour data from server.');
    this.toursApiService.getTours()
      .then(this.onGetToursSuccess.bind(this), this.onGetToursFailure.bind(this));
  }

  private onGetToursSuccess(result?) {
    result = result || { response: {} };
    this.setAlreadySawTourForThisSession(result.response);
    this.setUserSeenTours(result.response);
    this.registerTours();
    this.sortTours();
  }

  private setAlreadySawTourForThisSession(result): void {
    if (result == null || Object.keys(result).length === 0) {
      console.debug('user has no seen tours');

      //if we don't have any seen tour we assume to get the initial introduction tour and we don't
      //want to display the additional instruction popup
      this.alreadySawTourForThisSession = true;
    }
  }

  private setUserSeenTours(result) {
    this.userSeenTours = result;
  }

  private registerTours() {
    Object.keys(this.toursConfig).forEach((availableTour) => {
      if (this.userSeenTours == null || (this.userSeenTours[availableTour]) == null) {
        this.registerTour(availableTour);
      }
    });
  }

  private sortTours() {
    this.allToursSortedByPriority = Object.keys(this.tours).sort((a: string, b: string): number => {
      return this.tours[b] - this.tours[a];
    });
  }

  private onGetToursFailure(error) {
    console.log(error);
    if (error.status === 404) {
      this.onGetToursSuccess();
    } else {
      this.tours = {};
    }
  }

  registerElement(tourId: string, stepNumber: number, controller): void {
    const tourStep = this.getTourStep(tourId, stepNumber);
    if (tourStep) {
      tourStep.controller = controller;
    }
  }

  unregisterElement(tourId: string, stepNumber: number): void {
    const tourStep = this.getTourStep(tourId, stepNumber);
    if (tourStep) {
      tourStep.controller = null;
    }
  }

  getTourStep(tourId: string, stepNumber: number): TourStepModel {
    const tour = this.getTour(tourId);
    if (!tour) {
      return null;
    }
    return tour.steps[stepNumber];
  }

  startTour(tourId: string, stepNumber: number, isInternal: boolean): void {

    if (isInternal) {
      this.currentActiveTour.tourName = tourId;
      this.currentActiveTour.currentStep = stepNumber;
      this.currentActiveTour.totalSteps = this.getTourTotalSteps(tourId);
      this.startStep(tourId, stepNumber);
      return;
    }

    // eslint-disable-next-line eqeqeq
    if (this.currentActiveTour.tourName != null || stepNumber != 0 || Object.keys(this.tours).length === 0 || this.multiTabsManagerService.isTourDismissed) {
      return;
    }

    //checks if the next tour in the priority list is the tour that was received.
    //if not, we force playing the tours by priority
    const nextTour = this.getNextTour();
    if (nextTour !== tourId) {
      console.debug(tourId + ' was called but ' + nextTour + ' has greater priority and will be played first');
      tourId = nextTour;
    }

    //checks if the tour is valid and plays it
    if (this.tours[tourId] != null) {
      this.currentActiveTour.tourName = tourId;
      this.currentActiveTour.currentStep = stepNumber;
      this.currentActiveTour.totalSteps = this.getTourTotalSteps(tourId);
      delete this.tours[tourId];
      if (!this.alreadySawTourForThisSession) {
        this.alertFirstTourInSession().afterClosed().subscribe((result) => {
          if (result === 'stay') {
            this.startStep(tourId, stepNumber);
          } else {
            this.endTourWithoutMarkingAsSeen();
          }
        });
      } else {
        this.startStep(tourId, stepNumber);
      }
    }
  }

  private startStep(tourId: string, stepNumber: number): void {
    const tourStep = this.showTourStep(tourId, stepNumber);
    if (!!tourStep) {
      this.addOverlay();
    }
  }

  goToNextStep(tourId: string, currentStep: number): void {
    let nextStep;
    while (currentStep < this.currentActiveTour.totalSteps) {
      nextStep = this.showTourStep(tourId, ++currentStep);
      if (!!nextStep) {
        this.getTourStep(tourId, this.currentActiveTour.currentStep).controller.closeStep();
        this.currentActiveTour.currentStep = currentStep;
        return;
      }
    }
    this.endTour(tourId, currentStep - 1); // if we didn't find a next step we end current tour
  }

  goToPreviousStep(tourId: string, currentStep: number): void {
    let prevStep;
    while (currentStep < this.currentActiveTour.totalSteps && currentStep > 0) {
      prevStep = this.showTourStep(tourId, --currentStep);
      if (!!prevStep) {
        this.getTourStep(tourId, this.currentActiveTour.currentStep).controller.closeStep();
        this.currentActiveTour.currentStep = currentStep;
        return;
      }
    }

    if (this.playedToursForSingleRun.length > 0) {
      this.playLastTour(tourId);
    }
  }

  requestToAbortTour(tourId: string, currentStep: number): void {
    // eslint-disable-next-line eqeqeq
    if (this.currentActiveTour.tourName === tourId && this.currentActiveTour.currentStep == currentStep) {
      this.confirmEndStep(tourId, currentStep);
    }
  }

  requestToPostponeTour(tourId: string, currentStep: number): void {
    if (this.currentActiveTour.tourName === tourId && this.currentActiveTour.currentStep === currentStep) {
      this.getTourStep(tourId, currentStep).controller.closeStep();
      this.endTourWithoutMarkingAsSeen();
    }
  }

  isTourAbortable(tourId: string): boolean {
    const tour = this.getTour(tourId);
    if (!tour) {
      return false;
    }
    return tour.isAbortable;
  }

  markAsSeen(tourId: string): boolean {
    this.removeOverlay();
    this.currentActiveTour = {} as ActiveTourModel;
    let tourMarked = false;
    if (this.userSeenTours[tourId] == null) {
      this.userSeenTours[tourId] = {}; //currently empty object, we can add a specific step number in the future
      tourMarked = true;
    }
    return tourMarked;
  }

  saveUserTour(): void {
    const shouldSaveTours = Object.keys(this.userSeenTours).length > 0;

    if (shouldSaveTours) {
      console.debug('Save user tour in USER_PREFERENCES table');

      this.toursApiService.saveSeenTours(this.userSeenTours).then(() => {
        console.debug('User Tour data was saved.');
      }, (reason) => {
        console.error(reason);
      });
    }
  }

  private registerTour(tourId: string): void {
    const tour = this.getTour(tourId);
    if (tour != null) {
      this.tours[tourId] = tour.priority;
    }
  }

  private getTour(tourId: string): TourModel {
    return this.toursConfig[tourId];
  }

  private alertFirstTourInSession(): MatDialogRef<TourAdditionalIntroModalComponent> {
    this.alreadySawTourForThisSession = true;
    return this.modals.tourAdditionalIntroModal();
  }

  private showTourStep(tourId: string, stepNumber: number): TourStepModel {

    // eslint-disable-next-line eqeqeq
    const isFirstStep = this.playedToursForSingleRun.length === 0 && stepNumber == 0;

    const tourStep = this.getTourStep(tourId, stepNumber);
    if (!tourStep || !tourStep.controller) {
      return null;
    }

    if (!tourStep.controller.isVisible()) {
      return null;
    }

    window.setTimeout(() => {
      tourStep.controller.showStep(isFirstStep);
    }, 200);

    return tourStep;
  }

  private playLastTour(tourId: string): void {
    delete this.userSeenTours[tourId];
    this.registerTour(tourId);
    this.removeOverlay();
    const lastPlayedTour = this.playedToursForSingleRun.pop();
    this.startTour(lastPlayedTour.id, lastPlayedTour.step, true);
  }

  confirmEndStep(tourId: string, stepNumber: number) {
    this.getTourStep(tourId, stepNumber).controller.closeStep();

    this.modals.stayLeaveLater().afterClosed().subscribe((result) => {
      if (result === undefined) {
        this.endTourWithoutConfirmation(this.currentActiveTour.tourName);
      } else if (result === 'later') {
        this.endTourWithoutMarkingAsSeen();
      } else if (result === 'stay') {
        this.showTourStep(this.currentActiveTour.tourName, this.currentActiveTour.currentStep); // resume tour
      }
    });
  }

  private endTour(tourId: string, finalStep: number): void {
    const isMarked = this.markAsSeen(tourId);
    if (isMarked) {
      this.saveUserTour();
    }
    const nextTour = this.getNextTour();
    if (!!nextTour) {
      const lastTour = {
        id: tourId,
        step: finalStep
      };
      this.playedToursForSingleRun.push(lastTour);
      this.startTour(nextTour, 0, false);
    } else {
      this.notificationService.info(this.translateService.instant('tour.all.step1.text'));
      this.playedToursForSingleRun = [];
    }
  }

  private getNextTour(): string {
    for (const tour of this.allToursSortedByPriority) { //select the next tour if available
      if (this.userSeenTours[tour] == null) {
        const firstStep = this.getTourStep(tour, 0);
        if (firstStep.controller != null && firstStep.controller.isVisible()) {
          return tour;
        }
      }
    }
    return null;
  }

  private endTourWithoutConfirmation(tourId: string): void {
    this.tours = {}; //no tours will be displayed in that session
    this.markAsSeen(tourId);
    this.saveUserTour();
    this.dismissTour();
  }

  private endTourWithoutMarkingAsSeen(): void {
    this.tours = {}; //no tours will be displayed in that session
    this.currentActiveTour = {} as ActiveTourModel;
    this.removeOverlay();
    this.dismissTour();
  }

  private getTourTotalSteps(tourId: string): number {
    return this.getTour(tourId).steps.length;
  }

  private addOverlay() {
    const elem = document.createElement('div');
    elem.setAttribute('id', 'overlayDiv');
    elem.style.cssText = 'position: absolute;  width: 100%; height: 100%; z-index: 999; top: 0; left: 0; background: transparent;"/>';
    document.body.appendChild(elem);
  }

  private removeOverlay() {
    document.getElementById('overlayDiv')?.remove();
  }

  private dismissTour(): void {
    this.multiTabsManagerService.dismissTour();
  }

  disableTours(): void {
    localStorage.setItem('disableTours', 'true');
  }

  private isToursDisabled(): boolean {
    return localStorage.getItem('disableTours') === 'true';
  }
}
