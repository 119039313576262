import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ProfilesTree } from '../../../core/navigation/sitemap/models';
import { TaskBarService } from '../../../core/navigation/task-bar/services/task-bar.service';
import { PROFILE_ID } from '../../../profiles/components/profiles-grid/profiles-grid.component';
import { RulesProfilesManagementService } from '../../../profiles/services/rules-profiles-management.service';
import { RULE_TYPE_DELIMITER } from '../../models/consts/rule-type.consts';
import { RuleTypeService } from '../../services/rule-type.service';

@Component({
  selector: 'app-rules-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class RulesLandingPageComponent implements OnInit, OnDestroy {

  hideNewButton = false;
  navigationTree: ProfilesTree;
  noRuleTypes: boolean;

  private _subscriber = new Subscription();

  constructor(private router: Router,
              private route: ActivatedRoute,
              private rulesProfileService: RulesProfilesManagementService,
              private ruleTypeService: RuleTypeService,
              private taskBarService: TaskBarService) { }

  ngOnInit(): void {
    this.ruleTypeService.fromSearch = false;
    this._subscriber.add(this.route.data.subscribe((data) => {
      this.navigationTree = data.navigationTree;
    }));
    this._subscriber.add(this.ruleTypeService.noRuleTypes$.subscribe((value) => {
      this.noRuleTypes = value;
    }));
    this.rulesProfileService.setCreateButtonState(this.navigationTree);
    this.rulesProfileService.resetSearchRequest();
    this.rulesProfileService.resetGridData();
    this.hideNewButton = this.rulesProfileService.hideNewButton;
  }

  ngOnDestroy(): void {
    this._subscriber.unsubscribe();
  }

  createRule() {
    this.rulesProfileService.setProfileId(PROFILE_ID.RULE);
    this.rulesProfileService.createProfile();
  }

  searchRuleType() {
    if (!this.taskBarService.taskBarShowState) {
      this.taskBarService.taskBarShowState = true;
    }
    this.ruleTypeService.setFocus$.next(true);
  }

  searchRules() {
    this.ruleTypeService.selectAll();
    this.router.navigate(['/home/rules/business-rules'], { queryParams: { ruleType:this.ruleTypeService.toArray().join(RULE_TYPE_DELIMITER) } });
    if (!this.taskBarService.taskBarShowState) {
      this.taskBarService.taskBarShowState = true;
    }
    this.ruleTypeService.fromSearch = true;
    this.ruleTypeService.searchFilter = undefined;
    this.rulesProfileService.setProfileId(PROFILE_ID.RULE);
    if (!this.rulesProfileService.getSearchRequest()) {
      this.rulesProfileService.initializeSearchRequest();
    }
    this.rulesProfileService.updateProfilesView();
  }
}
