<div class="search-box-container">

    <div class="expand-search">
        <button color="primary"
                name="expand-search-button"
                class="expand-search-button"
                matRipple
                (click)="onExpandClick()">
          <app-fn-ui-icon icon="search" #searchIcon></app-fn-ui-icon>
        </button>

      <app-tour-item [tourId]="'search'" [tourStepNumber]="0" [targetAt]=searchIcon></app-tour-item>
      <app-tour-item [tourId]="'search'" [tourStepNumber]="1" [targetAt]=searchIcon></app-tour-item>
      <app-tour-item [tourId]="'search'" [tourStepNumber]="2" [targetAt]=searchIcon></app-tour-item>
    </div>

    <div class="search-box">
        <app-search-menu [items]="items"
                         [selectedItem]="selectedItem"
                         (menuItemClicked)="onMenuItemClick($event)">
        </app-search-menu>

        <app-search-input [selectedItem]="selectedItem"
                          (searchTriggered)="onSearchTrigger($event)">
        </app-search-input>
    </div>

    <button mat-icon-button color="primary"
            azure-id="collapse-search"
            name="collapse-search-button"
            class="collapse-search-button"
            (click)="onCollapseClick()">
        <app-fn-ui-icon icon="close"></app-fn-ui-icon>
    </button>

</div>
