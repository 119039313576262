export enum AccountAdditionalOwners  {
	"ACC_NO"= "ACCOUNTS-V_ACC_NUM",
  "ACCOUNT_UID"= "ACCOUNT_ADDITIONAL_OWNERS-ACCOUNT_UID",
  "CURRENCY"= "ACCOUNTS-CURRENCY",//
	"CUST_CODE"= "ACCOUNT_ADDITIONAL_OWNERS-CUST_CODE",
	"CUST_NAME"= "ACCOUNT_ADDITIONAL_OWNERS-CUST_NAME",
	"DEPARTMENT"= "ACCOUNT_ADDITIONAL_OWNERS-DEPARTMENT",
	"EFFECTIVE_DATE"= "EFFECTIVE_DATE",
	"OFFICE"= "ACCOUNT_ADDITIONAL_OWNERS-OFFICE",
  "PMT_CATEGORY"= "ACCOUNT_ADDITIONAL_OWNERS-PMT_CATEGORY",
	"PROFILE_CHANGE_STATUS"= "ACCOUNT_ADDITIONAL_OWNERS-PROFILE_CHANGE_STATUS",
	"REC_STATUS"= "ACCOUNT_ADDITIONAL_OWNERS-REC_STATUS",
	"TIME_STAMP"= "TIME_STAMP",
	"PROFILE_UPDATE_PK"= "PROFILE_UPDATE_PK",
	"PU_TIME_STAMP"= "PU_TIME_STAMP",
	"NOTE"= "NOTE"
}
