import { ProcessingTimeScope } from '../enums/processing-time-scope.enum';

export const PROCESSING_SCOPE = {
  all: {},
  [ProcessingTimeScope.PROCESSING_TODAY]: {
    additionalParameters: {
      _scope_processing_date: 'TODAY',
      _scope_final_status: false
    }
  },
  [ProcessingTimeScope.PROCESSING_FUTURE]: {
    additionalParameters: {
      _scope_processing_date: 'FUTURE',
      _scope_final_status: false
    }
  },
  [ProcessingTimeScope.COMPLETED_TODAY]: {
    additionalParameters: {
      _scope_processing_date: 'TODAY',
      _scope_final_status: true
    }
  },
  [ProcessingTimeScope.RESET]: {
    additionalParameters: {}
  }
};

