import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { SitemapNode, SitemapTreeItem } from '../../sitemap/models';
import { TabHandlerService } from './tab-handler.service';

@Injectable()
export class ProfilesTabHandlerService extends TabHandlerService {

  getRouterLink(node: SitemapNode, parents: Array<SitemapTreeItem>): string {
    const mainTab = parents[0].id;
    const taskBarItem = parents[1].id;
    const profileId = node.id;
    return `/home/${mainTab}/${taskBarItem}/${profileId}`;
  }

  getStateName(node: SitemapNode, parents: Array<SitemapTreeItem>): string {
    const mainTab = parents[0].id;
    return `home.${mainTab}.views.tree`;
  }

  getStateParams(node: SitemapNode, parents: Array<SitemapTreeItem>): Params {
    // return {
    //   taskBarItem: parents[1].id,
    //   profileId: node.id
    // };
    return {}
  }

}
