import { Token } from '../../../../authentication/models/token.model';
import { SessionStateModel } from '../model/session-state.model';


export const CREATE_SESSION = 'CREATE_SESSION';
export const CREATE_TOKEN = 'CREATE_TOKEN';
export const UPDATE_SESSION = 'UPDATE_SESSION';
export const DELETE_SESSION_USER_DATA = 'DELETE_SESSION_USER_DATA';
export const DELETE_SESSION_TOKEN = 'DELETE_SESSION_TOKEN';
export const GET_SESSION = 'GET_SESSION';
export const DUPLICATE_SESSION = 'DUPLICATE_SESSION';
export const DELETE_SESSION_USER_DATA_WITHOUT_ACTION = 'RESET_SESSION_STATE_WITHOUT_ACTION';
export const DELETE_SESSION_TOKEN_WITHOUT_ACTION = 'DELETE_SESSION_TOKEN_WITHOUT_ACTION';


export class CreateSession {

  static readonly type = CREATE_SESSION;

  constructor(public payload: SessionStateModel) { }

}

export class CreateToken {

  static readonly type = CREATE_TOKEN;

  constructor(public payload: Token) { }

}

export class UpdateSession {

  static readonly type = UPDATE_SESSION;

  constructor(public payload: Token) { }

}

export class DeleteSessionUserData {

  static readonly type = DELETE_SESSION_USER_DATA;

}

export class DeleteSessionToken {

  static readonly type = DELETE_SESSION_TOKEN;

}

export class GetSession {

  static readonly type = GET_SESSION;

}

export class DuplicateSession {

  static readonly type = DUPLICATE_SESSION;

  constructor(public payload: SessionStateModel) { }

}

export class RestSessionStateWithoutAction {

  static readonly type = DELETE_SESSION_USER_DATA_WITHOUT_ACTION;

}


export class DeleteSessionTokenWithoutAction {

  static readonly type = DELETE_SESSION_TOKEN_WITHOUT_ACTION;

}
