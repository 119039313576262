import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from '../../../core/navigation/services/navigation.service';
import { CreatePaymentService } from '../../services/create-payment.service';
import { SearchBoxService } from '../../../core/navigation-search/services/search-box.service';
import { NavigationSearchConfigService } from '../../../core/navigation-search/services/navigation-search-config.service';
import {MessagesService} from '../../../message/services/messages.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {FedPaymentTypesService} from "../../services/fed-payment-types.service";
@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit, OnDestroy {

  isCreateMessageTypesEmpty: boolean;
  messagesService = inject(MessagesService);
  isLoading = false;
  private destroy$ = new Subject<void>();

  constructor(private createPaymentService: CreatePaymentService,
    private router: Router,
    private navigationService: NavigationService,
    private searchBoxService: SearchBoxService,
    private navigationSearchConfigService: NavigationSearchConfigService,
    protected fedPaymentTypeHandlerService: FedPaymentTypesService) { }

  ngOnInit(): void {
    this.isCreateMessageTypesEmpty = this.navigationService.getCreatePaymentItems()?.length === 0;
    this.messagesService.isLoading.pipe(takeUntil(this.destroy$)).subscribe(loading => {
      this.isLoading = loading;
      this.fedPaymentTypeHandlerService.initFedMopMediaMap();
    });
  }

  createPayment() {
    this.createPaymentService.openModal();
  }

  searchAPayment() {
    const messagesSearchBoxItem = this.navigationSearchConfigService.getSearchBoxItem('messages');
    this.searchBoxService.emitSearchBoxItem(messagesSearchBoxItem);
  }

  recentQueuesView() {
    this.router.navigate(['/home/messages-center/recent-searches']);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
