import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FnUiIconModule } from '../fn-ui-icons/fn-ui-icon/fn-ui-icon.module';
import { FnUiMenuModule } from '../fn-ui-menu/fn-ui-menu.module';
import { FnUiNavigationTreeModule } from '../fn-ui-navigation-tree/fn-ui-navigation-tree.module';
import { FnUiNavigationActionsTreeComponent } from './fn-ui-navigation-actions-tree.component';


@NgModule({
  declarations: [
    FnUiNavigationActionsTreeComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    FnUiIconModule,
    FnUiMenuModule,
    FnUiNavigationTreeModule
  ],
  exports: [
    FnUiNavigationActionsTreeComponent
  ]
})
export class FnUiNavigationActionsTreeModule { }
