<button mat-button
        class="fn-ui-menu-trigger {{triggerClass}}"
        name="menu-trigger"
        [color]="triggerColor"
        [matMenuTriggerFor]="menu"
        [matMenuTriggerRestoreFocus]="triggerRestoreFocus"
        (click)="onTriggerClick($event)" 
        (menuOpened)="onMenuOpen()"
        (menuClosed)="onMenuClose()">
  <ng-container [ngTemplateOutlet]="triggerTemplate"></ng-container>
</button>

<mat-menu #menu="matMenu"
          class="fn-ui-menu-panel {{panelClass}}"
          [backdropClass]="backdropClass"
          [hasBackdrop]="hasBackdrop"
          [overlapTrigger]="overlapTrigger"
          [xPosition]="xPosition"
          [yPosition]="yPosition">

  <button *ngFor="let item of items"
          mat-menu-item
          class="fn-ui-menu-item"
          [name]="item.name"
          [disabled]="item.disabled"
          [disableRipple]="disableRippleOnMenuItems"
          (click)="onMenuItemClick({ item, $event })">
    <ng-container [ngTemplateOutlet]="menuItemTemplate" [ngTemplateOutletContext]="{ item }"></ng-container>
  </button>

</mat-menu>

<ng-template #defaultTriggerContent>
  <app-fn-ui-icon icon="menu" size="16px"></app-fn-ui-icon>
</ng-template>

<ng-template #defaultMenuItemContent let-item="item">
  <span>{{ item.alias | translate }}</span>
</ng-template>