import { IProfileFooter } from '../../interfaces/profile-footer.interface';
import { ButtonCategory } from '../../../core/enums/button-category.enum';
import { ProfilesFooterService } from '../../services/profiles-footer.service';
import { FgppButtonProps } from '../../../core/utils/fgpp-button-props.utils';
import { IProfileLayoutSelectionMetadata } from '../../interfaces/profile-layout-selection-metadata.interface';
import { ButtonType } from '../../../core/enums/button-type.enum';
import { ProfileButtons } from '../../enums/profile-buttons.enum';
import { StandingOrder } from '../../models/standing-order.model';
import { StandingOrderActivationStatus } from '../../enums/standing-order-field-mapping.enum';
import { ISdkProfileFooterReducer } from '../../interfaces/profile-footer-reducer.interface';

export class StandingOrderCustomReducer implements ISdkProfileFooterReducer {
  public reduce(buttonCategory: ButtonCategory, footer: IProfileFooter, inputs: IProfileLayoutSelectionMetadata): IProfileFooter {
    let soClosed = false;
    let isActive = true;
    if (inputs.formData && inputs.formData.hasOwnProperty(StandingOrder.SO_CLOSED)) {
        if (inputs.formData[StandingOrder.SO_CLOSED] === StandingOrderActivationStatus.CLOSED) {
          soClosed = true;
        }
    }
    if (inputs.formData && inputs.formData.hasOwnProperty(StandingOrder.REC_STATUS)) {
      if (inputs.formData[StandingOrder.REC_STATUS] !== 'AC' || inputs.formData[StandingOrder.PROFILE_CHANGE_STATUS] === 'PN'
        || inputs.formData[StandingOrder.PROFILE_CHANGE_STATUS] === 'FU' ) {
        isActive = false;
      }
    }
    const footerService = new ProfilesFooterService().setFooter(footer);
    const buttonList: FgppButtonProps[] = [];

    // Get custom button actions
    let btnAction = '';
    if ( footerService.getFooter() && footerService.getFooter().hasOwnProperty('controls')) {
      for (const key in footerService.getFooter().controls) {
          if (key === 'EXECUTE_MANUALLY') {
            const executeBtn = footerService.getFooter().controls[key];
            btnAction = executeBtn.action;
          }
      }
    }
    const executeButton = new FgppButtonProps('EXECUTE_MANUALLY', ButtonType.MENU, 11, btnAction);
    if (inputs.mode !== 'create' && !soClosed && isActive) {
      buttonList.push(executeButton);
    }
    // If standing order is closed remove Save, Undo buttons
    if (soClosed) {
       footerService.removeStandardButtons([ProfileButtons.SAVE, ProfileButtons.UNDO]);
       footerService.updateStandardButtonType([ProfileButtons.CLOSE], ButtonType.RAISED);
    }
    return footerService.retain(buttonCategory, buttonList).getFooter();
  }
}
