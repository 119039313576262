import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import * as angular from 'angular';
import { AuthenticationModule } from '../../authentication/authentication.module';
import { FileTranslationService } from './services/file-translation.services';
import { TextFormatterService } from './services/text-formatter.service';
import { TranslationCacheService } from './services/translation-cache.service';
import { TranslationConfigurationService } from './services/translation-configuration.service';
import { TranslationsHandlerService } from './services/translations-handler.service';


// angular.module('fundtech').provider('TranslateProvider', function ($translateProvider) {
//   this.$get = function () {
//     return $translateProvider;
//   };
// } as angular.IServiceProviderFactory);


@NgModule({
  imports: [
    TranslateModule,
    AuthenticationModule
  ],
  providers: [
    FileTranslationService,
    TextFormatterService,
    TranslationCacheService,
    TranslationConfigurationService,
    TranslationsHandlerService,
    {
      provide: 'TranslateProvider',
      useFactory: ($injector: any) => $injector.get('TranslateProvider'),
      deps: ['$injector']
    }
  ]
})
export class TranslationsModule { }
