import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { TranslateModule } from '@ngx-translate/core';
import { UiComponentsModule } from '../../ui-components/ui-components.module';
import { FnUiDialogModule } from '../fn-ui-dialog/fn-ui-dialog.module';

import { FnUiExportFormComponent } from './components/fn-ui-export-form/fn-ui-export-form.component';
import { FnUiExportModalComponent } from './components/fn-ui-export-modal/fn-ui-export-modal.component';

import { ExportFormService } from './services/export-form.service';
import { ExportApi } from './services/export.api';
import { ExportService } from './services/export.service';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    TranslateModule.forChild(),
    UiComponentsModule,
    FnUiDialogModule
  ],
  declarations: [
    FnUiExportFormComponent,
    FnUiExportModalComponent
  ],
  providers: [
    ExportFormService,
    ExportApi,
    ExportService
  ],
  exports: [
    FnUiExportModalComponent
  ]
})
export class FnUiExportModule { }
