import { Injectable } from '@angular/core';
import { DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { SystemVariablesService } from '../../services/system-variables/system-variables.service';
import { IdleConfig } from '../models/idle-config.model';
import { IIdleConfigService } from './interfaces/idle-config-service.interface';

@Injectable()
export class IdleConfigService implements IIdleConfigService {

  constructor(private systemVariablesService: SystemVariablesService) { }

  getConfig(): IdleConfig {
    return {
      seconds: (this.systemVariablesService.getSingleVariable('WEBIDLTME') || 30 * 60) - 30,
      timeout: 30,
      interrupts: DEFAULT_INTERRUPTSOURCES
    };
  }

}
