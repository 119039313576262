import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener, OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren, ChangeDetectorRef
} from '@angular/core';
import { MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatTabLink } from '@angular/material/tabs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {filter, Subscription} from 'rxjs';
import { RouterHelper } from '../../../../base-view/classes/router-helper';
import { NavigationTabConfig } from '../../models';
import { MenuService } from '../../../services/menu.service';

@Component({
  selector: 'app-main-navigation',
  templateUrl: './main-navigation.component.html',
  styleUrls: ['./main-navigation.component.scss']
})
export class MainNavigationComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChildren(MatTabLink) matTabLinkList: QueryList<MatTabLink>;
  @ViewChildren(MatMenuItem) menuItems: QueryList<MatMenuItem>;
  @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger: MatMenuTrigger;

  tabs: Array<NavigationTabConfig>;
  activeTabIndex: number;
  totalTabs: number;
  maxTabs: number;

  currentTab: string;

  private subscriber: Subscription;

  constructor(private elementRef: ElementRef<HTMLElement>, private menuService: MenuService,
              private route: ActivatedRoute, private router: Router, private changeDetectionRef: ChangeDetectorRef) {
    this.tabs = this.route.snapshot.data.navigationTabs;
    this.subscriber = router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe((event: NavigationEnd) => {
      this.initializeCurrentTab(event);
    });
  }

  private initializeCurrentTab(event: NavigationEnd) {
    const urlCurrentTab = RouterHelper.getSegments(this.router)[1]?.path;
    if( urlCurrentTab && this.currentTab !== urlCurrentTab) {
      this.currentTab = urlCurrentTab;
      this.changeTabByUrl(event, urlCurrentTab);
    }
  }

  private changeTabByUrl(event: NavigationEnd, urlCurrentTab: string) {
    const currentUrl = event.url
    if(currentUrl.indexOf('signout') === -1 && currentUrl.indexOf('page-not-found') === -1) {
    if(this.tabs) {
     const tabFoundIndex = this.tabs.findIndex(tab => {
      return tab.id === urlCurrentTab
    });
    if(tabFoundIndex !== -1) {
       this.onTabClick(tabFoundIndex);
      }
    }
  }
}

  ngOnInit(): void {
    this.totalTabs = this.tabs.length;
    this.maxTabs = this.totalTabs;
  }

  ngAfterViewInit(): void {
    this.setTabsWidth();
    this.setMaxTabs();
  }

  @HostListener('window:resize', ['$event'])
  onResize($event: Event): void {
    this.setMaxTabs();
    this.changeTabsOrder();
  }

  onTabClick(index: number): void {
    if (this.activeTabIndex !== index) {
      this.activeTabIndex = index;
      this.changeTabsOrder();
      window.setTimeout(() => {
        this.changeDetectionRef.detectChanges();
      }, 0)
    }
  }

  onMenuOpen(): void {
    document.addEventListener('click', this.closeMenu);
    for ( const item of this.menuItems) {
      item._getHostElement().setAttribute('azure-id', item.getLabel());
    }
  }

  onMenuClose(): void {
    document.removeEventListener('click', this.closeMenu);
  }

  trackByFunction(index: number, item: NavigationTabConfig): string {
    return item.id;
  }

  private setTabsWidth(): void {
    this.matTabLinkList.forEach((matTabLink: MatTabLink, index: number) => {
      this.tabs[index].width = matTabLink.elementRef.nativeElement.clientWidth;
    });
  }

  private setMaxTabs(): void {
    const componentElement = this.elementRef.nativeElement;
    const componentWidth = componentElement.clientWidth || componentElement.parentElement.clientWidth;
    let totalWidth = 0;
    const index = this.tabs.findIndex((tab: NavigationTabConfig) => {
      const isOverflow = totalWidth + tab.width > componentWidth;
      if (isOverflow) {
        return true;
      }
      totalWidth += tab.width;
      return false;
    });
    if (index === -1) {
      this.maxTabs = this.totalTabs;
      return;
    }
    const triggerWidth = this.matMenuTrigger['_element'].nativeElement.clientWidth;

    if (componentWidth - totalWidth < triggerWidth) {
      this.maxTabs = index - 1;
      return;
    }
    this.maxTabs = index;
  }

  private changeTabsOrder(): void {
    if (!this.activeTabIndex) {
      return;
    }
    this.activeTabIndex = this.tabs[this.activeTabIndex].index;
    this.tabs.sort((tab1: NavigationTabConfig, tab2: NavigationTabConfig) => tab1.index - tab2.index);

    let sourceIndex: number, targetIndex: number;

    const isMenuTab = this.activeTabIndex >= this.maxTabs;
    if (isMenuTab) {
      sourceIndex = this.activeTabIndex;
      targetIndex = this.maxTabs - 1;
      this.moveTab(sourceIndex, targetIndex);
      this.activeTabIndex = targetIndex;
    }

    const maxTabs = this.maxTabs;
    this.setMaxTabs();

    const diff = maxTabs - this.maxTabs;
    sourceIndex = this.activeTabIndex;
    if (diff > 0 || this.tabs[this.activeTabIndex].index > this.maxTabs - 1) {
      targetIndex = this.activeTabIndex - diff;
    } else {
      targetIndex = this.tabs[this.activeTabIndex].index;
    }
    this.moveTab(sourceIndex, targetIndex);
    this.activeTabIndex = targetIndex;
  }

  private moveTab(sourceIndex: number, targetIndex: number): void {
    const tab = this.tabs[sourceIndex];
    this.tabs.splice(sourceIndex, 1);
    this.tabs.splice(targetIndex, 0, tab);
  }

  private closeMenu = ($event: MouseEvent): void => {
    const triggerElement = this.matMenuTrigger['_element'].nativeElement;
    const menuElement = this.matMenuTrigger['_overlayRef']._pane;
    if (!triggerElement.contains($event.target) && !menuElement.contains($event.target)) {
      this.matMenuTrigger.closeMenu();
    }
  };

  ngOnDestroy(): void {
    this.subscriber.unsubscribe();
  }
}
