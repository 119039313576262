import { Injectable } from '@angular/core';
import { MessagesQueueType } from '../../../messages/models/enums/messages-queue-type.enum';
import { Entity } from '../../../shared/models/enums/entity.enum';
import { SearchQueryService } from '../../../shared/search/services/search-query.service';
import { RecentSearchResponse } from '../models/recent-search-response.model';
import { RecentSearch } from '../models/recent-search.model';
import { IRecentSearchMapperService } from './interfaces/recent-search-mapper-service.interface';

@Injectable()
export class RecentSearchMapperService implements IRecentSearchMapperService {

  constructor(private searchQueryService: SearchQueryService) { }

  map(recentSearchResponse: RecentSearchResponse, entity: Entity): RecentSearch {
    if (!recentSearchResponse.searchQuery.additionalParameters) {
      recentSearchResponse.searchQuery.additionalParameters = {};
    }
    return {
      id: recentSearchResponse.id.toString(),
      alias: this.searchQueryService.toHumanString(recentSearchResponse.searchQuery),
      searchDate: new Date(recentSearchResponse.searchDate),
      searchQuery: recentSearchResponse.searchQuery,
      type: entity,
      queueType: MessagesQueueType.RECENT_SEARCH,
      routerLink: `/home/messages-center/recent-searches/${entity}`,
      stateName: `home.messagesCenter.layout.taskBar.recent-searches.entity.${entity}.grid`,
      stateParams: {
        queueId: recentSearchResponse.id.toString()
      }
    };
  }

  mapAll(recentSearchesResponse: Array<RecentSearchResponse>, entity: Entity): Array<RecentSearch> {
    return recentSearchesResponse?.map((recentSearchResponse: RecentSearchResponse) => this.map(recentSearchResponse, entity));
  }

}
