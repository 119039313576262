import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FnUiFavoriteIconComponent } from './fn-ui-favorite-icon.component';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { FnUiIconModule } from '../fn-ui-icon/fn-ui-icon.module';


@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    TranslateModule.forChild(),
    FnUiIconModule
  ],
  declarations: [
    FnUiFavoriteIconComponent
  ],
  exports: [
    FnUiFavoriteIconComponent
  ]
})
export class FnUiFavoriteIconModule { }
