import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {PbiTaskbarItem} from "../../enums/pbi-taskbar-item.enum";
import {OperationDashboardComponent} from "../operational-dashboard/operational-dashboard.component";
import {PowerBiReportsComponent} from "../../power-bi/components/power-bi-reports/power-bi-reports.component";
import {Subscription} from "rxjs";


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnInit, OnDestroy {
  @Input() injectableData: any;
  @Input() dashboardComponent;

  subscriptions = new Subscription();

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit() {
    const  sub = this.route.params.subscribe(param => {
      this.dashboardComponent = param.taskBarItem === PbiTaskbarItem.OPERATIONAL_DASHBOARD ? OperationDashboardComponent : PowerBiReportsComponent;
    });
    this.subscriptions.add(sub);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
