import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/portal';
import { IFnUiDialogService } from './fn-ui-dialog-service.interface';

@Injectable()
export class FnUiDialogService implements IFnUiDialogService {

  get openDialogs(): Array<MatDialogRef<any, any>> {
    return this.dialog.openDialogs;
  }

  private defaultConfig: MatDialogConfig = {
    autoFocus: false,
    hasBackdrop: true
  };

  constructor(private dialog: MatDialog) { }

  open<TComponent, TDialogData>(component: ComponentType<TComponent>, config?: MatDialogConfig<TDialogData>): MatDialogRef<TComponent> {
    const mergedConfig = { ...this.defaultConfig, ...config };
    return this.dialog.open(component, mergedConfig);
  }

  closeAll(): void {
    this.dialog.closeAll();
  }

  getDialogById(id: string): MatDialogRef<any, any> {
    return this.dialog.getDialogById(id);
  }

  getActiveDialog(): MatDialogRef<any, any> {
    if (this.openDialogs.length > 0) {
      return this.openDialogs[this.openDialogs.length - 1];
    }
    return null;
  }

}
