import { SearchResourceType } from '../models';

export const SEARCH_RESOURCES_CONFIG = [
    {
        type: SearchResourceType.SITEMAP
    },
    {
        type: SearchResourceType.RULE_TYPES
    }
];
