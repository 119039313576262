import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { GridContainerConfig } from '../../../grid/models/grid-container-config.model';
import { GridEntityConfigService } from '../../../messages/services/grid-entity-config.service';

export const ProfileGridConfigResolver: ResolveFn<GridContainerConfig> =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): GridContainerConfig => {

    const gridEntityConfigService = inject(GridEntityConfigService);

    return gridEntityConfigService.getProfilesGridConfig();
  };
