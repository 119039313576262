import { Injectable } from '@angular/core';
import { TreeConfig } from '@fgpp-ui/components';
import { Observable, map, tap } from 'rxjs';
import { LiquidityTaskBarItem, CustomAccountGroupsTree, AccountNode, CustomAccountGroupNode, LiquidityNode, CustomAccountOfficeNode } from '../models';
import { ILiquidityTaskBarItemService } from './interfaces/liquidity-task-bar-item-service.interface';
import { LiquidityApiService } from './liquidity-api.service';

@Injectable()
export class CustomAccountGroupsService implements ILiquidityTaskBarItemService {

  get taskBarItem(): LiquidityTaskBarItem {
    return LiquidityTaskBarItem.CUSTOM_ACCOUNT_GROUPS;
  }

  get treeConfig(): Partial<TreeConfig> {
    return this._treeConfig;
  }

  get tree(): CustomAccountGroupsTree {
    return this._tree;
  }

  get activeNode(): AccountNode | CustomAccountGroupNode {
    return this._activeNode;
  }

  set activeNode(value: AccountNode | CustomAccountGroupNode) {
    this._activeNode = value;
  }

  get lastUpdate(): Date {
    return this._lastUpdate;
  }

  private _treeConfig: Partial<TreeConfig>;
  private _tree: CustomAccountGroupsTree;
  private _activeNode: AccountNode | CustomAccountGroupNode;
  private _lastUpdate: Date;

  constructor(private liquidityApiService: LiquidityApiService) {
    this.initTreeConfig();
   }

  getTree(): Observable<CustomAccountGroupsTree> {
    return this.liquidityApiService.getCustomAccountGroups().pipe(map((response: CustomAccountGroupsTree) => {
      this.setRoutingData(response);
      this._lastUpdate = new Date();
      this._tree = response;
      return response;
    }));
  }

  refreshTree(): Observable<CustomAccountGroupsTree> {
    return this.getTree().pipe(tap(() => {
      this.updateActiveNode();
    }));
  }

  findActiveNode(id: string, parentId?: string): AccountNode | CustomAccountGroupNode {
    for (const officeNode of this.tree.nodes) {
      for (const accountGroupNode of officeNode.nodes) {
        if (accountGroupNode.id === id) {
          return accountGroupNode;
        }
        for (const accountNode of accountGroupNode.nodes) {
          if (accountNode.id === id && accountNode.data.parentId == parentId) {
            return accountNode;
          }
        }
      }
    }
    return null;
  }

  private initTreeConfig(): void {
    const getUId = (node: LiquidityNode) => node.data?.parentId ? `${node.data.parentId}.${node.id}` : node.id;
    this._treeConfig = {
      getUId: getUId,
      expandable: (node: LiquidityNode) => !node.data?.uid,
      selectable: (node: LiquidityNode) => !!node.data?.uid || !!node.data?.groupUID,
      trackBy: (node) => node,
      expansionTrackBy: getUId
    };
  }

  private setRoutingData(tree: CustomAccountGroupsTree): void {
    tree.nodes.forEach((officeNode: CustomAccountOfficeNode) => {
      officeNode.nodes.forEach((accountGroupNode: CustomAccountGroupNode) => {

        accountGroupNode.routerLink = `/home/liquidity/${this.taskBarItem}`;
        accountGroupNode.stateName = 'home.liquidity.views.custom-account-groups.single';
        accountGroupNode.stateParams = { account: accountGroupNode.id };

        accountGroupNode.nodes.forEach((accountNode: AccountNode) => {
          accountNode.routerLink = `/home/liquidity/${this.taskBarItem}`;
          accountNode.stateName = 'home.liquidity.views.custom-account-groups.single';
          accountNode.stateParams = {
            account: accountNode.id,
            parent: accountNode.data.parentId
          };
        });
      });
    });
  }

  private updateActiveNode(): void {
    if (this.activeNode) {
      this.activeNode = this.findActiveNode(this.activeNode.id, (<AccountNode> this.activeNode).data.parentId);
    }
  }
}
