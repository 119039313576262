import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map, of } from 'rxjs';
import { IKeyboardConfigurationService } from './interfaces/keyboard-configuration-service.interface';
import { KeyboardConfiguration } from '../models/keyboard-configuration.model';
import { KeyboardMode } from '../models/enums/keyboard-mode.enum';

@Injectable()
export class KeyboardConfigurationService implements IKeyboardConfigurationService {

  private readonly url = 'assets/config/keyboard/keyboardShortcutsConfiguration.json';
  private configuration: KeyboardConfiguration;

  constructor(private http: HttpClient) { }

  getKeyboardMode(): Observable<KeyboardMode> {
    return this.getConfiguration().pipe(
      map((configuration: KeyboardConfiguration) => {
        return configuration.mode;
      })
    );
  }

  getResourceUrl(): Observable<string> {
    return this.getConfiguration().pipe(
      map((configuration: KeyboardConfiguration) => {
        return configuration.resourceUrl;
      })
    );
  }

  private getConfiguration(): Observable<KeyboardConfiguration> {
    if (this.configuration) {
      return of(this.configuration);
    }
    return this.http.get<KeyboardConfiguration>(this.url).pipe(
      map((response: KeyboardConfiguration) => {
        this.configuration = response;
        return this.configuration;
      })
    );
  }

}
