<mat-menu #childMenu="matMenu" [overlapTrigger]="false" class="user-settings-menu">
  <div *ngFor="let child of items">
    <div *ngIf="child.children && child.children.length > 0">
      <button mat-menu-item [matMenuTriggerFor]="menu.childMenu" class="user-menu-item">
         <mat-icon color="primary" class="mdi mdi-{{child.iconName}}"></mat-icon>
        <span>{{child.displayName | translate}}</span>
      </button>
      <lib-menu-item #menu [items]="child.children"></lib-menu-item>
    </div>
    <div *ngIf="!child.children || child.children.length === 0">
      <button mat-menu-item (contextmenu)="onRightClick(child)" (click)="onLeftClick(child)" class="user-menu-item" >
          <mat-icon color="primary" class="mdi mdi-{{child.iconName}}"></mat-icon>
          <span>{{child.displayName | translate}}</span>
       </button>
    </div>
  </div>
</mat-menu>
