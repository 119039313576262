import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { FnUiButtonModule } from '../shared/fn-ui-button/fn-ui-button.module';
import { FnUiDialogModule } from '../shared/fn-ui-dialog/fn-ui-dialog.module';
import { FnUiCloseIconModule } from '../shared/fn-ui-icons/fn-ui-close-icon/fn-ui-close-icon.module';
import { FnUiModalModule } from '../shared/fn-ui-modals/fn-ui-modal.module';
import { FnUiNavigationActionsTreeModule } from '../shared/fn-ui-navigation-actions-tree/fn-ui-navigation-actions-tree.module';
import { FnUiNavigationTreeModule } from '../shared/fn-ui-navigation-tree/fn-ui-navigation-tree.module';

import { HoldModalComponent } from './components/hold-modal/hold-modal.component';
import { LiquidityComponent } from './components/liquidity/liquidity.component';
import { MopSelectionModalComponent } from './components/mop-selection-modal/mop-selection-modal.component';
import { CustomAccountGroupsNavigationComponent } from './components/navigation/custom-account-groups-navigation/custom-account-groups-navigation.component';
import { LiquidityFavoritesNavigationComponent } from './components/navigation/liquidity-favorites-navigation/liquidity-favorites-navigation.component';
import { PartyExplorerNavigationComponent } from './components/navigation/party-explorer-navigation/party-explorer-navigation.component';
import { SystemAccountGroupsNavigationComponent } from './components/navigation/system-account-groups-navigation/system-account-groups-navigation.component';
import { RidirectLiquidityGuard } from './routing/guards/redirect-liquidity.guard';

import { CustomAccountGroupsService } from './services/custom-account-groups.service';
import { GPPFrontendLiquidityService } from './services/gpp-frontend-liquidity.service';
import { LiquidityApiService } from './services/liquidity-api.service';
import { LiquidityFavoriteNodeActionsService } from './services/liquidity-favorite-node-actions.service';
import { LiquidityFavoritesService } from './services/liquidity-favorites.service';
import { LiquidityModalsService } from './services/liquidity-modals.service';
import { LiquidityStatesService } from './services/liquidity-states.service';
import { LiquidityStoreService } from './services/liquidity-store.service';
import { LiquidityService, LIQUIDITY_ITEM_TOKEN } from './services/liquidity.service';
import { PartyExplorerService } from './services/party-explorer.service';
import { SystemAccountGroupsService } from './services/system-account-groups.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    TranslateModule.forChild(),
    FnUiButtonModule,
    FnUiDialogModule,
    FnUiCloseIconModule,
    FnUiModalModule,
    FnUiNavigationActionsTreeModule,
    FnUiNavigationTreeModule,
    // LiquidityRoutingModule,
    HttpClientModule
  ],
  declarations: [
    HoldModalComponent,
    MopSelectionModalComponent,
    CustomAccountGroupsNavigationComponent,
    LiquidityFavoritesNavigationComponent,
    PartyExplorerNavigationComponent,
    SystemAccountGroupsNavigationComponent,
    LiquidityComponent
  ],
  providers: [
    LiquidityApiService,
    LiquidityModalsService,
    LiquidityService,
    LiquidityStatesService,
    LiquidityStoreService,
    GPPFrontendLiquidityService,
    SystemAccountGroupsService,
    CustomAccountGroupsService,
    PartyExplorerService,
    LiquidityFavoritesService,
    LiquidityFavoriteNodeActionsService,
    RidirectLiquidityGuard,
    {
      provide: LIQUIDITY_ITEM_TOKEN,
      multi: true,
      useExisting: SystemAccountGroupsService
    },
    {
      provide: LIQUIDITY_ITEM_TOKEN,
      multi: true,
      useExisting: CustomAccountGroupsService
    },
    {
      provide: LIQUIDITY_ITEM_TOKEN,
      multi: true,
      useExisting: PartyExplorerService
    },
    {
      provide: LIQUIDITY_ITEM_TOKEN,
      multi: true,
      useExisting: LiquidityFavoritesService
    }
  ]
})
export class LiquidityModule {

  constructor(gppFrontendLiquidityService: GPPFrontendLiquidityService) {
    window['GPPFrontEndLiquidity'] = () => gppFrontendLiquidityService;
  }

}
