export const ALERT_DETILES_FIELDS = [
    { name: 'type', translate: true },
    { name: 'status', translate: false },
    { name: 'action', translate: false },
    { name: 'alias', translate: false },
    { name: 'tooltip', translate: false },
    { name: 'payByApproaching', translate: false },
    { name: 'accountUid', translate: false },
    { name: 'accountOffice', translate: false },
    { name: 'positionFigure', translate: false },
    { name: 'positionFigureValue', translate: false },
    { name: 'permittedQueue', translate: false },
    { name: 'caption', translate: false }
];
