import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AccountPosition, LimitsAccumulated, PartyOutstanding } from '../../../liquidity/models/liquidity-search-params.model';
import { XmlJsonParserService } from '../../../services/xml-json-parser.service';
import { FormatsService } from '../../services/formats.service';
import { AdditionalParameters } from '../models/additional-parameters.model';

export const SMART_SEARCH_PARAMETER_NAME = 'ADVANCED_SEARCH_FILTER';
export const ACCOUNT_POSITION_PARAMETER_NAME = 'ACCOUNT_POSITION';
export const LIMITS_ACCUMULATED_AMOUNT_PARAMETER_NAME = 'LIMITS_ACCUMULATED_AMOUNT';
export const EXPOSURE_ACCUMULATED_AMOUNT_PARAMETER_NAME = 'EXPOSURE_ACCUMULATED_AMOUNT';

@Injectable({
  providedIn: 'root'
})
export class AdditionalParametersService {

  constructor(private translateService: TranslateService,
              private xmlJsonParserService: XmlJsonParserService,
              private formatsService: FormatsService) { }

  toString(additionalParameters: AdditionalParameters): string {
    const keys = Object.keys(additionalParameters || {});
    const array = keys.map((key: string) => {
      const value = additionalParameters[key];
      const valueAsString = typeof value === 'object' ? JSON.stringify(value) : value.toString();
      return `(${key}:${valueAsString})`;
    });
    return `<${array.join('')}>`;
  }

  toHumanString(additionalParameters: AdditionalParameters): string {
    const keys = Object.keys(additionalParameters || {});
    if (!keys.length) {
      return '';
    }
    const array = keys.map((key: string) => {
      const value = additionalParameters[key];
      if (value === undefined) {
        return '';
      }
      switch (key) {
        case SMART_SEARCH_PARAMETER_NAME:
          return this.xmlJsonParserService.prepareSearchStringFromXml(value as string);
        case ACCOUNT_POSITION_PARAMETER_NAME:
          return this.accountPositionToHumanString(value as AccountPosition);
        case LIMITS_ACCUMULATED_AMOUNT_PARAMETER_NAME:
          return this.limitsAccumulatedToHumanString(value as LimitsAccumulated);
        case EXPOSURE_ACCUMULATED_AMOUNT_PARAMETER_NAME:
          return this.partyOutstandingToHumanString(value as PartyOutstanding);
        default:
          return `(${key}:${value})`;
      }
    });
    return `<${array.join(' ')}>`;
  }

  accountPositionToHumanString(accountPosition: AccountPosition): string {
    const accountPositionLabel = this.translateService.instant('messages_center.recent_searches.acount_position_title');
    const amount = this.formatsService.toNumberFormat(accountPosition.amount);
    const currency = accountPosition.accountUIDPrivate.split('^').pop();
    return `${accountPositionLabel}: ${amount} ${currency}`;
  }

  limitsAccumulatedToHumanString(limitsAccumulated: LimitsAccumulated): string {
    const partyExplorerLabel = this.translateService.instant('messages_center.recent_searches.party_explorer_title');
    const partyID = limitsAccumulated.PARTY_ID;
    return `${partyExplorerLabel}: ${partyID}`;
  }

  partyOutstandingToHumanString(partyOutstanding: PartyOutstanding): string {
    const partyExplorerLabel = this.translateService.instant('messages_center.recent_searches.party_explorer_title');
    const partyID = partyOutstanding.PARTY_ID;
    const currency = partyOutstanding.CURRENCY;
    return `${partyExplorerLabel}: ${partyID} - ${currency}`;
  }

}
