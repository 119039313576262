import { Injectable } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';
import dayjs from 'dayjs';
import { FormatsService } from '../shared/services/formats.service';

export enum ValidtorType {
  Mandatory,
  ConstantError
}

@Injectable({
  providedIn: 'root'
})
export class FormValidatorsService {

  constructor(private formatsService: FormatsService) { }

  setValidators(validations) {
    if (validations == null) {
      return;
    }

    const validatorsArr = [];
    for (let i = 0; i < validations.length; i++) {
      if (typeof this[validations[i].name] === 'function') {
        validatorsArr.push(this[validations[i].name](validations[i].arguments));
      }
    }

    return Validators.compose(validatorsArr);
  }

  drillDownReciverError() {
    return (control: AbstractControl) => {
      return { drillDownReciverError: true };
    };
  }

  // All function below will be called dynamically from getValidators

  forceFieldError() {
    return () => {
      return { forceFieldError: true };
    };
  }

  requiredValidator() {
    return Validators.required;
  }

  MaxNumber(maxNumber: number) {
    return (control: AbstractControl) => {
      let value;
      try {
        const thousandsSeparator = this.formatsService.thousandSeparator;
        const regex = new RegExp(thousandsSeparator, 'g');
        value = parseFloat(control.value.replace(regex, ''));
      } catch (e) {
        //do nothing, don't fail
      }
      if (value == null || value.length === 0) { //empty value is allowed
        return null;
      }
      if (value <= maxNumber) {
        return null;
      }
      return { MaxNumber: true };
    };
  }

  MaxLength(length) {
    return (control: AbstractControl) => {

      const value = typeof (control.value) === 'number' ? '' + control.value : control.value;

      if (value == null || value.length === 0) { //empty value is allowed
        return null;
      }
      if (value.length != null && value.length <= parseInt(length)) {
        return null;
      }
      return { MaxLength: true };
    };
  }

  ExactLength(length) {
    return (control: AbstractControl) => {
      if (control.value == null || control.value.length === 0) { //empty value is allowed
        return null;
      }
      if (control.value.length != null && control.value.length === parseInt(length[0])) {
        return null;
      }
      return { ExactLength: true };
    };
  }

  MultiFieldMaxLength(value) {
    return (control: AbstractControl) => {
      if (control.value != null && control.value.length > 0 && value != null) {
        const keys = Object.keys(control.value[0]);
        for (let i = 0; i < control.value.length; i++) {
          if (control.value[i][keys[0]].length > 0 && control.value[i][keys[0]].length < value[keys[0]]) {
            return { MultiFieldMaxLength: [i, keys[0]] };
          }
          if (control.value[i][keys[1]].length > 0 && control.value[i][keys[1]].length < value[keys[1]]) {
            return { MultiFieldMaxLength: [i, keys[1]] };
          }
        }
      }
      return null;
    };
  }

  MultiFieldExactLength(value) {
    return (control: AbstractControl) => {
      if (control.value != null && control.value.length > 0 && value != null) {
        const keys = Object.keys(control.value[0]);
        for (let i = 0; i < control.value.length; i++) {
          if (control.value[i][keys[0]].length > 0 && control.value[i][keys[0]].length !== value[keys[0]]) {
            return { MultiFieldExactLength: [i, keys[0]] };
          }
          if (control.value[i][keys[1]].length > 0 && control.value[i][keys[1]].length !== value[keys[1]]) {
            return { MultiFieldExactLength: [i, keys[1]] };
          }
        }
      }
      return null;
    };
  }

  BICLength() {
    return (control: AbstractControl) => {
      if (control.value == null || control.value.length === 0) { //empty value is allowed
        return null;
      }
      if (control.value.length === 8 || control.value.length === 11) {
        return null;
      }
      return { BICLength: true };
    };
  }

  DrilldownValueExistsValidation() {
    return () => {
      return { drillDownValueDoesNotExist: true };
    };
  }

  CommaPresence() {
    return (control: AbstractControl) => {
      if (control.value == null || control.value.length === 0) { //empty value is allowed
        return null;
      }
      if (control.value.toString().indexOf(this.formatsService.decimalSeparator) === -1) {
        return { CommaPresence: true };
      }
      return null;
    };
  }

  MinLengthIntegerPartInDecimal(length) {
    return (control: AbstractControl) => {
      if (control.value == null || control.value.length === 0) { //empty value is allowed
        return null;
      }
      const value = control.value.toString();
      const decimalSeparatorIdx = value.indexOf(this.formatsService.decimalSeparator);
      let digitsBeforeDecimalSeparatorCount;

      if (decimalSeparatorIdx === -1) {
        digitsBeforeDecimalSeparatorCount = value.length;
      } else {
        digitsBeforeDecimalSeparatorCount = value.substr(0, decimalSeparatorIdx).length;
      }

      if (digitsBeforeDecimalSeparatorCount < length[0]) {
        return { MinLengthIntegerPartInDecimal: true };
      }

      return null;
    };
  }

  NumberOfDigitsAfterPrecision(precision) {
    return (control: AbstractControl) => {
      if (control.value == null || control.value.length === 0) { //empty value is allowed
        return null;
      }

      const value = control.value.toString();
      const precisionIdx = value.indexOf(this.formatsService.decimalSeparator);

      if (parseInt(precision[0]) === 0) { // when configured 0 decimal digits
        return (precisionIdx === -1) ? null : { NumberOfDigitsAfterPrecision: true };
      }

      if (precisionIdx === -1) {
        return { NumberOfDigitsAfterPrecision: true };
      }
      const digitsAfterPrecisionCount = control.value.toString().substr(precisionIdx + 1).length;
      if (digitsAfterPrecisionCount !== parseInt(precision[0])) {
        return { NumberOfDigitsAfterPrecision: true };
      }
      return null;
    };
  }

  StartsWith(beginChars) {
    return (control: AbstractControl) => {
      if (control.value == null || control.value.length === 0) { //empty value is allowed
        return null;
      }
      const pattern = '^' + beginChars[0];
      if (control.value.toString().match(pattern) === null) {
        return { StartsWith: true };
      }
      return null;
    };
  }

  MustNotStartWith(beginChars) {
    return (control: AbstractControl) => {
      if (control.value == null || control.value.length === 0) { //empty value is allowed
        return null;
      }
      const pattern = '^' + beginChars[0];
      if (control.value.toString().match(pattern) != null) {
        return { MustNotStartWith: true };
      }
      return null;
    };
  }

  MustNotEndWith(endChars) {
    return (control: AbstractControl) => {
      if (control.value == null || control.value.length === 0) { //empty value is allowed
        return null;
      }
      const pattern = endChars[0] + '$';
      if (control.value.toString().match(pattern) != null) {
        return { MustNotEndWith: true };
      }
      return null;
    };
  }

  CharNotAllowed(chars) {
    return (control: AbstractControl) => {
      if (control.value == null || control.value.length === 0) { //empty value is allowed
        return null;
      }
      if (control.value.indexOf(chars[0]) > -1) {
        return { CharNotAllowed: true };
      }
      return null;
    };
  }

  MandatoryField() {
    return (control: AbstractControl) => {
      if (control.value == null || (typeof control.value === 'string' && control.value.trim() === '')) {
        return { MandatoryField: true };
      }
      return null;
    };
  }

  ValuesNotAllowed(values: string[]) {
    return (control: AbstractControl) => {
      if (control.value == null || control.value.length === 0) { //empty value is allowed
        return null;
      }
      for (const value of values) {
        if (control.value.toString().indexOf(value) > -1) {
          return { ValuesNotAllowed: true };
        }
      }
      return null;
    };
  }

  //validation for dh-select-simple component only
  InStaticList(values: { staticList: any[] }) {
    if (values == null || values.staticList == null) {
      return null;
    }
    return (control: AbstractControl) => {
      if (control.value == null || control.value.length === 0) { //empty value is allowed
        return null;
      }
      const valueInList = this.checkIfValueInList(control.value, values.staticList);
      if (!valueInList) {
        return { InStaticList: true };
      }
      return null;
    };
  }

  //validation for dh-select-simple component only
  MultiFieldInStaticList(values: { staticList: any[] }) {
    if (values == null || values.staticList == null) {
      return null;
    }
    return (control: AbstractControl) => {
      if (control.value == null || control.value.length === 0) { //empty value is allowed
        return null;
      }
      const valueInList = this.checkIfValueInList(control.value, values.staticList);
      if (!valueInList) {
        return { MultiFieldInStaticList: true };
      }
      return null;
    };
  }

  private checkIfValueInList(value, list) {
    let valueInList = false;
    for (const elem of list) {
      if (elem.id != null && elem.id === value) {
        valueInList = true;
        break;
      }
    }
    return valueInList;
  }

  // Not Used
  DigitsOnly() {
    return (control: AbstractControl) => {
      if (control.value == null || control.value.length === 0) { //empty value is allowed
        return null;
      }
      if (isNaN(this.formatsService.toNumber(control.value))) {
        return { DigitsOnly: true };
      }
      return null;
    };
  }

  sightVerification() {
    return () => {
      return { sightVerification: true };
    };
  }

  clickVerification() {
    return () => {
      return { clickVerification: true };
    };
  }

  rekeyVerification() {
    let origValue;
    let isFirstVerification = true;
    return (control: AbstractControl) => {
      if (isFirstVerification) {
        origValue = control.value;
        isFirstVerification = false;
        return { rekeyVerification: true };
      }
      if (control.value instanceof dayjs) {
        if ((control.value as any).unix() !== origValue.unix() || !control.dirty) {
          return { rekeyVerification: true };
        }
      } else {
        const value = typeof (origValue) === 'number' ? parseFloat(control.value) : control.value;
        if ((value && value != origValue) || !control.dirty) {
          return { rekeyVerification: true };
        }
      }

      return null;
    };
  }

  blindVerification() {
    let origValue;
    let isFirstVerification = true;
    return (control: AbstractControl) => {
      if (isFirstVerification) {
        origValue = control.value;
        isFirstVerification = false;
        return { rekeyVerification: true };
      }

      if (control.value instanceof dayjs) {
        if ((control.value as any).unix() !== origValue.unix() || !control.dirty) {
          return { rekeyVerification: true };
        }
      } else {
        const value = typeof (origValue) === 'number' ? parseFloat(control.value) : control.value;
        if ((value && value != origValue) || !control.dirty) {
          return { rekeyVerification: true };
        }
      }

      return null;
    };
  }

  slashValidator() {
    return (control: AbstractControl) => {
      if (control.value == null || control.value.length === 0) { //empty value is allowed
        return null;
      }
      const pattern = '(^/|//)|/$';
      if (control.value.match(pattern) !== null) {
        return { slashValidator: true };
      }
      return null;
    };
  }

  identifierCodeValidator() {
    return (control: AbstractControl) => {
      if (control.value == null || control.value.length === 0) { //empty value is allowed
        return null;
      }
      const pattern = '^//';
      return control.value.match(pattern) === null ? { identifierCodeValidator: true } : null;
    };
  }

  NonNegativeValue() {
    return (control: AbstractControl) => {
      return control.value < 0 ? { NonNegativeValue: true } : null;
    };
  }

  AllowedCharacters(chars: string) {
    return (control: AbstractControl) => {
      const value: string = control.value;
      if (value == null || value.length === 0) { //empty value is allowed
        return null;
      }

      const regex = RegExp(chars[0]);

      return regex.test(value) ? null : { allowedCharacters: true };
    };
  }

  NccCodeLength(lengthValues: number[]) {
    return (control: AbstractControl) => {
      if (control.value == null || control.value.length === 0) { //empty value is allowed
        return null;
      }
      if (lengthValues[0] == null || lengthValues[1] == null) {
        return null;
      }
      if (lengthValues && control.value.length >= lengthValues[0] && control.value.length <= lengthValues[1]) {
        return null;
      }
      return { NccCodeLength: true };
    };
  }

  getValidatorArrayByController(controllerName: string, metaData, validatorType?: ValidtorType) {
    const mandatoryObj = { name: 'MandatoryField', arguments: null };
    const constantErrorObject = { name: 'drillDownReciverError', arguments: null };
    const validatorArray: Array<any> = metaData[controllerName]['validations'];
    if (validatorType === ValidtorType.Mandatory) {
      return this.setValidators(Array.prototype.concat.apply(validatorArray, [mandatoryObj]));
    }
    if (validatorType === ValidtorType.ConstantError) {
      return this.setValidators(Array.prototype.concat.apply(validatorArray, [constantErrorObject]));
    }
    return this.setValidators(validatorArray);
  }

  IllegalIbanLength() {
    return () => {
      return { IllegalIbanLength: true };
    };
  }

  CapitalLettersAndDigits() {
    return () => {
      return { CapitalLettersAndDigits: true };
    };
  }

  CapitalLettersForIsoCountryCode() {
    return () => {
      return { CapitalLettersForIsoCountryCode: true };
    };
  }

  Chars3and4MustBeDigits() {
    return () => {
      return { Chars3and4MustBeDigits: true };
    };
  }

  InvalidIsoCountryCode() {
    return () => {
      return { InvalidIsoCountryCode: true };
    };
  }

  LengthNotDefinedForCountryCode() {
    return () => {
      return { LengthNotDefinedForCountryCode: true };
    };
  }

  LengthDiffThenRequiredForCountryCode() {
    return () => {
      return { LengthDiffThenRequiredForCountryCode: true };
    };
  }

  InvalidIbanCheckDigitFailed() {
    return () => {
      return { InvalidIbanCheckDigitFailed: true };
    };
  }
}
