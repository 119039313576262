<div class="navigation-result-item">
    <a class="navigation-item-link"
       [routerLink]="[item.routerLink]"
       [queryParams]="item.stateParams"
       (click)="onNavigationItemClick()">
        <div class="navigation-item-icon">
            <app-fn-ui-icon [icon]="item.icon"></app-fn-ui-icon>
        </div>
        <div class="navigation-item-details">
            <span class="item-alias" appHighlightSearch [content]="item.alias" [searchTerm]="searchTerm"></span>
            <div class="item-path">
                <span>{{ item.path[0] }}</span>
                <ng-container *ngFor="let part of item.path | slice : 1">
                    <span> / </span>
                    <span appHighlightSearch [content]="part" [searchTerm]="searchTerm"></span>
                </ng-container>
            </div>
        </div>
    </a>
</div>
