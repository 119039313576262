import { SearchCriteria } from '../../../../grid/models/grid-search-query.model';
import { LogicalOperator } from '../enums/logical-operator.enum';

export class SearchExpression {
  class = 'SearchExpression';
  logicalOperator: LogicalOperator;
  leftOperand: SearchCriteria;
  rightOperand: SearchCriteria;

  constructor(leftOperand: SearchCriteria, logicalOperator: LogicalOperator, rightOperand: SearchCriteria) {
    this.leftOperand = leftOperand;
    this.logicalOperator = logicalOperator;
    this.rightOperand = rightOperand;
  }

}
