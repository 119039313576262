import { HttpInterceptor, HttpRequest, HttpEvent, HttpErrorResponse, HttpHeaders, HttpHandler, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationService } from '@fgpp-ui/components';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Observable, catchError, filter, from, switchMap, take, throwError } from 'rxjs';
import { AuthenticationIdpService } from '../app/authentication/services/authentication-idp.service';
import { AuthenticationService } from '../app/authentication/services/authentication.service';
import { AuthenticationConfigService } from '../app/authentication/services/authentication-config.service';
import { SignOutService } from '../app/authentication/services/sign-out.service';
import { UpdateSession } from '../app/core/store/session/action/session.action';
import { PopOutService } from '../app/services/pop-out.service';
import { AuthenticationMode } from '../app/authentication/models/enums/authentication-mode.enum';
import { AuthenticationUrls } from '../app/authentication/models/enums/authentication-urls.enum';
import { ModalsService } from '../app/shared/fn-ui-modals/services/modals.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AlertModalComponent } from '../app/shared/fn-ui-modals/components/alert-modal/alert-modal.component';

@Injectable()
export class DefaultsInterceptor implements HttpInterceptor {

  private sessionId: string;
  private accessToken: string;
  private isPopout = false;
  private isTokenRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private readonly errorDuration = 5000;
  private invalidSessionModal: MatDialogRef<AlertModalComponent>;

  constructor(private store: Store,
              private authenticationConfigService: AuthenticationConfigService,
              private authenticationIdpService: AuthenticationIdpService,
              private popOutService: PopOutService,
              private notificationService: NotificationService,
              private authenticationService: AuthenticationService,
              private modalsService: ModalsService,
              private translateService: TranslateService,
              private signOutService: SignOutService) { }

  intercept(req: HttpRequest<any>, next): Observable<HttpEvent<any>> {

    let clonedRequest = req.clone();

    if (!this.authenticationIdpService.isIDPRequest(req)) {
      switch (req.method) {
        case 'POST':
          if (!req.headers.get('Content-Type')) {
            clonedRequest = req.clone({
              headers: req.headers.set('Content-Type', 'application/json;charset=utf-8')
            });
          }
          break;
        case 'GET':
          clonedRequest = req.clone({
            //disable IE ajax request caching
            headers: req.headers
              .set('If-Modified-Since', 'Mon, 26 Jul 1997 05:00:00 GMT')
              .set('Cache-Control', 'no-cache')
              .set('Pragma', 'no-cache')
          });
          break;
        case 'PUT':
          clonedRequest = req.clone({
            headers: req.headers
              .set('Cache-Control', 'no-cache')
              .set('Pragma', 'no-cache')
          });
          break;
      }
    }

    return from(this.addAuthenticationHeader(clonedRequest, next));
  }

  private isInvalidSessionId(response: HttpErrorResponse): boolean {
    if ((response.status === 401 || response.status === 403) && response.error != null) {
      return response.error === 'InvalidSessionId' || response.error.type === 'InvalidSessionIdException';
    }
    return false;
  }

  private isInvalidToken(response: HttpErrorResponse): boolean {
    const headers: HttpHeaders = response.headers;
    if (response.status === 401 && headers.has('WWW-Authenticate')) {
      const header: string = headers.get('WWW-Authenticate');
      return header.indexOf('invalid_token') > -1;
    }
    return false;
  }

  private handleInvalidSessionId(request: HttpRequest<any>): void {
    if (request.url !== AuthenticationUrls.SIGN_OUT) {
      if (this.invalidSessionModal) {
        return;
      }
      const params = {
        title: 'session_expiration.title',
        message: this.translateService.instant('session_expiration.message')
      };
      this.invalidSessionModal = this.modalsService.alert(params);

      setTimeout(() => {
        this.invalidSessionModal.close();
      }, 5000);

      this.invalidSessionModal.afterClosed().subscribe(() => {
        this.signOutService.signOut();
      });
    }
  }

  private async addAuthenticationHeader(req: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
    const isIdpMode = this.authenticationConfigService.authenticationMode === AuthenticationMode.IDP;
    this.isPopout = this.popOutService.isPopOut();
    if (isIdpMode || (this.isPopout && (window as any).top.opener?.GPPFrontEnd()?.getToken() != null)) {
      req = this.addAuthenticationHeaderIDPMode(req);
    }
    req = this.addAuthenticationHeaderGPPMode(req);

    return next.handle(req)
    .pipe(
      filter((event: HttpEvent<any>) => event instanceof HttpResponse),
      catchError(response => {
        if (response instanceof HttpErrorResponse) {
          if (this.authenticationIdpService.isRefreshTokenRequest(req)) {
            this.showErrorNotification(this.errorDuration).then(() => {
              this.signOutService.signOut();
            });
            return;
          }

          if (this.isInvalidToken(response)) {
            return this.refreshAccessToken(req, next);
          }

          if (this.isInvalidSessionId(response)) {
            this.handleInvalidSessionId(req);
          }
        }
        return throwError(response);
      })
      ).toPromise();
  }

  private refreshAccessToken(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (!this.isTokenRefreshing) {
      this.isTokenRefreshing = true;
      this.refreshTokenSubject.next(null);

      return from(this.authenticationIdpService.refreshToken())
      .pipe(
        switchMap((token: any) => {
          this.isTokenRefreshing = false;
          this.store.dispatch(new UpdateSession(token));
          return next.handle(this.addAuthenticationHeaderIDPMode(request));
        }),
        catchError((err: any) => {
          this.showErrorNotification(this.errorDuration).then(() => {
            this.isTokenRefreshing = false;
          });
          return next.handle(request);
        }));

    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(token => {
          this.store.dispatch(new UpdateSession(token));
          return next.handle(this.addAuthenticationHeaderIDPMode(request));
        }));
    }
  }

  private addAuthenticationHeaderIDPMode(req: HttpRequest<any>): HttpRequest<any> {
    if (this.authenticationIdpService.isIDPRequest(req)) {
      return req;
    }

    if (this.isPopout) {
      this.accessToken = (window as any).top.opener?.GPPFrontEnd()?.getToken();
    } else {
      this.accessToken = this.authenticationService.accessToken;
    }

    if (this.accessToken != null) {
      return req.clone({ headers: req.headers.set('Authorization', this.accessToken) });
    }
    return req;
  }


  private addAuthenticationHeaderGPPMode(req: HttpRequest<any>): HttpRequest<any> {
    this.sessionId = this.authenticationService.sessionId;
    if (this.sessionId != null && !req.url.includes('token')) {
      return req.clone({ headers: req.headers.set('session-id', this.sessionId) });
    }
    return req;
  }

  private async showErrorNotification(duration: number) {
    this.notificationService.error('You were logged out from the system, contact your administrator if it happens again');
    setTimeout(() => {
      return (true);
    }, duration);
  }

}
