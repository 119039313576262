export enum PaymentAddress {
  'OFFICE' = 'PAYMENT_ADDRESS-OFFICE',
  'DEPARTMENT' = 'PAYMENT_ADDRESS-DEPARTMENT',
  'REC_STATUS' = 'PAYMENT_ADDRESS-REC_STATUS',
  'PROFILE_CHANGE_STATUS' = 'PAYMENT_ADDRESS-PROFILE_CHANGE_STATUS',
  'EFFECTIVE_DATE' = 'EFFECTIVE_DATE',

  'NCC_CODE' = 'PAYMENT_ADDRESS-NCC_CODE',
  'NCC_TYPE' = 'PAYMENT_ADDRESS-NCC_TYPE',
  'ACC_NO' = 'PAYMENT_ADDRESS-ACC_NO',
  'CURRENCY' = 'PAYMENT_ADDRESS-CURRENCY',
  'DEFAULT_ACC_NO' = 'PAYMENT_ADDRESS-DEFAULT_ACC_NO',
  'EXTERNAL_PARTY_ID' = 'PAYMENT_ADDRESS-EXTERNAL_PARTY_ID',
  'CUST_NAME' = 'PAYMENT_ADDRESS-CUST_NAME',
  'PMT_ADDR_TYPE' = 'PAYMENT_ADDRESS-PMT_ADDR_TYPE',
  'PAYMENT_ADDRESS' = 'PAYMENT_ADDRESS-PAYMENT_ADDRESS',

  'TIME_STAMP' = 'TIME_STAMP',
  'UID' = 'VIRTUAL-UID',
  'PROFILE_UPDATE_PK' = 'PROFILE_UPDATE_PK',
  'PU_TIME_STAMP' = 'PU_TIME_STAMP',
  'NOTE' = 'NOTE'
};
