import { Component } from '@angular/core';

@Component({
  selector: 'app-navigation-result-empty',
  templateUrl: './navigation-result-empty.component.html',
  styleUrls: ['./navigation-result-empty.component.scss']
})
export class NavigationResultEmptyComponent {

  readonly message = 'navigation_search.results.empty_message';
}
