import {Routes} from "@angular/router";
import { NavigationGuardService } from '../../../core/navigation/services/navigation-guard.service';
import {LandingPageComponent} from "../../../messages/components/landing-page/landing-page.component";
import { IsSingularProfileResolver } from '../resolvers/is-singular-profile.resolver';
import {MainViewComponent} from "../../../base-view/components/main-view/main-view.component";
import {TabTaskBarResolver} from "../../../resolvers/tab-task-bar.resolver";
import {TaskBarItemComponentResolver} from "../../../resolvers/task-bar-item-component.resolver";
import {MainTab} from "../../../core/navigation/models";
import {NavigationTreeResolver} from "../resolvers/navigation-tree.resolver";
import {ProfileGridConfigResolver} from "../resolvers/profile-grid-config.resolver";
import {IsNewUiProfileResolver} from "../resolvers/is-new-ui-profile.resolver";
import { RedirectProfilesGuard } from '../guards/redirect-profiles-guard.service';
import { RedirectTaskBarItemGuard } from '../guards/redirect-task-bar-item.guard';
import {ProfilesCenterViewComponent} from "../components/profiles-center-view/profiles-center-view.component";
import {LoadTypeResolver} from "../resolvers/load-type.resolver";
import {Entity} from "../../../shared/models/enums/entity.enum";
import {DeactivateProfileGuard} from "../guards/deactivate-profile.guard";

export const BusinessSetupRoutes: Routes = [
  {
    path: '',
    component: LandingPageComponent,
    data: {
      tab: MainTab.BUSINESS_SETUP
    },
    canActivate: [RedirectProfilesGuard],
  },
  {
    path: ':taskBarItem',
    component: MainViewComponent,
    data: {
      tab: MainTab.BUSINESS_SETUP,
      type: Entity.PROFILES
    },
    canActivate: [RedirectTaskBarItemGuard],
    runGuardsAndResolvers: 'always',
    resolve: {
      'taskBar': TabTaskBarResolver,
      'taskBarComponent': TaskBarItemComponentResolver,
      'navigationTree': NavigationTreeResolver,
    },
    children: [
      {
        path: ':profileId',
        data: {
          entityType: Entity.PROFILES,
        },
        component: ProfilesCenterViewComponent,
        canActivate: [NavigationGuardService],
        canDeactivate: [DeactivateProfileGuard],
        resolve: {
          'gridEntityConfig': ProfileGridConfigResolver,
          'isNewUIProfile': IsNewUiProfileResolver,
          'loadType': LoadTypeResolver,
          'isSingularProfile': IsSingularProfileResolver
        },
        runGuardsAndResolvers: 'paramsOrQueryParamsChange'
      }
    ]
  },
];
