export const Billing = {
	'PROFILE_CHANGE_STATUS': 'BILLING-PROFILE_CHANGE_STATUS',
	'REC_STATUS': 'BILLING-REC_STATUS',
	'BILLING_GROUPING': 'BILLING-BILLING_GROUPING',
	'BILLING_NAME': 'BILLING-BILLING_NAME',
	'FILE_DIRECTORY_LOCATION': 'BILLING-FILE_DIRECTORY_LOCATION',
	'FILE_NAME_CONVENTION': 'BILLING-FILE_NAME_CONVENTION',
	'INTERFACE_TIMING': 'BILLING-INTERFACE_TIMING',
	'INTERFACE_TYPE': 'BILLING-INTERFACE_TYPE',
	'TIME_STAMP': 'TIME_STAMP',
	'EFFECTIVE_DATE': 'EFFECTIVE_DATE',
	'UID': 'VIRTUAL-UID',
	'PROFILE_UPDATE_PK': 'PROFILE_UPDATE_PK',
	'PU_TIME_STAMP': 'PU_TIME_STAMP',
	'NOTE': 'NOTE'
};
