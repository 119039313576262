import { Action, State, Selector, StateContext } from '@ngxs/store';
import { SideNavigationStateModel } from './models/side-navigation-state.model';
import { SidebarMode } from './models/enums/sidebar-mode.enum';
import { SetSideNavigationMode } from './actions/set-side-navigation-mode.action';
import { Injectable } from '@angular/core';


@State<SideNavigationStateModel>({
  name: 'sideBarNavigationMode',
  defaults: {
    mode: SidebarMode.OPEN
  }
})
@Injectable()
export class SideNavigationState {

  @Selector()
  static getSideNavigationMode(state: SideNavigationStateModel): SidebarMode {
    return state.mode;
  }

  @Action(SetSideNavigationMode)
  setSideNavigationMode( ctx: StateContext<SideNavigationStateModel>, { payload }: SetSideNavigationMode) {
    ctx.patchState({
      mode: payload
    });
  }
}
