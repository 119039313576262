import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ModalsService } from 'projects/gpp/src/app/shared/fn-ui-modals/services/modals.service';
import { ToursService } from 'projects/gpp/src/app/tours/services/tours.service';
import { Rule, RuleMetadata, RuleStatuses } from '../../models';
import { RuleCommonService } from '../../services/rule-common.service';
import { RuleConditionsService } from '../../services/rule-conditions.service';

@Component({
  selector: 'app-rule-content',
  templateUrl: './rule-content.component.html',
  styleUrls: ['./rule-content.component.scss']
})
export class RuleContentComponent implements OnInit {

  @Input() metadata: RuleMetadata;
  @Input() rule: Rule;
  @Input() ruleStatuses: RuleStatuses;

  conditionsData = this.ruleConditionsService.conditions;

  isEditable = true;
  showConditions = false;

  constructor(private route: ActivatedRoute,
              public ruleCommonService: RuleCommonService,
              public ruleConditionsService: RuleConditionsService,
              private toursService: ToursService,
              private translateService: TranslateService,
              private modalsService: ModalsService) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.ruleCommonService.show.isToggleClicked = false;
      this.showConditions = true;
      if (!this.ruleStatuses.isCreate) {
        this.ruleCommonService.show.previewRule = true;
      }
    });
    this.checkIfReadOnly();

    this.ruleCommonService.show.previewRule = false;
    this.ruleCommonService.show.auditTrail = false;
    this.ruleCommonService.show.conditionsBank = true;
    this.ruleCommonService.show.isTileSelected = false;
    if (!this.ruleStatuses.isCreate) {
      if (this.ruleConditionsService.conditions.conditions?.length > 0) {
        if (this.toursService.isTourRunning('rulesScreen')) {
          this.ruleCommonService.show.previewRule = false;
        }
      }
     this.ruleCommonService.initConditions(this.conditionsData);
    }
  }

  closeAuditTrails(): void {
    this.ruleCommonService.show.auditTrail = false;
  }

  afterRuleDetailsToggle(): void {
    this.ruleCommonService.operandWithOpenedPopover?.insertFunctionPopover?.updatePopoverPosition();
  }

  private checkIfReadOnly(): void {
    this.ruleCommonService.isReadOnly = this.ruleStatuses.isReadOnly;
    if (this.ruleStatuses.isReadOnly) {
      let message = '';
      if ((this.ruleStatuses.isRuleForApproval && this.ruleStatuses.loadType !== '3') ||
        (!this.ruleStatuses.isRuleForApproval && this.ruleStatuses.loadType === '3' && this.ruleStatuses.isInitiator && !this.ruleStatuses.isEditable)) {
        message = this.translateService.instant('general.pending_approval_profile_locked_message', { profile: 'Rule' });
      } else if (this.ruleStatuses.loadType === '2' && this.ruleStatuses.isChangeStatusFU) {
        message = this.translateService.instant('general.future_profile_lock_message', { profile: 'Rule' });
      } else if (this.ruleStatuses.loadType === '2' && this.rule.LOCKED_BY_USER === 'system') {
        message = this.translateService.instant('general.profile_locked_message_onboarding', { profile: 'Rule Association' });
      } else {
        message = this.translateService.instant('general.profile_locked_message', {
          profile: 'Rule',
          user: this.rule.LOCKED_BY_USER
        });
      }
      this.ruleCommonService.modalVisible = true;
      this.modalsService.confirm({
        title: this.translateService.instant('general.profile_locked_title', { profile: 'Rule' }),
        message: message,
        okButton: 'general.ok',
        cancelButton: 'general.cancel'
      }).afterClosed().subscribe((reason) => this.responseByReason(reason));

    } else if ((this.route.snapshot.queryParams.loadType === '3' &&
      (!this.ruleStatuses.isInitiator || (this.ruleStatuses.isInitiator && !this.ruleStatuses.isEditable))) ||
      this.ruleStatuses.isOnHold || this.ruleStatuses.isDeleted || this.ruleStatuses.isStatusDeleted || this.ruleStatuses.isStatusHold) {
      this.isEditable = false;
      this.ruleCommonService.modalVisible = true;
    }
  }

  private responseByReason(reason: string): void {
    reason === 'ok' ? this.ruleCommonService.modalVisible = false : this.ruleCommonService.closeRule();
  }

}
