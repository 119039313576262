import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FnUiIconComponent } from './fn-ui-icon.component';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule
    ],
    declarations: [
        FnUiIconComponent
    ],
    exports: [
        FnUiIconComponent
    ]
})
export class FnUiIconModule { }
