import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { ExportFormService } from '../../services/export-form.service';
import { ExportOptions } from '../../models/enums/export-options.enum';
import { FileTypes } from '../../models/enums/file-types.enum';
import { KeyValue } from '../../../models/key-value.model';
import { FileTemplate } from '../../models/enums/file-template.enum';

@Component({
  selector: 'app-fn-ui-export-form',
  templateUrl: './fn-ui-export-form.component.html',
  styleUrls: ['./fn-ui-export-form.component.scss']
})
export class FnUiExportFormComponent implements OnInit {

  exportForm: FormGroup;
  fileTypes: { label: string, value: FileTypes }[];
  exportOptions: Array<KeyValue<string, ExportOptions>>;
  fileTemplate: FileTemplate;
  exportOptionsEnum = ExportOptions;

  @Input() disableSelectedRowsOption: boolean;
  @Input() topLimit= 5000;

  constructor(
    private exportFormService: ExportFormService,
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.initForm();
    this.initFileTypes();
    this.initOptions();
    this.initFileTemplate();
  }

  initForm() {
    this.exportForm = this.exportFormService.createForm();
  }

  initFileTypes() {
    this.fileTypes = Object.keys(FileTypes).map((key: string) => {
      return { label: key, value: FileTypes[key] };
    });
  }

  initOptions() {
    this.exportFormService.getExportOptions().then((options: Array<KeyValue<string, ExportOptions>>) => {
      this.exportOptions = options;
    });
  }

  initFileTemplate() {
    this.fileTemplate = FileTemplate.EXTERNAL_AUDIT;
  }

  onOptionChange() {
    if (this.optionControl.value !== ExportOptions.CUSTOM) {
      if (this.rangeControl) {
        this.exportForm.removeControl('range');
      }
    } else {
      this.exportForm.addControl('range', this.formBuilder.control('', this.exportFormService.rangeValidators));
    }
  }

  error(control: AbstractControl): string {
    if (!control.touched || !control.errors) {
      return null;
    }
    return control.errors[Object.keys(control.errors)[0]];
  }

  onRangeFocus() {
    this.rangeControl.markAsUntouched();
  }

  get optionControl(): FormControl {
    return <FormControl> this.exportForm.get('option');
  }

  get rangeControl(): FormControl {
    return <FormControl> this.exportForm.get('range');
  }

  get fileTypeControl(): FormControl {
    return <FormControl> this.exportForm.get('fileType');
  }

  get fileNameControl(): FormControl {
    return <FormControl> this.exportForm.get('fileName');
  }

}
