import { GridContainerConfig } from '../../../../grid/models/grid-container-config.model';
import { GridFooterConfig } from '../../../../grid/models/grid-footer-config.model';
import { GridHeaderConfig } from '../../../../grid/models/grid-header-config.model';
import { GroupActionsConfig } from '../../../../grid/models/group-actions-config.model';

export const MsgInnerGridHeaderConfig: GridHeaderConfig = {
  showLastUpdate: false,
  showScopes: false,
  showRefresh: false,
  showLeftMenu: false,
  showRightMenu: true,
  showFavorite: false,
  showType: false,
  showTitle: false,
  showAutoFeeder: false,
  showFilter: false,
  showAllActions: true,
  showCreate: false,
  disableCreate : true,
  processingScope: false
};

export const MsgInnerGridGroupActionsConfig: GroupActionsConfig = {
  showGroupActionIcon: true,
  showBeforeAfter: true,
  showAddNotes: false,
  showGroupActions: false,
  showFollowUpFlag:true,
};

export const MsgInnerGridFooterConfig: GridFooterConfig = {
  showResultsAndCounts : true,
  groupActions: MsgInnerGridGroupActionsConfig,
  showCallerDetails: false,
  showCreateFromTemplate: false,
  showResultsWarning: false
};


//  Messages and Internal Batches
export const MsgInnerGridConfig: GridContainerConfig = {
  gridHeaderConfig: MsgInnerGridHeaderConfig,
  gridFooterConfig: MsgInnerGridFooterConfig,
};

