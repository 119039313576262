import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { CacheService } from '../../core/services/cache/cache.service';

const GRID_PREFERENCES_KEY = 'GRID_PREF';
const GRID_COLUMNS_KEY = 'GRID_COLUMNS';
const MIGRATION_METADATA_KEY = 'MIGRATION_METADATA';
const COLUMNS_MESSAGE_URL = 'GRID_COLUMNS_do/messages/';
const COLUMNS_MESSAGE_RECENT_SEARCH_URL = 'GRID_COLUMNS_do/messages/_RECENT_SEARCH_null';

@Injectable({
  providedIn: 'root'
})
export class GridApiCacheService extends CacheService {

   static getCacheKey(gridApiKey: string, baseUrl: string, httpParams: HttpParams): string {
    let key = `${gridApiKey}_${baseUrl}`;
    if (httpParams) {
      key = key + `_${httpParams.get('queueType')}_${httpParams.get('queueId')}`;
    }
    return key;
  }

  static getCacheKeyGridPreferences(baseUrl: string, httpParams?: HttpParams): string {
    return GridApiCacheService.getCacheKey(GRID_PREFERENCES_KEY, baseUrl, httpParams );
  }

  static getCacheKeyGridColumns(baseUrl: string, httpParams?: HttpParams): string {
    return GridApiCacheService.getCacheKey(GRID_COLUMNS_KEY, baseUrl, httpParams );
  }

  static getCacheMessagesKeyGridColumns(baseUrl: string, httpParams?: HttpParams): Array<string> {
    return [
      COLUMNS_MESSAGE_URL,
      COLUMNS_MESSAGE_RECENT_SEARCH_URL
    ];
  }

  static getCacheKeyMigrationMetadata(profileId: string): string {
    return GridApiCacheService.getCacheKey(MIGRATION_METADATA_KEY, profileId, null );
  }

}
