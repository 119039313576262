<app-fn-ui-dialog class="conditions-not-allowed-modal">

  <ng-template #header>
    <div class="conditions-not-allowed-header">
      <span [translate]="params.title" class="title"></span>
      <app-fn-ui-close-icon mat-dialog-close></app-fn-ui-close-icon>
    </div>
    <mat-divider></mat-divider>
  </ng-template>

  <ng-template #body>
    <div class="conditions-not-allowed-body">
      <span [translate]="params.messageText"></span>
      <ul>
        <li *ngFor="let field of params.message">{{field}}</li>
      </ul>
      <span [translate]="params.footer"></span>
   </div>
  </ng-template>

  <ng-template #footer>
    <div class="conditions-not-allowed-footer">
      <app-fn-ui-button type="flat" color="primary" mat-dialog-close>
        <span [translate]="params.okButton"></span>
      </app-fn-ui-button>
    </div>
  </ng-template>

</app-fn-ui-dialog>
