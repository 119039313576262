import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { TaskBarLayoutComponent } from './task-bar-layout.component';
import { SpinnerModule } from '../ui-components/spinner/spinner.module';
import { ToursModule } from '../tours/tours.module';
import { FnUiIconModule } from '../shared/fn-ui-icons/fn-ui-icon/fn-ui-icon.module';
import { TaskBarModule } from '../core/navigation/task-bar/task-bar.module';
import { NavigationSearchModule } from '../core/navigation-search/navigation-search.module';
import { FnUiButtonModule } from '../shared/fn-ui-button/fn-ui-button.module';
import { MessagesModule } from '../messages/messages.module';
import { DynamicComponentLoaderModule } from '../ui-components/dynamic-component-loader/dynamic-component-loader.module';


@NgModule({
  declarations: [TaskBarLayoutComponent],
  exports: [
    TaskBarLayoutComponent
  ],
    imports: [
        CommonModule,
        SpinnerModule,
        ToursModule,
        FnUiIconModule,
        TaskBarModule,
        TranslateModule.forChild(),
        NavigationSearchModule,
        FnUiButtonModule,
        MessagesModule,
        DynamicComponentLoaderModule
    ]
})
export class TaskBarLayoutModule { }
