import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Injectable } from "@angular/core";
import { ProfilesNavigationService } from "../../../profiles/services/profiles-navigation.service";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class RedirectRulesGuard implements CanActivate {

  constructor(private router: Router, private profilesNavigationService: ProfilesNavigationService) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const tab = route.data.tab;
    const taskBarItem = this.profilesNavigationService.getFirstTaskBarItemIdByComponentId(tab);
    if (!taskBarItem) {
      this.router.navigate(['/home/page-not-found'], { replaceUrl: true });
      return false;
    }
    this.router.navigate(['home', tab, taskBarItem]);
    return true;
  }

}
