export const Reasons = {
  'DEPARTMENT': 'REASONS-DEPARTMENT',
  'EFFECTIVE_DATE': 'EFFECTIVE_DATE',
  'OFFICE': 'REASONS-OFFICE',
  'PROFILE_CHANGE_STATUS': 'REASONS-PROFILE_CHANGE_STATUS',
  'QUALIFIER': 'REASONS-QUALIFIER',
  'REASONCODE': 'REASONS-REASONCODE',
  'REASONDESCRIPTION': 'REASONS-REASONDESCRIPTION',
  'REASONTYPE': 'REASONS-REASONTYPE',
  'REC_STATUS': 'REASONS-REC_STATUS',
  'QUALIFIER_LABEL': 'REASON_TYPES-QUALIFIER_LABEL',
  'TIME_STAMP': 'TIME_STAMP',
  'UID': 'VIRTUAL-UID',
  'PROFILE_UPDATE_PK': 'PROFILE_UPDATE_PK',
  'PU_TIME_STAMP': 'PU_TIME_STAMP',
  'NOTE': 'NOTE'
};
