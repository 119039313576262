import { FgppButtonProps } from '../../core/utils/fgpp-button-props.utils';
import { ProfileButtons } from '../enums/profile-buttons.enum';
import { ButtonType } from '../../core/enums/button-type.enum';
import { ButtonCategory } from '../../core/enums/button-category.enum';
import { ProfilesFooterService } from '../services/profiles-footer.service';
import { ISdkProfileFooterReducer } from '../interfaces/profile-footer-reducer.interface';
import { IProfileFooter } from '../interfaces/profile-footer.interface';
import { ProfileRecordStatus } from '../enums/profile-record-status.enum';
import { IProfileLayoutSelectionMetadata } from '../interfaces/profile-layout-selection-metadata.interface';

export class StandardProfileReducer implements ISdkProfileFooterReducer {
  protected footerService = new ProfilesFooterService();
  protected buttonList: FgppButtonProps[] = [];

  //TODO: Cleanup required
  public reduce(buttonCategory: ButtonCategory, footer: IProfileFooter, inputs: IProfileLayoutSelectionMetadata): IProfileFooter {
    this.processStandardProfileButtons(buttonCategory, footer, inputs);

    return this.footerService.retain(buttonCategory, this.buttonList).getFooter();
  }

  protected processStandardProfileButtons(buttonCategory: ButtonCategory, footer: IProfileFooter, inputs: IProfileLayoutSelectionMetadata) {
    this.buttonList = [];
    const coerce =   (value: string | boolean): boolean => {
      if (typeof value === 'string') {
        return value.toLowerCase() === 'true';
      } else if (typeof value === 'boolean') {
        return value;
      }
      return false;
    };

    this.footerService.setFooter(footer);
    const mode = inputs.mode;
    const changeStatus = inputs.profileChangeStatus;
    const recStatus = inputs.recStatus;
    const loadType = inputs.loadType;
    const isWritable = coerce(inputs.isWritable);
    const isEditable = coerce(inputs.isEditable);
    const isInitiator = inputs.isInitiator;
    const isLocked = inputs.isLocked;
    const isSameApprover = inputs.isSameApprover;

    // console.log('Footer', inputs);

    const temp: { [k: string]: any } = {};
    let showApproveButton = false;
    temp.isInitiator = inputs.isInitiator;
    temp.isProfileForApproval = mode !== 'create' && ('PN' === changeStatus && (!isInitiator || (isInitiator && isSameApprover)));
    temp.isLockedByAnotherUser = mode !== 'create' && (isLocked);
    temp.isReadOnly = isLocked || (temp.isProfileForApproval && loadType !== 3) ||
      (!temp.isProfileForApproval && loadType === 3 && isInitiator && !isEditable) ||
      (loadType === 2 && 'FU' === changeStatus);
    temp.isWritable = mode === 'create' || isWritable;

    const SaveButton = new FgppButtonProps(ProfileButtons.SAVE, ButtonType.RAISED, 1);
    const ApproveButton = new FgppButtonProps(ProfileButtons.APPROVE, ButtonType.RAISED, 2);
    const CloseButton = new FgppButtonProps(ProfileButtons.CLOSE, ButtonType.FLAT, 3);

    const AuditButton = new FgppButtonProps(ProfileButtons.AUDIT, ButtonType.MENU, 0);
    const DeleteButton = new FgppButtonProps(ProfileButtons.DELETE, ButtonType.MENU, 1);
    const HoldButton = new FgppButtonProps(ProfileButtons.HOLD, ButtonType.MENU, 2);
    const RetractButton = new FgppButtonProps(ProfileButtons.RETRACT, ButtonType.MENU, 3);
    const ActivateButton = new FgppButtonProps(ProfileButtons.ACTIVATE, ButtonType.MENU, 4);
    const DeclineButton = new FgppButtonProps(ProfileButtons.DECLINE, ButtonType.MENU, 5);
    const UndoButton = new FgppButtonProps(ProfileButtons.UNDO, ButtonType.MENU, 6);
    const SaveAsButton = new FgppButtonProps(ProfileButtons.SAVEAS, ButtonType.MENU, 7);

    const statuses = {
      isCreate: mode === 'create',
      isEditMode: mode === 'edit',

      isProfileForApproval: temp.isProfileForApproval,
      isLockedByAnotherUser: temp.isLockedByAnotherUser,
      isReadOnly: temp.isReadOnly,
      loadType: loadType,
      isEditable: isEditable,
      isInitiator: isInitiator,
      isWritable: mode === 'create' || isWritable,

      isStatusHold: ProfileRecordStatus.HOLD === recStatus,
      isStatusDeleted: ProfileRecordStatus.DELETE === recStatus,
      isStatusNew: ProfileRecordStatus.NEW === recStatus,
      isStatusActive: ProfileRecordStatus.ACTIVE === recStatus,
      isChangeStatusPN: 'PN' === changeStatus,
      isChangeStatusNO: 'NO' === changeStatus,
      isChangeStatusFU: 'FU' === changeStatus,

      canUserApproveOwnProfile: isSameApprover,
      isOpenedFromApprovalScreen: loadType === 3,
      isOpenedFromInactiveScreen: loadType === 6
    };

    // console.log('statuses', statuses);

    if (((statuses.isWritable && !statuses.isReadOnly) &&
      (statuses.isOpenedFromApprovalScreen && statuses.isEditable || !statuses.isOpenedFromApprovalScreen) &&
      ((statuses.isStatusNew &&
        (statuses.isChangeStatusNO ||
          (statuses.isChangeStatusPN && (statuses.isInitiator || statuses.isCreate))
        )) ||
        (statuses.isStatusActive &&
          (statuses.isChangeStatusNO ||
            (statuses.isChangeStatusPN && (statuses.isInitiator || statuses.isCreate))
          )))) || statuses.isCreate) {
      this.buttonList.push(SaveButton);
      if (!statuses.isCreate) {
        this.buttonList.push(UndoButton);

      }
    }

    if (inputs.supportsSaveAs) {
      this.buttonList.push(SaveAsButton);
    }

    if ((statuses.isWritable && !statuses.isReadOnly) &&
      (false === statuses.isCreate) &&
      (statuses.isChangeStatusPN) &&
      (statuses.isInitiator === false || (statuses.isInitiator && statuses.canUserApproveOwnProfile &&
        (statuses.isOpenedFromApprovalScreen && statuses.isEditable)))) {
      if (this.buttonList.length > 0) {
        this.buttonList.push({ ...ApproveButton, buttonType: ButtonType.FLAT });
      } else {
        this.buttonList.push({ ...ApproveButton, buttonType: ButtonType.RAISED });
      }
      showApproveButton = true;
    }

    this.buttonList.push({ ...CloseButton, buttonType: ButtonType.FLAT } as FgppButtonProps);

    if ((statuses.isWritable && statuses.isEditMode && !statuses.isReadOnly) &&
      (statuses.isOpenedFromApprovalScreen && statuses.isEditable || !statuses.isOpenedFromApprovalScreen) &&
      (statuses.isStatusActive && statuses.isChangeStatusNO)) {
      this.buttonList.push(HoldButton);
    }

    if (statuses.isChangeStatusFU &&
      statuses.isWritable && statuses.isEditMode) {
      this.buttonList.push(RetractButton);
    }

    if ((statuses.isWritable && !statuses.isReadOnly) &&
      (statuses.isOpenedFromApprovalScreen && statuses.isEditable || !statuses.isOpenedFromApprovalScreen) &&
      (statuses.isStatusActive && statuses.isChangeStatusNO)) {
      this.buttonList.push(DeleteButton);
    }

    if ((statuses.isWritable && (statuses.isEditMode || statuses.isCreate)) &&
      (false === (statuses.isStatusHold && statuses.isChangeStatusFU)) &&
      (false === (statuses.isStatusDeleted && statuses.isChangeStatusFU)) &&
      (false === (statuses.isStatusNew && statuses.isChangeStatusNO)) &&
      (false === (statuses.isCreate))) {
      this.buttonList.push(AuditButton);
    }

    if ((statuses.isWritable && statuses.isEditMode && !statuses.isReadOnly) &&
      (statuses.isOpenedFromApprovalScreen && statuses.isEditable || !statuses.isOpenedFromApprovalScreen) &&
      ((statuses.isStatusDeleted && statuses.isChangeStatusNO) ||
        (statuses.isStatusHold && statuses.isChangeStatusNO))) {
      this.buttonList.push(ActivateButton);
    }

    if ((statuses.isWritable && !statuses.isReadOnly) && (showApproveButton) && !statuses.isInitiator) {
      this.buttonList.push(DeclineButton);
    }

    if ((statuses.isWritable && !statuses.isReadOnly) && (showApproveButton) && statuses.isInitiator) {
      this.buttonList.push(RetractButton);
    }
  }
}
