import {AfterViewChecked, ChangeDetectorRef, Component, inject, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CallerCallbackApiService } from '../services/caller-callback-api.service';
import { FnUiDialogService } from '../../../../shared/fn-ui-dialog/services/fn-ui-dialog.service';
import { CallerPinVerificationModalComponent } from '../caller-pin-verification-modal/caller-pin-verification-modal.component';
import { CountdownTimerComponent, CountdownTimerData } from '../../../../forms/components/countdown-timer/countdown-timer.component';

@Component({
  styleUrls: ['./callback-caller-authentication-modal.component.scss'],
  templateUrl: './callback-caller-authentication-modal.component.html'
})
export class CallbackCallerAuthenticationModalComponent implements OnInit, AfterViewChecked {
  form;
  office;
  callerOffice;
  callerDepartment;
  custCode;
  custCodeFilter;
  callerFilter;
  callerPhone1;
  callerPhone2;
  showPassword;
  authType;
  isSuperCaller;
  customerNameColumn = 'CUSTOMRS-CUST_NAME';
  callerIdColumn = 'CALLERS-CALLER_ID';

  constructor(private dialogRef: MatDialogRef<CallbackCallerAuthenticationModalComponent>,
              @Inject(MAT_DIALOG_DATA) private params: any, private callerCallbackApiService: CallerCallbackApiService,
               private dialogService: FnUiDialogService, private changeDetection: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.office = this.params.office;
    this.custCode = this.params.custId;
    this.isSuperCaller = this.params.superCaller;
    this.initFilters();
    const custName = this.isSuperCaller ? '' : this.params.custName;
    this.form = new FormGroup({
      customerName: new FormControl({ value: custName || '', disabled: this.params.companyDisabled }),
      callerId: new FormControl({ value: this.params.callerId || '', disabled: (this.params.callerId) }),
      password: new FormControl({ value: '', disabled: !(this.params.callerId && this.params.custName) })
    });
    this.callerPhone1 = this.params.phone1 ? this.params.phone1 : '';
    this.callerPhone2 = this.params.phone2 ? this.params.phone2 : '';
    this.showPassword = this.params.authType && this.params.authType !== 'A';
    this.isShowPasswordVisible();
  }
  private isShowPasswordVisible() {
    if (this.showPassword) {
      this.form.get('password').enable();
      this.form.get('password').setValidators([Validators.required]);
      this.form.get('password').updateValueAndValidity();
    } else {
      this.form.get('password').setValidators([]);
      this.form.get('password').updateValueAndValidity();
    }
  }

  /**
   * Creates the filters for caller and customer drilldowns
   */
  private initFilters() {
    this.custCodeFilter = {
      searchFilter: {
        'CUSTOMRS.REC_STATUS': 'AC',
        'CUSTOMRS.CUST_TYPE': ['CO', 'CU'],
        'CUSTOMRS.OFFICE': this.office
      }
    };

    this.callerFilter = {
      searchFilter: {
        'CALLERS.REC_STATUS': 'AC',
        'CALLERS.OFFICE': this.office,
      }
    };
  }

  /**
   * Callback function for selecting company drilldown
   * @param {Object} selectedRows
   */
  onCompanySelected(selectedRows) {
    const selected = selectedRows[0];
    this.customerNameColumn = selected['CUSTOMRS-CUST_NAME'] === '' ? 'CUSTOMRS-CUST_CODE' : 'CUSTOMRS-CUST_NAME';
    const value = selected[this.customerNameColumn];
    this.form.get('customerName').setValue(value);
    this.form.get('callerId').setValue('');
    this.form.get('callerId').enable();
    this.form.get('password').setValue('');
    this.form.get('password').disable();
    this.custCode = selected['CUSTOMRS-CUST_CODE'];
    this.callerFilter.searchFilter['CALLERS.CUST_CODE'] = this.custCode;
    this.callerPhone1 = '';
    this.callerPhone2 = '';
    this.showPassword = false;
  }

  /**
   * Callback function for selecting caller drilldown
   * @param {Object} selectedRows
   */
  onCallerSelected(selectedRows) {
    const selected = selectedRows[0];
    this.form.get('callerId').setValue(selected[this.callerIdColumn]);
    this.form.get('password').setValue('');
   // this.form.get('password').enable();
    this.callerPhone1 = selected['CALLERS-PHONE_NO_1'];
    this.callerPhone2 = selected['CALLERS-PHONE_NO_2'];
    this.authType = selected['CALLERS-AUTH_TYPE'];
    this.showPassword = (selected['CALLERS-AUTH_TYPE'] !== 'A');
    this.custCode = selected['CALLERS-CUST_CODE'];
    this.isShowPasswordVisible();

    if (!selected['CALLERS-CUST_CODE']) {
      this.form.get('customerName').setValue('');
      this.form.get('customerName').disable();
    } else {
      this.form.get('customerName').setValue(selected['CALLERS-CUST_CODE']);
      this.form.get('customerName').enable();
    }
    this.callerOffice = selected['CALLERS-OFFICE'];
    this.callerDepartment = selected['CALLERS-DEPARTMENT'];
  }

  /**
   * Validates the caller credentials
   */
  verifyCaller() {
    const custCode = this.isSuperCaller ? '' : this.custCode?.split('-').shift();
    const callerCredentials = {
      callerId: this.form.get('callerId').value,
      custCode: custCode,
      callerPasw: this.form.get('password').value,
      authType: this.authType ? this.authType : this.params.authType
    };
    const modal = this.showCallerCheckModal();
    const requestTime = new Date().toISOString();
    this.callerCallbackApiService.authenticateCaller(callerCredentials,requestTime).subscribe(
      (response : any) => {
        const res = response.body;
        res.callerId = this.form.get('callerId').value;
        res.office = this.callerOffice;
        res.department = this.callerDepartment;
        if (res.requireMFA === true) {
          const config = {
            data:  {
              callerId : this.form.get('callerId').value,
              custCode : custCode
            }
          };
          const self = this;
          const modalRef = this.dialogService.open(CallerPinVerificationModalComponent, config);
          modalRef.afterClosed().toPromise().then(
            function( pinVerificationResponse) {
                if (!pinVerificationResponse || pinVerificationResponse === '') {
                  return;
                }
                if ( pinVerificationResponse.responseMsg === 'Success') {
                  self.dialogRef.close(res);
                }
              }
            );
        } else {
        this.dialogRef.close(res);
        }
        modal.close();
      },
      (res) => {
        console.log(res);
        if (res.error.responseMsg === 'callerNotAvailable') {
          this.form.get('password').setErrors({ 'callerNotAvailable': true });
        } else if (res.error.responseMsg === 'invalidDigitalSignature') {
          this.form.get('password').setErrors({ 'invalidDigitalSignature': true });
        } else {
          this.form.get('password').setErrors({ 'loginFailed': true });
        }
        this.form.get('password').markAsTouched(); //updates view on the first time in order to show error on Enter click
        modal.close();
      });
  }

  showCallerCheckModal() {
    const data: CountdownTimerData = {
      modalTitle: 'forms.caller_modal',
      title: 'forms.caller_modal_title',
      subTitle: '',
      abortButton: '',
      saveAndClose: 'forms.modal_save_close_button',
      button: 'save'
    };
    return this.dialogService.open(CountdownTimerComponent, {... { data }, ... {width: '350px'}});
  }

  onKeyDown($event: KeyboardEvent) {
    if ($event.code === 'Backspace' || $event.code === 'Delete') {
      this.custCode = '';
      this.form.get('customerName').setValue('');
      if (this.callerFilter.searchFilter['CALLERS.CUST_CODE']) {
        delete this.callerFilter.searchFilter['CALLERS.CUST_CODE'];
      }
      return true;
    }
    return false;
  }

  ngAfterViewChecked(): void {
    this.changeDetection.detectChanges();
  }
}
