import {Injectable} from '@angular/core';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {FxRateModalComponent} from '../fx-rate-modal/fx-rate-modal.component';
import {FxErrorResponse, FxRateModal, GetRateRequestBody} from '../models/fx-rate.model';
import {FxRateRetryModalComponent} from '../fx-rate-retry-modal/fx-rate-retry-modal.component';

@Injectable()
export class FxRateModalService {

  constructor(public dialog: MatDialog) { }

  openModal(data: { fxRate: FxRateModal, rateRequestBody: GetRateRequestBody, errorDetails?: FxErrorResponse }): MatDialogRef<FxRateModalComponent> {
    const config: MatDialogConfig = {
      data: data,
      panelClass: 'fx-rate-modal-container'
    };
    const modalRef = this.dialog.open(FxRateModalComponent, config);
    return modalRef;
  }

  openRetryModal(): MatDialogRef<FxRateRetryModalComponent> {
    const config: MatDialogConfig = {
      panelClass: 'fx-rate-error-modal-container'
    };
    const modalRef = this.dialog.open(FxRateRetryModalComponent, config);
    return modalRef;
  }

}
