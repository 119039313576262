import { InjectionToken } from '@angular/core';
import { NavigationTabType } from '..';

export const INSIGHT_TOKEN = new InjectionToken(NavigationTabType.INSIGHT);
export const LIQUIDITY_TOKEN = new InjectionToken(NavigationTabType.LIQUIDITY);
export const MESSAGES_CENTER_TOKEN = new InjectionToken(NavigationTabType.MESSAGES_CENTER);
export const PROFILES_TOKEN = new InjectionToken(NavigationTabType.PROFILES);
export const RULES_TOKEN = new InjectionToken(NavigationTabType.RULES);
export const ARCHIVE_TOKEN = new InjectionToken(NavigationTabType.ARCHIVE);

export const TAB_HANDLER_TOKENS = {
    [NavigationTabType.INSIGHT]: INSIGHT_TOKEN,
    [NavigationTabType.LIQUIDITY]: LIQUIDITY_TOKEN,
    [NavigationTabType.MESSAGES_CENTER]: MESSAGES_CENTER_TOKEN,
    [NavigationTabType.PROFILES]: PROFILES_TOKEN,
    [NavigationTabType.RULES]: RULES_TOKEN,
    [NavigationTabType.ARCHIVE]: ARCHIVE_TOKEN
};
