import { Injectable } from '@angular/core';
import { MultiTabsAction } from '../models/multi-tabs-action.model';
import { IMultiTabsActionsService } from './interfaces/multi-tabs-actions-service.interface';

export const MULTI_TABS_ACTION = 'multi-tabs-action';

@Injectable()
export class MultiTabsActionsService implements IMultiTabsActionsService {

  setAction(action: MultiTabsAction): void {
    localStorage.setItem(MULTI_TABS_ACTION, JSON.stringify(action));
  }

  resetAction(): void {
    localStorage.removeItem(MULTI_TABS_ACTION);
  }

}
