import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanDeactivate,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import {Observable} from 'rxjs';
import {ProfileMode} from "../../../business-framework/profiles/enums/profile-mode.enum";
import {map} from "rxjs/operators";
import {ProfilesMode} from '../models/enums/profiles-mode.enum';
import {BFEvents} from "@fgpp-ui/components";
import {AbstractProfileFormComponent} from "../../../business-framework/profiles/abstract/abstract-profile/abstract-profile-form.component";
import DialogEvent = BFEvents.DialogEvent;

@Injectable({
  providedIn: 'root'
})
export class DeactivateProfileGuard implements CanDeactivate<AbstractProfileFormComponent> {

  constructor() {
  }

  canDeactivate(component: AbstractProfileFormComponent, currentRoute: ActivatedRouteSnapshot,
                currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (component['profilesMode'] === ProfilesMode.PROFILE_FORM_VIEW) {
      let profileComponentInstance = component['singleProfile']?.instance.componentRef.instance;
      if (currentState.url.indexOf('mode') > -1 && nextState.url.indexOf('mode') === -1 && !profileComponentInstance.exitedState && !profileComponentInstance.closedByGuard) {
        if (profileComponentInstance.checkDirty()) {
          return profileComponentInstance.openSaveChangesModal().pipe(map((event: DialogEvent) => {
              const result = event ? event.value.payload : {};
              if (!result.button || result.button === 'ok') {
                profileComponentInstance.closedByGuard = true;
                const payload = {
                  target: 'CLOSE',
                  type: 'button-event',
                  name: 'buttonClick',
                  value: {action: '', aliasFor: 'CLOSE'}
                } as BFEvents.ButtonEvent;
                profileComponentInstance.nextState = nextState;
                profileComponentInstance.mode === ProfileMode.EDIT ? profileComponentInstance.doServerAction(payload, null, true) : profileComponentInstance.navigateToNextState(nextState);
                return true;
              } else {
                return false;
              }
            }));
        } else {
          if (profileComponentInstance.mode === ProfileMode.EDIT) {
              const payload = {
                target: 'CLOSE',
                type: 'button-event',
                name: 'buttonClick',
                value: {action: '', aliasFor: 'CLOSE'}
              } as BFEvents.ButtonEvent;
              profileComponentInstance.doServerAction(payload, null, false);
            profileComponentInstance.closedByGuard = true;
          }
          return true;
        }
      }
    }
    return true;
  }
}
