import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-fn-ui-favorite-icon',
  templateUrl: './fn-ui-favorite-icon.component.html',
  styleUrls: ['./fn-ui-favorite-icon.component.scss']
})
export class FnUiFavoriteIconComponent {

  @Input() get isFavorite(): boolean {
    return this._isFavorite;
  }

  set isFavorite(value: boolean) {
    this._isFavorite = value;
    this.setTitle();
  }

  @Input() addTitle: string;
  @Input() removeTitle: string;

  title: string;

  private _isFavorite: boolean;

  constructor() {
    this.addTitle = 'main_window.add_to_favorites';
    this.removeTitle = 'main_window.remove_from_favorites';
  }

  setTitle() {
    this.title = this.isFavorite ? this.removeTitle : this.addTitle;
  }

}
