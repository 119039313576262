import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { Router } from '@angular/router';
import { UserSettingsService } from '../../core/user-settings/services/user-settings.service';
import { InsightNavigationService } from '../services/insight-navigation.service';

@Injectable()
export class InsightTaskbarGuard implements CanActivate {

  private prefix = '/home/insight/';

  constructor(private router: Router,
              private insightNavigationService: InsightNavigationService,
              private userSettingsService: UserSettingsService) { }

  canActivate(): UrlTree {
    const redirect = this.chooseDefaultState();
    return this.router.createUrlTree([redirect]);
  }

  chooseDefaultState(): string {
    let defaultState = this.prefix + this.insightNavigationService.getFirstTaskBar();

    const landingPage = this.userSettingsService.getPreferences().landingPage;
    if (landingPage !== 'system_navigation.MAINTAB_INSIGHT') {
      return defaultState;
    }

    const van = this.userSettingsService.getPreferences().navigationBar;
    if (van && van.trim() !== '') {
      const arr = van.split('.');
      const item = arr[arr.length - 1].replace('_', '-').toLowerCase();
      defaultState = this.prefix + item;
    }

    return defaultState;
  }
}
