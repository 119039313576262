import { Injectable } from '@angular/core';
import { MessagesGridContainerConfig } from '../models/consts/messages-grid-config.const';
import { Entity } from '../../shared/models/enums/entity.enum';
import { ProfilesGridContainerConfig } from '../../profiles/models/consts/profiles-grid-config.const';
import { ProfilesInnerGridContainerConfig } from '../../profiles/models/consts/profiles-inner-grid-config.const';
import { MassPayGridContainerConfig } from '../../mass-pay/entity/models/consts/mass-pay-grid-config.const';
import { MpMsgInnerGridWConfig } from '../../mass-pay/entity/models/consts/mass-pay-inner-grid-config.const';
import { MsgInnerGridConfig } from '../../mass-pay/entity/models/consts/messages-inner-grid-config.const';
import { InnerGridTabs } from '../../mass-pay/entity/models/enums/inner-grid-tabs.enum';

@Injectable({
  providedIn: 'root'
})
export class GridEntityConfigService {

  constructor() { }

  getProfilesGridConfig() {
    return ProfilesGridContainerConfig;
  }

  getProfilesInnerGridConfig() {
    return ProfilesInnerGridContainerConfig;
  }

  getMessagesConfig(entity: Entity) {
    switch (entity) {
      case Entity.MESSAGES:
        return MessagesGridContainerConfig;
      case Entity.FILES:
      case Entity.BULKS:
      case Entity.BATCHES:
        return MassPayGridContainerConfig;
      default:
    }
  }

  getMessagesInnerGridConfig(tab: InnerGridTabs) {
    switch (tab) {
      case InnerGridTabs.MESSAGES:
      case InnerGridTabs.INTERNAL_BATCHES:
        return MsgInnerGridConfig;
      case InnerGridTabs.BULKS:
      case InnerGridTabs.BATCHES:
      case InnerGridTabs.LINKED_FILES:
        return MpMsgInnerGridWConfig;
      default:
    }
  }
}
