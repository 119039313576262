import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { MessagesQueueType } from '../../../messages/models/enums/messages-queue-type.enum';
import { Entity } from '../../../shared/models/enums/entity.enum';
import { MessagesCenterQueuesAlias } from '../../models/consts/messages-center-queues-alias.const';
import { RecentSearchesTree } from '../models/recent-searches-tree.model';
import { RecentSearch } from '../models/recent-search.model';
import { IRecentSearchesTreeBuilderService } from './interfaces/recent-searches-tree-builder-service.interface';

@Injectable()
export class RecentSearchesTreeBuilderService implements IRecentSearchesTreeBuilderService {

  constructor(private datePipe: DatePipe) { }

  buildTree(recentSearches: Array<RecentSearch>, entity: Entity): RecentSearchesTree {
    return {
      id: `${MessagesQueueType.RECENT_SEARCH.toLowerCase()}_${entity}`,
      alias: MessagesCenterQueuesAlias[entity],
      type: entity,
      recentSearches: this.buildTreeNodes(recentSearches, entity),
      isNode: true
    };
  }

  buildTreeNodes(recentSearches: Array<RecentSearch>, entity: Entity): Array<RecentSearchesTree> {
    const dates = {};
    const nodes = [];

    recentSearches = this.sortRecentSearches(recentSearches);

    recentSearches.forEach((recentSearch: RecentSearch) => {
      const date = this.datePipe.transform(recentSearch.searchDate);
      if (!dates[date]) {
        dates[date] = {
          id: date,
          alias: date,
          type: entity,
          isNode: true,
          recentSearches: []
        };
        nodes.push(dates[date]);
      }
      dates[date].recentSearches.push(recentSearch);
    });

    return nodes;
  }

  private sortRecentSearches(recentSearches: Array<RecentSearch>): Array<RecentSearch> {
    return [...recentSearches].sort((recentSearch1: RecentSearch, recentSearch2: RecentSearch) => {
      return recentSearch2.searchDate.getTime() - recentSearch1.searchDate.getTime();
    });
  }

}
