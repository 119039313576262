<div class="read-only-title" *ngIf="permissions === FileAttachmentPermissionMode.READ">({{texts.readOnlyMode}})</div>
<div class="container">
    <div class="form-elements">
        <div class="file-container">
            <lib-stepper [step]="stepFileUpload" >
                <lib-file-upload
                    #fileUpload
                    [uploaderInput]="uploader"
                    [disabled]="permissions === FileAttachmentPermissionMode.READ"
                    [texts] = "texts"
                    (fileData)="setFileToUpload($event)">
                </lib-file-upload>
            </lib-stepper>
            <span [ngClass]="{'file-size-error': fileError === FileAttachmentError.FILESIZE}" class="file-error">{{errorText}}</span>
        </div>
        <div class="space"></div>

        <lib-stepper [step]="stepDescription" [ngClass]="{disabled: stepDescription.status === StepStaus.DISABLE}">
            <textarea azure-id="description-file-text"  class="description-text"  [disabled]="stepDescription.status === StepStaus.DISABLE" #description
                type="text" maxlength="30" cols="30" rows="10" placeholder="{{texts.descriptionPlaceholder}}"
                (input)="setDescriptionComplite($event.target.value)"
                ></textarea>
        </lib-stepper>

    </div>

    <div class="button-container">
        <button azure-id="upload-attachment" mat-flat-button color="primary" class="btn-upload-file"
            [disabled]="stepFileUpload.status !== StepStaus.COMPLETE || permissions === FileAttachmentPermissionMode.READ"
            [ngClass]="{'upload-disabled': stepFileUpload.status !== StepStaus.COMPLETE}"
            (click)="uploadFile()">{{texts.uploadButton}}</button>
    </div>
</div>
