import { Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { FloatLabelType, MatFormFieldAppearance } from '@angular/material/form-field';

@Component({
  selector: 'app-fn-ui-autocomplete',
  templateUrl: './fn-ui-autocomplete.component.html',
  styleUrls: ['./fn-ui-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FnUiAutocompleteComponent),
      multi: true
    }
  ]
})
export class FnUiAutocompleteComponent<TOption, TValue = TOption> implements ControlValueAccessor {

  get ngModel(): TValue {
    return this._value;
  }

  set ngModel(value: TValue) {
    if (value !== this._value) {
      const option = this.getOptionByValue(value) || value as any;
      const text = this.getDisplay(option);
      this.filterOptions(text);
      this.autoSelectFirstOption();
      this.lastSelectedOption = option;
      this.selectedOption = option;
      this._value = value;
      this.onChange(value);
    }
  }
  private _value: TValue;

  @Input()
  get autoSelect() {
    return this._autoSelect;
  }
  set autoSelect(value: boolean) {
    this._autoSelect = value;
    this.autoSelectFirstOption();
  };
  private _autoSelect: boolean;

  // Optional - you can set filterFunction instead of set options
  @Input()
  get options(): Array<TOption> {
    return this._options;
  }
  set options(value: Array<TOption>) {
    this._options = value;
    const text = this.getDisplay(this.selectedOption);
    this.filterOptions(text);
    this.autoSelectFirstOption();
  }
  private _options: Array<TOption>;

  @Input() displayProperty = 'alias';
  @Input() valueProperty: string;

  @Input() displayWithOverride: (text: string, option: TOption | string, displayProperty: string) => string;
  @Input() filterFunction: (text: string) => Array<TOption> = this.defaultFilterFuction.bind(this);
  @Input() trackByFunction: (index: number, option: TOption) => any;

  @Input() label: string;
  @Input() placeholder: string;
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Input() disableFreeText: boolean;
  @Input() appearance: MatFormFieldAppearance;
  @Input() floatLabel: FloatLabelType = 'always';

  @Output() focused: EventEmitter<FocusEvent> = new EventEmitter();
  @Output() blured: EventEmitter<boolean> = new EventEmitter();
  @Output() textChanged: EventEmitter<string> = new EventEmitter();
  @Output() selectionChanged: EventEmitter<TValue> = new EventEmitter();

  @ViewChild('autoCompleteInput') autoCompleteInput: ElementRef;

  filteredOptions: Array<TOption> = [];
  selectedOption: TOption | string;
  lastSelectedOption: TOption;

  private _autoSelected: boolean;

  focusElement(): void {
    this.autoCompleteInput.nativeElement.focus();
  }

  selectFirstOption(): void {
    if (this.filteredOptions?.length) {
      setTimeout(() => this.ngModel = this.getValue(this.filteredOptions[0]));
    }
  }

  autoSelectFirstOption(): void {
    if (this.autoSelect && !this._autoSelected && this.filteredOptions?.length) {
      this.selectFirstOption();
      this._autoSelected = true;
    }
  }

  restoreLastValue(): void {
    this.selectedOption = this.lastSelectedOption;
  }

  getOptionByValue(value: TValue): TOption {
    return this.filteredOptions.find((option: TOption) => this.getValue(option) === value);
  }

  getOptionByText(text: string): TOption | string {
    return this.filteredOptions.find((option: TOption) => this.getDisplay(option) === text);
  }

  getValue(option: TOption): any {
    if (typeof option !== 'object') {
      return option;
    }
    if (this.valueProperty) {
      return option[this.valueProperty];
    }
    return option;
  }

  getDisplay(option: TOption | string): string {
    if (option) {
      const text = typeof option === 'string' ? option : option[this.displayProperty];
      return text || '';
    }
    return '';
  };

  displayWith = (option: TOption | string): string => {
    let text = this.getDisplay(option);
    if (this.displayWithOverride) {
      text = this.displayWithOverride(text, option, this.displayProperty);
      return text || '';
    }
    return text;
  };

  onFocus($event: FocusEvent): void {
    this.focused.emit($event);
  }

  onBlur($event: FocusEvent): void {
    this.blured.emit(this.filteredOptions.length > 0);
  }

  onModelChange($event: TOption | string): void {
    const text = typeof $event === 'string' ? $event : this.getDisplay($event);
    this.filterOptions(text);
    this.textChanged.emit(text);
  }

  onValueChange($event: Event): void {
    const text = ($event.target as HTMLInputElement).value;
    if (text && this.disableFreeText) {
      const isValueExist = !!this.getOptionByText(text);
      if (!isValueExist) {
        this.restoreLastValue();
      }
    }
  }

  onOptionSelection($event: MatAutocompleteSelectedEvent): void {
    const option = $event.option.value;
    this.ngModel = this.getValue(option);
    this.selectionChanged.emit(this.ngModel);
  }

  private filterOptions(text: string): void {
    this.filteredOptions = this.filterFunction(text);
  }

  private defaultFilterFuction(query: string): Array<TOption> {
    if (query) {
      return this.options.filter((option: TOption) => this.getDisplay(option).toLowerCase().startsWith(query.toLowerCase()));
    }
    return this.options;
  };

  onChange = (_) => { };
  onTouched = () => { };

  writeValue(value: any): void {
    this.ngModel = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

}
