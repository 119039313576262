<div class="policy-rows">
  <div class="policy-columns" *ngIf="policyDefinition.upperCase">
    <div class="policy-param"><span class="tick-mark" *ngIf="passwordUpperCase"></span><span *ngIf="!passwordUpperCase" class="mdi mdi-circle"></span>
      {{uppercaseCharClause}}</div>
  </div>
  <div class="policy-columns" *ngIf="policyDefinition.specialChar">
    <div class="policy-param" ><span class="tick-mark" *ngIf="passwordSpecialChars"></span><span *ngIf="!passwordSpecialChars" class="mdi mdi-circle"></span>
      {{specialCharClause}}</div>
  </div>
  <div class="policy-columns" *ngIf="policyDefinition.lowerCase">
    <div class="policy-param" ><span class="tick-mark" *ngIf="passwordLowerCase"></span><span *ngIf="!passwordLowerCase" class="mdi mdi-circle"></span>
      {{lowercaseCharClause}}</div>
  </div>
  <div class="policy-columns" *ngIf="policyDefinition.digits">
    <div class="policy-param"><span class="tick-mark" *ngIf="passwordDigits"></span><span *ngIf="!passwordDigits" class="mdi mdi-circle"></span>
      {{digitClause}}</div>
  </div>
</div>
<div class="policy-single-row" *ngIf="policyDefinition.minLength && policyDefinition.maxLength">
<div class="policy-single-column">
  <div class="policy-param"><span class="tick-mark" *ngIf="passwordLength"></span><span *ngIf="!passwordLength" class="mdi mdi-circle"></span>
    {{lengthClause}}</div>
</div>
</div>
