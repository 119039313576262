import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { GridSearchResponse } from '../../grid/models/grid-search-response.model';
import { GridApiService } from './../../grid/services/grid-api.service';
import { GridApiRoutes } from '../../grid/models/enums/grid-api.routes';
import { ProfilesSearchRequest } from '../models/profiles-search-request.type';

@Injectable()
export class ProfilesApiService extends GridApiService {

  getInnerGridData(searchRequest: ProfilesSearchRequest, params, tab, messageType): Observable<any[]> {
    const rows = this.http.post<GridSearchResponse>(`${GridApiRoutes.do}/${messageType}/${params}/${tab}/${GridApiRoutes.search}`, searchRequest);
    const metadata = this.http.get(`${GridApiRoutes.do}/${messageType}/${params}/${tab}/${GridApiRoutes.metadata}`);
    return forkJoin([rows, metadata]);
  }

  getUserPermission(profileId: string, office: string, department: string, uid: string): Promise<string> {
    const url = `${GridApiRoutes.do}/PROFILE/${profileId}/${office}/${department}/attachmentsButton`;
    return this.http.get<string>(url, { params: { uId: uid } }).toPromise();
  }

}
