import { Component, OnInit } from '@angular/core';
import { AbstractProfileFormComponent } from '../../abstract/abstract-profile/abstract-profile-form.component';
import { Company } from '../../models/company.model';
import { ProfileFormTemplateProviders } from '../../../core/templates/profile-form.template.providers';
import { BFOnValueChange } from '../../../core/interfaces/sdk-profile-hooks.interface';
import { IValueChange } from '../../../core/interfaces/form-value-change.interface';

@Component({
  selector: 'app-company',
  templateUrl: '../../../core/templates/profile-form.template.html',
  styleUrls: ['../../../core/templates/profile-form.template.scss'],
  providers: ProfileFormTemplateProviders
})
export class CompanyComponent extends AbstractProfileFormComponent implements OnInit, BFOnValueChange {

  fgppValueChanges(change: IValueChange): void {
    if (change.control === Company.CUST_CODE) {
      this.onCustCodeChange();
    }
  }

  private onCustCodeChange(): void {
    const custCodeData = this.getDrilldownData(Company.CUST_CODE);
    if (custCodeData) {
      this.formMgr.get(Company.CUST_NAME).setValue(custCodeData[Company.CUST_NAME]);
    }
  }

}
