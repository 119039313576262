import { Injectable } from '@angular/core';
import { MessagesQueueType } from '../enums/messages-queue-type.enum';

export const QUEUE_TYPES = {
  [MessagesQueueType.SYSTEM_QUEUE]: 'system-queues',
  [MessagesQueueType.CUSTOM_QUEUE]: 'custom-queues',
  [MessagesQueueType.FAVORITE]: 'favorites',
  [MessagesQueueType.RECENT_SEARCH]: 'recent-searches',
  [MessagesQueueType.TEMPLATES]: 'templates',
  [MessagesQueueType.CALLBACK]: 'callback'
};

@Injectable()
export class MessagesQueues {

  public get default(): string {
    return QUEUE_TYPES[MessagesQueueType.FAVORITE];
  }

  public types(type: string): string {
    return QUEUE_TYPES[type];
  }

  public getMessagesQueueType(taskBarItem: string): MessagesQueueType {
    return <MessagesQueueType>Object.keys(QUEUE_TYPES)[Object.values(QUEUE_TYPES).indexOf(taskBarItem)];
  }
}
