import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BaseViewComponent } from './components/base-view/base-view.component';
import { CoreModule } from '../core/core.module';
import { AzureInsightsModule } from '../azure-insight/azure-insights.module';
import { TaskBarLayoutModule } from '../task-bar-layout/task-bar-layout.module';
import { MainViewComponent } from './components/main-view/main-view.component';
import { SpinnerModule } from '../ui-components/spinner/spinner.module';
import { DeactivateGuard } from '../messages-center/routing/guards/deactivate-guard';
import { TaskBarItemExitGuard } from '../messages-center/routing/guards/task-bar-item-exit.guard';
import { RedirectSearchGuard } from '../messages-center/routing/guards/redirect-search.guard';
import { ProfilesCenterViewComponent } from '../profiles/routing/components/profiles-center-view/profiles-center-view.component';
import { ProfilesModule } from '../profiles/profiles.module';
import { FaFormsModule } from '../forms/fa-forms.module';
import { RulesModule } from '../rules/rules.module';
import { RulesMainViewComponent } from './rules/rules-main-view/rules-main-view.component';



@NgModule({
  declarations: [
    BaseViewComponent,
    MainViewComponent,
    ProfilesCenterViewComponent,
    RulesMainViewComponent,
  ],
  exports: [
    BaseViewComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    CoreModule,
    AzureInsightsModule,
    TaskBarLayoutModule,
    SpinnerModule,
    ProfilesModule,
    FaFormsModule,
    RulesModule,
  ],
  providers: [
    DeactivateGuard,
  ]
})
export class BaseViewModule { }
