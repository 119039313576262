<div matTooltip="{{iconConfig.tooltip}}">
  <button class="lib-icon-svg" mat-icon-button [ngClass]="iconConfig.mainClass ? iconConfig.mainClass: ''"
          [disabled]="iconConfig.isDisabled" (click)="onClick()">
    <svg-icon
      src="{{iconConfig.iconTemplateRef}}"
      [class]="iconConfig.isDisabled ? getClass() : ''"
      [svgStyle]="iconConfig.style">
    </svg-icon>
  </button>
</div>

