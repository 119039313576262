import { Injectable } from '@angular/core';
import { ProfilesTab, SitemapNode } from '../../../sitemap/models';
import { TaskBar } from '../../models/task-bar.model';
import { ITaskBarHandlerService } from './task-bar-handler-service.interface';

@Injectable()
export class ProfilesTaskBarHandlerService implements ITaskBarHandlerService {

  getTaskBar(component: ProfilesTab): TaskBar {
    const taskBar = {
      items: []
    };
    component.modules.inner_navigation?.nodes?.forEach((node: SitemapNode, index) => {
      if (node.nodes) {
        taskBar.items.push({
          id: node.id,
          alias: node.alias,
          icon: node.icon,
          position: index,
          routerLink: `/home/${component.id}/${node.id}`,
          stateName: 'home.' + component.id + '.views',
          stateParams: { taskBarItem: node.id }
        });
      }
    });
    return taskBar;
  }
}
