import { Component } from '@angular/core';
import { UserVacation } from '../../models/user-vacation.model';
import { IValueChange } from '../../../core/interfaces/form-value-change.interface';
import { ProfileFormTemplateProviders } from '../../../core/templates/profile-form.template.providers';
import { BFOnValueChange } from '../../../core/interfaces/sdk-profile-hooks.interface';
import {AbstractProfileFormComponent} from "../../abstract/abstract-profile/abstract-profile-form.component";

@Component({
  selector: 'app-user-vacation',
  templateUrl: '../../../core/templates/profile-form.template.html',
  styleUrls: ['./user-vacation.component.scss'],
  providers: ProfileFormTemplateProviders
})
export class UserVacationComponent extends AbstractProfileFormComponent implements BFOnValueChange {

  fgppValueChanges(change: IValueChange): void {
    if (change.control === UserVacation.USER_ID) {
      const userId = this.formMgr.get(UserVacation.USER_ID).getValue();
      if (userId) {
        const userData = this.getDrilldownData(UserVacation.USER_ID);
        this.formMgr.get(UserVacation.FIRST_NAME).setValue(userData[UserVacation.FIRST_NAME]);
        this.formMgr.get(UserVacation.USER_NAME).setValue(userData[UserVacation.USER_NAME]);
      } else {
        this.formMgr.get(UserVacation.FIRST_NAME).reset();
        this.formMgr.get(UserVacation.USER_NAME).reset();
      }
    }
  }
}
