import { InjectionToken } from '@angular/core';
import { SearchResourceType } from '../models';

export const SITEMAP_TOKEN = new InjectionToken(SearchResourceType.SITEMAP);
export const RULE_TYPES_TOKEN = new InjectionToken(SearchResourceType.RULE_TYPES);

export const SEARCH_RESOURCE_TOKENS = {
    [SearchResourceType.SITEMAP]: SITEMAP_TOKEN,
    [SearchResourceType.RULE_TYPES]: RULE_TYPES_TOKEN
};
