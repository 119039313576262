import { Component, Inject, OnInit } from '@angular/core';
import { NavigationService } from '../../navigation/services/navigation.service';
import { Office } from '../../../shared/models/office.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-office-selector',
  templateUrl: './office-selector.component.html',
  styleUrls: ['./office-selector.component.scss']
})
export class OfficeSelectorComponent {

  private _offices: Array<Office>;
  private _office: string;

  constructor(private navigationService: NavigationService,
              @Inject(MAT_DIALOG_DATA) private params,
              private dialogRef: MatDialogRef<OfficeSelectorComponent>) {

    this._offices = this.navigationService
      .getWriteOnlyOffices()
      .filter(this.officesFilter.bind(this))
      .sort(this.officesSort.bind(this));

    this.setDefaultOffice();
  }

  onSelectClick(): void {
    this.dialogRef.close(this._office);
  }

  set office(officeName) {
     this._office = officeName;
  }

  get office() {
    return this._office;
  }

  get offices() {
    return this._offices;
  }

  private setDefaultOffice(): void {
    if (!this._offices || this._offices.length === 0) {
      console.error('can\'t set office to null');
    } else {
      this._office = this._offices[0].name;
    }
  }

  private officesFilter(office): boolean {
    return this.params.withGlobal || !office.isGlobal;
  }

  private officesSort(a, b): number {
    const s1 = a.name || '';
     const s2 = b.name || '';

    if (s1 === '***') {
      return -1;
    }

    if (s2 === '***') {
      return 1;
    }
    return s1.localeCompare(s2);
  }
}
