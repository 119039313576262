import { Component } from '@angular/core';
import { IValueChange } from '../../../core/interfaces/form-value-change.interface';
import { BFBeforeProfileEdit, BFOnValueChange } from '../../../core/interfaces/sdk-profile-hooks.interface';
import { ProfileFormTemplateProviders } from '../../../core/templates/profile-form.template.providers';
import { AbstractProfileFormComponent } from '../../abstract/abstract-profile/abstract-profile-form.component';
import { MOPReleaseDate } from '../../models/mop-release-date.model';

@Component({
	selector: 'app-mop-release-date',
	templateUrl: '../../../core/templates/profile-form.template.html',
	styleUrls: ['../../../core/templates/profile-form.template.scss'],
	providers: ProfileFormTemplateProviders
})
export class MOPReleaseDateComponent extends AbstractProfileFormComponent implements BFBeforeProfileEdit, BFOnValueChange {

	fgppBeforeEdit(): void {
		this.handleCalendarByType();
		this.handleCalendarByName();
	}

	fgppValueChanges(change: IValueChange): void {
		switch (change.control) {
			case MOPReleaseDate.CALENDAR_BY_TYPE:
				this.handleCalendarByName();
				return;
			case MOPReleaseDate.CALENDAR_BY_NAME:
				this.handleCalendarByType();
				return;
		}
	}

	handleCalendarByType(): void {
		this.handleCalendarControl(MOPReleaseDate.CALENDAR_BY_TYPE, MOPReleaseDate.CALENDAR_BY_NAME);
	}

	handleCalendarByName(): void {
		this.handleCalendarControl(MOPReleaseDate.CALENDAR_BY_NAME, MOPReleaseDate.CALENDAR_BY_TYPE);
	}

	handleCalendarControl(calendarControlId: MOPReleaseDate, secondCalendarControlId: MOPReleaseDate): void {
		const calendarControl = this.formMgr.get(calendarControlId);
		const secondCalendarControl = this.formMgr.get(secondCalendarControlId);
		if (secondCalendarControl.getValue()) {
			calendarControl.disable();
		} else {
			calendarControl.enable();
		}
	}
}
