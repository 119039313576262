import { NgModule } from '@angular/core';
import { FileAttachmentFormComponent } from './file-attachment-form/file-attachment-form.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { FileUploadModule } from 'ng2-file-upload';
import { StepperModule } from '../stepper/stepper.module';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';


@NgModule({
  declarations: [
    FileAttachmentFormComponent,
    FileUploadComponent
  ],
  imports: [
    StepperModule,
    CommonModule,
    FileUploadModule,
    MatButtonModule
  ],
  providers: [],
  exports: [
    FileAttachmentFormComponent
  ]
})
export class FileAttachmentModule {

}
