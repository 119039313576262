import { Inject, Injectable } from '@angular/core';
import { FormService, NotificationService } from '@fgpp-ui/components';
import { TranslateService } from '@ngx-translate/core';
import { PROFILE_ID, PROFILE_MODEL } from '../../../../core/templates/profile-form.template.providers';
import { IBANModel } from '../../../models/iban.model';

@Injectable()
export class IBANService {

	constructor(private formService: FormService,
							private translateService: TranslateService,
							private notificationService: NotificationService,
							@Inject(PROFILE_MODEL) private ibanModel: IBANModel,
							@Inject(PROFILE_ID) private profileId: string) { }

	validatePosition(currentFieldPosition: number, currentFieldLength: number, nextFieldPosition: number, nextFieldName: string): boolean {
		const formMgr = this.formService.getFormManager();
		const digitType = formMgr.get(this.ibanModel.CHK_DIGIT_TYPE).getValue();
		if (digitType !== 'IBAN') {
			return true;
		}
		if (nextFieldPosition < currentFieldPosition + currentFieldLength) {
			// overlap
			const errMsg = this.translateService.instant(`business-framework.profiles.${this.profileId}.errors.overlap-position`, { fieldName: formMgr.get(nextFieldName).getLayoutControl().get('title') });
			this.notificationService.error(errMsg);
			return false;
		} else if (nextFieldPosition > currentFieldPosition + currentFieldLength) {
			// gap
			this.notificationService.warning(this.translateService.instant(`business-framework.profiles.${this.profileId}.errors.gap-position`));
			return true;
		}
		return true;
	}

	validateIBANLength(): boolean {
		const formMgr = this.formService.getFormManager();

		const digitType = formMgr.get(this.ibanModel.CHK_DIGIT_TYPE).getValue();
		if (digitType !== 'IBAN') {
			return true;
		}
		const definedIBANLength = +formMgr.get(this.ibanModel.IBAN_LEN).getValue();

		const ibanBankLength = +formMgr.get(this.ibanModel.IBAN_BANK_ID_LEN).getValue();
		const ibanBankPosition = +formMgr.get(this.ibanModel.IBAN_BANK_ID_POS).getValue();

		const ibanBranchLength = +formMgr.get(this.ibanModel.IBAN_BRANCH_LEN).getValue();
		const ibanBranchPosition = +formMgr.get(this.ibanModel.IBAN_BRANCH_POS).getValue();

		const ibanAccountLength = +formMgr.get(this.ibanModel.IBAN_ACCOUNT_LEN).getValue();
		const ibanAccountPosition = +formMgr.get(this.ibanModel.IBAN_ACCOUNT_POS).getValue();
		if (definedIBANLength) {
			if (ibanBankLength && ibanBankPosition && ibanBranchLength && ibanBranchPosition && ibanAccountLength && ibanAccountPosition) {
				if (definedIBANLength != +ibanBankLength + +ibanBranchLength + +ibanAccountLength + (+ibanBankPosition - 1)) {
					this.notificationService.warning(this.translateService.instant(`business-framework.profiles.${this.profileId}.errors.iban-length`));
				}
			} else if (ibanBankLength && ibanBankPosition && ibanAccountLength && ibanAccountPosition) {
				if (definedIBANLength != +ibanBankLength + +ibanAccountLength + (+ibanBankPosition - 1)) {
					this.notificationService.warning(this.translateService.instant(`business-framework.profiles.${this.profileId}.errors.iban-length`));
				}
			}
		}
		return true;
	}

	setIBANExample(): void {
		const formMgr = this.formService.getFormManager();
		const layoutEvent = this.formService.getEventBus().getLayoutEventBuilder();

		let ibanExample = '';
		const countryCode = formMgr.get(this.ibanModel.COUNTRYCODE).getValue();

		const ibanBranchLength = formMgr.get(this.ibanModel.IBAN_BRANCH_LEN).getValue();
		const ibanBranchPosition = formMgr.get(this.ibanModel.IBAN_BRANCH_POS).getValue();

		const ibanBankLength = formMgr.get(this.ibanModel.IBAN_BANK_ID_LEN).getValue();
		const ibanBankPosition = formMgr.get(this.ibanModel.IBAN_BANK_ID_POS).getValue();

		const ibanAccountLength = formMgr.get(this.ibanModel.IBAN_ACCOUNT_LEN).getValue();
		const ibanAccountPosition = formMgr.get(this.ibanModel.IBAN_ACCOUNT_POS).getValue();

		if (countryCode && ibanBankLength && ibanBankPosition && ibanAccountLength && ibanAccountPosition) {

			const ibanExamplePrefix = this.translateService.instant(`business-framework.profiles.${this.profileId}.IBAN_EXAMPLE_LABEL.ibanExamplePrefix`);
			if (+ibanBranchLength && +ibanBranchPosition) {
				ibanExample = ibanExamplePrefix + `${countryCode}00 ` + ''.padEnd(+ibanBankLength, '0') + ' ' + ''.padEnd(+ibanBranchLength, '0') + ' ' + ''.padEnd(+ibanAccountLength, '0');
			} else {
				ibanExample = ibanExamplePrefix + `${countryCode}00 ` + ''.padEnd(+ibanBankLength, '0') + ' ' + ''.padEnd(+ibanAccountLength, '0');
			}
			layoutEvent.show(this.ibanModel.IBAN_LABEL);
			layoutEvent.setProperty(this.ibanModel.IBAN_LABEL, 'displayText', ibanExample);
		} else {
			layoutEvent.hide(this.ibanModel.IBAN_LABEL);
		}
	}
}
