import { Inject, Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { GridConfig } from '@fgpp-ui/grid';
import { Entity } from '../../shared/models/enums/entity.enum';
import { GridConfigService } from '../../grid/services/grid-config.service';
import { GridManagementService } from '../../grid/services/grid-management.service';
import { MessagesApiService } from './messages-api.service';
import { MatchingType } from '../components/messages-matching/models/matching-type.model';
import { MessagesType } from '../models/enums/messages-type.enum';
import { SearchFilter } from '../../shared/models/search-filter.model';
import { GridSearchRequest } from '../../grid/models/grid-search-request.model';
import { MatchingRequest } from '../components/messages-matching/models/matching-request.model';
import { SearchRequestBuilderService } from '../../grid/services/search-request-builder.service';
import { FormatsService } from '../../shared/services/formats.service';
import { Resource } from '../../grid/models/resource.model';
import { GridDataInitialState } from '../../grid/models/consts/grid-data-initial-state.const';
import { MessagesManagementService } from './messages-management.service';
import { SearchCondition } from '../../shared/search/models/classes/search-condition.model';
import { Operators } from '../../shared/search/models/enums/operators.enum';
import { FilterOperator } from '@fgpp-ui/grid';
import { GridClass } from '../../grid/models/enums/grid-class';
import { MessagesMatchingHelperService } from './messages-matching-helper.service';
import { TableSort } from '@fgpp-ui/grid';
import { PaginatorData } from '@fgpp-ui/grid';
import { UserSettingsService } from '../../core/user-settings/services/user-settings.service';

@Injectable()
export class MessagesMatchingManagementService extends GridManagementService<GridSearchRequest> {

  matchingTypes: Array<MatchingType>;

  get entity(): Entity {
    return Entity.MESSAGES;
  }

  get baseUrl(): string {
    return `do/${this.entity}/`;
  }

  get httpParams(): HttpParams {
    return null;
  }

  get defaultFilters(): Array<SearchFilter> {
    return [];
  }

  constructor(
    userSettingsService: UserSettingsService,
    public messagesMatchingHelperService: MessagesMatchingHelperService,
    gridConfigService: GridConfigService,
    private messagesApiService: MessagesApiService,
    searchRequestBuilderService: SearchRequestBuilderService<GridSearchRequest>,
    formatsService: FormatsService,
    private messagesManagementService: MessagesManagementService) {
    super(userSettingsService, messagesMatchingHelperService, gridConfigService, searchRequestBuilderService, formatsService);
  }

  getGridConfig(): GridConfig {
    const gridConfig = super.getGridConfig();
    gridConfig.filterButtons.facetsFilterBtnConfig.showButton = false;
    gridConfig.table.multiSelect = false;
    this.gridConfig.table.tableClass = this.gridConfig.table.tableClass.concat( ' ' ).concat( GridClass.DENSE_MODE );
    return gridConfig;
  }

  protected getGridData(): Promise<Resource> {
    if (this.matchingTypes?.length) {
      return super.getGridData();
    }
    return new Promise((resolve) => {
      const gridData = { ...GridDataInitialState };
      gridData.availableColumns = [...this.messagesManagementService.availableColumns];
      gridData.columns = [...this.messagesManagementService.columnsForView];
      return resolve(gridData);
    });
  }

  getMatchingTypes(uid: string): Promise<Array<MatchingType>> {
    return this.messagesApiService.matchingTypes(MessagesType.MESSAGES, uid)
      .then((matchingTypes: Array<MatchingType>) => {
        this.matchingTypes = matchingTypes;
        return matchingTypes;
      });
  }

  public setAdditionalParameters(additionalParameters: { [key: string]: string | number | MatchingType[] }, getMetaData = false) {
    this.searchRequestBuilderService.buildAdditionalParameters(this.searchRequest, additionalParameters);
    if (this.searchRequest && this.searchRequest.searchQuery && !this.searchRequest.searchQuery.searchCriteria) {
      this.searchRequest.searchQuery.searchCriteria = new SearchCondition(false, '_all', Operators.contains, ['*']);
    }
    this.invokeGridDataChange(getMetaData);
  }

  protected async setSearchFilters(getMetaData: boolean = false): Promise<void> {
    this.searchRequest.searchQuery.additionalParameters['matchingTypes'] = this.matchingTypes;
    return super.setSearchFilters(getMetaData);
  }

  setSearchSort(sort: TableSort) {
    this.searchRequest.searchQuery.additionalParameters['matchingTypes'] = this.matchingTypes;
    super.setSearchSort(sort);
  }

  setSearchPage(paginatorData: PaginatorData) {
    this.searchRequest.searchQuery.additionalParameters['matchingTypes'] = this.matchingTypes;
    super.setSearchPage(paginatorData);
  }

  public async setInternalFilters(filters: Array<SearchFilter>, getMetaData?: boolean): Promise<any> {
    const defaultFilter: SearchFilter = {
      columnId: '_all',
      operator: FilterOperator.CONTAINS,
      value: ['*']
    };
    filters = filters.length > 0 ? filters : [defaultFilter];
    this.searchRequest.searchQuery.additionalParameters['matchingTypes'] = this.matchingTypes;
    return super.setInternalFilters(filters, getMetaData);
  }

  doCreateMatch(uid: string, matchingTypeId: string, matchedUid: string[]): Promise<void> {
    const matchingRequestPayload: MatchingRequest = {
      matchedId: matchedUid,
      mId: uid,
      uId: matchingTypeId
    };

    return this.messagesApiService.createMatch(MessagesType.MESSAGES, uid, matchingTypeId, matchedUid, matchingRequestPayload);
  }
}
