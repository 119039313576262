<app-fa-dialog [title]="data.modalTitle | translate" [footer]="footerTemplate">
  <ng-template #footerTemplate>
      <p *ngIf="!display"> {{data.title | translate}} <span id="countdowntimer">{{time}} </span> </p>
      <div *ngIf="display" id="myDiv" class="animate-bottom">
        <p>{{data.subTitle | translate}}</p>

            <button mat-button color="primary" id="exit-and-discard-modal-discard-button" mat-dialog-close *ngIf="data.abortButton">
              {{data.abortButton | translate}}
            </button>
            <button mat-button color="primary" id="exit-and-discard-modal-dismiss-button" [mat-dialog-close]="'discard'" *ngIf="data.abortButton && data.button ==='close'">
              {{data.abortButton | translate}}
            </button>
            <button mat-flat-button color="primary" id="exit-and-discard-modal-close-button" [mat-dialog-close]="'save'" *ngIf="data.button ==='close'">
              {{data.saveAndClose | translate}}
            </button>

      </div>
  </ng-template>
</app-fa-dialog>
