import { Component } from '@angular/core';
import { StoreService } from '../../../services/store.service';
import { CloseSidebar } from '../../state/sidebar/actions/close-sidebar.action';

@Component({
  selector: 'app-alert-sidebar-empty',
  templateUrl: './alert-sidebar-empty.component.html',
  styleUrls: ['./alert-sidebar-empty.component.scss']
})
export class AlertSidebarEmptyComponent {

  constructor(private storeService: StoreService) { }

  onCloseClick() {
    this.storeService.dispatch(new CloseSidebar());
  }

}
