export const ToursConfig = {

  'initialIntroduction': {
    id: 'initialIntroduction',
    name: 'tour.initial_introduction.name',
    priority: 10000,
    steps: [
      {
        tourId: 'initialIntroduction',
        text: 'tour.initial_introduction.step0.text',
        title: 'tour.initial_introduction.step0.title',
        nextLabel: 'tour.initial_introduction.step0.next',
        closeLabel: 'tour.initial_introduction.step0.close'
      }
    ],
    isAbortable: false
  },
  // 'additionalIntroduction': {
  //   id: 'additionalIntroduction',
  //   name: 'tour.additional_introduction.name',
  //   priority: 9999,
  //   steps: [
  //     {
  //       tourId: 'additionalIntroduction',
  //       text: 'tour.additional_introduction.step0.text',
  //       title: 'tour.additional_introduction.step0.title',
  //       nextLabel: 'tour.next'
  //     }
  //   ],
  //   isAbortable: true
  // },
  'mainPage': {
    id: 'mainPage',
    name: 'tour.main_page.name',
    priority: 9000,
    steps: [
      {
        tourId: 'mainPage',
        text: 'tour.main_page.step0.text',
        title: 'tour.main_page.step0.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      },
      {
        tourId: 'mainPage',
        text: 'tour.main_page.step1.text',
        title: 'tour.main_page.step1.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      },
      {
        tourId: 'mainPage',
        text: 'tour.main_page.step2.text',
        title: 'tour.main_page.step2.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      },
      {
        tourId: 'mainPage',
        text: 'tour.main_page.step3.text',
        title: 'tour.main_page.step3.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      }
    ],
    isAbortable: true
  },
  'search': {
    id: 'search',
    name: 'tour.search.name',
    priority: 8500,
    steps: [
      {
        tourId: 'search',
        text: 'tour.search.step0.text',
        title: 'tour.search.step0.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      },
      {
        tourId: 'search',
        text: 'tour.search.step1.text',
        title: 'tour.search.step1.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      },
      {
        tourId: 'search',
        text: 'tour.search.step2.text',
        title: 'tour.search.step2.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      }
    ],
    isAbortable: true
  },
  'navigationPane': {
    id: 'navigationPane',
    name: 'tour.navigation_pane.name',
    priority: 8000,
    steps: [
      {
        tourId: 'navigationPane',
        text: 'tour.navigation_pane.step0.text',
        title: 'tour.navigation_pane.step0.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      },
      {
        tourId: 'navigationPane',
        text: 'tour.navigation_pane.step1.text',
        title: 'tour.navigation_pane.step1.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      },
      {
        tourId: 'navigationPane',
        text: 'tour.navigation_pane.step2.text',
        title: 'tour.navigation_pane.step2.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      }
    ],
    isAbortable: true
  },
  'navigationTree': {
    id: 'navigationTree',
    name: 'tour.navigation_tree.name',
    priority: 7000,
    steps: [
      {
        tourId: 'navigationTree',
        text: 'tour.navigation_tree.step0.text',
        title: 'tour.navigation_tree.step0.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      },
      {
        tourId: 'navigationTree',
        text: 'tour.navigation_tree.step1.text',
        title: 'tour.navigation_tree.step1.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      },
      {
        tourId: 'navigationTree',
        text: 'tour.navigation_tree.step2.text',
        title: 'tour.navigation_tree.step2.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      }
    ],
    isAbortable: true
  },
  'workspaceDialer': {
    id: 'workspaceDialer',
    name: 'tour.workspace_dialer.name',
    priority: 6000,
    steps: [
      {
        tourId: 'workspaceDialer',
        text: 'tour.workspace_dialer.step0.text',
        title: 'tour.workspace_dialer.step0.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      }
    ],
    isAbortable: true
  },
  'ruleLandingPageDialer': {
    id: 'ruleLandingPageDialer',
    name: 'tour.ruleLanding_page_dialer.name',
    priority: 6000,
    steps: [
      {
        tourId: 'ruleLandingPageDialer',
        text: 'tour.ruleLanding_page_dialer.step0.text',
        title: 'tour.ruleLanding_page_dialer.step0.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      },
      {
        tourId: 'ruleLandingPageDialer',
        text: 'tour.ruleLanding_page_dialer.step1.text',
        title: 'tour.ruleLanding_page_dialer.step1.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      }
    ],
    isAbortable: true
  },
  'materialGridRefresh': {
    id: 'materialGridRefresh',
    name: 'tour.material_grid.name',
    priority: 9001,
    steps: [
      {
        tourId: 'materialGridRefresh',
        text: 'tour.material_grid.step0.text',
        title: 'tour.material_grid.step0.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      }
    ],
    isAbortable: true
  },
  'materialGrid': {
    id: 'materialGrid',
    name: 'tour.material_grid.name',
    priority: 9000,
    steps: [
      {
        tourId: 'materialGrid',
        text: 'tour.material_grid.step1.text',
        title: 'tour.material_grid.step1.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      },
      {
        tourId: 'materialGrid',
        text: 'tour.material_grid.step2.text',
        title: 'tour.material_grid.step2.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      },
      {
        tourId: 'materialGrid',
        text: 'tour.material_grid.step3.text',
        title: 'tour.material_grid.step3.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      },
      {
        tourId: 'materialGrid',
        text: 'tour.material_grid.step4.text',
        title: 'tour.material_grid.step4.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      },
      {
        tourId: 'materialGrid',
        text: 'tour.material_grid.step5.text',
        title: 'tour.material_grid.step5.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      }
    ],
    isAbortable: true
  },
  'gridHeaderAddToFavorites': {
    id: 'gridHeaderAddToFavorites',
    name: 'tour.grid_header.name',
    priority: 6000,
    steps: [
      {
        tourId: 'gridHeaderAddToFavorites',
        text: 'tour.grid_header.step0.text',
        title: 'tour.grid_header.step0.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      }
    ],
    isAbortable: true
  },
  'gridHeaderRefresh': {
    id: 'gridHeaderRefresh',
    name: 'tour.grid_header.name',
    priority: 5900,
    steps: [
      {
        tourId: 'gridHeaderRefresh',
        text: 'tour.grid_header.step5.text',
        title: 'tour.grid_header.step5.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      }
    ],
    isAbortable: true
  },
  'gridHeaderAutoFeeder': {
    id: 'gridHeaderAutoFeeder',
    name: 'tour.grid_header.name',
    priority: 5800,
    steps: [
      {
        tourId: 'gridHeaderAutoFeeder',
        text: 'tour.grid_header.step6.text',
        title: 'tour.grid_header.step6.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      }
    ],
    isAbortable: true
  },
  'gridRefineAndFiltering': {
    id: 'gridRefineAndFiltering',
    name: 'tour.grid_refine_and_filtering.name',
    priority: 5700,
    steps: [
      {
        tourId: 'gridRefineAndFiltering',
        text: 'tour.grid_refine_and_filtering.step1.text',
        title: 'tour.grid_refine_and_filtering.step1.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      },
      {
        tourId: 'gridRefineAndFiltering',
        text: 'tour.grid_refine_and_filtering.step2.text',
        title: 'tour.grid_refine_and_filtering.step2.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      },
      {
        tourId: 'gridRefineAndFiltering',
        text: 'tour.grid_refine_and_filtering.step3.text',
        title: 'tour.grid_refine_and_filtering.step3.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      }
    ],
    isAbortable: true
  },
  'gridHeaderCreateRule': {
    id: 'gridHeaderCreateRule',
    name: 'tour.grid_header.name',
    priority: 5700,
    steps: [
      {
        tourId: 'gridHeaderCreateRule',
        text: 'tour.grid_header_create_rule.step0.text',
        title: 'tour.grid_header_create_rule.step0.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      }
    ],
    isAbortable: true
  },
  'gridHeaderScopeSelector': {
    id: 'gridHeaderScopeSelector',
    name: 'tour.grid_header.name',
    priority: 5600,
    steps: [
      {
        tourId: 'gridHeaderScopeSelector',
        text: 'tour.grid_header.step7.text',
        title: 'tour.grid_header.step7.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      }
    ],
    isAbortable: true
  },
  'gridHeaderColumns': {
    id: 'gridHeaderColumns',
    name: 'tour.grid_header.name',
    priority: 5500,
    steps: [
      {
        tourId: 'gridHeaderColumns',
        text: 'tour.grid_header.step3.text',
        title: 'tour.grid_header.step3.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      },
      {
        tourId: 'gridHeaderColumns',
        text: 'tour.grid_header.step4.text',
        title: 'tour.grid_header.step4.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      }
    ],
    isAbortable: true
  },
  'gridFooter': {
    id: 'gridFooter',
    name: 'tour.grid_footer.name',
    priority: 5000,
    steps: [
      {
        tourId: 'gridFooter',
        text: 'tour.grid_footer.step0.text',
        title: 'tour.grid_footer.step0.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      },
      {
        tourId: 'gridFooter',
        text: 'tour.grid_footer.step1.text',
        title: 'tour.grid_footer.step1.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      },
      {
        tourId: 'gridFooter',
        text: 'tour.grid_footer.step2.text',
        title: 'tour.grid_footer.step2.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      }
    ],
    isAbortable: true
  },
  'quickPayment': {
    id: 'quickPayment',
    name: 'tour.quick_payment.name',
    priority: 4500,
    steps: [
      {
        tourId: 'quickPayment',
        text: 'tour.quick_payment.step0.text',
        title: 'tour.quick_payment.step0.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      },
      {
        tourId: 'quickPayment',
        text: 'tour.quick_payment.step1.text',
        title: 'tour.quick_payment.step1.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      }
    ],
    isAbortable: true
  },
  'mt103PaymentOrderingCustomer': {
    id: 'mt103PaymentOrderingCustomer',
    name: 'tour.mt103PaymentOrderingCustomer.name',
    priority: 4400,
    steps: [
      {
        tourId: 'mt103PaymentOrderingCustomer',
        text: 'tour.mt103.step3.text',
        title: 'tour.mt103.step3.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      }
    ],
    isAbortable: true
  },
  'mt103PaymentSenderToReceiver': {
    id: 'mt103PaymentSenderToReceiver',
    name: 'tour.mt103PaymentSenderToReceiver.name',
    priority: 4500,
    steps: [
      {
        tourId: 'mt103PaymentSenderToReceiver',
        text: 'tour.mt103.step4.text',
        title: 'tour.mt103.step4.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      }
    ],
    isAbortable: true
  },
  'mt103Payment': {
    id: 'mt103Payment',
    name: 'tour.mt103.name',
    priority: 4600,
    steps: [
      {
        tourId: 'mt103Payment',
        text: 'tour.mt103.step0.text',
        title: 'tour.mt103.step0.title',
        nextLabel: 'tour.initial_introduction.step0.next',
        closeLabel: 'tour.initial_introduction.step0.close'
      },
      {
        tourId: 'mt103Payment',
        text: 'tour.mt103.step1.text',
        title: 'tour.mt103.step1.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      },
      {
        tourId: 'mt103Payment',
        text: 'tour.mt103.step6.text',
        title: 'tour.mt103.step6.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      },
      {
        tourId: 'mt103Payment',
        text: 'tour.mt103.step7.text',
        title: 'tour.mt103.step7.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      }
    ],
    isAbortable: true
  },
  'ruleAssociationScreen': {
    id: 'ruleAssociationScreen',
    name: 'tour.rules_association_screen.name',
    priority: 4000,
    steps: [
      {
        tourId: 'ruleAssociationScreen',
        text: 'tour.rules_asscociation_screen.step0.text',
        title: 'tour.rules_asscociation_screen.step0.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      }
    ],
    isAbortable: true
  },
  'ruleAssociationScreenDetails': {
    id: 'ruleAssociationScreenDetails',
    name: 'tour.rules_association_screen_details.name',
    priority: 4000,
    steps: [
      {
        tourId: 'ruleAssociationScreenDetails',
        text: 'tour.rules_asscociation_screen_details.step0.text',
        title: 'tour.rules_asscociation_screen_details.step0.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      },
      {
        tourId: 'ruleAssociationScreenDetails',
        text: 'tour.rules_asscociation_screen_details.step1.text',
        title: 'tour.rules_asscociation_screen_details.step1.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      },
      {
        tourId: 'ruleAssociationScreenDetails',
        text: 'tour.rules_asscociation_screen_details.step2.text',
        title: 'tour.rules_asscociation_screen_details.step2.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      }
    ],
    isAbortable: true
  },
  'rulesScreen': {
    id: 'rulesScreen',
    name: 'tour.rules_screen.name',
    priority: 4000,
    steps: [
      {
        tourId: 'rulesScreen',
        text: 'tour.rules_screen.step0.text',
        title: 'tour.rules_screen.step0.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      },
      {
        tourId: 'rulesScreen',
        text: 'tour.rules_screen.step1.text',
        title: 'tour.rules_screen.step1.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      },
      {
        tourId: 'rulesScreen',
        text: 'tour.rules_screen.step2.text',
        title: 'tour.rules_screen.step2.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      },
      {
        tourId: 'rulesScreen',
        text: 'tour.rules_screen.step3.text',
        title: 'tour.rules_screen.step3.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      },
      {
        tourId: 'rulesScreen',
        text: 'tour.rules_screen.step4.text',
        title: 'tour.rules_screen.step4.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      },
      {
        tourId: 'rulesScreen',
        text: 'tour.rules_screen.step5.text',
        title: 'tour.rules_screen.step5.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      },
      {
        tourId: 'rulesScreen',
        text: 'tour.rules_screen.step6.text',
        title: 'tour.rules_screen.step6.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      },
      {
        tourId: 'rulesScreen',
        text: 'tour.rules_screen.step7.text',
        title: 'tour.rules_screen.step7.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      },
      {
        tourId: 'rulesScreen',
        text: 'tour.rules_screen.step8.text',
        title: 'tour.rules_screen.step8.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      },
      {
        tourId: 'rulesScreen',
        text: 'tour.rules_screen.step9.text',
        title: 'tour.rules_screen.step9.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      }
    ],
    isAbortable: true
  },
  'rulesFunction': {
    id: 'rulesFunction',
    name: 'tour.rules_screen.name',
    priority: 4000,
    steps: [
      {
        tourId: 'rulesScreen',
        text: 'tour.rules_screen.step3.text',
        title: 'tour.rules_screen.step3.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      }
    ],
    isAbortable: true
  },
  'activityTracker': {
    id: 'activityTracker',
    name: 'tour.activity_tracker.name',
    priority: 5000,
    steps: [
      {
        tourId: 'activityTracker',
        text: 'tour.activity_tracker.step0.text',
        title: 'tour.activity_tracker.step0.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      },
      {
        tourId: 'activityTracker',
        text: 'tour.activity_tracker.step1.text',
        title: 'tour.activity_tracker.step1.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      },
      {
        tourId: 'activityTracker',
        text: 'tour.activity_tracker.step2.text',
        title: 'tour.activity_tracker.step2.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      },
      {
        tourId: 'activityTracker',
        text: 'tour.activity_tracker.step3.text',
        title: 'tour.activity_tracker.step3.title',
        nextLabel: 'tour.next',
        prevLabel: 'tour.prev'
      }
    ],
    isAbortable: true
  },
  'all': {
    id: 'all',
    name: 'tour.all.name',
    priority: 0,
    steps: [
      {
        tourId: 'all',
        text: 'tour.all.step0.text',
        title: 'tour.all.step0.title',
        nextLabel: 'tour.all.step0.next',
        prevLabel: 'tour.all.step0.prev',
        closeLabel: 'tour.all.step0.close'
      },
      {
        tourId: 'all',
        text: 'tour.all.step1.text',
        title: 'tour.all.step1.title',
        okLabel:  'tour.all.step1.okLabel'
      }
    ],
    isAbortable: true
  }
};
