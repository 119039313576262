import {Component} from '@angular/core';
import {AbstractProfileFormComponent} from '../../abstract/abstract-profile/abstract-profile-form.component';
import {ProfileFormTemplateProviders} from '../../../core/templates/profile-form.template.providers';
import {
  BFBeforeProfileSaveAs,
  BFOnFormLoadComplete,
  BFOnValueChange
} from "../../../core/interfaces/sdk-profile-hooks.interface";
import {IValueChange} from "../../../core/interfaces/form-value-change.interface";
import {InterfaceTypes} from "../../models/interface-types.model";

@Component({
  selector: 'app-interface-types',
  templateUrl: '../../../core/templates/profile-form.template.html',
  styleUrls: ['../../../core/templates/profile-form.template.scss'],
  providers: ProfileFormTemplateProviders
})
export class InterfaceTypesComponent extends AbstractProfileFormComponent implements BFOnFormLoadComplete, BFOnValueChange, BFBeforeProfileSaveAs {

  fgppFormLoadComplete(): void {
    this.disableNoOfListeners(false);
    this.enablePreferencesName();
  }

   protected getParentUidForSaveAs(): string {
    return this.getData()[InterfaceTypes.PRODUCT_PARENT_UID] || this.uid;
  }

  fgppBeforeSaveAs(): boolean {
    const originalOffice = this.getSaveAsDataSource()[InterfaceTypes.OFFICE];
    const updatedOffice = this.getData()[InterfaceTypes.OFFICE];

    if (originalOffice === '***' && updatedOffice !== '***') {
      const interfaceControl = this.formMgr.get(InterfaceTypes.INTERFACE_NAME);
      if (interfaceControl.getValue()) {
        interfaceControl.setValue(`${this.getData()[InterfaceTypes.OFFICE]}_${interfaceControl.getValue()}`);
      }

      const reqProtocol = this.formMgr.get(InterfaceTypes.REQUEST_PROTOCOL).getValue();
      const requestConnectionPointControl = this.formMgr.get(InterfaceTypes.REQUEST_CONNECTIONS_POINT);
      const responseConnectionPoint = this.formMgr.get(InterfaceTypes.RESPONSE_CONNECTIONS_POINT).getValue();

      if (reqProtocol && reqProtocol === 'MQ') {
        requestConnectionPointControl.setValue(this.updateConnectionPoint(requestConnectionPointControl.getValue(), updatedOffice));
        if (responseConnectionPoint && responseConnectionPoint !== '') {
          this.formMgr.get(InterfaceTypes.RESPONSE_CONNECTIONS_POINT).setValue(this.updateConnectionPoint(responseConnectionPoint, updatedOffice));
        }
      } else if (['FILE', 'FILE_NOTIFY'].includes(reqProtocol)) {
        requestConnectionPointControl.setValue(this.updateFileConnectionPoint(requestConnectionPointControl.getValue(), updatedOffice));
      }
    }
    return false;
  }

  private updateConnectionPoint(connectionPoint = '', office: string): string {
    if (connectionPoint.includes('Q')) {
      return connectionPoint.replace('Q', 'Q_' + office);
    } else if (connectionPoint.includes('jms/')) {
      const index = connectionPoint.indexOf('jms/') + 4;
      return connectionPoint.slice(0, index) + office + '_' + connectionPoint.slice(index);
    } else if (connectionPoint) {
      return office + '_' + connectionPoint;
    }
    return connectionPoint;
  }

  private updateFileConnectionPoint(connectionPoint = '', office: string): string {
    if (connectionPoint && connectionPoint.includes('GlobalOffice')) {
      return connectionPoint.replace('GlobalOffice', office);
    }
    return connectionPoint;
  }

  fgppValueChanges(change: IValueChange): void {
    if (change.control === InterfaceTypes.TRANSFORMATION_PREFERENCES) {
      this.enablePreferencesName();
    }

    if (change.control === InterfaceTypes.REQUEST_DIRECTION) {
      this.disableNoOfListeners();
    }
  }

  private disableNoOfListeners(doReset = true): void {
    const noOfListeners = this.formMgr.get(InterfaceTypes.NO_OF_LISTENERS);
    if (this.formMgr.get(InterfaceTypes.REQUEST_DIRECTION).getValue() === 'O') {
      doReset ? noOfListeners.disable().reset() : noOfListeners.disable();
    } else {
      noOfListeners.enable();
    }
  }

  private enablePreferencesName(): void {
    if (this.formMgr.get(InterfaceTypes.TRANSFORMATION_PREFERENCES).getValue() === 'Select Preferences Profile') {
      this.formMgr.get(InterfaceTypes.PREFERENCES_NAME).enable().markAsRequired();
    } else {
      this.formMgr.get(InterfaceTypes.PREFERENCES_NAME).disable().markAsOptional();
    }
  }

}
