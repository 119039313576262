import { Route } from '@angular/router';
import { ActivateSoftTokenComponent } from '../components/activate-soft-token/activate-soft-token.component';
import { AssignSoftTokenComponent } from '../components/assign-soft-token/assign-soft-token.component';
import { ChangeExpiredPasswordComponent } from '../components/change-expired-password/change-expired-password.component';
import { SignInPageComponent } from '../components/sign-in-page/sign-in-page.component';
import { SignInComponent } from '../components/sign-in/sign-in.component';
import { SignOutComponent } from '../components/sign-out/sign-out.component';
import { TokenTwoAuthComponent } from '../components/token-two-auth/token-two-auth.component';

// @Injectable()
// export class NavigationGuard implements CanActivate {
//
//   constructor(private authenticationService: AuthenticationService,
//               private navigationService: NavigationService,
//               private router: Router){}
//
//   canActivate():
//     Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
//     if (!this.authenticationService.isUserDataSet) {
//       return true;
//     }
//     this.navigationService.getSitemap().then(() => {
//
//       const url = this.router.url;
//       const isUrlAllowed = this.navigationService.isUrlAllowed(url);
//       if (isUrlAllowed) {
//         return true;
//       }
//       return this.router.navigate(['']);
//     });
//   }
// }


export const authenticationRoutes: Route [] = [
  {
      path: 'user',
      component: SignInPageComponent,
      children: [
        {
          path: 'signin',
          component: SignInComponent
        },
        {
          path: 'signout',
          component: SignOutComponent
        },
        {
          path: 'userToken/:userId/:messageCode/:message',
          component: TokenTwoAuthComponent
        },
        {
          path: 'assignSoftToken',
          component: AssignSoftTokenComponent
        },
        {
          path: 'activateSoftToken',
          component: ActivateSoftTokenComponent
        },
        {
          path: 'changePassword/:userId/:message',
          component: ChangeExpiredPasswordComponent
        },
        { path: '**', redirectTo:'signin' },
      ]
    }
];
