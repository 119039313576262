import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {CdtrAcctIban, CdtrAcctIbanStateModel} from './models/cdtr-acct-iban-state.model';
import {SetCdtrAcctIban} from './actions/set-cdtr-acct-iban.actions';


@State<CdtrAcctIbanStateModel>({
  name: 'iban',
  defaults: {
    cdtrAcctIban: {
      showCdtrAcctIban: false,
      isMandatory: false
    }
  }
})

@Injectable()
export class CdtrAcctIbanState {

  @Selector()
  static getCdtrAcctIban(state: CdtrAcctIbanStateModel): CdtrAcctIban {
    return state.cdtrAcctIban;
  }

  @Action(SetCdtrAcctIban)
  SetCdtrAcctIban(ctx: StateContext<CdtrAcctIbanStateModel>, {payload}: SetCdtrAcctIban) {
    ctx.patchState({
      cdtrAcctIban: payload
    });
  }
}
