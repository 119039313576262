import { AfterViewInit, Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserSettingsComponent } from '../user-settings/user-settings.component';
import { SystemVariablesService } from '../../../services/system-variables/system-variables.service';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-localization',
  templateUrl: './localization.component.html',
  styleUrls: ['./localization.component.scss']
})
export class LocalizationComponent implements OnInit, AfterViewInit {

  @ViewChildren(MatSelect) selectItems: QueryList<MatSelect>;


  languages: string[];
  dateFormats: string[];
  timeFormats: string[];
  thousandSeparatorOptions: string[];
  decimalSeparatorOptions: string[];
  form: FormGroup;
  changed = false;

  constructor(private systemVariablesService: SystemVariablesService,
    private userSettingsService: UserSettingsService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<UserSettingsComponent>,
    private translate: TranslateService) {
  }

  ngOnInit(): void {
    this.languages = this.systemVariablesService.getSingleVariable('languages');
    this.dateFormats = this.systemVariablesService.getSingleVariable('dateFormats');
    this.timeFormats = this.systemVariablesService.getSingleVariable('timeFormats');
    this.thousandSeparatorOptions = this.systemVariablesService.getSingleVariable('thousandSeparators');
    this.decimalSeparatorOptions = this.systemVariablesService.getSingleVariable('decimalSeparators');

    this.createForm();
  }

  restore() {
    this.userSettingsService.getDefault().then((response) => {
      this.updateForm(response);
    });
    this.formChanged();
  }

  cancel() {
    this.dialogRef.close();
  }

  apply() {
    const newPreferences = this.getNewPreferences();
    this.changed = false;
    this.userSettingsService.save(newPreferences);
  }

  save() {
    const newPreferences = this.getNewPreferences();
    this.form.markAsPristine();
    this.userSettingsService.save(newPreferences).then((response) => {
      this.dialogRef.close();
    });
  }

  formChanged() {
    this.form.markAsDirty();
    this.changed = true;
  }

  private createForm() {
    const preferences = this.userSettingsService.getPreferences();
    this.form = this.formBuilder.group({
      'language': [preferences.language],
      'dateFormat': [preferences.dateFormat],
      'timeFormat': [preferences.timeFormat],
      'thousandSeparator': [preferences.thousandSeparator],
      'decimalSeparator': [preferences.decimalSeparator, this.sameSeparators]
    });

    this.form.valueChanges.subscribe(() => {
      this.changed = true;
    });

    this.form.get('thousandSeparator').valueChanges.subscribe(() => {
      this.form.get('decimalSeparator').updateValueAndValidity();
    });
  }

  private updateForm(data) {
    this.form.get('language').setValue(data.language);
    this.form.get('dateFormat').setValue(data.dateFormat);
    this.form.get('timeFormat').setValue(data.timeFormat);
    this.form.get('thousandSeparator').setValue(data.thousandSeparator);
    this.form.get('decimalSeparator').setValue(data.decimalSeparator);
  }

  private getNewPreferences() {
    const settings = this.userSettingsService.getPreferences();
    const newSettings: any = {};
    Object.assign(newSettings, settings);
    newSettings.language = this.form.get('language').value;
    newSettings.dateFormat = this.form.get('dateFormat').value;
    newSettings.timeFormat = this.form.get('timeFormat').value;
    newSettings.thousandSeparator = this.form.get('thousandSeparator').value;
    newSettings.decimalSeparator = this.form.get('decimalSeparator').value;
    return newSettings;
  }

  private sameSeparators = (control: AbstractControl) => {
    if (this.form && control.value === this.form.get('thousandSeparator').value) {
      return { sameSeparators: this.translate.instant('my_profile.same_separators') };
    }
  };

  getError() {
    const errors = this.form.get('decimalSeparator').errors;
    return errors[Object.keys(errors)[0]];
  }

  ngAfterViewInit(): void {
    for (const item of this.selectItems) {
      item._elementRef.nativeElement.setAttribute('azure-id', item['_id']);
      const options = item.options;
      for (const itemList of options) {
        itemList._getHostElement().setAttribute('azure-id', itemList.value);
      }
    }
  }
}
