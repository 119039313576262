import { Injectable } from '@angular/core';
import { ITranslationConfiguration } from './translation-configuration.interface';

@Injectable()
export class TranslationConfigurationService implements ITranslationConfiguration {

  public static EN_ID = 'en';
  public static DEFAULT_LANG = 'en-US';

  constructor() { }

  getDefaultLang(): string {
    return TranslationConfigurationService.DEFAULT_LANG;
  }


}
