import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MainTab } from '../../core/navigation/models';
import { ProfilesTab, ProfilesTree, SitemapNode } from '../../core/navigation/sitemap/models';
import { NavigationService } from '../../core/navigation/services/navigation.service';

@Injectable()
export class ProfilesNavigationService {

  constructor(private translateService: TranslateService,
              private navigationService: NavigationService) { }

  getFirstTaskBarItemIdByComponentId(componentId: MainTab): string {
    const component = this.navigationService.getSitemapComponent(componentId) as ProfilesTab;
    const tree = component?.modules.inner_navigation;
    if (tree?.nodes.length > 0) {
      return component.modules.inner_navigation.nodes[0].id;
    }
  }

  getFirstProfileIdByTaskBarItemId(componentId: MainTab, taskBarItem: string): string {
    const component = this.navigationService.getSitemapComponent(componentId) as ProfilesTab;
    const tree = component?.modules.inner_navigation;
    if (tree?.nodes.length > 0) {
      const profilesForTaskBarItem = tree.nodes.find((node: SitemapNode) => node.id === taskBarItem);
      if (componentId === MainTab.OPERATIONS && taskBarItem === 'tasks') {
        this.sortTreeAlphabeticallyByAlias(profilesForTaskBarItem);
      }
      if (profilesForTaskBarItem?.nodes.length > 0) {
        if (profilesForTaskBarItem.nodes[0]?.nodes.length > 0) {
          return this.getFirstLeafId(profilesForTaskBarItem.nodes[0].nodes);
        }
        return profilesForTaskBarItem.nodes[0].id;
      }
    }
  }

  private getFirstLeafId(nodes: Array<SitemapNode>): string {
    if (nodes[0]?.nodes.length > 0) {
      return this.getFirstLeafId(nodes[0].nodes);
    }
    return nodes[0].id;
  }

  selectProfiles(menuTab: MainTab, taskBarItem: string): ProfilesTree {
    if (taskBarItem) {
      const component = this.navigationService.getSitemapComponent(menuTab) as ProfilesTab;
      let tree = component?.modules.inner_navigation;
      tree = tree?.nodes.find((node: any) => node.id === taskBarItem);
      this.addRoutingStatesToNodes(menuTab, tree);
      if (menuTab === MainTab.OPERATIONS && tree.id.toLocaleLowerCase() === 'tasks') {
        this.sortTreeAlphabeticallyByAlias(tree);
      }
      return tree;
    }
  }

  private sortTreeAlphabeticallyByAlias(tree: SitemapNode): void {
    if (tree.nodes?.length > 0) {
      tree.nodes.forEach((node: SitemapNode) => {
        if (node.nodes?.length > 0) {
          this.sortTreeAlphabeticallyByAlias(node);
        }
      });
      tree.nodes.sort((na, nb) => {
        return this.translateService.instant(na.alias).localeCompare(this.translateService.instant(nb.alias), undefined, { sensitivity: 'base' });
      });
    }
  }

  private addRoutingStatesToNodes(menuTab: MainTab, tree: SitemapNode): void {
    const navigationItems = this.navigationService.navigationMap?.get(menuTab)?.items;

    tree?.nodes.forEach((node: SitemapNode) => {
      if (node.nodes?.length > 0) {
        this.addRoutingStatesToNodes(menuTab, node);
      } else {
        const navigationItem = navigationItems?.get(node.id);
        node.routerLink = navigationItem?.routerLink;
        node.stateName = navigationItem?.stateName;
        node.stateParams = navigationItem?.stateParams;
      }
    });
  }

  getRouterLinkByProfileId(profileId: string): string {
    const navigationItem = this.navigationService.getNavigationItem(profileId);
    if (navigationItem) {
      return navigationItem.routerLink;
    }
    return null;
  }
}
