export const StandingOrder = {
  'ACC_CCY': 'STANDING_ORDER-ACC_CCY',
  'ACC_NO': 'STANDING_ORDER-ACC_NO',
  'ADJ_TO_BSNESSDATE': 'STANDING_ORDER-ADJ_TO_BSNESSDATE',
  'AMT_SRC': 'STANDING_ORDER-AMT_SRC',
  'APPROVALS': 'STANDING_ORDER-APPROVALS',
  'CT_MAX_SWEEP_AMT': 'STANDING_ORDER-CT_MAX_SWEEP_AMT',
  'CT_MIN_SWEEP_AMT': 'STANDING_ORDER-CT_MIN_SWEEP_AMT',
  'CT_TEMPLATE_MID': 'STANDING_ORDER-CT_TEMPLATE_MID',
  'DAY_OF_MONTH': 'STANDING_ORDER-DAY_OF_MONTH',
  'DAY_OF_WEEK': 'STANDING_ORDER-DAY_OF_WEEK',
  'DD_MAX_SWEEP_AMT': 'STANDING_ORDER-DD_MAX_SWEEP_AMT',
  'DD_MIN_SWEEP_AMT': 'STANDING_ORDER-DD_MIN_SWEEP_AMT',
  'DD_TEMPLATE_MID': 'STANDING_ORDER-DD_TEMPLATE_MID',
  'DEPARTMENT': 'STANDING_ORDER-DEPARTMENT',
  'EFFECTIVE_DATE': 'EFFECTIVE_DATE',
  'END_BY_DATE': 'STANDING_ORDER-END_BY_DATE',
  'FREQUENCY_PATTERN': 'STANDING_ORDER-FREQUENCY_PATTERN',
  'LAST_MID': 'STANDING_ORDER-LAST_MID',
  'LAST_MIDS': 'STANDING_ORDER-LAST_MIDS',
  'MIN_REMAIN_BAL': 'STANDING_ORDER-MIN_REMAIN_BAL',
  'MONTH_NUMBER': 'STANDING_ORDER-MONTH_NUMBER',
  'NEXT_PROC_DT': 'STANDING_ORDER-NEXT_PROC_DT',
  'NUMBER_OF_OCCURRENCES': 'STANDING_ORDER-NUMBER_OF_OCCURRENCES',
  'OFFICE': 'STANDING_ORDER-OFFICE',
  'PAYMENTS_GENERATED_CNT': 'STANDING_ORDER-PAYMENTS_GENERATED_CNT',
  'PMNT_TYPE': 'STANDING_ORDER-PMNT_TYPE',
  'PRIMARY': 'STANDING_ORDER-PRIMARY',
  'PROFILE_CHANGE_STATUS': 'STANDING_ORDER-PROFILE_CHANGE_STATUS',
  'RECUR_EVERY': 'STANDING_ORDER-RECUR_EVERY',
  'REC_STATUS': 'STANDING_ORDER-REC_STATUS',
  'SO_CLOSED': 'STANDING_ORDER-SO_CLOSED',
  'SO_DESCRIPTION': 'STANDING_ORDER-SO_DESCRIPTION',
  'SO_NAME': 'STANDING_ORDER-SO_NAME',
  'TIMES_OF_OCCURRENCES': 'STANDING_ORDER-TIMES_OF_OCCURRENCES',
  'V_BEFORE_AFTER': 'STANDING_ORDER-V_BEFORE_AFTER',
  'V_CT_TEMPLATE_NM': 'MINF-V_CT_TEMPLATE_NM',
  'V_DD_TEMPLATE_NM': 'MINF-V_DD_TEMPLATE_NM',
  'TIME_STAMP': 'TIME_STAMP',
  'UID': 'VIRTUAL-UID',
  'PROFILE_UPDATE_PK': 'PROFILE_UPDATE_PK',
  'PU_TIME_STAMP': 'PU_TIME_STAMP',
  'NOTE': 'NOTE'
};
