import { Injectable } from '@angular/core';
import { IEventTelemetry } from '@microsoft/applicationinsights-web';
import { ClickItemProperties } from '../../interfaces/click-item-properties.interface';
import { EventType } from '../../interfaces/event-type.enum';

@Injectable({
  providedIn: 'root'
})
export class ClickItemFilterService {

  private elementList: string[] = ['BUTTON', 'INPUT'];
  private viewPropOrder = ['type', 'id'];

  constructor() { }

  public liquidityClickFilter(event: any): any {
    if (event['type'] === 'MAT-TREE-NODE') {
      const eventId = event['id'];
      if (eventId.indexOf('(') !== -1) {
        event['id'] = 'Account';
      } else if (eventId.length === 3) {
        event['id'] = 'Office';
      } else {
        event['id'] = 'Accounts group';
      }
      event['view'] = event['type'] + '^' + event['id'];
    }
    return event;
  }

  public getElementFromClickEvent(event): IEventTelemetry {
    const element = event.target;
    const parent = event.target.parentElement;
    const eventTelemetry = {} as IEventTelemetry;
    eventTelemetry.name = EventType.CLICKEVENT;
    eventTelemetry.properties = this.getElementProperties(element, parent);
    return eventTelemetry.properties?.id ? eventTelemetry : undefined;
  }

  private getElementProperties(element, parent): ClickItemProperties {
    const closestElement = element.closest('[azure-id]:not(null)');
    if (closestElement && closestElement.getAttribute('azure-id') === EventType.GRIDEVENT) {
      return;
    }
    const propeties = {} as ClickItemProperties;
    const elementToSend = closestElement ? closestElement : this.getElementOrParent(element, parent);
    propeties.type = elementToSend.nodeName || '';
    propeties.id = elementToSend.getAttribute('azure-id') || elementToSend.id || elementToSend.name;
    propeties.view = this.mergeElementData(propeties);
    return propeties;
  }

  private mergeElementData(elementData: ClickItemProperties): string {
    let view = '';
    this.viewPropOrder.forEach(prop => {
      if (elementData[prop] && elementData[prop].length > 0 ) {
        view = view.length > 0 ? view + '^' + elementData[prop] : elementData[prop];
      }
    });
    return view;
  }

  private getElementOrParent(element, parent) {
    return this.elementList.indexOf(parent?.nodeName) > -1 ? parent : element;
  }
}
