import {
  BFEvents,
  ComponentManagerService,
  FormService,
  ModalFormValueChange,
  MultiLineComponentTemplate,
  MultiLineFormModalComponent
} from '@fgpp-ui/components';
import { Component, OnDestroy, OnInit } from '@angular/core';


@Component({
  selector: 'app-md-fields-to-mask',
  template : MultiLineComponentTemplate.HTML_TEMPLATE,
  styles: [MultiLineComponentTemplate.STYLE_TEMPLATE],
  providers: [
    FormService,
    ComponentManagerService
  ]
})
export class FieldsToMaskComponent extends MultiLineFormModalComponent implements OnInit, OnDestroy {
  ngOnInit(): void {
    super.ngOnInit();
    this.formService.getEventBus().on(BFEvents.Type.CANVAS).subscribe((status: BFEvents.CanvasEvent) => {
      if (status.value.state === 'ready') {
        const maskingType = this.formMgr.get('MASKING_LOGICAL_FIELDS-MASKING_TYPE').getValue();
        this.onMaskingTypeValueChanges(maskingType);
      }
    });
  }

  protected valueChanges(change: ModalFormValueChange): void {
    if (change.control === 'MASKING_LOGICAL_FIELDS-MASKING_TYPE') {
      this.formMgr.get('MASKING_LOGICAL_FIELDS-MASKING_SUB_TYPE_NAME').reset();
      const maskingType = change.value;
      this.onMaskingTypeValueChanges(maskingType);
    }
  }

  onMaskingTypeValueChanges(maskingType: string): void {
    if (maskingType === 'Partial') {
      this.formMgr.get('MASKING_LOGICAL_FIELDS-MASKING_SUB_TYPE_NAME').enable();
      this.formMgr.get('MASKING_LOGICAL_FIELDS-MASKING_SUB_TYPE_NAME').markAsRequired();
    } else if (!maskingType || maskingType === 'Full') {
      this.formMgr.get('MASKING_LOGICAL_FIELDS-MASKING_SUB_TYPE_NAME').disable();
      this.formMgr.get('MASKING_LOGICAL_FIELDS-MASKING_SUB_TYPE_NAME').markAsOptional();
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}

