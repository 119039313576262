import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Icon } from '../../models/icon.model';

@Component({
  selector: 'lib-clear-icon',
  templateUrl: './clear-icon.component.html',
  styleUrls: ['./clear-icon.component.scss']
})
export class ClearIconComponent implements OnInit, AfterViewInit {
  clearIcon: Icon;
  readonly clearIconTemplateRef = 'assets/icons/clear.svg';
  @Input() isDisabled: boolean;
  @Output() onClicked = new EventEmitter<void>();

  constructor(private elem: ElementRef) { }

  ngOnInit() {
    this.clearIcon = {
      iconTemplateRef: this.clearIconTemplateRef,
      isDisabled: this.isDisabled,
      mainClass: null,
      iconClass: 'disabledClearIcon',
      tooltip: null,
      style: null
    };
  }

  onClick() {
    this.onClicked.emit();
  }

  ngAfterViewInit(): void {
    this.elem.nativeElement.setAttribute('azure-id', 'clear-icon');
  }
}
