<div class="fn-ui-severity {{severity}}">
    <svg viewBox="0 0 34.067 26.985" [ngStyle]="{ 'width': width, 'height': height }">
        <g transform="translate(-1622.123 -177)">
            <g transform="translate(1651.081 177)">
                <g transform="translate(0 0)">
                    <path class="path1"
                        d="M2.554,1A2.554,2.554,0,0,0,0,3.554V25.43a2.554,2.554,0,0,0,5.109,0V3.554A2.554,2.554,0,0,0,2.554,1Z"
                        transform="translate(0 -1)" />
                </g>
            </g>
            <g transform="translate(1636.602 183.605)">
                <g transform="translate(0 0)">
                    <path class="path2"
                        d="M2.554,3A2.554,2.554,0,0,0,0,5.554V20.825a2.554,2.554,0,1,0,5.109,0V5.554A2.554,2.554,0,0,0,2.554,3Z"
                        transform="translate(0 -3)" />
                </g>
            </g>
            <g transform="translate(1622.123 193.513)">
                <g transform="translate(0 0)">
                    <path class="path3"
                        d="M2.554,6A2.554,2.554,0,0,0,0,8.554v5.363a2.554,2.554,0,1,0,5.109,0V8.554A2.554,2.554,0,0,0,2.554,6Z"
                        transform="translate(0 -6)" />
                </g>
            </g>
        </g>
    </svg>
</div>