import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '../authentication/services/authentication.service';

@Injectable()
export class SpecialItemsService {

  constructor(private authenticationService: AuthenticationService, private http: HttpClient) { }

  openDialog(url: string): void {
    const width = 230;
    const height = 170;
    const left = window.screenX + (window.outerWidth / 2) - (width / 2);
    const top = window.screenY + (window.outerHeight / 2) - (height / 2);
    window.open(url, null,
      'toolbar=no, location=no, directories=no status=no, menubar=no, scrollbars=no, ' +
      'resizable=no, height=' + height + ', width=' + width + ', top=' + top + ', left=' + left + '');
  }

  eventRefreshSingleProfileJsp(): void {
    this.openDialog('/gppold/sd-html/refreshSingleJsp.html');
  }

  eventForceUserLoggout(): void {
    this.openDialog('/gppold/sd-html/forceUserLogout.html');
  }

  eventRefreshMsgScreensets(): void {
    this.openDialog('/gppold/sd-html/refreshMsgScreensets.html');
  }

  devRuntimeEvent(event: string): void {
    const url = '/gppold/servlet/MainServlet?' + '@FORM_NAME@=frmDevRuntime&@COMMAND_EVENT@=' + event
      + '&@SESSION_ID@=' + this.authenticationService.sessionId + '&txtLoginMode=INTEGRATED';

    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
      responseType: 'text'
    } as any;

    this.http.post<string>(url, null, options).toPromise()
      .then((response) => {
        const document = new DOMParser().parseFromString(response.toString(), 'text/html');
        const compCode = document.querySelector('COMP_CODE') as HTMLUnknownElement;
        if (compCode && compCode.innerText === '0') {
          window.alert('Success');
        } else {
          window.alert('Failure');
        }
      }).catch(function () {
        window.alert('Error occurred');
      });
  }
}
