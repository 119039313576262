import { Injectable } from '@angular/core';
import { ComplexCondition, ConditionsBankNode, ContentType, SimpleCondition } from '../models';

@Injectable()
export class RuleConditionsService {

  checkedConditionsCount = 0;
  conditionsCount = 0;
  countParentsGroups = 0;
  maxTreeLevel = 0;
  parentGroupsAreSiblings = false;
  isRedundantOperators = false;
  conditions = {} as ComplexCondition;
  checkedGroup = {} as ComplexCondition;
  parentGroup = {} as ComplexCondition;
  conditionsText: any = {};
  flatOriginalRuleConditions: Array<SimpleCondition>;
  flatRuleConditionsOnInit: Array<SimpleCondition>;

  private numberMarkedGroups = 0;

  createFlatConditionArray(group): Array<SimpleCondition> {
    const flatConditions = [];
    for (let i = 0; i < group.conditions.length; i++) {
      if ((group.conditions[i] as ComplexCondition).logicalOperator) {
        flatConditions.push(...this.createFlatConditionArray(group.conditions[i] as ComplexCondition));
      } else {
        flatConditions.push(group.conditions[i]);
      }
    }
    return flatConditions;
  }

  countCheckedConditions(group: ComplexCondition): void {
    if (!group.conditions) {
      return;
    }

    for (let i = 0; i < group.conditions.length; i++) {
      if ((group.conditions[i] as SimpleCondition).isChecked) {
        this.checkedConditionsCount++;
        this.conditionsCount++;
        this.checkedGroup = group;
      } else if ((group.conditions[i] as ComplexCondition).logicalOperator) {
        this.countCheckedConditions(group.conditions[i] as ComplexCondition);
      } else {
        this.conditionsCount++;
      }
    }
  }

  checkIfCheckedConditionsInTheSameGroup(): boolean {
    this.numberMarkedGroups = 0;
    this.countCheckedGroups(this.conditions);
    return this.numberMarkedGroups === 1;
  }

  unMarkGroupsWithCheckedConditions(group: ComplexCondition): void {
    if (group.logicalOperator && group.isCheckedCondition) {
      group.isCheckedCondition = false;
    }
    for (let i = 0; i < group.conditions.length; i++) {
      if ((group.conditions[i] as ComplexCondition).logicalOperator && (group.conditions[i] as ComplexCondition).isCheckedCondition) {
        (group.conditions[i] as ComplexCondition).isCheckedCondition = false;
        this.unMarkGroupsWithCheckedConditions(group.conditions[i] as ComplexCondition);
      } else if ((group.conditions[i] as ComplexCondition).logicalOperator && !(group.conditions[i] as ComplexCondition).isCheckedCondition) {
        this.unMarkGroupsWithCheckedConditions(group.conditions[i] as ComplexCondition);
      }
    }
  }

  markParentsGroups(group: ComplexCondition): void {
    this.doMarkParentsGroups(group);
    if (this.countParentsGroups > 1) {
      for (let i = 0; i < this.maxTreeLevel; i++) {
        this.checkParents(group);
      }
    }
  }

  unMarkParentsGroups(group: ComplexCondition): void {
    if (group.logicalOperator && group.isParent) {
      group.isParent = false;
    }

    for (let i = 0; i < group.conditions.length; i++) {
      if ((group.conditions[i] as ComplexCondition).logicalOperator && (group.conditions[i] as ComplexCondition).isParent) {
        (group.conditions[i] as ComplexCondition).isParent = false;
        this.unMarkParentsGroups(group.conditions[i] as ComplexCondition);
      } else if ((group.conditions[i] as ComplexCondition).logicalOperator && !(group.conditions[i] as ComplexCondition).isParent) {
        this.unMarkParentsGroups(group.conditions[i] as ComplexCondition);
      } else if (!(group.conditions[i] as ComplexCondition).logicalOperator && (group.conditions[i] as ComplexCondition).isParent) {
        (group.conditions[i] as ComplexCondition).isParent = false;
      }
    }
  }

  parentsGroupsIsSiblings(group: ComplexCondition): void {
    if (group.logicalOperator && group.isParent) {
      this.parentGroupsAreSiblings = false;
      return;
    }
    const countParentGroups = this.countParentGroupsInGroup(group);
    if (countParentGroups > 0) {
      this.parentGroupsAreSiblings = countParentGroups == this.countParentsGroups;
    } else {
      for (let i = 0; i < group.conditions.length; i++) {
        if ((group.conditions[i] as ComplexCondition).logicalOperator) {
          this.parentsGroupsIsSiblings(group.conditions[i] as ComplexCondition);
        }
      }
    }
  }

  countTreeLevels(conditions: Array<ComplexCondition | SimpleCondition>, group: ComplexCondition): void {
    this.maxTreeLevel = 0;
    this.makeCount(conditions, group);
  }

  checkRedundantOperators(group: ComplexCondition): void {
    if (!group.conditions) {
      return;
    }

    for (let i = 0; i < group.conditions.length; i++) {
      if ((group.conditions[i] as ComplexCondition).logicalOperator) {
        if (group.logicalOperator == (group.conditions[i] as ComplexCondition).logicalOperator) {
          this.isRedundantOperators = true;
          break;
        } else {
          this.checkRedundantOperators(group.conditions[i] as ComplexCondition);
        }
      }
    }
  }

  getCheckedCondition(conditions: Array<ComplexCondition | SimpleCondition>): SimpleCondition {
    for (let i = 0; i < conditions.length; i++) {
      if ((conditions[i] as ComplexCondition).conditions) {
        const condition = this.getCheckedCondition((conditions[i] as ComplexCondition).conditions);
        if (condition) {
          return condition;
        }
      }

      if ((conditions[i] as SimpleCondition).isChecked) {
        return conditions[i] as SimpleCondition;
      }
    }
  }

  doText(): string {
    this.maxTreeLevel = 0;
    this.countTreeLevels(this.conditions.conditions, this.conditions);
    this.conditionsText = JSON.parse(JSON.stringify(this.conditions));
    for (let i = 0; i < this.maxTreeLevel + 1; i++) {
      this.getTextConditions(this.conditionsText);
    }

    return this.conditionsText.text;
  }

  private getTextConditions(group: ComplexCondition): void {
    let hasComplexCondition = false;
    for (let i = 0; i < group.conditions.length; i++) {
      if ((group.conditions[i] as ComplexCondition).logicalOperator && (group.conditions[i] as ComplexCondition).text) {
        group.conditions[i] = (group.conditions[i] as ComplexCondition).text as any;
      } else if ((group.conditions[i] as ComplexCondition).logicalOperator && (group.conditions[i] as ComplexCondition).text === undefined) {
        hasComplexCondition = true;
        this.getTextConditions(group.conditions[i] as ComplexCondition);
      } else if (typeof (group.conditions[i]) === 'object') {
        group.conditions[i] = this.getTextForOneCondition(group.conditions[i] as SimpleCondition) as any;
      }
    }

    if (!hasComplexCondition && !group.text) {
      group.text = this.getTextForGroup(group);
    }
  }

  private getTextForOneCondition(condition: SimpleCondition): string {
    let conditionText =
      this.makeTextObject(condition.leftOperand as ConditionsBankNode) + ' ' + condition.conditionOperator + ' ' + this.makeTextObject(condition.rightOperand as ConditionsBankNode);
    if (condition.innerConditions) {
      conditionText += this.doTextFromInnerConditions(condition.innerConditions);
    }
    conditionText += '<br>';

    return conditionText;
  }

  private getTextForGroup(group: ComplexCondition): string {
    let text = '';
    let conditionText = '';
    for (let i = 0; i < group.conditions.length; i++) {
      typeof (group.conditions[i]) === 'object' ? conditionText = (group.conditions[i] as ComplexCondition).text : conditionText = group.conditions[i] as any;
      if (i === 0) {
        text = this.getTextForFirstConditionInGroup(group, conditionText);
      } else if (i === group.conditions.length - 1) {
        text = text + ' ' + conditionText;
      } else {
        text = `${text} ${conditionText} <span class="preview-condition-operator">${group.logicalOperator}</span>`;
      }
    }

    if (typeof group.level === 'undefined' || group.level != 0) {
      text = `(<br><span class="rule-preview-level-nesting"> ${text} </span>)<br>`;
    }

    return text;
  }

  private getTextForFirstConditionInGroup(group: ComplexCondition, conditionText: string): string {
    if (group.conditions.length === 1) {
      return conditionText;
    } else if (conditionText.charAt(conditionText.length - 1) === ' ') {
      return `${conditionText}span class="preview-condition-operator">${group.logicalOperator}</span>`;
    } else {
      return `${conditionText} <span class="preview-condition-operator">${group.logicalOperator}</span>`;
    }

  }

  private makeTextObject(node: ConditionsBankNode): string {
    if (node == null) {
      return;
    }
    let nodeAlias: any = node.alias || node;
    if (typeof nodeAlias !== 'string') {
      nodeAlias = ' ';
    }

    let nodeType = node.contentType as string;
    if (nodeType === ContentType.FUNCTION && node.alias.startsWith('BASE_CONDITION(')) {
      nodeType = 'BaseCondition';
    }

    return '<span class="' + nodeType + '">' + /*$filter('dhSanitize')*/(nodeAlias) + '</span>';
  }

  private doTextFromInnerConditions(innerConditions: Array<SimpleCondition>): string {
    let txt = '';
    for (let i = 0; i < innerConditions.length; i++) {
      txt += ` ${innerConditions[i].conditionOperator} ${this.makeTextObject(innerConditions[i].rightOperand as ConditionsBankNode)}`;
    }

    return txt;
  }

  private makeCount(conditions: Array<ComplexCondition | SimpleCondition>, group: ComplexCondition): void {
    for (let i = 0; i < conditions.length; i++) {
      if ((conditions[i] as ComplexCondition).logicalOperator) {
        if (!(conditions[i] as ComplexCondition).level) {
          (conditions[i] as ComplexCondition).level = group.level + 1;
        }
        if (this.maxTreeLevel < group.level + 1) {
          this.maxTreeLevel = group.level + 1;
        }
        this.makeCount((conditions[i] as ComplexCondition).conditions, conditions[i] as ComplexCondition);
      }
    }
  }

  private countParentGroupsInGroup(group: ComplexCondition): number {
    let countParentGroups = 0;
    for (let i = 0; i < group.conditions.length; i++) {
      if ((group.conditions[i] as ComplexCondition).isParent) {
        countParentGroups++;
      }
    }
    return countParentGroups;
  }

  private doMarkParentsGroups(group: ComplexCondition): void {
    const countCheckedConditions = this.countCheckedAndParentConditionsInGroup(group);

    if (group.conditions.length === countCheckedConditions && !group.isParent) {
      this.handleGroupAsParent(group);
    } else {
      for (let j = 0; j < group.conditions.length; j++) {
        if ((group.conditions[j] as SimpleCondition).isChecked) {
          (group.conditions[j] as ComplexCondition).isParent = true;
          this.countParentsGroups++;
        } else if ((group.conditions[j] as ComplexCondition).logicalOperator && !(group.conditions[j] as ComplexCondition).isParent) {
          this.doMarkParentsGroups(group.conditions[j] as ComplexCondition);
        }
      }
    }
  }

  private handleGroupAsParent(group: ComplexCondition): void {
    group.isParent = true;
    this.parentGroup = JSON.parse(JSON.stringify(group));
    this.countParentsGroups++;
  }

  private countCheckedAndParentConditionsInGroup(group: ComplexCondition): number {
    let countCheckedConditions = 0;
    for (let i = 0; i < group.conditions.length; i++) {
      if ((group.conditions[i] as SimpleCondition).isChecked || (group.conditions[i] as ComplexCondition).isParent) {
        countCheckedConditions++;
      }
    }
    return countCheckedConditions;
  }

  private countCheckedGroups(group: ComplexCondition): void {
    for (let i = 0; i < group.conditions.length; i++) {
      if ((group.conditions[i] as SimpleCondition).isChecked && !group.isCheckedCondition) {
        group.isCheckedCondition = true;
        this.numberMarkedGroups = this.numberMarkedGroups + 1;
        this.checkedGroup = group;
      } else if ((group.conditions[i] as ComplexCondition).logicalOperator) {
        this.countCheckedGroups(group.conditions[i] as ComplexCondition);
      }
    }
  }

  private checkParents(group: ComplexCondition): void {
    if (group.logicalOperator && group.isParent) {
      this.parentGroup = group;
      this.removeParentMarksInsideParentGroup(group);
    } else {
      if (group.logicalOperator) {
        const parentsConditions = this.checkParentsConditions(group.conditions);
        if (parentsConditions === group.conditions.length) {
          this.handleAsParentGroup(group);
        }
      }

      for (let j = 0; j < group.conditions.length; j++) {
        if ((group.conditions[j] as ComplexCondition).logicalOperator && !group.isParent) {
          this.checkParents(group.conditions[j] as ComplexCondition);
        }
      }
    }
  }

  private checkParentsConditions(conditions: Array<ComplexCondition | SimpleCondition>): number {
    let parentsConditions = 0;
    for (let i = 0; i < conditions.length; i++) {
      if ((conditions[i] as ComplexCondition).isParent) {
        parentsConditions++;
      }
    }
    return parentsConditions;
  }

  private handleAsParentGroup(group: ComplexCondition): void {
    group.isParent = true;
    this.parentGroup = group;
    this.countParentsGroups++;
    this.removeParentMarksInsideParentGroup(group);
  }

  private removeParentMarksInsideParentGroup(group: ComplexCondition): void {
    for (let i = 0; i < group.conditions.length; i++) {
      if ((group.conditions[i] as ComplexCondition).logicalOperator && (group.conditions[i] as ComplexCondition).isParent) {
        this.removeMark(group.conditions[i] as ComplexCondition);
        this.removeParentMarksInsideParentGroup(group.conditions[i] as ComplexCondition);
      } else if ((group.conditions[i] as ComplexCondition).logicalOperator && !(group.conditions[i] as ComplexCondition).isParent) {
        this.removeParentMarksInsideParentGroup(group.conditions[i] as ComplexCondition);
      } else if ((group.conditions[i] as SimpleCondition).isChecked && (group.conditions[i] as ComplexCondition).isParent) {
        this.removeMark(group.conditions[i] as ComplexCondition);
      }
    }
  }

  private removeMark(condition: ComplexCondition): void {
    condition.isParent = false;
    this.countParentsGroups--;
  }

}
