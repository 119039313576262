import { InjectionToken } from '@angular/core';
import { NavigationService } from '../../../core/navigation/services/navigation.service';
import { TranslateService } from '@ngx-translate/core';
import { MainTab } from '../../../core/navigation/models';
import { IEntityNavigationService } from '../interfaces/entity-navigation-service.interface';
import { MessagesType } from '../../../messages/models/enums/messages-type.enum';
import { FlatTreeNode } from '../models/flat-tree-node.model';
import { MessagesQueue } from '../../../messages/models/messages-queue.model';
import { SitemapNode } from '../../../core/navigation/sitemap/models';
import { TreeNode } from '../models/tree-node.model';
import { OfficesCounts } from '../models/offices-counts.model';

export const ENTITY_NAVIGATION_TOKEN = new InjectionToken<
  Array<IEntityNavigationService>
>('entity-navigation');

export abstract class BaseNavigationService
  implements IEntityNavigationService
{
  abstract get type(): MessagesType;
  protected abstract get systemQueueType(): string;
  protected abstract get customQueueType(): string;
  protected abstract get entityTypeGrid(): string;

  protected systemQueuesTree: MessagesQueue;
  protected flattedSystemQueuesTree: FlatTreeNode = {};
  protected flattedCustomQueuesTree: FlatTreeNode = {};
  private _customQueuesTree: MessagesQueue;

  get customQueuesTree(): MessagesQueue {
    return this._customQueuesTree;
  }

  get isSupportTemplates(): boolean {
    return false;
  }

  constructor(
    protected navigationService: NavigationService,
    protected translateService: TranslateService,
    protected entityService
  ) {}

  initSystemQueuesTree(): MessagesQueue {
    if (this.systemQueuesTree) {
      return this.systemQueuesTree;
    }
    const module = this.navigationService.getSitemapComponent(
      MainTab.MESSAGES_CENTER
    ).modules[this.systemQueueType];
    if (!module) {
      return null;
    }
    return (this.systemQueuesTree = this.walkNode(
      module,
      this.flattedSystemQueuesTree,
      'system-queues'
    ));
  }

  updateSystemQueuesTree(): Promise<MessagesQueue> {
    if (!this.systemQueuesTree) {
      return Promise.resolve(null);
    }
    return this.entityService.getSystemQueuesCounts(this.type).then(
      (response) => {
        this.putCounts(response, this.flattedSystemQueuesTree);
        return this.systemQueuesTree;
      },
      (reason) => {
        return Promise.reject(reason);
      }
    );
  }

  initCustomQueuesTree(): MessagesQueue {
    if (this._customQueuesTree) {
      return this._customQueuesTree;
    }
    const module = this.navigationService.getSitemapComponent(
      MainTab.MESSAGES_CENTER
    ).modules[this.customQueueType];
    if (!module) { //The sitemap data didn't return any data for this navigation, but still we want to present the tree (like in Favorites)
      this._customQueuesTree = {
        id: this.customQueueType,
        queueType: 'custom-queues',
        status: this.customQueueType,
        statusAlias: this.customQueueType,
        type: this.type,
      };
      return this._customQueuesTree;
    }
    return (this._customQueuesTree = this.walkNode(
      module,
      this.flattedCustomQueuesTree,
      'custom-queues'
    ));
  }

  updateCustomQueuesTree(): Promise<Array<MessagesQueue>> {
    if (!this.customQueuesTree) {
      return Promise.resolve(null);
    }
    const promises = this.createPromises();
    return Promise.all(promises);
  }

  protected createPromises(): Array<Promise<MessagesQueue>> {
    this.customQueuesTree.count = 0;
    return this.customQueuesTree.nodes.map((node) => this.createPromise(node));
  }

  protected createPromise(node: MessagesQueue): Promise<MessagesQueue> {
    const udqName = node.id;
    return this.entityService.getCustomQueuesCounts(this.type, udqName).then(
      (response) => {
        this.setCounts(node, response);
        this.customQueuesTree.count += node.count;
        return Promise.resolve(node);
      },
      (reason) => {
        return Promise.reject(reason);
      }
    );
  }

  protected setCounts(node: MessagesQueue, response: OfficesCounts): void {
    node.count = 0;
    // iterate children nodes (offices) to set office data
    for (let j = 0; j < node.nodes.length; j++) {
      const kid = node.nodes[j];
      const kidId = kid.alias.toLowerCase();
      const kidCounts = response[kidId];
      // reset kid counts
      kid.count = 0;
      kid.amount = 0;

      // set newer kid counts
      if (kidCounts != null) {
        kid.count = kidCounts.count;
        kid.amount = kidCounts.amount;
      }
      // propagate count to parent
      node.count += kid.count;
    }
  }

  // SitemapNode demand alias to be defined but in MessagesQueue its optional
  // @ts-ignore
  protected walkNode(
    node: SitemapNode<MessagesQueue>,
    flatTree: FlatTreeNode,
    queueType: string,
    specificData?
  ): MessagesQueue {
    let treeNode = this.buildTreeNode(node, queueType);
    this.setSpecificDate(treeNode, node, specificData);
    this.setOffice(treeNode, node, flatTree);

    if (treeNode.searchable) {
      treeNode = this.addRoutingStatesToNodes(treeNode);
    }

    flatTree[treeNode.id] = {
      self: treeNode,
      parent: undefined,
    };

    if (!treeNode.isLeaf) {
      for (let i = 0; i < node.nodes.length; i++) {
        const childTreeNode = this.walkNode(
          node.nodes[i],
          flatTree,
          queueType,
          specificData
        );
        flatTree[childTreeNode.id].parent = treeNode;
        treeNode.nodes.push(childTreeNode);
      }
    }

    return treeNode;
  }

  private buildTreeNode(
    node: SitemapNode<MessagesQueue>,
    queueType: string
  ): MessagesQueue {
    return {
      id: node.id,
      status: node.id,
      isLeaf: !(node.nodes != null && node.nodes.length > 0),
      nodes: [],
      type: this.type,
      queueType,
      alias: node.data.alias,
      statusAlias: node.data.alias,
      searchable: node.data.searchable === true,
      count: node.data.count || 0,
      amount: node.data.amount || 0,
      currency: node.data.currency || '',
    };
  }

  private setSpecificDate(
    treeNode: MessagesQueue,
    node: SitemapNode<MessagesQueue>,
    specificData
  ): void {
    if (specificData && specificData.length > 0) {
      for (let j = 0; j < specificData.length; j++) {
        treeNode[specificData[j]] = node.data[specificData[j]];
      }
    }
  }

  private setOffice(
    treeNode: MessagesQueue,
    node: SitemapNode<MessagesQueue>,
    flatTree: FlatTreeNode
  ): void {
    if (node.data?.office != null) {
      treeNode['office'] = node.data.office;
      treeNode.alias = node.data.office;
      treeNode.statusAlias = flatTree[treeNode.id].self.alias;
      treeNode.id = node.id + '/' + node.data.office;
    }
  }

  private addRoutingStatesToNodes(treeNode: MessagesQueue): MessagesQueue {
    const key = `${treeNode.queueType}.${treeNode.type}.${
      treeNode.uid || treeNode.id.replace('/', '.')
    }`;
    const navigationItem = this.navigationService.getTabNavigationItem(
      MainTab.MESSAGES_CENTER,
      key
    );
    treeNode.routerLink = navigationItem?.routerLink;
    treeNode.stateName = navigationItem?.stateName;
    treeNode.stateParams =
      JSON.stringify(navigationItem?.stateParams || null) !== JSON.stringify({})
        ? navigationItem?.stateParams
        : null;
    return treeNode;
  }

  private propagateCounts(
    node: TreeNode,
    count: number,
    flattedQueuesTree: FlatTreeNode
  ): void {
    if (node && node.parent) {
      node.parent.count = parseInt('' + node.parent.count, 10) + count;
      const id = node.parent.id;
      if (flattedQueuesTree[id]) {
        this.propagateCounts(flattedQueuesTree[id], count, flattedQueuesTree);
      }
    }
  }

  protected resetCounts(flattedQueuesTree: FlatTreeNode): void {
    Object.keys(flattedQueuesTree).forEach((key) => {
      const node = flattedQueuesTree[key];
      node.self.count = 0;
      if (node.self.office != null) {
        node.self.amount = 0;
      }
    });
  }

  protected putCounts(
    counts: OfficesCounts,
    flattedQueuesTree: FlatTreeNode
  ): void {
    this.resetCounts(flattedQueuesTree);
    Object.keys(flattedQueuesTree).forEach((key) => {
      const node = flattedQueuesTree[key];
      const id = key.toLowerCase().split('/');
      if (counts[id[0]] != null) {
        let countsItem = counts[id[0]];

        if (node.self.office != null) {
          if (id.length > 1 && countsItem.offices[id[1]] != null) {
            countsItem = countsItem.offices[id[1]];
            node.self.count = countsItem.count;
            node.self.amount = countsItem.amount;
          }
        } else {
          // propagate counts only when not office
          node.self.count = countsItem.count;
          this.propagateCounts(node, countsItem.count, flattedQueuesTree);
        }
      }
    });
  }
}
