import { TranslateService } from '@ngx-translate/core';
import { FileMimeType } from '../../../shared/models/enums/file-mime-type.enum';
import { Injectable } from '@angular/core';

@Injectable()
export class FileTranslationService {
    private prefix = 'file.mimetype.';

    constructor(private translationService: TranslateService) {
    }

    translate(mimeType: FileMimeType): string {
        const key = this.prefix + mimeType;
        return this.translationService.instant(key);
    }
}
