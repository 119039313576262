<ng-template #complexConditionTemplate let-condition="condition">

  <div [appWatch]="condition.conditions.length" (valueChanged)="onConditionsChange($event.newValue, $event.oldValue, condition)"></div>

  <div class="dh-complex-condition">

    <button *ngIf="condition.conditions.length > 1"
            azure-id="change-operator"
            class="operator"
            mat-button
            [ngClass]="{ 'field-changed': condition.logicalOperatorChanged }"
            (click)="changeOperator(condition)">
      {{ condition.logicalOperator }}
    </button>

    <div class="conditions">

      <span *ngIf="condition.conditions.length > 1" class="condition-top-border"></span>

      <div class="condition-list"
           cdkDropList
           [cdkDropListData]="condition"
           [cdkDropListConnectedTo]="dropLists?.toArray()"
           [cdkDropListEnterPredicate]="allowDropPredicate"
           (cdkDropListDropped)="onDrop($event)">
        
        <div *ngFor="let childCondition of condition.conditions"
             class="condition-box"
             cdkDrag
             [cdkDragData]="childCondition"
             [cdkDragDisabled]="condition.conditions.length <= 1 || ruleConditionsService.checkedConditionsCount > 0"
             (cdkDragMoved)="onDragMove($event)"
             (cdkDragReleased)="onDragRelease($event)">

          <div class="condition-drag-placeholder" *cdkDragPlaceholder></div>

          <ng-container [ngTemplateOutlet]="childCondition.conditions ? complexConditionTemplate: simpleConditionTemplate"
                        [ngTemplateOutletContext]="{ condition : childCondition }">
          </ng-container>

          <ng-template #simpleConditionTemplate let-condition="condition">
            <app-simple-condition [simpleCondition]="condition">
              <ng-template #dragHandle>
                <span cdkDragHandle
                      mat-button
                      color="primary"
                      azure-id="bar"
                      class="condition-icon"
                      tabindex="-1"
                      [ngClass]="{ 'drag-disabled': ruleConditionsService.checkedConditionsCount > 0 }">
                  <app-fn-ui-icon icon="bars"></app-fn-ui-icon>
                </span>
              </ng-template>
            </app-simple-condition> 
          </ng-template>

        </div>
      </div>

      <span *ngIf="condition.conditions.length > 1" class="condition-bottom-border"></span>

    </div>
  </div>

</ng-template>

<ng-container [ngTemplateOutlet]="complexConditionTemplate"
              [ngTemplateOutletContext]="{ condition: complexCondition }">
</ng-container>
