import { Injectable } from '@angular/core';
import { ITranslationCache } from './translation-cache.interface';
import { AppConfigService } from '../../../config/services/app-config.service';
import { BuildVersion } from '../../../config/models';

@Injectable()
export class TranslationCacheService implements ITranslationCache {

  private readonly translationKey = 'translation';
  private readonly uiBuildVersionKey = 'ui-build-version';
  private readonly translationLanguageKey = 'translation-language';

  constructor(private appConfigService: AppConfigService) { }

  get(languageId: string): any {
    const uiVersionJson = localStorage.getItem(this.uiBuildVersionKey);
    const language = localStorage.getItem(this.translationLanguageKey);
    if (uiVersionJson && language) {
      const uiBuildVersion = JSON.parse(uiVersionJson) as BuildVersion;
      if (this.isCorrectTranslation(uiBuildVersion, language, languageId)) {
        const translationJson = localStorage.getItem(this.translationKey);
        if (translationJson) {
          return JSON.parse(translationJson).translation;
        }
      }
    }
    return null;
  }

  set(languageId: string, translation: any): void {
    const uiBuildVersion = this.getUiBuildVersion();
    if (uiBuildVersion) {
      localStorage.setItem(this.translationKey, JSON.stringify({ translation }));
      localStorage.setItem(this.uiBuildVersionKey, JSON.stringify(uiBuildVersion));
      localStorage.setItem(this.translationLanguageKey, languageId);
    }
  }

  private isCorrectTranslation(uiBuildVersion: BuildVersion, languageId: string, expectedLanguageId: string): boolean {
    if (languageId !== expectedLanguageId) {
      return false;
    }
    const currentUiBuildVersion = this.getUiBuildVersion();
    return currentUiBuildVersion && uiBuildVersion.commitId === currentUiBuildVersion.commitId;
  }

  private getUiBuildVersion(): BuildVersion {
    return this.appConfigService.uiBuildVersion;
  }

}
