export const PartyValidations = {
  'DEPARTMENT': 'CSTMR_VALIDATIONS-DEPARTMENT',
  'OFFICE': 'CSTMR_VALIDATIONS-OFFICE',
  'REC_STATUS': 'CSTMR_VALIDATIONS-REC_STATUS',
  'PROFILE_CHANGE_STATUS': 'CSTMR_VALIDATIONS-PROFILE_CHANGE_STATUS',
  'EFFECTIVE_DATE': 'EFFECTIVE_DATE',
  'VALIDATION_NM': 'CSTMR_VALIDATIONS-VALIDATION_NM',
  'DESCRIPTION': 'CSTMR_VALIDATIONS-DESCRIPTION',
  'VLD_MSG_TYPES': 'CSTMR_VALIDATIONS-VLD_MSG_TYPES',
  'VLD_MSG_TYPES_ACTION': 'CSTMR_VALIDATIONS-VLD_MSG_TYPES_ACTION',
  'VLD_MSG_SUB_TYPES': 'CSTMR_VALIDATIONS-VLD_MSG_SUB_TYPES',
  'VLD_MSG_SUB_TYPES_ACTION': 'CSTMR_VALIDATIONS-VLD_MSG_SUB_TYPES_ACTION',
  'STALE_DT_CHECK': 'CSTMR_VALIDATIONS-STALE_DT_CHECK',
  'WEEKEND_DT_CHECK': 'CSTMR_VALIDATIONS-WEEKEND_DT_CHECK',
  'FUTURE_DT_CHECK': 'CSTMR_VALIDATIONS-FUTURE_DT_CHECK',
  'FUTURE_DT_CHECK_EXTNSN': 'CSTMR_VALIDATIONS-FUTURE_DT_CHECK_EXTNSN',
  'DUP_BATCH_CHECK': 'CSTMR_VALIDATIONS-DUP_BATCH_CHECK',
  'DUP_BATCH_CHECK_TIME_FRAME': 'CSTMR_VALIDATIONS-DUP_BATCH_CHECK_TIME_FRAME',
  'DUP_ITEM_CHECK': 'CSTMR_VALIDATIONS-DUP_ITEM_CHECK',
  'DUP_ITEM_CHECK_TIME_FRAME': 'CSTMR_VALIDATIONS-DUP_ITEM_CHECK_TIME_FRAME',
  'TIME_STAMP': 'TIME_STAMP',
  'UID': 'VIRTUAL-UID',
  'PROFILE_UPDATE_PK': 'PROFILE_UPDATE_PK',
  'PU_TIME_STAMP': 'PU_TIME_STAMP',
  'NOTE': 'NOTE'
};
