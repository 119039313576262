import { Component, OnInit, Injector, Inject } from '@angular/core';
import { FormatsService, DatepickerComponent } from '@fgpp-ui/components';
import dayjs from 'dayjs';
import { MIN_MAX_DATE_FORMAT, SERVER_TIME_FORMAT } from '../../../core/consts/general.const';
import { IEndpoint } from '../../../core/interfaces/endpoint.interface';
import { IValueChange } from '../../../core/interfaces/form-value-change.interface';
import { BFOnFormLoadComplete, BFOnValueChange, BFProfileOnSave } from '../../../core/interfaces/sdk-profile-hooks.interface';
import { CutoffTimesProfileProviders, PROFILE_MODEL } from '../../../core/templates/profile-form.template.providers';
import { AbstractProfileFormComponent } from '../../abstract/abstract-profile/abstract-profile-form.component';
import { ProfileActions } from '../../enums/profile-actions.enum';
import { ProfileMode } from '../../enums/profile-mode.enum';
import { CutoffTimesModel } from '../../models/cutoff-times.model';


@Component({
  selector: 'app-cutoff-times',
  templateUrl: '../../../core/templates/profile-form.template.html',
  styleUrls: ['../../../core/templates/profile-form.template.scss'],
  providers: CutoffTimesProfileProviders
})
export class CutoffTimesComponent extends AbstractProfileFormComponent implements OnInit, BFOnFormLoadComplete, BFOnValueChange, BFProfileOnSave {

  constructor(protected injector: Injector,
              private formatsService: FormatsService,
              @Inject(PROFILE_MODEL) private cutoffTimesModel: CutoffTimesModel) {
    super(injector);
  }


  fgppFormLoadComplete(): void {
    this.setExceptionDateCheckbox();
    this.setClientTimeFormattedValue(this.cutoffTimesModel.INTERIM_CUTOFF);
    this.setClientTimeFormattedValue(this.cutoffTimesModel.FINAL_CUTOFF);
  }

  fgppValueChanges(change: IValueChange): void {
    switch (change.control) {
      case this.cutoffTimesModel.V_EXCEPTION_DATE:
        this.onExceptionDateCheckboxChange(change.value.inputValue);
        break;
      case this.cutoffTimesModel.EXP_FROM_DATE:
        this.onFromDateChange(change.value.inputValue);
        break;
      case this.cutoffTimesModel.EXP_TO_DATE:
        this.validateExceptionDates();
        break;
      case this.cutoffTimesModel.INTERIM_CUTOFF:
      case this.cutoffTimesModel.FINAL_CUTOFF:
        this.validateCutoffTime();
        break;
      }
  }

  fgppOnSave(): boolean {
    return this.validateExceptionDates() && this.validateCutoffTime();
  }

  protected getServerActionRequestBody(endpoint: IEndpoint, buttonId: string): any {
    const body = super.getServerActionRequestBody(endpoint, buttonId);
    const action = ProfileActions[buttonId];
    if (action === ProfileActions.CREATE || action === ProfileActions.SAVE || action === ProfileActions.APPROVE) {
      this.setDefaultTimeIndicator(body);
      this.setServerTimeFormattedValue(body, this.cutoffTimesModel.INTERIM_CUTOFF);
      this.setServerTimeFormattedValue(body, this.cutoffTimesModel.FINAL_CUTOFF);
    }
    return body;
  }

  private setExceptionDateCheckbox(): void {
    const defaultTime = this.getData()[this.cutoffTimesModel.DEFAULT_IND];
    const exceptionDate = defaultTime === '0' ? '1' : '0';
    this.formMgr.get(this.cutoffTimesModel.V_EXCEPTION_DATE).setValue(exceptionDate);
    this.onExceptionDateCheckboxChange(exceptionDate);
  }

  private onExceptionDateCheckboxChange(value: '0' | '1'): void {
    if (value === '1') {
      if (this.mode === ProfileMode.CREATE) {
        this.formMgr.get(this.cutoffTimesModel.EXP_FROM_DATE).enable();
        this.formMgr.get(this.cutoffTimesModel.EXP_TO_DATE).enable();
      }
      this.formMgr.get(this.cutoffTimesModel.EXP_FROM_DATE).markAsRequired();
      this.formMgr.get(this.cutoffTimesModel.EXP_TO_DATE).markAsRequired();
    } else {
      this.formMgr.get(this.cutoffTimesModel.EXP_FROM_DATE).disable().markAsOptional().reset();
      this.formMgr.get(this.cutoffTimesModel.EXP_TO_DATE).disable().markAsOptional().reset();
    }
  }

  private onFromDateChange(value: string): void {
    if (value) {
      const fromDate = dayjs(+value).format(MIN_MAX_DATE_FORMAT);
      const datepickerComponent = this.formMgr.get(this.cutoffTimesModel.EXP_TO_DATE).getLayoutControl() as DatepickerComponent;
      datepickerComponent.controlData.minDate = fromDate;
      this.validateExceptionDates();
    }
  }

  private validateExceptionDates(): boolean {
    const fromDate = +this.formMgr.get(this.cutoffTimesModel.EXP_FROM_DATE).getValue();
    const toDate = +this.formMgr.get(this.cutoffTimesModel.EXP_TO_DATE).getValue();
    if (fromDate && toDate && fromDate > toDate) {
      this.notificationService.error(this.translate.instant(`business-framework.profiles.${this.profileId}.errors.exception-dates-comparison`));
      return false;
    }
    return true;
  }

  private validateCutoffTime(): boolean {
    if (!this.cutoffTimesModel.INTERIM_CUTOFF) {
      return true;
    }
    const interimCutoffTime = this.formMgr.get(this.cutoffTimesModel.INTERIM_CUTOFF).getValue();
    const finalCutoffTime = this.formMgr.get(this.cutoffTimesModel.FINAL_CUTOFF).getValue();
    if (interimCutoffTime && finalCutoffTime && this.formatsService.toTime(interimCutoffTime) > this.formatsService.toTime(finalCutoffTime)) {
      this.notificationService.error(this.translate.instant(`business-framework.profiles.${this.profileId}.errors.cutoff-time-comparison`));
      return false;
    }
    return true;
  }

  private setClientTimeFormattedValue(fieldName: string): void {
    if (!fieldName) {
      return;
    }
    const value = this.formMgr.get(fieldName).getValue();
    if (value && dayjs(value).isValid()) {
      const time = dayjs(value).format(this.userPreferences.timeFormat);
      this.formMgr.get(fieldName).setValue(time);
    }
  }

  private setServerTimeFormattedValue(body: any, fieldName: string): void {
    if (body[fieldName]) {
      body[fieldName] = this.formatsService.toTime(body[fieldName]).format(SERVER_TIME_FORMAT);
    }
  }

  private setDefaultTimeIndicator(body: any): void {
    body[this.cutoffTimesModel.DEFAULT_IND] = body[this.cutoffTimesModel.V_EXCEPTION_DATE] === '0' ? '1' : '0';
    delete body[this.cutoffTimesModel.V_EXCEPTION_DATE];
  }

}
