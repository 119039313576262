import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Injectable()
export class PopOutHelperService {
  private readonly popoutURL = 'popout/';

  constructor(private router: Router, private location: Location) { }

  public createUrl(stateParams: any, segments?: string[]): string {
    if(!segments) {
      const url = this.router.url;
      const index = document.URL.indexOf('#');
      const baseUrl = document.URL.substring(0, index);
      if (baseUrl.indexOf(this.popoutURL) > -1) {
        return baseUrl + url;
      }
      return baseUrl + this.popoutURL + url;
    }
    const tree = this.router.createUrlTree(segments, {
      queryParams: stateParams
    })
    return this.location.prepareExternalUrl(this.router.serializeUrl(tree));
  }

  public isPastedPopOut(): boolean {
    return document.URL.includes('pop-out');
  }
}
