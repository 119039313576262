import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from '@fgpp-ui/components';
import { FnUiDatetimePickerComponent } from './fn-ui-datetime-picker.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule
  ],
  declarations: [
    FnUiDatetimePickerComponent
  ],
  exports: [
    FnUiDatetimePickerComponent
  ]
})
export class FnUiDatetimePickerModule { }
