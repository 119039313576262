import { Injectable } from '@angular/core';
import { SearchExpression } from '../models/classes/search-expression.model';
import { ISearchCriteriaService } from './interfaces/search-criteria-service.interface';
import { SearchCriteriaService } from './search-criteria.service';

@Injectable({
  providedIn: 'root'
})
export class SearchExpressionService implements ISearchCriteriaService {

  constructor(private searchCriteriaService: SearchCriteriaService) { }

  toString(searchExpression: SearchExpression): string {
    const type = searchExpression.class;
    const leftOperand = this.searchCriteriaService.toString(searchExpression.leftOperand);
    const logicalOperator = searchExpression.logicalOperator;
    const rightOperand = this.searchCriteriaService.toString(searchExpression.rightOperand);
    return `<@${type}:{${leftOperand}} ${logicalOperator} {${rightOperand}}>`;
  }

  toHumanString(searchExpression: SearchExpression): string {
    const leftOperand = this.searchCriteriaService.toHumanString(searchExpression.leftOperand);
    const logicalOperator = searchExpression.logicalOperator;
    const rightOperand = this.searchCriteriaService.toHumanString(searchExpression.rightOperand);
    return `{${leftOperand}} ${logicalOperator} {${rightOperand}}`;
  }

}
