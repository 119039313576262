import { Injectable } from '@angular/core';
import {PaymentType} from "../components/create-payment/payment-type.model";
import {GridSearchResponse} from "../../grid/models/grid-search-response.model";
import {NavigationService} from "../../core/navigation/services/navigation.service";
import {HttpClient} from "@angular/common/http";
import {Observable, Subscriber} from "rxjs";
import {FED_PAYMENT_TYPES_ACTIONS} from "../models/consts/fed-payment-types-actions.const";

@Injectable({
  providedIn: 'root'
})
export class FedPaymentTypesService {

  private readonly fedMopsUrl = '/ui/profiles/32?MOP-MOP=FED&& MOP-REC_STATUS=AC&MOP-OFFICE=';

  private _fedMopMediaMap= new Map<String,String>();
  private paymentTypesOriginal: PaymentType[];

  readonly MOP_OFFICE = 'MOP-OFFICE';
  readonly MOP_MEDIA = 'MOP-MEDIA';
  readonly ISO_MEDIA = 'ISO';
  readonly GLOBAL_OFFICE = '***';

  get fedMopMediaMap(): Map<String, String> {
    return this._fedMopMediaMap;
  }

  constructor(protected navigationService: NavigationService,
              protected http: HttpClient) { }

  initPaymentTypes(paymentType: PaymentType[]){

    if(!this.paymentTypesOriginal){
      this.paymentTypesOriginal = paymentType;
    }
  }

  getPaymentTypeByMop(office:String){
    let media = this._fedMopMediaMap.get(office);
    if(!media){
      media = this._fedMopMediaMap.get(this.GLOBAL_OFFICE);
    }
    if(media === this.ISO_MEDIA){
      return this.getFilteredPaymentTypeForFedIso();
    }
    return this.paymentTypesOriginal;
  }

   initFedMopMediaMap(){
    if(this.fedMopMediaMap.size){
      return;
    }
    let allowedOffices =this.navigationService.getAllowedOffices();
    let officeArr = allowedOffices.map(item=>item.name);
    officeArr.forEach(office=>this.fedMopMediaMap.set(office,""));
    let officeListStr = officeArr.join(",");
    this.getFedMops(officeListStr).pipe().subscribe((res:GridSearchResponse)=>{
      let rows = res.rows;
      rows.forEach(row=>this.fedMopMediaMap.set(row[this.MOP_OFFICE],row[this.MOP_MEDIA]));
    });
  }

  private getFedMops(officeListStr): Observable<Object>{
    return this.http.get( this.fedMopsUrl+officeListStr);
  }

  protected getFilteredPaymentTypeForFedIso(){
    let paymentTypes = this.paymentTypesOriginal;
    return paymentTypes.filter(item => !FED_PAYMENT_TYPES_ACTIONS.includes(item.action));
  }

}
