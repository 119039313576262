import {
  StandingOrderPaymentType, StandingOrderActivationStatus,
  StandingOrderEndsWith,
  StandingOrderFrequencyPattern,
  StandingOrderTemplateType
} from '../../enums/standing-order-field-mapping.enum';
import {StandingOrder} from '../../models/standing-order.model';
import {Injectable, Injector} from '@angular/core';
import {FormManager, FormService} from "@fgpp-ui/components";

@Injectable()
export class StandingOrderService {
  private formService: FormService;

  constructor(protected injector: Injector) {
    this.formService = injector.get(FormService);
  }

  public applyFrequency(frequency, reset: boolean = false) {
    const formMgr = this.formService.getFormManager();
    switch (frequency) {
      case StandingOrderFrequencyPattern.DAILY : {
        formMgr.get(StandingOrder.DAY_OF_WEEK).disable().markAsOptional();
        formMgr.get(StandingOrder.DAY_OF_MONTH).disable().markAsOptional();
        formMgr.get(StandingOrder.MONTH_NUMBER).disable().markAsOptional();
        if (reset) {
          formMgr.get(StandingOrder.DAY_OF_WEEK).reset();
          formMgr.get(StandingOrder.DAY_OF_MONTH).reset();
          formMgr.get(StandingOrder.MONTH_NUMBER).reset();
        }
        break;
      }
      case StandingOrderFrequencyPattern.WEEKLY : {
        formMgr.get(StandingOrder.DAY_OF_WEEK).enable().markAsRequired();
        formMgr.get(StandingOrder.DAY_OF_MONTH).disable().markAsOptional();
        formMgr.get(StandingOrder.MONTH_NUMBER).disable().markAsOptional();
        if (reset) {
          formMgr.get(StandingOrder.DAY_OF_WEEK).reset();
          formMgr.get(StandingOrder.DAY_OF_MONTH).reset();
          formMgr.get(StandingOrder.MONTH_NUMBER).reset();
        }
        break;
      }
      case StandingOrderFrequencyPattern.MONTHLY : {
        formMgr.get(StandingOrder.DAY_OF_WEEK).disable().markAsOptional();
        formMgr.get(StandingOrder.DAY_OF_MONTH).enable().markAsRequired();
        formMgr.get(StandingOrder.MONTH_NUMBER).disable().markAsOptional();
        if (reset) {
          formMgr.get(StandingOrder.DAY_OF_WEEK).reset();
          formMgr.get(StandingOrder.DAY_OF_MONTH).reset();
          formMgr.get(StandingOrder.MONTH_NUMBER).reset();
        }
        break;
      }
      case StandingOrderFrequencyPattern.YEARLY : {
        formMgr.get(StandingOrder.DAY_OF_WEEK).disable().markAsOptional();
        formMgr.get(StandingOrder.DAY_OF_MONTH).enable().markAsRequired();
        formMgr.get(StandingOrder.MONTH_NUMBER).enable().markAsRequired();
        if (reset) {
          formMgr.get(StandingOrder.DAY_OF_WEEK).reset();
          formMgr.get(StandingOrder.DAY_OF_MONTH).reset();
          formMgr.get(StandingOrder.MONTH_NUMBER).reset();
        }
        break;
      }
    }
  }

  public applyRecurrenceRange(beforeAfter: any) {
    const formMgr = this.formService.getFormManager();
    if (beforeAfter === StandingOrderEndsWith.END_AFTER) {
      formMgr.get(StandingOrder.END_BY_DATE).hide().markAsOptional().reset();
      formMgr.get(StandingOrder.NUMBER_OF_OCCURRENCES).show().markAsRequired();
    } else if (beforeAfter === StandingOrderEndsWith.END_BY) {
      formMgr.get(StandingOrder.END_BY_DATE).show().markAsRequired();
      formMgr.get(StandingOrder.NUMBER_OF_OCCURRENCES).hide().markAsOptional().reset();
    }
  }

  public applyPrimaryTemplate(primaryTemplate: any) {
    const formMgr = this.formService.getFormManager();
    if (primaryTemplate === StandingOrderTemplateType.CT) {
      formMgr.get(StandingOrder.CT_TEMPLATE_MID).enable().markAsRequired();
      if (formMgr.get(StandingOrder.PMNT_TYPE).getValue() !== StandingOrderPaymentType.SWEEP) {
        formMgr.get(StandingOrder.DD_TEMPLATE_MID).disable().markAsOptional();
      } else {
        formMgr.get(StandingOrder.DD_TEMPLATE_MID).enable().markAsOptional();
      }

    } else if (primaryTemplate === StandingOrderTemplateType.DD) {
      formMgr.get(StandingOrder.DD_TEMPLATE_MID).enable().markAsRequired();
      if (formMgr.get(StandingOrder.PMNT_TYPE).getValue() !== StandingOrderPaymentType.SWEEP) {
        formMgr.get(StandingOrder.CT_TEMPLATE_MID).disable().markAsOptional();
      } else {
        formMgr.get(StandingOrder.CT_TEMPLATE_MID).enable().markAsOptional();
      }
    }
  }

  public applyPaymentType(paymentType: any) {
    const formMgr = this.formService.getFormManager();
    if (paymentType === StandingOrderPaymentType.ORDER) {
      formMgr.get(StandingOrder.AMT_SRC).reset().markAsOptional().disable();
      formMgr.get(StandingOrder.CT_MAX_SWEEP_AMT).reset().disable();
      formMgr.get(StandingOrder.CT_MIN_SWEEP_AMT).reset().disable();
      formMgr.get(StandingOrder.DD_MAX_SWEEP_AMT).reset().disable();
      formMgr.get(StandingOrder.DD_MIN_SWEEP_AMT).reset().disable();
      formMgr.get(StandingOrder.MIN_REMAIN_BAL).reset().markAsOptional().disable();
    } else if (paymentType === StandingOrderPaymentType.SWEEP) {
      formMgr.get(StandingOrder.AMT_SRC).markAsRequired();
      formMgr.get(StandingOrder.MIN_REMAIN_BAL).markAsRequired();
      formMgr.get(StandingOrder.CT_TEMPLATE_MID).enable();
      formMgr.get(StandingOrder.DD_TEMPLATE_MID).enable();
      formMgr.get(StandingOrder.AMT_SRC).enable();
      formMgr.get(StandingOrder.CT_MAX_SWEEP_AMT).enable();
      formMgr.get(StandingOrder.CT_MIN_SWEEP_AMT).enable();
      formMgr.get(StandingOrder.DD_MAX_SWEEP_AMT).enable();
      formMgr.get(StandingOrder.DD_MIN_SWEEP_AMT).enable();
      formMgr.get(StandingOrder.MIN_REMAIN_BAL).enable();
    }
  }

  public isAmountValid(minAmountField: any, maxAmountField: any): boolean {
    const formMgr = this.formService.getFormManager();
    const minAmount = formMgr.get(minAmountField).getValue();
    const maxAmount = formMgr.get(maxAmountField).getValue();
    return !(minAmount && maxAmount && maxAmount <= minAmount);
  }

  public initializeFields() {
    const formMgr = this.formService.getFormManager();
    formMgr.get(StandingOrder.SO_CLOSED).setValue(StandingOrderActivationStatus.OPEN);
    formMgr.get(StandingOrder.PMNT_TYPE).setValue(StandingOrderPaymentType.ORDER);
    this.applyPaymentType(formMgr.get(StandingOrder.PMNT_TYPE).getValue());
    formMgr.get(StandingOrder.PRIMARY).setValue(StandingOrderTemplateType.CT);
    this.applyPrimaryTemplate(formMgr.get(StandingOrder.PRIMARY).getValue());
    formMgr.get(StandingOrder.V_BEFORE_AFTER).setValue(StandingOrderEndsWith.END_AFTER);
    this.applyRecurrenceRange(formMgr.get(StandingOrder.V_BEFORE_AFTER).getValue());
  }

  public setCTTemplateData(templateData: any) {
    const formMgr = this.formService.getFormManager();
    if (templateData) {
      formMgr.get(StandingOrder.V_CT_TEMPLATE_NM).setValue(templateData['MINF.P_TEMPLATE_NM']);
      formMgr.get(StandingOrder.ACC_NO).setValue(templateData['MINF.P_DBT_ACCT_NB']);
      formMgr.get(StandingOrder.ACC_CCY).setValue(templateData['MINF.P_DBT_ACCT_CCY']);
    }
  }

  public setDDTemplateData(templateData: any) {
    const formMgr = this.formService.getFormManager();
    if (templateData) {
      formMgr.get(StandingOrder.V_DD_TEMPLATE_NM).setValue(templateData['MINF.P_TEMPLATE_NM']);
      formMgr.get(StandingOrder.ACC_NO).setValue(templateData['MINF.P_CDT_ACCT_NB']);
      formMgr.get(StandingOrder.ACC_CCY).setValue(templateData['MINF.P_CDT_ACCT_CCY']);
    }
  }
}
