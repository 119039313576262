import { Component, OnInit, Input, Output, EventEmitter, Type } from '@angular/core';
import { FileAttachmenConfiguration } from './models/file-attachment-configuration.model';
import { FileStatus } from '../file-attachment/file-attachment-form/models/enums/file-status.enum';
import { FileAttachmentManagerMapperService } from './services/file-attachment-manager-mapper.service';
import { FileAttachmentPermissionMode } from './models/enums/file-attachment-permission-mode.enum';
import { FileAttachmentRow } from '../file-attachment-table/file-attachment-row/models/file-attachment-row.model';
import { FileAttachment } from '../models/file-attachment.model';
import { FileAttachmentManagerTexts } from './models/file-attachment-manager-texts.model';


@Component({
  selector: 'lib-file-attachment-manager',
  templateUrl: './file-attachment-manager.component.html',
  styleUrls: ['./file-attachment-manager.component.scss']
})
export class FileAttachmentManagerComponent implements OnInit {
  @Input() fileAttachmentConfig: FileAttachmenConfiguration;
  @Input()
  set fileAttachments(fileAttachments: Array<FileAttachment>) {
    if (!fileAttachments) {
      this.fileAttachmentsList = [];
      return;
    }
    fileAttachments.forEach(fileAttachment => {
      this.fileAttachmentsList.push(this.fileAttachmentManagerMapperService
        .mapFileAttachmentToFileAttachmentRow(fileAttachment));
    });
  }
  @Input() texts: FileAttachmentManagerTexts;
  @Input() loaderComponent: Type<any>;

  @Output() attachmentsChanged: EventEmitter<boolean> = new EventEmitter<true>();

  permissions: FileAttachmentPermissionMode;
  fileAttachmentsList: Array<FileAttachmentRow> = new Array<FileAttachmentRow>();
  fileStatus: FileStatus;
  isUploadInProgress: boolean;
  dateFormatParser: Function;

  constructor(
    private fileAttachmentManagerMapperService: FileAttachmentManagerMapperService) {
  }

  ngOnInit() {
    this.permissions = this.fileAttachmentConfig.fileAttachmentManagerConfig.mode;
    this.dateFormatParser = this.fileAttachmentConfig.fileAttachmentManagerConfig.dateFormatParser;
  }

  initAttachmentList(fileAttachmentList) {
    fileAttachmentList.forEach(file => file.uploaded = false);
    return fileAttachmentList;
  }

  downloadClicked(fileAttachmentRow) {
    this.getFile(fileAttachmentRow.fileId, fileAttachmentRow.fileName);
  }

  clearClicked(fileAttachmentRow) {
    const index = this.fileAttachmentsList.indexOf(fileAttachmentRow);
    if (index !== -1) {
      this.fileAttachmentsList.splice(index, 1);
    }
    this.attachmentsChanged.emit();
  }

  formStatusChanged(fileStatus) {
    this.fileStatus = fileStatus;
  }

  setIsUploadInProgress(isUploadInProgress) {
    this.isUploadInProgress = isUploadInProgress;
  }

  uploadedFile(uploadedFileAttachmentsDetails) {
    const rowAttachment = this.fileAttachmentManagerMapperService
          .mapFileAttachmentToFileAttachmentRow(uploadedFileAttachmentsDetails, true);
    this.fileAttachmentsList.unshift(rowAttachment);
    this.attachmentsChanged.emit();
  }

  private getFile(fileId: string, fileName: string) {
    this.fileAttachmentConfig.fileAttachmentManagerConfig.fileAttachmentApiService.getFileDownload(fileId).subscribe((res: any) => {
      this.downLoadFile(res, res.type, fileName);
    });
  }

  private downLoadFile(data: any, type: string, fileName: string) {
    const a = document.createElement('a');
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
  }
}
