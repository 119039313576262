import {Component, Injector} from '@angular/core';
import {
  SystemParameterProfileProviders
} from '../../../core/templates/profile-form.template.providers';
import {
  BFOnFormLoadComplete, BFProfileOnSave,
} from '../../../core/interfaces/sdk-profile-hooks.interface';
import { SystemParametersService } from './services/system-parameters.service';
import { SystemParametersCustomReducer } from './system-parameters-custom.reducer';
import {AbstractProfileFormComponent} from "../../abstract/abstract-profile/abstract-profile-form.component";

@Component({
    selector: 'app-systpar-profile',
    templateUrl: '../../../core/templates/profile-form.template.html',
    styleUrls: ['../../../core/templates/profile-form.template.scss'],
    providers: SystemParameterProfileProviders
})
export class SystemParametersComponent extends AbstractProfileFormComponent implements BFOnFormLoadComplete, BFProfileOnSave {

    constructor(protected injector: Injector,
                private systemParametersService: SystemParametersService,
                ) {
        super(injector);
    }

    fgppFormLoadComplete(): void {
        this.systemParametersService.hideFields();
        this.systemParametersService.showRelevantValueField();
    }

    fgppOnSave(): boolean {
        this.systemParametersService.convertFieldsValueToString();
        return true;
    }

    fgppOnInit(): void {
      this.setCustomReducer(new SystemParametersCustomReducer());
    }
}
