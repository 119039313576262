import {inject, Injectable} from "@angular/core";
import {CountryPickerModel} from "@fgpp-ui/components";
import {BFPaymentTypes} from "../models/enums/bf-payment-types.enum";
import {Params, Router} from "@angular/router";
import {BfMessageNavigationService} from "./bf-message-navigation.service";

@Injectable()
export class BfCountryPickerService {

  private messageNavigationService: BfMessageNavigationService = inject(BfMessageNavigationService)

  navigateToSelectedCountryState(countryInfo: CountryPickerModel, paymentType: BFPaymentTypes, stateParams: Params, userData: Record<string, any>) {
    const targetPaymentType = this.getPaymentType(countryInfo);
    if (paymentType === targetPaymentType) {
      return;
    }
    const params = structuredClone(stateParams);
    params.paymentType = targetPaymentType;
    this.messageNavigationService.navigateToTargetState(params, {countryInfo, userData});
  }

  getPaymentType(action: CountryPickerModel): BFPaymentTypes {
    if (action.fxEnabled) {
      return BFPaymentTypes.PACS_008_US_INTERNATIONAL_FX;
    } else if (action.code !== 'US') {
      return BFPaymentTypes.PACS_008_US_INTERNATIONAL;
    } else {
      return BFPaymentTypes.PACS_008_US_DOMESTIC;
    }
  }
}
