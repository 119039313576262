
import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, fromEvent } from 'rxjs';
import { AuthenticationService } from '../../authentication/services/authentication.service';
import { SignOutService } from '../../authentication/services/sign-out.service';
import { MultiTabsManagerService } from '../multi-tabs/services/multi-tabs-manager.service';
import { GetSession, DuplicateSession, UpdateSession, DeleteSessionTokenWithoutAction, RestSessionStateWithoutAction } from '../store/session/action/session.action';
import { SESSIONACTION } from '../store/session/model/session-action.enum';
import { SessionStateWithActionModel } from '../store/session/model/session-state-with-action.model';
import { SessionStateModel } from '../store/session/model/session-state.model';

@Injectable()
export class SessionSyncService {

  source$: Observable<Event>;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private store: Store,
              private authenticationService: AuthenticationService,
              private multiTabsManagerService: MultiTabsManagerService,
              private signOutService: SignOutService) { }

  init() {
    this.source$ = fromEvent(window, 'storage');
    this.source$.pipe().subscribe(
      async ldata => {
        const storageEvent = ldata as StorageEvent;
        if (storageEvent.key === 'session' && storageEvent.storageArea === localStorage
          && storageEvent.newValue !== null) {
          const sessionStateWithAction: SessionStateWithActionModel = JSON.parse(storageEvent.newValue) as SessionStateWithActionModel;
          if (sessionStateWithAction.sessionAction) {
            if (sessionStateWithAction.sessionAction === SESSIONACTION.INFO && this.authenticationService.isUserDataSet) {
              // Some tab asked for the sessionStorage -> send it , store service will send this
              if (this.multiTabsManagerService.isMasterTab) {
                this.store.dispatch(new GetSession());
              }
            } else if (sessionStateWithAction.sessionAction === SESSIONACTION.CREATE && !this.authenticationService.isUserDataSet) {
              console.log(sessionStateWithAction);
              const sessionState: SessionStateModel = { token: sessionStateWithAction.token, userData: sessionStateWithAction.userData };
              this.store.dispatch(new DuplicateSession(sessionState));
              if (this.router.url.includes('user/signin') || this.router.url.includes('user/changePassword')) {
                this.router.navigate(['home']);
              }
              // const userDataInSessionStorage = sessionStorage.getItem('userData');
              // if (!userDataInSessionStorage && userData) {
              //  await this.updateSessionUserData(userData);
              // }
            } else if (sessionStateWithAction.sessionAction === SESSIONACTION.UPDATE) {
              this.store.dispatch(new UpdateSession(sessionStateWithAction.token));
            } else if (sessionStateWithAction.sessionAction === SESSIONACTION.DELETE_SESSION_TOKEN) {
              this.store.dispatch(new DeleteSessionTokenWithoutAction());
            } else if (sessionStateWithAction.sessionAction === SESSIONACTION.DELETE_SESSION_USER_DATA) {
              this.store.dispatch(new RestSessionStateWithoutAction());
              this.signOutService.cleanBeforeSignOut();
              this.signOutService.cleanAfterSignOut();
              this.signOutService.changeLocation(true);
            }
          }

        }
      }
    );
  }

}
