export class RuleCustomerLevelActionService {
  ruleName = null;
  associateTo = null;
  ruleAction = null;
  lastSelectedRule = null;
  lastSelectedRuleRow = null;
  customerLevelActionConst = 'CUSTOMER_LEVEL_ACTION';

  isCustomerLevelAction(lastSelectedRuleRow) {
    return lastSelectedRuleRow['PRULES-RULE_ACTION_UID'] === this.customerLevelActionConst;
  }

  openCustomerLevelActions(lastSelectedRuleRow, associateToProfileName) {
    this.lastSelectedRuleRow = lastSelectedRuleRow;
    this.associateTo = associateToProfileName;
  }

  isAssociatedLevelActionExist(rows) {
    let associatedLevelActionExist = false;
    rows.forEach(row => {
      if (row['PRULES-RULE_ACTION_UID'] === this.customerLevelActionConst) {
        associatedLevelActionExist = true;
      }
    })
    return associatedLevelActionExist;
  }
}
