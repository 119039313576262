<app-fn-ui-navigation-tree [treeConfig]="treeConfig"
                           [treeModel]="treeModel"
                           [selectedNode]="selectedNode"
                           (selectionChanged)="onSelectionChange($event)">

  <ng-template #node let-node="node">
    <div class="link-content" [ngClass]="{ 'is-full': node.count, 'is-office': node.office }">
      <span class="node-alias">{{ node.alias | translate }}</span>
      <span class="node-count">({{ node.count | ftNumber }})</span>
      <span class="office-data">{{ node.amount | ftNumber | ftRound }} {{ node.currency }}</span>
  </div>
  </ng-template>

</app-fn-ui-navigation-tree>