import { ISdkProfileFooterReducer } from '../interfaces/profile-footer-reducer.interface';
import { ButtonCategory } from '../../core/enums/button-category.enum';
import { IProfileFooter } from '../interfaces/profile-footer.interface';
import { ProfilesFooterService } from '../services/profiles-footer.service';
import { FgppButtonProps } from '../../core/utils/fgpp-button-props.utils';
import { IProfileLayoutSelectionMetadata } from '../interfaces/profile-layout-selection-metadata.interface';

export class EmptyProfileReducer implements ISdkProfileFooterReducer {
  public reduce(buttonCategory: ButtonCategory, footer: IProfileFooter, inputs: IProfileLayoutSelectionMetadata): IProfileFooter {
    const footerService = new ProfilesFooterService().setFooter(footer);
    const buttonList: FgppButtonProps[] = [];
    return footerService.retain(buttonCategory, buttonList).getFooter();
  }
}
