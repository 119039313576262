<div class="conditions-editor">

  <div [appWatch]="conditionsData | json" (valueChanged)="onConditionsDataChange()"></div>

  <div class="rules-conditions-pane-wrapper">
    <div class="rules-conditions-pane-title guided-tour">
      <span class="rules-conditions-pane-title">{{ 'rules.conditions_title' | translate }}</span>
    </div>

    <app-tour-item [tourId]="'rulesScreen'" [tourStepNumber]="2"></app-tour-item>
    <app-tour-item [tourId]="'rulesScreen'" [tourStepNumber]="6"></app-tour-item>

    <div class="rules-conditions-pane-actions">
      <button azure-id="edit"
              automation-id="rules-conditions-pane-actions-edit"
              mat-button
              color="primary"
              [title]="'rules.button.baseCondition' | translate"
              [disabled]="baseConditionDisabled || isReadOnly"
              (click)="convertToBaseCondition()">
        <app-fn-ui-icon icon="edit" fontweight="900"></app-fn-ui-icon>
        <app-tour-item [tourId]="'rulesScreen'" [tourStepNumber]="5"></app-tour-item>
      </button>

      <span class="rules-conditions-pane-actions-spacer"></span>
      <span class="rules-conditions-pane-actions-spacer"></span>
      <button azure-id="move-up"
              automation-id="rules-conditions-pane-actions-move-up"
              mat-button
              color="primary"
              [title]="'rules.button.moveUp' | translate"
              [disabled]="moveUpDisabled || isReadOnly"
              (click)="moveUp()">
        <app-fn-ui-icon icon="chevron_up" fontweight="900"></app-fn-ui-icon>
      </button>
      <button azure-id="move-down"
              automation-id="rules-conditions-pane-actions-move-down"
              mat-button
              color="primary"
              [title]="'rules.button.moveDown' | translate"
              [disabled]="moveDownDisabled || isReadOnly"
              (click)="moveDown()">
        <app-fn-ui-icon icon="chevron_down" fontweight="900"></app-fn-ui-icon>
      </button>
      <span class="rules-conditions-pane-actions-spacer"></span>
      <button azure-id="move-left"
              automation-id="rules-conditions-pane-actions-move-left"
              mat-button
              color="primary"
              [title]="'rules.button.moveLeft' | translate"
              [disabled]="moveLeftDisabled || isReadOnly"
              (click)="moveLeft()">
        <app-fn-ui-icon icon="arrow_left_circle" fontweight="900"></app-fn-ui-icon>
      </button>
      <button azure-id="move-right"
              automation-id="rules-conditions-pane-actions-move-right"
              mat-button
              color="primary"
              [title]="'rules.button.moveRight' | translate"
              [disabled]="moveRightDisabled || isReadOnly"
              (click)="moveRight()">
        <app-fn-ui-icon icon="arrow_right" fontweight="900"></app-fn-ui-icon>
      </button>
      <span class="rules-conditions-pane-actions-spacer"></span>
      <button azure-id="remove-condition"
              automation-id="rules-conditions-pane-actions-remove-condition"
              mat-button
              color="primary"
              [title]="'rules.button.remove' | translate"
              [disabled]="removeDisabled || isReadOnly"
              (click)="remove()">
        <app-fn-ui-icon icon="minus" fontweight="900"></app-fn-ui-icon>
      </button>
      <span class="rules-conditions-pane-actions-spacer"></span>
      <button azure-id="add-condition"
              automation-id="rules-conditions-pane-actions-add-condition"
              class="alert-btn-text"
              mat-button
              color="primary"
              [title]="'rules.button.add' | translate"
              [disabled]="addDisabled || isReadOnly"
              (click)="add()">
        <app-fn-ui-icon icon="plus" fontweight="900"></app-fn-ui-icon>
        <app-tour-item [tourId]="'rulesScreen'" [tourStepNumber]="1"></app-tour-item>
      </button>
    </div>
  </div>

  <div id="conditions-content-wrapper" class="conditions-content-wrapper">
    <fieldset class="conditions-content" [disabled]="isReadOnly">
      <app-complex-condition [complexCondition]="conditionsData"></app-complex-condition>
    </fieldset>
  </div>
</div>
