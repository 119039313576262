import {Component, OnInit} from '@angular/core';
import {AbstractProfileFormComponent} from '../../abstract/abstract-profile/abstract-profile-form.component';
import {PaymentAddress} from '../../models/payment-address.model';
import { ProfileFormTemplateProviders } from '../../../core/templates/profile-form.template.providers';
import { BFOnValueChange } from '../../../core/interfaces/sdk-profile-hooks.interface';
import { IValueChange } from '../../../core/interfaces/form-value-change.interface';

@Component({
  selector: 'app-payment-address',
  templateUrl: '../../../core/templates/profile-form.template.html',
  styleUrls: ['../../../core/templates/profile-form.template.scss'],
  providers: ProfileFormTemplateProviders
})
export class PaymentAddressComponent extends AbstractProfileFormComponent implements OnInit, BFOnValueChange {

  fgppValueChanges(change: IValueChange): void {
    if (change.control === PaymentAddress.NCC_CODE) {
      this.onNCCCodeChange();
    } else if (change.control === PaymentAddress.ACC_NO) {
      this.onAccountNumberChange();
    }
  }

  private onNCCCodeChange(): void {
    const nccCodeData = this.getDrilldownData(PaymentAddress.NCC_CODE);
    if (nccCodeData) {
      this.formMgr.get(PaymentAddress.NCC_TYPE).setValue(nccCodeData['NCC-NCC_TYPE_ISO']);
    }
  }

  private onAccountNumberChange(): void {
    const accountData = this.getDrilldownData(PaymentAddress.ACC_NO);
    if (accountData) {
      this.formMgr.get(PaymentAddress.CURRENCY).setValue(accountData['ACCOUNTS-CURRENCY']);
    }
  }
}
