import { Injectable } from '@angular/core';
import { NavigationService } from '../navigation/services/navigation.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Office } from '../../shared/models/office.model';
import { OfficeSelectorComponent } from '../components/office-selector/office-selector.component';
import { ModalSize } from '../../shared/fn-ui-modals/models/enums/modal-size.enum';

@Injectable({
  providedIn: 'root'
})
export class OfficeSelectorService {

  private allowedOffices: Array<Office>;

  constructor(private navigationService: NavigationService,
              private dialog: MatDialog) {}

   private dealWithOneOffice(withGlobal: boolean): Promise<string> {
      if (this.allowedOffices[0].name === '***' && !withGlobal) {
        console.error('only global office allowed, but unwanted');
        return Promise.reject('only global office allowed, but unwanted');
      } else {
        return Promise.resolve(this.allowedOffices[0].name);
      }
  }

   private openModal(withGlobal: boolean): Promise<string> {
    const config: MatDialogConfig = {
      width: ModalSize.SMALL,
      data: {
        withGlobal: withGlobal
      },
      panelClass: ['office-modal', 'gpp-ui-dialog-panel'],
      autoFocus: false,
      disableClose: true
    };
     return this.dialog.open(OfficeSelectorComponent, config).afterClosed().toPromise();
   }

  open(withGlobal = false): Promise<string> {
    this.allowedOffices = this.navigationService.getWriteOnlyOffices();
    if (!!this.allowedOffices) {
      switch (this.allowedOffices.length) {
        case 0:
          console.error('no offices allowed');
          return Promise.reject('no offices allowed');
        case 1:
          return this.dealWithOneOffice(withGlobal);
        default:
          return this.openModal(withGlobal);
      }
    } else {
      console.error('allowedOffices is undefined');
    }
  }
}
