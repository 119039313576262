import { Injectable } from '@angular/core';
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { FnUiDialogService } from '../../../shared/fn-ui-dialog/services/fn-ui-dialog.service';
import { BaseConditionFoundModalComponent } from '../components/base-condition-found-modal/base-condition-found-modal.component';
import { CreateBaseConditionModalComponent } from '../components/create-base-condition-modal/create-base-condition-modal.component';
import { DeleteBaseConditionModalComponent } from '../components/delete-base-condition-modal/delete-base-condition-modal.component';
import { RuleUniqueData } from '../models';

@Injectable()
export class RuleModalsService {

  constructor(private dialogService: FnUiDialogService,
              private translateService: TranslateService) {}

  openCreateBaseConditionModal(): MatDialogRef<CreateBaseConditionModalComponent> {
    const config: MatDialogConfig = {
      width: '500px',
      data: {
        ruleName: '',
        description: '',
        maxFieldsLength: 250
      }
    };
    return this.dialogService.open(CreateBaseConditionModalComponent, config);
  }

  openBaseConditionFoundModal(baseConditionName: string, mode: string): MatDialogRef<BaseConditionFoundModalComponent> {
    let answerText = '';
    let message = '';
    let acceptButton = '';

    if (mode === 'replaceMode') {
      message = 'rules.verification_base_condition.replaseNotification';
      acceptButton = 'rules.modal_verification_base_condition.replace';
      answerText = this.translateService.instant('rules.verification_base_condition.replaceAnswer', {
        ruleName: baseConditionName
      });
    } else {
      message = 'rules.verification_base_condition.createNotification';
      acceptButton = 'rules.modal_verification_base_condition.create';
      answerText = this.translateService.instant('rules.verification_base_condition.createAnswer');
    }

    const config: MatDialogConfig = {
      width: '500px',
      data: {
        notification: this.translateService.instant(message, {
          ruleName: baseConditionName
        }),
        answer: answerText,
        okButton: acceptButton,
        cancelButton: 'rules.modal_verification_base_condition.reject'
      }
    };

    return this.dialogService.open(BaseConditionFoundModalComponent, config);
  }


  openDeleteBaseConditionModal(baseConditionName: string, bcRestrictedLogicalFields: { [key: string]: Array<RuleUniqueData>},
    texts): MatDialogRef<DeleteBaseConditionModalComponent> {
    const rulesArray = this.getRulesTextArray(bcRestrictedLogicalFields);

    const config: MatDialogConfig = {
      width: '500px',
      data: {
        title: texts.title,
        notification: this.translateService.instant(texts.notification, {
          ruleName: baseConditionName
        }),
        confirmationMessage: this.translateService.instant('rules.delete_base_condition.confirmationMessage'),
        rules: rulesArray,
        cancelButton: this.translateService.instant('rules.delete_base_condition.reject')
      }
    };

    return this.dialogService.open(DeleteBaseConditionModalComponent, config);
  }

   private getRulesTextArray(bcRestrictedLogicalFields: { [key: string]: Array<RuleUniqueData>}): string[] {
   const rules = Object.keys(bcRestrictedLogicalFields).map(key => bcRestrictedLogicalFields[key]);
     console.log(JSON.stringify(rules));
     const rulesArray = [];
    for (let i = 0; i < rules.length; i++) {
      for (let j = 0; j < rules[i].length; j++) {
        const rule = this.translateService.instant('rules.delete_base_condition.ruleType') + ': ' + rules[i][j].typeId + ' ' +
          this.translateService.instant('rules.delete_base_condition.ruleName') + ': ' + rules[i][j].name + '\n';
        rulesArray.push(rule);
      }
    }
    return rulesArray;
  }
}
