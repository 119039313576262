<div class="fx-rate-error-modal">
  <div mat-dialog-title>
    <app-fn-ui-close-icon [size]="'18px'" (click)="cancel()"></app-fn-ui-close-icon>
  </div>

  <div mat-dialog-content>
    <div class="status error">
      <div class="text-status">
        <span class="message-icon"><app-fn-ui-icon icon="alert_circle_outline" size="20px"></app-fn-ui-icon></span>
        <span class="message">{{'message.panel.fx_rate_table.fx_rate_failed' | translate}}</span>
      </div>
    </div>
  </div>

  <div mat-dialog-actions>
    <button mat-button (click)="cancel()">{{'button.Cancel' | translate}}</button>
    <button mat-flat-button color="primary" (click)="getNewRate()">
      <span>{{'message.panel.fx_rate_table.get_new_rate' | translate}}</span>
    </button>
  </div>
</div>
