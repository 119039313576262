import { IEndpoint } from '../interfaces/endpoint.interface';
import { HTTPMethods } from '../enums/http-methods.enum';
import { v4 as uuid } from 'uuid';

export type MessageOperations = 'contract' | 'query' | 'submit' | 'permissionsAndDefaults' | 'save' | 'delete' | 'verify' | 'data' | 'cancel' | 'send-to-repair' | 'release' | 'rawInformation';
export const GenericMessageActions: Array<MessageOperations> = ['verify', 'cancel', 'send-to-repair', 'release'];

export class MessageEndpointBuilder {
  private endpoints: { [action: string]: IEndpoint } = {};
  constructor(private baseUrl: string, private operations: MessageOperations[], private domain: string, private messageType: string) {
  }

  buildQueryEndpoints() {
    if (this.operations.indexOf('query') !== -1) {
      this.endpoints['grid'] = {
        endpoint: `${this.baseUrl}/messages`,
        method: HTTPMethods.GET
      };

      this.endpoints['data'] = {
        endpoint: `${this.baseUrl}/messages/{MID}`,
        method: HTTPMethods.GET
      };

    } else {
      console.warn(`query not supported by domain ${this.domain} for message type ${this.messageType}`)
    }
    return this;
  }

  buildContractEndpoint() {
    this.endpoints['contract'] = {
      endpoint: `${this.baseUrl}/messages/metadata/contract`,
      method: HTTPMethods.GET
    };
    return this;
  }

  buildLayoutEndpoint() {
    this.endpoints['layout'] = {
      endpoint: '/ui/v1/metadata/messages/layout',
      method: HTTPMethods.GET
    };
    return this;
  }

  buildPermissionAndDefaultsEndpoint() {
    this.endpoints['permissionsAndDefaults'] = {
      endpoint: `${this.baseUrl}/messages/defaults`,
      method: HTTPMethods.GET
    };
    return this;
  }

  buildDataEndpoint() {
    this.endpoints['data'] = {
      endpoint: `${this.baseUrl}/messages/{MID}`,
      method: HTTPMethods.GET
    };
    return this;
  }

  buildRawInformationEndpoint() {
    this.endpoints['rawInformation'] = {
      endpoint: `${this.baseUrl}/messages/{MID}/rawInformation`,
      method: HTTPMethods.GET
    };
    return this;
  }

  buildActionEndpoints() {
    GenericMessageActions.forEach(op => {
      if (this.operations.indexOf(op) !== -1) {
        this.endpoints[op] = {
          endpoint: `${this.baseUrl}/messages/{MID}/${op}`,
          method: HTTPMethods.PUT
        };
      }
    });
    return this;
  }

  buildSubmitEndpoint() {
    if (this.operations.indexOf('submit') !== -1) {
      this.endpoints['initiate'] = {
        endpoint: `${this.baseUrl}/messages/initiate`,
        method: HTTPMethods.POST,
        idempotencyKey: uuid()
      };

      this.endpoints['submit'] = {
        endpoint: `${this.baseUrl}/messages/{MID}/submit`,
        method: HTTPMethods.PUT
      };
    } else {
      console.warn(`submit not supported by domain ${this.domain} for message type ${this.messageType}`)
    }
    return this;
  }

  buildSaveEndpoint() {
    if (this.operations.indexOf('save') !== -1) {
      this.endpoints['save'] = {
        endpoint: `${this.baseUrl}/messages/{MID}/save`,
        method: HTTPMethods.PUT,
        idempotencyKey: uuid()
      };

      this.endpoints['update_draft'] = {
        endpoint: `${this.baseUrl}/messages/drafts/{MID}`,
        method: HTTPMethods.PUT
      };
    } else {
      console.warn(`save not supported by domain ${this.domain} for message type ${this.messageType}`)
    }
    return this;
  }

  get(): {[action: string]: IEndpoint} {
    return this.endpoints;
  }

}
