import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, tap } from 'rxjs';
import { ApiCacheRequest } from '../models/api-cache-request.model';
import { ApiCachingStorageService } from './api-caching-storage.service';
import { IApiCachingService } from './interfaces/api-caching-service.interface';

@Injectable()
export class ApiCachingService implements IApiCachingService {

  constructor(private httpClient: HttpClient,
              private httpBackend: HttpBackend,
              private storageService: ApiCachingStorageService) { }

  cacheRequest<T>(request: ApiCacheRequest): Observable<T> {
    const entry = this.storageService.get<T>(request.key);
    if (entry?.data) {
      return of(entry.data);
    }
    const http = this.getHttpClient(request.isPublic);
    return http.request<T>(request.method || 'GET', request.url, request.options).pipe(
      tap((response: T) => {
        const newEntry = {
          data: response
        };
        this.storageService.set(request.key, newEntry);
      })
    );
  }

  uncacheRequest<T>(request: ApiCacheRequest): Observable<T> {
    const http = this.getHttpClient(request.isPublic);
    return http.request<T>(request.method || 'PUT', request.url, request.options).pipe(
      tap((response: T) => {
        this.storageService.remove(request.key);
      })
    );
  }

  private getHttpClient(isPublic: boolean): HttpClient {
    return isPublic ? new HttpClient(this.httpBackend) : this.httpClient;
  }

}
