import { Injectable } from '@angular/core';
import { SystemParameters } from '../models/system-parameters.model';
import { SYSTEM_PARAMETERS_HIDDEN_FIELDS } from '../consts/system-parameters-hidden-fields.const';
import { SYSTEM_PARAMETERS_VALUE_PER_UNIT_CODE } from '../consts/system-parameters-value-per-unit-code.const';
import { SYSTEM_PARAMETERS_FIELDS_TO_CONVERT } from '../consts/system-parameters-fields-to-convert.const';
import {FormService} from "@fgpp-ui/components";

@Injectable()
export class SystemParametersService {

  constructor(private formService: FormService) {
  }

    public hideFields(): void {
        SYSTEM_PARAMETERS_HIDDEN_FIELDS.forEach((fieldName: string) => {
          const fmg = this.formService.getFormManager();
          const field = fmg.get(fieldName);
            this.formService.getFormManager().get(fieldName).hide();
        });

    }

    public showRelevantValueField(): void {
    const formMgr = this.formService.getFormManager();
      this.hideAllValueFields();
      const unitCode = formMgr.get(SystemParameters.UNITCODE).getValue();
      const relevantValueFieldName = SYSTEM_PARAMETERS_VALUE_PER_UNIT_CODE[unitCode];
      formMgr.get(relevantValueFieldName).show();
    }

    public convertFieldsValueToString() {
      const formMgr = this.formService.getFormManager();
        SYSTEM_PARAMETERS_FIELDS_TO_CONVERT.forEach((fieldName: string) => {
            const value = formMgr.get(fieldName).getValue();
            if (value) {
              formMgr.get(fieldName).setValue(value.toString());
            }
        });
    }

    private hideAllValueFields(): void {
        const valueFields = (<any>Object).values(SYSTEM_PARAMETERS_VALUE_PER_UNIT_CODE);
        valueFields.forEach((fieldName: string) => {
          this.formService.getFormManager().get(fieldName).hide();
        });
    }

}
