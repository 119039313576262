<div class="side-navigation" [ngClass]="isOpen ? 'open-mode' : 'close-mode'">

    <mat-list *ngIf="showList || !isOpen; else singleItem">
        <ng-container>
            <mat-list-item *ngFor="let item of items; trackBy: trackByFunction">
                <a (click)="onItemClick(item)" [attr.automation-id]="item.alias" class="navigation-item"
                   [routerLink]="[item.routerLink]" routerLinkActive="active">
                    <app-fn-ui-icon [icon]="item.icon"></app-fn-ui-icon>
                    <span>{{ item.alias | translate }}</span>
                </a>
            </mat-list-item>
        </ng-container>
    </mat-list>

    <ng-template #singleItem>
        <div class="single-item" (click)="toggleList()">
            <a [attr.automation-id]="selectedItem.alias" [attr.azure-id]="selectedItem.alias" class="active"
               [routerLink]="[selectedItem.routerLink]">
                <app-fn-ui-icon [icon]="selectedItem.icon"></app-fn-ui-icon>
                <span>{{ selectedItem.alias | translate }}</span>
                <app-fn-ui-icon icon="chevron_down"></app-fn-ui-icon>
            </a>
        </div>
    </ng-template>
</div>
