<app-fn-ui-dialog class="office-selector">

  <ng-template #header>
    <div class="office-selector-header">
      <span class="title">{{ 'main_window.office_selector.title' | translate }}</span>
      <app-fn-ui-close-icon mat-dialog-close class="close"></app-fn-ui-close-icon>
    </div>
  </ng-template>

  <ng-template #body>
    <div class="office-selector-body">
      <h5>{{'main_window.office_selector.sub_title' | translate}}</h5>
        <mat-radio-group class="radio-group" [(ngModel)]="office">
              <mat-radio-button color="primary" *ngFor="let officeObj of offices" azure-id="office-name" id="os-{{officeObj.name}}" [value]=officeObj.name>
                <div class="offices">{{officeObj.name + ' - ' + officeObj.alias}} </div>
              </mat-radio-button>
        </mat-radio-group>
    </div>
  </ng-template>


  <ng-template #footer>
    <div class="office-selector-footer">
      <app-fn-ui-button [name]="'office-selector-modal-cancel-button'" color="primary" automation-id="office-selector-modal-cancel-button" mat-dialog-close>
        {{ 'general.cancel' | translate }}
      </app-fn-ui-button>

      <app-fn-ui-button [name]="'office-selector-modal-select-button'"  type="flat" color="primary" automation-id="office-selector-modal-select-button" [disabled]="!office" (click)="onSelectClick()">
        {{ 'general.select' | translate }}
      </app-fn-ui-button>

      </div>
  </ng-template>

</app-fn-ui-dialog>
