import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class TimeService {
  T_24_FORMATS = [];
  T_12_FORMATS = [];

  constructor(private translator: TranslateService) {
    const timeIntervals = ['00', '05', '10', '15', '25', '30', '35', '40', '45', '50', '55'];
    // let times = [];
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 11; j++) {
        let time = i + ':' + timeIntervals[j];
        if (i < 10) {
          time = '0' + time;
        }
        this.T_24_FORMATS.push(time);
      }
    }

    for (let i = 0; i < this.T_24_FORMATS.length; i++) {
      let hour = parseInt(this.T_24_FORMATS[i].split(':')[0]);
      const minute = parseInt(this.T_24_FORMATS[i].split(':')[1]);
      let amPm = this.translator.instant('AM');

      if (hour > 12) {
        hour -= 12;
        amPm = this.translator.instant('PM');
      }

      this.T_12_FORMATS.push(hour + ':' + minute + amPm);
    }
  }

  public getTimeList(timeMode) {
    if (timeMode === 24) {
      return this.T_24_FORMATS;
    } else {
      return this.T_12_FORMATS;
    }
  }
}
