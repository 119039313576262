<div class="alert-item">
  <div class="alert-title">

      <app-fn-ui-severity-icon [severity]="alert.severity"></app-fn-ui-severity-icon>

      <div class="alert-name">
          <span>{{alert.name}}</span>
      </div>
  </div>
  <div class="alert-description">
      <span>{{alert.description}}</span>
  </div>
  <div class="alert-actions">
      <div>
          <app-fn-ui-button name="alert-action-details"
                            (buttonClick)="onDetailsClick()">
            {{ 'alert.action.details' | translate}}
          </app-fn-ui-button>
          
          <app-fn-ui-button name="alert-action-investigate"
                            [disabled]="!alert.permittedQueue"
                            [matTooltip]="!alert.permittedQueue ? ('alert.message.not-permitted-queue' | translate) : ''"
                            (buttonClick)="onInvestigateClick()">
            {{ 'alert.action.investigate' | translate}}
          </app-fn-ui-button>
      </div>
  </div>
</div>
