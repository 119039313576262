import { Injectable, OnDestroy } from '@angular/core';
import { AddNoteModalComponent, AddNoteModalFields } from '../ui-components/add-note-modal/add-note-modal.component';
import { FtDateTimePipe } from '../pipes/ftDateTime.pipe';
import { DataServiceService } from './data-service.service';
import { FnUiDialogService } from '../shared/fn-ui-dialog/services/fn-ui-dialog.service';
import { AuthenticationService } from '../authentication/services/authentication.service';
import dayjs from 'dayjs';


@Injectable()
export class NotesService implements OnDestroy {
    private userId: string;
    isNewNoteAdded = false;
    subscriber;

    constructor(private dialogService: FnUiDialogService,
                private dateTimePipe: FtDateTimePipe,
                private dataService: DataServiceService,
                authenticationService: AuthenticationService) {
      this.userId = authenticationService.userData.userId;
    }

    ngOnDestroy() {
      if (this.subscriber != null) {
        this.subscriber.unsubscribe();
      }
    }

    /**
     * Opens the add note dialog using the AddNoteModalComponent.
     * On 'apply' we create and add a new note to the source
     */
    public addNote(classObject, obj?: any) {
      const createDate = this.dataService.getCurrentDateAsPerUserFormat();
      const config: AddNoteModalFields = {
        title: 'forms.confirmation_modal.content_text_' + obj.actionId,
        body: 'forms.note_modal.header_text_' + obj.actionId,
        closeButton: 'forms.confirmation_modal.dismiss_button_' + obj.actionId,
        applyButton: 'forms.confirmation_modal.apply_button_' + obj.actionId
      };
      const modalRef = this.dialogService.open(AddNoteModalComponent, { data: config });

      return new Promise((resolve, reject) => {
        this.subscriber = modalRef.afterClosed().subscribe((result) => {
          if (result) { //if some value from the modal (if 'apply' button clicked)
            const newNote = {
              'noteAsText': result,
              'userId': this.userId,
              'status': 'NW',
              'noteId': this.userId + this.dateTimePipe.transform(dayjs()), //only for the ui delete use dateTimePipe to get unique date with time stamp
              'createDate': createDate,
              'updatedUserId': this.userId

            };
            classObject.addNoteToDataSource(newNote, obj);
            this.isNewNoteAdded = true;
            resolve(newNote);
          } else {
            classObject.addNoteToDataSource(false, obj);
            reject();
          }
        });
      });
    }
}
