import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { GridHeaderConfig } from '../../models/grid-header-config.model';
import { Entity } from '../../../shared/models/enums/entity.enum';
import { Scope } from '../../models/scope.model';
import { EnumHelpers } from '../../../shared/models/classes/enum-helper';
import { ProcessingTimeScope } from '../../models/enums/processing-time-scope.enum';
import { TranslateService } from '@ngx-translate/core';
import { AutoFeederOption } from '../../models/auto-feeder-option.model';
import { GridEventType } from '../../../azure-insight/interfaces/grid-event-type.enum';
import { GridEventsService } from '../../../azure-insight/services/grid-events.service';
import { EventType } from '../../../azure-insight/interfaces/event-type.enum';
import { ActiveStatus } from '../../models/enums/active-status.enum';
import { UserSettingsService } from '../../../core/user-settings/services/user-settings.service';

@Component({
  selector: 'app-grid-header',
  templateUrl: './grid-header.component.html',
  styleUrls: ['./grid-header.component.scss']
})
export class GridHeaderComponent implements AfterViewInit {

  private currentQueueTitle = undefined;

  @Input() gridHeaderConfig: GridHeaderConfig = {} as GridHeaderConfig;
  @Input() displayTour: boolean;
  @Input() set entity(entity: Entity) {
    if (!!entity) {
      this._entity = this.traslateService.instant('messages_center.' + entity);
    }
  }
  @Input() set queueTitle(queueTitle) {
    this._queueTitle = queueTitle;

    if (this.getCurrentQueueTitle(queueTitle) !== this.currentQueueTitle) {
      this.currentQueueTitle =  this.getCurrentQueueTitle(queueTitle);
      this._selectedProcessingScope = 'system_navigation.scope_selector_title';
    }
  }
  @Input() isFavorite: boolean;
  @Input() scopes: Array<Scope>;
  @Input() autoFeederOptions: Array<AutoFeederOption>;
  @Input() set processingScopes(processingScopesEnum) {
    this._processingScopes = EnumHelpers.getNames(processingScopesEnum);
  }
  @Input() totalCount: number;
  private _isMatchingMode: boolean;

  @Input()
  get selectedScope() {
    return this._selectedScope || this.scopes[0];
  }
  set selectedScope(value: Scope) {
    this._selectedScope = value;
  }

  get selectedProcessingScopes() {
    return this._selectedProcessingScope;
  }

  set selectedProcessingScopes(value: any) {
    this._selectedProcessingScope = value;
  }

  @Input()
  get lastUpdate(): Date {
    return this._lastUpdate;
  }
  set lastUpdate(lastUpdate: Date) {
    if (this.refreshClicked) {
      this.updatedNow = true;
      setTimeout(() => {
        this.updatedNow = false;
      }, this.lastUpdateMS);
      this.refreshClicked = false;
    }
    this._lastUpdate = lastUpdate;
  }

  @Output() autoFeeder = new EventEmitter<string>();
  @Output() columnSettings = new EventEmitter<void>();
  @Output() export = new EventEmitter<void>();
  @Output() print = new EventEmitter<void>();
  @Output() create = new EventEmitter<boolean>();
  @Output() refresh = new EventEmitter<void>();
  @Output() matching = new EventEmitter<void>();
  @Output() scopeChanged = new EventEmitter<number>();
  @Output() processingScopeChanged = new EventEmitter<any>();
  @Output() favorites = new EventEmitter<void>();

  //todo on caller US , set to true when needed and move callerCallbackVisibleState from here to caller service with all
  // the caller's boolean
  callerCallbackVisibleState = false;
  //todo on matching US , set to true when needed and move matchingVisible from here to matching service
  matchingVisible = false;
  updatedNow: boolean;
  refreshClicked: boolean;
  lastUpdateMS = 5000;
  _entity = '';
  _processingScopes;
  _selectedProcessingScope;
  _queueTitle = '';

  private readonly processingScopeDefaultText;
  private _lastUpdate: Date;
  private _selectedScope;
  public dateTimeFormat;

  constructor(userSettingsService: UserSettingsService,
              private traslateService: TranslateService,
              protected gridEventsService: GridEventsService,
              protected elem: ElementRef) {
    this.dateTimeFormat = userSettingsService.getPreferences().dateFormat + ' ' + userSettingsService.getPreferences().timeFormat;
    this.processingScopeDefaultText = traslateService.instant('system_navigation.scope_selector_title');
    this._selectedProcessingScope = 'system_navigation.scope_selector_title';
  }

  private getCurrentQueueTitle(queueTitle = ''): string {
    let titleArray: string[] = queueTitle.split(' | ');
    let titleStr: string;
    if (titleArray.length >= 3) {
      titleArray.splice(2);
    }
    titleStr = titleArray.join("+")
    return titleStr;
  }

  get showActions(): boolean {
    return this.gridHeaderConfig.showAllActions ||
      this.gridHeaderConfig.showColumnSettings ||
      this.gridHeaderConfig.showExport ||
      this.gridHeaderConfig.showPrint ||
      this.gridHeaderConfig.showManualMatching;
  }

  get isMatchingMode(): boolean {
    return this._isMatchingMode;
  }

  get showColumnSettings(): boolean {
    return this.gridHeaderConfig.showAllActions ||
      this.gridHeaderConfig.showColumnSettings;
  }

  get showExport(): boolean {
    return this.gridHeaderConfig.showAllActions ||
      this.gridHeaderConfig.showExport;
  }

  get showPrint(): boolean {
    return this.gridHeaderConfig.showAllActions ||
      this.gridHeaderConfig.showPrint;
  }

  get showMatching(): boolean {
    return this.gridHeaderConfig.showManualMatching;
  }

  get eventType(): typeof EventType {
    return EventType;
  }

  toggleFavorites() {
    this.favorites.emit();
  }

  onAutoFeederClicked(type: string) {
    this.autoFeeder.emit(type);
  }

  toggleFacetFiltering() {
    //todo implement
  }

  onColumnSettingsClick() {
    this.gridEventsService.triggerEvent(GridEventType.COLUMN_SETTINGS);
    this.columnSettings.emit();
  }

  onExportClick() {
    this.gridEventsService.triggerEvent(GridEventType.EXPORT);
    this.export.emit();
  }

  onPrintClick() {
    this.gridEventsService.triggerEvent(GridEventType.PRINT);
    this.print.emit();
  }

  onCreate() {
    this.create.emit(true);
  }

  onMatchingClick() {
    this.matching.emit();
    this._isMatchingMode = !this._isMatchingMode;
  }

  onScopeChanged(index: number) {
    const enumKey = EnumHelpers.getValueFromIndex(ActiveStatus, index);
    this.gridEventsService.triggerEvent(GridEventType[enumKey]);
    this.scopeChanged.emit(index);
  }

  onProcessingScopeChanged(processingScope: any) {
    this._selectedProcessingScope = processingScope === ProcessingTimeScope.RESET ? 'system_navigation.scope_selector_title' : processingScope;
    this.processingScopeChanged.emit(processingScope);
  }

  onRefreshClick() {
    this.refresh.emit();
    this.refreshClicked = true;
  }

  ngAfterViewInit(): void {
    this.elem.nativeElement.setAttribute('azure-id', EventType.GRIDEVENT);
  }

}
