import { Injectable } from '@angular/core';
import { SystemVariablesApiService } from './system-variables-api.service';
import { SystemVariables } from './models/system-variables.model';
import { SetSystemVariables } from '../../state/actions/set-system-variables.action';
import { StoreService } from '../store.service';

@Injectable({
  providedIn: 'root'
})
export class SystemVariablesService {

  _variables: SystemVariables = undefined;

  get variables(): SystemVariables {
    return this._variables;
  }

  constructor(private storeService: StoreService,
              private systemVariablesApiService: SystemVariablesApiService) {
  }

  getSystemVariables(): Promise<SystemVariables> {
    return new Promise((resolve, reject) => {
      if (!!this._variables) {
        resolve(this._variables);
     } else {
      this.systemVariablesApiService.getSystemVariables().then((systemVariables: SystemVariables) => {
        this._variables = systemVariables;
        this.storeService.dispatch(new SetSystemVariables(this._variables));
        resolve(systemVariables);
      }).catch((e) => {
        reject(e);
      });
     }
    });
  }

  getSingleVariable(name: string) {
    if (!!this._variables) {
      if (!!name) {
        return this._variables[name];
      } else {
        console.error(`${name} is not a system variable`);
      }
    } else {
      console.error('variables is undefined');
    }
  }

}
