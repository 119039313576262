import { Injectable } from '@angular/core';
import { ShortcutInput, ShortcutEventOutput, AllowIn } from '@egoistdeveloper/ng-keyboard-shortcuts';
import { IKeyboardShortcutsBuilderService } from './interfaces/keyboard-shortcuts-builder-service.interface';
import { KeyboardShortcutEventEmitterService } from './keyboard-shortcut-event-emitter.service';
import { KeyboardShortcut } from '../models/keyboard-shortcut.model';

@Injectable()
export class KeyboardShortcutsBuilderService implements IKeyboardShortcutsBuilderService {

  constructor(private keyboardShortcutEventEmitterService: KeyboardShortcutEventEmitterService) { }

  buildShortcuts(keyboardShortcuts: Array<KeyboardShortcut>): Array<ShortcutInput> {
    if (!keyboardShortcuts) {
      return [];
    }
    return keyboardShortcuts.map((shortcut: KeyboardShortcut) => {
      return {
        key: shortcut.keys,
        command: (event: ShortcutEventOutput) => {
          this.keyboardShortcutEventEmitterService.emitEvent(shortcut, event);
        },
        allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select]
      };
    });
  }

}
