import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable()
export class EventHandlerService {

  public eventsData$: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor() {
  }

  subscribeButtonEvent(): Observable<any> {
    return this.eventsData$.asObservable();
  }

  emitEvent(event) {
    this.eventsData$.next(event);
  }

}
