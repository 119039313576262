import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PrintService {

  printHtml(html: string) {
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    document.body.appendChild(iframe);
    iframe.contentWindow.document.open();
    iframe.contentWindow.document.write(html);
    iframe.contentWindow.document.close();
    iframe.contentWindow.focus();
    const result = iframe.contentWindow.document.execCommand('print', false, null);
    if (!result) {
      iframe.contentWindow.print();
    }
    document.body.removeChild(iframe);
  }

}
