import { HttpClient, HttpRequest, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { IdpProviderUrl, IdpProviderStaticParams, IdpProviderConfig } from '../../config/models';
import { DeleteSessionToken } from '../../core/store/session/action/session.action';
import { AuthenticationGrantTypes } from '../models/enums/auth-grant-types.enum';
import { AuthenticationUrls } from '../models/enums/authentication-urls.enum';
import { IdpRequestType } from '../models/enums/idp-request-type.enum';
import { IdpRequestObject } from '../models/idp-request-object.model';
import { AuthenticationConfigService } from './authentication-config.service';
import { AuthenticationService } from './authentication.service';
import { SecurityService } from './security.service';

@Injectable()
export class AuthenticationIdpService {

  private readonly authTokenUrls = {
    GET_TOKEN: '/token',
    LOGOUT: '/logout',
    REVOKE: '/revoke'
  };

  private isRedirectedToIdp = false;

  constructor(private httpClient: HttpClient,
              private store: Store,
              private authenticationConfigService: AuthenticationConfigService,
              private authenticationService: AuthenticationService,
              private securityService: SecurityService) { }

  isIDPRequest(req: HttpRequest<any>): boolean {
    return (req.url.includes(this.authTokenUrls.GET_TOKEN) ||
     (req.url.includes(this.authTokenUrls.LOGOUT) && !req.url.includes(AuthenticationUrls.SIGN_OUT)) || req.url.includes(this.authTokenUrls.REVOKE));
  }

  isRefreshTokenRequest(req: HttpRequest<any>): boolean {
    return (req.url.includes(this.authTokenUrls.GET_TOKEN) && req.body.includes(AuthenticationGrantTypes.REFRESH_TOKEN));
  }

  setIsRedirectedToIdp(isRedirectedToIdp: boolean) {
    this.isRedirectedToIdp = isRedirectedToIdp;
  }

  getIsRedirectedToIdp(): boolean {
    return this.isRedirectedToIdp;
  }

  getRequestBody(idpConfig: IdpProviderConfig, idpRequestType: IdpRequestType, tokenOrAuthrization?: string): HttpParams {
    const isPkceEnable = idpConfig.PKCE;
    const idpProviderUrl = idpConfig[idpRequestType] ;
    let body = new HttpParams();
    if (idpProviderUrl === undefined)
      return body;

    if (idpProviderUrl.hasOwnProperty("staticParams")){
      for (const key of Object.keys(idpProviderUrl.staticParams)) {
        body = body.append(key.toString(), idpProviderUrl.staticParams[key].toString());
      }
    }
    if ( idpProviderUrl.hasOwnProperty("useParams")){
      idpProviderUrl.useParams.forEach(param => {
        if (param === 'code' || param === 'refresh_token' || param === 'token') {
          body = body.append(param, tokenOrAuthrization);
        }
        if (param === 'code_verifier') {
          if (isPkceEnable) {
            body = body.append('code_verifier', this.securityService.getCodeVerifierFromLocalStorage());
          }
        }
        if (param === 'redirect_uri') {
          body = body.append(param, this.authenticationConfigService.callbackUrl);
        }
        if (param === 'state') {
          body = body.append(param, this.securityService.getState());
        }
      });
    }
    return body;
  }

  getStaticParamsAsString(idpProviderExtraParams: IdpProviderStaticParams): string {
    if (!idpProviderExtraParams) return "";

    return Object.entries(idpProviderExtraParams)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
  }

  getRequestQueryParams(idpConfig: IdpProviderConfig, idpRequestType: IdpRequestType, authorizationCode?: string): string {
    const isPKCEEnabled = !!idpConfig.PKCE;
    const idpProviderUrl: IdpProviderUrl = idpConfig[idpRequestType];
    let queryParams = '?' + this.getStaticParamsAsString({...idpProviderUrl.staticParams as IdpProviderStaticParams } );
    if (idpProviderUrl === undefined || !idpProviderUrl.hasOwnProperty("useParams") )
      return queryParams;
    idpProviderUrl.useParams.forEach(param => {
      if (param === 'state') {
        queryParams += '&state=' + this.securityService.getState();
      }
      if (param === 'code_challenge' && isPKCEEnabled) {
        const codeChallenge = this.securityService.getCodeChallenge();
        queryParams +=  '&code_challenge=' + codeChallenge;
      }
      if (param === 'code_challenge_method' && isPKCEEnabled){
        queryParams += '&code_challenge_method=' + idpConfig.codeChallengeMethode || 'S256';
      }
      if (param === 'id_token_hint' && authorizationCode) {
        queryParams += '&id_token_hint=' + authorizationCode;
      }
      if (param === 'redirect_uri' || param === 'post_logout_redirect_uri') {
        if (queryParams.length > 1) {
          queryParams += '&' + param + '=' + encodeURIComponent(this.authenticationConfigService.callbackUrl);
        } else {
          queryParams += param + '=' + window.location.origin;
        }
      }
    });
    return queryParams;
  }

  getRequestObject(idpUrl: string, idpConfig: IdpProviderConfig, idpRequestType: IdpRequestType, authorizationCode?: string): IdpRequestObject {
    const requestObject: IdpRequestObject = { url: null, param: null, body: null };
    const idpProviderUrlConfig = idpConfig[idpRequestType];
    requestObject.url = idpUrl + idpProviderUrlConfig.url;

    if (idpProviderUrlConfig.method === 'GET')
      requestObject.param = this.getRequestQueryParams(idpConfig, idpRequestType, authorizationCode);
    else if (idpProviderUrlConfig.method === 'POST')
      requestObject.body = this.getRequestBody(idpConfig, idpRequestType, authorizationCode);

    return requestObject;
  }

  goToLoginPageIDP() {
    const idpConfig = this.authenticationConfigService.idpProviderConfig;
    const idpURL = this.authenticationConfigService.authenticationProviderUrl;
    const requestObject = this.getRequestObject(idpURL, idpConfig, IdpRequestType.LOGIN);
    window.location.href = requestObject.url + requestObject.param;
  }

  async getToken(authorizationCode: string): Promise<any> {
    const idpConfig = this.authenticationConfigService.idpProviderConfig;
    const idpURL = this.authenticationConfigService.authenticationProviderUrl;
    const requestObject = this.getRequestObject(idpURL, idpConfig, IdpRequestType.TOKEN, authorizationCode);
    let httpOptions;
    if (idpConfig.addAuthHttpHeaders) {
      httpOptions = this.buildAuthHttpHeaders();
    } else {
      httpOptions = this.buildAuthHttpHeadersWithOutAuth();
    }
    return this.httpClient.post(requestObject.url, requestObject.body.toString(), httpOptions).toPromise();
  }

  async refreshToken(): Promise<any> {
    const idpConfig = this.authenticationConfigService.idpProviderConfig;
    const idpURL = this.authenticationConfigService.authenticationProviderUrl;
    const requestObject = this.getRequestObject(idpURL, idpConfig, IdpRequestType.REFRESH_TOKEN, this.authenticationService.refreshToken);
    let httpOptions;
    if (idpConfig.addAuthHttpHeaders) {
      httpOptions = this.buildAuthHttpHeaders();
    } else {
      httpOptions = this.buildAuthHttpHeadersWithOutAuth();
    }
    return this.httpClient.post(requestObject.url, requestObject.body.toString(), httpOptions).toPromise();
  }

  async logout() {
    const idpConfig = this.authenticationConfigService.idpProviderConfig;
    const logoutRedirectUrl = this.authenticationConfigService.logoutRedirectUrl;
    const idpURL = this.authenticationConfigService.authenticationProviderUrl;
    const token = JSON.parse(JSON.stringify(this.authenticationService.idToken));
    const requestObject = this.getRequestObject(idpURL, idpConfig, IdpRequestType.LOGOUT, token);
    const httpOptions = this.buildAuthHttpHeadersWithOutAuth();
    this.store.dispatch(new DeleteSessionToken());
    if (idpConfig[IdpRequestType.LOGOUT].method === 'GET') {
      window.location.href = requestObject.url + requestObject.param;
    } else {
      this.httpClient.post(requestObject.url, requestObject.body.toString(), httpOptions).toPromise().then(res => {
        if (logoutRedirectUrl.length > 0) {
          window.location.href = logoutRedirectUrl;
        } else {
          window.location.href = window.location.origin + window.location.pathname;
        }
      });
    }
  }

  forceLogout() {
    const idpConfig = this.authenticationConfigService.idpProviderConfig;
    const idpURL = this.authenticationConfigService.authenticationProviderUrl;
    const token = JSON.parse(JSON.stringify(this.authenticationService.idToken));
    const requestObject = this.getRequestObject(idpURL, idpConfig, IdpRequestType.LOGOUT, token);
    const httpOptions = this.buildHeaderFroceLogout();
    this.store.dispatch(new DeleteSessionToken());
    fetch(requestObject.url, {
      method: 'POST',
      headers: httpOptions,
      body: JSON.stringify(requestObject.body),
      keepalive: true
    });
    return true;
  }

  private buildHeaderFroceLogout() {
    return new Headers({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
  }

  private buildAuthHttpHeadersWithOutAuth() {
    const header = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return {
      headers: header
    };
  }

  private buildAuthHttpHeaders() {
    const authorizationHeader = this.getAuthorizationHeader();
    const header = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': authorizationHeader });
    return {
      headers: header
    };
  }

  private getAuthorizationHeader() {
    const encoded = this.securityService.encodeBase64Url('gpp:');
    return 'Basic ' + encoded;
  }

}
