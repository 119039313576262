<div class="user-menu-container">
    <span *ngFor="let item of menuItems">
      <span *ngIf="item.children && item.children.length > 0">
         <button mat-menu-item [matMenuTriggerFor]="menu.childMenu" [class]="item.class">
           <mat-icon class="mdi mdi-{{item.iconName}}"></mat-icon>
           <span matTooltip="{{item.tooltip}}">{{item.displayName}}</span>
         </button>
        <lib-menu-item #menu [items]="item.children"></lib-menu-item>
      </span>
    </span>
</div>
