import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArchiveModule, INTERCEPTING_HANDLER_INJECTOR } from '@fgpp-ui/archive';

import { ArchiveComponent } from './archive.component';
import { ArchiveRoutingModule } from './archive-routing.module';
import { ArchiveTreeComponent } from './archive-tree/archive-tree.component';
import { FnUiNavigationTreeModule } from '../shared/fn-ui-navigation-tree/fn-ui-navigation-tree.module';
import { InterceptingHandlerInjector } from '../../services/intercepting-handler/intercepting-handler.injector';


@NgModule({
  declarations: [
    ArchiveTreeComponent,
    ArchiveComponent
  ],
  imports: [
    CommonModule,
    ArchiveRoutingModule,
    ArchiveModule,
    FnUiNavigationTreeModule
  ],
  providers: [
    { provide: INTERCEPTING_HANDLER_INJECTOR, useClass: InterceptingHandlerInjector }
  ],
  exports: [
    ArchiveTreeComponent
  ],
})
export class AppArchiveModule { }
