import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Select } from '@ngxs/store';
import { MessagesManagementService } from '../../services/messages-management.service';
import { Observable, Subscription } from 'rxjs';
import { MessagesCenterQueueState } from '../../../core/state/messages-center-queue.state';
import { MessagesCenterQueue } from '../../../core/models/messages-center-queue.model';
import { MessagesCenterNavigationService } from '../../../messages-center/messages-center-navigation/services/messages-center-navigation.service';

@Component({
  selector: 'app-messages-center-system-queues',
  providers: [],
  templateUrl: 'messages-center-system-queues.component.html',
})
export class MessagesCenterSystemQueuesComponent implements OnInit, OnDestroy, AfterViewInit {

  systemQueuesTree: any;

  @Select(MessagesCenterQueueState.getSystemQueue) queue$: Observable<MessagesCenterQueue>;

  private _subscription = new Subscription();
  queueId: string;

  constructor(public messagesCenterNavigationService: MessagesCenterNavigationService,
              private messagesManagementService: MessagesManagementService, private route: ActivatedRoute, private elRef: ElementRef ){

    this.messagesCenterNavigationService.registerTreeUpdateFunction(this.updateTree);
  }

   ngOnInit() {
     this._subscription.add(this.queue$.subscribe((queue: MessagesCenterQueue) => {
       this.selectTreeItem(queue);
     }));
     this.systemQueuesTree = this.route.snapshot.data.tree;
   }

   selectTreeItem(queue: MessagesCenterQueue) {
    if (queue && queue.id) {
        this.queueId = queue.id.split('/')[0];
        const systemQueuesTree = this.messagesCenterNavigationService.systemQueuesTree?.nodes.find(node => node.type === queue.entityType);
        this.messagesCenterNavigationService.getQueuesTreeByQueueId(queue.id, systemQueuesTree);
        this.messagesCenterNavigationService.currentQueue.queueType = queue.type;
        this.messagesManagementService.onTreeItemSelect(this.messagesCenterNavigationService.currentQueue);

    } else {
      this.messagesCenterNavigationService.currentQueue = null;
    }
   }

  /**
   * This function will be passed to a service which it will execute on tree refresh
   * Arrow function is a must in order to keep 'this'
   */
  updateTree = () => {
    return this.messagesCenterNavigationService.updateSystemQueuesTree();
  };

  ngAfterViewInit() {
    const queueTree = this.elRef.nativeElement.querySelectorAll(`a[href="#/home/messages-center/system-queues/messages?queueId=${this.queueId}"]`);
    if (queueTree && queueTree.length > 0) {
      setTimeout(() => queueTree[0].scrollIntoView());
    }

  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }
}
