import { Injectable } from '@angular/core';
import { IEndpoint } from '../interfaces/endpoint.interface';
import { DataService } from '@fgpp-ui/components';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProfileEndpointBuilder, ProfileOperations } from './profile-endpoint-builder.service';
import { EMetadataCategory } from '../enums/metadata-category.enum';
import { IMetadataResponse } from '../interfaces/metadata-response.interface';
import { MessageEndpointBuilder, MessageOperations } from './message-endpoint-builder.service';
import { HttpHeaders } from '@angular/common/http';


@Injectable()
export class MetadataService {
  private readonly defaultMetadataUrl = '/ui/metadata';
  private readonly defaultDomain = 'bff'
  private domainMetadataMap = new Map<string, Observable<any>>();

  constructor(private dataService: DataService) {
  }

  getMetadata(domain: string, category: EMetadataCategory, mapping: string, typeId: string): Observable<{ [action: string]: IEndpoint }> {
    const url = domain && domain !== this.defaultDomain ? `/${domain}/metadata` : this.defaultMetadataUrl;
    if (!this.domainMetadataMap.has(domain)) {
      this.domainMetadataMap.set(domain, this.dataService.get(url, null));
    }
    return this.getDomainMetadata(domain, category,
      mapping || typeId, typeId);
  }

  private getDomainMetadata(domain, category: EMetadataCategory, name: string, profileId?: string): Observable<{ [action: string]: IEndpoint }> {
    return this.domainMetadataMap.get(domain).pipe(map(
      (domainMetadata: IMetadataResponse) => {
        const baseUrl = domainMetadata[category][name].baseUrl;
        const operations = domainMetadata[category][name].operations as (ProfileOperations | MessageOperations)[];
        if (category === EMetadataCategory.PROFILES || category === EMetadataCategory.TASKS) {
          return this.generateProfileEndpoints(baseUrl, operations as ProfileOperations[], domain, profileId);
        } else {
          // TODO: Implement message endpoints
          return this.generateMessageEndpoints(baseUrl, operations as MessageOperations[], domain, profileId);
        }
      }
    ));
  }

  private generateProfileEndpoints(baseUrl: string, operations: ProfileOperations[], domain: string, profileId: string): { [action: string]: IEndpoint } {
    const endpoints = {};
    return new ProfileEndpointBuilder(baseUrl, operations, domain, profileId)
      .buildContractEndpoint()
      .buildLayoutEndpoint()
      .buildTranslateEndpoint()
      .buildRelatedEntitiesEndpoint()
      .buildPermissionAndDefaultsEndpoint()
      .buildCloseEndpoint()
      .buildQueryEndpoints()
      .buildCreateEndpoint()
      .buildUpdateEndpoint()
      .buildAuditEndpoint()
      .buildDecisionEndpoint()
      .buildStateEndpoint()
      .buildExecuteEndpoint()
      .get();
  }

  private generateMessageEndpoints(baseUrl: string, operations: MessageOperations[], domain: string, profileId: string): { [action: string]: IEndpoint } {
    const endpoints = {};
    return new MessageEndpointBuilder(baseUrl, operations, domain, profileId)
      .buildContractEndpoint()
      .buildLayoutEndpoint()
      .buildSaveEndpoint()
      .buildSubmitEndpoint()
      .buildPermissionAndDefaultsEndpoint()
      .buildDataEndpoint()
      .buildQueryEndpoints()
      .buildActionEndpoints()
      .buildRawInformationEndpoint()
      .get();
  }
}
