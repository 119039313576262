<app-fa-dialog [title]="dialogData.title | translate" [body]="bodyTemplate" [footer]="footerTemplate"  ></app-fa-dialog>
  <ng-template #bodyTemplate>
    <app-drill-down-grid [externalFilterGridData] = "dialogData"
                         (rowClicked) = "onRowClicked($event)"
                         (selectedRowsChange) = "onSelectionChange($event)"
    ></app-drill-down-grid>
  </ng-template>
<ng-template #footerTemplate>
  <button azure-id="close-drill-down-dialog" mat-button color="primary" mat-dialog-close>{{'general.cancel' | translate}}</button>
  <button azure-id="confirm-drill-down-dialog" mat-flat-button color="primary" (click)="onConfirmBtn()" [disabled]="!selectedRows.length">{{'general.ok' | translate}}</button>
</ng-template>
