import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientModule } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';

import { GridModule as LibGridModule } from '@fgpp-ui/grid';
import { ColumnSettingsModule } from '@fgpp-ui/grid';
import { UiComponentsModule } from '../ui-components/ui-components.module';
import { FnUiExportModule } from '../shared/fn-ui-export/fn-ui-export.module';
import { FnUiFavoriteIconModule } from '../shared/fn-ui-icons/fn-ui-favorite-icon/fn-ui-favorite-icon.module';
import { FnUiRefreshIconModule } from '../shared/fn-ui-icons/fn-ui-refresh-icon/fn-ui-refresh-icon.module';
import { FnUiDialogModule } from '../shared/fn-ui-dialog/fn-ui-dialog.module';
import { FnUiIconModule } from '../shared/fn-ui-icons/fn-ui-icon/fn-ui-icon.module';
import { SpinnerModule } from '../ui-components/spinner/spinner.module';

import { ColumnSettingsService } from './services/column-settings.service';
import { GridConfigService } from './services/grid-config.service';
import { GridColumnsService } from './services/grid-columns.service';
import { SearchRequestBuilderService } from './services/search-request-builder.service';
import { FilterMapperService } from './services/filter-mapper.service';
import { ExternalFilterGridHelperService } from '../profiles/services/external-filter-grid-helper.service';
import { MessageGridColumnsService } from '../messages/services/message-grid-column.service';
import { ActionFilterService } from '@fgpp-ui/grid';

import { GridHeaderComponent } from './components/grid-header/grid-header.component';
import { GridFooterComponent } from './components/grid-footer/grid-footer.component';
import { DrillDownGridComponent } from './components/drill-down-grid/drill-down-grid.component';
import { DialogDrillDownComponent } from './components/drill-down-dialog/drill-down-dialog.component';
import { ToursModule } from '../tours/tours.module';
import { FnUiCloseIconModule } from '../shared/fn-ui-icons/fn-ui-close-icon/fn-ui-close-icon.module';

@NgModule({
  declarations: [
    GridHeaderComponent,
    GridFooterComponent,
    DrillDownGridComponent,
    DialogDrillDownComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    UiComponentsModule,
    TranslateModule.forChild(),
    LibGridModule,
    MatTooltipModule,
    MatButtonModule,
    ColumnSettingsModule,
    FnUiExportModule,
    FnUiFavoriteIconModule,
    FnUiRefreshIconModule,
    FnUiDialogModule,
    FnUiIconModule,
    SpinnerModule,
    ToursModule,
    FnUiCloseIconModule
  ],
  providers: [
    ColumnSettingsService,
    GridConfigService,
    GridColumnsService,
    MessageGridColumnsService,
    SearchRequestBuilderService,
    ExternalFilterGridHelperService,
    FilterMapperService,
    ActionFilterService
  ],
  exports: [
    LibGridModule,
    GridHeaderComponent,
    GridFooterComponent,
    DialogDrillDownComponent
  ]
})
export class GridModule { }
