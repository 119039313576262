<div class="full">
  <div *ngIf="isFormReadyForRendering" class="form-container">
    <form [formGroup]="form">
      <gpp-ui-form-canvas [isRoot]="true" [classList]="'form-root'"
                          [form]="form" [controlsMetadata]="formMetadata"
                          [userPreferences]="userPreferences"></gpp-ui-form-canvas>
    </form>
  </div>
</div>
<ng-container *ngIf="isLoading">
  <gpp-ui-loading-indicator [showSpinner]="true"></gpp-ui-loading-indicator>
</ng-container>
