import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatDialogConfig } from '@angular/material/dialog';
import { FileAttachmentDialogComponent } from '../file-attachment-dialog.component';
import { IFileAttachmentDialogService } from './base/file-attachment-dialog.interface';
import { DialogFileAttachmentConfig } from '../models/dialog-file-attachment-config.model';
import { FileAttachmentDialogOutput } from '../models/file-attachment-dialog-output.model';
import { FnUiDialogService } from '../../../fn-ui-dialog/services/fn-ui-dialog.service';

@Injectable()
export class FileAttachmentDialogService implements IFileAttachmentDialogService {
    private _isDialogOpen = false;

    constructor(private dialogService: FnUiDialogService) { }

    public open(fileAttachmentData: DialogFileAttachmentConfig): MatDialogRef<FileAttachmentDialogComponent> {
      if (!this._isDialogOpen) {
        this._isDialogOpen = true;
        const matConfig: MatDialogConfig<DialogFileAttachmentConfig> = {
          data: fileAttachmentData,
          panelClass: 'dialog-attachment-view',
          hasBackdrop: true
        };
        return this.dialogService.open(FileAttachmentDialogComponent, matConfig);
      }
    }

    public openAsPromise(fileAttachmentData: DialogFileAttachmentConfig): Promise<FileAttachmentDialogOutput> {
      return this.open(fileAttachmentData).afterClosed().toPromise();
    }

    public closeDialog() {
      this.setIsDialogOpen(false);
      this.dialogService.closeAll();
    }

    public getIsDialogOpen(): boolean {
      return this._isDialogOpen;
    }

    public setIsDialogOpen( value: boolean ) {
      this._isDialogOpen = value;
    }
}
