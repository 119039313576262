import { Component } from '@angular/core';
import { IValueChange } from '../../../core/interfaces/form-value-change.interface';
import {BFOnValueChange} from '../../../core/interfaces/sdk-profile-hooks.interface';
import { ProfileFormTemplateProviders } from '../../../core/templates/profile-form.template.providers';
import {AbstractProfileFormComponent} from "../../abstract/abstract-profile/abstract-profile-form.component";

@Component({
  selector: 'app-message-codeword-profile',
  templateUrl: '../../../core/templates/profile-form.template.html',
  styleUrls: ['../../../core/templates/profile-form.template.scss'],
  providers: ProfileFormTemplateProviders
})
export class MessageCodewordsComponent extends AbstractProfileFormComponent implements BFOnValueChange {

  fgppValueChanges(change: IValueChange): void {
    const logicalField = 'CODE_LIST-FIELD_LOGICAL_ID';
    const alias = 'LOGICAL_FIELDS-ALIAS';
    if (change.control === logicalField) {
      if (change.value) {
        const logicalFieldData = this.getDrilldownData(logicalField);
        if (logicalFieldData) {
          this.formMgr.get(alias).setValue(logicalFieldData[alias])
        }
      } else {
        this.formMgr.get(alias).reset();
      }
    }
  }
}
