import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { GppTreeConfigService, TreeConfig } from '@fgpp-ui/components';
import { ConditionsBankCategory } from '../../models/conditions-bank-category.model';
import { ConditionsBankNode } from '../../models/conditions-bank-node.model';
import { LogicalField } from '../../models/logical-field.model';

@Component({
  selector: 'app-conditions-bank-tree',
  templateUrl: './conditions-bank-tree.component.html',
  styleUrls: ['./conditions-bank-tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    GppTreeConfigService
  ]
})
export class ConditionsBankTreeComponent {

  @Input() treeModel: Array<ConditionsBankCategory>;
  @Input() searchTerm: string;
  @Input() ruleDisabled: boolean;

  @Output() selectionChanged = new EventEmitter<ConditionsBankNode>();

  treeConfig: Partial<TreeConfig> = {
    childrenProperty: 'nodes',
    classes: {
      expandedButton: 'mat-primary'
    },
    expandable: (node: ConditionsBankNode) => !!node.nodes?.length,
    selectable: (node: ConditionsBankNode) => node.available && !node.nodes?.length,
    expansionTrackBy: (node: ConditionsBankNode) => node.id,
    filter: {
      filterComparator: this.filterComparator.bind(this),
      autoExpansionMinLength: 3
    },
    sort: {
      sortComparator: this.sortComparator.bind(this)
    }
  };

  selectedNode: ConditionsBankNode;

  onSelectionChange($event: ConditionsBankNode): void {
    this.selectedNode = $event;
    if ($event) {
      this.selectionChanged.emit($event);
      this.resetSelection();
    }
  }

  onDragStart($event: DragEvent, node: ConditionsBankNode): void {
    $event.dataTransfer.setData('text', JSON.stringify({ data: node }));
  }

  private resetSelection(): void {
    this.selectedNode = null;
  }

  private filterComparator(node: ConditionsBankCategory | ConditionsBankNode, searchTerm: string): boolean {
    const expandable = this.treeConfig.expandable(node);
    if (expandable) {
      return false;
    }
    const alias = (node as LogicalField).treeAlias || node.alias;
    return alias.toUpperCase().includes(searchTerm.trim().toUpperCase());
  }

  private sortComparator(node1: ConditionsBankCategory | ConditionsBankNode, node2: ConditionsBankCategory | ConditionsBankNode): number {
    const isCategory = !(node1 as ConditionsBankNode).contentType;
    if (isCategory) {
      return 0;
    }
    const alias1 = (node1 as LogicalField).treeAlias || node1.alias;
    const alias2 = (node2 as LogicalField).treeAlias || node2.alias;
    return alias1.toUpperCase().localeCompare(alias2.toUpperCase());
  }
}
