import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { GppTreeModule } from '@fgpp-ui/components';
import { TranslateModule } from '@ngx-translate/core';
import { GridModule } from '../grid/grid.module';
import { PipesModule } from '../pipes/pipes.module';
import { AuditTrailRulesContainerComponent } from '../profiles/components/audit-trail-rules-container/audit-trail-rules-container.component';
import { ProfilesModule } from '../profiles/profiles.module';

import { ProfilesManagementService } from '../profiles/services/profiles-management.service';
import { RulesProfilesManagementService } from '../profiles/services/rules-profiles-management.service';

import { WatchDirective } from '../shared/directives/watch.directive';

import { FnUiAutocompleteModule } from '../shared/fn-ui-autocomplete/fn-ui-autocomplete.module';
import { FnUiButtonModule } from '../shared/fn-ui-button/fn-ui-button.module';
import { FnUiCheckboxModule } from '../shared/fn-ui-checkbox/fn-ui-checkbox.module';
import { FnUiDatetimePickerModule } from '../shared/fn-ui-datetime-picker/fn-ui-datetime-picker.module';
import { FnUiDialogModule } from '../shared/fn-ui-dialog/fn-ui-dialog.module';
import { FnUiDrillDownModule } from '../shared/fn-ui-drilldown/fn-ui-drill-down.module';
import { FnUiCloseIconModule } from '../shared/fn-ui-icons/fn-ui-close-icon/fn-ui-close-icon.module';
import { FnUiIconModule } from '../shared/fn-ui-icons/fn-ui-icon/fn-ui-icon.module';
import { FnUiInputModule } from '../shared/fn-ui-input/fn-ui-input.module';
import { FnUiMenuModule } from '../shared/fn-ui-menu/fn-ui-menu.module';
import { FnUiModalModule } from '../shared/fn-ui-modals/fn-ui-modal.module';
import { FnUiPopoverModule } from '../shared/fn-ui-popover/fn-ui-popover.module';
import { FnUiSelectModule } from '../shared/fn-ui-select/fn-ui-select.module';
import { ToursModule } from '../tours/tours.module';
import { SpinnerModule } from '../ui-components/spinner/spinner.module';
import { UiComponentsModule } from '../ui-components/ui-components.module';

import { BusinessRulesComponent } from './components/business-rules/business-rules.component';
import { RulesLandingPageComponent } from './components/landing-page/landing-page.component';

import { SingleRuleDeactivateGuard } from './routing/guards/single-rule-deactivate.guard';

import { RulesAssociationAuditTrailComponent } from './rules-associations/single-view/components/rules-association-audit-trail/rules-association-audit-trail.component';
import { RulesAssociationContentAreaComponent } from './rules-associations/single-view/components/rules-association-content-area/rules-association-content-area.component';
import { RulesAssociationFooterComponent } from './rules-associations/single-view/components/rules-association-footer/rules-association-footer.component';
import { RuleAssociationsGridWrapperComponent } from './rules-associations/single-view/components/rules-association-grid-wrapper/rule-associations-grid-wrapper.component';
import { RulesAssociationListComponent } from './rules-associations/single-view/components/rules-association-list/rules-association-list.component';
import { RulesAssociationComponent } from './rules-associations/single-view/components/rules-association.component';

import { RulesAssociationListGroupByPipe } from './rules-associations/single-view/pipes/rules-association-list-group-by.pipe';

import { RuleAssociationsEditService } from './rules-associations/single-view/services/rule-associations-edit.service';
import { RuleCustomerLevelActionService } from './rules-associations/single-view/services/rule-customer-level-action.service';

import { RuleTypeService } from './services/rule-type.service';
import { RuleTypesAccessService } from './services/rule-types-access.service';
import { RuleTypesApiService } from './services/rule-types-api.service';
import { AuditTrailDetailsComponent } from './single-rule/components/audit-trail-details/audit-trail-details.component';

import { AuditTrailComponent } from './single-rule/components/audit-trail/audit-trail.component';
import { BaseConditionFoundModalComponent } from './single-rule/components/base-condition-found-modal/base-condition-found-modal.component';
import { ComplexConditionComponent } from './single-rule/components/complex-condition/complex-condition.component';
import { ConditionOperandComponent } from './single-rule/components/condition-operand/condition-operand.component';
import { ConditionOperatorComponent } from './single-rule/components/condition-operator/condition-operator.component';
import { ConditionsBankTreeComponent } from './single-rule/components/conditions-bank-tree/conditions-bank-tree.component';
import { ConditionsBankComponent } from './single-rule/components/conditions-bank/conditions-bank.component';
import { ConditionsEditorComponent } from './single-rule/components/conditions-editor/conditions-editor.component';
import { ConditionsNotAllowedModalComponent } from './single-rule/components/conditions-not-allowed-modal/conditions-not-allowed-modal.component';
import { CreateBaseConditionModalComponent } from './single-rule/components/create-base-condition-modal/create-base-condition-modal.component';
import { DeleteBaseConditionModalComponent } from './single-rule/components/delete-base-condition-modal/delete-base-condition-modal.component';
import { InnerConditionComponent } from './single-rule/components/inner-condition/inner-condition.component';
import { InnerConditionsComponent } from './single-rule/components/inner-conditions/inner-conditions.component';
import { InsertFunctionPopoverComponent } from './single-rule/components/insert-function-popover/insert-function-popover.component';
import { RestrictedLogicalFieldPopoverComponent } from './single-rule/components/restricted-logical-field-popover/restricted-logical-field-popover.component';
import { RuleContentComponent } from './single-rule/components/rule-content/rule-content.component';
import { RuleDetailsComponent } from './single-rule/components/rule-details/rule-details.component';
import { RuleFooterComponent } from './single-rule/components/rule-footer/rule-footer.component';
import { RuleFormComponent } from './single-rule/components/rule-form/rule-form.component';
import { RulePreviewComponent } from './single-rule/components/rule-preview/rule-preview.component';
import { SimpleConditionComponent } from './single-rule/components/simple-condition/simple-condition.component';

import { FunctionArgumentsDirective } from './single-rule/directives/function-arguments.directive';
import { RestrictSpecialCharactersDirective } from './single-rule/directives/restrict-special-characters.directive';

import { AttachedRuleHandlerService } from './single-rule/services/attached-rule-handler.service';
import { ComplexConditionService } from './single-rule/services/complex-condition.service';
import { RuleApiService } from './single-rule/services/rule-api.service';
import { RuleCommonService } from './single-rule/services/rule-common.service';
import { RuleConditionsActionsService } from './single-rule/services/rule-conditions-actions.service';
import { RuleConditionsAlgorithmsService } from './single-rule/services/rule-conditions-algorithms.service';
import { RuleConditionsService } from './single-rule/services/rule-conditions.service';
import { RuleModalsService } from './single-rule/services/rule-modals.service';
import { RuleService } from './single-rule/services/rule.service';
import { SimpleConditionLogicService } from './single-rule/services/simple-condition-logic.service';

@NgModule({
  imports: [
    ClipboardModule,
    DragDropModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatSelectModule,
    GppTreeModule,
    TranslateModule.forChild(),
    GridModule,
    PipesModule,
    ProfilesModule,
    FnUiAutocompleteModule,
    FnUiButtonModule,
    FnUiCheckboxModule,
    FnUiDatetimePickerModule,
    FnUiDialogModule,
    FnUiDrillDownModule,
    FnUiCloseIconModule,
    FnUiIconModule,
    FnUiInputModule,
    FnUiMenuModule,
    FnUiModalModule,
    FnUiPopoverModule,
    FnUiSelectModule,
    ToursModule,
    SpinnerModule,
    UiComponentsModule
  ],
  declarations: [
    BusinessRulesComponent,
    RulesLandingPageComponent,
    BaseConditionFoundModalComponent,
    ConditionsNotAllowedModalComponent,
    CreateBaseConditionModalComponent,
    DeleteBaseConditionModalComponent,
    ConditionsBankTreeComponent,
    RestrictedLogicalFieldPopoverComponent,
    RuleFormComponent,
    RuleContentComponent,
    RuleDetailsComponent,
    ConditionsEditorComponent,
    ComplexConditionComponent,
    SimpleConditionComponent,
    ConditionOperandComponent,
    ConditionOperatorComponent,
    InsertFunctionPopoverComponent,
    FunctionArgumentsDirective,
    RulePreviewComponent,
    RuleFooterComponent,
    AuditTrailComponent,
    AuditTrailDetailsComponent,
    InnerConditionComponent,
    InnerConditionsComponent,
    RestrictSpecialCharactersDirective,
    WatchDirective,
    ConditionsBankComponent,
    RulesAssociationAuditTrailComponent,
    RulesAssociationContentAreaComponent,
    RulesAssociationFooterComponent,
    RuleAssociationsGridWrapperComponent,
    RulesAssociationListComponent,
    RulesAssociationComponent,
    RulesAssociationListGroupByPipe
  ],
  providers: [
    SingleRuleDeactivateGuard,
    RuleTypeService,
    RuleTypesAccessService,
    RuleTypesApiService,
    AttachedRuleHandlerService,
    ComplexConditionService,
    RuleApiService,
    RuleCommonService,
    RuleConditionsActionsService,
    RuleConditionsAlgorithmsService,
    RuleConditionsService,
    RuleModalsService,
    RuleService,
    SimpleConditionLogicService,
    RulesProfilesManagementService,
    ProfilesManagementService,
    RuleAssociationsEditService,
    RuleCustomerLevelActionService
  ],
  exports: [
    BusinessRulesComponent,
    RulesLandingPageComponent,
    ConditionsNotAllowedModalComponent,
    RuleContentComponent,
    AuditTrailRulesContainerComponent,
    RulesAssociationAuditTrailComponent,
    RulesAssociationComponent,
    RuleAssociationsGridWrapperComponent,
  ]
})
export class RulesModule { }
