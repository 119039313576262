import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, HostListener, AfterViewInit, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotificationService } from '@fgpp-ui/components';
import { Store } from '@ngxs/store';
import { UserData } from '../../models/user-data.model';
import { AuthenticationApiService } from '../../services/authentication-api.service';
import { SystemVariablesService } from '../../../core/services/system-variables/system-variables.service';
import { CreateSession } from '../../../core/store/session/action/session.action';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit, AfterViewInit, AfterViewChecked {
  form: FormGroup;
  ldapDomains: string;
  authenticationInProgress = false;
  credentials = { userId: null, password: null, domain: null };
  capsLock = false;
  selected: string;

  private _error: string;

  constructor(private formBuilder: FormBuilder,
              private store: Store,
              private router: Router,
              private notificationService: NotificationService,
              private authenticationApiService: AuthenticationApiService,
              private systemVariablesService: SystemVariablesService, private changeDetectorRef: ChangeDetectorRef) { }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
    }

  ngOnInit() {
    this.form = this.formBuilder.group({
      'username': ['', Validators.required],
      'password': ['', Validators.required],
    }
    );
    this.ldapDomains = this.systemVariablesService.getSingleVariable('ldapDomains');
    if (this.ldapDomains != null && this.ldapDomains.length > 0) {
      this.selected = this.ldapDomains[0];
      this.credentials.domain = this.ldapDomains[0];
    }
  }

  ngAfterViewInit(): void {
    this.form.get('username').setErrors(null);
    this.form.get('password').setErrors(null);
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDownShowCapLock(event): void {
    this.capsLock = event.getModifierState && event.getModifierState('CapsLock');
  }

  onFormSubmit(): void {
    this.authenticationInProgress = true;
    this._error = null;
    this.credentials.userId = this.form.value.username;
    this.credentials.password = this.form.value.password;

    this.authenticationApiService.signIn(this.credentials).subscribe({
      next: (response: UserData) => {
        this.store.dispatch(new CreateSession({ userData: response, token: null }));
        this.router.navigate(['/home'])
      },
      error: (response: HttpErrorResponse) => {
        this._error = response.error.type;

        let message;
        switch (this._error) {
          case 'LockedException':
          case 'LoggedInException':
          case 'PasswordHistoryException':
          case 'PasswordStrengthException':
          case 'AuthenticationServiceException':
            message = 'Login failed due to: Bad credentials / User suspension / User is  already signed in';
            break;
          case 'PasswordChangeException':
            message = 'Password change failed';
            break;
          case 'TokenDeviceNotActiveException':
            message = 'Second authentication failed, Token device is not active. Please contact your administrator';
            break;
          case 'PasswordExpiredException':
            this.router.navigate([`user/changePassword/${this.credentials.userId}/${response.error.type}`]);
            break;
          case 'BadCredentialsException':
            message = 'Login failed due to: Bad credentials / User suspension / User is  already signed in';
            const field = this.form.get('password');
            field.setValue('');
            break;
          case 'NeedTokenAuthenticationException':
            this.router.navigate([`user/userToken/${this.credentials.userId}/${response.error.message}/${response.error.type}`], {
              state: {
                userPassWord: this.credentials.password
              }
            });
            break;
          case 'AssignSoftTokenException':
            this.router.navigate(['user/assignSoftToken'], {
              state: {
                userId: this.credentials.userId,
                message: response.error.message,
              }
            })
            break;
        }

        this.notificationService.error(message);
        this.credentials.password = '';
        this.authenticationInProgress = false;
      }
    });
  }

  onDomainValueChange($event): void {
    this.credentials.domain = $event.source.value;
  }

}
