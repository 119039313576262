<form class="dh-signin-form" [formGroup]="form" #signupForm="ngForm" (ngSubmit)="onFormSubmit()" autocomplete="off">
  <div class="dh-product-title">Payments To Go</div>
    <mat-form-field [floatLabel]="'always'" class="dh-form-field username field">
      <mat-label>User ID</mat-label>
      <input matInput
             id="signin-username-field"
             color="primary"
             autocomplete="off"
             [disableControl]="authenticationInProgress"
             formControlName="username"
             #usernameField
             automationid="loginPage-usernameField"  >
      <mat-hint>Enter user ID</mat-hint>
    </mat-form-field>
    <mat-form-field [floatLabel]="'always'" class="dh-form-field password field">
      <mat-label>Password</mat-label>
      <input matInput
             id="signin-password-field"
             type="password"
             autocomplete="off"
             [disableControl]="authenticationInProgress"
             formControlName="password"
             (keydown)="onKeyDownShowCapLock($event)"
             #passwordField
             automationid="loginPage-passwordField"  >
      <mat-hint>Enter password</mat-hint>
    </mat-form-field>
  <mat-form-field class="dh-form-field" *ngIf="ldapDomains?.length > 0">
    <mat-select [panelClass]="'select-dropdown'"
                [(value)]="selected"
                placeholder="Domain"
                name="ldapDomains"
                (selectionChange)="onDomainValueChange($event)">
      <mat-option *ngFor="let domain of ldapDomains" [value]="domain">
        {{domain}}
      </mat-option>
    </mat-select>
  </mat-form-field>
    <button mat-flat-button
            id="signin-login-button"
            class="login-button"
            color="primary"
            [disabled]="!signupForm.valid || !usernameField.value || !passwordField.value || authenticationInProgress"
            type="submit"
            automationid="loginPage-logInButton" >
      <span class="dh-text-label">Login</span>
    </button>
  <span class="login-caps-lock-warning" *ngIf="capsLock"><app-fn-ui-icon icon="report_problem" size="14px"></app-fn-ui-icon>Caps Lock Is On</span>
</form>
