export const FnUiDatetimePickerLayout = {
  properties: {
    controls: {
      'DATE_TIME': {
        controlType: 'datetimepicker',
        position: 1,
        colSpan: 12
      }
    }
  }
};
