import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {PROFILE_ID} from "../../../../profiles/components/profiles-grid/profiles-grid.component";

@Injectable({
  providedIn: 'root'
})
export class RulesAssociationApiService {

  constructor(private http: HttpClient) {
  }

  public getRulesAssociationDetails(uid: string, loadType: string): Observable<any> {
    return this.http.get('do/rulesAssociation/ruleAssociationDetails', {params: {ruleAssociationId: uid, loadType: loadType}})
  }

  getRuleTypeDetails(param: { typeId: string; office: any }): Observable<any> {
    return this.http.get(`do/rulesAssociation/${param['typeId']}/fields?office=${param['office']}`);
  }

/*  getRuleAssociation(param: { loadType: string; ruleAssociationId: string }) : Observable<any> {
    return of({});
  }*/

  getRuleTypeObjects(param: { typeId: any }): Observable<any> {
    return this.http.get(`do/rulesAssociation/${param['typeId']}/getSelect/search`);
  }

  deleteRuleAssociation(payload: { note: any; profileTimeStamp: any; uniqueRecID: any; effectiveDate: any }): Observable<any>{
    return this.http.post('do/rulesAssociation/delete', payload);
  }

  holdRuleAssociation(payload: { note: any; profileTimeStamp: any; uniqueRecID: any; effectiveDate: any }): Observable<any> {
    return this.http.post('do/rulesAssociation/hold', payload);
  }

  activateRuleAssociation(payload: { note: any; profileTimeStamp: any; uniqueRecID: any; effectiveDate: any }): Observable<any> {
    return this.http.post('do/rulesAssociation/activate', payload);
  }

  saveRuleAssociation(param: { ruleId: any }, rule): Observable<any>{
    return this.http.put('do/rulesAssociation/save', {param, contentHolder: rule});
  }

  createRuleAssociation(rule): Observable<any>{
    return this.http.post('do/rulesAssociation/save', {contentHolder: rule});
  }

  approve(payload: { profileTimeStamp: any; profileUpdateTimeStamp: any; uniqueRecID: any }): Observable<any> {
    return this.http.post('do/rulesAssociation/approve', payload);
  }

  decline(payload: { note: any; profileTimeStamp: any; profileUpdateTimeStamp: any; uniqueRecID: any }): Observable<any> {
    return this.http.post('do/rulesAssociation/decline', payload);
  }

  close(payload: any): Observable<any> {
    return this.http.post('do/rulesAssociation/close', {uniqueRecID: payload['uniqueRecID']});
  }

  getAuditDetails(uid: string): Observable<any> {
    return this.http.get(`do/PROFILE/${PROFILE_ID.RULE_ASSOCIATION}/auditTrail?fkProfileUpdate=${uid}`);
  }

  getComplexAuditDetails(uid: string, searchRequest: any): Observable<any> {
    return this.http.post(`do/PROFILE/${PROFILE_ID.RULE_ASSOCIATION}/auditTrailComplex/search?pkProfileUpdate=${uid}*`, searchRequest);
  }
}
