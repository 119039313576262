import { Component } from '@angular/core';
import { IValueChange } from '../../../core/interfaces/form-value-change.interface';
import { BFOnFormLoadComplete, BFOnValueChange } from '../../../core/interfaces/sdk-profile-hooks.interface';
import { ProfileFormTemplateProviders } from '../../../core/templates/profile-form.template.providers';
import { AbstractProfileFormComponent } from '../../abstract/abstract-profile/abstract-profile-form.component';
import { ValueDateDetermination } from '../../models/value-date-determination.model';

@Component({
  selector: 'app-value-date-determination',
  templateUrl: '../../../core/templates/profile-form.template.html',
  styleUrls: ['../../../core/templates/profile-form.template.scss'],
  providers: ProfileFormTemplateProviders
})
export class ValueDateDeterminationComponent extends AbstractProfileFormComponent implements BFOnFormLoadComplete, BFOnValueChange {
 
  fgppFormLoadComplete(): void {
    if (!this.formService.disableAllFields) {
      this.updateValidityPeriodAvailability();
    }
  }

  fgppValueChanges(change: IValueChange): void {
    if (change.control === ValueDateDetermination.CALC_VALID_UNTIL_DT) {
      this.updateValidityPeriodAvailability();
    }
  }

  private updateValidityPeriodAvailability(): void {
    const calcValidUntilDt = this.formMgr.get(ValueDateDetermination.CALC_VALID_UNTIL_DT);
    const validityPeriod = this.formMgr.get(ValueDateDetermination.VALIDITY_WAIT_DAYS);
    if (calcValidUntilDt.getValue() === '1') {
      validityPeriod.enable();
    } else {
      validityPeriod.disable();
      validityPeriod.reset();
    }
  }

}
