import {Component, OnInit} from '@angular/core';
import {AbstractProfileFormComponent} from '../../abstract/abstract-profile/abstract-profile-form.component';
import { ProfileFormTemplateProviders } from '../../../core/templates/profile-form.template.providers';
import {IValueChange} from "../../../core/interfaces/form-value-change.interface";
import {TimeZones} from "../../models/time-zones.model";

@Component({
  selector: 'app-time-zones',
  templateUrl: '../../../core/templates/profile-form.template.html',
  styleUrls: ['../../../core/templates/profile-form.template.scss'],
  providers: ProfileFormTemplateProviders
})
export class TimeZonesComponent extends AbstractProfileFormComponent implements OnInit {

  fgppOnSave(): boolean {
    //if
    let standardHoursFromUtc_hhmm=this.formMgr.get(TimeZones.V_STANDARD_HOURS_FROM_UTC_HHMM).getValue();
    let standardHoursFromUtc_sign=this.formMgr.get(TimeZones.V_STANDARD_HOURS_FROM_UTC_SIGN).getValue();

    standardHoursFromUtc_hhmm=standardHoursFromUtc_hhmm.replace(':','');
    standardHoursFromUtc_sign=standardHoursFromUtc_sign === '-'?'-':'';
    const standardHoursFromUtcDB=standardHoursFromUtc_sign+standardHoursFromUtc_hhmm;
    this.formMgr.get(TimeZones.STANDARD_HOURS_FROM_UTC).setValue(standardHoursFromUtcDB);

    return true;
  }
  fgppFormLoadComplete(): void{
    const standardHoursFromUtc= this.formMgr.get(TimeZones.STANDARD_HOURS_FROM_UTC).getValue();
    let standardHoursFromUtc_hhmm=standardHoursFromUtc;
    if(standardHoursFromUtc){
      if(standardHoursFromUtc.charAt(0) === '-'){
        this.formMgr.get(TimeZones.V_STANDARD_HOURS_FROM_UTC_SIGN).setValue('-');
        standardHoursFromUtc_hhmm=standardHoursFromUtc.substring(1);
      }else{
        this.formMgr.get(TimeZones.V_STANDARD_HOURS_FROM_UTC_SIGN).setValue('+');
      }
      this.formMgr.get(TimeZones.V_STANDARD_HOURS_FROM_UTC_HHMM).setValue(this.formatUtcTime(standardHoursFromUtc_hhmm));
    }

  }

  formatUtcTime(value:string):string{
    value=value.padStart(4,'0');
    value= value.substring(0,2) +':'+ value.substring(2);
    return value;
  }
}
