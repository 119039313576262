import { Component, AfterViewInit, ViewChild, Input, Output, EventEmitter, forwardRef, ContentChild, TemplateRef, ElementRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FloatLabelType, MatFormFieldAppearance } from '@angular/material/form-field';
import { MatSelect, MatSelectChange } from '@angular/material/select';

export interface FnUiOption {
  value: any;
  alias: string;
}

@Component({
  selector: 'app-fn-ui-select',
  templateUrl: './fn-ui-select.component.html',
  styleUrls: ['./fn-ui-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FnUiSelectComponent),
      multi: true
    }
  ]
})
export class FnUiSelectComponent<TOption, TValue> implements AfterViewInit, ControlValueAccessor {

  get ngModel(): TValue {
    return this._value;
  }

  set ngModel(value: TValue) {
    if (value !== this._value) {
      this._value = value;
      this.onChange(value);
    }
  }
  private _value: TValue;

  @Input()
  get autoSelect() {
    return this._autoSelect;
  }
  set autoSelect(value: boolean) {
    this._autoSelect = value;
    if (this.autoSelect) {
      this.selectFirstOption();
    }
  };
  private _autoSelect: boolean;

  @Input()
  get options(): Array<TOption> {
    return this._options;
  }
  set options(value: Array<TOption>) {
    this._options = value;
    if (this.autoSelect) {
      this.selectFirstOption();
    }
  }
  private _options: Array<TOption>;

  @Input() label: string;
  @Input() multiple = false;
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Input() displayProperty = 'alias';
  @Input() valueProperty: string;
  @Input() overlayOffsetY = 35;
  @Input() panelClass: string;
  @Input() optionClass: string;
  @Input() appearance: MatFormFieldAppearance;
  @Input() floatLabel: FloatLabelType = 'always';

  @Output() selectionChanged = new EventEmitter<TValue>();

  @ContentChild('optionTemplate', { static: true }) optionTemplate: TemplateRef<ElementRef>;

  @ViewChild('defaultOptionTemplate', { static: true }) defaultOptionTemplate: TemplateRef<ElementRef>;
  @ViewChild(MatSelect) matSelect: MatSelect;

  ngAfterViewInit(): void {
    if (!this.optionTemplate) {
      this.optionTemplate = this.defaultOptionTemplate;
    }
    this.matSelect['_overlayDir'].offsetY = this.overlayOffsetY;
  }

  selectFirstOption(): void {
    if (this.options?.length) {
      setTimeout(() => this.ngModel = this.getValue(this.options[0]));
    }
  }

  getValue(option: TOption): any {
    if (typeof option !== 'object') {
      return option;
    }
    if (this.valueProperty) {
      return option[this.valueProperty];
    }
    return option;
  }

  getAlias(option: TOption): any {
    if (typeof option !== 'object') {
      return option;
    }
    return option[this.displayProperty];
  }

  onSelectionChange(event: MatSelectChange): void {
    this.ngModel = event.value;
    this.selectionChanged.emit(event.value);
  }

  onChange = (_) => { };
  onTouched = () => { };

  writeValue(value: any): void {
    this.ngModel = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

}
