import { Directive, OnChanges, SimpleChanges, ViewContainerRef, Renderer2, Input, Type } from '@angular/core';

@Directive({
  selector: '[libLoader]'
})
export class LoaderDirective implements OnChanges {

  @Input() loaderVisible: boolean;
  @Input() color: string;
  @Input() loaderComponent: Type<any>;
  loaderComponentRef;

  constructor(private vcRef: ViewContainerRef,
              private renderer: Renderer2) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.loaderVisible) {
      if (changes.loaderVisible.currentValue === true) {
        this.createSpinner();
      } else {
        this.destroySpinner();
      }
    }
  }

  createSpinner() {
    this.loaderComponentRef = this.vcRef.createComponent(this.loaderComponent);
    if (this.color) {
      this.loaderComponentRef.instance.color = this.color;
    }
    this.renderer.appendChild(this.vcRef.element.nativeElement, this.loaderComponentRef.injector.get(this.loaderComponent).vcRef.element.nativeElement);
  }

  destroySpinner() {
    if (this.loaderComponentRef) {
      this.loaderComponentRef.destroy();
    }
  }
}
