import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { MainViewComponent } from '../../../base-view/components/main-view/main-view.component';
import { MessagesQueuesStateService } from '../../../messages/services/messages-queues-state.service';

@Injectable()
export class TaskBarItemExitGuard implements CanDeactivate<MainViewComponent> {

  constructor(private messagesQueuesStateService: MessagesQueuesStateService) { }

  canDeactivate(component: MainViewComponent): boolean {
    this.messagesQueuesStateService.resetState();
    return true;
  }

}
