import { Injectable } from '@angular/core';
import { SystemVariablesService } from '../../services/system-variables/system-variables.service';
import { PasswordPolicyInterface } from '../models/password-policy.interface';

@Injectable()
export class PasswordPolicyService {
  constructor(private readonly systemVariablesService: SystemVariablesService) {
  }

  fetch(): PasswordPolicyInterface {
    const maxLength = this.systemVariablesService.getSingleVariable('MAXPASSLEN');
    const minLength = this.systemVariablesService.getSingleVariable('MINPASSLEN');
    const minUpperCase = this.systemVariablesService.getSingleVariable('MIN_UPPERCASE_CHAR');
    const minLowerCase = this.systemVariablesService.getSingleVariable('MIN_LOWERCASE_CHAR');
    const minSpecialChars = this.systemVariablesService.getSingleVariable('MIN_NON_ALPHANUMERIC_CHAR');
    const minDigits = this.systemVariablesService.getSingleVariable('MINPASSDIG');
    return this.createPolicy(minLength, maxLength, minUpperCase, minLowerCase, minSpecialChars, minDigits);
  }

  private createPolicy(minLength: number, maxLength: number, minUpperCase: number, minLowerCase: number, minSpecialChars: number, minDigits: number): PasswordPolicyInterface {
    const policy: PasswordPolicyInterface = {};
    if (minLength) {
      policy.minLength = minLength;
    }
    if (maxLength) {
      policy.maxLength = maxLength;
    }
    if (minUpperCase) {
      policy.upperCase = minUpperCase;
    }
    if (minLowerCase) {
      policy.lowerCase = minLowerCase;
    }
    if (minSpecialChars) {
      policy.specialChar = minSpecialChars;
    }
    if (minDigits) {
      policy.digits = minDigits;
    }
    return policy;
  }
}
