import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseInfo } from '../../../shared/models/response-info.model';
import {
  ApproveDeclineRequest, AuditTrail, BaseConditionUsedProfilesResponse, ChangeRuleStatusRequest, RuleCriteria, RuleIdRequest, RulePayload, RuleTypeDetails,
  SearchBaseConditionResponse
} from '../models';

@Injectable()
export class RuleApiService {

  readonly url = 'do/rules';

  constructor(private http: HttpClient) { }

  getRuleTypeDetails(typeId: string, office: string): Observable<RuleTypeDetails> {
    return this.http.get<RuleTypeDetails>(`${this.url}/${typeId}/fields`, {
      params: { office }
    });
  }

  getRule(ruleId: string, loadType: string): Observable<RulePayload> {
    return this.http.get<RulePayload>(`${this.url}/ruleDetails`, {
      params: { ruleId, loadType }
    });
  }

  saveRule(ruleId: string, ruleContent: RulePayload): Observable<ResponseInfo> {
    return this.http.put<ResponseInfo>(`${this.url}/save`, ruleContent);
  }

  createRule(ruleContent: RulePayload): Observable<ResponseInfo> {
    return this.http.post<ResponseInfo>(`${this.url}/save`, ruleContent);
  }

  deleteRule(deleteRuleData: ChangeRuleStatusRequest): Observable<ResponseInfo> {
    return this.http.post<ResponseInfo>(`${this.url}/delete`, deleteRuleData);
  }

  holdRule(holdRuleData: ChangeRuleStatusRequest): Observable<ResponseInfo> {
    return this.http.post<ResponseInfo>(`${this.url}/hold`, holdRuleData);
  }

  activateRule(activateRuleData: ChangeRuleStatusRequest): Observable<ResponseInfo> {
    return this.http.post<ResponseInfo>(`${this.url}/activate`, activateRuleData);
  }

  close(closeRuleData: RuleIdRequest): Observable<ResponseInfo> {
    return this.http.post<ResponseInfo>(`${this.url}/close`, closeRuleData);
  }

  approve(approveData: ApproveDeclineRequest): Observable<ResponseInfo> {
    return this.http.post<ResponseInfo>(`${this.url}/approve`, approveData);
  }

  decline(declineData: ApproveDeclineRequest): Observable<ResponseInfo> {
    return this.http.post<ResponseInfo>(`${this.url}/decline`, declineData);
  }

  verifyBaseCondition(office: string, ruleConditions: RuleCriteria): Observable<RulePayload> {
    return this.http.post<RulePayload>(`${this.url}/verifyBaseCondition`, ruleConditions, {
      params: { office }
    });
  }

  retractForCreate(ruleIdRequest: RuleIdRequest): Observable<ResponseInfo> {
    return this.http.post<ResponseInfo>(`${this.url}/retractToCreate`, ruleIdRequest);
  }

  retract(retractRuleData: ApproveDeclineRequest): Observable<ResponseInfo> {
    return this.http.post<ResponseInfo>(`${this.url}/retract`, retractRuleData);
  }

  searchBaseCondition(uniqueRecId: string): Observable<SearchBaseConditionResponse> {
    return this.http.get<SearchBaseConditionResponse>(`${this.url}/searchbasecondition`, {
      params: { UID: uniqueRecId }
    });
  }

  baseConditionUsedProfiles(uniqueRecId: string): Observable<BaseConditionUsedProfilesResponse> {
    return this.http.get<BaseConditionUsedProfilesResponse>(`${this.url}/baseconditionusedprofiles`, {
      params: { UID: uniqueRecId }
    });
  }

  getAuditTrail(uid: string): Observable<AuditTrail> {
    return this.http.get<any>('do/PROFILE/416/auditTrail', {
      params: {
        fkProfileUpdate: uid
      }
    });
  }

}
