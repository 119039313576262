import { UserMenuButton } from './user-menu-button.enum';
import {MenuItem} from "../../../../../../menu/src/lib/dynamic-menu/models/menu-item";

export const USER_MENU_ITEMS = [
  {
    displayName: 'userId',
    class: 'user-menu-button',
    iconClass: 'material-icons',
    iconName: 'account',
    children: [
      {
        displayName: 'userMenu.settings',
        iconClass: 'settings',
        iconName: 'cog',
        event: UserMenuButton.SETTING
      },
      {
        displayName: 'userMenu.help',
        iconClass: 'help',
        iconName: 'help-circle',
        event: UserMenuButton.HELP
      },
      {
        displayName: 'userMenu.about',
        iconClass: 'material-icons',
        iconName: 'information',
        children: [
          {
            displayName: 'Version: {{version}}',
            class: 'user-menu-item',
            iconName: 'information-outline',
            event: UserMenuButton.FINASTRA_VERSION,
            rightClick: true
          },
          {
            displayName: 'http://www.finastra.com',
            class: 'user-menu-item',
            iconName: 'link-box-variant-outline',
            event: UserMenuButton.FINASTRA_PAGE
          }
        ]
      },
      {
        displayName: 'userMenu.logout',
        iconClass: 'material-icons',
        iconName: 'logout',
        event: UserMenuButton.LOGOUT
      }
    ]
  }
] as MenuItem[];
