import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';
import { Subscription, combineLatest} from 'rxjs';
import {ProfileComponentLoader} from '../../../../business-framework/config/profile-component-loader';
import {DynamicFormLoaderComponent} from '../../../../forms/components/dynamic-form-loader/dynamic-form-loader.component';
import {ProfilesManagementService} from '../../../services/profiles-management.service';
import {ProfilesMode} from '../../models/enums/profiles-mode.enum';
import {RulesAssociationComponent} from "../../../../rules/rules-associations/single-view/components/rules-association.component";

@Component({
  selector: 'app-profiles-center-view',
  templateUrl: './profiles-center-view.component.html',
  styleUrls: ['./profiles-center-view.component.scss']
})
export class ProfilesCenterViewComponent implements OnInit, OnDestroy {

  private sub: Subscription;
  private _profilesMode: ProfilesMode;

  isGridView: boolean;
  component;

  get profilesModeModal(): typeof ProfilesMode {
    return ProfilesMode;
  }

  get profilesMode(): ProfilesMode {
    return this._profilesMode;
  }

  @ViewChild(DynamicFormLoaderComponent) singleProfile: DynamicFormLoaderComponent;
  @ViewChild(RulesAssociationComponent) rulesAssociationComponent: RulesAssociationComponent;

  constructor(private router: Router, private route: ActivatedRoute, private profilesManagementService: ProfilesManagementService ) {
  }

  ngOnInit(): void {
    this.sub = combineLatest([this.route.params, this.route.queryParams])
      .subscribe(() => {
        this.setProfilesMode();
      });
  }

  private setProfilesMode(): void {
    const isSingularProfile = this.route.snapshot.data.isSingularProfile;
    const businessFrameworkProfile = this.route.snapshot.data.isNewUIProfile;
    const rulesAssociationSingleView = this.route.snapshot.data.rulesAssociationSingleView;
    if (rulesAssociationSingleView) {
      this._profilesMode = ProfilesMode.SINGULAR_RULES_ASSOCIATION_PROFILE_VIEW
    } else if (isSingularProfile) {
      this._profilesMode = ProfilesMode.SINGULAR_PROFILE_VIEW;
    } else if (!this.route.snapshot.queryParamMap.get('mode') && !this.route.snapshot.queryParamMap.get('uniqueRecId')) {
      this._profilesMode = ProfilesMode.GRID_VIEW;
      if (this.router.getCurrentNavigation()?.extras?.state?.fromForm) {
        this.profilesManagementService.invokeGridDataChange(true);
      }
    } else if (businessFrameworkProfile) {
      this._profilesMode = ProfilesMode.PROFILE_FORM_VIEW;
      this.resolveFormComponent();
    }
  }

  private resolveFormComponent() {
    const profileId = this.route.snapshot.queryParamMap.get('profile');
    // const taskCode =  this.route.snapshot.queryParamMap.get('taskCode');
    const component = ProfileComponentLoader.profileCodeToComponent[profileId];
    if (component == null) {
      console.error('no form component found, component code = ' + profileId);
      return;
    }
    this.component = component;
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

}
