<form [formGroup]="form" class="user-settings-localization">
    <div class="body">
      <mat-form-field class="dh-form-field">
            <mat-label>{{'my_profile.select_language' | translate}}</mat-label>
        <mat-select [hideSingleSelectionIndicator]="true" [panelClass]="'select-dropdown'"  id="my_profile.select_language" [formControl]="form.get('language')">
                <mat-option *ngFor="let language of languages" [value]="language">
                    {{language | translate}}
                </mat-option>
            </mat-select>
        </mat-form-field>

      <mat-form-field class="dh-form-field">
            <mat-label>{{'my_profile.date_formatting' | translate}}</mat-label>
        <mat-select [hideSingleSelectionIndicator]="true" [panelClass]="'select-dropdown'" id="my_profile.date_formatting" [formControl]="form.get('dateFormat')">
                <mat-option *ngFor="let format of dateFormats" [value]="format">
                    {{(format | dateExample)}}
                </mat-option>
            </mat-select>
        </mat-form-field>

      <mat-form-field class="dh-form-field">
            <mat-label>{{'my_profile.time_format' | translate}}</mat-label>
        <mat-select [hideSingleSelectionIndicator]="true" [panelClass]="'select-dropdown'" id="my_profile.time_format" [formControl]="form.get('timeFormat')">
                <mat-option *ngFor="let format of timeFormats" [value]="format">
                    {{(format | timeExample)}}
                </mat-option>
            </mat-select>
        </mat-form-field>

      <mat-form-field class="dh-form-field">
            <mat-label>{{'my_profile.thousands_separator' | translate}}</mat-label>
        <mat-select [hideSingleSelectionIndicator]="true" [panelClass]="'select-dropdown'" id="my_profile.thousands_separator" [formControl]="form.get('thousandSeparator')">
                <mat-option *ngFor="let format of thousandSeparatorOptions" [value]="format">
                    {{format}}
                </mat-option>
            </mat-select>
        </mat-form-field>

      <mat-form-field class="dh-form-field">
            <mat-label>{{'my_profile.decimal_separator' | translate}}</mat-label>
        <mat-select [hideSingleSelectionIndicator]="true" [panelClass]="'select-dropdown'" id="my_profile.decimal_separator" [formControl]="form.get('decimalSeparator')">
                <mat-option *ngFor="let format of decimalSeparatorOptions" [value]="format">
                    {{format}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="form.get('decimalSeparator').invalid">{{getError()}}</mat-error>
        </mat-form-field>
    </div>

    <div class="actions">
        <app-fn-ui-button name="localization_restore" id="localization-restore" (buttonClick)="restore()" color="primary">
            {{'my_profile.restore_default' | translate}}
        </app-fn-ui-button>
        <app-fn-ui-button name="localization_cancel" id="localization-cancel" (buttonClick)="cancel()" color="primary">
            {{'general.cancel' | translate}}</app-fn-ui-button>
        <app-fn-ui-button name="localization_apply" id="localization-apply" (buttonClick)="apply()" color="primary"
            [disabled]="!form.dirty || !changed">
            {{'general.apply' | translate}}</app-fn-ui-button>
        <app-fn-ui-button name="localization_ok" id="localization-ok" (buttonClick)="save()" color="primary" type="flat"
            [disabled]="!form.dirty">
            {{'general.ok' | translate}}
        </app-fn-ui-button>
    </div>
</form>
