export enum Operators {
  contains = 'like',
  notContains = 'like',
  startsWith = 'like',
  endsWith = 'like',
  greaterThan = '>',
  lessThan = '<',
  greaterThanOrEqual = '>=',
  lessThanOrEqual = '<=',
  equal = '=',
  notEqual = '=',
  between = 'between',
  is = '=',
  isEmpty = '=',
  in = 'in'
}

export type OperatorsKey = keyof typeof Operators;

export const OPERATOR_KEYS = new Map<Operators, OperatorsKey>(
  Object.keys(Operators).map((key: OperatorsKey) => [Operators[key], key])
);
