import { Component } from '@angular/core';

@Component({
  selector: 'app-singular-profile-content',
  templateUrl: './singular-profile-content.component.html',
  styleUrls: ['./singular-profile-content.component.scss']
})
export class SingularProfileContentComponent {

}
