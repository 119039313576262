import { Component, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FloatLabelType, MatFormFieldAppearance } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';

@Component({
  selector: 'app-fn-ui-input',
  templateUrl: './fn-ui-input.component.html',
  styleUrls: ['./fn-ui-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FnUiInputComponent),
      multi: true
    }
  ]
})
export class FnUiInputComponent implements ControlValueAccessor {

  get ngModel(): string {
    return this._value;
  }

  set ngModel(value: string) {
    if (value !== this._value) {
      this._value = value;
      this.onChange(value);
    }
  }
  private _value: string;

  @Input() label: string;
  @Input() placeholder: string;
  @Input() prefixIcon: string;
  @Input() suffixIcon: string;
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Input() appearance: MatFormFieldAppearance;
  @Input() floatLabel: FloatLabelType = 'always';

  @Output() focused: EventEmitter<FocusEvent> = new EventEmitter();
  @Output() blured: EventEmitter<FocusEvent> = new EventEmitter();
  @Output() inputChanged: EventEmitter<string> = new EventEmitter();

  @ViewChild(MatInput) matInput: MatInput;

  focus(): void {
    this.matInput.focus();
  }

  onFocus($event: FocusEvent): void {
    this.focused.emit($event);
  }

  onBlur($event: FocusEvent): void {
    this.blured.emit($event);
  }

  onInputChange($event: Event) {
    this.inputChanged.emit((<HTMLInputElement>$event.target).value);
  }

  onChange = (_) => { };
  onTouched = () => { };

  writeValue(value: any): void {
    this.ngModel = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

}
