import { Injectable } from '@angular/core';
import { GppTreeConfigService } from '@fgpp-ui/components';

@Injectable()
export class FnUiNavigationTreeConfigService extends GppTreeConfigService {

  get childrenProperty(): string {
    return 'nodes';
  }

  get allowUserSelection(): boolean {
    return false;
  }

  get autoExpansionToSelected(): boolean {
    return true;
  }

  get expandedButtonClass(): string {
    return 'mat-primary';
  }

  get getUId(): (node) => string {
    return (node) => node.id;
  }

  get expandable(): (node) => boolean {
    return (node) => !!node.nodes;
  }

  get selectable(): (node) => boolean {
    return (node) => !node.nodes;
  }

}
