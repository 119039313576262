import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { map, Observable } from 'rxjs';
import { GridContainerConfig } from '../../../grid/models/grid-container-config.model';
import { ProfileGridConfigResolver } from '../../../profiles/routing/resolvers/profile-grid-config.resolver';
import { RULE_TYPE_DELIMITER } from '../../models/consts/rule-type.consts';
import { RuleTypePermissions } from '../../models/rule-type-permissions.model';
import { RuleTypesAccessService as RuleTypesAccessService } from '../../services/rule-types-access.service';

export const RuleGridContainerConfigResolver: ResolveFn<GridContainerConfig> =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<GridContainerConfig> => {

    const ruleTypesAccessService = inject(RuleTypesAccessService);
    const profileGridConfig = ProfileGridConfigResolver(route, state);

    const getRuleTypePermissions = (): Observable<RuleTypePermissions> => {
      return ruleTypesAccessService.getPermissions();
    };

    const getGridContainerConfig = (): GridContainerConfig => {
      const ruleTypes = route.queryParams.ruleType?.split(RULE_TYPE_DELIMITER);
      const disableCreate = shouldDisableCreate(ruleTypes);
      const config = JSON.parse(JSON.stringify(profileGridConfig));
      config.gridHeaderConfig.disableCreate = disableCreate;
      return config;
    };

    const shouldDisableCreate = (ruleTypes: Array<string>): boolean => {
      if (ruleTypes?.length) {
        return ruleTypes.every((ruleType: string) => !ruleTypesAccessService.hasWritePermission(ruleType));
      }
      return false;
    };

    return getRuleTypePermissions().pipe(map(() => getGridContainerConfig()));
  };
