import { Component, OnInit } from '@angular/core';
import { AbstractProfileFormComponent } from '../../abstract/abstract-profile/abstract-profile-form.component';
import { ProfileFormTemplateProviders } from '../../../core/templates/profile-form.template.providers';
import { IValueChange } from '../../../core/interfaces/form-value-change.interface';
import { Billing } from '../../models/billing.model';
import { SystemParameters } from '../system-parameters/models/system-parameters.model';
import { Base64 } from 'js-base64';

@Component({
  selector: 'app-billing',
  templateUrl: '../../../core/templates/profile-form.template.html',
  styleUrls: ['../../../core/templates/profile-form.template.scss'],
  providers: ProfileFormTemplateProviders
})
export class BillingComponent extends AbstractProfileFormComponent implements OnInit {

  fgppFormLoadComplete(): void {
    this.loadAccumulationFields(this.formMgr.get(Billing.INTERFACE_TYPE).getValue());
  }

  fgppValueChanges(change: IValueChange): void {
    if (change.control === Billing.INTERFACE_TYPE) {
      this.handleInterfaceTypeChanges(change.value.inputValue);
    }
  }

  private handleInterfaceTypeChanges(interfaceType: string) {
    this.loadAccumulationFields(interfaceType);
    if (interfaceType === 'Accumulated') {
      this.setBillingGroupingValue();
    }
    else {
      this.formMgr.get(Billing.BILLING_GROUPING).setValue(null);
    }
  }

  private loadAccumulationFields(interfaceType: string) {
    if (interfaceType === 'Accumulated') {
      this.formMgr.get(Billing.INTERFACE_TIMING).enable().markAsRequired();
      this.formMgr.get(Billing.FILE_DIRECTORY_LOCATION).enable().markAsRequired();
      this.formMgr.get(Billing.FILE_NAME_CONVENTION).enable().markAsRequired();
      this.formMgr.get(Billing.BILLING_GROUPING).markAsRequired();
    } else {
      this.formMgr.get(Billing.INTERFACE_TIMING).disable().markAsOptional().reset();
      this.formMgr.get(Billing.FILE_DIRECTORY_LOCATION).disable().markAsOptional().reset();
      this.formMgr.get(Billing.FILE_NAME_CONVENTION).disable().markAsOptional().reset();
      this.formMgr.get(Billing.BILLING_GROUPING).markAsOptional();
    }
  }

  private setBillingGroupingValue() {
    const url = `/ui/profiles/54/${Base64.encodeURL('***^BILLING_GROUPING')}?loadType=2`;
    this.dataService.get(url).subscribe((response: any) => {
      if (response) {
        this.formMgr.get(Billing.BILLING_GROUPING).setValue(response[SystemParameters.PARM_VALUE]);
      }
    });
  }
}
