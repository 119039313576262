import {Component, OnDestroy, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TaskBarService } from '../core/navigation/task-bar/services/task-bar.service';
import {Subscription} from "rxjs";
import { Entity } from '../shared/models/enums/entity.enum';
import { TaskBar } from '../core/navigation/task-bar/models/task-bar.model';

@Component({
  selector: 'app-task-bar-layout',
  templateUrl: './task-bar-layout.component.html',
  styleUrls: ['./task-bar-layout.component.scss']
})
export class TaskBarLayoutComponent implements OnInit, OnDestroy {

  taskBar: TaskBar;
  taskBarComponent;
  showTaskBarSettings: boolean

  private subscriber: Subscription;

  constructor(public taskBarService: TaskBarService, private route: ActivatedRoute) {
    this.taskBar = this.route.snapshot.data.taskBar.taskBar;
  }

  toggleSideBar(): void {
    this.taskBarService.taskBarShowState = !this.taskBarService.taskBarState.showSideBar;
    this.taskBarService.setSideNavigationStateOnClick(this.taskBarService.taskBarShowState);
  }

  onItemClick(): void {
    if (!this.taskBarService.taskBarShowState) {
      this.toggleSideBar();
    }
  }

  ngOnInit(): void {
     this.subscriber = this.route.data.subscribe(data => {
       this.taskBarComponent = data.taskBarComponent;
       this.showTaskBarSettings = data.type === Entity.MESSAGES;
     })
  }

  ngOnDestroy(): void {
    if(this.subscriber) {
      this.subscriber.unsubscribe();
    }
  }

}
