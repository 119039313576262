import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiCacheKeys } from '../../api-caching/models/enums/api-cache-keys.enum';
import { ApiCachingService } from '../../api-caching/services/api-caching.service';
import { UserPreferences } from '../models/user-preferences.model';

@Injectable()
export class UserSettingsApiService {

    private resource = 'do/myprofile';
    private resourceDefault = this.resource + '/default';

    constructor(protected http: HttpClient,
                private apiCachingService: ApiCachingService) { }

    getPreferences(): Promise<UserPreferences> {
        const request = {
            key: ApiCacheKeys.USER_SETTINGS,
            url: this.resource
        };
        return this.apiCachingService.cacheRequest<UserPreferences>(request).toPromise();
    }

    getDefault(): Promise<UserPreferences> {
        return this.http.get<UserPreferences>(this.resourceDefault).toPromise();
    }

    savePreferences(preferences): Promise<void> {
        const request = {
            key: ApiCacheKeys.USER_SETTINGS,
            url: this.resource,
            method: 'POST',
            options: {
                body: preferences
            }
        };
        return this.apiCachingService.uncacheRequest<void>(request).toPromise();
    }

    resetPreferences(preferences): Promise<any> {
        return this.http.delete(this.resource, preferences).toPromise();
    }

}
