import { Injectable } from '@angular/core';
import { IKeyboardShortcutElementSelectorService } from './interfaces/keyboard-shortcut-element-selector-service.interface';
import { FnUiDialogService } from '../../../shared/fn-ui-dialog/services/fn-ui-dialog.service';

@Injectable()
export class KeyboardShortcutElementSelectorService implements IKeyboardShortcutElementSelectorService {

  constructor(private dialogService: FnUiDialogService) { }

  selectElement(selector: string): HTMLElement {
    const view = this.getAvailableView();
    const elements = Array.from(view.querySelectorAll(selector)) as Array<HTMLElement>;
    for (const element of elements) {
      if (this.isAvailable(element)) {
        return element;
      }
    }
    return null;
  }

  private getAvailableView(): ParentNode {
    const activeDialog = this.dialogService.getActiveDialog();
    if (activeDialog) {
      return document.getElementById(activeDialog.id);
    }
    return document;
  }

  private isAvailable(element: HTMLElement): boolean {
    const isDisabled = element.getAttribute('disabled');
    if (isDisabled) {
      return false;
    }
    const isHidden = element.offsetParent === null || getComputedStyle(element).visibility === 'hidden';
    if (isHidden) {
      return false;
    }
    return true;

  }
}
