<app-fn-ui-dialog class="fn-ui-confirm-modal">

  <ng-template #header>
      <div class="fn-ui-confirm-header">
          <span class="title" [translate]="params.title"></span>
          <app-fn-ui-close-icon mat-dialog-close></app-fn-ui-close-icon>
      </div>
      <mat-divider></mat-divider>
  </ng-template>

  <ng-template #body>
      <div class="fn-ui-confirm-body">
        <span class="fn-ui-confirm-message" [translate]="params.message"></span>

        <div *ngIf="params.withCopy" class="fn-ui-copy-message">
          <span translate="modal.not_Sure"></span>

          <app-fn-ui-button name="copy-to-clipboard"
                            class="copy-to-clipboard"
                            color="primary"
                            [cdkCopyToClipboard]="params.affectedList"
                            (cdkCopyToClipboardCopied)="copySuccess()">
            <app-fn-ui-icon icon="content_copy"></app-fn-ui-icon>
            <span class="copy-text" translate="modal.copy_to_clipboard"></span>
          </app-fn-ui-button>

          <span translate="modal.review"></span>
        </div>
      </div>

  </ng-template>

  <ng-template #footer>
      <div class="fn-ui-confirm-footer">
          <app-fn-ui-button [name]="params.title + ' ' + params.cancelButton" mat-dialog-close="cancel">
              <span [translate]="params.cancelButton"></span>
          </app-fn-ui-button>

          <app-fn-ui-button [name]="params.title + ' ' + params.okButton" type="flat" color="primary" mat-dialog-close="ok">
              <span [translate]="params.okButton"></span>
          </app-fn-ui-button>
      </div>
  </ng-template>

</app-fn-ui-dialog>
