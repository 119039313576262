import { Injectable, InjectionToken } from '@angular/core';
import { Observable, map } from 'rxjs';
import { Entity } from '../../../shared/models/enums/entity.enum';
import { SearchQueryService } from '../../../shared/search/services/search-query.service';
import { MessagesCenterQueuesAlias } from '../../models/consts/messages-center-queues-alias.const';
import { FavoriteIdentifier } from '../models/favorite-identifier.model';
import { FavoritesTree } from '../models/favorite-tree.model';
import { Favorite } from '../models/favorite.model';
import { FavoritesApiService } from './favorites-api.service';
import { IEntityFavoritesService } from './interfaces/entity-favorites-service.interface';

export function EntityFavoritesFactory(entity: Entity) {
  return (favoritesApiService, searchQueryService) => new EntityFavoritesService(entity, favoritesApiService, searchQueryService);
}

export const ENTITY_FAVORITES_TOKEN = new InjectionToken<Array<IEntityFavoritesService>>('entity-favorites');
export const ENTITY_FAVORITES_DEPS = [FavoritesApiService, SearchQueryService];
export const FAVORITES = 'favorites';

@Injectable()
export class EntityFavoritesService implements IEntityFavoritesService {

  get entity(): Entity {
    return this._entity;
  }

  get favorites(): Array<Favorite> {
    return this._favorites as Array<Favorite>;
  }

  get tree(): FavoritesTree {
    return this._tree;
  }

  private _favorites: Array<Favorite>;
  private _tree: FavoritesTree;
  private _map: { [key: string]: Favorite };

  constructor(private _entity: Entity,
              private favoritesApiService: FavoritesApiService,
              private searchQueryService: SearchQueryService) { }

  getTree(): Observable<FavoritesTree> {
    return this.getAll().pipe(map((response: Array<Favorite>) => {
      this._tree = {
        id: `${FAVORITES}_${this.entity}`,
        alias: MessagesCenterQueuesAlias[this.entity],
        type: this.entity,
        favorites: response,
        isNode: true
      };
      return this.tree;
    }));
  }

  refreshTree(): Observable<FavoritesTree> {
    if (!this.tree) {
      return this.getTree();
    }
    return this.getAll().pipe(map((response: Array<Favorite>) => {
      this.tree.favorites = response;
      return this.tree;
    }));
  }

  getAll(): Observable<Array<Favorite>> {
    return this.favoritesApiService.getAll(this.entity).pipe(map(response => {
      this._favorites = response;
      this.setFavoritesEntityAndRoutingParams(response);
      this.initializeMap(response);
      return response;
    }));
  }

  add(favorite: Favorite, queueType: string, queueId?: string): Observable<void> {
    return this.favoritesApiService.add(favorite, this.entity, queueType, queueId);
  }

  update(favorite: Favorite, favoriteId: string): Observable<void> {
    return this.favoritesApiService.update(favorite, favoriteId, this.entity);
  }

  delete(favoriteId: string): Observable<void> {
    return this.favoritesApiService.delete(favoriteId, this.entity);
  }

  getFavorite(identifier: FavoriteIdentifier): Favorite {
    const key = this.getKey(identifier);
    return this._map && this._map[key];
  }

  private setFavoritesEntityAndRoutingParams(favorites: Array<Favorite>): void {
    favorites.forEach((favorite: Favorite) => {
      favorite.type = this.entity;
      favorite.routerLink = `/home/messages-center/favorites/${this.entity}`;
      favorite.stateName = `home.messagesCenter.layout.taskBar.favorites.entity.${this.entity}.grid`;
      favorite.stateParams = {
        queueId: favorite.id
      };
    });
  }

  private initializeMap(favorites: Array<Favorite>): void {
    this._map = {};
    favorites.forEach((favorite: Favorite) => {
      const key = this.getKey(favorite);
      this._map[key] = favorite;
    });
  }

  private getKey(identifier: FavoriteIdentifier): string {
    return this.searchQueryService.toString(identifier.searchQuery) + JSON.stringify(identifier.sortBy || []);
  }

}
