import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-hold-modal',
  templateUrl: './mop-selection-modal.component.html',
  styleUrls: ['./mop-selection-modal.component.scss']
})
export class MopSelectionModalComponent implements OnInit {
  mopObs: Promise<any[]>;
  constructor(@Inject(MAT_DIALOG_DATA) private _params, private http: HttpClient,
              private dialogRef: MatDialogRef<MopSelectionModalComponent>) { }
  get params() {
    return this._params;
  }
  close(data?) {
    this.dialogRef.close(data);
  }

  ngOnInit() {
    this.mopObs = this.http.get('do/liquidity/mopSelectionLists').toPromise() as Promise<any[]>;
  }
}
