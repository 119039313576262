export enum ProfileButtons {
  SAVE = 'SAVE',
  CLOSE = 'CLOSE',
  HOLD = 'HOLD',
  DELETE = 'DELETE',
  UNDO = 'UNDO',
  SAVEAS = 'SAVEAS',
  ACTIVATE = 'ACTIVATE',
  DECLINE = 'DECLINE',
  APPROVE = 'APPROVE',
  RETRACT = 'RETRACT',
  AUDIT = 'AUDIT',
  CREATE = 'CREATE',
  EXECUTE = 'EXECUTE',
  TASK_SCHEDULER = 'TASK_SCHEDULER',
  EXECUTION_HISTORY = 'EXECUTION-HISTORY',
  ATTACHMENTS = 'ATTACHMENTS-BUTTON',
  POPOUT = 'PROFILE-POPOUT'
}
