import { Injectable } from '@angular/core';
import { BaseNavigationService } from './base-navigation.service';
import { NavigationService } from '../../../core/navigation/services/navigation.service';
import { TranslateService } from '@ngx-translate/core';
import { MessagesType } from '../../../messages/models/enums/messages-type.enum';
import { MessagesManagementService } from '../../../messages/services/messages-management.service';

@Injectable()
export class BulksNavigationService extends BaseNavigationService {

  protected get systemQueueType() {
    return 'system_queues_bulks';
  }

  protected get customQueueType() {
    return 'custom_queues_bulks';
  }

  protected get entityTypeGrid() {
    return '.entity.bulks.grid';
  }

  get type() {
    return MessagesType.BULKS;
  }

  constructor(protected navigationService: NavigationService,
              protected translateService: TranslateService,
              protected entityService: MessagesManagementService) {
    super(navigationService, translateService, entityService);
  }
}
