<mat-hint *ngIf="errorForView === 'CommaPresence'"  [translate]="'message.validation_error.commaPresence'"></mat-hint>
<mat-hint *ngIf="errorForView === 'MinLengthIntegerPartInDecimal'" [translate]="'message.validation_error.minLengthIntegerPartInDecimal'" [translateParams]="{length: validationParam}"></mat-hint>
<mat-hint *ngIf="errorForView === 'NumberOfDigitsAfterPrecision'" [translate]="'message.validation_error.numberOfDigitsAfterPrecision'" [translateParams]="{length: validationParam}"></mat-hint>
<mat-hint *ngIf="errorForView === 'MaxLength'" [translate]="'message.validation_error.maxLength'" [translateParams]="{length: validationParam}"></mat-hint>
<mat-hint *ngIf="errorForView === 'ExactLength'" [translate]="'message.validation_error.exactLength'" [translateParams]="{length: validationParam}"></mat-hint>
<mat-hint *ngIf="errorForView === 'MandatoryField'" [translate]="'message.validation_error.mandatoryField'"></mat-hint>
<mat-hint *ngIf="errorForView === 'StartsWith'" [translate]="'message.validation_error.startsWith'" [translateParams]="{value: validationParam}"></mat-hint>
<mat-hint *ngIf="errorForView === 'MustNotStartWith'" [translate]="'message.validation_error.mustNotStartWith'" [translateParams]="{value: validationParam}"></mat-hint>
<mat-hint *ngIf="errorForView === 'MustNotEndWith'" [translate]="'message.validation_error.mustNotEndWith'" [translateParams]="{value: validationParam}"></mat-hint>
<mat-hint *ngIf="errorForView === 'CharNotAllowed'" [translate]="'message.validation_error.charNotAllowed'" [translateParams]="{value: validationParam}"></mat-hint>
<mat-hint *ngIf="errorForView === 'ValuesNotAllowed'" [translate]="'message.validation_error.valuesNotAllowed'" [translateParams]="{value: validationParam}"></mat-hint>
<mat-hint *ngIf="errorForView === 'BICLength'" [translate]="'message.validation_error.bic_length'" [translateParams]="{value: validationParam}"></mat-hint>
<mat-hint *ngIf="errorForView === 'PaymentError'"> {{validationParam}}</mat-hint>
<mat-hint *ngIf="errorForView === 'MultiFieldMaxLength'" [translate]="'message.validation_error.multiFieldMaxLength'"
[translateParams]="{field: multifieldName, length: validationParam}"></mat-hint>
<mat-hint *ngIf="errorForView === 'MultiFieldExactLength'" [translate]="'message.validation_error.multiFieldExactLength'"
[translateParams]="{field: multifieldName, length: validationParam}"></mat-hint>
<mat-hint *ngIf="errorForView === 'drillDownValueDoesNotExist'" [translate]="'message.validation_error.drill_down_value_does_not_exist'"></mat-hint>
<mat-hint *ngIf="errorForView === 'drillDownMultiValues'" [translate]="'message.validation_error.drill_down_multi_values'"></mat-hint>
<mat-hint *ngIf="errorForView === 'drillDownReciverError'" [translate]="'message.validation_error.drill_down_reciver_error'"></mat-hint>
<mat-hint *ngIf="errorForView === 'InvalidCurrency'" [translate]="'message.validation_error.invalid_currency'"></mat-hint>
<mat-hint *ngIf="errorForView === 'CharecterNotAllowed'" [translate]="'message.validation_error.charecter_not_allowed'"></mat-hint>
<mat-hint *ngIf="errorForView === 'DigitsOnly'" [translate]="'message.validation_error.digits_only'"></mat-hint>
<mat-hint *ngIf="errorForView === 'InStaticList'" [translate]="'message.validation_error.in_static_list'"></mat-hint>
<mat-hint *ngIf="errorForView === 'MultiFieldInStaticList'" [translate]="'message.validation_error.multi_field_in_static_list'"></mat-hint>
<mat-hint *ngIf="errorForView === 'NonNegativeValue'" [translate]="'message.validation_error.non_negative_value'"></mat-hint>
<mat-hint *ngIf="errorForView === 'allowedCharacters'" [translate]="'message.validation_error.allowedCharacters'"></mat-hint>
<mat-hint *ngIf="errorForView === 'MaxNumber'" [translate]="'message.validation_error.maxNumber'" [translateParams]="{number: validationParam}"></mat-hint>
<mat-hint *ngIf="errorForView === 'pattern'" [translate]="'message.validation_error.invalid_password'"></mat-hint>
<mat-hint *ngIf="errorForView === 'passwordConfirming'" [translate]="'message.validation_error.password_confirming'"></mat-hint>
<mat-hint *ngIf="errorForView === 'NccCodeLength'" [translate]="'message.validation_error.invalid_ncc_code_length'" [translateParams]="{minLength: validationParam[0], maxLength: validationParam[1]}"></mat-hint>
<mat-hint *ngIf="errorForView === 'IllegalIbanLength'" [translate]="'message.validation_error.illegalIbanLength'"></mat-hint>
<mat-hint *ngIf="errorForView === 'CapitalLettersAndDigits'" [translate]="'message.validation_error.capitalLettersAndDigits'"></mat-hint>
<mat-hint *ngIf="errorForView === 'CapitalLettersForIsoCountryCode'" [translate]="'message.validation_error.capitalLettersForIsoCountryCode'"></mat-hint>
<mat-hint *ngIf="errorForView === 'Chars3and4MustBeDigits'" [translate]="'message.validation_error.chars3and4MustBeDigits'"></mat-hint>
<mat-hint *ngIf="errorForView === 'InvalidIsoCountryCode'" [translate]="'message.validation_error.invalidIsoCountryCode'"></mat-hint>
<mat-hint *ngIf="errorForView === 'LengthNotDefinedForCountryCode'" [translate]="'message.validation_error.lengthNotDefinedForCountryCode'"></mat-hint>
<mat-hint *ngIf="errorForView === 'LengthDiffThenRequiredForCountryCode'" [translate]="'message.validation_error.lengthDiffThenRequiredForCountryCode'"></mat-hint>
<mat-hint *ngIf="errorForView === 'InvalidIbanCheckDigitFailed'" [translate]="'message.validation_error.invalidIbanCheckDigitFailed'"></mat-hint>
