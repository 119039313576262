import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {catchError, EMPTY, forkJoin, map, merge, Observable, of, startWith} from 'rxjs';
import dayjs from "dayjs";
import {ActivityLogResponse} from "../models/activity-log-response.model";
import {ActivityApiResponse} from "../interfaces/activity-api-response.interface";
import {BaseActivity} from "../interfaces/base-activity.interface";
import {ActivityLogItemResponse} from "../models/activity-log-item-response.model";
import {IActivityTrackerApiService} from "../interfaces/activity-tracker-api-service.interface";
import {ActivityLogType} from "../models/enums/activity-log-type.enum";

@Injectable()
export class ActivityTrackerApiService implements IActivityTrackerApiService {
  sort = `date desc`;
  resourceBaseUrl = '/payments/v1/messages/';
  ruleActivityUrl = `rules?sort=${this.sort}`;
  auditActivityUrl = `audit-entries?sort=${this.sort}`;
  errorActivityUrl = `errors?sort=${this.sort}`;

  private readonly _defaultActivityLog = {
    count: {
      audit: 0,
      rules: 0,
      errors: 0
    },
    items: []

  };

  get defaultActivityLog() {
    return structuredClone(this._defaultActivityLog);
  }

  constructor(protected http: HttpClient) { }

  getActivityLog(mid: string, limit = 100): Observable<ActivityLogResponse> {
    const activityLogRes = this.defaultActivityLog;
    return merge(
      this.getAuditObservable(mid, limit),
      this.getRulesObservable(mid, limit),
      this.getErrorsObservable(mid, limit)
    )
      .pipe(startWith(of(activityLogRes)),
        map((apiResponse: ActivityApiResponse) => {
      return this.parseActivityApiResponse(apiResponse, activityLogRes);
    }));
  }

  getCallToActionLog(mid: string): Observable<ActivityLogResponse> {
    const activityLogRes = this.defaultActivityLog;
    return forkJoin(
      // Skip user has opened payment audit log
      this.getAuditObservable(mid, 1, 'codeNotEquals=390'),
      this.getRulesObservable(mid, 1),
      this.getErrorsObservable(mid, 1)
    )
      .pipe(map((apiResponses: ActivityApiResponse[]) => {
        apiResponses.forEach(apiResponse => {
          this.parseActivityApiResponse(apiResponse, activityLogRes);
        })
        return activityLogRes;
        }));
  }

  parseActivityApiResponse(activityApiResponse: ActivityApiResponse, activityLogRes: ActivityLogResponse): ActivityLogResponse {
    if (activityApiResponse && activityApiResponse.links) {
      const isRule = activityApiResponse.links.self.href.indexOf('/rules?') !== -1;
      const isAudit = activityApiResponse.links.self.href.indexOf('/audit-entries?') !== -1;
      const isError = activityApiResponse.links.self.href.indexOf('/errors?') !== -1;
      if (isRule) {
        activityLogRes.count.rules = activityApiResponse._meta.itemCount;
      } else if (isAudit) {
        activityLogRes.count.audit = activityApiResponse._meta.itemCount
      } else if (isError) {
        activityLogRes.count.errors = activityApiResponse._meta.itemCount
      }
      activityLogRes.items = this.sortItems(activityApiResponse.items || [], activityLogRes.items);
    }
    return activityLogRes;
  }

  mapApiResponseToActivityLogItem(activityItems: Array<BaseActivity>): Array<ActivityLogItemResponse> {
    return (activityItems || []).map((activity: any) => {
      return {
        date: activity.date,
        code: activity.code,
        description: activity.description,
        parameters: activity.paramerter,
        user: activity.user,
        source: activity.source,
        typeId: activity.typeId,
        typeName: activity.typeName,
        name: activity.name,
        actionName: activity.actionName,
        detailsOfChange: activity.detailsOfChange
      } as ActivityLogItemResponse
    });
  }

  sortItems(apiItems: Array<BaseActivity>, exitingItems: Array<ActivityLogItemResponse>): Array<ActivityLogItemResponse> {
    const newData = this.mapApiResponseToActivityLogItem(apiItems);
    const sortedArray = [...newData, ...exitingItems].sort((a, b) => {
      const dayJsA = dayjs(a.date);
      const dayJsB = dayjs(b.date);
      // If any of the date is invalid return same order
      if (!dayJsA.isValid() || !dayJsB.isValid()) {
        return 0;
      } else if (dayJsB.isSame(dayJsA)) {
        // If dates are same Error and then Audit takes precedence
        if (b.source === ActivityLogType.ERROR || b.source === ActivityLogType.AUDIT) {
          return 1;
        }
        // If dates are same for the source return same order
        return 0;
      } else {
        return dayJsB.valueOf() - dayJsA.valueOf();
      }
    });
    return sortedArray;
  }

  private getAuditObservable(mid, limit, filter = null) {
    let url = `${this.resourceBaseUrl}${mid}/${this.auditActivityUrl}&limit=${limit}`;
    if (filter) {
      url += `&${filter}`
    }
    return this.http.get<ActivityApiResponse>(url).pipe(catchError(e => {
      console.error('Unable to get audit data', e);
      return of(this.defaultActivityLog);
    }));
  }

  private getRulesObservable(mid, limit) {
    return this.http.get<ActivityApiResponse>(`${this.resourceBaseUrl}${mid}/${this.ruleActivityUrl}&limit=${limit}`).pipe(catchError(e => {
      console.error('Unable to get rules data', e);
      return of(this.defaultActivityLog);
    }));
  }

  private getErrorsObservable(mid, limit) {
    return this.http.get<ActivityApiResponse>(`${this.resourceBaseUrl}${mid}/${this.errorActivityUrl}&limit=${limit}`).pipe(catchError(e => {
      console.error('Unable to get error data', e);
      return of(this.defaultActivityLog);
    }));
  }
}
