import { Injectable } from '@angular/core';
import { GridColumnsService } from '../../grid/services/grid-columns.service';
import { TranslateService } from '@ngx-translate/core';

const MSGPREFIX = 'logicalfields.column_alias';
@Injectable()
  export class MessageGridColumnsService extends GridColumnsService {
    protected getTranslateKey(key) {
        return MSGPREFIX + '.' + key;
      }
  }
