import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { SitemapNode, BaseNodeData } from '../../core/navigation/sitemap/models';
import { InsightTaskbarService } from '../services/insight-taskbar.service';

export const InsightTaskbarResolver: ResolveFn<SitemapNode<BaseNodeData>> =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): SitemapNode<BaseNodeData> => {

    const insightTaskbarService = inject(InsightTaskbarService);

    return insightTaskbarService.getGuardsAndResolversTaskbar(route);
  };
