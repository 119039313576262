export const ROUTES_MAPPING_RULES = 'business-rules';

export const ROUTES_MAPPING_LIQUIDITY = {
  'system-account-groups': 'system-account-groups',
  'custom-account-groups': 'custom-account-groups',
  'party-explorer': 'party-explorer',
  'favorites': 'favorites'
};

export const ROUTES_MAPPING_MESSAGES_CENTER = {
  'system-queues': 'system-queues',
  'custom-queues': 'custom-queues',
  'favorites': 'favorites',
  'templates': 'templates',
  'recent-searches': 'recent-searches',
  'callback': 'callback'
};

export class RoutesMappingLiquidity {

  static getLiquidityCategory(category): any {
    return ROUTES_MAPPING_LIQUIDITY[category];
  }

}


