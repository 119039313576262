import { ITextsBuilder } from '../../../../core/translations/services/texts-builder.interface';
import { FileAttachmentManagerTexts } from '../../../../../../../attachment/src/lib/file-attachment-manager/models/file-attachment-manager-texts.model';
import { TranslateService } from '@ngx-translate/core';
import { TextFormatterService } from '../../../../core/translations/services/text-formatter.service';
import { TextDelimiter } from '../../../../core/translations/models/text-delimiter.model';
import { Injectable } from '@angular/core';

@Injectable()
export class TextBuilderFileAttachmentDialogService implements ITextsBuilder<FileAttachmentManagerTexts> {
    private fileAttachmentManagerTexts: FileAttachmentManagerTexts = null;
    public fileSizeLimit: string;
    public fileTypes: string;

    constructor(private translateService: TranslateService,
                private textFormatterService: TextFormatterService) {
    }

    buildTexts(): FileAttachmentManagerTexts {
        this.initTexts();
        return this.fileAttachmentManagerTexts;
    }

    private initTexts() {
        if (this.fileAttachmentManagerTexts != null) {
            return;
        }

        const fileSize = this.textFormatterService.foramt(
            this.translateService.instant('dialog.file.attachment.file_size_instructions'),
            [this.fileSizeLimit],
            TextDelimiter.PLACEHOLDER);

        const fileSizeError = this.textFormatterService.foramt(
                this.translateService.instant('dialog.file.attachment.size_error_text'),
                [this.fileSizeLimit],
                TextDelimiter.PLACEHOLDER);

        const fileTypes = this.textFormatterService.foramt(
            this.translateService.instant('dialog.file.attachment.file_types_instructions'),
            [this.fileTypes],
            TextDelimiter.PLACEHOLDER);

        const fileTypesError = this.textFormatterService.foramt(
                this.translateService.instant('dialog.file.attachment.type_error_text'),
                [this.fileTypes],
                TextDelimiter.PLACEHOLDER);

        this.fileAttachmentManagerTexts = {
            title: this.translateService.instant('dialog.file.title'),
            closeDialog: this.translateService.instant('dialog.file.close_dialog'),
            confirmation: this.translateService.instant('dialog.file.confirmation'),
            confirmationAgree: this.translateService.instant('dialog.file.confirmation.agree'),
            confirmationDisagree: this.translateService.instant('dialog.file.confirmation.disagree'),
            sizeErrorText: fileSizeError,
            typeErrorText: fileTypesError,
            descriptionPlaceholder: this.translateService.instant('dialog.file.description_placeholder'),
            uploadInstructions: this.translateService.instant('dialog.file.attachment.upload_instructions'),
            browse: this.translateService.instant('dialog.file.attachment.browse'),
            fileSizeInstructions: fileSize,
            fileTypesInstructions: fileTypes,
            uploadButton: this.translateService.instant('dialog.file.upload_button'),
            uploadSuccesfull: this.translateService.instant('dialog.file.attachment.upload_succesfull'),
            noFilesAvailableYetText: this.translateService.instant('dialog.file.attachment.no_files_available'),
            description: this.translateService.instant('dialog.file.attachment.description'),
            userId: this.translateService.instant('dialog.file.attachment.user_id'),
            fileName: this.translateService.instant('dialog.file.attachment.file_name'),
            updatedDate: this.translateService.instant('dialog.file.attachment.updated_date'),
            readOnlyMode: this.translateService.instant('dialog.file.attachment.read_only_mode')
        };
    }
}
