export enum StandingOrderPaymentType {
  SWEEP = 'SWEEP',
  ORDER = 'ORDER'
}

export enum StandingOrderTemplateType {
  CT = '1',
  DD = '2',
}

export enum StandingOrderEndsWith {
  END_BY = '1',
  END_AFTER = '0'
}

export enum StandingOrderActivationStatus {
  OPEN = '0',
  CLOSED = '1'
}
export enum StandingOrderFrequencyPattern {
  DAILY = 'D',
  WEEKLY = 'W',
  MONTHLY = 'M',
  YEARLY = 'Y'
}

