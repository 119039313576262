import { AfterViewInit, ChangeDetectionStrategy, Component, ContentChild, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { MatMenu, MatMenuItem, MatMenuTrigger, MenuPositionX, MenuPositionY } from '@angular/material/menu';
import { FnUiMenuItemClickEvent } from './models/fn-ui-menu-item-click-event.model';
import { FnUiMenuItem } from './models/fn-ui-menu-item.model';

@Component({
  selector: 'app-fn-ui-menu',
  templateUrl: './fn-ui-menu.component.html',
  styleUrls: ['./fn-ui-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FnUiMenuComponent implements OnInit, AfterViewInit {

  @Input() items: Array<FnUiMenuItem>;
  @Input() triggerColor: string;
  @Input() triggerClass: string;
  @Input() triggerRestoreFocus: boolean;
  @Input() backdropClass: string;
  @Input() hasBackdrop = false;
  @Input() overlapTrigger: boolean;
  @Input() panelClass: string;
  @Input() xPosition: MenuPositionX = 'after';
  @Input() yPosition: MenuPositionY = 'below';
  @Input() disableRippleOnMenuItems: boolean;

  @Output() triggerClicked = new EventEmitter<MouseEvent>();
  @Output() menuItemClicked = new EventEmitter<FnUiMenuItemClickEvent>();
  @Output() menuOpened = new EventEmitter<void>();
  @Output() menuClosed = new EventEmitter<void>();

  @ContentChild('triggerContent', { static: true }) triggerTemplate: TemplateRef<ElementRef>;
  @ContentChild('menuItemContent', { static: true }) menuItemTemplate: TemplateRef<ElementRef>;

  @ViewChild('defaultTriggerContent', { static: true }) defaultTriggerTemplate: TemplateRef<ElementRef>;
  @ViewChild('defaultMenuItemContent', { static: true }) defaultMenuItemTemplate: TemplateRef<ElementRef>;
  @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger: MatMenuTrigger;
  @ViewChild(MatMenu, { static: true }) matMenu: MatMenu;
  @ViewChildren(MatMenuItem) matMenuItems: QueryList<MatMenuItem>;

  ngOnInit(): void {
    if (!this.triggerTemplate) {
      this.triggerTemplate = this.defaultTriggerTemplate;
    }
    if (!this.menuItemTemplate) {
      this.menuItemTemplate = this.defaultMenuItemTemplate;
    }
  }

  ngAfterViewInit(): void {
    this.setMenuItemsAdditionalAttributes();
  }

  onTriggerClick($event: MouseEvent): void {
    this.triggerClicked.emit($event);
  }

  onMenuItemClick($event: FnUiMenuItemClickEvent): void {
    this.menuItemClicked.emit($event);
  }

  onMenuOpen(): void {
    if (!this.hasBackdrop) {
      document.addEventListener('click', this.onDocumentClick);
    }
    this.menuOpened.emit();
  }

  onMenuClose(): void {
    if (!this.hasBackdrop) {
      document.removeEventListener('click', this.onDocumentClick);
    }
    this.menuClosed.emit();
  }

  openMenu(): void {
    this.matMenuTrigger.openMenu();
  }

  closeMenu(): void {
    this.matMenuTrigger.closeMenu();
  }

  private setMenuItemsAdditionalAttributes(): void {
    this.items.forEach((item: FnUiMenuItem, index: number) => {
      if (item.attributes) {
        const button = this.matMenuItems.get(index)['_elementRef'].nativeElement;
        Object.keys(item.attributes).forEach((key: string) => {
          button.setAttribute(key, item.attributes[key]);
        });
      }
    });
  }

  private onDocumentClick = ($event: MouseEvent): void => {
    const triggerElement = this.matMenuTrigger['_element'].nativeElement;
    const menuElement = this.matMenuTrigger['_overlayRef']._pane;
    if (!triggerElement.contains($event.target) && !menuElement.contains($event.target)) {
      this.closeMenu();
    }
  };

}
