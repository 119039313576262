import {
  BFEvents,
  ComponentManagerService,
  FormService,
  MultiLineComponentTemplate,
  MultiLineFormModalComponent
} from '@fgpp-ui/components';
import { Component, OnInit } from '@angular/core';
import { AccountGroups } from '../../../models/account-groups.model';

@Component({
  selector: 'app-account-multi-line',
  template: MultiLineComponentTemplate.HTML_TEMPLATE,
  styles: [MultiLineComponentTemplate.STYLE_TEMPLATE],
  providers: [
    FormService,
    ComponentManagerService
  ]
})
export class AccountsMultiLineFormComponent extends MultiLineFormModalComponent implements OnInit {

  ngOnInit(): void {
    super.ngOnInit();
    this.populateAccountFieldsOnDrilldownChange();
    this.disableControls();
  }

  private populateAccountFieldsOnDrilldownChange() {
    const subscription = this.formService.getEventBus().on(BFEvents.Type.DRILLDOWN).subscribe((change: BFEvents.DrillDownEvent) => {
      if (change.target === AccountGroups.ACC_NO) {
        if (change.value.inputValue && change.value.data && change.value.data.length) {
          this.formMgr.get(AccountGroups.ACC_TYPE).setValue(change.value.data[0]['ACCOUNTS-ACC_TYPE']);
          this.formMgr.get(AccountGroups.ACC_CURRENCY).setValue(change.value.data[0]['ACCOUNTS-CURRENCY']);
          this.formMgr.get(AccountGroups.ACC_CUST_CODE).setValue(change.value.data[0]['ACCOUNTS-CUST_CODE']);
          this.formMgr.get(AccountGroups.ACC_ACCOUNTNAME).setValue(change.value.data[0]['ACCOUNTS-ACCOUNT_NAME']);
          this.formMgr.get(AccountGroups.ACC_VIRTUAL_UID).setValue(change.value.data[0]['VIRTUAL.UID']);
        } else {
          this.formMgr.get(AccountGroups.ACC_TYPE).setValue('');
          this.formMgr.get(AccountGroups.ACC_CURRENCY).setValue('');
          this.formMgr.get(AccountGroups.ACC_CUST_CODE).setValue('');
          this.formMgr.get(AccountGroups.ACC_ACCOUNTNAME).setValue('');
          this.formMgr.get(AccountGroups.ACC_VIRTUAL_UID).setValue('');
        }
      }
    });
    this.subscriptions.add(subscription);
  }

  private disableControls() {
    const subscription = this.formService.getEventBus().on(BFEvents.Type.CANVAS).subscribe((status: BFEvents.CanvasEvent) => {
      if (status.value.state === 'ready') {
        this.formMgr.get(AccountGroups.ACC_TYPE).disable();
        this.formMgr.get(AccountGroups.ACC_CURRENCY).disable();
        this.formMgr.get(AccountGroups.ACC_CUST_CODE).disable();
        this.formMgr.get(AccountGroups.ACC_ACCOUNTNAME).disable();
      }
    });
    this.subscriptions.add(subscription);
  }
}
