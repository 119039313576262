<app-fn-ui-navigation-tree [treeConfig]="treeConfig"
                           [treeModel]="treeModel"
                           [selectedNode]="selectedNode"
                           (selectionChanged)="onSelectionChange($event)">

  <ng-template #node let-node="node">
    <span class="node-alias">{{ node.alias | translate }}</span>
    <ng-container [ngTemplateOutlet]="actionsMenu" [ngTemplateOutletContext]="{ node, actions: nodeActions }"></ng-container>
  </ng-template>

  <ng-template #leaf let-node="node">
    <span class="node-alias">{{ node.alias | translate }}</span>
    <ng-container [ngTemplateOutlet]="actionsMenu" [ngTemplateOutletContext]="{ node, actions: leafActions }"></ng-container>
  </ng-template>

</app-fn-ui-navigation-tree>

<ng-template #actionsMenu let-node="node" let-actions="actions">
    <app-fn-ui-menu *ngIf="actions"
                    [items]="actions"
                    class="node-actions-menu {{openedMenuComponent === nodeActionsMenu ? 'actions-menu-open' :  'actions-menu-close' }}"
                    triggerColor="primary"
                    triggerClass="actions-menu-trigger mat-icon-button"
                    panelClass="actions-menu-panel"
                    yPosition="above"
                    (triggerClicked)="onMenuTriggerClick($event)"
                    (menuItemClicked)="onMenuItemClick($event, node)"
                    (menuOpened)="onMenuOpen(nodeActionsMenu)"
                    (menuClosed)="onMenuClose(nodeActionsMenu)"
                    #nodeActionsMenu>
      <ng-template #triggerContent>
        <app-fn-ui-icon icon="dots_vertical" size="16px"></app-fn-ui-icon>
      </ng-template>
    </app-fn-ui-menu>
</ng-template>