import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, Directive } from '@angular/core';
import { AbstractFormControlComponent } from '../abstract-form-control/abstract-form-control.component';
import { FaFormComponent } from '../fa-form/fa-form.component';
import { FieldVerificationsService } from '../../services/field-verifications.service';
import { ControlContainer } from '@angular/forms';


@Directive()
export class AbstractFormControlInputComponent extends AbstractFormControlComponent implements OnInit {

  sizeToShowHint = 11;
  shouldShowHint: boolean;
  allowTypingMaxLength: number;
  passMaxLength = false;

  @Output() change = new EventEmitter<any>();
  @Input() maxLength: number;

  constructor(protected parentForm: ControlContainer,
              faFormComponent: FaFormComponent,
              renderer: Renderer2,
              protected elem: ElementRef,
              protected fieldVerificationsService: FieldVerificationsService) {
  super(parentForm, faFormComponent, renderer, elem, fieldVerificationsService);
  this.shouldShowHint = false;
}

  ngOnInit() {
    super.ngOnInit();
    // if maxLength is not an input - search validation on metadata
    if (this.metadata && !this.maxLength) {
      this.metadata.validations.forEach((validation, index) => {
        if (validation.name === 'MaxLength') {
          this.maxLength = validation.arguments;
        }
      });
    }
    if (this.maxLength && !this.metadata.readOnly) {
      this.allowTypingMaxLength = this.maxLength * 2;
      if (this.maxLength >= this.sizeToShowHint ) {
        this.shouldShowHint = true;
      }
    }
  }

  onInput(value) {
    if (this.maxLength) {
      this.handleIsPassMaxLengthCounter(value.target.value);
    }
  }

  onBlur(value, controller?) {
    if (this.maxLength) {
      this.handleIsPassMaxLengthValidation(value.target.value);
    }
  }

  handleIsPassMaxLengthCounter(value) {
    this.passMaxLength = value.length > this.maxLength;
  }

  handleIsPassMaxLengthValidation(value) {
    if (value.length > this.maxLength ) {
      this.controller.setErrors({ 'MaxLength': this.maxLength });
    } else {
      if (this.controller.hasError('MaxLength')) {
        delete this.controller.errors.maxLength;
      }
    }
  }

  onInputChange(event?) {
    this.passMaxLength = false;
  }
}
