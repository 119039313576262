import { Injectable } from '@angular/core';
@Injectable()
export class ActivityLogConstants {
  public static readonly NOTES = 'notes';
  public static readonly AUDIT = 'audit';
  public static readonly ERRORS = 'errors';
  public static readonly RULES = 'rules';
  public static readonly SPECIAL_INSTRUCTIONS = 'special instructions';
  public static readonly USER_NOTICE = 'user notice';
  public static readonly NOTE_ICON = 'speaker_notes';
  public static readonly AUDIT_ICON = 'border_color';
  public static readonly ERROR_ICON = 'report_problem';
  public static readonly RULE_ICON = 'call_split';
  public static readonly SPECIAL_INSTRUCTION_ICON = 'visibility';
  public static readonly USER_NOTICE_ICON = 'help';
}
