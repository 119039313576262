import { MainTab } from '../../core/navigation/models';
import { CustomAccountGroupsNavigationComponent }
  from '../../liquidity/components/navigation/custom-account-groups-navigation/custom-account-groups-navigation.component';
import { LiquidityFavoritesNavigationComponent }
  from '../../liquidity/components/navigation/liquidity-favorites-navigation/liquidity-favorites-navigation.component';
import { PartyExplorerNavigationComponent }
  from '../../liquidity/components/navigation/party-explorer-navigation/party-explorer-navigation.component';
import { SystemAccountGroupsNavigationComponent }
  from '../../liquidity/components/navigation/system-account-groups-navigation/system-account-groups-navigation.component';
import { LiquidityTaskBarItem } from '../../liquidity/models';
import { MessagesCenterCallbackQueuesComponent }
  from '../../messages-center/components/messages-center-callback/messages-center-callback-queues/messages-center-callback-queues.component';
import { MessagesCenterCustomQueuesComponent } from '../../messages/components/messages-center-custom-queues/messages-center-custom-queues.component';
import { MessagesCenterFavoritesQueuesComponent } from '../../messages/components/messages-center-favorites-queues/messages-center-favorites-queues.component';
import { MessagesCenterRecentSearchesComponent } from '../../messages/components/messages-center-recent-searches/messages-center-recent-searches.component';
import { MessagesCenterSystemQueuesComponent } from '../../messages/components/messages-center-system-queues/messages-center-system-queues.component';
import { MessagesCenterTemplatesComponent } from '../../messages/components/messages-center-templates/messages-center-templates.component';
import { QUEUE_TYPES } from '../../messages/models/consts/messages-queues.service';
import {RulesTaskBarItem} from "../../rules/enums/rules-task-bar-item.enum";
import {BusinessRulesComponent} from "../../rules/components/business-rules/business-rules.component";
import { PbiTaskbarItem } from '../../insight/enums/pbi-taskbar-item.enum';
import { InsightTreeComponent } from '../../insight/components/insight-tree/insight-tree.component';
import { ArchiveTaskbarItem } from '../../archive/enums/archive-taskbar-item.enum';
import { ArchiveTreeComponent } from '../../archive/archive-tree/archive-tree.component';

export class TaskbarItemConstants {

  public static readonly taskBarItemCodeToComponent = {
    [MainTab.INSIGHT]: {
      [PbiTaskbarItem.OPERATIONAL_DASHBOARD]: InsightTreeComponent,
      [PbiTaskbarItem.MANAGEMENT_REPORTS]: InsightTreeComponent,
      [PbiTaskbarItem.INSIGHT_DASHBOARDS]: InsightTreeComponent,
    },
    [MainTab.MESSAGES_CENTER]: {
      [QUEUE_TYPES.SYSTEM_QUEUE]: MessagesCenterSystemQueuesComponent,
      [QUEUE_TYPES.CUSTOM_QUEUE]: MessagesCenterCustomQueuesComponent,
      [QUEUE_TYPES.FAVORITE]: MessagesCenterFavoritesQueuesComponent,
      [QUEUE_TYPES.CALLBACK]: MessagesCenterCallbackQueuesComponent,
      [QUEUE_TYPES.RECENT_SEARCH]: MessagesCenterRecentSearchesComponent,
      [QUEUE_TYPES.TEMPLATES]: MessagesCenterTemplatesComponent
    },
    [MainTab.LIQUIDITY]: {
      [LiquidityTaskBarItem.SYSTEM_ACCOUNT_GROUPS]: SystemAccountGroupsNavigationComponent,
      [LiquidityTaskBarItem.CUSTOM_ACCOUNT_GROUPS]: CustomAccountGroupsNavigationComponent,
      [LiquidityTaskBarItem.FAVORITES]: LiquidityFavoritesNavigationComponent,
      [LiquidityTaskBarItem.PARTY_EXPLORER]: PartyExplorerNavigationComponent
    },
    [MainTab.RULES] : {
      [RulesTaskBarItem.BUSINESS_RULES]: BusinessRulesComponent
    },
    [MainTab.ARCHIVE] : {
      [ArchiveTaskbarItem.SEARCH_BUILDER]: ArchiveTreeComponent,
      [ArchiveTaskbarItem.RECENT_SEARCHES]: ArchiveTreeComponent,
      [ArchiveTaskbarItem.SAVED_SEARCHES]: ArchiveTreeComponent,
    },

  };

}
