import { GridFooterConfig } from '../../../../../grid/models/grid-footer-config.model';
import { GridContainerConfig } from '../../../../../grid/models/grid-container-config.model';
import { GridHeaderConfig } from '../../../../../grid/models/grid-header-config.model';
import { GroupActionsConfig } from '../../../../../grid/models/group-actions-config.model';

export const AuditTrailRulesConfigGridHeaderConfig: GridHeaderConfig = {
  showLastUpdate: false,
  showScopes: false,
  showRefresh: false,
  showLeftMenu: false,
  showRightMenu: true,
  showFavorite: false,
  showType: false,
  showTitle: true,
  showAutoFeeder: false,
  showFilter: false,
  showAllActions: false,
  showCreate: false,
  disableCreate: false,
  processingScope: false,
  showPrint: true,
  showExport: false,
  showColumnSettings: false
};

export const AuditTrailRulesGroupActionsConfig: GroupActionsConfig = {
  showGroupActionIcon: false,
  showBeforeAfter: false,
  showAddNotes: false,
  showGroupActions: false,
};

export const AuditTrailRulesFooterConfig: GridFooterConfig = {
  showResultsAndCounts : true,
  groupActions: AuditTrailRulesGroupActionsConfig,
  showCallerDetails: false,
  showCreateFromTemplate: false,
  showResultsWarning: false
};


export const AuditTrailRulesConfig: GridContainerConfig = {
  gridHeaderConfig: AuditTrailRulesConfigGridHeaderConfig,
  gridFooterConfig: AuditTrailRulesFooterConfig
};

