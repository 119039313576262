import {Component, OnInit} from '@angular/core';
import {BFEvents} from '@fgpp-ui/components';
import {Advising} from '../../models/advising.model';
import {
  BFOnValueChange, BFOnFormLoadComplete, BFOnButtonClick, BFBeforeProfileCreate, BFOnInit, BFBeforeProfileSaveAs,
} from '../../../core/interfaces/sdk-profile-hooks.interface';
import {ProfileFormTemplateProviders} from '../../../core/templates/profile-form.template.providers';
import {AbstractProfileFormComponent} from "../../abstract/abstract-profile/abstract-profile-form.component";
import {IValueChange} from "../../../core/interfaces/form-value-change.interface";
import {filter} from "rxjs";
import {takeUntil} from "rxjs/operators";

export enum AccountSource {
  INTERNAL = "0",
  EXTERNAL = "1"
}

@Component({
  selector: 'app-advising',
  templateUrl: '../../../core/templates/profile-form.template.html',
  styleUrls: ['../../../core/templates/profile-form.template.scss'],
  providers: ProfileFormTemplateProviders,
})
export class AdvisingComponent extends AbstractProfileFormComponent implements OnInit, BFOnInit, BFOnValueChange, BFOnFormLoadComplete, BFOnButtonClick, BFBeforeProfileCreate, BFBeforeProfileSaveAs {
  private defaultPartyCode: string;
  private accountSource: AccountSource;

  fgppOnInit(): void {
    this.drilldownEvent.listen().pipe(filter((e) => e.target === Advising.ACC_NO), takeUntil(this.destroy$)).subscribe((event) => {
      const data = event.value.data;
      const accSource = this.formMgr.get(Advising.ACC_SOURCE).getValue();
      if (data && data.length === 1 && accSource === AccountSource.INTERNAL) {
        this.formMgr.get(Advising.ACC_CCY).setValue(data[0]['ACCOUNTS-CURRENCY']);
      }
    });
  }

  fgppBeforeCreate() {
    this.validateExternalAccount();
  }

  fgppBeforeSaveAs() {
    this.validateExternalAccount();
  }

  private validateExternalAccount() {
    this.defaultPartyCode = this.getData()[Advising.DEFAULT_CUST_CODE];
    this.accountSource = this.getData()[Advising.ACC_SOURCE];
    if (!this.defaultPartyCode) {
      this.formMgr.get(Advising.ACC_SOURCE).disable();
    }
    this.handleAccountSourceValidations(this.accountSource);
  }

  fgppValueChanges(change: IValueChange): void {
    switch (change.control) {
      case Advising.CUST_CODE:
        this.onCustCodeChanges();
        break;
      case Advising.ACC_NO:
        this.onAccountNumberChanges();
        break;
      case Advising.ADVICE_TYPE:
        this.onAdviseTypeChanges();
        break;
      case Advising.ACC_SOURCE:
        this.formMgr.get(Advising.CUST_CODE).reset();
        this.formMgr.get(Advising.CUST_NAME).reset();
        this.formMgr.get(Advising.ACC_NO).reset();
        this.formMgr.get(Advising.ACC_CCY).reset();
        this.formMgr.get(Advising.ACC_TYPE).reset();
        this.handleAccountSourceValidations(change.value.inputValue);
        break;
    }
  }

  private onCustCodeChanges(): void {
    const custCode = this.formMgr.get(Advising.CUST_CODE).getValue();
    if (custCode) {
      const partyData = this.getDrilldownData(Advising.CUST_CODE);
      if (partyData) {
        this.formMgr.get(Advising.CUST_NAME).setValue(
          partyData['CUSTOMRS-CUST_NAME']
        );
      }
    } else {
      this.formMgr.get(Advising.CUST_NAME).reset();
    }
    this.formMgr.get(Advising.ACC_NO).reset();
    this.formMgr.get(Advising.ACC_CCY).reset();
  }

  private onAccountNumberChanges(): void {
    const accountNo = this.formMgr.get(Advising.ACC_NO).getValue();
    const accSource = this.formMgr.get(Advising.ACC_SOURCE).getValue();
    if (accountNo) {
      const accountData = this.getDrilldownData(Advising.ACC_NO);
      this.handleExternalAccountNumberValidations(accSource);
      if (accountData) {
        this.formMgr.get(Advising.ACC_CCY).setValue(accountData['ACCOUNTS-CURRENCY']);
        this.formMgr.get(Advising.ACC_TYPE).setValue(accountData['ACCOUNTS-ACC_TYPE']);
      } else {
        this.layoutEvent.setProperty(Advising.ACC_NO, 'checkManualEntry', true);
      }
      if (this.formMgr.get(Advising.ACC_NO).isInValid()) {
        this.formMgr.get(Advising.ACC_CCY).disable().markAsOptional().reset();
        this.formMgr.get(Advising.ACC_TYPE).disable().reset();
      }
    } else {
      this.formMgr.get(Advising.ACC_CCY).disable().markAsOptional().reset();
      this.formMgr.get(Advising.ACC_TYPE).disable().reset();
    }
  }

  private onAdviseTypeChanges(): void {
    const adviceType = this.getDrilldownData(Advising.ADVICE_TYPE);
    if (adviceType) {
      this.populateContactDetails(adviceType['ADVCTYPE.ADVC_VEHICLE']);
      if (adviceType['ADVCTYPE.ADVC_VEHICLE'] === null || adviceType['ADVCTYPE.ADVC_VEHICLE'] === '') {
        this.formMgr.get(Advising.CONTACT_NAME).reset();
        this.formMgr.get(Advising.CONTACT_DETAILS).reset();
        this.formMgr.get(Advising.ADVICE_SUB_TYPE).reset();
      }
    }
  }

  private handleAccountSourceValidations(accSource: AccountSource) {

    if (accSource === AccountSource.EXTERNAL) {
      this.formMgr.get(Advising.CUST_CODE).setValue(this.defaultPartyCode).disable();
      this.formMgr.get(Advising.ACC_CCY).disable().reset();
      this.formMgr.get(Advising.ACC_TYPE).disable().reset();
      this.layoutEvent.setProperty(Advising.ACC_NO, 'disableSeek', true);
      this.layoutEvent.setProperty(Advising.ACC_NO, 'checkManualEntry', false);
    } else if (accSource === AccountSource.INTERNAL) {
      this.formMgr.get(Advising.CUST_CODE).enable();
      this.layoutEvent.setProperty(Advising.ACC_NO, 'disableSeek', false);
      this.layoutEvent.setProperty(Advising.ACC_NO, 'checkManualEntry', true);
      this.formMgr.get(Advising.ACC_CCY).markAsOptional().disable();
      this.formMgr.get(Advising.ACC_TYPE).disable();
    }
  }

  private handleExternalAccountNumberValidations(accSource: AccountSource) {
    if (accSource === AccountSource.EXTERNAL) {
      this.formMgr.get(Advising.ACC_CCY).enable().markAsRequired();
      this.formMgr.get(Advising.ACC_TYPE).enable();
    } else {
      this.formMgr.get(Advising.ACC_CCY).disable().markAsOptional();
      this.formMgr.get(Advising.ACC_TYPE).disable();
    }
  }

  fgppOnButtonClick(actionPayload: BFEvents.ButtonEvent): void {
    if (actionPayload.target === 'SAVE' || actionPayload.target === 'CREATE') {
      const fieldsArray = [Advising.MIN_AMT];
      fieldsArray.forEach((field) => {
        const val = this.formMgr.get(field).getValue() == 0 ? '0.00' : this.formMgr.get(field).getValue();
        if (val !== '') {
          this.formMgr.get(field).setValue(val.toString());
        }
      });

      const adviceInd = this.formMgr.get(Advising.ADVICE_IND).getValue();
      this.formMgr.get(Advising.CR_ADVICE_IND).setValue('0');
      this.formMgr.get(Advising.DR_ADVICE_IND).setValue('0');
      this.formMgr.get(Advising.FEE_ADVICE_IND).setValue('0');
      if (adviceInd === 'CR') {
        this.formMgr.get(Advising.CR_ADVICE_IND).setValue('1');
      } else if (adviceInd === 'DR') {
        this.formMgr.get(Advising.DR_ADVICE_IND).setValue('1');
      } else if (adviceInd === 'FEE') {
        this.formMgr.get(Advising.FEE_ADVICE_IND).setValue('1');
      }
      if (this.formMgr.get(Advising.CONTACT_TYPE).getValue() === null || this.formMgr.get(Advising.CONTACT_TYPE).getValue() === '') {
        this.formMgr.get(Advising.CONTACT_TYPE).setValue('RM');
      }
    }
  }

  fgppFormLoadComplete(): void {
    if (this.formMgr.get(Advising.CR_ADVICE_IND).getValue() === '1') {
      this.formMgr.get(Advising.ADVICE_IND).setValue('CR');
    } else if (this.formMgr.get(Advising.DR_ADVICE_IND).getValue() === '1') {
      this.formMgr.get(Advising.ADVICE_IND).setValue('DR');
    } else if (this.formMgr.get(Advising.FEE_ADVICE_IND).getValue() === '1') {
      this.formMgr.get(Advising.ADVICE_IND).setValue('FEE');
    }

    if (this.formMgr.get(Advising.CONTACT_NAME).getValue() !== null && this.formMgr.get(Advising.CONTACT_NAME).getValue() !== '') {
      this.populateContactDetails(null);
    } else {
      this.populateContactDetails('M');
    }

    if (this.formMgr.get(Advising.MIN_AMT).getValue() === null || this.formMgr.get(Advising.MIN_AMT).getValue() === '') {
      this.formMgr.get(Advising.MIN_AMT).setValue('0.00');
    }
  }

  private populateContactDetails(advcVehicle: string) {
    if (advcVehicle === 'M') {
      this.formMgr.get(Advising.CONTACT_NAME).markAsOptional().setValue('').disable();
      this.formMgr.get(Advising.CONTACT_TYPE).markAsOptional().setValue('').disable();
      this.formMgr.get(Advising.CONTACT_DETAILS).markAsOptional().setValue('').disable();
      this.layoutEvent.hide(Advising.ADVICE_SUB_TYPE);
      this.formMgr.get(Advising.ADVICE_SUB_TYPE).markAsOptional().setValue('');
      this.collapseContactSection(true);
    } else {
      this.collapseContactSection(false);
      this.formMgr.get(Advising.CONTACT_NAME).markAsRequired().enable();
      this.formMgr.get(Advising.CONTACT_TYPE).markAsRequired().enable();
      this.formMgr.get(Advising.CONTACT_DETAILS).markAsRequired().enable();
      this.layoutEvent.show(Advising.ADVICE_SUB_TYPE);
      this.formMgr.get(Advising.ADVICE_SUB_TYPE).markAsRequired();
    }
  }

  private collapseContactSection(collapsed: boolean) {
    this.formService.getEventBus().emit<BFEvents.LayoutControlEvent>({
      type: BFEvents.Type.LAYOUT_CONTROL,
      target: 'CONTACT-INFO-SECTION',
      name: 'setAttribute',
      value: {
        property: 'collapsed',
        newValue: collapsed,
      },
    });
  }
}
