import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-fx-rate-retry-modal',
  templateUrl: './fx-rate-retry-modal.component.html',
  styleUrls: ['./fx-rate-retry-modal.component.scss']
})
export class FxRateRetryModalComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<FxRateRetryModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: string) {

  }

  ngOnInit(): void {
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  getNewRate(): void {
    this.dialogRef.close(true);
  }

}
