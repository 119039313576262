import { ElementRef, Input, OnInit, Renderer2, HostBinding, ViewChildren, Directive } from '@angular/core';
import { ControlContainer, ControlValueAccessor } from '@angular/forms';
import { FaFormComponent } from '../fa-form/fa-form.component';
import { FieldVerifications, FieldVerificationsService } from '../../services/field-verifications.service';
import { DhValidationErrorsMessageComponent } from '../validation-errors-message/validation-errors-message.component';

@Directive()
export class AbstractFormControlComponent implements OnInit, ControlValueAccessor {
  alias;
  formControl;
  renderer;
  controller;
  metadata;
  hint;
  mandatory;
  highlight;
  previousValue;
  faFormComponent;
  verification: FieldVerifications;

  //get component for showing payment error on focus
  @ViewChildren(DhValidationErrorsMessageComponent) dhValidationErrorsMessageComponent: DhValidationErrorsMessageComponent;
  // component id set to be logical field id
  @HostBinding('attr.id') id;

  @HostBinding('class.fa-mandatory') get isMandatory() {
    const validator = this.controller.validator != null ? this.controller.validator(this.formControl) : null;
    return validator != null && validator['MandatoryField'] != null;
  }

  @Input() formControlName;
  @Input() label;

  constructor(protected parentForm: ControlContainer,
              faFormComponent: FaFormComponent,
              renderer: Renderer2,
              protected elem: ElementRef,
              protected fieldVerificationsService: FieldVerificationsService) {
    this.formControl = elem.nativeElement;
    this.renderer = renderer;
    this.faFormComponent = faFormComponent;
  }

  /**
   * registers the controller and metadata of the form control
   */
  ngOnInit() {
    this.controller = this.parentForm.control.get(this.formControlName);
    this.metadata = this.faFormComponent ? this.faFormComponent.getFormData(this.formControlName) : null;
    this.id = this.formControlName;
    this.alignAccordingToMetadata(this.formControlName);
    if (!this.controller?.disabled && this.metadata != null) { //no need to run verification process if field is disabled
      this.initControlVerification();
    }
    const focusedElement: HTMLElement = this.formControl.getElementsByClassName('mat-form-field-infix')[0]?.children[0] as HTMLElement;
    focusedElement?.addEventListener('focus', this.showPaymentErrorOnFocus.bind(this));
  }

  showPaymentErrorOnFocus() {
    if (this.fieldVerificationsService.paymentError.fieldLogicalId === this.formControlName) {
      if (this.controller.errors) {
        this.controller.errors.PaymentError = true;
      } else {
        this.controller.setErrors({ 'PaymentError': true });
      }
      this.metadata.validations.unshift({
        name: this.fieldVerificationsService.paymentError.validations.name,
        arguments: this.fieldVerificationsService.paymentError.validations.arguments
      });
      if (this.dhValidationErrorsMessageComponent !== undefined) {
        this.dhValidationErrorsMessageComponent.showPaymentError(this.controller.errors);
      }
    }
  }

  private alignAccordingToMetadata(formControls) {
    if (Array.isArray(formControls)) {
      if (formControls.indexOf(this.formControlName) === -1) {
        return;
      }
    } else if (this.formControlName !== formControls) {
        return;
    }


    if (this.metadata && this.metadata.highlight) {
      this.renderer.addClass(this.formControl, 'fa-highlight');
    } else {
      this.renderer.removeClass(this.formControl, 'fa-highlight');
    }

    this.controller?.updateValueAndValidity({ onlySelf: true });
  }

  protected initControlVerification() {
    if (this.metadata.verification != null) {
      /// dirty fix for FRB stream only rekeyVerification => blindVerification
      const verifaction = this.metadata.verification;
      this.renderer.addClass(this.formControl, 'fa-' + verifaction);
      this.faFormComponent.addValidator(verifaction, this.formControlName);
      const pasteEventListener = this.renderer.listen(this.formControl, 'paste', (e) => {
        e.preventDefault();
        e.stopPropagation();
        return false;
      });
      this.fieldVerificationsService[verifaction](this.formControl, this.controller).then(() => {
        this.elem.nativeElement.blur();
        pasteEventListener();

        this.renderer.removeClass(this.formControl, 'fa-' + verifaction);
        this.renderer.addClass(this.formControl, 'fa-' + verifaction + '-verified');
        this.faFormComponent.removeValidator(verifaction, this.formControlName);
        this.controller.updateValueAndValidity({ onlySelf: true });
        this.controller.disable();
        this.controller.markAsDirty();
      });
    }
  }

  registerOnChange() {}
  registerOnTouched() {}
  writeValue() {}
}
