import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IDynamicModalDialogData } from '../../interfaces/dynamic-modal-dialog-data.interface';

@Component({
  selector: 'app-task-execution-history',
  templateUrl: './task-execution-history.component.html',
  styleUrls: ['./task-execution-history.component.scss']
})
export class TaskExecutionHistoryComponent {
  @Output() onClose = new EventEmitter<void>();
  @Input() dataEndpoint: string;
  public isGridView = true;
  public endpoint;
  constructor(@Inject(MAT_DIALOG_DATA) public dialogConfig: IDynamicModalDialogData,
              private matDialogRef: MatDialogRef<TaskExecutionHistoryComponent>) {
    this.endpoint = dialogConfig;
  }

  close(): void {
    this.onClose.emit();
    this.matDialogRef.close();
  }

}
