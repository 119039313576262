import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router } from '@angular/router';
import { map, Observable } from 'rxjs';
import { ALL_RULE_TYPES, PRULE_TYPES_RULE_TYPE_ID, RULE_TYPE_DELIMITER } from '../../models/consts/rule-type.consts';
import { RuleTypesApiService } from '../../services/rule-types-api.service';

@Injectable({
  providedIn: 'root'
})
export class RuleTypesGuard implements CanActivate {

  constructor(private router: Router,
              private ruleTypesApiService: RuleTypesApiService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (route.queryParams.ruleType === ALL_RULE_TYPES) {
      return this.ruleTypesApiService.search().pipe(map((response) => {
        const ruleType = response.rows.map(item => item[PRULE_TYPES_RULE_TYPE_ID]).join(RULE_TYPE_DELIMITER);
        this.router.navigate([state.url.split('?')[0]], { queryParams: { ...route.queryParams, ruleType } });
        return false;
      }));
    }
    return true;
  }

}
