export const CustomFieldSettings = {
  OFFICE: 'UDFS-OFFICE',
  DEPARTMENT: 'UDFS-DEPARTMENT',
  REC_STATUS: 'UDFS-REC_STATUS',
  PROFILE_CHANGE_STATUS: 'UDFS-PROFILE_CHANGE_STATUS',
  EFFECTIVE_DATE: 'UDFS-EFFECTIVE_DATE',
  UDF_ID: 'UDFS-UDF_ID',
  SCREEN_LABEL: 'UDFS-SCREEN_LABEL',
  DESCRIPTION: 'UDFS-DESCRIPTION',
  USAGE_TYPE: 'UDFS-USAGE_TYPE',
  UDF_TYPE: 'UDFS-UDF_TYPE',
  CUST_CODE: 'UDFS-CUST_CODE',
  OFFICE_CUST_CODE: 'UDFS-V_OFFICE_CUST_CODE',
  V_PROFILE_ALIAS: 'UDFS-V_PROFILE_ALIAS',
  PROFILE_ID: 'UDFS-PROFILE_ID',
  OBJECT_ID: 'UDFS-OBJECT-ID',
  DATA_TYPE: 'UDFS-DATA_TYPE',
  MIN_LENGTH: 'UDFS-MIN_LENGTH',
  TYPE_LENGTH: 'UDFS-TYPE_LENGTH',
  NUMBER_PRECISION: 'UDFS-NUMBER_PRECISION',
  LIST_ID: 'UDFS-LIST_ID',
  IS_AUTO_EXPOSE_MSG_UDF: 'UDFS-IS_AUTO_EXPOSE_MSG_UDF',
  TIME_STAMP: 'TIME_STAMP',
  UID: 'VIRTUAL-UID',
  PROFILE_UPDATE_PK: 'PROFILE_UPDATE_PK',
  PU_TIME_STAMP: 'PU_TIME_STAMP',
  NOTE: 'NOTE'
};
