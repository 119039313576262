import { Component, OnInit, OnDestroy, AfterViewInit, ElementRef } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { SystemVariables } from '../services/system-variables/models/system-variables.model';
import { SystemVariablesState } from '../state/system-variables.state';
import { UserMenuService } from './services/user-menu.service';
import { USER_MENU_ITEMS } from './models/user-menu-items.const';
import { UserMenuButton } from './models/user-menu-button.enum';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../../authentication/services/authentication.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: 'user-menu.component.html',
  styleUrls: [
    'user-menu.component.scss'
  ]
})
export class UserMenuComponent implements OnInit, OnDestroy, AfterViewInit {

  @Select(SystemVariablesState.getSystemVariables) systemVariables$: Observable<SystemVariables>;

  private _subscriber = new Subscription();
  productVersion;
  userId: string;
  userMenuItems = USER_MENU_ITEMS;
  private readonly maxDisplayName = 60;

  constructor(
    private userMenuService: UserMenuService,
    private $translate: TranslateService,
    private authenticationService: AuthenticationService,
    private elem: ElementRef) {
  }

  ngOnInit() {
    this.userId = this.authenticationService.userData.userId;
    this.subscribeToState();
  }

  prepareMenu(): void {
    this.setUserDisplayName();
    const versionTranslate = this.$translate.instant('userMenu.version');
    this.userMenuItems[0].children[2].children[0].displayName = `${versionTranslate}: ${this.productVersion}`;
  }


  subscribeToState(): void {
    const systemVariablesSub = this.systemVariables$.subscribe((systemVariables: SystemVariables) => {
      this.productVersion = systemVariables.PRODUCT_VERSION;
      this.prepareMenu();
    });
    this._subscriber.add(systemVariablesSub);
  }

  onClickMenuEvent($event: any): void {
    switch ($event.event) {
      case UserMenuButton.SETTING:
        return this.onSettingsClick();
      case UserMenuButton.HELP:
        return this.onOpenHelpPage();
      case UserMenuButton.FINASTRA_VERSION:
        return this.onFinastraVersionClick($event.displayName);
      case UserMenuButton.FINASTRA_PAGE:
        return this.onFinastraUrlClick();
      case UserMenuButton.LOGOUT:
        window.setTimeout(() => {
          return this.onSignOut();
        }, 0);
    }
  }


  onSettingsClick(): void {
    this.userMenuService.openUserSettings();
  }

  onOpenHelpPage(): void {
    this.userMenuService.openHelpPage();
  }

  onSignOut(): void {
    this.userMenuService.logOut();
  }

  onFinastraVersionClick($event): void {
    this.userMenuService.onFinastraVersion($event);
  }

  onFinastraUrlClick(): void {
    this.userMenuService.openFinastraPage();
  }

  private setUserDisplayName() {
    if(this.userId.length > this.maxDisplayName) {
      this.userMenuItems[0].displayName = this.userId.substring(0, this.maxDisplayName) + '...';
      this.userMenuItems[0].tooltip = this.userId;
    } else {
      this.userMenuItems[0].displayName = this.userId;
    }
  }

  ngOnDestroy(): void {
    this._subscriber.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.elem.nativeElement.setAttribute('azure-id', 'user-menu');
  }
}
