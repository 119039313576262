import { Injectable } from '@angular/core';
import { IEventTelemetry } from '@microsoft/applicationinsights-web';
import { UserNotification } from '../../../core/state/models/user-notification-state.model';
import { EventType } from '../../interfaces/event-type.enum';


@Injectable({
  providedIn: 'root'
})
export class UserNotificationFilterService {

  private messageFilterRegex = '(?<=mid\:\s)[^\s]+|(?<=uid\:\s)[^\s]+';
  constructor() { }

  public getMessageFromUserNotificationEvent(userNotification: UserNotification): IEventTelemetry {
    const eventTelemetry = {} as IEventTelemetry;
    eventTelemetry.name = EventType.NOTIFICATIONEVENT;
    eventTelemetry.properties = this.filterMessageForPrivateData(userNotification.message);
    return eventTelemetry;
  }

  private filterMessageForPrivateData(message: string): Object {
    return { text: message.replace(this.messageFilterRegex, '') };
  }

}


