export const TransferMethods = {
	'COUNTRY': 'COUNTRY_CURRENCY-COUNTRY',
	'CURRENCY': 'COUNTRY_CURRENCY-CURRENCY',
	'DEPARTMENT': 'COUNTRY_CURRENCY-DEPARTMENT',
	'EFFECTIVE_DATE': 'EFFECTIVE_DATE',
	'OFFICE': 'COUNTRY_CURRENCY-OFFICE',
	'PROFILE_CHANGE_STATUS': 'COUNTRY_CURRENCY-PROFILE_CHANGE_STATUS',
	'REC_STATUS': 'COUNTRY_CURRENCY-REC_STATUS',
	'TRANSFER_METHOD': 'COUNTRY_CURRENCY-TRANSFER_METHOD',
	'TIME_STAMP': 'TIME_STAMP',
	'UID': 'VIRTUAL-UID',
	'PROFILE_UPDATE_PK': 'PROFILE_UPDATE_PK',
	'PU_TIME_STAMP': 'PU_TIME_STAMP',
	'NOTE': 'NOTE'
};
