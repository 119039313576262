import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Router } from '@angular/router';
import { MainViewComponent } from '../../base-view/components/main-view/main-view.component';
import { SitemapNode } from '../../core/navigation/sitemap/models';
import { PowerBIReportMetadata } from '../power-bi/models/power-bi-report-metadata.model';
import { PowerBINavigationService } from '../power-bi/services/power-bi-navigation.service';
import { InsightNavigationService } from '../services/insight-navigation.service';
import { InsightTaskbarService } from '../services/insight-taskbar.service';
import {PbiTaskbarItem} from "../enums/pbi-taskbar-item.enum";

@Injectable()
export class PowerBITaskbarGuard implements CanActivate, CanDeactivate<MainViewComponent> {

  constructor(private router: Router,
              private insightTaskbarService: InsightTaskbarService,
              private insightNavigationService: InsightNavigationService,
              private powerBINavigationService: PowerBINavigationService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean | UrlTree> {
    const taskBarItem = route.paramMap.get('taskBarItem');
    if (taskBarItem === PbiTaskbarItem.OPERATIONAL_DASHBOARD) {
      return true;
    }

    if (!this.insightNavigationService.isPbi(taskBarItem)) {
      return false;
    }

    const pbiTaskBar = this.insightTaskbarService.getGuardsAndResolversTaskbar(route);
    if (pbiTaskBar.nodes?.length && route.queryParams.report) {
      return true;
    }
    const pbiNodes = this.powerBINavigationService.getNodes(taskBarItem);
    return pbiNodes.then((nodes: Array<SitemapNode<PowerBIReportMetadata>>) => {
      pbiTaskBar.nodes = nodes;
      if (!route.queryParams.report && nodes?.length) {
        return this.router.createUrlTree([state.url], { queryParams: { report: nodes[0].id } });
      }
      return true;
    }).finally(() => {
      this.powerBINavigationService.finalizeNodeRequest();
    });
  }

  canDeactivate(component: MainViewComponent, currentRoute: ActivatedRouteSnapshot,
                currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): boolean {
    this.powerBINavigationService.cancelNodesRequest();
    return true;
  }

}
