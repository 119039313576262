import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FnUiSelectComponent } from './fn-ui-select.component';

@NgModule({
  declarations: [
    FnUiSelectComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatSelectModule
  ],
  exports: [
    FnUiSelectComponent
  ]
})
export class FnUiSelectModule { }
