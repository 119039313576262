export enum FxProvider {
  'FX_PROVIDER_NAME' = 'FX_PROVIDERS-FX_PROVIDER_NAME',
  'OFFICE' = 'FX_PROVIDERS-OFFICE',
  'DEPARTMENT' = 'FX_PROVIDERS-DEPARTMENT',
  'PARTY_TYPE' = 'FX_PROVIDERS-PARTY_TYPE',
  'PARTY_CODE' = 'FX_PROVIDERS-PARTY_CODE',
  'SETTLEMENT_ACCT_NB' = 'FX_PROVIDERS-SETTLEMENT_ACCT_NB',
  'SETTLEMENT_ACCT_OFFICE' = 'FX_PROVIDERS-SETTLEMENT_ACCT_OFFICE',
  'SETTLEMENT_ACCT_CCY' = 'FX_PROVIDERS-SETTLEMENT_ACCT_CCY',
  'SUSP_ACCT_NB' = 'FX_PROVIDERS-SUSP_ACCT_NB',
  'SUSP_ACCT_OFFICE' = 'FX_PROVIDERS-SUSP_ACCT_OFFICE',
  'SUSP_ACCT_CCY' = 'FX_PROVIDERS-SUSP_ACCT_CCY',
  'SEND_DRAWDOWN_POSS_OFFSET' = 'FX_PROVIDERS-SEND_DRAWDOWN_POSS_OFFSET',
  'USE_BANK_FOR_NEG_OFFSET' = 'FX_PROVIDERS-USE_BANK_FOR_NEG_OFFSET',
  'FUNDING_METHOD' = 'FX_PROVIDERS-FUNDING_METHOD',
  'PROFILE_CHANGE_STATUS' = 'FX_PROVIDERS-PROFILE_CHANGE_STATUS',
  'PENDING_ACTION' = 'FX_PROVIDERS-PENDING_ACTION',
  'EFFECTIVE_DATE' = 'FX_PROVIDERS-EFFECTIVE_DATE',
  'REC_STATUS' = 'FX_PROVIDERS-REC_STATUS',
  'PARTY_NAME' = 'FX_PROVIDERS-PARTY_NAME'
}
