<div class="grid" [appSpinner]="isFetchingData">
  <app-grid-header [gridHeaderConfig]="gridContainerConfig.gridHeaderConfig"
                   [scopes]="scopes"
                   [queueTitle]="queueTitle"
                   [lastUpdate]="lastUpdate"
                   [totalCount]="gridData.totalCount"
                   [selectedScope]="selectedScope"
                   [entity]="entity"
                   (create)="onCreateProfile()"
                   (columnSettings)="onColumnSettingsClick()"
                   (export)="onExportClick()"
                   (print)="onPrintClick()"
                   (refresh)="onRefreshClick()"
                   (scopeChanged)="onScopeChanged($event)"
                   [displayTour]="displayTour"
  >
  </app-grid-header>

  <lib-grid [config]="gridConfig"
            [columns]="gridColumns"
            [data]="gridData"
            [selectedFilters]="selectedActionFilters"
            [leftFooterTemplate]="footerTemplateRef"
            [keyId]="keyId"
            [routingData]="routingData"
            (filterChanged)="onFilterChanged($event)"
            (pageChanged)="onPageChanged($event)"
            (rowClicked)="onRowClicked($event)"
            (selectionChanged)="onSelectionChanged($event)"
            (sortChanged)="onSortChanged($event)"
            (pinChanged)="onColumnPinChange($event)"
            (columnSizeChanged)="onColumnSizeChanged()"
            (refineFilteringClick)="onRefineFilteringClick($event)"
            (operatorChanged)="onOperatorChanged($event)">
  </lib-grid>

</div>

<ng-template #footerTemplateRef>
  <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
  <app-grid-footer *ngIf="gridContainerConfig.gridFooterConfig"
                   [gridFooterConfig]="gridContainerConfig.gridFooterConfig"
                   [groupActions]="groupActions"
                   [totalCount]="gridData.totalCount"
                   [selectedRows]="selectedRows"
                   (actionClicked)="onActionClick($event)">
  </app-grid-footer>
</ng-template>
