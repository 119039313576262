import {Component, OnInit} from '@angular/core';
import {AbstractProfileFormComponent} from '../../abstract/abstract-profile/abstract-profile-form.component';
import { ProfileFormTemplateProviders } from '../../../core/templates/profile-form.template.providers';
import {BFProfileOnSave} from "../../../core/interfaces/sdk-profile-hooks.interface";

@Component({
  selector: 'app-document-template',
  templateUrl: '../../../core/templates/profile-form.template.html',
  styleUrls: ['../../../core/templates/profile-form.template.scss'],
  providers: ProfileFormTemplateProviders
})
export class DocumentTemplateComponent extends AbstractProfileFormComponent implements OnInit, BFProfileOnSave {

  fgppOnSave(): boolean {
    const mandatoryFieldsEntered = super.checkRequired();
    const details = this.formMgr.get('DETAILS').getValue();
    const multilineEntered = details && (Array.isArray(details) && details.length > 0);
    if (mandatoryFieldsEntered && !multilineEntered) {
      this.notificationService.error(this.translate.instant('business-framework.profiles.952.DETAILS.requiredError'));
    }
    return mandatoryFieldsEntered &&  multilineEntered;
  }
}
