import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { FnUiIconModule } from '../fn-ui-icons/fn-ui-icon/fn-ui-icon.module';
import { FnUiMenuComponent } from './fn-ui-menu.component';

@NgModule({
  declarations: [
    FnUiMenuComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatMenuModule,
    TranslateModule,
    FnUiIconModule
  ],
  exports: [
    FnUiMenuComponent
  ]
})
export class FnUiMenuModule { }
