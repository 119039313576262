export const PRULE_TYPES_CLASSIFICATION_LEVEL = 'PRULE_TYPES-CLASSIFICATION_LEVEL';
export const PRULE_TYPES_RULE_TYPE_ID = 'PRULE_TYPES-RULE_TYPE_ID';
export const PRULES_RULE_TYPE_ID = 'PRULES-RULE_TYPE_ID';
export const PRULE_TYPES_RULE_TYPE_NAME = 'PRULE_TYPES-RULE_TYPE_NAME';

export const RULE_TYPE_TRANSLATION_PREFIX = 'rule_type.';

export const RULE_TYPE_DELIMITER = '_';
export const ALL_RULE_TYPES = 'all';

