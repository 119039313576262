import { Component, Input } from '@angular/core';
import { Severity } from './enums/severity.enum';

@Component({
  selector: 'app-fn-ui-severity-icon',
  templateUrl: './fn-ui-severity-icon.component.html',
  styleUrls: ['./fn-ui-severity-icon.component.scss']
})
export class FnUiSeverityIconComponent {

  @Input() severity: Severity;
  @Input() width = '15px';
  @Input() height = '20px';

}
