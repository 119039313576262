import { Clipboard } from '@angular/cdk/clipboard';
import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { NotificationService } from '@fgpp-ui/components';
import { TranslateService } from '@ngx-translate/core';
import { FnUiPopoverComponent } from '../../../../shared/fn-ui-popover/fn-ui-popover.component';
import { RuleUniqueData } from '../../models';
import { LogicalField } from '../../models/logical-field.model';
import { RuleService } from '../../services/rule.service';

@Component({
  selector: 'app-restricted-logical-field-popover',
  templateUrl: './restricted-logical-field-popover.component.html',
  styleUrls: ['./restricted-logical-field-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RestrictedLogicalFieldPopoverComponent {

  @Input() logicalField: LogicalField;

  @ViewChild(FnUiPopoverComponent, { static: true }) popoverComponent: FnUiPopoverComponent;

  constructor(private ruleService: RuleService,
              private clipboard: Clipboard,
              private notificationService: NotificationService,
              private translateService: TranslateService) { }

  onCopyToClipboardClick(): void {
    const text = this.getRulesAsText();
    this.copyToClipboard(text);
    this.closePopover();
  }

  onRejectClick(): void {
    this.closePopover();
  }

  closePopover(): void {
    this.popoverComponent.closePopover();
  }

  private copyToClipboard(text: string): void {
    if (!text) {
      return;
    }
    const success = this.clipboard.copy(text);
    if (success) {
      this.onCopySuccess();
    } else {
      this.onCopyFailed();
    }
  }

  private getRulesAsText(): string {
    const rules = this.ruleService.bcRestrictedLogicalFields[this.logicalField.id] || [];
    const ruleType = this.translateService.instant('rules.delete_base_condition.ruleType');
    const ruleName = this.translateService.instant('rules.delete_base_condition.ruleName');
    return rules.map((rule: RuleUniqueData) => `${ruleType}: ${rule.typeId} ${ruleName}: ${rule.name}`).join('\n');
  }

  private onCopySuccess(): void {
    const message = this.translateService.instant('rules.clipboard_restricted_fields.successfully');
    this.notificationService.success(message);
  }

  private onCopyFailed(): void {
    const message = this.translateService.instant('rules.clipboard_restricted_fields.unsuccessfully');
    this.notificationService.error(message);
  }

}
