import { Injectable } from '@angular/core';

@Injectable()
export class BrowsersService {

  isIE(): boolean {
    const ua = navigator.userAgent;
    /* MSIE used to detect old browsers and Trident used to newer ones*/
    return ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;
  }

  isChrome(): boolean {
    return !!( <any> window ).chrome && !!( <any> window ).chrome.webstore;
  }
}
