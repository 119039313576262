export const getEffectiveDateDialogLayout = () => ({
    properties: {
      controls: {
        'EFFECTIVE_DATE': {
          controlType: 'datetimepicker',
          position: 1,
          colSpan: 12,
          hidden: false
        },
        NOTE: {
          controlType: 'textarea',
          colSpan: 12,
          position: 2,
          rows: 4
        }
      }
    }
});
