export enum GridEventType {
    CREATE = 'create',
    FAVORITES = 'favorites',
    REFRESH = 'refresh',
    TRASH_CAN = 'trashCan',
    ADVANCED_FILTER = 'advancedFilter',
    BASIC_FILTER = 'basicFilter',
    ADVANCED_FILTER_APPPLY = 'advancedFilterApply',
    BASIC_FILTER_SEARCH = 'basicFilterSearch',
    PIN = 'pin',
    SORT = 'sort',
    PAGINATION = 'pagination',
    ITEMS_PER_PAGE = 'itemsPerPage',
    SHOW_MATCHING = 'showMatching',
    EXIT_MATCHING = 'exitMatching',
    CHECK_ITEM = 'checkItem',
    UNCHECK_ITEM = 'uncheckItem',
    CHECK_ALL = 'checkAll',
    UNCHECK_ALL = 'uncheckAll',
    ENTER_ROW = 'enterRow',
    GROUP_ACTIONS = 'groupActions',
    BEFORE_AFTER = 'beforeAfter',
    FILTER_MENU_TRIGGER = 'filterMenuTrigger',
    // auto feeder
    SIMPLE_AUTO_FEEDER = 'simpleAutoFeeder',
    SORTED_AUTO_FEEDER = 'sortedAutoFeeder',
    PRIORITEZED_AUTO_FEEDER = 'prioritizedAutoFeeder',
    SELECTED_AUTO_FEEDER = 'selectedAutoFeeder',
    // processing scope
    PROCESSING_TODAY = 'processingToday',
    PROCESSING_FUTURE = 'processingFuture',
    COMPLETED_TODAY = 'completedToday',
    RESET = 'reset',
    // Actions
    COLUMN_SETTINGS = 'columnSettings',
    EXPORT = 'export',
    PRINT = 'print',
    // menu operators
    CONTAINS = 'contains',
    STARTS_WITH = 'startsWith',
    ENDS_WITH = 'endsWith',
    EQUAL = 'equal',
    NOT_EQUAL = 'notEqual',
    NOT_CONTAINS = 'notContains',
    BETWEEN = 'between',
    GREATER_THAN = 'greaterThan',
    LESS_THAN = 'lessThan',
    GREATER_THAN_OR_EQUAL = 'greaterThanOrEqual',
    LESS_THAN_OR_EQUAL = 'lessThanOrEqual',
    IS = 'is',
    IS_EMPTY = 'isEmpty',
    IN = 'in',
    //status active
    ACTIVE = 'active',
    IN_ACTIVE = 'inActive',
    ALL = 'all',
    PENDING_APPROVALS = 'pendingApprovals'
}
