export enum ProfileActions {
  CONTRACT = 'contract',
  LAYOUTS = 'layout',
  CREATE = 'create',
  GRID = 'grid',
  SAVE = 'save',
  AUDIT = 'audit',
  HOLD = 'hold',
  DELETE = 'delete',
  RETRACT = 'retract',
  ACTIVATE = 'activate',
  APPROVE = 'approve',
  DECLINE = 'decline',
  CLOSE = 'close',
  DATA = 'data',
  NEW = 'new',
  EXECUTE = 'execute',
  EXECUTION_HISTORY = 'executionHistory',
  TASK_SCHEDULER = 'taskScheduler',
  L10N = 'l10n',
  RELATED_ENTITY = 'relatedEntities'
}
