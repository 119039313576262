import { Injectable } from '@angular/core';
import { SetMessagesCenterQueueId } from '../../core/state/actions/set-messages-center-queueId.actions';
import { StoreService } from '../../core/services/store.service';
import { MessagesCenterQueue } from '../../core/models/messages-center-queue.model';

@Injectable()
export class MessagesQueuesStateService {

  constructor(private storeService: StoreService) { }
  public dispatchQueue(queueId: string, queueType: string, entityType: string, uid?: string): void {
    this.storeService.dispatch(new SetMessagesCenterQueueId({
      id: queueId,
      type: queueType,
      entityType: entityType,
      uid: uid,
    } as MessagesCenterQueue));
  }

  public resetState(): void {
    this.storeService.dispatch(new SetMessagesCenterQueueId({
      id: null,
      type: null,
      entityType: null
    }));
  }
}
