import { AccountGroupsComponent } from './profiles/components/account-groups/account-groups.component';
import { TransferMethodsComponent } from './profiles/components/transfer-methods/transfer-methods.component';
import { CalendarComponent } from './profiles/components/calendar/calendar.component';
import { AdvisingComponent } from './profiles/components/advising/advising.component';
import { NgModule } from '@angular/core';
import { ComponentsModule } from '@fgpp-ui/components';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DynamicModalComponent } from './core/components/dynamic-modal/dynamic-modal.component';
import { FieldsToMaskComponent } from './profiles/components/masking/modals/fields-to-mask/fields-to-mask.component';
import { ProfileAuditTrailComponent } from './core/components/profile-audit-trail/profile-audit-trail.component';
import { IdentifiersComponent } from './profiles/components/identifiers/identifiers.component';
import { MaskingComponent } from './profiles/components/masking/masking.component';
import { StandingOrderComponent } from './profiles/components/standing-order/standing-order.component';
import { TaskExecutionHistoryComponent } from './core/components/task-execution-history/task-execution-history.component';
import { DepartmentComponent } from './profiles/components/department/department.component';
import { TaskSchedulerModalComponent } from './tasks/components/task-scheduler-modal/task-scheduler-modal.component';
import { MessageCodewordsComponent } from './profiles/components/message-codewords/message-codewords.component';
import { DrawdownComponent } from './profiles/components/drawdown/drawdown.component';
import { TranslateModule } from '@ngx-translate/core';
import { SystemParametersComponent } from './profiles/components/system-parameters/system-parameters.component';
import { BfPopoutService } from './core/services/bf-popout-service';
import { ProfileActionErrorHandlerService } from './profiles/services/profile-action-error-handler.service';
import { RulesRelatedProfileHandlerService } from './profiles/services/rules-related-profile-handler.service';
import { FnUiDialogModule } from '../shared/fn-ui-dialog/fn-ui-dialog.module';
import { ReasonsComponent } from './profiles/components/reasons/reasons.component';
import { FnUiIconModule } from '../shared/fn-ui-icons/fn-ui-icon/fn-ui-icon.module';
import { FnUiNotificationModule } from '../shared/fn-ui-notification/fn-ui-notification.module';
import { FnUiModalModule } from '../shared/fn-ui-modals/fn-ui-modal.module';
import { BfNewTabService } from './core/services/bf-new-tab.service';
import { AccountsMultiLineFormComponent } from './profiles/components/account-groups/modals/accounts-mutli-line-form.component';
import { UserVacationComponent } from './profiles/components/user-vacation/user-vacation.component';
import { environment } from '../../environments/environment';
import { BusinessFrameworkMessagesModule } from './messages/business-framwork-messages.module';
import { MetadataService } from './core/services/metadata.service';
import {CountryInfoComponent} from "./profiles/components/country-info/country-info.component";
import {GenericProfileComponent} from "./profiles/components/generic-profile/generic-profile.component";
import { AccountAdditionalOwnersComponent } from './profiles/components/account-additional-owners/account-additional-owners.component';
import { ErrcodesComponent } from './profiles/components/errcodes/errcodes.component';
import { UniqueIdFormatComponent } from './profiles/components/unique-id-format/unique-id-format.component';
import { FieldFormatFormModalComponent } from './profiles/components/unique-id-format/modals/field-format-form-modal/field-format-form-modal.component';
import {GenericTaskComponent} from "./tasks/components/generic-task/generic-task.component";
import { CurrencyCfgComponent } from './profiles/components/currency-cfg/currency-cfg.component';
import { BillingComponent } from './profiles/components/billing/billing.component';
import { CompanyComponent } from './profiles/components/company/company.component';
import { PaymentAddressComponent } from './profiles/components/payment-address/payment-address.component';
import {TimeZonesComponent} from "./profiles/components/time-zones/time-zones.component";
import { CutoffTimesComponent } from './profiles/components/cutoff-times/cutoff-times.component';
import {ParticipantAvailabilityComponent} from "./profiles/components/participant-availability/participant-availability.component";
import {FieldsToParticipantAvailabilityComponent} from "./profiles/modals/fields-to-participant-availability.component";
import {DocumentTemplateComponent} from "./profiles/components/document-template/document-template.component";
import {InterfaceTypesComponent} from "./profiles/components/interface-types/interface-types.component";
import {
  CallerAssociatedAccountComponent
} from "./profiles/components/caller-associated-account/caller-associated-account.component";
import {CallerComponent} from "./profiles/components/caller/caller.component";
import { ValueDateDeterminationComponent } from './profiles/components/value-date-determination/value-date-determination.component';
import { FileCutoffTimesComponent } from './profiles/components/file-cutoff-times/file-cutoff-times.component';
import { NccComponent } from './profiles/components/ncc/ncc.component';
import { CustomFieldSettingsComponent } from './profiles/components/custom-field-settings/custom-field-settings.component';
import { AccountAliasComponent } from './profiles/components/account-alias/account-alias.component';
import { DNEStopComponent } from './profiles/components/dne-stop/dne-stop.component';
import { PartyValidationsComponent } from './profiles/components/party-validations/party-validations.component';
import { ErrorCodeMappingComponent } from './profiles/components/error-code-mapping/error-code-mapping.component';
import { SenderCreditLineComponent } from './profiles/components/sender-credit-line/sender-credit-line.component';
import { CreditorIdStructureComponent } from './profiles/components/creditor-id-structure/creditor-id-structure.component';
import { CountryPreferencesComponent } from './profiles/components/country-preferences/country-preferences.component';
import { MOPReleaseDateComponent } from './profiles/components/mop-release-date/mop-release-date.component';
import { ServiceLevelAgreementComponent } from './profiles/components/service-level-agreement/service-level-agreement.component';
import {CurrencyPreferencesComponent} from "./profiles/components/currency-preferences/currency-preferences.component";

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatExpansionModule, MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    MatIconModule,
    MatProgressSpinnerModule,
    ComponentsModule.forRoot({
      language: 'en-US',
      production: environment.production
    }),
    TranslateModule.forChild({
      isolate: true
    }),
    FnUiDialogModule,
    FnUiIconModule,
    FnUiNotificationModule,
    FnUiModalModule,
    BusinessFrameworkMessagesModule,
  ],
  declarations: [
    UserVacationComponent,
    TransferMethodsComponent,
    AccountGroupsComponent,
    AccountsMultiLineFormComponent,
    CalendarComponent,
    AdvisingComponent,
    IdentifiersComponent,
    DynamicModalComponent,
    ProfileAuditTrailComponent,
    TaskExecutionHistoryComponent,
    MaskingComponent,
    FieldsToMaskComponent,
    GenericProfileComponent,
    StandingOrderComponent,
    DepartmentComponent,
    TaskSchedulerModalComponent,
    MessageCodewordsComponent,
    DrawdownComponent,
    SystemParametersComponent,
    ReasonsComponent,
    CountryInfoComponent,
    AccountAdditionalOwnersComponent,
    UniqueIdFormatComponent,
    FieldFormatFormModalComponent,
    ErrcodesComponent,
    GenericTaskComponent,
    CurrencyCfgComponent,
    BillingComponent,
    CompanyComponent,
    PaymentAddressComponent,
    TimeZonesComponent,
    CutoffTimesComponent,
    FileCutoffTimesComponent,
    ParticipantAvailabilityComponent,
    FieldsToParticipantAvailabilityComponent,
    DocumentTemplateComponent,
    InterfaceTypesComponent,
    CallerAssociatedAccountComponent,
    CallerComponent,
    ValueDateDeterminationComponent,
    NccComponent,
    CustomFieldSettingsComponent,
    AccountAliasComponent,
    DNEStopComponent,
    PartyValidationsComponent,
    ErrorCodeMappingComponent,
    SenderCreditLineComponent,
    CreditorIdStructureComponent,
    CountryPreferencesComponent,
    MOPReleaseDateComponent,
    ServiceLevelAgreementComponent,
    CurrencyPreferencesComponent
  ],
  exports: [
    GenericTaskComponent,
    CountryInfoComponent,
    AccountAdditionalOwnersComponent,
    UserVacationComponent,
    AccountGroupsComponent,
    AccountsMultiLineFormComponent,
    TransferMethodsComponent,
    CalendarComponent,
    AdvisingComponent,
    IdentifiersComponent,
    ProfileAuditTrailComponent,
    TaskExecutionHistoryComponent,
    MaskingComponent,
    FieldsToMaskComponent,
    GenericProfileComponent,
    StandingOrderComponent,
    DepartmentComponent,
    MessageCodewordsComponent,
    DrawdownComponent,
    ReasonsComponent,
    SystemParametersComponent,
    UniqueIdFormatComponent,
    FieldFormatFormModalComponent,
    CompanyComponent,
    PaymentAddressComponent,
    CutoffTimesComponent,
    FileCutoffTimesComponent,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatExpansionModule, MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    MatIconModule,
    MatProgressSpinnerModule,
    ComponentsModule,
    TranslateModule,
    FnUiDialogModule,
    FnUiIconModule,
    FnUiNotificationModule,
    FnUiModalModule,
    BusinessFrameworkMessagesModule,
    TimeZonesComponent,
    ParticipantAvailabilityComponent,
    FieldsToParticipantAvailabilityComponent,
    DocumentTemplateComponent,
    InterfaceTypesComponent,
    CallerAssociatedAccountComponent,
    CallerComponent,
    ValueDateDeterminationComponent,
    NccComponent,
    CustomFieldSettingsComponent,
    AccountAliasComponent,
    DNEStopComponent,
    PartyValidationsComponent,
    ErrorCodeMappingComponent,
    SenderCreditLineComponent,
    CreditorIdStructureComponent,
    CountryPreferencesComponent,
    MOPReleaseDateComponent,
    ServiceLevelAgreementComponent
  ],
  providers: [
    BfPopoutService,
    ProfileActionErrorHandlerService,
    RulesRelatedProfileHandlerService,
    BfNewTabService,
    MetadataService
  ]
})

export class BusinessFrameworkModule { }
