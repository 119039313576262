import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { InitializeFeatureFlags } from '../../store/feature-flags/feature-flags.actions';
import { FeatureFlagsState } from '../../store/feature-flags/feature-flags.state';
import { IFeatureFlagsService } from './interfaces/feature-flags-service.interface';

@Injectable()
export class FeatureFlagsService implements IFeatureFlagsService {

  constructor(private store: Store) { }

  initialize(): void {
    this.store.dispatch(new InitializeFeatureFlags());
  }

  isFeatureOn(name: string): boolean {
    return this.store.selectSnapshot(FeatureFlagsState.isFeatureOn)(name);
  }

  isFeatureOff(name: string): boolean {
    return this.store.selectSnapshot(FeatureFlagsState.isFeatureOff)(name);
  }

}
