import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, HostListener } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '@fgpp-ui/components';
import { AuthenticationApiService } from '../../services/authentication-api.service';
import { SystemVariablesService } from '../../../core/services/system-variables/system-variables.service';
import { PasswordValidationService } from '../../../core/user-settings/services/password-validation.service';

@Component({
  selector: 'app-change-expired-password',
  templateUrl: './change-expired-password.component.html',
  styleUrls: ['./change-expired-password.component.scss']
})
export class ChangeExpiredPasswordComponent implements OnInit {
  form: FormGroup;
  passwordPattern: string;
  ldapDomains: string;
  newPassword: string;
  // confirmPassword: string;
  authenticationInProgress = false;
  credentials = { userId: null, password: null, domain: null };
  capsLock = false;

  private _error: string;
  private message: string;
  private userId: string;


  constructor(private formBuilder: FormBuilder,
              private notificationService: NotificationService,
              private authenticationApiService: AuthenticationApiService,
              private systemVariablesService: SystemVariablesService,
              private passwordValidationService: PasswordValidationService,
              private route: ActivatedRoute,
              private router: Router) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      'username': ['', [Validators.required]],
      'password': ['', [Validators.required]],
      'newPassword': ['', [Validators.required, this.passwordValidationService.validPassword(true)]],
      // 'confirmPassword': ['', [Validators.required]]
    });

    this.ldapDomains = this.systemVariablesService.getSingleVariable('ldapDomains');
    this.passwordPattern = this.systemVariablesService.getSingleVariable('passwordValidationPattern');

    this.userId = this.route.snapshot.paramMap.get('userId');
    this.message = this.route.snapshot.paramMap.get('message');

    if (this.message !== undefined) {
      this.notificationService.error('Your password has expired and must be changed');
    }

    if (this.userId !== undefined) {
      this.credentials.userId = this.userId;
    }
  }

  changeExpiredPassword(): void {
    this.authenticationInProgress = true;
    const credentials = {
      userId: this.credentials.userId,
      password: this.credentials.password,
      newPassword: this.newPassword
    };
    this.authenticationApiService.changePassword(credentials).subscribe({
      next: () => {
        this.notificationService.success('Your password has been changed successfully');
        this.router.navigateByUrl('user/signin');
        this.authenticationInProgress = false;
      },
      error: (response: HttpErrorResponse) => {
        if (response.error.type && response.error.type === 'PasswordStrengthException') {
          this._error = 'The password you entered doesn’t meet the minimum security requirements';
        } else {
          this._error = response.error.message;
        }
        this.authenticationInProgress = false;
        this.notificationService.error(this._error);
      }
    });
    // clean password data
    this.credentials.password = '';
    this.newPassword = '';
    // this.confirmPassword = '';
  }

  onNewPasswordChange(): void {
    const field = this.form.get('confirmPassword');
    field.setValue('');
  }

  getError(formControl: AbstractControl): string {
    const config = {
      'required': 'You must enter a password',
      'validateEqual': 'No match, try again',
      'pattern': 'Not valid, try again',
      'invalidPassword': ''
    };
    //changes for the defect 175122
    if (formControl.errors !== null) {
      return config[Object.keys(formControl.errors)[0]] ? config[Object.keys(formControl.errors)[0]] : '';
    }
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDownShowCapLock(event): void {
    this.capsLock = event.getModifierState && event.getModifierState('CapsLock');
  }

  toggle(field: any): void {
    field.type = field.type === 'password' ? 'text' : 'password';
  }

}
