<form class="dh-signin-form" [formGroup]="form" #signupForm="ngForm" (ngSubmit)="onFormSubmit()" autocomplete="off">
  <div class="dh-product-title">Payments To Go</div>
  <div class="dh-product-Samll-title">Two factor authentication</div>
  <mat-hint *ngIf="isChallengeExist">Please enter your response code below</mat-hint>
  <mat-hint *ngIf="!isChallengeExist">Please enter your PIN below</mat-hint>
  <br/>
  <mat-hint *ngIf="isChallengeExist">Challenge Code : {{challengCode}}</mat-hint>
  <mat-form-field [floatLabel]="'always'" class="Challenge Response field">
    <input matInput
           id="signin-challenge-res-field"
           type="password"
           autocomplete="off"
           [disableControl]="authenticationInProgress"
           formControlName="password"
           (keydown)="onKeyDownShowCapLock($event)"
           #passwordField>
    <mat-hint>Challenge Response</mat-hint>
  </mat-form-field>
  <div>
    <button mat-raised-button
            id="signin-login-button"
            class="login-button"
            color="primary"
            [disabled]="authenticationInProgress"
            type="submit">
      <span class="dh-text-label">Authenticate</span>
    </button>
    <button mat-raised-button
            (click)="onBackClick()"
            id="back-button"
            class="back-button"
            color="primary"
            type="submit">
      <span class="dh-text-label">Back</span>
    </button>
  </div>
  <span class="login-caps-lock-warning" *ngIf="capsLock"><app-fn-ui-icon icon="report_problem" size="14px"></app-fn-ui-icon>Caps Lock is on</span>
</form>
