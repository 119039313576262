import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { TaskBarService } from '../core/navigation/task-bar/services/task-bar.service';

export const TaskBarResolver: ResolveFn<void> =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void => {

    const taskBarService = inject(TaskBarService);

    taskBarService.init();
  };
