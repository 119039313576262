import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { GridColumn } from '@fgpp-ui/grid';
import { GridApiRoutes } from '../models/enums/grid-api.routes';
import { GridSearchRequest } from '../models/grid-search-request.model';
import { GridPreferences } from '../models/grid-preferences.model';
import { memoize, unmemoize } from '../../core/services/memoized.service';
import { GridApiCacheService } from './grid-api-cache.service';

@Injectable()
export abstract class GridApiService {
  static cache: Map<string, GridPreferences> = new Map();

  constructor(protected http: HttpClient) { }

  getMetaData(baseUrl: string, httpParams: HttpParams): Promise<any> {
    return this.http.get(`${baseUrl}${GridApiRoutes.metadata}`, { params: httpParams }).toPromise();
  }

  @memoize({ keyResolver: GridApiCacheService.getCacheKeyGridColumns })
  getGridsColumns(baseUrl: string, httpParams: HttpParams): Promise<any> {
    return this.http.get(`${baseUrl}${GridApiRoutes.gridColumns}`, { params: httpParams }).toPromise();
  }

  getSearch<T extends GridSearchRequest>(searchRequest: T, baseUrl: string, httpParams?: HttpParams): Promise<any> {
    return this.http.post(`${baseUrl}${GridApiRoutes.search}`, searchRequest, { params: httpParams }).toPromise();
  }

  saveColumns(gridColumns: GridColumn[], baseUrl: string, httpParams?: HttpParams, applyAllQueues = false): Promise<void> {
    const url = `${baseUrl}${GridApiRoutes.columns}`;
    const columns = gridColumns.map((gridColumn: GridColumn) => {
      return {
        fieldId: gridColumn.name,
        width: gridColumn.width,
        isPinned: gridColumn.pinned,
        sort: gridColumn.sort
      };
    });
    return this.http.put<void>(url, columns, { params: httpParams }).toPromise();
  }

  deleteColumnSettings(baseUrl: string, httpParams?: HttpParams): Promise<void> {
    const url = `${baseUrl}${GridApiRoutes.columns}`;
    return this.http.delete<void>(url, { params: httpParams }).toPromise();
  }

  @memoize({ keyResolver: GridApiCacheService.getCacheKeyGridPreferences })
  getGridPreferences(baseUrl: string, httpParams?: HttpParams): Promise<GridPreferences> {
    return this.http.get<GridPreferences>(`${baseUrl}${GridApiRoutes.gridPreferences}`, { params: httpParams }).toPromise();
  }

  @unmemoize({
    keyResolver: (gridPreferences: GridPreferences, baseUrl: string, httpParams?: HttpParams) =>
      GridApiCacheService.getCacheKeyGridPreferences(baseUrl, httpParams)
  })
  saveGridPreferences(gridPreferences: GridPreferences, baseUrl: string, httpParams?: HttpParams): Promise<void> {
    return this.http.put<void>(`${baseUrl}${GridApiRoutes.gridPreferences}`, gridPreferences, { params: httpParams }).toPromise();
  }
}
