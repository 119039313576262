import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DrillDownGridService } from '../../grid/services/drill-down-grid.service';
import { Entity } from '../models/enums/entity.enum';
import { FnUiDrilldownFilterParams } from './models/fn-ui-drill-down-filter-params.model';
import { FnUiDrillDownResult } from './models/fn-ui-drill-down-result.modelt';

@Component({
  selector: 'app-fn-ui-drill-down',
  templateUrl: './fn-ui-drill-down.component.html',
  styleUrls: ['./fn-ui-drill-down.component.scss']
})
export class FnUiDrillDownComponent implements OnInit, AfterViewInit {

  private drillDownUniqueId: number;
  private _drillDownId: string;

  @Input() filterParameters: FnUiDrilldownFilterParams;
  @Input() dhDrillDown: string;
  @Input() preventMultiSelect: boolean;
  @Input() inputValue: string;
  @Input() inputLabel: string;
  @Input() readOnly: boolean;
  @Input() entity: Entity;
  @Input() id: string;
  @Input() required: boolean;
  @Input() name: string;
  @Input() keyId: string;

  @Output() rowSelected = new EventEmitter<FnUiDrillDownResult>();

  get drillDownId(): string {
    return this._drillDownId;
  }

  constructor(private element: ElementRef, private drillDownGridService: DrillDownGridService) {
    this.drillDownUniqueId = Date.now();
  }

  ngOnInit(): void {
    this._drillDownId = this.id || this.name || this.drillDownUniqueId++ + 'DD';
    this.name = this.name || this._drillDownId;
  }

  ngAfterViewInit(): void {
    this.element.nativeElement.setAttribute('azure-id', this.inputLabel);
  }

  handleDrillDown(): void {
    const data = this.getDrillDownDialogData();
    const dialogRef = this.drillDownGridService.openDialog(data);
    dialogRef.afterClosed().subscribe(result => {
      this.handleModalResults(result);
    });
  }

  private getDrillDownDialogData() {
    const isMultiSelect = !this.preventMultiSelect;
    const selectType = this.dhDrillDown === 'SELECT';
    return {
      'entity': this.getEntity(),
      'baseUrl': this.drillDownGridService.getBaseUrl(selectType, this.dhDrillDown, this.entity),
      'searchFilter': this.getSearchFilter(),
      'additionalParameters': this.drillDownGridService.getAdditionalParameters(this.filterParameters, selectType),
      'title': this.inputLabel,
      'alias': this.inputLabel,
      'isSelectType': selectType,
      'multiSelect': isMultiSelect,
      'selectedRows': this.getSelectedRows() || [],
      'inputValue': this.inputValue,
      'keyId': this.keyId,
    };
  }

  private getSelectedRows() {
    return this.inputValue ? this.inputValue.split(',')?.map(value => {
      const obj = {};
      obj[this.keyId] = value;
      return obj;
    }) : [];
  }

  private getSearchFilter() {
    const filter = {
      'searchFilter': this.filterParameters ? this.filterParameters.searchFilter : {}
    };
    const filterKeys = filter.searchFilter ? Object.keys(filter.searchFilter) : [];
    const iSSelectType = this.dhDrillDown === 'SELECT';
    return this.drillDownGridService.getPreDefinedFacets(filter, '', this.inputLabel, filterKeys, '', iSSelectType);
  }

  private handleModalResults(selectedRows) {
    if (Array.isArray(selectedRows)) {
      this.rowSelected.emit({ selectedRows: selectedRows });
    }
  }

  private getEntity(): Entity {
    return this.entity ? this.entity : Entity.PROFILES;
  }

}
