import { Injectable } from '@angular/core';
import { IStateStoreService } from './interfaces/state-store-service.interface';

export const ROUTER_STATE_KEY = 'router-state';

@Injectable()
export class StateStoreService implements IStateStoreService {

  constructor() {}

  public saveRouterState(url: string): boolean {
    if (url.indexOf('page-not-found') !== -1) {
      return false;
    }
    sessionStorage.setItem(ROUTER_STATE_KEY, url);
    return true;
  }

  public getRouterState(): string {
    return sessionStorage.getItem(ROUTER_STATE_KEY);
  }

  public clearRouterState(): void {
    sessionStorage.removeItem(ROUTER_STATE_KEY);
  }

}
