import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot } from '@angular/router';
import { NotificationService } from '@fgpp-ui/components';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import { map, Observable, Subject } from 'rxjs';
import { ModalsService } from '../../../shared/fn-ui-modals/services/modals.service';
import { ResponseInfo } from '../../../shared/models/response-info.model';
import { FileDownloaderService } from '../../../shared/services/file-downloader.service';
import { ConditionOperandComponent } from '../components/condition-operand/condition-operand.component';
import {
  Rule, RuleCriteria, ContentType, ConditionsBankTree, ConditionsBankNode, RuleMetadata, ChangeRuleStatusRequest, SearchBaseConditionResponse,
  BaseConditionUsedProfilesResponse, ApproveDeclineRequest, RulePayload, LogicalField, ConditionFunction
} from '../models';
import { Dates } from '../models/consts/rule-config.const';
import { AttachedRuleHandlerService } from './attached-rule-handler.service';
import { RuleConditionsAlgorithmsService } from './rule-conditions-algorithms.service';
import { RuleConditionsService } from './rule-conditions.service';
import { RuleModalsService } from './rule-modals.service';
import { RuleService } from './rule.service';

export const ERROR_CODES = {
  DELETE_RELATED: '657',
  SAVE_PENDING_ON_EDIT: '99136',
  SAVE_PENDING_ON_CREATE: '99137'
};

@Injectable()
export class RuleCommonService {

  operandInFocus: boolean;
  rule = {} as Rule;
  originalRule = {} as Rule;
  NtreeOriginalRuleConditions = {} as RuleCriteria;
  isNeedCheckBeforeClose = true;
  operatorsChangedOnInit = 0;
  ruleTypes: string;
  operandWithOpenedPopover: ConditionOperandComponent;
  auditTrailVisible = new Subject();

  show = {
    previewRule: false,
    auditTrail: false,
    conditionsBank: true,
    isTileSelected: true,
    isToggleClicked: false
  };

  isDropped = {
    data: false
  };

  private readonly conditionsLimit = 10;
  private readonly halfConditionsLimit = this.conditionsLimit / 2;
  private readonly conditionTypes = [ContentType.FIELD, ContentType.FUNCTION, ContentType.BASE_CONDITION];
  private countRecentlyUsedConditions = 0;

  isReadOnly: boolean;
  modalVisible: boolean;
  LAYER_CLASSIFICATION_RELATION = undefined;

  focusedOperand: ConditionOperandComponent;
  loseFocusOperand: ConditionOperandComponent;

  get ruleTextHolderElement(): Element {
    return document.querySelector('.preview-rule-content');
  }

  constructor(private router: Router,
              private notificationService: NotificationService,
              private translateService: TranslateService,
              private modalsService: ModalsService,
              private fileDownloaderService: FileDownloaderService,
              private attachedRuleHandlerService: AttachedRuleHandlerService,
              private ruleConditionsAlgorithmsService: RuleConditionsAlgorithmsService,
              private ruleConditionsService: RuleConditionsService,
              private ruleModalsService: RuleModalsService,
              private ruleService: RuleService) { }

  setOperandFocusedFlag(value: boolean): void {
    this.operandInFocus = value;
  }

  checkBeforeCloseRule(nextState: RouterStateSnapshot): Observable<boolean> | boolean {
    const isChanged = this.isRuleChanged();
    return isChanged ? this.openConfirmModal(nextState) : this.doCloseRule(nextState);
  }

  initConditions(conditionsData: any) {
    this.ruleConditionsService.countTreeLevels(conditionsData.conditions, conditionsData);
    this.NtreeOriginalRuleConditions = JSON.parse(JSON.stringify(conditionsData));
    this.ruleConditionsService.flatOriginalRuleConditions = this.ruleConditionsService.createFlatConditionArray(this.NtreeOriginalRuleConditions);
    this.ruleConditionsService.flatRuleConditionsOnInit = this.ruleConditionsService.createFlatConditionArray(conditionsData);
  }

  isRuleChanged(): boolean {
    const currentRule = JSON.parse(JSON.stringify(this.rule));
    const originalRule = JSON.parse(JSON.stringify(this.originalRule));
    originalRule.CONDITIONS = JSON.parse(JSON.stringify(this.NtreeOriginalRuleConditions));
    currentRule.CONDITIONS = JSON.parse(JSON.stringify(this.ruleConditionsService.conditions));
    if (!originalRule.EXPIRY_DATE) {
      originalRule.EXPIRY_DATE = Dates.DEFAULT_MAX_DATE_TIMESTAMP;
    }

    if (currentRule.EFFECTIVE_DATE) {
      currentRule.EFFECTIVE_DATE = currentRule.EFFECTIVE_DATE.toString();
    }

    if (currentRule.EXPIRY_DATE) {
      currentRule.EXPIRY_DATE = currentRule.EXPIRY_DATE.toString();
    }

    if (originalRule.EFFECTIVE_DATE) {
      originalRule.EFFECTIVE_DATE = originalRule.EFFECTIVE_DATE.toString();
    }

    if (originalRule.EXPIRY_DATE) {
      originalRule.EXPIRY_DATE = originalRule.EXPIRY_DATE.toString();
    }

    const originalEffectiveDate = dayjs(parseInt(originalRule.EFFECTIVE_DATE));
    const currentEffectiveDate = dayjs(parseInt(currentRule.EFFECTIVE_DATE));

    if (originalEffectiveDate.isValid() && currentEffectiveDate.isValid()) {
      const diff = Math.abs(originalEffectiveDate.diff(currentEffectiveDate, 'seconds'));

      if (diff < 60) {
        //in case there is a difference of less then 1 min , we simply make the values the same
        //please note : these are cppied and we are not changing the original object
        //this is done only for the comparison by angular.equals
        currentRule.EFFECTIVE_DATE = originalRule.EFFECTIVE_DATE.toString();
      }
    }

    const modifiedCurrentRuleObj = this.modifyRuleObject(currentRule);
    const modifiedOriginalRuleObj = this.modifyRuleObject(originalRule);
    return JSON.stringify(modifiedOriginalRuleObj) !== JSON.stringify(modifiedCurrentRuleObj);
  }

  closeRule(nextState?: RouterStateSnapshot): void {
    if (!this.isNeedCheckBeforeClose) {
      this.operatorsChangedOnInit = 0;
      this.rule.RULE_TYPE_ID = undefined;
      this.originalRule.RULE_TYPE_ID = undefined;
      this.ruleService.conditionsBank = undefined;
      this.ruleService.flatConditionsBank = undefined;
      this.ruleConditionsService.checkedConditionsCount = 0;
    }
    if (nextState != null) {
      this.router.navigateByUrl(nextState.url);
    } else {
      this.goToRulesGrid();
    }
  }

  getConditionsBankData(): ConditionsBankTree {
    return this.ruleService.conditionsBank;
  }

  getFlatConditionsBank(): Array<ConditionsBankNode> {
    return this.ruleService.flatConditionsBank;
  }

  conditionBankItemSelected(item: ConditionsBankNode): void {
    if (this.focusedOperand) {
      this.focusedOperand.changeOperand(item);
    }
  }

  togglePreviewRulePane(isInitial?: boolean): void {
    if(!isInitial) {
      this.show.isToggleClicked = true;
    }
    if (!this.show.previewRule) {
      this.show.auditTrail = false;
      this.show.previewRule = true;
    } else {
      this.show.previewRule = false;
      if (this.show.conditionsBank) {
        setTimeout(() => {
          const ruleContentElement = document.getElementById('rule-content-inner-section');
          ruleContentElement?.scrollTo({ top: ruleContentElement.scrollHeight, behavior: 'smooth' });
        }, 0);
      }
    }
  }

  toggleAuditTrail(): void {
    this.show.previewRule = false;
    this.show.auditTrail = !this.show.auditTrail;

    if (this.show.auditTrail) {
      this.auditTrailVisible.next(true);
    }
  }

  toggleConditionsBank(): void {
    this.show.conditionsBank = !this.show.conditionsBank;
  }

  filterRules(query: string, additionalFilter: (value: ConditionsBankNode) => boolean): Array<ConditionsBankNode> {
    if (!query) {
      return [];
    }
    let itemType;
    const queryLowerCase = query.trim().toLowerCase();
    this.countRecentlyUsedConditions = 0;

    if (queryLowerCase.startsWith('fn(')) {
      itemType = 'function';
    } else if (queryLowerCase.startsWith('bc(')) {
      itemType = 'BASE_CONDITION';
    } else if (queryLowerCase.startsWith(':')) {
      itemType = 'COLON_EXTRACT_MODE';
    }

    const flatConditionsBank = this.getFlatConditionsBank();

    if (flatConditionsBank == null) {
      return [];
    }

    let filteredList = flatConditionsBank.filter(this.filterFunction(queryLowerCase, itemType));
    if (additionalFilter != null) { //don't change to !== otherwise it'll allow undefined
      filteredList = filteredList.filter(additionalFilter);
    }

    // filteredList.concat(this.getSuggestions(queryLowerCase)); //adding suggestions from the server

    if (filteredList.length > this.conditionsLimit) {
      this.countRecentlyUsedConditions <= this.halfConditionsLimit ?
        filteredList.length = this.conditionsLimit : filteredList = this.truncateFilteredList(filteredList);
    }

    return filteredList;
  }

  downloadRule(rule: Rule, metadata: RuleMetadata): void {
    const ruleData = this.getRuleData(rule, metadata);
    const downloadResponse = {
      blob: new Blob([ruleData]),
      type: '',
      fileName: rule.RULE_NAME + ' ' + dayjs().format('DD_MMM_Y-HH_mm') + '.txt'
    };
    this.fileDownloaderService.downloadFile(downloadResponse);
  }

  deleteRule(rule: Rule, note: string): void {
    const payload = {
      uniqueRecID: rule.UID,
      profileTimeStamp: rule.TIME_STAMP,
      effectiveDate: rule.EFFECTIVE_DATE,
      note: note
    } as ChangeRuleStatusRequest;

    this.ruleService.deleteRule(payload).subscribe({
      next: (response: ResponseInfo) => this.onActionSuccess(response),
      error: (error: HttpErrorResponse) => this.onActionError(error)
    });
  }

  getBaseConditionData(): Promise<SearchBaseConditionResponse> {
    return this.ruleService.searchBaseCondition(this.rule.UID || '').toPromise();
  }

  getBaseConditionUsedProfiles(): Promise<BaseConditionUsedProfilesResponse> {
    return this.ruleService.baseConditionUsedProfiles(this.rule.UID || '').toPromise();
  }

  private onActionSuccess(response: ResponseInfo): void {
    if (response.errorMessage !== '' && response.errorMessage != null) {
      this.notificationService.warning(response.errorMessage);
    }
    this.isNeedCheckBeforeClose = false;
    this.closeRule();
  }

  private onActionError(error: HttpErrorResponse, rule?: Rule): void {
    this.isNeedCheckBeforeClose = true;
    switch (error.error.errorCode) {
      case ERROR_CODES.SAVE_PENDING_ON_CREATE:
      case ERROR_CODES.SAVE_PENDING_ON_EDIT:
        const message = error.error.errorCode === ERROR_CODES.SAVE_PENDING_ON_CREATE ? 'rule.save_in_pending_alert.retract_for_create_message' :
          'rule.save_in_pending_alert.retract_for_edit_message';
        this.modalsService.confirm({
          title: 'rule.save_in_pending_alert.title',
          message: this.translateService.instant(message),
          okButton: 'rule.save_in_pending_alert.ok',
          cancelButton: 'rule.save_in_pending_alert.cancel'
        }).afterClosed().subscribe((reason) => {
          reason === 'ok' ? this.doCloseByErrorCode(error.error.errorCode, rule) : this.stay();
        });
        break;
      case ERROR_CODES.DELETE_RELATED:
        const json = JSON.parse(error.error.errorMessage);
        const relatedObjectIds = json.map((object) => object.UID_OBJECT_PRULE_TYPES);
        this.attachedRuleHandlerService.onDelete(relatedObjectIds);
        break;
      default:
        const msg = (error.error && error.error.errorMessage) ? error.error.errorMessage : this.translateService.instant('rules.general_error');
        this.notificationService.error(msg);
        break;
    }

  }

  declineRule(rule: Rule, note?: string): void {
    const payload = {
      uniqueRecID: rule.UID,
      profileTimeStamp: rule.TIME_STAMP,
      profileUpdateTimeStamp: rule.PU_TIME_STAMP,
      note: note
    };

    this.ruleService.decline(payload).subscribe({
      next: (response: ResponseInfo) => this.onActionSuccess(response),
      error: (error: HttpErrorResponse) => this.onActionError(error)
    });
  }

  approveRule(rule: Rule): void {
    const payload = {
      uniqueRecID: rule.UID,
      profileTimeStamp: rule.TIME_STAMP,
      profileUpdateTimeStamp: rule.PU_TIME_STAMP
    } as ApproveDeclineRequest;

    this.ruleService.approve(payload).subscribe({
      next: (response: ResponseInfo) => this.onActionSuccess(response),
      error: (error: HttpErrorResponse) => this.onActionError(error)
    });
  }

  holdRule(rule: Rule, note?: string): void {
    const payload = {
      uniqueRecID: rule.UID,
      profileTimeStamp: rule.TIME_STAMP,
      effectiveDate: rule.EFFECTIVE_DATE,
      note: note
    } as ChangeRuleStatusRequest;

    this.ruleService.holdRule(payload).subscribe({
      next: (response: ResponseInfo) => this.onActionSuccess(response),
      error: (error: HttpErrorResponse) => this.onActionError(error)
    });
  }

  retractRule(rule: Rule): void {
    const payload = {
      uniqueRecID: rule.UID,
      profileTimeStamp: rule.TIME_STAMP
    } as ApproveDeclineRequest;

    this.ruleService.retract(payload).subscribe({
      next: (response: ResponseInfo) => this.onActionSuccess(response),
      error: (error: HttpErrorResponse) => this.onActionError(error)
    });
  }

  activateRule(rule: Rule, note: string): void {
    const payload = {
      uniqueRecID: rule.UID,
      profileTimeStamp: rule.TIME_STAMP,
      effectiveDate: rule.EFFECTIVE_DATE,
      note: note
    } as ChangeRuleStatusRequest;

    this.ruleService.activateRule(payload).subscribe({
      next: (response: ResponseInfo) => this.onActionSuccess(response),
      error: (error: HttpErrorResponse) => this.onActionError(error)
    });
  }

  saveRule(rule: Rule): void {
    this.ruleService.saveRule(this.rule.UID, rule).subscribe({
      next: (response: ResponseInfo) => this.onActionSuccess(response),
      error: (error: HttpErrorResponse) => this.onActionError(error, rule)
    });
  }

  createRule(rule: Rule): void {
    if (rule.BASE_CONDITION_IND == 1) {
      this.ruleService.verifyBaseCondition(rule.OFFICE, rule.CONDITIONS).subscribe({
        next: (response: RulePayload) => this.resolveVerify(response, rule),
        error: (error: HttpErrorResponse) => this.rejectVerify(error)
      });
    } else {
      this.doCreateRule(rule);
    }
  }

  sanitizeRule(rule: Rule): Rule {
    const currentRule = JSON.parse(JSON.stringify(rule));
    currentRule.CONDITIONS = this.ruleConditionsAlgorithmsService.fromNTreeToBinaryTree(
      JSON.parse(JSON.stringify(this.ruleConditionsService.conditions)), this.ruleService.baseConditions, this.ruleService.flatConditionsBank);
    currentRule.BASE_CONDITION_IND = currentRule.BASE_CONDITION_IND.toString();
    currentRule.EFFECTIVE_DATE = currentRule.EFFECTIVE_DATE.toString();
    currentRule.EXPIRY_DATE = currentRule.EXPIRY_DATE.toString();

    delete currentRule.conditionsBank;
    delete currentRule.flatConditionsBank;
    delete currentRule.actionDetails;
    delete currentRule.subType;
    delete currentRule.expiryDate;
    delete currentRule.conditions;
    delete currentRule.businessDate;

    this.emptyStringToZero(currentRule, 'SEC_RULE_ACTION_UID');
    this.emptyStringToZero(currentRule, 'RULE_ACTION_UID');
    this.emptyStringToZero(currentRule, 'RULE_SUB_TYPE');
    this.emptyStringToZero(currentRule, 'BASE_CONDITION_IND');

    return currentRule;
  }

  showInvalidActionError(error: string, params?: { [key: string]: any }): void {
    this.notificationService.error(this.translateService.instant(error, params));
  }

  operandFocused(operandComponent: ConditionOperandComponent): void {
    this.focusedOperand = operandComponent;
  }

  operandUnfocused(): void {
    this.focusedOperand = null;
    this.loseFocusOperand = null;
  }

  showFunctionPopover(operandComponent: ConditionOperandComponent): void {
    if (this.operandWithOpenedPopover === operandComponent && this.operandWithOpenedPopover?.popoverOpen) {
      return;
    }
    this.closeFunctionPopover();
    setTimeout(() => operandComponent.insertFunctionPopover?.openPopover());
    this.operandWithOpenedPopover = operandComponent;
  }

  closeFunctionPopover(): void {
    if (this.operandWithOpenedPopover?.popoverOpen) {
      //The closePopover method move the focus to the popover trigger - restore focus to the condition operand input
      const focusedElement = document.activeElement as HTMLElement;
      this.operandWithOpenedPopover.insertFunctionPopover.closePopover();
      focusedElement?.focus();
      this.operandWithOpenedPopover = null;
    }
  }

  private emptyStringToZero(rule: Rule, propertyName: string) {
    if (rule[propertyName] != null && rule[propertyName].length === 0) {
      rule[propertyName] = '0';
    }
  }

  private doCreateRule(rule: Rule): void {
    this.ruleService.createRule(rule).subscribe({
      next: (response: ResponseInfo) => this.onActionSuccess(response),
      error: (error: HttpErrorResponse) => this.onActionError(error)
    });
  }

  private resolveVerify(response: RulePayload, rule: Rule): void {
    if (JSON.stringify(response.contentHolder || null) === '{}') {
      this.doCreateRule(rule);
    } else {
      this.ruleModalsService.openBaseConditionFoundModal(response.contentHolder.RULE_NAME, 'createMode').afterClosed().subscribe(
        (data) => {
          if (!!data) {
            this.doCreateRule(rule);
          }
        });
    }
  }

  private rejectVerify(error: HttpErrorResponse): void {
    console.error('Exception: ' + error);
  }

  private doCloseByErrorCode(errorCode: string, rule: Rule): void {
    this.isNeedCheckBeforeClose = false;
    if (errorCode === ERROR_CODES.SAVE_PENDING_ON_CREATE) {
      this.retractRuleForCreate(rule);
    } else if (errorCode === ERROR_CODES.SAVE_PENDING_ON_EDIT) {
      this.declineRule(rule);
    }
  }

  private retractRuleForCreate(rule: Rule): void {
    const payload = {
      uniqueRecID: rule.UID
    };

    this.ruleService.retractForCreate(payload).subscribe({
      next: (response: ResponseInfo) => this.onActionSuccess(response),
      error: (error: HttpErrorResponse) => this.onActionError(error)
    });
  }

  private getRuleData(rule: Rule, metadata: RuleMetadata): string {
    let expression = '';
    const EOL = '\r\n';

    expression += metadata['PRULES-OFFICE'].displayName.trim() + ': ' + rule.OFFICE + EOL;
    expression += metadata['PRULES-DEPARTMENT'].displayName.trim() + ': ' + rule.DEPARTMENT + EOL;
    expression += metadata['PRULES-REC_STATUS'].displayName.trim() + ': ' + rule.REC_STATUS + EOL;
    expression += metadata['PRULES-PROFILE_CHANGE_STATUS'].displayName.trim() + ': ' + rule.PROFILE_CHANGE_STATUS + EOL;
    expression += this.translateService.instant('rule.ruleTypeNameLabel') + ': ' + rule.RULE_TYPE_NAME + EOL;
    expression += metadata['PRULES-RULE_NAME'].displayName.trim() + ': ' + rule.RULE_NAME + EOL;
    if (rule.RULE_SUB_TYPE && rule.RULE_SUB_TYPE != '0') {
      expression += this.translateService.instant('rule.ruleSubTypeLabel') + ': ' + rule.RULE_SUB_TYPE + EOL;
    }
    expression += metadata['PRULES-DESCRIPTION'].displayName.trim() + ': ' + rule.DESCRIPTION + EOL;
    expression += metadata['PRULES-EFFECTIVE_DATE'].displayName.trim() + ': ' + new Date(rule.EFFECTIVE_DATE) + EOL;
    expression += metadata['PRULES-EXPIRY_DATE'].displayName.trim() + ': ' + new Date(rule.EXPIRY_DATE) + EOL;
    expression += this.translateService.instant('PRULES-RULE_ACTION').trim() + ': ' + this.getActionUsageName(rule.actionDetails.ruleActionType) + EOL;
    if (rule.RULE_ACTION) {
      expression += this.translateService.instant('PRULES-RULE_ACTION_UID').trim() + ': ' + rule.RULE_ACTION + EOL;
    }
    if (rule.SEC_RULE_ACTION) {
      expression += this.translateService.instant('rule.usage') + ': ' + rule.SEC_RULE_ACTION + EOL;
    }
    expression += this.translateService.instant('rules.conditions_title') + ': ' + EOL + this.getRuleConditionAsText(EOL) + EOL;
    expression += EOL + EOL + EOL + this.translateService.instant('rule.file-creation-date') + ': ' + dayjs().toString();

    return expression;
  }

  private getRuleConditionAsText(EOL: string): string {
    let conditionAsText = '';
    const div = document.createElement('div');
    div.innerHTML = this.ruleTextHolderElement.innerHTML;
    const LF = '\n';
    conditionAsText = div.innerText.replace(new RegExp(EOL, 'g'), LF).replace(new RegExp(LF, 'g'), EOL);
    return conditionAsText;
  }

  private getActionUsageName(action: string): string {
    if (action === 'STOP' || action === 'MET' || action === '') {
      return action;
    } else {
      return 'SET';
    }
  }

  private truncateFilteredList(list: Array<ConditionsBankNode>): Array<ConditionsBankNode> {
    let truncatedList;

    if (list.length - this.countRecentlyUsedConditions === 0) {
      truncatedList = this.getTruncatedConditions(list);
      truncatedList = this.getSomeConditions(truncatedList, this.conditionsLimit);
    } else {
      truncatedList = this.truncateFilteredListForAllConditions(list, this.countRecentlyUsedConditions);
    }

    return truncatedList;
  }

  private truncateFilteredListForAllConditions(filteredList: Array<ConditionsBankNode>, countRecentlyUsedConditions: number): Array<ConditionsBankNode> {
    let result = [];
    let recently;

    if (countRecentlyUsedConditions > this.halfConditionsLimit && filteredList.length - countRecentlyUsedConditions >= this.halfConditionsLimit) {
      recently = this.handleRecentlyUsedConditions(filteredList, this.halfConditionsLimit, filteredList, countRecentlyUsedConditions);
    } else {
      recently = this.handleRecentlyUsedConditions(filteredList, this.conditionsLimit - (filteredList.length - countRecentlyUsedConditions),
        filteredList, countRecentlyUsedConditions);
    }

    const notRecently = filteredList.slice(countRecentlyUsedConditions, countRecentlyUsedConditions + this.conditionsLimit - recently.length);
    result = result.concat(recently, notRecently);

    return result;
  }

  private handleRecentlyUsedConditions(list: Array<ConditionsBankNode>, numberConditions: number,
    filteredList: Array<ConditionsBankNode>, countRecentlyUsedConditions: number): Array<ConditionsBankNode> {
    list = filteredList.slice(0, countRecentlyUsedConditions);
    const orderedList = this.getTrancatedConditions(list);
    return this.getSomeConditions(orderedList, numberConditions);
  }

  private getTrancatedConditions(list: Array<ConditionsBankNode>) {
    const result = {
      Field: [],
      Function: [],
      BASE_CONDITION: []
    };

    for (let i = 0; i < list.length; i++) {
      for (let j = 0; j < this.conditionTypes.length; j++) {
        if (list[i].contentType === this.conditionTypes[j]) {
          this.addToConditions(result[this.conditionTypes[j]], list[i]);
        }
      }
    }

    return result;
  }

  private addToConditions(conditions, condition: ConditionsBankNode): void {
    if (conditions.length < this.conditionsLimit) {
      conditions.push(condition);
    }
  }

  private getSomeConditions(conditions, limitConditions: number): Array<ConditionsBankNode> {
    let result = [];
    const third = +(limitConditions / 3).toFixed(0);
    let numberFields = third;
    const numberFunctions = third;
    let numberBC = third;
    const n = limitConditions - third * 3;
    if (n === 1) {
      numberFields++;
    } else if (n === -1) {
      numberBC--;
    }

    if (conditions.Field.length > numberFields - 1 && conditions.Function.length > numberFunctions - 1 &&
      conditions.BASE_CONDITION.length > numberBC - 1) {
      conditions.Field.length = numberFields;
      conditions.Function.length = numberFunctions;
      conditions.BASE_CONDITION.length = numberBC;
      result = result.concat(conditions.Field, conditions.Function, conditions.BASE_CONDITION);
    } else {
      result = this.getResult(conditions, numberFields, numberFunctions, limitConditions);
    }

    return result;
  }

  private getResult(conditions, numberFields: number, numberFunctions: number, limitConditions: number): Array<ConditionsBankNode> {

    const getConditionsFromAllTypes = (conditionsGroup: Array<ConditionsBankNode>): void => {
      for (let i = 0; i < conditionsGroup.length; i++) {
        if (conditionsGroup[i].contentType === ContentType.FIELD && i < numberFields) {
          result = result.concat(conditionsGroup[i]);
          countFields++;
        }
        if (conditionsGroup[i].contentType === ContentType.FUNCTION && ((countFields < numberFields && result.length < halfNumber) ||
          (countFields === numberFields && result.length < numberFieldsAndFunctions))) {
          result = result.concat(conditionsGroup[i]);
          countFunctions++;
        }
        if (conditionsGroup[i].contentType === ContentType.BASE_CONDITION && result.length < limitConditions) {
          result = result.concat(conditionsGroup[i]);
        }
      }
    };

    const getMaxNumberConditions = (numberAddedConditions: number, conditionsList: Array<ConditionsBankNode>): number => {
      const lackNumber = limitConditions - result.length;
      let topNumber = parseInt(numberAddedConditions as any) + parseInt(lackNumber as any);
      if (conditionsList.length < topNumber) {
        topNumber = conditionsList.length;
      }
      return topNumber;
    };

    const fillConditions = (fromNumber: number, toNumber: number, isFields: boolean): void => {
      for (let i = fromNumber; i < toNumber; i++) {
        if (isFields) {
          result = result.concat(conditions.Field[i]);
          numberFields++;
        } else {
          result = result.concat(conditions.Function[i]);
          numberFunctions++;
        }
      }
    };

    const fillConditionsTillEnd = (fromNumber: number, toNumber: number, conditionsList: Array<ConditionsBankNode>): void => {
      for (let i = fromNumber; i < toNumber; i++) {
        if (result.length < toNumber) {
          result = result.concat(conditionsList[i]);
          numberFunctions++;
        }
      }
    };

    const fillShortedResult = (): void => {
      const fillFields = (): void => {
        topNumber = getMaxNumberConditions(numberFields, conditions.Field);
        fillConditions(numberFields, topNumber, true);
      };

      const fillFunctions = (): void => {
        topNumber = getMaxNumberConditions(numberFunctions, conditions.Function);
        fillConditions(numberFunctions, topNumber, false);
      };

      const fillFieldsOrFunctions = (): void => {
        conditions.Field.length > numberFields ? fillFields() : fillFunctions();
        if (result.length < limitConditions) {
          return fillShortedResult();
        }
      };

      let topNumber = 0;
      if (countFields === numberFields) {
        countFunctions === numberFunctions ?
          fillFieldsOrFunctions() : fillConditionsTillEnd(numberFields, limitConditions, conditions.Field);
      } else {
        fillConditionsTillEnd(countFunctions, limitConditions, conditions.Function);
      }
    };

    let result = [];
    let countFields = 0;
    let countFunctions = 0;
    const halfNumber = +(limitConditions / 2).toFixed(0);
    const numberFieldsAndFunctions = parseInt(numberFields as any) + parseInt(numberFunctions as any);
    getConditionsFromAllTypes(conditions.Field);
    getConditionsFromAllTypes(conditions.Function);
    getConditionsFromAllTypes(conditions.BASE_CONDITION);

    if (result.length < limitConditions) {
      fillShortedResult();
    }

    return result;
  }

  private getTruncatedConditions(list: Array<ConditionsBankNode>) {
    const result = {
      Field: [],
      Function: [],
      BASE_CONDITION: []
    };

    for (let i = 0; i < list.length; i++) {
      for (let j = 0; j < this.conditionTypes.length; j++) {
        if (list[i].contentType === this.conditionTypes[j]) {
          this.addToConditions(result[this.conditionTypes[j]], list[i]);
        }
      }
    }

    return result;
  }

  private filterFunction(query: string, itemType: string): (item: ConditionsBankNode) => boolean {
    let returnFunction;

    if (itemType !== undefined) {
      if (itemType === 'function' || itemType === 'BASE_CONDITION') {
        query = query.substring(3); //removes fn( or bc( for the filter
        returnFunction = (item: ConditionsBankNode): boolean => {
          return (((item as LogicalField | ConditionFunction).type === itemType || item.contentType === itemType) && this.doStringMatching(item, query));
        };
      } else if (itemType === 'COLON_EXTRACT_MODE') {
        query = query.substring(1); //removes ':' for the filter
        returnFunction = (item: ConditionsBankNode): boolean => {
          return ((item.contentType === ContentType.FIELD) && this.doStringMatching(item, query));
        };
      }


    } else {
      returnFunction = (item: ConditionsBankNode): boolean => {
        return this.doStringMatching(item, query);
      };
    }

    return returnFunction;
  }

  private doStringMatching(item: ConditionsBankNode, query: string): boolean {

    const result = ((item.contentType === ContentType.FIELD && (item as LogicalField).parent === false) ||
      item.contentType !== ContentType.FIELD) && item.alias.toLowerCase().indexOf(query) === 0 ||
      (item.id && item.id.toLowerCase().indexOf(query) === 0);
    if (result && item.isRecentlyUsed) {
      this.countRecentlyUsedConditions++;
    }
    return result;
  }

  private goToRulesGrid(): void {
    setTimeout(() => {
      const queryParams = this.ruleTypes ? { ruleType: this.ruleTypes } : {};
      this.router.navigate(['/home/rules/business-rules'], { queryParams });
    }, 0);
  }

  private openConfirmModal(nextState: RouterStateSnapshot): Observable<boolean> {
    return this.modalsService.confirm({
      title: 'confirm.leavePage.title',
      message: this.translateService.instant('confirm.leavePage.message'),
      okButton: 'rules.button.exit',
      cancelButton: 'confirm.leavePage.cancelButton'
    }).afterClosed().pipe(map(result => {
      return result === 'ok' ? this.doCloseRule(nextState) : this.stay();
    }));
  }

  private doCloseRule(nextState: RouterStateSnapshot): boolean {
    this.closeRule(nextState);
    this.ruleService.close({ uniqueRecID: this.rule.UID }).subscribe();
    return true;
  }

  private stay(): boolean {
    this.isNeedCheckBeforeClose = true;
    return false;
  }

  private modifyRuleObject(obj: Rule): Rule {
    delete obj.actionDetails;
    delete obj.subType;
    delete obj.BC_RESTRICTED_LOGICAL_FIELDS;
    delete obj.CHANGED_FIELDS;
    return obj;
  }

}
