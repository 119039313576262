<app-fn-ui-dialog class="tour-additional-intro-modal">

  <ng-template #header>
    <div class="tour-additional-intro-header">
      <span class="title" [translate]="params.title"></span>
    </div>
    <mat-divider></mat-divider>
  </ng-template>

  <ng-template #body>
    <div class="tour-additional-intro-body">
      <p [translate]="params.message"></p>
    </div>
  </ng-template>

  <ng-template #footer>
    <div class="tour-additional-intro-footer">
      <app-fn-ui-button [name]="params.showLaterButton" mat-dialog-close="leave">
        <span [translate]="params.showLaterButton"></span>
      </app-fn-ui-button>

      <app-fn-ui-button [name]="params.continueButton" type="flat" color="primary" mat-dialog-close="stay">
        <span [translate]="params.continueButton"></span>
      </app-fn-ui-button>
    </div>
  </ng-template>

</app-fn-ui-dialog>
