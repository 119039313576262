import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface AddNoteModalFields {
  title: string;
  body: string;
  closeButton: string;
  applyButton: string;
  note?: string;
}

@Component({
  styleUrls: ['./add-note-modal.component.scss'],
  template: `
    <app-fa-dialog [title]="data.title | translate" [body]="bodyTemplate" [footer]="footerTemplate">

      <ng-template #bodyTemplate>
        <span>{{data.body  | translate}}</span>
        <mat-form-field [floatLabel]="'always'">
          <textarea id="add-note-modal-note-textarea" matInput type="text" maxlength="400" rows="7" [(ngModel)]="data.note" autofocus #note> </textarea>
          <mat-hint>{{note.value?.length || 0}}/{{note.maxLength}}</mat-hint>
        </mat-form-field>
      </ng-template>

      <ng-template #footerTemplate>
        <button mat-button color="primary" mat-dialog-close>{{data.closeButton | translate}}</button>
        <button mat-flat-button color="primary" id="modal-add-note-apply" [ngClass]="{'main-action': data.note}"
                [mat-dialog-close]="data.note" [disabled]="!data.note">{{data.applyButton | translate}}
        </button>
      </ng-template>

    </app-fa-dialog>
  `
})
export class AddNoteModalComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: AddNoteModalFields) {}
}

