<div class="landing-page" [appSpinner]="isLoading">
<app-tour-item [tourId]="'workspaceDialer'" [tourStepNumber]="0"></app-tour-item>
  <button mat-button color="primary" id="landing-page-create-payment" azure-id="landing-page-create-payment"
          class="landing-page-create-payment btn landing-page-link" (click)="createPayment()"
          [disabled]="isCreateMessageTypesEmpty || isLoading">
        <app-fn-ui-icon class="landing-page-icon" icon="pencil" size="32px"></app-fn-ui-icon>
        <span>{{'messages_center.landing_page.create_payment' | translate}}</span>
    </button>
  <button mat-button color="primary" id="landing-page-search-a-payment" azure-id="landing-page-search-a-payment"
          [disabled]="isLoading"
          class="btn landing-page-link" (click)="searchAPayment()">
        <app-fn-ui-icon class="landing-page-icon" icon="search" size="32px"></app-fn-ui-icon>
        <span>{{'messages_center.landing_page.search_payment' | translate}}</span>
    </button>
  <button mat-button color="primary" id="landing-page-recent-queues-view" azure-id="landing-page-recent-queues-view"
          [disabled]="isLoading"
          class="btn landing-page-link" (click)="recentQueuesView()">
        <app-fn-ui-icon class="landing-page-icon" icon="clock" size="32px"></app-fn-ui-icon>
        <span>{{'messages_center.landing_page.recent_searches' | translate}}</span>
    </button>
</div>
