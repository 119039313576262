import { Injectable } from '@angular/core';
import { CurrentNode, IntraDayPosition, PartyGroup } from '../models';

@Injectable()
export class LiquidityStatesService {

  intraDayPosition = {} as IntraDayPosition;

  partyGroup = {} as PartyGroup;

  currentNode = {} as CurrentNode;

}

