import { Injectable } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { IEventTelemetry, IPageViewTelemetry } from '@microsoft/applicationinsights-web';
import { RouterHelper } from '../../base-view/classes/router-helper';
import { AzureInsightsConfig } from './azure-insights.config';
import { AppConfigService } from '../../config/services/app-config.service';
import { ClickItemFilterService } from './filter/click-item-filter.service';
import { Observable, Subscription, filter } from 'rxjs';
import { Select } from '@ngxs/store';
import { UserNotification } from '../../core/state/models/user-notification-state.model';
import { GridEventState } from '../../core/state/grid-event.state';
import { UserNotificationState } from '../../core/state/user-notification.state';
import { AzureInsightsAbstartService } from './azure-insights-abstart.service';
import { UserNotificationFilterService } from './filter/user-notification-filter.service';
import { GridEventsService } from './grid-events.service';
import { GridEvent } from '../interfaces/grid-event.model';
import { GridEventFilterService } from './filter/grid-event-filter.service';
import { NavigationService } from '../../core/navigation/services/navigation.service';
import { MainTab } from '../../core/navigation/models';

@Injectable()
export class AzureInsightsService extends AzureInsightsAbstartService {

  protected subscriber = new Subscription();
  @Select(GridEventState.getGridEvent) gridEventObserver$: Observable<any>;
  @Select(UserNotificationState.getUserNotification) userNotificationObserver$: Observable<any>;

  constructor(config: AzureInsightsConfig,
              private appConfigService: AppConfigService,
              private clickItemFilter: ClickItemFilterService,
              private gridEventFilter: GridEventFilterService,
              private userNotificationFilterService: UserNotificationFilterService,
              private gridEventService: GridEventsService,
              private navigationService: NavigationService,
              private router: Router, private route: ActivatedRoute) {
    super(config);
    this.registerTransitionHooks();
  }

  init() {
    this.azureConfig = this.appConfigService.azureAnalyticsConfig;
    if (this.azureConfig && this.azureConfig.isOn) {
      try {
        this.initAppInsights(this.azureConfig.instrumentationKey);
        this.appInsightsInited = true;
        this.setUserId();
        document.documentElement.onclick = this.trackClickEvent.bind(this);
        this.gridEventService.subscribeGridEvent().subscribe((gridEvent: GridEvent) => {
          if (gridEvent) {
            this.trackGridEvent(gridEvent);
          }
        });
        // this.subscirbeToUserNotificationEvents();
        // this.subscirbeToGridEvents();
      } catch (ex) {
        console.warn('Angular application insights Error [loadAppInsights]: ', ex);
      }
    } else {
      console.warn('An instrumentationKey value is required to initialize AppInsightsService');
    }
  }


  getAzureCustomProprties() {
    return this.azureConfig.customProprties;
  }

  trackPage(name: string) {
    if (this.azureConfig && this.azureConfig.isOn) {
      const pageView: IPageViewTelemetry = {};
      pageView.name = name;
      pageView.properties = this.azureConfig.customProprties;
      this.trackPageView(pageView);
    }
  }

  trackClickEvent(event: any) {
    try {
      if (this.appInsightsInited) {
        const clickEventTelemetry: IEventTelemetry = this.clickItemFilter.getElementFromClickEvent(event);
        if (clickEventTelemetry) {
          if (this.router.url.includes('liquidity')) {
            clickEventTelemetry.properties = this.clickItemFilter.liquidityClickFilter(clickEventTelemetry.properties);
          }
        }
        this.trackEvent(clickEventTelemetry, this.azureConfig.customProprties);
      }
    } catch (ex) {
      console.warn('Angular application insights Error [trackEvent]: ', ex);
    }
  }

  private trackGridEvent(event: GridEvent) {
    try {
      if (this.appInsightsInited) {
        const gridEventTelemetry: IEventTelemetry = this.gridEventFilter.getGridEventData(event);
        this.trackEvent(gridEventTelemetry, this.azureConfig.customProprties);
      }
    } catch (ex) {
      console.warn('Angular application insights Error [trackEvent]: ', ex);
    }
  }

  trackCustomEvent(event: IEventTelemetry) {
    try {
      if (this.appInsightsInited) {
        this.trackEvent(event, this.azureConfig.customProprties);
      }
    } catch (ex) {
      console.warn('Angular application insights Error [trackEvent]: ', ex);
    }
  }

  private registerTransitionHooks(): void {
    const homePath = "/home";
    const sub = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe((event: NavigationEnd) => {
      let path = event.url;
        if (path && path !== homePath) {
          path = path.substring(path.indexOf(homePath) + homePath.length + 1);
          path = path.split('?')[0];
          const tab = RouterHelper.getSegments(this.router)[1].path;
          path = this.setQueueIdParam(this.route.snapshot.queryParamMap.get('queueId'), path);
          path = this.translateProfileId(RouterHelper.getSegments(this.router)[3]?.path, path, tab);
          path = path.split('-').join(' ').split('/').join(' - ');
          this.trackPage(path);
        }
      }, error => {
        this._onerror(error as any);
    });
    this.subscriber.add(sub);
    }

  private setQueueIdParam(queueIdParam: string, path: string): string {
    if (queueIdParam) {
      path += queueIdParam.indexOf('/') === -1 ?
        '/' + queueIdParam.substr(0, queueIdParam.length) : // there is no sensitive data
        '/' + queueIdParam.substr(0, queueIdParam.indexOf('/')); // there is sensitive data don't include it
    }
    return path;
}

  private translateProfileId(profileId: string, path: string, tab: string): string {
    if(profileId) {
      const profile = this.navigationService.getTabNavigationItem(tab as MainTab, profileId);
      if (profile) {
        const adaptedPath = path.substring(0, path.lastIndexOf('/') + 1);
        return adaptedPath + profile.alias.substring(profile.alias.indexOf('.') + 1).split('_').join('-');
      }
    }
    return path;
  }

  private subscirbeToUserNotificationEvents() {
    const subscriptionUserNotification = this.userNotificationObserver$.subscribe((userNotification: UserNotification) => {
      const eventToSend = this.userNotificationFilterService.getMessageFromUserNotificationEvent(userNotification);
      this.trackCustomEvent(eventToSend);
    });
    this.subscriber.add(subscriptionUserNotification);
  }

  // private subscirbeToGridEvents() {
  //   const subscriptionGrid = this.gridEventObserver$.subscribe((gridEvent: GridEvent) => {
  //     const eventToSend = this.userNotificationFilterService.getMessageFromUserNotificationEvent(gridEvent);
  //     this.trackCustomEvent(eventToSend);
  //   });
  //   this.subscriber.add(subscriptionGrid);
  // }
}
