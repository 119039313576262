<!-- ruleAssociationList.html -->
<div class="rules-association-list-section" *ngIf="rulesCommonService.showRulesList">
  <div class="rules-association-virtual-list" *ngIf="!showRulesGrid">
    <div class="rules-association-list-pane-wrapper">
      <span class="rules-list-pane-title"
            *ngIf="!rulesAssociationDetails.RULE_TYPE_NAME">{{'ruleAssociation.emptyDetails' | translate}}</span>
      <span class="rules-list-pane-title" *ngIf="rulesAssociationDetails.RULE_TYPE_NAME">{{ruleSelectionTitle}}</span>
      <div class="rules-association-list-pane-actions">
        <span class="re-search-filter fit-content">
        <input azure-id="filter-rule-association" automation-id="filter-rule-association" class="dh-input-field ng-pristine ng-untouched ng-valid ng-empty"
               type="text"
               placeholder="Find rule in list..."
               (input)="filterList(this.searchRulesFilter.value)" aria-invalid="false" #searchRulesFilter>
        </span>
        <span class="rules-conditions-pane-actions-spacer"></span>
        <button mat-icon-button color="primary" azure-id="undo" automation-id="undo" (click)="undo()"
                title="{{'ruleAssociation.button.undo' | translate}}"
                [disabled]="rulesCommonService.undoStack?.length === 0 || rulesCommonService.undoRedoInProgress  || rulesCommonService.filterString.length > 0">
          <app-fn-ui-icon icon="undo"></app-fn-ui-icon>
        </button>

        <button mat-icon-button color="primary" azure-id="redo" automation-id="redo" (click)="redo()"
                title="{{'ruleAssociation.button.redo' | translate}}"
                [disabled]="rulesCommonService.redoStack?.length == 0 || rulesCommonService.undoRedoInProgress || rulesCommonService.filterString.length > 0">
          <app-fn-ui-icon icon="redo"></app-fn-ui-icon>
        </button>

        <span class="rules-conditions-pane-actions-spacer"></span>

        <button mat-icon-button color="primary" azure-id="remove" automation-id="remove" title="{{'rules.button.remove' | translate}}"
                (click)="removeSelected()"
                [disabled]="rulesCommonService.isListReadOnly || !rulesCommonService.enableRemoveOptions">
          <app-fn-ui-icon icon="minus"></app-fn-ui-icon>
        </button>

        <span class="rules-conditions-pane-actions-spacer"></span>
        <button mat-icon-button color="primary" azure-id="add" automation-id="add" class="alert-btn-text"
                title="{{'rules.button.add' | translate}}"
                ui-sref="home.rules.views.tree.rulesAssociation.single.edit"
                (click)="displayRulesGrid()"
                [disabled]="rulesCommonService.isListReadOnly">
          <app-fn-ui-icon icon="plus"></app-fn-ui-icon>
          <!--      <data-dh-tour-item data-tour-id='ruleAssociationScreen' data-tour-step-number="0"
                                   data-ng-if="!ruleCommonService.modalVisible"
                ></data-dh-tour-item>-->
          <app-tour-item [tourId]="'ruleAssociationScreen'" [tourStepNumber]="0"></app-tour-item>
        </button>
      </div>
    </div>
    <div class="rules-association-list-wrapper">
      <div class="list-container add-margin">
        <div class="dh rules-association-virtual-list-container">
          <div class="si-list-item-body-wrapper">
            <div *ngFor="let groups of virtualList | groupBy: 'position'">
              <ng-template #record>
                <div class="rule-association-row" *ngFor="let rule of groups.value"
                     [ngClass]="rule.CHECKED ? 'checked': 'unchecked'">
                  <div class="association-checkbox pull-left">
                    <mat-checkbox color="primary" azure-id="rule-association-check"
                                  [ngClass]="rule.CHECKED ? 'checked' : 'unchecked'" class="rule-association-check"
                                  (change)="toggleSelection(rule, $event)" [checked]="rule.CHECKED"
                                  [disabled]="rulesCommonService.isListReadOnly">
                    </mat-checkbox>
                  </div>
                  <div class="association-details pull-left">
                    <div class="dh-virtual-list-field-header">{{rule.RULE_NAME}}</div>
                    <div class="dh-virtual-list-field-value">
                      <span>{{rule.RULE_DESCRIPTION}}</span>
                    </div>
                    <div class="dh-virtual-list-field-action">
                      <span>{{rule.ACTION}}</span>
                    </div>
                    <div class="dh-virtual-list-field-value-faded">
                      <span>{{rule.APPLICABLE_FOR}}</span>
                    </div>
                  </div>
                  <div class="dh-simple-condition-actions pull-left align-self-center">
                    <button mat-icon-button azure-id="preview-association" [matMenuTriggerFor]="preview"
                            (click)="togglePreviewRulePane(rule)"
                            class="preview-association rules-association-row-buttons">
                      <app-fn-ui-icon [icon]="!rule.PREVIEW_MODE ? 'visibility' :'visibility_off'"></app-fn-ui-icon>
                    </button>
                    <button mat-icon-button azure-id="remove-association" class="remove-association rules-association-row-buttons"
                            (click)="remove(rule.RULE_SEQ_NUMBER, rule.RULE_NAME)"
                            [disabled]="rulesCommonService.isListReadOnly || !rulesCommonService.enableRemoveOptions "
                            title="{{'ruleAssociation.button.remove' | translate}}">
                      <app-fn-ui-icon [icon]="'minus'"></app-fn-ui-icon>
                    </button>
                  </div>
                </div>
              </ng-template>
              <div *ngIf="groups.key === 'start'">
                <ng-container *ngTemplateOutlet="record"></ng-container>
              </div>
              <div *ngIf="groups.key === 'mid'" (click)="unGroup()">
                <div class="group-spacer">
                  <span
                    class="group-spacer-indicator">{{signIndicator}}{{groups.value.length > 99 ? 99 : groups.value.length}}</span>
                  <span class="group-spacer-line"></span>
                </div>
              </div>
              <div *ngIf="groups.key === 'end'">
                <ng-container *ngTemplateOutlet="record"></ng-container>
              </div>
              <div *ngIf="groups.key === 'none'">
                <ng-container *ngTemplateOutlet="record"></ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="list-options">
        <button mat-icon-button color="primary" azure-id="move-to-top" (click)="moveToTop()"
                title="{{'rules.button.moveToTop' | translate}}"
                [disabled]="!rulesCommonService.enableMoveToTop">
          <app-fn-ui-icon icon="chevron_double_up"></app-fn-ui-icon>
        </button>

        <span class="vertical-actions-spacer"></span>

        <button mat-icon-button color="primary" azure-id="move-up" (click)="moveUp()"
                title="{{'rules.button.moveUp' | translate}}"
                [disabled]="!rulesCommonService.enableMoveUp">
          <app-fn-ui-icon icon="chevron_up"></app-fn-ui-icon>
        </button>

        <span class="vertical-actions-spacer"></span>

        <button mat-icon-button color="primary" azure-id="move-down" (click)="moveDown()"
                title="{{'rules.button.moveDown' | translate}}"
                [disabled]="!rulesCommonService.enableMoveDown">
          <app-fn-ui-icon icon="chevron_down"></app-fn-ui-icon>
        </button>

        <span class="vertical-actions-spacer"></span>

        <button mat-icon-button color="primary" azure-id="move-to-bottom" (click)="moveToBottom()"
                title="{{'rules.button.moveToBottom' | translate}}"
                [disabled]="!rulesCommonService.enableMoveToBottom">
          <app-fn-ui-icon icon="chevron_double_down"></app-fn-ui-icon>
        </button>
      </div>
    </div>
  </div>
  <mat-menu #preview="matMenu" class="preview-condition-menu" [hasBackdrop]="false">
    <div class="preview-rule-association-popup-wrapper">
      <div class="preview-rule-association-popup-title-wrapper">
        <span class="preview-rule-association-popup-title">{{previewRuleObject.alias}}</span>
        <button mat-icon-button azure-id="close-preview-pane-icon" (click)="closePreviewPane()">
          <app-fn-ui-icon icon="close"></app-fn-ui-icon>
        </button>
      </div>
      <div class="preview-rule-association-popup-body-wrapper">
        <div class="preview-rule-association-popup-description">{{previewRuleObject.condition}}</div>
        <div class="preview-rule-association-popup-args">
          <div class="preview-rule-association-popup-actions">
            <button mat-raised-button color="primary" azure-id="close-preview-pane-btn" type="button"
                    (click)="closePreviewPane()">
              <span class="dh-text-label">{{'ruleAssociation.button.previewRule.close' | translate}}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </mat-menu>
  <div class="rules-association-grid-wrapper-list" *ngIf="showRulesGrid">
    <div class="rules-association-list-pane-wrapper">
      <span class="rules-list-pane-title" *ngIf="ruleAssociationListTitle">{{ruleAssociationListTitle}}</span>
      <div class="rules-wrapper-grid-actions">
        <button mat-button color="primary" azure-id="back-to-association" (click)="resetSelectedRules()">
          <span>{{"rules.go_back_to_association" | translate}}</span>
        </button>
        <span class="rules-conditions-pane-actions-spacer"></span>
        <button mat-raised-button color="primary" azure-id="add-selected"
                class="rule-association-add-selected-btn" data-dh-tour-item
                data-tour-id='ruleAssociationScreenDetails' data-tour-step-number="2"
                [disabled]="!gridItemsSelected"
                (click)="addSelectedRules()">
          <span class="dh-text-label">{{"rules.add_selected" | translate}}</span>
          <app-tour-item [tourId]="'ruleAssociationScreenDetails'" [tourStepNumber]="2"></app-tour-item>
        </button>
        <span class="rules-conditions-pane-actions-spacer"></span>
      </div>
    </div>
    <div class="rules-association-list-wrapper">
      <div class="list-container">
        <div class="full">
          <div class="ft-grid-container">
            <app-tour-item [tourId]="'ruleAssociationScreenDetails'" [tourStepNumber]="0"></app-tour-item>
            <app-rule-associations-grid-wrapper
              [rulesAssociationDetails]="rulesAssociationDetails"
              [gridContainerConfig]="gridEntityConfig"
              (rowSelected)="onRowSelectionChanged($event)"
              (pageChanged)="onPageChangedInRulesList($event)">
            </app-rule-associations-grid-wrapper>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="rules-association-audit-section" *ngIf="rulesCommonService.show.auditTrail">
  <app-rules-association-audit-trail [ruleProfileId]="417"
                                     [ruleId]="rulesAssociationDetails.UID"></app-rules-association-audit-trail>
</div>
