import { Component } from '@angular/core';
import { NodeActionClickEvent } from '../../../../shared/fn-ui-navigation-actions-tree/models/node-action-click-event.model';
import { LiquidityFavorite } from '../../../models';
import { LiquidityFavoriteNodeActionsService } from '../../../services/liquidity-favorite-node-actions.service';
import { LiquidityFavoritesService } from '../../../services/liquidity-favorites.service';

@Component({
  selector: 'app-liquidity-favorites-navigation',
  templateUrl: './liquidity-favorites-navigation.component.html',
  styleUrls: ['./liquidity-favorites-navigation.component.scss']
})
export class LiquidityFavoritesNavigationComponent {

  constructor(public liquidityFavoritesService: LiquidityFavoritesService,
              public liquidityFavoriteNodeActionsService: LiquidityFavoriteNodeActionsService) { }

  onMenuItemClick($event: NodeActionClickEvent<LiquidityFavorite>) {
    $event.action.command($event.node);
  }
}

