import { Injectable } from '@angular/core';
import { NavigationResultGroup, NavigationResultItem } from '../models';
import { INavigationSearchSortService } from './interfaces/navigation-search-sort-service.interface';
import { NavigationSearchConfigService } from './navigation-search-config.service';

@Injectable()
export class NavigationSearchSortService implements INavigationSearchSortService {

  constructor(private configService: NavigationSearchConfigService) { }

  sortGroups(groups: Array<NavigationResultGroup>): Array<NavigationResultGroup> {
    groups.sort((group1: NavigationResultGroup, group2: NavigationResultGroup) => {
      const index1 = this.configService.navigationSearchTabs.indexOf(group1.id);
      const index2 = this.configService.navigationSearchTabs.indexOf(group2.id);
      return index1 - index2;
    });
    return groups;
  }

  sortItems(items: Array<NavigationResultItem>): Array<NavigationResultItem> {
    items.sort((item1: NavigationResultItem, item2: NavigationResultItem) => {
      return item1.alias.trim().localeCompare(item2.alias.trim(), undefined, { sensitivity: 'base' });
    });
    return items;
  }

}
