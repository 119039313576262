import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { FnUiIconModule } from '../../shared/fn-ui-icons/fn-ui-icon/fn-ui-icon.module';
import { FnUiNotificationModule } from '../../shared/fn-ui-notification/fn-ui-notification.module';

import { PowerBiReportsComponent } from './components/power-bi-reports/power-bi-reports.component';

import { PowerBIApiService } from './services/power-bi-api.service';
import { PowerBINavigationService } from './services/power-bi-navigation.service';
import { PowerBIRefreshTokenService } from './services/power-bi-refresh-token.service';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        MatButtonModule,
        TranslateModule.forChild(),
        FnUiIconModule,
        FnUiNotificationModule
    ],
    declarations: [
        PowerBiReportsComponent
    ],
    providers: [
        PowerBIApiService,
        PowerBINavigationService,
        PowerBIRefreshTokenService
    ],
    exports: [
        PowerBiReportsComponent
    ]
})
export class PowerBIModule { }
