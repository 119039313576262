import { GridFooterConfig } from '../../../../models/grid-footer-config.model';
import { GridContainerConfig } from '../../../../models/grid-container-config.model';
import { GridHeaderConfig } from '../../../../models/grid-header-config.model';
import { GroupActionsConfig } from '../../../../models/group-actions-config.model';

export const DrillDownGridConfigGridHeaderConfig: GridHeaderConfig = {
  showLastUpdate: false,
  showScopes: false,
  showRefresh: false,
  showLeftMenu: false,
  showRightMenu: true,
  showFavorite: false,
  showType: false,
  showTitle: false,
  showAutoFeeder: false,
  showFilter: false,
  showAllActions: true,
  showCreate: true,
  disableCreate: true,
  processingScope: false
};

export const DrillDownGridGroupActionsConfig: GroupActionsConfig = {
  showGroupActionIcon: false,
  showBeforeAfter: false,
  showAddNotes: false,
  showGroupActions: false,
};

export const DrillDownGridFooterConfig: GridFooterConfig = {
  showResultsAndCounts: true,
  groupActions: DrillDownGridGroupActionsConfig,
  showCallerDetails: false,
  showCreateFromTemplate: false,
  showResultsWarning: false
};


export const DrillDownGridConfig: GridContainerConfig = {
  gridHeaderConfig: DrillDownGridConfigGridHeaderConfig,
  gridFooterConfig: DrillDownGridFooterConfig
};

