import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderModule } from 'projects/attachment/src/lib/loader/loader.module';
import { FnUiButtonModule } from '../../../shared/fn-ui-button/fn-ui-button.module';
import { FnUiDialogModule } from '../../../shared/fn-ui-dialog/fn-ui-dialog.module';
import { FnUiCloseIconModule } from '../../../shared/fn-ui-icons/fn-ui-close-icon/fn-ui-close-icon.module';
import { FnUiIconModule } from '../../../shared/fn-ui-icons/fn-ui-icon/fn-ui-icon.module';
import { UiComponentsModule } from '../../../ui-components/ui-components.module';

import { FxAdditionalFieldsComponent } from './fx-additional-fields/fx-additional-fields.component';
import { FxPaymentEnhancementComponent } from './fx-payment-enhancement/fx-payment-enhancement.component';
import { FxRateModalComponent } from './fx-rate-modal/fx-rate-modal.component';
import { FxRateRetryModalComponent } from './fx-rate-retry-modal/fx-rate-retry-modal.component';
import { FxRateTableComponent } from './fx-rate-table/fx-rate-table.component';
import { FxReverseSellPanelCompent } from './fx-reverse-sell/fx-reverse-sell.component';

import { FxLogicalFieldsService } from './services/fx-logical-fields.service';
import { FxRateApiService } from './services/fx-rate-api.service';
import { FxRateHelperService } from './services/fx-rate-helper.service';
import { FxRateModalService } from './services/fx-rate-modal.service';
import { FxRateModalsService } from './services/fx-rate-modals.service';
import { FxRateService } from './services/fx-rate.service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    TranslateModule.forChild(),
    LoaderModule,
    FnUiButtonModule,
    FnUiDialogModule,
    FnUiCloseIconModule,
    FnUiIconModule,
    UiComponentsModule
  ],
  declarations: [
    FxAdditionalFieldsComponent,
    FxPaymentEnhancementComponent,
    FxRateModalComponent,
    FxRateRetryModalComponent,
    FxRateTableComponent,
    FxReverseSellPanelCompent    
  ],
  providers: [
    FxLogicalFieldsService,
    FxRateApiService,
    FxRateHelperService,
    FxRateModalService,
    FxRateModalsService,
    FxRateService
  ],
  exports: [
    FxPaymentEnhancementComponent
  ]
})
export class FxProviderModule { }
