import { Directive, ComponentFactoryResolver, OnChanges, SimpleChanges, ViewContainerRef, Renderer2, Input } from '@angular/core';
import { SpinnerComponent } from './spinner.component';

@Directive({
  selector: '[appSpinner]'
})
export class SpinnerDirective implements OnChanges {

  @Input() appSpinner: boolean;
  @Input() color: string;

  spinnerComponentRef;

  constructor(private cfResolver: ComponentFactoryResolver,
    private vcRef: ViewContainerRef,
    private renderer: Renderer2) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.appSpinner) {
      if (changes.appSpinner.currentValue === true) {
        this.createSpinner();
      } else {
        this.destroySpinner();
      }
    }
  }

  createSpinner() {
    const spinnerCF = this.cfResolver.resolveComponentFactory(SpinnerComponent);
    this.spinnerComponentRef = this.vcRef.createComponent(spinnerCF);
    if (this.color) {
      this.spinnerComponentRef.instance.color = this.color;
    }
    this.renderer.appendChild(this.vcRef.element.nativeElement, this.spinnerComponentRef.injector.get(SpinnerComponent).vcRef.element.nativeElement);
  }

  destroySpinner() {
    if (this.spinnerComponentRef) {
      this.spinnerComponentRef.destroy();
    }
  }
}
