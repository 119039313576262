import { Injectable } from '@angular/core';
import { OverlayKeyboardDispatcher, OverlayRef } from '@angular/cdk/overlay';
import { IKeyboardShortcutHandlerService } from './interfaces/keyboard-shortcut-handler-service.interface';
import { KeyboardShortcutHandlerFactoryService } from './keyboard-shortcut-handler-factory.service';
import { KeyboardShortcut } from '../../models/keyboard-shortcut.model';

@Injectable()
export class KeyboardShortcutHandlerService implements IKeyboardShortcutHandlerService {

  constructor(private keyboardShortcutHandlerFactoryService: KeyboardShortcutHandlerFactoryService,
              private overlayKeyboardDispatcher: OverlayKeyboardDispatcher) { }

  handleEvent(shortcut: KeyboardShortcut): boolean {
    if (!shortcut) {
      return;
    }
    const shortcutType = shortcut.type;
    const handler = this.keyboardShortcutHandlerFactoryService.getHandler(shortcutType);
    if (!handler) {
      return;
    }
    const done = handler.handleEvent(shortcut);
    if (done) {
      this.removeBackdrops();
    }
    return done;
  }

  private removeBackdrops(): void {
    const attachedOverlays = this.overlayKeyboardDispatcher._attachedOverlays;
    if (attachedOverlays.length) {
      attachedOverlays.forEach((overlay: OverlayRef) => {
        const isDialogBackdrop = overlay.overlayElement.classList.contains('dialog-container');
        if (!isDialogBackdrop && overlay.backdropElement) {
          overlay.backdropElement.click();
        }
      });
    }
  }

}
