import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {UserData} from '../authentication/models/user-data.model';
import {AuthenticationService} from '../authentication/services/authentication.service';
import {UserPreferences} from '../core/user-settings/models/user-preferences.model';
import {UserSettingsService} from '../core/user-settings/services/user-settings.service';
import {Router} from "@angular/router";
import { MenuService } from '../core/services/menu.service';
import { AuthenticationMode } from '../authentication/models/enums/authentication-mode.enum';
import { AuthenticationConfigService } from '../authentication/services/authentication-config.service';

@Component({
  selector: 'app-archive',
  templateUrl: './archive.component.html',
  styleUrls: ['./archive.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArchiveComponent implements OnInit {

  userPreferences: UserPreferences;
  userData: UserData;
  messageCenterRoute = '/home/messages-center/system-queues';
  idpAccessToken: string;
  sessionId: string;
  constructor(private userSettingsService: UserSettingsService,
              private authenticationService: AuthenticationService,
              private router: Router,
              protected menuService: MenuService,
              protected authenticationConfigService: AuthenticationConfigService) {
    this.setAuthenticationTokens();
  }

  ngOnInit(): void {
    this.userPreferences = { ...this.userSettingsService.getPreferences() };
    this.userData = { ...this.authenticationService.userData };
  }

  onBackHomeClicked(){
    const topLevelMenu = this.menuService.getTopLevelMenu();
    const link = topLevelMenu.alias === 'system_navigation.MAINTAB_ARCHIVE' ? this.messageCenterRoute : topLevelMenu.routerLink;
    this.router.navigate([link]);
  }

  setAuthenticationTokens(){
    const isIdpMode = this.authenticationConfigService.authenticationMode === AuthenticationMode.IDP;
    if(isIdpMode){
      this.idpAccessToken =  this.authenticationService.accessToken;
    }else{
      this.sessionId = this.authenticationService.sessionId;
    }
  }
}
