import { Injectable } from '@angular/core';
import { IFileAttachmentConfigService } from './base/file-attachment-config.interface';
import { FileUploaderConfiguration } from '../../../../../../../attachment/src/lib/file-attachment/file-attachment-form/models/file-uploader-configuration.model';
import { FileMimeType } from '../../../models/enums/file-mime-type.enum';


@Injectable({
    providedIn: 'root'
})
export class FileAttachmentConfigService implements IFileAttachmentConfigService {

    private path: string;
    constructor() { }

    getAttachmentConfiguration(): FileUploaderConfiguration {
        return {
            uploadFileApi: 'do/forms/attachments/upload',
            maxNumberOfUploadFiles: 1,
            contentType: [FileMimeType.JPEG, FileMimeType.PNG, FileMimeType.PDF],
            maxFileSizeInByte: 1024 * 1024
        };
    }

}
