<form [formGroup]="form" class="user-settings-display">
  <mat-form-field class="dh-form-field">
        <mat-label>{{'my_profile.landing_page' | translate}}</mat-label>
    <mat-select [hideSingleSelectionIndicator]="true" [panelClass]="'select-dropdown'" id="my-profile-landing-page" [formControl]="form.get('landingPage')"
            (selectionChange)="onLandingPageChange()">
            <mat-option *ngFor="let page of landingPages" [value]="page" [attr.automation-id]="'my_profile.landing_page-'+page">
                {{(page | translate)}}
            </mat-option>
        </mat-select>
    </mat-form-field>

  <mat-form-field class="dh-form-field">
        <mat-label>{{'my_profile.default_navigation_bar' | translate}}</mat-label>
    <mat-select [hideSingleSelectionIndicator]="true" [panelClass]="'select-dropdown'" id="my-profile-default-navigation-bar" [formControl]="form.get('navigationBar')">
            <mat-option *ngFor="let navBar of navigationBars" [attr.automation-id]="'default_navigation_bar-'+navBar" [value]="navBar">
                {{(navBar | translate)}}
            </mat-option>
        </mat-select>
    </mat-form-field>

  <mat-checkbox color="primary" azure-id="my-display-round-number-toggle" id="my-display-round-number-toggle" [formControl]="form.get('roundNumber')">
        {{"my_profile.round_numbers" | translate}}
    </mat-checkbox>

    <div class="actions">
        <app-fn-ui-button name="display_restore" id="display-restore" (buttonClick)="restore()" color="primary">{{'my_profile.restore_default' | translate}}
        </app-fn-ui-button>
        <app-fn-ui-button name="display_cancel" id="display-cancel" (buttonClick)="cancel()" color="primary">{{'general.cancel' | translate}}</app-fn-ui-button>
        <app-fn-ui-button name="display_apply" id="display-apply" (buttonClick)="apply()" color="primary" [disabled]="!form.dirty || !changed">
            {{'general.apply' | translate}}
        </app-fn-ui-button>
        <app-fn-ui-button name="display_save" id="display-save" (buttonClick)="save()" color="primary" type="flat" [disabled]="!form.dirty">
            {{'general.ok' | translate}}
        </app-fn-ui-button>
    </div>
</form>
