import { Component, OnDestroy } from '@angular/core';
import { ShortcutInput } from '@egoistdeveloper/ng-keyboard-shortcuts';
import { Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { KeyboardShortcutsState } from '../../store/states/keyboard-shortcuts.state';
import { KeyboardShortcutActions } from '../../store/actions/keyboard-shortcuts.actions';
import { KeyboardMode } from '../../models/enums/keyboard-mode.enum';
import { KeyboardShortcut } from '../../models/keyboard-shortcut.model';
import { StoreService } from '../../../../core/services/store.service';
import { KeyboardShortcutsBuilderService } from '../../services/keyboard-shortcuts-builder.service';
import { KeyboardShortcutHandlerService } from '../../services/handlers/keyboard-shortcut-handler.service';

@Component({
  selector: 'app-keyboard-shortcuts',
  templateUrl: './keyboard-shortcuts.component.html',
  styleUrls: ['./keyboard-shortcuts.component.scss']
})
export class KeyboardShortcutsComponent implements OnDestroy {

  @Select(KeyboardShortcutsState.getKeboardMode) keyboardMode$: Observable<KeyboardMode>;
  @Select(KeyboardShortcutsState.getShortcuts) shortcuts$: Observable<Array<KeyboardShortcut>>;
  @Select(KeyboardShortcutsState.getActiveShortcut) activeShortcut$: Observable<KeyboardShortcut>;

  shortcuts: Array<ShortcutInput> = [];
  disabled = true;

  private subscriber = new Subscription();

  constructor(private storeService: StoreService,
              private keyboardShortcutHandlerService: KeyboardShortcutHandlerService,
              private keyboardShortcutsBuilderService: KeyboardShortcutsBuilderService) {
    this.initialize();
  }

  ngOnDestroy(): void {
    this.subscriber.unsubscribe();
  }

  private initialize(): void {
    this.fetchKeyboardMode();
    this.subscribeToKeyboardMode();
  }

  private fetchKeyboardMode(): void {
    this.storeService.dispatch(new KeyboardShortcutActions.FetchKeboardMode);
  }

  private fetchShortcuts(): void {
    this.storeService.dispatch(new KeyboardShortcutActions.FetchKeyboardShortcuts());
  }

  private subscribeToKeyboardMode(): void {
    const subscription = this.keyboardMode$.subscribe((mode: KeyboardMode) => {
      if (mode === KeyboardMode.ON) {
        this.disabled = false;
        this.fetchShortcuts();
        this.subscribeToShortcuts();
        this.subscribeToActiveShortcut();
      } else {
        this.disabled = true;
      }
    });
    this.subscriber.add(subscription);
  }

  private subscribeToShortcuts(): void {
    const subscription = this.shortcuts$.subscribe((shortcuts: Array<KeyboardShortcut>) => {
      this.shortcuts = this.keyboardShortcutsBuilderService.buildShortcuts(shortcuts);
    });
    this.subscriber.add(subscription);
  }

  private subscribeToActiveShortcut(): void {
    const subscription = this.activeShortcut$.subscribe((activeShortcut: KeyboardShortcut) => {
      this.keyboardShortcutHandlerService.handleEvent(activeShortcut);
    });
    this.subscriber.add(subscription);
  }

}
