export const SaveChangesLayout = {
    properties: {
        controls: {
        'message': {
            controlType: 'label',
            title: 'business-framework.profiles.modals.saveChanges.body',
            colSpan: 12,
            position: 0,
            padding: '0',
            margin: '0'
        }
    }
    }
};
