import { Injectable } from '@angular/core';
import { SystemVariables } from './models/system-variables.model';
import { ApiCachingService } from '../../api-caching/services/api-caching.service';
import { ApiCacheKeys } from '../../api-caching/models/enums/api-cache-keys.enum';

@Injectable({
  providedIn: 'root'
})
export class SystemVariablesApiService {

  constructor(protected apiCachingService: ApiCachingService) { }

  getSystemVariables(): Promise<SystemVariables> {
    const request = {
      key: ApiCacheKeys.SYSTEM_VARIABLES,
      url: 'do/systemVariables',
      isPublic: true
    };
    return this.apiCachingService.cacheRequest<SystemVariables>(request).toPromise();
  }

}

