import { Component, OnInit } from '@angular/core';
import { FileCutoffTimesProfileProviders } from '../../../core/templates/profile-form.template.providers';
import { CutoffTimesComponent } from '../cutoff-times/cutoff-times.component';
import { BFOnFormLoadComplete, BFOnValueChange, BFProfileOnSave } from '../../../core/interfaces/sdk-profile-hooks.interface';
import { IValueChange } from '../../../core/interfaces/form-value-change.interface';

@Component({
  selector: 'app-file-cutoff-times',
  templateUrl: '../../../core/templates/profile-form.template.html',
  styleUrls: ['../../../core/templates/profile-form.template.scss'],
  providers: FileCutoffTimesProfileProviders
})
export class FileCutoffTimesComponent extends CutoffTimesComponent implements OnInit, BFOnFormLoadComplete, BFOnValueChange, BFProfileOnSave {

  fgppFormLoadComplete(): void {
    super.fgppFormLoadComplete();
  }

  fgppValueChanges(change: IValueChange): void {
    super.fgppValueChanges(change);
  }

  fgppOnSave(): boolean {
    return super.fgppOnSave();
  }

}
