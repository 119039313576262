import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IconComponent } from './components/icon/icon.component';
import { DownloadIconComponent } from './components/download-icon/download-icon.component';
import { ClearIconComponent } from './components/clear-icon/clear-icon.component';
import { CommonModule } from '@angular/common';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { MatButtonModule } from '@angular/material/button';


@NgModule({
    declarations: [
        IconComponent,
        DownloadIconComponent,
        ClearIconComponent,
    ],
    imports: [
        MatIconModule,
        MatTooltipModule,
        CommonModule,
        AngularSvgIconModule,
        MatButtonModule,
        MatIconModule
    ],
    providers: [],
    exports: [
        IconComponent,
        DownloadIconComponent,
        ClearIconComponent,
    ]
  })
export class AttachmentSharedModule {

}
