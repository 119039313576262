import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ApiCacheKeys } from '../../core/api-caching/models/enums/api-cache-keys.enum';
import { ApiCachingService } from '../../core/api-caching/services/api-caching.service';
import { AppConfig, AuthenticationConfig, AzureAnalyticsConfig, BuildVersion, EnvVariablesResponse, FeatureFlags } from '../models';
import { IAppConfigService } from './app-config-service.interface';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService implements IAppConfigService {

  private readonly _envVariablesUrl = window.location.origin + '/ui/system-configs/env-varibles';
  private _config: AppConfig;
  private httpClient: HttpClient;

  private get _defaultConfig(): AppConfig {
    return {
      authentication: {
        idpMode: false,
        idpObject: {}
      } as AuthenticationConfig,
      azureAnalytics: null,
      features: {},
      uiBuildVersion: null
    };
  }

  get authenticationConfig(): AuthenticationConfig {
    return this._config ? this._config.authentication : {
      idpMode: false,
      idpObject: {}
    } as AuthenticationConfig;
  }

  get azureAnalyticsConfig(): AzureAnalyticsConfig {
    return this._config.azureAnalytics;
  }

  get featureFlags(): FeatureFlags {
    return this._config.features;
  }

  get uiBuildVersion(): BuildVersion {
    return this._config.uiBuildVersion;
  }

  constructor(httpBackend: HttpBackend,
              private apiCachingService: ApiCachingService) {
    this.httpClient = new HttpClient(httpBackend);
  }
  async initialize(): Promise<void> {

    let envVariablesResponse: EnvVariablesResponse;
    try {
      envVariablesResponse = await this.getEnvVariables().toPromise();
    } catch (error) {
      console.error(error);
      envVariablesResponse = this._defaultConfig;
    }

    let uiBuildVersion: BuildVersion;
    try {
      uiBuildVersion = await this.getUiBuildVersion().toPromise();
    } catch (error) {
      console.error(error);
      uiBuildVersion = null;
    }

    this._config = { ... this._defaultConfig, ...envVariablesResponse, uiBuildVersion };
  }

  private getEnvVariables(): Observable<EnvVariablesResponse> {
    const request = {
      key: ApiCacheKeys.ENV_VARIABLES,
      url: this._envVariablesUrl,
      isPublic: true
    };
    return this.apiCachingService.cacheRequest<EnvVariablesResponse>(request);
  }

  private getUiBuildVersion(): Observable<BuildVersion> {
    return this.httpClient.get('.version', { responseType: 'text' }).pipe(map((data: string) => {
      const releaseNumberRegex = /Release_number = (.*)\n/;
      const commitIdRegex = /Commit_id = (.*)/;

      const releaseNumberRegexMatch = data.match(releaseNumberRegex);
      if (!releaseNumberRegexMatch) {
        console.error('no matches were found for release number regex.');
        return null;
      }

      const commitIdRegexMatch = data.match(commitIdRegex);
      if (!commitIdRegexMatch) {
        console.error('no matches were found for commit id regex.');
        return null;
      }

      const buildVersion = {
        releaseNumber: releaseNumberRegexMatch[1],
        commitId: commitIdRegexMatch[1]
      };

      return buildVersion;
    }));
  }

}
