import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-base-condition-found-modal',
  templateUrl: './base-condition-found-modal.component.html',
  styleUrls: ['./base-condition-found-modal.component.scss']
})
export class BaseConditionFoundModalComponent {

  constructor(@Inject(MAT_DIALOG_DATA) private _params) { }

  get params() {
    return this._params;
  }
}
