import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { FnUiButtonModule } from '../../shared/fn-ui-button/fn-ui-button.module';
import { FnUiDialogModule } from '../../shared/fn-ui-dialog/fn-ui-dialog.module';
import { FnUiCloseIconModule } from '../../shared/fn-ui-icons/fn-ui-close-icon/fn-ui-close-icon.module';
import { MessageTemplateDownloadService } from './services/message-template-download.service';
import { MessageDisclaimerService } from './services/message-disclaimer.service';
import { MessageDisclaimerPreviewComponent } from './message-disclaimer-preview/message-disclaimer-preview.component';
import { MessageDisclaimerComponent } from './message-disclaimer.component';

@NgModule({
  declarations: [
    MessageDisclaimerComponent,
    MessageDisclaimerPreviewComponent
  ],
  imports: [
    CommonModule,
    FnUiDialogModule,
    TranslateModule.forChild(),
    FnUiCloseIconModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    FnUiButtonModule,
    HttpClientModule
  ],
  providers: [
    MessageDisclaimerService,
    MessageTemplateDownloadService
  ]
})
export class MessageDisclaimerModule { }
