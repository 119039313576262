import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {BaseEntityComponent} from "../components/base-entity/base-entity.component";
import {BreadcrumbsService} from "../../../ui-components/services/breadcrumbs.service";
import {MatDialogRef} from "@angular/material/dialog";
import {ConfirmModalComponent} from "../../../shared/fn-ui-modals/components/confirm-modal/confirm-modal.component";
import {ModalsService} from "../../../shared/fn-ui-modals/services/modals.service";
import {map} from "rxjs/operators";
import {of} from "rxjs";

@Injectable()
export class DeactivateEntityGuard implements CanDeactivate<BaseEntityComponent> {

  constructor(public modalsService: ModalsService, private translateService: TranslateService,
              private breadCrumbsService: BreadcrumbsService) {}

  private exitForm(): MatDialogRef<ConfirmModalComponent> {
    return this.modalsService.confirm({
      title: 'confirm.leavePage.title',
      message: this.translateService.instant('confirm.leavePage.message'),
      okButton: 'confirm.leavePageOkButton',
      cancelButton: 'confirm.leavePage.cancelButton'
    });
  }

  canDeactivate(component: BaseEntityComponent) {
    if (component && component['investigationComponent']?.entityNote?.form.dirty || component.detailsForm.form?.dirty) {
      this.breadCrumbsService.toUpdate = false;
      return this.exitForm().afterClosed().pipe(map((reason) => {
        return reason === 'ok';
      }))
    } else {
      this.breadCrumbsService.toUpdate = true;
      return of(true);
    }
  }
}

