import { Action, State, Selector, StateContext } from '@ngxs/store';
import { MessagesCenterQueueStateModel } from './models/messages-center-queue-state.model';
import { SetMessagesCenterQueueId } from './actions/set-messages-center-queueId.actions';
import { MessagesQueueType } from '../../messages/models/enums/messages-queue-type.enum';
import { MessagesCenterQueue } from '../models/messages-center-queue.model';


@State<MessagesCenterQueueStateModel>({
name: 'MessagesCenterQueue',
  defaults: {
    messagesQueue: {
      id: null,
      type: null,
      entityType: null
    }
  }
})
export class MessagesCenterQueueState {

  @Selector()
  static getSystemQueue(state: MessagesCenterQueueStateModel): MessagesCenterQueue {
    if (state.messagesQueue.type === MessagesQueueType.SYSTEM_QUEUE) {
      return state.messagesQueue;
    }
  }

  @Selector()
  static getCustomQueue(state: MessagesCenterQueueStateModel): MessagesCenterQueue {
    if (state.messagesQueue.type === MessagesQueueType.CUSTOM_QUEUE) {
      return state.messagesQueue;
    }
  }

  @Selector()
  static getFavoritesQueue(state: MessagesCenterQueueStateModel): MessagesCenterQueue {
    if (state.messagesQueue.type === MessagesQueueType.FAVORITE) {
      return state.messagesQueue;
    }
  }

  @Selector()
  static getRecentSearchesQueue(state: MessagesCenterQueueStateModel): MessagesCenterQueue {
    if (state.messagesQueue.type === MessagesQueueType.RECENT_SEARCH) {
      return {...state.messagesQueue};
    }
  }

  @Selector()
  static getTemplatesQueue(state: MessagesCenterQueueStateModel): MessagesCenterQueue {
    if (state.messagesQueue.type === MessagesQueueType.TEMPLATES) {
      return state.messagesQueue;
    }
  }

  @Selector()
  static getCallbackQueue(state: MessagesCenterQueueStateModel): MessagesCenterQueue {
    if (state.messagesQueue.type === MessagesQueueType.CALLBACK) {
      return state.messagesQueue;
    }
  }

  @Action(SetMessagesCenterQueueId)
  SetQueueIdForMessages(ctx: StateContext<MessagesCenterQueueStateModel>, { payload }: SetMessagesCenterQueueId) {
    ctx.patchState({
      messagesQueue: payload
    });
  }
}
