import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormsService } from '../../forms/services/forms.service';
import { FormatsService } from '../../shared/services/formats.service';
import { MessageDisclaimerService } from './services/message-disclaimer.service';
import { MessageTemplateDownloadService } from './services/message-template-download.service';


@Component({
  selector: 'app-message-disclaimer',
  templateUrl: './message-disclaimer.component.html',
  styleUrls: ['./message-disclaimer.component.scss'],
})
export class MessageDisclaimerComponent implements OnInit {
  templates;
  templateId: string;
  messageFields: {};
  mimeType: string;
  showButton = false;
  action = 'preview';

  constructor(@Inject(MAT_DIALOG_DATA) private modalData: any,
              private formsService: FormsService,
              private formatsService: FormatsService,
              private messageDisclaimerService: MessageDisclaimerService,
              protected messageTemplateDownloadService: MessageTemplateDownloadService) {
    this.formsService.templates().subscribe(data => this.templates = data);
  }

  ngOnInit() {
    this.messageFields = this.modalData.data;
    this.unformatNumericFields();
  }

  onChangeTemplate($event) {
    this.templateId = $event.value.id;
    this.action = 'download';
    this.mimeType = $event.value.mime;
    this.showButton = true;
  }


  onButtonClick() {
    if (this.action === 'download') {
      this.messageTemplateDownloadService
        .downloadTemplateFile(this.modalData.uid, this.messageFields)
        .then(() => {
          setTimeout(window.close, 10000);
        });
    } else {
      this.messageDisclaimerService.openMessageDisclaimerPreview( this.modalData.uid, this.messageFields, this.templateId).then(window.close);
    }
  }

  unformatNumericFields() {
    const numFields = ['OX_STTLM_AMT', 'P_DBT_AMT', 'F_FC_AMOUNT', 'F_FC_AMOUNT_TO',
      'D_FX_ORIG_DBT_AMT', 'P_DBT_RATE', 'D_RMT_FEES', 'D_RMT_CONVERTED_AMT',
      'D_RMT_SUBTOTAL_AMT', 'D_RMT_OTHR_FEES', 'D_RMT_TOTAL_AMT'];
    numFields.forEach(field => this.convertToNumber(field));
  }

  convertToNumber(fieldId) {
    const value = this.messageFields[fieldId];
    if (value) {
      this.messageFields[fieldId] = this.formatsService.toNumber(value);
    }
  }

}
