export const AccountAlias = {
	'OFFICE': 'ACCOUNT_ALIAS-OFFICE',
	'DEPARTMENT': 'ACCOUNT_ALIAS-DEPARTMENT',
	'REC_STATUS': 'ACCOUNT_ALIAS-REC_STATUS',
	'PROFILE_CHANGE_STATUS': 'ACCOUNT_ALIAS-PROFILE_CHANGE_STATUS',
	'EFFECTIVE_DATE': 'EFFECTIVE_DATE',
	'ACC_NO': 'ACCOUNT_ALIAS-ACC_NO',
	'CURRENCY': 'ACCOUNT_ALIAS-CURRENCY',
	'ACCOUNT_ALIAS': 'ACCOUNT_ALIAS-ACCOUNT_ALIAS',
	'ALIAS_TYPE': 'ACCOUNT_ALIAS-ALIAS_TYPE',
	'TIME_STAMP': 'TIME_STAMP',
	'UID': 'VIRTUAL-UID',
	'PROFILE_UPDATE_PK': 'PROFILE_UPDATE_PK',
	'PU_TIME_STAMP': 'PU_TIME_STAMP',
	'NOTE': 'NOTE'
};
