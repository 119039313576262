import { Component, Input } from '@angular/core';
import { StepStaus } from '../enum/step-stauts.enum';

@Component({
  selector: 'lib-stepper-icon',
  templateUrl: './stepper-icon.component.html',
  styleUrls: ['./stepper-icon.component.scss']
})
export class StepperIconComponent {

  constructor() { }

  @Input() title: number;
  @Input() stepStaus: StepStaus;

  StepStaus = StepStaus;

}
