import { Entity } from '../../../shared/models/enums/entity.enum';

export const ROUTING_DATA = {
  [Entity.FILES]: {
    fileId: 'FILE_SUMMARY_INTERNAL_FILE_ID'
  },
  [Entity.BULKS]: {
    bulkId: 'FILE_SUMMARY_INTERNAL_FILE_ID'
  },
  [Entity.BATCHES]: {
    batchId: 'BATCH_SUMMARY_INTERNAL_BATCH_ID'
  },
  [Entity.PROFILES]: {
    uniqueRecId: 'VIRTUAL.UID'
  },
  [Entity.RULES]: {
    ruleId: 'VIRTUAL.UID',
    ruleType: 'PRULES-RULE_TYPE_ID'
  }
};

