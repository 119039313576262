<app-fn-ui-dialog class="stay-leave-later-modal">

  <ng-template #header>
    <div class="stay-leave-later-header">
      <span class="title" [translate]="params.title"></span>
    </div>
    <mat-divider></mat-divider>
  </ng-template>


  <ng-template #body>
    <div class="stay-leave-later-body">
    <p>{{params.message}}</p>
      </div>
  </ng-template>

  <ng-template #footer>
    <div class="stay-leave-later-footer">
      <app-fn-ui-button [name]="params.title + ' ' + params.noButton" mat-dialog-close="stay">
        <span [translate]="params.noButton"></span>
      </app-fn-ui-button>

      <app-fn-ui-button [name]="params.title + ' ' + params.yesButton" type="flat" color="primary" mat-dialog-close="later">
        <span [translate]="params.yesButton"></span>
      </app-fn-ui-button>
    </div>
  </ng-template>

</app-fn-ui-dialog>
