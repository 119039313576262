import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CreatePaymentComponent } from '../components/create-payment/create-payment.component';

@Injectable()
export class CreatePaymentService {

  constructor(private dialog: MatDialog) {}

   openModal() {
    const config: MatDialogConfig = {
      panelClass: ['create-payment-modal', 'gpp-ui-dialog-panel'],
      minWidth: '380px'
    };
    return this.dialog.open(CreatePaymentComponent, config);
  }
}
