import { Injectable } from '@angular/core';
import { MessagesQueueType } from '../models/enums/messages-queue-type.enum';
import { MessagesQueue } from '../models/messages-queue.model';
import { SearchFilter } from '../../shared/models/search-filter.model';
import { FilterOperator } from '@fgpp-ui/grid';
import { SearchQueryBuilderService } from '../../shared/services/search-query-builder.service';

@Injectable({
  providedIn: 'root'
})
export class MessagesBaseQueryFactoryService {

  static getBaseAdditionalParams(queue: MessagesQueue): { [key: string]: string | number } {
    switch (queue.queueType) {
      case MessagesQueueType.CUSTOM_QUEUE:
        return { UDQ_NAME: queue.status };
      case MessagesQueueType.RECENT_SEARCH:
        return queue.searchQuery.additionalParameters;
      default:
        return {};
    }
  }

  static getMessagesBaseFiltersPerQueueType(queue: MessagesQueue) {
    const filters: Array<SearchFilter> = [];

    switch (queue.queueType) {
      case MessagesQueueType.SYSTEM_QUEUE:
        filters.push({ columnId: 'P_IS_HISTORY', operator: FilterOperator.NOT_EQUAL, value: ['2'] });
        if (!!queue.status) {
          filters.push({ columnId: 'P_MSG_STS', operator: FilterOperator.EQUAL, value: [queue.status] });
          if (!!queue.office) {
            filters.push({ columnId: 'P_OFFICE', operator: FilterOperator.EQUAL, value: [queue.office] });
          }
        }
        break;
      case MessagesQueueType.CUSTOM_QUEUE:
        filters.push({ columnId: 'P_IS_HISTORY', operator: FilterOperator.NOT_EQUAL, value: ['2'] });
        filters.push({ columnId: '_all', operator: FilterOperator.CONTAINS, value: ['*'] });
        if (!!queue.office) {
          filters.push({ columnId: 'P_OFFICE', operator: FilterOperator.EQUAL, value: [queue.office] });
        }
        break;
      case MessagesQueueType.RECENT_SEARCH:
        filters.push(...SearchQueryBuilderService.searchCriteriaToFilters(queue.searchQuery.searchCriteria));
        break;
      case MessagesQueueType.TEMPLATES:
        filters.push({ columnId: 'P_IS_HISTORY', operator: FilterOperator.EQUAL, value: ['2'] });
        if (!!queue.status) {
          filters.push({ columnId: 'P_MSG_STS', operator: FilterOperator.EQUAL, value: [queue.status] });
          if (!!queue.office) {
            filters.push({ columnId: 'P_OFFICE', operator: FilterOperator.EQUAL, value: [queue.office] });
          }
        }
        break;
    }

    return filters;
  }
}


