import { Injectable } from '@angular/core';
import {FxRate, FxRateModal} from '../models/fx-rate.model';
import {TranslateService} from '@ngx-translate/core';
import {FormatsService} from '../../../../shared/services/formats.service';

@Injectable()
export class FxRateHelperService {

  constructor(private translateService: TranslateService,
              private formatsService: FormatsService) { }

  getTranslateFxRateTitle(fxRate: FxRate) {
    return this.translateService.instant('message.panel.fx_rate_table.fx_rate', {
      fxFromContractRate: fxRate.fxFromContractRate,
      fxToContractRate: fxRate.fxToContractRate,
      fromCurrency: fxRate.fromCurrency,
      toCurrency: fxRate.toCurrency,
    });
  }

  getDebitAmt(fxRate: FxRateModal): number | string {
    if (fxRate.isReverseSell) {
      return this.formatsService.toNumber(fxRate.debitAmt as string);
    }
    return fxRate.toAmountBeforeFormatting;
  }
}
