import { Injectable } from '@angular/core';
import { Observable, of, tap } from 'rxjs';
import { RuleTypePermissions } from '../models/rule-type-permissions.model';
import { IRuleTypesAccessService } from './interfaces/rule-types-access-service.interface';
import { RuleTypesApiService } from './rule-types-api.service';

@Injectable()
export class RuleTypesAccessService implements IRuleTypesAccessService {

  private _permissions: RuleTypePermissions;

  constructor(private ruleTypesApiService: RuleTypesApiService) { }

  getPermissions(): Observable<RuleTypePermissions> {
    if (this._permissions) {
      return of(this._permissions);
    }
    return this.ruleTypesApiService.getPermissions().pipe(tap((response: RuleTypePermissions) => {
      this._permissions = response;
    }));
  }

  getPermission(ruleType: string): boolean {
    return this._permissions[ruleType];
  }

  hasPermission(ruleType: string): boolean {
    return this._permissions[ruleType] !== undefined;
  }

  hasReadPermission(ruleType: string): boolean {
    return this._permissions[ruleType] === false;
  }

  hasWritePermission(ruleType: string): boolean {
    return this._permissions[ruleType] === true;
  }

}
