import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { ProfilesTree } from '../../../core/navigation/sitemap/models';
import { ProfilesNavigationService } from '../../services/profiles-navigation.service';

export const NavigationTreeResolver: ResolveFn<ProfilesTree> =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): ProfilesTree => {

    const profilesNavigationService = inject(ProfilesNavigationService);

    return profilesNavigationService.selectProfiles(route.data.tab, route.paramMap.get('taskBarItem') || route.routeConfig.path);
  };
