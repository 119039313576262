import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-hint-error-message',
  templateUrl: './hint-error-message.component.html',
  styleUrls: ['./hint-error-message.component.scss']
})
export class HintErrorMessageComponent {

  @Input() hint: string;

}
