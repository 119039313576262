import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export abstract class CacheService {
  static getCacheKey( ... args: any[] ): string {
    return '';
  }
}
