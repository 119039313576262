import { Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { HttpParams } from '@angular/common/http';
import { FnUiDialogService } from '../../fn-ui-dialog/services/fn-ui-dialog.service';
import { FileDownloaderService } from '../../services/file-downloader.service';
import { ExportApi } from './export.api';
import { FnUiExportModalComponent } from '../components/fn-ui-export-modal/fn-ui-export-modal.component';
import { ExportData } from '../models/export-data.model';
import { ExportRequest } from '../models/export-request.model';
import { ExportOptions } from '../models/enums/export-options.enum';
import { FileTypes } from '../models/enums/file-types.enum';
import { SearchCondition } from '../../search/models/classes/search-condition.model';
import { SearchExpression } from '../../search/models/classes/search-expression.model';
import { Operators } from '../../search/models/enums/operators.enum';
import { PrintService } from '../../services/print.service';
import { LogicalOperator } from '../../search/models/enums/logical-operator.enum';

@Injectable()
export class ExportService {
  private topResults = 5000;

  constructor(
    private dialogService: FnUiDialogService,
    private exportApi: ExportApi,
    private fileDownloaderService: FileDownloaderService,
    private printService: PrintService) { }

  openDialog(exportData: ExportData) {
    const dialogData = {
      disableSelectedRowsOption: exportData.selectedItems.length === 0
    };
    const config: MatDialogConfig = {
      data: dialogData,
      width: '380px'
    };
    const dialogRef = this.dialogService.open(FnUiExportModalComponent, config);
    dialogRef.afterClosed().subscribe((exportRequest: ExportRequest) => {
      this.export(exportRequest, exportData);
    });
  }

  print(exportData: ExportData) {
    this.exportApi.exportToHtml(exportData.searchRequest, exportData.baseUrl, exportData.httpParams)
      .subscribe((response: string) => this.printService.printHtml(response));
  }

  private export(exportRequest: ExportRequest, exportData: ExportData) {
    if (!exportRequest) {
      return;
    }
    this.updateSearchRequest(exportRequest, exportData);

    const actionName = exportRequest.fileType === FileTypes.EXCEL ? 'exportToExcel' :
      exportRequest.fileTemplate ? 'exportToCsvWithDefaults' : 'exportToCsv';

    const url = `${exportData.baseUrl}${actionName}`;

    let httpParams = exportData.httpParams ? exportData.httpParams : new HttpParams();
    if (exportRequest.fileName) {
      httpParams = httpParams.set('fileName', exportRequest.fileName);
    }

    this.fileDownloaderService.download(url, exportData.searchRequest, httpParams);
  }

  private updateSearchRequest(exportRequest: ExportRequest, exportData: ExportData) {
    const searchRequest = exportData.searchRequest = { ...exportData.searchRequest };
    switch (exportRequest.option) {
      case ExportOptions.ALL_RESULTS:
        searchRequest.pageNo = 1;
        searchRequest.pageSize = exportData.totalCount;
        break;

      case ExportOptions.TOP:
        searchRequest.pageNo = 1;
        searchRequest.pageSize = this.topResults;
        break;

      case ExportOptions.SELECTED_ROWS:
        searchRequest.pageNo = 1;
        searchRequest.pageSize = exportData.totalCount;
        searchRequest.searchQuery = { ...searchRequest.searchQuery };
        const selectedCondition = new SearchCondition(false, exportData.itemId, <Operators>'in', exportData.selectedItems);
        searchRequest.searchQuery.searchCriteria = new SearchExpression(selectedCondition, LogicalOperator.AND, searchRequest.searchQuery.searchCriteria);
        break;

      case ExportOptions.CUSTOM:
        exportData.from = (exportRequest.range.from - 1) * searchRequest.pageSize;
        searchRequest.pageNo = exportRequest.range.from;
        searchRequest.pageSize = (exportRequest.range.to - exportRequest.range.from + 1) * searchRequest.pageSize;

        if (exportData.from + searchRequest.pageSize > exportData.totalCount) {
          searchRequest.pageSize = exportData.totalCount - exportData.from;
        }
        break;
    }
  }

}
