import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DynamicModalComponent } from '../../../business-framework/core/components/dynamic-modal/dynamic-modal.component';
import { IDynamicModalDialogData } from '../../../business-framework/core/interfaces/dynamic-modal-dialog-data.interface';
import { getEffectiveDateDialogContract } from '../../../business-framework/profiles/modals/effective-date-modal/effective-date-modal.contract';
import { getEffectiveDateDialogLayout } from '../../../business-framework/profiles/modals/effective-date-modal/effective-date-modal.layout';
import { ConditionsNotAllowedModalComponent } from '../../../rules/single-rule/components/conditions-not-allowed-modal/conditions-not-allowed-modal.component';
import { FnUiDialogService } from '../../fn-ui-dialog/services/fn-ui-dialog.service';
import { AlertModalComponent } from '../components/alert-modal/alert-modal.component';
import { ConfirmModalComponent } from '../components/confirm-modal/confirm-modal.component';
import { PromptModalComponent } from '../components/prompt-modal/prompt-modal.component';
import { PromptWithNoteModalComponent } from '../components/prompt-with-note-modal/prompt-with-note-modal.component';
import { StayLeaveLaterModalComponent } from '../components/stay-leave-later-modal/stay-leave-later-modal.component';
import { TourAdditionalIntroModalComponent } from '../components/tour-additional-intro-modal/tour-additional-intro-modal.component';
import { AlertModalData } from '../models/alert-modal-data.model';
import { ModalSize } from '../models/enums/modal-size.enum';

@Injectable({
  providedIn: 'root'
})
export class ModalsService {

  constructor(private dialogService: FnUiDialogService,
              private translateService: TranslateService) { }

  charLimitForNotes = 200;

  open<TComponent, TDialogData>(component: ComponentType<TComponent>, config?: MatDialogConfig<TDialogData>): MatDialogRef<TComponent> {
    const defaultConfig = {
        width: ModalSize.MEDIUM
    };
    config = { ...defaultConfig, ...config };
    return this.dialogService.open(component, config);
  }

  conditionsNotAllowed(additionalData): MatDialogRef<ConditionsNotAllowedModalComponent> {
    const params: MatDialogConfig = {
      width: ModalSize.MEDIUM,
      data: {
        message: {},
        messageText: '',
        title: 'modals.alertListTitle',
        okButton: 'general.ok',
        footer: 'modals.alertListFooter'
      }
    };

    params.data = this.handleModalParams(params.data, additionalData);

    return this.dialogService.open(ConditionsNotAllowedModalComponent, params);
  }

  alert(additionalData: Partial<AlertModalData> | string): MatDialogRef<AlertModalComponent> {
    const params: MatDialogConfig = {
      width: ModalSize.MEDIUM,
      data: {
        title: 'modals.alert',
        message: '',
        okButton: 'general.ok',
        showCloseButton: true
      }
    };

    params.data = this.handleModalParams(params.data, additionalData);

    return this.dialogService.open(AlertModalComponent, params);
  }

  confirm(additionalData, width?: ModalSize): MatDialogRef<ConfirmModalComponent> {
    const params: MatDialogConfig = {
      width: width || ModalSize.MEDIUM,
      closeOnNavigation: false,
      data: {
        message: '',
        title: 'modals.confirm',
        okButton: 'general.ok',
        cancelButton: 'general.cancel',
        withCopy: false
      }
    };

    params.data = this.handleModalParams(params.data, additionalData);

    return this.dialogService.open(ConfirmModalComponent, params);
  }

   confirmWithCopy(additionalData): MatDialogRef<ConfirmModalComponent> {
    const params: MatDialogConfig = {
      width: ModalSize.MEDIUM,
      data: {
        message: '',
        title: 'modals.confirm',
        okButton: 'general.ok',
        cancelButton: 'general.cancel',
        affectedList: '',
        listName: '',
        count: 0,
        withCopy: true
      }
    };

    params.data = this.handleModalParams(params.data, additionalData);

     return this.dialogService.open(ConfirmModalComponent, params);
  }

  prompt(additionalData): MatDialogRef<PromptModalComponent> {
    const params: MatDialogConfig = {
      width: ModalSize.MEDIUM,
      data: {
        message: '',
        title: '',
        name: '',
        okButton: 'general.ok',
        cancelButton: 'general.cancel'
      }
    };

    params.data = this.handleModalParams(params.data, additionalData);

    return this.dialogService.open(PromptModalComponent, params);
  }

  promptWithNote(additionalData): MatDialogRef<PromptWithNoteModalComponent> {
    const params: MatDialogConfig = {
      width: ModalSize.MEDIUM,
      data: {
      title: '',
      aboveNoteText: '',
      message: '',
      okButton: 'general.ok',
      cancelButton: 'general.cancel',
      isNoteEditable: true,
      isNoteMandatory: false,
      noteCharsLimit: this.charLimitForNotes
      }
    };

    params.data = this.handleModalParams(params.data, additionalData);

    return this.dialogService.open(PromptWithNoteModalComponent, params);
  }

  effectiveDate(additionalData): MatDialogRef<DynamicModalComponent> {

    const modalConfig = <IDynamicModalDialogData>{
      width: 550,
      height: 350,
      layout: getEffectiveDateDialogLayout(),
      contract: getEffectiveDateDialogContract(),
      title: 'business-framework.profiles.modals.effectiveDate.title',
      okButtonTittle: 'business-framework.profiles.modals.effectiveDate.buttons.ok',
      cancelButtonTitle: 'business-framework.profiles.modals.effectiveDate.buttons.cancel',
        data: {
          isEffectiveDateEditable: true,
          isNoteMandatory: false,
          noteCharsLimit: this.charLimitForNotes
        }
      };

    modalConfig.data = this.handleModalParams(modalConfig.data, additionalData);

    modalConfig.contract.properties.NOTE.maxLength = modalConfig.data.noteCharsLimit;

    if (!modalConfig.data.isEffectiveDateEditable) {
      modalConfig.layout.properties = { ...modalConfig.layout.properties, disabled: ['EFFECTIVE_DATE'] };
    }
    modalConfig.layout.properties.controls.EFFECTIVE_DATE.hidden = false;

    if (modalConfig.data.isNoteMandatory && modalConfig.contract.required.indexOf('NOTE') === -1) {
         modalConfig.contract.required.push('NOTE');
    }
    return this.openModal(modalConfig);
  }

  stayLeaveLater(): MatDialogRef<StayLeaveLaterModalComponent> {
    const config: MatDialogConfig = {
      width: ModalSize.MEDIUM,
      data: {
        title: 'tour.all.step0.title',
        message: this.translateService.instant('tour.all.step0.text'),
        noButton: this.translateService.instant('tour.all.step0.prev'),
        leaveButton: this.translateService.instant('tour.all.step0.next'),
        yesButton: this.translateService.instant('tour.all.step0.close')
      }
    };
    return this.dialogService.open(StayLeaveLaterModalComponent, config);
  }

  tourAdditionalIntroModal(): MatDialogRef<TourAdditionalIntroModalComponent> {
    const config: MatDialogConfig = {
      width: ModalSize.SMALL,
      closeOnNavigation: false,
      data: {
        title: 'tour.additional_introduction.step0.title',
        message: 'tour.additional_introduction.step0.text',
        continueButton: 'tour.additional_introduction.continue',
        showLaterButton: 'tour.additional_introduction.later'
      }
    };

    return this.dialogService.open(TourAdditionalIntroModalComponent, config);
  }

  private handleModalParams(params, inputParams) {
    if (typeof (inputParams) === 'object') {
      params = { ...params, ...inputParams };
    } else {
      params.message = inputParams;
    }
    return params;
  }

  protected openModal(config: IDynamicModalDialogData): MatDialogRef<DynamicModalComponent> {
    return this.dialogService.open(DynamicModalComponent, {
      role: 'alertdialog',
      panelClass: ['gpp-ui-modal-no-padding', 'gpp-ui-dialog-panel'],
      data: config,
      disableClose: true
    });
  }
}
