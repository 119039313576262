import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-grid-tour',
  templateUrl: './grid-tour.component.html',
  styleUrls: ['./grid-tour.component.scss']
})
export class GridTourComponent implements OnInit {

  @Input() isMessagesGrid: boolean;
  constructor() { }

  ngOnInit(): void {
  }

}
