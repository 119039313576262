import { Injectable } from '@angular/core';
import { TabHandlerService } from './tab-handler.service';
import { SitemapNode , SitemapTreeItem } from '../../sitemap/models';

@Injectable({
  providedIn: 'root'
})
export class ArchiveTabHandlerService extends TabHandlerService {

  getRouterLink(node: SitemapNode, parents: Array<SitemapTreeItem>): string {
    const taskBarItem = parents[0].id;
    const item = node.id;
    return `/home/${taskBarItem}/${item}`;
  }

  getStateName(node: SitemapNode, parents: Array<SitemapTreeItem>): string {
    return `home.archive.taskbar.main`;
  }

  getStateParams(node: SitemapNode, parents: Array<SitemapTreeItem>): any {
    const stateParams = { item: parents.length > 1 ? node.id : null };
    stateParams['taskbarItem'] = node.id;
    return stateParams;
  }
}
