import { CdkDrag, CdkDragDrop, CdkDragMove, CdkDragRelease, CdkDropList } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { debounceTime, Subject } from 'rxjs';
import { ComplexCondition } from '../../models';
import { ComplexConditionService } from '../../services/complex-condition.service';
import { RuleConditionsService } from '../../services/rule-conditions.service';

@Component({
  selector: 'app-complex-condition',
  templateUrl: './complex-condition.component.html',
  styleUrls: ['./complex-condition.component.scss']
})
export class ComplexConditionComponent implements OnInit {

  @Input() complexCondition: ComplexCondition;

  @ViewChildren(CdkDropList) dropLists?: QueryList<CdkDropList>;

  private readonly drag$ = new Subject<CdkDragMove<ComplexCondition>>();

  allowDropPredicate = (drag: CdkDrag, drop: CdkDropList): boolean => {
    return this.complexConditionService.isDropAllowed(drag, drop);
  };

  constructor(private complexConditionService: ComplexConditionService,
              public ruleConditionsService: RuleConditionsService) { }

  ngOnInit(): void {
    this.drag$
      .asObservable()
      .pipe(debounceTime(100))
      .subscribe(($event: CdkDragMove<ComplexCondition>) => {
        this.complexConditionService.onDragMove($event);
      });
  }

  changeOperator(condition: ComplexCondition): void {
    if (condition.logicalOperator === 'AND') {
      condition.logicalOperator = 'OR';
    } else {
      condition.logicalOperator = 'AND';
    }
  }

  onDragMove($event: CdkDragMove<ComplexCondition>): void {
    this.drag$.next($event);
  }

  onDragRelease($event: CdkDragRelease): void {
    this.complexConditionService.onDragRelease($event);
  }

  onDrop($event: CdkDragDrop<ComplexCondition>): void {
    this.complexConditionService.onDrop($event);
  }

  onConditionsChange(newLength: number, oldLength: number, complexCondition: ComplexCondition): void {
    this.complexConditionService.onConditionsChange(newLength, oldLength, complexCondition);
  }

}
