export enum MOPReleaseDate {
	DEPARTMENT = 'MOP_RELEASE_DATE-DEPARTMENT',
	OFFICE = 'MOP_RELEASE_DATE-OFFICE',
	REC_STATUS = 'MOP_RELEASE_DATE-REC_STATUS',
	PROFILE_CHANGE_STATUS = 'MOP_RELEASE_DATE-PROFILE_CHANGE_STATUS',
	EFFECTIVE_DATE = 'EFFECTIVE_DATE',
	PROFILE_NAME = 'MOP_RELEASE_DATE-PROFILE_NAME',
	DESCRIPTION = 'MOP_RELEASE_DATE-DESCRIPTION',
	SEND_NO_ERL = 'MOP_RELEASE_DATE-SEND_NO_ERL',
	SEND_NO_LATE = 'MOP_RELEASE_DATE-SEND_NO_LATE',
	ADJUST_ERL = 'MOP_RELEASE_DATE-ADJUST_ERL',
	ADJUST_LATE = 'MOP_RELEASE_DATE-ADJUST_LATE',
	CALENDAR_BY_TYPE = 'MOP_RELEASE_DATE-CALENDAR_BY_TYPE',
	CALENDAR_BY_NAME = 'MOP_RELEASE_DATE-CALENDAR_BY_NAME',
	TIME_STAMP = 'TIME_STAMP',
	UID = 'VIRTUAL-UID',
	PROFILE_UPDATE_PK = 'PROFILE_UPDATE_PK',
	PU_TIME_STAMP = 'PU_TIME_STAMP',
	NOTE = 'NOTE'
}
