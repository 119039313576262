import {
  AfterViewInit,
  Component,
  Input, OnChanges, OnDestroy,
  OnInit, SimpleChanges,
  ViewChild,
  ViewContainerRef
} from '@angular/core';

/**
 * This class load a component dynamically into the view.
 * it gets two inputs:
 *  1. component - the component that we want to load (not as a string!) see message.routes for example
 *  2. injectableData - each component can have some data, so as a loader, this component must get it and pass it into the form
 */
@Component({
  selector: 'app-dynamic-component-loader',
  templateUrl: './dynamic-component-loader.component.html',
})
export class DynamicComponentLoaderComponent implements AfterViewInit, OnDestroy, OnChanges {
  componentRef;
  @ViewChild('container', { read: ViewContainerRef }) viewContainerRef: ViewContainerRef;

  @Input() component;
  @Input() injectableData;

  /**
   * Clearing the container (important if we should load several component one by one)
   * Creating the relevant component
   * inject data (as @Input()) to the relevant component
   */
  ngAfterViewInit() {
    if (this.component == null) {
      return;
    }
    this.initComponent();
    this.componentRef.changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    this.viewContainerRef.clear();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.component == null || !this.viewContainerRef) {
      return;
    }
    this.initComponent();
  }

  private initComponent(): void {
    this.viewContainerRef.clear();
    this.componentRef = this.viewContainerRef.createComponent(this.component);


    if (this.injectableData != null) {
      for (const key in this.injectableData) {
        if (this.injectableData.hasOwnProperty(key)) {
          (this.componentRef.instance)[key] = this.injectableData[key];
        }
      }
    }
  }

}
