import { Component } from '@angular/core';
import { IAlertModalBody } from '../../../../shared/fn-ui-modals/interfaces/alert-modal-body.interface';
import { IdleTimeoutWarningData } from '../../models/idle-timeout-warning-data.model';

@Component({
  selector: 'app-idle-timeout-warning',
  templateUrl: './idle-timeout-warning.component.html',
  styleUrls: ['./idle-timeout-warning.component.scss']
})
export class IdleTimeoutWarningComponent implements IAlertModalBody<IdleTimeoutWarningData> {

  constructor(public data: IdleTimeoutWarningData) { }

}
