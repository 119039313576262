import { Component, OnInit, Input, ElementRef, ViewContainerRef, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit, OnDestroy {

  @Input() color: string;

  constructor(private elRef: ElementRef,
              public vcRef: ViewContainerRef) { }

  ngOnInit(): void {
    this.elRef.nativeElement.parentNode.style.position = 'relative';
  }

  get spinnerSizeStyle() {
    return {
      width: this.elRef.nativeElement.parentNode.offsetWidth + 'px',
      height: this.elRef.nativeElement.parentNode.offsetHeight + 'px'
    };
  }

  ngOnDestroy(): void {
    this.elRef.nativeElement.parentNode.style.position = 'initial';
  }
}
