import { Injectable, OnDestroy } from '@angular/core';
import { Select } from '@ngxs/store';
import { ActiveToast, IndividualConfig } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { IToastAlertManagerService } from './toast-alert-manager-service.interface';
import { AlertState } from '../../../state/alert/alert.state';
import { SidebarState } from '../../../state/sidebar/sidebar.state';
import { SidebarMode } from '../../../../state/models/enums/sidebar-mode.enum';
import { Alert } from '../../../models/alert.model';
import { ALERTS_CONFIG } from '../../../models/consts/alerts-config.const';
import { Severity } from '../../../../../shared/fn-ui-icons/fn-ui-severity-icon/enums/severity.enum';
import { FnUiToastService } from '../../../../../shared/fn-ui-toast/services/fn-ui-toast.service';
import { ToastAlertComponent } from '../toast-alert.component';
import { AlertNotificationComponent } from '../../alert-notification/alert-notification.component';

@Injectable()
export class ToastAlertManagerService implements IToastAlertManagerService, OnDestroy {

  @Select(AlertState.getNewAlerts) newAlert$: Observable<Array<Alert>>;
  @Select(SidebarState.getSidebarMode) sidebarMode$: Observable<SidebarMode>;

  private _sidebarMode: SidebarMode;

  private _activeToasts = new Array<ActiveToast<any>>();
  private _notificationToast: ActiveToast<any>;
  private _subscriber = new Subscription();

  constructor(private fnUiToastService: FnUiToastService) { }

  ngOnDestroy() {
    this._subscriber.unsubscribe();
  }

  initializeToast() {
    this.subscribeToAlerts();
    this.subscribeToSidebarMode();
  }

  private subscribeToAlerts() {
    const subscription = this.newAlert$.subscribe((alerts: Array<Alert>) => {
      if (alerts.length > 0) {
        if (this._sidebarMode === SidebarMode.CLOSE) {
          this.showToastAlerts(alerts);
        } else {
          this.showNotificationToast(alerts);
        }
      }
    });
    this._subscriber.add(subscription);
  }

  private subscribeToSidebarMode() {
    const subscription = this.sidebarMode$.subscribe((mode: SidebarMode) => {
      this._sidebarMode = mode;
      if (mode === SidebarMode.OPEN) {
        this.removeActiveToasts();
      } else {
        this.removeNotificationToast();
      }
    });
    this._subscriber.add(subscription);
  }

  private showToastAlerts(alerts: Array<Alert>) {
    alerts.forEach((alert: Alert) => {
      const options: Partial<IndividualConfig> = {
        positionClass: 'toast-top-right',
        disableTimeOut: false,
        timeOut: ALERTS_CONFIG.toastTimeout,
        tapToDismiss: false,
        progressBar: alert.severity === Severity.CRITICAL,
        progressAnimation: 'increasing',
        toastClass: 'ngx-toastr notification-alert',
        toastComponent: ToastAlertComponent
      };
      const activeToast = this.fnUiToastService.show(alert, options);
      this.pushActiveToast(activeToast);
    });
  }

  private showNotificationToast(alerts: Array<Alert>) {
    this.removeNotificationToast();
    const options = {
      positionClass: 'alert-notification-position',
      disableTimeOut: false,
      timeOut: ALERTS_CONFIG.notificationTimeout,
      tapToDismiss: false,
      toastComponent: AlertNotificationComponent,
      toastClass: 'ngx-toastr notification-alert'
    };
    this._notificationToast = this.fnUiToastService.show(alerts.length, options);
  }

  private pushActiveToast(activeToast: ActiveToast<any>) {
    this._activeToasts.push(activeToast);
    activeToast.onHidden.toPromise().then(() => {
      this._activeToasts.splice(this._activeToasts.findIndex(t => t.toastId === activeToast.toastId), 1);
    });
  }

  private removeActiveToasts() {
    this._activeToasts.forEach((activeToast: ActiveToast<any>) => {
      this.fnUiToastService.remove(activeToast.toastId);
    });
    this._activeToasts = [];
  }

  private removeNotificationToast() {
    if (this._notificationToast) {
      this.fnUiToastService.remove(this._notificationToast.toastId);
    }
  }

}
