import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { map, Observable } from 'rxjs';
import { RecentSearchesManagerService } from '../../recent-searches/services/recent-searches-manager.service';

@Injectable()
export class RedirectSearchGuard implements CanActivate {


  constructor(private router: Router,
              private recentSearchesManagerService: RecentSearchesManagerService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (route.queryParams.search) {
      const search = JSON.parse(route.queryParams.search);
      const observable = this.recentSearchesManagerService.add(search);
      if (observable) {
        return observable.pipe(map((queueId: string) => {
          const succeeded = this.router.navigate([`/home/messages-center/recent-searches/${search.entity}`], { queryParams: { queueId } });
          return succeeded ? false : true;
        }));
      }
    }
    return true;
  }

}
