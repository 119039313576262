import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationIdpService } from './authentication/services/authentication-idp.service';
import { AuthenticationService } from './authentication/services/authentication.service';
import { AuthenticationConfig } from './config/models';
import { AppConfigService } from './config/services/app-config.service';
import { ApiCachingStorageService } from './core/api-caching/services/api-caching-storage.service';
import { FeatureFlagsService } from './core/feature-flags/services/feature-flags.service';
import { MultiTabsManagerService } from './core/multi-tabs/services/multi-tabs-manager.service';
import { StateStoreService } from './core/routing/services/state-store.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'GPP';
  private prefixCallBack = '/callback';
  private prefixLogin = '/user/signin';
  private authenticationConfig: AuthenticationConfig;

  private prefix: string;
  private params: string;

  constructor(private router: Router,
              private multiTabsManagerService: MultiTabsManagerService, appConfigService: AppConfigService,
    private apiCachingStorageService: ApiCachingStorageService, private authenticationService: AuthenticationService,
    private featureFlagsService: FeatureFlagsService, private authenticationIdpService: AuthenticationIdpService,
              private stateStoreService: StateStoreService) {
    router.canceledNavigationResolution = 'computed';
    this.authenticationConfig = appConfigService.authenticationConfig;
  }

  async ngOnInit(): Promise<void> {
    if (!this.multiTabsManagerService.hasActiveTabs) {
      this.apiCachingStorageService.clear();
    }
    this.featureFlagsService.initialize();
    this.prefix = window.location.pathname;
    this.params = window.location.search;
    await this.login();
  }

  private async login(): Promise<void> {
    const isLoggedIn = this.authenticationService.isUserDataSet;
    const url = window.location.hash.substring(1);
    const isRedirected = this.guard(url, isLoggedIn);
    if(!isRedirected) {
      if (isLoggedIn) {
        this.loggedInNavigate(url);
      } else {
        this.goToLogin(url);
      }
    }
  }

  private loggedInNavigate(url: string): void {
    if (url.length > 0 ) {
      if(url.indexOf('user/signin') !== -1) {
        this.router.navigate(['/home']);
      } else {
        this.router.navigateByUrl(url);
      }
    } else {
      if(this.prefix !== '/' && this.prefix !== '/gpp/') {
        this.router.navigateByUrl(this.prefix + this.params);
      } else {
        this.router.navigate(['/home']);
      }
    }
  }

  private goToLogin(url: string): void {
    if (this.authenticationConfig.idpMode) {
      if (url && url.includes('code') && url.includes('state')) {
        this.router.navigate([this.prefixCallBack], {
          fragment: url
        });
      } else {
        this.authenticationIdpService.goToLoginPageIDP();
      }
    } else {
      this.router.navigate([this.prefixLogin]);
    }
  }

  private guard(url: string, isLoggedIn: boolean): boolean {
    if(url?.includes('home') && !isLoggedIn) {
        this.stateStoreService.saveRouterState(url);
        this.goToLogin(url);
        return true;
    }
    return false;
  }
}
