import { Component } from '@angular/core';
import { IValueChange } from '../../../core/interfaces/form-value-change.interface';
import { BFOnValueChange, BFOnFormLoadComplete } from '../../../core/interfaces/sdk-profile-hooks.interface';
import { ProfileFormTemplateProviders } from '../../../core/templates/profile-form.template.providers';
import { AbstractProfileFormComponent } from '../../abstract/abstract-profile/abstract-profile-form.component';
import { SenderCreditLine } from '../../models/sender-credit-line.model';
import { ProfileLayoutType } from '../../enums/profile-layout-type.enum';

@Component({
  selector: 'app-sender-credit-lines',
  templateUrl: '../../../core/templates/profile-form.template.html',
  styleUrls: ['../../../core/templates/profile-form.template.scss'],
  providers: ProfileFormTemplateProviders
})
export class SenderCreditLineComponent extends AbstractProfileFormComponent implements BFOnValueChange, BFOnFormLoadComplete {

  fgppFormLoadComplete(): void {
    this.handleAmountControlAvailability();
  }

  fgppValueChanges(change: IValueChange): void {
    switch (change.control) {
      case SenderCreditLine.MATCH_LVL:
      case SenderCreditLine.POL_CODE:
        this.handleCodeLength();
        return;

      case SenderCreditLine.UNLIMITED:
        this.handleAmountControlAvailability();
        return;
    }
  }

  private handleAmountControlAvailability(): void {
    const amountControl = this.formMgr.get(SenderCreditLine.POL_AMOUNT);
    const unlimited = this.formMgr.get(SenderCreditLine.UNLIMITED).getValue();
    if (unlimited === '1') {
      amountControl.markAsOptional();
      if (this.layoutMode !== ProfileLayoutType.VIEW) {
        amountControl.disable().setValue('');
      }
    } else {
      amountControl.markAsRequired();
      if (this.layoutMode !== ProfileLayoutType.VIEW) {
        amountControl.enable();
      }
    }
  }

  private handleCodeLength(): void {
    const codeControl = this.formMgr.get(SenderCreditLine.POL_CODE);
    const code = codeControl.getValue();
    if (code) {
      const matchLvl = +this.formMgr.get(SenderCreditLine.MATCH_LVL).getValue();
      const newCode = code.length >= matchLvl ? code.substring(0, matchLvl) : '';
      codeControl.setValue(newCode);
    }
  }
}
