import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertDetailsDialogData } from './models/alert-details-dialog-data.model';
import { DialogResult } from './models/enums/dialog-result.enum';

@Component({
  selector: 'app-alert-details-dialog',
  templateUrl: './alert-details-dialog.component.html',
  styleUrls: ['./alert-details-dialog.component.scss']
})
export class AlertDetailsDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<AlertDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AlertDetailsDialogData
  ) { }

  onCloseClick() {
    this.dialogRef.close(DialogResult.CLOSE);
  }

  onInvestigateClick() {
    this.dialogRef.close(DialogResult.INVESTIGATE);
  }
}
