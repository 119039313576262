import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CallerCallbackApiService } from '../components/messages-center-callback/services/caller-callback-api.service';
import { ENTITY_NAVIGATION_TOKEN } from './services/base-navigation.service';
import { BatchesNavigationService } from './services/batches-navigation.service';
import { MessagesNavigationService } from './services/messages-navigation.service';
import { FilesNavigationService } from './services/files-navigation.service';
import { BulksNavigationService } from './services/bulks-navigation.service';
import { MessagesCenterNavigationService } from './services/messages-center-navigation.service';
import { MessagesCenterNavigationTreeComponent } from './components/messages-center-navigation-tree/messages-center-navigation-tree.component';
import { FnUiNavigationTreeModule } from '../../shared/fn-ui-navigation-tree/fn-ui-navigation-tree.module';
import { PipesModule } from '../../pipes/pipes.module';


@NgModule({
  declarations: [
    MessagesCenterNavigationTreeComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    FnUiNavigationTreeModule,
    PipesModule
  ],
  providers: [
    MessagesCenterNavigationService,
    CallerCallbackApiService,
    {
      provide: ENTITY_NAVIGATION_TOKEN,
      multi: true,
      useClass: MessagesNavigationService
    },
    {
      provide: ENTITY_NAVIGATION_TOKEN,
      multi: true,
      useClass: FilesNavigationService
    },
    {
      provide: ENTITY_NAVIGATION_TOKEN,
      multi: true,
      useClass: BulksNavigationService
    },
    {
      provide: ENTITY_NAVIGATION_TOKEN,
      multi: true,
      useClass: BatchesNavigationService
    }
  ],
  exports: [
    MessagesCenterNavigationTreeComponent
  ]
})
export class MessagesCenterNavigationModule { }
