import { Component, Input } from '@angular/core';
import { StepStaus } from '../enum/step-stauts.enum';
import { StepStyle } from '../enum/step-style.enum';
import { StepForm } from '../../file-attachment/models/step-form.model';

@Component({
  selector: 'lib-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent {


  @Input() step: StepForm;

  StepStaus = StepStaus;
  StepStyle = StepStyle;

  constructor() {
  }

}
