import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FnUiAutocompleteComponent } from './fn-ui-autocomplete.component';
import { FtHighlightText } from '../../pipes/ftHighlightText.pipe';

@NgModule({
  declarations: [
    FnUiAutocompleteComponent,
    FtHighlightText
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule
  ],
  exports:[
    FnUiAutocompleteComponent
  ]
})
export class FnUiAutocompleteModule { }
