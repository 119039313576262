import {
  Component,
  ViewEncapsulation,
  Input,
  TemplateRef,
  ElementRef,
  ChangeDetectionStrategy, OnInit, OnDestroy, AfterViewInit, ViewChild, EventEmitter, Output
} from '@angular/core';
import { FormsService } from '../../forms/services/forms.service';
import { Subscription } from 'rxjs';
import { FormValidatorsService } from '../../services/form-validators.service';
import { HintService } from '../../services/hint.service';
import { FaFormComponent } from '../fa-form/fa-form.component';
import { MatExpansionPanel } from '@angular/material/expansion';

@Component({
  selector: 'app-dh-panel',
  templateUrl: './dh-panel.component.html',
  styleUrls: ['./dh-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DhPanelComponent implements OnInit, OnDestroy, AfterViewInit {

  panelOpened;
  panelClosed: boolean;
  isReadOnly = false;

  @Input() formGroup;

  @Input() id: string;
  @Input() title: string;
  @Input() disabled: boolean;
  @Input() expanded: boolean;
  @Input() showContentInDom: boolean;
  @Input() isLocked = false;
  @Input() isExpandDisabled = false;
  @Input() showIcon = false;
  @Input() iconTitle: string;

  @Input() previewTemplate: TemplateRef<ElementRef>;
  @Input() contentTemplate: TemplateRef<ElementRef>;

  @Output() opened = new EventEmitter<void>();
  @Output() closed = new EventEmitter<void>();
  @Output() afterExpanded = new EventEmitter<void>();
  @Output() afterCollapsed = new EventEmitter<void>();

  @ViewChild(MatExpansionPanel) panel: MatExpansionPanel;

  private _subscription = new Subscription();


  /**
   * setting the contol's value to empty string
   * @param controls
   */
  static clearControls(controls) {
    for (let i = 0; i < controls.length; i++) {
      controls[i].setValue('', { emitEvent: false });
      controls[i].enable({ emitEvent: true });
    }
  }

  constructor(protected formsService: FormsService,
              private hintService: HintService,
              protected formValidatorsService: FormValidatorsService,
              public faFormComponent: FaFormComponent,
              protected elem?: ElementRef) { }

  ngOnInit() {
    this.panelClosed = !this.expanded;
    if (this.formGroup != null) { //panel as part of form
      this.checkStatus();
    }
  }

  ngAfterViewInit(): void {
    this.elem?.nativeElement.setAttribute('azure-id', this.title + ' panel');
    if (this.panel) {
      if (this.showContentInDom) {
        this.panel.open();
      }
      if (!this.expanded) {
        this.panel.close();
      }
    }
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  checkStatus() {
      if (!this.isLocked) {
          const messageStatus = this.formGroup.get('P_MSG_STS').value;
          if (messageStatus === 'COMPLETE' || messageStatus === 'VERIFY') {
              this.isReadOnly = true;
          }
      }
  }

  getBusinessData(field, additionalParameters?) {
    if (additionalParameters == null) {
      additionalParameters = {};
    }
    return new Promise((resolve, reject) => this.callBusinessData(resolve, reject, field, additionalParameters));
  }

  private callBusinessData(resolve, reject, field, additionalParameters) {
    const onSuccess = () => {
      const control = this.formGroup.get(field.fieldId);
      control.hint = null;
      if (control.hasError('drillDownValueDoesNotExist')) {
        this.faFormComponent.removeValidator('DrilldownValueExistsValidation', field.fieldId);
      }
      control.updateValueAndValidity({ onlySelf: true });
    };

    const onFailure = (error) => {
      if (error.status === 422 && error.error?.payload?.ERROR_CODE !== '1393') {
        const control = this.formGroup.get(field.fieldId);
        control.hint = null;
        if (!additionalParameters.isHintError) {
          if (!control.errors) {
            this.faFormComponent.addValidator('DrilldownValueExistsValidation', field.fieldId);
            control.updateValueAndValidity({ onlySelf: true });
          }
        } else {
          control.hint = this.hintService.getHint(error.error.payload.ERROR_CODE);
          control.updateValueAndValidity({ onlySelf: true });
        }
      } else {
        //show general notification
      }
    };

    if (field.fieldId == null || field.value == null) {
      console.error('callBusinessData was called but without a valid field/value');
      return;
    }

    const obj = {};
    obj[field.fieldId] = {
      'ID': field.fieldId,
      'VALUE': field.value,
      'additionalParameters': additionalParameters
    };

    this._subscription.add(this.formsService.getBusinessData(obj).subscribe(data => {
        if (data == null || data.payload.length === 0) {
          return;
        }

        const fieldsId: Array<string> = Object.keys(data.payload);
        fieldsId.forEach(fieldId => {
          const control = this.formGroup.get(fieldId);
          if (control != null) {
            control.setValue(data.payload[fieldId], { emitEvent: true });
          }
        });
        onSuccess();
        resolve(data.payload);
      },
      error => {
        onFailure(error);
        reject(error);
      }));
  }

  afterExpand(): void {
    this.afterExpanded.emit();
  }

  afterCollapse(): void {
    this.panelClosed = true;
    this.afterCollapsed.emit();
  }

  onOpen(): void {
    this.panelOpened = true;
    this.panelClosed = false;
    this.opened.emit();
  }

  onClose(): void {
    this.panelOpened = false;
    this.closed.emit();
  }

}
