<div class="oops-page-container">
  <div class="content">
    <div class="oops-text">
      <div class="title" id="oops-title">{{'oopsPage.title' | translate}}</div>
      <div class="body" id="oops-body">{{'oopsPage.content' | translate}}</div>
    </div>
    <div class="oops-image">
      <img [alt]="'oopsPage.title' | translate" src="assets/images/svg/page-not-found-robot.svg"/>
    </div>
  </div>
</div>
