import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { TOAST_CONFIG } from './consts/toast-config.const';
import { FnUiToastService } from './services/fn-ui-toast.service';


@NgModule({
  imports: [
    CommonModule,
    ToastrModule.forRoot(TOAST_CONFIG)
  ],
  declarations: [
  ],
  providers: [
    FnUiToastService,
  ]
})
export class FnUiToastModule {
}
