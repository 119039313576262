import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FnUiOption } from 'projects/gpp/src/app/shared/fn-ui-select/fn-ui-select.component';

@Component({
  selector: 'app-condition-operator',
  templateUrl: './condition-operator.component.html',
  styleUrls: ['./condition-operator.component.scss']
})
export class ConditionOperatorComponent {

  @Input() operator: string;
  @Input() operatorList: Array<FnUiOption>;

  @Output() operatorChanged = new EventEmitter<string>();

  onOperatorChange(operator: string): void {
    this.operatorChanged.emit(operator);
  }

}
