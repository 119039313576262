import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, Subject, map } from 'rxjs';
import { FormResponse } from '../../message/models/form-response.mode';
import dayjs from 'dayjs';
import { IbanValidationRequest } from '../../message/iban-validation/models/iban-validation-request.model';
import { IbanValidationResponse } from '../../message/iban-validation/models/iban-validation-response.model';

@Injectable()
export class FormsService {

    private URL = 'do/entity/';
    private httpClient: HttpClient;
    private formName: string;
    private entity: string;
    private _isCallerPayment: string;
    controlData$ = new Subject<any>();
    agentChange = new Subject<any>();

    constructor(httpClient: HttpClient) {
        this.httpClient = httpClient;
    }

    public setFormName(formName: string) {
        this.formName = formName;
    }

    public setEntity(entity: string) {
        this.entity = entity;
    }

    public set isCallerPayment(isCallerPayment: string) {
      this._isCallerPayment = isCallerPayment;
    }

    public get isCallerPayment(): string {
      return this._isCallerPayment;
    }

  /**
   * Copy fields from the formData (all form controls) to the metadata object which will be sent to the server
   * @param metadata
   * @param formData
   * @returns {any}
   */
    public buildMetadataObject(metadata, formData) {
      for (const fieldId in formData.getRawValue()) {
        if (typeof metadata[fieldId] === 'object' && metadata[fieldId].hasOwnProperty('value')) {
          metadata[fieldId].value = this.transformValue(formData.getRawValue()[fieldId]);
        }
      }
      return metadata;
    }
    /**
     * This function is used as fields modifier before posting to server.
     * If the value passed should be modified, this is the right place to do so
     * @param value
     */
    public transformValue(value) {
      if (value instanceof dayjs) {
        return (value as any).unix() * 1000;
      }

      if (value === '') {
        return null;
      }

      return value;
    }

    public prepareOpenForm(entity: string, id: string, paymentType?: string): Observable<FormResponse> {
      const isCallerPayment = this._isCallerPayment != null ? this._isCallerPayment : null;
      const params = new HttpParams().set('uid', entity + '^' + id).set('paymentType', paymentType).set('isCallerPayment', isCallerPayment);
      this.entity = entity;
      return this.httpClient.get<FormResponse>('do/entity/forms', { params });
    }

    public getMessageOpsData(id: string): Observable<any> {
      return this.httpClient.get<FormResponse>(`/payments/v1/messages/${id}`, {observe: 'response'});
    }

    public getForm(entity: string, id: string, formName?: string): Promise<any> {
        const form = formName != null ? formName : this.formName;
        this.entity = entity;
        const params = new HttpParams()
            .set('uid', entity + '^' + id)
            .set('form', form);
        return this.httpClient.get('do/entity', { params: params }).toPromise();
    }

    public getPermissionsForAttachmentsButton(mid: string, form): Promise<any> {
      const params = new HttpParams()
          .set('mid', mid)
          .set('formName', form);
      return this.httpClient.get('do/entity/attachmentsButton', { params: params }).toPromise();
  }

    public saveForm(form: Object): Promise<any> {
        const params = new HttpParams()
            .set('uid', this.entity + '^' + form['P_MID'].value)
            .set('form', this.formName);
        return this.httpClient.put('do/entity', form, { params: params }).toPromise();
    }

    public getBusinessData(field: Object): Observable<any> {
        return this.httpClient.put('do/entity/businessData', field);
    }

    public getBusinessDataWithCustDetails (field: Object): Observable<any> {
      return this.httpClient.put('do/entity/businessDataWithCustDetails', field);
    }

    public getBusinessDataWithCustDetailsInSync (field: Object): Observable<any> {
      return this.httpClient.put('do/entity/businessDataWithCustDetails', field).pipe(map((res: Response) => res.json()));
    }

    public getControlsData(controlsNames: Array<string>): Observable<any> {
      const header = new HttpHeaders({ 'Accept': 'application/json,*/*' });
      const httpOptions = {
        headers: header
      };
      return this.httpClient.post('do/entity/controls', controlsNames, httpOptions);
    }

    public releaseLock(uid: string): Observable<any> {
        const params = new HttpParams()
            .set('uid', this.entity + '^' + uid)
            .set('form', this.formName);
        const header = new HttpHeaders({ 'Accept': 'application/json,*/*' });
        const httpOptions = {
          headers: header,
          params: params
        };
        return this.httpClient.post('do/entity/release', null, httpOptions);
    }

  public releaseLockedMid(mid: string, payload: any = null): Promise<any> {
    const params = new HttpParams()
      .set('mid', mid);
    const header = new HttpHeaders({ 'Accept': 'application/json,*/*' });
    const httpOptions = {
      headers: header,
      params: params
    };
    return this.httpClient.post('do/entity/releaseLockedMid', payload, httpOptions).toPromise();
  }

/**
   * Save form data in cache in order to transfer
   * the updated data to pop out
   * @param {Object} form
   * @param {string} uid
   * @returns {Observable<any>}
   */
    public tempSave(form: Object, uid: string): Observable<any> {
      const params = new HttpParams()
        .set('form', this.formName);
      const url = 'do/entity/tempSave/' + this.entity + '^' + uid;
      return this.httpClient.put(url, form, { params: params });
    }

    public getCreatePaymentForm(urlParams: any): Promise<any> {
      const params = new HttpParams()
        .set('uid', urlParams.form + '^' + urlParams.uid)
        .set('form', this.formName ? this.formName : urlParams.messageComponent)
        .set('department', urlParams.P_DEPARTMENT)
        .set('office', urlParams.txtDefOffice);
      return this.httpClient.get('do/entity/createPayment', { params: params }).toPromise();
    }

    public getSingleAuditData(entity: string, mid: string, timeStamp: string): Promise<any> {
        const params = new HttpParams()
        .set('uid', entity + '^' + mid)
        .set('timeStamp', timeStamp);
        return this.httpClient.get('do/entity/singleAuditTrailData', { params: params }).toPromise();
    }

    public actions(actionId: string, form: Object, formName?: string): Observable<any> {
        const params = new HttpParams().set('form', formName != null ? formName : this.formName);
        const url = this.URL + this.entity + '^' + form['P_MID'].value + '/' + actionId;
        const header = new HttpHeaders({ 'Accept': 'application/json,*/*' });
        const httpOptions = {
          headers: header,
          params: params
        };
        return this.httpClient.post(url, form, httpOptions);
    }

    public locked(mid: string): Promise<any> {
        const url = 'do/autoFeeder/' + mid + '/locked';
        return this.httpClient.get(url).toPromise();
    }

    public templates(): Observable<any> {
      return this.httpClient.get('do/templates');
    }

    public getTemplate(templateId, messageFields, mid): Observable<string> {
      const params = new HttpParams()
        .set('template', templateId)
        .set('mid', mid);
      const header = new HttpHeaders({ 'Accept':  'text/html' });
      const httpOptions = {
        headers: header,
        params: params,
        responseType: 'text'
      };
      // @ts-ignore
      return this.httpClient.post<string>('do/templates/generate', { data: messageFields }, httpOptions);
    }

    public getSenderABAName(office: string, aba: string) {
      return this.httpClient.get<any>(`do/entity/getSenderNm/${office}/${aba}`);
    }

    public validateIban(body: IbanValidationRequest): Observable<IbanValidationResponse> {
      return this.httpClient.put<IbanValidationResponse>('do/entity/validateIban', body);
    }
}
