import { ErrorCodeMapping } from '../../../../models/error-code-mapping.model';
import { ChannelTypeControlsMetadata } from '../channel-type-control-metadata.model';
import { ErrorCodeChannelType } from '../enums/error-code-channel-type.enum';

export const FromChannelTypeControlsMetadata: ChannelTypeControlsMetadata = {
  [ErrorCodeChannelType.GPP]: [
    {
      originalControlId: ErrorCodeMapping.FROM_CHANNEL,
      displayControlId: ErrorCodeMapping.V_FROM_GPP,
      isMandatory: true,
      defaultValue: ErrorCodeChannelType.GPP
    },
    {
      originalControlId: ErrorCodeMapping.FROM_CODE,
      displayControlId: ErrorCodeMapping.V_FROM_GPP_CODE_SELECT,
      isMandatory: true
    }
  ],
  [ErrorCodeChannelType.HOST]: [
    {
      originalControlId: ErrorCodeMapping.FROM_CHANNEL,
      displayControlId: ErrorCodeMapping.V_FROM_HOST_SELECT,
      isMandatory: true
    },
    {
      originalControlId: ErrorCodeMapping.FROM_CODE,
      displayControlId: ErrorCodeMapping.FROM_CODE,
      isMandatory: true
    }
  ],
  [ErrorCodeChannelType.MOP]: [
    {
      originalControlId: ErrorCodeMapping.FROM_CHANNEL,
      displayControlId: ErrorCodeMapping.V_FROM_MOP_SELECT,
      isMandatory: true
    },
    {
      originalControlId: ErrorCodeMapping.FROM_CODE,
      displayControlId: ErrorCodeMapping.V_FROM_MOP_CODE_SELECT,
      isMandatory: true
    }
  ],
  [ErrorCodeChannelType.NONE]: [
    {
      originalControlId: ErrorCodeMapping.FROM_CHANNEL,
      displayControlId: ErrorCodeMapping.FROM_CHANNEL,
      isMandatory: true
    },
    {
      originalControlId: ErrorCodeMapping.FROM_CODE,
      displayControlId: ErrorCodeMapping.FROM_CODE,
      isMandatory: true
    }
  ]
};

export const ToChannelTypeControlsMetadata: ChannelTypeControlsMetadata = {
  [ErrorCodeChannelType.GPP]: [
    {
      originalControlId: ErrorCodeMapping.TO_CHANNEL,
      displayControlId: ErrorCodeMapping.V_TO_GPP,
      isMandatory: true,
      defaultValue: ErrorCodeChannelType.GPP
    },
    {
      originalControlId: ErrorCodeMapping.TO_CODE,
      displayControlId: ErrorCodeMapping.V_TO_GPP_CODE_SELECT,
      isMandatory: false
    }
  ],
  [ErrorCodeChannelType.HOST]: [
    {
      originalControlId: ErrorCodeMapping.TO_CHANNEL,
      displayControlId: ErrorCodeMapping.V_TO_HOST_SELECT,
      isMandatory: true
    },
    {
      originalControlId: ErrorCodeMapping.TO_CODE,
      displayControlId: ErrorCodeMapping.TO_CODE,
      isMandatory: false
    }
  ],
  [ErrorCodeChannelType.MOP]: [
    {
      originalControlId: ErrorCodeMapping.TO_CHANNEL,
      displayControlId: ErrorCodeMapping.V_TO_MOP_SELECT,
      isMandatory: true
    },
    {
      originalControlId: ErrorCodeMapping.TO_CODE,
      displayControlId: ErrorCodeMapping.V_TO_MOP_CODE_SELECT,
      isMandatory: false
    }
  ],
  [ErrorCodeChannelType.NONE]: [
    {
      originalControlId: ErrorCodeMapping.TO_CHANNEL,
      displayControlId: ErrorCodeMapping.TO_CHANNEL,
      isMandatory: true
    },
    {
      originalControlId: ErrorCodeMapping.TO_CODE,
      displayControlId: ErrorCodeMapping.TO_CODE,
      isMandatory: false
    }
  ]
};
