import { Component } from '@angular/core';
import { AbstractProfileFormComponent } from '../../abstract/abstract-profile/abstract-profile-form.component';
import { ProfileFormTemplateProviders } from '../../../core/templates/profile-form.template.providers';
import { BFOnValueChange } from '../../../core/interfaces/sdk-profile-hooks.interface';
import { IValueChange } from '../../../core/interfaces/form-value-change.interface';
import { NCC } from '../../models/ncc.model';

@Component({
  selector: 'app-ncc',
  templateUrl: '../../../core/templates/profile-form.template.html',
  styleUrls: ['../../../core/templates/profile-form.template.scss'],
  providers: ProfileFormTemplateProviders
})
export class NccComponent extends AbstractProfileFormComponent implements BFOnValueChange {

  fgppValueChanges(change: IValueChange): void {
    if (change.control === NCC.NCC_TYPE_ISO) {
      this.populateCountryNccDetails();
    }
    if (change.control === NCC.CUST_CODE) {
      this.populateCustomerDetails();
    }
  }

  private populateCountryNccDetails(): void {
    const countryNCCDetails = this.getDrilldownData(NCC.NCC_TYPE_ISO);
    if (countryNCCDetails) {
      this.formMgr.get(NCC.NCC_TYPE_SWIFT).setValue(countryNCCDetails[NCC.COUNTRY_NCC_TYPE]);
      this.formMgr.get(NCC.PROPRIETARY).setValue(countryNCCDetails[NCC.COUNTRY_NCC_PROPRIETARY] === '1' ? '1' : '0');
    } else {
      this.formMgr.get(NCC.NCC_TYPE_SWIFT).reset();
      this.formMgr.get(NCC.PROPRIETARY).reset();
    }
  }

  private populateCustomerDetails(): void {
    const customerDetails = this.getDrilldownData(NCC.CUST_CODE);
    if (customerDetails) {
      this.formMgr.get(NCC.CUST_NAME).setValue(customerDetails[NCC.CUST_NAME]);
      this.formMgr.get(NCC.SHORT_NAME).setValue(customerDetails[NCC.SHORT_NAME]);
      this.formMgr.get(NCC.CITY).setValue(customerDetails[NCC.CITY]);
      this.formMgr.get(NCC.COUNTRYCODE).setValue(customerDetails[NCC.COUNTRYCODE]);
      this.formMgr.get(NCC.ZIP).setValue(customerDetails[NCC.ZIP]);
    } else {
      this.formMgr.get(NCC.CUST_NAME).reset();
      this.formMgr.get(NCC.SHORT_NAME).reset();
      this.formMgr.get(NCC.CITY).reset();
      this.formMgr.get(NCC.COUNTRYCODE).reset();
      this.formMgr.get(NCC.ZIP).reset();
    }
  }
}
