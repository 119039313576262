import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { AppConfigService } from '../../../config/services/app-config.service';
import { FeatureFlagsStateModel } from './feature-flags-state.model';
import { InitializeFeatureFlags } from './feature-flags.actions';


@State<FeatureFlagsStateModel>({
  name: 'feature_flags',
  defaults: {}
})
@Injectable()
export class FeatureFlagsState {

  constructor(private appConfigService: AppConfigService) { }

  @Selector()
  static isFeatureOn(state: FeatureFlagsStateModel): (name: string) => boolean {
    return (name: string) => !!state[name];
  }

  @Selector()
  static isFeatureOff(state: FeatureFlagsStateModel): (name: string) => boolean {
    return (name: string) => !state[name];
  }

  @Action(InitializeFeatureFlags)
  initialize(ctx: StateContext<FeatureFlagsStateModel>): void {
    const featureFlags = this.initFeatureFlag(this.appConfigService.featureFlags || {});
    ctx.patchState(featureFlags);
  }

  initFeatureFlag(featureFlags: Object): {[key: string]: boolean} {
    const featureFlagBoolean = {};
    Object.keys(featureFlags).forEach(key => {
     if (typeof featureFlags[key] === 'boolean') {
      featureFlagBoolean[key] = featureFlags[key];
     } else if (typeof featureFlags[key] === 'string' && featureFlags[key].toLowerCase() === 'true') {
      featureFlagBoolean[key] = true;
     } else {
      featureFlagBoolean[key] = false;
     }
    });
    return featureFlagBoolean;
  }

}
