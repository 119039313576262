import { ITextFormatterService } from './text-formatter.interface';
import { Injectable } from '@angular/core';

@Injectable()
export class TextFormatterService implements ITextFormatterService {
    constructor() {
    }

    foramt(text: string, stringToReplace: Array<string>, delemiter: string ): string {
       let delemiterIndex = 0;
       const formattedText = [];
        for (let i = 0; i < text.length; i++) {
            formattedText.push(text[i] === delemiter ? stringToReplace[delemiterIndex++] : text[i]);
        }

        return formattedText.join('');
    }
}
