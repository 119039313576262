const FxLogicalFieldsErrorMessage = 'FxLogicalFieldsError: ';
const NotFoundOfficeFxProviderErrorMessage = 'No FX provider is defined for office: ';
const NotSupportedFxProviderErrorMessage = ' FX provider is not supported';
const NotSupportedCurrencyErrorMessage = ' currency is not supported by the office FX provider. The currency doesn\'t exist in json file: ';

export class FxLogicalFieldsError extends Error {

  constructor(message: string) {
    super(FxLogicalFieldsErrorMessage + message);
  }

}

export class NotFoundOfficeFxProviderError extends FxLogicalFieldsError {

  constructor(office: string) {
    super(`${NotFoundOfficeFxProviderErrorMessage}'${office}'`);
  }

}

export class NotSupportedFxProviderError extends FxLogicalFieldsError {

  constructor(fxProviderName: string) {
    super(`'${fxProviderName}'${NotSupportedFxProviderErrorMessage}`);
  }

}

export class NotSupportedCurrencyError extends FxLogicalFieldsError {

  constructor(currency: string, file: string) {
    super(`'${currency}'${NotSupportedCurrencyErrorMessage}${file}`);
  }

}
