import { Injectable, Injector, ApplicationRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AlertModalComponent } from '../../../shared/fn-ui-modals/components/alert-modal/alert-modal.component';
import { ModalSize } from '../../../shared/fn-ui-modals/models/enums/modal-size.enum';
import { ModalsService } from '../../../shared/fn-ui-modals/services/modals.service';
import { IdleTimeoutWarningComponent } from '../components/idle-timeout-warning/idle-timeout-warning.component';
import { IdleTimeoutWarningData } from '../models/idle-timeout-warning-data.model';
import { IIdleModalService } from './interfaces/idle-modals-service.interface';

@Injectable()
export class IdleModalsService implements IIdleModalService {

  private _timeoutWarningModalRef: MatDialogRef<AlertModalComponent>;
  private _timeoutModalRef: MatDialogRef<AlertModalComponent>;

  constructor(private injector: Injector,
              private modalsService: ModalsService, private ref: ApplicationRef) { }

  openTimeoutWarningModal(data: IdleTimeoutWarningData): MatDialogRef<AlertModalComponent> {
    const modalData = {
      title: 'idle.timeout-warning.title',
      component: {
        type: IdleTimeoutWarningComponent,
        injectorData: Injector.create({
          providers: [{ provide: IdleTimeoutWarningData, useValue: data }],
          parent: this.injector
        })
      },
      okButton: null,
      showCloseButton: false
    };
    this._timeoutWarningModalRef = this.modalsService.alert(modalData);
    return this._timeoutWarningModalRef;
  }

  openTimeoutModal(): MatDialogRef<AlertModalComponent> {
    const config = {
      width: ModalSize.SMALL,
      data: {
        title: 'idle.timeout.title',
        message: 'idle.timeout.message',
        okButton: null,
        showCloseButton: false
      }
    };
    this._timeoutModalRef = this.modalsService.open(AlertModalComponent, config);
    return this._timeoutModalRef;
  }

  closeTimeoutWarningModal(): void {
    if (this._timeoutWarningModalRef) {
      this._timeoutWarningModalRef.close();
      this._timeoutWarningModalRef = null;
      this.ref.tick();
    }
  }

  closeTimeoutModal(): void {
    if (this._timeoutModalRef) {
      this._timeoutModalRef.close();
      this._timeoutModalRef = null;
    }
  }

}
