import { Pipe, PipeTransform } from '@angular/core';
import { FtDate } from './ftDate';
import { FormatsService } from '../shared/services/formats.service';

@Pipe({ name: 'ftDate' })
export class FtDatePipe extends FtDate implements PipeTransform {

  constructor(formatsService: FormatsService) {
    super(formatsService);
  }

  transform(value: any, customFormat?: string): string {
    return this.format(value, customFormat);
  }
}
