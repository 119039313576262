import { Component, OnInit } from '@angular/core';
import { RulesAssociationCommonService } from '../../services/rules-association-common.service';
import { RulesAssociationApiService } from '../../services/rules-association-api.service';
import { Router } from '@angular/router';
import { RuleStatuses } from '../../interfaces/rule-statuses.interface';
import { FnUiDialogService } from '../../../../../shared/fn-ui-dialog/services/fn-ui-dialog.service';
import { PromptWithNoteModalComponent } from '../../../../../shared/fn-ui-modals/components/prompt-with-note-modal/prompt-with-note-modal.component';
import { ModalSize } from '../../../../../shared/fn-ui-modals/models/enums/modal-size.enum';
import { NotificationService } from '@fgpp-ui/components';
import { TranslateService } from '@ngx-translate/core';
import { ModalsService } from '../../../../../shared/fn-ui-modals/services/modals.service';

@Component({
  selector: 'app-rules-association-footer',
  templateUrl: './rules-association-footer.component.html',
  styleUrls: ['./rules-association-footer.component.scss']
})
export class RulesAssociationFooterComponent implements OnInit {
  showDeleteButton: boolean;
  showHoldButton: boolean;
  showAuditTrailButton: boolean;
  showMainCloseButton: boolean;
  showDeclineButton: boolean;
  showSaveButton: boolean;
  showApproveButton: boolean;
  showRevertButton: boolean;
  showActivateButton: boolean;

  private showRemoveButton: boolean;
  private showRemoveAllButton: boolean;
  private showAddButton: boolean;

  constructor(private router: Router,
              public ruleAssociationCommonService: RulesAssociationCommonService,
              public ruleAssociationService: RulesAssociationApiService,
              public dialogService: FnUiDialogService,
              public modals: ModalsService,
              private notificationService: NotificationService,
              private translate: TranslateService) {
  }

  ngOnInit(): void {
    this.initializeFooterButtons(this.ruleAssociationCommonService.ruleAssociationStatuses);
  }

  deleteRule() {
    this.ruleAssociationCommonService.closePreviewAndResetFilter();
    this.dialogService.open(PromptWithNoteModalComponent, {
      width: ModalSize.MEDIUM,
      data: {
        title: 'ruleAssociation.delete.confirmation.title',
        message: '',
        aboveNoteText: 'ruleAssociation.delete.confirmation.message',
        okButton: 'rule.delete.confirmation.ok_button',
        cancelButton: 'rule.delete.confirmation.cancel_button',
        isNoteMandatory: true,
        isNoteEditable: true,
        noteCharsLimit: 2000,
      }
    }).afterClosed().subscribe((note) => {
      if (note) {
        this.ruleAssociationCommonService.deleteRuleAssociation(this.ruleAssociationCommonService.rule, note);
      }
    });
  }

  holdRule() {
    this.ruleAssociationCommonService.closePreviewAndResetFilter();
    this.dialogService.open(PromptWithNoteModalComponent, {
      width: ModalSize.MEDIUM,
      data: {
        title: 'ruleAssociation.hold_rule.title',
        aboveNoteText: 'ruleAssociation.hold_rule.text_above_prompt',
        message: '',
        okButton: 'rule.hold_rule.ok_button',
        cancelButton: 'rule.hold_rule.cancel_button',
        isNoteMandatory: true,
        isNoteEditable: true,
        noteCharsLimit: 2000,
      }
    }).afterClosed().subscribe((note) => {
      if (note) {
        this.ruleAssociationCommonService.holdRuleAssociation(this.ruleAssociationCommonService.rule, note);
      }
    });
  }

  toggleAuditTrail() {
    this.ruleAssociationCommonService.show.previewRule = false;
    this.ruleAssociationCommonService.show.auditTrail = !this.ruleAssociationCommonService.show.auditTrail;
    this.ruleAssociationCommonService.showRulesList = false;
    this.ruleAssociationCommonService.saveDisable = true;
  }

  closeRuleAssociation() {
    if (this.ruleAssociationCommonService.ruleAssociationStatuses.isCreate) {
      this.ruleAssociationCommonService.isNeedCheckBeforeClose = true;
    }
    this.ruleAssociationCommonService.doAfterClose();
  }

  declineRule() {
    this.ruleAssociationCommonService.closePreviewAndResetFilter();
    this.dialogService.open(PromptWithNoteModalComponent, {
      width: ModalSize.MEDIUM,
      data: {
        title: 'ruleAssociation.decline_rule.title',
        aboveNoteText: 'ruleAssociation.decline_rule.text_above_prompt',
        message: '',
        okButton: 'rule.decline_rule.ok_button',
        cancelButton: 'rule.decline_rule.cancel_button',
        isNoteMandatory: true,
        isNoteEditable: true,
        noteCharsLimit: 2000,
      }
    }).afterClosed().subscribe((note) => {
      if (note) {
        this.ruleAssociationCommonService.declineRuleAssociation(this.ruleAssociationCommonService.rule, note);
        this.ruleAssociationCommonService.isNeedCheckBeforeClose = false;
      }
    });
  }

  saveRecord() {
    this.ruleAssociationCommonService.closePreviewAndResetFilter();
    if (this.ruleAssociationCommonService.rule) {
      if (!(this.ruleAssociationCommonService.rule.RULE_TYPE_NAME && this.ruleAssociationCommonService.rule.RULE_SUB_TYPE && this.ruleAssociationCommonService.rule.LAYER_CLASSIFICATION_ALIAS && this.ruleAssociationCommonService.rule.OBJECTID_NAME)) {
        this.notificationService.error(this.translate.instant('rule.save_rule.missing_data_message'));
        return;
      }
    }
    const currentRecord = this.ruleAssociationCommonService.sanitize(this.ruleAssociationCommonService.rule);
    if (!this.ruleAssociationCommonService.isChanged()) {
      this.notificationService.error(this.translate.instant('ruleAssociation.save_rule.no_change_message'));
      return;
    }

    this.modals.effectiveDate({
      EFFECTIVE_DATE: this.ruleAssociationCommonService.ruleAssociationStatuses.isCreate ? this.ruleAssociationCommonService.rule.EFFECTIVE_DATE : this.ruleAssociationCommonService.rule.businessDate,
      minDate: this.ruleAssociationCommonService.rule.businessDate,
      isNoteMandatory: !this.ruleAssociationCommonService.ruleAssociationStatuses.isCreate //when creating new rule the note is not mandatory
    }).afterClosed().subscribe((choice) => {
      if (!!choice && !!choice.payload) {
        const results = choice.payload;
        currentRecord.NOTE = results.NOTE;
        currentRecord.EFFECTIVE_DATE = results.EFFECTIVE_DATE.toString();
        this.ruleAssociationCommonService.rule.NOTE = results.NOTE;
        this.ruleAssociationCommonService.rule.EFFECTIVE_DATE = results.EFFECTIVE_DATE.toString();
        if (this.ruleAssociationCommonService.ruleAssociationStatuses.isCreate && !this.ruleAssociationCommonService.associationExists) {
          this.ruleAssociationCommonService.createRuleAssociation(currentRecord)
        } else {
          this.ruleAssociationCommonService.saveRuleAssociation(currentRecord);
        }
        this.ruleAssociationCommonService.isNeedCheckBeforeClose = false;
      }
    });
  }

  approveRule() {
    this.ruleAssociationCommonService.closePreviewAndResetFilter();
    this.ruleAssociationCommonService.approveRuleAssociation(this.ruleAssociationCommonService.rule);
  }

/*  togglePreviewRulePane() {
    this.ruleAssociationCommonService.setSelectedRule();
    const rule = this.ruleAssociationCommonService.selectedRule;
    this.ruleAssociationCommonService.togglePreviewRulePane(rule);
  }*/

  activateRule() {
    this.ruleAssociationCommonService.closePreviewAndResetFilter();
    this.dialogService.open(PromptWithNoteModalComponent, {
      width: ModalSize.MEDIUM,
      data: {
        title: 'ruleAssociation.activate.confirmation.title',
        aboveNoteText: 'ruleAssociation.activate.confirmation.message',
        message: '',
        okButton: 'rule.activate.confirmation.ok_button',
        cancelButton: 'rule.activate.confirmation.cancel_button',
        isNoteMandatory: true,
        isNoteEditable: true,
        noteCharsLimit: 2000
      }
    }).afterClosed().subscribe((note) => {
      if (note) {
        this.ruleAssociationCommonService.activateRuleAssociation(this.ruleAssociationCommonService.rule, note);
        this.ruleAssociationCommonService.isNeedCheckBeforeClose = false;
      }
    });
  }

  private initializeFooterButtons(ruleStatuses: RuleStatuses) {
    this.showSaveButton =
      (ruleStatuses.isWritable && !ruleStatuses.isReadOnly) &&
      (!ruleStatuses.isOpenedFromApprovalScreen && ruleStatuses.isEditable) &&
      ((ruleStatuses.isStatusNew &&
        (ruleStatuses.isChangeStatusNO ||
          ruleStatuses.isChangeStatusFU ||
          (ruleStatuses.isChangeStatusPN && (ruleStatuses.isInitiator || ruleStatuses.isCreate))
        )) ||
        (ruleStatuses.isStatusActive &&
          (ruleStatuses.isChangeStatusNO ||
            ruleStatuses.isChangeStatusFU ||
            (ruleStatuses.isChangeStatusPN && (ruleStatuses.isInitiator || ruleStatuses.isCreate))
          )));
    this.ruleAssociationCommonService.showSaveButton = this.showSaveButton;
    this.ruleAssociationCommonService.enableAddButton();
    this.ruleAssociationCommonService.controlListItemMovementOptions();
    if (ruleStatuses.isCreate) {
      this.ruleAssociationCommonService.moreDisable = true;
    }

    this.showHoldButton =
      (ruleStatuses.isWritable && ruleStatuses.isEditMode && !ruleStatuses.isReadOnly) &&
      (ruleStatuses.isOpenedFromApprovalScreen && ruleStatuses.isEditable || !ruleStatuses.isOpenedFromApprovalScreen) &&
      (ruleStatuses.isStatusActive && ruleStatuses.isChangeStatusNO);

    this.showDeleteButton =
      (ruleStatuses.isWritable && !ruleStatuses.isReadOnly) &&
      ((ruleStatuses.isOpenedFromApprovalScreen && ruleStatuses.isEditable) || !ruleStatuses.isOpenedFromApprovalScreen) &&
      ((ruleStatuses.isStatusActive && ruleStatuses.isChangeStatusNO) ||
        (ruleStatuses.isStatusHold && ruleStatuses.isChangeStatusNO));

    this.showAuditTrailButton =
      (ruleStatuses.isEditMode || ruleStatuses.isCreate) &&
      (false === (ruleStatuses.isStatusHold && ruleStatuses.isChangeStatusFU)) &&
      (false === (ruleStatuses.isStatusDeleted && ruleStatuses.isChangeStatusFU)) &&
      (false === (ruleStatuses.isStatusNew && ruleStatuses.isChangeStatusNO)) &&
      (false === (ruleStatuses.isStatusNew && ruleStatuses.isCreate));

    this.showActivateButton =
      (ruleStatuses.isWritable && ruleStatuses.isEditMode && !ruleStatuses.isReadOnly) &&
      (ruleStatuses.isOpenedFromApprovalScreen && ruleStatuses.isEditable || !ruleStatuses.isOpenedFromApprovalScreen) &&
      ((ruleStatuses.isStatusDeleted && ruleStatuses.isChangeStatusNO) ||
        (ruleStatuses.isStatusHold && ruleStatuses.isChangeStatusNO));


    this.showApproveButton =
      (ruleStatuses.isWritable && !ruleStatuses.isReadOnly) &&
      (false === ruleStatuses.isCreate) &&
      (ruleStatuses.isChangeStatusPN) &&
      (ruleStatuses.isInitiator === false || (ruleStatuses.isInitiator && ruleStatuses.canUserApproveOwnRules && (ruleStatuses.isOpenedFromApprovalScreen && ruleStatuses.isEditable)));

    this.showDeclineButton = (ruleStatuses.isWritable && !ruleStatuses.isReadOnly) && (this.showApproveButton) && !ruleStatuses.isInitiator;

    this.showRevertButton = (ruleStatuses.isWritable && !ruleStatuses.isReadOnly) && (this.showApproveButton) && ruleStatuses.isInitiator;

    this.showMainCloseButton =
      (false === (ruleStatuses.isStatusHold && ruleStatuses.isChangeStatusFU)) &&
      (false === (ruleStatuses.isStatusDeleted && ruleStatuses.isChangeStatusFU));

    this.showAddButton =
      (ruleStatuses.isWritable && !ruleStatuses.isReadOnly && (!ruleStatuses.isChangeStatusPN || ruleStatuses.isRuleForApproval && ruleStatuses.isInitiator)) && (false === ruleStatuses.isCreate) ||
      (true === ruleStatuses.isCreate && this.ruleAssociationCommonService.isListReadOnly !== true);

    this.showRemoveButton =
      (ruleStatuses.isWritable && !ruleStatuses.isReadOnly) ||
      (true === ruleStatuses.isCreate);

    this.showRemoveAllButton =
      (ruleStatuses.isWritable && !ruleStatuses.isReadOnly) ||
      (true === ruleStatuses.isCreate);
  }
}
