import { Injectable, Injector } from '@angular/core';
import { IKeyboardShortcutHandlerFactoryService } from './interfaces/keyboard-shortcut-handler-factory-service.interface';
import { IKeyboardShortcutHandlerService } from './interfaces/keyboard-shortcut-handler-service.interface';
import { KeyboardShortcutHandlerMap } from './models/keyboard-shortcut-handler-map.model';
import { KeyboardShortcutType } from '../../models/enums/keyboard-shortcut-type.enum';

@Injectable()
export class KeyboardShortcutHandlerFactoryService implements IKeyboardShortcutHandlerFactoryService {

  private handlerMap: KeyboardShortcutHandlerMap = {};

  constructor(private injector: Injector) { }

  getHandler(type: KeyboardShortcutType): IKeyboardShortcutHandlerService {
    if (!type) {
      return null;
    }
    if (!this.handlerMap[type]) {
      this.handlerMap[type] = this.injector.get(type);
    }
    return this.handlerMap[type];
  }

}
