import { Component, Input, EventEmitter, Output, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { MenuItem } from './models/menu-item';
import { EventHandlerService } from './services/event-handler.service';
import { Subscription, filter } from 'rxjs';


@Component({
  selector: 'lib-dynamic-menu',
  templateUrl: 'fn-dynamic-menu.component.html',
  styleUrls: ['fn-dynamic-menu.component.scss']
})
export class FnDynamicMenuComponent implements OnInit, OnDestroy {

  @Input() menuItems: MenuItem[];
  @Output() onClickMenuEvent: EventEmitter<any> = new EventEmitter();
  protected subscription = new Subscription();

  constructor(private eventHandlerService: EventHandlerService) {
  }

  ngOnInit(): void {
    const buttonEvent = this.eventHandlerService.subscribeButtonEvent().pipe(filter(event => !!event));
    const sub = buttonEvent.subscribe(events => {
      this.emitEvent(events);
    });
    this.subscription.add(sub);
  }

  emitEvent($event) {
    this.onClickMenuEvent.emit($event);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
