import { Injectable } from '@angular/core';
import { FocusMonitor } from '@angular/cdk/a11y';
import { IKeyboardShortcutHandlerService } from './interfaces/keyboard-shortcut-handler-service.interface';
import { KeyboardShortcutElementSelectorService } from '../keyboard-shortcut-element-selector.service';
import { KeyboardShortcut } from '../../models/keyboard-shortcut.model';

@Injectable()
export class NativeFocusKeyboardShortcutHandlerService implements IKeyboardShortcutHandlerService {

  constructor(private focusMonitor: FocusMonitor,
              private keyboardShortcutElementSelectorService: KeyboardShortcutElementSelectorService) { }

  handleEvent(shortcut: KeyboardShortcut): boolean {
    const element = this.keyboardShortcutElementSelectorService.selectElement(shortcut.selector);
    if (element) {
      setTimeout(() => {
        this.focusMonitor.focusVia(element, 'keyboard');
      }, 0);
      return true;
    }
    return false;
  }

}
