import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { CheckBalanceRequestBody, CheckBalanceResponse, FxErrorResponse, FxRateModal, GetRateRequestBody} from '../models/fx-rate.model';
import {Subscription, timer} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {NotificationService} from '@fgpp-ui/components';
import {TranslateService} from '@ngx-translate/core';
import {FxRateApiService} from '../services/fx-rate-api.service';
import {BalanceResponse} from '../models/enums/balance-response.enum';
import {FxRateHelperService} from '../services/fx-rate-helper.service';
import {SpinnerComponent} from '../../../../ui-components/spinner/spinner.component';
import {NavigationService} from '../../../../core/navigation/services/navigation.service';
import {StatusType} from '../models/enums/status-type.enum';


@Component({
  selector: 'app-fx-rate-modal',
  templateUrl: './fx-rate-modal.component.html',
  styleUrls: ['./fx-rate-modal.component.scss']
})
export class FxRateModalComponent implements OnInit, OnDestroy {

  private subscription = new Subscription();
  fxRate: FxRateModal;
  counter = 0;
  enableAccept = false;
  spinnerComponent = SpinnerComponent;
  showForce = false;
  showLoader = true;
  statusType = StatusType.INFO;
  StatusType = StatusType;
  status;
  rateRequestBody: GetRateRequestBody;
  enableReject = true;
  transactionErrorDetails: FxErrorResponse

  constructor(public dialogRef: MatDialogRef<FxRateModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { fxRate: FxRateModal, rateRequestBody: GetRateRequestBody, errorDetails?: FxErrorResponse },
              private notificationService: NotificationService,
              private translateService: TranslateService,
              private fxRateHelperService: FxRateHelperService,
              private fxRateApiService: FxRateApiService,
              private navigationService: NavigationService) {
    this.fxRate = data.fxRate;
    this.rateRequestBody = data.rateRequestBody;
    this.transactionErrorDetails = data.errorDetails;
  }

  ngOnInit(): void {
    this.status = this.translateService.instant('message.fx_rate_modal.checking_funds_message');
    this.initSubscriptions();
  }

  initSubscriptions(): void {
    const timerSubscription = this.getCounterSubscription();
    this.subscription.add(timerSubscription);
    if(this.transactionErrorDetails) {
      this.handleLimitModal();
    } else {
      const checkBalanceSub = this.getCheckBalanceSubscription();
      this.subscription.add(checkBalanceSub);
    }
  }

  getCounterSubscription() {
    return timer(0, 1000).pipe(take(this.fxRate.fxRateDetails.expTimeInMillis + 1), map(i => this.fxRate.fxRateDetails.expTimeInMillis - i))
      .subscribe({
        next: (counter: number) => {
          this.counter = counter;
        },
        error: (err) => {
          console.log('Error: ', err);
        },
        complete: () => {
          this.subscription.unsubscribe();
          this.handleCheckFundsNegativeNSFResponse('message.panel.fx_rate_table.expiration_error', true);
          this._closeDialog();
        }
    });
  }

  getCheckBalanceSubscription() {
    const body: CheckBalanceRequestBody = {
      debitAmt: this.fxRateHelperService.getDebitAmt(this.data.fxRate),
      office: this.data.fxRate.office,
      mid: this.data.fxRate.mid
    };
    return this.fxRateApiService.checkBalanceForFxRate(body).subscribe((res: CheckBalanceResponse) => {
      switch (res.response) {
        case BalanceResponse.SUCCESSFUL:
          this.handleCheckFundsPositiveResponse();
          break;
        case BalanceResponse.NO_FUNDS:
          this.handleCheckFundsNegativeNSFResponse('message.fx_rate_modal.no_funds_message');
          break;
        case BalanceResponse.NOT_AVAILABLE:
          this.handleCheckFundsNegativeNSFResponse('message.fx_rate_modal.check_not_available_message');
          break;
        case BalanceResponse.BLOCK_ACCOUNT:
          this.handleCheckFundsNegativeNSFResponse('message.fx_rate_modal.block_account_message', true);
          break;
        case BalanceResponse.FORCED_BALANCE:
          this.handleForcedBalance();
          break;
      }
    }, () => {
      this.handleCheckFundsNegativeNSFResponse('message.fx_rate_modal.check_not_available_message');
    });
  }

  reject() {
    this._closeDialog();
  }

  approve() {
    this.handlePendingApprove();
    this.fxRateApiService.approveRate(this.rateRequestBody).subscribe((response) => {
      this.dialogRef.close(response.fxRateDetails);
      const message = this.translateService.instant('message.panel.fx_rate_table.approve_rate');
      this.notificationService.success(message);
    }, () => {
      if (this.counter) {
        this.handleApproveNegativeResponse();
      }
    });
  }

  force() {
    this.handleForcedBalance();
    this.fxRateApiService.force(this.data.fxRate.mid).subscribe(
      () => { },
      (err) => console.log('Force error: ', err)
    );
  }

  getFxRateTitle() {
    return this.fxRateHelperService.getTranslateFxRateTitle(this.fxRate.fxRateDetails);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private _closeDialog(): void {
    this.dialogRef.close();
  }

  handleCheckFundsNegativeNSFResponse(status: string, noShowForce?: boolean): void {
    this.showLoader = false;
    this.statusType = StatusType.ERROR;
    this.enableAccept = false;
    this.showForce = noShowForce ? false : this.navigationService.sitemap.modules.allowedPermissions.forceNsf === 'true';
    this.status = this.translateService.instant(status);
  }

  handleCheckFundsPositiveResponse(): void {
    this.showLoader = false;
    this.statusType = StatusType.SUCCESSFUL;
    this.enableAccept = true;
    this.status = this.translateService.instant('message.fx_rate_modal.sufficient_funds_message');
  }

  handleForcedBalance() {
    this.showLoader = false;
    this.statusType = StatusType.INFO;
    this.showForce = false;
    this.enableAccept = true;
    this.status = this.translateService.instant('message.fx_rate_modal.force_message');
  }

  handleLimitModal() {
    this.showLoader = false;
    this.statusType = StatusType.ERROR;
    this.showForce = false;
    this.enableAccept = false;
    this.status = this.transactionErrorDetails.errDescription
  }

  handlePendingApprove(): void {
    this.dialogRef.disableClose = true;
    this.enableReject = false;
    this.showLoader = true;
    this.statusType = StatusType.INFO;
    this.enableAccept = false;
    this.status = this.translateService.instant('message.panel.fx_rate_table.checking_approve_rate');
  }

  handleApproveNegativeResponse(): void {
    this.dialogRef.disableClose = false;
    this.enableReject = true;
    this.showLoader = false;
    this.statusType = StatusType.ERROR;
    this.status = this.translateService.instant('message.panel.fx_rate_table.approve_rate_failed');
  }
}
