import { Component, Directive, Inject } from '@angular/core';
import { ExternalFilterGridData } from '../../models/external-filter-grid-data.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Directive()
export abstract class ExternalGridDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: ExternalFilterGridData,
              public dialogRef: MatDialogRef<Component>) { }

}
