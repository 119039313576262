import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from '@fgpp-ui/components';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import { DownloadResponse } from '../../../../shared/models/download-response.model';
import { FileDownloaderService } from '../../../../shared/services/file-downloader.service';

@Component({
  selector: 'app-delete-base-condition-modal',
  templateUrl: './delete-base-condition-modal.component.html',
  styleUrls: ['./delete-base-condition-modal.component.scss']
})
export class DeleteBaseConditionModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) private _params,
              private fileDownloader: FileDownloaderService,
              private notificationService: NotificationService,
              private translateService: TranslateService) { }

  ngOnInit(): void {
  }

  get params() {
    return this._params;
  }

   copySuccess() {
     this.notificationService.success(this.translateService.instant('rules.clipboard_message.successfully'));
  }

   downloadRules() {
     const downloadResponse: DownloadResponse = {
       blob: new Blob([this.params.rules]),
       type: '',
       fileName: 'Rules ' + dayjs().format('DD_MMM_Y-HH_MM') + '.txt'
     };
    this.fileDownloader.downloadFile(downloadResponse);
  }
}
