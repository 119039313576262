import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-stay-leave-later-modal',
  templateUrl: './stay-leave-later-modal.component.html',
  styleUrls: ['./stay-leave-later-modal.component.scss']
})
export class StayLeaveLaterModalComponent {

  constructor(@Inject(MAT_DIALOG_DATA) private _params) { }


  get params() {
    return this._params;
  }
}
