export const Drawdown = {
  'CREDITOR_ADDRESS1': 'DRAWDOWN-CREDITOR_ADDRESS1',
  'CREDITOR_ADDRESS2': 'DRAWDOWN-CREDITOR_ADDRESS2',
  'CREDITOR_ADDRESS3': 'DRAWDOWN-CREDITOR_ADDRESS3',
  'CREDITOR_ID': 'DRAWDOWN-CREDITOR_ID',
  'CREDITOR_NAME': 'DRAWDOWN-CREDITOR_NAME',
  'DEBTOR_ACC_NO': 'DRAWDOWN-DEBTOR_ACC_NO',
  'DEBTOR_CURRENCY': 'DRAWDOWN-DEBTOR_CURRENCY',
  'DEPARTMENT': 'DRAWDOWN-DEPARTMENT',
  'DRAWDOWN_NAME': 'DRAWDOWN-DRAWDOWN_NAME',
  'ACCOUNTS_NAME': 'ACCOUNTS-NAME',
  'ACCOUNTS_CUSTNAME': 'ACCOUNTS-CUSTNAME',
  'ACCOUNTS_CUSTOMERCODE': 'ACCOUNTS-CUSTOMERCODE',
  'EFFECTIVE_DATE': 'EFFECTIVE_DATE',
  'END_DATE': 'DRAWDOWN-END_DATE',
  'LAST_MID': 'DRAWDOWN-LAST_MID',
  'LAST_USE_DATE': 'DRAWDOWN-LAST_USE_DATE',
  'MAX_AMOUNT': 'DRAWDOWN-MAX_AMOUNT',
  'MAX_AMOUNT_CCY': 'DRAWDOWN-MAX_AMOUNT_CCY',
  'OFFICE': 'DRAWDOWN-OFFICE',
  'PENDING_ACTION': 'DRAWDOWN-PENDING_ACTION',
  'PROFILE_CHANGE_STATUS': 'DRAWDOWN-PROFILE_CHANGE_STATUS',
  'RECEIVER_ABA': 'DRAWDOWN-RECEIVER_ABA',
  'REC_STATUS': 'DRAWDOWN-REC_STATUS',
  'SENDER_ABA': 'DRAWDOWN-SENDER_ABA',
  'SHARE_OTHER_OFFICES': 'DRAWDOWN-SHARE_OTHER_OFFICES',
  'START_DATE': 'DRAWDOWN-START_DATE',
  'TIME_STAMP': 'TIME_STAMP',
  'DRAWDOWN_TIME_STAMP': 'DRAWDOWN-TIME_STAMP',
  'UID': 'VIRTUAL-UID',
  'PROFILE_UPDATE_PK': 'PROFILE_UPDATE_PK',
  'PU_TIME_STAMP': 'PU_TIME_STAMP',
  'NOTE': 'NOTE',
  'ACC_SOURCE': 'DRAWDOWN-ACC_SOURCE',
  'DEFAULT_CUST_CODE': 'DRAWDOWN-DEFAULT_CUST_CODE',
};
