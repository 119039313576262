import { Pipe, PipeTransform } from '@angular/core';
import { FtDate } from './ftDate';
import { FormatsService } from '../shared/services/formats.service';

@Pipe({ name: 'ftDateTime' })
export class FtDateTimePipe extends FtDate implements PipeTransform {

  constructor(formatsService: FormatsService) {
    super(formatsService);
    this.formatSuffix = formatsService.timeFormat;
  }

  transform(value: any, isUtc?: boolean): string {
    return this.format(value, null, isUtc);
  }
}
