import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiComponentsModule } from '../../../ui-components/ui-components.module';
import { MessagesCenterCallbackQueuesComponent } from './messages-center-callback-queues/messages-center-callback-queues.component';
import { CallbackCallerAuthenticationModalComponent } from './callback-caller-authentication-modal/callback-caller-authentication-modal.component';
import { CallerPinVerificationModalComponent } from './caller-pin-verification-modal/caller-pin-verification-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CallerCallbackService } from './services/caller-callback.service';
import { CallerCallbackApiService } from './services/caller-callback-api.service';
import { FnUiDialogModule } from '../../../shared/fn-ui-dialog/fn-ui-dialog.module';
import { FnUiNotificationModule } from '../../../shared/fn-ui-notification/fn-ui-notification.module';
import { SpinnerModule } from '../../../ui-components/spinner/spinner.module';
import { CoreModule } from '../../../core/core.module';
import { MessagesCenterNavigationModule } from '../../messages-center-navigation/messages-center-navigation.module';

@NgModule({
  providers: [
    CallerCallbackService,
    CallerCallbackApiService
  ],
  imports: [
    CommonModule,
    UiComponentsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    FnUiDialogModule,
    FnUiNotificationModule,
    SpinnerModule,
    CoreModule,
    MessagesCenterNavigationModule
  ],
  declarations: [
    CallbackCallerAuthenticationModalComponent,
    CallerPinVerificationModalComponent
  ],
  exports: []
})
export class MessagesCenterCallbackModule {
}
