<app-fn-ui-dialog class="create-base-condition-modal">

  <ng-template #header>
    <div class="create-base-header">
    <span class="title" [translate]="'rules.create_base_condition.title'"></span>
      <app-fn-ui-close-icon mat-dialog-close></app-fn-ui-close-icon>
    </div>
    <mat-divider></mat-divider>
  </ng-template>

  <ng-template #body>
    <div class="create-base-body">
      <mat-form-field  [floatLabel]="'always'">
          <mat-label [translate]="'rules.create_base_condition.body.ruleName'"></mat-label>
          <input matInput class="input" automation-id="base-condition-name" [(ngModel)]="params.ruleName" type="text" maxlength="50" required>
        </mat-form-field>

        <mat-form-field [floatLabel]="'always'">
          <mat-label [translate]="'rules.create_base_condition.body.description'"></mat-label>
          <textarea matInput class="input" automation-id="base-condition-description" rows="5" maxlength="{{params.maxFieldsLength}}" [(ngModel)]="params.description" required></textarea>
          <mat-hint [translate]="'main_window.characters_limit'" [translateParams]="{chars: params.maxFieldsLength - params.description.length}"></mat-hint>
        </mat-form-field>
    </div>
  </ng-template>

  <ng-template #footer>
    <div class="create-base-footer">
      <app-fn-ui-button mat-dialog-close name="base-condition-discard">
        <span [translate]="'rules.create_base_condition.button.discard'"></span>
      </app-fn-ui-button>

      <app-fn-ui-button type="flat" color="primary" name="base-condition-save" [disabled]="params.description.length === 0 || params.ruleName.length === 0"
                        (click)="onSubmit()">
        <span [translate]="'rules.create_base_condition.button.save'"></span>
      </app-fn-ui-button>
    </div>
  </ng-template>

</app-fn-ui-dialog>
