import { Component } from '@angular/core';
import { CustomAccountGroupsService } from '../../../services/custom-account-groups.service';

@Component({
  selector: 'app-custom-account-groups-navigation',
  templateUrl: './custom-account-groups-navigation.component.html',
  styleUrls: ['./custom-account-groups-navigation.component.scss']
})
export class CustomAccountGroupsNavigationComponent {

  constructor(public customAccountGroupsService: CustomAccountGroupsService) { }

}
