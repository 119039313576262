import { Component, ContentChild, TemplateRef, ElementRef, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-fn-ui-dialog',
  templateUrl: './fn-ui-dialog.component.html',
  styleUrls: ['./fn-ui-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FnUiDialogComponent {

  @ContentChild('header', { static: true }) header: TemplateRef<ElementRef>;
  @ContentChild('body', { static: true }) body: TemplateRef<ElementRef>;
  @ContentChild('footer', { static: true }) footer: TemplateRef<ElementRef>;

}
