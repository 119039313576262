<!-- rulesTreeCheckBoxed.html -->
<div class="rule-search-filter">
  <app-fn-ui-input [(ngModel)]="searchFilter"
                   [placeholder]="'rule_filter' | translate"
                   prefixIcon="search"
                   floatLabel="never"
                   (ngModelChange)="filterRuleTypes(searchFilter)">
  </app-fn-ui-input>

  <app-tour-item [tourId]="'ruleLandingPageDialer'" [tourStepNumber]="1"></app-tour-item>
</div>
<div>
  <button mat-button color="primary" automation-id="rules-unselect-all" azure-id="rules-unselect-all" class="btn dh-info-link" [disabled]="ruleTypeService.disableTreeItems" (click)="unselectAll()">
    <span class="dh-text-label">Clear All</span>
  </button>
</div>
<div id="rules-tree-rule-type-list" class="dh rule-type-list">
  <div *ngFor="let row of resourceForSearch; let i = index" class="rule-type-row  ft-tree-child text-nowrap">
    <mat-checkbox color="primary" (change)="toggleSelection(row, $event.checked)" [(ngModel)]="row.selected" [disabled]="ruleTypeService.disableTreeItems">
      <span [ngClass]="{'rule-type-checkbox-selected': row.selected}">{{ 'rule_type.' + row['PRULE_TYPES-RULE_TYPE_ID'] | translate }} </span>
    </mat-checkbox>
    <div class="ft-selected-checkbox-bottom" *ngIf="splitListAtIndex === i"></div>
  </div>
</div>
