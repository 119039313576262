<app-fn-ui-dialog class="hold-modal">

  <ng-template #header>
    <div class="hold-modal-header">
      <span class="title" [translate]="params.modalTitle"></span>
      <app-fn-ui-close-icon (click)="close()"></app-fn-ui-close-icon>
    </div>
    <mat-divider></mat-divider>
  </ng-template>

  <ng-template #body>
    <div class="hold-modal-body">
      <mat-form-field [floatLabel]="'always'">
        <mat-label [translate]="params.bodyTitle"></mat-label>
        <mat-select [(ngModel)]="params.message">
          <mat-option *ngFor="let option of mopObs | async" [value]="option">
            <div>{{option}} </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

  </ng-template>

  <ng-template #footer>
    <div class="hold-modal-footer">
      <app-fn-ui-button (click)="close()">
        <span [translate]="'general.cancel'"></span>
        <span></span>
      </app-fn-ui-button>

      <app-fn-ui-button type="flat" color="primary" (click)="close(params.message)" >
        <span [translate]="'general.apply'"></span>
      </app-fn-ui-button>
    </div>
  </ng-template>

</app-fn-ui-dialog>
