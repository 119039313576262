import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  OnDestroy,
  OnInit,
  Output,
  SkipSelf,
  ViewChild
} from '@angular/core';
import { Subscription } from 'rxjs';
import { SearchInputComponent } from '../search-input/search-input.component';
import { SearchBoxItem, NavigationBoxMode, SearchType } from '../../models';
import { MainTab } from '../../../navigation/models';
import { NavigationService } from '../../../navigation/services/navigation.service';
import { NavigationSearchConfigService } from '../../services/navigation-search-config.service';
import { SearchBoxService } from '../../services/search-box.service';
import { Search } from '../../models/search.model';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss']
})
export class SearchBoxComponent implements OnInit, OnDestroy, AfterViewInit {

  @Output() toggled = new EventEmitter<boolean>();

  @ViewChild(SearchInputComponent) searchInputComponent: SearchInputComponent;

  @HostBinding('class')
  get hostClass(): string {
    return this.isExpanded ? 'expanded' : 'collapsed';
  }

  items: Array<SearchBoxItem>;
  selectedItem: SearchBoxItem;
  isExpanded: boolean;

  private _subscriber = new Subscription();

  constructor(private navigationService: NavigationService,
              private configService: NavigationSearchConfigService,
              private searchBoxService: SearchBoxService,
              @SkipSelf() private changeDetectorRef: ChangeDetectorRef,
              private elem: ElementRef) { }

  ngOnInit(): void {
    this.items = this.getSearchBoxItems();
    this.selectedItem = this.items[0] || {} as SearchBoxItem;
    this.subscribeToMode();
    this.subscribeToSearchBoxItem();
  }

  ngOnDestroy(): void {
    this._subscriber.unsubscribe();
  }

  onExpandClick(): void {
    this.expandSearchBox();
    this.focusSearchInput();
    this.searchInputComponent.reset();
  }

  onCollapseClick(): void {
    this.reset();
    this.closeNavigationResultBox();
  }

  onMenuItemClick($event: SearchBoxItem): void {
    if ($event.type === SearchType.ADVANCED_SEARCH) {
      const search = {
        type: $event.type,
        entity: $event.entity,
        data: null
      };
      this.applySearch(search);
    } else {
      this.selectedItem = $event;
      this.focusSearchInput();
    }
  }

  onSearchTrigger($event: string): void {
    const search = {
      type: this.selectedItem.type,
      entity: this.selectedItem.entity,
      data: $event
    };
    this.applySearch(search);
  }

  private getSearchBoxItems(): Array<SearchBoxItem> {
    const userTabs = Array.from(this.navigationService.navigationMap?.keys() || []);
    return this.configService.searchBoxItems.filter((item: SearchBoxItem) => {
      return item.tabs.some((tab: MainTab) => userTabs.indexOf(tab) !== -1);
    });
  }

  private subscribeToMode(): void {
    const subscription = this.searchBoxService.navigationBoxMode$.subscribe((mode: NavigationBoxMode) => {
      if (mode === NavigationBoxMode.CLOSED) {
        this.reset();
      }
    });
    this._subscriber.add(subscription);
  }

  private subscribeToSearchBoxItem(): void {
    const subscription = this.searchBoxService.searchBoxItem$.subscribe((item: SearchBoxItem) => {
      if (!item) {
        return;
      }
      if (!this.isExpanded) {
        this.expandSearchBox();
      }
      this.onMenuItemClick(item);
      this.changeDetectorRef.detectChanges();
    });
    this._subscriber.add(subscription);
  }

  private expandSearchBox(): void {
    this.isExpanded = true;
    this.toggled.emit(true);
  }

  private collapseSearchBox(): void {
    this.isExpanded = false;
    this.toggled.emit(false);
  }

  private focusSearchInput(): void {
    this.searchInputComponent.focus();
  }

  private closeNavigationResultBox(): void {
    this.searchBoxService.closeNavigationBox();
  }

  private applySearch(search: Search): void {
    const promise = this.searchBoxService.applySearch(search);
    if (promise) {
      promise.then(this.reset.bind(this), () => { });
    }
  }

  private reset(): void {
    this.collapseSearchBox();
    this.searchInputComponent?.reset();
    this.changeDetectorRef.detectChanges();
  }

  ngAfterViewInit(): void {
    this.elem.nativeElement.setAttribute('azure-id', 'search-box');
  }
}
