import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileAttachmentDialogComponent } from './file-attachment-dialog.component';
import { FileAttachmentDialogService } from './services/file-attachment-dialog.service';
import { AttachmentModule } from '../../../../../../attachment/src/public-api';
import { FileAttachmentConfigService } from './services/file-attachment-config.service';
import { FileAttachmentApiService } from './services/file-attachment-api.service';
import { FileAttachmentDialogMapperService } from './services/file-attachment-dialog-mapper.service';
import { TextBuilderFileAttachmentDialogService } from './services/texts-builder-file-attachment-dialog.service';
import { FnUiDialogModule } from '../../fn-ui-dialog/fn-ui-dialog.module';
import { MatButtonModule } from '@angular/material/button';
import { FnUiIconModule } from '../../fn-ui-icons/fn-ui-icon/fn-ui-icon.module';
import { SpinnerModule } from '../../../ui-components/spinner/spinner.module';


@NgModule({
  declarations: [
    FileAttachmentDialogComponent
  ],
  imports: [
    CommonModule,
    AttachmentModule,
    FnUiDialogModule,
    MatButtonModule,
    FnUiIconModule,
    SpinnerModule,
  ],
  providers: [
    FileAttachmentApiService,
    FileAttachmentConfigService,
    FileAttachmentDialogService,
    FileAttachmentDialogMapperService,
    TextBuilderFileAttachmentDialogService
  ],
  exports: [
    FileAttachmentDialogComponent
  ]
})
export class FileAttachmentDialogModule {

}
