export enum CreditorIdStructure {
	DEPARTMENT = 'DD_CREDITOR_ID_STRUCTURE-DEPARTMENT',
	OFFICE = 'DD_CREDITOR_ID_STRUCTURE-OFFICE',
	REC_STATUS = 'DD_CREDITOR_ID_STRUCTURE-REC_STATUS',
	PROFILE_CHANGE_STATUS = 'DD_CREDITOR_ID_STRUCTURE-PROFILE_CHANGE_STATUS',
	PENDING_ACTION = 'DD_CREDITOR_ID_STRUCTURE-PENDING_ACTION',
	EFFECTIVE_DATE = 'EFFECTIVE_DATE',
	SCHEME = 'DD_CREDITOR_ID_STRUCTURE-SCHEME',
	CHECK_DIGIT_ALG = 'DD_CREDITOR_ID_STRUCTURE-CHECK_DIGIT_ALG',
	CID_MAX_LENGTH = 'DD_CREDITOR_ID_STRUCTURE-CID_MAX_LENGTH',
	DISCR_POSITION = 'DD_CREDITOR_ID_STRUCTURE-DISCR_POSITION',
	DISCR_LENGTH = 'DD_CREDITOR_ID_STRUCTURE-DISCR_LENGTH'
};
