import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FnUiExportFormComponent } from '../fn-ui-export-form/fn-ui-export-form.component';
import { DialogData } from '../../models/dialog-data.model';
import { ExportRequest } from '../../models/export-request.model';
import { ExportFormService } from '../../services/export-form.service';

@Component({
  selector: 'app-fn-ui-export-modal',
  templateUrl: './fn-ui-export-modal.component.html',
})
export class FnUiExportModalComponent implements OnInit {

  //need the DOM to be dynamically questioned
  @ViewChild('appExportForm') appExportForm: FnUiExportFormComponent;

  isValid: boolean;

  get exportRequest(): ExportRequest {
    return this.exportFormService.getExportRequest(this.appExportForm.exportForm);
  }

  constructor(
    public dialogRef: MatDialogRef<FnUiExportModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private exportFormService: ExportFormService) { }

  ngOnInit(): void {
    this.dialogRef.afterOpened().subscribe(() => {
      this.isValid = this.appExportForm ? this.appExportForm.exportForm.valid : null;
    });
  }


  submit() {
    if (this.isValid) {
      this.dialogRef.close(this.exportRequest);
    }
  }

}
