export const ProfilePopUpSize = {
  default: {
    x: 100,
    y: 0,
    width: 890,
    height: 600
  },
  profileData: {
    'message': { width: 1300, height: 900 }, //messages
    '458': { width: 880, height: 480 }, // ,frmAccountAdditionalOwners.jsp
    '186': { width: 1138, height: 768 }, // ,frmAccountPosition.jsp
    '55': { width: 877, height: 420 }, // ,frmAccountType.jsp
    '444': { width: 893, height: 768 }, // ,frmAdvising.jsp
    '121': { width: 877, height: 448 }, // ,frmAdvisingPartyProfile.jsp
    '84': { width: 877, height: 410 }, // ,frmAKA.jsp
    '13': { width: 932, height: 711 }, // ,frmAlertLevelProfile.jsp
    '193': { width: 743, height: 437 }, // ,frmApplyChanges.jsp
    '34': { width: 877, height: 532 }, // ,frmBankRout.jsp
    '37': { width: 877, height: 490 }, // ,frmBilateralKey.jsp
    '79': { width: 992, height: 606 }, // ,frmBulking.jsp
    '682': { width: 992, height: 450 }, // ,frmFileAgreement.jsp
    '685': { width: 992, height: 450 }, // ,frmBatchAgreement.jsp
    '74': { width: 877, height: 450 }, // ,frmBulkingSendingTimes.jsp
    '659': { width: 877, height: 650 }, // ,frmMasking.jsp
    '78': { width: 877, height: 478 }, // ,frmBusinessArea.jsp
    '6': { width: 938, height: 711 }, // ,frmCategorizedLevelProfiles.jsp
    '450': { width: 985, height: 768 }, // ,frmCharacterSetProfile.jsp
    '463': { width: 877, height: 350 }, // ,frmClientIDs.jsp
    '449': { width: 877, height: 492 }, // ,frmCodesList.jsp
    '459': { width: 894, height: 768 }, // ,frmCompany.jsp
    '86': { width: 896, height: 768 }, // ,frmContactsAddr.jsp
    '58': { width: 893, height: 768 }, // ,frmCountry.jsp
    '50': { width: 877, height: 671 }, // ,frmCountryCFG.jsp
    '26': { width: 877, height: 645 }, // ,frmCountryCutoff.jsp
    '25': { width: 877, height: 677 }, // ,frmCurrency.jsp
    '51': { width: 877, height: 569 }, // ,frmCurrencyCFG.jsp
    '191': { width: 1189, height: 768 }, // ,frmCustomers.jsp
    '447': { width: 877, height: 497 }, // ,frmCustValueList.jsp
    '428': { width: 1153, height: 768 }, // ,frmDataManipulation.jsp
    '430': { width: 877, height: 487 }, // ,frmDebitAuthorization.jsp
    '671': { width: 900, height: 487 }, // ,frmExposureValidation.jsp
    '687': { width: 900, height: 487 }, // ,frmRepresentment.jsp
    '675': { width: 900, height: 487 }, // ,frmTransformationPreferences.jsp
    '46': { width: 877, height: 493 }, // ,frmDepartment.jsp
    '7': { width: 932, height: 711 }, // ,frmDepartmentLevelProfile.jsp
    '145': { width: 877, height: 768 }, // ,frmDirectDebits.jsp
    '28': { width: 1168, height: 768 }, // ,frmEditAccounts.jsp
    '190': { width: 877, height: 630 }, // ,frmEditGLMAccounts.jsp
    '3': { width: 895, height: 768 }, // ,frmEditUsers.jsp
    '461': { width: 878, height: 499 }, // ,frmEventDefinitions.jsp
    '30': { width: 897, height: 659 }, // ,frmExchangeRate.jsp
    '414': { width: 877, height: 587 }, // ,frmExchangeRateInfo.jsp
    '415': { width: 894, height: 768 }, // ,frmFeeFormula.jsp
    '228': { width: 896, height: 768 }, // ,frmFeeTypes.jsp
    '690': { width: 896, height: 768 }, // ,frmFeeTypeGroup.jsp
    '76': { width: 940, height: 711 }, // ,frmFieldVerificationProfile.jsp
    '440': { width: 877, height: 696 }, // ,frmGenerateTransProfile.jsp
    '129': { width: 877, height: 370 }, // ,frmGlobalSuppressAdvice.jsp
    '188': { width: 877, height: 562 }, // ,frmIdentifiers.jsp
    '432': { width: 877, height: 480 }, // ,frmIncomingFileSummary.jsp
    '456': { width: 907, height: 768 }, // ,frmInterfaceTypes.jsp
    '457': { width: 877, height: 641 }, // ,frmJournalMessages.jsp
    '27': { width: 1019, height: 768 }, // ,frmLocalBank.jsp
    '401': { width: 877, height: 320 }, // ,frmLVStopBandsProfile.jsp
    '80': { width: 1081, height: 768 }, // ,frmMandate.jsp
    '152': { width: 877, height: 370 }, // ,frmMappingProfile.jsp
    '1': { width: 877, height: 621 }, // ,frmMatchingCheck.jsp
    '33': { width: 937, height: 639 }, // ,frmMember.jsp
    '468': { width: 904, height: 565 }, // ,frmMessageFieldsSet.jsp
    '8': { width: 932, height: 711 }, // ,frmMessageLevelProfile.jsp
    '56': { width: 877, height: 768 }, // ,frmMessagePartnerProfile.jsp
    '452': { width: 895, height: 768 }, // ,frmMessageStpFieldRule.jsp
    '451': { width: 1233, height: 712 }, // ,frmMessageStpRules.jsp
    '32': { width: 1019, height: 768 }, // ,frmMOP.jsp
    '400': { width: 877, height: 500 }, // ,frmMopFpsExtension.jsp
    '162': { width: 877, height: 500 }, // ,frmNameAddressPopulation.jsp
    '146': { width: 877, height: 507 }, // ,frmNATS.jsp
    '29': { width: 895, height: 768 }, // ,frmNcc.jsp
    '49': { width: 877, height: 711 }, // ,frmNewCalendar.jsp
    '2': { width: 994, height: 768 }, // ,frmOutgoingFileSummary.jsp
    '460': { width: 877, height: 300 }, // ,frmPartyIdentifierEnrichment.jsp
    '40': { width: 877, height: 482 }, // ,frmPaymentTypes.jsp
    '435': { width: 1075, height: 800 }, // ,frmPOLDynamic.jsp
    '48': { width: 877, height: 500 }, // ,frmPolProfile.jsp
    '406': { width: 877, height: 768 }, // ,frmPosManage.jsp
    '424': { width: 877, height: 470 }, // ,frmPreventStp.jsp
    '45': { width: 877, height: 481 }, // ,frmProduct.jsp
    '142': { width: 888, height: 553 }, // ,frmProfileCtrl.jsp
    '416': { width: 1156, height: 768 }, // ,frmPRules.jsp
    '9': { width: 932, height: 711 }, // ,frmQueueLevelProfile.jsp
    '39': { width: 894, height: 768 }, // ,frmRateUsageProfile.jsp
    '53': { width: 883, height: 540 }, // ,frmReasons.jsp
    '443': { width: 877, height: 443 }, // ,frmRejectReturnCodes.jsp
    '106': { width: 1100, height: 500 }, // ,frmDDStopsAndPermits.jsp
    '167': { width: 885, height: 400 }, // ,frmReleaseBands.jsp
    '427': { width: 877, height: 562 }, // ,frmRmaProfile.jsp
    '421': { width: 1052, height: 563 }, // ,frmSearchScreen.jsp
    '9997': { width: 877, height: 480 }, // ,frmSelectiveCacheRefresh.jsp
    '82': { width: 877, height: 401 }, // ,frmSerialCoverControl.jsp
    '455': { width: 894, height: 768 }, // ,frmSlaProfile.jsp
    '441': { width: 877, height: 440 }, // ,frmSORecurrencePattern.jsp
    '16': { width: 877, height: 550 }, // ,frmStandingOrder.jsp
    '114': { width: 877, height: 462 }, // ,frmStopFlags.jsp
    '299': { width: 877, height: 462 }, // ,frmSwap.jsp
    '681': { width: 877, height: 462 }, // ,frmStopProfile.jsp
    '54': { width: 877, height: 728 }, // ,frmSystemOptions.jsp
    '183': { width: 877, height: 700 }, // ,frmTimeZone.jsp
    '75': { width: 877, height: 433 }, // ,frmTransactionCodes.jsp
    '446': { width: 894, height: 768 }, // ,frmUDFs.jsp
    '4': { width: 877, height: 460 }, // ,frmUserAbsence.jsp
    '100': { width: 877, height: 603 }, // ,frmUserEntitlement.jsp
    '128': { width: 885, height: 549 }, // ,frmValueListRules.jsp
    '136': { width: 877, height: 522 }, // ,frmValueListTypesProfile.jsp
    '464': { width: 877, height: 400 }, // ,frmVendorCodes.jsp
    '41': { width: 885, height: 768 }, // ,frmPartyLimits.jsp
    '88': { width: 863, height: 470 }, // ,frmRunTaskAccuityUpload.jsp
    '90': { width: 700, height: 400 }, // ,frmRunTaskEiscdUpload.jsp
    '202': { width: 879, height: 450 }, // ,frmRunTaskWithOffice.jsp
    '203': { width: 886, height: 680 }, // ,frmRunTaskWithOfficeAndMOP.jsp
    '204': { width: 896, height: 768 }, // ,frmRunTaskAdvanceBusinessDate.jsp
    '205': { width: 700, height: 400 }, // ,frmRunTaskInsertSTPFields.jsp
    '207': { width: 700, height: 400 }, // ,frmRunTaskRefreshMessagesSnapshot.jsp
    '438': { width: 700, height: 400 }, // ,frmRunTaskFileUpload.jsp
    '439': { width: 862, height: 637 }, // ,frmRunTaskBicPlusUpload.jsp
    '442': { width: 881, height: 659 }, // ,frmRunTaskRMAFileUpload.jsp
    '445': { width: 866, height: 594 }, // ,frmRunTaskExchRateUpload.jsp
    '465': { width: 700, height: 400 }, // ,frmRunTaskVendorCodesUpload.jsp
    '513': { width: 896, height: 850 }, // ,frmBatchControl.jsp
    '18': { width: 877, height: 480 }, // ,frmFileStatistics.jsp
    '476': { width: 897, height: 768 }, // ,frmDDParameters.jsp
    '477': { width: 877, height: 454 }, // ,frmDDCreditorIDAccounts.jsp
    '93': { width: 990, height: 663 }, // ,frmRuleScriptGen.jsp
    '487': { width: 877, height: 395 }, // ,frmNLSTelecodeConversion.jsp
    '521': { width: 705, height: 480 }, // ,frmGapsData.jsp
    '417': { width: 897, height: 768 }, //frmObjectPRules.jsp
    '483': { width: 895, height: 683 },
    '481': { width: 893, height: 768 },
    '418': { width: 894, height: 768 },
    '91': { width: 878, height: 656 },
    '437': { width: 884, height: 697 },
    '490': { width: 890, height: 694 },
    '391': { width: 895, height: 768 },
    '518': { width: 932, height: 711 },
    '422': { width: 932, height: 711 },
    '334': { width: 877, height: 492 },
    '515': { width: 877, height: 483 },
    '514': { width: 877, height: 644 },
    '385': { width: 877, height: 643 },
    '469': { width: 877, height: 409 },
    '470': { width: 877, height: 411 },
    '475': { width: 877, height: 661 },
    '480': { width: 877, height: 465 },
    '47': { width: 936, height: 768 },
    '530': { width: 877, height: 634 },
    '562': { width: 890, height: 606 },
    '380': { width: 885, height: 690 },
    '381': { width: 885, height: 690 },
    '105': { width: 870, height: 610 },
    '482': { width: 870, height: 610 },
    '284': { width: 877, height: 627 },
    '390': { width: 890, height: 606 },
    '285': { width: 890, height: 627 },
    '491': { width: 890, height: 627 },
    '559': { width: 890, height: 627 },
    '488': { width: 890, height: 627 },
    '113': { width: 877, height: 605 },
    '620': { width: 877, height: 768 }, //frmGPIDirectoryProfile.jsp
    '360': { width: 890, height: 627 },
    '663': { width: 932, height: 711 }, //frmMaskingLevelProfile.jsp
    '287': { width: 900, height: 650 } //frmCallers.jsp
  },
  maxDimensions: {
    width: 1366,
    height: 728, //768 - standard taskbar height
    sideGutters: 20,
    topBottomGutters: 64
  }
};


