import { Component, OnInit } from '@angular/core';
import { Department } from '../../models/department.model';
import { IValueChange } from '../../../core/interfaces/form-value-change.interface';
import { ProfileFormTemplateProviders } from '../../../core/templates/profile-form.template.providers';
import dayjs from 'dayjs';
import {
  BFOnValueChange,
} from '../../../core/interfaces/sdk-profile-hooks.interface';
import {AbstractProfileFormComponent} from "../../abstract/abstract-profile/abstract-profile-form.component";

@Component({
  selector: 'app-department-profile',
  templateUrl: '../../../core/templates/profile-form.template.html',
  styleUrls: ['../../../core/templates/profile-form.template.scss'],
  providers: ProfileFormTemplateProviders
})
export class DepartmentComponent extends AbstractProfileFormComponent implements BFOnValueChange {


  fgppValueChanges(change: IValueChange): void {
    if (change.control === Department.OFFICE) {
      if (change.value) {
        const templateData = this.getDrilldownData(Department.OFFICE);
        if (templateData) {
          this.formMgr.get(Department.OFFC_NAME).reset().disable();
          this.formMgr.get(Department.OFFC_NAME).setValue(templateData[Department.OFFC_NAME]);
          if (this.formMgr.get(Department.REC_STATUS).getValue() === 'NW') {
            const originalData = {...this.getOriginalData()};
            originalData['OFFICE_BUSINESS_DATE'] = dayjs.utc((templateData[Department.BUSINESS_DATE])).valueOf();
            this.setOriginalData(originalData);
          }
        }
      }
    }
  }

}
