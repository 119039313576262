import { AfterViewInit, Component, ElementRef, forwardRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FloatLabelType, MatFormFieldAppearance } from '@angular/material/form-field';
import { ComponentManagerService, FormService, ILayout, ILayoutProperties } from '@fgpp-ui/components';
import { Subscription } from 'rxjs';
import { DEFAULT_USER_PREFERENCES } from '../../core/user-settings/models/default-user-preferences.const';
import { UserPreferences } from '../../core/user-settings/models/user-preferences.model';
import { UserSettingsService } from '../../core/user-settings/services/user-settings.service';
import { FnUiDatetimePickerContract } from './fn-ui-datetime-picker.contract';
import { FnUiDatetimePickerData } from './fn-ui-datetime-picker.data';
import { FnUiDatetimePickerLayout } from './fn-ui-datetime-picker.layout';

@Component({
  selector: 'app-fn-ui-datetime-picker',
  templateUrl: './fn-ui-datetime-picker.component.html',
  styleUrls: ['./fn-ui-datetime-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FnUiDatetimePickerComponent),
      multi: true
    },
    ComponentManagerService,
    FormService
  ]
})
export class FnUiDatetimePickerComponent implements ControlValueAccessor, OnInit, AfterViewInit, OnDestroy {

  get ngModel(): string {
    return this._value;
  }
  set ngModel(value: string) {
    if (value !== this._value) {
      this._value = value;
      this._data.DATE_TIME = value;
      this.setDefaults();
      this.onChange(value);
    }
  }
  private _value: string;

  @Input() set label(value: string) {
    this._contract.properties.DATE_TIME.title = value;
  }

  @Input() set maxDate(value: string) {
    this._data.maxDate = value;
  }

  @Input() set minDate(value: string) {
    this._layout.properties.controls.DATE_TIME.minimum = +value;
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = value;
    if (this.form) {
      value ? this.form.disable() : this.form.enable();
    }
  }
  private _disabled: boolean;

  @Input() set required(required: boolean) {
    this._contract.required = required ? ['DATE_TIME'] : [];
  }

  @Input() appearance: MatFormFieldAppearance = 'fill';
  @Input() floatLabel: FloatLabelType = 'always';

  form: FormGroup;
  formMetadata: ILayoutProperties;
  userPreferences: UserPreferences;

  private _layout: ILayout;
  private _contract: any;
  private _data: any;
  private _subscriber = new Subscription();

  constructor(private elementRef: ElementRef,
              private renderer: Renderer2,
              private formService: FormService,
              private userSettingsService: UserSettingsService) {
    this.setUserPreferences();
    this._layout = JSON.parse(JSON.stringify(FnUiDatetimePickerLayout));
    this._contract = JSON.parse(JSON.stringify(FnUiDatetimePickerContract));
    this._data = JSON.parse(JSON.stringify(FnUiDatetimePickerData));
  }

  ngOnInit(): void {
    this.setForm();
    setTimeout(() => this.setDefaults());
  }

  ngAfterViewInit(): void {
    const labelElement = this.elementRef.nativeElement.querySelector('mat-label');
    if (labelElement) {
      this.renderer.addClass(labelElement, 'dh-form-component-header');
      if (this.required) {
        this.renderer.addClass(labelElement, 'required_input-label');
      }
    }
  }

  ngOnDestroy(): void {
    this._subscriber.unsubscribe();
  }

  private setUserPreferences(): void {
    this.userPreferences = this.userSettingsService.getPreferences();
    if (!this.userPreferences || JSON.stringify(this.userPreferences) === '{}') {
      this.userPreferences = DEFAULT_USER_PREFERENCES;
    }
  }

  private setForm(): void {
    if (!this._layout || !this._contract) {
      return;
    }
    this.form = new FormGroup({});
    this.formService.setForm(this.form);
    this.formMetadata = this.formService.createForm(this.form, this._layout, this._contract, this._data);
    if (this.disabled) {
      this.form.disable();
    }
    const subscription = this.form.get('DATE_TIME').valueChanges.subscribe((value) => {
      this.ngModel = value;
    });
    this._subscriber.add(subscription);
  }

  private setDefaults(): void {
    this.form.patchValue(this._data);
  }

  onChange = (_) => { };
  onTouched = () => { };

  writeValue(value: any): void {
    this.ngModel = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

}
