export enum ParticipantAvailability  {
	"ADMIN_MESSAGE_TYPE"= "TASKS_SETTINGS-ADMIN_MESSAGE_TYPE",
	"MOP"= "TASKS_SETTINGS-MOP",
	"OFFICE"= "TASKS_SETTINGS-OFFICE",
	"PROFILE_CHANGE_STATUS"= "TASKS_SETTINGS-PROFILE_CHANGE_STATUS",
	"REC_STATUS"= "TASKS_SETTINGS-REC_STATUS",
	"SIGN_ON_STATUS"= "TASKS_SETTINGS-SIGN_ON_STATUS",
	"START_DATE"= "TASKS_SETTINGS-START_DATE",
	"START_TIME"= "TASKS_SETTINGS-START_TIME",
	"TASKS_SETTINGS_DESCRIPTION"= "TASKS_SETTINGS-TASKS_SETTINGS_DESCRIPTION",
	"TASKS_SETTINGS_NAME"= "TASKS_SETTINGS-TASKS_SETTINGS_NAME",
	"V_ATTACHED_TO_SCRIPT"= "TASKS_SETTINGS-V_ATTACHED_TO_SCRIPT",
	"V_SIGN_ON_STATUS"= "TASKS_SETTINGS-V_SIGN_ON_STATUS",
	"V_TASKDESCRIPTION"= "TASKS_SETTINGS-V_TASKDESCRIPTION",
	"V_TASKNAME"= "TASKS_SETTINGS-V_TASKNAME",
	"BROADCAST_MSG"= "TASKS_SETTINGS-BROADCAST_MSG",
	"DEPARTMENT"= "TASKS_SETTINGS-DEPARTMENT",
	"EFFECTIVE_DATE"= "EFFECTIVE_DATE",
	"END_DATE"= "TASKS_SETTINGS-END_DATE",
	"END_TIME"= "TASKS_SETTINGS-END_TIME",
	"INDP_IDENTIFIER"= "TASKS_SETTINGS-INDP_IDENTIFIER",
	"TIME_STAMP"= "TIME_STAMP",
	"UID"= "VIRTUAL-UID",
	"PROFILE_UPDATE_PK"= "PROFILE_UPDATE_PK",
	"PU_TIME_STAMP"= "PU_TIME_STAMP",
	"NOTE"= "NOTE"
};
