import { Component, EventEmitter, Inject, Injectable, Input, Output } from '@angular/core';
import { ComponentManagerService, FormService } from '@fgpp-ui/components';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IDynamicModalDialogData } from '../../../core/interfaces/dynamic-modal-dialog-data.interface';

@Injectable()
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-task-scheduler-modal',
  templateUrl: './task-scheduler-modal.component.html',
  styleUrls: ['./task-scheduler-modal.component.scss'],
  providers: [FormService, ComponentManagerService]
})
export class TaskSchedulerModalComponent {
  @Output() onClose = new EventEmitter<void>();
  public endpoint;
  constructor(
              @Inject(MAT_DIALOG_DATA) public dialogConfig: IDynamicModalDialogData,
              private matDialogRef: MatDialogRef<TaskSchedulerModalComponent>) {
    this.endpoint = dialogConfig;
  }


  close(): void {
    this.onClose.emit();
    this.matDialogRef.close();
  }

}
