import { AfterViewInit, Component, Input, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MenuItem } from '../models/menu-item';
import { EventHandlerService } from '../services/event-handler.service';
import { MatMenuItem } from '@angular/material/menu';

@Component({
  selector: 'lib-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss']
})
export class MenuItemComponent implements AfterViewInit {

  @Input() items: MenuItem[];
  @ViewChild('childMenu', { static: true }) public childMenu;
  @ViewChildren(MatMenuItem) menuItems: QueryList<MatMenuItem>;

  constructor(private eventHandlerService: EventHandlerService) { }

  onLeftClick($event) {
    this.eventHandlerService.eventsData$.next($event);
    return false;
  }

  onRightClick($event): boolean {
    if ($event.rightClick) {
      this.eventHandlerService.eventsData$.next($event);
      return false;
    }
    return true;
  }

  ngAfterViewInit(): void {
    for ( const item of this.menuItems) {
      item._getHostElement().setAttribute('azure-id', item.getLabel());
    }
  }
}
