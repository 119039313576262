import { Component, OnDestroy, OnInit } from '@angular/core';
import { IValueChange } from '../../../core/interfaces/form-value-change.interface';
import {
  BFEvents,
  CheckboxComponent,
  DrilldownComponent,
  DrilldownResponseInterface,
  FilterBuilder,
  TokenUtilsService
} from '@fgpp-ui/components';
import { Subscription } from 'rxjs';import { ProfileFormTemplateProviders } from '../../../core/templates/profile-form.template.providers';
import { PopoutParams } from '../../../core/interfaces/popout-params.interface';
import { ProfileMode } from '../../enums/profile-mode.enum';
import {
  BFOnValueChange,
  BFBeforeProfileCreate, BFBeforeProfileSaveAs, BFOnFormLoadStart, BFOnInit
} from '../../../core/interfaces/sdk-profile-hooks.interface';
import {AbstractProfileFormComponent} from "../../abstract/abstract-profile/abstract-profile-form.component";


@Component({
  selector: 'app-identifiers-profile',
  templateUrl: '../../../core/templates/profile-form.template.html',
  styleUrls: ['../../../core/templates/profile-form.template.scss'],
  providers: ProfileFormTemplateProviders
})
export class IdentifiersComponent extends AbstractProfileFormComponent implements BFOnInit, OnDestroy, BFBeforeProfileCreate, BFBeforeProfileSaveAs, BFOnFormLoadStart, BFOnValueChange {
  private idSub = new Subscription();

  fgppOnInit(): void {
    this.setPrefetchDrilldownList('SWIFT_ID-MOP');
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.idSub.unsubscribe();
  }

  fgppFormLoadStart(): void {
    this.updateIdentifierURL();
    this.updateCurrencyFilter(true);
  }

  fgppBeforeCreate(): void {
    this.formMgr.get('SWIFT_ID-SWIFT_ID').disable();
    this.formMgr.get('SWIFT_ID-ACC_NO').disable();

  }

  fgppBeforeSaveAs(): void {
    this.validateIdentifier();
  }

  updateCurrencyFilter(includeCurrency: boolean) {
    const mopData = this.getDrilldownData('SWIFT_ID-MOP');
    const accountNo = this.componentManager.getComponent<DrilldownComponent>('SWIFT_ID-ACC_NO');
    const filters = accountNo.get('filters');
    if (includeCurrency && mopData && mopData['SWIFT_ID_MOP-CURRENCY']) {
      filters['ACCOUNTS-CURRENCY'] = mopData['SWIFT_ID_MOP-CURRENCY'];
    } else {
      if (filters.hasOwnProperty('ACCOUNTS-CURRENCY')) {
        delete filters['ACCOUNTS-CURRENCY'];
      }
    }
    accountNo.set('filters', filters);
  }

  fgppValueChanges(change: IValueChange): void {
    if (change.control === 'SWIFT_ID-MOP') {
      const mopData = this.getDrilldownData('SWIFT_ID-MOP');
      if (!change.value || !mopData) {
        this.formMgr.get('SWIFT_ID-SWIFT_ID').reset().disable();
        this.formMgr.get('SWIFT_ID-ACC_NO').reset().disable();
        this.formMgr.get('SWIFT_ID-DEFAULT_SWIFT').disable();
        this.formMgr.get('SWIFT_ID-IS_DEF_OFFICE').disable();
        const defaultSwift = this.componentManager.getComponent<CheckboxComponent>('SWIFT_ID-DEFAULT_SWIFT');
        defaultSwift.set('value', '0');
        const defaultOffice = this.componentManager.getComponent<CheckboxComponent>('SWIFT_ID-IS_DEF_OFFICE');
        defaultOffice.set('value', '0');
        this.updateCurrencyFilter(false);
      } else {
        this.updateIdentifierURL();
        this.updateCurrencyFilter(true);
        this.formMgr.get('SWIFT_ID-SWIFT_ID').reset();
        this.formMgr.get('MOP-SEND_REC_IDCODE').setValue(mopData['SWIFT_ID_MOP-SEND_REC_IDCODE']);
        this.formMgr.get('SWIFT_ID-DEFAULT_SWIFT').enable();
        this.formMgr.get('SWIFT_ID-IS_DEF_OFFICE').enable();
        this.formMgr.get( 'SWIFT_ID-SWIFT_ID').enable();
        this.formMgr.get('SWIFT_ID-ACC_NO').enable();
      }
    } else if (change.control === 'SWIFT_ID-ACC_NO') {
      const accData = this.getDrilldownData('SWIFT_ID-ACC_NO');
      if (accData) {
        this.formMgr.get('SWIFT_ID-ACC_NO_OFFICE').setValue(accData['ACCOUNTS-OFFICE']);
        this.formMgr.get('SWIFT_ID-CURRENCY').setValue(accData['ACCOUNTS-CURRENCY']);
        this.formMgr.get('ACCOUNTS-V_CUST_CODE').setValue(accData['CUSTOMRS-CUST_NAME']);
      } else {
        this.formMgr.get('SWIFT_ID-ACC_NO_OFFICE').setValue('');
        this.formMgr.get('SWIFT_ID-CURRENCY').setValue('');
        this.formMgr.get('ACCOUNTS-V_CUST_CODE').setValue('');
      }
    }
  }

  fgppOnButtonClick(actionPayload: BFEvents.ButtonEvent): void {
    if (actionPayload.target === 'MOP') {
      const data = this.getOriginalData();
      const mop = data['SWIFT_ID-MOP'];
      const office = data['SWIFT_ID-OFFICE'];
      const uid = [office, mop].join('^');
      const params: PopoutParams = {
        uniqueRecId: uid,
        profileId: '32',
        loadType: this.loadType === 3 ? 6 : this.loadType,
        mode: ProfileMode.EDIT,
        office: office,
        closeEndPoint: null,
        taskCode: null
      };

      this.popout.openOld(params, data, this.authenticationService.sessionId);
    }
  }

  private updateIdentifierURL(): void {
    const mopData = this.getDrilldownData('SWIFT_ID-MOP');
    if (!mopData) {
      return;
    }

    const filterBuilder = new FilterBuilder();
    let profileId = '';
    const tokenUtils: any = new TokenUtilsService();
    let dataKey;
    const sendRecvIdCode = mopData['SWIFT_ID_MOP-SEND_REC_IDCODE'];

    if (sendRecvIdCode === 'USPID') {
      filterBuilder
        .add('CUSTOMRS.OFFICE', this.formMgr.get('SWIFT_ID-OFFICE').getValue())
        .add('CUSTOMRS.REC_STATUS', 'AC')
        .add('CUSTOMRS.ALIAS', '[NOT_EMPTY]');

      profileId = '191';
      dataKey = 'CUSTOMRS-ALIAS';
    } else if (sendRecvIdCode === 'USABA') {
      filterBuilder
        .add('CUSTOMRS.OFFICE', this.formMgr.get('SWIFT_ID-OFFICE').getValue())
        .add('CUSTOMRS.REC_STATUS', 'AC')
        .add('CUSTOMRS.ABA', '[NOT_EMPTY]');

      profileId = '191';
      dataKey = 'CUSTOMRS-ABA';
    } else if (sendRecvIdCode === 'INFSC' || sendRecvIdCode === 'MYIBG' || sendRecvIdCode === 'INMCR') {
      filterBuilder
        .add('NCC.OFFICE', this.formMgr.get('SWIFT_ID-OFFICE').getValue())
        .add('NCC.REC_STATUS', 'AC');

      profileId = '29';
      dataKey = 'NCC-NCC_CODE';
    } else {
      filterBuilder
        .add('CUSTOMRS.OFFICE', this.formMgr.get('SWIFT_ID-OFFICE').getValue())
        .add('CUSTOMRS.REC_STATUS', 'AC')
        .add('CUSTOMRS.SWIFT_ID', '[NOT_EMPTY]');

      profileId = '191';
      dataKey = 'CUSTOMRS-SWIFT_ID';
    }

    const filters = filterBuilder.getFilters();
    const tokens = filterBuilder.getTokens();

    const queryParams = tokenUtils.getQueryString(tokenUtils.parse(filters, tokens));

    const swiftIdComponent = this.componentManager.getComponent<DrilldownComponent>('SWIFT_ID-SWIFT_ID');
    swiftIdComponent.set('selectList', `/ui/profiles/${profileId}${queryParams}`).set('dataKey', dataKey);
  }

  private validateIdentifier(): void {
    const swiftIdComponent = this.formMgr.get('SWIFT_ID-SWIFT_ID').getLayoutControl<DrilldownComponent>();
    const swiftIdValue = this.formMgr.get('SWIFT_ID-SWIFT_ID').getValue();
    if (swiftIdValue) {
      if (this.idSub) {
        this.idSub.unsubscribe();
      }
      this.idSub = swiftIdComponent.resolveData(swiftIdValue).subscribe((data: DrilldownResponseInterface) => {
        if (data.isValueMissing) {
          setTimeout(() => {
            swiftIdComponent.setValueNotInList(true);
          });
        }
      });
    }
  }
}
