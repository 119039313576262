import { Injectable, Inject, EventEmitter } from '@angular/core';
import { Observable, forkJoin, map, mergeMap, tap } from 'rxjs';
import { MessagesManagementService } from '../../../messages/services/messages-management.service';
import { Entity } from '../../../shared/models/enums/entity.enum';
import { FavoriteIdentifier } from '../models/favorite-identifier.model';
import { FavoritesTree } from '../models/favorite-tree.model';
import { Favorite } from '../models/favorite.model';
import { IFavoritesManagerService } from './interfaces/favorites-manager-service.interface';
import { ENTITY_FAVORITES_TOKEN, FAVORITES } from './entity-favorites.service';
import { IEntityFavoritesService } from './interfaces/entity-favorites-service.interface';

@Injectable()
export class FavoritesManagerService implements IFavoritesManagerService {

  favoriteChanged$ = new EventEmitter<void>();

  get tree(): FavoritesTree {
    return this._tree;
  }

  private _favoritesServiceMap: { [key: string]: IEntityFavoritesService };
  private _tree: FavoritesTree;

  constructor(@Inject(ENTITY_FAVORITES_TOKEN) private favoritesServices: Array<IEntityFavoritesService>,
              private messagesManagementService: MessagesManagementService) {
    this.initializeFavoritesServiceMap();
  }

  getTree(): Observable<FavoritesTree> {
    const observables = this.favoritesServices.map((favoritesService: IEntityFavoritesService) => {
      return favoritesService.getTree();
    });
    return forkJoin(observables).pipe(map((trees: Array<FavoritesTree>) => {
      this._tree = {
        id: FAVORITES,
        alias: 'favorites',
        type: null,
        favorites: trees,
        isNode: true
      };
      return this._tree;
    }));
  }

  getFavoritesService(entity: Entity): IEntityFavoritesService {
    return this._favoritesServiceMap[entity];
  }

  add(favorite: Favorite, queueType: string, queueId?: string): Observable<FavoritesTree> {
    const favoritesService = this.getFavoritesService(favorite.type);
    return favoritesService.add(favorite, queueType, queueId).pipe(mergeMap(() => {
      return this.onFavoriteChange(favorite.type);
    }));
  }

  update(favorite: Favorite): Observable<FavoritesTree> {
    const favoritesService = this.getFavoritesService(favorite.type);
    return favoritesService.update(favorite, favorite.id).pipe(mergeMap(() => {
      return this.onFavoriteChange(favorite.type);
    }));
  }

  delete(favorite: Favorite): Observable<FavoritesTree> {
    const favoritesService = this.getFavoritesService(favorite.type);
    return favoritesService.delete(favorite.id).pipe(mergeMap(() => {
      return this.onFavoriteChange(favorite.type);
    }));
  }

  isFavorite(): boolean {
    const favoritesService = this.getFavoritesService(this.messagesManagementService.entity);
    const identifier = this.getFavoriteIdentifier();
    return favoritesService.getFavorite(identifier) !== undefined;
  }

  toggleFavorite(): Observable<FavoritesTree> {
    const favoritesService = this.getFavoritesService(this.messagesManagementService.entity);
    const identifier = this.getFavoriteIdentifier();
    let favorite = favoritesService.getFavorite(identifier);
    if (favorite) {
      return this.delete(favorite);
    } else {
      favorite = this.createFvorite(identifier);
      return this.add(favorite, this.messagesManagementService.queueType, this.messagesManagementService.queueId);
    }
  }

  private initializeFavoritesServiceMap(): void {
    this._favoritesServiceMap = {};
    this.favoritesServices.forEach((favoritesService: IEntityFavoritesService) => {
      this._favoritesServiceMap[favoritesService.entity] = favoritesService;
    });
  }

  private getFavoriteIdentifier(): FavoriteIdentifier {
    const searchRequest = this.messagesManagementService.getSearchRequest();
    return { searchQuery: searchRequest?.searchQuery, sortBy: searchRequest?.sort };
  }

  private createFvorite(identifier: FavoriteIdentifier): Favorite {
    return {
      id: null,
      alias: this.messagesManagementService.gridTitle,
      type: this.messagesManagementService.entity,
      searchQuery: identifier.searchQuery,
      sortBy: identifier.sortBy,
      pinned: [],
      routerLink: null,
      stateName: null,
      stateParams: null
    };
  }

  private onFavoriteChange(entity: Entity): Observable<FavoritesTree> {
    const favoritesService = this.getFavoritesService(entity);
    return favoritesService.refreshTree().pipe(tap(() => {
      this.favoriteChanged$.emit();
    }));
  }
}
