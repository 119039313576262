import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { FnUiDrillDownComponent } from './fn-ui-drill-down.component';
import { FormsModule } from '@angular/forms';
import { FnUiIconModule } from '../fn-ui-icons/fn-ui-icon/fn-ui-icon.module';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    FormsModule,
    FnUiIconModule,
    TranslateModule
  ],
  declarations: [
    FnUiDrillDownComponent
  ],
  exports: [
    FnUiDrillDownComponent
  ]
})
export class FnUiDrillDownModule { }
