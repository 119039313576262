import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { TaskBarState } from '../core/navigation/task-bar/models/task-bar-state.model';
import { TaskBarService } from '../core/navigation/task-bar/services/task-bar.service';

export const TabTaskBarResolver: ResolveFn<TaskBarState> =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): TaskBarState => {

    const taskBarService = inject(TaskBarService);

    const tab = route.parent.routeConfig.path;
    return taskBarService.getTaskBar(tab);
  };
