import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessagesCenterNavigationService } from '../../../../../messages-center/messages-center-navigation/services/messages-center-navigation.service';
import { SystemVariablesService } from '../../../../services/system-variables/system-variables.service';

@Component({
  selector: 'app-task-bar-settings',
  templateUrl: './task-bar-settings.component.html',
  styleUrls: ['./task-bar-settings.component.scss']
})
export class TaskBarSettingsComponent implements OnInit, OnDestroy {

  private readonly timeInterval: number;
  private interval: ReturnType<typeof setTimeout> ;

  constructor(private messagesCenterNavigationService: MessagesCenterNavigationService,
              private systemVariablesService: SystemVariablesService) {
    this.timeInterval = this.systemVariablesService.getSingleVariable('REFRESHPOS') || 30;
  }

  ngOnInit(): void {
    this.interval = setInterval(() => {
      this.messagesCenterNavigationService.updateCurrentTree();
    }, this.timeInterval * 1000)
  }

  refreshTree() {
    this.messagesCenterNavigationService.updateCurrentTree();
  }

  getLastUpdate(): string {
    return this.messagesCenterNavigationService.treeLastUpdate;
  }

  isUpdatedByUser(): boolean {
    return this.messagesCenterNavigationService.updatedByUser
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }
}
