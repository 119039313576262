import { Injectable } from '@angular/core';
import { FnUiDialogService } from '../../shared/fn-ui-dialog/services/fn-ui-dialog.service';
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { HoldModalComponent } from '../components/hold-modal/hold-modal.component';
import { MopSelectionModalComponent } from '../components/mop-selection-modal/mop-selection-modal.component';

@Injectable({
  providedIn: 'root'
})
export class LiquidityModalsService {

  constructor(private dialogService: FnUiDialogService) { }

  openHoldModal(bodyTitleText, modalTitleText): MatDialogRef<HoldModalComponent> {

    const config: MatDialogConfig = {
      width: '500px',
      data: {
        bodyTitle: bodyTitleText,
        modalTitle: modalTitleText,
        message: '',
        noteCharsLimit: 250
      }
    };
    return this.dialogService.open(HoldModalComponent, config);
  }

  openMopSelectionModal(bodyTitleText, modalTitleText): MatDialogRef<MopSelectionModalComponent> {

    const config: MatDialogConfig = {
      width: '500px',
      data: {
        bodyTitle: bodyTitleText,
        modalTitle: modalTitleText,
        message: '',
        noteCharsLimit: 250
      }
    };
    return this.dialogService.open(MopSelectionModalComponent, config);
  }
}
