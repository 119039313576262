import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
// // import { AnalyticsDashboardModule } from './analytics-dashboard/analytics-dashboard.module';
import { FnUiNavigationTreeModule } from '../shared/fn-ui-navigation-tree/fn-ui-navigation-tree.module';
import { UiComponentsModule } from '../ui-components/ui-components.module';

import { InsightTreeComponent } from './components/insight-tree/insight-tree.component';

import { InsightTaskbarGuard } from './guards/insight-task-bar.guard';
import { PowerBIReportGuard } from './guards/power-bi-report.guard';
import { PowerBITaskbarGuard } from './guards/power-bi-task-bar.guard';

import { InsightRoutingModule } from './insight-routing.module';
import { PowerBIModule } from './power-bi/power-bi.module';

import { InsightNavigationService } from './services/insight-navigation.service';
import { InsightTaskbarService } from './services/insight-taskbar.service';
import {OperationDashboardComponent} from "./components/operational-dashboard/operational-dashboard.component";
import {DashboardModule} from "@fgpp-ui/dashboard";
import {DashboardComponent} from "./components/dashboard/dashboard.component";
import {DynamicComponentLoaderModule} from "../ui-components/dynamic-component-loader/dynamic-component-loader.module";
import {ComponentsModule} from "@fgpp-ui/components";

@NgModule({
    imports: [
        CommonModule,
        // AnalyticsDashboardModule,
        FnUiNavigationTreeModule,
        UiComponentsModule,
        InsightRoutingModule,
        PowerBIModule,
        DashboardModule.forRoot(),
        DynamicComponentLoaderModule,
        ComponentsModule
    ],
  declarations: [
    InsightTreeComponent,
    OperationDashboardComponent,
    DashboardComponent
  ],
  exports: [
    InsightTreeComponent,
    OperationDashboardComponent
  ],
  providers: [
    InsightTaskbarGuard,
    PowerBIReportGuard,
    PowerBITaskbarGuard,
    InsightNavigationService,
    InsightTaskbarService,
  ]
})
export class InsightModule { }
