import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { NavigationService } from '../../core/navigation/services/navigation.service';
import { Sitemap } from '../../core/navigation/sitemap/models';

export const SitemapResolver: ResolveFn<Sitemap> =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Sitemap> => {

    const navigationService = inject(NavigationService);

    return navigationService.getSitemap();
  };
