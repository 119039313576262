import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TreeConfig } from '@fgpp-ui/components';
import { RouterHelper } from '../../../base-view/classes/router-helper';
import { ProfileNodeData, ProfilesTree, SitemapNode } from '../../../core/navigation/sitemap/models';
import { ProfilesManagementService } from '../../services/profiles-management.service';
import { Subscription } from "rxjs";

@Component({
  selector: 'app-profiles-navigation-tree',
  templateUrl: './profiles-navigation-tree.component.html',
  styleUrls: ['./profiles-navigation-tree.component.scss']
})
export class ProfilesNavigationTreeComponent implements OnDestroy, OnInit {

  private subscriber = new Subscription();

  @Input() navigationTree: ProfilesTree;
  @Input() profileId: any;

  treeConfig: Partial<TreeConfig> = {
    expandable: (node) => node.nodes?.length > 0,
    selectable: (node) => node.data.action
  };

  currentTreeItemId: string;
  selectedNode: SitemapNode<ProfileNodeData>;

  constructor(private profilesManagementService: ProfilesManagementService,
              private route: ActivatedRoute,
              private router: Router) { }

  ngOnInit(): void {
    this.subscribeRouteData();
    this.subscribeRouteParams();
  }

  private subscribeRouteData(): void {
    const routeDataSubscriber = this.route.data.subscribe((data) => {
      this.navigationTree = data.navigationTree;
    });
    this.subscriber.add(routeDataSubscriber);
  }

  private subscribeRouteParams(): void {
    const routeParamsSubscriber = this.route.firstChild.params.subscribe(params => {
      this.selectTreeItem();
    });
    this.subscriber.add(routeParamsSubscriber);
  }

  ngOnDestroy(): void {
    this.subscriber.unsubscribe();
  }

  selectTreeItem(): void {
    this.profilesManagementService.selectTreeItem(this.navigationTree, RouterHelper.getSegments(this.router)[3].path);
    this.selectedNode = this.profilesManagementService.selectedTreeNode;
    if (this.selectedNode != null && !this.route.snapshot.params['fromForm']) {
      this.onTreeItemSelect(this.selectedNode);
    }
  }

  onTreeItemSelect(node: SitemapNode<ProfileNodeData>): void {
    if (!!node.data && !!node.data.action && node.id !== this.currentTreeItemId) {
      this.profilesManagementService.resetGridState();
      this.currentTreeItemId = node.id;
      this.profilesManagementService.onSelectProfile(node, true);
    }
  }
}
