import { Injectable } from '@angular/core';
import { FgppButton } from '../../core/utils/fgpp-buttons.utils';
import { ButtonCategory } from '../../core/enums/button-category.enum';
import { ProfileButtons } from '../enums/profile-buttons.enum';
import { FgppButtonProps } from '../../core/utils/fgpp-button-props.utils';
import { ButtonType } from '../../core/enums/button-type.enum';

@Injectable()
export class ProfilesFooterService {
  private footer;
  private standardButtons: Map<string, FgppButton> = new Map();
  private customButtons: Map<string, FgppButton> = new Map();

  private static validate(footer: any): boolean {
    return footer && footer.controlType === 'footer' && footer.controls;
  }

  public setFooter(footer: any): this {
    if (ProfilesFooterService.validate(footer)) {
      this.footer = footer;
      this.setControls(footer.controls);
    } else {
      console.error('Invalid Profile Footer provided');
    }

    return this;
  }

  public getFooter(): any {
    const newFooter = Object.assign({}, this.footer);
    newFooter.controls = this.getControls();
    return newFooter;
  }

  public retain(buttonCategory: ButtonCategory, buttonPropsArr: FgppButtonProps[]): ProfilesFooterService {
    if (buttonCategory === ButtonCategory.STANDARD) {
      this.filterButtons(this.standardButtons, buttonPropsArr);
    } else {
      this.filterButtons(this.customButtons, buttonPropsArr);
    }
    return this;
  }

  private filterButtons(source: Map<string, FgppButton>, buttonPropsArr: FgppButtonProps[]): void {
    source.forEach((button, buttonId) => {
      const newButtonProps = buttonPropsArr.find(b => b.buttonId === buttonId);
      if (newButtonProps) {
        button.buttonType = newButtonProps.buttonType;
        if (newButtonProps.action) {
          button.action = newButtonProps.action;
        }
      } else {
        source.delete(buttonId);
      }
    });
  }

  private getControls(): { [action: string]: FgppButton } {
    const newControls: { [action: string]: FgppButton } = {};
    this.standardButtons.forEach((button, buttonId) => {
      newControls[buttonId] = button;
    });
    this.customButtons.forEach((button, buttonId) => {
      newControls[buttonId] = button;
    });
    return newControls;
  }

  private setControls(controls: { [action: string]: FgppButton }): void {
    const standardButtonsList = Object.keys(ProfileButtons);
    for (const button in controls) {
      if (standardButtonsList.findIndex(btnName => btnName === button) >= 0) {
        this.standardButtons.set(button, new FgppButton(controls[button]));
      } else {
        this.customButtons.set(button, new FgppButton(controls[button]));
      }
    }
  }

  public removeStandardButtons(buttonIds: string[]): void {
    this.standardButtons.forEach((button, buttonId) => {
      const index = buttonIds.find( b => b === buttonId);
      if (index) {
        this.standardButtons.delete(buttonId);
      }
    });
  }

  public updateStandardButtonType(buttonIds: string[], buttonType: ButtonType) {
    this.standardButtons.forEach((button, buttonId) => {
      const index = buttonIds.find( b => b === buttonId);
      if (index) {
        button.buttonType = buttonType;
      }
    });
  }
}
