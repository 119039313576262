import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-hold-modal',
  templateUrl: './hold-modal.component.html',
  styleUrls: ['./hold-modal.component.scss']
})
export class HoldModalComponent {

  constructor(@Inject(MAT_DIALOG_DATA) private _params,
              private dialogRef: MatDialogRef<HoldModalComponent>) { }

get params() {
    return this._params;
}

close(data?) {
    this.dialogRef.close(data);
}
}
