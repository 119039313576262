import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, catchError, of, tap } from 'rxjs';
import { TranslationCacheService } from './translation-cache.service';
import { TranslationConfigurationService } from './translation-configuration.service';

export function TranslateLoaderFactory(injector: Injector) {
  return new TranslationLoaderService(injector.get(HttpClient), injector.get(TranslationCacheService));
}

@Injectable()
export class TranslationLoaderService implements TranslateLoader {

  private readonly localTranslationsUrl = 'assets/languages/product_' + TranslationConfigurationService.DEFAULT_LANG + '.json';

  constructor(private http: HttpClient,
              private translationCacheService: TranslationCacheService) { }

  public getTranslation(lang: string): Observable<any> {
    lang = this.getLangOnFirstLoading(lang);

    const translation = this.translationCacheService.get(lang);
    if (translation) {
      return of(translation);
    }

    return this.http.get(this.localTranslationsUrl)
      .pipe(tap((translations) => {
      this.translationCacheService.set(lang, translations);
    }));

  }

  private getLangOnFirstLoading(lang: string): string {
    return lang === TranslationConfigurationService.EN_ID ? TranslationConfigurationService.DEFAULT_LANG : lang;
  }

}
