import { ClipboardModule } from '@angular/cdk/clipboard';
import { NgModule } from '@angular/core';
import { UserMenuComponent } from './user-menu.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { UserMenuService } from './services/user-menu.service';
import { TranslateModule } from '@ngx-translate/core';
import { FnDynamicMenuModule } from '../../../../../../projects/menu/src/lib/dynamic-menu/fn-dynamic-menu.module';
import { FnUiToastModule } from '../../shared/fn-ui-toast/fn-ui-toast.module';
import { ToursModule } from '../../tours/tours.module';

@NgModule({
  imports: [
    MatMenuModule,
    MatIconModule,
    MatDividerModule,
    TranslateModule.forChild(),
    FnDynamicMenuModule,
    ClipboardModule,
    FnUiToastModule,
    ToursModule
  ],
  declarations: [UserMenuComponent],
  providers: [UserMenuComponent, UserMenuService],
  exports: [UserMenuComponent]
})
export class UserMenuModule {
}
