import { Injectable } from '@angular/core';
import { GridSearchQuery } from '../../../grid/models/grid-search-query.model';
import { AdditionalParametersService } from './additional-parameters.service';
import { SearchCriteriaService } from './search-criteria.service';

@Injectable({
  providedIn: 'root'
})
export class SearchQueryService {

  constructor(private searchCriteriaService: SearchCriteriaService,
              private additionalParametersService: AdditionalParametersService) { }

  toString(searchQuery: GridSearchQuery): string {
    const searchCriteria = this.searchCriteriaService.toString(searchQuery.searchCriteria);
    const additionalParameters = this.additionalParametersService.toString(searchQuery.additionalParameters);
    return `{{ ${searchCriteria} ${additionalParameters} }}`;
  }

  toHumanString(searchQuery: GridSearchQuery): string {
    const searchCriteria = this.searchCriteriaService.toHumanString(searchQuery.searchCriteria);
    const additionalParameters = this.additionalParametersService.toHumanString(searchQuery.additionalParameters);
    return searchCriteria + additionalParameters;
  }

}
