`<app-fn-ui-dialog class="message-disclaimer-component">
  <ng-template #header>
    <div class="header">
      <span id="title-disclaimer" class="title">{{ 'message.disclaimer.title' | translate }}</span>
      <app-fn-ui-close-icon mat-dialog-close class="close"></app-fn-ui-close-icon>
    </div>
  </ng-template>

  <ng-template #body>
    <div class="body">
      <div>
        <span>{{'message.disclaimer.subtitle' | translate}}</span>
      </div>
      <div>
        <mat-form-field>
          <mat-select placeholder="{{'message.disclaimer.selector.label' | translate}}"
                      [id]="'message-disclaimer-select'"
                      (selectionChange)="onChangeTemplate($event)">
            <mat-option *ngFor="let template of templates" [value]="template">
              {{template.alias}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </ng-template>


  <ng-template #footer>
    <div class="footer">
      <app-fn-ui-button id="message-disclaimer-button-action" [disabled]="!showButton" type="flat" color="primary" 
      (click)="onButtonClick()">
            {{"message.disclaimer.button.label.download" | translate}}
      </app-fn-ui-button>
    </div>
  </ng-template>
</app-fn-ui-dialog>
