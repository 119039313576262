<div class="file-attachment-row">
  <div class="column upload-icon">
    <lib-icon [iconConfig]="uploadSuccessfulIcon" *ngIf="fileAttachmentRow.uploaded"></lib-icon>
  </div>
  <div class="column fileName">
    <div class="row-container">{{fileAttachmentRow.fileName}}</div>
  </div>
  <div class="column date" matTooltip="{{parseDate}}">{{parseDate}}</div>
  <div class="column user" matTooltip="{{fileAttachmentRow.userId | uppercase}}">{{fileAttachmentRow.userId.substring(0,9) }} </div>
  <div matTooltipPosition="above" matTooltip="{{fileAttachmentRow.description}}" class="column description">
    <div class="row-container">
      {{(fileAttachmentRow.description && fileAttachmentRow.description.length > 0) ? fileAttachmentRow.description.substring(0, 13) : 'N/A'}}
    </div>
  </div>
  <div class="icon">
    <lib-download-icon (onClicked)="onDownloadClick()"></lib-download-icon>
  </div>
  <div class="column icon">
    <lib-clear-icon [isDisabled]="permissions === FileAttachmentPermissionMode.READ" (onClicked)="onClearClick()">
    </lib-clear-icon>
  </div>
</div>
