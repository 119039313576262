import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TableRow } from '@fgpp-ui/grid';
import { GridFooterConfig } from '../../models/grid-footer-config.model';
import { IGroupAction } from '../../../messages-center/services/group-actions.service';
import { FormatsService } from '../../../shared/services/formats.service';

@Component({
  selector: 'app-grid-footer',
  templateUrl: './grid-footer.component.html',
  styleUrls: ['./grid-footer.component.scss']
})
export class GridFooterComponent {

  get disableActions(): boolean {
    return !this.selectedRows || !this.selectedRows.length;
  }

  get disableBeforAfter(): boolean {
    return !this.selectedRows || this.selectedRows.length !== 1;
  }

  @Input() set totalCount(totalCount: number) {
    this._totalCount = totalCount;
    this.totalCountFormatted = this.formatsService.toNumberFormat(totalCount);
  }

  get totalCount(): number {
    return this._totalCount;
  }

  @Input() gridFooterConfig: GridFooterConfig;
  @Input() selectedRows: Array<TableRow>;
  @Input() groupActions: Array<any>;

  @Output() beforeAfterClicked = new EventEmitter<void>();
  @Output() actionClicked = new EventEmitter<IGroupAction>();
  @Output() callerDetailsClicked = new EventEmitter<void>();
  @Output() createFromTemplateClicked = new EventEmitter<void>();

  totalCountFormatted: string;
  private _totalCount: number;

  constructor(private formatsService: FormatsService) {
  }

  onCallerDetailsClicked() {
    if (this.disableActions) {
      return;
    }
    this.callerDetailsClicked.emit();
  }

  onCreateFromTemplateClick() {
    if (this.disableActions) {
      return;
    }
    this.createFromTemplateClicked.emit();
  }

  onAddNoteClick() {
    if (this.disableActions) {
      return;
    }
    const action = {
      actionId: 'ADD_NOTE',
      mandateNote: true
    };
    this.onActionClick(action);
  }
  onFollowupFlagClick() {
    if (this.disableActions) {
      return;
    }
    const action = {
      actionId: 'ADD_FOLLOW_UP_FLAG',
      mandateNote: false
    };
    this.onActionClick(action);
  }
  onBeforeAfterClick() {
    if (this.disableBeforAfter) {
      return;
    }
    this.beforeAfterClicked.emit();
  }

  onActionClick(action: IGroupAction) {
    if (this.disableActions) {
      return;
    }
    this.actionClicked.emit(action);
  }

}
