import { Action, State, Selector, StateContext } from '@ngxs/store';
import { UserPreferences } from '../user-settings/models/user-preferences.model';
import { SetUserPreferences } from './actions/user-preferences.actions';
import { UserPreferencesStateModel } from './models/user-preferences-state.model';
import { Injectable } from '@angular/core';


@State<UserPreferencesStateModel>({
  name: 'userPreferences',
  defaults: {
    userPreferences: {
      language: 'en-US',
      dateFormat: 'dd/MM/yyyy',
      timeFormat: 'HH:MM',
      decimalSeparator: '.',
      thousandSeparator: ',',
      itemsPerPage: null,
      landingPage: '',
      navigationBar: '',
      roundNumber: false,
      timeMode: 24
    }
  }
})
@Injectable()
export class UserPreferencesState {

  @Selector()
  static getUserPreferences({ userPreferences }: UserPreferencesStateModel): UserPreferences {
    return userPreferences;
  }

  @Action(SetUserPreferences)
  SetUserPreferences(ctx: StateContext<UserPreferencesStateModel>, { payload }: SetUserPreferences) {
    ctx.patchState({
      userPreferences: payload
    });
  }
}
