<div class="main-navigation">

  <nav class="navigation-tabs" >
    <div mat-tab-nav-bar color="primary" [tabPanel]="tabPanel">
      <a mat-tab-link
              class="main-navigation-tab"
              *ngFor="let tab of tabs | slice:'0':maxTabs; let index = index; trackBy: trackByFunction"
              [active]="activeTabIndex === index"
              [attr.automation-id]="tab.id + '-tab'"
              [attr.azure-id]="tab.id + '-tab'"
              [routerLink]="[tab.routerLink]">
        <app-fn-ui-icon class="navigation-tab-icon" [icon]="tab.icon"></app-fn-ui-icon>
        <span class="navigation-tab-alias">{{ tab.alias | translate }}</span>
      </a>
    </div>

    <button mat-icon-button
            azure-id="more tabs"
            name="more-tabs-trigger"
            class="more-tabs-trigger"
            [matMenuTriggerFor]="menu"
            [hidden]="maxTabs === totalTabs"
            (menuOpened)="onMenuOpen()"
            (menuClosed)="onMenuClose()">
      <app-fn-ui-icon class="navigation-tab-icon" icon="dots_horizontal"></app-fn-ui-icon>
    </button>

    <mat-menu #menu="matMenu" class="more-tabs-panel" [hasBackdrop]="false">
      <a mat-menu-item
              class="main-navigation-tab"
              *ngFor="let tab of tabs | slice:maxTabs:totalTabs; let index = index; trackBy: trackByFunction"
              [attr.automation-id]="tab.id + '-tab'"
              [attr.azure-id]="tab.id + '-tab'"
              [routerLink]="[tab.routerLink]">
        <app-fn-ui-icon class="navigation-tab-icon" [icon]="tab.icon"></app-fn-ui-icon>
        <span class="navigation-tab-alias">{{ tab.alias | translate }}</span>
      </a>
    </mat-menu>
    <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
  </nav>

  <app-tour-item [tourId]="'mainPage'" [tourStepNumber]="3" [targetAt]="menu"></app-tour-item>

</div>
