import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';

export const TOAST_ALERT_ANIMATION = trigger('flyInOut', [
  state('inactive', style({
    opacity: 0
  })),
  transition('inactive => active', animate('400ms ease-out', keyframes([
    style({
      opacity: 0
    }),
    style({
      opacity: 1
    })
  ]))),
  transition('active => removed', animate('400ms ease-out', keyframes([
    style({
      opacity: 1
    }),
    style({
      opacity: 0
    })
  ]))),
]);
