import { Pipe, PipeTransform } from '@angular/core';
import { IFormatsInterface } from '../shared/models/format-time.model';
import { Formats } from '../shared/models/formats.model';
import { FormatsService } from '../shared/services/formats.service';

@Pipe({ name: 'ftRound' })
export class FtRoundPipe implements PipeTransform {

  constructor(private formatsService: FormatsService) { }

  transform(value: any, thousandSeparator?: string, decimalSeparator?: string): string {
    if (value == null) {
      return '';
    }

    const formatObj: Formats = this.formatsService.formats;

    if (!formatObj || !formatObj.roundNumber) {
      return value.toLocaleString();
    }

    value = this.deformatNumberValue(value, thousandSeparator, decimalSeparator);
    if (isNaN(value)) {
      return '';
    }

    const units: string[] = ['K', 'M', 'B', 'T'];
    let decimal: number;
    let i: number = units.length - 1;

    for (; i >= 0; i--) {
      decimal = Math.pow(1000, i + 1);
      if (value <= -decimal || value >= decimal) {
        return +(value / decimal).toFixed(2) + units[i]; //toFixed(2) in order to show 2 digits after the decimal point
      }
    }

    return '' + Number(value); //will trim trailing zeroes if there are any
  }


  deformatNumberValue(value: any, thousandSeparator: string, decimalSeparator: string): string {
    thousandSeparator = thousandSeparator || this.formatsService.thousandSeparator;
    decimalSeparator = decimalSeparator || this.formatsService.decimalSeparator;
    value = value.toString();
    value = value.replace(decimalSeparator, '.');
    value = value.split(thousandSeparator).join(''); //in order to replace all occurrences

    return value;
  }

}
