import { NgModule } from '@angular/core';
import { UiComponentsModule } from '../../ui-components/ui-components.module';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { DisplayComponent } from './components/display/display.component';
import { LocalizationComponent } from './components/localization/localization.component';
import { UserSettingsComponent } from './components/user-settings/user-settings.component';
import { UserSettingsService } from './services/user-settings.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FnUiButtonModule } from '../../shared/fn-ui-button/fn-ui-button.module';
import { TranslateModule } from '@ngx-translate/core';
import { FnUiCloseIconModule } from '../../shared/fn-ui-icons/fn-ui-close-icon/fn-ui-close-icon.module';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { UserSettingsApiService } from './services/user-settings-api.service';
import { DateExamplePipe } from './components/localization/date-example.pipe';
import { TimeExamplePipe } from './components/localization/time-example.pipe';
import { FnUiIconModule } from '../../shared/fn-ui-icons/fn-ui-icon/fn-ui-icon.module';
import { PasswordValidationService } from './services/password-validation.service';
import { PasswordPolicyService } from './services/password-policy.service';
import { ApiCachingModule } from '../api-caching/api-caching.module';
import { AuthenticationModule } from '../../authentication/authentication.module';

@NgModule({
    declarations: [
        UserSettingsComponent,
        ChangePasswordComponent,
        LocalizationComponent,
        DisplayComponent,
        DateExamplePipe,
        TimeExamplePipe
    ],
    imports: [
        UiComponentsModule,
        ReactiveFormsModule,
        FnUiButtonModule,
        TranslateModule.forChild(),
        FnUiCloseIconModule,
        MatDividerModule,
        MatListModule,
        CommonModule,
        MatSelectModule,
        FormsModule,
        MatCheckboxModule,
        FnUiIconModule,
        ApiCachingModule,
        AuthenticationModule
    ],
    providers: [
        UserSettingsService,
        UserSettingsApiService,
        PasswordValidationService,
        PasswordPolicyService
    ],
    exports: [
    ]
})
export class UserSettingsModule { }
