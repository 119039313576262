import { NgModule } from '@angular/core';
import { StepperComponent } from './stepper/stepper.component';
import { StepperIconComponent } from './stepper-icon/stepper-icon.component';
import { CommonModule } from '@angular/common';



@NgModule({
    declarations: [
      StepperComponent,
      StepperIconComponent
    ],
    imports: [CommonModule],
    providers: [],
    exports: [
      StepperComponent
    ]
  })
export class StepperModule {

}
