export const getEffectiveDateDialogContract = () => ({
    $schema: 'http://json-schema.org/draft-07/schema#',
    $id: 'http://example.com/product.schema.json',
    $title: 'Effective Date',
    $description: 'JSON Schema Contract for Effective Date Modal',
    $type: 'object',
    properties: {
        'EFFECTIVE_DATE': {
          title: 'business-framework.profiles.modals.effectiveDate.title',
        },
        NOTE: {
            title: 'business-framework.profiles.modals.effectiveDate.note',
            maxLength: 200
        }
    },
  required: ['EFFECTIVE_DATE']
});
