import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Icon } from '../../shared/models/icon.model';
import { FileAttachmentPermissionMode } from '../../file-attachment-manager/models/enums/file-attachment-permission-mode.enum';
import { FileAttachmentRow } from './models/file-attachment-row.model';

@Component({
  selector: 'lib-file-attachment-row',
  templateUrl: './file-attachment-row.component.html',
  styleUrls: ['./file-attachment-row.component.scss']
})
export class FileAttachmentRowComponent implements OnInit {
  @Input() permissions: FileAttachmentPermissionMode;
  @Input() fileAttachmentRow: FileAttachmentRow;
  @Input() dateFormatParser: Function;
  @Output() downloadOnClick: EventEmitter<FileAttachmentRow> = new EventEmitter<FileAttachmentRow> ();
  @Output() clearOnClick: EventEmitter<FileAttachmentRow> = new EventEmitter<FileAttachmentRow> ();

  parseDate: string;
  uploadSuccessfulIcon: Icon;
  FileAttachmentPermissionMode = FileAttachmentPermissionMode;

  constructor() { }

  ngOnInit() {
    this.initUploadSuccessfulIcon();
    this.parseDate = this.dateFormatParser(this.fileAttachmentRow.updatedDate);
  }

  onDownloadClick() {
    this.downloadOnClick.emit();
  }

  onClearClick() {
    this.clearOnClick.emit();
  }

  initUploadSuccessfulIcon() {
    const style = {
      'height.px': 13,
      'width.px': 13
    };
    const uploadSuccessfulIconTemplateRef = 'assets/icons/upload_successful.svg';
    this.uploadSuccessfulIcon = {
      iconTemplateRef: uploadSuccessfulIconTemplateRef,
      isDisabled: true,
      mainClass: null,
      iconClass: null,
      tooltip: null,
      style
    };
  }

}
