<header class="header">

    <app-logo></app-logo>

    <div class="navigation-panel">
        <app-main-navigation [hidden]="isSearchBoxExpanded"></app-main-navigation>
        <app-search-box (toggled)="onToggleSearchBox($event)"></app-search-box>
    </div>

    <div class="user-panel">
        <app-notification-bell></app-notification-bell>
        <app-user-menu></app-user-menu>
    </div>
</header>
