export const ValueDateDetermination = {
  "UID_VALUE_DATE_INFO": "VALUE_DATE_INFO-UID_VALUE_DATE_INFO",
  "DEPARTMENT": "VALUE_DATE_INFO-DEPARTMENT",
  "OFFICE": "VALUE_DATE_INFO-OFFICE",
  "REC_STATUS": "VALUE_DATE_INFO-REC_STATUS",
  "PROFILE_CHANGE_STATUS": "VALUE_DATE_INFO-PROFILE_CHANGE_STATUS",
  "EFFECTIVE_DATE": "EFFECTIVE_DATE",
  "NAME": "VALUE_DATE_INFO-NAME",
  "DESCRIPTION": "VALUE_DATE_INFO-DESCRIPTION",
  "PROCESSING_DATE": "VALUE_DATE_INFO-PROCESSING_DATE",
  "RELEASE_DATE": "VALUE_DATE_INFO-RELEASE_DATE",
  "DEBIT_DATE": "VALUE_DATE_INFO-DEBIT_DATE",
  "CREDIT_DATE": "VALUE_DATE_INFO-CREDIT_DATE",
  "SOONEST_VALUE_DATE": "VALUE_DATE_INFO-SOONEST_VALUE_DATE",
  "CALENDARS": "VALUE_DATE_INFO-CALENDARS",
  "OFFSET_DAYS": "VALUE_DATE_INFO-OFFSET_DAYS",
  "CALC_VALID_UNTIL_DT": "VALUE_DATE_INFO-CALC_VALID_UNTIL_DT",
  "VALIDITY_WAIT_DAYS": "VALUE_DATE_INFO-VALIDITY_WAIT_DAYS",
  "TIME_STAMP": "TIME_STAMP",
  "UID": "VIRTUAL-UID",
  "PROFILE_UPDATE_PK": "PROFILE_UPDATE_PK",
  "PU_TIME_STAMP": "PU_TIME_STAMP",
  "NOTE": "NOTE"
};
