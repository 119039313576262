<div class="search-menu">
    <button mat-button
            name="search-menu-trigger"
            class="search-menu-trigger"
            [matMenuTriggerFor]="menu"
            (menuOpened)="onOpen()">
        <span class="trigger-text">{{ triggerAlias | translate }}</span>
        <app-fn-ui-icon class="trigger-icon" icon="chevron_down"></app-fn-ui-icon>
    </button>
    <mat-menu class="search-menu-panel" [ngClass]="{ 'single-item': items.length === 1 }" #menu="matMenu">
        <button name="{{item.name}}-button"
                mat-menu-item
                *ngFor="let item of items; trackBy: trackByFunction"
                [ngClass]="{ 'selected': selectedItem === item }"
                (click)="onMenuItemClick(item)">
            <div class="menu-item-content">
                <span class="menu-item-text">{{ aliasTranslationPrefix + item.name | translate }}</span>
            </div>
        </button>
    </mat-menu>
</div>
