import {Component, OnInit} from '@angular/core';
import {AbstractProfileFormComponent} from '../../abstract/abstract-profile/abstract-profile-form.component';
import { ProfileFormTemplateProviders } from '../../../core/templates/profile-form.template.providers';

@Component({
  selector: 'app-generic-profile',
  templateUrl: '../../../core/templates/profile-form.template.html',
  styleUrls: ['../../../core/templates/profile-form.template.scss'],
  providers: ProfileFormTemplateProviders
})
export class GenericProfileComponent extends AbstractProfileFormComponent implements OnInit {

}
