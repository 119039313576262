import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaListComponent } from './fa-list.component';
import { FaListItemComponent } from './fa-list-item/fa-list-item.component';
import { MatListModule } from '@angular/material/list';
import { DynamicComponentLoaderModule } from '../dynamic-component-loader/dynamic-component-loader.module';

@NgModule({
  imports: [
    CommonModule,
    MatListModule,
    DynamicComponentLoaderModule
  ],
  providers: [],
  declarations: [FaListComponent, FaListItemComponent],
  exports: [FaListComponent, FaListItemComponent]
})

export class FaListModule {
}

