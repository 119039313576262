import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Entity } from '../../shared/models/enums/entity.enum';
import { EntityFavoritesFactory, ENTITY_FAVORITES_DEPS, ENTITY_FAVORITES_TOKEN } from './services/entity-favorites.service';
import { FavoriteActionsService } from './services/favorite-actions.service';
import { FavoritesApiService } from './services/favorites-api.service';
import { FavoritesManagerService } from './services/favorites-manager.service';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forChild()
    ],
    providers: [
        FavoriteActionsService,
        FavoritesApiService,
        {
            provide: ENTITY_FAVORITES_TOKEN,
            multi: true,
            useFactory: EntityFavoritesFactory(Entity.MESSAGES),
            deps: ENTITY_FAVORITES_DEPS
        },
        {
            provide: ENTITY_FAVORITES_TOKEN,
            multi: true,
            useFactory: EntityFavoritesFactory(Entity.FILES),
            deps: ENTITY_FAVORITES_DEPS
        },
        {
            provide: ENTITY_FAVORITES_TOKEN,
            multi: true,
            useFactory: EntityFavoritesFactory(Entity.BULKS),
            deps: ENTITY_FAVORITES_DEPS
        },
        {
            provide: ENTITY_FAVORITES_TOKEN,
            multi: true,
            useFactory: EntityFavoritesFactory(Entity.BATCHES),
            deps: ENTITY_FAVORITES_DEPS
        },
        FavoritesManagerService
    ]
})
export class FavoritesModule { }
