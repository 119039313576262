import { Injectable } from '@angular/core';
import { SearchRequestBuilderService } from '../../grid/services/search-request-builder.service';
import { ProfilesSearchRequest } from '../models/profiles-search-request.type';

@Injectable()
export class ProfilesSearchRequestBuilderService extends SearchRequestBuilderService<ProfilesSearchRequest> {

  get defaultSearchRequest(): ProfilesSearchRequest {
    const searchRequest = super.defaultSearchRequest as ProfilesSearchRequest;
    searchRequest.selectType = 1;
    searchRequest.searchQuery.additionalParameters = { recStatus: 1 };
    return searchRequest;
  }

  buildScope(searchRequest: ProfilesSearchRequest, selectType: number): ProfilesSearchRequest {
    searchRequest.selectType = selectType;
    searchRequest.searchQuery.additionalParameters.recStatus = selectType;
    return searchRequest;
  }

}
