import { GridSearchRequest } from '../models/grid-search-request.model';
import { PaginatorData } from '@fgpp-ui/grid';
import { TableSort } from '@fgpp-ui/grid';
import { SearchFilter } from '../../shared/models/search-filter.model';
import { SearchQueryBuilderService } from '../../shared/services/search-query-builder.service';
import { Injectable } from '@angular/core';
import { MatchingType } from '../../messages/components/messages-matching/models/matching-type.model';
import { GridConfigService } from './grid-config.service';

@Injectable()
export class SearchRequestBuilderService<T extends GridSearchRequest> {

    get defaultSearchRequest(): GridSearchRequest {
        return {
            pageNo: 1,
            pageSize: this.gridConfigService.itemsPerPage,
            searchQuery: {
                additionalParameters: {},
                searchCriteria: null
            },
            sort: []
        };
    }

    constructor(private gridConfigService: GridConfigService) { }

    buildPage(searchRequest: T, page: PaginatorData) {
        searchRequest.pageSize = page.pageSize;
        searchRequest.pageNo = page.pageNumber;
        return searchRequest;
    }

    resetPageNo(searchRequest: T) {
        searchRequest.pageNo = 1;
        return searchRequest;
    }

    buildSort(searchRequest: T, sort: TableSort) {
        const sortBy = sort.direction ? { fieldName: sort.active, sortType: sort.direction } : null;
        searchRequest.sort = sortBy != null ? [sortBy] : [];
        return searchRequest;
    }

    buildFilters(searchRequest: T, filters: Array<SearchFilter>) {
        searchRequest.searchQuery.searchCriteria = SearchQueryBuilderService.buildSearchQuery(filters);
        return searchRequest;
    }

    buildAdditionalParameters(searchRequest: T, additionalParameters: { [key: string]: string | number | MatchingType[] }) {
        searchRequest.searchQuery.additionalParameters = additionalParameters;
        return searchRequest;
    }
}
