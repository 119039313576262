import {Component, Input, OnInit} from '@angular/core';
import {RulesAssociationCommonService} from "../../services/rules-association-common.service";
import {NotificationService} from "@fgpp-ui/components";
import {TranslateService} from "@ngx-translate/core";
import {RuleAssociationsEditService} from "../../services/rule-associations-edit.service";
import {RuleCustomerLevelActionService} from "../../services/rule-customer-level-action.service";
import {FnUiDialogService} from "../../../../../shared/fn-ui-dialog/services/fn-ui-dialog.service";
import {ModalsService} from "../../../../../shared/fn-ui-modals/services/modals.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-rules-association-list',
  templateUrl: './rules-association-list.component.html',
  styleUrls: ['./rules-association-list.component.scss']
})
export class RulesAssociationListComponent implements OnInit {

  get virtualList(): any[] {
    return this._virtualList;
  }

  set virtualList(value: any[]) {
    this._virtualList = value;
    this.rulesCommonService.ruleList.next(value);
  }

  @Input() rulesAssociationDetails: any;
  DEFAULT_GROUPING_THRESHOLD = 5;
  DEFAULT_NUMBER_OF_ITEMS_ABOVE = 1;
  DEFAULT_NUMBER_OF_ITEMS_BELOW = 1;
  MAX_GROUPED_NUMBER_INDICATOR = 99;
  gridEntityConfig: any;
  ruleAssociationListTitle: string = '';
  ruleSelectionTitle: string = '';
  showRulesGrid: boolean = false;
  _virtualList = [];
  previewRuleObject = {
    alias: '',
    ruleDescription: '',
    ruleCondition: '',
    index: -1,
    condition: ''
  }
  selectedRows = [];
  selectedRowsFromAllPages = [];
  gridItemsSelected: boolean = false;
  removeIcon: boolean = false;
  signIndicator: string;
  private grouped: boolean;
  private subscription = new Subscription();

  constructor(public rulesCommonService: RulesAssociationCommonService,
              private ruleAssociationsEditService: RuleAssociationsEditService,
              private ruleCustomerLevelActionService: RuleCustomerLevelActionService,
              private dialogService: FnUiDialogService,
              private modalsService: ModalsService,
              private notificationService: NotificationService,
              private translate: TranslateService) {
    this.gridEntityConfig = this.ruleAssociationsEditService.getGridConfig();
  }

  ngOnInit(): void {
    if (this.rulesCommonService.ruleAssociationStatuses.isCreate) {
      this.onRuleTypeSelected();
    } else {
      this.ruleSelectionTitle = this.translate.instant('ruleAssociation.selectedDetails', {
        ruleName: this.rulesAssociationDetails.RULE_TYPE_NAME,
        associatedTo: this.rulesAssociationDetails.OBJECTID_NAME
      });
    }
    this.showRuleGridHeaderDetails();
    //Set the rule type in the screen if only 1 selection is made
    this.rulesCommonService.virtualList = [];
    if (this.rulesAssociationDetails.RULES) {
      if (this.rulesAssociationDetails.RULES.length > 0) {
        this.rulesCommonService.virtualList.length = 0;
        this.rulesAssociationDetails.RULES.forEach((rule, $index) => {
          rule.PREVIEW_MODE = false;
          rule.CHECKED = false;
          rule.RULE_SEQ_NUMBER = rule.RULE_SEQ_NUMBER ? rule.RULE_SEQ_NUMBER : $index;
          this.rulesCommonService.virtualList.push(rule);
        });
        this.rulesCommonService.sort();
      }
    }
    this.virtualList = this.rulesCommonService.virtualList;
    this.virtualList = this.virtualList.length > this.DEFAULT_GROUPING_THRESHOLD ? this.prepareVirtualList(this.virtualList, false) : this.prepareVirtualList(this.virtualList, true);
/*    this.virtualList?.forEach((rule) => {
      rule.CHECKED = false;
    });*/
    this.rulesCommonService.show.auditTrail = false;
    this.rulesCommonService.showRulesList = true;
  }

  toggleSelection(row, selected) {
    this.virtualList.forEach((rule) => {
      if (row.RULE_UID === rule.RULE_UID) {
        rule.CHECKED = selected.checked;
      }
    });
    this.rulesCommonService.virtualList = this.virtualList;
    this.rulesCommonService.setSelectedRule();
    this.rulesCommonService.show.previewRule = false;
    this.rulesCommonService.controlListItemMovementOptions();
  }

  moveToTop() {
    this.unGroup();
    const index = this.rulesCommonService.getSelectionDetails().index;
    this.rulesCommonService.swap(index, 0);
    this.rulesCommonService.addToUndoStack('SWAP', [0, index], [index, 0]);
    this.virtualList = this.rulesCommonService.virtualList;

  }

  moveUp() {
    this.unGroup();
    const index = this.rulesCommonService.getSelectionDetails().index;
    this.rulesCommonService.swap(index, index - 1);
    this.rulesCommonService.addToUndoStack('SWAP', [index - 1, index], [index, index - 1]);
    this.virtualList = this.rulesCommonService.virtualList;

  }

  moveDown() {
    this.unGroup();
    const index = this.rulesCommonService.getSelectionDetails().index;
    this.rulesCommonService.swap(index, index + 1);
    this.rulesCommonService.addToUndoStack('SWAP', [index + 1, index], [index, index + 1]);
    this.virtualList = this.rulesCommonService.virtualList;

  }

  moveToBottom() {
    this.unGroup();
    const index = this.rulesCommonService.getSelectionDetails().index;
    const length = this.rulesCommonService.virtualList.length;
    this.rulesCommonService.swap(index, length - 1);
    this.rulesCommonService.addToUndoStack('SWAP', [length - 1, index], [index, length - 1]);
    this.virtualList = this.rulesCommonService.virtualList;

  }


  remove(ruleSequenceNumber, ruleDescription) {
    this.unGroup();
    this._closeAllPreviewPaneInScope(-1);
    this.notificationService.info(this.translate.instant('ruleAssociation.remove.notification', {name: ruleDescription}));
    const removedRows = this.rulesCommonService.remove(ruleSequenceNumber);
    const removedSequenceNumbers = [];
    removedSequenceNumbers.push(ruleSequenceNumber);
    this.rulesCommonService.addToUndoStack('REMOVE', removedRows, removedSequenceNumbers);
    this.virtualList = this.rulesCommonService.virtualList;
    this.filterList(this.rulesCommonService.filterString);

  }


  removeSelected() {
    let selectionDetails = this.rulesCommonService.getSelectionDetails();
    this._closeAllPreviewPaneInScope(-1);
    if (selectionDetails.count == 1) {
      const ruleName = this.rulesCommonService.virtualList[selectionDetails.index].RULE_NAME;
      this.notificationService.info(this.translate.instant('ruleAssociation.remove.notification', {name: ruleName}));
      this.doRemove();
    } else if (selectionDetails.count > 1) {
      let affectedRules = '';
      let recordCount = 0;
      this.rulesCommonService.virtualList.forEach((row) => {
        if (row.CHECKED) {
          affectedRules += row.RULE_NAME + '\r\n';
          recordCount++;
        }
      });
      this.modalsService.confirmWithCopy({
        title: 'ruleAssociation.remove.confirmation.title',
        message: this.translate.instant('ruleAssociation.remove.confirmation.message'),
        okButton: 'ruleAssociation.remove.confirmation.ok_button',
        cancelButton: 'ruleAssociation.remove.confirmation.cancel_button',
        affectedList: affectedRules,
        listName: 'Rule Names',
        count: recordCount
      }).afterClosed().subscribe(res => {
        this.responseByReason(res);
      });
    }
  }

  responseByReason(reason) {
    reason === 'ok' ? this.doRemove() : this.doNothing();
  }

  doNothing() {
  }

  doRemove() {
    const removedRows = this.rulesCommonService.removeSelected();
    const removedSequenceNumbers = [];
    removedRows.forEach((row) => {
      removedSequenceNumbers.push(row.RULE_SEQ_NUMBER);
    });
    this.rulesCommonService.addToUndoStack('REMOVE', removedRows.reverse(), removedSequenceNumbers);
    this.virtualList = this.rulesCommonService.virtualList;

    this.rulesCommonService.controlListItemMovementOptions();
    this.rulesCommonService.sort();
    this.rulesCommonService.show.previewRule = false;
    this.filterList(this.rulesCommonService.filterString);

  }

  undo() {
    this.rulesCommonService.doUndo();
    this.virtualList = this.rulesCommonService.virtualList;
  }

  redo() {
    this.rulesCommonService.doRedo();
    this.virtualList = this.rulesCommonService.virtualList;
  }

  filterList(searchString) {
    if (this.grouped) {
      this.unGroup();
    }
    const rulesList = this.rulesCommonService.virtualList;
    this.virtualList = [];
    const tempList = [];
    if (searchString && searchString.length > 0) {
      for (let i = 0; i < rulesList.length; i++) {
        if (rulesList[i].RULE_NAME.toUpperCase()
          .indexOf(searchString.toUpperCase()) != -1 || rulesList[i].RULE_DESCRIPTION.toUpperCase()
          .indexOf(searchString.toUpperCase()) != -1) {
          tempList.push({...rulesList[i]});
        }
      }
      this.rulesCommonService.filterString = searchString;
      this.virtualList = tempList;
    } else {
      this.virtualList = this.rulesCommonService.virtualList;
      this.rulesCommonService.filterString = '';
    }
    this.rulesCommonService.controlListItemMovementOptions();
    return this.virtualList;
  }

  togglePreviewRulePane(selectedRow) {
    const scopeIndex = this._findInScope(selectedRow.RULE_SEQ_NUMBER);
    this._closeAllPreviewPaneInScope(scopeIndex);
    this.previewRuleObject.alias = selectedRow.RULE_NAME;
    this.previewRuleObject.condition = this.formatRule(selectedRow.CONDITION_AS_TEXT);
    setTimeout(() => {
      this.previewRuleObject.condition = this.formatRule(selectedRow.CONDITION_AS_TEXT);
    }, 150);
    this.previewRuleObject.index = selectedRow.RULE_SEQ_NUMBER;
    this.rulesCommonService.show.previewRule = false;
  }

  formatRule(text) {
    return text ? text.replace(new RegExp('\\[', 'g'), '').replace(new RegExp('\\]', 'g'), '').replace(new RegExp('\n', 'g'), '\r\n').replace(new RegExp(' DOES NOT EXIST', 'g'), '') : '';
  }

  _findInScope(sequenceNumber) {
    let matchedKey = undefined;
    this.virtualList.forEach((row, key) => {
      if (sequenceNumber == row.RULE_SEQ_NUMBER) {
        matchedKey = key;
      }
    });

    return matchedKey;
  }

  closePreviewPane() {

    this._closeAllPreviewPaneInScope(-1);
  }

  _closeAllPreviewPaneInScope(sequenceNumber) {
    this.virtualList.forEach((row, key) => {
      if (sequenceNumber != key) {
        row.PREVIEW_MODE = false;
      }
    });
  }

  resetSelectedRules() {
    this.showRulesGrid = false;
    this.ruleAssociationsEditService.newSelectedRows = [];
    this.rulesCommonService.disableAssociationDetails = false;
    this.rulesCommonService.saveDisable = false;
    if (!this.rulesCommonService.ruleAssociationStatuses.isCreate) {
      this.rulesCommonService.moreDisable = false;
    }
  }

  addSelectedRules() {
    const allRows = this.selectedRowsFromAllPages.concat(this.selectedRows);
    this.selectedRowsFromAllPages = [];
    const addedRows = this.rulesCommonService.add(allRows);
    this.rulesCommonService.addToUndoStack('ADD', addedRows.reverse(), allRows);
    this.virtualList = this.rulesCommonService.virtualList;
    this.virtualList?.forEach((rule) => {
      rule.position = 'none';
    });
    this.resetSelectedRules();
  }

  displayRulesGrid() {
    this.showRulesGrid = true;
    this.rulesCommonService.showRulesList = true;
    this.rulesCommonService.disableAssociationDetails = true;
    this.rulesCommonService.moreDisable = true;
    this.rulesCommonService.saveDisable = true;
    this.rulesCommonService.rulesGridRows.next({});
  }

  onPageChangedInRulesList($event: any) {
    this.selectedRowsFromAllPages = this.selectedRowsFromAllPages.concat(this.selectedRows);
    this.ruleAssociationsEditService.newSelectedRows = this.selectedRowsFromAllPages;
  }

  rowUncheck(row) {
    /*this.ruleAssociationsEditService.setUnselectRow(row);
    // $timeout(rowsToChange = row, 10);*/
  }

  onRowSelectionChanged(rows: any) {
    let ruleGrid = {
      rows: rows.allRows,
      options: {
        enableSelectAll: false,
      }
    };
    this.selectedRows = [];
    if (rows.selectedRows.length > 0) {
      rows.selectedRows.forEach((row) => {
        if (!row.isSelected) {
          this.selectedRows.push(row);
        }
      });
      this.gridItemsSelected = this.selectedRows.length > 0 || this.selectedRowsFromAllPages.length > 0;
      if (ruleGrid && ruleGrid.rows) {
        const associatedLevelActionExist = this.ruleCustomerLevelActionService.isAssociatedLevelActionExist(ruleGrid.rows);
        //if one of the rows represent a rule with associated level action then we should disable select all option in the list of rules to associate
        if (associatedLevelActionExist) {
          ruleGrid.options.enableSelectAll = false;
        }
      }
      if (rows.selectedRows.length >= 1) {
        var lastSelectedRuleRow = rows.selectedRows[rows.selectedRows.length - 1];

        if (this.ruleCustomerLevelActionService.isCustomerLevelAction(lastSelectedRuleRow) && lastSelectedRuleRow.isSelected) {
          var ModalInstance = this.ruleCustomerLevelActionService.openCustomerLevelActions(lastSelectedRuleRow, this.rulesCommonService.selectedAssociatedTo);
          // ModalInstance.result.then(onCustomerLevelActionChosen, onCustomerLevelActionReject);
        }
      } else {
        this.rowUncheck({})
      }
    } else {
      this.gridItemsSelected = this.selectedRowsFromAllPages.length > 0;
    }

    function onCustomerLevelActionChosen(customerLevelActionInfo) {
      lastSelectedRuleRow.CUSTOMER_LEVEL_ACTION = customerLevelActionInfo.selectedCustomerLevelAction;
      lastSelectedRuleRow.CUSTOMER_LEVEL_ACTION_UID = customerLevelActionInfo.selectedCustomerLevelActionUID;
    }

    function onCustomerLevelActionReject(reason) {
      lastSelectedRuleRow.isSelected = false;
      if (this.selectedRows.length === 1) { //if only one row was selected when calling onRowSelectionChanged, then now no rows are selected
        this.gridItemsSelected = false;
      }
    }
  }

  private prepareVirtualList(list: any[], defaultGrouping?: boolean) {
    let groupCount = 0;
    if (!defaultGrouping) {
      list?.forEach((rule, index) => {
        if (index >= 0 && index < this.DEFAULT_NUMBER_OF_ITEMS_ABOVE) {
          rule.position = 'start';
        } else if (index >= this.DEFAULT_NUMBER_OF_ITEMS_ABOVE && index < list.length - this.DEFAULT_NUMBER_OF_ITEMS_BELOW) {
          rule.position = 'mid';
          groupCount++;
        } else {
          if (index >= list.length - this.DEFAULT_NUMBER_OF_ITEMS_BELOW && index <= list.length) {
            rule.position = 'end';
          }
        }
      });
      this.signIndicator = groupCount > this.MAX_GROUPED_NUMBER_INDICATOR ? '>' : '+';
      this.grouped = true;
    } else {
      this.grouped = false;
      list?.forEach((rule) => {
        rule.position = 'none';
      });
    }
    return list;
  }

  unGroup() {
    this.grouped = false;
    this.virtualList = this.rulesCommonService.virtualList = this.prepareVirtualList(this.virtualList, true);
    const tempList = [...this.virtualList];
    this.virtualList = [];
    this.virtualList = tempList;
  }

  private onRuleTypeSelected() {
    const sub = this.rulesCommonService.ruleTypeDetails.subscribe((ruleDetails) => {
      this.ruleSelectionTitle = this.translate.instant('ruleAssociation.selectedDetails', {
        ruleName: this.rulesAssociationDetails.RULE_TYPE_NAME ? this.rulesAssociationDetails.RULE_TYPE_NAME : ruleDetails['RULE_TYPE_NAME'],
        associatedTo: ruleDetails['associatedTo'] ? ruleDetails['associatedTo'] : '...'
      });
      this.virtualList = [];
      this.rulesCommonService.resetListSection();

    });
    this.subscription.add(sub);
  }

  private showRuleGridHeaderDetails() {
    const sub = this.rulesCommonService.rulesGridRows.subscribe((gridRows) => {
      if (gridRows['totalCount']) {
        this.ruleAssociationListTitle = this.translate.instant('rules.grid_title', {
          count: gridRows['totalCount'],
          selectedRuleTypeName: this.rulesAssociationDetails.RULE_TYPE_NAME
        });
      } else {
        this.ruleAssociationListTitle = '';
      }
    });
    this.subscription.add(sub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
