import { Injectable, Inject, EventEmitter } from '@angular/core';
import { Observable, forkJoin, map, mergeMap, tap, of } from 'rxjs';
import { Search } from '../../../core/navigation-search/models/search.model';
import { MessagesQueueType } from '../../../messages/models/enums/messages-queue-type.enum';
import { Entity } from '../../../shared/models/enums/entity.enum';
import { RecentSearch } from '../models/recent-search.model';
import { RecentSearchesTree } from '../models/recent-searches-tree.model';
import { ENTITY_RECENT_SEARCHES_TOKEN } from './entity-recent-searches.service';
import { IEntityRecentSearchesService } from './interfaces/entity-recent-searches-service.interface';
import { IRecentSearchesManagerService } from './interfaces/recent-searches-manager-service.interface';

@Injectable()
export class RecentSearchesManagerService implements IRecentSearchesManagerService {

  recentSearchChanged$ = new EventEmitter<void>();

  get tree(): RecentSearchesTree {
    return this._tree;
  }

  private _recentSearchesServiceMap: { [key: string]: IEntityRecentSearchesService };
  private _tree: RecentSearchesTree;

  constructor(@Inject(ENTITY_RECENT_SEARCHES_TOKEN) private recentSearchesServices: Array<IEntityRecentSearchesService>) {
    this.initializeRecentSearchesServiceMap();
  }

  getRecentSearchesService(entity: Entity): IEntityRecentSearchesService {
    return this._recentSearchesServiceMap[entity];
  }

  getTree(): Observable<RecentSearchesTree> {
    const observables = this.recentSearchesServices.map((recentSearchesService: IEntityRecentSearchesService) => {
      return recentSearchesService.getTree();
    });
    return forkJoin(observables).pipe(map((trees: Array<RecentSearchesTree>) => {
      this._tree = {
        id: MessagesQueueType.RECENT_SEARCH.toLowerCase(),
        alias: MessagesQueueType.RECENT_SEARCH.toLowerCase(),
        type: null,
        recentSearches: trees,
        isNode: true
      };
      return this._tree;
    }));
  }

  add(search: Search): Observable<string> {
    if (!search) {
      return null;
    }
    const recentSearchesService = this.getRecentSearchesService(search.entity);
    return recentSearchesService?.add(search)?.pipe(mergeMap((queueId: string) => {
      return this.onRecentSearchChange(search.entity).pipe(map((tree: RecentSearchesTree) => queueId));
    }));
  }

  delete(recentSearch: RecentSearch): Observable<RecentSearchesTree> {
    const recentSearchesService = this.getRecentSearchesService(recentSearch.type);
    return recentSearchesService?.delete(recentSearch.id).pipe(mergeMap(() => {
      return this.onRecentSearchChange(recentSearch.type);
    }));
  }

  resetTree(): void {
    this.recentSearchesServices.forEach((recentSearchesService: IEntityRecentSearchesService) => {
      recentSearchesService.resetTree();
    });
    this._tree = null;
  }

  private initializeRecentSearchesServiceMap(): void {
    this._recentSearchesServiceMap = {};
    this.recentSearchesServices.forEach((recentSearchesService: IEntityRecentSearchesService) => {
      this._recentSearchesServiceMap[recentSearchesService.entity] = recentSearchesService;
    });
  }

  private onRecentSearchChange(entity: Entity): Observable<RecentSearchesTree> {
    const recentSearchesService = this.getRecentSearchesService(entity);
    if (!recentSearchesService.tree) {
      return of(null);
    }
    return recentSearchesService.refreshTree().pipe(tap(() => {
      this.recentSearchChanged$.emit();
    }));
  }

}
