import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Icon } from '../../models/icon.model';

@Component({
  selector: 'lib-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent {

  @Input() iconConfig: Icon;

  @Output() onClicked = new EventEmitter<void>();

  onClick(): void {
    this.onClicked.emit();
  }

  getClass(): string {
    return this.iconConfig.iconClass;
  }

}
