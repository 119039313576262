import {Component, OnInit} from '@angular/core';
import {AbstractProfileFormComponent} from '../../abstract/abstract-profile/abstract-profile-form.component';
import {AccountAdditionalOwners} from '../../models/account-additional-owners.model';
import { ProfileFormTemplateProviders } from '../../../core/templates/profile-form.template.providers';
import { IValueChange } from '../../../core/interfaces/form-value-change.interface';


@Component({
  selector: 'app-account-additional-owners',
  templateUrl: '../../../core/templates/profile-form.template.html',
  styleUrls: ['../../../core/templates/profile-form.template.scss'],
  providers: ProfileFormTemplateProviders
})
export class AccountAdditionalOwnersComponent extends AbstractProfileFormComponent implements OnInit {

  fgppValueChanges(change: IValueChange): void {
    const control = change.control;
    switch (control) {
      case AccountAdditionalOwners.ACC_NO: {
        this.doAccountEnrichment();
        break;
      }
      case AccountAdditionalOwners.CUST_CODE:{
        this.enrichPartyName();
        break;
      }
    }
  }

  fgppFormLoadComplete(): void {
    this.populatePartyName();
  }

  protected populatePartyName(){
    const data = this.getOriginalData();
    this.formMgr.get(AccountAdditionalOwners.CUST_NAME).setValue(data['CUSTOMRS-CUST_NAME']);
  }

  protected doAccountEnrichment(){
    const data = this.getDrilldownData(AccountAdditionalOwners.ACC_NO);
    let currency = data ? data['ACCOUNTS-CURRENCY'] : "";
    this.formMgr.get(AccountAdditionalOwners.CURRENCY).setValue(currency);
    let uid = data ? data['VIRTUAL.UID'] : null;
    this.formMgr.get(AccountAdditionalOwners.ACCOUNT_UID).setValue(uid);
  }

  protected enrichPartyName(){
    const data = this.getDrilldownData(AccountAdditionalOwners.CUST_CODE);
    let partyName =  data ? data['CUSTOMRS-CUST_NAME'] : "";
    this.formMgr.get(AccountAdditionalOwners.CUST_NAME).setValue(partyName);
  }

}

