<div class="dh-main-external-container">

  <app-spinner *ngIf="isLoading"></app-spinner>

  <div class="dh-main-left-container-wrapper"
       [hidden]="isStandAlone" [ngClass]="{'minimized': !showSideBar}">

    <app-task-bar-layout></app-task-bar-layout>
  </div>


  <div class="dh-main-right-container" [ngClass]="{'expand' : !showSideBar, 'isStandAlone': isStandAlone}">
    <app-navigation-result></app-navigation-result>
    <div class="dh-main-right-content">
      <app-rules-landing-page *ngIf="rulesLandingView"></app-rules-landing-page>
      <ng-container [ngSwitch]="profilesMode">
        <app-rules-grid *ngSwitchCase="profilesModeModal.GRID_VIEW"></app-rules-grid>
      </ng-container>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
