import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PowerBIReportsResponse } from '../models/power-bi-reports-response.model';
import { IPowerBIApiService } from './interfaces/power-bi-api-service.interface';

@Injectable()
export class PowerBIApiService implements IPowerBIApiService {

  readonly reportsUrl = 'do/pbi/reports';

  constructor(private http: HttpClient) { }

  getReports(taskBarId: string): Observable<PowerBIReportsResponse> {
    const params = new HttpParams().set('categoryId', taskBarId);
    return this.http.get<PowerBIReportsResponse>(this.reportsUrl, { params });
  }

}
