import { Component } from '@angular/core';
import { BFProfileOnSave } from '../../../core/interfaces/sdk-profile-hooks.interface';
import { ProfileFormTemplateProviders } from '../../../core/templates/profile-form.template.providers';
import { AbstractProfileFormComponent } from '../../abstract/abstract-profile/abstract-profile-form.component';
import { CreditorIdStructure } from '../../models/creditor-id-structure.model';

@Component({
  selector: 'app-creditor-id-structure',
  templateUrl: '../../../core/templates/profile-form.template.html',
  styleUrls: ['../../../core/templates/profile-form.template.scss'],
  providers: ProfileFormTemplateProviders
})
export class CreditorIdStructureComponent extends AbstractProfileFormComponent implements BFProfileOnSave {

  fgppOnSave(): boolean {
    return this.validateFields();
  }

  validateFields(): boolean {
    const maxLengthValue = this.formMgr.get(CreditorIdStructure.CID_MAX_LENGTH).getValue() || 0;
    const discrPositionValue = this.formMgr.get(CreditorIdStructure.DISCR_POSITION).getValue() || 0;
    const discrLengthValue = this.formMgr.get(CreditorIdStructure.DISCR_LENGTH).getValue() || 0;

    if ((+discrPositionValue + +discrLengthValue) > +maxLengthValue) {
      this.notificationService.error(this.translate.instant('business-framework.profiles.480.errors.maxLength'));
      return false;
    }

    return true;
  }
}
