import { TableRow, SortDirection, ColumnTypes } from '@fgpp-ui/grid';
import { GridColumn } from '@fgpp-ui/grid';

//TODO: remove service after implementing sort on server
export class SorterService {

  static sort(rows: Array<TableRow>, column: GridColumn): Array<TableRow> {
    return [...rows].sort((row1: TableRow, row2: TableRow) => {
      const comparison = this.compare(row1[column.name], row2[column.name], column.type);
      return column.sort.direction === SortDirection.ASC ? comparison : -comparison;
    });
  }

  private static compare(value1: any, value2: any, columnType: ColumnTypes) {
    switch (columnType) {
      case ColumnTypes.NUMBER:
        value1 = Number(value1);
        value2 = Number(value2);
        return value1 - value2;
      case ColumnTypes.DECIMAL:
        value1 = parseFloat(value1.replace(/[^\d\.\-]/g, ''));
        value2 = parseFloat(value2.replace(/[^\d\.\-]/g, ''));
        return value1 - value2;
      case ColumnTypes.DATE:
      case ColumnTypes.TIME:
      case ColumnTypes.DATE_TIME:
        value1 = new Date(value1).getTime();
        value2 = new Date(value2).getTime();
        return value1 - value2;
      default:
        value1 = value1 ? value1.trim().toUpperCase() : '';
        value2 = value2 ? value2.trim().toUpperCase() : '';
        return value1 === value2 ? 0 : value1 > value2 ? 1 : -1;
    }
  }

}
