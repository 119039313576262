import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivityLogComponent } from './activity-log.component';
import { ActivityLogItemComponent } from './activity-log-item/activity-log-item.component';
import { FaListModule } from '../../../ui-components/fa-list/fa-list.module';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivityLogItemFullViewComponent } from './activity-log-item-full-view/activity-log-item-full-view.component';
import { ActivityLogTodoMenuComponent } from './activity-log-todo-menu/activity-log-todo-menu.component';
import { ActivityLogAddNoteComponent } from './activity-log-add-note/activity-log-add-note.component';
import { DynamicComponentLoaderModule } from '../../../ui-components/dynamic-component-loader/dynamic-component-loader.module';
import { ActivityLogConstants } from './activity-log-constants';
import { RuleLogListItemComponent } from './activity-log-item/rule-log-list-item/rule-log-list-item.component';
import { NoteListItemComponent } from './activity-log-item/note-list-item/note-list-item.component';
import { ErrorLogListItemComponent } from './activity-log-item/error-log-list-item/error-log-list-item.component';
import { AuditTrailListItemComponent } from './activity-log-item/audit-trail-list-item/audit-trail-list-item.component';
import { TranslateModule } from '@ngx-translate/core';
import { AuditTrailItemFullViewComponent } from './activity-log-item-full-view/audit-trail-item-full-view/audit-trail-item-full-view.component';
import { ErrorLogItemFullViewComponent } from './activity-log-item-full-view/error-log-item-full-view/error-log-item-full-view.component';
import { RuleLogItemFullViewComponent } from './activity-log-item-full-view/rule-log-item-full-view/rule-log-item-full-view.component';
import { NoteItemFullViewComponent } from './activity-log-item-full-view/note-item-full-view/note-item-full-view.component';
import { SpecialInstructionItemFullViewComponent
} from './activity-log-item-full-view/special-instruction-item-full-view/special-instruction-item-full-view.component';
import { SpecialInstructionListItemComponent } from './activity-log-item/special-instruction-list-item/special-instruction-list-item.component';
import { UserNoticeItemFullViewComponent } from './activity-log-item-full-view/user-notice-item-full-view/user-notice-item-full-view.component';
import { UserNoticeListItemComponent } from './activity-log-item/user-notice-list-item/user-notice-list-item.component';
import { FnUiIconModule } from '../../../shared/fn-ui-icons/fn-ui-icon/fn-ui-icon.module';
import { ToursModule } from '../../../tours/tours.module';

@NgModule({
    imports: [
        CommonModule,
        FaListModule,
        DynamicComponentLoaderModule,
        MatFormFieldModule,
        MatInputModule,
        MatMenuModule,
        MatTooltipModule,
        MatTabsModule,
        MatSelectModule,
        MatButtonModule,
        MatRadioModule,
        TranslateModule.forChild(),
        FnUiIconModule,
        ToursModule
    ],
  providers: [ActivityLogConstants],
  declarations: [ActivityLogComponent, AuditTrailListItemComponent, ActivityLogItemComponent,
    ErrorLogListItemComponent, NoteListItemComponent, RuleLogListItemComponent, ActivityLogItemFullViewComponent,
    ActivityLogTodoMenuComponent, ActivityLogAddNoteComponent, AuditTrailItemFullViewComponent, ErrorLogItemFullViewComponent, UserNoticeListItemComponent,
    RuleLogItemFullViewComponent, NoteItemFullViewComponent, SpecialInstructionItemFullViewComponent, SpecialInstructionListItemComponent,
    UserNoticeItemFullViewComponent],
  exports: [ActivityLogComponent]
})

export class ActivityLogModule {
}

