import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SignOutService } from '../../authentication/services/sign-out.service';
import { AzureInsightsService } from '../../azure-insight/services/azure-insights.service';
import { AlertService } from '../../core/alerts/services/alert.service';
import { IdleService } from '../../core/idle/services/idle.service';
import { ModalsService } from '../../shared/fn-ui-modals/services/modals.service';

@Injectable({
  providedIn: 'root'
})
export class DeactivateBaseViewGuard implements CanDeactivate<unknown> {

  constructor(private modalsService: ModalsService, private idleService: IdleService,
              private alertService: AlertService, private azureInsightsService: AzureInsightsService,
              private signOutService: SignOutService) {
  }
  openConfirmLogoutModal() {
    const data = {
      title: 'modals.confirm-logout.title',
      message: 'modals.confirm-logout.message',
      okButton: 'general.yes',
      cancelButton: 'general.no'
    };
    return this.modalsService.confirm(data);
  }

  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree | any {

    if(nextState.url === '/home') {
      return this.openConfirmLogoutModal().afterClosed().pipe(map((result) => {
        if (result === 'ok') {
          this.idleService.stop();
          this.alertService.stop();
          this.azureInsightsService.clearUserId();
          this.signOutService.signOut();
          return true;
        } else {
          return false;
        }
      })
      )} else {
      return true;
    }
  }
}
