import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FnUiDialogService } from '../../shared/fn-ui-dialog/services/fn-ui-dialog.service';
import { AddNoteModalComponent, AddNoteModalFields } from '../../ui-components/add-note-modal/add-note-modal.component';
import { Entity } from '../../shared/models/enums/entity.enum';
import { DrillDownGridService } from '../../grid/services/drill-down-grid.service';
import { GroupActionsFollowupService } from './group-actions-followup.service';
import { Action } from '../../mass-pay/entity/models/action.model';

export interface IGroupAction {
  actionId: string;
  mandateNote: boolean;
}

export interface IGroupActionOutput {
  mid: string;
  errorDescription: boolean;
}

@Injectable()
export class GroupActionsService {

  constructor(private httpClient: HttpClient, private dialogService: FnUiDialogService, private drillDownGridService: DrillDownGridService, private groupActionsFollowupService: GroupActionsFollowupService) {
  }

  /**
   * Fetching group actions for the selected unique ids
   * @param {Entity} entity
   * @param {Array<string>} recordsUniqueIds
   */
  public getGroupActions(entity: Entity, recordsUniqueIds: Array<string>): Promise<Array<Action>> {
    let queryStringParams = new HttpParams();
    recordsUniqueIds.forEach((mid) => {
      queryStringParams = queryStringParams.append('mid', mid);
    });

    return this.httpClient.get<Array<Action>>('do/' + entity + '/groupActions', { params: queryStringParams }).toPromise();
  }

  /**
   * Fetching group actions for the selected profile id
   * @param {Entity} entity
   * @param {string} profileId
   */
  public getProfileGroupActions(entity: Entity, profileId: string) {
    let queryStringParams = new HttpParams();
    queryStringParams = queryStringParams.append('profileId', profileId);

    return this.httpClient.get('do/PROFILE/' + profileId + '/groupActions', { params: queryStringParams }).toPromise();
  }

  /**
   * Executing the selected group action, if needed it prompts for note
   * @param {Entity} entity
   * @param {GroupAction} action
   * @param {Array<string>} recordsUniqueIds
   */
  public async executeGroupAction(entity: Entity, action: IGroupAction, recordsUniqueIds: Array<string>, selectedRows: Array<any>, status?: Array<string>) {
    let note = null;
    let followUpFlag = null;
    let office = null;
    const actionId = action.actionId ? action.actionId.toLowerCase().split(' ').join('_') : null;

    if (action.mandateNote) {
      const config: AddNoteModalFields = {
        title: 'group_action.add_note.' + actionId + '.title',
        body: 'group_action.add_note.' + actionId + '.body',
        closeButton: 'group_action.add_note.' + actionId + '.close_button',
        applyButton: 'group_action.add_note.' + actionId + '.ok_button',
      };

      note = await this.dialogService.open(AddNoteModalComponent, { data: config }).afterClosed().toPromise();
      if (!note || note === '') {
        return null;
      }
    } else if (action.actionId === 'ADD_FOLLOW_UP_FLAG') {
      const paymentOffices = this.getPaymentOfficesForFollowup(selectedRows);
      const drilldownConfig = this.groupActionsFollowupService.getSearchFilter(paymentOffices);
      const selectedFollowUp = await this.drillDownGridService.openDialog(drilldownConfig).afterClosed().toPromise();
      if (Array.isArray(selectedFollowUp) && selectedFollowUp.length === 1) {
        followUpFlag = selectedFollowUp[0]['REASONS-REASONCODE'];
        office = selectedFollowUp[0]['REASONS-OFFICE'];
      } else {
        return null;
      }
    }

    const payload = {
      action: action.actionId,
      mids: recordsUniqueIds,
      msgStatus: status,
      note: note,
      followup: followUpFlag,
      office: office
    };

    return this.httpClient.post('do/' + entity + '/performGroupAction', payload).toPromise();
  }

  public getPaymentOfficesForFollowup(selectedRows: Array<any>) {
    const selectedPaymentOffice = Array.from(new Set(selectedRows.map(row => row.P_OFFICE)));
    const paymentOffice = selectedPaymentOffice.length === 1 ? selectedPaymentOffice.concat('***') : ['***'];
    return paymentOffice;
  }

  /**
   * Executing the selected group action, if needed it prompts for note
   * @param {Entity} entity
   * @param {GroupAction} action
   * @param {Array<string>} recordsUniqueIds
   */
  public async executeProfileGroupAction(entity: Entity, profileId: string, action: IGroupAction, recordsUniqueIds: Array<string>) {
    let note = null;
    const actionId = action.actionId ? action.actionId.toLowerCase().split(' ').join('_') : null;

    if (action.mandateNote) {
      const config: AddNoteModalFields = {
        title: 'group_action.add_note.' + actionId + '.title',
        body: 'group_action.add_note.' + actionId + '.body',
        closeButton: 'group_action.add_note.' + actionId + '.close_button',
        applyButton: 'group_action.add_note.' + actionId + '.ok_button',
      };

      note = await this.dialogService.open(AddNoteModalComponent, { data: config }).afterClosed().toPromise();
      if (!note || note === '') {
        return null;
      }
    }

    const payload = {
      action: action.actionId,
      uids: recordsUniqueIds,
      profileId: profileId,
      note: note
    };

    return this.httpClient.post('do/PROFILE/' + profileId + '/performGroupAction', payload).toPromise();
  }
}

