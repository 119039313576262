import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map, mergeMap, Observable } from 'rxjs';
import { ResponseInfo } from '../../../shared/models/response-info.model';
import {
  ApproveDeclineRequest, AuditTrail, BaseConditionUsedProfilesResponse, ChangeRuleStatusRequest, ComplexCondition, ComplexConditionPayload, ConditionBank,
  ConditionFunction, ConditionOperand, ConditionOperator, ConditionsBankCategory, ConditionsBankNode, ConditionsBankTree, ContentType, DefaultOperand,
  LogicalField, MultipleConditionPayload, RecentConditions, Rule, RuleAction, RuleCriteria, RuleIdRequest, RulePayload, RuleSubType, RuleTypeDetails,
  RuleUniqueData, SearchBaseConditionResponse, SimpleCondition, SimpleConditionPayload
} from '../models';
import { FieldAsString } from '../models/field-as-string.model';
import { RuleApiService } from './rule-api.service';
import { RuleConditionsAlgorithmsService } from './rule-conditions-algorithms.service';
import { RuleConditionsService } from './rule-conditions.service';

@Injectable()
export class RuleService {

  typeId = '0';
  bcRestrictedLogicalFields = {} as { [key: string]: Array<RuleUniqueData>};
  baseConditions = [] as Array<string>;
  conditionsBank = { nodes: [] } as ConditionsBankTree;
  flatConditionsBank = [] as Array<ConditionsBankNode>;
  actionDetails = {} as RuleAction;
  subType = {} as RuleSubType;
  isBCAllowed = false;
  execTypeBindWhere = false;
  operatorsPerDataType: { [key: string]: Array<ConditionOperator> };
  justUsedConditions = [] as Array<string>;

  private conditionBankFields = [] as Array<LogicalField>;

  constructor(private ruleApiService: RuleApiService,
              private ruleConditionsService: RuleConditionsService,
              private ruleConditionsAlgorithmsService: RuleConditionsAlgorithmsService,
              private translateService: TranslateService) {
    this.ruleConditionsAlgorithmsService.getFieldAsObject = this.getFieldAsObject.bind(this);
  }

  getRuleTypeDetails(typeId: string, office: string): Observable<RuleTypeDetails> {
    return this.ruleApiService.getRuleTypeDetails(typeId, office).pipe(map((data: RuleTypeDetails) => {
      this.handleRuleTypeDetails(data);
      return data;
    }));
  }

  getRule(ruleId: string, loadType: string): Observable<Rule> {
    return this.ruleApiService.getRule(ruleId, loadType).pipe(map((data: RulePayload) => {
      this.handleRule(data);
      return data.contentHolder;
    }));
  }

  private handleRule(data: RulePayload): void {
    this.ruleConditionsService.conditions = this.prepareRuleConditions(data.contentHolder.CONDITIONS);
    if (data.contentHolder.BC_RESTRICTED_LOGICAL_FIELDS) {
      this.bcRestrictedLogicalFields = data.contentHolder.BC_RESTRICTED_LOGICAL_FIELDS;
    }
  }

  saveRule(ruleId: string, rule: Rule): Observable<ResponseInfo> {
    const ruleContent = this.mapSaveRule(rule);
    return this.ruleApiService.saveRule(ruleId, ruleContent);
  }

  createRule(rule: Rule): Observable<ResponseInfo> {
    const ruleContent = this.mapSaveRule(rule);
    return this.ruleApiService.createRule(ruleContent);
  }

  deleteRule(deleteRuleData: ChangeRuleStatusRequest): Observable<ResponseInfo> {
    return this.ruleApiService.deleteRule(deleteRuleData);
  }

  holdRule(holdRuleData: ChangeRuleStatusRequest): Observable<ResponseInfo> {
    return this.ruleApiService.holdRule(holdRuleData);
  }

  activateRule(activateRuleData: ChangeRuleStatusRequest): Observable<ResponseInfo> {
    return this.ruleApiService.activateRule(activateRuleData);
  }

  close(closeRuleData: RuleIdRequest): Observable<ResponseInfo> {
    return this.ruleApiService.close(closeRuleData);
  }

  approve(approveData: ApproveDeclineRequest): Observable<ResponseInfo> {
    return this.ruleApiService.approve(approveData);
  }

  decline(declineData: ApproveDeclineRequest): Observable<ResponseInfo> {
    return this.ruleApiService.decline(declineData);
  }

  verifyBaseCondition(office: string, ruleConditions: RuleCriteria): Observable<RulePayload> {
    return this.ruleApiService.verifyBaseCondition(office, ruleConditions);
  }

  retractForCreate(ruleIdRequest: RuleIdRequest): Observable<ResponseInfo> {
    return this.ruleApiService.retractForCreate(ruleIdRequest);
  }

  retract(retractRuleData: ApproveDeclineRequest): Observable<ResponseInfo> {
    return this.ruleApiService.retract(retractRuleData);
  }

  searchBaseCondition(uniqueRecId: string): Observable<SearchBaseConditionResponse> {
    return this.ruleApiService.searchBaseCondition(uniqueRecId);
  }

  baseConditionUsedProfiles(uniqueRecId: string): Observable<BaseConditionUsedProfilesResponse> {
    return this.ruleApiService.baseConditionUsedProfiles(uniqueRecId);
  }

  getAuditTrail(uid: string): Observable<AuditTrail> {
    return this.ruleApiService.getAuditTrail(uid);
  }

  private handleConditionBank(conditionBank: ConditionBank, recentlyUsedConditions: RecentConditions, typeId: string): void {
    const fieldsTree = this.handleFields(conditionBank, recentlyUsedConditions);
    const functionsTree = this.handleFunctions(conditionBank, recentlyUsedConditions, typeId);
    const baseConditionsTree = this.handleBaseConditions(conditionBank, recentlyUsedConditions);

    this.conditionsBank = { nodes: [fieldsTree, functionsTree] };

    if (baseConditionsTree.nodes.length > 0) {
      this.conditionsBank.nodes.push(baseConditionsTree);
    }

    if (recentlyUsedConditions &&
      (recentlyUsedConditions.fields && recentlyUsedConditions.fields.length > 0) ||
      (recentlyUsedConditions.functions && recentlyUsedConditions.functions.length > 0) ||
      (recentlyUsedConditions.baseConditions && recentlyUsedConditions.baseConditions.length > 0)) {
      this.handleFlatConditionsBank(conditionBank, recentlyUsedConditions);
    } else {
      //in case there are no recently Used Conditions
      this.flatConditionsBank = (conditionBank.fields as Array<ConditionsBankNode>).concat(conditionBank.functions.filter(this.removeDisabledItems))
        .concat(conditionBank.baseConditions.filter(this.removeDisabledItems));
    }

    this.sortFlatConditionsBank();
  }

  private mapSaveRule(rule: Rule): RulePayload {
    rule.RECENT_CONDITIONS = this.getJustUsedConditions();
    this.justUsedConditions.length = 0;
    return { contentHolder: rule };
  }

  private getJustUsedConditions(): RecentConditions {
    const result: RecentConditions = {
      class: 'RecentConditions',
      fields: [],
      functions: [],
      baseConditions: []
    };

    const conditionsBank = this.conditionsBank.nodes;

    if (conditionsBank.length > 0) {
      for (let i = 0; i < conditionsBank.length; i++) {
        if (conditionsBank[i].alias.toLowerCase() === 'base conditions') {
          this.handleConditions(conditionsBank[i].nodes, 'baseConditions', result);
        } else if (conditionsBank[i].alias.toLowerCase() === 'fields') {
          this.handleConditions(this.conditionBankFields, 'fields', result);
        } else {
          this.handleConditions(conditionsBank[i].nodes, 'functions', result);
        }
      }
    }

    return result;
  }

  private handleConditions(conditions: Array<ConditionsBankNode>, conditionType: string, result: RecentConditions): void {
    for (let i = 0; i < conditions.length; i++) {
      if (this.justUsedConditions.indexOf(conditions[i].id) > -1) {
        result[conditionType].push(conditions[i].id);
      }
    }
  }

  private handleRuleTypeDetails(data: RuleTypeDetails): void {
    if (data.typeId) {
      this.typeId = data.typeId;
    }

    if (data.conditionBank) {
      this.handleConditionBank(data.conditionBank, data.recentlyUsedConditions, data.typeId);
    }

    if (data.ruleAction) {
      this.actionDetails = data.ruleAction;
    }
    if (data.ruleSubType) {
      this.subType = data.ruleSubType;
    }

    if (data.bcallowed) {
      this.isBCAllowed = data.bcallowed;
    }

    if (data.execTypeBindWhere) {
      this.execTypeBindWhere = data.execTypeBindWhere;
    }

    if (data.operatorsPerDataType) {
      this.operatorsPerDataType = data.operatorsPerDataType;
    }

  }

  private sortFlatConditionsBank(): void {
    //sort nodes shouldn't delay the rule opening
    setTimeout(() => {
      const nodes = this.flatConditionsBank;
      if (Array.isArray(nodes) && nodes.length > 1) {
        nodes.sort(function (a, b) {
          const textA = a.alias.toUpperCase();
          const textB = b.alias.toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
      }
    }, 0);
  }

  private handleFields(conditionBank: ConditionBank, recentlyUsedConditions: RecentConditions): ConditionsBankCategory {
    if (conditionBank.fields && conditionBank.fields.length > 0) {
      this.conditionBankFields = conditionBank.fields;
    }
    const restrictedLogicalFields = Object.keys(this.bcRestrictedLogicalFields);
    const fieldsTree = this.ruleConditionsAlgorithmsService.buildTreeFromFlatArray(conditionBank.fields, '1', restrictedLogicalFields,
      recentlyUsedConditions.fields);

    fieldsTree.alias = this.translateService.instant('rules.conditions_bank.fields');
    fieldsTree.id = 1;
    return fieldsTree;
  }

  private handleFunctions(conditionBank: ConditionBank, recentlyUsedConditions: RecentConditions, typeId: string): ConditionsBankCategory {
    for (let i = 0; i < conditionBank.functions.length; i++) {
      conditionBank.functions[i].type = 'function';
      conditionBank.functions[i].parentId = 'fn';
      if (typeId !== '2' && conditionBank.functions[i].alias === 'GET_OFFICE_TIME_ADD_MINUTES') { //GPP-124706
        conditionBank.functions.splice(i, 1);
      }
      this.checkIfRecentlyUsedCondition(conditionBank.functions[i], recentlyUsedConditions.functions);
    }

    const result = {
      alias: this.translateService.instant('rules.conditions_bank.functions'),
      nodes: conditionBank.functions,
      id: 'fn'
    };

    return result;
  }

  private checkIfRecentlyUsedCondition(node: ConditionsBankNode, recentlyUsedConditions: Array<string>): void {
    if (recentlyUsedConditions && recentlyUsedConditions.indexOf(node.id) > -1) {
      node.isRecentlyUsed = true;
    }
  }

  private handleBaseConditions(conditionBank: ConditionBank, recentlyUsedConditions: RecentConditions): ConditionsBankCategory {
    for (let i = 0; i < conditionBank.baseConditions.length; i++) {
      this.baseConditions.push(conditionBank.baseConditions[i].alias);
      conditionBank.baseConditions[i].parentId = 'bc';
      this.checkIfRecentlyUsedCondition(conditionBank.baseConditions[i], recentlyUsedConditions.baseConditions);
    }

    const result = {
      alias: this.translateService.instant('rules.conditions_bank.base_conditions'),
      nodes: conditionBank.baseConditions,
      id: 'bc'
    };

    return result;
  }

  handleFlatConditionsBank(conditionBank: ConditionBank, recentlyUsedConditions: RecentConditions): void {

    const conditionTypes = Object.keys(conditionBank);
    const indexesRecentlyUsed = [];
    let recentlyUsed = [];
    let recentlyUsedConditionsList = [];
    let conditionBankConditionsList = [];

    const deleteRecentlyUsedFromConditionBank =
      (conditionBankGroup: Array<ConditionsBankNode>, indexesRecentlyUsed: Array<number>): Array<ConditionsBankNode> => {
        for (let i = 0; i < indexesRecentlyUsed.length; i++) {
          conditionBankGroup.splice(indexesRecentlyUsed[i] - i, 1);
        }
        return conditionBankGroup;
      };

    const concatToFlatConditionsBank = (conditionBankGroup: string, conditionsGroup: Array<ConditionsBankNode>, recentlyUsed: Array<string>): void => {
      if (conditionBankGroup === 'fields') {
        recentlyUsedConditionsList = JSON.parse(JSON.stringify(recentlyUsed));
        conditionBankConditionsList = JSON.parse(JSON.stringify(conditionsGroup));
      } else {
        recentlyUsedConditionsList = recentlyUsedConditionsList.concat(JSON.parse(JSON.stringify(recentlyUsed)).filter(this.removeDisabledItems));
        conditionBankConditionsList = conditionBankConditionsList.concat(JSON.parse(JSON.stringify(conditionsGroup)).filter(this.removeDisabledItems));
      }
    };

    const orderRecentlyUsedConditions =
      (recentlyUsedIdArray: Array<string>, recentlyUsedObjectsArray: Array<ConditionsBankNode>): Array<ConditionsBankNode> => {
        const result = [];
        for (let i = 0; i < recentlyUsedIdArray.length; i++) {
          for (let j = 0; j < recentlyUsedObjectsArray.length; j++) {
            if (recentlyUsedObjectsArray[j].id === recentlyUsedIdArray[i]) {
              result.push(recentlyUsedObjectsArray[j]);
              break;
            }
          }
        }
        return result;
      };

    const getRecentlyUsedConditionsFromConditionBank = (recentlyUsedConditions: Array<string>, conditionBank: Array<ConditionsBankNode>): void => {
      for (let i = 0; i < conditionBank.length; i++) {
        const index = recentlyUsedConditions.indexOf(conditionBank[i].id);
        if (index > -1) {
          conditionBank[i].isRecentlyUsed = true;
          recentlyUsed.push(conditionBank[i]);
          indexesRecentlyUsed.push(i);
        }
      }
    };

    const buildFlatConditionsBank = (conditionType: string): void => {
      getRecentlyUsedConditionsFromConditionBank(recentlyUsedConditions[conditionType], conditionBank[conditionType]);
      recentlyUsed = orderRecentlyUsedConditions(recentlyUsedConditions[conditionType], recentlyUsed);
      const conditionsGroup = deleteRecentlyUsedFromConditionBank(JSON.parse(JSON.stringify(conditionBank[conditionType])), indexesRecentlyUsed);
      concatToFlatConditionsBank(conditionType, conditionsGroup, recentlyUsed);
      recentlyUsed.length = 0;
      indexesRecentlyUsed.length = 0;
    };

    for (let i = 0; i < conditionTypes.length; i++) {
      if (conditionBank[conditionTypes[i]].length > 0) {
        buildFlatConditionsBank(conditionTypes[i]);
      }
    }

    this.flatConditionsBank = recentlyUsedConditionsList.concat(conditionBankConditionsList);

  }

  doGetRule(ruleId: string, loadType: string): Observable<Rule> {
    return this.getRule(ruleId, loadType).pipe(mergeMap((rule: Rule) => {
      return this.getRuleTypeDetails(rule.RULE_TYPE_ID, rule.OFFICE).pipe(map((ruleTypeDetails: RuleTypeDetails) => {
        rule.actionDetails = ruleTypeDetails.ruleAction;
        rule.subType = ruleTypeDetails.ruleSubType;
        if (rule.EFFECTIVE_DATE == '' && ruleTypeDetails.businessDate) {
          rule.EFFECTIVE_DATE = ruleTypeDetails.businessDate;
        }
        this.convertOperandsToObjects(this.ruleConditionsService.conditions);
        return rule;
      }));
    }));
  }

  private convertOperandsToObjects(rule: ComplexCondition): void {
    for (let i = 0; i < rule.conditions.length; i++) {

      if ((rule.conditions[i] as ComplexCondition).conditions != null) {
        this.convertOperandsToObjects(rule.conditions[i] as ComplexCondition);
      }

      if ((rule.conditions[i] as SimpleCondition).innerConditions != null) {
        for (let j = 0; j < (rule.conditions[i] as SimpleCondition).innerConditions.length; j++) {
          this.makeObject((rule.conditions[i] as SimpleCondition).innerConditions[j]);
        }
      }
      this.makeObject(rule.conditions[i] as SimpleCondition);
    }
  }

  private makeObject(condition: SimpleCondition): void {
    if (condition.leftOperand != null) {
      condition.leftOperand = this.turnIntoObject(condition.leftOperand);
    }

    if (condition.rightOperand != null) {
      if ((condition.rightOperand as any).length > 1) { //right operand is an array
        condition.rightOperand = this.turnIntoObject(condition.rightOperand, condition.rightOperandType);
      } else {
        condition.rightOperand = this.turnIntoObject(condition.rightOperand[0], condition.rightOperandType);
      }
    }
  }

  private turnIntoObject(operand: string | Array<string> | ConditionOperand, operandType?: ContentType): ConditionOperand {
    let operandAsString = '';

    if (operand instanceof Array) {
      return this.doOperandObjectFromArray(operand, operandType);
    }

    operandAsString = operand as string;

    operand = (this.getFieldAsObject(operand as string) || operand) as string | ConditionOperand;
    if (typeof operand === 'string') {
      return this.doOperandObjectFromString(operand, operandType);
    } else if (operand.type === 'function') {
      return this.doOperandObjectFromFunction(operand as ConditionFunction, operandAsString);
    }

    return operand;
  }

  private doOperandObjectFromArray(operand: Array<string>, operandType: ContentType): DefaultOperand {
    let operandAsString = '';
    for (let j = 0; j < operand.length; j++) {
      operandAsString += operand[j] + ', ';
    }

    return {
      type: operandType || 'InList',
      alias: operandAsString.slice(0, -2)
    };
  }

  private doOperandObjectFromString(operand: string, operandType: ContentType): DefaultOperand {
    return {
      type: operandType || ContentType.VALUE,
      alias: operand
    };
  }

  doOperandObjectFromFunction(operand: ConditionFunction, operandAsString: string): ConditionFunction {
    operand.alias = operandAsString;
    const functionName = operandAsString.split('(')[0];
    const functionArgsStr = operandAsString.length > functionName.length ? operandAsString.substring(functionName.length + 1, operandAsString.length - 1) : '';
    if (functionArgsStr.length < 1) {
      return operand;
    }
    const functionArgs = functionArgsStr.split(',');
    for (let i = 0; i < functionArgs.length; i++) {
      functionArgs[i] = this.ruleConditionsAlgorithmsService.getArgumentAliasFromId(functionArgs[i]);
      operand.functionArguments[i].value = functionArgs[i];
    }

    operand.alias = functionName + '(' + functionArgs + ')';
    return operand;
  }

  private prepareRuleConditions(ruleConditions: RuleCriteria): ComplexCondition {
    let conditions = ruleConditions;
    if ((conditions as ComplexConditionPayload).logicalOperator == null) {
      if ((conditions as SimpleConditionPayload).conditionOperator == null) {
        if (!(conditions && (conditions as MultipleConditionPayload).conditionsList)) {
          // rule without conditions which is also not concatenate/operators
          conditions = null;
        }
      } else {
        //rule with one conditions
        const newRule = {} as ComplexConditionPayload;
        newRule.leftOperand = conditions as SimpleConditionPayload;
        conditions = newRule;
      }
    }
    return this.ruleConditionsAlgorithmsService.fromBinaryTreeToNTree(JSON.parse(JSON.stringify(conditions)));
  }

  private removeDisabledItems(node: ConditionsBankNode): boolean {
    return node.available;
  }

  private setFieldAsString(fieldAsString: string): FieldAsString {
    let foundColonExtractMode = false;

    if (fieldAsString.startsWith(':')) { //colon EXTRACT mode ,just colon :
      fieldAsString = fieldAsString.slice(1);
      foundColonExtractMode = true;
    } else if (fieldAsString.startsWith('[:')) { //colon EXTRACT mode server [:
      fieldAsString = fieldAsString.slice(2, -1);
      foundColonExtractMode = true;
    } else if (fieldAsString.startsWith('[')) { //Logical field
      fieldAsString = fieldAsString.slice(1, -1);
    } else if (fieldAsString.charAt(fieldAsString.length - 1) === ')') { //Function (endsWith doesn't supported in IE11)
      fieldAsString = fieldAsString.split('(')[0];
    }

    return {
      foundColonExtractMode, fieldAsString
    };
  }

  private setFieldObject(foundColonExtractMode: boolean, fieldAsString: string): ConditionsBankNode {
    const conditionsBank = this.flatConditionsBank;

    let fieldObject;
    for (let i = 0; i < conditionsBank.length; i++) {
      if ((!foundColonExtractMode || foundColonExtractMode && (conditionsBank[i] as ConditionFunction).type !== 'function') &&
        fieldAsString.toLowerCase() === conditionsBank[i].alias.toLowerCase() ||
        (conditionsBank[i].id != null &&
          fieldAsString.toLowerCase() === conditionsBank[i].id.toLowerCase())) {
        fieldObject = conditionsBank[i];
        break;
      }
    }
    return fieldObject;
  }

  getFieldAsObject(inputFieldAsString: string): ConditionsBankNode | string {
    if (inputFieldAsString) {
      const { foundColonExtractMode, fieldAsString } = this.setFieldAsString(inputFieldAsString.trim());

      //at this point all extra decorators : [: [ (  are removed, and we are
      //left with just the bare alias key, which we use as the look up value into the conditionsBank
      const fieldObject = this.setFieldObject(foundColonExtractMode, fieldAsString);

      const fieldObjectCopy = fieldObject ? JSON.parse(JSON.stringify(fieldObject)) : fieldObject;

      if (fieldObjectCopy && foundColonExtractMode) {
        //here we add the : to the copy of the  fieldObject from the  conditionsBank
        fieldObjectCopy.alias = ':' + fieldObjectCopy.alias;
        fieldObjectCopy.id = ':' + fieldObjectCopy.id;
      }

      return fieldObjectCopy;
    }

    return inputFieldAsString;
  }
}
