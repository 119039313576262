import { NgModule } from '@angular/core';
import { NgIdleModule } from '@ng-idle/core';
import { TranslateModule } from '@ngx-translate/core';
import { FnUiModalModule } from '../../shared/fn-ui-modals/fn-ui-modal.module';

import { IdleTimeoutWarningComponent } from './components/idle-timeout-warning/idle-timeout-warning.component';

import { IdleConfigService } from './services/idle-config.service';
import { IdleModalsService } from './services/idle-modals.service';
import { IdleService } from './services/idle.service';


@NgModule({
    imports: [
        NgIdleModule.forRoot(),
        TranslateModule.forChild(),
        FnUiModalModule
    ],
    declarations: [
        IdleTimeoutWarningComponent
    ],
    providers: [
        IdleConfigService,
        IdleModalsService,
        IdleService
    ]
})
export class IdleModule { }
