import { FormatsService } from '../shared/services/formats.service';
import { Formats } from '../shared/models/formats.model';
import dayjs from 'dayjs';

export class FtDate {
    formatSuffix = '';

    constructor(private formatsService: FormatsService) { }

    format(value: any, customFormat?: string, isUtc?: boolean): string {
        if (!value) {
            return '';
        }

        const formatObj: Formats = this.formatsService.formats;

        if (formatObj.dateFormat === '') {
            return value.toLocaleString();
        }

        let dateFormat = formatObj.dateFormat.toUpperCase();

        if (customFormat) {
            dateFormat = customFormat;
        } else {
            dateFormat += ' ' + this.formatSuffix;
        }

        const dateObject = this.formatsService.parseDate(value);

        if (typeof dateObject === 'object') {
          return dayjs(dateObject).format(dateFormat);
        }

        if (!isNaN(dateObject)) { //unix timestamp
          if (dateObject.toString().length === 13) {
            //unix timestamp could be in milliseconds (13 chars) or seconds (10 chars), it's being normalized to 10 seconds
            value = value / 1000;
          }
          if (isUtc) {
            return dayjs.unix(value).utc().format(dateFormat);
          }
          return dayjs.unix(value).format(dateFormat);
        }

        return dateObject;
    }

}
