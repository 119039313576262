import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

import { FnUiButtonComponent } from './fn-ui-button.component';


@NgModule({
  imports: [
    CommonModule,
    MatButtonModule
  ],
  declarations: [
    FnUiButtonComponent
  ],
  exports: [
    FnUiButtonComponent
  ]
})
export class FnUiButtonModule { }
