import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, catchError, map, throwError } from 'rxjs';
import { GridSearchRequest } from '../../../grid/models/grid-search-request.model';


@Injectable()
export class ExportApi {

  constructor(private httpClient: HttpClient) { }

  exportToHtml( searchRequest: GridSearchRequest, baseUrl: string, httpParams?: HttpParams): Observable<string> {
    const headers: HttpHeaders = new HttpHeaders({ 'Accept': 'text/html' });
    const url = `${baseUrl}exportToHtml`;
    return this.httpClient.post(url, searchRequest,
      { headers: headers, params: httpParams, responseType: 'text' }).pipe(
        map((response: string) => response),
        catchError((error: HttpErrorResponse) => throwError(error)));
  }

}
