import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Subscription } from 'rxjs';
import { IPowerBINavigationService } from './interfaces/power-bi-navigation-service.interface';
import { PowerBIApiService } from './power-bi-api.service';
import { InsightNavigationService } from '../../services/insight-navigation.service';
import { TaskBarService } from '../../../core/navigation/task-bar/services/task-bar.service';
import { SitemapNode } from '../../../core/navigation/sitemap/models';
import { PowerBIReportMetadata } from '../models/power-bi-report-metadata.model';
import { PowerBIReportsResponse } from '../models/power-bi-reports-response.model';

@Injectable()
export class PowerBINavigationService implements IPowerBINavigationService {

  private _taskBar: SitemapNode;
  private _subscriber: Subscription;
  private reportsLastState: any
  private readonly reportsLastStateKey = 'pbiReportsLastState'

  get taskBar(): SitemapNode {
    return this._taskBar;
  }

  set taskBar(value: SitemapNode) {
    this._taskBar = value;
  }

  constructor(private powerBIApiService: PowerBIApiService,
              private insightNavigationService: InsightNavigationService,
              private taskBarService: TaskBarService) {
    this.reportsLastState = {};
  }

  getTaskBar(taskBarId: string): SitemapNode {
    this._taskBar = { ...this.insightNavigationService.getTaskBar(taskBarId) } as SitemapNode<PowerBIReportMetadata>;
    return this._taskBar;
  }

  getNodes(taskBarId: string): Promise<Array<SitemapNode<PowerBIReportMetadata>>> {
    return new Promise((resolve, reject) => {
      this.taskBarService.emitLoading(true);
      this._subscriber = this.powerBIApiService.getReports(taskBarId).pipe(
        map((response: PowerBIReportsResponse) => {
          return this.map(response, taskBarId);
        })
      ).subscribe({
        next: (nodes: Array<SitemapNode<PowerBIReportMetadata>>) => {
          resolve(nodes);
        },
        error: (error: HttpErrorResponse) => {
          console.log(error);
          resolve(null);
        }
      });
    });
  }

  finalizeNodeRequest() {
    this._subscriber = null;
    this.taskBarService.emitLoading(false);
  }

  cancelNodesRequest(): void {
    if (this._subscriber) {
      this._subscriber.unsubscribe();
      this.finalizeNodeRequest();
    }
  }

  getReportMetadata(id: string): PowerBIReportMetadata {
    return this._taskBar?.nodes?.find((node: SitemapNode) => node.id === id)?.data as PowerBIReportMetadata;
  }

  updateTaskBar(updatedNodes: Array<PowerBIReportMetadata>): void {
    this._taskBar?.nodes.forEach((node: SitemapNode<PowerBIReportMetadata>) => {
      const newMetadata = updatedNodes?.find(updatedNode => updatedNode.id === node.id) as PowerBIReportMetadata;
      if (!!newMetadata) {
        node.data.embedToken = newMetadata.embedToken;
        node.data.tokenExpiration = newMetadata.tokenExpiration;
      }
    });
  }

  private map(response: PowerBIReportsResponse, taskBarId: string): Array<SitemapNode<PowerBIReportMetadata>> {
    return response.responseMsg.value?.map((report: PowerBIReportMetadata) => {
      return {
        id: report.id,
        alias: report.name,
        icon: null,
        data: report as PowerBIReportMetadata,
        nodes: [],
        routerLink: `/home/insight/${taskBarId}`,
        stateName: 'home.insight.taskbar.pbi.report',
        stateParams: {
          report: report.id
        }
      } as SitemapNode<PowerBIReportMetadata>;
    });
  }

  public setReportLastState(reportId: string, state: string) {
    this.reportsLastState[reportId] = state;
    sessionStorage.setItem(this.reportsLastStateKey, JSON.stringify(this.reportsLastState));
  }

  public getReportLastState(reportId: string): string | undefined {
    const reportsState = sessionStorage.getItem(this.reportsLastStateKey);
    if (reportsState) {
      const parsedState = JSON.parse(reportsState);
      return parsedState[reportId];
    }
    return undefined;
  }

  clearLastState() {
    sessionStorage.removeItem(this.reportsLastStateKey);
  }
}
