import { InjectionToken, Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { SearchType } from '../../../core/navigation-search/models';
import { Search } from '../../../core/navigation-search/models/search.model';
import { GridSearchQuery } from '../../../grid/models/grid-search-query.model';
import { Entity } from '../../../shared/models/enums/entity.enum';
import { Operators } from '../../../shared/search/models/enums/operators.enum';
import { RecentSearchResponse } from '../models/recent-search-response.model';
import { RecentSearch } from '../models/recent-search.model';
import { RecentSearchesTree } from '../models/recent-searches-tree.model';
import { IEntityRecentSearchesService } from './interfaces/entity-recent-searches-service.interface';
import { RecentSearchMapperService } from './recent-search-mapper.service';
import { RecentSearchesApiService } from './recent-searches-api.service';
import { RecentSearchesTreeBuilderService } from './recent-searches-tree-builder.service';

export function EntityRecentSearchesFactory(entity: Entity) {
  return (recentSearchesApiService, recentSearchMapperService, recentSearchesTreeBuilderService) => {
    return new EntityRecentSearchesService(entity, recentSearchesApiService, recentSearchMapperService, recentSearchesTreeBuilderService);
  };
}

export const ENTITY_RECENT_SEARCHES_TOKEN = new InjectionToken<Array<IEntityRecentSearchesService>>('entity-recent-searches');
export const ENTITY_RECENT_SEARCHES_DEPS = [RecentSearchesApiService, RecentSearchMapperService, RecentSearchesTreeBuilderService];

@Injectable()
export class EntityRecentSearchesService implements IEntityRecentSearchesService {

  get entity(): Entity {
    return this._entity;
  }

  get recentSearches(): Array<RecentSearch> {
    return this._recentSearches;
  }

  get tree(): RecentSearchesTree {
    return this._tree;
  }

  private _recentSearches: Array<RecentSearch>;
  private _tree: RecentSearchesTree;

  constructor(private _entity: Entity,
              private recentSearchesApiService: RecentSearchesApiService,
              private recentSearchMapperService: RecentSearchMapperService,
              private recentSearchesTreeBuilderService: RecentSearchesTreeBuilderService) { }

  getAll(): Observable<Array<RecentSearch>> {
    return this.recentSearchesApiService.getAll(this.entity).pipe(map((response: Array<RecentSearchResponse>) => {
      this._recentSearches = this.recentSearchMapperService.mapAll(response, this.entity);
      return this.recentSearches;
    }));
  }

  getTree(): Observable<RecentSearchesTree> {
    return this.getAll().pipe(map((recentSearches: Array<RecentSearch>) => {
      this._tree = this.recentSearchesTreeBuilderService.buildTree(recentSearches, this.entity);
      return this.tree;
    }));
  }

  refreshTree(): Observable<RecentSearchesTree> {
    if (!this.tree) {
      return this.getTree();
    }
    return this.getAll().pipe(map((recentSearches: Array<RecentSearch>) => {
      this.tree.recentSearches = this.recentSearchesTreeBuilderService.buildTreeNodes(recentSearches, this.entity);
      return this.tree;
    }));
  }

  add(search: Search): Observable<string> {
    const searchQuery = this.createSearchQuery(search);
    if (!searchQuery) {
      return null;
    }
    return this.recentSearchesApiService.add(searchQuery, this.entity).pipe(map((response: number) => {
      return response.toString();
    }));
  }

  delete(recentSearchId: string): Observable<void> {
    return this.recentSearchesApiService.delete(+recentSearchId, this.entity);
  }

  resetTree(): void {
    this._tree = null;
  }

  private createSearchQuery(search: Search): GridSearchQuery {
    switch (search?.type) {
      case SearchType.QUICK_SEARCH:
        return this.createQuickSearchQuery(search.data);
      case SearchType.ADVANCED_SEARCH:
        return this.createAdvancedSearchQuery(search.data);
      default:
        return null;
    }
  }

  private createQuickSearchQuery(data: string): GridSearchQuery {
    if (!data.endsWith('*')) {
      data += '*';
    }
    return {
      additionalParameters: {},
      searchCriteria: {
        class: 'SearchCondition',
        leftOperand: 'quick_search',
        not: false,
        operator: Operators.startsWith,
        rightOperand: [data]
      }
    };
  }

  private createAdvancedSearchQuery(data: { [key: string]: string | number | boolean }): GridSearchQuery {

    const additionalParameters: { [key: string]: string | number | boolean } = {};

    if (data.hasOwnProperty('filterXmlString')) {
      additionalParameters.ADVANCED_SEARCH_FILTER = data.filterXmlString;
    }
    if (data.hasOwnProperty('ACCOUNT_POSITION')) {
      additionalParameters.ACCOUNT_POSITION = data.ACCOUNT_POSITION;
    }
    if (data.hasOwnProperty('LIMITS_ACCUMULATED_AMOUNT')) {
      additionalParameters.LIMITS_ACCUMULATED_AMOUNT = data.LIMITS_ACCUMULATED_AMOUNT;
    }
    if (data.hasOwnProperty('EXPOSURE_ACCUMULATED_AMOUNT')) {
      additionalParameters.EXPOSURE_ACCUMULATED_AMOUNT = data.EXPOSURE_ACCUMULATED_AMOUNT;
    }
    if (typeof data.SEARCH_IN_ARCHIVE === 'boolean') {
      additionalParameters.SEARCH_IN_ARCHIVE = data.SEARCH_IN_ARCHIVE;
    }

    const searchCriteria = {
      class: 'SearchCondition',
      leftOperand: '_all',
      not: false,
      operator: Operators.contains,
      rightOperand: ['*']
    };

    return { additionalParameters, searchCriteria };
  }

}
