import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { SitemapNode, BaseNodeData } from '../../core/navigation/sitemap/models';
import { PowerBINavigationService } from '../power-bi/services/power-bi-navigation.service';
import { InsightNavigationService } from './insight-navigation.service';

@Injectable()
export class InsightTaskbarService {

  private _taskBarMap = {};

  constructor(private insightNavigationService: InsightNavigationService,
              private powerBINavigationService: PowerBINavigationService) { }

  getGuardsAndResolversTaskbar(route: ActivatedRouteSnapshot): SitemapNode<BaseNodeData> {
    const taskBarItem = route.paramMap.get('taskBarItem');
    if (!!this._taskBarMap[taskBarItem]) {
      if (this.insightNavigationService.isPbi(taskBarItem)) {
        this.powerBINavigationService.taskBar = this._taskBarMap[taskBarItem];
      }
      return this._taskBarMap[taskBarItem];
    }

    this._taskBarMap[taskBarItem] = this.insightNavigationService.isPbi(taskBarItem) ?
      this.powerBINavigationService.getTaskBar(taskBarItem) : this.insightNavigationService.getTaskBar(taskBarItem);

    return this._taskBarMap[taskBarItem];
  }
}
