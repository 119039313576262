<h1 mat-dialog-title>
  <div class="title-wrapper">
    <div class="title" translate>
      {{dialogConfig.title}}
    </div>
  </div>
</h1>
<div mat-dialog-content>
    <div class="body form-content">
        <div class="container">
            <form [formGroup]="form">
                <gpp-ui-form-canvas [classList]="'form-root'" [form]="form" [userPreferences]="userPreferences"
                                     [controlsMetadata]="formMetadata"></gpp-ui-form-canvas>
            </form>
        </div>
    </div>
</div>
<div mat-dialog-actions>
    <div class="footer"  *ngIf="formReady">
        <gpp-ui-button azure-id="cancel" class="button" [form]="form" [controlData]="cancelBtnConfig"
                       (buttonClicked)="onCancelClick()"></gpp-ui-button>
        <gpp-ui-button azure-id="ok" class="button" [form]="form" [controlData]="okBtnConfig"
                       [disabled]="this.form.invalid" (buttonClicked)="onOkClick()"></gpp-ui-button>
    </div>
</div>
