import {Component, Injector} from '@angular/core';
import {AbstractFormComponent} from '../../../core/abstract/abstract-form/abstract-form.component';
import {BFEvents, ModalSize, TabChangeEvent} from '@fgpp-ui/components';
import {catchError, forkJoin, Observable, of, throwError} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {EMetadataCategory} from '../../../core/enums/metadata-category.enum';
import {HttpErrorResponse} from '@angular/common/http';
import {IDynamicModalDialogData} from '../../../core/interfaces/dynamic-modal-dialog-data.interface';
import {SaveChangesLayout} from '../../modals/save-changes-modal/save-changes.layout';
import {SaveChangesContract} from '../../modals/save-changes-modal/save-changes.contract';
import {IEndpoint} from '../../../core/interfaces/endpoint.interface';
import {ProfileLoadType} from '../../enums/profile-load-type.enum';
import {BfPopoutService} from '../../../core/services/bf-popout-service';
import {ProfileActions} from '../../enums/profile-actions.enum';
import {Base64} from 'js-base64';
import {IProfileLayoutSelectionMetadata} from '../../interfaces/profile-layout-selection-metadata.interface';
import {IProfileLayoutInfo} from '../../interfaces/profile-layout-info.interface';
import {ProfileMode} from '../../enums/profile-mode.enum';
import {ISdkProfileFooterReducer} from '../../interfaces/profile-footer-reducer.interface';
import {StandardProfileReducer} from '../../utils/standard-profile.reducer';
import {EmptyProfileReducer} from '../../utils/empty-profile.reducer';
import {IProfileFooter} from '../../interfaces/profile-footer.interface';
import {ButtonCategory} from '../../../core/enums/button-category.enum';
import {IProfileLayoutTypes} from '../../interfaces/profile-layout-type.interface';
import {ProfileRecordStatus} from '../../enums/profile-record-status.enum';
import {ProfileLayoutType} from '../../enums/profile-layout-type.enum';
import {ProfileButtons} from '../../enums/profile-buttons.enum';
import {ProfileAuditTrailComponent} from '../../../core/components/profile-audit-trail/profile-audit-trail.component';
import {OfficeSelectorService} from '../../../../core/services/office-selector.service';
import {IBffResponse} from '../../../core/interfaces/bff-response.interface';
import {ProfileActionErrorHandlerService} from '../../services/profile-action-error-handler.service';
import {getEffectiveDateDialogLayout} from '../../modals/effective-date-modal/effective-date-modal.layout';
import {getEffectiveDateDialogContract} from '../../modals/effective-date-modal/effective-date-modal.contract';
import {RecordLockedLayout} from '../../modals/record-locked-modal/record-locked.layout';
import {RecordLockedContract} from '../../modals/record-locked-modal/record-locked.contract';
import {ComponentType} from '@angular/cdk/portal';
import {DialogFileAttachmentConfig} from '../../../../shared/attachments/file-attachment-dialog/models/dialog-file-attachment-config.model';
import {
  FileAttachmentPermissionMode
} from '../../../../../../../attachment/src/lib/file-attachment-manager/models/enums/file-attachment-permission-mode.enum';
import {AttachmentPermissions} from '../../../core/enums/attachment-permissions-enum';
import {FileAttachmentMapping} from '../../../../shared/attachments/file-attachment-dialog/models/file-attachment-mapping.model';
import {FileAttachmentDialogService} from '../../../../shared/attachments/file-attachment-dialog/services/file-attachment-dialog.service';
import {ProfilesManagementService} from '../../../../profiles/services/profiles-management.service';
import {BfNewTabService} from '../../../core/services/bf-new-tab.service';
import {NavigationItem} from '../../../../core/navigation/models';
import {ProfileNodeData} from '../../../../core/navigation/sitemap/models';
import DialogEvent = BFEvents.DialogEvent;
import {
  FileAttachmentDialogOutput
} from "../../../../shared/attachments/file-attachment-dialog/models/file-attachment-dialog-output.model";
import {IValueChange} from "../../../core/interfaces/form-value-change.interface";
import dayjs from 'dayjs';
import {IData} from "../../../core/interfaces/data.interface";
import {ProfileComponentLoader} from "../../../config/profile-component-loader";

@Component({
  template: ''
})
export abstract class AbstractProfileFormComponent extends AbstractFormComponent {

  protected taskCode: string;
  protected profileId: string;
  protected loadType: ProfileLoadType;
  protected uid;
  protected navigationCategory: EMetadataCategory = EMetadataCategory.PROFILES;
  protected popout: BfPopoutService;
  protected newTabService: BfNewTabService;
  protected locale: string;
  protected mode: ProfileMode;
  protected navigationMapping: string;
  private hasAttachments: boolean;
  private layouts: IProfileLayoutTypes;
  protected readonly footerPropertyName = 'PROFILE-FOOTER';
  protected readonly pagePropertyName = 'PROFILES_PAGE';
  protected readonly attachmentButtonId = 'ATTACHMENTS-BUTTON';
  private StandardReducer: ISdkProfileFooterReducer = new StandardProfileReducer();
  private CustomReducer: ISdkProfileFooterReducer = new EmptyProfileReducer();
  protected layoutMode: ProfileLayoutType;
  protected officeSelector: OfficeSelectorService;
  protected profileActionErrorHandlerService: ProfileActionErrorHandlerService;
  protected isSaveAs: boolean;
  private _profileActionMessage = new Map<ProfileButtons, { key: string, params?: Object }>();
  protected readonly attachmentControlName = 'ATTACHMENTS';
  protected attachmentPermission: AttachmentPermissions;
  protected attachmentList?: Array<FileAttachmentMapping>;
  protected fileAttachmentDialogService?: FileAttachmentDialogService;
  protected profileService: ProfilesManagementService;
  private readonly effectiveDateDialog = 'EFFECTIVE_DATE_DIALOG';
  private closedByGuard: boolean;
  private saveAsDataSource: any = null;

  private coerceValue(value: string | boolean): boolean {
    if (typeof value === 'string') {
      return value.toLowerCase() === 'true';
    } else if (typeof value === 'boolean') {
      return value;
    }
    return false;
  }

  constructor(protected injector: Injector) {
    super(injector);
    this.stateParams = this.route.snapshot.queryParams;
    this.popout = this.injector.get(BfPopoutService);
    this.officeSelector = injector.get(OfficeSelectorService);
    this.profileActionErrorHandlerService = injector.get(ProfileActionErrorHandlerService);
    this.fileAttachmentDialogService = injector.get(FileAttachmentDialogService);
    this.newTabService = injector.get(BfNewTabService);
    this.profileService = injector.get(ProfilesManagementService);
    this.domain = 'bff';
  }

  protected getProfileId(profileTreeItemId: string) {
    return profileTreeItemId;
  }

  protected setProfileActionSuccessMessage(button: ProfileButtons, message: { key: string, params?: Object }) {
    this._profileActionMessage.set(button, message);
  }

  protected setStandardReducer(Reducer: ISdkProfileFooterReducer): void {
    this.StandardReducer = Reducer;
  }

  protected beforeOnInit() {
    this.profileId = this.profileService.getProfileInfo(this.profileService.profileId || this.stateParams.profile).profileId;
    this.setFormRenderingStatus(false);
    this.loadType = +this.stateParams.loadType;
    this.taskCode = this.stateParams.taskCode;
    const key = this.taskCode ? `task-${this.taskCode}` : this.profileId;
    const sitemap = this.navigationService.getNavigationItem(key);
    this.navigationCategory = this.taskCode ? EMetadataCategory.TASKS : EMetadataCategory.PROFILES;
    this.navigationMapping = sitemap.data.mapping || this.profileId;
    this.navigationTypeId = this.profileId;
    this.uid = this.stateParams.uniqueRecId;
    this.locale = this.userPreferences.language;
    this.office = this.stateParams.office;
    this.mode = this.stateParams.mode;
    this.initToastMessages();
  }

  private initToastMessages(): void {
    const key = 'business-framework.profiles.result';
    const params = {alias: this.translate.instant(`business-framework.profiles.common.single-name.${this.profileId}`)};
    this._profileActionMessage.set(ProfileButtons.ACTIVATE, {key: `${key}.action_success`});
    this._profileActionMessage.set(ProfileButtons.APPROVE, {key: `${key}.action_success`});
    this._profileActionMessage.set(ProfileButtons.CREATE, {key: `${key}.save_success`, params});
    this._profileActionMessage.set(ProfileButtons.DECLINE, {key: `${key}.action_success`});
    this._profileActionMessage.set(ProfileButtons.DELETE, {key: `${key}.delete_success`, params});
    this._profileActionMessage.set(ProfileButtons.HOLD, {key: `${key}.hold_success`, params});
    this._profileActionMessage.set(ProfileButtons.RETRACT, {key: `${key}.action_success`});
    this._profileActionMessage.set(ProfileButtons.SAVE, {key: `${key}.save_success`, params});
  }


  protected createForm(data?: any): void {
    const contract$ = this.getCachedObservableFromEndpoint(this.endpoints['contract']);
    const layout$ = this.getCachedObservableFromEndpoint(this.endpoints['layout']);
    const dataEndpoint = this.resolveProfileEndpoint(ProfileActions.DATA, this.uid, {loadType: this.loadType});
    const defaultDataEndpoint = this.resolveProfileEndpoint(ProfileActions.NEW, null, {office: this.office || '', taskCode: this.taskCode});
    const data$ = data ? of(data) : this.getObservableFromEndpoint(this.uid ? dataEndpoint : defaultDataEndpoint);
    const l10nEndpoint = this.resolveProfileEndpoint(ProfileActions.L10N, null);
    const l10n$ = this.isL10NDataAvailable() ? of({}) : this.getCachedObservableFromEndpoint(l10nEndpoint);
    const sub = forkJoin([contract$, layout$, data$, l10n$]).pipe(map((response) => {
      return {contract: response[0], layout: response[1], data: response[2], locale: response[3]};
    })).subscribe({
      next: (res) => {
        this.setContract(res.contract);
        this.setOriginalData(res.data);
        this.setData(res.data);
        this.setL10NData(this.locale, res.locale);
        this.layouts = res.layout;
        this.doLayoutSelection();
        this.doDynamicFormCreate();
        if (this.mode !== ProfileMode.CREATE) {
          this.office = res.data[this.getKeyNameEndsWith('-OFFICE', this.getData())] || '';
        }
      },
      error: err => {
        const url = err.url || '';
        if (url.indexOf(this.endpoints['contract'].endpoint) !== -1) {
          this.notificationService.error(this.translate.instant('business-framework.errors.unable-to-get-contract'));
        } else if (url.indexOf(this.endpoints['layout'].endpoint) !== -1) {
          this.notificationService.error(this.translate.instant('business-framework.errors.unable-to-get-layout'));
        } else if (url.indexOf(this.endpoints['permissionsAndDefaults']?.endpoint) !== -1) {
          this.notificationService.error(this.translate.instant('business-framework.errors.unable-to-get-defaults'));
        } else if (url.indexOf(this.endpoints['data'].endpoint) !== -1) {
          this.notificationService.error(this.translate.instant('business-framework.errors.unable-to-get-data'));
        }
        this.returnToPreviousRoute();
      }
    });
    this.subscription.add(sub);

  }

  protected buttonClickHandler(actionPayload: BFEvents.ButtonEvent): void {
    const data = this.getData();
    if(actionPayload.name === 'buttonClick'){
      if (actionPayload.target === 'SAVE') {
        const payload = actionPayload;
        payload.target = data['VIRTUAL-UID'] ? actionPayload.target : ProfileButtons.CREATE;
        this.onSaveProfile(payload);
      } else if (actionPayload.target === 'CLOSE') {
        this.onCloseProfile();
      } else if (actionPayload.target === ProfileButtons.HOLD || actionPayload.target === ProfileButtons.DELETE ||
        actionPayload.target === ProfileButtons.RETRACT || actionPayload.target === ProfileButtons.ACTIVATE) {
        this.onProfileStatusChange(actionPayload);
      } else if (actionPayload.target === ProfileButtons.APPROVE) {
        this.doServerAction(actionPayload, this.getToastMessage(ProfileButtons.APPROVE));
      } else if (actionPayload.target === ProfileButtons.DECLINE) {
        this.onDeclineProfile(actionPayload);
      } else if (actionPayload.target === ProfileButtons.UNDO) {
        this.onUndoProfile();
      } else if (actionPayload.target === ProfileButtons.AUDIT) {
        const auditTailEndpoint = this.resolveProfileEndpoint(ProfileActions.AUDIT, this.uid).endpoint;
        this.openDialog(ProfileAuditTrailComponent, auditTailEndpoint);
      } else if (actionPayload.target === ProfileButtons.SAVEAS) {
        this.officeSelector.open(true).then(this.onOfficeSelection.bind(this));
      } else if (actionPayload.target === ProfileButtons.ATTACHMENTS) {
        this.handleAttachmentsButtonClick();
      } else if (actionPayload.target === 'PROFILES_PAGE_TOOLBAR:relatedEntities') {
        const relatedEntityData = actionPayload.value.action;
        const rawData = relatedEntityData.isCreate && relatedEntityData.createFilter ? this.populateCreateParams(relatedEntityData, this.formMgr.getValue()) : {};
        const params = this.populateParams(relatedEntityData, rawData);
        if (ProfileComponentLoader.isMigratedProfile(relatedEntityData.profileId)) {
          this.newTabService.openRelatedEntityProfileInNewTab(params);
        } else {
          this.popout.openOld(params, rawData, this.authenticationService.sessionId);
        }
      }
    }

    this.executeHook('fgppOnButtonClick', actionPayload).pipe(take(1)).subscribe(() => {});
  }

  protected tabChangeHandler(payload: TabChangeEvent): void {
  }

  protected valueChangesHandler(change: IValueChange) {
    this.executeHook('fgppValueChanges', change).pipe(take(1));
  }

  protected beforeFormLoadStart(): void {
  }

  protected beforeValueChanges(): void {
    this.executeProfileStateHooks(this.isSaveAs);
  }

  protected beforeFormReady(): void {
    this.isFormReadyForRendering = true;

    const metadata = {
      profileId: this.profileId,
      uniqueRecId: Base64.encodeURI(this.uid),
      isCreateMode: this.mode === ProfileMode.CREATE,
      relatedEntityEndpoint: this.getRelatedEntityEndpoint(this.authenticationService.userData.userId).endpoint,
      profileData: this.getOriginalData()
    };
    this.layoutEvent
      .setProperty('PROFILES_PAGE_TOOLBAR:relatedEntities', 'metadata', metadata);
  }

  protected afterFormReady() {
    this.handleRecordLocking();
    this.handleIncompleteData();
  }

  protected executeProfileStateHooks(isSaveAs = false): void {
    if (this.layoutMode === ProfileLayoutType.CREATE) {
      if (!isSaveAs) {
        setTimeout(() => this.beforeCreate());
      } else {
        setTimeout(() => this.beforeSaveAs());
      }
    } else if (this.layoutMode === ProfileLayoutType.EDIT) {
      setTimeout(() => this.beforeEdit());
    } else if (this.layoutMode === ProfileLayoutType.VIEW) {
      setTimeout(() => this.beforeView());
    }
  }

  protected beforeCreate(): void {
    this.executeHook('fgppBeforeCreate').pipe(take(1)).subscribe(() => {});
  }

  protected beforeSaveAs(): void {
    this.executeHook('fgppBeforeSaveAs').pipe(take(1)).subscribe(() => {});
  }

  protected beforeEdit(): void {
    this.executeHook('fgppBeforeEdit').pipe(take(1)).subscribe(() => {});
  }

  protected beforeView(): void {
    this.executeHook('fgppBeforeView').pipe(take(1)).subscribe(() => {});
  }


  protected resolveProfileEndpoint(action: ProfileActions, uid: string = null, queryParams: { [key: string]: any } = null): IEndpoint {
    const profileEndpoint = this.endpoints[action];
    const method = profileEndpoint.method;
    let endpoint;
    if (profileEndpoint.endpoint.indexOf('{UID}') >= 0) {
      if (!uid) {
        return null;
      } else {
        endpoint = profileEndpoint.endpoint.replace('{UID}', Base64.encodeURI(uid));
      }
    } else if (profileEndpoint.endpoint.indexOf('{locale}') >= 0) {
      endpoint = profileEndpoint.endpoint.replace('{locale}', encodeURIComponent(this.locale));
    } else {
      endpoint = profileEndpoint.endpoint;
    }

    if (queryParams) {
      const queryStringArr: string[] = [];
      Object.keys(queryParams).forEach((key) => {
        queryStringArr.push(key + '=' + queryParams[key]);
      });
      const separator = endpoint.indexOf('?') !== -1 ? '&' : '?';
      endpoint += queryStringArr.length > 0 ? separator + queryStringArr.join('&') : '';
    }

    return {method, endpoint};
  }

  protected getRelatedEntityEndpoint(userId: string): IEndpoint {
    return this.resolveProfileEndpoint(ProfileActions.RELATED_ENTITY, null, {userId});
  }


  protected selectLayout(inputs: IProfileLayoutSelectionMetadata): IProfileLayoutInfo {
    let layout, layoutMode;
    const temp: { [k: string]: any } = {};
    const mode = inputs.mode;
    const recStatus = inputs.recStatus;
    const loadType = inputs.loadType;
    const changeStatus = inputs.profileChangeStatus;
    const isInitiator = inputs.isInitiator;
    const isSameApprover = inputs.isSameApprover;
    const isLocked = this.coerceValue(inputs.isLocked);
    const isEditable = this.coerceValue(inputs.isEditable);

    temp.isProfileForApproval = mode !== 'create' && ('PN' === changeStatus && (!isInitiator || (isInitiator && isSameApprover)));
    temp.isReadOnly = isLocked || (temp.isProfileForApproval && loadType !== 3) ||
      (!temp.isProfileForApproval && loadType === 3 && isInitiator && !isEditable) ||
      ('FU' === changeStatus);

    const statuses = {
      isWritable: inputs.isWritable,
      isReadOnly: temp.isReadOnly,
      isOpenedFromApprovalScreen: loadType === 3,
      isOpenedFromInactiveScreen: loadType === 6,
      isEditable: isEditable,
      isStatusNew: ProfileRecordStatus.NEW === recStatus,
      isChangeStatusPN: 'PN' === changeStatus,
      isChangeStatusNO: 'NO' === changeStatus,
      isChangeStatusFU: 'FU' === changeStatus,
      isInitiator: isInitiator,
      isCreate: mode === 'create',
    };

    if (inputs.mode === ProfileMode.CREATE) {
      layout = Object.assign({}, this.layouts.create);
      layoutMode = ProfileLayoutType.CREATE;
    } else {
      if (statuses.isReadOnly === true || (statuses.isOpenedFromApprovalScreen && !statuses.isEditable) || statuses.isOpenedFromInactiveScreen) {
        layout = Object.assign({}, this.layouts.view);
        layoutMode = ProfileLayoutType.VIEW;
      } else {
        layout = Object.assign({}, this.layouts.edit);
        layoutMode = ProfileLayoutType.EDIT;
      }
    }

    if (!recStatus || !changeStatus) {
      layout = Object.assign({}, this.layouts.view);
    }

    return {selectedLayout: layout, layoutMode};
  }

  protected doLayoutSelection(): void {
    const data = this.getOriginalData();
    const layoutSelectionInputs: IProfileLayoutSelectionMetadata = {
      tenant: this.tenantId,
      profileTypeId: this.profileId,
      mode: this.mode,
      recStatus: data['REC_STATUS'],
      loadType: this.loadType,
      profileChangeStatus: data['PROFILE_CHANGE_STATUS'],
      supportsSaveAs: this.coerceValue(data['SUPPORTS_SAVE_AS']) || false,
      isSameApprover: data['IS_SAME_APPROVER'] || false,
      isWritable: data['IS_WRITABLE'] || 'FALSE',
      isEditable: data['IS_EDITABLE'] || 'FALSE',
      isLocked: data['IS_LOCKED_BY_ANOTHER_USER'] || false,
      isInitiator: data['IS_INITIATOR'] || false,
      formData: data,
      hasAttachment: this.hasAttachments
    };

    //TODO: Strong Type
    const layoutInfo: IProfileLayoutInfo = this.selectLayout(layoutSelectionInputs);
    const layout = this.clone(layoutInfo.selectedLayout);
    layout.properties.controls[this.footerPropertyName] = this.buildFooter(layout.properties.controls[this.footerPropertyName], layoutSelectionInputs);
    this.setLayout(layout);
    if (layout.properties.controls[this.pagePropertyName]) {
      this.buildPageButtons();
    }

    this.layoutMode = layoutInfo.layoutMode;
  }

  private buildFooter(originalFooter: any, layoutSelectionInputs: IProfileLayoutSelectionMetadata): any {
    let footer = Object.assign({}, originalFooter);
    footer = this.standardProfileReducer(footer, layoutSelectionInputs, this.StandardReducer);
    footer = this.customProfileReducer(footer, layoutSelectionInputs, this.CustomReducer);
    return footer;
  }

  protected setCustomReducer(Reducer: ISdkProfileFooterReducer): void {
    this.CustomReducer = Reducer;
  }

  private buildPageButtons() {
    const layout = this.getLayout();
    const pageControl = layout.properties.controls[this.pagePropertyName];
    const footerControl = layout.properties.controls[this.footerPropertyName];
    const footerControls = footerControl.controls;
    const footerButtons = Object.keys(footerControls).filter(key => footerControls[key].buttonType !== 'menu');
    const pageButtons = Object.keys(footerControls).filter(key => footerControls[key].buttonType === 'menu');
    footerControl.controls = {};
    footerButtons.forEach(key => {
      footerControl.controls[key] = footerControls[key];
    });
    pageButtons.forEach(key => {
      pageControl.buttons[key] = footerControls[key];
    });
    this.setLayout(layout);
  }

  private standardProfileReducer(footer: IProfileFooter, inputs: IProfileLayoutSelectionMetadata, Reducer: ISdkProfileFooterReducer): IProfileFooter {
    return Reducer.reduce(ButtonCategory.STANDARD, footer, inputs);
  }

  private customProfileReducer(footer: IProfileFooter, inputs: IProfileLayoutSelectionMetadata, Reducer: ISdkProfileFooterReducer): IProfileFooter {
    return Reducer.reduce(ButtonCategory.CUSTOM, footer, inputs);
  }

  protected isL10NDataAvailable(): boolean {
    const translationCheckKey = `business-framework.profiles.${this.profileId}`;
    return !(this.translate.instant(translationCheckKey) === translationCheckKey);
  }

  private getKeyNameEndsWith(key: string, data: any): string {
    let keyName = '';
    if (data) {
      Object.keys(data).forEach((dataKey) => {
        if (dataKey.match(key + '$')) {
          keyName = dataKey;
        }
      });
      return keyName;
    }
  }

  private onCloseProfile(): void {
    const obs$: Observable<DialogEvent> = this.checkDirty() ? this.openSaveChangesModal() : of(null);
    obs$.pipe(take(1)).subscribe(event => {
      const result = event ? event.value.payload : {};
      if (!result.button || result.button === this.dialogOkBtn) {
        const payload = {target: 'CLOSE', type: 'button-event', name: 'buttonClick', value: {action: '', aliasFor: 'CLOSE'}} as BFEvents.ButtonEvent;
        this.mode === ProfileMode.EDIT ? this.doServerAction(payload) : this.returnToPreviousRoute();
      }
    });
  }

  protected onSaveProfile(actionPayload: BFEvents.ButtonEvent, returnToGrid = true): void {
    this.executeHook('fgppOnSave').pipe(take(1)).subscribe((hookResult: boolean) => {
      if(hookResult) {
        if (this.checkRequired() && this.checkInvalid(true) && this.checkDirtyOnSave()) {
          this.openEffectiveDateModal(true).subscribe(event => {
            this.onEffectiveDateDialogClose(actionPayload, event, returnToGrid);
          });
        }
      }
    });
  }

  private onEffectiveDateDialogClose(actionPayload: BFEvents.ButtonEvent, event: DialogEvent, returnToGrid = true) {
    const result = event.value.payload;
    if (result.button === this.dialogOkBtn) {
      if (result.data['EFFECTIVE_DATE']) {
        this.formMgr.get('EFFECTIVE_DATE').setValue(result.data['EFFECTIVE_DATE'].toString());
      }
      this.formMgr.get('NOTE').setValue(result.data['NOTE']);
      this.doServerAction(actionPayload, this.getToastMessage(actionPayload.target as ProfileButtons), returnToGrid);
    }

  }

  protected checkDirtyOnSave(): boolean {
    if (this.isSaveAs) {
      return true;
    }
    return super.checkDirtyOnSave();
  }

  protected onDeclineProfile(payload: BFEvents.ButtonEvent, returnToGrid = true): void {
    this.openEffectiveDateModal(false).subscribe(event => {
      this.onEffectiveDateDialogClose(payload, event, returnToGrid);
    });
  }

  private onUndoProfile(): void {
    this.showSpinner();
    this.unsubscribeValueChanges();
    this.formMgr.reset();
    this.setData(this.getOriginalData());
    this.loadDefaultValues();
    this.formMgr.patchValue(this.getOriginalData());
    this.canvasEvent.setTarget(this.formService.getId()).set('state', 'setValue').emit();
    this.formLoadStart();
    this.hideSpinner();
  }

  protected onSaveAsProfile(office: string, profileData: any): void {
    this.mode = ProfileMode.CREATE;
    this.loadType = undefined;
    this.showSpinner();

    this.onCloseAction$().subscribe({
      next: () => {
        this.createCopyForSaveAs(profileData, office).subscribe({
          next: (response) => {
            this.saveAsDataSource = {...this.getOriginalData()};
            const data = {...profileData, ...response};
            if (data['VIRTUAL-UID']) {
              data['VIRTUAL-UID'] = null;
            }

            if (data['REC_STATUS']) {
              data['REC_STATUS'] = 'NW';
            }

            if(data['PROFILE_CHANGE_STATUS']) {
              data['PROFILE_CHANGE_STATUS'] = 'PN';
            }
            const recStatusKey = this.getKeyNameEndsWith('-REC_STATUS', data);
            const changeStatusKey = this.getKeyNameEndsWith('-PROFILE_CHANGE_STATUS', data);
            if (recStatusKey) {
              data[recStatusKey] = 'NW';
            }

            if (changeStatusKey) {
              data[changeStatusKey] = 'PN';
            }
            this.setFormRenderingStatus(false);
            this.setData(data);
            this.setOriginalData(data);
            this.unsubscribeValueChanges();
            this.formMgr.reset();
            this.office = office;
            this.mode = ProfileMode.CREATE;
            this.uid = '';
            this.setPrefetchDrilldownList();
            this.createForm(data);
            this.isSaveAs = true;
          },
          error: () => {
            this.notificationService.error(this.translate.instant('business-framework.profiles.errors.28560'));
            this.returnToPreviousRoute();
          }
        })
      },
      error: () => {
        this.notificationService.error(this.translate.instant('business-framework.profiles.errors.28560'));
      }
    });
  }

  private onProfileStatusChange(payload: BFEvents.ButtonEvent): void {
    this.openEffectiveDateModal(true).subscribe(event => {
      this.onEffectiveDateDialogClose(payload, event);
    });
  }

  protected doServerAction(payload: BFEvents.ButtonEvent, message: string = null, returnToGrid: boolean = true) {
    let popoutWindow = this.stateParams.isStandAlone === 'true';
    this.showSpinner();
    const actionEndpoint = this.resolveProfileEndpoint(ProfileActions[payload.target], this.uid);
    const body = this.getServerActionRequestBody(actionEndpoint, payload.target);
    this.dataService.request(actionEndpoint.method, actionEndpoint.endpoint, { body }).subscribe({
      next: (response: IBffResponse) => {
        this.handleServerResponse(payload, response, message, returnToGrid && !popoutWindow);
        if(popoutWindow) {
          window.close();
        }
      },
      error: (error: HttpErrorResponse) => {
        this.handleServerError(error);
      }
    });
  }

  private onCloseAction$(): Observable<any> {
    if (!this.uid) {
      return of(null);
    }
    const payload = {target: 'CLOSE', type: 'button-event', name: 'buttonClick', value: {action: '', aliasFor: 'CLOSE'}} as BFEvents.ButtonEvent;
    const actionEndpoint = this.resolveProfileEndpoint(ProfileActions[payload.target], this.uid);
    const body = this.getServerActionRequestBody(actionEndpoint, payload.target);
    return this.dataService.request(actionEndpoint.method, actionEndpoint.endpoint, { body }).pipe(
      catchError(error => {
        console.error('Error occurred while closing action:', error);
        return throwError(() => error);
      })
    );
  }

  protected getServerActionRequestBody(endpoint: IEndpoint, buttonId: string): any {
    return this.formMgr.getValue();
  }

  protected handleServerResponse(payload: BFEvents.ButtonEvent , response: IBffResponse, message: string = null, returnToGrid: boolean = true) {
      let obs$: Observable<any>;
      if (payload.target === ProfileButtons.CREATE || payload.target  === ProfileButtons.SAVE) {
        obs$ = this.transformToObservable(this.executeHook('fgppAfterSave', payload));
      } else {
        obs$ = of(true);
      }
      const sub = obs$.subscribe(() => {
        this.form.markAsPristine();
        this.hideSpinner();
        if (message) {
          this.notificationService.success(message);
        }
        if (returnToGrid) {
          this.returnToPreviousRoute();
        }
      });
      this.subscription.add(sub);
  }

  protected handleServerError(error: HttpErrorResponse) {
    this.hideSpinner();
    this.profileActionErrorHandlerService.onServerError(error);
  }

  protected openEffectiveDateModal(showEffectiveDate = true): Observable<DialogEvent> {
    const data = {
      'EFFECTIVE_DATE': +this.getOriginalData().OFFICE_BUSINESS_DATE
    };

    const config = {
      layout: getEffectiveDateDialogLayout(),
      contract: getEffectiveDateDialogContract(),
      data,
      okButtonTitle: 'business-framework.profiles.modals.effectiveDate.buttons.ok',
      cancelButtonTitle: 'business-framework.profiles.modals.effectiveDate.buttons.cancel',
      title: 'business-framework.profiles.modals.effectiveDate.title',
      size: ModalSize.SMALL
    };

    config.layout.properties.controls.EFFECTIVE_DATE.hidden = showEffectiveDate === false;
    const businessDate = dayjs(+this.getOriginalData().OFFICE_BUSINESS_DATE).startOf('day').valueOf();
    config.layout.properties.controls.EFFECTIVE_DATE['minimum'] = businessDate;

    return this.openModal(config, this.effectiveDateDialog).pipe(take(1));

  }

  protected openSaveChangesModal(): Observable<DialogEvent> {
    const modalConfig = <IDynamicModalDialogData>{
      size: ModalSize.X_SMALL,
      layout: SaveChangesLayout,
      contract: SaveChangesContract,
      title: this.translate.instant('business-framework.profiles.modals.saveChanges.title'),
      okButtonTitle: 'business-framework.profiles.modals.saveChanges.buttons.ok',
      cancelButtonTitle: 'business-framework.profiles.modals.saveChanges.buttons.cancel'
    };
    return this.openModal(modalConfig, 'SAVE-CHANGES-DIALOG').pipe((take(1)));

  }

  private handleRecordLocking() {
    if (this.getData()['IS_LOCKED_BY_ANOTHER_USER']) {
      const obs = this.showModalIfRecordIsLocked();
      obs.subscribe(event => {
        const result = event.value.payload;
        if (result.button !== this.dialogOkBtn) {
          this.onCloseProfile();
        }
      });
    }
  }

  private handleIncompleteData() {
    const data = this.getData();
    if (!data['REC_STATUS'] || !data['PROFILE_CHANGE_STATUS']) {
      const obs = this.showModalIfDataCorrupt();
      obs.subscribe(event => {
        const result = event.value.payload;
        if (result.button !== this.dialogOkBtn) {
          this.onCloseProfile();
        }
      });
    }
  }

  private showModalIfRecordIsLocked(): Observable<DialogEvent> {
    const layout = structuredClone(RecordLockedLayout);
    const data = this.getData();
    if (data.LOCKED_MESSAGE) {
      layout.properties.controls.message.title = this.translate.instant('business-framework.profiles.modals.recordLockedExternal.body',
      { params: { lockMessage: data.LOCKED_MESSAGE } });
    } else {
      layout.properties.controls.message.title = this.translate.instant('business-framework.profiles.modals.recordLocked.body',
      { params: { username: data.LOCKED_BY_USER } });
    }
    const modalConfig = <IDynamicModalDialogData>{
      size: ModalSize.SMALL,
      layout,
      data: {},
      contract: RecordLockedContract,
      title: this.translate.instant('business-framework.profiles.modals.recordLocked.title'),
      okButtonTitle: 'business-framework.profiles.modals.recordLocked.buttons.ok',
      cancelButtonTitle: 'business-framework.profiles.modals.recordLocked.buttons.cancel'
    };

    return this.openModal(modalConfig, 'LOCKED_BY_ANOTHER_USER').pipe(take(1));

  }

  private showModalIfDataCorrupt(): Observable<DialogEvent> {
    const layout = structuredClone(RecordLockedLayout);
    const data = this.getData();
    layout.properties.controls.message.title = this.translate.instant('business-framework.profiles.modals.incompleteData.body');

    const modalConfig = <IDynamicModalDialogData>{
      size: ModalSize.SMALL,
      layout,
      data: {},
      contract: RecordLockedContract,
      title: this.translate.instant('business-framework.profiles.modals.incompleteData.title'),
      okButtonTitle: 'business-framework.profiles.modals.recordLocked.buttons.ok',
      cancelButtonTitle: 'business-framework.profiles.modals.recordLocked.buttons.cancel'
    };

    return this.openModal(modalConfig, 'INCOMPLETE_DATA').pipe(take(1));

  }
  protected getToastMessage(button: ProfileButtons): any {
    const message = this._profileActionMessage.get(button);
    return this.translate.instant(message.key, message.params);
  }

  protected getParentUidForSaveAs(): string {
    return this.uid;
  }

  private createCopyForSaveAs(source: any, office: string): Observable<any> {
    const newEndpoint = this.resolveProfileEndpoint(ProfileActions.NEW, null, {office, taskCode: this.taskCode, parentUid: this.getParentUidForSaveAs()});
    return this.dataService.request(newEndpoint.method, newEndpoint.endpoint, {});
  }

  protected openDialog<T>(dialogComponent: ComponentType<T>, endpoint: string) {
    this.dialogService.open(dialogComponent, {
      role: 'alertdialog',
      panelClass: 'gpp-ui-modal-no-padding',
      disableClose: false,
      width: '65%',
      height: '550px',
      data: endpoint
    });
  }

  protected onOfficeSelection(office: string): void {
    if (office.length > 0) {
      this.onSaveAsProfile(office, this.getOriginalData());
    }
  }

  private handleAttachmentsButtonClick() {
    const config: DialogFileAttachmentConfig = {
      userId: this.authenticationService.userData.userId,
      sourceTypeId: this.profileId || '',
      sourceUid: this.formMgr.get('VIRTUAL-UID').getValue() || '*',
      mode: this.getAttachmentsMode(),
      attachmentList: this.attachmentList || []
    };
    const dialogRef = this.fileAttachmentDialogService.open(config);
    if (dialogRef) {
      dialogRef.afterClosed().subscribe((response: FileAttachmentDialogOutput) => {
        if (response) {
          if (response.attachmentChanged) {
            this.formMgr.markAsDirty();
          }
          this.attachmentList = response.fileAttachmentsList;
          const attachmentList = response.fileAttachmentsList ? JSON.stringify(response.fileAttachmentsList) : null
          this.formMgr.get(this.attachmentControlName).setValue(attachmentList);
          this.fileAttachmentDialogService.closeDialog();
        }
      });
    }
  }

  getAttachmentsMode(): FileAttachmentPermissionMode {
    const data = this.getData();
    if ((this.mode === ProfileMode.CREATE || (this.loadType === ProfileLoadType.EDIT && data['IS_WRITABLE'] === 'TRUE'))
      && this.attachmentPermission === AttachmentPermissions.PERMISSION_WRITE && !data['IS_LOCKED_BY_ANOTHER_USER']) {
      return FileAttachmentPermissionMode.READANDWRITE;
    }
    return FileAttachmentPermissionMode.READ;
  }

  getAttachmentButtonPermission(department: string) {
    let attachmentPermissionUrl = `/ui/profiles/${this.profileId}/${this.office}/${department}/attachmentsButton`;
    if (this.uid) {
      attachmentPermissionUrl += `?uid=${this.uid}`;
    }
    this.dataService.get(attachmentPermissionUrl).subscribe({
      next: (response: any) => {
        this.attachmentPermission = response.permission;
        if (this.attachmentPermission === AttachmentPermissions.NO_PERMISSION) {
          this.layoutEvent.hide(ProfileButtons.ATTACHMENTS);
        } else {
          if (this.hasAttachments) {
            if (this.getData()['VIRTUAL-UID']) {
              this.getAttachments();
            }
          }
        }
      },
      error: error => {
        this.notificationService.error(this.translate.instant('business-framework.profiles.errors.unableToLoadAttachmentsPermission'));
      }
    });
  }

  public getAttachments() {
    const virtualUid = this.formMgr.get('VIRTUAL-UID').getValue();
    const attachmentUrl = `/ui/profiles/${this.profileId}/${Base64.encodeURL(virtualUid)}/attachments`;

    const sub = this.dataService.get(attachmentUrl).subscribe((response: any) => {
        this.attachmentList = response.attachments;
      },
      error => {
        this.notificationService.error(this.translate.instant('business-framework.profiles.errors.unableToLoadAttachments'));
      });
    this.subscription.add(sub);
  }

  private populateParams(reData: any, fData: any): any {
    const profileId = ((this.navigationService.getNavigationItem(reData.profileId.toString()) as NavigationItem)?.data as ProfileNodeData)?.profileId;
    const profileInfo = this.profileService.getProfileInfo(profileId);
    return {
      uniqueRecId: !reData.isCreate ? reData.selectedRow['VIRTUAL.UID'] : null,
      profileId: reData.profileId,
      profile: profileInfo.profileId,
      loadType: reData.isCreate ? 1 : this.loadType,
      mode: reData.isCreate ? ProfileMode.CREATE : this.mode,
      office: reData.isCreate && fData[this.getKeyNameEndsWith('-OFFICE', fData)] ? fData[this.getKeyNameEndsWith('-OFFICE', fData)] : this.office,
      closeEndPoint: reData.isNewUI && !reData.isCreate ? this.resolveProfileEndpoint(ProfileActions.CLOSE, reData.selectedRow['VIRTUAL.UID']) : null,
      taskCode: profileInfo.taskCode
    };
  }

  private populateCreateParams(relatedEntityData: any, data: any): any {
    let queryParams: any;
    try {
      queryParams = JSON.parse(relatedEntityData.createFilter);
      Object.keys(queryParams).forEach((keyName) => {
        if (queryParams[keyName].indexOf('${', 0) >= 0) {
          const key = queryParams[keyName].replace('${', '').replace('}', '');
          if (data[key]) {
            queryParams[keyName] = data[key];
          } else {
            delete queryParams[keyName];
          }
        }
      });
    } catch (e) {
      console.error('Error occurred parsing the create filter');
    }
    return queryParams;
  }

  protected getSaveAsDataSource(): Partial<IData> {
    return this.saveAsDataSource;
  }
}
