import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ProfilesNavigationService } from "../../services/profiles-navigation.service";

@Injectable({
  providedIn: 'root'
})
export class RedirectProfilesGuard implements CanActivate {

  constructor(private router: Router, private profilesNavigationService: ProfilesNavigationService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const tab = route.data.tab;
    const taskBarItem = this.profilesNavigationService.getFirstTaskBarItemIdByComponentId(tab);

    if (!taskBarItem) {
      this.router.navigate(['/home/page-not-found'], { replaceUrl: true });
      return false;
    }

    this.router.navigate(['home', tab, taskBarItem]);
    window.dispatchEvent(new CustomEvent('resize', { detail: route.data.tab }));
    return true;
  }
}
