import { FileAttachmentRow } from '../../../../../../../attachment/src/lib/file-attachment-table/file-attachment-row/models/file-attachment-row.model';//'../../../../../../projects/attachment/src/lib/file-attachment-table/file-attachment-row/models/file-attachment-row.model';
import { FileAttachmentMapping } from '../models/file-attachment-mapping.model';
import { Injectable } from '@angular/core';

@Injectable()
export class FileAttachmentDialogMapperService {

    constructor() {}

    mapFileAttachmentRowToFileAttachmentMapping( fileAttachmentsList: Array<FileAttachmentRow>, sourceUid: string, sourceTypeId: string): Array<FileAttachmentMapping> {
        const mappingArray = [];
        for (let index = 0; index < fileAttachmentsList.length; index++) {
            const mappingObject: FileAttachmentMapping = {
                sourceUid: sourceUid,
                sourceTypeId: sourceTypeId,
                fileName: fileAttachmentsList[index].fileName,
                updatedDate: fileAttachmentsList[index].updatedDate,
                userId: fileAttachmentsList[index].userId,
                description: fileAttachmentsList[index].description,
                fileId: fileAttachmentsList[index].fileId,
                status: fileAttachmentsList[index].status
            };
            mappingArray.push(mappingObject);
        }
        return mappingArray;
   }
}
