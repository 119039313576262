import {Component, OnInit} from '@angular/core';
import { ProfileFormTemplateProviders } from '../../../core/templates/profile-form.template.providers';
import {MultiLineComponent} from "@fgpp-ui/components";
import {FieldsToParticipantAvailabilityComponent} from "../../modals/fields-to-participant-availability.component";
import {
  BFOnFormLoadComplete,
  BFOnValueChange,
} from "../../../core/interfaces/sdk-profile-hooks.interface";
import {AbstractTaskFormComponent} from "../../../tasks/abstract/abstract-task/abstract-task-form.component";
import {IValueChange} from "../../../core/interfaces/form-value-change.interface";
import {ParticipantAvailability} from "../../models/participant-availability.model";

@Component({
  selector: 'app-participant-availability',
  templateUrl: '../../../core/templates/profile-form.template.html',
  styleUrls: ['../../../core/templates/profile-form.template.scss'],
  providers: ProfileFormTemplateProviders
})
export class ParticipantAvailabilityComponent extends AbstractTaskFormComponent implements BFOnFormLoadComplete, BFOnValueChange{

  fgppFormLoadComplete() {
    this.formMgr.get('DETAILS').getLayoutControl<MultiLineComponent>().setModalComponent(FieldsToParticipantAvailabilityComponent);
    if(this.formMgr.get(ParticipantAvailability.MOP).getValue() === 'NPP' && this.formMgr.get(ParticipantAvailability.V_SIGN_ON_STATUS).getValue() === 'Service Specific'){
      this.formMgr.get('DETAILS').enable();
    }else{
      this.formMgr.get('DETAILS').setValue([]);
      this.formMgr.get('DETAILS').disable();
    }
  }

  fgppValueChanges(change: IValueChange): void {
    if (change.control === ParticipantAvailability.MOP) {
      if ((change.value.inputValue === 'NPP') || this.formMgr.get(ParticipantAvailability.MOP).getValue() === 'NPP'){
        if(this.formMgr.get(ParticipantAvailability.V_SIGN_ON_STATUS).getValue() === 'Service Specific')
          this.formMgr.get('DETAILS').enable();
      } else {
        this.formMgr.get('DETAILS').setValue([]);
        this.formMgr.get('DETAILS').disable();
      }
    }
    if(change.control === ParticipantAvailability.V_SIGN_ON_STATUS){
      if(change.value.inputValue){
        const status = this.getDrilldownData(ParticipantAvailability.V_SIGN_ON_STATUS);
        this.formMgr.get(ParticipantAvailability.SIGN_ON_STATUS).setValue(status['SIGN_ON_STATUS_MOP-VALUE_CODE']);
        if(change.value.inputValue === 'Service Specific' || this.formMgr.get(ParticipantAvailability.V_SIGN_ON_STATUS).getValue() === 'Service Specific') {
          if (this.formMgr.get(ParticipantAvailability.MOP).getValue() === 'NPP') {
            this.formMgr.get('DETAILS').enable();
          }
        }
        else{
          this.formMgr.get('DETAILS').setValue([]);
          this.formMgr.get('DETAILS').disable();
        }
      }
    }
  }
}
