import { AfterViewInit, Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[appSelectElement]'
})
export class SelectElementDirective implements AfterViewInit, OnInit {

  private isSelected;

  constructor(private element: ElementRef) {}

  ngOnInit(): void {
     this.isSelected = false;
  }

  private selectInput() {
    if (!!this.element.nativeElement.value && !this.isSelected) {
      this.element.nativeElement.select();
      this.isSelected = true;
    }
  }

  ngAfterViewInit() {
    window.setInterval(this.selectInput.bind(this), 200);
  }
}
