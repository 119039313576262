import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { SEARCH_RESOURCE_TOKENS } from '../config/search-resource-tokens.config';
import { NavigationResultGroup } from '../models';
import { INavigationSearchService } from './interfaces/navigation-search-service.interface';
import { NavigationSearchConfigService } from './navigation-search-config.service';
import { NavigationSearchSortService } from './navigation-search-sort.service';
import { ISearchResourceService } from './resources/search-resource-service.intreface';

@Injectable()
export class NavigationSearchService implements INavigationSearchService {

  private _results$ = new BehaviorSubject<Array<NavigationResultGroup>>([]);
  private _subscriber = new Subscription();

  get results$(): Observable<Array<NavigationResultGroup>> {
    return this._results$.asObservable();
  }

  constructor(private injector: Injector,
              private configService: NavigationSearchConfigService,
              private sortService: NavigationSearchSortService) { }

  search(term: string): void {
    this._subscriber.unsubscribe();
    this._subscriber = new Subscription();

    const searchResources = this.configService.searchResources;
    const groups = [];

    for (const searchResourceConfig of searchResources) {
      const searchResourceService = this.injector.get<ISearchResourceService>(SEARCH_RESOURCE_TOKENS[searchResourceConfig.type]);
      const subscription = searchResourceService.search(term).subscribe((result) => {
        this.mergeResult(result, groups);
        this.emitResults(groups);
      });
      this._subscriber.add(subscription);
    }
  }

  private mergeResult(result: Array<NavigationResultGroup> | NavigationResultGroup, groups: Array<NavigationResultGroup>): void {
    if (result) {
      if (Array.isArray(result)) {
        result.forEach((group: NavigationResultGroup) => this.mergeGroup(group, groups));
      } else {
        this.mergeGroup(result, groups);
      }
    }
  }

  private mergeGroup(group: NavigationResultGroup, groups: Array<NavigationResultGroup>): void {
    if (!group) {
      return;
    }
    const existingGroup = groups.find((navigationResultGroup: NavigationResultGroup) => navigationResultGroup.id === group.id);
    if (existingGroup) {
      existingGroup.items.push(...group.items);
      this.sortService.sortItems(existingGroup.items);
    } else {
      groups.push(group);
      this.sortService.sortGroups(groups);
      this.sortService.sortItems(group.items);
    }
  }

  private emitResults(groups: Array<NavigationResultGroup>): void {
    this._results$.next(groups);
  }

}
