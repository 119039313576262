export const Advising =
  {
    'CUST_NAME': 'CUSTOMRS-CUST_NAME',
    'ACC_NO': 'ADVISING-ACC_NO',
    'ACC_CCY': 'ADVISING-ACC_CCY',
    'ACC_TYPE': 'ADVISING-ACC_TYPE',
    'CONTACT_NAME': 'ADVISING-CONTACT_NAME',
    'CONTACT_TYPE': 'ADVISING-CONTACT_TYPE',
    'CONTACT_DETAILS': 'ADVISING-CONTACT_DETAILS',
    'DEPARTMENT': 'ADVISING-DEPARTMENT',
    'EFFECTIVE_DATE': 'EFFECTIVE_DATE',
    'OFFICE': 'ADVISING-OFFICE',
    'PROFILE_CHANGE_STATUS': 'ADVISING-PROFILE_CHANGE_STATUS',
    'REC_STATUS': 'ADVISING-REC_STATUS',
    'CUST_CODE': 'ADVISING-CUST_CODE',
    'FEE_ADVICE_IND': 'ADVISING-FEE_ADVICE_IND',
    'DR_ADVICE_IND': 'ADVISING-DR_ADVICE_IND',
    'CR_ADVICE_IND': 'ADVISING-CR_ADVICE_IND',
    'ADVICE_TYPE': 'ADVISING-ADVICE_TYPE',
    'ADVICE_SUB_TYPE': 'ADVISING-ADVICE_SUB_TYPE',
    'ACC_SOURCE': 'ADVISING-ACC_SOURCE',
    'DEFAULT_CUST_CODE': 'ADVISING-DEFAULT_CUST_CODE',
    'MIN_AMT': 'ADVISING-MIN_AMT',
    'ADVICE_IND': 'ADVISING-ADVICE_IND',
    'TIME_STAMP': 'TIME_STAMP',
    'UID': 'VIRTUAL-UID',
    'PROFILE_UPDATE_PK': 'PROFILE_UPDATE_PK',
    'PU_TIME_STAMP': 'PU_TIME_STAMP',
    'NOTE': 'NOTE'
  };
