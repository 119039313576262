export enum TimeZones  {
	"DAY_LIGHT_SAVING_ENDS"= "DAYLIGHT_SAVINGS-DAY_LIGHT_SAVING_ENDS",
	"DAY_LIGHT_SAVING_SHIFT"= "DAYLIGHT_SAVINGS-DAY_LIGHT_SAVING_SHIFT",
	"DAY_LIGHT_SAVING_STARTS"= "DAYLIGHT_SAVINGS-DAY_LIGHT_SAVING_STARTS",
	"ZONE_CODE"= "TIME_ZONES-ZONE_CODE",
	"DAYLIGHT_ZONE_CODE"= "TIME_ZONES-DAYLIGHT_ZONE_CODE",
	"DESCRIPTION"= "TIME_ZONES-DESCRIPTION",
	"EFFECTIVE_DATE"= "EFFECTIVE_DATE",
	"PROFILE_CHANGE_STATUS"= "TIME_ZONES-PROFILE_CHANGE_STATUS",
	"REC_STATUS"= "TIME_ZONES-REC_STATUS",
	"STANDARD_HOURS_FROM_UTC"= "TIME_ZONES-STANDARD_HOURS_FROM_UTC",
	"ZONE_NAME"= "TIME_ZONES-ZONE_NAME",
	"TIME_STAMP"= "TIME_STAMP",
	"UID"= "VIRTUAL-UID",
	"PROFILE_UPDATE_PK"= "PROFILE_UPDATE_PK",
	"PU_TIME_STAMP"= "PU_TIME_STAMP",
	"NOTE"= "NOTE",
	"DAYLIGHT_SAVINGS_MULTILINE"= "DAYLIGHT_SAVINGS_MULTILINE",
	"V_STANDARD_HOURS_FROM_UTC_HHMM"= "TIME_ZONES-V_STANDARD_HOURS_FROM_UTC_HHMM",
  "V_STANDARD_HOURS_FROM_UTC_SIGN"="TIME_ZONES-V_STANDARD_HOURS_FROM_UTC_SIGN"
};
