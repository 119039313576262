import { Injectable } from '@angular/core';
import {
  ComplexCondition, ComplexConditionPayload, ConditionFunction, ConditionOperand, ConditionsBankCategory, ConditionsBankNode, ContentType,
  FunctionArgument, LogicalField, MultipleCondition, MultipleConditionPayload, RuleCriteria, SimpleCondition, SimpleConditionPayload
} from '../models';

@Injectable()
export class RuleConditionsAlgorithmsService {

  allRightOperandsFill = true;
  allConditionsValid = true;
  invalidConditions = [] as Array<string>;
  getFieldAsObject: (fieldAsString: string) => ConditionsBankNode | string;

  private maxBinaryTreeLevel = 0;
  private logicalOperatorsMap = {
    '&&': 'AND',
    'and': 'AND',
    'AND': 'AND',
    '||': 'OR',
    'or': 'OR',
    'OR': 'OR'
  } as { [key: string]: 'AND' | 'OR' };


  fromBinaryTreeToNTree(binaryTree: RuleCriteria): ComplexCondition {
    const treeConditions = {} as ComplexCondition;
    if (binaryTree == null) {
      binaryTree = {} as RuleCriteria;
    }

    (binaryTree as ComplexConditionPayload).level = 0;
    this.maxBinaryTreeLevel = 0;
    this.countBinaryTreeLevelsFunction(binaryTree, 0);
    treeConditions.logicalOperator = (binaryTree as ComplexConditionPayload).logicalOperator as 'AND' | 'OR';
    treeConditions.logicalOperatorChanged = (binaryTree as ComplexConditionPayload).logicalOperatorChanged;
    treeConditions.conditions = [];
    (treeConditions as any).leftOperand = (binaryTree as ComplexConditionPayload).leftOperand;
    (treeConditions as any).rightOperand = (binaryTree as ComplexConditionPayload).rightOperand;
    treeConditions.level = 0;

    if (!(treeConditions as any).rightOperand) {
      if ((binaryTree as MultipleConditionPayload).conditionsList) {
        binaryTree.class = 'PRulesMultipleConditionBean';
        (treeConditions as any).leftOperand = binaryTree;
      }

      // a single condition rule case
      this.operandsToConditions(treeConditions);
    } else {
      this.buildTree(treeConditions);
    }
    this.removeLevelsFunction(treeConditions);
    return treeConditions;
  }

  fromNTreeToBinaryTree(tree: ComplexCondition, allBaseConditions: Array<string>, conditionsBank: Array<ConditionsBankNode>): RuleCriteria {
    this.allRightOperandsFill = true;
    let binaryTree = this.buildBinaryTreeFromNTree(tree, allBaseConditions, conditionsBank);

    //SingleConditionRule case that has no inner conditions
    if (!(binaryTree as ComplexConditionPayload).logicalOperator && binaryTree.class !== 'PRulesMultipleConditionBean') {
      binaryTree = binaryTree.leftOperand as SimpleConditionPayload;
    }

    return binaryTree;
  }

  buildTreeFromFlatArray(flatArray: Array<LogicalField>, noParentID: string,
    bcRestrictedLogicalFields: Array<string>, recentlyUsedConditionFields: Array<string>): ConditionsBankCategory {
    const parentsMap = this.mapNodesToParent(flatArray, bcRestrictedLogicalFields, recentlyUsedConditionFields);
    const root = this.setUpRootNode(parentsMap, noParentID);
    this.buildByParents(parentsMap, root);
    this.markSterileParents(root);
    this.cleanSterileNodes(root);
    return root;
  }

  getArgumentAliasFromId(operand: string): string {
    let id = operand;
    if (operand.startsWith('[')) {
      id = operand.split('[')[1].slice(0, -1);
    }
    const logicalField = this.getFieldAsObject(id);
    if (logicalField) {
      return (logicalField as ConditionsBankNode).alias;
    } else {
      return operand;
    }
  }

  private mapNodesToParent(flatArray: Array<LogicalField>, bcRestrictedLogicalFields: Array<string>,
    recentlyUsedConditionFields: Array<string>): { [key: string]: Array<ConditionsBankNode> } {

    const parentsMap = {} as { [key: string]: Array<ConditionsBankNode> };

    for (let i = 0; i < flatArray.length; i++) {
      const currentNode = flatArray[i];
      if (!parentsMap[currentNode.parentId] /*|| !parentsMap.hasOwnProperty(currentNode.parentId)*/) {
        parentsMap[currentNode.parentId] = [];
      }

      if (bcRestrictedLogicalFields && bcRestrictedLogicalFields.length > 0 &&
        bcRestrictedLogicalFields.indexOf(currentNode.id) > -1) {
        currentNode.locked = true;
      }

      if (recentlyUsedConditionFields && recentlyUsedConditionFields.indexOf(currentNode.id) > -1) {
        currentNode.isRecentlyUsed = true;
      }
      parentsMap[currentNode.parentId].push(currentNode);
    }

    return parentsMap;
  }

  private setUpRootNode(parentsMap: { [key: string]: Array<ConditionsBankNode> }, noParentID: string): ConditionsBankCategory {
    let root = null;
    if (!!parentsMap[noParentID] && parentsMap[noParentID].length > 0) {
      if (parentsMap[noParentID].length === 1) {
        root = parentsMap[noParentID];
        root.nodes = parentsMap[root.parentId];
      } else {
        root = { nodes: parentsMap[noParentID] };
      }
    }

    return root;
  }

  private buildByParents(parentsMap: { [key: string]: Array<ConditionsBankNode> }, root: ConditionsBankCategory | ConditionsBankNode): void {
    for (let i = 0; i < root.nodes.length; i++) {
      const currentNode = root.nodes[i];
      const nodeID = currentNode.id;
      if (!!parentsMap[nodeID]) {
        currentNode.nodes = parentsMap[nodeID];
        this.buildByParents(parentsMap, currentNode);
      }
    }
  }

  private markSterileParents(root: ConditionsBankCategory | ConditionsBankNode): void {
    let isSterile = (root as LogicalField).parent;
    for (let i = 0; !!root.nodes && i < root.nodes.length; i++) {
      const currentNode = root.nodes[i];
      this.markSterileParents(currentNode);
      isSterile = isSterile && (currentNode as LogicalField).isSterile;
    }

    (root as LogicalField).isSterile = isSterile;
  }

  private cleanSterileNodes(root: ConditionsBankCategory | ConditionsBankNode): void {
    if (!root.nodes) {
      return;
    }
    for (let i = root.nodes.length - 1; i >= 0; i--) {
      const currentNode = root.nodes[i];
      if ((currentNode as LogicalField).isSterile) {
        root.nodes.splice(i, 1);
      } else {
        this.cleanSterileNodes(currentNode);
      }
    }
  }

  private countBinaryTreeLevelsFunction(conditions: RuleCriteria, groupLevel: number): void {
    if (conditions && (conditions as ComplexConditionPayload).logicalOperator) {
      const complexConditionPayload = conditions as ComplexConditionPayload;
      if (!complexConditionPayload.level) {
        complexConditionPayload.level = groupLevel + 1;
        if (this.maxBinaryTreeLevel < complexConditionPayload.level) {
          this.maxBinaryTreeLevel = complexConditionPayload.level;
        }
      }
      this.countBinaryTreeLevelsFunction(complexConditionPayload.leftOperand, complexConditionPayload.level);
      this.countBinaryTreeLevelsFunction(complexConditionPayload.rightOperand, complexConditionPayload.level);
    }
  }

  private operandsToConditions(targetNode: ComplexCondition): void {
    targetNode.conditions = targetNode.conditions || [];

    if ((targetNode as any).leftOperand) {
      targetNode.conditions.push((this.handleIfMultipleCondition((targetNode as any).leftOperand) as any));
      delete (targetNode as any).leftOperand;
    }

    if ((targetNode as any).rightOperand) {
      targetNode.conditions.push((this.handleIfMultipleCondition((targetNode as any).rightOperand) as any));
      delete (targetNode as any).rightOperand;
    }
  }

  private handleIfMultipleCondition(operand: RuleCriteria): SimpleConditionPayload | ComplexConditionPayload {
    if ('PRulesMultipleConditionBean' === operand.class) {
      const parsedMultipleCondition = this.parseMultipleConditionToNTreeForm(operand);
      operand = parsedMultipleCondition;
    }
    return operand;
  }

  private parseMultipleConditionToNTreeForm(multipleCondition: MultipleConditionPayload): SimpleConditionPayload {
    const mainCondition = multipleCondition.conditionsList.shift();
    mainCondition.innerConditions = multipleCondition.conditionsList.map(this.removeLeftOperandInInnerCondition) as Array<SimpleConditionPayload>;

    return mainCondition;
  }

  private removeLeftOperandInInnerCondition(innerCondition: SimpleConditionPayload | SimpleCondition): SimpleConditionPayload | SimpleCondition {
    delete innerCondition.leftOperand;
    delete innerCondition.leftOperandType;

    return innerCondition;
  }

  private buildTree(tree: ComplexCondition): void {
    if (tree.logicalOperator && ((tree as any).leftOperand || (tree as any).rightOperand)) {
      tree.logicalOperator = this.logicalOperatorsMap[tree.logicalOperator];
      this.operandsToConditions(tree);
    }

    if (!tree.conditions) {
      return;
    }

    for (let i = 0; i < tree.conditions.length;) {
      if ((tree.conditions[i] as ComplexCondition).logicalOperator &&
        (this.logicalOperatorsMap[(tree.conditions[i] as ComplexCondition).logicalOperator] === this.logicalOperatorsMap[tree.logicalOperator])) {
        const child = tree.conditions[i] as ComplexCondition;
        this.operandsToConditions(child);
        tree.conditions.splice.apply(tree.conditions, [i, 1].concat(child.conditions as Array<any>));
      } else {
        i++;
      }
    }

    for (let j = 0; j < tree.conditions.length; j++) {
      if ((tree.conditions[j] as ComplexCondition).logicalOperator) {
        this.buildTree(tree.conditions[j] as ComplexCondition);
      }
    }
  }

  private removeLevelsFunction(array: ComplexCondition): void {
    if (array.conditions) {
      for (let i = 0; i < array.conditions.length; i++) {
        if ((array.conditions[i] as ComplexCondition).logicalOperator && (array.conditions[i] as ComplexCondition).level &&
          ((array.conditions[i] as ComplexCondition).level !== 0)) {
          delete (array.conditions[i] as ComplexCondition).level;
          this.removeLevelsFunction(array.conditions[i] as ComplexCondition);
        }
      }
    }
  }

  private buildBinaryTreeFromNTree(tree: ComplexCondition | SimpleCondition,
    allBaseConditions?: Array<string>, conditionsBank?: Array<ConditionsBankNode>): RuleCriteria {

    if (!(tree as ComplexCondition).conditions || (tree as ComplexCondition).conditions.length === 0) {
      let simpleCondition = tree as SimpleCondition;

      delete simpleCondition.operator;
      delete simpleCondition.isChecked;

      simpleCondition.class = 'PRulesConditionBean';

      if (simpleCondition.leftOperand != null && typeof simpleCondition.leftOperand === 'object') {
        if (!this.isConditionValid(simpleCondition, allBaseConditions, conditionsBank)) {
          this.allConditionsValid = false;
        }
        simpleCondition.leftOperandType = (simpleCondition.leftOperand as LogicalField).contentType || ContentType.VALUE;
        if ((simpleCondition.leftOperand as ConditionFunction).contentType ===
          ContentType.FUNCTION && !simpleCondition.leftOperand.alias.startsWith(ContentType.BASE_CONDITION)) {
          this.replaceArgumentsAliasToId(simpleCondition.leftOperand as ConditionFunction);
        }
        if ((simpleCondition.leftOperand as LogicalField).contentType === ContentType.FIELD) {
          simpleCondition.leftOperand = '[' + (simpleCondition.leftOperand as LogicalField).id + ']' as any;
        } else {
          simpleCondition.leftOperand = simpleCondition.leftOperand.alias as any;
        }
      }

      this.checkIfRightOperandFill(simpleCondition.rightOperand);

      if (simpleCondition.rightOperand != null && typeof simpleCondition.rightOperand === 'object') {
        simpleCondition.rightOperandType =
          (simpleCondition.rightOperand as LogicalField).contentType || simpleCondition.rightOperand.type as any || ContentType.VALUE;
        if (simpleCondition.rightOperandType === ContentType.FUNCTION) {
          this.replaceArgumentsAliasToId(simpleCondition.rightOperand as ConditionFunction);
        }
        if ((simpleCondition.rightOperand as LogicalField).contentType === ContentType.FIELD) {
          simpleCondition.rightOperand = '[' + (simpleCondition.rightOperand as LogicalField).id + ']' as any;
        } else {
          simpleCondition.rightOperand = simpleCondition.rightOperand.alias as any;
        }
      }

      if (typeof simpleCondition.rightOperand === 'string') { //Right operand should be sent inside an array
        const rightOperandValue = JSON.parse(JSON.stringify(simpleCondition.rightOperand));
        simpleCondition.rightOperand = [] as any;
        simpleCondition.rightOperand = rightOperandValue.split(',') as any;
      }

      if (simpleCondition.innerConditions) {
        simpleCondition = this.createInnerConditionsObjectFromNode(simpleCondition) as any;
      }

      return simpleCondition as any as SimpleConditionPayload;
    }

    const complexCondition = tree as ComplexCondition;

    delete complexCondition.level;
    delete complexCondition.isCheckedCondition;
    delete complexCondition.isParent;

    complexCondition.class = 'SearchExpression';
    (complexCondition as any).leftOperand = this.buildBinaryTreeFromNTree(complexCondition.conditions[0], allBaseConditions, conditionsBank);
    complexCondition.conditions.splice(0, 1);
    if (complexCondition.conditions.length === 1) {
      (complexCondition as any).rightOperand = this.buildBinaryTreeFromNTree(complexCondition.conditions[0], allBaseConditions, conditionsBank);
    } else {
      const obj = {
        class: complexCondition.class,
        logicalOperator: complexCondition.logicalOperator,
        conditions: complexCondition.conditions
      } as ComplexCondition;
      (complexCondition as any).rightOperand = this.buildBinaryTreeFromNTree(obj, allBaseConditions, conditionsBank);
    }

    delete complexCondition.conditions;

    return complexCondition as any as ComplexConditionPayload;
  }

  private isConditionValid(ruleCondition: SimpleCondition, allBaseConditions: Array<string>, conditionsBank: Array<ConditionsBankNode>): boolean {
    let hasBCInConditionsBank = false;
    if (this.invalidConditions.indexOf(ruleCondition.leftOperand.alias) !== -1) {
      return hasBCInConditionsBank;
    }
    this.invalidConditions.push(ruleCondition.leftOperand.alias);
    if (((ruleCondition.leftOperand as ConditionFunction).id) &&
      ((ruleCondition.leftOperand as ConditionFunction).id.indexOf(ContentType.BASE_CONDITION) !== -1)) {
      for (let i = 0; i < allBaseConditions.length; i++) {
        if (allBaseConditions[i] === (ruleCondition.leftOperand as ConditionFunction).functionArguments[0].value) {
          hasBCInConditionsBank = true;
          this.invalidConditions.pop();
          break;
        }
      }
    } else {
      if ((ruleCondition.leftOperand as ConditionFunction).contentType === ContentType.FUNCTION) {
        for (let i = 0; i < conditionsBank.length; i++) {
          if (ruleCondition.leftOperand.alias.startsWith(conditionsBank[i].alias)) {
            let allArgumentsValid = true;
            for (let j = 0; j < (ruleCondition.leftOperand as ConditionFunction).functionArguments.length; j++) {
              if ((ruleCondition.leftOperand as ConditionFunction).functionArguments[j].alias === ContentType.FIELD &&
                !this.isFieldValid((ruleCondition.leftOperand as ConditionFunction).functionArguments[j], conditionsBank)) {
                allArgumentsValid = false;
                break;
              }
            }
            if (allArgumentsValid === true) {
              hasBCInConditionsBank = true;
              this.invalidConditions.pop();
              break;
            }
          }
        }
      } else {

        let leftOperandAlias = ruleCondition.leftOperand.alias.slice(0);
        if (leftOperandAlias.startsWith(':')) {
          leftOperandAlias = leftOperandAlias.slice(1);
        }

        for (let i = 0; i < conditionsBank.length; i++) {
          if (conditionsBank[i].alias == leftOperandAlias) {
            hasBCInConditionsBank = true;
            this.invalidConditions.pop();
            break;
          }
        }
      }
    }
    return hasBCInConditionsBank;
  }

  private replaceArgumentsAliasToId(operand: ConditionFunction): void {
    const functionName = operand.alias.split('(')[0];

    if (operand.alias.split('(')[1] == null) {
      //this is in case we have function name with no parentheses
      //such in case of copy paste of function name
      return;
    }

    let functionArgsStr = operand.alias.split('(')[1].slice(0, -1);
    if (functionArgsStr.length < 1) {
      return;
    }

    const functionArgs = functionArgsStr.split(',');
    for (let i = 0; i < functionArgs.length; i++) {
      const logicalField = this.getFieldAsObject(functionArgs[i].trim()) as LogicalField;
      if (logicalField) {
        functionArgs[i] = '[' + logicalField.id + ']';
      }
    }
    functionArgsStr = functionArgs.join();
    operand.alias = functionName + '(' + functionArgsStr + ')';
  }

  private checkIfRightOperandFill(rightOperand: ConditionOperand | Array<string>): void {
    const rightOperandIsEmpty = rightOperand == null || (rightOperand && (rightOperand as Array<string>).length === 0) ||
      ((rightOperand as ConditionOperand).alias !== undefined &&
        ((rightOperand as ConditionOperand).alias === ' ' || (rightOperand as ConditionOperand).alias === ''));
    if (rightOperandIsEmpty) {
      this.allRightOperandsFill = false;
    }
  }

  private createInnerConditionsObjectFromNode(tree: SimpleCondition): MultipleCondition {
    const innerConditions = [];
    for (let i = 0; i < tree.innerConditions.length; i++) {
      innerConditions.push(
        this.buildBinaryTreeFromNTree(this.removeLeftOperandInInnerCondition(tree.innerConditions[i] as SimpleCondition) as SimpleCondition));
    }

    delete tree.innerConditions;
    innerConditions.unshift(tree);

    return {
      class: 'PRulesMultipleConditionBean',
      conditionsList: innerConditions
    } as MultipleCondition;
  }

  private isFieldValid(fieldName: FunctionArgument, conditionsBank: Array<ConditionsBankNode>): boolean {
    for (let i = 0; i < conditionsBank.length; i++) {
      if (conditionsBank[i].alias === fieldName.value) {
        return true;
      }
    }
    return false;
  }
}
