import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TreeConfig } from '@fgpp-ui/components';
import { Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { MessagesManagementService } from '../../services/messages-management.service';
import { MessagesCenterQueueState } from '../../../core/state/messages-center-queue.state';
import { MessagesCenterQueue } from '../../../core/models/messages-center-queue.model';
import { MessagesCenterQueuesAlias } from '../../../messages-center/models/consts/messages-center-queues-alias.const';
import { FavoritesTree } from '../../../messages-center/favorites/models/favorite-tree.model';
import { MessagesQueue } from '../../models/messages-queue.model';
import { FavoriteActionsService } from '../../../messages-center/favorites/services/favorite-actions.service';
import { FavoritesManagerService } from '../../../messages-center/favorites/services/favorites-manager.service';
import { Favorite } from '../../../messages-center/favorites/models/favorite.model';
import { MessagesQueueType } from '../../models/enums/messages-queue-type.enum';
import { MessagesCenterNavigationService } from '../../../messages-center/messages-center-navigation/services/messages-center-navigation.service';
import { FnUiNavigationActionsTreeComponent } from '../../../shared/fn-ui-navigation-actions-tree/fn-ui-navigation-actions-tree.component';
import { NodeActionClickEvent } from '../../../shared/fn-ui-navigation-actions-tree/models/node-action-click-event.model';
import { MessagesType } from '../../models/enums/messages-type.enum';
import { BreadcrumbsService } from '../../../ui-components/services/breadcrumbs.service';
import { NodeAction } from '../../../shared/fn-ui-navigation-actions-tree/models/node-action.model';

@Component({
  selector: 'app-messages-center-favorites',
  templateUrl: 'messages-center-favorites-queues.component.html',
})
export class MessagesCenterFavoritesQueuesComponent implements OnInit, OnDestroy {

  @Select(MessagesCenterQueueState.getFavoritesQueue) queue$: Observable<MessagesCenterQueue>;

  @ViewChild(FnUiNavigationActionsTreeComponent, { static: true }) navigationActionsTreeComponent: FnUiNavigationActionsTreeComponent<Favorite>;

  treeConfig: Partial<TreeConfig> = {
    childrenProperty: 'favorites',
    expandable: (node) => !!node.favorites,
    selectable: (node) => !node.isNode,
    trackBy: (node) => node,
    expansionTrackBy: (node) => node.id
  };

  tree: FavoritesTree;
  selectedNode: Favorite;
  menuActions: Array<NodeAction>;

  private _subscription = new Subscription();

  constructor(private breadcrumbs: BreadcrumbsService,
              private messagesCenterNavigationService: MessagesCenterNavigationService,
              private favoritesManagerService: FavoritesManagerService,
              private favoriteActionsService: FavoriteActionsService,
              private messagesManagementService: MessagesManagementService, private router: Router) {
    this.tree = this.favoritesManagerService.tree;
    this.menuActions = this.favoriteActionsService.actions;
    this.messagesCenterNavigationService.registerTreeUpdateFunction(function () { });
  }

  ngOnInit(): void {
    this._subscription.add(this.queue$.subscribe((queue: MessagesCenterQueue) => {
      this.selectTreeItem(queue);
    }));
    this._subscription.add(this.favoritesManagerService.favoriteChanged$.subscribe(() => {
      this.onFavoriteChange();
    }));
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  onMenuItemClick($event: NodeActionClickEvent<Favorite>): void {
    $event?.action?.command($event.node);
  }

  private selectTreeItem(queue: MessagesCenterQueue): void {
    if (queue && queue.id) {
      const favoritesQueue = this.findQueue(queue);
      if (!favoritesQueue) {
        this.router.navigate(['/home/page-not-found'], { replaceUrl: true });
        return;
      }
      this.selectedNode = favoritesQueue;
      const messagesQueue = this.handleQueue(favoritesQueue, queue);
      this.messagesManagementService.onFavoritesItemSelect(messagesQueue);
      this.breadcrumbs.setBreadcrumbs(queue, 'breadcrumbs.FAVORITE', messagesQueue.status);
    }
  }

  private findQueue(queue: MessagesCenterQueue): Favorite {
    const favoritesTree = this.favoritesManagerService.tree.favorites.find(
      (tree: FavoritesTree) => tree.alias === MessagesCenterQueuesAlias[queue.entityType]
    ) as FavoritesTree;
    return favoritesTree?.favorites.find((favorite: Favorite) => favorite.id === queue.id) as Favorite;
  }

  private handleQueue(favorite: Favorite, queue: MessagesCenterQueue): MessagesQueue {
    const messagesQueue = favorite as any as MessagesQueue;
    const index = (favorite.alias).indexOf('/');
    messagesQueue.status = favorite.alias.substring(0, index !== -1 ? index : favorite.alias.length);
    messagesQueue.queueType = queue.type;
    return messagesQueue;
  }

  private onFavoriteChange(): void {
    this.navigationActionsTreeComponent.treeComponent.onTreeModelChange();
    this.updateSelectedNode();
  }

  private updateSelectedNode(): void {
    if (this.selectedNode) {
      const queue = {
        id: this.selectedNode.id,
        type: MessagesQueueType.FAVORITE,
        entityType: this.selectedNode.type as string as MessagesType
      };
      this.selectedNode = this.findQueue(queue);
    }
  }
}
