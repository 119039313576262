import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { GridApiService } from './grid-api.service';
import { GridColumnsService } from './grid-columns.service';
import { GridColumn } from '@fgpp-ui/grid';
import { TableRow } from '@fgpp-ui/grid';
import { Resource } from '../models/resource.model';
import { GridColumnsModel } from '../models/grid-columns.model';
import { GridSearchRequest } from '../models/grid-search-request.model';
import { AggregationOption, DataAggregation } from '@fgpp-ui/grid';
import { Injectable } from '@angular/core';

@Injectable()
export abstract class GridHelperService {

  constructor(
    public gridApiService: GridApiService,
    public gridColumnsService: GridColumnsService,
    protected http: HttpClient,
    protected translateService: TranslateService) { }

  getGridData<T extends GridSearchRequest>(searchRequest: T, baseUrl: string, httpParams?: HttpParams, getMetaData?: boolean): Promise<any> {
    if (getMetaData === false) {
      return this.callSearch(searchRequest, baseUrl, httpParams);
    }
    return this.callMetaDataSearch(searchRequest, baseUrl, httpParams);
  }

  getGridMetaData(baseUrl: string, httpParams?: HttpParams): Promise<GridColumnsModel> {
    return this.gridApiService.getMetaData(baseUrl, httpParams).then(metadata => {
      return this.gridColumnsService.getColumns(metadata, baseUrl);
    });
  }

  getGridsColumns(baseUrl: string, httpParams: HttpParams): Promise<any> {
    return this.gridApiService.getGridsColumns(baseUrl, httpParams).then(columns => {
      return this.gridColumnsService.getColumns(columns, baseUrl);
    });
  }

  protected callSearch<T extends GridSearchRequest>(searchRequest: T, baseUrl: string, httpParams?: HttpParams): Promise<any> {
    const columns = this.gridColumnsService.getStoredColumns();
    this.addSortToSearchRequest(columns, searchRequest);
    return this.doSearch(searchRequest, baseUrl, httpParams, columns);
  }

  protected callMetaDataSearch<T extends GridSearchRequest>(searchRequest: T, baseUrl: string, httpParams?: HttpParams): Promise<any> {
    const promises = [];
    promises.push(this.getGridMetaData(baseUrl, httpParams).then((columns: GridColumnsModel) => {
      this.addSortToSearchRequest(columns, searchRequest);
      return this.doSearch(searchRequest, baseUrl, httpParams, columns);
    }));
    promises.push(this.gridApiService.getGridPreferences(baseUrl, httpParams).catch(() => Promise.resolve({ showRefine: true })));
    return Promise.all(promises);
  }

  addSortToSearchRequest<T extends GridSearchRequest>(columns: GridColumnsModel, searchRequest: T): void {
    if (searchRequest.sort && searchRequest.sort.length > 0) {
      return;
    }

    const sortedCol = columns.gridColumns.find(col => col.sort);
    const sort = [];
    if (sortedCol) {
      sort.push({ fieldName: sortedCol.name, sortType: sortedCol.sort.direction });
    }
    searchRequest.sort = sort;
  }

  protected doSearch<T extends GridSearchRequest>(searchRequest: T, baseUrl: string, httpParams: HttpParams, columns: GridColumnsModel): Promise<Resource> {
    return this.gridApiService.getSearch(searchRequest, baseUrl, httpParams).then((rows: TableRow) => {
      const data = this.prepareDataObj(rows, columns.gridColumns, columns.availableColumns);
      return new Promise(function (resolve) {
        resolve(data);
      });
    }, (error: HttpErrorResponse) => {
      console.error(error);
      const data = this.prepareDataObj(null, columns.gridColumns, columns.availableColumns);
      return new Promise(function (resolve) {
        resolve(data);
      });
    });
  }

  protected prepareDataObj(searchResponse, columns?: Array<GridColumn>, availableColumns?: Array<GridColumn>): Resource {
    searchResponse = searchResponse || {};
    searchResponse.rows = searchResponse.rows || [];
    const filteredAmount = searchResponse.baseAmountSum >= 0 ? searchResponse.baseAmountSum : null;
    return {
      gridData: {
        rows: <any>searchResponse.rows,
        dataAggregations: this.mapDataAggregation(searchResponse.aggregates),
        totalCount: <any>searchResponse.totalCount || 0
      },
      columns: columns,
      availableColumns: availableColumns,
      queueTitle: '',
      baseAmountSum: filteredAmount,
      lastUpdate: new Date()
    };
  }

  private mapDataAggregation(aggregates: Array<any>): Array<DataAggregation> {
    if (!aggregates) {
      return [];
    }
    return aggregates.map((aggregate: any) => {
      const dataAggregation = {
        fieldName: aggregate.fieldName,
        options: aggregate.buckets
      };
      this.translateEmptyOptions(dataAggregation.options);
      return dataAggregation;
    });
  }

  private translateEmptyOptions(aggregationOption: Array<AggregationOption>) {
    if (aggregationOption) {
      aggregationOption.forEach((option: AggregationOption) => {
        if (option.key === 'facets.empty.value') {
          option.keyAsText = this.translateService.instant('facets.empty.value');
        }
      });
    }
  }

}

