<div class="rules-association-general-details">
  <app-dh-panel [title]="'mp.single.details.general_details' | translate" [expanded]="true"
                [contentTemplate]="generalDetailsTemplate">
  </app-dh-panel>
</div>
<div>
  <app-dh-panel [title]="'ruleAssociation.association.details' | translate" [expanded]="true" class="rules-association-association-details"
                [previewTemplate]="associationDetailsPreviewTemplate"
                [contentTemplate]="associationDetailsTemplate">
  </app-dh-panel>
</div>
<ng-template #generalDetailsTemplate>
  <div class="row">
    <div class="col-xs-3" automation-id="office-container">
      <label class="dh-form-component-header">{{ 'ruleAssociation.object_office' | translate }}</label>
      <label class="dh-form-field-value ft-ellipsis">{{ rulesAssociationDetails.OFFICE | translate }}</label>
    </div>
    <div class="col-xs-3 col-md-3" automation-id="effective-date-container">
      <label class="dh-form-component-header">{{ 'ruleAssociation.effective_date_message_title' | translate }}</label>
      <app-fn-ui-datetime-picker
        [(ngModel)]="rulesAssociationDetails.EFFECTIVE_DATE"
        [label]="' '"
        [minDate]="MIN_EFFECTIVE_DATE"
        [disabled]="rulesCommonService.ruleAssociationStatuses.isEditMode"
        [ngClass]="'effective-date-input'">
      </app-fn-ui-datetime-picker>
    </div>
    <div class="col-xs-3" automation-id="rec-status-container">
      <label class="dh-form-component-header">{{ 'OBJECT_PRULE_TYPES-REC_STATUS' | translate }}</label>
      <label class="dh-form-field-value ft-ellipsis">{{ rulesAssociationDetails.REC_STATUS | translate }}</label>
    </div>
    <div class="col-xs-3" automation-id="change-status-container">
      <label class="dh-form-component-header">{{ 'OBJECT_PRULE_TYPES-PROFILE_CHANGE_STATUS' | translate }}</label>
      <label
        class="dh-form-field-value ft-ellipsis">{{ rulesAssociationDetails.PROFILE_CHANGE_STATUS | translate }}</label>
    </div>
  </div>
</ng-template>
<ng-template #associationDetailsPreviewTemplate>
  <span class="preview-component-header">
    <label class="dh-form-field-value">{{'ruleAssociation.rule_type_id' | translate}}</label>
    <label class="dh-form-field-value">{{rulesAssociationDetails.RULE_TYPE_NAME}}</label>
  </span>
  <span>
  <label class="dh-form-field-value">{{'ruleAssociation.evaluate_against' | translate}}</label>
  <label class="dh-form-field-value">{{rulesAssociationDetails.selectedObjectTypeValue}}</label>
</span>
</ng-template>
<ng-template #associationDetailsTemplate>
  <div class="col-xs-6 col-md-6 dh-container-content-padding">
    <div class="row">
      <div class="col-xs-4 col-md-4">
        <div id="rule-content-rule-action">
          <app-fn-ui-drill-down [name]="'RULE_ACTION'"
                                [dhDrillDown]="ruleAssociationProfileId"
                                [inputValue]="rulesAssociationDetails.RULE_TYPE_NAME"
                                [inputLabel]="'ruleAssociation.rule_type_id'"
                                [filterParameters]="inParamsActionFilter"
                                [preventMultiSelect]="true"
                                [readOnly]="rulesCommonService.ruleAssociationStatuses.isEditMode  || rulesCommonService.disableAssociationDetails"
                                [entity]="'rulesAssociation'"
                                [required]="true"
                                (rowSelected)="onRuleTypeSelected($event.selectedRows)">
          </app-fn-ui-drill-down>
        </div>
      </div>
      <div class="col-xs-4 col-md-4 col-sm-6">
        <app-fn-ui-drill-down [name]="'RULE_ACTION'"
                              [dhDrillDown]="subTypeProfileId"
                              [inputValue]="rulesAssociationDetails.ruleSubTypeValue"
                              [inputLabel]="'ruleAssociation.rule_sub_type'"
                              [filterParameters]="inParamsSubTypeFilter"
                              [preventMultiSelect]="true"
                              [readOnly]="rulesCommonService.ruleAssociationStatuses.isEditMode ||  rulesAssociationDetails.RULE_SUB_TYPE === '0' "
                              [entity]="'rulesAssociation'"
                              [required]="true"
                              (rowSelected)="onRuleSubTypeSelect($event.selectedRows)">
        </app-fn-ui-drill-down>
      </div>
      <!-- New feature for rule evaluation F8194 -Run Time classification and evaluation per class - START -->
      <div class="col-xs-4 col-md-4 col-sm-6">
        <app-fn-ui-drill-down [name]="'RULE_ACTION'"
                              [dhDrillDown]="layerClassificationProfileId"
                              [inputValue]="rulesAssociationDetails.LAYER_CLASSIFICATION_ALIAS"
                              [inputLabel]="'rule.LAYER_CLASSIFICATION'"
                              [filterParameters]="inParamsLayerClassificationFilter"
                              [preventMultiSelect]="true"
                              [readOnly]="rulesCommonService.disableAssociationDetails || disableFormFields"
                              [required]="true"
                              (rowSelected)="onLayerClassificationSelected($event.selectedRows)">
        </app-fn-ui-drill-down>
        <div class="allowed-levels">*Allowed levels: {{rulesAssociationDetails.CLASSIFICATION_LEVEL}}</div>
      </div>
    </div>
  </div>
  <div class="col-xs-6 col-md-6 col-sm-6" >
    <div class="row">
      <div class="col-xs-4 col-md-4 col-sm-4" automation-id="evaluated-against-select">
        <label class="dh-form-component-header">{{'ruleAssociation.evaluate_against' | translate}}</label>
        <select [className]="rulesCommonService.disableAssociationDetails || disableFormFields ? 'evaluated-against-select-disabled': 'evaluated-against-select'"
                [disabled]="rulesCommonService.disableAssociationDetails || disableFormFields"
                [value]="rulesAssociationDetails.selectedObjectType"
                (change)="setObjectTypeProfile($event)">
          <option *ngFor="let item of rulesCommonService.ObjectsDataOptions" [value]="item.key"> {{item.value}}</option>
        </select>
      </div>
      <div class="col-xs-6 col-md-4 col-sm-6">
        <app-fn-ui-drill-down [name]="'RULE_ACTION'"
                              [dhDrillDown]="objectTypeProfileId"
                              [inputValue]="rulesAssociationDetails.OBJECTID_NAME"
                              [inputLabel]="'ruleAssociation.AssociatedTo'"
                              [filterParameters]="inParamsRuleTypeFilter1"
                              [preventMultiSelect]="true"
                              [readOnly]="rulesCommonService.disableAssociationDetails || disableFormFields"
                              [entity]="'rulesAssociation'"
                              [required]="true"
                              (rowSelected)="onObjectTypeProfileIdSelected($event.selectedRows)">
        </app-fn-ui-drill-down>
      </div>
      <div class="col-xs-2 col-md-2 col-sm-2" automation-id="description">
        <label class="dh-form-component-header">{{'ruleAssociation.description' | translate}}</label>
        <label class="dh-form-field-value rules-association-label-value ft-ellipsis">{{rulesAssociationDetails.OBJECTID_DESCR}}</label>
      </div>
    </div>
  </div>
</ng-template>
