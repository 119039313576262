import { Component } from '@angular/core';
import { SystemAccountGroupsService } from '../../../services/system-account-groups.service';

@Component({
  selector: 'app-system-account-groups-navigation',
  templateUrl: './system-account-groups-navigation.component.html',
  styleUrls: ['./system-account-groups-navigation.component.scss']
})
export class SystemAccountGroupsNavigationComponent {

  constructor(public systemAccountGroupsService: SystemAccountGroupsService) { }

}
