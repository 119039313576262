import { CdkDragMove, CdkDragRelease, CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DragDropService {

  currentHoverDropListId?: string;

  constructor(@Inject(DOCUMENT) private document: Document) { }

  onDragMove($event: CdkDragMove<any>) {
    const elementFromPoint = this.document.elementFromPoint(
      $event.pointerPosition.x,
      $event.pointerPosition.y
    );

    if (!elementFromPoint) {
      this.currentHoverDropListId = undefined;
      return;
    }

    const dropList = elementFromPoint.classList.contains('cdk-drop-list')
      ? elementFromPoint
      : elementFromPoint.closest('.cdk-drop-list');

    if (!dropList) {
      this.currentHoverDropListId = undefined;
      return;
    }

    this.currentHoverDropListId = dropList.id;
  }

  onDragRelease($event: CdkDragRelease) {
    this.currentHoverDropListId = undefined;
  }

  onDrop($event: CdkDragDrop<any>, getList?: (data: any) => Array<any>): void {
    if ($event.previousContainer === $event.container) {
      const list = getList ? getList($event.container.data) : $event.container.data;
      moveItemInArray(list, $event.previousIndex, $event.currentIndex);
    } else {
      const previousList = getList ? getList($event.previousContainer.data) : $event.previousContainer.data;
      const currentList = getList ? getList($event.container.data) : $event.container.data;
      transferArrayItem(previousList, currentList, $event.previousIndex, $event.currentIndex);
    }
  }

}
