import { Injectable, Injector } from '@angular/core';
import { IMapperService } from '../../../shared/interfaces/mapper-service.interface';
import { NavigationTab, NavigationMap, MainTab, NavigationTabType, NavigationItem } from '../models';
import { TAB_HANDLER_TOKENS } from '../models/consts/tab-handler-tokens.const';
import { CallerQueuesTree, Sitemap, SitemapComponent, SitemapNode, SitemapTreeItem } from '../sitemap/models';
import { ITabHandlerService } from './tab-handlers/tab-handler-service.interface';

@Injectable()
export class NavigationMapperService implements IMapperService<Sitemap, NavigationMap> {

  private tabHandlerService: ITabHandlerService;

  constructor(private injector: Injector) { }

  map(sitemap: Sitemap): NavigationMap {
    const map = new Map<MainTab, NavigationTab>();
    sitemap.components.forEach((component: SitemapComponent) => {
      this.tabHandlerService = this.injector.get<ITabHandlerService>(TAB_HANDLER_TOKENS[component.type]);
      map.set(<MainTab>component.id, this.mapTab(component));
    });
    this.tabHandlerService = null;
    return map;
  }

  mapCallbackQueue(callerTree: CallerQueuesTree, messagesCenterComponent: SitemapComponent): Map<string, NavigationItem> {
    const items = new Map<string, NavigationItem>();
    const moduleName = callerTree.id;
    this.tabHandlerService = this.injector.get<ITabHandlerService>(TAB_HANDLER_TOKENS[messagesCenterComponent.type]);
    const parents = [messagesCenterComponent];
    callerTree?.nodes?.forEach((node: SitemapNode) => {
      this.setItems(node, items, moduleName, parents);
    });
    return items;
  }

  private mapTab(component: SitemapComponent): NavigationTab {
    const id = <MainTab>component.id;
    const alias = component.alias;
    const type = <NavigationTabType>component.type;
    const parents = [component];
    const items = this.getTabItems(component, parents);
    return { id, alias, type, items };
  }

  private getTabItems(component: SitemapComponent, parents: Array<SitemapTreeItem>): Map<string, NavigationItem> {
    const items = new Map<string, NavigationItem>();
    this.tabHandlerService.moduleNames.forEach((moduleName: string) => {
      const module = component.modules[moduleName];
      module?.nodes?.forEach((node: SitemapNode) => {
        this.setItems(node, items, moduleName, parents);
      });
    });
    return items;
  }

  private setItems(node: SitemapNode, items: Map<string, NavigationItem>, moduleName: string, parents: Array<SitemapTreeItem>): void {
    if (this.tabHandlerService.shouldMapped(node)) {
      const key = this.tabHandlerService.getKey(node, moduleName);
      items.set(key, this.getNavigationItem(node, parents, moduleName));
    }

    node.nodes?.forEach((innerNode: SitemapNode) => {
      this.setItems(innerNode, items, moduleName, parents.concat(node));
    });
  }

  private getNavigationItem(node: SitemapNode, parents: Array<SitemapTreeItem>, moduleName: string): NavigationItem {
    return {
      id: node.id,
      alias: this.tabHandlerService.getAlias(node),
      icon: this.tabHandlerService.getIcon(node, parents),
      path: this.tabHandlerService.getPath(parents),
      routerLink: this.tabHandlerService.getRouterLink(node, parents, moduleName),
      stateName: this.tabHandlerService.getStateName(node, parents, moduleName),
      stateParams: this.tabHandlerService.getStateParams(node, parents),
      data: node.data
    };
  }

}
