import {
  AfterViewInit, ChangeDetectorRef,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
  OnDestroy
} from '@angular/core';
import { PopoverComponent } from '../popover/popover.component';
import { ToursService } from '../../services/tours.service';
import { TourStepModel } from '../../models/tour-step.model';

@Component({
  selector: 'app-tour-item',
  templateUrl: './tour-item.component.html',
  styleUrls: ['./tour-item.component.scss']
})
export class TourItemComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() tourId;
  @Input() tourStepNumber;
  @Input() scrollToView;

  @Input() targetAt;
  @Input() arrowWidth = 10;

  public tourItem: TourStepModel;
  public isTourAbortable: boolean;
  public shouldShowPopover = false;
  public isNextLabelEnabled: boolean;

  private factory: ComponentFactory<PopoverComponent>;
  private tourItemModalComponent: ComponentRef<PopoverComponent>;

  @ViewChild('container', { read: ViewContainerRef }) container;

  constructor(private tours: ToursService, private element: ElementRef,
              private resolver: ComponentFactoryResolver, private changeDetectorRef: ChangeDetectorRef) {
    this.factory = this.resolver.resolveComponentFactory(PopoverComponent);
  }

  isVisible(): boolean {
    const elementTagName = this.element.nativeElement.tagName.toLowerCase();
    const parentElement = this.element.nativeElement.parentElement;
    const style = window.getComputedStyle(parentElement);
    const rect = parentElement.getBoundingClientRect();

    return (window.getComputedStyle(this.element.nativeElement).display !== 'none' ||
      (elementTagName === 'data-dh-tour-item' || elementTagName === 'dh-tour-item' ||
        elementTagName === 'app-tour-item') &&
      style.visibility !== 'hidden' && !!(rect.bottom || rect.top || rect.height || rect.width));
  }

  ngOnInit(): void {
    const tourItem = this.tours.getTourStep(this.tourId, this.tourStepNumber);
    if (!tourItem) {
      return null;
    }
    this.tourItem = tourItem;

    this.isTourAbortable = this.tours.isTourAbortable(this.tourId);
    this.isNextLabelEnabled = this.tourItem.nextLabel && this.tourItem.nextLabel.length > 0 ? true : false;

    this.tours.registerElement(this.tourId, this.tourStepNumber, this);
  }

  ngAfterViewInit() {
    if (!this.tourItem) {
      return;
    }
    setTimeout(() => {
      this.tours.startTour(this.tourId, this.tourStepNumber, false);
    }, 0);
  }

  ngOnDestroy() {
    this.tours.unregisterElement(this.tourId, this.tourStepNumber);
  }

  showStep(isFirstStep: boolean): void {
    this.shouldShowPopover = true;
    if (this.scrollToView) {
      this.scrollToView.scrollIntoView(true);
    }

    if (!this.container) {
      this.changeDetectorRef.detectChanges();
    }
    this.tourItemModalComponent = this.container.createComponent(this.factory);
    this.tourItemModalComponent.instance.tourItem = this.tourItem;
    this.tourItemModalComponent.instance.isTourAbortable = this.isTourAbortable;
    this.tourItemModalComponent.instance.isNextLabelEnabled = this.isNextLabelEnabled;
    this.tourItemModalComponent.instance.isPrevLabelEnabled = !isFirstStep;
    this.tourItemModalComponent.instance.shouldShowPopover = this.shouldShowPopover;
    if (this.targetAt) {
      this.targetAt['_elementRef'] = this.element;
    }
    this.tourItemModalComponent.instance.targetAt = this.targetAt;
    this.tourItemModalComponent.instance.arrowWidth = this.arrowWidth;

    this.tourItemModalComponent.instance.onOkHandler = this.onOkHandler.bind(this);
    this.tourItemModalComponent.instance.onPostponeHandler = this.onPostponeHandler.bind(this);
    this.tourItemModalComponent.instance.onPrevHandler = this.onPrevHandler.bind(this);
    this.tourItemModalComponent.instance.onNextHandler = this.onNextHandler.bind(this);
    this.tourItemModalComponent.instance.onCloseHandler = this.onCloseHandler.bind(this);
  }

  @HostListener('document:keydown.escape', ['$event'])
  handleEScEvent(event: KeyboardEvent) {
    if (this.shouldShowPopover) {
      this.onCloseHandler();
    }
  }

  closeStep(): void {
    this.shouldShowPopover = false;
    this.tourItemModalComponent.instance.shouldShowPopover = this.shouldShowPopover;
  }

  onCloseHandler(): void {
    window.setTimeout(() => {
      this.tours.requestToAbortTour(this.tourId, this.tourStepNumber);
    }, 0);
  }

  onPostponeHandler(): void {
    window.setTimeout(() => {
      this.tours.requestToPostponeTour(this.tourId, this.tourStepNumber);
    }, 0);

  }

  onOkHandler(event): void {
    window.setTimeout(() => {
    event.stopPropagation();
    event.preventDefault();
    this.shouldShowPopover = false;
    this.tours.markAsSeen(this.tourId);
    }, 0);
  }

  onPrevHandler(event): void {
    window.setTimeout(() => {
      event.stopPropagation();
      event.preventDefault();
      this.shouldShowPopover = false;
      this.tourItemModalComponent.instance.shouldShowPopover = this.shouldShowPopover;
      this.tours.goToPreviousStep(this.tourId, this.tourStepNumber);
    }, 0);
  }

  onNextHandler(event): void {
    window.setTimeout(() => {
      event.stopPropagation();
      event.preventDefault();
      this.shouldShowPopover = false;
      this.tourItemModalComponent.instance.shouldShowPopover = this.shouldShowPopover;
      this.tours.goToNextStep(this.tourId, this.tourStepNumber);
      }, 0);
  }
}
