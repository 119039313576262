import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ExternalFilterGridService } from './external-filter-grid.service';
import { ExternalFilterGridHelperService } from '../../profiles/services/external-filter-grid-helper.service';
import { GridConfigService } from './grid-config.service';
import { SearchRequestBuilderService } from './search-request-builder.service';
import { ProfilesSearchRequest } from '../../profiles/models/profiles-search-request.type';
import { FormatsService } from '../../shared/services/formats.service';
import { FnUiDialogService } from '../../shared/fn-ui-dialog/services/fn-ui-dialog.service';
import { GridConfig } from '@fgpp-ui/grid';
import { Entity } from '../../shared/models/enums/entity.enum';
import { SorterService } from './sorter.service';
import { PaginationService } from './pagination.service';
import { DialogDrillDownComponent } from '../components/drill-down-dialog/drill-down-dialog.component';
import { PROFILE_UID } from '../../profiles/services/profiles-management.service';
import { UserSettingsService } from '../../core/user-settings/services/user-settings.service';
import { Resource } from '../models/resource.model';

@Injectable({
  providedIn: 'root'
})
export class DrillDownGridService extends ExternalFilterGridService {

  constructor(
    userSettingsService: UserSettingsService,
    public externalFilterGridHelperService: ExternalFilterGridHelperService,
    gridConfigService: GridConfigService,
    searchRequestBuilderService: SearchRequestBuilderService<ProfilesSearchRequest>,
    formatsService: FormatsService,
    private dialogService: FnUiDialogService) {
    super(userSettingsService, externalFilterGridHelperService, gridConfigService, searchRequestBuilderService, formatsService);
  }

  get keyId() {
    if (this.externalFilterGridData?.keyId && this.gridData.columns) {
      const column = this.gridData.columns.find
        (c => c.name === this.externalFilterGridData.keyId || c.name === this.externalFilterGridData.keyId?.replace('.', '-'));
      if (column) {
        return column.name;
      }
    }
    return this.gridData.columns.length ? this.gridData.columns[0].name : PROFILE_UID;
  }

  getGridConfig(): GridConfig {
    const gridConfig = super.getGridConfig();
    if (this.externalFilterGridData.isSelectType) {
      gridConfig.table.refineFiltering.enableRefine = false;
      gridConfig.filterButtons.refineFilterBtnConfig.showButton = false;
    }
    return gridConfig;
  }

  getBaseUrl(selectType: boolean, profileId: string, entity: Entity) {
    if (!selectType) {
      return 'do/PROFILE/' + profileId + '/';
    }
    return 'do/' + entity + '/getSelect/';
  }

  //TODO: remove after implementing sort and pagination on server
  nextGridData() {
    if (this.externalFilterGridData.isSelectType) {
      const resource = { ...this.gridData };
      resource.gridData = { ...resource.gridData };
      const columnWithSort = resource.columns.find(column => column.sort);
      if (columnWithSort) {
        resource.gridData.rows = SorterService.sort(resource.gridData.rows, columnWithSort);
      }
      const paginatorData = { pageSize: this.gridConfig.paginator.size, pageNumber: this.searchRequest.pageNo };
      resource.gridData.rows = PaginationService.getPageRows(resource.gridData.rows, paginatorData);
      this.gridData$.next(resource);
    } else {
      super.nextGridData();
    }
  }

  getAdditionalParameters(filter, isSelectType: boolean) {
    const additionalParams = {
      recStatus: 1
    };
    if (filter) {
      Object.keys(filter).forEach(key => {
        if (key !== 'searchFilter') {
          additionalParams[key] = filter[key];
        }
      });
      const searchFilter = filter.searchFilter;
      //on select type, searchFilters are the additional parameters
      if (isSelectType && searchFilter) {
        Object.keys(searchFilter).forEach(key => {
          additionalParams[key] = searchFilter[key];
        });
        delete additionalParams['recStatus']; //todo fix code
      }
    }

    return additionalParams;
  }

  openDialog(data): MatDialogRef<DialogDrillDownComponent> {
    this.externalFilterGridData = data;
    const config = this.getDialogConfig(data);
    return this.dialogService.open(DialogDrillDownComponent, config);
  }

  protected resolveGridData(resource: Resource) {
    super.resolveGridData(resource);
    this.setSelectedRows();
  }

  private setSelectedRows() {
    let newSelectedRows = false;
    if (!!this.gridData) {
      const key = this.keyId;
      this.externalFilterGridData.inputValue?.split(',')?.forEach((value) => {
        const row = this.gridData.gridData.rows.find(r => r[key] === value);
        const selectedRowIndex = this.externalFilterGridData.selectedRows.findIndex(r => r[this.externalFilterGridData.keyId] === value);
        if (row && selectedRowIndex !== -1) {
          this.externalFilterGridData.selectedRows.splice(selectedRowIndex, 1);
          this.externalFilterGridData.selectedRows.push(row);
          newSelectedRows = true;
        }
      });
      if (newSelectedRows) {
        this.externalFilterGridData.selectedRows = [...this.externalFilterGridData.selectedRows];
      }
    }
  }
}
