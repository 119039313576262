import { Component, inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router, RouterStateSnapshot } from '@angular/router';
import { TaskbarItemConstants } from '../base-view/config/taskbar-item-constants';
import { ProfilesNavigationTreeComponent } from '../profiles/components/profiles-navigation-tree/profiles-navigation-tree.component';

export const TaskBarItemComponentResolver: ResolveFn<Component> =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Component => {

    const router = inject(Router);

    const parent = route.parent.routeConfig.path;
    const taskBarItem = route.params.taskBarItem;
    let component;
    if (route.data.type !== 'profiles') {
      component = TaskbarItemConstants.taskBarItemCodeToComponent[parent][taskBarItem];
    } else {
      component = ProfilesNavigationTreeComponent;
    }

    if (!component) {
      const error = 'no form component found, component code = ' + [parent][taskBarItem];
      console.log(error);
      router.navigate(['/home/page-not-found'], { replaceUrl: true });
      return;
    }
    return component;
  };
