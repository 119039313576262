import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '@fgpp-ui/components';
import { AuthenticationApiService } from '../../services/authentication-api.service';
import { VascoActivationMessageResponse } from '../../models/vasco-activation-message-response.model';

@Component({
  selector: 'app-assign-soft-token',
  templateUrl: './assign-soft-token.component.html',
  styleUrls: ['./assign-soft-token.component.scss']
})
export class AssignSoftTokenComponent implements OnInit {

  form: FormGroup;
  message: string;

  private _userId: string;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private formBuilder: FormBuilder,
              private notificationService: NotificationService,
              private authenticationApiService: AuthenticationApiService) {
    this.message = this.router.getCurrentNavigation().extras?.state?.message || null;
    this._userId = this.router.getCurrentNavigation().extras?.state?.userId || null;
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      signature: ['', Validators.required]
    });
  }

  onBackClick(): void {
    this.router.navigateByUrl('user/signin');
  }

  onFormSubmit(): boolean {
    this.authenticationApiService.vascoCrontoValidate({
      userId: this._userId,
      signature: this.form.value.signature
    }).subscribe({
      next: (response: VascoActivationMessageResponse) => {
        this.router.navigate(['user/activateSoftToken'], {
          state: {
              instanceActivationMessage: response.instanceActivationMessage,
              sequenceNumber: response.sequenceNumber,
              userId: this._userId
          }
        })
      },
      error: (response: HttpErrorResponse) => {
        this.notificationService.error(response.error.type);
      }
    });
    return false;
  }

}
