import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, of } from 'rxjs';
import { RuleType } from '../models/rule-type.model';
import { IRuleTypesApiService } from './interfaces/rule-types-api-service.interface';
import { ProfilesManagementService } from '../../profiles/services/profiles-management.service';
import { ProfilesSearchRequest } from '../../profiles/models/profiles-search-request.type';
import { GridSearchResponse } from '../../grid/models/grid-search-response.model';
import { TableRow } from '@fgpp-ui/grid';
import { RuleTypePermissions } from '../models/rule-type-permissions.model';
import { PRULE_TYPES_RULE_TYPE_ID, PRULE_TYPES_RULE_TYPE_NAME, RULE_TYPE_TRANSLATION_PREFIX } from '../models/consts/rule-type.consts';

@Injectable()
export class RuleTypesApiService implements IRuleTypesApiService {

  private get ruleTypesUrl(): string {
    return 'do/PROFILE/543/search';
  }

  private get permissionsUrl(): string {
    return 'do/rules/ruleTypePermissions';
  }

  private get ruleTypesSearchRequest(): ProfilesSearchRequest {
    const searchRequest = this.profilesManagementService.getDefaultSearchRequest();
    searchRequest.pageSize = null;
    return searchRequest;
  }

  private _searchResponse: GridSearchResponse;

  constructor(private http: HttpClient,
              private profilesManagementService: ProfilesManagementService) { }

  get(): Observable<Array<RuleType>> {
    const observable = this.search();
    return observable.pipe(map((searchResponse: GridSearchResponse) => {
      return this.mapRuleTypes(searchResponse);
    }));
  }

  search(): Observable<GridSearchResponse> {
    if (this._searchResponse) {
      return of(JSON.parse(JSON.stringify(this._searchResponse)));
    }
    return this.doSearch();
  }

  getPermissions(): Observable<RuleTypePermissions> {
    return this.http.get<RuleTypePermissions>(this.permissionsUrl);
  }

  private doSearch(): Observable<GridSearchResponse> {
    return this.http.post<GridSearchResponse>(this.ruleTypesUrl, this.ruleTypesSearchRequest).pipe(map((response: GridSearchResponse) => {
      this._searchResponse = response;
      return response;
    }));
  }

  private mapRuleTypes(response: GridSearchResponse): Array<RuleType> {
    return response.rows.map((row: TableRow) => {
      return {
        id: row[PRULE_TYPES_RULE_TYPE_ID],
        name: row[PRULE_TYPES_RULE_TYPE_NAME],
        alias: RULE_TYPE_TRANSLATION_PREFIX + row[PRULE_TYPES_RULE_TYPE_ID]
      };
    });
  }

}
