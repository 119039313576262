import { Component } from '@angular/core';
import { SelectComponent } from '@fgpp-ui/components';
import { take } from 'rxjs';
import { IValueChange } from '../../../core/interfaces/form-value-change.interface';
import { BFOnFormLoadComplete, BFOnValueChange } from '../../../core/interfaces/sdk-profile-hooks.interface';
import { ProfileFormTemplateProviders } from '../../../core/templates/profile-form.template.providers';
import { AbstractProfileFormComponent } from '../../abstract/abstract-profile/abstract-profile-form.component';
import { ProfileLayoutType } from '../../enums/profile-layout-type.enum';
import { ProfileMode } from '../../enums/profile-mode.enum';
import { CustomFieldSettings } from '../../models/custom-field-settings.model';
import { FieldLevel, FieldType, UsageType } from './enums';
import { CustomFieldAttributesState } from './consts/custom-field-attributes-state.const';

const GLOBAL_OFFICE = '***';
const SD_PROFILES_PROFILE_ID = 'SD_PROFILES.PROFILE_ID';

@Component({
  selector: 'app-custom-field-settings',
  templateUrl: '../../../core/templates/profile-form.template.html',
  styleUrls: ['../../../core/templates/profile-form.template.scss'],
  providers: ProfileFormTemplateProviders
})
export class CustomFieldSettingsComponent extends AbstractProfileFormComponent implements BFOnFormLoadComplete, BFOnValueChange {


  fgppFormLoadComplete(): void {
    if (this.mode === ProfileMode.CREATE) {
      this.handleFieldLevelControl();
      this.handleCustCodeControl();
      this.handleProfileAliasControl();
    }
		if (this.layoutMode !== ProfileLayoutType.VIEW) {
			this.handleCustomFieldAttributeControls();
		}
  }

  fgppValueChanges(change: IValueChange): void {
    switch (change.control) {
      case CustomFieldSettings.USAGE_TYPE:
        this.handleProfileAliasControl();
        this.handleIsAutoExposeMsgUdfControl();
        this.handleFieldLevelControl();
        return;
      case CustomFieldSettings.UDF_TYPE:
        this.handleCustCodeControl();
        return;
      case CustomFieldSettings.V_PROFILE_ALIAS:
        this.populateProfileId();
        return;
      case CustomFieldSettings.DATA_TYPE:
        this.handleCustomFieldAttributeControls();
        return;
    }
  }

  private handleFieldLevelControl(): void {
    const fieldLevelControl = this.formMgr.get(CustomFieldSettings.UDF_TYPE);
    const isGlobalOffice = this.formMgr.get(CustomFieldSettings.OFFICE).getValue() === GLOBAL_OFFICE;
    if (isGlobalOffice) {
      fieldLevelControl.setValue(FieldLevel.SYSTEM).disable();
      return;
    }
    const isProfileUsageType = this.formMgr.get(CustomFieldSettings.USAGE_TYPE).getValue() === UsageType.PROFILE;
    if (isProfileUsageType) {
      fieldLevelControl.setValue(FieldLevel.OFFICE).disable();
      return;
    }
    fieldLevelControl.enable();
    const selectComponent = fieldLevelControl.getLayoutControl<SelectComponent>();
    if (!selectComponent.options.length) {
      selectComponent.dataLoaded.pipe(take(1)).subscribe(() => {
        const systemOption = selectComponent.options.find((option) => option.key === FieldLevel.SYSTEM);
        if (systemOption) {
          systemOption.disabled = true;
        }
      });
    }
  }

  private handleProfileAliasControl(): void {
    const usageType = this.formMgr.get(CustomFieldSettings.USAGE_TYPE).getValue();
    if (usageType === UsageType.PROFILE) {
      this.formMgr.get(CustomFieldSettings.V_PROFILE_ALIAS).enable().markAsRequired();
    } else {
      this.formMgr.get(CustomFieldSettings.V_PROFILE_ALIAS).disable().markAsOptional().reset();
    }
  }

  private handleCustCodeControl(): void {
    const fieldLevel = this.formMgr.get(CustomFieldSettings.UDF_TYPE).getValue();
    const custCodeControl = this.formMgr.get(CustomFieldSettings.CUST_CODE);
    if (fieldLevel === FieldLevel.CLIENT) {
      custCodeControl.enable().markAsRequired().reset();
    } else {
      custCodeControl.disable().markAsOptional();
      if (fieldLevel) {
        const officeCustCode = this.formMgr.get(CustomFieldSettings.OFFICE_CUST_CODE).getValue();
        custCodeControl.setValue(officeCustCode);
      } else {
        custCodeControl.reset();
      }
    }
  }

  private handleCustomFieldAttributeControls(): void {
    const fieldType = this.formMgr.get(CustomFieldSettings.DATA_TYPE).getValue();
    const customFieldAttributesState = CustomFieldAttributesState[fieldType] || CustomFieldAttributesState[''];
    customFieldAttributesState.mandatory?.forEach((field: string) => this.formMgr.get(field).enable().markAsRequired());
    customFieldAttributesState.optional?.forEach((field: string) => this.formMgr.get(field).enable().markAsOptional());
    customFieldAttributesState.disable?.forEach((field: string) => {
      const control = this.formMgr.get(field);
      control.disable().markAsOptional();
      if (this.mode === ProfileMode.CREATE) {
        control.reset();
      }
    });
    this.handleIsAutoExposeMsgUdfControl();
  }

  private handleIsAutoExposeMsgUdfControl(): void {
    const isAutoExposeMsgUDFControl = this.formMgr.get(CustomFieldSettings.IS_AUTO_EXPOSE_MSG_UDF);
    const usageType = this.formMgr.get(CustomFieldSettings.USAGE_TYPE).getValue();
    const fieldType = this.formMgr.get(CustomFieldSettings.DATA_TYPE).getValue();
    if (usageType === UsageType.MESSAGE && fieldType === FieldType.STRING) {
      isAutoExposeMsgUDFControl.enable();
    } else {
      isAutoExposeMsgUDFControl.disable();
      if (this.mode === ProfileMode.CREATE) {
        isAutoExposeMsgUDFControl.reset();
      };
    }
  }

  private populateProfileId(): void {
    const profileData = this.getDrilldownData(CustomFieldSettings.V_PROFILE_ALIAS);
    if (profileData) {
      this.formMgr.get(CustomFieldSettings.PROFILE_ID).setValue(profileData[SD_PROFILES_PROFILE_ID]);
    }
  }
}
