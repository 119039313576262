<div class="conditions-tree-header-wrapper">
  <span class="conditions-tree-header">Conditions</span>
  <button mat-icon-button dense color="primary" azure-id="close-filter-conditions" class="close-condition-btn" (click)="closeButtonClicked()">
    <app-fn-ui-icon icon="close"></app-fn-ui-icon>
  </button>
</div>

<div class="search-filter" automation-id="rule-conditions-search-filter">
  <app-fn-ui-input [(ngModel)]="searchFilter"
                   [placeholder]="'rule_filter' | translate"
                   prefixIcon="search"
                   floatLabel="never">
  </app-fn-ui-input>
</div>

<app-tour-item [tourId]="'rulesScreen'" [tourStepNumber]="7"></app-tour-item>
<app-tour-item [tourId]="'rulesScreen'" [tourStepNumber]="9"></app-tour-item>
<app-tour-item [tourId]="'rulesScreen'" [tourStepNumber]="8"></app-tour-item>

<div class="ft-tree conditions-bank scrollable-content">
  <app-conditions-bank-tree [treeModel]="ruleService.conditionsBank?.nodes"
                            [searchTerm]="searchFilter"
                            [ruleDisabled]="!ruleCommonService.focusedOperand  || (ruleCommonService.isReadOnly || ruleConditionsService.checkedConditionsCount > 0)"
                            (selectionChanged)="onTreeItemSelect($event)"
                            (mousedown)="onTreeItemMouseDown()">
  </app-conditions-bank-tree>
</div>
