import { NgModule } from '@angular/core';

import { MultiTabsActionsService } from './services/multi-tabs-actions.service';
import { MultiTabsManagerService } from './services/multi-tabs-manager.service';
import { MultiTabsStorageService } from './services/multi-tabs-storage.service';

@NgModule({
    providers: [
        MultiTabsActionsService,
        MultiTabsManagerService,
        MultiTabsStorageService
    ]
})
export class MultiTabsModule { }
