<app-fn-ui-dialog class="base-condition-found-modal">

  <ng-template #header>
    <div class="base-condition-found-header">
      <span class="title" [translate]="'rules.verification_base_condition.title'"></span>
      <app-fn-ui-close-icon mat-dialog-close></app-fn-ui-close-icon>
    </div>
    <mat-divider></mat-divider>
  </ng-template>

  <ng-template #body>
    <div class="base-condition-found-body">
      <p>{{params.notification}}</p>
      <br>
      <p>{{params.answer}}</p>
    </div>
  </ng-template>

  <ng-template #footer>
    <div class="base-condition-found-footer">

      <app-fn-ui-button mat-dialog-close>
        <span [translate]="params.cancelButton"></span>
      </app-fn-ui-button>

      <app-fn-ui-button type="flat" color="primary"
                       mat-dialog-close="ok">
        <span [translate]="params.okButton"></span>
      </app-fn-ui-button>
    </div>
  </ng-template>

</app-fn-ui-dialog>
