export enum InnerGridTabs {
  BULKS = 'bulks',
  BATCHES = 'batches',
  MESSAGES = 'messages',
  LINKED_FILES = 'linkedFiles',
  INTERNAL_BATCHES = 'internalBatches',
  ERROR_LOG = 'errorLog',
  RULE_LOG = 'ruleLog',
  NOTES = 'notes',
  AUDIT_TRAIL = 'auditTrail'
}
