import { ChangeDetectionStrategy, Component, ContentChild, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MdePopover, MdePopoverTrigger } from '@material-extended/mde';
import { MdePopoverPositionX, MdePopoverPositionY, MdePopoverTriggerEvent } from '@material-extended/mde/lib/popover/popover-types';

@Component({
  selector: 'app-fn-ui-popover',
  templateUrl: './fn-ui-popover.component.html',
  styleUrls: ['./fn-ui-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FnUiPopoverComponent implements OnInit {

  @Input() popoverTriggerColor: string;
  @Input() popoverTriggerClass: string;
  @Input() popoverTargetAt: TemplateRef<ElementRef>;
  @Input() popoverPositionX: MdePopoverPositionX = 'after';
  @Input() popoverPositionY: MdePopoverPositionY = 'below';
  @Input() popoverTriggerOn: MdePopoverTriggerEvent = 'click';
  @Input() popoverEnterDelay: number;
  @Input() popoverLeaveDelay: number;
  @Input() popoverOverlapTrigger = false;
  @Input() popoverOffsetX: number;
  @Input() popoverOffsetY: number;
  @Input() popoverArrowOffsetX: number;
  @Input() popoverArrowWidth = 10;
  @Input() popoverArrowColor = 'white';
  @Input() popoverCloseOnClick: boolean;
  @Input() popoverBackdropCloseOnClick: boolean;
  @Input() popoverFocusTrapEnabled: boolean;
  @Input() popoverFocusTrapAutoCaptureEnabled: boolean;
  @Input() popoverClass: string;

  @Output() popoverOpened = new EventEmitter<void>();
  @Output() popoverClosed = new EventEmitter<void>();

  @ContentChild('popoverTriggerContent', { static: true }) popoverTriggerTemplate: TemplateRef<ElementRef>;
  @ContentChild('popoverContent', { static: true }) popoverTemplate: TemplateRef<ElementRef>;
  @ContentChild('popoverHeader', { static: true }) popoverHeaderTemplate: TemplateRef<ElementRef>;
  @ContentChild('popoverBody', { static: true }) popoverBodyTemplate: TemplateRef<ElementRef>;
  @ContentChild('popoverFooter', { static: true }) popoverFooterTemplate: TemplateRef<ElementRef>;

  @ViewChild('defaultPopoverContent', { static: true }) defaultPopoverTemplate: TemplateRef<ElementRef>;

  @ViewChild(MdePopoverTrigger, { static: true }) popoverTrigger: MdePopoverTrigger;
  @ViewChild(MdePopover, { static: true }) popover: MdePopover;

  get popoverOpen(): boolean {
    return this.popoverTrigger.popoverOpen;
  }

  ngOnInit(): void {
    if (!this.popoverTemplate) {
      this.popoverTemplate = this.defaultPopoverTemplate;
    }
    if (this.popoverTriggerOn === 'none') {
      (this.popoverTrigger._elementRef.nativeElement as HTMLInputElement).tabIndex = -1;
    }
  }

  togglePopover(): void {
    this.popoverTrigger.togglePopover();
  }

  openPopover(): void {
    this.popoverTrigger.openPopover();
  }

  closePopover(): void {
    this.popoverTrigger.closePopover();
  }

  updatePopoverPosition(): void {
    this.popoverTrigger['_overlayRef']?.updatePosition();
  }

  onPopoverOpen(): void {
    this.popoverOpened.emit();
  }

  onPopoverClose(): void {
    this.popoverClosed.emit();
  }

}
