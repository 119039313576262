import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { SideNavigationItem } from '../../core/navigation/task-bar/models/side-navigation-item.model';
import { MenuService } from '../../core/services/menu.service';

export const ActiveMenuTabResolver: ResolveFn<SideNavigationItem> =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): SideNavigationItem => {
    const menuService = inject(MenuService);
    return menuService.getTopLevelMenu();
  };
