import { InjectionToken } from '@angular/core';
import { ComponentManagerService, FormService } from '@fgpp-ui/components';
import { TaskBarService } from '../../../core/navigation/task-bar/services/task-bar.service';
import { FileAttachmentApiService } from '../../../shared/attachments/file-attachment-dialog/services/file-attachment-api.service';
import { FileAttachmentDialogService } from '../../../shared/attachments/file-attachment-dialog/services/file-attachment-dialog.service';
import { CalendarService } from '../../profiles/components/calendar/services/calendar.service';
import { IBANService } from '../../profiles/components/country-info/services/iban.service';
import { StandingOrderService } from '../../profiles/components/standing-order/standing-order.service';
import { SystemParametersService } from '../../profiles/components/system-parameters/services/system-parameters.service';
import { CountryInfo } from '../../profiles/models/country-info.model';
import { CountryPreferences } from '../../profiles/models/country-preferences.model';
import { CutoffTimes } from '../../profiles/models/cutoff-times.model';
import { FileCutoffTimes } from '../../profiles/models/file-cutoff-times.model';

export const ProfileFormTemplateProviders = [
	TaskBarService,
	FormService,
	ComponentManagerService,
	FileAttachmentApiService,
	FileAttachmentDialogService
];
export const PROFILE_MODEL = new InjectionToken<any>('profile-model');
export const PROFILE_ID = new InjectionToken<string>('profile-id');

export const SystemParameterProfileProviders = [...[SystemParametersService], ProfileFormTemplateProviders];
export const CalendarProfileProviders = [...[CalendarService], ProfileFormTemplateProviders];
export const StandingOrdersProfileProviders = [...[StandingOrderService], ProfileFormTemplateProviders];
export const CutoffTimesProfileProviders = [...[{ provide: PROFILE_MODEL, useValue: CutoffTimes }], ProfileFormTemplateProviders];
export const FileCutoffTimesProfileProviders = [...[{ provide: PROFILE_MODEL, useValue: FileCutoffTimes }], ProfileFormTemplateProviders];
export const CountryInfoProfileProviders = [...
	[IBANService, { provide: PROFILE_MODEL, useValue: CountryInfo }, { provide: PROFILE_ID, useValue: '50' }],
	ProfileFormTemplateProviders];
export const CountryPreferencesProfileProviders = [...
	[IBANService, { provide: PROFILE_MODEL, useValue: CountryPreferences }, { provide: PROFILE_ID, useValue: '58' }],
	ProfileFormTemplateProviders];
