import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { RuleFormComponent } from '../../single-rule/components/rule-form/rule-form.component';
import { RuleCommonService } from '../../single-rule/services/rule-common.service';
import {Observable} from "rxjs";

@Injectable()
export class SingleRuleDeactivateGuard implements CanDeactivate<RuleFormComponent> {

  constructor(private ruleCommonService: RuleCommonService) { }

  canDeactivate(component: RuleFormComponent, currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): Observable<boolean> | boolean {
    if (!this.ruleCommonService.isNeedCheckBeforeClose) {
      return true;
    }
    this.ruleCommonService.isNeedCheckBeforeClose = false;
    return this.ruleCommonService.checkBeforeCloseRule(nextState);
  }

}
