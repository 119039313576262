import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FnUiNotificationModule } from '../../shared/fn-ui-notification/fn-ui-notification.module';
import { AutoFeederApiService } from './services/auto-feeder-api.service';
import { AutoFeederService } from './services/auto-feeder.service';

@NgModule({
    imports: [
        HttpClientModule,
        TranslateModule.forChild(),
        FnUiNotificationModule
    ],
    providers: [
        AutoFeederApiService,
        AutoFeederService
    ]
})
export class AutoFeederModule { }
