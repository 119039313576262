import { Injectable } from '@angular/core';
import { NotificationService } from '@fgpp-ui/components';
import { TranslateService } from '@ngx-translate/core';
import { FormsService } from '../../forms/services/forms.service';
import { ProfilePopUpSize } from '../../profiles/models/consts/profile-pop-up-size.consts';
import { FileAttachmentDialogOutput } from '../../shared/attachments/file-attachment-dialog/models/file-attachment-dialog-output.model';
import { FileAttachmentMapping } from '../../shared/attachments/file-attachment-dialog/models/file-attachment-mapping.model';
import { FileAttachmentDialogService } from '../../shared/attachments/file-attachment-dialog/services/file-attachment-dialog.service';
import { PopupWindow } from '../models/popup-window.model';
import { WindowSize } from '../models/window-size.model';
import { WindowState } from '../models/window-state.model';
import { IPopupService } from './interfaces/popup-service.interface';

@Injectable()
export class PopupService implements IPopupService {

  private _windowStates: { [key: string]: WindowState } = {};
  private _openedWindows: Array<PopupWindow> = [];
  private _interval = null;

  constructor(private translateService: TranslateService,
              private notificationService: NotificationService,
              private formsService: FormsService,
              private fileAttachmentDialogService: FileAttachmentDialogService) { }

  open(params: any, profileId?: string, sourceWindowId?: string): Promise<any> {
    if (!this.shouldOpenPopup(params, profileId, sourceWindowId)) {
      return;
    }
    const windowId = this.generateWindowId();
    const attributes = this.getWindowAttributes(profileId);

    const popupWindow = window.open('', windowId, attributes);
    if (!popupWindow) {
      this.notificationService.warning(this.translateService.instant('main_window.should_allow_popup'));
      return;
    }

    this.postServletRequest(windowId, params);

    this.pushOpenWindow(params, profileId, popupWindow);

    this._windowStates[windowId] = {
      window: popupWindow
    };
    (window as any).GPPFrontEnd().setPopupParams(params);
    return this.getPopupResult(this._windowStates[windowId], params);
  }

  close(windowId: string): void {
    if (this._windowStates[windowId]?.window) {
      this._windowStates[windowId].window.close();
    }
  }

  closeAll(): void {
    Object.keys(this._windowStates).forEach((windowId: string) => {
      this._windowStates[windowId].window?.close();
    });
  }

  getWindowById(windowId: string): Window & { [key: string]: Object } {
    return this._windowStates[windowId]?.window || null;
  }

  getWindowByMID(mid: string): string {
    for (const openedWindow of this._openedWindows) {
      if (mid === openedWindow.params.txtMID) {
        return openedWindow.id;
      }
    }
    return null;
  }

  isWindowAlreadyOpen(params: any, sourceWindowId?: string): boolean {
    for (const openedWindow of this._openedWindows) {
      if (params.txtMID === openedWindow.params.txtMID) {
        // In case of RETURN/CANCEL action when a new window is opened after closing existing. We still receive the parent MID as txtMID.
        // Remove old window reference and generate new.
        if ((params.txtUserModified && params.txtUserModified.indexOf('D_BUTTON_ID^^Print') !== -1)) {
          return false;
        }
        if (params['@COMMAND_EVENT@'] === 'eventLoadGeneratedMessage') {
          this.removeClosedWindow(openedWindow.id);
          return false;
        }
        if (params.txtTriggeredMessageButtonID) {
          openedWindow.state = params.txtTriggeredMessageButtonID;
        }
        if (openedWindow.window.closed) {
          return false;
        }
        openedWindow.window.focus();
        if (sourceWindowId) {
          this.getWindowById(sourceWindowId).alert(this.translateService.instant('message_already_open'));
        }
        return true;
      }
    }
    return false;
  }

  updatePopupAttanchmentData(windowId: string, data: FileAttachmentDialogOutput): void {
    if (this._windowStates[windowId]?.window && data) {
      const doc = this._windowStates[windowId].window.document;
      const attachmentInput = doc.getElementById('file-attachment');
      const isAttachmentsChangedInput = doc.getElementById('isAttachmentsChanged');
      if (attachmentInput === null) {
        this.createAttachmentElement(doc, data.fileAttachmentsList);
        this.createIsAttachmentChangedElement(doc, data.attachmentChanged);
      } else {
        attachmentInput.setAttribute('value', JSON.stringify(data.fileAttachmentsList));
        isAttachmentsChangedInput.setAttribute('value', data.attachmentChanged.toString());
      }
    }
  }

  private shouldOpenPopup(params: any, profileId: string, sourceWindowId: string): boolean {
    return !(this.shouldCheckIfWindowAlreadyOpen(params) === true
      && this.isWindowAlreadyOpen(params, sourceWindowId) === true
      && profileId === 'message'
      && !params.txtTemplateLoad);
  }

  private shouldCheckIfWindowAlreadyOpen(params: any): boolean {
    return params['@COMMAND_EVENT@'] !== 'eventReloadMessage';
  }

  private generateWindowId(): string {
    return 1 + '_ID_' + Math.round(Math.random() * 10000) + Date.now();
  }

  private getWindowSize(profileId: string): WindowSize {
    const profileSize = ProfilePopUpSize.profileData[profileId];
    const windowSize = profileSize ? { ...ProfilePopUpSize.default, ...profileSize } : ProfilePopUpSize.default;
    const actualWindowHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0) - ProfilePopUpSize.maxDimensions.topBottomGutters;

    windowSize.width = Math.min(windowSize.width, ProfilePopUpSize.maxDimensions.width - ProfilePopUpSize.maxDimensions.sideGutters);
    windowSize.height = Math.min(windowSize.height, actualWindowHeight);

    return windowSize;
  }

  private getWindowAttributes(profileId: string): string {
    const windowSize = this.getWindowSize(profileId);
    return `status=1, left=0, top=0,width=${windowSize.width},height=${windowSize.height}`;
  }

  private postServletRequest(windowId: string, params: any): void {
    const unattachedForm = window.document.createElement('form');
    unattachedForm.target = windowId;
    unattachedForm.method = 'POST';
    unattachedForm.action = '/gppold/servlet/MainServlet';
    unattachedForm.style.display = 'none';
    window.document.body.appendChild(unattachedForm);

    Object.keys(params).forEach((key: string) => {
      const mapInput = document.createElement('input');
      mapInput.type = 'text';
      mapInput.name = key;
      mapInput.value = params[key];
      unattachedForm.appendChild(mapInput);
    });

    unattachedForm.submit();
    unattachedForm.remove();
  }

  private shouldPushWindow(params: any, profileId: string): boolean {
    return !!params.txtMID
      && !params.txtTemplateLoad
      && (profileId === 'message' || params['@COMMAND_EVENT@'] === 'eventLoadGeneratedMessage');
  }

  private pushOpenWindow(params: any, profileId: string, popupWindow: Window): void {
    if (this.shouldPushWindow(params, profileId)) {
      this._openedWindows.push({
        id: popupWindow.name,
        state: undefined,
        window: popupWindow,
        params: params
      });
      popupWindow['parentMid'] = params.txtMID;
    }
  }

  private removeClosedWindow(windowId: string): void {
    const index = this._openedWindows.findIndex((openedWindow) => openedWindow.id === windowId);
    if (index !== -1) {
      this._openedWindows[index].window = undefined;
      this._openedWindows.splice(index, 1);
    }
  }

  private updateWindowState(windowId: string, state: any): void {
    const index = this._openedWindows.findIndex((openedWindow) => openedWindow.id === windowId);
    if (index !== -1) {
      this._openedWindows[index].state = state;
    }
  }

  private getPopupResult(windowState: WindowState, params: any): Promise<any> {
    return new Promise((resolve) => {
      const onUnload = () => {
        if (windowState.window.popupResult !== undefined) {
          windowState.popupResult = { ...windowState.window.popupResult };
          this.updateWindowState(windowState.window.name, windowState.popupResult);
        }
        if (windowState.window.isAttachmentModalOpen) {
          this.fileAttachmentDialogService.closeDialog();
        }

        if (windowState.window.closed) {
          resolve(windowState.popupResult);
        } else {
          setTimeout(() => {
            if (windowState.window.closed) {
              resolve(windowState.popupResult);
            } else {
              windowState.window.addEventListener('unload', onUnload, false);
            }
          }, 100);
        }
        this.releaseMid(params);
      };

      windowState.window.addEventListener('unload', onUnload, false);
    });
  }

  private releaseMid(params: any): void {
    if (params.txtMID && !this._interval && !params.txtTemplateLoad) {
      this._interval = setInterval(() => {
        this._openedWindows.forEach((openedWindow: PopupWindow) => {
          if (openedWindow?.window?.closed) {
            const midSet = new Set<string>();
            // Release lock only if window unload event has not generated a new message or generated message is being closed
            if ((!openedWindow.window.isGeneratedNewMessage)
              || (openedWindow.window.isGeneratedNewMessage && openedWindow.window.childMid !== params.txtMID)) {
              // Add parent and child MIDs to set. If message is not generating a child MID then set will have only one entry
              midSet.add(params.txtMID);
              midSet.add(openedWindow.window.childMid || params.txtMID);
              midSet.forEach((mid: string) => {
                if (!openedWindow.window.skipReleaseLock) {
                  const payload = {
                    txtMID: mid,
                    txtPerformAuditAction: !!openedWindow.window.txtPerformAuditAction || '',
                    txtMIDToRelease: openedWindow.window.txtMIDToRelease || '',
                    txtUserModified: openedWindow.window.txtUserModified || ''
                  };
                  this.formsService.releaseLockedMid(mid, payload);
                }
              });
            }
            this.removeClosedWindow(openedWindow.id);
          }
        });
        if (this._openedWindows.length === 0) {
          clearInterval(this._interval);
          this._interval = undefined;
          this._windowStates = {};
        }
      }, 1000);
    }
  }

  private createAttachmentElement(doc: Document, data: Array<FileAttachmentMapping>): void {
    const input = doc.createElement('input');
    input.setAttribute('class', 'file-attachment');
    input.setAttribute('type', 'hidden');
    input.setAttribute('name', 'file-attachment');
    input.setAttribute('id', 'file-attachment');
    input.setAttribute('value', JSON.stringify(data));
    doc.body.appendChild(input);
  }

  private createIsAttachmentChangedElement(doc: Document, isChanged: boolean): void {
    const input = doc.createElement('input');
    input.setAttribute('type', 'hidden');
    input.setAttribute('id', 'isAttachmentsChanged');
    input.setAttribute('value', isChanged.toString());
    doc.body.appendChild(input);
  }

}
