import { Injectable, Injector } from '@angular/core';
import { SearchCriteria } from '../../../grid/models/grid-search-query.model';
import { SearchCriteriaTokens } from '../models/consts/search-criteria-tokens.const';
import { ISearchCriteriaService } from './interfaces/search-criteria-service.interface';

@Injectable({
  providedIn: 'root'
})
export class SearchCriteriaService implements ISearchCriteriaService {

  constructor(private injector: Injector) { }

  toString(searchCriteria: SearchCriteria): string {
    return this.getService(searchCriteria).toString(searchCriteria);
  }

  toHumanString(searchCriteria: SearchCriteria): string {
    return this.getService(searchCriteria).toHumanString(searchCriteria);
  }

  private getService(searchCriteria: SearchCriteria): ISearchCriteriaService {
    return this.injector.get<ISearchCriteriaService>(SearchCriteriaTokens[searchCriteria.class]);
  }

}
