import { Injectable } from '@angular/core';
import { PopupService } from '../../../core/services/popup.service';
import { PopoutParams } from '../interfaces/popout-params.interface';


@Injectable()
export class BfPopoutService {

  constructor(private popupService: PopupService) {}

  openOld(params: PopoutParams, data: any, sessionId: string) {
    const popupData = {
      'PK': params.uniqueRecId,
      'VIRTUAL.UID': params.uniqueRecId,
      'VIRTUAL.STATIC_DATA_ID': params.profileId,
      'VIRTUAL.LOCK_MODE': 0,
      'FROM_PROFILE_ID': '',
      'SELECTED_SET': [],
      'SELECTED_SET_INDEX': 0,
      'PROFILE_SELECTED_OFFICE': params.office,
      '@SESSION_ID@': sessionId,
      '@FORM_NAME@': 'frmLoadProfile',
      '@COMMAND_EVENT@': this.getCommandEvent(params.loadType)
    };

    this.popupService.open(popupData, params.profileId);

  }

  private getCommandEvent(loadType) {
    switch (+loadType) {
      case 3:
        return 'eventLoadForApproval';
      case 1:
          return 'eventLoadForCreate';
      case 6:
          return 'eventLoadInactiveForUpdate';
      default:
        return 'eventLoadForUpdate';
    }
  }

}
