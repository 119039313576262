import {
  Input,
  OnInit,
  Directive,
  ComponentFactoryResolver,
  Injector,
  ApplicationRef,
  ChangeDetectorRef
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ExternalFilterGridData } from '../../models/external-filter-grid-data.model';
import { ExternalFilterGridService } from '../../services/external-filter-grid.service';
import { ColumnSettingsService } from '../../services/column-settings.service';
import { FilterMapperService } from '../../services/filter-mapper.service';
import { SearchQueryBuilderService } from '../../../shared/services/search-query-builder.service';
import { ExportService } from '../../../shared/fn-ui-export/services/export.service';
import { Resource } from '../../models/resource.model';
import { GridSearchRequest } from '../../models/grid-search-request.model';
import { TableRow } from '@fgpp-ui/grid';
import { GridContainerComponent } from '../grid-container/grid-container.component';
import { ActionFilterService } from '@fgpp-ui/grid';
import { ActionFilterMapperService } from '@fgpp-ui/grid';
import { GridEventsService } from '../../../azure-insight/services/grid-events.service';
import { FilterEvent } from '@fgpp-ui/grid';
import { UserSettingsService } from '../../../core/user-settings/services/user-settings.service';
import { SelectionEvent } from '@fgpp-ui/grid';
import { FormatsService } from '@fgpp-ui/components';

@Directive()
export abstract class ExternalFilterGridComponent extends GridContainerComponent<GridSearchRequest> implements OnInit {
  @Input() externalFilterGridData: ExternalFilterGridData;

  get keyId(): string {
    return this._keyId;
  }

  private _keyId: string;

  constructor(route: ActivatedRoute,
    userSettingsService: UserSettingsService,
    gridEventsService: GridEventsService,
    public externalFilterGridService: ExternalFilterGridService,
    searchQueryBuilderService: SearchQueryBuilderService,
    columnSettingsService: ColumnSettingsService,
    exportService: ExportService,
    filterMapperService: FilterMapperService,
    actionFilterMapperService: ActionFilterMapperService,
    protected actionFilterService: ActionFilterService, resolver: ComponentFactoryResolver,
    injector: Injector, app: ApplicationRef,
    cdr: ChangeDetectorRef, protected formatsService: FormatsService) {
    super(route, userSettingsService, gridEventsService, externalFilterGridService, columnSettingsService, exportService, filterMapperService,
      actionFilterMapperService, actionFilterService, resolver, injector, app,cdr, formatsService);
  }

  ngOnInit() {
    this.externalFilterGridService.resetGridData();
    this.externalFilterGridService.resetSearchRequest();
    super.ngOnInit();
    this.setContainerConfig();
    this.gridConfig.table.multiSelect = this.externalFilterGridData.multiSelect;
    const externalFilters = this.externalFilterGridData.searchFilter.filter(searchFilter => !searchFilter.hidden);
    this.externalFilterGridService.setInternalFilters(externalFilters, !this.externalFilterGridData.isSelectType);
  }

  onGridDataChange() {
    this.subscriber.add(this.externalFilterGridService.currentData.subscribe((resource: Resource) => {
      if (!!resource) {
        resource.queueTitle = this.externalFilterGridData.alias;
        super.setContainerGridData(resource);
        const refineFilters = this.externalFilterGridData.searchFilter.filter(filter => !filter.hidden);
        const filterList = SearchQueryBuilderService.getSelectedFacets(refineFilters, resource.availableColumns);
        this.selectedActionFilters = filterList.length ? this.actionFilterService.getActionFilterList(filterList) : [];
        this._keyId = this.externalFilterGridService.keyId;
      }
    }));
  }

  onFilterChanged(filterEvent: FilterEvent) {
    this.gridComponent.resetPagination();
    this.gridComponent.resetSelection();
    const filters = this.actionFilterMapperService.mapActionFiltersToFilters(filterEvent.data);
    const searchFilters = this.filterMapperService.mapAll(filters);
    const externalFilters = this.externalFilterGridData.searchFilter.filter(searchFilter => searchFilter.hidden);
    this.externalFilterGridData.searchFilter = [...externalFilters, ...searchFilters];
    this.externalFilterGridService.setInternalFilters(searchFilters);
  }

  setContainerConfig() {
  }

  onRowClicked($event: TableRow) {
    this.selectedRows = [$event];
    this.rowClicked.emit($event);
  }

  onSelectionChanged($event: SelectionEvent) {
    super.onSelectionChanged($event);
    this.externalFilterGridData.selectedRows = $event.data;
  }
}
