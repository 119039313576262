import { Injectable } from '@angular/core';
import { CallbackCallerAuthenticationModalComponent } from '../callback-caller-authentication-modal/callback-caller-authentication-modal.component';
import { FnUiDialogService } from '../../../../shared/fn-ui-dialog/services/fn-ui-dialog.service';

@Injectable()
export class CallerCallbackService {

  constructor(private dialogService: FnUiDialogService) {}

  public openCallerAuthenticationModal(payload) {
    const config = {
      closeOnNavigation: false,
      data: payload ? payload : {}
    };

    const modalRef = this.dialogService.open(CallbackCallerAuthenticationModalComponent, config);
    return modalRef.afterClosed().toPromise();
  }
}
