import { Injectable } from '@angular/core';
import { SitemapNode, SitemapTreeItem } from '../../sitemap/models';
import { NavigationService } from '../navigation.service';
import { TabHandlerService } from './tab-handler.service';

@Injectable()
export class InsightTabHandlerService extends TabHandlerService {

  constructor(private navigationService: NavigationService) {
    super();
  }

  getRouterLink(node: SitemapNode, parents: Array<SitemapTreeItem>): string {
    const mainTab = parents[0].id;
    const includeCategory = parents.length > 1 && parents[1].id !== node.id;
    return `/home/${mainTab}${includeCategory ? '/' + parents[1].id : ''}/${node.id}`;
  }

  getStateName(node: SitemapNode, parents: Array<SitemapTreeItem>): string {
    const mainTab = parents[0].id;
    const includeCategory = parents.length > 1 && parents[1].id !== node.id;
    const stateName = this.navigationService.isInsightPbi(node.id) ?
      `home.${mainTab}.taskbar.pbi` :
      `home.${mainTab}.taskbar${includeCategory ? '.' + parents[1].id : ''}.${node.id}`;
    return stateName;
  }

  getStateParams(node: SitemapNode, parents: Array<SitemapTreeItem>): any {
    const stateParams = { item: parents.length > 1 ? node.id : null };
    if (this.navigationService.isInsightPbi(node.id)) {
      stateParams['taskbarItem'] = node.id;
    }
    return stateParams;
  }

}
