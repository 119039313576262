import { Clipboard } from '@angular/cdk/clipboard';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { NotificationService } from '@fgpp-ui/components';
import { TranslateService } from '@ngx-translate/core';
import { RuleCommonService } from '../../services/rule-common.service';
import { RuleConditionsService } from '../../services/rule-conditions.service';

@Component({
  selector: 'app-rule-preview',
  templateUrl: './rule-preview.component.html',
  styleUrls: ['./rule-preview.component.scss']
})
export class RulePreviewComponent {

  @Input()
  set conditionsDataJson(value: string) {
    this.conditionsAsHtml = this.ruleConditionsService.doText();
  }

  conditionsAsHtml: string;

  @ViewChild('rulePreviewContent', { static: true }) rulePreviewContent: ElementRef<HTMLElement>;

  constructor(private clipboard: Clipboard,
              private notificationService: NotificationService,
              private translateService: TranslateService,
              private ruleCommonService: RuleCommonService,
              private ruleConditionsService: RuleConditionsService) { }

  onCopyToClipboardClick(): void {
    const text = this.rulePreviewContent.nativeElement.textContent;
    if (!text) {
      return;
    }
    const success = this.clipboard.copy(text);
    if (success) {
      this.onCopySuccess();
    } else {
      this.onCopyFailed();
    }
  }

  private onCopySuccess(): void {
    this.notificationService.success(this.translateService.instant('rules.clipboard_message.successfully'));
  }

  private onCopyFailed(): void {
    this.notificationService.error(this.translateService.instant('rules.clipboard_message.unsuccessfully'));
  }

  togglePreviewRulePane(): void {
    this.ruleCommonService.togglePreviewRulePane();
  }

}
