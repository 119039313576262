import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { FnUiDialogComponent } from './fn-ui-dialog.component';
import { FnUiDialogService } from './services/fn-ui-dialog.service';


@NgModule({
  declarations: [
    FnUiDialogComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule
  ],
  providers: [
    FnUiDialogService
  ],
  exports: [
    FnUiDialogComponent,
    MatDialogModule
  ]
})
export class FnUiDialogModule { }
