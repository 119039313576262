import { Direction } from '@angular/cdk/bidi';
import { Injectable } from '@angular/core';
import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { NotificationConfigService } from '@fgpp-ui/components';

@Injectable()
export class FnUiNotificationConfigService extends NotificationConfigService {

    get duration(): number {
        return 8000;
    }

    get panelClass(): string | string[] {
        return 'fn-ui-notification-panel';
    }

    get direction(): Direction {
        return 'ltr';
    }

    get closeButton(): boolean {
        return true;
    }

    get clickToDismiss(): boolean {
        return false;
    }

    get horizontalPosition(): MatSnackBarHorizontalPosition {
        return 'center';
    }

    get verticalPosition(): MatSnackBarVerticalPosition {
        return 'top';
    }

    get successIcon(): string {
        return 'mdi-check-box-outline';
    }

    get errorIcon(): string {
        return 'mdi-information';
    }

    get warningIcon(): string {
        return 'mdi-alert-outline';
    }

    get infoIcon(): string {
        return 'mdi-information-outline';
    }

}
