import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PopOutService } from '../../../services/pop-out.service';
import { FnUiDialogService } from '../../../shared/fn-ui-dialog/services/fn-ui-dialog.service';
import { MessageDisclaimerComponent } from '../message-disclaimer.component';

@Injectable()
export class MessageDisclaimerService {

  private _isDialogOpen = false;

  constructor(private dialog: MatDialog, private popOutService: PopOutService, private dialogService: FnUiDialogService) {}

  openDialog(uid, data) {
    if(!this._isDialogOpen) {
      this._isDialogOpen = true;
      const config: MatDialogConfig = {
        panelClass: 'message-disclaimer-modal',
        data: {
          uid: uid,
          data: data
        }
      };
      return this.dialog.open(MessageDisclaimerComponent, config).afterClosed().subscribe(this.closeDialog.bind(this));
    }
  }

  openMessageDisclaimerPreview(uid, data, templateId) {
    this.popOutService.options = 'width=1124px, height=768px, resizable=1, location=yes';
    this.popOutService.stateName = 'popOut.messageDisclaimerPreview';
    this.popOutService.stateParams = { 'uid': uid, 'data': data, 'templateId': templateId };
    return this.popOutService.openMessageDisclaimerPreview(uid);
  }

  public closeDialog() {
    this.setIsDialogOpen(false);
    this.dialogService.closeAll();
  }

  public getIsDialogOpen(): boolean {
    return this._isDialogOpen;
  }

  public setIsDialogOpen( value: boolean ) {
    this._isDialogOpen = value;
  }
}
