<!-- tour item modal -->

<app-fn-ui-dialog class="tour-step-modal">

  <ng-template #header>
  <div class="tour-step-header">
    <span class="title" *ngIf="tourItem.title">{{tourItem?.title | translate}}</span>
      <app-fn-ui-close-icon (click)="onCloseHandler()" *ngIf="isTourAbortable"></app-fn-ui-close-icon>
  </div>
    <mat-divider></mat-divider>
  </ng-template>

  <ng-template #body>
  <div class="tour-step-body" [translate]="tourItem.text"></div>
  </ng-template>

  <ng-template #footer>
  <div class="tour-step-footer">
    <app-fn-ui-button [name]="tourItem.title + ' ' + tourItem.okLabel" type="flat" color="primary"
            *ngIf="tourItem.okLabel && tourItem.okLabel.length > 0"
            (click)="onOkHandler($event)">
      <span [translate]="tourItem.okLabel"> </span>
    </app-fn-ui-button>

    <app-fn-ui-button [name]="tourItem.title + ' ' + tourItem.closeLabel"
            *ngIf="tourItem.closeLabel && tourItem.closeLabel.length > 0"
            (click)="onPostponeHandler()">
      <span [translate]="tourItem.closeLabel"> </span>
    </app-fn-ui-button>

    <app-fn-ui-button [name]="tourItem.title + ' ' + tourItem.prevLabel"
            *ngIf="isPrevLabelEnabled && tourItem.prevLabel"
            (click)="onPrevHandler($event)">
      <span [translate]="tourItem.prevLabel"> </span>
    </app-fn-ui-button>

    <app-fn-ui-button [name]="tourItem.title + ' ' + tourItem.nextLabel" cdkfocusinitial="true" type="flat" color="primary"
            *ngIf="isNextLabelEnabled"
            (click)="onNextHandler($event)">
      <span [translate]="tourItem.nextLabel"> </span>
    </app-fn-ui-button>
  </div>
  </ng-template>

</app-fn-ui-dialog>
