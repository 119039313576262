import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { TranslationsHandlerService } from '../../core/translations/services/translations-handler.service';
import { UserSettingsService } from '../../core/user-settings/services/user-settings.service';

export const TranslationsResolver: ResolveFn<Object> =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Object> => {

    const translationsHandlerService = inject(TranslationsHandlerService);
    const userSettingsService = inject(UserSettingsService);

    return translationsHandlerService.setTranslation(userSettingsService.getPreferences().language);
  };
