<gpp-ui-tree class="fn-ui-navigation-tree"
             [treeConfig]="treeConfig"
             [treeModel]="treeModel"
             [selectedNode]="selectedNode"
             (selectionChanged)="onSelectionChange($event)">

  <ng-template #node let-node="node">
    <a class="node-link" tabindex="0"
       [routerLink]="node.routerLink"
       [queryParams]="node.stateParams"
        (keyup.enter)="onNodeEnterClick($event)">
      <ng-container [ngTemplateOutlet]="nodeTemplate" [ngTemplateOutletContext]="{ node }"></ng-container>
    </a>
  </ng-template>

  <ng-template #leaf let-node="node">
    <a class="node-link" tabindex="0"
       [routerLink]="node.routerLink"
       [queryParams]="node.stateParams"
       (keyup.enter)="onNodeEnterClick($event)">
      <ng-container [ngTemplateOutlet]="leafTemplate" [ngTemplateOutletContext]="{ node }"></ng-container>
    </a>
  </ng-template>

</gpp-ui-tree>

<ng-template #defaultNode let-node="node">
  <span class="node-alias">{{ node.alias | translate }}</span>
</ng-template>
