<app-fn-ui-dialog class="prompt-with-note-modal">

  <ng-template #header>
    <div class="prompt-with-note-header">
      <span class="title" [translate]="params.title"></span>
      <app-fn-ui-close-icon (click)="close()"></app-fn-ui-close-icon>
    </div>
    <mat-divider></mat-divider>
  </ng-template>


  <ng-template #body>
    <div class="prompt-with-note-body">
      <mat-form-field [floatLabel]="'always'" class="dh-form-field">
        <mat-label *ngIf="params.aboveNoteText" [translate]="params.aboveNoteText"></mat-label>
    <textarea azure-id="note" matInput rows="7"
              [(ngModel)]="params.message"
              [disabled]="!params.isNoteEditable"
              [required]="params.isNoteMandatory"
              maxlength="{{params.noteCharsLimit}}">
    </textarea>
        <mat-hint>{{"main_window.characters_limit" | translate : {chars: params.noteCharsLimit - params.message.length} }}</mat-hint>
      </mat-form-field>
    </div>
  </ng-template>


  <ng-template #footer>
    <div class="prompt-with-note-footer">
      <app-fn-ui-button [name]="params.title + ' ' + params.cancelButton" (click)="close()">
        <span [translate]="params.cancelButton"></span>
      </app-fn-ui-button>
      <app-fn-ui-button [name]="params.title + ' ' + params.okButton" type="flat" color="primary" (click)="close(params.message)"
              [disabled]="!params.message && params.isNoteMandatory">
        <span [translate]="params.okButton"></span>
      </app-fn-ui-button>
    </div>
  </ng-template>

</app-fn-ui-dialog>
