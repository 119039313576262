import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FnUiIconModule } from '../fn-ui-icons/fn-ui-icon/fn-ui-icon.module';
import { FnUiInputComponent } from './fn-ui-input.component';

@NgModule({
  declarations: [
    FnUiInputComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    FnUiIconModule
  ],
  exports: [
    FnUiInputComponent
  ]
})
export class FnUiInputModule { }
