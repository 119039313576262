import { NgModule } from '@angular/core';
import { ToursApiService } from './services/tours-api.service';
import { TourItemComponent } from './components/tour-item/tour-item.component';
import { PopoverComponent } from './components/popover/popover.component';
import { TourStepComponent } from './components/tour-step/tour-step.component';
import { FnUiCloseIconModule } from '../shared/fn-ui-icons/fn-ui-close-icon/fn-ui-close-icon.module';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MdePopoverModule } from '@material-extended/mde';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FnUiDialogModule } from '../shared/fn-ui-dialog/fn-ui-dialog.module';
import { MatDividerModule } from '@angular/material/divider';
import { FnUiButtonModule } from '../shared/fn-ui-button/fn-ui-button.module';
import { GridTourComponent } from './components/grid-tour/grid-tour.component';

@NgModule({
  imports: [
    FnUiCloseIconModule,
    CommonModule,
    TranslateModule,
    MdePopoverModule,
    MatSelectModule,
    MatCheckboxModule,
    FnUiDialogModule,
    MatDividerModule,
    FnUiButtonModule
  ],
  declarations: [
  TourItemComponent,
  PopoverComponent,
  TourStepComponent,
  GridTourComponent,
  ],
  providers: [
    ToursApiService
  ],
  exports: [
    TourItemComponent
  ]
})
export class ToursModule { }
