<div class="results-wrapper" [ngClass]="{'results-warning': gridFooterConfig.showResultsWarning}">
    <label>

      <app-tour-item [tourId]="'materialGrid'" [tourStepNumber]="3"></app-tour-item>
      <app-tour-item [tourId]="'materialGrid'" [tourStepNumber]="4" [arrowWidth]="0"></app-tour-item>


      <app-fn-ui-icon *ngIf="gridFooterConfig.showResultsWarning" icon="information_outline" [matTooltip]="'grid.results_warning' | translate"
        matTooltipPosition="above"></app-fn-ui-icon>
      <span>{{'grid.results' | translate}}: </span>
      <span automation-id="grid-results-total-count" class="total-count">{{totalCountFormatted}}</span><span *ngIf="gridFooterConfig.showResultsWarning">+</span>
    </label>
    <label *ngIf="selectedRows.length && !gridFooterConfig.hideSelectedRowsCounter" class="selected">{{'grid.selected' | translate }}: {{selectedRows.length}}</label>
</div>


<button *ngIf="gridFooterConfig.showCallerDetails" mat-button color="primary"
        [disabled] = "disableActions"
        (click) = "onCallerDetailsClicked()">
  <span class="dh-text-label">{{'messages.caller.callback.split_screen_button' | translate}}</span>
</button>

<button *ngIf="gridFooterConfig.showCreateFromTemplate" mat-button color="primary"
        [disabled]="disableActions"
        (click)="onCreateFromTemplateClick()">
  <span class="dh-text-label">{{ 'main_window.create_from_template' | translate }}</span>
</button>

  <div class="dh-dropdown-button dropup" *ngIf="gridFooterConfig.groupActions.showGroupActionIcon">
      <button mat-button automation-id="group_action" [matMenuTriggerFor]="groupAction"
            [disabled] = "disableActions">
        <app-fn-ui-icon icon="menu"></app-fn-ui-icon>
      </button>
      <mat-menu #groupAction="matMenu">
        <span>
            <button mat-menu-item *ngIf="gridFooterConfig.groupActions.showAddNotes"
                    (click)="onAddNoteClick()">{{"group_actions_add_note" | translate}}</button>
            <div *ngIf="gridFooterConfig.groupActions.showBeforeAfter"
                 [matTooltip]="'group_actions.before_after.message' | translate"
                 [matTooltipDisabled]="!disableBeforAfter">
              <button mat-menu-item [disabled]="disableBeforAfter"
                      (click)="onBeforeAfterClick()">{{"group_actions_before_after" | translate}}</button>
            </div>
           <button mat-menu-item *ngIf="gridFooterConfig.groupActions.showFollowUpFlag"
                   (click)="onFollowupFlagClick()">{{"group_actions_followUpFlag" | translate}}</button>
        </span>
        <div *ngIf="gridFooterConfig.groupActions.showGroupActions">
            <div *ngFor="let action of groupActions">
              <button mat-menu-item (click)="onActionClick(action)">
                <span class="dh-text-label">{{"button."+ action.actionId | translate}}</span>
              </button>
            </div>
        </div>
      </mat-menu>
  </div>

