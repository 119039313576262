export enum UniqueIdFormat {
  'OFFICE' = 'POPULATE_FIELD_FORMAT-OFFICE',
  'DEPARTMENT' = 'POPULATE_FIELD_FORMAT-DEPARTMENT',
  'REC_STATUS' = 'POPULATE_FIELD_FORMAT-REC_STATUS',
  'PROFILE_CHANGE_STATUS' = 'POPULATE_FIELD_FORMAT-PROFILE_CHANGE_STATUS',
  'EFFECTIVE_DATE' = 'EFFECTIVE_DATE',
  'FIELD_LOGICAL_ID' = 'POPULATE_FIELD_FORMAT-FIELD_LOGICAL_ID',
  'MAX_LENGTH' = 'POPULATE_FIELD_FORMAT-MAX_LENGTH',
  'DETAILS' = 'DETAILS',
  'SUBSET_FLD_NM' = 'POPULATE_FIELD_FORMAT_DTL-SUBSET_FLD_NM',
  'SUBSET_FLD_NM_VAL' = 'POPULATE_FIELD_FORMAT_DTL-SUBSET_FLD_NM_VAL',
  'V_SUBSET_FLD_NM_VAL' = 'POPULATE_FIELD_FORMAT_DTL-V_SUBSET_FLD_NM_VAL',
  'V_SUBSET_FLD_NM_VAL_BUSINESS_DATE' = 'POPULATE_FIELD_FORMAT_DTL-V_SUBSET_FLD_NM_VAL_BUSINESS_DATE',
  'V_SUBSET_FLD_NM_VAL_BUSINESS_UNIT' = 'POPULATE_FIELD_FORMAT_DTL-V_SUBSET_FLD_NM_VAL_BUSINESS_UNIT',
  'V_SUBSET_FLD_NM_VAL_SEQUENCE' = 'POPULATE_FIELD_FORMAT_DTL-V_SUBSET_FLD_NM_VAL_SEQUENCE',
  'TIME_STAMP' = 'TIME_STAMP',
  'UID' = 'VIRTUAL-UID',
  'PROFILE_UPDATE_PK' = 'PROFILE_UPDATE_PK',
  'PU_TIME_STAMP' = 'PU_TIME_STAMP',
  'NOTE' = 'NOTE'
};
