import { Component, Input, EventEmitter, Output } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-fn-ui-refresh-icon',
  templateUrl: './fn-ui-refresh-icon.component.html',
  styleUrls: ['./fn-ui-refresh-icon.component.scss'],
  animations: [
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(1440deg)' })),
      transition('default => rotated', animate('3000ms linear'))
    ])
  ]
})
export class FnUiRefreshIconComponent {

  clicked: boolean;
  state = 'default';

  @Input() disableMS: number;
  @Output() refreshClicked = new EventEmitter();

  onRefreshClick() {
    this.rotate();
    this.clicked = true;
    setTimeout(() => {
      this.clicked = false;
    }, this.disableMS);
    this.refreshClicked.emit();
  }

  rotate() {
    this.state = 'rotated';
    setTimeout(() => {
      this.state = 'default';
    }, 3000);
  }
}
