<div *ngIf="isOpen" class="navigation-result">

    <ng-container *ngIf="!isEmpty">
        <div class="header">
            <span>{{ header | translate}} ({{ totalResults | number: '2.' }})</span>
        </div>
        <div class="scrollable-content" #resultContent>
            <div class="navigation-result-content" [ngClass]="{ 'overflow': resultContent.scrollHeight > resultContent.clientHeight }">
                <div class="group" *ngFor="let group of groups; trackBy: trackByFunction">
                    <div class="group-title">
                        <span class="group-alias">{{ group.alias }}</span>
                        <span class="group-count">&nbsp;({{ group.items.length | number: '2.' }})</span>
                    </div>
                    <div class="group-items">
                        <app-navigation-result-item *ngFor="let item of group.items; trackBy: trackByFunction"
                                                    [item]="item"
                                                    [searchTerm]="searchTerm">
                        </app-navigation-result-item>
                    </div>
                </div>
            </div>
        </div>

    </ng-container>

    <app-navigation-result-empty *ngIf="isEmpty"></app-navigation-result-empty>

</div>