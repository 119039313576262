import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GridSearchRequest } from '../../../grid/models/grid-search-request.model';
import { AutoFeederMessage } from '../models/auto-feeder-message.model';
import { AutoFeederType } from '../models/enums/auto-feeder-type.enum';
import { IAutoFeederApiService } from './interfaces/auto-feeder-api-service.interface';

@Injectable()
export class AutoFeederApiService implements IAutoFeederApiService {

  private readonly _baseUrl = 'do/autoFeeder';

  constructor(private http: HttpClient) { }

  nextMessage(feederType: AutoFeederType, skip: string, searchRequest: GridSearchRequest): Observable<AutoFeederMessage> {
    const url = `${this._baseUrl}/${feederType}/nextMessage`;
    const params = new HttpParams().set('skip', skip);
    return this.http.post<AutoFeederMessage>(url, searchRequest, { params });
  }

  reset(): Observable<void> {
    const url = `${this._baseUrl}/reset`;
    return this.http.post<void>(url, null);
  }

}
