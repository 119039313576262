import { AfterViewInit, Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NavigationService } from '../../../core/navigation/services/navigation.service';
import { CallerCallbackService } from '../../../messages-center/components/messages-center-callback/services/caller-callback.service';
import { Office } from '../../../shared/models/office.model';
import { MatDialogRef } from '@angular/material/dialog';
import { PaymentType } from './payment-type.model';
import { AllowedPermissions } from '../../../shared/interfaces/allowed-permissions.interface';
import { FeatureFlagsService } from '../../../core/feature-flags/services/feature-flags.service';
import { FeatureName } from '../../../core/feature-flags/models/feature-name.enum';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { MessagesService } from '../../../message/services/messages.service';
import { BFPaymentTypes } from '../../../business-framework/messages/models/enums/bf-payment-types.enum';
import { AuthenticationService } from '../../../authentication/services/authentication.service';
import { SystemVariablesService } from '../../../core/services/system-variables/system-variables.service';
import { FxSliderMode } from '../../../core/state/models/fx-slider-mode.model';
import { FxSliderValueMode } from '../../../core/state/models/consts/fx-slider-value-mode.const';
import {FedPaymentTypesService} from "../../services/fed-payment-types.service";

@Component({
  selector: 'app-create-payment',
  templateUrl: './create-payment.component.html',
  styleUrls: ['./create-payment.component.scss']
})
export class CreatePaymentComponent implements OnInit, AfterViewInit {

  @ViewChildren(MatSelect) selectItems: QueryList<MatSelect>;
  @ViewChildren(MatOption) optionItems: QueryList<MatOption>;
  @ViewChild(MatSlideToggle) slideToggle: MatSlideToggle;


  private _offices: Office[];
  private _office: Office;
  private _officeIsVisible = false;
  private isCallerPaymentApplicable: Boolean;
  private department: string = undefined;
  private departments: string[] = [];
  fxEnhancementCheckbox = false;
  fxEnhancementCheckboxMode = false;
  private showfxEnhancement = false;
  private fxFeatureFlag: boolean;
  private paymentTypes: PaymentType[];
  private paymentType: PaymentType = undefined;
  private _officeSelected = false;
  private _paymentTypeSelected = false;
  private paymentTypeSupportedForCallerPayment: string[];
  private _isCallerPaymentVisible = false;

  private allowedCallerPermissions: AllowedPermissions = undefined;

  constructor(private navigationService: NavigationService,
              private messagesService: MessagesService,
              private callerCallbackService: CallerCallbackService,
              private featureFlagsService: FeatureFlagsService,
              private dialogRef: MatDialogRef<CreatePaymentComponent>,
              private authenticationService: AuthenticationService,
              private systemVariablesService: SystemVariablesService,
              protected fedPaymentTypeHandlerService:FedPaymentTypesService) { }

  ngOnInit(): void {
    this.fxFeatureFlag = this.featureFlagsService.isFeatureOn(FeatureName.fxFeature);
    this._offices = this.navigationService.getWriteOnlyOffices().filter((office: Office) => !office.isGlobal);
    this.isCallerPaymentApplicable = this.navigationService.isCallerPaymentApplicable();
    this.paymentTypes = this.navigationService.getCreatePaymentItems().filter((item: PaymentType) => {
      return item.event !== BFPaymentTypes.PACS_008_US_INTERNATIONAL && item.event !== BFPaymentTypes.PACS_008_US_INTERNATIONAL_FX;
    });
    this.fedPaymentTypeHandlerService.initPaymentTypes(this.paymentTypes);
    this.paymentTypeSupportedForCallerPayment = ['eventCreateCustomerMessage', 'eventCreateCustomerMessageTemplate', 'eventCreateFedMessage',
      'eventCreateFedMessageTemplate', 'eventCreateFedSvcMessage', 'eventCreateFedSvcMessagTemplate', 'eventCreateFedDrawdownMessage',
      'eventCreateFedDrawdownMessageTemplate','eventCreateRequestForPayment','eventCreateRequestForPaymentTemplate'
    ];
    this.allowedCallerPermissions = this.navigationService.sitemap.modules.allowedPermissions;

    this.setOffice();
    this.setPaymentType();

    this.messagesService.callerFeature = this.allowedCallerPermissions['callerFeature'];
    this.messagesService.actOnBehalfOfCaller = this.allowedCallerPermissions['actOnBehalfOfCaller'];
    this.callerPaymentVisible();
  }

  get officeSelected() {
    return this._officeSelected;
  }

  get paymentTypeSelected() {
    return this._paymentTypeSelected;
  }

  get isCallerPaymentVisible() {
    return this._isCallerPaymentVisible;
  }

  get offices() {
    return this._offices;
  }

  get office() {
    return this._office;
  }

  set office(office: Office) {
    this._office = office;
  }

  get officeIsVisible() {
    return this._officeIsVisible;
  }

  private setOffice(): void {
    if (!!this._offices && !!this._offices[0]) {
      this._office = this._offices[0];
      this._officeIsVisible = this._offices.length !== 1;
      this._officeSelected = true;
      this.getMatchingDepartments();
    } else {
      console.error('couldn\'t get office from offices');
    }
  }

  private setPaymentType(): void {
    if (!!this.paymentTypes && !!this.paymentTypes[0]) {
      this.paymentType = this.paymentTypes[0];
      this.callerPaymentVisible();
      this.setFxEnhancementVisibility();
      this._paymentTypeSelected = true;
      this.paymentTypes = this.fedPaymentTypeHandlerService.getPaymentTypeByMop(this.office.name)
    } else {
      console.error('couldn\'t get paymentType from paymentTypes');
    }
  }

  private getMatchingDepartments(): void {
    this.departments = [].concat(this.navigationService.getOfficeDepartments(this.office.name));
    this.department = this.departments[0];
  }

  createCallerPayment(): void {
    const payload = {
      office: this._office?.name
    };

    this.callerCallbackService.openCallerAuthenticationModal(payload).then(
      res => {
        if (!res || res === '') {
          return;
        }
        const office = res.office ? res.office : this._office.name;
        const department = res.department ? res.department : this.department;
        this.messagesService.callerId = res.callerId;
        if (office && department && !!(this.paymentType.event)) {
          const isCallerPayment = true;
          this.messagesService.createMessage(office, department, this.paymentType.event, isCallerPayment);
        } else {
          console.error('cannot create caller payment: Office= ' + this._office.name + ' Department=' + this.department +
            ' Payment type=' + this.paymentType.event);
        }
      },
      (err) => { }
    );

    this.dialogRef.close();
  }

  officeChanged(): void {
    this._officeSelected = true;
    this.getMatchingDepartments();
    this.paymentTypes = this.fedPaymentTypeHandlerService.getPaymentTypeByMop(this.office.name);
  }

  paymentTypeChanged(): void {
    this._paymentTypeSelected = true;
    this.callerPaymentVisible();
    this.setFxEnhancementVisibility();
  }


  createPayment(): void {
    if (!!(this._office.name) && !!(this.department) && !!(this.paymentType.event)) {
      let paymentType = this.paymentType.event;
      const isCallerPayment = false;
      this.messagesService.callerId = undefined;
      if (paymentType === 'eventCreateCustomerMessage') {
      if (this.fxEnhancementCheckbox) {
        paymentType = 'eventCreateFxCustomerMessage';
      } else {
        if (this.fxFeatureFlag) {
          paymentType = 'eventCreateUsaCustomerMessage';
        }
      }
    }
      this.messagesService.createMessage(this._office.name, this.department, paymentType, isCallerPayment, this.fxEnhancementCheckbox, this.fxFeatureFlag);
    } else {
      console.error('cannot create payment: Office= ' + this._office.name + ' Department=' + this.department +
        ' Payment type=' + this.paymentType);
    }
    this.dialogRef.close();
  }

  callerPaymentVisible(): void {
    this._isCallerPaymentVisible = this.messagesService.callerFeature === 'true' &&
      this.messagesService.actOnBehalfOfCaller === 'true' &&
      this.paymentTypeSupportedForCallerPayment.indexOf(this.paymentType.event) !== -1;
  }

  setFxEnhancementVisibility() {
    if (this.fxFeatureFlag) {
      if (this.paymentType.action === 'create_customer_payment') {
        const modeSlider = this.getModeSliderByOffice();
        this.showfxEnhancement = true;
        this.fxEnhancementCheckbox = modeSlider.value;
        this.fxEnhancementCheckboxMode = modeSlider.disabled;
      } else {
        this.showfxEnhancement = false;
        this.fxEnhancementCheckbox = false;
      }
    } else {
      this.showfxEnhancement = false;
    }
  }

  private getModeSliderByOffice(): FxSliderMode {
    const DEFAULT_MODE = FxSliderValueMode.FALSE_UNLOCK;
    const office = this.authenticationService.userData.userOffice;
    const systemModeSlider = JSON.parse(this.systemVariablesService.getSingleVariable('ORG_FX_SLIDER_LOCKED_OFFICES') || '{}');
    return FxSliderValueMode[systemModeSlider[office]] || DEFAULT_MODE;
  }

  ngAfterViewInit(): void {
    this.slideToggle._elementRef.nativeElement.setAttribute('azure-id', this.slideToggle._elementRef.nativeElement.getAttribute('id'));
    for (const item of this.selectItems) {
      item._elementRef.nativeElement.setAttribute('azure-id', item['_id']);
    }
    for (const itemList of this.optionItems) {
      itemList._getHostElement().setAttribute('azure-id', 'option-selected');
    }
  }
}
