import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogData, Titles } from '@fgpp-ui/grid';
import { GridColumn } from '@fgpp-ui/grid';
import { ColumnSettingsModalComponent } from '@fgpp-ui/grid';
import { ColumnSettingsService as LibColumnSettingsService } from '@fgpp-ui/grid';

@Injectable({
  providedIn: 'root'
})
export class ColumnSettingsService {

  maxColumnSelection = 25;

  private get columnSettingsTitles(): Titles {
    return {
      columnSettings: this.translateService.instant('column_settings.mainTitle'),
      available: this.translateService.instant('column_settings.availableColumnTitle'),
      selected: this.translateService.instant('column_settings.selectedColumnTitle'),
      itemsSelected: this.translateService.instant('column_settings.items_selected'),
      max: this.translateService.instant('column_settings.max'),
      maxSelection: this.translateService.instant('column_setting.max_items_displayed', { maxAllowedColumnsInGrid: this.maxColumnSelection }),
      moveRight: this.translateService.instant('column_setting.move_right'),
      moveLeft: this.translateService.instant('column_setting.move_left'),
      removeAll: this.translateService.instant('main_window.refine_remove_all'),
      moveTop: this.translateService.instant('column_setting.move_top'),
      moveUp: this.translateService.instant('column_setting.move_up'),
      moveDown: this.translateService.instant('column_setting.move_down'),
      moveBottom: this.translateService.instant('column_setting.move_bottom'),
      restore: this.translateService.instant('column_setting.restore_default_button'),
      save: this.translateService.instant('general.save'),
      cancel: this.translateService.instant('general.cancel'),
      search: this.translateService.instant('column_settings.search'),
      saveGlobally: this.translateService.instant('column_setting.save-globally'),
    };
  }

  constructor(
    private translateService: TranslateService,
    private columnSettingsService: LibColumnSettingsService) { }

  openDialog(availableColumns: GridColumn[], gridColumns: GridColumn[], shouldShowApplyAll:boolean): MatDialogRef<ColumnSettingsModalComponent> {
    const data = this.getDialogData(availableColumns, gridColumns,shouldShowApplyAll);
    return this.columnSettingsService.open(data);
  }

  private getDialogData(availableColumns: GridColumn[], gridColumns: GridColumn[],shouldShowApplyAll:boolean): DialogData {
    const data: DialogData = {
      availableColumns: availableColumns,
      selectedColumns: gridColumns,
      maxSelection: this.maxColumnSelection,
      titles: this.columnSettingsTitles,
      dualListboxContainerClass: 'fa-dual-listbox-container',
      sortBy: (column: GridColumn) => column.displayName.trim().toLowerCase(),
      showApplyAll: shouldShowApplyAll
    };
    return data;
  }

}
