import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ProfilesNavigationService } from '../../../../profiles/services/profiles-navigation.service';
import { Alert } from '../../models/alert.model';
import { AlertType } from '../../models/enums/alert-type.enum';
import { InvestigationConfig } from '../models/investigation-config.model';

const ACTION_SEPARATOR = '|||';
const POWER_SIGN = '^';

@Injectable()
export class InvestigationService {

  private globalOffice = '***';

  private alertTypes = {
    [AlertType.ACCOUNT_POSITION]: 'LIQUIDITY_INVESTIGATION_CONFIG',
    [AlertType.MESSAGE_QUEUES]: 'MESSAGE_QUEUES_INVESTIGATION_CONFIG',
    [AlertType.INTERFACE_INACTIVITY]: 'PROFILES_INVESTIGATION_CONFIG',
    [AlertType.EVENT_INACTIVITY]: 'PROFILES_INVESTIGATION_CONFIG',
    [AlertType.SYSTEM_ERROR_LOG]: 'PROFILES_INVESTIGATION_CONFIG',
    [AlertType.FAILED_INCOMING_INTERACTION]: 'PROFILES_INVESTIGATION_CONFIG'
  };

  constructor(private router: Router, private profilesNavigationService: ProfilesNavigationService) { }

  investigate(alert: Alert) {
    const investigationConfig: InvestigationConfig = this.getInvestigationConfig(alert)[this.alertTypes[alert.type]];
    const route = investigationConfig.route;
    this.router.navigate([route], {
      queryParams: investigationConfig.params
    });
  }

  private getLiquidityInvestigationConfig(): InvestigationConfig {
    return {
      route: '/home/liquidity'
    };
  }

  private getMessageQueuesInvestigationConfig(alert: Alert): InvestigationConfig {
    const values = alert.action.split(ACTION_SEPARATOR);
    const queue = values[0].split(POWER_SIGN)[0];
    const office = values[1];

    const queueType = values[0].includes(POWER_SIGN) ? 'system-queues' : 'custom-queues';
    const params = { queueId: office !== this.globalOffice ? queue + '/' + office : queue };

    return {
      route: `/home/messages-center/${queueType}/messages/`,
      params: params
    };
  }

  private getProfileInvestigationConfig(alert: Alert): InvestigationConfig {
    return {
      route: this.profilesNavigationService.getRouterLinkByProfileId(alert.action.substring(alert.action.indexOf('P_=') + 3))
    };
  }

  private getInvestigationConfig(alert: Alert) {
    return {
      'LIQUIDITY_INVESTIGATION_CONFIG': this.getLiquidityInvestigationConfig(),
      'MESSAGE_QUEUES_INVESTIGATION_CONFIG': this.getMessageQueuesInvestigationConfig(alert),
      'PROFILES_INVESTIGATION_CONFIG': this.getProfileInvestigationConfig(alert)
    };
  }
}
