import { Injectable } from '@angular/core';
import { switchMap } from 'rxjs';
import { NodeAction } from '../../shared/fn-ui-navigation-actions-tree/models/node-action.model';
import { LiquidityFavorite } from '../models';
import { ILiquidityFavoriteNodeActionsService } from './interfaces/liquidity-favorite-node-actions-service.interface';
import { LiquidityFavoritesService } from './liquidity-favorites.service';

@Injectable()
export class LiquidityFavoriteNodeActionsService implements ILiquidityFavoriteNodeActionsService {

  get nodeActions(): Array<NodeAction> {
    return this._nodeActions;
  }

  private _nodeActions: Array<NodeAction>;

  constructor(private liquidityFavoritesService: LiquidityFavoritesService) {
    this.initNodeActions();
  }

  deleteFavorite(favorite: LiquidityFavorite): void {
    const observable = this.liquidityFavoritesService.deleteFavorite(favorite);
    if (observable) {
      observable.pipe(switchMap(() => {
        return this.liquidityFavoritesService.refreshTree();
      })).subscribe();
    }
  }

  private initNodeActions(): void {
    this._nodeActions = [
      {
        name: 'Delete',
        alias: 'tree_action.delete',
        command: this.deleteFavorite.bind(this)
      }
    ];
  }

}
