import { Component, Input } from '@angular/core';
import { TableRow } from '@fgpp-ui/grid';
import { RuleCommonService } from '../../services/rule-common.service';

@Component({
  selector: 'app-audit-trail',
  templateUrl: './audit-trail.component.html',
  styleUrls: ['./audit-trail.component.scss']
})
export class AuditTrailComponent {

  @Input() ruleId: string;

  selectedRow: TableRow;

  constructor(private ruleCommonService: RuleCommonService) { }

  closeAuditTrails(): void {
    this.ruleCommonService.show.auditTrail = false;
  }

  onRowClick(row: TableRow): void {
    this.selectedRow = row;
  }

  onBackClick(): void {
    this.selectedRow = undefined;
  }

}
