export const AccountGroups =
{
	'DEPARTMENT': 'ACCOUNT_GROUPS-DEPARTMENT',
	'DESCRIPTION': 'ACCOUNT_GROUPS-DESCRIPTION',
	'EFFECTIVE_DATE': 'EFFECTIVE_DATE',
	'NAME': 'ACCOUNT_GROUPS-NAME',
	'OFFICE': 'ACCOUNT_GROUPS-OFFICE',
	'PENDING_ACTION': 'ACCOUNT_GROUPS-PENDING_ACTION',
	'REC_STATUS': 'ACCOUNT_GROUPS-REC_STATUS',
	'UID_ACCOUNT_GROUPS': 'ACCOUNT_GROUPS-UID_ACCOUNT_GROUPS',
	'TIME_STAMP': 'TIME_STAMP',
	'UID': 'VIRTUAL-UID',
	'PROFILE_UPDATE_PK': 'PROFILE_UPDATE_PK',
	'PU_TIME_STAMP': 'PU_TIME_STAMP',
	'NOTE': 'NOTE',
	'ACC_NO': 'ACCOUNT_GROUPS-ACC_NO',
	'ACC_OFFICE': 'ACCOUNT_GROUPS-ACC_OFFICE',
	'ACC_TYPE': 'ACCOUNT_GROUPS-ACC_TYPE',
	'ACC_CURRENCY': 'ACCOUNT_GROUPS-ACC_CURRENCY',
	'ACC_CUST_CODE': 'ACCOUNT_GROUPS-ACC_CUST_CODE',
	'ACC_ACCOUNTNAME': 'ACCOUNT_GROUPS-ACC_ACCOUNTNAME',
	'ACC_VIRTUAL_UID': 'ACCOUNT_GROUPS-ACC_VIRTUAL_UID',
	'SELECTED_VALUES': 'ACCOUNT_GROUPS-SELECTED_VALUES'
};

