import { Injectable } from '@angular/core';
import { IdpProviderConfig, AuthenticationConfig } from '../../config/models';
import { AppConfigService } from '../../config/services/app-config.service';
import { AuthenticationMode } from '../models/enums/authentication-mode.enum';
import { IAuthenticationConfigService } from './interfaces/authentication-config-service.interface';

@Injectable()
export class AuthenticationConfigService implements IAuthenticationConfigService {

  private get _defaultConfig(): AuthenticationConfig {
    return {
      idpMode: false,
      idpObject: null
    };
  }

  private get _config(): AuthenticationConfig {
    return this.appConfigService.authenticationConfig || this._defaultConfig;
  }

  private _isSSOMode = false;

  get callbackUrl(): string {
    return window.location.origin + '/gpp';
  }

  get authenticationMode(): AuthenticationMode {
    if (this._isSSOMode) {
      return AuthenticationMode.SSO;
    }
    if (this._config.idpMode) {
      return AuthenticationMode.IDP;
    }
    return AuthenticationMode.GPP;
  }

  get authenticationProviderUrl(): string {
    return this._config.idpObject?.authenticationProviderUrl;
  }

  get idpProviderConfig(): IdpProviderConfig {
    return this._config.idpObject?.idpProvider;
  }

  get doIdpLogout(): boolean {
    return this.idpProviderConfig?.doIdpLogout || false;
  }

  get accessTokenProperty(): string {
    return this.idpProviderConfig?.accessTokenProperty || 'access_token';
  }

  get logoutRedirectUrl(): string {
    return this.idpProviderConfig?.logoutRedirectUrl || '';
  }

  constructor(private appConfigService: AppConfigService) { }

  setSSOMode(): void {
    this._isSSOMode = true;
  }
}
