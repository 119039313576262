
<div class="file-attachments-table">
  <div class="upload" [style.visibility]="fileStatus===FileStatus.UPLOADED ? 'visible' : 'hidden'" >
    <lib-icon class="icn" [iconConfig]="uploadSuccessfulIcon"></lib-icon>
    <span class="txt">{{texts.uploadSuccesfull}}</span>
  </div>
  <div class="file-attachment-row title">
    <div class="column upload-icon"></div>
    <div class="column fileName">{{texts.fileName}}</div>
    <div class="column date">{{texts.updatedDate }} </div>
    <div class="column user">{{texts.userId}}</div>
    <div class="column description">{{texts.description}}</div>
    <div class="column icon"></div>
  </div>
  <div class="wrapper"
       libLoader
       [loaderVisible]="showLoader"
       [loaderComponent]="loaderComponent"
  >
    <div class="content my-custom-scrollbar">
      <div *ngFor="let file of fileAttachmentsList" >
        <lib-file-attachment-row [fileAttachmentRow]="file" [permissions]="permissions" [dateFormatParser]="dateFormatParser"
          (downloadOnClick)="onDownloadClick(file)" (clearOnClick)="onClearClick(file)">
        </lib-file-attachment-row>
      </div>
      <div *ngIf="fileAttachmentsList.length === 0" class="no-files"><span>{{texts.noFilesAvailableYetText}}</span></div>
    </div>
  </div>
</div>

<div class="success" *ngIf="fileAttachmentsList.length > 0" [style.visibility]="fileStatus===FileStatus.UPLOADED ? 'visible' : 'hidden'" >
  <div class="col-xs-12" fbc-label content="{{uploadNotification}}" background="info" textColor="info" icon="alert-circle-outline" ></div>
</div>
