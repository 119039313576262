export const Department = {
    'OFFC_NAME': 'BANKS-OFFC_NAME',
    'ALLOW_REROUTE': 'DEPARTMENT-ALLOW_REROUTE',
    'DEPARTMENTCODE': 'DEPARTMENT-DEPARTMENTCODE',
    'DESCRIPTION': 'DEPARTMENT-DESCRIPTION',
    'EFFECTIVE_DATE': 'EFFECTIVE_DATE',
    'NCC': 'DEPARTMENT-NCC',
    'OFFICE': 'DEPARTMENT-OFFICE',
    'PROFILE_CHANGE_STATUS': 'DEPARTMENT-PROFILE_CHANGE_STATUS',
    'REC_STATUS': 'DEPARTMENT-REC_STATUS',
    'V_RANGE_LIST_FROM': 'DEPARTMENT-V_RANGE_LIST_FROM',
    'V_RANGE_LIST_TO': 'DEPARTMENT-V_RANGE_LIST_TO',
    'TIME_STAMP': 'TIME_STAMP',
    'UID': 'VIRTUAL-UID',
    'PROFILE_UPDATE_PK': 'PROFILE_UPDATE_PK',
    'PU_TIME_STAMP': 'PU_TIME_STAMP',
    'NOTE': 'NOTE',
    'BUSINESS_DATE': 'BANKS-BSNESSDATE'
  };
