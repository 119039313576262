
import { Component, HostListener, ViewEncapsulation } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import { TOAST_ALERT_ANIMATION } from './consts/toast-alert-animation.const';
import { AlertDetailsDialogService } from '../alert-details-dialog/services/alert-details-dialog.service';
import { InvestigationService } from '../../investigation/services/investigation.service';
import { ALERT_DETILES_FIELDS } from '../../models/consts/alert-details-fields.const';
import { Alert } from '../../models/alert.model';

@Component({
  selector: 'app-toast-alert',
  templateUrl: 'toast-alert.component.html',
  styleUrls: [
    '../../../../shared/fn-ui-toast/fn-ui-toast.component.scss',
    'toast-alert.component.scss'
  ],
  animations: [TOAST_ALERT_ANIMATION],
  preserveWhitespaces: false
})
export class ToastAlertComponent extends Toast {

  alert: Alert = this.message as any;

  @HostListener('mouseenter')
  preventMouseEnterStick(): boolean {
    return false;
  }

  @HostListener('mouseleave')
  preventMouseLeaveHide(): boolean {
    return false;
  }

  constructor(
    private alertDetailsDialogService: AlertDetailsDialogService,
    private investigationService: InvestigationService,
    toastrService: ToastrService,
    toastPackage: ToastPackage) {
    super(toastrService, toastPackage);
  }

  onDetailsClick() {
    const dialogData = { fields: ALERT_DETILES_FIELDS, alert: this.alert };
    this.alertDetailsDialogService.open(dialogData);
    this.onActionClick();
  }

  onInvestigateClick() {
    this.investigationService.investigate(this.alert);
    this.onActionClick();
  }

  onActionClick() {
    this.remove();
  }

}
