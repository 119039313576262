import {Injectable} from '@angular/core';

@Injectable()
export class GroupActionsFollowupService {

  public getSearchFilter(office: Array<string>) {
    return {
      entity: 'profiles',
      baseUrl: 'do/PROFILE/53/',
      searchFilter: [
        {
          columnId: 'REASONS.REASONTYPE',
          operator: 'contains',
          alias: 'REASONS.REASONTYPE',
          value: [
            'FOLLOWUP'
          ],
          hidden: true
        },
        {
          columnId: 'REASONS.OFFICE',
          operator: 'in',
          alias: 'REASONS.OFFICE',
          value: office,
          hidden: true
        }
      ],
      additionalParameters: {
        recStatus: 1
      },
      title: 'REASONS',
      multiSelect: false,
      isSelectType: false
    };
  }
}
