import { Component, Input, OnInit} from '@angular/core';
import {RulesAssociationCommonService} from "../../services/rules-association-common.service";
import {TranslateService} from "@ngx-translate/core";
import {AuditTrailsRulesProfilesManagementService} from "../../../../../profiles/services/audit-trails-rules-profiles-management.service";
import {Subscription} from "rxjs";
import {FtDateTimePipe} from "../../../../../pipes/ftDateTime.pipe";
import {TableRow} from "@fgpp-ui/grid";
import {RulesAssociationApiService} from "../../services/rules-association-api.service";

@Component({
  selector: 'app-rules-association-audit-trail',
  templateUrl: './rules-association-audit-trail.component.html',
  styleUrls: ['./rules-association-audit-trail.component.scss']
})
export class RulesAssociationAuditTrailComponent implements OnInit {
  changes: any[];
  actionSettingAuditTrail = {};
  audit = {};
  @Input() ruleId;
  @Input() ruleProfileId;

  protected subscriber = new Subscription();
  private newValueKey: string;
  private oldValueKey: string;
  complexData = [];
  changedBy: string = '';

  constructor(public rulesAssociationCommonService: RulesAssociationCommonService,
              public auditTrailsRulesProfilesManagementService: AuditTrailsRulesProfilesManagementService,
              private rulesAssociationApiService: RulesAssociationApiService,
              private translate: TranslateService,
              private ftDateTimePipe: FtDateTimePipe) {
  }

  ngOnInit() {
    this.auditTrailsRulesProfilesManagementService.ruleId = this.ruleId;
    this.auditTrailsRulesProfilesManagementService.ruleProfileId = this.ruleProfileId;
    this.auditTrailsRulesProfilesManagementService.resetSearchRequest();
    this.auditTrailsRulesProfilesManagementService.resetGridData();
    this.auditTrailsRulesProfilesManagementService.invokeGridDataChange(true);
  }

  closeAuditTrails() {
    this.rulesAssociationCommonService.show.auditTrail = false;
    this.rulesAssociationCommonService.showRulesList = true;
    this.rulesAssociationCommonService.saveDisable = false;
    this.rulesAssociationCommonService.showAuditDetails = false;
  }

  back() {
    this.rulesAssociationCommonService.show.previewRule = false;
    this.rulesAssociationCommonService.showRulesList = false;
    this.rulesAssociationCommonService.showAuditDetails = false;
    this.rulesAssociationCommonService.show.auditTrail = true;
    this.rulesAssociationCommonService.saveDisable = true;
    this.auditTrailsRulesProfilesManagementService.resetGridData();
    this.auditTrailsRulesProfilesManagementService.invokeGridDataChange(true);
  }

  private searchForComplexData(uid: string, change: any) {
    this.rulesAssociationApiService.getComplexAuditDetails(uid, this.auditTrailsRulesProfilesManagementService.getDefaultSearchRequest()).subscribe((complexData) => {
      let complexDataResource = [];
      let newValues = '';
      let oldValues = '';
      change.newRules = [];
      change.oldRules = [];
      complexData.rows.forEach((row) => {
        // var rowJson = row.toJSON();
        complexDataResource.push(row);
      });
      complexDataResource.forEach((row) => {
        if (row['PROFILE_UPDATE_COMPLEX-PK_PROFILE_UPDATE_COMPLEX'].indexOf('_NEW') !== -1) {
          newValues += row['PROFILE_UPDATE_COMPLEX-COMPLEX_DATA'];
        } else if (row['PROFILE_UPDATE_COMPLEX-PK_PROFILE_UPDATE_COMPLEX'].indexOf('_OLD') !== -1) {
          oldValues += row['PROFILE_UPDATE_COMPLEX-COMPLEX_DATA'];
        }
      });
      change.newValue = this.rulesAssociationCommonService.processComplexData(change, newValues, 'new');
      change.oldValue = this.rulesAssociationCommonService.processComplexData(change, oldValues, 'old');
    });
  }

  showAuditDetails(row: TableRow) {
    this.rulesAssociationCommonService.showAuditDetails = true;
    this.rulesAssociationApiService.getAuditDetails(row['VIRTUAL.UID']).subscribe((res) => {
      this.changes = res['changes'];
      this.formatAuditDetails(row['VIRTUAL.UID']);
    });
    this.audit = row;
    this.changedBy = this.translate.instant('rulesAssociation.auditTrail.madeBy', {username:row['PROFILE_UPDATE-INITIATOR'], timestamp: this.ftDateTimePipe.transform(row['PROFILE_UPDATE.TIME_STAMP']) })
  }

  private formatAuditDetails(uid: string) {
    if (!this.changes) {
      return;
    }
    for (let i = 0 ; i < this.changes.length ; i++) {
      if (this.changes[i].columnName === 'EFFECTIVE_DATE') {
        this.changes.splice(0, 0, this.changes.splice(i, 1)[0]);
        break;
      }
    }
    for (let i = 0 ; i < this.changes.length ; i++) {
      if (this.changes[i].columnName === 'EFFECTIVE_DATE') {
        this.changes[i].oldValue = this.ftDateTimePipe.transform(this.changes[i].oldValue);
        this.changes[i].newValue = this.ftDateTimePipe.transform(this.changes[i].newValue);
      }
      if (this.changes[i].columnName === 'EXPIRY_DATE') {
        this.changes[i].oldValue = this.ftDateTimePipe.transform(this.changes[i].oldValue);
        this.changes[i].newValue = this.ftDateTimePipe.transform(this.changes[i].newValue);
      }
      if (this.changes[i].columnName === 'COMPLEX_DATA') {
        if (this.changes[i].newValue.indexOf(',') != -1) {
          this.changes[i].newValue = this.changes[i].newValue.split(',')[0];
        }
        if (this.changes[i].oldValue.indexOf(',') != -1) {
          this.changes[i].oldValue = this.changes[i].oldValue.split(',')[0];
        }
        this.newValueKey = this.changes[i].newValue;
        this.oldValueKey = this.changes[i].oldValue;
        this.complexData.push(this.newValueKey);
        this.complexData.push(this.oldValueKey);
        this.changes[i].fieldName = this.translate.instant('ruleAssociation.associatedRules');
        this.searchForComplexData(uid, this.changes[i]);
      }
    }
  }
}
