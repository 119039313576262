import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FtDateTimePipe } from 'projects/gpp/src/app/pipes/ftDateTime.pipe';
import { AuditTrail } from '../../models';
import { RuleService } from '../../services/rule.service';

@Component({
  selector: 'app-audit-trail-details',
  templateUrl: './audit-trail-details.component.html',
  styleUrls: ['./audit-trail-details.component.scss']
})
export class AuditTrailDetailsComponent implements OnInit {

  @Input() uid: string;
  @Input() userName: string;
  @Input() timestamp: string;

  @Output() backClicked = new EventEmitter();

  auditTrail: AuditTrail;

  constructor(private ruleService: RuleService,
    private ftDateTimePipe: FtDateTimePipe) { }

  ngOnInit(): void {
    this.ruleService.getAuditTrail(this.uid).subscribe((auditTrail: AuditTrail) => {
      this.auditTrail = auditTrail;
      this.auditTrail.username = this.userName;
      this.auditTrail.timestamp = this.timestamp;
      this.formatAuditDetails();
    });
  }

  private formatAuditDetails() {
    const changes = this.auditTrail.changes;
    for (let i = 0; i < changes?.length; i++) {
      if (changes[i].columnName === 'EFFECTIVE_DATE' || changes[i].columnName === 'EXPIRY_DATE') {
        changes[i].oldValue = this.ftDateTimePipe.transform(changes[i].oldValue);
        changes[i].newValue = this.ftDateTimePipe.transform(changes[i].newValue);
      }
    }
  }

  onBackClick(): void {
    this.backClicked.emit();
  }
}
