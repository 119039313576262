import { Injectable } from '@angular/core';
import { MainTab } from '../../navigation/models';
import { NavigationSearchConfig, SearchBoxItem, SearchResourceConfig, SearchType } from '../models';
import { INavigationSearchConfig } from './interfaces/navigation-search-config-service.interface';
import { SEARCH_BOX_ITEMS } from '../config/search-box-items.config';
import { SEARCH_RESOURCES_CONFIG } from '../config/search-resources.config';

@Injectable()
export class NavigationSearchConfigService implements INavigationSearchConfig {

  private _config: NavigationSearchConfig;
  private _navigationSearchTabs: Array<MainTab>;

  get searchBoxItems(): Array<SearchBoxItem> {
    return this._config.searchBoxItems;
  }

  get searchResources(): Array<SearchResourceConfig> {
    return this._config.searchResources;
  }

  get navigationSearchTabs(): Array<MainTab> {
    return this._navigationSearchTabs;
  }

  constructor() {
    this.initialize();
  }

  getSearchBoxItem(name: string): SearchBoxItem {
    return this.searchBoxItems.find((item: SearchBoxItem) => item.name === name);
  }

  private initialize() {
    this._config = {
      searchBoxItems: SEARCH_BOX_ITEMS,
      searchResources: SEARCH_RESOURCES_CONFIG
    };
    this._navigationSearchTabs = this.getSearchBoxItem(SearchType.NAVIGATION_SEARCH)?.tabs;
  }

}
