import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FtDateTimePipe } from '../../../pipes/ftDateTime.pipe';
import { MessagesQueue } from '../../../messages/models/messages-queue.model';
import { IEntityNavigationService } from '../interfaces/entity-navigation-service.interface';
import { ENTITY_NAVIGATION_TOKEN } from './base-navigation.service';
import { MessagesCenterQueuesAlias } from '../../models/consts/messages-center-queues-alias.const';
import { MessagesType } from '../../../messages/models/enums/messages-type.enum';
import { CallerQueuesTree } from '../../../core/navigation/sitemap/models';

@Injectable()
export class MessagesCenterNavigationService {

  private _navigationsServiceMap: { [key: string]: IEntityNavigationService };

  updateCurrentTreeFunction: Function;
  treeLastUpdate: string;
  updatedByUser = false;

  systemQueuesTree: MessagesQueue;
  callbackQueuesTree: MessagesQueue;
  customQueuesTree: MessagesQueue;
  templatesQueuesTree: MessagesQueue;

  currentQueue = undefined;

  private treeNodeSchema: MessagesQueue = {
    isLeaf: false,
    searchable: false,
    count: 0,
    amount: 0,
    currency: '',
    nodes: []
  };

  constructor(@Inject(ENTITY_NAVIGATION_TOKEN) private navigationsServices: Array<IEntityNavigationService>,
              protected translateService: TranslateService,
              private ftDateTime: FtDateTimePipe) {
    this.treeLastUpdate = this.getCurrentTime();
    this.initializeNavigationsServiceMap();
  }

  private initializeNavigationsServiceMap(): void {
    this._navigationsServiceMap = {};
    this.navigationsServices.forEach((navigationsService: IEntityNavigationService) => {
      this._navigationsServiceMap[navigationsService.type] = navigationsService;
    });
  }

  registerTreeUpdateFunction(treeUpdateFunction: Function): void {
    this.updateCurrentTreeFunction = treeUpdateFunction;
  }

  updateCurrentTree(byUser?: boolean): void {
    if (this.updateCurrentTreeFunction != null) {
      // this is a nice trick, if the return type is a promise it waits for it to resolve, otherwise it resolves immediately
      Promise.resolve(this.updateCurrentTreeFunction()).then(() => {
        this.treeLastUpdate = this.getCurrentTime();
        if (byUser) {
          this.updatedByUser = true;
          window.setTimeout(() => {
            this.updatedByUser = false;
          }, 5000);
        }
      });
    }
  }

  initSystemQueuesTree(): MessagesQueue {
    const treeNode = this.getBasicTreeNode();

    const nodes = this.navigationsServices.map((service: IEntityNavigationService) => {
      const entityTree = service.initSystemQueuesTree();
      if (!entityTree) {
        return null;
      }
      const node = entityTree.nodes[0];
      node.alias = this.translateService.instant(MessagesCenterQueuesAlias[service.type]);
      node.parent = treeNode;
      return node;
    }).filter(node => node != null);

    treeNode.nodes.push.apply(treeNode.nodes, nodes);

    return this.systemQueuesTree = treeNode;
  }

  getQueuesTreeByQueueId(queueId: string, queue: MessagesQueue): void {
    if (queue?.id === queueId) {
      this.currentQueue = queue;
      return;
    }

    if (queue?.id !== queueId && queue?.nodes.length > 0) {
      for (const node of queue.nodes) {
        this.getQueuesTreeByQueueId(queueId, node);
      }
    }
  }

  updateSystemQueuesTree(): Promise<Array<MessagesQueue>> {
    const promises = this.navigationsServices.map((service: IEntityNavigationService) => {
      return service.updateSystemQueuesTree();
    });

    return Promise.all(promises);
  }

  refreshTree(): void {
    if (this.systemQueuesTree) {
      this.updateSystemQueuesTree();
    }
    if (this.customQueuesTree) {
      this.updateCustomQueuesTree();
    }
  }

  initCallbackQueuesTree(callerTree: CallerQueuesTree): MessagesQueue {
    const treeNode = this.getBasicTreeNode();
    const messagesNavigationService = this._navigationsServiceMap[MessagesType.MESSAGES];
    const nodes = messagesNavigationService.initCallbackQueuesTree(callerTree)?.nodes;
    treeNode.nodes.push.apply(treeNode.nodes, nodes);
    return this.callbackQueuesTree = treeNode;
  }

  updateCallbackQueuesTree(): Promise<MessagesQueue> {
    const messagesNavigationService = this._navigationsServiceMap[MessagesType.MESSAGES];
    return messagesNavigationService.updateCallbackQueuesTree().then((callerTree: CallerQueuesTree) => {
      this.callbackQueuesTree.nodes = callerTree.nodes;
      return this.callbackQueuesTree;
    });
  }

  initCustomQueuesTree(): MessagesQueue {
    const treeNode = this.getBasicTreeNode();
    let nodes = this.navigationsServices.map((service: IEntityNavigationService) => {
      service.initCustomQueuesTree();
      return this.setCustomQueuesTreeNodeProperties(service, treeNode);
    });
    nodes = nodes.filter(this.undefinedFilter);
    this.sortCustomQueuesNodes(nodes);
    treeNode.nodes.push.apply(treeNode.nodes, nodes);
    return this.customQueuesTree = treeNode;
  }

  private setCustomQueuesTreeNodeProperties(service: IEntityNavigationService, treeNode: MessagesQueue): MessagesQueue {
    const node = service.customQueuesTree;
    if (!node) {
      return null;
    }
    node.parent = treeNode;
    node.alias = this.translateService.instant(MessagesCenterQueuesAlias[service.type]);
    node.isLeaf = false;
    node.searchable = false;
    node.nodes = node.nodes || [];

    for (let j = 0; j < node.nodes.length; j++) {
      node.nodes[j].parent = node;
    }
    return node;
  }

  private sortCustomQueuesNodes(nodes: MessagesQueue[]): void {
    for (const node of nodes) {
      node.nodes.sort(
        (a, b) => a.id.localeCompare(b.id)
      );
    }
  }

  updateCustomQueuesTree(): Promise<Array<Array<MessagesQueue>>> {
    let promises = this.navigationsServices.map((service: IEntityNavigationService) => {
      return service.updateCustomQueuesTree();
    });

    promises = promises.filter(this.undefinedFilter);
    return Promise.all(promises);
  }

  initTemplatesQueuesTree(): MessagesQueue {
    const treeNode = this.getBasicTreeNode();

    const participatingServices = this.getSupportedTemplates();

    const nodes = participatingServices.map((service: IEntityNavigationService) => {
      const node = service.initTemplatesQueuesTree().nodes[0];
      node.alias = this.translateService.instant(MessagesCenterQueuesAlias[service.type]);
      node.parent = treeNode;
      return node;
    });

    treeNode.nodes.push.apply(treeNode.nodes, nodes);

    return this.templatesQueuesTree = treeNode;
  }

  updateTemplatesQueuesTree(): Promise<Array<MessagesQueue>> {
    const participatingServices = this.getSupportedTemplates();

    const promises = participatingServices.map((service: IEntityNavigationService) => {
      return service.updateTemplatesQueuesTree();
    });

    return Promise.all(promises);
  }

  private getSupportedTemplates(): Array<IEntityNavigationService> {
    return this.navigationsServices.filter((service: IEntityNavigationService) => {
      return service.isSupportTemplates === true;
    });
  }

  private getBasicTreeNode(): MessagesQueue {
    return {
      ...this.treeNodeSchema,
      nodes: [...this.treeNodeSchema.nodes]
    };
  }

  private undefinedFilter(item): boolean {
    return item != null;
  }

  getCurrentTime(): string {
    return this.ftDateTime.transform(Date.now());
  }
}
