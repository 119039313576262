import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Router } from '@angular/router';
import { SitemapNode } from '../../core/navigation/sitemap/models';
import { PowerBIReportMetadata } from '../power-bi/models/power-bi-report-metadata.model';
import { InsightTaskbarService } from '../services/insight-taskbar.service';

@Injectable()
export class PowerBIReportGuard implements CanActivate {

  constructor(private router: Router,
              private insightTaskbarService: InsightTaskbarService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    const reportParam = route.queryParams.report;
    if (!!reportParam) {
      const pbiTaskBar = this.insightTaskbarService.getGuardsAndResolversTaskbar(route);
      const nodes = pbiTaskBar.nodes;
      const isAllowedReport = !!nodes && nodes.findIndex((node: SitemapNode<PowerBIReportMetadata>) => node.id === reportParam) !== -1;
      if (!isAllowedReport) {
        return this.router.createUrlTree(['/home/page-not-found']);
      }
      return true;
    }
    return true;
  }

}
