import { NgModule } from '@angular/core';
import { FileAttachmentRowComponent } from './file-attachment-row/file-attachment-row.component';
import { FileAttachmentsListComponent } from './file-attachments-list/file-attachments-list.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DatePipe, CommonModule } from '@angular/common';
import { AttachmentSharedModule } from '../shared/shared.module';
import { LoaderModule } from '../loader/loader.module';
import {ComponentsModule} from "@fgpp-ui/components";


@NgModule({
    declarations: [
        FileAttachmentRowComponent,
        FileAttachmentsListComponent
    ],
    imports : [
        MatIconModule,
        MatTooltipModule,
        AttachmentSharedModule,
        CommonModule,
        LoaderModule,
      ComponentsModule.forRoot({})
    ],
    providers: [DatePipe],
    exports: [
        FileAttachmentsListComponent
    ]
  })
export class FileAttachmentTableModule {

}
