<form class="dh-signin-form" [formGroup]="form" (ngSubmit)="onFormSubmit()" autocomplete="off">
  <div class="dh-product-title">Payments To Go</div>
  <div>Using DIGIPASS app, scan the second Cronto image</div>
  <img src="do/security/cronto-{{instanceActivationMessage}}.png" class="cronto"
       alt="Second Cronto image"/>
  <mat-form-field [floatLabel]="'always'" class="signature field">
    <input matInput
           placeholder="Signature"
           id="signin-cronto-field"
           type="text"
           autocomplete="off"
           formControlName="signature" />
    <mat-hint>Enter the Signature generated by your device</mat-hint>
  </mat-form-field>
  <button mat-raised-button
          id="signin-cronto-validate-button"
          class="login-button"
          color="primary"
          type="submit">
    <span class="dh-text-label">Activate</span>
  </button>
  <button mat-raised-button
          id="back-button"
          class="back-button"
          color="primary"
          type="button"
          (click)="onBackClick()">
    <span class="dh-text-label">Back</span>
  </button>
</form>
