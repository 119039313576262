import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableRow, FilterOperator, GridColumn, ColumnTypes } from '@fgpp-ui/grid';
import { NotificationService } from '@fgpp-ui/components';
import { AuthenticationService } from 'projects/gpp/src/app/authentication/services/authentication.service';
import { OfficeSelectorService } from 'projects/gpp/src/app/core/services/office-selector.service';
import { PopupService } from 'projects/gpp/src/app/core/services/popup.service';
import { UserSettingsService } from 'projects/gpp/src/app/core/user-settings/services/user-settings.service';
import { Resource } from 'projects/gpp/src/app/grid/models/resource.model';
import { GridColumnsService } from 'projects/gpp/src/app/grid/services/grid-columns.service';
import { GridConfigService } from 'projects/gpp/src/app/grid/services/grid-config.service';
import { PROFILE_ID } from 'projects/gpp/src/app/profiles/components/profiles-grid/profiles-grid.component';
import { ProfilesHelperService } from 'projects/gpp/src/app/profiles/services/profiles-helper.service';
import { ProfilesManagementService, PROFILE_UID } from 'projects/gpp/src/app/profiles/services/profiles-management.service';
import { ProfilesSearchRequestBuilderService } from 'projects/gpp/src/app/profiles/services/profiles-search-request-builder';
import { SpecialItemsService } from 'projects/gpp/src/app/services/special-items.service';
import { SearchFilter } from 'projects/gpp/src/app/shared/models/search-filter.model';
import { FormatsService } from 'projects/gpp/src/app/shared/services/formats.service';
import { take } from 'rxjs';
import { RulesAssociationCommonService } from './rules-association-common.service';

@Injectable()
export class RuleAssociationsEditService extends ProfilesManagementService {

  profileId = PROFILE_ID.RULE;
  office: string;
  virtualSelectedRows: Array<TableRow> = [];
  newSelectedRows: Array<TableRow> = [];

  get ruleTypeFilter(): SearchFilter {
    return {
      columnId: 'PRULES-RULE_TYPE_ID',
      operator: FilterOperator.EQUAL,
      value: [this.ruleType]
    };
  }

  get officeFilter(): SearchFilter {
    return {
      columnId: 'PRULES-OFFICE',
      operator: FilterOperator.IN,
      value: this.offices
    };
  }

  get layerClassificationFilter(): SearchFilter {
    return {
      columnId: 'PRULES-LAYER_CLASSIFICATION',
      operator: FilterOperator.IN,
      value: this.layerClassifications
    };
  }

  get ruleSubTypeFilter(): SearchFilter {
    return {
      columnId: 'PRULES-RULE_SUB_TYPE',
      operator: FilterOperator.EQUAL,
      value: [this.ruleSubType]
    };
  }

  get ruleType() {
    return this.ruleAssociationCommonService.selectedRuleTypeId;
  }

  get ruleSubType() {
    return this.ruleAssociationCommonService.RULE_SUB_TYPE;
  }

  get offices() {
    return ['***', this.office];
  }

  get layerClassifications() {
    const layerClassifications = this.ruleAssociationCommonService.ALL_LAYER;
    return layerClassifications.split(',');
  }

  constructor(userSettingsService: UserSettingsService,
    route: ActivatedRoute,
    router: Router,
    authenticationService: AuthenticationService,
    specialItems: SpecialItemsService,
    officeSelector: OfficeSelectorService,
    popupService: PopupService,
    profilesHelperService: ProfilesHelperService,
    gridColumnsService: GridColumnsService,
    searchRequestBuilderService: ProfilesSearchRequestBuilderService,
    http: HttpClient,
    notificationService: NotificationService,
    gridConfigService: GridConfigService,
    formatsService: FormatsService, private ruleAssociationCommonService: RulesAssociationCommonService) {
    super(userSettingsService, route, router, authenticationService, specialItems, officeSelector, popupService, profilesHelperService,
      gridColumnsService, searchRequestBuilderService, http, notificationService, gridConfigService, formatsService);
  }

  protected resolveGridData(resource: Resource) {
    this.virtualSelectedRows = this.ruleAssociationCommonService.virtualList;
    super.resolveGridData(resource);
    this.addAttachColumn(this.gridData.columns);
    this.ruleAssociationCommonService.rulesGridRows.next({ totalCount: this.gridData.gridData.totalCount });
  }

  async setExternalFilters(filters: Array<SearchFilter>, getMetaData = false): Promise<any> {
    this.externalFilters = filters;
    return await this.setSearchFilters(getMetaData);
  }

  public setSearchRequestFilters(searchFilters: Array<SearchFilter>) {
    this.searchRequestBuilderService.resetPageNo(this.searchRequest);
    this.searchRequestBuilderService.buildFilters(this.searchRequest, searchFilters);
  }

  setSelectedRows(rows: Array<TableRow>): void {
    this.virtualSelectedRows?.forEach((virtualSelectedRow: TableRow) => {
      const selectedRow = rows.find((row: TableRow) => row[PROFILE_UID] === virtualSelectedRow.RULE_UID);
      if (selectedRow) {
        selectedRow.isSelected = true;
        selectedRow.isDisabled = true;
        selectedRow.ATTACH_ICON = 'paperclip';
      }
    });
  }

  setNewSelectedRows(rows: Array<TableRow>): void {
    this.newSelectedRows.forEach((newSelectedRow: TableRow) => {
      const selectedRow = rows.find((row: TableRow) => row[PROFILE_UID] === newSelectedRow[PROFILE_UID]);
      if (selectedRow) {
        selectedRow.isSelected = true;
      }
    });
  }

  addAttachColumn(columns: GridColumn[]): void {
    const attachColumn: GridColumn = {
      type: ColumnTypes.TEXT,
      name: 'ATTACH_ICON',
      displayName: 'attach',
      pinned: false,
      sort: null,
      sortable: false,
      width: 150,
      refinable: false,
      facetable: false,
      presentAsIcon: true
    };
    columns.unshift(attachColumn);
  }

  protected async setSearchFilters(getMetaData: boolean = false): Promise<any> {
    return new Promise(resolve => {
      this.searchRequestBuilderService.resetPageNo(this.searchRequest);
      const filters = this.defaultFilters.concat(this.externalFilters).concat(this.internalFilters);
      this.searchRequestBuilderService.buildFilters(this.searchRequest, filters);
      this.invokeGridDataChange(getMetaData);
      this.subscriber.add(this.gridData$.pipe(take(1)).subscribe((res) => {
        resolve({ res });
      }));
    });
  }

}
