<!-- ruleAssociationAudit.html -->
<!-- Audit trail -->
<div class="rules-association-audit">
  <div class="rules-association-audit-header">
    <div class="rules-association-audit-wrapper" *ngIf="!rulesAssociationCommonService.showAuditDetails">
      {{'system_navigation.entity.audit_trail' | translate}}
    </div>
    <div class="audit-grid-action" *ngIf="rulesAssociationCommonService.showAuditDetails">
      <button mat-button color="primary" type="button" (click)="back()">
        <span class="dh-text-label">{{'system_navigation.entity.audit_trail.audit_trail_changes.back_to_audit_trail' | translate }}</span>
      </button>
      <span class="top-grid-action-section-title" *ngIf="changedBy">{{changedBy}}</span>
    </div>
    <button mat-icon-button class="btn rules-association-audit-close-btn" (click)="closeAuditTrails()"
            [title]="'rules.audit_trail.collapse_tooltip' | translate">
      <app-fn-ui-icon icon="close"></app-fn-ui-icon>
    </button>
  </div>
  <div class="rules-association-audit-grid" *ngIf="!rulesAssociationCommonService.showAuditDetails">
    <app-audit-trail-rules-grid [ruleProfileId]="ruleProfileId" (rowClicked)="showAuditDetails($event)"></app-audit-trail-rules-grid>
  </div>
  <div *ngIf="rulesAssociationCommonService.showAuditDetails">
    <div class="audit-trail-rules">
      <table class="table table-bordered table-striped">
        <tbody>
        <tr>
          <th class="fieldName">{{'rules.auditTrail.fieldName' | translate}}</th>
          <th class="oldValue">{{'rules.auditTrail.oldValue' | translate}}</th>
          <th class="newValue">{{'rules.auditTrail.newValue' | translate}}</th>
        </tr>
        <tr *ngFor="let change of changes">
          <td class="fieldName">{{change.fieldName}}</td>
          <td class="oldValue" *ngIf="change.columnName !== 'COMPLEX_DATA'">{{change.oldValue}}</td>
          <td class="newValue" *ngIf="change.columnName !== 'COMPLEX_DATA'">{{change.newValue}}</td>
          <td class="oldValue complex" *ngIf="change.columnName === 'COMPLEX_DATA'">
            <div *ngFor="let oldRule of change.oldRules">
              <span class="ruleName" *ngIf="oldRule.ruleName">{{oldRule.ruleName}}</span>
              <span class="ruleDesc" *ngIf="oldRule.ruleDescription">{{oldRule.ruleDescription}}</span>
              <span class="ruleDesc" *ngIf="oldRule.customerLevelAction"> | {{oldRule.customerLevelAction}}</span>
            </div>
          </td>
          <td class="newValue complex" *ngIf="change.columnName === 'COMPLEX_DATA'">
            <div *ngFor="let newRule of change.newRules" >
              <span class="ruleName" *ngIf="newRule.ruleName">{{newRule.ruleName}}</span>
              <span class="ruleDesc" *ngIf="newRule.ruleDescription">{{newRule.ruleDescription}}</span>
              <span class="ruleDesc" *ngIf="newRule.customerLevelAction"> | {{newRule.customerLevelAction}}</span>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
