import { Action, State, Selector, StateContext } from '@ngxs/store';
import { SetSystemVariables } from './actions/set-system-variables.action';
import { SystemVariables } from '../services/system-variables/models/system-variables.model';
import { SystemVariablesStateModel } from './models/system-variables-state.model';


@State<SystemVariablesStateModel>({
  name: 'system',
  defaults: {
    systemVariables: {
      PRODUCT_VERSION: '',
      REFRESHPOS: 0,
      WEBIDLTME: 0,
      bffUrl: '',
      dateFormats: [],
      decimalSeparators: [],
      languages: [],
      ldapDomains: [],
      passwordValidationPattern: '',
      thousandSeparators: [],
      timeFormats: []
    }
  }
})
export class SystemVariablesState {

  @Selector()
  static getSystemVariables(state: SystemVariablesStateModel): SystemVariables {
    return state.systemVariables;
  }


  @Action(SetSystemVariables)
  setSystemVariables(ctx: StateContext<SystemVariablesStateModel>, { payload }: SetSystemVariables) {
    ctx.patchState({
      systemVariables: payload
    });
  }
}
