import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[appHighlightSearch]'
})
export class HighlightSearchDirective implements OnChanges {

    @Input() content: string;
    @Input() searchTerm: string;
    @Input() caseSensitive: boolean;
    @Input() highlightColor = 'rgba(var(--color-primary), 0.24)';

    private readonly spacialCharsRegex = /[|\\{}()[\]^$+*?.]/g;

    private get highlightStyle(): string {
        return `padding: 0;
                color: inherit;
                background-color: ${this.highlightColor};`;
    }

    constructor(private element: ElementRef) { }

    ngOnChanges(changes: SimpleChanges): void {
        this.element.nativeElement.innerHTML = this.getHighlightedContent();
    }

    private getHighlightedContent() {
        if (!this.searchTerm) {
            return this.content;
        }
        const searchTerm = this.searchTerm.replace(this.spacialCharsRegex, '\\$&');
        const regex = new RegExp(searchTerm, this.caseSensitive ? 'g' : 'gi');
        return this.content.replace(regex, `<mark style="${this.highlightStyle}">$&</mark>`);
    }

}
