import { Injectable } from '@angular/core';
import { IMapperService } from '../../shared/interfaces/mapper-service.interface';
import { SearchFilter } from '../../shared/models/search-filter.model';
import { Filter } from '@fgpp-ui/grid';

@Injectable()
export class FilterMapperService implements IMapperService<Filter, SearchFilter> {

  map(filter: Filter): SearchFilter {
    if (!filter) {
      return null;
    }
    return {
      columnId: filter.id,
      operator: filter.output.operator,
      value: filter.output.value
    };
  }

  mapAll(filters: Array<Filter>): Array<SearchFilter> {
    if (!filters) {
      return null;
    }
    return filters.map((facet: Filter) => this.map(facet));
  }

}
