import { IEndpoint } from '../interfaces/endpoint.interface';
import { HTTPMethods } from '../enums/http-methods.enum';

export type ProfileOperations = 'contract' | 'state' | 'decision' | 'permissionsAndDefaults' | 'audit' | 'query' | 'create' | 'update' | 'execute' | 'close';

export class ProfileEndpointBuilder {

  private endpoints: { [action: string]: IEndpoint } = {};

  constructor(private baseUrl: string, private operations: ProfileOperations[], private domain: string, private profileId: string) { }

  buildQueryEndpoints() {
    if (this.operations.indexOf('query') !== -1) {
      this.endpoints['grid'] = {
        endpoint: `${this.baseUrl}`,
        method: HTTPMethods.GET
      };

      this.endpoints['data'] = {
        endpoint: `${this.baseUrl}/{UID}`,
        method: HTTPMethods.GET
      };

    } else {
      console.warn(`query not supported by domain ${this.domain} for profile ${this.profileId}`)
    }
    return this;
  }

  buildContractEndpoint() {
    if (this.operations.indexOf('contract') !== -1) {
      this.endpoints['contract'] = {
        endpoint: this.domain === 'bff' ? `/ui/metadata/profiles/${this.profileId}/contract` : `${this.baseUrl}/contract`,
        method: HTTPMethods.GET
      };
    } else {
      console.warn(`contract not supported by domain ${this.domain} for profile ${this.profileId}`)
    }
    return this;
  }

  buildLayoutEndpoint() {
    this.endpoints['layout'] = {
      endpoint: `/ui/metadata/profiles/${this.profileId}/layout`,
      method: HTTPMethods.GET
    };
    return this;
  }

  buildPermissionAndDefaultsEndpoint() {
    if (this.operations.indexOf('permissionsAndDefaults') !== -1) {
      this.endpoints['new'] = {
        endpoint: `${this.baseUrl}/new`,
        method: HTTPMethods.GET
      };
    } else {
      console.warn(`permissionsAndDefaults not supported by domain ${this.domain} for profile ${this.profileId}`)
    }
    return this;
  }

  buildTranslateEndpoint() {
    this.endpoints['l10n'] = {
      endpoint: `/ui/translation/{locale}/business-framework/profiles/${this.profileId}`,
      method: HTTPMethods.GET
    };
    return this;
  }

  buildRelatedEntitiesEndpoint() {
    this.endpoints['relatedEntities'] = {
      endpoint: `${this.baseUrl}/relatedEntities`,
      method: HTTPMethods.GET
    };
    return this;
  }

  buildAuditEndpoint() {
    if (this.operations.indexOf('audit') !== -1) {
      this.endpoints['audit'] = {
        endpoint: `${this.baseUrl}/{UID}/audit`,
        method: HTTPMethods.GET
      };
    } else {
      console.warn(`audit not supported by domain ${this.domain} for profile ${this.profileId}`)
    }
    return this;
  }

  buildExecuteEndpoint() {
    if (this.operations.indexOf('execute') !== -1) {
      this.endpoints['execute'] = {
        endpoint: `${this.baseUrl}/{UID}/execute`,
        method: HTTPMethods.POST
      };

      this.endpoints['executionHistory'] = {
        endpoint: `${this.baseUrl}/{UID}/executionHistory`,
        method: HTTPMethods.GET
      };

      this.endpoints['taskScheduler'] = {
        endpoint: `${this.baseUrl}/{UID}taskScheduler`,
        method: HTTPMethods.GET

      };
    }
    return this;
  }

  buildCreateEndpoint() {
    if (this.operations.indexOf('create') !== -1) {
      this.endpoints['create'] = {
        endpoint: `${this.baseUrl}`,
        method: HTTPMethods.POST
      };
    } else {
      console.warn(`create not supported by domain ${this.domain} for profile ${this.profileId}`)
    }
    return this;
  }

  buildCloseEndpoint() {
    if (this.operations.indexOf('close') !== -1) {
      this.endpoints['close'] = {
        endpoint: `${this.baseUrl}/{UID}/close`,
        method: HTTPMethods.POST
      };
    }
    return this;
  }

  buildUpdateEndpoint() {
    if (this.operations.indexOf('update') !== -1) {
      this.endpoints['save'] = {
        endpoint: `${this.baseUrl}/{UID}`,
        method: HTTPMethods.PUT
      };
    } else {
      console.warn(`update not supported by domain ${this.domain} for profile ${this.profileId}`)
    }
    return this;
  }

  buildDecisionEndpoint() {
    if (this.operations.indexOf('decision') !== -1) {
      this.endpoints['approve'] = {
        endpoint: `${this.baseUrl}/{UID}/approve`,
        method: HTTPMethods.POST
      };

      this.endpoints['decline'] = {
        endpoint: `${this.baseUrl}/{UID}/decline`,
        method: HTTPMethods.POST
      };
    } else {
      console.warn(`decision not supported by domain ${this.domain} for profile ${this.profileId}`)
    }
    return this;
  }

  buildStateEndpoint() {
    if (this.operations.indexOf('state') !== -1) {
      this.endpoints['hold'] = {
        endpoint: `${this.baseUrl}/{UID}/hold`,
        method: HTTPMethods.POST

      };

      this.endpoints['delete'] = {
        endpoint: `${this.baseUrl}/{UID}/delete`,
        method: HTTPMethods.POST
      };

      this.endpoints['retract'] = {
        endpoint: `${this.baseUrl}/{UID}/retract`,
        method: HTTPMethods.POST
      };

      this.endpoints['activate'] = {
        endpoint: `${this.baseUrl}/{UID}/activate`,
        method: HTTPMethods.POST
      };
    } else {
      console.warn(`state not supported by domain ${this.domain} for profile ${this.profileId}`)
    }
    return this;
  }

  get(): { [action: string]: IEndpoint } {
    return this.endpoints;
  }

}
