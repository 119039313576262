import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSelectionList } from '@angular/material/list';
import { AuthenticationMode } from '../../../../authentication/models/enums/authentication-mode.enum';
import { AuthenticationConfigService } from '../../../../authentication/services/authentication-config.service';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent implements OnInit, AfterViewInit, AfterViewChecked {

  @ViewChild(MatSelectionList) sideMenuList: MatSelectionList;

  showChangePassword: boolean;

  constructor(private dialogRef: MatDialogRef<UserSettingsComponent>,
              private authenticationConfigService: AuthenticationConfigService,
              private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.showChangePassword = this.authenticationConfigService.authenticationMode === AuthenticationMode.GPP;
  }

  ngAfterViewInit(): void {
    this.sideMenuList.options.first.selected = true;
    for (const item of this.sideMenuList.options) {
      item._unscopedContent.nativeElement.setAttribute('azure-id', item._unscopedContent.nativeElement.innerText);
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

}
