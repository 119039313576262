import { AfterViewInit, Component, ElementRef, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tour-step',
  templateUrl: './tour-step.component.html',
  styleUrls: ['./tour-step.component.scss']
})
export class TourStepComponent implements AfterViewInit {

  @Input() tourItem;
  @Input() isTourAbortable;
  @Input() isNextLabelEnabled;
  @Input() isPrevLabelEnabled;
  @Input() onOkHandler;
  @Input() onPostponeHandler;
  @Input() onPrevHandler;
  @Input() onNextHandler;
  @Input() onCloseHandler;

  constructor(private elem: ElementRef) {}

  ngAfterViewInit(): void {
    this.elem.nativeElement.setAttribute('azure-id', this.tourItem.text + ' modal');
  }
}
