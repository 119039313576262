import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, ResolveFn } from '@angular/router';
import { UserPreferences } from '../../core/user-settings/models/user-preferences.model';
import { UserSettingsService } from '../../core/user-settings/services/user-settings.service';

export const UserPreferencesResolver: ResolveFn<UserPreferences> =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<UserPreferences> => {

    const userSettingsService = inject(UserSettingsService);

    return userSettingsService.get();
  };
