import { Injectable } from '@angular/core';
import { TreeConfig } from '@fgpp-ui/components';
import { Observable, map, tap } from 'rxjs';
import { LiquidityTaskBarItem, LiquidityFavoritesTree, LiquidityFavorite, AccountNode, LiquidityNode, CustomAccountGroupNode } from '../models';
import { ILiquidityFavoritesService } from './interfaces/liquidity-favorites-service.interface';
import { ILiquidityTaskBarItemService } from './interfaces/liquidity-task-bar-item-service.interface';
import { LiquidityApiService } from './liquidity-api.service';

@Injectable()
export class LiquidityFavoritesService implements ILiquidityFavoritesService, ILiquidityTaskBarItemService {

  get taskBarItem(): LiquidityTaskBarItem {
    return LiquidityTaskBarItem.FAVORITES;
  }

  get treeConfig(): Partial<TreeConfig> {
    return this._treeConfig;
  }

  get tree(): LiquidityFavoritesTree {
    return this._tree;
  }

  get activeNode(): LiquidityFavorite {
    return this._activeNode;
  }

  set activeNode(value: LiquidityFavorite) {
    this._activeNode = value;
  }

  get lastUpdate(): Date {
    return this._lastUpdate;
  }

  private _treeConfig: Partial<TreeConfig>;
  private _tree: LiquidityFavoritesTree;
  private _activeNode: LiquidityFavorite;
  private _lastUpdate: Date;

  constructor(private liquidityApiService: LiquidityApiService) {
    this.initTreeConfig();
  }

  getTree(): Observable<LiquidityFavoritesTree> {
    return this.liquidityApiService.getFavorites().pipe(map((response: Array<string>) => {
      this._tree = this.createTree(response);
      this._lastUpdate = new Date();
      return this._tree;
    }));
  }

  refreshTree(): Observable<LiquidityFavoritesTree> {
    return this.getTree().pipe(tap(() => {
      this.updateActiveNode();
    }));
  }

  findActiveNode(id: string, parentId?: string): LiquidityFavorite {
    for (const favorite of this.tree.nodes) {
      if (this.isMatch(favorite, id, parentId)) {
        return favorite;
      }
      if (this.isCustomAccountGroupNode(favorite)) {
        for (const accountNode of favorite.nodes) {
          if (this.isMatch(accountNode, id, parentId)) {
            return accountNode;
          }
        }
      }
    }
    return null;
  }

  addFavorite(favorite: LiquidityFavorite): Observable<void> {
    return this.liquidityApiService.addFavorite(favorite);
  }

  deleteFavorite(favorite: LiquidityFavorite): Observable<void> {
    const index = this.findFavoriteIndex(favorite.id, (<AccountNode>favorite).data.parentId);
    if (index === -1) {
      console.warn('couldn\'t find liquidity favorite index to delete.');
      return;
    }
    return this.liquidityApiService.deleteFavorite(index);
  }

  private initTreeConfig(): void {
    const getUId = (node: LiquidityNode) => node.data?.parentId ? `${node.data.parentId}.${node.id}` : node.id;
    this._treeConfig = {
      getUId: getUId,
      expandable: (node) => node.nodes?.length > 0,
      selectable: (node) => node.data && !!(node.data.uid || node.data.groupUID),
      trackBy: (node) => node,
      expansionTrackBy: getUId
    };
  }

  private createTree(favorites: Array<string>): LiquidityFavoritesTree {
    const tree = {
      id: null,
      alias: null,
      icon: null,
      data: null,
      nodes: favorites.map(item => JSON.parse(item) as LiquidityFavorite)
    };
    this.setRoutingData(tree);
    return tree;
  }

  private setRoutingData(tree: LiquidityFavoritesTree): void {
    tree.nodes.forEach((favorite: LiquidityFavorite) => {
      favorite.routerLink = `/home/liquidity/${this.taskBarItem}`;
      favorite.stateName = 'home.liquidity.views.favorites.single';
      favorite.stateParams = {
        account: favorite.id,
        parent: (<AccountNode>favorite).data.parentId
      };
      if (this.isCustomAccountGroupNode(favorite)) {
        (<CustomAccountGroupNode>favorite).nodes.forEach((node: AccountNode) => {
          node.routerLink = `/home/liquidity/${this.taskBarItem}`;
          node.stateName = 'home.liquidity.views.favorites.single';
          node.stateParams = {
            account: node.id,
            parent: node.data.parentId
          };
        });
      }
    });
  }

  private updateActiveNode(): void {
    if (this.activeNode) {
      this.activeNode = this.findActiveNode(this.activeNode.id, (<AccountNode> this.activeNode).data.parentId);
    }
  }

  private findFavoriteIndex(id: string, parentId?: string): number {
    return this.tree.nodes.findIndex((favorite: LiquidityFavorite) => this.isMatch(favorite, id, parentId));
  }

  private isMatch(favorite: LiquidityFavorite, id: string, parentId: string): boolean {
    return favorite.id === id && (<AccountNode>favorite).data?.parentId == parentId;
  }

  private isCustomAccountGroupNode(favorite: LiquidityFavorite): boolean {
    return (<CustomAccountGroupNode>favorite).data.hasOwnProperty('groupUID');
  }
}
