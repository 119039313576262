import { NgModule, ModuleWithProviders } from '@angular/core';
import { AzureInsightsService } from './services/azure-insights.service';
import { AzureInsightsConfig } from './services/azure-insights.config';
import { CommonModule } from '@angular/common';
import { GridEventsService } from './services/grid-events.service';

export { AzureInsightsService } from './services/azure-insights.service';
export { AzureInsightsConfig } from './services/azure-insights.config';

@NgModule({
  imports: [CommonModule],
  declarations: [],
  exports: [],
  providers: [ AzureInsightsService ]
})

export class AzureInsightsModule {
  static forRoot(config: AzureInsightsConfig): ModuleWithProviders<AzureInsightsModule> {
    return {
      ngModule: AzureInsightsModule,
      providers: [
        { provide: AzureInsightsConfig, useValue: config },
        AzureInsightsService,
        GridEventsService
      ],
    };
  }

}
