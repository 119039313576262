import {Injectable, Injector} from '@angular/core';
import { NavigationService } from '../../../core/navigation/services/navigation.service';
import { NavigationItem } from '../../../core/navigation/models/navigation-item.model';


@Injectable()
export class BfNewTabService {

  private navigationService: NavigationService;

  constructor(protected injector: Injector) {
    this.navigationService = this.injector.get(NavigationService);
  }

  // todo: refactor
  public openRelatedEntityProfileInNewTab(params: any) {
    const navigationItem = this.navigationService.getNavigationItem(params.profile);
    let url = '#' + navigationItem.routerLink + `?mode=${params.mode}&profile=${params.profileId}&isStandAlone=true`;
    url = params.mode === 'edit' ? url + `&loadType=${params.loadType}&uniqueRecId=${params.uniqueRecId}`: url + `&office=${params.office}`;
    const childWindow: any = window.open( url , '_blank' , 'width=1024px, height=768px, resizable=1, location=yes' );
    childWindow.windowId = params.uniqueRecId;
    //childWindow.popoutWindow = true;
    // const navigationItem = this.navigationService.getNavigationItem(stateParams.profileId) as NavigationItem;
    // const gridStateName = navigationItem?.stateName;
    // const gridStateParams = navigationItem?.stateParams;
    // const stateName = `${gridStateName}.singleProfile`;
    // const mergedStateParams = { ...stateParams, ...gridStateParams };
    // const url = this.stateService.href(stateName, mergedStateParams);
    // window.open(url, '_blank');
  }

}
