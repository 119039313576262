import { Injectable } from '@angular/core';
import {
  ApplicationInsights,
  IAppInsights,
  IEventTelemetry,
  IPageViewTelemetry,
  IExceptionTelemetry,
  ITraceTelemetry,
  IAutoExceptionTelemetry,
  IMetricTelemetry,
  ITelemetryItem,
  IPageViewPerformanceTelemetry
} from '@microsoft/applicationinsights-web';
import { AzureInsightsConfig } from './azure-insights.config';
import { AzureAnalyticsConfig } from '../../config/models';
import { ICookieMgr } from '@microsoft/applicationinsights-core-js';

@Injectable()
export abstract class AzureInsightsAbstartService implements IAppInsights {

  appInsights: ApplicationInsights;
  azureConfig: AzureAnalyticsConfig;
  appInsightsInited = false;

  constructor(private config: AzureInsightsConfig) {
    this.appInsights = new ApplicationInsights({
      config: <any>config
    });
  }

  initAppInsights(instrumentationKey: string) {
    this.config.instrumentationKey = instrumentationKey;
    this.appInsights.loadAppInsights();
  }

  trackEvent(event: IEventTelemetry, customProperties?: { [key: string]: any; }) {
    try {
      this.appInsights.trackEvent(event, customProperties);
    } catch (ex) {
      console.warn('Angular application insights Error [trackEvent]: ', ex);
    }
  }

  startTrackEvent(name: string) {
    try {
      this.appInsights.startTrackEvent(name);
    } catch (ex) {
      console.warn('Angular application insights Error [startTrackEvent]: ', ex);
    }
  }

  stopTrackEvent(name: string, properties?: Object, measurements?: Object) {
    try {
      this.appInsights.stopTrackEvent(name, <any>properties, <any>measurements);
    } catch (ex) {
      console.warn('Angular application insights Error [stopTrackEvent]: ', ex);
    }
  }

  trackPageView(pageView: IPageViewTelemetry) {
    try {
      pageView.uri = 'GPPHOST';
      pageView.refUri = 'GPPHOST';
      this.appInsights.trackPageView(pageView);
    } catch (ex) {
      console.warn('Angular application insights Error [trackPageView]: ', ex);
    }
  }

  startTrackPage(name?: string) {
    try {
      this.appInsights.startTrackPage(name);
    } catch (ex) {
      console.warn('Angular application insights Error [startTrackPage]: ', ex);
    }
  }

  stopTrackPage(name?: string, url?: string, customProperties?: Object) {
    try {
      this.appInsights.stopTrackPage(name, url, customProperties);
    } catch (ex) {
      console.warn('Angular application insights Error [stopTrackPage]: ', ex);
    }
  }

  trackException(exception: IExceptionTelemetry): void {
    try {
      this.appInsights.trackException(exception);
    } catch (ex) {
      console.warn('Angular application insights Error [trackException]: ', ex);
    }
  }

  _onerror(exception: IAutoExceptionTelemetry): void {
    try {
      exception.url = 'GPPHOST';
      this.appInsights._onerror(exception);
    } catch (ex) {
      console.warn('Angular application insights Error [trackTrace]: ', ex);
    }
  }

  trackTrace(trace: ITraceTelemetry, customProperties?: { [key: string]: any; }): void {
    try {
      this.appInsights.trackTrace(trace, customProperties);
    } catch (ex) {
      console.warn('Angular application insights Error [trackTrace]: ', ex);
    }
  }

  trackMetric(metric: IMetricTelemetry, customProperties?: { [key: string]: any; }): void {
    try {
      this.appInsights.trackMetric(metric, customProperties);
    } catch (ex) {
      console.warn('Angular application insights Error [trackTrace]: ', ex);
    }
  }

  addTelemetryInitializer(telemetryInitializer: (item: ITelemetryItem) => boolean | void) {
    try {
      this.appInsights.addTelemetryInitializer(telemetryInitializer);
    } catch (ex) {
      console.warn('Angular application insights Error [addTelemetryInitializer]: ', ex);
    }
  }

  trackPageViewPerformance(pageViewPerformance: IPageViewPerformanceTelemetry): void {
    try {
      this.appInsights.trackPageViewPerformance(pageViewPerformance);
    } catch (ex) {
      console.warn('Angular application insights Error [trackPageViewPerformance]: ', ex);
    }
  }

  setUserId(userId: string = null, accountId: string = null, storeSession: boolean = true) {
    try {
      if (userId === null) {
        userId = this.generateRandomString(6);
      }
      this.appInsights.setAuthenticatedUserContext(userId, accountId, storeSession);
      (<any>window).azureUserId = userId;
    } catch (ex) {
      console.warn('Angular application insights Error [setUserId]: ', ex);
    }
  }

  clearUserId() {
    try {
      this.appInsights.clearAuthenticatedUserContext();
      this.appInsightsInited = false;
    } catch (ex) {
      console.warn('Angular application insights Error [clearAuthenticatedUserContext]: ', ex);
    }
  }

  getCookieMgr(): ICookieMgr {
    return this.appInsights.getCookieMgr();
  }

  private generateRandomString(length: number): string {
    let result = '';
    const stringLength = length;
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
    for (let i = 0; i < stringLength; i++) {
      result += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return result;
  }

}
