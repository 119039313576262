<mat-form-field class="fn-ui-input" [appearance]="appearance" [floatLabel]="floatLabel">

  <mat-label *ngIf="label" class="dh-form-component-header" [ngClass]="{ 'required_input-label': required }">{{ label }}</mat-label>

  <app-fn-ui-icon *ngIf="prefixIcon" [icon]="prefixIcon" size="14px" matPrefix></app-fn-ui-icon>

  <input matInput
         [placeholder]="placeholder"
         [disabled]="disabled"
         [required]="required"
         [(ngModel)]="ngModel"
         (focus)="onFocus($event)"
         (blur)="onBlur($event)"
         (change)="onInputChange($event)">

 <app-fn-ui-icon *ngIf="suffixIcon" [icon]="suffixIcon" size="14px" matSuffix></app-fn-ui-icon>

</mat-form-field>
