import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FtDatePipe } from './ftDate.pipe';
import { FtDateTimePipe } from './ftDateTime.pipe';
import { FtNumberPipe } from './ftNumber.pipe';
import { FtRoundPipe } from './ftRound.pipe';
import { FtTimePipe } from './ftTime.pipe';

@NgModule({
    declarations: [
        FtDatePipe,
        FtDateTimePipe,
        FtNumberPipe,
        FtRoundPipe,
        FtTimePipe
    ],
    imports: [
        CommonModule
    ],
    exports: [
        FtDatePipe,
        FtDateTimePipe,
        FtNumberPipe,
        FtRoundPipe,
        FtTimePipe
    ]
})
export class PipesModule { }
