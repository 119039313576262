import { Component } from '@angular/core';
import { COMMA } from '../../../core/consts/general.const';
import { IValueChange } from '../../../core/interfaces/form-value-change.interface';
import { BFOnFormLoadStart, BFOnValueChange } from '../../../core/interfaces/sdk-profile-hooks.interface';
import { ProfileFormTemplateProviders } from '../../../core/templates/profile-form.template.providers';
import { AbstractProfileFormComponent } from '../../abstract/abstract-profile/abstract-profile-form.component';
import { PartyValidations } from '../../models/party-validations.model';
import { ProfileLayoutType } from '../../enums/profile-layout-type.enum';

@Component({
  selector: 'app-party-validations',
  templateUrl: '../../../core/templates/profile-form.template.html',
  styleUrls: ['../../../core/templates/profile-form.template.scss'],
  providers: ProfileFormTemplateProviders
})
export class PartyValidationsComponent extends AbstractProfileFormComponent implements BFOnFormLoadStart, BFOnValueChange {

  private controlsRelationship = {
    [PartyValidations.FUTURE_DT_CHECK]: PartyValidations.FUTURE_DT_CHECK_EXTNSN,
    [PartyValidations.DUP_BATCH_CHECK]: PartyValidations.DUP_BATCH_CHECK_TIME_FRAME,
    [PartyValidations.DUP_ITEM_CHECK]: PartyValidations.DUP_ITEM_CHECK_TIME_FRAME
  };

  fgppFormLoadStart(): void {
    if (this.layoutMode !== ProfileLayoutType.VIEW) {
      Object.keys(this.controlsRelationship).forEach((controlId: string) => {
        this.handleRelatedControlAvailability(controlId, this.controlsRelationship[controlId]);
      });
    }
  }

  fgppValueChanges(change: IValueChange): void {
    if (this.controlsRelationship[change.control]) {
      this.handleRelatedControlAvailability(change.control, this.controlsRelationship[change.control]);
    }
    if (change.control === PartyValidations.VLD_MSG_TYPES) {
      this.handleValidMsgSubTypesControl();
    }
  }

  private handleRelatedControlAvailability(controlId: string, relatedControlId: string): void {
    const control = this.formMgr.get(controlId);
    const relatedControl = this.formMgr.get(relatedControlId);
    if (control.getValue()) {
      relatedControl.enable().markAsRequired();
    } else {
      relatedControl.disable().markAsOptional().reset();
    }
  }

  private handleValidMsgSubTypesControl(): void {
    const validMsgTypesControl = this.formMgr.get(PartyValidations.VLD_MSG_TYPES);
    const validMsgSubTypesControl = this.formMgr.get(PartyValidations.VLD_MSG_SUB_TYPES);
    if (validMsgTypesControl.getValue()) {
      const isMultiSelectedMsgTypes = validMsgTypesControl.getValue().includes(COMMA);
      if (isMultiSelectedMsgTypes) {
        validMsgSubTypesControl.disable().reset();
      } else {
        validMsgSubTypesControl.enable();
      }
    }
  }

}
