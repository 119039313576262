import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MessagesManagementService } from '../../services/messages-management.service';
import { Select } from '@ngxs/store';
import { MessagesCenterQueueState } from '../../../core/state/messages-center-queue.state';
import { Observable, Subscription } from 'rxjs';
import { MessagesCenterQueue } from '../../../core/models/messages-center-queue.model';
import { MessagesQueueType } from '../../models/enums/messages-queue-type.enum';
import { MessagesType } from '../../models/enums/messages-type.enum';
import { MessagesCenterNavigationService } from '../../../messages-center/messages-center-navigation/services/messages-center-navigation.service';
import { BreadcrumbsService } from '../../../ui-components/services/breadcrumbs.service';

@Component({
  selector: 'app-messages-center-custom-queues',
  templateUrl: 'messages-center-custom-queues.component.html',
})
export class MessagesCenterCustomQueuesComponent implements OnInit, OnDestroy {

  @Select(MessagesCenterQueueState.getCustomQueue) queue$: Observable<MessagesCenterQueue>;

  private _subscription = new Subscription();
  private _stateParams: Params;

  constructor(private breadcrumbs: BreadcrumbsService,
              public messagesCenterNavigationService: MessagesCenterNavigationService,
              private messagesManagementService: MessagesManagementService,
              protected route: ActivatedRoute,
              protected router: Router) {
    this.messagesCenterNavigationService.registerTreeUpdateFunction(this.updateTree);
  }

  ngOnInit() {
    this._subscription.add(this.queue$.subscribe((queue: MessagesCenterQueue) => {
      this.selectTreeItem(queue);
    }));
    this._stateParams = this.route.snapshot.params;
    this.showGridIfNeeded();
  }

  showGridIfNeeded() {
    const itemIdParam = this._stateParams['itemId'];
    if (!!itemIdParam) {
      const item = {
        id: itemIdParam,
        type: MessagesType.MESSAGES,
        queueType: MessagesQueueType.CUSTOM_QUEUE,
        office: itemIdParam.slice(-3), //last 3 chars are the office
        status: itemIdParam.slice(0, -4), //all but last 4 chars (office name + '\') are the status (queue name)
        statusAlias: itemIdParam.slice(0, -4),
        alias: itemIdParam.slice(-3),
        searchable: true
      };

      this.messagesManagementService.onTreeItemSelect(item);
      const stateName = 'messages-center/custom-queues/entity/' + item.type;
      if (this.router.url.split('?')[0] !== stateName) {
        this.router.navigate([stateName], { queryParams: { queueId: item.id } });
      }
      this.breadcrumbs.setBreadcrumbs(this.messagesCenterNavigationService.currentQueue, 'breadcrumbs.CUSTOM_QUEUE', status);
    }
  }

  selectTreeItem(queue: MessagesCenterQueue) {
    if (queue && queue.id) {
      const customQueuesTree = this.messagesCenterNavigationService.customQueuesTree?.nodes.find(node => node.type === queue.entityType);
      this.messagesCenterNavigationService.getQueuesTreeByQueueId(queue.id, customQueuesTree);
      this.messagesCenterNavigationService.currentQueue.queueType = queue.type;
      this.messagesManagementService.onTreeItemSelect(this.messagesCenterNavigationService.currentQueue);
      this.breadcrumbs.setBreadcrumbs(this.messagesCenterNavigationService.currentQueue, 'breadcrumbs.CUSTOM_QUEUE',
        this.messagesCenterNavigationService.currentQueue.status);
    } else {
      this.messagesCenterNavigationService.currentQueue = null;
    }
  }

  /**
   * This function will be passed to a service which it will execute on tree refresh
   * Arrow function is a must in order to keep 'this'
   */
  updateTree = () => {
    return this.messagesCenterNavigationService.updateCustomQueuesTree();
  };


  ngOnDestroy() {
    this._subscription.unsubscribe();
  }
}
