import { Component } from '@angular/core';

@Component({
  selector: 'app-fn-ui-empty-icon',
  templateUrl: './fn-ui-empty-icon.component.html',
  styleUrls: ['./fn-ui-empty-icon.component.scss']
})
export class FnUiEmptyIconComponent {

}
