export const SystemParameters = {
    'OFFICE': 'SYST_PAR-OFFICE',
    'REC_STATUS': 'SYST_PAR-REC_STATUS',
    'PROFILE_CHANGE_STATUS': 'SYST_PAR-PROFILE_CHANGE_STATUS',
    'EFFECTIVE_DATE': 'EFFECTIVE_DATE',
    'CATEGORYNAME': 'PARAMCATEGORY-CATEGORYNAME',
    'SUBCATEGORYNAME': 'PARAMCATEGORY-SUBCATEGORYNAME',
    'PARAM_NAME': 'SYST_PAR-PARAM_NAME',
    'DESCRIPTION': 'SYST_PAR-DESCRIPTION',
    'UNITNAME': 'PARAMUNITS-UNITNAME',
    'PARM_VALUE': 'SYST_PAR-PARM_VALUE',
    'V_PARM_VALUE_DATE': 'SYST_PAR-V_PARM_VALUE_DATE',
    'V_PARM_VALUE_TIME': 'SYST_PAR-V_PARM_VALUE_TIME',
    'V_PARM_VALUE_INTEGER': 'SYST_PAR-V_PARM_VALUE_INTEGER',
    'V_PARM_VALUE_FLOAT': 'SYST_PAR-V_PARM_VALUE_FLOAT',
    'V_PARM_VALUE_SELECT_YESNO': 'SYST_PAR-V_PARM_VALUE_SELECT_YESNO',
    'V_PARM_VALUE_SELECT_LOGLEVEL': 'SYST_PAR-V_PARM_VALUE_SELECT_LOGLEVEL',
    'V_PARM_VALUE_SELECT_BOOLSTRING': 'SYST_PAR-V_PARM_VALUE_SELECT_BOOLSTRING',
    'V_PARM_VALUE_SELECT_REPAIR': 'SYST_PAR-V_PARM_VALUE_SELECT_REPAIR',
    'V_PARM_VALUE_DATE_METHOD': 'SYST_PAR-V_PARM_VALUE_DATE_METHOD',
    'V_PARM_VALUE_FX_SLIDER_OPTIONS': 'SYST_PAR-V_PARM_VALUE_FX_SLIDER_OPTIONS',
    'V_PARM_VALUE_MINUTES_SECONDS': 'SYST_PAR-V_PARM_VALUE_MINUTES_SECONDS',
    'V_PARM_VALUE_NO_TEXTAREA': 'SYST_PAR-V_PARM_VALUE_NO_TEXTAREA',
    'ENABLE': 'SYST_PAR-ENABLE',
    'LAYER_CLASSIFICATION': 'SYST_PAR-LAYER_CLASSIFICATION',
    'PRODUCT_PARENT_UID': 'SYST_PAR-PRODUCT_PARENT_UID',
    'CATEGORYCODE': 'SYST_PAR-CATEGORYCODE',
    'UNITCODE': 'SYST_PAR-UNITCODE',
    'STATUS': 'SYST_PAR-STATUS',
    'TIME_ZONE': 'SYST_PAR-TIME_ZONE',
    'TIME_STAMP': 'TIME_STAMP',
    'UID': 'VIRTUAL-UID',
    'PROFILE_UPDATE_PK': 'PROFILE_UPDATE_PK',
    'PU_TIME_STAMP': 'PU_TIME_STAMP',
    'NOTE': 'NOTE'
};
