import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Favorite } from '../models/favorite.model';
import { FavoritesManagerService } from './favorites-manager.service';
import { IFavoriteActionsService } from './interfaces/favorite-actions.service-interface';
import { ModalsService } from '../../../shared/fn-ui-modals/services/modals.service';
import { NodeAction } from '../../../shared/fn-ui-navigation-actions-tree/models/node-action.model';


@Injectable()
export class FavoriteActionsService implements IFavoriteActionsService {

  get actions(): Array<NodeAction> {
    return [
      this.renameAction,
      this.deleteAction
    ];
  }

  private get renameAction(): NodeAction {
    return {
      name: 'Rename',
      alias: 'tree_action.rename',
      command: this.rename.bind(this)
    };
  }

  private get deleteAction(): NodeAction {
    return {
      name: 'Delete',
      alias: 'tree_action.delete',
      command: this.delete.bind(this)
    };
  }

  constructor(private modalsService: ModalsService,
              private translateService: TranslateService,
              private favoritesManagerService: FavoritesManagerService) { }

  rename(favorite: Favorite): void {
    this.openRenamePrompt(favorite).afterClosed().subscribe((newName: string) => {
      if (!!newName) {
      favorite = { ...favorite, alias: newName };
      this.favoritesManagerService.update(favorite).subscribe();
      }
    });
  }

  delete(favorite: Favorite): void {
    this.favoritesManagerService.delete(favorite).subscribe();
  }

  private openRenamePrompt(favorite: Favorite) {
    return this.modalsService.prompt({
      message: this.translateService.instant('modals.favorites.rename_message'),
      title: this.translateService.instant('modals.favorites.rename'),
      name: favorite.alias
    });
  }

}
