<form class="export-form" [formGroup]="exportForm">

    <div class="export-options">
        <mat-radio-group formControlName="option" (change)="onOptionChange()">
            <mat-radio-button *ngFor="let option of exportOptions"
                               color="primary"
                               [attr.azure-id]="option.value"
                               [attr.automation-id]="'export-'+option.value"
                               [value]="option.value"
                              [disabled]="disableSelectedRowsOption && option.value === exportOptionsEnum.SELECTED_ROWS">
              {{option.key | translate : {topLimit: topLimit} }}
            </mat-radio-button>
        </mat-radio-group>
    </div>

    <div class="range">
        <mat-form-field *ngIf="rangeControl" floatLabel="always">
            <input matInput
                   formControlName="range"
                   azure-id="range"
                   automation-id="export-range"
                   [placeholder]="'export.range.label' | translate"
                   (focus)="onRangeFocus()">
            <mat-hint>{{'export.range.hint' | translate}}</mat-hint>
            <mat-error *ngIf="error(rangeControl)">{{error(rangeControl) | translate}}</mat-error>
        </mat-form-field>
    </div>

    <div class="file-types">
        <label>{{'export.file_type.label' | translate}}</label>
        <mat-radio-group formControlName="fileType">
            <mat-radio-button *ngFor="let type of fileTypes"
                               color="primary"
                               [attr.azure-id]="type.value"
                               [attr.automation-id]="'export-'+type.value"
                               [value]="type.value">{{type.label | translate}}
            </mat-radio-button>
        </mat-radio-group>
    </div>

    <div class="file-name">
        <mat-form-field floatLabel="always">
                <input matInput
                       formControlName="fileName"
                       [placeholder]="'export.file_name.label' | translate"
                       azure-id="file-name"
                       automation-id="export-file-name">

                <mat-error *ngIf="error(fileNameControl)">{{error(fileNameControl) | translate}}</mat-error>
        </mat-form-field>
    </div>

    <div class="file-template">
      <label>{{'export.file_template.label' | translate}}</label>
      <mat-checkbox formControlName="fileTemplate"  color="primary">
        <span>{{fileTemplate | translate}}</span>
      </mat-checkbox>
    </div>

</form>
