import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Note } from '../../mass-pay/entity/models/note.model';

@Injectable({
  providedIn: 'root'
})
export class NoteApiService {

  constructor(private http: HttpClient) { }

  remove(baseUrl: string, timestamp: string): Observable<void> {
    const url = `${baseUrl}notes/${timestamp}`;
    return this.http.delete<void>(url);
  }

  get(url: string): Observable<Note> {
    return this.http.get<Note>(url);
  }

  save(note: Note, url: string): Observable<void> {
    return this.http.post<void>(url, note);
  }
}
