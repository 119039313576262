<div class="notification-bell" [ngClass]="{ 'dark-hover': sidebarMode === 'open' && alertCount }">

  <div class="fn-ui-notification-icon" >
    <button matRipple class="notification-icon-btn" (click)="onBellClick()">
      <ng-content></ng-content>
      <div *ngIf="alertCount" class="count">{{ alertCount | number: '2.' }}</div>
    </button>
  </div>

  <div class="bell-icon" [ngClass]="{ 'empty': !alertCount, 'is-animating': isAnimating }">
    <svg width="20" height="22" viewBox="0 0 21 20">
      <g transform="translate(2, 0)">
        <path class="bell-bow"
              d="M15,8.5 C15,5.43 12.86,2.86 10,2.18 L10,1.5 C10,0.671572875 9.32842712,0 8.5,0 C7.67157288,0 7,0.671572875 7,1.5 L7,2.18 C4.13,2.86 2,5.43 2,8.5 L2,14 L0,16 L0,17 L17,17 L17,16 L15,14 L15,8.5 Z" />
        <path class="bell-clapper"
              d="M2.5,2 C2.64,2 2.77,2 2.9,1.96 C3.55,1.82 4.09,1.38 4.34,0.78 C4.44,0.54 4.5,0.27 4.5,0 L0.5,0 C0.5,1.1045695 1.3954305,2 2.5,2 L2.5,2 Z" />
      </g>
    </svg>
  </div>
  <app-tour-item [tourId]="'mainPage'" [tourStepNumber]="2"></app-tour-item>

</div>
