import { Component, OnDestroy, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BFEvents, NotificationService, UserPreferences} from '@fgpp-ui/components';
import { TranslateService } from '@ngx-translate/core';
import {UserSettingsService} from "../../../core/user-settings/services/user-settings.service";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-operational-dashboard',
  templateUrl: './operational-dashboard.component.html',
  styleUrls: ['./operational-dashboard.component.scss']
})
export class OperationDashboardComponent implements OnInit, OnDestroy {
  userPreferences: UserPreferences;
  dashboardReadyIndicator = true;

  constructor(private route: ActivatedRoute,
              private notificationService: NotificationService,
              private translateService: TranslateService, private userSettingsService: UserSettingsService,
              private http: HttpClient, private router: Router) { }

  ngOnInit(): void {
    this.userPreferences = this.userSettingsService.getPreferences() as any;
  }

  uiEventHandler($event: BFEvents.UIEvent) {
    if ($event.target === 'WIDGET:SYSTEM_QUEUES_TABLE') {
      const queueId = $event.value.data.queueName.toUpperCase() + '/' + $event.value.data.office;
      this.router.navigate([`/home/messages-center/system-queues/messages`], {queryParams: {queueId}, preserveFragment: true} );
    } else if ($event.target === 'WIDGET:CUSTOM_QUEUES_TABLE') {
      const queueId = $event.value.data.queueName  + '/' + $event.value.data.office;
      this.router.navigate([`/home/messages-center/custom-queues/messages`], {queryParams: {queueId}, preserveFragment: true} );
    } else if ($event.target === 'WIDGET:MANUAL_STATUSES_COUNT') {
      const queueId = $event.value.data.metadata.queueName.toUpperCase()  + '/' + $event.value.data.metadata.office;
      this.router.navigate([`/home/messages-center/system-queues/messages`], {queryParams: {queueId}, preserveFragment: true} )
        .then((res) => {
          console.log('navigated');
          }, (err) => {
          console.log('navigation failed');
        });
    } else if ($event.target === 'WIDGET:MESSAGES_PENDING_APPROVAL') {
      const queueId = $event.value.data.metadata.queueName.toUpperCase()  + '/' + $event.value.data.metadata.office;
      this.router.navigate([`/home/messages-center/system-queues/messages`], {queryParams: {queueId}, preserveFragment: true} );
    }
  }

  ngOnDestroy(): void {
  }

  setDashboardReadyState() {
    this.dashboardReadyIndicator = false;
  }
}
