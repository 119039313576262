import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationService } from '@fgpp-ui/components';
import { BehaviorSubject } from 'rxjs';
import { ProfilesHelperService } from './profiles-helper.service';
import { Entity } from '../../shared/models/enums/entity.enum';
import { GridConfigService } from '../../grid/services/grid-config.service';
import { GridManagementService } from '../../grid/services/grid-management.service';
import { SearchFilter } from '../../shared/models/search-filter.model';
import { ProfilesSearchRequest } from '../models/profiles-search-request.type';
import { GridColumnsService } from '../../grid/services/grid-columns.service';
import { ProfilesSearchRequestBuilderService } from './profiles-search-request-builder';
import { FormatsService } from '../../shared/services/formats.service';
import { SearchQueryBuilderService } from '../../shared/services/search-query-builder.service';
import { FilterOperator } from '@fgpp-ui/grid';
import { OfficeSelectorService } from '../../core/services/office-selector.service';
import { memoize } from '../../core/services/memoized.service';
import { AuthenticationService } from '../../authentication/services/authentication.service';
import { SpecialItemsService } from '../../services/special-items.service';
import { UserSettingsService } from '../../core/user-settings/services/user-settings.service';
import { PopupService } from '../../core/services/popup.service';
import { PROFILE_ID } from '../components/profiles-grid/profiles-grid.component';
import {ProfileComponentLoader} from "../../business-framework/config/profile-component-loader";
import { GridApiCacheService } from '../../grid/services/grid-api-cache.service';

export const PROFILE_UID = 'VIRTUAL.UID';

@Injectable()
export class ProfilesManagementService extends GridManagementService<ProfilesSearchRequest> {
  // Added to notify other module if tree items has changed, since on tree item select state is not changed
  treeItemClicked: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  isGridFilteringVisible = false;
  isFacetFilteringVisible = false;
  gridItemsSelected = false;
  hideNewButton: boolean;
  selectedTreeNode = null;
  profileId;
  noOfficeSelector;
  noFormForProfile;
  rows;
  private _loadType;

  set loadType(loadType) {
    this._loadType = loadType;
  }

  get loadType() {
    return this._loadType;
  }

  get baseUrl() {
    return `do/PROFILE/${this.profileId}/`;
  }

  get httpParams(): HttpParams {
    return null;
  }

  get entity(): Entity {
    return Entity.PROFILES;
  }

  get defaultFilters(): Array<SearchFilter> {
    return [{ columnId: '_all', operator: FilterOperator.EQUAL, value: ['*'] }];
  }

  constructor(
    userSettingsService: UserSettingsService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected authenticationService: AuthenticationService,
    protected specialItems: SpecialItemsService,
    protected officeSelector: OfficeSelectorService,
    protected popupService: PopupService,
    protected profilesHelperService: ProfilesHelperService,
    protected gridColumnsService: GridColumnsService,
    protected searchRequestBuilderService: ProfilesSearchRequestBuilderService,
    protected http: HttpClient,
    protected notificationService: NotificationService,
    gridConfigService: GridConfigService,
    formatsService: FormatsService
  ) {
    super(userSettingsService, profilesHelperService, gridConfigService, searchRequestBuilderService, formatsService);
  }

  getDefaultSelectedType(): number {
    return 1;
  }

  getDefaultSearchRequest(): ProfilesSearchRequest {
    const searchRequest = super.getDefaultSearchRequest();
    searchRequest.selectType = this.getDefaultSelectedType();
    searchRequest.searchQuery.additionalParameters = { recStatus: 1 };
    return searchRequest;
  }

  selectTreeItem(navigationTree, profileId?): any {
    const treeNode = navigationTree;
    for (let i = 0; i < treeNode.nodes.length; i++) {
      if (treeNode.nodes[i].id == profileId) {
        this.profileId = treeNode.nodes[i].data.profileId;
        treeNode.nodes[i].selected = true;
        this.selectedTreeNode = treeNode.nodes[i];
        break;
      } else if (treeNode.nodes[i].nodes != null && treeNode.nodes[i].nodes.length > 0) {
        this.selectTreeItem(treeNode.nodes[i], profileId);
      }
    }
  }


  resetGridState(): void {
    this.isGridFilteringVisible = false;
    this.isFacetFilteringVisible = false;
    this.gridItemsSelected = false;
    this.selectedFilters = [];
  }

  setProfileId(profileId): void {
    if (!!profileId) {
      this.profileId = profileId;
    }
  }

  shouldHideCreateButton(node) {
    return node.data.hideNewButton === 'true';
  }

  onSelectProfile(node, fromRouter): void {
    this.hideNewButton = this.shouldHideCreateButton(node);
    this.resetSearchRequest();
    switch (node.data.action) {
      case 'navigateToProfileList':
        this.title = node.alias;
        if (fromRouter === true) {
          this.setRouteFilters();
          this.setRouteSort();
        }
        this.noOfficeSelector = node.data.noOfficeSelector;
        this.noFormForProfile = node.data.noFormForProfile;
        if (this.profileId !== node.data.profileId) {
          this.resetGridData();
          this.gridConfig = this.getGridConfig();
        }
        this.setProfileId(node.data.profileId);
        this.treeItemClicked.next(this.profileId);
        this.updateProfilesView();
        break;
      case 'loadProfile':
        this.openSpecificProfile(node.data.profileId);
        this.setProfileId(undefined);
        break;
      case 'servletRequestFromMenu':
        this.openServletRequestFromMenu(node.data.event);
        this.setProfileId(undefined);
        break;
      case 'openUrlInNewTab':
        this.openUrlInNewTab(node.data.url);
        break;
      default:
        this.notificationService.error('Not Supported Yet');
        this.setProfileId(undefined);
        break;
    }
  }

  private setRouteFilters() {
    let filter = this.route.snapshot.queryParams.filters;
    if (!filter) {
      return;
    }
    if (typeof filter === 'string') {
      filter = JSON.parse(filter);
    }
    this.externalFilters = this.getRouteFilters(filter);
    const filters = this.defaultFilters.concat(this.externalFilters);
    this.searchRequestBuilderService.buildFilters(this.searchRequest, filters);
  }

  private getRouteFilters(filter): Array<SearchFilter> {
    if (filter == null) {
      return [];
    }
    if (Array.isArray(filter)) {
      return filter;
    }
    return SearchQueryBuilderService.searchCriteriaToFilters(filter);
  }

  private setRouteSort() {
    if (this.route.snapshot.queryParams.sort) {
      this.searchRequest.sort = [JSON.parse(this.route.snapshot.queryParams.sort)];
    }
  }

  openSpecificProfile(profileId): void {
    const params = {
      'PK': -1,
      'VIRTUAL.UID': -1,
      'CompositeKeysOriginalValuesString': '',
      '@FORM_NAME@': 'frmLoadProfile',
      '@COMMAND_EVENT@': 'eventLoadForCreate',
      '@SESSION_ID@': this.authenticationService.sessionId,
      'VIRTUAL.STATIC_DATA_ID': profileId,
      'VIRTUAL.LOCK_MODE': 0,
      'FROM_PROFILE_ID': '',
      'PROFILE_SELECTED_OFFICE': '***', // todo - not hardcoded
      'SELECTED_SET': '',
      'SELECTED_SET_INDEX': 0
    };

    this.popupService.open(params, profileId);
  }

  openServletRequestFromMenu(event): void {
    switch (event) {
      case 'eventRefreshSingleProfileJsp':
        this.specialItems.eventRefreshSingleProfileJsp();
        break;
      case 'eventRefreshMsgScreensets':
        this.specialItems.eventRefreshMsgScreensets();
        break;
      case 'eventForceUserLoggout':
        this.specialItems.eventForceUserLoggout();
        break;
      default:
        this.specialItems.devRuntimeEvent(event);
    }
  }

  // TODO - refactor
  openUrlInNewTab(url): void {
    const windowId = 1 + '_ID_' + Math.round(Math.random() * 10000) + Date.now();
    (<Window>window).open('', windowId);

    const unattachedForm = (<Window>window).document.createElement('form');
    unattachedForm.target = windowId;
    unattachedForm.method = 'POST';
    unattachedForm.action = url;
    unattachedForm.style.display = 'none';

    const parameter = (<Window>window).document.createElement('input');
    parameter.type = 'hidden';
    parameter.name = 'session-id';
    parameter.value = this.authenticationService.sessionId;

    unattachedForm.appendChild(parameter);

    (<Window>window).document.body.appendChild(unattachedForm);
    unattachedForm.submit();
  }

  updateProfilesView(): void {
    if (!!this.profileId && !this.route.snapshot.queryParams.ruleId) {
      this.invokeGridDataChange(true);
    }
  }

  createProfile() {
    if (!!this.noOfficeSelector) {
      this.createProfiles();
    } else {
      const selectedOffice = this.officeSelector.open(true);
      selectedOffice.then((office) => {
        if (office) {
          this.createProfiles(office);
        }
      }, () => { });
    }
  }

  createProfiles(office?) {
    const profileInfo = this.getProfileInfo(this.profileId);
    if(ProfileComponentLoader.isMigratedProfile(profileInfo.profileId)) {
      this.router.navigate([this.router.url], {
        queryParams: {
          taskCode: profileInfo.taskCode,
          mode: 'create',
          office:office ? office: '',
          profile: profileInfo.profileId
        },
        state: {
          office: office,
          profile: profileInfo.profileId,
        }
      });
    } else {
      this.onCreateProfiles(office);
    }
    // TODO : uncomment metadata call
/*    this.businessFrameworkCheck(profileInfo.profileId).then((response) => {
        if (response && (profileInfo.taskCode ? response.tasks[profileInfo.profileId] : response.profiles[profileInfo.profileId])) {
          this.router.navigate([this.router.url], {
          queryParams: {
            taskCode: profileInfo.taskCode,
            mode: 'create',
            office:office ? office: '',
            profile: profileInfo.profileId
          },
          state: {
            office: office,
            profile: profileInfo.profileId,
          }
        });
      } else {
        this.onCreateProfiles(office);
      }
    },
      (error) => {
        this.onCreateProfiles(office);
      });*/
  }

  onCreateProfiles(office?) {
    let profileId = this.profileId;
    if (profileId === PROFILE_ID.RULE) {
      this.router.navigate([`${this.router.url.split('?')[0]}/single`], { queryParams: { mode: 'create', office } });
      return;
    }
    if (profileId === PROFILE_ID.RULE_ASSOCIATION) {
      this.router.navigate([this.router.url], { queryParams: { mode: 'create', office } });
      return;
    }
    const taskDelimiterIndex = profileId.indexOf('_');
    let taskCode;
    if (taskDelimiterIndex > 0) {
      taskCode = profileId.substring(taskDelimiterIndex + 1);
      profileId = profileId.substring(0, taskDelimiterIndex);
    }
    const params = {
      'VIRTUAL.STATIC_DATA_ID': profileId,
      'VIRTUAL.LOCK_MODE': 0,
      'FROM_PROFILE_ID': '',
      'SELECTED_SET': [],
      'SELECTED_SET_INDEX': 0,
      '@SESSION_ID@': this.authenticationService.sessionId,
      '@FORM_NAME@': 'frmLoadProfile',
      '@COMMAND_EVENT@': 'eventLoadForCreate',
      'PROFILE_SELECTED_OFFICE': office,
      'TASKS_SETTINGS.TASKCODE': taskCode,
      'VIRTUAL.CREATE_FIELDS': 'TASKS_SETTINGS.TASKCODE@' + taskCode
    };
    this.popupService.open(params, profileId);
  }

  @memoize({ keyResolver: GridApiCacheService.getCacheKeyMigrationMetadata })
  businessFrameworkCheck(profileId: string): Promise<any> {
    return this.http.get('/ui/metadata').toPromise();
  }

  onRowClicked(row: any, selectedRows: any[], isOldUIProfile: boolean) {
    if (isOldUIProfile) {
      this.navigateToProfile(row, selectedRows);
    }
  }

  navigateToProfile(row: any, selectedRows: any[]) {
    const noFormNeededForProfile = this.noFormForProfile != null && this.noFormForProfile === 'true';
    if (!noFormNeededForProfile) {
      this.openProfiles([row], selectedRows);
    }
  }


  encodeCharacter(selectedSet, item) {
    if (item && item['NLS_CODE_CONVERSION-NLS_CHARACTER']) {
      const res = selectedSet.split('^');
      let finalResult = '';
      const encodedString = '&#' + res[1].charCodeAt() + ';';
      finalResult = this._replaceAt(selectedSet, res[1], encodedString, 0, selectedSet.length);
      return finalResult;
    } else {
      return selectedSet;
    }
  }

  _replaceAt(input, search, replace, start, end) {
    return input.slice(0, start)
      + input.slice(start, end).replace(search, replace)
      + input.slice(end);
  }


  openProfiles(items: any[], selectedRows: any[], profile?) {
    const selectedSet = this._getSelectedSet(items, selectedRows);
    const selectedSetIndex = this._getSelectedIndex(items[0], selectedSet);
    let profileId = profile || this.profileId;
    const taskDelimiterIndex = profileId.indexOf('_');
    if (taskDelimiterIndex > 0) {
      profileId = profileId.substring(0, taskDelimiterIndex);
    }

    if (items.length < 1) {
      return;
    }

    const item = items[0];

    let commandEvent = '';
    switch (this.searchRequest.selectType) {
      case 3:
        commandEvent = 'eventLoadForApproval';
        break;
      case 2:
        if (profileId === '428') {
          commandEvent = 'eventLoadInactiveForUpdate';
          break;
        }
        break;
      default:
        commandEvent = 'eventLoadForUpdate';
        break;
    }


    const params = {
      'PK': (profileId === '487') ? this.encodeCharacter(selectedSet[selectedSetIndex], item) : selectedSet[selectedSetIndex],
      'VIRTUAL.UID': (profileId === '487') ? this.encodeCharacter(selectedSet[selectedSetIndex], item) : selectedSet[selectedSetIndex],
      'VIRTUAL.STATIC_DATA_ID': profileId,
      'VIRTUAL.LOCK_MODE': 0,
      'FROM_PROFILE_ID': '',
      'SELECTED_SET': (profileId === '487') ? this.encodeCharacter(selectedSet[selectedSetIndex], item) : selectedSet.join(),
      'SELECTED_SET_INDEX': selectedSetIndex,
      '@SESSION_ID@': this.authenticationService.sessionId,
      '@FORM_NAME@': 'frmLoadProfile',
      '@COMMAND_EVENT@': commandEvent
    };

    if (this.searchRequest.selectType === 3) {
      params['PROFILE_UPDATE.TIME_STAMP'] = item['PROFILE_UPDATE.TIME_STAMP'];
      params['PROFILE_UPDATE_PK'] = item['PROFILE_UPDATE.PK_PROFILE_UPDATE'];
    }

    this.popupService.open(params, profileId).then();
  }

  _getSelectedIndex(row: any, selectedRows: any[]) {
    if (selectedRows.length > 1) {
      return selectedRows.indexOf(row['VIRTUAL.UID']);
    }
    return 0;
  }

  _getSelectedSet(items: any[], selectedRows: any[]): any[] {
    if (selectedRows.length > 1) {
      const uids = selectedRows.map(this._mapVirtualUid);
      if (uids.indexOf(items[0]['VIRTUAL.UID']) > -1) {
        return uids;
      }
    }
    return items.map(this._mapVirtualUid);
  }

  _mapVirtualUid(itemArr) {
    if (itemArr) {
      return itemArr['VIRTUAL.UID'];
    } else {
      return '';
    }
  }

  setSearchScope(selectType: number) {
    console.log('this.searchRequest: ', this.searchRequest);
    this.searchRequestBuilderService.buildScope(this.searchRequest, selectType);
    this.invokeGridDataChange(true);
  }

  getProfileInfo(profileId) {
    const taskDelimiterIndex = profileId?.indexOf('_');
    let taskCode;
    if (taskDelimiterIndex > 0) {
      taskCode = profileId.substring(taskDelimiterIndex + 1);
      profileId = profileId.substring(0, taskDelimiterIndex);
      return { profileId, taskCode };
    } else {
      return { profileId };
    }
  }
}
