<app-fn-ui-popover popoverTriggerClass="restricted-logical-field-popover-trigger mat-icon-button"
                   popoverTriggerColor="primary"
                   popoverClass="restricted-logical-field-popover">
  <ng-template #popoverTriggerContent>
    <app-fn-ui-icon class="restricted-logical-field-icon" icon="block_helper" size="11px"></app-fn-ui-icon>
  </ng-template>

  <ng-template #popoverHeader>
    <span>{{ 'rules.restricted_fields.title' | translate }}</span>
  </ng-template>

  <ng-template #popoverBody>
    <span class="restricted-logical-field-message">
      {{ 'rules.restricted_fields.notification' | translate }}
      <br><br>
      {{ 'rules.restricted_fields.confirmationMessage' | translate }}
    </span>
  </ng-template>

  <ng-template #popoverFooter>
    <app-fn-ui-button name="copy-to-clipboard"
                      class="copy-to-clipboard-button"
                      (click)="onCopyToClipboardClick()">
      <app-fn-ui-icon class="restricted-logical-field-button-icon" icon="content_copy" size="16px"></app-fn-ui-icon>
      <span class="restricted-logical-field-button-text">{{ 'rules.preview.copy_to_clipboard' | translate }}</span>
    </app-fn-ui-button>

    <app-fn-ui-button name="reject"
                      class="reject-button"
                      type="flat"
                      color="primary"
                      (click)="onRejectClick()">
      <span class="restricted-logical-field-button-text">{{ 'rules.delete_base_condition.reject' | translate }}</span>
    </app-fn-ui-button>
  </ng-template>

</app-fn-ui-popover>