export enum InterfaceTypes  {
	"MESSAGE_WAIT_STATUS"= "INTERFACE_TYPES-MESSAGE_WAIT_STATUS",
	"MULTIPLE_DESTINATIONS"= "INTERFACE_TYPES-MULTIPLE_DESTINATIONS",
	"NOTIFICATION_VERSION"= "INTERFACE_TYPES-NOTIFICATION_VERSION",
	"NOT_ACTIVE_BEHAVIOUR"= "INTERFACE_TYPES-NOT_ACTIVE_BEHAVIOUR",
	"NO_OF_LISTENERS"= "INTERFACE_TYPES-NO_OF_LISTENERS",
	"OFFICE"= "INTERFACE_TYPES-OFFICE",
	"PCI_DSS_COMPLIANT"= "INTERFACE_TYPES-PCI_DSS_COMPLIANT",
	"PMNT_SRC"= "INTERFACE_TYPES-PMNT_SRC",
	"PREFERENCES_NAME"= "INTERFACE_TYPES-PREFERENCES_NAME",
	"PRODUCT_PARENT_UID"= "INTERFACE_TYPES-PRODUCT_PARENT_UID",
	"PROFILE_CHANGE_STATUS"= "INTERFACE_TYPES-PROFILE_CHANGE_STATUS",
	"REC_STATUS"= "INTERFACE_TYPES-REC_STATUS",
	"REQUEST_CONNECTIONS_POINT"= "INTERFACE_TYPES-REQUEST_CONNECTIONS_POINT",
	"REQUEST_DIRECTION"= "INTERFACE_TYPES-REQUEST_DIRECTION",
	"REQUEST_FORMAT_TYPE"= "INTERFACE_TYPES-REQUEST_FORMAT_TYPE",
	"REQUEST_PROTOCOL"= "INTERFACE_TYPES-REQUEST_PROTOCOL",
	"RESPONSE_CONNECTIONS_POINT"= "INTERFACE_TYPES-RESPONSE_CONNECTIONS_POINT",
	"RESPONSE_FORMAT_TYPE"= "INTERFACE_TYPES-RESPONSE_FORMAT_TYPE",
	"RESPONSE_INTERFACE"= "INTERFACE_TYPES-RESPONSE_INTERFACE",
	"RESPONSE_PROTOCOL"= "INTERFACE_TYPES-RESPONSE_PROTOCOL",
	"RESPONSE_TIMEOUT_MILLIS"= "INTERFACE_TYPES-RESPONSE_TIMEOUT_MILLIS",
	"RESPONSE_TIMEOUT_RETRY_NUM"= "INTERFACE_TYPES-RESPONSE_TIMEOUT_RETRY_NUM",
	"STOP_AFTER_CONN_EXCEPTION"= "INTERFACE_TYPES-STOP_AFTER_CONN_EXCEPTION",
	"THROTTLING_MILLIS"= "INTERFACE_TYPES-THROTTLING_MILLIS",
	"THROTTLING_TX"= "INTERFACE_TYPES-THROTTLING_TX",
	"TRANSFORMATION_ERROR_STORAGE"= "INTERFACE_TYPES-TRANSFORMATION_ERROR_STORAGE",
	"TRANSFORMATION_PREFERENCES"= "INTERFACE_TYPES-TRANSFORMATION_PREFERENCES",
	"FIELD_NAME"= "INTERFACE_TYPES_PROPERTIES-FIELD_NAME",
	"VALUE"= "INTERFACE_TYPES_PROPERTIES-VALUE",
	"ALLOWED_MOP_FORMATS"= "INTERFACE_TYPES-ALLOWED_MOP_FORMATS",
	"BULKING_TRIGGER_METHOD"= "INTERFACE_TYPES-BULKING_TRIGGER_METHOD",
	"CUSTOM_PROPERTIES"= "INTERFACE_TYPES-CUSTOM_PROPERTIES",
	"DESTINATION_ID"= "INTERFACE_TYPES-DESTINATION_ID",
	"EFFECTIVE_DATE"= "EFFECTIVE_DATE",
	"INTERFACE_NAME"= "INTERFACE_TYPES-INTERFACE_NAME",
	"INTERFACE_STATUS"= "INTERFACE_TYPES-INTERFACE_STATUS",
	"INTERFACE_SUB_TYPE"= "INTERFACE_TYPES-INTERFACE_SUB_TYPE",
	"INTERFACE_TYPE"= "INTERFACE_TYPES-INTERFACE_TYPE",
	"INVALID_RESPONSE_IN"= "INTERFACE_TYPES-INVALID_RESPONSE_IN",
	"IS_BULK"= "INTERFACE_TYPES-IS_BULK",
	"MESSAGE_STOP_STATUS"= "INTERFACE_TYPES-MESSAGE_STOP_STATUS",
	"ASSOCIATED_SERVICE_NAME"= "INTERFACE_TYPES-ASSOCIATED_SERVICE_NAME",
	"TIME_STAMP"= "TIME_STAMP",
	"UID"= "VIRTUAL-UID",
	"PROFILE_UPDATE_PK"= "PROFILE_UPDATE_PK",
	"PU_TIME_STAMP"= "PU_TIME_STAMP",
	"NOTE"= "NOTE",
  DETAILS = 'DETAILS'
};
