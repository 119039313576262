import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Alert } from '../../models/alert.model';
import { Field } from '../alert-details-dialog/models/field.model';
import { ActionLabels } from '../alert-details-dialog/models/action-labels.model';
import { TranslateService } from '@ngx-translate/core';

const EMPTY_PLACEHOLDER = '-';

@Component({
  selector: 'app-alert-more-details',
  templateUrl: './alert-more-details.component.html',
  styleUrls: ['./alert-more-details.component.scss']
})
export class AlertMoreDetailsComponent {

  @Input() fields: Array<Field>;

  @Input()
  get alert(): Alert {
    return this._alert;
  }
  set alert(value: Alert) {
    this._alert = value;
    this._setFieldValues();
  }
  private _alert: Alert;

  @Input()
  get actionLabels(): ActionLabels {
    return this._actionLabels;
  }
  set actionLabels(value: ActionLabels) {
    this._actionLabels = this._getActionLabels(value);
  }
  private _actionLabels = this.defaultLabels;

  @Output() closeClick: EventEmitter<void> = new EventEmitter();
  @Output() investigateClick: EventEmitter<void> = new EventEmitter();

  get defaultLabels(): ActionLabels {
    return {
      close: this.translateService.instant('general.close'),
      investigate: this.translateService.instant('alert.action.investigate')
    };
  }

  constructor(private translateService: TranslateService) { }

  onCloseClick() {
    this.closeClick.emit();
  }

  onInvestigateClick() {
    if (this.alert.permittedQueue) {
      this.investigateClick.emit();
    }
  }

  private _getActionLabels(value: ActionLabels) {
    return value ? { ...this.defaultLabels, ...value } : this.defaultLabels;
  }

  private _setFieldValues() {
    this.fields.forEach((field: Field) => {
      const fieldValue = this.alert[field.name];
      if (!fieldValue) {
        field.value = EMPTY_PLACEHOLDER;
      } else {
        if (field.translate) {
          field.value = this.translateService.instant(`alert.${field.name}.${fieldValue}`);
        } else {
          field.value = fieldValue;
        }
      }
    });
  }

}
