import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LiquidityStoreService } from '../../services/liquidity-store.service';
import { LiquidityService } from '../../services/liquidity.service';
import { LIQUIDITY_DEFAULT_STATE } from '../../../core/state/liquidity.state';

@Component({
  selector: 'app-liquidity',
  templateUrl: './liquidity.component.html',
  styleUrls: ['./liquidity.component.scss']
})
export class LiquidityComponent implements OnInit, OnDestroy {

  private _subscriber = new Subscription();

  constructor(private route: ActivatedRoute,
              private router: Router,
              private liquidityService: LiquidityService,
              private liquidityStoreService: LiquidityStoreService) {
    this.subscribeToQueryParams();
  }

  ngOnInit(): void {
    this.liquidityService.postViaForm('/gppold/webapp/gppFELiquidity.htm', {}, 'liquidity');
  }

  ngOnDestroy(): void {
    this._subscriber.unsubscribe();
  }

  private subscribeToQueryParams(): void {
    const subscription = this.route.queryParams.subscribe((queryParams: Params) => {
      this.onQueryParamsChange(queryParams);
    });
    this._subscriber.add(subscription);
  }

  private onQueryParamsChange(queryParams: Params): void {
    const activeTaskBarItem = this.route.snapshot.params.taskBarItem;
    const service = this.liquidityService.getTaskBarItemService(activeTaskBarItem);
    if (!service) {
      return;
    }
    if (queryParams.account) {
      const activeNode = service.findActiveNode(queryParams.account, queryParams.parent);
      if (!activeNode) {
        setTimeout(() => {
          if (queryParams.isLastState) {
            this.router.navigate([LIQUIDITY_DEFAULT_STATE.path]);
          } else {
            this.router.navigate(['/home/page-not-found'], { replaceUrl: true });
          }
        });
        return;
      }
      service.activeNode = activeNode;
      this.liquidityStoreService.setLiquidityRoute(activeNode.routerLink, activeNode.stateParams);
      this.liquidityService.loadLiquidityScreen(activeNode);
    } else {
      service.activeNode = null;
    }
  }

}
