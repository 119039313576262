import { Injectable } from '@angular/core';
import { IAttachmentsAccessService } from './interfaces/attachments-access-service.interface';
import { ProfilesApiService } from '../../../profiles/services/profiles-api.service';
import { NavigationService } from '../../../core/navigation/services/navigation.service';
import { ProfileNodeData } from '../../../core/navigation/sitemap/models';

@Injectable({
  providedIn: 'root'
})
export class AttachmentsAccessService implements IAttachmentsAccessService {

  constructor(private navigationService: NavigationService,
              private profilesApiService: ProfilesApiService) { }

  supportAttachments(profileId: string): boolean {
    const navigationItem = this.navigationService.getNavigationItem(profileId);
    return navigationItem && (<ProfileNodeData>navigationItem.data).attachmentsButton === 'true';
  }

  getUserPermission(profileId: string, office: string, department: string, uid: string): Promise<string> {
    return this.profilesApiService.getUserPermission(profileId, office, department, uid);
  }
}
